import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

@Directive({
	selector: '[pIcon]',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class IconDirective implements OnInit {

	/**
	 * pIcon should be used to add icons to an element.
	 * You can also use pIconPlacement to decide if it should be before or after the element.
	 * By default it will be before. Example:
	 * <span [pIcon]="PlanoFaIconPool.NAV_FORWARD" pIconPlacement="after">Next</span>
	 */
	@Input() private pIcon! : PlanoFaIconPoolValues;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private pIconPlacement : 'before' | 'after' = 'before';

	constructor(private elementRef : ElementRef<HTMLElement>) {

	}

	public ngOnInit() : void {
		const iconElement : HTMLElement = document.createElement('i');
		iconElement.classList.add('fa-fw', 'fas', `fa-${this.pIcon}`, 'small', 'pIcon');
		const htmlElement = this.elementRef.nativeElement;
		htmlElement.style.display = 'inline-flex';
		htmlElement.style.alignItems = 'center';
		htmlElement.style.verticalAlign = 'top';
		htmlElement.style.gap = '0.25rem';
		if (this.pIconPlacement === 'before') {
			htmlElement.insertBefore(iconElement, htmlElement.firstChild);
		} else {
			htmlElement.appendChild(iconElement);
		}

	}

}
