import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { TimeStampApiService, TimeStampApiShift, TimeStampApiShiftModel } from '@plano/shared/api';
import { DateTime, PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { LogService } from '@plano/shared/core/log.service';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PPushNotificationsService, PRequestWebPushNotificationPermissionContext } from '@plano/shared/core/p-push-notifications.service';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidationErrors, PValidatorObject } from '@plano/shared/core/validators.types';
import { PAISwitchComponent } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';

@Component({
	selector: 'p-stopwatch',
	templateUrl: './stopwatch.component.html',
	styleUrls: ['./stopwatch.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class StopwatchComponent implements AfterContentInit, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onEnd = new EventEmitter<undefined>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private selectedItem : TimeStampApiShift | TimeStampApiShiftModel | null = null;

	@ViewChild('inputDateStartAiSwitchRef') private inputDateStartAiSwitchRef ! : PAISwitchComponent;
	@ViewChild('inputDateEndAiSwitchRef') private inputDateEndAiSwitchRef ! : PAISwitchComponent;

	constructor(
		public api : TimeStampApiService,
		private modalService : ModalService,
		public toasts : ToastsService,
		private pFormsService : PFormsService,
		private validators : ValidatorsService,
		private pPushNotificationsService : PPushNotificationsService,
		private console : LogService,
		private pMomentService : PMomentService,
	) {
		this.initValues();
	}

	public PApiPrimitiveTypes = PApiPrimitiveTypes;
	public PBtnThemeEnum = PBtnThemeEnum;
	public enums = enumsObject;

	public formGroup : FormGroup<{
		'duration' : PFormControl<number>,
	}> | null = null;
	private now ! : number;

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		this.now = +this.pMomentService.m();
	}

	private intervalRefresh : number | null = null;

	public ngAfterContentInit() : void {
		this.initFormGroup();
	}

	public ngOnDestroy() : void {
		if (this.intervalRefresh) {
			window.clearInterval(this.intervalRefresh);
			this.intervalRefresh = null;
		}
	}

	/**
	 * Initialize the formGroup for this component
	 */
	protected initFormGroup() : void {
		if (this.formGroup) this.formGroup = null;

		const tempFormGroup = this.pFormsService.group({}) as FormGroup<{
			'duration' : PFormControl<number>,
		}>;

		// We should have something like <p-input type="Minutes" [formControlName]="duration"></p-input>
		this.pFormsService.addControl(tempFormGroup, 'duration', {
			value: this.api.data.regularPauseDuration,
			disabled: false,
		}, [
			this.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.Integer),
			this.validators.required(PApiPrimitiveTypes.Duration),

			new PValidatorObject({name: PPossibleErrorNames.MAX, fn: (control : Pick<AbstractControl<typeof this.api.data.regularPauseDuration | null>, 'value'>) => {
				return control.value !== null ? this.maxPauseValidator(control.value) : null;
			}}),
		], () => {});
		this.formGroup = tempFormGroup;
	}

	/**
	 * Exists in the following components:
	 * - DetailFormComponent
	 * - StopwatchComponent
	 *
	 * @param value The value that should be validated
	 */
	private maxPauseValidator(value : number) : PValidationErrors | null {
		let end : DateTime;
		let start : DateTime | null = null;
		if (this.api.data.selectedItem instanceof TimeStampApiShift) {
			start = this.api.data.selectedItem.start;
			end = this.api.data.selectedItem.end;
		} else {
			start = this.api.data.start;
			end = +this.pMomentService.m();
		}
		if (start === null) return null;
		const maxDurationOfPause = end - start;
		const limitAsMinutes = this.pMomentService.d(maxDurationOfPause).asMinutes();
		const controlValueAsMinutes = this.pMomentService.d(value).asMinutes();
		return this.validators.max(
			limitAsMinutes,
			true,
			PApiPrimitiveTypes.Minutes,
			undefined,
			'Die Pause war länger als die Arbeitszeit? Witzbold ;)',
		).fn({value: controlValueAsMinutes});
	}

	/**
	 * Should the user be able to add a pause for this shift? If not, user must stamp the pause.
	 */
	public get isAddPauseMode() : boolean {
		if (this.api.data.selectedItem instanceof TimeStampApiShift) {
			if (this.api.data.whenMemberStampedStart === null) return false;

			// Did the user click the start button after shift.end?

			const shiftStartTimestamp = this.api.data.whenMemberStampedStart;

			/**
			 * Changed the boolean check to fix problem in PLANO-154264,
			 * users will be able to change the pause duration if
			 * they didn't stamp the correct time.
			 */
			const endOfShiftWithTolerance = +this.pMomentService.m(this.api.data.selectedItem.end).subtract(10, 'minutes');

			const hasAddPauseBehavior = this.api.data.warnStampedNotCurrentTime ||
												(shiftStartTimestamp > this.api.data.selectedItem.end) ||
												(+this.pMomentService.m() >= endOfShiftWithTolerance);

			if (hasAddPauseBehavior && this.api.isPausing) {
				this.api.completePause();
				void this.api.save();
			}

			return hasAddPauseBehavior;
		}

		if (this.api.data.selectedItem instanceof TimeStampApiShiftModel && this.api.data.start) {
			// Did the user select a start time that is before/outside a limit?
			const minLimit = +this.pMomentService.m().subtract(3, 'hours');
			return this.api.data.warnStampedNotCurrentTime || (this.api.data.start < minLimit);
		}

		return false;
	}

	/**
	 * Should the start button be disabled?
	 */
	public get startButtonDisabled() : boolean {
		const started = !!this.api.data.start;
		const isInvalid = !this.selectedItem;
		return isInvalid || started;
	}

	/**
	 * Should the Pause button be disabled?
	 */
	public get pauseButtonDisabled() : boolean {
		return !this.api.timeStampIsRunning() || this.api.hasDataCopy();
	}

	/**
	 * Should the Stop button be disabled?
	 */
	public get stopButtonDisabled() : boolean {
		return !this.api.timeStampIsRunning() || this.api.hasDataCopy();
	}

	/**
	 * Start the Pause and save it
	 */
	private startPause() : void {
		this.api.startPause();
		void this.api.save();
	}

	/**
	 * Stop the Pause and save it
	 */
	private stopPause() : void {
		this.api.completePause();
		void this.api.save();
	}

	/**
	 * Ask for permission to send browser notifications if necessary.
	 */
	protected askForNotificationPermissionIfNecessary() : void {
		if (!(this.selectedItem instanceof TimeStampApiShift)) return;
		const deadline = +this.pMomentService.m(this.now).subtract(10, 'hours');
		if (this.selectedItem.end > deadline) return;

		// End was more then 10 hours ago. So user probably forgot to stamp his/her shift.
		this.pPushNotificationsService.requestWebPushNotificationPermission(
			PRequestWebPushNotificationPermissionContext.STAMPED_PAST_SHIFT,
		);
	}

	private storeSelectedItemIntoApiData() : void {
		assumeNonNull(this.selectedItem, 'selectedItem', 'No item is selected. Start button should have been disabled.');
		if (this.selectedItem instanceof TimeStampApiShift) {
			this.api.data.selectedShiftId = this.selectedItem.id;
		} else {
			this.api.data.selectedShiftModelId = this.selectedItem.id;
		}
	}

	/**
	 * Stop the pause
	 */
	public togglePause() : void {
		if (this.api.isPausing) {
			this.stopPause();
		} else {
			this.startPause();
		}
	}

	/**
	 * Add the pause
	 * @param modalContent The content for the modal that asks for the pause duration
	 */
	public addPause(modalContent : TemplateRef<PModalTemplateDirective>) : ModalRef {
		this.initFormGroup();
		const modalRef = this.modalService.openModal(modalContent);
		void modalRef.result.then(promiseResult => {
			if (promiseResult.modalResult === 'dismiss') {
				this.initFormGroup();
			} else {
				assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
				this.api.completePause(this.formGroup.get('duration')!.value!);
				void this.api.save();
			}
		});
		return modalRef;
	}

	/** A time the user probably wants to set */
	public get suggestionTimestampForStart() : number | null {
		if (this.selectedItem instanceof TimeStampApiShift) {
			return this.selectedItem.start;
		} else if (this.selectedItem instanceof TimeStampApiShiftModel) {
			return null;
		}
		return null;
	}

	/** A time the user probably wants to set */
	public get suggestionTimestampForEnd() : number | null {
		const selectedItem = this.selectedItem ?? this.api.data.selectedItem;
		if (selectedItem instanceof TimeStampApiShift) {
			let earliestPossibleEnd ! : number;
			if (this.api.data.start === null) {
				earliestPossibleEnd = this.api.data.regularPauseDuration;
			} else {
				earliestPossibleEnd = this.api.data.start + this.api.data.regularPauseDuration;
			}
			if (selectedItem.end < earliestPossibleEnd) return null;
			return selectedItem.end;
		} else if (selectedItem instanceof TimeStampApiShiftModel) {
			return null;
		}
		return null;
	}

	/**
	 * Start the tracking of the currently running shift
	 */
	public onStartTracking() : void {
		// Make sure the input-date modal does not have the values for a previous formControl.
		// Note that the id of e.g. attributeInfoStart can change.
		this.initFormGroup();

		this.storeSelectedItemIntoApiData();

		const inputDate = this.inputDateStartAiSwitchRef.inputDateRef!;
		const editableRef = inputDate.pEditableModalButtonRef!.elementRef;
		editableRef.nativeElement.click();
	}

	/**
	 * End the tracking of the currently running shift
	 */
	public onStopTracking() : void {
		// Make sure the input-date modal does not have the values for a previous formControl.
		// Note that the id of e.g. attributeInfoEnd can change.
		this.initFormGroup();

		const inputDate = this.inputDateEndAiSwitchRef.inputDateRef!;
		const editableRef = inputDate.pEditableModalButtonRef!.elementRef;
		editableRef.nativeElement.click();
	}
}
