import { Directive, HostListener, Input, TemplateRef } from '@angular/core';
import { PShiftCommentModalContentComponent } from '@plano/client/shared/p-shift-module/shift-comment-modal-content/shift-comment-modal-content.component';
import { RightsService, SchedulingApiService, SchedulingApiShift, SchedulingApiTodaysShiftDescription } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';
import { ModalContentComponentCloseReason } from '@plano/shared/core/p-modal/modal-content-component.interface';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';

@Directive({
	selector: '[pShiftCommentModal][shift]',

	// exportAs: 'pModal',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ShiftCommentModalDirective {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private modalContent ?: TemplateRef<PModalTemplateDirective>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private modalContentOptions : ModalContentOptions = {
		modalTitle: 'Sicher?',
		description: 'Echt jetzt?',
		closeBtnLabel: 'Okay …',
		dismissBtnLabel: 'Never ever!',
		hideDismissBtn: false,
	};
	@Input() private disabled : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift : SchedulingApiShift | SchedulingApiTodaysShiftDescription | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public userCanWrite : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public beforeSaveChangesHook : ((success : () => void) => void) | null = null;

	@HostListener('click') private onClick() : void {
		if (this.disabled) return;

		// this.getCommentModalContent();

		this.openModalAndInitComponent();
	}

	constructor(
		private modalService : ModalService,
		private api : SchedulingApiService,
		public rightsService : RightsService,
		private console : LogService,
	) {
	}

	private openModalAndInitComponent() : void {

		if (this.api.isLoaded() && this.userCanWrite) {
			this.api.createDataCopy();
		}

		const modalRef = this.modalService.openModal<ModalContentComponentCloseReason>(PShiftCommentModalContentComponent);
		void modalRef.result.then(value => {
			if (value.modalResult === 'success') {
				this.console.debug('Success', value.value, ModalContentComponentCloseReason[value.value]);
				switch (value.value) {
					case ModalContentComponentCloseReason.ADD:

						break;
					case ModalContentComponentCloseReason.REMOVE:
						if (this.shift) this.shift.description = '';

						// if (this.todaysShiftDescription) this.todaysShiftDescription.description = '';

						break;

					default:
						break;
				}

				if (this.beforeSaveChangesHook) {
					this.beforeSaveChangesHook(() => {
						this.api.mergeDataCopy();
						this.api.save();
					});
				} else {
					this.api.mergeDataCopy();
					this.api.save();
				}
			} else {
				this.console.debug('Dismiss');
				if (this.api.isLoaded() && this.userCanWrite) {
					this.api.dismissDataCopy();
				}
			}
		});
		const contentComponentInstance = modalRef.componentInstance as PShiftCommentModalContentComponent;
		assumeNonNull(this.userCanWrite);
		contentComponentInstance.initModalContentComponent(modalRef, this.shift, this.userCanWrite);
	}
}
