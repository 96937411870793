import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SpinnerComponent {
	@HostBinding('class.align-items-center')
	@HostBinding('class.justify-content-center')
	@HostBinding('class.h-100') protected _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	constructor(
		public elementRef : ElementRef<HTMLElement>,
	) {}

	public enums = enumsObject;
}
