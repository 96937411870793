import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MeService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-assign-members-headline',
	templateUrl: './p-assign-members-headline.component.html',
	styleUrls: ['./p-assign-members-headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAssignMembersHeadlineComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public editMode : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showHearts : boolean = false;

	constructor( public me : MeService ) {
	}

	public NgxPopperjsPlacements = NgxPopperjsPlacements;
	public Config = Config;
}
