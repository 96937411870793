import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormArray } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { EditableHookType } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { ErrorArray, ErrorItem } from '@plano/client/shared/p-shift-module/shift-member-exchange.service';
import { PShiftPickerService } from '@plano/client/shared/p-shift-picker/p-shift-picker.service';
import { PShiftPickerCalendarComponent } from '@plano/client/shared/p-shift-picker/shift-picker-calendar/shift-picker-calendar.component';
import { PossibleShiftPickerValueType } from '@plano/client/shared/p-shift-picker/shift-picker-picked-offers/shift-picker-picked-offers.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { MeService, SchedulingApiBooking, SchedulingApiMembers, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOffers, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeShiftRefs, SchedulingApiShiftExchangeSwappedShiftRef, SchedulingApiShiftExchangeSwappedShiftRefs, SchedulingApiShifts, ShiftId } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PossibleShiftPickerValueItemType = (
	SchedulingApiShiftExchangeShiftRef |
	SchedulingApiShiftExchangeCommunicationSwapOffer |
	SchedulingApiShiftExchangeSwappedShiftRef |
	SchedulingApiShiftExchangeShiftRefs
);

type ValueType = PossibleShiftPickerValueType;

@Component({
	selector: 'p-shift-picker[availableShifts][offersRef]',
	templateUrl: './p-shift-picker.component.html',
	styleUrls: ['./p-shift-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PShiftPickerComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftPickerComponent extends PAbstractControlComponentBaseDirective
	implements ControlValueAccessor, OnInit, AfterContentChecked, AfterContentInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideAddToOffersBtn : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public loadDetailedItem : SchedulingApiBooking | SchedulingApiShiftExchange | SchedulingApiShift | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('availableShifts') private _availableShifts ! : SchedulingApiShifts;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public offerTemplate : TemplateRef<unknown> | null = null;

	/**
	 * This can be a list of shiftRef or a list OF LISTS of shiftRef.
	 * Its the same as shiftRefs, but shiftRefs is generated by the formArray, and offersRef is bound like
	 * [offersRef]="shiftExchange.swapOffers"
	 */
	@Input() public offersRef ! : PossibleShiftPickerValueType;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onAddShifts : EventEmitter<SchedulingApiShifts> =
		new EventEmitter<SchedulingApiShifts>();

	/**
	 * A hook which can get used to open a modal to confirm the change offer action.
	 */
	@Input() public changeOfferHook : null | (() => EditableHookType | null) = null;

	@ViewChild('shiftPickerCalendarRef') private shiftPickerCalendarRef ! : PShiftPickerCalendarComponent;

	/* eslint-disable-next-line @typescript-eslint/ban-types -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Input('formArray') public override control : UntypedFormArray | null = null;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		// TODO: Obsolete?
		private api : SchedulingApiService,

		// TODO: Obsolete?
		private highlightService : HighlightService,

		// TODO: Obsolete?
		public pShiftPickerService : PShiftPickerService,
		public pShiftExchangeService : PShiftExchangeService,
		public meService : MeService,
		private localize : LocalizePipe,
		private pMoment : PMomentService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
		this.now = +this.pMoment.m();
	}

	public readonly CONFIG = Config;
	public enums = enumsObject;
	public SectionWhitespace = SectionWhitespace;
	public showList : boolean = true;
	public now ! : number;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get availableShifts() : SchedulingApiShifts {
		const date = this.pShiftPickerService.date;
		const start = (+this.pMoment.m(date).startOf(this.pShiftPickerService.mode));
		const end = (+this.pMoment.m(date).startOf(this.pShiftPickerService.mode).add(1, this.pShiftPickerService.mode));

		return this._availableShifts.filterBy(item => {
			if (start > item.end) return false;
			if (end < item.start) return false;
			return true;
		});
	}

	public showAsList : boolean = false;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public async loadNewData() : Promise<void> {
		await this.shiftPickerCalendarRef.loadNewData();
	}

	/**
	 * A list of all assignable members for this ShiftRef
	 * Assignable are members that are assignable to each and every of the provided shiftRefs
	 */
	private get assignableMembersForShiftRefs() : SchedulingApiMembers | undefined {
		if (!(this.loadDetailedItem instanceof SchedulingApiShiftExchange)) return undefined;
		const members = new SchedulingApiMembers(null, null);
		for (const assignableMember of this.loadDetailedItem.shiftRefs.assignableMembers.iterable()) {
			// Is this the indisposed member?
			if (this.loadDetailedItem.indisposedMemberId.equals(assignableMember.memberId)) continue;
			const member = this.api.data.members.get(assignableMember.memberId);
			if (!member) throw new Error('Could not find assignable member');
			members.push(member);
		}

		return members;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get noMemberAvailableForTheseShiftRefs() : boolean | undefined {
		if (!(this.loadDetailedItem instanceof SchedulingApiShiftExchange)) return undefined;
		if (!this.loadDetailedItem.shiftRefs.length) return undefined;
		if (!(this.loadDetailedItem instanceof SchedulingApiShiftExchange)) return undefined;
		if (!this.assignableMembersForShiftRefs) throw new Error('can not get length of null');
		return !this.assignableMembersForShiftRefs.length;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showCommunicationResetAlert() : boolean | undefined {
		if (!(this.loadDetailedItem instanceof SchedulingApiShiftExchange)) return undefined;

		if (!this.pShiftExchangeService.iAmTheResponsiblePersonForThisIllness(this.loadDetailedItem)) return undefined;

		// TODO: PLANO-156519
		if (!this.loadDetailedItem.attributeInfoCommunications.isAvailable) return undefined;

		const communications = this.loadDetailedItem.communications;
		const reactionsLength = communications.reactionsForList.filterBy((item) => {
			if (
				communications.managerResponseCommunication?.id.equals(item.id)
			) return false;
			return true;
		}).length;
		return !!reactionsLength;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showBoundShiftOfferSetBtn() : boolean {
		return this.isPickerForCommunication;
	}

	private get isPickerForCommunication() : boolean {
		return (this.offersRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffers);
	}

	// private isSamePacketAsShiftRefs(itemId : ShiftId) : boolean {
	// 	for (const shiftRef of this.shiftRefs.iterable()) {
	// 		if (shiftRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffer) {
	// 			for (const offerRef of shiftRef.shiftRefs.iterable()) {
	// 				if (offerRef.id.isSamePacket(itemId)) continue;
	// 				return false;
	// 			}
	// 		} else if (shiftRef.id.isSamePacket(itemId)) {
	// 			continue;
	// 		}
	// 		return false;
	// 	}
	// 	return true;
	// }

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftRefs() : PossibleShiftPickerValueType {
		if (!this.control!.controls.length) return new SchedulingApiShiftExchangeShiftRefs(null, null);

		let array : PossibleShiftPickerValueType;
		if (this.offersRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffers) {
			array = new SchedulingApiShiftExchangeCommunicationSwapOffers(null, null);
		} else if (this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs) {
			array = new SchedulingApiShiftExchangeShiftRefs(null, null);
		} else if (this.offersRef instanceof SchedulingApiShiftExchangeSwappedShiftRefs) {
			array = new SchedulingApiShiftExchangeSwappedShiftRefs(null, null);
		} else {
			throw new TypeError('Unexpected shiftRefs type.');
		}

		for (const control of this.control!.controls) {
			array.push(control.value);
		}
		return array;
	}

	/**
	 * Check if the bound ngModel/formControl.value already contains the provided id
	 */
	public valueContainsShiftId(shiftId : ShiftId) : boolean {
		for (const offer of this.offersRef.iterable()) {
			if (offer instanceof SchedulingApiShiftExchangeCommunicationSwapOffer) {
				for (const shiftRef of offer.shiftRefs.iterable()) {
					if (shiftRef.id.equals(shiftId)) return true;
				}
			}
			// eslint-disable-next-line sonarjs/no-collapsible-if
			if (offer instanceof SchedulingApiShiftExchangeShiftRef) {
				if (offer.id.equals(shiftId)) return true;
			}
		}
		return false;
	}

	/**
	 * Remove the whole offer. This also gets called if there is only one shift in the offer.
	 * @param offer - The offer to remove
	 */
	public async onRemoveOffer(offer : PossibleShiftPickerValueItemType | SchedulingApiShiftExchangeShiftRefs) : Promise<void> {
		const hook = this.changeOfferHook ? this.changeOfferHook() : null;
		if (hook) {
			const hookResult = await hook;
			if (hookResult.modalResult === 'dismiss') return;
		}
		this.pShiftPickerService.onRemoveOffer(this.control!, this.offersRef, offer);
	}

	/**
	 * Remove one item from the offer.
	 * @param input - An object that contains the necessary information to process this action
	 * @param input.shiftRef - The shift to remove
	 * @param input.offer - The offer to remove the shift from
	 */
	public async onRemoveShiftRefFromOffer(input : {
		shiftRef : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef | SchedulingApiShiftExchangeShiftRef,
		offer : (
			SchedulingApiShiftExchangeCommunicationSwapOffer |
			SchedulingApiShiftExchangeSwappedShiftRef |
			SchedulingApiShiftExchangeShiftRefs
		),
	}) : Promise<void> {
		const hook = this.changeOfferHook ? this.changeOfferHook() : null;
		if (hook) {
			const hookResult = await hook;
			if (hookResult.modalResult === 'dismiss') return;
		}
		if (input.shiftRef instanceof SchedulingApiShiftExchangeShiftRef) {
			void this.onRemoveOffer(input.shiftRef);
		} else if (input.offer instanceof SchedulingApiShiftExchangeCommunicationSwapOffer) {
			input.offer.shiftRefs.removeItem(input.shiftRef);
			this.control!.updateValueAndValidity();

		}
	}

	/**
	 * Let the user confirm the change and run the emit.
	 */
	public async onAddSelectedShifts() : Promise<void> {
		const hook = this.changeOfferHook ? this.changeOfferHook() : null;
		if (hook) {
			const hookResult = await hook;
			if (hookResult.modalResult === 'dismiss') return;
		}
		const selectedShifts = this.api.data.shifts.filterBy(item => item.selected).sortedBy(item => item.start);
		if (this.someHint) this.someHint = null;
		this.onAddShifts.emit(selectedShifts);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public async addSelectedShiftsToRefs(refs : (
		SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs | SchedulingApiShiftExchangeShiftRefs
	)) : Promise<void> {
		// We show shiftExchangeShiftRefs as a package, but in code we treat them like a non-bundled offer
		if (this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs) {
			await this.onAddSelectedShifts();
			return;
		}

		for (const selectedShift of this.api.data.shifts.filterBy(item => item.selected).iterable()) {
			selectedShift.selected = false;
			if (refs.contains(selectedShift.id)) continue;
			refs.createNewItem(null, selectedShift.id);
			this.control!.updateValueAndValidity();
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public addSelectedShiftsAsPacket() : void {
		const newOfferPaket = (this.offersRef as SchedulingApiShiftExchangeCommunicationSwapOffers).createNewItem();

		void this.addSelectedShiftsToRefs(newOfferPaket.shiftRefs);

		this.control!.push(new PFormControl({ formState: {value : newOfferPaket, disabled: false} }));
	}

	public someHint : string | null = null;

	public override ngAfterContentChecked() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initAlertDefaults();
		this.refreshAlertsArray();
		return super.ngAfterContentChecked();
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.someHint = this.initialSomeHintText;
		return super.ngAfterContentInit();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get pickedOffersHeadline() : string {
		if (this.offersRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffers) {
			return this.localize.transform('Schichten für den Tausch');
		}
		if (
			this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs &&
			this.loadDetailedItem instanceof SchedulingApiShiftExchange
		) {
			if (this.loadDetailedItem.isIllness) return this.localize.transform('Schichten für Krankmeldung');
			return this.localize.transform('Schichten für Ersatzsuche');
		}
		return this.localize.transform('Schicht-Auswahl');
	}

	private get initialSomeHintText() : string | null {
		if (this.shiftRefs.length) return null;
		if (
			this.offersRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffers &&
			this.loadDetailedItem instanceof SchedulingApiShiftExchange &&
			!this.pShiftExchangeService.iAmTheIndisposedMember(this.loadDetailedItem)
		) {
			return this.localize.transform({
				sourceString: 'Wähle im Kalender die Schichten, die du ${firstName} zum Tausch anbieten möchtest, und füge sie anschließend hier hinzu.',
				params: { firstName: this.loadDetailedItem.indisposedMember!.firstName },
			});
		} else if (
			this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs &&
			this.loadDetailedItem instanceof SchedulingApiShiftExchange
		) {
			if (this.loadDetailedItem.isIllness) {
				const I_AM_THE_INDISPOSED_MEMBER = this.pShiftExchangeService.iAmTheIndisposedMember(this.loadDetailedItem);
				const recipient = I_AM_THE_INDISPOSED_MEMBER ? this.localize.transform('dich') : this.loadDetailedItem.indisposedMember!.firstName;
				return this.localize.transform({
					sourceString: 'Wähle im Kalender diejenigen Schichten, für die du ${recipient} krank melden möchtest, und füge sie anschließend hier hinzu.',
					params: { recipient: recipient },
				});
			}
			return this.localize.transform('Wähle im Kalender die Schichten, für die du Ersatz suchen möchtest, und füge sie anschließend hier hinzu.');
		}
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get addToOffersBtnLabel() : string {
		const selectedShifts = this.api.data.shifts.filterBy(item => item.selected);

		let result : PDictionarySourceString;
		const counter = selectedShifts.length;

		if (this.offersRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffers) {
			if (selectedShifts.length <= 1) return this.localize.transform('Schicht hinzufügen');
			result = '${counter} Schichten einzeln hinzufügen';
		} else if (
			this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs &&
			this.loadDetailedItem instanceof SchedulingApiShiftExchange
		) {
			if (this.loadDetailedItem.isIllness) {
				if (counter === 1) return 'Schicht der Krankmeldung hinzufügen';
				result = '${counter} Schichten der Krankmeldung hinzufügen';
			} else {
				if (counter === 1) return 'Schicht der Suche hinzufügen';
				result = '${counter} Schichten der Suche hinzufügen';
			}
		} else {
			if (counter === 1) return 'Schicht der Auswahl hinzufügen';
			result = '${counter} Schichten der Auswahl hinzufügen';
		}
		return this.localize.transform({sourceString: result, params: { counter: counter.toString() }}, false);
	}

	public override ngOnInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.highlightService.clear();
		return super.ngOnInit();
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formArray. #two-way-binding
		this.disabled ? this.control?.disable() : this.control?.enable();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showHasBundleWarning() : boolean | undefined {

		// For info about this, see initAlertForHasBundle
		if (!this.alertForHasBundle) return false;

		if (!(this.loadDetailedItem instanceof SchedulingApiShiftExchange)) return undefined;
		if (this.loadDetailedItem.isIllness) return false;

		if (this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs) return this.offersRef.length > 1;
		if (!(this.offersRef instanceof SchedulingApiShiftExchangeCommunicationSwapOffers)) return undefined;
		if (this.offersRef.findBy(item => item.shiftRefs.length > 1)) return true;
		return false;
	}

	public alerts : ErrorArray = [];
	public alertForHasBundle : ErrorItem | null = null;
	public alertForEqualoffers : ErrorItem | null = null;
	public alertForCommunicationReset : ErrorItem | null = null;
	public alertForNoMember : ErrorItem | null = null;

	private initAlertForHasBundle() : void {

		// If there are already multiple shiftRefs when user opened the modal, we assume, that we don’t need to remind him
		// again, so we leave .alertForHasBundle undefined
		if (this.shiftRefs.length > 1) return;

		let textForAlertForHasBundle : string;
		if (this.offersRef instanceof SchedulingApiShiftExchangeShiftRefs) {
			textForAlertForHasBundle = this.localize.transform('Du hast mehrere Schichten hinzugefügt. Sie alle müssen von einer Person komplett übernommen werden. Möchtest du das nicht, solltest du die Schichten einzeln in die Tauschbörse stellen.');
		} else {
			textForAlertForHasBundle = this.localize.transform('Bedenke, dass dein Verhandlungspartner ein gebündeltes Schicht-Angebot komplett übernehmen muss und sich nicht einzelne Schichten rauspicken kann.');
		}
		this.alertForHasBundle = {
			type: enumsObject.PThemeEnum.WARNING,
			text: textForAlertForHasBundle,
		};
	}

	private initAlertDefaults() : void {
		this.initAlertForHasBundle();

		this.alertForEqualoffers = {
			type: enumsObject.PThemeEnum.DANGER,
			text: this.localize.transform('Zwei Angebote gleichen sich.'),
		};

		this.alertForCommunicationReset = {
			type: enumsObject.PThemeEnum.WARNING,
			text: this.localize.transform('Es gibt Mitarbeitende, die schon auf die bisherige Schicht-Auswahl geantwortet haben. Änderst du die Auswahl, müssen sie erneut antworten.'),
		};

		this.alertForNoMember = {
			type: enumsObject.PThemeEnum.DANGER,
			text: this.localize.transform('Niemand aus dem Team kann deine aktuelle Schicht-Auswahl übernehmen. Du solltest deine Auswahl ändern.'),
		};
	}

	private pushItToAlertsIfPossible(error : ErrorItem) : void {
		const isAlreadyAdded = this.alerts.find(item => item.text === error.text && item.type === error.type);
		if (!isAlreadyAdded) this.alerts.push(error);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public refreshAlertsArray() : ErrorArray {
		this.alerts = [];

		if (this.control!.hasError('equaloffers'.toLowerCase())) this.pushItToAlertsIfPossible(this.alertForEqualoffers!);
		if (this.showCommunicationResetAlert) this.pushItToAlertsIfPossible(this.alertForCommunicationReset!);
		if (this.noMemberAvailableForTheseShiftRefs) this.pushItToAlertsIfPossible(this.alertForNoMember!);

		return this.alerts;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public requesterIsAssigned(shift : SchedulingApiShift) : boolean {
		if (!this.meService.isLoaded()) return false;
		return shift.assignedMemberIds.contains(this.meService.data.id);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onShiftClick(shift : SchedulingApiShift) : void {
		if (!this.requesterIsAssigned(shift)) return;
		if (this.valueContainsShiftId(shift.id)) return;
		if (this.pShiftExchangeService.shiftExchangeExistsForShiftAndRequester(shift.id)) return;
		shift.selected = !shift.selected;
	}
}
