import { Injectable } from '@angular/core';
import {
	SchedulingApiAssignmentProcessShiftRef,
	SchedulingApiAssignmentProcessState,
} from '@plano/shared/api';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { NonEmptyArray } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import * as _ from 'underscore';
import { PAssignmentProcessIcon } from './assignment-process-icon';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AssignmentProcessesService {
	private readonly STATES_ARRAY : {
		state : SchedulingApiAssignmentProcessState;
		description : PDictionarySourceString;
		icon : PAssignmentProcessIcon;
	}[] = [
			{
				state: SchedulingApiAssignmentProcessState.APPROVE,
				description: 'Muss veröffentlicht werden',
				icon: 'bullhorn',
			},
			{
				state: SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES,
				description: 'Schichtwünsche anfordern',
				icon: ['regular', enumsObject.PlanoFaIconPool.ITEMS_ASSIGNMENT_PROCESS[1]],
			},
			{
				state: SchedulingApiAssignmentProcessState.EARLY_BIRD_FINISHED,
				description: 'Vorgang abschließen',
				icon: 'check',
			},
			{
				state: SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING,
				description: 'Für Angestellte freigeben',
				icon: enumsObject.PlanoFaIconPool.EARLY_BIRD,
			},
			{
				state: SchedulingApiAssignmentProcessState.MANUAL_SCHEDULING,
				description: 'Schichten manuell besetzen',
				icon: enumsObject.PlanoFaIconPool.MANUAL_SCHEDULING,
			},
			{
				state: SchedulingApiAssignmentProcessState.NEEDING_APPROVAL,
				description: 'Dr.&nbsp;Plano hat verteilt',

				// icon : 'dr-plano'
				icon: enumsObject.PlanoFaIconPool.AUTO_SCHEDULING,
			},
			{
				state: SchedulingApiAssignmentProcessState.NOT_STARTED,
				description: 'Schichten für Verteilung auswählen',
				icon: ['regular', 'clone'],
			},
		];

	/**
	 * Find a icon for the provided state if possible
	 */
	public getIcon(
		state : SchedulingApiAssignmentProcessState,
	) : PFaIcon | 'dr-plano' | null {
		const object = _.findWhere(this.STATES_ARRAY, {
			state: state,
		});
		if (!object) return null;
		return object.icon;
	}

	/**
	 * Find a description for the provided state if possible
	 */
	public getDescription(
		state : SchedulingApiAssignmentProcessState,
		userCanSetAssignmentProcess ?: boolean,
	) : PDictionarySourceString | null {
		if (
			state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES &&
			!userCanSetAssignmentProcess
		) {
			return 'Schichtwünsche abgeben';
		}

		const object = _.findWhere(this.STATES_ARRAY, { state: state });
		if (!object) return null;
		return object.description;
	}

	/** Get earliest start of ShiftRefs */
	public getEarliestStartOfShiftRefs(shiftRefArray : NonEmptyArray<SchedulingApiAssignmentProcessShiftRef>) : number {
		const allStarts = shiftRefArray.map(item => item.id.start);
		return Math.min(...allStarts);
	}
}
