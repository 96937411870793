import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';

const ngWizardConfig : NgWizardConfig = {
	theme: THEME.dots,
};

@NgModule({
	declarations: [
	],
	imports: [
		CommonModule,
		NgWizardModule.forRoot(ngWizardConfig),
	],
	exports: [
		NgWizardModule,
	],
})
export class PWizardModule {}
