import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/**
 * A directive to contain all logic that a component needs, which holds a form control as well as an editable directive.
 */
@Directive({
	selector: '[formControl][pEditable], [formGroup][pEditable], [formArray][pEditable]',
})
export class ControlWithEditableDirective extends PAbstractControlComponentBaseDirective implements EditableControlInterface {

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public api : EditableControlInterface['api'] = null;
	@Input() public valid : EditableControlInterface['valid'] = null;
	@Input() public saveChangesHook ?: EditableControlInterface['saveChangesHook'];
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	/**
	 * Icon to visualize the success state.
	 * Can be some icon to visualize that a process is running.
	 */
	public get successBtnIcon() : PlanoFaIconPoolValues {

		// Warning has higher priority than success, since it usually indicates a ongoing process.
		if (this.hasWarning) return enumsObject.PlanoFaIconPool.SYNCING;

		return enumsObject.PlanoFaIconPool.SUCCESS;
	}

	/**
	 * Should the success button icon be spinning?
	 */
	public get successBtnIconSpin() : boolean {
		if (this.successBtnIcon === enumsObject.PlanoFaIconPool.SYNCING) return true;
		return false;
	}

	/**
	 * @see PAbstractControlComponentBaseDirective#isValid
	 *
	 * [valid]="…" overwrites the valid state if the formControl.
	 */
	public override get isValid() : boolean {
		if (this.hasWarning) return false;
		if (this.valid !== null) return this.valid;
		return super.isValid;
	}
}
