/* eslint-disable import/no-relative-parent-imports -- Remove this before you work here. */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisabledDirective } from '@plano/shared/core/directive/disabled.directive';
import { TitleToAriaLabelDirective } from '@plano/shared/core/directive/title-to-aria-label.directive';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { PFaIconModule } from '../component/fa-icon/fa-icon.module';
import { PConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PModalDefaultTemplateComponent } from './modal-default-template/modal-default-template.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalDirective } from './modal.directive';
import { PModalContentBodyComponent, PModalContentComponent, PModalContentFooterComponent } from './p-modal-content/p-modal-content.component';

@NgModule({
	imports: [
		CommonModule,
		CorePipesModule,
		DisabledDirective,
		NgbModule,
		PFaIconModule,
		PModalTemplateDirective,
		TitleToAriaLabelDirective,
	],
	declarations: [
		ModalDirective,
		ModalHeaderComponent,
		PConfirmModalComponent,
		PModalContentBodyComponent,
		PModalContentComponent,
		PModalContentFooterComponent,
		PModalDefaultTemplateComponent,
	],
	providers: [],
	exports: [
		ModalDirective,
		ModalHeaderComponent,
		PModalContentBodyComponent,
		PModalContentComponent,
		PModalContentFooterComponent,
		PModalTemplateDirective,
		TitleToAriaLabelDirective,
	],
})
export class PModalModule {}
