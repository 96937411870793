<div class="overlay"

	[class.has-fixed-footer]="!!showFooterAsFixed"
	[class.no-fixed-footer]="!showFooterAsFixed"

	[class.bg-darker]="backgroundColor==='darker'"
	[class.bg-dark]="backgroundColor==='dark'"
	[class.bg-light-cold]="backgroundColor==='light'"
	[class.bg-primary]="backgroundColor==='primary'"
>
	<!-- If any of the classes in this file change, please check their usage
	throughout the app -->
	<div class="shadow-container"
		*ngIf="!showFooterAsFixed && !noShadows"
	>
		<div [class.opacity-0]="!showShadowTop" class="radial-shadow-top"></div>
		<div [class.opacity-0]="!showShadowBottom" class="radial-shadow-bottom"></div>
	</div>
	<div class="content p-scroll-parent"
		#contentElement
		[class.disable-scrollbar-gutter]="disableScrollbarGutter"
		[class.always-show-scrollbar]="alwaysShowScrollbar"
		[style]="contentContainerStyles"
	>
		<div class="content-inner" #innerContentElement>
			<ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
			<ng-template [ngIf]="!showFooterAsFixed && !!fixedFooterTemplate">
				<ng-template [ngTemplateOutlet]="fixedFooterTemplate"></ng-template>
			</ng-template>
		</div>
	</div>
	<ng-template [ngIf]="showFooterAsFixed">
		<div class="flex-grow-1 flex-shrink-1">
			<ng-template [ngTemplateOutlet]="fixedFooterTemplate"></ng-template>
		</div>
	</ng-template>
</div>

<ng-template #contentTemplate>
	<ng-content></ng-content>
</ng-template>
