<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">01</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">02</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">03</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">04</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">05</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">06</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">07</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">08</span></div>
<div class="timeline-separator text-monospace" #startOfWorkday><span *ngIf="showNumbers">09</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">10</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">11</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">12</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">13</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">14</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">15</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">16</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">17</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">18</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">19</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">20</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">21</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">22</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">23</span></div>
<div class="timeline-separator text-monospace"><span *ngIf="showNumbers">24</span></div>
