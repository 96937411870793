/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "subscribe_newsletter".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	EMAIL = 1;
	FIRST_NAME = 2;
	LAST_NAME = 3;
	COMPANY = 4;
	LOCALE_ID = 5;
}


@Injectable({
  providedIn: 'root',
})
export class SubscribeNewsletterApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'subscribe_newsletter');
	}

	protected version() : string {
		return '0154a4a7a2852dae6f8c1bd6c7c6d8a4,7aebd99d982defa4224b1a3c93bd336a';
	}

	private dataWrapper = new SubscribeNewsletterApiRoot(this, );

	get data() : SubscribeNewsletterApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : SubscribeNewsletterApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new SubscribeNewsletterApiRoot(this, );
	}
}

		 
export class SubscribeNewsletterApiRoot extends ApiObjectWrapper<SubscribeNewsletterApiRoot>
{
	constructor(override readonly api : SubscribeNewsletterApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<SubscribeNewsletterApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, SubscribeNewsletterApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<SubscribeNewsletterApiRoot, SubscribeNewsletterApiRoot> = new ApiAttributeInfo<SubscribeNewsletterApiRoot, SubscribeNewsletterApiRoot>({
			apiObjWrapper: this as any as SubscribeNewsletterApiRoot,
			name: '',
			nodeName: 'ROOT',
			defaultValue: function(this : SubscribeNewsletterApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoEmail : ApiAttributeInfo<SubscribeNewsletterApiRoot, Email> = new ApiAttributeInfo<SubscribeNewsletterApiRoot, Email>({
			apiObjWrapper: this as any as SubscribeNewsletterApiRoot,
			name: 'email',
			nodeName: 'EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			validations: function(this : SubscribeNewsletterApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SubscribeNewsletterApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoFirstName : ApiAttributeInfo<SubscribeNewsletterApiRoot, string> = new ApiAttributeInfo<SubscribeNewsletterApiRoot, string>({
			apiObjWrapper: this as any as SubscribeNewsletterApiRoot,
			name: 'firstName',
			nodeName: 'FIRST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : SubscribeNewsletterApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SubscribeNewsletterApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoLastName : ApiAttributeInfo<SubscribeNewsletterApiRoot, string> = new ApiAttributeInfo<SubscribeNewsletterApiRoot, string>({
			apiObjWrapper: this as any as SubscribeNewsletterApiRoot,
			name: 'lastName',
			nodeName: 'LAST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : SubscribeNewsletterApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SubscribeNewsletterApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoCompany : ApiAttributeInfo<SubscribeNewsletterApiRoot, string> = new ApiAttributeInfo<SubscribeNewsletterApiRoot, string>({
			apiObjWrapper: this as any as SubscribeNewsletterApiRoot,
			name: 'company',
			nodeName: 'COMPANY',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : SubscribeNewsletterApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SubscribeNewsletterApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoLocaleId : ApiAttributeInfo<SubscribeNewsletterApiRoot, string> = new ApiAttributeInfo<SubscribeNewsletterApiRoot, string>({
			apiObjWrapper: this as any as SubscribeNewsletterApiRoot,
			name: 'localeId',
			nodeName: 'LOCALE_ID',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : SubscribeNewsletterApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : SubscribeNewsletterApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});


	set email(v : Email) {
        this.setterImpl(1, v, 'email', false, null, null);
	}


	set firstName(v : string) {
        this.setterImpl(2, v, 'firstName', false, null, null);
	}


	set lastName(v : string) {
        this.setterImpl(3, v, 'lastName', false, null, null);
	}


	set company(v : string) {
        this.setterImpl(4, v, 'company', false, null, null);
	}


	set localeId(v : string) {
        this.setterImpl(5, v, 'localeId', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : SubscribeNewsletterApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}



