import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, EventEmitter, forwardRef, HostBinding, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PAssignmentProcessIcon } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-process-icon';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentChildInterface, PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { PRadiosRadioComponent } from './p-radios-radio/p-radios-radio.component';

type ValueType = unknown;

/**
 * <p-radios> is like <radios> with all the options for pEditables
 * @example with PFormControl binding
 * 	<form [formGroup]="myFormGroup">
 * 		<p-radios
 * 			[formControl]="myFormGroup.get('favoriteFood')"
 * 		>
 * 			<p-radios-radio
 * 				value="unhealthy"
 * 				label="Pizza" i18n-label
 * 			></p-radios-radio>
 * 			<p-radios-radio
 * 				value="healthy"
 * 				label="Salat" i18n-label
 * 			></p-radios-radio>
 * 		</p-radios>
 * 	</form>
 * @example with model binding
 * 	<p-radios
 * 		[(ngModel)]="member.favoriteFood"
 * 	>
 * 		<p-radios-radio
 * 			value="unhealthy"
 * 			label="Pizza" i18n-label
 * 		></p-radios-radio>
 * 		<p-radios-radio
 * 			value="healthy"
 * 			label="Salat" i18n-label
 * 		></p-radios-radio>
 * 	</p-radios>
 * @example as editable
 * 	<form [formGroup]="myFormGroup">
 * 		<p-radios
 * 			[pEditable]="!member.isNewItem()"
 * 			[api]="api"
 *
 * 			[formControl]="myFormGroup.get('favoriteFood')"
 * 			placeholder="Plano" i18n-placeholder
 * 		>
 * 			<p-radios-radio
 * 				value="unhealthy"
 * 				label="Pizza" i18n-label
 * 			></p-radios-radio>
 * 			<p-radios-radio
 * 				value="healthy"
 * 				label="Salat" i18n-label
 * 			></p-radios-radio>
 * 		</p-radios>
 * 	</form>
 */
@Component({
	selector: 'p-radios',
	templateUrl: './p-radios.component.html',
	styleUrls: ['./p-radios.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PRadiosComponent),
			multi: true,
		},
	],
})
export class PRadiosComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, EditableControlInterface, PFormControlComponentInterface, AfterContentInit {
	@ContentChildren(PRadiosRadioComponent) public radios ?: QueryList<PRadiosRadioComponent>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public inline : boolean = true;

	/**
	 * Should the default icons be visible?
	 * Useful if you want to define your own icons in <ng-content>
	 */
	@Input() public hideRadioCircles : boolean = false;

	// NOTE: Its not possible to dismiss changes on a radio-input. But editable.directive can trigger it – e.g. onDestroy.
	@Output() public override onDismiss : EditableDirective['onDismiss'] = new EventEmitter();

	@HostBinding('class.disabled')
	@HostBinding('attr.aria-disabled') private get ariaDisabled() : boolean {
		return this.disabled;
	}
	@HostBinding('role') private role = 'radiogroup';

	/**
	 * Visual size of this component.
	 * Can be useful if you have few space in a button-bar or want to have large buttons on mobile.
	 */
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	/**
	 * Template to be shown inside the cannotSetHint modal, if any, otherwise the text from the
	 * cannotSetHint will be shown.
	 * This template will receive as context the source of the cannotSetHint.
	 */
	@Input() public cannotSetHintTemplate : TemplateRef<unknown> | null = null;

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to set this if you want to use [(ngModel)] AND [formControl] together.
	 */
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}
	@Input('disabled') public override set disabled(input : boolean) {
		this.setDisabledState(input);
		super.disabled = input;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	constructor(
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,
		private modalService : ModalService,
		protected override console : LogService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public readonly CONFIG = Config;

	/**
	 * HACK: Since we try to handle the lifecycle inside the component
	 * @see PAbstractControlComponentBaseDirective#group
	 * We dont have all the Angular functionality available anymore.
	 *
	 * This is a hack to re-invent what Angular already invented.
	 * This should be removed when we found a good way to handle the lifecycles of formControl’s
	 */
	// @HostBinding('class.ng-p-invalid') private get _classNgInvalid() : boolean {
	// 	return !this.isValid;
	// }
	// @HostBinding('class.ng-p-invalid') private get _classNgValid() : boolean {
	// 	return !!this.isValid;
	// }

	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get getActiveRadio() : PRadiosRadioComponent | null {
		return this.radios?.find((item) => this.isActive(item)) ?? null;
	}

	/**
	 * This method checks if the given item is in a active state.
	 */
	public isActive(item : PRadiosRadioComponent) : boolean {

		// If set, the item.checked value has a higher priority then the other expression
		if (item.active !== null) return item.active;

		if (item.value === undefined) return false;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (this.value && (this.value as any).equals) return (this.value as any).equals(item.value);

		return this.value === item.value;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onClick(input : PRadiosRadioComponent) : void {
		if (this.disabled) return;
		this.value = input.value;
		input.onClick.emit(input.value);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public popoverValueIsString(popover : string | TemplateRef<unknown>) : boolean {
		return typeof popover === 'string';
	}

	public _disabled : boolean = false;

	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	private _value : ValueType | null = null;
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public override _onChange : (value : ValueType | null) => void = () => {};

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onChange(value : ValueType) : void {
		this._onChange(value);
	}

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public get value() : ValueType | null { return this._value; }
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;

		if (this.group) {
			this.control!.setValue(value);
		} else {
			this.onChange(value);
		}
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute
		this._disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			// make sure the formControl value is up-to-date with the AI value
			if (!this.disabled && this.attributeInfo) this.refreshValue();
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public showFontawesomeIcon(icon : PAssignmentProcessIcon | null) : boolean {
		if (icon === null) return false;
		return icon !== 'dr-plano';
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.control!);
	}

	/**
	 * Open a Modal like info-circle does it when in IS_MOBILE mode.
	 */
	public openCannotSetHint(cannotSetHint : PDictionarySource, contentTemplateRef : TemplateRef<unknown> | null) : void {
		this.modalService.openCannotSetHintModal(cannotSetHint, this.cannotSetHintTemplate ? contentTemplateRef! : undefined);
	}

	/**
	 *	Return the text color class to be added to the radio template, if any
	 */
	public getTextColorClassForRadio(radioValue : PFormControlComponentChildInterface['value'], iconTheme : PThemeEnum | null) : string | null {
		const isPrimaryByDefault = this.hideRadioCircles && radioValue === this.value;
		if (!iconTheme && !isPrimaryByDefault) return null;
		if (!iconTheme && isPrimaryByDefault) return 'text-primary';
		return `text-${iconTheme}`;
	}
}
