import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PAiSwitchShowcaseCustomComponent } from '@plano/p-ai-switch-showcase/p-ai-switch-showcase-custom/p-ai-switch-showcase-custom.component';
import { PAiSwitchShowcaseDefaultComponent } from '@plano/p-ai-switch-showcase/p-ai-switch-showcase-default/p-ai-switch-showcase-default.component';
import { PAiSwitchShowcaseEditableComponent } from '@plano/p-ai-switch-showcase/p-ai-switch-showcase-editable/p-ai-switch-showcase-editable.component';
import { PFormsModule } from '@plano/shared/p-forms/p-forms.module';

@NgModule({
	declarations: [
		PAiSwitchShowcaseCustomComponent,
		PAiSwitchShowcaseDefaultComponent,
		PAiSwitchShowcaseEditableComponent,
	],
	imports: [
		CommonModule,
		PFormsModule,
	],
	providers: [
	],
	exports: [
		PAiSwitchShowcaseCustomComponent,
		PAiSwitchShowcaseDefaultComponent,
		PAiSwitchShowcaseEditableComponent,
	],
})
export class PAiSwitchShowcaseModule {}
