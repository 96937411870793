import { Injectable } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';

/**
 * Service that provides a method "refreshFocus" that can be called
 * from the outside to check if some new element should be focused.
 *
 * This method can be used to when the modal is already open but we want to change
 * the focus element. This happens for example in the change-selectors-modal, because
 * there we have modals with multiple steps which require focus to be calculated
 * whenever the step changes.
 */
@Injectable({ providedIn: 'root' })
export class PAutoFocusService {

	constructor(
        private modalService : ModalService,
	) {
		// subscribe to when a modal is opened through the modal service
		this.modalService.modalStateOpenSubject.subscribe(() => {
			this.refreshFocus();
		});
	}

	/**
     * Refresh the focussed element by performing the necessary logics to decide which element, if any, should be focused
     */
	public refreshFocus() : void {
		// select the last opened modal
		const modalElement : HTMLElement = Array.from(document.querySelectorAll<HTMLElement>('ngb-modal-window')!).at(-1)!;

		// no focus on mobile, focus on modal instead to avoid that the close button gets focused
		if (Config.IS_MOBILE) {
			modalElement.focus();
			return;
		}

		// check if there is an element marked with priority
		const priorityFocusableElement = modalElement.querySelector<HTMLElement>('.p-auto-focus.priority-auto-focus:not(.disabled)');

		// if no priority is set take the first element
		const focusableElement = (priorityFocusableElement ?? modalElement.querySelector<HTMLElement>('.p-auto-focus:not(.disabled)'));

		if (!focusableElement) {
			// focus the modal window if no auto focus could be set to avoid that the close button gets focused
			modalElement.focus();
			return;
		}

		// check if the first focusable element is visible, if not, no focus should happen
		const focusElementObserver = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				focusableElement.focus();
				observer.disconnect();
			} else {
				// focus the modal window if no auto focus could be set to avoid that the close button gets focused
				modalElement.focus();
				observer.disconnect();
			}
		}, {
			// focus only if the element is fully visible
			threshold: 1.0,
		});
		focusElementObserver.observe(focusableElement);
	}

}
