import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { EmailValidApiService } from '@plano/shared/api';
import { Integer, PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { getBase64DimensionsAsync } from '@plano/shared/core/utils/base64-utils';
import { PDFDocument } from 'pdf-lib';
import { Observable, Subject, of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FILE_URL_REGEXP } from './validators.service';
import { PPossibleErrorNames, PValidationErrorValue, PValidationErrors, PValidatorObject } from './validators.types';

/**
 * Custom validations for our forms
 */

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AsyncValidatorsService {
	constructor(

		/**
		 * Use the injector to get the services instead of providing them in the constructor,
		 * to avoid circular dependencies.
		 */
		private injector : Injector,
	) {
	}

	/**
	 * Checks if an email address is valid. This validator sets the validation-names "emailInvalid" and "emailUsed".
	 * @param checkIsUsed If "true" is passed this validator also ensures that the email is
	 * not already used by another user.
	 * @param userId Pass user id for whom we are doing this test. don’t pass anything here
	 * when are you creating a new user. This parameter is ignored when "checkIsUsed" is "false".
	 */
	public emailValidAsync(
		checkIsUsed : boolean = false,
		userId ?: Id,
	) : PValidatorObject<'async'> {
		return new PValidatorObject<'async'>({
			name: PPossibleErrorNames.EMAIL_HAS_ERROR,
			fn: (control : AbstractControl) : Observable<PValidationErrors<PValidationErrorValue & {
				name : PPossibleErrorNames.EMAIL_INVALID | PPossibleErrorNames.EMAIL_USED
			}> | null> => {
				// don’t do anything if control ist not dirty. This is true e.g. if a form has just been initialized.
				if (!control.dirty) return of(null);

				// an empty string is valid according to this validator
				// (if it should not be valid a "required" validator has to be added to the input)
				if (control.value === undefined) return of(null);
				if (control.value === '') return of(null);
				if (control.value === null) return of(null);

				// delay request
				return timer(1000).pipe(switchMap(() => {
					const sendResult = new Subject<PValidationErrors<PValidationErrorValue & {
						name : PPossibleErrorNames.EMAIL_INVALID | PPossibleErrorNames.EMAIL_USED
					}> | null>();

					// queryParams
					let queryParams = new HttpParams()
						.set('emails', encodeURIComponent(control.value));

					if (checkIsUsed) queryParams = queryParams.set('checkIsUsed', '');

					// Don’t send an id if the entity is new. See email-valid api docs for more info.
					if (userId && !userId.isOfNewItem()) queryParams = queryParams.set('user', userId.rawData);
					const emailValidApiService = this.injector.get(EmailValidApiService);

					// call api
					emailValidApiService.load({
						success: () => {
							if (emailValidApiService.data.invalid) {
								sendResult.next({ [PPossibleErrorNames.EMAIL_INVALID]: {
									name: PPossibleErrorNames.EMAIL_INVALID,
									primitiveType: undefined,
									actual: control.value,
								} });
							} else if (emailValidApiService.data.used) {
								sendResult.next({ [PPossibleErrorNames.EMAIL_USED]: {
									name: PPossibleErrorNames.EMAIL_USED,
									primitiveType: undefined,
									actual: control.value,
								} });
							} else {
								sendResult.next(null);
							}

							sendResult.complete();
						},
						error: (error) => {
							sendResult.error(error);
						},
						searchParams: queryParams,
					});

					return sendResult.asObservable();
				}));
			},
		});
	}

	private isAnA4(mediaBoxSize : {width : number, height : number}, tolerance : number) : boolean {
		const a4Sizes = {x:595, y:842};
		return (mediaBoxSize.width >= a4Sizes.x - tolerance && mediaBoxSize.width <= a4Sizes.x + tolerance &&
			mediaBoxSize.height >= a4Sizes.y - tolerance && mediaBoxSize.height <= a4Sizes.y + tolerance);
	}

	/**
	 *
	 * @returns If the pdf page dimension is of the correct dimension
	 */
	public asyncPdfPageDimension(pageDimension : 'A4') : PValidatorObject<'async'> {
		return new PValidatorObject<'async'>({
			name: PPossibleErrorNames.PDF_PAGE_DIMENSION,
			fn: async (control) : Promise<ValidationErrors | null> => {
				return new Promise((resolve) => {
					if (control.value === null || control.value.match(FILE_URL_REGEXP)) {
						resolve(null);
					} else {
						const pdfPromise = PDFDocument.load(control.value);
						pdfPromise.then(pdf => {
							// do validation
							const pageDPIWidth = pdf.getPage(0).getWidth();
							const pageDPIHeight = pdf.getPage(0).getHeight();

							let isValid : boolean;
							// eslint-disable-next-line sonarjs/no-small-switch
							switch (pageDimension) {
								case 'A4':
									isValid = this.isAnA4({width:pageDPIWidth, height:pageDPIHeight}, 1);
									break;

							}

							if (isValid) {
								resolve(null);
							} else {
								resolve({[PPossibleErrorNames.PDF_PAGE_DIMENSION]: {
									name: PPossibleErrorNames.PDF_PAGE_DIMENSION,
									primitiveType: PApiPrimitiveTypes.Pdf,
									actual: `{${pageDPIWidth}, ${pageDPIHeight}}`,
									expected: pageDimension,
								}});
							}
						});
					}
				});
			},
			comparedConst: pageDimension,
		});
	}

	/**
	 *
	 * @returns Check if the pdf only has one page
	 */
	public asyncPdfPagesCount(expectedPagesCount : Integer) : PValidatorObject<'async'> {
		return new PValidatorObject<'async'>({
			name: PPossibleErrorNames.PDF_MAX_PAGES,
			fn: async (control) : Promise<ValidationErrors | null> => {

				return new Promise(resolve => {
					// template case (no value is set) or its a link
					if (control.value === null || control.value.match(FILE_URL_REGEXP)) {
						resolve(null);
					} else {
						const pdfPromise = PDFDocument.load(control.value);
						pdfPromise.then(pdf => {
							const numberOfPages = pdf.getPageCount();
							if (numberOfPages === expectedPagesCount) {
								resolve(null);
							} else {
								resolve({[PPossibleErrorNames.PDF_MAX_PAGES]: {
									name: PPossibleErrorNames.PDF_MAX_PAGES,
									primitiveType: PApiPrimitiveTypes.Pdf,
									actual: numberOfPages,
									expected: expectedPagesCount,
								}});
							}
						});
					}

				});

			},
			comparedConst: expectedPagesCount,
		});
	}

	/**
	 * @param ratio The expected image ratio (= width / height).
	 */
	public asyncImageRatio(ratio : number) : PValidatorObject<'async'> {
		return new PValidatorObject<'async'>({
			name: PPossibleErrorNames.IMAGE_RATIO,
			fn: async (control) : Promise<ValidationErrors | null> => {

				return new Promise(resolve => {
					// template case (no value is set) or its a link
					if (control.value === null ||
							control.value === '' ||
							control.value === undefined ||
							control.value.match(FILE_URL_REGEXP)) {
						resolve(null);
					} else {
						getBase64DimensionsAsync(control.value).then(dimensions => {
							const actualRatio = dimensions.width / dimensions.height;
							const actualRatioRounded = Math.round(actualRatio * 10) / 10;
							const ratioRounded = Math.round(ratio * 10) / 10;
							if (actualRatioRounded === ratioRounded) resolve(null);
							else
								resolve({
									[PPossibleErrorNames.IMAGE_RATIO]: {
										name: PPossibleErrorNames.IMAGE_RATIO,
										primitiveType: PApiPrimitiveTypes.Image,
										actual: actualRatioRounded,
										expected: ratioRounded,
									},
								});
						});
					}

				});

			},
			comparedConst: ratio,
		});
	}

	/**
	 * @param minWidth Min image width in pixels.
	 */
	public asyncImageMinWidth(minWidth : Integer) : PValidatorObject<'async'> {
		return new PValidatorObject<'async'>({
			name: PPossibleErrorNames.IMAGE_MIN_WIDTH,
			fn: async (control) : Promise<ValidationErrors | null> => {

				return new Promise(resolve => {
					// template case (no value is set) or its a link
					if (control.value === null ||
							control.value === '' ||
							control.value === undefined ||
							control.value.match(FILE_URL_REGEXP)) {
						resolve(null);
					} else {
						getBase64DimensionsAsync(control.value).then(dimensions => {
							if (dimensions.width >= minWidth) resolve(null);
							else
								resolve({
									[PPossibleErrorNames.IMAGE_MIN_WIDTH]: {
										name: PPossibleErrorNames.IMAGE_MIN_WIDTH,
										primitiveType: PApiPrimitiveTypes.Image,
										actual: dimensions.width,
										expected: minWidth,
									},
								});
						});
					}

				});

			},
			comparedConst: minWidth,
		});
	}

	/**
		 * @param minHeight Min image height in pixels.
		 */
	public asyncImageMinHeight(minHeight : Integer) : PValidatorObject<'async'> {
		return new PValidatorObject<'async'>({
			name: PPossibleErrorNames.IMAGE_MIN_HEIGHT,
			fn: async (control) : Promise<ValidationErrors | null> => {

				return new Promise(resolve => {
					// template case (no value is set) or its a link
					if (control.value === null ||
							control.value === '' ||
							control.value === undefined ||
							control.value.match(FILE_URL_REGEXP)) {
						resolve(null);
					} else {
						getBase64DimensionsAsync(control.value).then(dimensions => {
							if (dimensions.height >= minHeight) resolve(null);
							else
								resolve({
									[PPossibleErrorNames.IMAGE_MIN_HEIGHT]: {
										name: PPossibleErrorNames.IMAGE_MIN_HEIGHT,
										primitiveType: PApiPrimitiveTypes.Image,
										actual: dimensions.height,
										expected: minHeight,
									},
								});
						});
					}

				});

			},
			comparedConst: minHeight,
		});
	}
}
