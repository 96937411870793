import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { AssignmentProcessesService } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-processes.service';
import { MeService, RightsService, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessShiftRef, SchedulingApiAssignmentProcessState, SchedulingApiAssignmentProcessType, SchedulingApiAssignmentProcesses, SchedulingApiService, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { NonEmptyArray } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-assignment-processes',
	templateUrl: './p-assignment-processes.component.html',
	styleUrls: ['./p-assignment-processes.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAssignmentProcessesComponent {

	constructor(
		public api : SchedulingApiService,
		private assignmentProcessesService : AssignmentProcessesService,
		private pMomentService : PMomentService,
		public meService : MeService,
		private modalService : ModalService,
		private rightsService : RightsService,
		private localize : LocalizePipe,
		private schedulingService : SchedulingService,
		private filterService : FilterService,
		private pRouterService : PRouterService,
		private decimalPipe : DecimalPipe,
		private toastsService : ToastsService,
	) {
	}

	public readonly TYPES : typeof SchedulingApiAssignmentProcessType = SchedulingApiAssignmentProcessType;

	public readonly CONFIG = Config;
	public newProcessName : string | null = null;
	public newProcessType : SchedulingApiAssignmentProcessType = SchedulingApiAssignmentProcessType.DR_PLANO;

	public enums = enumsObject;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get noItemsYet() : boolean {
		if (!this.api.isLoaded()) return true;
		if (this.assignmentProcessesForList.length) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get assignmentProcessesForList() : SchedulingApiAssignmentProcesses {
		if (!this.api.isLoaded()) return new SchedulingApiAssignmentProcesses(null, null);

		// TODO: PLANO-156519
		if (!this.api.data.attributeInfoAssignmentProcesses.isAvailable) return new SchedulingApiAssignmentProcesses(null, null);
		return this.api.data.assignmentProcesses;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showAddButton() : boolean | undefined {
		if (Config.IS_MOBILE) return false;
		return this.userCanSetAssignmentProcesses;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get userCanSetAssignmentProcesses() : boolean | undefined {
		return this.rightsService.userCanSetAssignmentProcesses;
	}

	/**
	 * Open Modal with for for a new assignment process
	 */
	public createNewAssignmentProcess(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		this.newProcessName = this.localize.transform({
			sourceString: 'Schichtverteilung ${counter}',
			params: {counter: (this.api.data.assignmentProcesses.length + 1).toString()},
		});
		void this.modalService.openModal(modalContent).result.then(async value => {
			if (value.modalResult === 'success') {
				const newProcess = this.api.data.assignmentProcesses.createNewItem();
				newProcess.name = this.newProcessName!;
				newProcess.type = this.newProcessType;
				return this.api.save();
			}
		});
	}

	/**
	 * @returns Returns all shifts not a process with empty members slots.
	 */
	private _shiftsRemainingWithEmptyMemberSlotsData = new Data<SchedulingApiShifts>(this.api, this.filterService);

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftsRemainingWithEmptyMemberSlots() : SchedulingApiShifts {
		return this._shiftsRemainingWithEmptyMemberSlotsData.get(() => {
			return this.api.data.shifts.filterBy((item) => {
				if (item.assignmentProcess) return false;
				if (!item.emptyMemberSlots) return false;
				if (!this.filterService.isVisible(item))
					return false;
				return true;
			});
		});
	}

	/**
	 * Selects/deselects all items with free slots
	 */
	public toggleShiftsRemainingWithEmptyMemberSlots() : void {
		if (this.allShiftsRemainingWithEmptyMemberSlotsSelected) {
			this.shiftsRemainingWithEmptyMemberSlots.setSelected(false);
		} else {
			this.shiftsRemainingWithEmptyMemberSlots.setSelected();
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get allShiftsRemainingWithEmptyMemberSlotsSelected() : boolean {
		const items = this.shiftsRemainingWithEmptyMemberSlots;
		return !!items.length && items.length === items.selectedItems.length;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get someShiftsRemainingWithEmptyMemberSlotsSelected() : boolean {
		return this.shiftsRemainingWithEmptyMemberSlots.selectedItems.length > 0;
	}

	/**
	 * Count empty slots in the current view for shifts which are not in a assignment process.
	 */
	public get remainingEmptySlotsCounter() : number {
		let result = 0;
		for (const shift of this.shiftsRemainingWithEmptyMemberSlots.iterable()) {
			result += shift.emptyMemberSlots;
		}
		return result;
	}

	/**
	 * Count hours of empty slots in the current view for shifts which are not in a assignment process.
	 */
	public get remainingEmptySlotsHoursCounter() : string | null {
		const hour = 60 * 60 * 1000;
		let hours = 0;
		for (const shift of this.shiftsRemainingWithEmptyMemberSlots.iterable()) {
			hours += (shift.end - shift.start) / hour;
		}
		if (hours > 999) '999+';
		return this.decimalPipe.transform(hours, '1.0-0');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showRemainingProcessesButton() : boolean {
		if (Config.IS_MOBILE) return false;
		if (!this.api.isLoaded()) return false;
		if (!this.rightsService.userCanWriteAnyShiftModel && !this.userCanSetAssignmentProcesses) return false;
		return this.api.data.shifts.length > 0;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get listHeadline() : string {
		if (this.userCanSetAssignmentProcesses) return this.localize.transform('Schichten verteilen');
		return this.localize.transform('Schichten zu verteilen');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get listHeadlineTooltip() : string | undefined {
		if (!this.showAddButton) return undefined;
		if (this.userCanSetAssignmentProcesses) return this.localize.transform('Erstelle einen neuen Verteilungsvorgang, um deine Schichten zu besetzen.');
		return this.localize.transform('Sobald deine Personalleitung Schichten verteilen lässt, siehst du das hier und kannst aktiv werden.');
	}

	private async getToFirstShift(process : SchedulingApiAssignmentProcess) : Promise<boolean | null> {
		return this.navigateToFirstShiftOfProcess(process, false);
	}

	private async getToFirstShiftWithToDo(process : SchedulingApiAssignmentProcess) : Promise<boolean | null> {
		return this.navigateToFirstShiftOfProcess(process, true);
	}

	private getEndOfCurrentView() : number {
		const calendarModeIdentifier : 'D' | 'M' | 'W' = this.schedulingService.urlParam.calendarMode.at(0)!.toUpperCase() as 'D' | 'M' | 'W';
		return this.pMomentService.m(this.schedulingService.urlParam.date).endOf(calendarModeIdentifier).valueOf() + 1;

	}

	private getStartOfCurrentView() : number {
		const calendarModeIdentifier : 'D' | 'M' | 'W' = this.schedulingService.urlParam.calendarMode.at(0)!.toUpperCase() as 'D' | 'M' | 'W';
		return this.pMomentService.m(this.schedulingService.urlParam.date).startOf(calendarModeIdentifier).valueOf();
	}

	private deselectPreviousSelectedProcesses(process : SchedulingApiAssignmentProcess) : void {
		for (const existingProcess of this.api.data.assignmentProcesses.iterable()) {
			if (!existingProcess.id.equals(process.id) && existingProcess.selected) {
				this.api.data.shifts.toggleSelectionByItem(existingProcess);
			}
		}
	}

	private handleNoMoreToDo(process : SchedulingApiAssignmentProcess) : void {
		this.noMoreToDoShifts = true;
		const earliestStart =
				this.assignmentProcessesService.getEarliestStartOfShiftRefs(
					process.shiftRefs.iterable() as NonEmptyArray<SchedulingApiAssignmentProcessShiftRef>,
				);
		if (earliestStart >= this.getStartOfCurrentView() &&
				earliestStart < this.getEndOfCurrentView()) {
			this.scrollToCorrectShift(process, true);
		} else {
			const tempSubscriber = this.api.onDataLoaded.subscribe(() => {
				this.scrollToCorrectShift(process, true);
				tempSubscriber.unsubscribe();
			});
			// eslint-disable-next-line ban/ban -- intended navigation
			void this.pRouterService.navigate([`/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${earliestStart}`]);
		}
	}

	private noMoreToDoShifts = false;
	private couldFindRelevantShifts = true;

	private async navigateToFirstShiftOfProcess(process : SchedulingApiAssignmentProcess, onlyWithTodos : boolean) : Promise<boolean | null> {

		// When we are creating an assignment process it is possible that no shiftRef is yet added to the process
		// in such cases, do nothing
		if (process.shiftRefs.length === 0) {
			return null;
		}

		if (process.selected) {
			this.api.data.shifts.toggleSelectionByItem(process);
			return null;
		}

		// deselect any previous selected process
		this.deselectPreviousSelectedProcesses(process);

		// all the shiftRefs present on the current view
		const allShiftRefsOnCurrentView = process.shiftRefs.filterBy(item => {
			return	item.id.start >= this.getStartOfCurrentView() &&
					item.id.end <= this.getEndOfCurrentView();
		}).iterable();

		// relevant shifts that could be loaded for the current view
		const currentLoadedShifts = this.getAllShiftsForMode(process, onlyWithTodos);

		// the list of shiftRefs that should be considered
		let shiftRefsToConsider : SchedulingApiAssignmentProcessShiftRef[] | undefined = undefined;

		let firstDayStartWithTodo : number | null = null;

		// There is nothing left to do in this process
		if (onlyWithTodos && process.firstDayStartWithTodo === null) {
			this.handleNoMoreToDo(process);
			return null;
		}

		// are there no shifts matching the shifts for this process on the current view?
		if (!allShiftRefsOnCurrentView.some(shiftRef => currentLoadedShifts.contains(shiftRef.id))) {
			if (onlyWithTodos) {
				shiftRefsToConsider = this.shiftRefsWithTodos(process.shiftRefs.iterable(), process);
				this.couldFindRelevantShifts = false;
				firstDayStartWithTodo = process.firstDayStartWithTodo;
			} else {
				this.couldFindRelevantShifts = false;
				shiftRefsToConsider = [...process.shiftRefs.iterable()];
			}
		} else {
			if (onlyWithTodos)
				shiftRefsToConsider = this.shiftRefsWithTodos(allShiftRefsOnCurrentView, process);
			else shiftRefsToConsider = [...allShiftRefsOnCurrentView];
		}

		const earliestStartOfShiftRefs = firstDayStartWithTodo ??
			this.assignmentProcessesService.getEarliestStartOfShiftRefs(shiftRefsToConsider as NonEmptyArray<SchedulingApiAssignmentProcessShiftRef>);

		const earliestStart = earliestStartOfShiftRefs || this.schedulingService.urlParam.date;

		// if there are no relevant shifts loaded, we need to wait for the data to be loaded through the navigation
		if (currentLoadedShifts.length === 0 || firstDayStartWithTodo) {
			const tempSubscriber = this.api.onDataLoaded.subscribe(() => {
				this.scrollToCorrectShift(process, onlyWithTodos);
				tempSubscriber.unsubscribe();
			});
		} else {
			this.scrollToCorrectShift(process, onlyWithTodos);
		}

		// eslint-disable-next-line ban/ban -- intended navigation inside the calendar
		return await this.pRouterService.navigate([`/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${earliestStart}`]);
	}

	private shiftRefsWithTodos(
		allShiftRefs : readonly SchedulingApiAssignmentProcessShiftRef[],
		process : SchedulingApiAssignmentProcess,
	) : SchedulingApiAssignmentProcessShiftRef[] {
		return allShiftRefs.filter(item => {
			if (process.firstDayStartWithTodo && item.id.start < process.firstDayStartWithTodo)
				return false;
			if (process.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES)
				return !!item.requesterCanSetPref;
			if (process.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING)
				return !!item.requesterCanDoEarlyBird;
			return false;
		});
	}

	/**
	 * Decide if we should scroll to the first shift with todo or to the first general shift.
	 * It will only scroll if the shift has been selected
	 */
	private scrollToCorrectShift(process : SchedulingApiAssignmentProcess, onlyWithTodos : boolean) : void {
		if (!onlyWithTodos) this.api.data.shifts.toggleSelectionByItem(process, onlyWithTodos);

		const scrollWasSuccessful = onlyWithTodos && this.scrollToFirstShiftWithTodo(process);
		if (!scrollWasSuccessful) this.scrollToFirstShift(process);
	}

	/** returns true if shift with todo could be found on the view */
	private scrollToFirstShiftWithTodo(process : SchedulingApiAssignmentProcess) : boolean {
		const now = +this.pMomentService.m().startOf('D');
		const shifts = this.getAllShiftsForMode(process, true).filterBy(item => item.id.start >= now);
		if (this.noMoreToDoShifts) {
			this.toastsService.addToast({
				theme: enumsObject.PThemeEnum.INFO,
				visibilityDuration: 'long',
				content: this.localize.transform('Es gibt keine Schichten mehr, wo du was zu tun hättest. Daher wurdest du zur ersten Schicht des Verteilungsvorgangs navigiert.'),
			});
			this.noMoreToDoShifts = false;
			this.couldFindRelevantShifts = true;
		} else if (!this.couldFindRelevantShifts) {
			this.toastsService.addToast({
				theme: enumsObject.PThemeEnum.INFO,
				visibilityDuration: 'long',
				content: this.localize.transform('Es gab keine passenden Schichten in der Kalender-Ansicht, wo du dich befandest. Daher wurdest du zur ersten passenden Schicht navigiert.'),
			});
			this.couldFindRelevantShifts = true;
		}
		return this.pRouterService.scrollToFirstShift(shifts);
	}

	private scrollToFirstShift(process : SchedulingApiAssignmentProcess) : void {
		const shifts = this.getAllShiftsForMode(process, false);
		this.pRouterService.scrollToFirstShift(shifts);
		if (!this.couldFindRelevantShifts) {
			this.toastsService.addToast({
				theme: enumsObject.PThemeEnum.INFO,
				visibilityDuration: 'long',
				content: this.localize.transform('Es gab keine passenden Schichten in der Kalender-Ansicht, wo du dich befandest. Daher wurdest du zur ersten passenden Schicht navigiert.'),
			});
			this.couldFindRelevantShifts = true;
		}
	}

	private getAllShiftsForMode(process : SchedulingApiAssignmentProcess, onlyWithTodos : boolean) : SchedulingApiShifts {
		const now = this.pMomentService.m().startOf('D').valueOf();
		return this.api.data.shifts.filterBy(item => {
			const shiftRef = process.shiftRefs.get(item.id);
			if (!shiftRef) return false;
			if (!onlyWithTodos) return true;
			if (item.start < now) return false;
			if (process.firstDayStartWithTodo && item.id.start < process.firstDayStartWithTodo) return false;
			if (process.type === SchedulingApiAssignmentProcessType.EARLY_BIRD) return shiftRef.requesterCanDoEarlyBird;
			return shiftRef.requesterCanSetPref && item.myPref === null;
		});
	}

	/**
	 * Open the collapsible if possible.
	 * If not then its probably a member not an admin. Then select the fist shifts of this process in the calendar.
	 */
	public onClickProcess(process : SchedulingApiAssignmentProcess) : void {
		if (!this.isCollapsible(process)) {
			void this.getToFirstShiftWithToDo(process).then(() => {
				this.toggleRelatedMode(process);
			});
			return;
		}
		this.onClickOpenToggle(process);
	}

	/**
	 * Toggle the related shifts. If not possible, nav to first shift of them, and then toggle again.
	 */
	public toggleRelatedShifts(process : SchedulingApiAssignmentProcess) : void {
		void this.getToFirstShift(process);
	}

	private toggleRelatedMode(process : SchedulingApiAssignmentProcess) : void {
		switch (process.type) {
			case SchedulingApiAssignmentProcessType.EARLY_BIRD:
				if (process.state !== SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING) return;
				this.schedulingService.earlyBirdMode = true;
				break;
			case SchedulingApiAssignmentProcessType.MANUAL:
			case SchedulingApiAssignmentProcessType.DR_PLANO:
				if (process.state !== SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES) return;
				this.schedulingService.wishPickerMode = true;
		}
	}

	private isCollapsible(process : SchedulingApiAssignmentProcess) : boolean | null {
		if (Config.IS_MOBILE) return false;
		return this.userCanSet(process);
	}

	private userCanSet(process : SchedulingApiAssignmentProcess) : boolean | null {
		return this.rightsService.userCanSetAssignmentProcess(process);
	}

	/**
	 * Open or close this collapsible
	 */
	public onClickOpenToggle(process : SchedulingApiAssignmentProcess) : void {
		if (!this.userCanSet(process)) return;
		process.collapsed = !process.collapsed;
	}

	/** Get a title for a SchedulingApiAssignmentProcessType */
	public titleForType(type : SchedulingApiAssignmentProcessType) : PDictionarySourceString {
		switch (type) {
			case SchedulingApiAssignmentProcessType.DR_PLANO:
				return 'Automatische Verteilung';
			case SchedulingApiAssignmentProcessType.EARLY_BIRD:
				return 'Der frühe Vogel';
			case SchedulingApiAssignmentProcessType.MANUAL:
				return 'Manuelle Verteilung';
		}
	}

	/** Get a text for a SchedulingApiAssignmentProcessType */
	public textForType(type : SchedulingApiAssignmentProcessType) : PDictionarySourceString {
		switch (type) {
			case SchedulingApiAssignmentProcessType.DR_PLANO:
				return 'Diese Verteilungsart kostet dich am wenigsten Zeit und ist zugleich besonders fair für das gesamte Team. Dr.&nbsp;Plano berücksichtigt dabei folgende Faktoren:';
			case SchedulingApiAssignmentProcessType.EARLY_BIRD:
				return 'Deine Angestellten tragen sich selbst für die freigegebenen Schichten ein. Wer am schnellsten ist, bekommt womöglich die besten Schichten. Daher ist das nicht die fairste Verteilungsart, aber sinnvoll, wenn es z.B. mal besonders schnell gehen soll.';
			case SchedulingApiAssignmentProcessType.MANUAL:
				return 'Bei dieser Verteilungsart bekommst du die Schichtwünsche deiner Angestellten. Anschließend musst du jede Schicht manuell besetzen. Das ist viel zeitaufwendiger als die automatische Verteilung, aber sinnvoll, wenn du bei der Verteilung sehr viele Sonderwünsche berücksichtigen möchtest.';
		}
	}

	/**
	 * Should the assignment processes box be visible?
	 */
	public get showAssignmentProcessesCard() : boolean {
		// This was undocumented. I just took it from the template.
		return !!this.userCanSetAssignmentProcesses || !!this.rightsService.userCanWriteAnyShiftModel || !!this.assignmentProcessesForList.length;
	}
}
