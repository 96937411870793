<ng-template [ngIf]="isLoading">
	<span class="badge-content">{{!!content ? content : '██'}}</span>
</ng-template>
<a
	*ngIf="badgeRouterLinkForTemplate; else isLoadedTemplate"
	class="navigate-inside-app"
	[routerLink]="badgeRouterLinkForTemplate"
	[fragment]="badgeFragment!">
	<ng-container *ngTemplateOutlet="isLoadedTemplate"></ng-container>
</a>
<ng-template #isLoadedTemplate>
	<ng-template [ngIf]="!!content || !!icon">
		<ng-template [ngIf]="!!icon">
			<fa-icon class="block-rotation" [class.mr-1]="!!text" [icon]="icon"></fa-icon>
		</ng-template>
		<ng-template [ngIf]="!!text">
			<span class="badge-content" [innerHTML]="text | pSafeHtml"></span>
		</ng-template>
	</ng-template>
</ng-template>
