<p-report-row
	[id]="absenceId"
	[class.border-bottom]="false"
	[headline]="false"
	[ruler]="true"
	[duration]="null"
	[workingTime]="null"
	[absence]="absence"
	[isEditable]="true"
	[hideEditBtn]="!rightsService.userCanWriteAbsences"
	[min]="min"
	[max]="max"
	[class.bg-light]="false"
>
	<div report-row-title class="crop-on-overflow d-flex align-items-center justify-content-between flex-grow-1">
		<div [class.d-flex]="!CONFIG.IS_MOBILE" class="crop-on-overflow">
			<ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
			<ng-template [ngTemplateOutlet]="dateAndTimeTemplate"></ng-template>
		</div>
	</div>
	<strong class="nowrap" report-row-earnings>{{ absence.totalEarningsBetween(min, max) | currency:CONFIG.CURRENCY_CODE:'symbol':'1.2-2' }}</strong>
</p-report-row>

<ng-template #titleTemplate>
	<span class="mr-3"><fa-icon [icon]="absence.typeIconName"></fa-icon> {{ title | localize }}</span>
</ng-template>

<ng-template #dateAndTimeTemplate>
	<div class="d-flex crop-on-overflow" [class.small]="CONFIG.IS_MOBILE">
		<ng-template [ngTemplateOutlet]="dateTemplate"></ng-template>
		<ng-template [ngTemplateOutlet]="timeTemplate"></ng-template>
	</div>
</ng-template>

<ng-template #dateTemplate>
	<span class="text-monospace mr-3">
		<span
			[pTooltip]="min > absence.time.start ? tooltipOutOfRange : null"
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

			[class.text-muted]="min > absence.time.start"
		>{{ dateInfo.start }}</span>
		{{ dateInfo.separator }}<span
			[pTooltip]="absenceIsOutsideRange ? tooltipOutOfRange : null"
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

			[class.text-muted]="absenceIsOutsideRange"
		>{{ dateInfo.end }}</span></span>

	<ng-template #tooltipOutOfRange>
		<div class="d-block clickable card m-0 text-left o-hidden"
		>
			<div class="card-body p-1 pl-2 pr-2">
				<div i18n>Dieses Datum liegt außerhalb des Zeitraums {{ min | date:'shortDate' }} – {{ max - 1 | date:'shortDate' }}, der für diese Auswertung eingestellt wurde. Dauer der Arbeit/Abwesenheit und Verdienst werden deshalb anteilig berechnet.</div>
			</div>
		</div>
	</ng-template>
</ng-template>

<ng-template #timeTemplate>
	<span class="text-monospace crop-on-overflow" *ngIf="absence.workingTimePerDay === null"
	>{{ absence.time.start | date:'veryShortTime' }} – {{ absence.time.end | date:'shortTime' }}</span>
</ng-template>
