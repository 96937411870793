import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { PAllDayItemsListComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar/p-all-day-items-list/p-all-day-items-list.component';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiService, SchedulingApiShift, SchedulingApiShifts } from '@plano/shared/api';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subscription, interval } from 'rxjs';

@Component({
	selector: 'p-week-cell[weekday][shifts][absences][holidays][birthdays]',
	templateUrl: './week-cell.component.html',
	styleUrls: ['./week-cell.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class WeekCellComponent implements OnDestroy, PComponentInterface {

	@HostBinding('attr.aria-labelledby') private get _ariaLabelledBy() : string {
		return `calendar-week-day-${this.weekday}`;
	}
	@HostBinding('attr.role') private _role = 'gridcell';

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public weekday ! : number;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts ! : SchedulingApiShifts;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absences ! : PAllDayItemsListComponent['absences'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public holidays ! : PAllDayItemsListComponent['holidays'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public birthdays ! : PAllDayItemsListComponent['birthdays'];

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onShiftClick = new EventEmitter<{shift : SchedulingApiShift, event : MouseEvent}>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : boolean = false;

	constructor(
		private pMomentService : PMomentService,
		private console : LogService,
		private api : SchedulingApiService,
	) {
		this.now = +this.pMomentService.m();
	}

	public CalendarModes = CalendarModes;

	public enums = enumsObject;

	public now ! : number;

	private firstShiftAfterNow : SchedulingApiShift | null = null;

	private nowLineUpdateIntervals : {[key : string] : Subscription | undefined} = {};

	private updateFirstShiftAfterNow(shifts : SchedulingApiShift[]) : void {
		const now = Date.now();
		this.firstShiftAfterNow = shifts.sort((a, b) => {
			if (!a.rawData || !b.rawData) {
				this.clearNowLineUpdateIntervals();
				return 0;
			}
			return a.start - b.start;
		}).find((item) => {
			if (!item.rawData) return null;
			return item.start > now;
		}) ?? null;
	}

	/** Should the now line be visible? */
	public showNowLine(shift : SchedulingApiShift) : boolean {
		if (!this.isToday(this.weekday)) return false;

		if (this.firstShiftAfterNow === null && !this.nowLineUpdateIntervals[`${this.weekday}${this.shifts.length}`]) {
			if (!this.api.isBackendOperationRunning) {
				this.updateFirstShiftAfterNow([...this.shifts.iterable()]);
				this.nowLineUpdateIntervals[`${this.weekday}${this.shifts.length}`] = interval(5000).pipe().subscribe(() => {
					this.updateFirstShiftAfterNow([...this.shifts.iterable()]);
				});
			}
			return false;
		}

		if (this.firstShiftAfterNow === null) return false;

		if (this.firstShiftAfterNow.rawData === null) {
			this.console.error('firstShiftAfterNow.rawData is null [PRODUCTION-505]');
			return false;
		}

		// eslint-disable-next-line sonarjs/prefer-immediate-return
		const isSameId = shift.id.equals(this.firstShiftAfterNow.id);
		return isSameId;
	}

	private isToday(date : Exclude<SchedulingService['urlParam'], null>['date']) : boolean {
		return this.pMomentService.m(date).isSame(this.now, 'date');
	}

	public ngOnDestroy() : void {
		this.clearNowLineUpdateIntervals();
	}

	private clearNowLineUpdateIntervals() : void {
		for (const key of Object.keys(this.nowLineUpdateIntervals)) {
			this.nowLineUpdateIntervals[key]?.unsubscribe();
			this.nowLineUpdateIntervals[key] = undefined;
		}
	}

}
