/* eslint-disable no-warning-comments */
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BootstrapSizePool, PThemePool } from '@plano/client/shared/bootstrap-styles.enum';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PlanoFaIconPool } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This Object can be used to import it components, when you need some enums in the template.
 *
 * @example
 * 	foo.component.ts: public enums = enumsObject;
 * 	foo.component.html: <p-foo [theme]="enums.PThemeEnum.Success">
 *
 */
export const enumsObject = {
	/** @see PThemePool */
	PThemeEnum: PThemePool,

	/** @see PlanoFaIconPool */
	PlanoFaIconPool: PlanoFaIconPool,

	/** @see BootstrapSizePool */
	BootstrapSize: BootstrapSizePool,

	// NOTE:	If you add one enum here consider the following:
	// 				- you should restrict others to import that enum. Do that via "@typescript-eslint/no-restricted-imports".
	// 				- only enums that are wide spread should be added. Have a quick chat with a colleague if not sure.
};
/* eslint-enable @typescript-eslint/naming-convention */
