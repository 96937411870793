<div
	[tabindex]="0"
	[routerLink]="isLoading || !userCanWrite || !Config.IS_MOBILE ? null : editBookingLink"
	class="mb-0 o-hidden"
	[class.clickable]="Config.IS_MOBILE"
	[class.is-inside-list]="isInsideList"
	[class.is-not-inside-list]="!isInsideList">
	<p-color-marker
		[item]="model"
		[hexColor]="modelColor"
		[title]="model?.name"
		[isLoading]="isLoading"
	></p-color-marker>
	<div class="d-flex align-items-center data-tupel">
		<div class="booking-data-values">
			<fa-icon
				class="status"
				[fixedWidth]="true"
				[icon]="statusIcon"
				[class.text-danger]="statusTheme === enums.PThemeEnum.DANGER"
				[pTooltipTheme]="statusTheme ? statusTheme : enums.PThemeEnum.DARK"
				[pTooltip]="statusLabel"
				[isLoading]="isLoading"
			></fa-icon>
			<span class="bookingNumber crop-on-overflow"
				[class.text-skeleton-animated]="isLoading"
				title="Buchungsnummer" i18n-title
			><fa-icon class="small text-muted" [icon]="enums.PlanoFaIconPool.NUMBER"></fa-icon>{{ bookingNumber }}</span>
			<span
				class="shiftModelName crop-on-overflow"
				[class.text-skeleton-animated]="isLoading"
			>{{ !isLoading ? modelName : '████ ██████' }}</span>
			<ng-container *ngIf="isInsideList">
				<span class="bookingPerson crop-on-overflow"
					*ngIf="!isLoading"
				>{{ firstName }} {{ lastName }}</span>
				<span class="bookingPerson text-skeleton-animated"
					*ngIf="isLoading"
				>████ ██████</span>
			</ng-container>
			<span class="dateOfBooking">
				<span
					*ngIf="!isLoading"
					[pTooltip]="isInsideList ? null : 'Buchungsdatum' | localize"
				>{{ dateOfBooking | date:(Config.IS_MOBILE ? 'veryShortDate' : 'shortDate') }}</span>
				<span
					*ngIf="isLoading"
					class="text-skeleton-animated"
				>██.██.████</span>
			</span>

			<span class="firstShiftStart" *ngIf="showFirstShiftStart">
				<ng-container *ngIf="!isLoading">
					<span
						*ngIf="!firstShiftStart"
					>-</span>
					<span
						*ngIf="firstShiftStart"
						[class.text-line-through]="noRelatedShiftsAvailable"
					>{{ firstShiftStart | date:(Config.IS_MOBILE ? 'veryShortDate' : 'shortDate') }}</span>
				</ng-container>
				<span
					*ngIf="isLoading"
					class="text-skeleton-animated text-monospace"
				>██.██.████</span>
			</span>

			<span class="participantCount" *ngIf="isLoading || participantCount !== null">
				<span
					*ngIf="!isLoading"
				>{{ participantCount }}</span>
				<span
					*ngIf="isLoading"
					class="text-skeleton-animated text-monospace"
				>██</span>
			</span>

			<span class="price" *ngIf="isLoading || (price !== null && price !== undefined)">
				<span
					*ngIf="!isLoading"
				>{{ price | currency:Config.CURRENCY_CODE }}</span>
				<span
					*ngIf="isLoading"
					class="text-skeleton-animated text-monospace"
				>███.██</span>
			</span>

			<div
				class="paymentStatus"
				[pTooltip]="paymentStatusTitle | localize"
				[pTooltipPlacement]="tooltipPlacements.LEFT"
				[pTooltipTheme]="paymentStatusTooltipTheme"
				[class.text-skeleton-animated]="isLoading"
			>
				<fa-icon
					[isLoading]="isLoading || paymentStatusIcon === enums.PlanoFaIconPool.LOADING"
					[fixedWidth]="true"
					[icon]="paymentStatusIcon"
					[theme]="paymentStatusIconStyle"
					class="d-block"
				></fa-icon>
			</div>

			<ng-container *ngIf="isInsideList">
				<span class="bookingComment">
					<fa-icon
						*ngIf="!isLoading && !!bookingComment"
						[pTooltip]="bookingCommentTemplate"
						[pTooltipPlacement]="tooltipPlacements.LEFT"
						class="mr-1 d-block"
						[fixedWidth]="true" [icon]="['regular', 'comment-dots']"
					></fa-icon>
					<ng-template #bookingCommentTemplate>
						<div class="px-2 py-1">
							<strong class="d-block" i18n>Kommentar der buchenden Person:</strong>
							<span><i [title]="bookingComment">{{bookingComment!.slice(0,200)}}{{bookingComment!.length > 200 ? '…' : ''}}</i><a class="d-flex flex-nowrap" fragment="booking-booking-person-comment" routerLink="/client/booking/{{id!.toString()}}/owner-comment"><ng-container i18n>Zum Kommentar</ng-container><fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.NAV_FORWARD" /></a></span>
						</div>
					</ng-template>
				</span>
				<span class="ownerComment">
					<fa-icon
						*ngIf="!isLoading && ownerComment"
						[pTooltip]="ownerCommentTemplate"
						[pTooltipPlacement]="tooltipPlacements.LEFT"
						class="mr-1 d-block"
						[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.NOTE"
					></fa-icon>
					<ng-template #ownerCommentTemplate>
						<div class="px-2 py-1">
							<strong class="d-block" i18n>Interne Notiz:</strong>
							<span><i [title]="ownerComment">{{ownerComment!.slice(0,200)}}{{ownerComment!.length > 200 ? '…' : ''}}</i><a fragment="booking-owner-comment" class="d-flex flex-nowrap" routerLink="/client/booking/{{id!.toString()}}/owner-comment"><ng-container i18n>Zur Notiz</ng-container><fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.NAV_FORWARD" /></a></span>
						</div>
					</ng-template>
				</span>
			</ng-container>
		</div>
		<div class="booking-controls card-options">
			<ng-content></ng-content>
			<a
				[routerLink]="isLoading || noRelatedShiftsAvailable ? null : onSelectShiftsLink"
				[queryParams]="bookingCourseSelector ? {bookingCourseSelector: bookingCourseSelectorUrl} : {}"
				*ngIf="!Config.IS_MOBILE"
				class="card-option btn btn-outline-secondary radius-0 border-bottom-0 nowrap"
				[disabled]="!!(isLoading || noRelatedShiftsAvailable)"
				role="button"
				[attr.aria-label]="noRelatedShiftsAvailable ? ('Keine Schicht im Kalender' | localize) : ('Im Kalender ansehen' | localize)"
				[pTooltip]="noRelatedShiftsAvailable ? ('Keine Schicht im Kalender' | localize) : ('Im Kalender ansehen' | localize)"
			><fa-icon [icon]="enums.PlanoFaIconPool.CALENDAR_SELECT_RELATED_SHIFTS"></fa-icon></a>
			<a
				*ngIf="isLoading || (userCanWrite || userCanWriteSomeBookings) && !Config.IS_MOBILE"
				[routerLink]="editButtonIsDisabled ? null : editBookingLink"
				role="button"
				class="card-option btn btn-outline-secondary radius-0 border-bottom-0 nowrap"
				[disabled]="editButtonIsDisabled"
				[pTooltip]="!userCanWrite ? ('Du hast nicht die Berechtigung dazu. Bitte wende dich an einen Admin.' | localize) : null"
				title="Bearbeiten" i18n-title
			><fa-icon icon="pen"></fa-icon></a>
		</div>
	</div>
</div>

<ng-template #spinnerTemplate>
	<p-spinner></p-spinner>
</ng-template>
