<!-- This card is advertising for our open job offers. Should get rid of after a while. -->
<ng-container *ngTemplateOutlet="deskCardTemplate"></ng-container>
<ng-container *ngTemplateOutlet="commentList"></ng-container>
<ng-container *ngTemplateOutlet="shiftExchangeList"></ng-container>
<p-assignment-processes></p-assignment-processes>

<ng-template #deskCardTemplate>
	<div *ngIf="meService.isLoaded() && deskCardIsVisible" class="card border-0 o-hidden launch-darkly-desk-card mb-3">
		<button type="button" class="close py-2" title="Schließen" i18n-title (click)="hideSection('desk-card')">
			<fa-icon aria-hidden="true" [icon]="enums.PlanoFaIconPool.DISMISS"></fa-icon>
		</button>
		<section [innerHTML]="safeHtmlStr"></section>
	</div>
</ng-template>
<ng-template #commentList>
	<div
		*ngIf="!!now"
		class="card border-0 rounded o-hidden">
		<section>
			<list-headline id="today-comment-headline" text="Heutige Kommentare – {{ now | date:'minimalDate' }}" i18n-text>
				<p-sticky-note headline-icon height="18"></p-sticky-note>
			</list-headline>
		</section>
		<div
			aria-labelledby="today-comment-headline"
			class="position-relative bg-light">
			<p-spinner [size]="enums.BootstrapSize.LG" *ngIf="isLoading" class="area-blocking-spinner"></p-spinner>

			<p-day-comment
				role="listitem"
				class="small"
				[maxLines]="true"
				[maxTextLength]="true"
			></p-day-comment>
			<p-shift-comments></p-shift-comments>
		</div>
	</div>
</ng-template>
<ng-template #shiftExchangeList>
	<p-shift-exchange-list
		[showOnlyItemsForDesk]="true"
		[showDetails]="false"
		[shiftExchanges]="shiftExchanges"
		(calendarBtnClick)="pShiftExchangeService.onCalendarBtnClick($event)"
	>
		<a *ngIf="!Config.IS_MOBILE" routerLink="/client/shift-exchanges" (click)="navToShiftExchanges.emit()" class="w-100 btn btn-light btn-frameless border-top text-center d-flex justify-content-center align-items-center">
			<fa-icon [icon]="enums.PlanoFaIconPool.LIST" class="mr-2"></fa-icon>
			<small i18n>Alle Einträge anzeigen</small>
		</a>
	</p-shift-exchange-list>
</ng-template>
