<p-tabs
	[theme]="PTabsTheme.SIDE"
	[containerTabs]="true"
	[renderOnlyContentOfActiveTab]="true"
>
	<p-tab
		[scrollableTab]="true"
		label="Error modal"
		urlName="error-modal"
	>
		<button (click)="throw()">Throw</button>
	</p-tab>
	<p-tab
		[scrollableTab]="true"
		label="p-input-date"
		urlName="p-input-date"
	>
		<div class="row">
			<div class="col-md-6">
				<p-input-date
					title="Start"
					[(ngModel)]="startTimestamp"
					[max]="endTimestamp"
				/>
			</div>
			<div class="col-md-6">
				<p-input-date
					title="End"
					[type]="PInputDateTypes.deadline"
					[(ngModel)]="endTimestamp"
					[min]="startTimestamp"
				/>
			</div>
		</div>
	</p-tab>
	<p-tab
		label="PTextEditorComponent"
		urlName="p-text-editor-component"
	>
		<p-bootstrap-form-group
			#disabledTextEditor
			label="Disabled text-editor"
			[ariaLabel]="disabledTextEditor.label"
		>
			<p-text-editor
				[pEditable]="true"
				[api]="aiSwitchShowcaseApi"
				[disabled]="true"
			/>
		</p-bootstrap-form-group>
		<p-bootstrap-form-group
			#disabledTextEditor
			label="Enabled text-editor"
			[ariaLabel]="disabledTextEditor.label"
		>
			<p-text-editor
				*ngIf="aiSwitchShowcaseApi.data.attributeInfoAllTypes.isAvailable && aiSwitchShowcaseApi.data.allTypes.attributeInfoString.isAvailable"
				[pEditable]="true"
				[api]="aiSwitchShowcaseApi"
				[(ngModel)]="aiSwitchShowcaseApi.data.allTypes.string"
			/>
		</p-bootstrap-form-group>
	</p-tab>
	<p-tab
		label="PTabsComponent"
		urlName="p-tabs-component"
	>
		<div class="p-tabs-wrapper">
			<p-section
				label="Clean themed tabs with overflow"
				pAnchorLink="clean-themed-overflow"
			>
				<p-tabs
					[theme]="PTabsTheme.CLEAN"
				>
					<p-tab label="First tab"></p-tab>
					<p-tab label="Second tab"></p-tab>
					<p-tab label="Third tab"></p-tab>
					<p-tab label="Fourth tab"></p-tab>
					<p-tab label="Fifth tab"></p-tab>
					<p-tab label="Sixth tab"></p-tab>
					<p-tab label="Seventh tab"></p-tab>
					<p-tab label="Eighth tab"></p-tab>
					<p-tab label="Ninth tab"></p-tab>
					<p-tab label="Tenth tab"></p-tab>
				</p-tabs>
			</p-section>
			<p-section
				label="Default themed tabs with overflow"
				pAnchorLink="clean-themed-overflow"
			>
				<p-tabs
					[theme]="PTabsTheme.DEFAULT"
				>
					<p-tab label="First default tab"></p-tab>
					<p-tab label="Second default tab"></p-tab>
					<p-tab label="Third default tab"></p-tab>
					<p-tab label="Fourth default tab"></p-tab>
					<p-tab label="Fifth default tab"></p-tab>
					<p-tab label="Sixth default tab"></p-tab>
					<p-tab label="Seventh default tab"></p-tab>
					<p-tab label="Eighth default tab"></p-tab>
					<p-tab label="Ninth default tab"></p-tab>
					<p-tab label="Tenth default tab"></p-tab>
				</p-tabs>
			</p-section>
		</div>
	</p-tab>
</p-tabs>

