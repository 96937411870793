import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { DetailFormComponentInterface } from '@plano/client/shared/detail-form-component.interface';
import { FormattedDateTimePipe } from '@plano/client/shared/formatted-date-time.pipe';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW } from '@plano/client/shift/shift-modal-sizes';
import { RightsService, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'detail-form[item][writableShiftModelsForMember]',
	templateUrl: './detail-form.component.html',
	styleUrls: ['./detail-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class DetailFormComponent implements PComponentInterface, AfterContentInit, DetailFormComponentInterface<SchedulingApiShift> {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;
	@Input() public item : SchedulingApiShift | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public writableShiftModelsForMember ! : SchedulingApiShiftModels;

	/**
	 * Emit to the outside of this component the new item to be added
	 */
	@Output() public onAddItem = new EventEmitter<SchedulingApiShift>();

	constructor(
		private activatedRoute : ActivatedRoute,
		public api : SchedulingApiService,
		private pRouterService : PRouterService,
		private modalService : ModalService,
		public rightsService : RightsService,
		public formattedDateTimePipe : FormattedDateTimePipe,
		private localize : LocalizePipe,
		public schedulingService : SchedulingService,
	) {
	}

	public readonly CONFIG = Config;

	public enums = enumsObject;
	public formGroup : DetailFormComponentInterface['formGroup'] = null;

	public routeShiftModelId : Id | null = null;

	/**
	 * Check if url has shiftModelId
	 */
	public getRouteShiftModelId() : void {
		const idAsString = this.activatedRoute.snapshot.paramMap.get('shiftmodelid');
		this.routeShiftModelId = idAsString === null ? null : Id.create(+idAsString);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public headlineDate() : string | null {
		assumeNonNull(this.item);
		return this.formattedDateTimePipe.getFormattedDateInfo(this.item.start, this.item.end, true).full;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get headlineTime() : string | null {
		assumeNonNull(this.item);
		if (this.item.attributeInfoTime.isAvailable && this.item.time.attributeInfoStart.isAvailable && this.item.time.attributeInfoStart.value) {
			return PMomentService.d(this.item.time.attributeInfoStart.value).format('HH:mm');
		} else return '-';
	}

	public ngAfterContentInit() : void {
		this.initComponent();
	}

	/**
	 * Load and set everything that is necessary for this component
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public initComponent() : void {
	}

	/**
	 * Initialize the formGroup for this component
	 */
	public initFormGroup() : void {
		if (this.formGroup) { this.formGroup = null; }

	}

	/**
	 * Remove Item of this Detail page
	 */
	public async removeItem(deleteWarning : TemplateRef<unknown>) : ModalRef['result'] {
		const promise = this.modalService.openModal(deleteWarning, {
			size: SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW,
		}).result;
		const promiseResult = await promise;
		if (promiseResult.modalResult === 'success') {
			this.formGroup = null;
			assumeNonNull(this.item);
			this.api.data.shifts.removeItem(this.item);

			void this.api.save({
				success : () : void => {
					this.pRouterService.navBack();
				},
			});
		}
		return promise;
	}

	/**
	 * Save this item
	 */
	public saveItem() : void {
		assumeNonNull(this.item);
		if (!this.item.isNewItem()) return;
		this.onAddItem.emit(this.item);

		// PLANO-170639 remove next line
		this.pRouterService.navBack();
	}

	/** navBack */
	public navBack() : void {
		this.pRouterService.navBack();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get courseCode() : string | undefined {
		assumeNonNull(this.item);
		if (this.item.id.isNewItemId) return undefined;
		return this.item.id.courseCode ?? undefined;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isCourse() : boolean {
		assumeNonNull(this.item);
		return this.item.model.isCourse;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftName() : string {

		if (this.item?.name) {
			if (!this.item.rawData) throw new Error('Can not get shift name. Shift is lost [PLANO-FE-2TT]');
			return this.item.name;
		}
		return this.localize.transform('Neue Schicht');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftColor() : string | null {

		if (!this.item) return null;
		if (!this.item.model.rawData) throw new Error('[PLANO-FE-JV]');
		if (!this.item.model.color) return null;
		return `#${this.item.model.color}`;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftModel() : SchedulingApiShiftModel | undefined {

		if (!this.item) return undefined;
		return this.item.model;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftStart() : number | undefined {

		if (!this.item) return undefined;
		if (!this.item.rawData) return undefined;
		if (!this.item.start) return undefined;
		return this.item.start;
	}
}
