import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { CourseFilterService } from '@plano/client/scheduling/course-filter.service';
import { SchedulingFilterService } from '@plano/client/scheduling/scheduling-filter.service';
import { BirthdayService } from '@plano/client/scheduling/shared/api/birthday.service';
import { SchedulingApiBirthdays } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { CalenderAllDayItemLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-all-day-item-layout.service';
import { PCalendarShiftStyle } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-shift-item-module/shift-item/shift-item-styles';
import { FilterService } from '@plano/client/shared/filter.service';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PShiftPickerService } from '@plano/client/shared/p-shift-picker/p-shift-picker.service';
import { SchedulingApiAbsences, SchedulingApiBooking, SchedulingApiHolidays, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-shift-picker-calendar[loadDetailedItem][availableShifts]',
	templateUrl: './shift-picker-calendar.component.html',
	styleUrls: ['./shift-picker-calendar.component.scss'],
	providers: [
		SchedulingFilterService,
		CourseFilterService,
		FilterService,
		CalenderAllDayItemLayoutService,
	],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftPickerCalendarComponent implements OnInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public availableShifts ! : SchedulingApiShifts;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public loadDetailedItem ! : SchedulingApiBooking | SchedulingApiShiftExchange | SchedulingApiShift;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	constructor(
		public pShiftPickerService : PShiftPickerService,
		private highlightService : HighlightService,
		public api : SchedulingApiService,
		public birthdayService : BirthdayService,
		private schedulingFilterService : SchedulingFilterService,
	) {
	}

	public readonly CONFIG = Config;

	public enums = enumsObject;
	public PCalendarShiftStyle = PCalendarShiftStyle;
	public CalendarModes = CalendarModes;

	/**
	 * Get the absences that should be available to the calendar component
	 */
	public get absences() : SchedulingApiAbsences {
		if (this.schedulingFilterService.hideAllAbsences) return new SchedulingApiAbsences(null, null);
		if (!this.api.isLoaded()) return new SchedulingApiAbsences(null, null);

		// TODO: PLANO-156519
		if (!this.api.data.attributeInfoAbsences.isAvailable) return new SchedulingApiAbsences(null, null);
		return this.api.data.absences;
	}

	public ngOnInit() : void {
		this.schedulingFilterService.cookiePrefix = 'PShiftExchanges';
		this.schedulingFilterService.readCookies();
	}

	/**
	 * Get the holidays that should be available to the calendar component
	 */
	public get holidays() : SchedulingApiHolidays {
		if (!this.api.isLoaded()) return new SchedulingApiHolidays(null, null);

		// TODO: PLANO-156519
		if (!this.api.data.attributeInfoHolidays.isAvailable) return new SchedulingApiHolidays(null, null);
		return this.api.data.holidays;
	}

	/**
	 * Get the birthdays that should be available to the calendar component
	 */
	public get birthdays() : SchedulingApiBirthdays {
		if (!this.api.isLoaded()) return new SchedulingApiBirthdays(null, null, null, false);
		return this.birthdayService.birthdays;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public async setSelectedDateAndLoadData(value : number) : Promise<void> {
		this.pShiftPickerService.date = value;
		return this.loadNewData();
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public async setCalendarModeAndLoadData(value : CalendarModes) : Promise<void> {
		this.pShiftPickerService.mode = value;
		return this.loadNewData();
	}

	/**
	 * Load new Data
	 */
	public async loadNewData() : Promise<void> {
		this.highlightService.clear();

		this.pShiftPickerService.updateQueryParams();
		if (this.loadDetailedItem.isNewItem()) {
			await this.api.load({
				searchParams: this.pShiftPickerService.queryParams,
			});
		} else {
			await this.loadDetailedItem.loadDetailed({
				searchParams: this.pShiftPickerService.queryParams,
			});
		}
	}
}
