/* eslint-disable import/no-relative-parent-imports -- Remove this before you work here. */
import { NgModule } from '@angular/core';
import { CoreModule } from '@plano/shared/core/core.module';
import { PFormsModule } from '../../../shared/p-forms/p-forms.module';
import { PEditableModule } from '../p-editable/p-editable.module';
import {
	PEditableModalBoxComponent,
	PEditableModalBoxHeaderComponent, PEditableModalBoxShowroomComponent,
} from './p-editable-modal-box/p-editable-modal-box.component';

@NgModule({
	imports: [
		CoreModule,
		PEditableModule,
		PFormsModule,
	],
	declarations: [
		PEditableModalBoxComponent,
		PEditableModalBoxHeaderComponent, PEditableModalBoxShowroomComponent,
	],
	exports: [
		PEditableModalBoxComponent,
		PEditableModalBoxHeaderComponent, PEditableModalBoxShowroomComponent,
	],
})
export class PEditableFormsModule {}
