import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimeStampApiService, TimeStampApiStampedMembers } from '@plano/shared/api';

@Component({
	selector: 'p-stamped-members-card',
	templateUrl: './stamped-members-card.component.html',
	styleUrls: ['./stamped-members-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class StampedMembersCardComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public members ! : TimeStampApiStampedMembers;
	constructor(public api : TimeStampApiService) {

	}

}
