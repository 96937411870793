import { DataInputBase } from './data-input-base';

/**
 * This class represents a {@code Data} dependencies which is just defines as an primitive. So, whenever the primitive changes a recalculation should be triggered.
 * Thus, {@link dataVersion()} will just return the value of the primitive.
 */
export class PrimitiveDataInput<T> extends DataInputBase {
	constructor(private calculateFn : () => T) {
		super();
	}

	public override get dataVersion() : T {
		return this.calculateFn();
	}
}
