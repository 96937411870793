import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PShiftAndShiftmodelFormService } from '@plano/client/shared/component/p-shift-and-shiftmodel-form/p-shift-and-shiftmodel-form.service';
import { SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-weekdays[repetition][group]',
	templateUrl: './weekdays.component.html',
	styleUrls: ['./weekdays.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class WeekdaysComponent {
	@Input() public disabled : boolean = false;

	/** The headline */
	@Input() public label : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public repetition ! : (
		SchedulingApiShift['repetition'] |
		SchedulingApiShift['repetition']['packetRepetition'] |
		SchedulingApiShiftModel['repetition'] |
		SchedulingApiShiftModel['repetition']['packetRepetition']
	);

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public group ! : FormGroup;

	constructor(
		public pShiftAndShiftmodelFormService : PShiftAndShiftmodelFormService,
		private localize : LocalizePipe,
	) {
		if (this.label === null) this.label = this.localize.transform('An jedem');
	}

	public Config = Config;
	public enums = enumsObject;
}
