<p-subheader
	[isNewItem]="!!item && item.behavesAsNewItem"
	[routeId]="routeId"
	(onNavBack)="dismissReOpen()">

	<ng-container *ngIf="!!item && !!item.rawData">
		<div hidden><span i18n #translatedDeleteSectionLabel>{item.isIllness && !item.isBasedOnIllness, select, true {Krankmeldung} other {Ersatzsuche}} zurückziehen</span></div>
		<p-dropdown
			*ngIf="item.attributeInfoCloseShiftExchange.isAvailable"
			[dropdownType]="DropdownTypeEnum.ACTIONS"
			label="Aktionen" i18n-label
			[icon]="enums.PlanoFaIconPool.MORE_ACTIONS"
			[size]="enums.BootstrapSize.SM"
			[showActiveItem]="false"
		>
			<p-dropdown-item
				(onClick)="onRemoveClick()"
				[disabled]="false"
				[label]="translatedDeleteSectionLabel.textContent!"
				[prependedItem]="enums.PlanoFaIconPool.DELETE"
				[theme]="PBtnThemeEnum.OUTLINE_DANGER"
				></p-dropdown-item>
		</p-dropdown>
	</ng-container>

</p-subheader>
<div class="flex-grow-1 d-flex position-relative">
	<scroll-shadow-box class="flex-grow-1">
		<div class="container pb-tawk">
			<h2 *ngIf="!!item && item.behavesAsNewItem">
				<span i18n>{!!item && item.isNewItem(), select, true {Neue }}{item && item.isIllness && !item.isBasedOnIllness, select, true {Krankmeldung} other {Ersatzsuche}} per Tauschbörse</span>
				<span *ngIf="isExpired" class="text-danger"> <ng-container i18n>verstrichen</ng-container></span></h2>
			<ng-template [ngIf]="!isLoaded || item === undefined" [ngIfElse]="loadedTemplate">
				<p-spinner [size]="enums.BootstrapSize.LG" class="m-5 h-100"></p-spinner>
			</ng-template>
			<ng-template #loadedTemplate>
				<p-detail-form
					*ngIf="!requestedItemCouldNotBeFound; else noItemAvailable"
					[item]="item ?? null"
					(onAddItem)="saveNewItem($event)"
					(onClickReopenFormBtn)="reOpenForm()"
				></p-detail-form>
				<ng-template #noItemAvailable>
					<p-no-item></p-no-item>
				</ng-template>
			</ng-template>
		</div>
	</scroll-shadow-box>
</div>
