<p-input
	*ngIf="!!formGroup"
	[type]="PApiPrimitiveTypes.ClientCurrency"
	[inputGroupAppendText]="null"
	[(ngModel)]="earnings"
	[formControl]="formGroup.controls['hourlyEarnings']!"
	title="Stundenlohn von {{member.firstName}} {{member.lastName}}" i18n-title
	style="max-width: 18ch;"
	placeholder="–"
></p-input>
