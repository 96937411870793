import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardDirective } from './card.directive';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		CardDirective,
	],
	providers: [],
	exports: [
		CardDirective,
	],
})
export class PCardModule {}
