import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { SchedulingApiBirthdays } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { CalendarAllDayItemType, CalenderAllDayItemLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-all-day-item-layout.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { SchedulingApiAbsences, SchedulingApiHolidays, SchedulingApiService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-all-day-items-list[startOfDay][calendarMode]',
	templateUrl: './p-all-day-items-list.component.html',
	styleUrls: ['./p-all-day-items-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAllDayItemsListComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public holidays : SchedulingApiHolidays = new SchedulingApiHolidays(null, null);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absences : SchedulingApiAbsences = new SchedulingApiAbsences(null, null);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public birthdays : SchedulingApiBirthdays = new SchedulingApiBirthdays(null, null, null, false);

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public popperPlacement : NgxPopperjsPlacements = NgxPopperjsPlacements.BOTTOM;

	/**
	 * The calendar mode in which the all day items will be displayed
	 */
	@Input() public calendarMode ! : CalendarModes;

	/**
	 * Height of one line
	 * @return height in px
	 */
	@Input() public heightOfLine : number = 24;

	/**
	 * Day as timestamp
	 */
	@Input() public startOfDay ! : number;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	constructor(
		public layoutService : CalenderAllDayItemLayoutService,
		private filterService : FilterService,
		public api : SchedulingApiService,
	) {
	}

	public config : typeof Config = Config;

	/**
	 * Height of this list in px
	 * @return height in px
	 */
	public get height() : number {
		return (this.layoutService.getMaxPosIndex(this.startOfDay) + 1) * this.heightOfLine;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public distanceAboveItem(item : CalendarAllDayItemType) : number {
		return this.layoutService.getLayout(this.startOfDay, item).posIndex * this.heightOfLine;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public showItem(item : CalendarAllDayItemType) : boolean {
		return this.layoutService.getLayout(this.startOfDay, item).show;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showList() : boolean {
		if (
			this.filterService.schedulingFilterService.hideAllAbsences &&
			this.filterService.schedulingFilterService.hideAllHolidays &&
			this.filterService.schedulingFilterService.hideAllBirthdays
		) return false;
		if (!this.absences.length && !this.holidays.length && !this.birthdays.length) return false;

		return true;
	}
}
