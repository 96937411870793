import { AccountApiRootBase } from '@plano/shared/api';
import { ApiDataCopyAttribute } from '@plano/shared/api/base/api-data-copy-attribute/api-data-copy-attribute';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AccountApiRoot extends AccountApiRootBase {

	/**
	 * Are we currently transforming an test account into a paid account. In current implementation this is the case
	 * when the <testaccount> component ist open.
	 */
	public transformingToPaidAccount = new ApiDataCopyAttribute<boolean>(false);
}
