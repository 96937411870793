import { Pipe, PipeTransform } from '@angular/core';
import { AccountApiCountry, ClientsApiCountry, RegisterTestAccountApiCountry } from '@plano/shared/api';
import { PSupportedCountryCodes, PSupportedLanguageCodes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from './localize.pipe';

@Pipe({ name: 'pTranslateCountryKey' })

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class TranslatePipe implements PipeTransform {
	constructor(
		private console : LogService,
		private localize : LocalizePipe,
	) {
	}

	public transform(value : string | AccountApiCountry | PSupportedLanguageCodes | RegisterTestAccountApiCountry | ClientsApiCountry) : string;
	public transform(value : string | AccountApiCountry | PSupportedLanguageCodes | RegisterTestAccountApiCountry | ClientsApiCountry | null) : string | null;

	// TODO: Get rid of the type 'string' in 'value : string | AccountApiCountry'
	// eslint-disable-next-line jsdoc/require-jsdoc
	public transform(value : string | AccountApiCountry | PSupportedLanguageCodes | RegisterTestAccountApiCountry | ClientsApiCountry | null) : string | null {
		if (value === null) return null;
		switch (value) {
			case 'GERMANY' :
			case AccountApiCountry.GERMANY :
			case PSupportedCountryCodes.DE :
				return this.localize.transform('Deutschland');

			case 'SWITZERLAND' :
			case AccountApiCountry.SWITZERLAND :
			case PSupportedCountryCodes.CH :
				return this.localize.transform('Schweiz');

			case 'AUSTRIA' :
			case AccountApiCountry.AUSTRIA :
			case PSupportedCountryCodes.AT :
				return this.localize.transform('Österreich');

			case 'ITALY' :
			case AccountApiCountry.ITALY :
			case PSupportedCountryCodes.IT :
				return this.localize.transform('Italien');

			case 'ROMANIA' :
			case AccountApiCountry.ROMANIA :
			case PSupportedCountryCodes.RO :
				return this.localize.transform('Rumänien');

			case 'NETHERLANDS' :
			case AccountApiCountry.NETHERLANDS :
			case PSupportedCountryCodes.NL :
				return this.localize.transform('Niederlande');

			case 'BELGIUM' :
			case AccountApiCountry.BELGIUM :
			case PSupportedCountryCodes.BE :
				return this.localize.transform('Belgien');

			case 'UNITED_KINGDOM' :
			case AccountApiCountry.UNITED_KINGDOM :
			case PSupportedCountryCodes.GB :
				return this.localize.transform('Vereinigtes Königreich');

			case 'CZECH_REPUBLIC' :
			case AccountApiCountry.CZECH_REPUBLIC :
			case PSupportedCountryCodes.CZ :
				return this.localize.transform('Tschechien');

			case 'SWEDEN' :
			case AccountApiCountry.SWEDEN :
			case PSupportedCountryCodes.SE :
				return this.localize.transform('Schweden');

			case 'LUXEMBOURG' :
			case AccountApiCountry.LUXEMBOURG :
			case PSupportedCountryCodes.LU :
				return this.localize.transform('Luxemburg');

			case PSupportedLanguageCodes.de :
				return this.localize.transform('Deutsch');

			case PSupportedLanguageCodes.en :
				return this.localize.transform('Englisch');

			default :
				if (Config.DEBUG) this.console.error(`Could not find translation for country key: "${value}"`);
				if (typeof value === 'string') return value;
				return null;

		}
	}
}
