import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { PSidebarService } from '@plano/client/shared/p-sidebar/p-sidebar.service';
import { Config } from '@plano/shared/core/config';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-sidebar',
	templateUrl: './p-sidebar.component.html',
	styleUrls: ['./p-sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PSidebarComponent {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showStickyNoteIcon : boolean | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showStickyNoteIconDot : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showFilterIcon : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public badgeContent : number | null = null;

	/**
	 * Should the collapse button be visible?
	 */
	@Input() public showCollapseButton : boolean = true;

	@HostBinding('class.d-flex') protected _alwaysTrue = true;

	@HostBinding('class.p-0')
	@HostBinding('class.btn-frameless')
	@HostBinding('class.btn-dark')
	@HostBinding('class.border-right')
	@HostBinding('class.btn')
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	@HostBinding('class.collapsed') public get collapsed() : boolean {
		return !!this.pSidebarService.mainSidebarIsCollapsed;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	@HostBinding('class.desktop-mode') public get hideIfCollapsed() : boolean {
		return !Config.IS_MOBILE && this.showCollapseButton;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	@HostBinding('class.mobile-mode') public get mobileMode() : boolean {
		return Config.IS_MOBILE || !this.showCollapseButton;
	}

	@HostBinding('class.uncollapsed') private get _classUncollapsed() : boolean { return !this.collapsed; }

	@HostListener('click') private _onClick() : void {
		if (!this.collapsed) return;
		this.toggleCollapsed();
	}

	constructor(
		private pSidebarService : PSidebarService,
		private localize : LocalizePipe,
	) {
	}

	public config : typeof Config = Config;

	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public toggleCollapsed(event ?: Event) : void {
		if (event) event.stopPropagation();
		this.pSidebarService.mainSidebarIsCollapsed = !this.pSidebarService.mainSidebarIsCollapsed;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showFilterIconPopover() : string {
		if (!this.showFilterIcon) return this.localize.transform('Filter aus');
		return this.localize.transform('Filter sind aktiv');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get badgeContentPopover() : string {
		return this.localize.transform({ sourceString: '${amount} To-dos vorhanden', params: { amount : this.badgeContent!.toString() }});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showStickyNoteIconPopover() : string {
		if (!this.showStickyNoteIcon) return this.localize.transform('Kein Kommentar für dich vorhanden');
		if (this.showStickyNoteIconDot) return this.localize.transform('Kommentare für dich und deine Schichten vorhanden');
		return this.localize.transform('Kommentare für dich vorhanden');
	}
}
