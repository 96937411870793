<p-editable-modal-box
	*ngIf="shiftModel"
	class="mb-4"
	[pEditable]="!shiftModel.isNewItem()"
	[api]="api"
	[valid]="!someChildrenAreInvalid"
	(onModalOpen)="initFormGroup.emit()"
	(onSaveSuccess)="initFormGroup.emit()"
	[saveChangesHook]="saveChangesHook"

	[modalWhitespace]="SectionWhitespace.NONE"

	[disabled]="!userCanWrite"

	size="lg"
>
	<p-editable-modal-box-header><label class="m-0 w-100 justify-content-between" [style.padding]="editableModalBoxHeaderPadding"><ng-container i18n>Stornogebühren & Widerruf & Online-Storno</ng-container><a *ngIf="userCanWrite" routerLink="/client/tutorials" fragment="cancellation-and-refund" rel="noopener" target="_blank" [pIcon]="enums.PlanoFaIconPool.AREA_TUTORIALS"><ng-container i18n>Tutorial ansehen</ng-container></a></label></p-editable-modal-box-header>
	<!-- (onSaveStart)="onSaveStart.emit()" -->
	<p-editable-modal-box-showroom>
		<p-bootstrap-form-group label="Stornogebühren" i18n-label>
			<div class="d-flex flex-column-reverse" *ngIf="shiftModel.currentCancellationPolicy!.feePeriods.length">
				<ng-container *ngFor="let feePeriod of shiftModel.currentCancellationPolicy!.feePeriods.iterable()">
					<div class="card mb-1">
						<div class="card-body d-flex flex-column flex-lg-row justify-content-lg-between text-wrap py-2 gap-1" *ngIf="!!feePeriod.rawData">
							<label class="m-0 mb-2 mb-sm-0"><ng-container *ngTemplateOutlet="feePeriodTitleTemplate; context: {$implicit: feePeriod}"></ng-container></label>
							<ng-container *ngTemplateOutlet="feePeriodPriceTemplate; context: {$implicit: feePeriod}"></ng-container>
						</div>
					</div>
				</ng-container>
			</div>
			<p-alert
				*ngIf="!shiftModel.currentCancellationPolicy?.feePeriods!.length"
				[theme]="PAlertThemeEnum.PLAIN"
				[dismissable]="false"
				class="text-muted"
				i18n
			>Keine Stornogebühren definiert</p-alert>
		</p-bootstrap-form-group>

		<p-bootstrap-form-group
			label="Widerrufsfrist" i18n-label
		>
			<div class="list-group">
				<div class="list-group-item">
					<p class="m-0" *ngIf="shiftModel.currentCancellationPolicy!.attributeInfoWithdrawalPeriod.isAvailable && (shiftModel.currentCancellationPolicy?.attributeInfoWithdrawalEnabled?.isAvailable && shiftModel.currentCancellationPolicy?.withdrawalEnabled)" i18n>Widerruf gestattet innerhalb von <code>{{ shiftModel.currentCancellationPolicy!.withdrawalPeriod }}</code> Tagen nach dem Buchungstag.</p>
					<p class="m-0" *ngIf="!(shiftModel.currentCancellationPolicy?.attributeInfoWithdrawalEnabled?.isAvailable && shiftModel.currentCancellationPolicy?.withdrawalEnabled)" i18n>Die Widerrufsfrist ist <code>deaktiviert</code>.</p>
				</div>
			</div>
		</p-bootstrap-form-group>
		<p-bootstrap-form-group
			label="Online-Storno durch Kunden" i18n-label
		>
			<div class="list-group">
				<div class="list-group-item">

					<div *ngIf="shiftModel.attributeInfoOnlineCancellationForFreeBookingsDeadline.isAvailable">
						<p class="m-0 mb-lg-0 mb-2" *ngIf="onlineCancellationForFreeBookingsEnabled" i18n>Kostenlose Buchungen dürfen von Kunden <code *ngIf="shiftModel.onlineCancellationForFreeBookingsDeadline === null">bis zum Angebotsbeginn</code><code *ngIf="shiftModel.onlineCancellationForFreeBookingsDeadline !== null"><ng-container *ngIf="shiftModel.onlineCancellationForFreeBookingsDeadline === null">bis zum Angebotstag, aber nicht mehr am Angebotstag selbst</ng-container><ng-container *ngIf="shiftModel.onlineCancellationForFreeBookingsDeadline !== 0">bis {{shiftModel.onlineCancellationForFreeBookingsDeadline ? shiftModel.onlineCancellationForFreeBookingsDeadline / 1000 / 60 / 60 / 24 : 0}} {shiftModel.onlineCancellationForFreeBookingsDeadline ? shiftModel.onlineCancellationForFreeBookingsDeadline / 1000 / 60 / 60 / 24 : 0, plural, 1 {Tag} other {Tage}} vor dem Angebotstag</ng-container></code> online storniert werden.</p>
						<p class="m-0 mb-lg-0 mb-2" *ngIf="!onlineCancellationForFreeBookingsEnabled" i18n>Kostenlose Buchungen dürfen <code>nicht</code> online storniert werden.</p>
					</div>
					<div *ngIf="shiftModel.attributeInfoOnlineCancellationForChargeableBookingsEnabled.isAvailable && shiftModel.attributeInfoOnlineCancellationForChargeableBookingsDeadline.isAvailable">
						<p class="m-0 mb-lg-0 mb-2" *ngIf="shiftModel.onlineCancellationForChargeableBookingsEnabled" i18n>Kostenpflichtige Buchungen dürfen von Kunden <code *ngIf="shiftModel.onlineCancellationForChargeableBookingsDeadline === null">bis zum Angebotsbeginn</code><code *ngIf="shiftModel.onlineCancellationForChargeableBookingsDeadline !== null"><ng-container *ngIf="shiftModel.onlineCancellationForChargeableBookingsDeadline === null">bis zum Angebotstag, aber nicht mehr am Angebotstag selbst</ng-container><ng-container *ngIf="shiftModel.onlineCancellationForChargeableBookingsDeadline !== 0">bis {{shiftModel.onlineCancellationForChargeableBookingsDeadline ? shiftModel.onlineCancellationForChargeableBookingsDeadline / 1000 / 60 / 60 / 24 : 0}} {shiftModel.onlineCancellationForChargeableBookingsDeadline ? shiftModel.onlineCancellationForChargeableBookingsDeadline / 1000 / 60 / 60 / 24 : 0, plural, 1 {Tag} other {Tage}} vor dem Angebotstag</ng-container></code> online storniert werden.</p>
						<p class="m-0 mb-lg-0 mb-2" *ngIf="!shiftModel.onlineCancellationForChargeableBookingsEnabled" i18n>Kostenpflichtige Buchungen dürfen <code>nicht</code> online storniert werden.</p>
					</div>

					<ng-container *ngIf="shiftModel.attributeInfoOnlineCancellationForWithdrawableBookingsAlwaysEnabled.isAvailable">
						<div i18n *ngIf="shiftModel.onlineCancellationForWithdrawableBookingsAlwaysEnabled"><p class="m-0 mb-lg-0 mb-2">Innerhalb der Widerrufsfrist ist Online-Stornierung immer <code>erlaubt</code>.</p></div>
						<div i18n><p class="m-0 mb-lg-0 mb-2">Die automatische Rückzahlung nach Kunden-Stornos ist <code>{shiftModel.onlineCancellationAutomaticOnlineRefundEnabled, select, true {aktiviert} other {deaktiviert}}</code>. Die automatische Gutschein-Ausstellung für Rückzahlungen ist <code>{shiftModel.onlineCancellationAutomaticGiftCardRefundEnabled, select, true {aktiviert} other {deaktiviert}}</code>.</p></div>
					</ng-container>
			</div>
			</div>
		</p-bootstrap-form-group>
	</p-editable-modal-box-showroom>
	<p-editable-modal-form [class.pt-0]="Config.IS_MOBILE" *ngIf="formGroup">

		<p-tabs
			[theme]="PTabsTheme.CLEAN"
			[pageSubNav]="true"
		>
			<p-tab
				*ngIf="formGroup.controls['feePeriods']"
				label="Stornogebühren" i18n-label
				[size]="PTabSizeEnum.FRAMELESS"
				[hasDanger]="!!formGroup.controls['feePeriods'].invalid"
			>
				<p-section>
					<p i18n>Hier kannst du deine Stornierungsgebühren hinterlegen, die bei jeder Buchung Teil des Vertrags mit deinen Kunden sind.</p>
					<ul class="list custom-marker pb-3 pl-3 mb-0">
						<li><ng-container i18n>Mehr Transparenz für deine Kunden, denn die hinterlegten Stornierungsgebühren werden bei der Online-Buchung angezeigt.</ng-container></li>
						<li><ng-container i18n>Bei manuellen Stornierungen brauchst du die Gebühren nicht mehr selbst auszurechnen.</ng-container></li>
						<li><ng-container i18n>Wenn du die Stornobedingungen hinterlegt hast, kannst du deinen Kunden erlauben, kostenpflichtige Buchungen selbst online zu stornieren.</ng-container></li>
						<li><ng-container i18n>Bei kostenlosen Buchungen werden automatisch keine Stornierungsgebühren angerechnet.</ng-container></li>
						<li><ng-container i18n>Alle Einstellungen einfach erklärt in unserem passenden <a routerLink="/client/tutorials" target="_blank" rel="noopener" [pIcon]="enums.PlanoFaIconPool.AREA_TUTORIALS">Tutorial-Video</a>.</ng-container></li>
					</ul>
					<p-alert [theme]="enums.PThemeEnum.INFO" class="small mt-4 mb-5" i18n>Änderungen der Stornogebühren wirken sich <strong>nur auf neue Buchungen</strong> aus. Bereits vorhandene Buchungen bleiben unangetastet.</p-alert>
					<div class="d-flex flex-column-reverse">

						<ng-template
							ngFor let-feePeriodFormGroup
							[ngForOf]="formGroup.controls['feePeriods']!.controls"
							let-i="index"
						>
							<ng-container *ngVar="feePeriodFormGroup.controls['feePeriodRef'].value! as feePeriod">
								<ng-container *ngIf="!!feePeriod.rawData; else spinnerTemplate">
									<p-collapsible
										class="ignore-last-child-style"
										[hasDanger]="feePeriodFormGroup.invalid"
										[collapsed]="!feePeriod.isNewItem()"
									>
										<div trigger class="flex-grow-1 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between text-wrap crop-on-overflow">
											<h6 class="mb-0 mt-0 mr-3"><ng-container *ngTemplateOutlet="feePeriodTitleTemplate; context: {$implicit: feePeriod}"></ng-container></h6>
											<small class="crop-on-overflow mt-2 mt-sm-0"><ng-container *ngTemplateOutlet="feePeriodPriceTemplate; context: {$implicit: feePeriod}"></ng-container></small>
										</div>
										<div content class="m-3">
											<p-grid class="mb-lg-0 mb-5" >
												<div class="col-lg flex-grow-1">
													<p-bootstrap-form-group
														label="Start der Zeitspanne" i18n-label
														description="<p>Um eine Stornogebühr für Stornos <strong>am Angebotstag selbst</strong> zu definieren, trage hier »0 Tage vor dem Angebotstag« ein.</p>Dementsprechend bezieht sich die Einstellung »2 Tage vor dem Angebotstag« bei einem Angebot, das beispielsweise an einem 3. Juli stattfinden soll, auf den 1. Juli." i18n-description
													>
														<p-ai-switch
															*ngIf="i === 0; else nonNullableTemplate"
															[inputGroupAppendIcon]="null"
															[group]="$any(feePeriodFormGroup)"
															[attributeInfo]="feePeriod.attributeInfoStart"
															[durationUIType]="PApiPrimitiveTypes.Days"
															[options]="startInputDurationOptions"
														></p-ai-switch>
														<ng-template #nonNullableTemplate>
															<p-ai-switch
																[inputGroupAppendIcon]="null"
																[group]="$any(feePeriodFormGroup)"
																[attributeInfo]="feePeriod.attributeInfoStart"
																inputGroupAppendText="Tage vor dem Angebotstag" i18n-inputGroupAppendText
															></p-ai-switch>
														</ng-template>
													</p-bootstrap-form-group>
												</div>
												<div class="col-lg flex-grow-0 d-flex align-items-center justify-content-center">
													<p-bootstrap-form-group minimumLabelDisplayBootstrapSize="lg" label="&nbsp;">
														<div class="d-flex align-items-center justify-content-center no-overflow-wrap" i18n>bis</div>
													</p-bootstrap-form-group>
												</div>
												<div class="col-lg flex-grow-1">
													<p-bootstrap-form-group
														*ngIf="i === (formGroup.controls['feePeriods']!.controls.length - 1); else realEndSwitch"
														label="Ende der Zeitspanne" i18n-label
													>
														<p-input
															[api]="null!"
															label="Ende der Zeitspanne" i18n-label
															[inputGroupAppendIcon]="null"
															[forcePrependIconOnPrepend]="true"
															[ngModel]="'Angebotsbeginn & danach' | localize"
															[attributeInfo]="feePeriod.attributeInfoEnd"
															[disabled]="true"
															[readMode]="false"
														></p-input>
													</p-bootstrap-form-group>

													<ng-template #realEndSwitch>
														<p-ai-switch
															label="Ende der Zeitspanne" i18n-label
															[inputGroupAppendIcon]="null"
															[forcePrependIconOnPrepend]="true"
															inputGroupAppendText="Tage vor dem Angebotstag" i18n-inputGroupAppendText
															[group]="$any(feePeriodFormGroup)"
															[attributeInfo]="feePeriod.attributeInfoEnd"
															[placeholder]="!feePeriodFormGroup.valid || !formGroup.valid ? '?' : (feePeriod!.end === null ? ('Angebotsbeginn & danach' | localize) : '')"
														></p-ai-switch>
													</ng-template>
												</div>
											</p-grid>
											<p-bootstrap-form-group label="Stornogebühr in dieser Zeitspanne" i18n-label>
												<p-grid>
													<div class="col-md flex-grow-1">
														<p-ai-switch
															[group]="$any(feePeriodFormGroup)"
															[attributeInfo]="feePeriod.attributeInfoFeeFix"
															inputGroupAppendText="Fixe Gebühr" i18n-inputGroupAppendText
														></p-ai-switch>
													</div>
													<div class="col-md flex-grow-0 d-flex align-items-center justify-content-center pb-3 no-overflow-wrap" i18n>und</div>
													<div class="col-md flex-grow-1">
														<p-ai-switch
															[group]="$any(feePeriodFormGroup)"
															[attributeInfo]="feePeriod.attributeInfoFeePercentage"
															[inputGroupAppendIcon]="enums.PlanoFaIconPool.PERCENTAGE"
															inputGroupAppendText="vom Buchungsbetrag" i18n-inputGroupAppendText
														></p-ai-switch>
													</div>
												</p-grid>
											</p-bootstrap-form-group>
											<div class="mt-4">
												<h4 i18n>Löschen</h4>
												<div class="d-flex align-items-center flex-column flex-sm-row gap-2">
													<div class="flex-grow-1" i18n>Willst du diesen Zeitraum und die dazugehörigen Gebühren entfernen?</div>
													<div class="flex-grow-0">
														<p-button
															(triggerClick)="onRemoveFeePeriodClick($any(feePeriodFormGroup))"
															[theme]="enums.PThemeEnum.DANGER"
															class="no-overflow-wrap"
															[icon]="enums.PlanoFaIconPool.DELETE"
															i18n
														>Löschen</p-button>
													</div>
												</div>
											</div>
										</div>
									</p-collapsible>
								</ng-container>
								<p-button
									class="mb-3"
									*ngIf="!!feePeriod.rawData && i!==(formGroup.controls['feePeriods']!.controls.length - 1)"
									[icon]="enums.PlanoFaIconPool.ADD"
									[theme]="PBtnThemeEnum.OUTLINE_PRIMARY"
									(triggerClick)="onAddFeePeriodClick(i + 1)"
								><ng-container i18n>Zeitspanne & Gebühr hinzufügen</ng-container>&ngsp;<ng-container i18n *ngIf="formGroup.controls['feePeriods']!.controls.length >= 1">an dieser Stelle</ng-container></p-button>
							</ng-container>
						</ng-template>
					</div>
					<p-button
						[icon]="enums.PlanoFaIconPool.ADD"
						[theme]="PBtnThemeEnum.OUTLINE_PRIMARY"
						(triggerClick)="onAddFeePeriodClick(0)"
						[disabled]="shiftModel.currentCancellationPolicy?.feePeriods?.get(0)?.start===-1"
						[cannotSetHint]="'Damit hier eine weitere Zeitspanne hinzugefügt werden kann, ändere bitte den Start der vorherigen Zeitspanne von »UNBEGRENZT« in eine konkrete Anzahl an Tagen.'"
					><ng-container i18n>Zeitspanne & Gebühr hinzufügen</ng-container>&ngsp;<ng-container i18n *ngIf="formGroup.controls['feePeriods']!.controls.length >= 1">an dieser Stelle</ng-container></p-button>
					<p-alert *ngIf="formGroup.controls['feePeriods']!.hasError(PPossibleErrorNames.REQUIRED)" [theme]="enums.PThemeEnum.DANGER" class="mt-3" i18n>Du hast die Funktion aktiviert, dass deine Kunden <strong>kostenpflichtige</strong> Buchungen selbst online stornieren dürfen. Voraussetzung dafür ist, dass du hier deine Stornogebühren angibst.</p-alert>

					<p-alert *ngIf="firstFeePeriodStartIsNullError" [theme]="enums.PThemeEnum.DANGER" class="mt-3" i18n>Um speichern zu können, definiere bitte noch die Gebühr für Stornierungen <mark>{{ firstFeePeriodStartIsNullError['actual'] !== undefined && firstFeePeriodStartIsNullError['actual'] !== null ? firstFeePeriodStartIsNullError['actual'] + 1 : '?' }}</mark> oder mehr Tage vor dem Angebotstag. Lege dazu eine Zeitspanne mit dem Start <mark>UNBEGRENZT</mark> an.</p-alert>
					<p-alert *ngIf="noCancellationConditionsLinked || editFeePeriodEmailIsDisabled" [theme]="enums.PThemeEnum.WARNING" class="mt-3"><ng-container *ngIf="noCancellationConditionsLinked" i18n>Wir empfehlen dir sehr, eure Stornobedingungen in Dr.&nbsp;Plano zu <a routerLink="/client/plugin/basis/" fragment="legal-links-anchor" target="_blank" rel="noopener">hinterlegen</a>.</ng-container>&ngsp;<ng-container *ngIf="editFeePeriodEmailIsDisabled" i18n>Die automatische Email »Buchungspreis geändert« sollte für »{{ shiftModel.name }}« aktiviert werden, damit eure Kunden über mögliche Änderungen der Stornogebühren benachrichtigt werden.</ng-container></p-alert>
				</p-section>
			</p-tab>
			<p-tab
				*ngIf="shiftModel.currentCancellationPolicy"
				label="Widerrufsfrist" i18n-label
				[size]="PTabSizeEnum.FRAMELESS"
				[hasDanger]="!!formGroup.get(shiftModel.currentCancellationPolicy.attributeInfoWithdrawalPeriod.id)?.invalid"
			>
				<p-section>
					<p i18n>Falls das Widerrufsrecht für eure buchbaren Angebote gilt, kannst du hier die Widerrufsfrist einstellen, damit sie deinen Kunden bei der Online-Buchung angezeigt wird. Werden Buchungen innerhalb der gesetzten Widerrufsfrist storniert, fallen generell keine Stornierungsgebühren an – die Widerrufsfrist hat also Vorrang gegenüber den hinterlegten Stornierungsgebühren. Zum Thema Widerrufsfrist empfehlen wir dir sehr unser <a routerLink="/client/tutorials" target="_blank" rel="noopener" [pIcon]="enums.PlanoFaIconPool.AREA_TUTORIALS">Tutorial-Video</a> über die Stornierung & Rückzahlung von Buchungen.</p>
					<p-alert [theme]="enums.PThemeEnum.INFO" class="small mt-4 mb-5" i18n>Änderungen der Widerrufsfrist wirken sich <strong>nur auf neue Buchungen</strong> aus. Bereits vorhandene Buchungen bleiben unangetastet.</p-alert>
					<p-ai-switch
						[group]="formGroup"
						[attributeInfo]="shiftModel.currentCancellationPolicy.attributeInfoWithdrawalEnabled"
						[type]="AISwitchUIType.RADIOS"
					>
						<p-ai-switch-item
							label="Widerrufsfrist aktivieren" i18n-label
							[value]="true"
						></p-ai-switch-item>
						<p-ai-switch-item
							label="Widerrufsfrist deaktivieren" i18n-label
							[value]="false"
						></p-ai-switch-item>
					</p-ai-switch>
					<p-grid *ngIf="shiftModel.currentCancellationPolicy.attributeInfoWithdrawalEnabled?.isAvailable && shiftModel.currentCancellationPolicy.withdrawalEnabled">
						<div class="col-md-6">
							<p-ai-switch
								label="Widerrufsfrist gilt innerhalb von" i18n-label
								placeholder="z.B. 14" i18n-placeholder
								[group]="formGroup"
								[attributeInfo]="shiftModel.currentCancellationPolicy.attributeInfoWithdrawalPeriod"
								[durationUIType]="PApiPrimitiveTypes.Days"
								inputGroupAppendText="Tagen nach dem Buchungstag" i18n-inputGroupAppendText
							></p-ai-switch>
							<span class="form-text" i18n><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon>&nbsp;Der Buchungstag selbst wird <strong>nicht</strong> mitgezählt. Die Widerrufsfrist endet spätestens mit dem Beginn des gebuchten Angebots – bei mehrtägigen Angeboten spätestens mit dem Beginn des ersten Termins.</span>
						</div>
					</p-grid>
				</p-section>
			</p-tab>
			<p-tab
				oddEvenParent
				label="Online-Storno durch Kunden" i18n-label
				[size]="PTabSizeEnum.FRAMELESS"
				[hasDanger]="(
					!!formGroup.get(shiftModel.attributeInfoOnlineCancellationForFreeBookingsEnabled.id)?.invalid ||
					!!formGroup.get(shiftModel.attributeInfoOnlineCancellationForFreeBookingsDeadline.id)?.invalid ||
					!!formGroup.get(shiftModel.attributeInfoOnlineCancellationForChargeableBookingsEnabled.id)?.invalid ||
					!!formGroup.get(shiftModel.attributeInfoOnlineCancellationForChargeableBookingsDeadline.id)?.invalid ||
					!!formGroup.get(shiftModel.attributeInfoOnlineCancellationForWithdrawableBookingsAlwaysEnabled.id)?.invalid ||
					!!formGroup.get(shiftModel.attributeInfoOnlineCancellationAutomaticOnlineRefundEnabled.id)?.invalid
				)"
			>
				<p-section [useClassesPadding]="true" class="pt-3 pb-5 px-3">
					<p i18n>Hier kannst du deinen Kunden erlauben, ihre Buchungen selbst online zu stornieren. Nutzt du die Online-Zahlung, kannst du zusätzlich eine automatische Rückerstattung aktivieren.</p>
					<ul class="list custom-marker pb-3 pl-3 mb-0">
						<li><ng-container i18n>Deine Kunden können komfortabel über einen Link in den Emails stornieren. Der Link wird automatisch in die Emails platziert, wenn du die Stornierung durch Kunden erlaubst. Du brauchst also deine <a target="_blank" rel="noopener" routerLink="/client/plugin/emails">Email-Vorlagen</a> nicht manuell anzupassen.</ng-container></li>
						<li><ng-container i18n>Du sparst dir das Lesen von Storno-Emails, das manuelle Stornieren etc.</ng-container></li>
						<li><ng-container i18n>Du hast dabei die volle Kontrolle, weil du Fristen definieren kannst, bis wann Kunden selbst stornieren dürfen. Die Fristen dafür können übrigens andere sein als die, die für deine Stornierungsgebühren gelten.</ng-container></li>
						<li><ng-container i18n>Aktiviere noch die automatische Geldrückerstattung für maximale Effizienz.</ng-container></li>
						<li><ng-container i18n>Lasse dich über Online-Stornierung durch Kunden automatisch benachrichtigen und werde bei Bedarf aktiv – das geht wie gewohnt über deine persönlichen <a target="_blank" rel="noopener" routerLink="/client/notifications">Benachrichtigungs-Einstellungen</a>.</ng-container></li>
					</ul>
					<p-alert [theme]="enums.PThemeEnum.INFO" class="small mt-3 mb-0" i18n>Änderungen bei Online-Storno wirken sich sofort <strong>auch auf bereits bestehende Buchungen</strong> aus – anders als Änderungen der Stornogebühren oder der Widerrufsfrist.</p-alert>
				</p-section>
				<p-section
					label="Kostenlose Buchungen stornieren" i18n-label
					pAnchorLink="cancel-free-bookings"
				>
					<p i18n>Bei Stornierungen von kostenlosen Buchungen durch Kunden fallen generell <strong>keine Stornierungsgebühren</strong> an. Als kostenlos gilt eine Buchung mit einem Buchungspreis von <mark>{{0 | currency:Config.CURRENCY_CODE :undefined:undefined:undefined:true}}</mark> – ist also unabhängig von der vom Kunden gewählten Zahlungsart.</p>
					<p-ai-switch
						[group]="formGroup"
						[attributeInfo]="shiftModel.attributeInfoOnlineCancellationForFreeBookingsEnabled"
						[type]="AISwitchUIType.RADIOS"
					>
						<p-ai-switch-item
							label="Kunden dürfen online stornieren" i18n-label
							[value]="true"
						></p-ai-switch-item>
						<p-ai-switch-item
							label="Kunden dürfen nicht online stornieren" i18n-label
							[value]="false"
						></p-ai-switch-item>
					</p-ai-switch>

					<p-grid *ngIf="onlineCancellationForFreeBookingsEnabled" class="mb-3">
						<div class="col-md-6">
							<p-ai-switch
								label="Frist für Online-Stornos durch Kunden" i18n-label
								description="<p>Um eine Online-Stornierung <strong>am Angebotstag selbst</strong> auszuschließen, trage hier »0 Tage vor dem Angebotstag« ein.</p>Dementsprechend bedeutet die Einstellung »2 Tage vor dem Angebotstag« bei einem Angebot, das beispielsweise an einem 4. Juli stattfinden soll, dass deine Kunden bis zum einschließlich 1. Juli selbst online stornieren dürfen." i18n-description
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoOnlineCancellationForFreeBookingsDeadline"
								[durationUIType]="PApiPrimitiveTypes.Days"
								[options]="deadlinesInputDurationOptions"
								[maxDecimalPlacesCount]="0"
							></p-ai-switch>
							<span class="form-text" i18n><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon>&nbsp;Bei mehrtägigen Angeboten ist der Starttermin relevant.</span>
						</div>
					</p-grid>

					<p-alert *ngIf="onlineCancellationForFreeBookingsEnabled" [theme]="enums.PThemeEnum.SUCCESS" class="mb-0" i18n>Ab jetzt werden deine Kunden einen Link für die Stornierung in den Emails vorfinden, die sie von Dr.&nbsp;Plano erhalten – z.B. in der Email für die Buchungsbestätigung. Du brauchst also deine Email-Vorlagen nicht selbst manuell anzupassen.</p-alert>

				</p-section>
				<p-section
					[background]="'light-cold'"
					label="Kostenpflichtige Buchungen stornieren" i18n-label
					pAnchorLink="cancel-paid-bookings"
				>
					<p i18n>Bei Stornierungen von kostenpflichtigen Buchungen durch Kunden werden automatisch die in Dr.&nbsp;Plano hinterlegten Stornogebühren angerechnet. Buchungen, für die keine Stornogebühren hinterlegt sind, können nicht online storniert werden.</p>
					<p-ai-switch
						label="Kunden dürfen online stornieren" i18n-label
						[group]="formGroup"
						[attributeInfo]="shiftModel.attributeInfoOnlineCancellationForChargeableBookingsEnabled"
						[canSet]="!(!shiftModel.onlineCancellationForChargeableBookingsEnabled && shiftModel.currentCancellationPolicy?.feePeriods!.length === 0)"
						[cannotSetHint]="!shiftModel.onlineCancellationForChargeableBookingsEnabled && shiftModel.currentCancellationPolicy?.feePeriods!.length === 0 ? 'Um diese Funktion nutzen zu können, musst du erst deine Stornogebühren unter dem Reiter »Stornogebühren« angeben.' : null"
					>
							<p-ai-switch-item
								label="Kunden dürfen online stornieren" i18n-label
								[value]="true"
								[cannotSetHint]="shiftModel!.attributeInfoOnlineCancellationForChargeableBookingsEnabled.cannotSetHint"
							></p-ai-switch-item>
							<p-ai-switch-item
								label="Kunden dürfen nicht online stornieren" i18n-label
								[value]="false"
								[cannotSetHint]="shiftModel!.attributeInfoOnlineCancellationForChargeableBookingsEnabled.cannotSetHint"
							></p-ai-switch-item>
					</p-ai-switch>

					<p-grid *ngIf="shiftModel.onlineCancellationForChargeableBookingsEnabled" class="mb-3">
						<div class="col-md-6">
							<p-ai-switch
								label="Frist für Online-Stornos durch Kunden" i18n-label
								description="<p>Um eine Online-Stornierung <strong>am Angebotstag selbst</strong> auszuschließen, trage hier »0 Tage vor dem Angebotstag« ein.</p>Dementsprechend bedeutet die Einstellung »2 Tage vor dem Angebotstag« bei einem Angebot, das beispielsweise an einem 4. Juli stattfinden soll, dass deine Kunden bis zum einschließlich 1. Juli selbst online stornieren dürfen." i18n-description
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoOnlineCancellationForChargeableBookingsDeadline"
								[durationUIType]="PApiPrimitiveTypes.Days"
								[options]="deadlinesInputDurationOptions"
								[maxDecimalPlacesCount]="0"
							></p-ai-switch>
							<span class="form-text" i18n><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon>&nbsp;Bei mehrtägigen Angeboten ist der Starttermin relevant.</span>
						</div>
					</p-grid>

					<p-alert *ngIf="shiftModel.onlineCancellationForChargeableBookingsEnabled" [theme]="enums.PThemeEnum.SUCCESS" class="mb-0" i18n>Ab jetzt werden deine Kunden einen Link für die Stornierung in den Emails vorfinden, die sie von Dr.&nbsp;Plano erhalten – z.B. in der Email für die Buchungsbestätigung. Du brauchst also deine Email-Vorlagen nicht selbst manuell anzupassen.</p-alert>

				</p-section>
				<p-section
					[background]="'light-cold'" label="Innerhalb der Widerrufsfrist stornieren" i18n-label
					pAnchorLink="cancel-in-cooling-off-period"
				>
					<p><ng-container i18n *ngIf="shiftModel.currentCancellationPolicy?.attributeInfoWithdrawalPeriod?.isAvailable && shiftModel.currentCancellationPolicy?.attributeInfoWithdrawalEnabled?.isAvailable && shiftModel.currentCancellationPolicy?.withdrawalEnabled">Du hast für »{{ shiftModel.name }}« eine Frist von <code>{shiftModel.currentCancellationPolicy?.withdrawalPeriod, plural, one {einem Tag} other {{{shiftModel.currentCancellationPolicy?.withdrawalPeriod}} Tagen}}</code> im Sinne des Widerrufsrechts definiert.&nbsp;</ng-container><ng-container i18n>Hier kannst du die Online-Stornierung durch Kunden innerhalb der Widerrufsfrist separat regulieren – das, was du hier einstellst, hat Vorrang gegenüber den Einstellungen oben für kostenlose oder kostenpflichtige Buchungen.</ng-container></p>
					<p-ai-switch
						[group]="formGroup"
						[attributeInfo]="shiftModel.attributeInfoOnlineCancellationForWithdrawableBookingsAlwaysEnabled"
					>
						<p-ai-switch-item
							label="Online-Stornierung in der Widerrufsfrist immer erlauben" i18n-label
							[value]="true"
						></p-ai-switch-item>
						<p-ai-switch-item
							label="Keine besondere Regelung für Stornierungen in der Widerrufsfrist" i18n-label
							[value]="false"
						></p-ai-switch-item>
					</p-ai-switch>
					<p-alert *ngIf="shiftModel.attributeInfoOnlineCancellationForWithdrawableBookingsAlwaysEnabled.isAvailable && shiftModel.onlineCancellationForWithdrawableBookingsAlwaysEnabled" [theme]="enums.PThemeEnum.INFO" class="mb-0" i18n>Bedenke bitte, dass bei Stornierungen innerhalb der Widerrufsfrist generell keine Stornierungsgebühren angerechnet werden.</p-alert>

				</p-section>
				<p-section
					label="Automatische Rückzahlung" i18n-label
					pAnchorLink="automatic-refund"
				>
					<p i18n>Wenn du unten die automatische Rückzahlung aktivierst, wird nach einem Online-Storno durch deinen Kunden der zu erstattende Betrag von Dr.&nbsp;Plano ausgerechnet, indem eine ggf. anfallende Stornogebühr von der bereits bezahlten Buchungsgebühr abgezogen wird. Anschließend wird der Betrag automatisch an deinen Kunden zurückgezahlt. Diese Funktionalität steht nur bei Buchungen zur Verfügung, die per <a routerLink="/client/plugin/payments" target="_blank" rel="noopener">Online-Zahlung</a> bezahlt werden.</p>
					<p i18n>☝ Wenn du die automatische Rückzahlung nicht aktivierst, wirst du nach Stornierungen durch Kunden informiert, dass du noch Geld zurückzahlen musst. Das kannst du dann in der jeweiligen Buchung mit ein paar Klicks erledigen.</p>
					<p-ai-switch
						label="Automatische Rückzahlung per Online-Zahlung" i18n-label
						[group]="formGroup"
						[attributeInfo]="shiftModel.attributeInfoOnlineCancellationAutomaticOnlineRefundEnabled"
					>
						<p-ai-switch-item
							label="Automatische Online-Rückzahlung aktivieren" i18n-label
							[value]="true"
						></p-ai-switch-item>
						<p-ai-switch-item
							label="Keine automatische Online-Rückzahlung" i18n-label
							[value]="false"
						></p-ai-switch-item>
					</p-ai-switch>

					<p-alert *ngIf="shiftModel.attributeInfoOnlineCancellationAutomaticOnlineRefundEnabled.isAvailable && shiftModel.onlineCancellationAutomaticOnlineRefundEnabled" [theme]="enums.PThemeEnum.INFO" i18n>Die automatische Rückzahlung erfolgt nur, wenn dein gesamter Account und die jeweilige Buchung genügend <a routerLink="/client/plugin/faq-online-payment" fragment="refund" target="_blank" rel="noopener">Online-Guthaben</a> aufweisen. Für Rückzahlungen fallen <a routerLink="/client/plugin/faq-online-payment" fragment="pricing" rel="noopener" target="_blank">Gebühren</a> an. Online-Zahlungen von Kunden, die vor mehr als 180 Tagen getätigt worden sind, können leider nicht zurückerstattet werden, da die Banken sie nicht mehr akzeptieren – Dr.&nbsp;Plano siebt so alte Zahlungen automatisch aus.</p-alert>

					<h5 class="text-transform-none mt-3" i18n>Gutschein statt Rückzahlung</h5>
					<div i18n>Sollte dein Kunde eine Buchung stornieren, die teils oder ganz über einen Gutschein bezahlt worden war, kann Dr.&nbsp;Plano anstatt einer Rückzahlung einen Gutschein ausstellen. Dazu erledigt Dr.&nbsp;Plano automatisch folgende Schritte:</div>
					<ol class="mt-3">
						<li i18n class="pl-0">Dr.&nbsp;Plano erstellt einen neuen Gutschein: mit einem Guthaben in Höhe des Betrags, den der Kunde ursprünglich über einen oder mehrere Gutscheine eingelöst hatte (wie immer werden ggf. anfallende Stornogebühren vorher abgezogen).</li>
						<li i18n class="pl-0">Dein Kunde erhält automatisch eine Email mit dem neuen Gutschein.</li>
						<li i18n class="pl-0">Der Zahlungsstatus der stornierten Buchung wird automatisch korrigiert.</li>
						<li i18n class="pl-0">Der ausgestellte Gutschein wird mit der stornierten Buchung verbunden, sodass der Vorgang jederzeit nachvollziehbar bleibt.</li>
						<li i18n class="pl-0">Du wirst per Email über den Vorgang benachrichtigt, falls du das möchtest.</li>
					</ol>
					<div class="mb-4" i18n>Und all das ohne dein Zutun! 💫</div>
					<p-ai-switch
						label="Automatische Gutschein-Ausstellung" i18n-label
						[group]="formGroup"
						[attributeInfo]="shiftModel.attributeInfoOnlineCancellationAutomaticGiftCardRefundEnabled"
					>
						<p-ai-switch-item
							label="Automatische Gutschein-Ausstellung aktivieren" i18n-label
							[value]="true"
						></p-ai-switch-item>
						<p-ai-switch-item
							label="Keine automatische Gutschein-Ausstellung" i18n-label
							[value]="false"
						></p-ai-switch-item>
					</p-ai-switch>
					<p-alert
						*ngIf="shiftModel.onlineCancellationAutomaticOnlineRefundEnabled && !shiftModel.onlineCancellationAutomaticGiftCardRefundEnabled && api.data.giftCardSettings.isSaleEnabled"
						i18n
					>Wenn du die automatische Gutschein-Ausstellung deaktivierst, können Buchungen, die teils oder ganz über einen Gutschein bezahlt worden sind, in der Regel nicht mehr automatisch erstattet werden. Das liegt daran, dass automatische Rückzahlungen nur erfolgen, wenn der gesamte zu erstattende Betrag zurückgezahlt werden kann. Wenn allerdings eine Buchung teils oder ganz über einen Gutschein bezahlt worden ist, liegt in der Regel nicht genügend Online-Guthaben vor, um den gesamten Betrag zu erstatten.</p-alert>
					<p-alert [theme]="enums.PThemeEnum.INFO" i18n>Der ausgestellte Gutschein hat dieselben Eigenschaften wie ein gewöhnlich über Dr.&nbsp;Plano erworbener Gutschein: Er entspricht deinen <a target="_blank" rel="noopener" routerLink="/client/plugin/gift-cards/settings">Gutschein-Einstellungen<fa-icon [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB" class="ml-1"/></a> und wird mit der üblichen <a target="_blank" rel="noopener" routerLink="/client/plugin/gift-cards/introduction" fragment="gift-card-pricing">Gebühr<fa-icon [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB" class="ml-1"/></a> abgerechnet. ☝️ Wir empfehlen die Rückzahlung per Gutschein nur, wenn deine Stornobedingungen oder eine individuelle Absprache mit deinem Kunden es zulassen.</p-alert>
				</p-section>
				<div class="p-4 px-5" *ngIf="showCancelEmailIsDisabledHint">
					<hr>
					<p-alert class="mt-3" i18n>Die automatische Email »Buchung storniert« ist für »{{shiftModel.name}}« deaktiviert. Wir empfehlen dringend, sie zu aktivieren, damit eure Kunden nach erfolgreicher Online-Stornierung automatisch eine Bestätigung per Email erhalten.</p-alert>
				</div>
			</p-tab>
		</p-tabs>

	</p-editable-modal-form>
</p-editable-modal-box>

<ng-template #feePeriodTitleTemplate let-feePeriod>
	<span>
		<ng-container *ngIf="feePeriod.start === null && feePeriod.end === null; else detailPeriodDatesTemplate" i18n>Bei jeglicher Stornierung</ng-container>

		<ng-template #detailPeriodDatesTemplate>
			<p-fee-period-time-text
				[start]="feePeriod.start"
				[end]="feePeriod.end"
			></p-fee-period-time-text>

		</ng-template>
	</span>
</ng-template>

<ng-template #feePeriodPriceTemplate let-feePeriod>
	<span *ngIf="!feePeriod.feeFix && !feePeriod.feePercentage; else priceTemplate" i18n>kostenlose Stornierung</span>
	<ng-template #priceTemplate>
		<p class="m-0">
			<code *ngIf="feePeriod.feeFix">{{ feePeriod.attributeInfoFeeFix | currency }}</code>
			<ng-container *ngIf="!!feePeriod.feeFix && !!feePeriod.feePercentage">&nbsp;+&nbsp;</ng-container>
			<ng-container *ngIf="!!feePeriod.feePercentage">
				<code>{{ feePeriod.feePercentage / 100 | percent : '1.0-1' }}</code>&nbsp;<ng-container i18n>vom Buchungsbetrag</ng-container>
			</ng-container>
		</p>
	</ng-template>
</ng-template>

<ng-template #spinnerTemplate>
	<p-spinner [size]="enums.BootstrapSize.LG" class="m-3"></p-spinner>
</ng-template>
