<p-list-item
	[id]="shiftModel.id.toString()"
	size="frameless"
	*ngIf="shiftModel && shiftModel.rawData"
	class="rounded o-hidden card-options border bg-white"

	[hideListItemStyle]="true"

	[class.border-secondary]="!highlightService.isHighlighted(shiftModel)"
	[class.border-primary]="highlightService.isHighlighted(shiftModel)"
	[class.shadow]="highlightService.isHighlighted(shiftModel)"

	(onClick)="!undefined"
>
	<button
		class="flex-grow-1 d-flex align-items-center flex-nowrap crop-on-overflow p-0 position-relative"
		[affected]="affected"
		[selected]="selected"
		[class.list-group-item-mobile]="Config.IS_MOBILE"
		[class.clickable]="hasOnItemClickBinding"
		[class.list-group-item]="hasOnItemClickBinding"
		[class.list-group-item-action]="hasOnItemClickBinding"
		[class.border-0]="true"
		[class.bg-white]="highlightService.isHighlighted(shiftModel) && !selected && !affected"
		(click)="Config.IS_MOBILE ? undefined : onItemClick.emit(shiftModel)"
	>
		<p-color-marker
			[item]="shiftModel"
			[hexColor]="shiftModelColor"
			[title]="shiftModel.name"
			[isPacket]="shiftModel.isPacket ?? null"
		></p-color-marker>
		<div
			class="mr-auto crop-on-overflow p-2 pl-3 pr-3 w-100"
			[class.text-muted]="!shiftModel.name"
		>{{ shiftModelName }}</div>
	</button>

	<div class="d-flex justify-content-end ml-auto">
		<button
			*ngIf="showMultiSelectCheckbox"
			type="button"
			class="card-option btn pt-2 pb-2 d-flex align-items-center justify-content-center border-left btn-frameless rounded-0"
			[class.btn-primary]="shiftModel.selected"
			[class.btn-light]="!shiftModel.selected"
			[class.btn-outline-secondary]="!shiftModel.selected"
			(click)="selectInCalendar($event)"
			title="Alle Einträge dieser Tätigkeit selektieren" i18n-title
		><fa-icon [icon]="enums.PlanoFaIconPool.CALENDAR_SELECT_RELATED_SHIFTS">
			<!-- [class.text-primary]="affected && !selected" -->
		</fa-icon></button>
		<a
			type="button"
			role="button"
			*ngIf="showDetailsBtn"
			class="card-option btn pt-2 pb-2 d-flex align-items-center justify-content-center border-left btn-frameless rounded-0 btn-outline-secondary btn-light"
			[routerLink]="showDetails()"
			[title]="(userCanWrite ? 'Bearbeiten' : 'Details anzeigen') | localize"
		><fa-icon [icon]="userCanWrite ? 'pen' : enums.PThemeEnum.INFO"></fa-icon></a>
		<button
			*ngIf="editFilterModeActive"
			type="button"
			[title]="isVisible ? ('Ausblenden' | localize) : ('Einblenden' | localize)"
			(click)="toggleItem()"
			class="card-option btn pt-2 pb-2 d-flex align-items-center justify-content-center border-left btn-frameless rounded-0 btn-secondary"
		><fa-icon [class.text-primary]="!isVisible" [icon]="isVisible ? enums.PlanoFaIconPool.VISIBLE : enums.PlanoFaIconPool.INVISIBLE"></fa-icon></button>
	</div>
</p-list-item>
