import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { ApiListWrapper, GenerateAbsencesEarningSetting, GenerateAbsencesMode, GenerateAbsencesOptions, GenerateAbsencesTimeSetting, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchangeShiftRefs } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { Subscription } from 'rxjs';

type ValueType = GenerateAbsencesOptions;

@Component({
	selector: 'p-generate-absences-options',
	templateUrl: './generate-absences-options.component.html',
	styleUrls: ['./generate-absences-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PGenerateAbsencesOptionsComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PGenerateAbsencesOptionsComponent extends PAbstractControlComponentBaseDirective implements AfterContentChecked, ControlValueAccessor, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftRefs : SchedulingApiShiftExchangeShiftRefs | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private indisposedMemberId : Id | null = null;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		private api : SchedulingApiService,
		private validators : ValidatorsService,
		private localize : LocalizePipe,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public generateAbsencesModesEnum : typeof GenerateAbsencesMode = GenerateAbsencesMode;
	public timeSettingsEnum : typeof GenerateAbsencesTimeSetting = GenerateAbsencesTimeSetting;
	public earningSettingsEnum : typeof GenerateAbsencesEarningSetting = GenerateAbsencesEarningSetting;

	public formGroup : FormGroup<{
		'generateItems' : PFormControl<boolean>,
		'mode' : PFormControl<GenerateAbsencesMode>,
		'timeSetting' : PFormControl,
		'averageWorkingTimePerDay' : PFormControl,
		'absenceStartDate' : PFormControl,
		'absenceEndDate' : PFormControl,
		'wholeDayEntry' : PFormControl,
		'paid' : PFormControl,
		'earningSetting' : PFormControl,
		'earningsPerHour' : PFormControl,
		'visibleToTeamMembers' : PFormControl,
	}> | null = null;

	public PApiPrimitiveTypes = PApiPrimitiveTypes;
	public SectionWhitespace = SectionWhitespace;

	public override ngAfterContentChecked() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initAfterValueHack();
		return super.ngAfterContentChecked();
	}

	private initAfterValueHack() : void {
		// HACK: this.value is not defined in the first run here. Therefore i ask for (this._value && !this.formGroup)
		// It is not clear why this.value is not defined. I added a post to Stackoverflow for this:
		// https://stackoverflow.com/questions/57918712/why-is-this-value-undefined-null-on-every-lifecycle-hook-when-using-ngmodel-t

		if (!this.value) return;
		if (this.formGroup) return;

		assumeDefinedToGetStrictNullChecksRunning(this.shiftRefs, 'shiftRefs');
		assumeDefinedToGetStrictNullChecksRunning(this.indisposedMemberId, 'indisposedMemberId');
		this.initFormGroup();
		this.setChangesListenerForControlError();
		this.setChangesListenerForReset();
	}

	private setChangesListenerForControlError() : void {
		if (!this.control) return;

		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		this.subscriptions.push(this.formGroup.valueChanges.subscribe(() => {
			assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
			if (this.formGroup.valid) {
				this.control!.setErrors(null);
			} else {
				this.control!.setErrors({ invalid : true });
			}
		}));
	}

	private subscriptions : Subscription[] = [];

	private setChangesListenerForReset() : void {
		if (!this.control) return;

		this.subscriptions.push(this.control.valueChanges.subscribe(() => {
			this.initFormGroup();
		}));
	}

	/**
	 * Initialize the formGroup for this component
	 */
	/* eslint max-lines-per-function: ['warn', 250] */ // eslint-disable-next-line sonarjs/cognitive-complexity, jsdoc/require-jsdoc
	public initFormGroup() : void {
		if (this.formGroup) { this.formGroup = null; }

		const newFormGroup = this.pFormsService.group({}) as Exclude<PGenerateAbsencesOptionsComponent['formGroup'], null>;
		this.pFormsService.addControl(
			newFormGroup,
			'generateItems',
			{
				value: this.value!.generateItems,
				disabled: false,
			},
			[],
			(value : boolean) => {

				/**
				 * Set or change the generateItems flag and refresh other generator values if necessary
				 */
				this.value!.generateItems = value;
				if (!this.value!.generateItems) {
					newFormGroup.controls['mode'].setValue(null);
					newFormGroup.controls['timeSetting'].setValue(null);
					newFormGroup.controls['absenceStartDate'].setValue(null);
					newFormGroup.controls['absenceEndDate'].setValue(null);
					newFormGroup.controls['wholeDayEntry'].setValue(null);
					newFormGroup.controls['averageWorkingTimePerDay'].setValue(null);
					newFormGroup.controls['paid'].setValue(null);
					newFormGroup.controls['earningSetting'].setValue(null);
					newFormGroup.controls['earningsPerHour'].setValue(null);
					newFormGroup.controls['visibleToTeamMembers'].setValue(null);

					newFormGroup.updateValueAndValidity();
				} else {
					this.refreshStartAndEnd();
					this.refreshEarningsPerHour();
					newFormGroup.controls['timeSetting'].updateValueAndValidity();
				}
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'mode',
			{
				value: this.value!.mode,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					if (!this.showModeInput) return null;

					// FIXME: PLANO-15096
					if (!this.value!.generateItems) return null;
					return this.validators.required(PApiPrimitiveTypes.Enum).fn(control);
				}}),
			],
			value => {

				/**
				 * Set or change the generateAbsencesMode and refresh other generator values if necessary
				 */
				this.value!.mode = value;
				const timeSettingControl = newFormGroup.get('timeSetting');
				assumeDefinedToGetStrictNullChecksRunning(timeSettingControl, 'timeSettingControl');
				if (this.value!.mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_ALL) {
					timeSettingControl.setValue(GenerateAbsencesTimeSetting.OVERWRITE_DURATION);
				} else {
					timeSettingControl.setValue(null);
					timeSettingControl.updateValueAndValidity();
				}
				this.refreshStartAndEnd();
				this.refreshEarningsPerHour();
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'timeSetting',
			{
				value: this.value!.timeSetting,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					if (!this.value!.generateItems) return null;
					return this.validators.required(PApiPrimitiveTypes.string).fn(control);
				}}),
			],
			value => {
				if (value === this.value!.timeSetting) return;
				this.value!.timeSetting = value;

				const control = newFormGroup.get('averageWorkingTimePerDay');
				assumeDefinedToGetStrictNullChecksRunning(control, 'control');
				if (value !== GenerateAbsencesTimeSetting.OVERWRITE_DURATION) {
					control.setValue(null);
				}
				control.updateValueAndValidity();
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'wholeDayEntry',
			{
				value: this.value!.wholeDayEntry,
				disabled: false,
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'absenceStartDate',
			{
				value: this.value!.absenceStartDate,
				disabled: false,
			},
			[
				this.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.Integer),
			],
			value => {
				this.value!.absenceStartDate = value;
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'absenceEndDate',
			{
				value: this.value!.absenceEndDate,
				disabled: false,
			},
			[
				this.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.Integer),
			],
			value => {
				this.value!.absenceEndDate = value;
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'averageWorkingTimePerDay',
			{
				value: this.value!.averageWorkingTimePerDay,
				disabled: false,
			},
			[
				this.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.Integer),
				this.validators.greaterThan(0, PApiPrimitiveTypes.Integer),
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					if (this.value!.timeSetting === undefined) return null;
					if (this.value!.timeSetting !== GenerateAbsencesTimeSetting.OVERWRITE_DURATION) return null;
					return this.validators.required(PApiPrimitiveTypes.Duration).fn(control);
				}}),
			],
			value => {
				this.value!.averageWorkingTimePerDay = value;
			},
		);

		this.pFormsService.addControl(
			newFormGroup,
			'paid',
			{
				value: this.value!.paid,
				disabled: false,
			},
			[],
			value => {
				this.value!.paid = value;
				const control = newFormGroup.get('earningSetting');
				assumeDefinedToGetStrictNullChecksRunning(control, 'control');
				if (value !== true) {
					control.setValue(null);
				} else if (this.value!.mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_ALL) {
					control.setValue(GenerateAbsencesEarningSetting.OVERWRITE_EARNING);
				}
				control.updateValueAndValidity();
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'earningSetting',
			{
				value: this.value!.earningSetting,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					if (!this.showEarningSettingInput) return null;

					// FIXME: PLANO-15096
					if (!this.value!.generateItems) return null;
					return this.validators.required(PApiPrimitiveTypes.string).fn(control);
				}}),
			],
			value => {
				this.value!.earningSetting = value;
				if (value !== GenerateAbsencesEarningSetting.OVERWRITE_EARNING) {
					newFormGroup.controls['earningsPerHour'].setValue(null);
				}
				newFormGroup.controls['earningsPerHour'].updateValueAndValidity();
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'earningsPerHour',
			{
				value: this.value!.earningsPerHour,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					if (this.value!.earningSetting === undefined) return null;
					if (!this.value!.paid) return null;
					if (this.value!.earningSetting !== GenerateAbsencesEarningSetting.OVERWRITE_EARNING) return null;
					return this.validators.required(PApiPrimitiveTypes.string).fn(control);
				}}),
			],
			value => {
				this.value!.earningsPerHour = value;
			},
		);

		this.pFormsService.addPControl(
			newFormGroup,
			'visibleToTeamMembers',
			{
				formState: {
					value: this.value!.visibleToTeamMembers,
					disabled: false,
				},
				validatorObjects: [
					new PValidatorObject({
						name: PPossibleErrorNames.REQUIRED,
						fn: (control) => {
							if (!newFormGroup.controls['generateItems'].value) return null;
							return this.validators.required(PApiPrimitiveTypes.boolean).fn(control);
						},
					}),
				],
				subscribe: (value : boolean) => {
					this.value!.visibleToTeamMembers = value;
				},

			},
		);

		this.formGroup = newFormGroup;
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showAverageWorkingTimePerDayInput() : boolean {
		if (this.value!.timeSetting === GenerateAbsencesTimeSetting.OVERWRITE_DURATION) {
			return true;
		}
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get averageWorkingTimePerDayInputLabel() : string {
		if (!this.shiftRefs!.length) return this.localize.transform('Abwesende Stunden');
		const unit = this.value!.mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_EACH ? 'Eintrag' : 'Tag';
		return this.localize.transform({
			sourceString: 'Abwesende Stunden pro ${unit}',
			params: {unit: this.localize.transform(unit)},
		});
	}

	private get shiftRefsAsShiftsSorted() : ApiListWrapper<SchedulingApiShift> {
		return this.api.data.shifts.filterBy(item => this.shiftRefs!.contains(item.id)).sortedBy(item => item.start);
	}

	private get earliestShift() : SchedulingApiShift {
		return this.shiftRefsAsShiftsSorted.get(0)!;
	}

	private get latestShift() : SchedulingApiShift {
		const shiftRefsAsShiftsSorted = this.shiftRefsAsShiftsSorted;
		return shiftRefsAsShiftsSorted.get(shiftRefsAsShiftsSorted.length - 1)!;
	}

	private refreshEarningsPerHour() : void {
		const options = this.value!;
		if (options.mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_ALL && this.shiftRefs!.length === 1) {
			options.earningsPerHour = this.earliestShift.assignableMembers.get(this.indisposedMemberId)!.hourlyEarnings;
		}
	}

	private refreshStartAndEnd() : void {
		const options = this.value!;
		if (options.mode !== GenerateAbsencesMode.ONE_ABSENCE_FOR_ALL) return;

		// If only one Absence gets created, the start and end must be calculated from the shiftRefs
		options.averageWorkingTimePerDay = null;
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		this.formGroup.controls['absenceStartDate'].setValue(this.earliestShift.start);
		this.formGroup.controls['absenceEndDate'].setValue(this.latestShift.end);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showTimeSettingInput() : boolean {
		if (this.value!.mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_EACH) return true;
		if (this.shiftRefs!.length === 1) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showAbsenceStartAndEndDateInput() : boolean {
		if (
			this.value!.mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_ALL &&
			this.value!.timeSetting === GenerateAbsencesTimeSetting.OVERWRITE_DURATION
		) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showTimeBox() : boolean {
		if (this.showTimeSettingInput) return true;
		if (this.showAverageWorkingTimePerDayInput) return true;
		if (this.showAbsenceStartAndEndDateInput) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showEarningSettingInput() : boolean {
		if (!this.value!.paid) return false;
		const mode = this.value!.mode;
		if (mode === null || mode === GenerateAbsencesMode.ONE_ABSENCE_FOR_EACH) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showEarningsPerHourInput() : boolean {
		if (!this.value!.paid) return false;
		if (this.value!.earningSetting === GenerateAbsencesEarningSetting.OVERWRITE_EARNING) return true;
		return false;
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		for (const subscription of this.subscriptions) subscription.unsubscribe();
		return super.ngOnDestroy();
	}

	/**
	 * Should the input (e.g. radio buttons) for the attribute mode be visible?
	 * In case there are no or is only one shift, there is no need to set this mode.
	 */
	public get showModeInput() : boolean {
		return !!this.shiftRefs && this.shiftRefs.length > 1;
	}
}
