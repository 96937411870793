<div class="card">
	<div class="card-header">
		<div
			*ngIf="!!api.data.selectedItem && !!api.data.selectedItem.name && !!start"
			class="d-flex flex-wrap align-items-baseline justify-content-between">
			<strong>{{ api.data.selectedItem.name }}</strong>
			<span class="text-monospace text-right">{{ formattedDateTimePipe.getFormattedDateInfo(start, end, true).full }}</span>
		</div>
		<div *ngIf="!(!!api.data.selectedItem && !!api.data.selectedItem.name && !!start)" i18n>Deine Schicht</div>
	</div>
	<table class="table mb-0">
		<tr aria-labelledby="timestamp-stamped-start-label">
			<td><label i18n id="timestamp-stamped-start-label">Beginn</label></td>
			<td>
				<span *ngIf="!!start" role="status">{{ start | date:'shortTime' }}</span>
			</td>
		</tr>
		<tr [class.highlight]="api.isWorking" aria-labelledby="timestamp-stamped-working-time-duration-label">
			<td><label i18n id="timestamp-stamped-working-time-duration-label">Arbeitsdauer</label></td>
			<td>
				<div role="status" [innerHTML]="workingTimeDuration | pDurationTime | pSafeHtml"></div>
			</td>
		</tr>
		<tr [class.highlight]="api.isWorking" aria-labelledby="timestamp-stamped-pause-label">
			<td><label i18n id="timestamp-stamped-pause-label">Pause</label></td>
			<td>
				<p-pause-duration
					role="status"
					*ngIf="regularPauseDuration || automaticPauseDuration"
					[duration]="regularPauseDuration"
					[automaticPauseDuration]="automaticPauseDuration"
					[merged]="false"
					[tooltipTemplate]="tooltipAutomaticPauseDurationTemplate"
				></p-pause-duration>
			</td>
		</tr>
		<tr aria-labelledby="timestamp-stamped-end-label">
			<td><label i18n id="timestamp-stamped-end-label">Ende</label></td>
			<td role="status">
				<span *ngIf="!!end">{{ end | date:'shortTime' }} <span
					class="text-warning"
					*ngIf="automaticPauseDuration"

					[pTooltip]="tooltipAutomaticPauseDurationTemplate"
					[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
				><span>+</span><span
					[innerHTML]="automaticPauseDuration | pDurationAsHours: false | pSafeHtml"
				></span></span>
				</span>
			</td>
		</tr>
		<ng-template #tooltipAutomaticPauseDurationTemplate>
			<ng-container i18n>Diese Zeit wurde automatisch hinzugefügt, da die eingetragene Pause <strong [innerHTML]="regularPauseDuration | pDurationAsHours: false | pSafeHtml"></strong> unter dem gesetzlichen Minimum liegt.</ng-container>
		</ng-template>
	</table>

</div>
