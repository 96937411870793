<p-report-row
	[id]="workingTimeId"
	[class.border-bottom]="CONFIG.IS_MOBILE"
	[headline]="false"
	[ruler]="true"
	[duration]="workingTime.durationBetween(min, max)"
	[workingTime]="workingTime"
	[absence]="null"
	[isEditable]="true"
	[hideEditBtn]="workingTime.isExpectedWorkingTime"
	[min]="min"
	[max]="max"
	[class.bg-light]="workingTime.isExpectedWorkingTime"
>
	<div report-row-title class="crop-on-overflow d-flex align-items-center justify-content-between flex-grow-1">
		<div [class.d-flex]="!CONFIG.IS_MOBILE" class="crop-on-overflow">
			<span class="mr-3 crop-on-overflow" i18n>{{shiftModelName}}</span>
			<ng-template [ngTemplateOutlet]="dateAndTimeTemplate"></ng-template>
		</div>
	</div>
	<span *ngIf="workingTime.isExpectedWorkingTime" report-row-badges><p-forecast-badge class="ml-1"></p-forecast-badge></span>
	<div report-row-duration>
		<p-pause-duration
			[duration]="workingTime.regularPauseDuration"
			[automaticPauseDuration]="workingTime.automaticPauseDuration"
			[memberName]="memberName"
			[tooltipTemplate]="tooltipAutomaticPauseDurationTemplate"
			[isForecast]="workingTime.isExpectedWorkingTime"
		></p-pause-duration>
	</div>
	<strong report-row-earnings class="nowrap">{{ workingTime.totalEarningsBetween(min, max) | currency:CONFIG.CURRENCY_CODE:'symbol':'1.2-2' }}</strong>
</p-report-row>

<ng-template #dateAndTimeTemplate>
	<div class="d-flex crop-on-overflow" [class.small]="CONFIG.IS_MOBILE">
		<ng-template [ngTemplateOutlet]="dateTemplate"></ng-template>
		<ng-template [ngTemplateOutlet]="timeTemplate"></ng-template>
	</div>
</ng-template>

<ng-template #dateTemplate>
	<span class="text-monospace mr-3">
		<span
			class="crop-on-overflow"
			[pTooltip]="min > workingTime.time.start ? tooltipOutOfRange : null"
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

			[class.text-muted]="min > workingTime.time.start"
		>{{ dateInfo.start }}</span>
		{{ dateInfo.separator }}<span
			class="crop-on-overflow"
			[pTooltip]="max < workingTime.time.end ? tooltipOutOfRange : null"
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

			[class.text-muted]="max < workingTime.time.end"
		>{{ dateInfo.end }}</span></span>

	<ng-template #tooltipOutOfRange>
		<div class="d-block clickable card m-0 text-left o-hidden"
		>
			<div class="card-body p-1 pl-2 pr-2">
				<div i18n>Dieses Datum liegt außerhalb des Zeitraums {{ min | date:'shortDate' }} – {{ max - 1 | date:'shortDate' }}, der für diese Auswertung eingestellt wurde. Dauer der Arbeit/Abwesenheit und Verdienst werden deshalb anteilig berechnet.</div>
			</div>
		</div>
	</ng-template>
</ng-template>

<ng-template #timeTemplate>
	<span class="text-monospace crop-on-overflow"
	>{{ workingTime.time.start | date:'veryShortTime' }} – {{ workingTime.time.end | date:'shortTime' }}<span
		class="text-warning crop-on-overflow"
		*ngIf="workingTime.automaticPauseDuration"

		[pTooltip]="tooltipAutomaticPauseDurationTemplate"
		[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
	><span class="crop-on-overflow">+</span><span class="crop-on-overflow" [innerHTML]="workingTime.automaticPauseDuration | pDurationAsHours: false | pSafeHtml"></span></span>
	</span>
</ng-template>

<ng-template #tooltipAutomaticPauseDurationTemplate>
	<ng-template [ngIf]="!workingTime.isExpectedWorkingTime" [ngIfElse]="isForecastTemplate"><ng-container i18n>Diese Zeit wurde automatisch hinzugefügt, da die <strong *ngIf="memberName">von {{ memberName }} </strong>eingetragene Pause <strong [innerHTML]="workingTime.regularPauseDuration | pDurationAsHours: false | pSafeHtml"></strong> unter dem gesetzlichen Minimum liegt.</ng-container></ng-template>
	<ng-template #isForecastTemplate><ng-container i18n>Laut Gesetz wird<strong *ngIf="memberName"> {{ memberName }}</strong> mindestens <span [innerHTML]="workingTime.automaticPauseDuration | pDurationAsHours: false | pSafeHtml"></span> Pause machen müssen.</ng-container></ng-template>
</ng-template>
