/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "export_gift_cards_boulderado_csv".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	GIFT_CARD_IDS = 1;
}


@Injectable({
  providedIn: 'root',
})
export class ExportGiftCardsBoulderadoCsvApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_gift_cards_boulderado_csv');
	}

	protected version() : string {
		return '23718f20b86ae00ea068af5655dfabff,4c84544783fe9dda50b4141318c45bb2';
	}

	private dataWrapper = new ExportGiftCardsBoulderadoCsvApiRoot(this, );

	get data() : ExportGiftCardsBoulderadoCsvApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportGiftCardsBoulderadoCsvApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportGiftCardsBoulderadoCsvApiRoot(this, );
	}
}

		 
export class ExportGiftCardsBoulderadoCsvApiRoot extends ApiObjectWrapper<ExportGiftCardsBoulderadoCsvApiRoot>
{
	constructor(override readonly api : ExportGiftCardsBoulderadoCsvApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportGiftCardsBoulderadoCsvApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportGiftCardsBoulderadoCsvApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiRoot, ExportGiftCardsBoulderadoCsvApiRoot> = new ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiRoot, ExportGiftCardsBoulderadoCsvApiRoot>({
			apiObjWrapper: this as any as ExportGiftCardsBoulderadoCsvApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToGet: function(this : ExportGiftCardsBoulderadoCsvApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.CLIENT_OWNER) || this.api!.rightsService.requesterIs(AuthenticatedApiRole.ADMIN) || this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN))&&(this.api!.schedulingApi.data.posSystem === SchedulingApiPosSystem.BOULDERADO)&&(this.api!.schedulingApi.data.giftCardSettings.waysToRedeem === SchedulingApiGiftCardSettingsWaysToRedeem.OTHER));
			},
			hasRightToSet: function(this : ExportGiftCardsBoulderadoCsvApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.CLIENT_OWNER) || this.api!.rightsService.requesterIs(AuthenticatedApiRole.ADMIN) || this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN))&&(this.api!.schedulingApi.data.posSystem === SchedulingApiPosSystem.BOULDERADO)&&(this.api!.schedulingApi.data.giftCardSettings.waysToRedeem === SchedulingApiGiftCardSettingsWaysToRedeem.OTHER));
			},
			defaultValue: function(this : ExportGiftCardsBoulderadoCsvApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private giftCardIdsWrapper : ExportGiftCardsBoulderadoCsvApiGiftCardIds = new ExportGiftCardsBoulderadoCsvApiGiftCardIds(this.api,
		this as unknown as ExportGiftCardsBoulderadoCsvApiRoot, false);
	public attributeInfoGiftCardIds = this.giftCardIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the gift cards to be exported.
     */
	get giftCardIds() : ExportGiftCardsBoulderadoCsvApiGiftCardIds {
		this.getterDebugValidations(this.attributeInfoGiftCardIds, false);
		return this.giftCardIdsWrapper;
	}

	set giftCardIdsTestSetter(v : ExportGiftCardsBoulderadoCsvApiGiftCardIds) {
        this.setterImpl(1, v.rawData, 'giftCardIds', true, null, () => {this.giftCardIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.giftCardIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.giftCardIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportGiftCardsBoulderadoCsvApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportGiftCardsBoulderadoCsvApiGiftCardIds extends ApiListWrapper<ExportGiftCardsBoulderadoCsvApiGiftCardId>
{
	constructor(override readonly api : ExportGiftCardsBoulderadoCsvApiService | null,
		override readonly parent : ExportGiftCardsBoulderadoCsvApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'giftCardIds');
	}

	override wrapRawData(itemRawData : any) : ExportGiftCardsBoulderadoCsvApiGiftCardId {
		return new ExportGiftCardsBoulderadoCsvApiGiftCardId(this.api, this as unknown as ExportGiftCardsBoulderadoCsvApiGiftCardIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportGiftCardsBoulderadoCsvApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportGiftCardsBoulderadoCsvApiGiftCardIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : ExportGiftCardsBoulderadoCsvApiGiftCardId) => void) | null = null, _backendId : Id | null = null) : ExportGiftCardsBoulderadoCsvApiGiftCardId {
		const newItem = new ExportGiftCardsBoulderadoCsvApiGiftCardId(this.api, this as unknown as ExportGiftCardsBoulderadoCsvApiGiftCardIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('giftCardIds');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiGiftCardIds, ExportGiftCardsBoulderadoCsvApiGiftCardIds> = new ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiGiftCardIds, ExportGiftCardsBoulderadoCsvApiGiftCardIds>({
			apiObjWrapper: this as any as ExportGiftCardsBoulderadoCsvApiGiftCardIds,
			name: 'giftCardIds',
			nodeName: 'GIFT_CARD_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportGiftCardsBoulderadoCsvApiGiftCardIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 1,
		});
	attributeInfoGiftCardId : ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiGiftCardIds, ExportGiftCardsBoulderadoCsvApiGiftCardId> = new ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiGiftCardIds, ExportGiftCardsBoulderadoCsvApiGiftCardId>({
			apiObjWrapper: this as any as ExportGiftCardsBoulderadoCsvApiGiftCardIds,
			name: 'giftCardId',
			nodeName: 'GIFT_CARD_ID',
			defaultValue: function(this : ExportGiftCardsBoulderadoCsvApiGiftCardIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportGiftCardsBoulderadoCsvApiGiftCardId extends ApiObjectWrapper<ExportGiftCardsBoulderadoCsvApiGiftCardId>
{
	constructor(override readonly api : ExportGiftCardsBoulderadoCsvApiService | null,
		override readonly parent : ExportGiftCardsBoulderadoCsvApiGiftCardIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportGiftCardsBoulderadoCsvApiGiftCardId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportGiftCardsBoulderadoCsvApiGiftCardId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiGiftCardId, ExportGiftCardsBoulderadoCsvApiGiftCardId> = new ApiAttributeInfo<ExportGiftCardsBoulderadoCsvApiGiftCardId, ExportGiftCardsBoulderadoCsvApiGiftCardId>({
			apiObjWrapper: this as any as ExportGiftCardsBoulderadoCsvApiGiftCardId,
			name: 'giftCardId',
			nodeName: 'GIFT_CARD_ID',
			defaultValue: function(this : ExportGiftCardsBoulderadoCsvApiGiftCardId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('GIFT_CARD_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '3';
	}

	static async loadDetailed(	api : ExportGiftCardsBoulderadoCsvApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '3', { success: success, error: error, searchParams: searchParams});
	}
}



