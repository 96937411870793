import { AfterContentChecked, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { RightsService } from '@plano/client/accesscontrol/rights.service';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { SchedulingApiAccountingPeriods, SchedulingApiMember, SchedulingApiMembers } from '@plano/client/scheduling/shared/api/scheduling-api.service';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FilterService } from '@plano/client/shared/filter.service';
import { FormattedDateTimePipe } from '@plano/client/shared/formatted-date-time.pipe';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PExportService } from '@plano/client/shared/p-export.service';
import { PSidebarService } from '@plano/client/shared/p-sidebar/p-sidebar.service';
import { SidebarApiListWrapperItemInterface } from '@plano/client/shared/p-sidebar/p-sidebar.types';
import { ExportMembersExcelApiService } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { MeService } from '@plano/shared/core/me/me.service';
import { PCookieService } from '@plano/shared/core/p-cookie.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PButtonType } from '@plano/shared/p-forms/p-button/p-button.component';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-sidebar-members[members][accountingPeriods]',
	templateUrl: './sidebar-members.component.html',
	styleUrls: ['./sidebar-members.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [],
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PSidebarMembersComponent implements PComponentInterface, SidebarApiListWrapperItemInterface<SchedulingApiMember>, AfterContentChecked {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public members ! : SchedulingApiMembers;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onSelectRelatedShifts : EventEmitter<SchedulingApiMember> = new EventEmitter<SchedulingApiMember>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public accountingPeriods : SchedulingApiAccountingPeriods | null = null;

	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column') private _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideMultiSelectBtn : boolean = true;

	constructor(
		public rightsService : RightsService,
		private localize : LocalizePipe,
		public me : MeService,
		public filterService : FilterService,
		private pCurrencyPipe : PCurrencyPipe,
		public pSidebarService : PSidebarService,
		public pWishesService : PWishesService,
		public formattedDateTimePipe : FormattedDateTimePipe,
		public highlightService : HighlightService,
		public exportMembersApiService : ExportMembersExcelApiService,
		private pExportService : PExportService,
		private pCookieService : PCookieService,
	) {
	}

	public searchPopoverIsOpen : boolean = false;
	public searchIsActive = true;

	public config = Config;
	public enums = enumsObject;
	public PBtnThemeEnum = PBtnThemeEnum;
	public PApiPrimitiveTypes = PApiPrimitiveTypes;
	public PButtonType = PButtonType;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	/** @see PSidebarService#editMemberListItemsMode */
	public get editListItemsMode() : PSidebarService['editMemberListItemsMode'] {
		return this.pSidebarService.editMemberListItemsMode;
	}
	public set editListItemsMode(input : PSidebarService['editMemberListItemsMode']) {
		this.pSidebarService.editMemberListItemsMode = input;
	}

	/** @see PSidebarService#filterMembersModeActive */
	public get editFilterModeActive() : PSidebarService['filterMembersModeActive'] {
		return this.pSidebarService.filterMembersModeActive;
	}
	public set editFilterModeActive(input : PSidebarService['filterMembersModeActive']) {
		this.pSidebarService.filterMembersModeActive = input;
	}

	/** @see PSidebarService#memberSearchTerm */
	public get searchTerm() : PSidebarService['memberSearchTerm'] {
		return this.pSidebarService.memberSearchTerm;
	}
	public set searchTerm(input : PSidebarService['memberSearchTerm']) {
		this.pSidebarService.memberSearchTerm = input;
	}

	/**
	 * All trashed members, not matter if visible or not.
	 */
	protected get trashedMembers() : SchedulingApiMembers {
		return this.members.filterBy(item => item.trashed);
	}

	/**
	 * All untrashed members, not matter if visible or not.
	 */
	protected get untrashedMembers() : SchedulingApiMembers {
		return this.members.filterBy(item => !item.trashed);
	}

	/**
	 * Get a translated text for the edit button
	 */
	public get editButtonTitle() : string {
		return this.localize.transform('User Bearbeiten');
	}

	/**
	 * Should the edit button be visible?
	 */
	public get showAddButton() : boolean {
		return !!this.rightsService.isOwner;
	}

	/**
	 * Show details for specific member
	 */
	public showDetails(item : SchedulingApiMember | null) : string {
		if (item) {
			return `/client/member/${item.id.toString()}`;
		} else {
			return '/client/member/';
		}
	}

	/**
	 * Returns all searched items. If search is not active, returns all given items.
	 */
	public get searchedMembers() : SchedulingApiMembers {
		return this.members.search(this.searchTerm);
	}

	public membersForList : {
		trashed : SchedulingApiMembers,
		untrashed : SchedulingApiMembers,
	} = { trashed: null!, untrashed: null! };

	public ngAfterContentChecked() : void {

		/** TODO: this is probably a performance issue */
		this.membersForList.untrashed = this.searchedMembers.filterBy(item => !item.trashed);

		/** TODO: this is probably a performance issue */
		this.membersForList.trashed = this.searchedMembers.filterBy(item => item.trashed);
	}

	/**
	 * Sort a list of members by name
	 */
	public sortedMembersListByFns(list : SchedulingApiMembers) : SchedulingApiMembers {
		return list.sortedBy([
			...defaultSortingForMembers,
		]);
	}

	/** @see SidebarApiListWrapperItemInterface#allItemsAreHiddenBecauseOfFilterSettings */
	public get allItemsAreHiddenBecauseOfFilterSettings() : boolean {
		const ITEMS = this.members;
		if (!ITEMS.length) return false;
		if (!this.filterService.isHideAllMembers(ITEMS)) return false;
		return true;
	}

	public exportIsRunning = false;

	/**
	 * Filter items by current ui settings and start a export download for them.
	 */
	public exportMembers() : void {
		// set members to be exported
		this.exportMembersApiService.setEmptyData();

		for (const member of this.members.iterable()) {
			if (this.filterService.isVisible(member)) {
				this.exportMembersApiService.data.memberIds.push(member.id);
			}
		}

		// download file
		this.exportIsRunning = true;
		const fileName = this.pExportService.getFileName(this.localize.transform('user_export'));
		this.exportMembersApiService.downloadFile(fileName, 'xlsx', null, 'PUT', () => {
			this.exportIsRunning = false;
		});
	}

	/**
	 * Check if this member should be visible in ui or not.
	 */
	public isVisibleItem(input : SchedulingApiMember) : boolean {
		return this.filterService.isVisible(input);
	}

	/**
	 * Check if these members should be visible in ui or not.
	 */
	public isVisibleItems(input : SchedulingApiMembers) : boolean {
		return this.filterService.isVisible(input);
	}

	/**
	 * Should this item be visible in the list?
	 * Beware: this does not only mean, it is visible by filter
	 */
	public showThisMemberInList(member : SchedulingApiMember | null) : boolean {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		return !!member && (this.editFilterModeActive || this.isVisibleItem(member));
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get currencyIcon() : PFaIcon {
		return this.pCurrencyPipe.getCurrencyIcon();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public allMembersIsAffected(members : SchedulingApiMembers) : boolean {
		const someMembersAreVisible = !this.filterService.isHideAllMembers(members);
		const notAllMembersAreVisible = !this.filterService.isVisible(members);
		return someMembersAreVisible && notAllMembersAreVisible;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get filterIsActive() : boolean {
		if (this.isLoading) return false;

		if (this.filterService.isVisible(this.members)) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get itemsFilterTitle() : string | null {
		if (this.isLoading) return null;

		if (
			this.members.length &&
			this.filterService.isHideAllMembers(this.members)
		) return this.localize.transform('Alles per Filter ausgeblendet');

		let counter = 0;
		for (const member of this.members.iterable()) {
			if (!this.filterService.isVisible(member)) counter++;
		}
		const itemsFilterLabel : string = this.localize.transform('Ausgeblendet:');
		const itemsFilterValue : string = this.localize.transform({
			sourceString: '${counter} von ${amount}',
			params: { counter: counter.toString(), amount: this.members.length.toString() },
		});
		return `<span id="members-hidden-filter-label">${itemsFilterLabel}</span>&nbsp;${itemsFilterValue}`;
	}

	/** @see SidebarApiListWrapperItemInterface#showAllItemsFilteredHint */
	public get showAllItemsFilteredHint() : boolean {
		return !this.editFilterModeActive && this.allItemsAreHiddenBecauseOfFilterSettings;
	}

	/** @see SidebarApiListWrapperItemInterface#showSomeItemsFilteredHint */
	public get showSomeItemsFilteredHint() : boolean {
		if (this.showAllItemsFilteredHint) return false;
		return !!this.searchTerm && !this.editFilterModeActive && !!this.filterService.hiddenItemsCount(this.searchedMembers);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public showHeadline(type : 'untrashed' | 'trashed') : boolean {
		if (this.editFilterModeActive) return true;
		const members = type === 'trashed' ? this.trashedMembers : this.untrashedMembers;
		if (!members.length) return false;
		if (this.searchTerm) return true;
		if (this.filterService.isVisible(members)) return true;
		if (this.filterService.someMembersAreVisible(members)) return true;

		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public toggleList(type : 'untrashed' | 'trashed') : void {
		const cookieKeyData = {
			prefix : 'SidebarMemberComponent' as const,
			name : type,
		};
		if (this.isCollapsed(type)) {
			return this.pCookieService.showSection(cookieKeyData);
		}
		return this.pCookieService.hideSection(cookieKeyData);
	}

	/**
	 * Is the list collapsed?
	 * null if there is no possibility to collapse the list, because the section is not visible.
	 * @param type - Which list should be checked?
	 */
	public isCollapsed(type : 'untrashed' | 'trashed') : boolean | null {
		// If there are no headlines to click, then there is nothing that can be collapsed.
		const userCanCollapseSection = type === 'trashed' ? this.showHeadline('trashed') : this.showHeadline('untrashed');
		if (!userCanCollapseSection) return null;
		const cookieKeyData = {
			prefix : 'SidebarMemberComponent' as const,
			name : type,
		};
		return !this.pCookieService.sectionIsVisible(cookieKeyData, type === 'untrashed');
	}

	/** Should the export button be visible? */
	public get userCanExport() : boolean {
		// TODO: PLANO-174740
		if (Config.platform !== 'browser') return false;
		return this.exportMembersApiService.data.attributeInfoThis.hasRightToSet === true;
	}
}
