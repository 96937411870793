<div class="flex-grow-1 d-flex flex-column">
	<div class="flex-grow-0 d-flex pl-3 pr-3 pt-3 pb-2">
		<div class="flex-grow-1 d-flex gap-2">
			<p-button
				[buttonType]="PButtonType.TOGGLE"
				[icon]="null"
				[theme]="enums.PThemeEnum.PRIMARY"
				[isActiveButton]="!!editFilterModeActive"
				[darkMode]="true"
				[size]="enums.BootstrapSize.SM"
				(triggerClick)="editFilterModeActive=!editFilterModeActive"

				[pTooltip]="!editFilterModeActive ? (filterIsActive ? itemsFilterTitlePopover : 'Filter') : null"
				[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
			><span class="nowrap"><fa-icon
				[class.text-white]="!editFilterModeActive"
				[fixedWidth]="true"
				[icon]="enums.PlanoFaIconPool.FILTER"
			></fa-icon><span *ngIf="editFilterModeActive || filterIsActive">&nbsp;<p-led [theme]="enums.PThemeEnum.PRIMARY" [off]="!filterIsActive"></p-led></span></span></p-button>
			<ng-template #itemsFilterTitlePopover>
				<span [innerHTML]="itemsFilterTitle! | pSafeHtml"></span>
			</ng-template>
			<p-button
				[buttonType]="PButtonType.TOGGLE"
				[icon]="null"
				[theme]="enums.PThemeEnum.PRIMARY"
				[isActiveButton]="!!editListItemsMode"
				[darkMode]="true"
				[size]="enums.BootstrapSize.SM"
				(triggerClick)="editListItemsMode=!editListItemsMode"

				[pTooltip]="editButtonTitle"
				[ariaLabel]="editButtonTitle"
				[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
			><fa-icon
				[class.text-white]="!editListItemsMode"
				[fixedWidth]="true" icon="pen"
			></fa-icon></p-button>
			<p-button
				ariaLabel="Verdienstanzeige" i18n-ariaLabel
				pTooltip="Verdienstanzeige" i18n-pTooltip
				[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

				[buttonType]="PButtonType.TOGGLE"
				[icon]="null"
				[theme]="enums.PThemeEnum.PRIMARY"
				[isActiveButton]="!!pSidebarService.isWorkloadMode"
				[darkMode]="true"
				[size]="enums.BootstrapSize.SM"
				(triggerClick)="pSidebarService.isWorkloadMode=!pSidebarService.isWorkloadMode"
			><fa-icon
				[class.text-white]="!pSidebarService.isWorkloadMode"
				[fixedWidth]="true" [icon]="currencyIcon"
			></fa-icon></p-button>
			<p-button
				*ngIf="userCanExport"
				pTooltip="Exportieren" i18n-pTooltip
				[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

				[darkMode]="true"
				[size]="enums.BootstrapSize.SM"
				[disabled]="exportIsRunning"
				(triggerClick)="exportMembers()"
			><fa-icon
				[fixedWidth]="true" [icon]="exportIsRunning ? enums.PlanoFaIconPool.SYNCING : enums.PlanoFaIconPool.EXPORT" [spin]="exportIsRunning"
			></fa-icon></p-button>
			<a
				[routerLink]="!!isLoading ? null : showDetails(null)"
				[disabled]="!!isLoading"
				class="ml-auto">
				<p-button
					*ngIf="rightsService.isOwner"
					[darkMode]="true"
					[theme]="PBtnThemeEnum.OUTLINE_PRIMARY"
					[size]="enums.BootstrapSize.SM"
					pTooltip="User hinzufügen" i18n-pTooltip
					ariaLabel="User hinzufügen" i18n-ariaLabel
					[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
				><fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon></p-button>
			</a>
		</div>
	</div>
	<div class="flex-grow-1 position-relative">
		<scroll-shadow-box [backgroundStyle]="enums.PThemeEnum.DARK">
			<div
				[class.pb-tawk]="config.IS_MOBILE"
				class="px-3 pb-lg-2"
			>
				<p-input
					class="mt-1 mb-3"
					[type]="PApiPrimitiveTypes.Search"
					[(ngModel)]="searchTerm"
					[theme]="!!searchTerm ? null : PBtnThemeEnum.OUTLINE_DARK"
					[class.flex-grow-1]="searchIsActive"
					[size]="enums.BootstrapSize.SM"
				></p-input>

				<div
					id="filter-members"
					*ngIf="editFilterModeActive && itemsFilterTitle"
					class="crop-on-overflow my-3"
					aria-labelledby="members-hidden-filter-label">
					<span><fa-icon
						[class.text-primary]="filterIsActive"
						[class.text-white]="!filterIsActive"
						[style.opacity]="filterIsActive ? '1' : '0.2'"
						[fixedWidth]="true"
						[icon]="enums.PlanoFaIconPool.FILTER"
					></fa-icon>&nbsp;<span class="text-white" [innerHTML]="itemsFilterTitle | pSafeHtml"></span></span>
				</div>
				<nav class="d-block my-2" *ngIf="pSidebarService.isWorkloadMode && accountingPeriods">
					<label *ngIf="me.isLoaded() && me.data.isOwner"><a target="_self" routerLink="/client/account" fragment="abrechnungszeitraum" i18n>Lohn-Abrechnungszeitraum:</a></label>
					<label *ngIf="!(me.isLoaded() && me.data.isOwner)" class="text-white" i18n>Lohn-Abrechnungszeitraum:</label>
					<div class="text-white">
						<ng-template [ngIf]="!isLoading" [ngIfElse]="spinnerTemplate">
						<ng-template [ngIf]="accountingPeriods.length === 1">
							<div class="d-flex justify-content-between align-items-center mb-2">
								<p-checkbox
									[(ngModel)]="pSidebarService.showWorkload[1]"
									[hasButtonStyle]="false"
									class="flex-grow-1 mr-2"
									valueText="{{ formattedDateTimePipe.getFormattedDateInfo(accountingPeriods.get(0)!.start, accountingPeriods.get(0)!.end, true, true).full }}"
								></p-checkbox>
								<img alt="Icon für die Auslastungsanzeige" i18n-alt src="images/miniatur-bottom.png">
							</div>
						</ng-template>
						<ng-template [ngIf]="accountingPeriods.length === 2">
							<div class="d-flex justify-content-between align-items-center ml-1 mb-2">
								<p-checkbox
									[(ngModel)]="pSidebarService.showWorkload[0]"
									[hasButtonStyle]="false"
									class="flex-grow-1 mr-2"
									valueText="{{ formattedDateTimePipe.getFormattedDateInfo(accountingPeriods.get(0)!.start, accountingPeriods.get(0)!.end, true, true).full }}"
								></p-checkbox>
								<img alt="Icon für die Auslastungsanzeige" i18n-alt src="images/miniatur-top.png">
							</div>
							<div class="d-flex justify-content-between align-items-center ml-1 mb-2">
								<p-checkbox
									[(ngModel)]="pSidebarService.showWorkload[1]"
									[hasButtonStyle]="false"
									class="flex-grow-1 mr-2"
									valueText="{{ formattedDateTimePipe.getFormattedDateInfo(accountingPeriods.get(1)!.start, accountingPeriods!.get(1)!.end, true, true).full }}"
								></p-checkbox>
								<img alt="Icon für die Auslastungsanzeige" i18n-alt src="images/miniatur-bottom.png">
							</div>
						</ng-template>
						</ng-template>
					</div>
				</nav>
				<div class="mb-0">
					<div class="list-group list-group-flush members"
						*ngIf="!isLoading; else spinnerTemplate"
					>
						<ng-template
							[ngIf]="!showAllItemsFilteredHint"
							[ngIfElse]="everyItemFilteredTemplate"
						>
							<list-headline
								class="card mb-1 mt-2 o-hidden"
								*ngIf="showHeadline('untrashed')"
								headlineIconAlign="left"
								[class.trashed]="false"
								text="Aktive User" i18n-text
								(onClick)="toggleList('untrashed')"
							>
								<fa-icon headline-icon [icon]="isCollapsed('untrashed') === false ? enums.PlanoFaIconPool.COLLAPSIBLE_OPEN : enums.PlanoFaIconPool.COLLAPSIBLE_CLOSE"></fa-icon>
								<!-- linthtml-configure tag-req-attr="false" -->
								<button
									*ngIf="editFilterModeActive"
									[disabled]="membersForList.untrashed.length===0"
									(click)="$event.stopPropagation();filterService.toggleMembers(membersForList.untrashed)"
									[class.affected]="allMembersIsAffected(membersForList.untrashed)"
									class="card-option btn btn-sm pt-1 pb-1 d-flex align-items-center justify-content-center btn-secondary"
								><fa-icon [class.text-primary]="!(allMembersIsAffected(membersForList.untrashed) || isVisibleItems(membersForList.untrashed))" [icon]="allMembersIsAffected(membersForList.untrashed) || isVisibleItems(membersForList.untrashed) ? enums.PlanoFaIconPool.VISIBLE : enums.PlanoFaIconPool.INVISIBLE"></fa-icon></button>
							</list-headline>

							<ng-container *ngIf="isCollapsed('untrashed') === false">
								<ng-container *ngIf="membersForList.untrashed.length > 0; else noUntrashedMembers">
									<ng-template
										ngFor let-member
										[ngForOf]="sortedMembersListByFns(membersForList.untrashed).iterable()"
									>
										<ng-container *ngTemplateOutlet="memberListItemTemplate; context: {$implicit: member}"></ng-container>
									</ng-template>
								</ng-container>
								<ng-template #noUntrashedMembers>
									<p-list-item
										*ngIf="!!searchTerm"
										size="frameless"
										class="border border-info rounded o-hidden card-options justify-content-center my-2 p-2 bg-info text-white"
									>
										<div class="d-flex gap-2 text-small">
											<fa-icon [icon]="enums.PlanoFaIconPool.SEARCH"/>
											<span i18n>Nichts Passendes gefunden.</span>
										</div>
									</p-list-item>
								</ng-template>
							</ng-container>
							<ng-template #deletedUsersHeadline>
								<div class="d-flex align-items-center justify-content-center gap-1">
									<fa-icon [icon]="enums.PlanoFaIconPool.TRASHED"/>
									<span i18n>Gelöschte User</span>
								</div>
							</ng-template>
							<list-headline
								class="card mb-1 mt-2 o-hidden border-danger border-0"
								*ngIf="showHeadline('trashed')"
								[theme]="enums.PThemeEnum.DANGER"
								headlineIconAlign="left"
								[class.trashed]="true"
								[text]="deletedUsersHeadline"
								(onClick)="toggleList('trashed')"
							>
								<fa-icon headline-icon [icon]="isCollapsed('trashed') === false ? enums.PlanoFaIconPool.COLLAPSIBLE_OPEN : enums.PlanoFaIconPool.COLLAPSIBLE_CLOSE"></fa-icon>
								<!-- linthtml-configure tag-req-attr="false" -->
								<button
									*ngIf="editFilterModeActive"
									[disabled]="membersForList.trashed.length===0"
									(click)="$event.stopPropagation();filterService.toggleMembers(membersForList.trashed)"
									[class.affected]="allMembersIsAffected(membersForList.trashed)"
									class="card-option btn btn-sm pt-1 pb-1 d-flex align-items-center justify-content-center btn-secondary"
								><fa-icon [class.text-primary]="!(allMembersIsAffected(membersForList.trashed) || isVisibleItems(membersForList.trashed))" [icon]="allMembersIsAffected(membersForList.trashed) || isVisibleItems(membersForList.trashed) ? enums.PlanoFaIconPool.VISIBLE : enums.PlanoFaIconPool.INVISIBLE"></fa-icon></button>
							</list-headline>

							<ng-container *ngIf="isCollapsed('trashed') === false">
								<ng-container *ngIf="membersForList.trashed.length > 0; else noTrashedMembers">
									<ng-template
										ngFor let-member
										[ngForOf]="sortedMembersListByFns(membersForList.trashed).iterable()"
									>
										<ng-container *ngTemplateOutlet="memberListItemTemplate; context: {$implicit: member}"></ng-container>
									</ng-template>
								</ng-container>
								<ng-template #noTrashedMembers>
									<p-list-item
										size="frameless"
										class="border border-info rounded o-hidden card-options justify-content-center my-2 p-2 bg-info text-white"
									>
										<span *ngIf="!trashedMembers.length" i18n>Es gibt keine gelöschten User.</span>
										<div *ngIf="!!trashedMembers.length" class="d-flex gap-2">
											<fa-icon [icon]="enums.PlanoFaIconPool.SEARCH"/>
											<span i18n>Nichts Passendes gefunden.</span>
										</div>
									</p-list-item>
								</ng-template>
							</ng-container>
						</ng-template>
						<ng-template #everyItemFilteredTemplate>
							<ng-template
								[ngTemplateOutlet]="everythingFilteredTemplate"
								[ngTemplateOutletContext]="{$implicit: members}"
							></ng-template>
						</ng-template>
					</div>
				</div>
				<ng-template [ngIf]="showSomeItemsFilteredHint">
					<p-alert
						class="mt-2"
						[theme]="enums.PThemeEnum.SECONDARY"
						[dismissable]="false"
					>
						<div class="mb-2" i18n>Übrigens… {{filterService.hiddenItemsCount(searchedMembers)}} {filterService.hiddenItemsCount(searchedMembers), plural, =1 {weiteres Suchergebnis ist} other {weitere Suchergebnisse sind}} durch deine Filter-Einstellung nicht sichtbar.</div>
						<p-button
							(triggerClick)="editFilterModeActive=!editFilterModeActive"
							[theme]="enums.PThemeEnum.PRIMARY"
							[darkMode]="true"
							i18n
						>Filter anzeigen</p-button>
					</p-alert>
				</ng-template>
			</div>
		</scroll-shadow-box>
	</div>
</div>

<ng-template #everythingFilteredTemplate let-items>
	<p-alert
		class="mt-2"
		[theme]="enums.PThemeEnum.SECONDARY"
		[dismissable]="false"
	>
		<div class="mb-2">
			<span i18n>Aktuell sind alle <mark>{{filterService.hiddenItemsCount(items)}}</mark> User mit Hilfe der Filter-Funktion ausgeblendet.</span>
		</div>
		<p-button
			(triggerClick)="editFilterModeActive=!editFilterModeActive"
			[theme]="enums.PThemeEnum.PRIMARY"
			[darkMode]="true"
			i18n
		>Filter anzeigen</p-button>
	</p-alert>
</ng-template>
<ng-template #spinnerTemplate>
	<div class="card m-0">
		<p-spinner [size]="enums.BootstrapSize.LG" class="m-3"></p-spinner>
	</div>
</ng-template>

<ng-template #memberListItemTemplate let-member>
	<p-member-list-item
		*ngIf="showThisMemberInList(member)"
		class="mb-1 hover-hide-trashed"
		[member]="member"
		(onItemClick)="highlightService.isHighlighted($event) ? pWishesService.item = null : pWishesService.item = $event;highlightService.isHighlighted($event) ? highlightService.setHighlighted(null) : highlightService.setHighlighted($event)"
		(onSelectInCalendarClick)="onSelectRelatedShifts.emit(member)"
		[showExpectedEarnings]="true"
		[editFilterModeActive]="!!editFilterModeActive"
		[editListItemsMode]="!!editListItemsMode"
		[hideMultiSelectBtn]="hideMultiSelectBtn"
	></p-member-list-item>
</ng-template>
