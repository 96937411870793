import { NgModule } from '@angular/core';
import { PPopperArrowColorDirective } from './p-popper-arrow-color.directive';

// cSpell:ignore youtube
import { PTooltipDirective } from './tooltip.directive';

@NgModule({
	imports: [
	],
	declarations: [
		PPopperArrowColorDirective,
		PTooltipDirective,
	],
	providers: [],
	exports: [
		PPopperArrowColorDirective,
		PTooltipDirective,
	],
})
export class PTooltipModule {}