<p-sidebar #sidebar
	class="d-flex flex-column"
	[showStickyNoteIcon]="hasImportantNoteForToday"
	[showStickyNoteIconDot]="hasTodaysShiftDescriptionsForMember"
	[showFilterIcon]="filterService.hasSideBarFilters"
	[showCollapseButton]="showCollapseButtonOnSidebar"
	[badgeContent]="badgeContent"
>
	<ng-template [ngIf]="!sidebar.collapsed">
		<p-tabs
			class="m-0 rounded-0 flex-grow-1 o-hidden"
			[card]="false"
			[size]="enums.BootstrapSize.SM"
			[minHeaderTabBar]="46"
			[showIconOnlyBtns]="true"
			[darkMode]="true"
			*ngIf="shiftModelsForList !== undefined && membersForList !== undefined"
			[noWrap]="true"
			#pTabsComponent
		>
			<p-tab
				label="Dein Schreibtisch" i18n-label
				class="flex-grow-1 d-flex flex-column p-3 position-relative"
				icon="inbox"
				[badgeContent]="badgeContent"
				[initialActiveTab]="pSidebarService.currentTab===SidebarTab.DESK"
				(select)="openTab(SidebarTab.DESK)"
				[size]="PTabSizeEnum.FRAMELESS"
				[show]="true"
				[canSet]="true"
				#deskTab
			>
				<scroll-shadow-box [backgroundStyle]="enums.PThemeEnum.DARK">
					<p-sidebar-desk
						[class.pb-tawk]="config.IS_MOBILE"
						class="flex-grow-1 d-flex flex-column pb-lg-3 pt-3 px-3"
						[isLoading]="!me.isLoaded() || !api.isLoaded() || api.isLoadOperationRunning"
						[shiftExchanges]="shiftExchangesForList"
						(navToShiftExchanges)="navToShiftExchanges()"
					></p-sidebar-desk>
				</scroll-shadow-box>
			</p-tab>
			<p-tab
				class="flex-grow-1 d-flex flex-column"
				*ngIf="shiftModelsForList"
				label="Tätigkeiten" i18n-label
				[icon]="enums.PlanoFaIconPool.ITEMS_SHIFT_MODELS"
				[initialActiveTab]="pSidebarService.currentTab===SidebarTab.SHIFT_MODELS"
				(select)="openTab(SidebarTab.SHIFT_MODELS)"
				[hasFilter]="!filterService.isVisible(shiftModelsForList)"
				[filterDescription]="!filterService.isVisible(shiftModelsForList) ? 'Filter Aktiv' : 'Filter nicht aktiv'"
				[size]="PTabSizeEnum.FRAMELESS"
				[show]="true"
				[canSet]="true"
				#shiftModelsTab
			>
				<p-sidebar-shiftmodels
					class="flex-grow-1 d-flex flex-column"
					[shiftModels]="shiftModelsForList"
					[changeDetection]="pSidebarService.currentTab===SidebarTab.SHIFT_MODELS"
					[isLoading]="!api.isLoaded() || !me.isLoaded()"
					(onSelectRelatedShifts)="onSelectShiftsRelatedToShiftModel($event.id)"
					[hideMultiSelectBtn]="hideMultiSelectBtn"
				></p-sidebar-shiftmodels>
			</p-tab>
			<p-tab
				class="flex-grow-1 d-flex flex-column"
				*ngIf="membersForList"
				label="User" i18n-label
				icon="users"
				[initialActiveTab]="pSidebarService.currentTab===SidebarTab.MEMBERS"
				(select)="openTab(SidebarTab.MEMBERS)"
				[hasFilter]="!filterService.isVisible(membersForList)"
				[filterDescription]="!filterService.isVisible(membersForList) ? 'Filter Aktiv' : 'Filter nicht aktiv'"
				[size]="PTabSizeEnum.FRAMELESS"
				[show]="true"
				[canSet]="true"
				#membersTab
			>
				<p-sidebar-members
					class="flex-grow-1 d-flex flex-column"
					[members]="membersForList"
					[accountingPeriods]="api.data.attributeInfoAccountingPeriods.isAvailable ? api.data.accountingPeriods : null"
					[changeDetection]="pSidebarService.currentTab===SidebarTab.MEMBERS"
					[isLoading]="!api.isLoaded() || !me.isLoaded()"
					(onSelectRelatedShifts)="onSelectShiftsRelatedToMember($event.id)"
					[hideMultiSelectBtn]="hideMultiSelectBtn"
				></p-sidebar-members>
			</p-tab>
		</p-tabs>
		<div class="sidebar-dr"></div>
	</ng-template>
</p-sidebar>
