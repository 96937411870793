/* NOTE: Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-lines: ["error", 1200] */

/**	NOTE: Do not make this service more complex than it already is */
/* eslint complexity: ["error", 20]  */
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SchedulingApiShiftModel } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { SchedulingApiShift } from '@plano/client/scheduling/shared/api/scheduling-api-shift.service';
import { NgbFormatsService } from '@plano/client/service/ngbformats.service';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { RightsService, SchedulingApiBookingDesiredDateSetting, SchedulingApiCourseType, SchedulingApiNotificationsConf, SchedulingApiPaymentMethodType, SchedulingApiShiftModelCourseTariffFee, SchedulingApiShiftModelNeededMembersCountConf, SchedulingApiShiftNeededMembersCountConf, SchedulingApiWorkingTimeCreationMethod, ShiftModelRepetitionEndMode } from '@plano/shared/api';
import { Duration, PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { LogService } from '@plano/shared/core/log.service';
import { PDurationTimePipe, SupportedDurationTimePipeUnits } from '@plano/shared/core/pipe/duration-time.pipe';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidationErrorValue, PValidationErrors, PValidatorObject } from '@plano/shared/core/validators.types';
import { PFormArrayBasedOnAI, PFormControl, PFormGroupBasedOnAI } from '@plano/shared/p-forms/p-form-control';
import { PShiftmodelTariffService } from '@plano/shared/p-forms/p-shiftmodel-tariff.service';

/**
 * Some day the form should be completely refactored to attributeInfo.
 * This type exists is here to make it easier / more secure to refactor things.
 * If you want to refactor the form to AI, start by removing a child from the formGroup here.
 */
export type CourseTariffFeeFormGroup = PFormGroupBasedOnAI<{
	'reference' : PFormControl<SchedulingApiShiftModelCourseTariffFee>,
}>;

/**
 * Some day the form should be completely refactored to attributeInfo.
 * This type exists is here to make it easier / more secure to refactor things.
 * If you want to refactor the form to AI, start by removing a child from the formGroup here.
 */
export type CourseTariffFeesFormArray = FormArray<CourseTariffFeeFormGroup>;

/**
 * Some day the form should be completely refactored to attributeInfo.
 * This type exists is here to make it easier / more secure to refactor things.
 * If you want to refactor the form to AI, start by removing a child from the formGroup here.
 */
export type CourseTariffsFormArray = PFormArrayBasedOnAI<PFormGroupBasedOnAI>;

/**
 * Some day the form should be completely refactored to attributeInfo.
 * This type exists is here to make it easier / more secure to refactor things.
 * If you want to refactor the form to AI, start by removing a child from the formGroup here.
 */
export type NeededMembersCountConfFormGroup = FormGroup<{
	'reference' : PFormControl<SchedulingApiShiftNeededMembersCountConf | SchedulingApiShiftModelNeededMembersCountConf>,
}>;

/**
 * Some day the form should be completely refactored to attributeInfo.
 * This type exists is here to make it easier / more secure to refactor things.
 * If you want to refactor the form to AI, start by removing a child from the formGroup here.
 * @deprecated We decided to remove this as we switch to AI
 */
export type ShiftAndShiftModelFormType = FormGroup<{
	'neededMembersCountConf' : NeededMembersCountConfFormGroup,
	'ngbStartDate' ?: PFormControl,
	'startDate' : PFormControl,
	'color' : PFormControl,
	'type' ?: PFormControl,

	'isCourse' : PFormControl<boolean>,

	'courseType' : PFormControl<SchedulingApiCourseType>,
	'courseBookingDeadlineFrom' : PFormControl<Duration | null>,
	'courseBookingDeadlineUntil' : PFormControl,
	'currentCancellationPolicy' : FormGroup,

	'courseCode' : PFormControl,

	'freeclimberArticleId' : PFormControl,
	'sendEmail' : PFormControl,
}>;

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftAndShiftmodelFormService {
	constructor(
		private pFormsService : PFormsService,
		private rightsService : RightsService,
		private ngbFormats : NgbFormatsService,
		private validators : ValidatorsService,
		public pShiftmodelTariffService : PShiftmodelTariffService,
		private localize : LocalizePipe,
		private console : LogService,
		private pDurationTimePipe : PDurationTimePipe,
	) {
		this.intervalEndDateModesIterable = [
			{ title: this.localize.transform('Nie'), enum: ShiftModelRepetitionEndMode.NEVER },
			{ title: this.localize.transform('Nach X Wiederholungen'), enum: ShiftModelRepetitionEndMode.AFTER_X_TIMES },
			{ title: this.localize.transform('An dem Datum X'), enum: ShiftModelRepetitionEndMode.ENDS_AFTER_DATE },
		];
	}

	public modeIsEditShift : boolean | null = null;
	public modeIsEditShiftModel : boolean | null = null;
	public modeIsCreateShift : boolean | null = null;
	public modeIsCreateShiftModel : boolean | null = null;

	public paymentMethodTypes = SchedulingApiPaymentMethodType;

	public intervalEndDateModesIterable : { title : string, enum : ShiftModelRepetitionEndMode }[];

	public now ! : number;

	/**
	 * Initialize all formGroup values, states, validators, subscribers etc.
	 */
	// eslint-disable-next-line sonarjs/cognitive-complexity
	public initFormGroup(
		formItem : SchedulingApiShift | SchedulingApiShiftModel,
		userCanWrite : boolean,
		notificationsConf : SchedulingApiNotificationsConf,
		shiftModel : SchedulingApiShiftModel | null | undefined,
	) : ShiftAndShiftModelFormType {
		const model : SchedulingApiShiftModel = formItem instanceof SchedulingApiShiftModel ? formItem : shiftModel!;

		const tempFormGroup = this.pFormsService.group({}) as unknown as ShiftAndShiftModelFormType;

		this.addControlsForBasis(tempFormGroup, formItem, userCanWrite, model);

		if (formItem.attributeInfoWorkingTimeCreationMethod.isAvailable) {
			this.pFormsService.addControl(
				tempFormGroup,
				'workingTimeCreationMethodIsViaTimestamp',
				{
					value: formItem.workingTimeCreationMethod === SchedulingApiWorkingTimeCreationMethod.TIME_STAMP,
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
					disabled: (this.modeIsEditShiftModel && model.trashed) || !this.rightsService.isOwner || !userCanWrite,
				},
				[this.validators.required(PApiPrimitiveTypes.boolean)],
				value => {
					if (value) {
						formItem.workingTimeCreationMethod = SchedulingApiWorkingTimeCreationMethod.TIME_STAMP;
					} else {
						formItem.workingTimeCreationMethod = SchedulingApiWorkingTimeCreationMethod.AUTOMATIC;
					}
				},
			);

		}

		this.pFormsService.addControl(
			tempFormGroup,
			'isCourse',
			{
				value: model.isCourse,
				disabled: (this.formIsDisabled(model, userCanWrite) ||
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
				this.modeIsEditShift || this.modeIsCreateShift) ?? false,
			},
			[
			],
			value => {
				model.isCourse = value;
				if (tempFormGroup.get('courseType') && !tempFormGroup.get('courseType')!.value) {
					tempFormGroup.get('courseType')!.setValue(SchedulingApiCourseType.ONLINE_BOOKABLE);
				}
				if (value && !model.courseCodePrefix) {
					model.courseCodePrefix = model.parent!.getDefaultPrefix(model);
				}
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'courseCode',
			{
				disabled: this.formIsDisabled(model, userCanWrite),
			},
		);

		this.pFormsService.addControl(
			tempFormGroup,
			'freeclimberArticleId',
			{
				value: model.freeclimberArticleId ?? undefined,
				disabled: model.trashed || !userCanWrite,
			},
			[
				this.validators.freeclimberArticleId(PApiPrimitiveTypes.string),
			],
			(value : string) => {
				if (!value || value === '0') {
					model.freeclimberArticleId = null;
					return;
				}
				model.freeclimberArticleId = +value;
			},
		);

		this.pFormsService.addControl(
			tempFormGroup,
			'courseType',
			{
				value: model.courseType,
				disabled: this.formIsDisabled(model, userCanWrite),
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					// FIXME: PLANO-15096
					if (!model.isCourse) return null;
					return this.validators.required(model.attributeInfoCourseType.primitiveType).fn(control);
				}}),
			],
			value => {
				model.courseType = value;
				if (
					model.attributeInfoBookingDesiredDateSetting.isAvailable &&
					model.attributeInfoBookingDesiredDateSetting.value !== SchedulingApiBookingDesiredDateSetting.DESIRED_DATE_NOT_ALLOWED &&
					value !== SchedulingApiCourseType.ONLINE_INQUIRY
				) {
					model.attributeInfoBookingDesiredDateSetting.value = null;
				}
			},
		);

		this.addShiftModelCourseSpecificControls(tempFormGroup, formItem, userCanWrite, model);
		this.addShiftSpecificControls(tempFormGroup, formItem, userCanWrite, model);

		if (formItem.isPacket === null) this.console.error('Could not get formItem.isPacket');
		this.setIntervalEndDateModesIterableTitle(formItem.isPacket ?? false);

		this.pFormsService.addControl(
			tempFormGroup,
			'sendEmail',
			{
				value : notificationsConf.sendEmail,
				disabled: false,
			},
			[this.validators.required(notificationsConf.attributeInfoSendEmail.primitiveType)],
			(value : boolean) : void => {
				notificationsConf.sendEmail = value;
			},
		);

		return tempFormGroup;
	}

	private addControlsForBasis(
		tempFormGroup : ShiftAndShiftModelFormType,
		formItem : SchedulingApiShift | SchedulingApiShiftModel,
		userCanWrite : boolean,
		model : SchedulingApiShiftModel,
	) : void {
		this.pFormsService.addControl(
			tempFormGroup,
			'color',
			{
				value : model.color,
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
				disabled: (this.modeIsEditShiftModel && model.trashed) || !this.rightsService.isOwner,
			},
			[this.validators.required(model.attributeInfoColor.primitiveType)],
			value => {
				model.color = value;
			},
		);
		this.pFormsService.addFormGroup(
			tempFormGroup,
			'neededMembersCountConf',
			this.pFormsService.group({
				reference: formItem.neededMembersCountConf,
			}) as NeededMembersCountConfFormGroup,
		);

		this.pFormsService.addControl(
			tempFormGroup,
			'description',
			{
				value : formItem.description,
				disabled: this.formIsDisabled(model, userCanWrite),
			},
		);
	}

	private addShiftSpecificControls(
		tempFormGroup : ShiftAndShiftModelFormType,
		formItem : SchedulingApiShift | SchedulingApiShiftModel,
		userCanWrite : boolean,
		model : SchedulingApiShiftModel,
	) : void {
		if (!(formItem instanceof SchedulingApiShift)) return;

		this.pFormsService.addControl(
			tempFormGroup,
			'ngbStartDate',
			{
				value: this.ngbFormats.timestampToDateStruct(formItem.start),
				disabled: this.formIsDisabled(model, userCanWrite),
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'startDate',
			{
				value: formItem.start,
				disabled: this.formIsDisabled(model, userCanWrite),
			},
			[
				this.validators.required(PApiPrimitiveTypes.DateTime),
				this.validators.max(formItem.end, true, formItem.attributeInfoStart.primitiveType),
			],
			value => {
				formItem.start = value;

				const timestamp = this.ngbFormats.timestampToDateStruct(value);
				tempFormGroup.get('ngbStartDate')!.setValue(timestamp);
				tempFormGroup.get('ngbStartDate')!.updateValueAndValidity();

				tempFormGroup.get('workingTimeCreationMethodIsViaTimestamp')!.updateValueAndValidity();
			},
		);
	}

	private setIntervalEndDateModesIterableTitle(isPacket : boolean) : void {
		if (isPacket) {
			this.intervalEndDateModesIterable[1].title = this.localize.transform('Nach X Paketen');
		} else {
			this.intervalEndDateModesIterable[1].title = this.localize.transform('Nach X Schichten');
		}
	}

	private formIsDisabled(
		model : SchedulingApiShiftModel,
		userCanWrite : boolean,
	) : boolean {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		return (this.modeIsEditShiftModel && model.trashed) || !userCanWrite;
	}

	private durationToNumberOfDays(duration : number | null) : number {
		return +this.pDurationTimePipe.transform(duration, SupportedDurationTimePipeUnits.DAYS, false, true).split(' ')[0];
	}

	private courseBookingDeadlineFromMinValidator(
		model : SchedulingApiShiftModel,
	) : PValidatorObject<'sync', PValidationErrors<(
		PValidationErrorValue & { min : number; }) |
		(PValidationErrorValue & { name : PPossibleErrorNames.GREATER_THAN; actual : number; greaterThan : number; })
	>> {
		return new PValidatorObject({name: PPossibleErrorNames.MIN, fn: (control) => {
			// TODO: PLANO-156519
			// eslint-disable-next-line sonarjs/no-collapsible-if
			if (model.attributeInfoCourseBookingDeadlineFrom.isAvailable) {
				if (!model.courseBookingDeadlineFrom || model.attributeInfoCourseBookingDeadlineFrom.value === null) return null;
			}

			// TODO: PLANO-156519
			// eslint-disable-next-line sonarjs/no-collapsible-if
			if (model.attributeInfoCourseBookingDeadlineUntil.isAvailable) {
				if (!model.courseBookingDeadlineUntil || model.attributeInfoCourseBookingDeadlineUntil.value === null) return null;
			}
			if (!model.attributeInfoCourseBookingDeadlineUntil.isAvailable) return null;

			// This control comes in milliseconds but we want to show the errors in days
			if (control.value === null) return null;

			if (Number.isNaN(+control.value)) return null;
			const tempControl = new FormControl(this.durationToNumberOfDays(control.value));
			return this.validators.min(
				this.durationToNumberOfDays(model.courseBookingDeadlineUntil),
				true,
				PApiPrimitiveTypes.Days,
			).fn(tempControl);
		}});
	}

	/* eslint sonarjs/cognitive-complexity: ["warn", 24] */
	private addShiftModelCourseSpecificControls(
		tempFormGroup : ShiftAndShiftModelFormType,
		formItem : SchedulingApiShift | SchedulingApiShiftModel,
		userCanWrite : boolean,
		model : SchedulingApiShiftModel,
	) : void {
		if (!(formItem instanceof SchedulingApiShiftModel)) return;

		this.pFormsService.addControl(
			tempFormGroup,
			'courseBookingDeadlineFrom',
			{
				value: model.courseBookingDeadlineFrom,
				disabled: this.formIsDisabled(model, userCanWrite) || !formItem.isCourse,
			},
			[
				this.validators.integerDaysDuration(),
				this.validators.min(
					0,
					true,
					model.attributeInfoCourseBookingDeadlineFrom.primitiveType as Exclude<PApiPrimitiveTypes, PApiPrimitiveTypes.ApiList>,
				),
				this.courseBookingDeadlineFromMinValidator(model),
			],
			(value : Duration) => {
				// eslint-disable-next-line unicorn/prefer-number-properties
				if (isNaN(value)) return;
				if (model.attributeInfoCourseBookingDeadlineFrom.canSet) model.courseBookingDeadlineFrom = value;
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'courseBookingDeadlineUntil',
			{
				value: model.courseBookingDeadlineUntil,
				disabled: this.formIsDisabled(model, userCanWrite),
			},
			[
				this.validators.integerDaysDuration(),
				this.validators.min(
					0,
					true,
					model.attributeInfoCourseBookingDeadlineUntil.primitiveType as Exclude<PApiPrimitiveTypes, PApiPrimitiveTypes.ApiList>,
				),
				new PValidatorObject({name: PPossibleErrorNames.MAX, fn: (control) => {
					// TODO: PLANO-156519
					// eslint-disable-next-line sonarjs/no-collapsible-if
					if (model.attributeInfoCourseBookingDeadlineFrom.isAvailable) {
						if (!model.courseBookingDeadlineFrom || model.attributeInfoCourseBookingDeadlineFrom.value === null) return null;
					}

					// TODO: PLANO-156519
					// eslint-disable-next-line sonarjs/no-collapsible-if
					if (model.attributeInfoCourseBookingDeadlineUntil.isAvailable) {
						if (!model.courseBookingDeadlineUntil || model.attributeInfoCourseBookingDeadlineUntil.value === null) return null;
					}
					if (!model.attributeInfoCourseBookingDeadlineFrom.isAvailable) return null;
					const tempControl = new FormControl(this.durationToNumberOfDays(control.value));
					return this.validators.max(
						this.durationToNumberOfDays(model.courseBookingDeadlineFrom),
						true,
						PApiPrimitiveTypes.Days,
					).fn(tempControl);
				}}),
			],
			(value : Duration) => {
				if (model.courseBookingDeadlineUntil === value) return;
				// eslint-disable-next-line unicorn/prefer-number-properties
				if (isNaN(value)) return;
				model.courseBookingDeadlineUntil = value;
			},
		);
	}

	/**
	 * Check if start and end is valid
	 */
	public startAndEndIsValid(formGroup : PFormGroupBasedOnAI, formItem : SchedulingApiShift | SchedulingApiShiftModel) : boolean {
		if (formGroup.get(formItem.time.attributeInfoStart.id)?.invalid) return false;
		if (formGroup.get(formItem.time.attributeInfoEnd.id)?.invalid) return false;

		return true;
	}
}
