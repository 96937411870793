import { AuthenticatedApiRole, AuthenticatedApiRootBase } from '@plano/shared/api';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AuthenticatedApiRoot extends AuthenticatedApiRootBase {
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isOwner() : boolean {
		if (!this.rawData) throw new Error(`Please load AuthenticatedApi first [PLANO-FE-9X]`);
		return this.role === AuthenticatedApiRole.CLIENT_OWNER;
	}

}
