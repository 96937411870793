import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel, SchedulingApiTodaysShiftDescription } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-shift-comment',
	templateUrl: './p-shift-comment.component.html',
	styleUrls: ['./p-shift-comment.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftCommentComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public todaysShiftDescription : SchedulingApiTodaysShiftDescription | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift : SchedulingApiShift | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public userCanWrite : boolean = false;

	@HostBinding('attr.role') private _role = 'listitem';

	constructor(
		public api : SchedulingApiService,
		private modalService : ModalService,
		private textToHtmlService : TextToHtmlService,
	) {
	}

	public config : typeof Config = Config;

	public enums = enumsObject;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get item() : SchedulingApiTodaysShiftDescription | SchedulingApiShift | null {
		if (this.shift) return this.shift;
		if (this.todaysShiftDescription) return this.todaysShiftDescription;
		return null;
	}

	private textToHtml(text : string, doNotCut ?: boolean) : string {
		return this.textToHtmlService.textToHtml(text, doNotCut, doNotCut);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public innerHTML(doNotCut : boolean = false) : string | undefined {
		if (!this.api.isLoaded()) return undefined;
		assumeNonNull(this.item);
		assumeDefinedToGetStrictNullChecksRunning(this.item.description, 'this.item.description');
		const description = this.textToHtml(this.item.description, doNotCut);
		if (description) return description;
		return '…';
	}

	/**
	 * Modal with question if comments should be removed
	 */
	public removeDescriptionPrompt(removeMemoModalContent : TemplateRef<PModalTemplateDirective>) : void {
		void this.modalService.openModal(removeMemoModalContent, {
			animation: false,
			theme: enumsObject.PThemeEnum.DANGER,
		}).result.then(value => {
			if (value.modalResult === 'success') {
				void this.removeDescriptionAndSave();
			}
		});
	}

	/**
	 * Remove a comment
	 */
	private async removeDescriptionAndSave() : Promise<unknown> {
		this.removeDescription();
		return this.api.save();
	}

	private removeDescription() : void {
		if (this.shift) this.shift.description = '';
		if (this.todaysShiftDescription) this.todaysShiftDescription.description = '';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shiftModel() : SchedulingApiShiftModel | null {
		if (this.shift) {
			return this.api.data.shiftModels.get(this.shift.id.shiftModelId);
		}
		if (this.todaysShiftDescription) return this.api.data.shiftModels.get(this.todaysShiftDescription.id.shiftModelId);
		return null;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get title() : string | undefined {
		if (this.shift) return this.shift.name;
		if (this.shiftModel) return this.shiftModel.name;
		if (this.todaysShiftDescription) return this.todaysShiftDescription.name;
		return '';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get start() : number | undefined {
		if (this.shift) return this.shift.start;
		if (this.todaysShiftDescription) return this.todaysShiftDescription.shiftStart;
		return undefined;
	}

}
