import { Directive } from '@angular/core';
import { PTooltipDirective } from '@plano/shared/core/p-tooltip/tooltip.directive';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '.crop-on-overflow:not(.no-tooltip-when-cropped)' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PCropOnOverflowDirective
	extends PTooltipDirective {
	/** @see PTooltipDirective#addTooltipIfCropped */
	protected override addTooltipIfCropped = true;
}
