import { Injectable } from '@angular/core';
import { PCookieService, PServiceWithCookiesInterface } from '@plano/shared/core/p-cookie.service';
import { Subject } from 'rxjs';
import { CourseFilterServiceCookieKeyPrefixDataType } from './course-filter.service.types';

// import { SchedulingApiService } from '@plano/shared/api';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CourseFilterService implements PServiceWithCookiesInterface {
	constructor( private pCookieService : PCookieService ) {
	}

	private _courseVisible : boolean | null = null;
	private _bookingsVisible : boolean | null = null;

	/**
	 * Method to be run before the OnChanges event is emitted.
	 * If this method uses 'this' in its definition please bind it.
	 * To prevent possible bugs, since all components share the same service, please
	 * remove this method on ngOnDestroy, by assigning it to null.
	 * @example this.courseFilterService.runBeforeChange = this.privateMethod.bind(this)
	 */
	public runBeforeChange : (() => void) | null = null;

	private _cookiePrefix : CourseFilterServiceCookieKeyPrefixDataType = null;

	public onChange : Subject<void> = new Subject<void>();

	/**
	 * Init all necessary values for this class
	 */
	public initValues() : void {
		if (this.bookingsVisible === null) this.bookingsVisible = false;
		if (this.courseVisible === null) this.courseVisible = true;
	}

	/**
	 * Read values from cookies if available
	 */
	public readCookies() : void {
		if (this.pCookieService.has({name: 'bookingsVisible', prefix: this._cookiePrefix})) {
			this.bookingsVisible = this.pCookieService.get({name: 'bookingsVisible', prefix: this._cookiePrefix}) === 'true';
		}
		if (this.pCookieService.has({name: 'courseVisible', prefix: this._cookiePrefix})) {
			this.courseVisible = this.pCookieService.get({name: 'courseVisible', prefix: this._cookiePrefix}) === 'true';
		}
	}

	// TODO: obsolete?
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get bookingsVisible() : boolean | null {
		return this._bookingsVisible;
	}
	public set bookingsVisible(value : boolean | null) {
		if (this.runBeforeChange) {
			this.runBeforeChange();
		}

		this.pCookieService.put({name: 'bookingsVisible', prefix: this._cookiePrefix}, value);
		this._bookingsVisible = value;

		this.onChange.next();
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get courseVisible() : boolean | null {
		return this._courseVisible!;
	}
	public set courseVisible(value : boolean | null) {
		if (this.runBeforeChange) {
			this.runBeforeChange();
		}
		this.pCookieService.put({name: 'courseVisible', prefix: this._cookiePrefix}, value);
		this._courseVisible = value;

		this.onChange.next();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public set cookiePrefix(value : CourseFilterServiceCookieKeyPrefixDataType) {
		this._cookiePrefix = value;
	}

	/** @see PServiceInterface#unload */
	public unload() : void {
		this._bookingsVisible = null;
		this._courseVisible = null;
	}
}
