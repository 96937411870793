import { Injectable, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn:'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PClipboardService {
	private timeout : number | null = null;
	public copiedToClipboard : string | number | null = null;
	constructor( private zone : NgZone, private activeModal : NgbActiveModal) {

	}

	/**
     * Method to copy a given string to the clipboard
     */
	public copy(input : string|number) : void {
		window.clearTimeout(this.timeout ?? undefined);

		// Create a dummy input to copy the string array inside it
		const dummy = document.createElement('input');

		// Output the array into it
		dummy.value = typeof input === 'number' ? input.toString() : input;

		// Add it to the document
		document.body.appendChild(dummy);

		// Set its ID
		dummy.setAttribute('id', 'dummy_id');

		// Select it
		dummy.select();

		// Copy its contents
		document.execCommand('copy');

		// Remove it as its not needed anymore
		document.body.removeChild(dummy);

		this.copiedToClipboard = input;
		this.zone.runOutsideAngular(() => {
			this.timeout = window.setTimeout(() => {
				this.zone.run(() => {
					if (this.copiedToClipboard) {
						this.copiedToClipboard = null;
					}
					this.activeModal.close();
				});
			}, 1000);
		});
	}

}
