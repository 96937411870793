// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum ShiftItemViewStyles {
	SMALL = 'small',
	DETAILED = 'detailed',
	BUTTON = 'button',
	MEDIUM = 'medium',
	MEDIUM_MULTI_SELECT = 'mediumMultiSelect',
	MULTI_SELECT = 'multiSelect',
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PCalendarShiftStyle {
	OVERVIEW = 'overview',
	SHIFT_PICKER = 'shiftPicker',
	FULL = 'full',
}
