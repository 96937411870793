import { Injectable, NgZone } from '@angular/core';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { Data } from '@plano/shared/core/data/data';
import { DataInput } from '@plano/shared/core/data/data-input';
import { PServiceInterface } from '@plano/shared/core/interfaces/p-service.interface';
import { SchedulingApiBirthday, SchedulingApiBirthdays } from './scheduling-api-birthday.service';
import { SchedulingApiMember, SchedulingApiService } from './scheduling-api.service';

@Injectable({providedIn: 'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class BirthdayService extends DataInput implements PServiceInterface {
	constructor(
		protected override zone : NgZone,
		public api : SchedulingApiService,
		private pMoment : PMomentService,
	) {
		super(zone);
	}

	private _birthdays = new Data<SchedulingApiBirthdays>(this.api);

	/**
	 * A stack of all birthdays available for this account
	 */
	public get birthdays() : SchedulingApiBirthdays {
		return this._birthdays.get(() => {
			const birthdaysList = new SchedulingApiBirthdays(this, null, null, false);
			if (!this.api.data.attributeInfoMembers.isAvailable || this.api.isLoadOperationRunning)
				return birthdaysList;
			for (const member of this.api.data.members.iterable()) {
				if (member.attributeInfoBirthday.isAvailable) {
					birthdaysList.push(this.birthdayFromMember(member));
				}
			}
			return birthdaysList;
		});
	}

	private birthdayToDayMonthObject(birthdayTimestamp : number) : {day : SchedulingApiBirthday['day'], month : SchedulingApiBirthday['month']} {
		return {day: +this.pMoment.m(birthdayTimestamp).get('date') as SchedulingApiBirthday['day'], month: +this.pMoment.m(birthdayTimestamp).get('month') as SchedulingApiBirthday['month']};
	}

	private birthdayFromMember(member : SchedulingApiMember) : SchedulingApiBirthday {
		const birthday = new SchedulingApiBirthday();
		birthday.firstName = member.firstName;
		birthday.lastName = member.lastName;
		const birthdayDate = this.birthdayToDayMonthObject(member.birthday!);
		birthday.day = birthdayDate.day;
		birthday.month = birthdayDate.month;
		birthday.memberId = member.id;
		return birthday;
	}

	/** @see PServiceInterface#unload */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public unload() : void {
	}

	/** @see PServiceInterface#initValues */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public initValues() : void {
	}
}
