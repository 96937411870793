import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { PSeverity } from '@plano/global-error-handler/error-utils';
import { SentryTicketId } from '@plano/global-error-handler/sentry-ticket-id.enum';
import { MeService } from '@plano/shared/api';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { PSentryService } from '@plano/shared/sentry/sentry.service';

@Component({
	selector: 'p-error-modal-content',
	templateUrl: './error-modal-content.component.html',
	styleUrls: ['./error-modal-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ErrorModalContentComponent {
	/*
	 * TODO: Currently there is sometimes a problem with modal not hiding the page navigation so
	 * you can navigate somewhere else after error modal opened. This will report us a wrong href where
	 * error happened. To do a hotfix we save the href on modal open.
	 */
	constructor(
		private injector : Injector,
		private pSentryService : PSentryService,
		private localize : LocalizePipe,
	) {
	}

	protected readonly PSeverity = PSeverity;

	protected sentryIsBlocked : boolean | null = null;

	public errorObj ! : Error;
	public userMessage : string = '';
	public USER_MESSAGE_LENGTH_LIMIT = 180;
	public customErrorModalDescription ! : string;

	protected severityLevel = PSeverity.FATAL;

	/**
	 * Set up the modal content and send the error to our error-tracking service.
	 * @param error Error that triggert the modal
	 * @param severity The severity level of error
	 */
	public async initModal(
		error : Error,
		severity : PSeverity.FATAL | PSeverity.WARNING | null = null,
	) : ReturnType<PSentryService['handleError']> {
		this.sentryIsBlocked = await this.pSentryService.sentryIsBlocked();
		if (severity !== null) this.severityLevel = severity;

		this.errorObj = error;
		if (this.errorObj.name === 'Error' && this.errorObj.message) this.errorObj.name = `${this.errorObj.name}: ${this.errorObj.message}`;

		if (this.errorObj.name.includes(SentryTicketId.PLANO_FE_CY)) this.customErrorModalDescription = `
			Du hast einen Fehler ausgelöst, der uns so richtig Kopfzerbrechen bereitet!
			Jetzt ist jede Information für uns Gold wert.
			Kann es sein, dass du im selben Browser vorher mit einem anderen Account eingeloggt warst?
			War sonst etwas besonders?
		`;

		const me = this.injector.get<MeService>(MeService);
		return await this.pSentryService.handleError(this.errorObj, me, undefined, { level: this.severityLevel, extra: {error: this.errorObj} });
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public refreshBrowserTab() : void {
		window.location.reload();
	}

	/**
	 * Try to set up and send a message to our error-tracking service.
	 * If it does not work, just continue, or if the user has written a message, ask him to send us the error message
	 * via support chat.
	 */
	private async sendMessageToSentryIfPossible() : Promise<void> {
		try {
			const me = this.injector.get<MeService>(MeService);
			assumeDefinedToGetStrictNullChecksRunning(this.errorObj, 'this.errorObj');
			await this.pSentryService.handleError(this.errorObj, me, this.userMessage, { level: this.severityLevel, extra: {error: this.errorObj} });
		} catch {
			if (this.userMessage) {
				window.prompt('Leider konnte deine Nachricht nicht verschickt werden.\nBitte kopiere die unten stehende Fehlermeldung und melde dich damit in unserem Support-Chat. Danke ♥\n\nSorry, your message could not be sent.\nPlease copy the error message below and report it via our support chat. Thank you ♥', `Error: ${this.errorObj.message}`);
			}
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public async onClose() : Promise<void> {
		await this.sendMessageToSentryIfPossible();
		this.refreshBrowserTab();
	}

	/**
	 * Subject for error modal email when sentry is blocked
	 */
	public get errorModalEmailSubject() : string {
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		return encodeURI(this.localize.transform('Ich hatte einen Fehler in Dr. Plano'));
	}

	/**
	 * Body for error modal email when sentry is blocked
	 */
	public errorModalEmailBody(error : string) : string {
		return encodeURI(this.localize.transform({sourceString: 'Folgende Fehlermeldung wurde mir angezeigt: ${error}',params: {error}}));
	}

	/**
	 * Get some color if user comes close or is at length limit
	 * @param length Length of user message
	 */
	public lengthLimitState(length : number) : 'danger' | 'warning' | null {
		if (!length) return null;
		if (length >= this.USER_MESSAGE_LENGTH_LIMIT) return 'danger';
		if (length >= (this.USER_MESSAGE_LENGTH_LIMIT / 100 * 70)) return 'warning';
		return null;
	}
}
