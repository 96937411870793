<div class="card m-0 text-left o-hidden shadow-lg"
	style="border-radius: 0.5rem;"
	[style.border-color]="'#' + shift.model.color"
>
	<ng-container *ngTemplateOutlet="cardHeader"></ng-container>
	<ng-container *ngTemplateOutlet="detailedPacketInfo"></ng-container>
	<ng-container *ngTemplateOutlet="processInfoTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="commentBtn"></ng-container>
	<ng-container *ngTemplateOutlet="assignedMembersTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="quickAssignmentTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="illnessShiftExchangesListTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="linkedCourseInfoTemplate"></ng-container>
</div>

<ng-template #cardHeader>
	<div class="card-header bg-white small position-relative p-0">
		<p-color-marker
			[item]="shift"
			[title]="shift.name"
			[hexColor]="'#' + shift.color"
			[isPacket]="shift.isPacket"
		></p-color-marker>
		<div class="card-options">
			<ng-container *ngTemplateOutlet="titleAndTime"></ng-container>
			<div class="d-flex">
				<a
					role="button"
					[title]="(userCanWrite ? 'Bearbeiten' : 'Details anzeigen') | localize"
					[routerLink]="editShiftLink"
					*ngIf="showEditShiftButton"
					(click)="onClose.emit($event);onClickEdit.emit();"
					class="btn btn-outline-secondary btn-light card-option border-bottom-0"
				><fa-icon [icon]="userCanWrite ? enums.PlanoFaIconPool.EDIT : enums.PlanoFaIconPool.MORE_INFO"></fa-icon></a>
				<button
					title="Löschen" i18n-title
					type="button"
					*ngIf="showRemoveShiftButton"
					(click)="removeClickHandler($event)"
					class="btn btn-outline-secondary btn-light card-option border-bottom-0"
				><fa-icon class="text-danger" [icon]="enums.PlanoFaIconPool.TRASHED"></fa-icon></button>
				<button
					title="Schließen" i18n-title
					type="button"
					(click)="onClose.emit($event)"
					class="btn btn-outline-secondary btn-light card-option border-bottom-0"
				><fa-icon [icon]="enums.PlanoFaIconPool.DISMISS" size="lg"></fa-icon></button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #illnessShiftExchangesListTemplate>
		<ng-template [ngIf]="!!illnessShiftExchanges && illnessShiftExchanges.length > 0">
			<section>
				<label class="m-0 p-1 pl-2 pr-2"><fa-icon class="mr-2" [icon]="enums.PlanoFaIconPool.ITEMS_ABSENCE_ILLNESS"/><ng-container i18n>Genehmigte Krankmeldungen:</ng-container></label>
			</section>
			<section *ngFor="let shiftExchange of illnessShiftExchanges.iterable()" class="card-options pl-2">
				<ng-container *ngVar="shiftExchange.indisposedMember! as indisposedMember">
					<p-member-badge
						class="align-self-center"
						size="small"
						[memberId]="indisposedMember.id"
						[firstName]="indisposedMember.firstName"
						[lastName]="indisposedMember.lastName"
						[isMe]="!!rightsService.isMe(indisposedMember.id)"/>
					<div class="d-flex align-items-center flex-grow-1 pt-1 pl-2 pb-1 pr-2 title small">{{indisposedMember.firstName}} {{indisposedMember.lastName}}</div>
					<p-shift-exchange-btn
						[shiftExchange]="shiftExchange"
						[hideNonCounterBadges]="false"
						[colorizeIconIfShiftExchangeExists]="false"
					></p-shift-exchange-btn>
				</ng-container>
			</section>
		</ng-template>
</ng-template>

<ng-template #detailedPacketInfo>
	<section *ngIf="shift.packetShifts.length" class="p-0">
		<p-packet-shifts
			class="d-block p-1 pl-2 pr-2"
			[clickable]="false"
			[currentShiftId]="shift.id"
			[packetShifts]="shift.packetShifts"
		></p-packet-shifts>
	</section>
</ng-template>

<ng-template #assignedMembersTemplate>
	<section *ngIf="shift.attributeInfoAssignedMemberIds.isAvailable && shift.assignedMemberIds.length > 0">
		<p-assigned-members
			[size]="enums.BootstrapSize.SM"
			[readMode]="readMode"
			[shift]="shift"
		></p-assigned-members>
	</section>
</ng-template>

<ng-template #commentBtn>
	<section *ngIf="shift.description && shift.description.length" class="card-options d-block">
		<blockquote
			class="flex-grow-1 text-style-reset-to-body text-left o-hidden p-2 shift-comment crop-on-overflow"
			[class.btn-light]="!readMode"
			[class.btn-frameless]="!readMode"
			[class.clickable]="!readMode"

			pShiftCommentModal
			[shift]="shift"
			[disabled]="readMode"
			[userCanWrite]="userCanWrite"
		>
			<p-sticky-note height="18" class="mr-2 text-muted"></p-sticky-note><span [innerHTML]="textToHtml(shift.description, 180)"></span>
		</blockquote>
	</section>
</ng-template>

<ng-template #titleAndTime>
	<section class="crop-on-overflow p-0 border-bottom-0">
		<div class="crop-on-overflow pt-2 pl-3 pr-3 pb-0 mt-0"><label
			class="w-100 crop-on-overflow"
			style="line-height: 1em;"
		>{{ shift.name }}</label></div>
		<div class="pb-2 pl-3 pr-3 pt-0 mt-0"
			style="line-height: 1em;"
		>
			<span class="text-monospace">{{ shift.start | date:'veryShortTime' }} <fa-icon icon="long-arrow-alt-right"></fa-icon> {{ shift.end | date:'veryShortTime' }}</span>
		</div>
	</section>
</ng-template>
