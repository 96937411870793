import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-editable-box-header',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableBoxHeaderComponent {}

@Component({
	selector: 'p-editable-box-form',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableBoxFormComponent {
	@HostBinding('class.d-block')
	@HostBinding('class.w-100') protected _alwaysTrue = true;

}

@Component({
	selector: 'p-editable-box',
	templateUrl: './p-editable-box.component.html',
	styleUrls: ['./p-editable-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableBoxComponent implements AfterViewInit, EditableControlInterface {
	@ViewChild('showroom', { static: true }) private showroom ! : ElementRef<HTMLElement>;

	/** The headline of this component */
	@Input() public label : string | null = null;

	@Input() public disabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemoveItemClick = new EventEmitter<undefined>();

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public api : EditableControlInterface['api'] = null;
	@Input() public valid : EditableControlInterface['valid'] = null;
	@Input() public saveChangesHook ?: EditableControlInterface['saveChangesHook'];
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	constructor(
		private console : LogService,
	) {
	}

	public showShowroom : boolean = false;
	public boxEditMode : boolean = false;

	/**
	 * If the box is initially invalid the form must be visible till the next boxEditMode update comes
	 */
	public initialInvalid : boolean = false;

	public enums = enumsObject;

	public ngAfterViewInit() : void {
		this.initialInvalid = !this.valid;
		this.showShowroom = this.showroom.nativeElement.children.length > 0;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public updateEditMode(event : boolean) : void {
		this.console.log('updateEditMode');
		this.boxEditMode = event;
		this.editMode.emit(event);
	}
}
