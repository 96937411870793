import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';

@Component({
	selector: 'p-icon-tooltip',
	templateUrl: './icon-tooltip.component.html',
	styleUrls: ['./icon-tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PIconTooltipComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public headline : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public placement : NgxPopperjsPlacements = NgxPopperjsPlacements.TOP;

	/**
	 * The icon that will be shown. If not set internal logic will choose an icon.
	 */
	@Input('icon') private _icon : FaIcon | null = null;
	@Input() public theme : PThemeEnum | null = null;

	/**
	 * If you don’t want the internal logic to add a fixed height, you can pass a defined height like 'auto' here.
	 */
	@Input('height') private _height : 'auto' | null = null;

	@ViewChild('ngContentRef') public ngContentRef ?: TemplateRef<unknown>;

	/**
	 * Add some hight on mobile. On mobile this is a button which needs to be tappable with a thumb. But the height of the
	 * line should not be increased by that, because it usually is in line with some label text.
	 */
	@HostBinding('style.height') private get height() : 'auto' | `${number}px` {
		if (this._height !== null) return this._height;
		return Config.IS_MOBILE ? '21px' : 'auto';
	}

	/**
	 * size of the icon to be shown
	 */
	@Input() public size : 'small' | 'normal' = 'small';

	/**
	 * The spacing that will be added to the icon, if default is passed then the class
	 * px-2 will be added to the icon, if none, the padding should be provided from the outside
	 */
	@Input() public spacing : 'default' | 'none' = 'default';

	constructor(
		private console : LogService,
		private modalService : ModalService,
	) {
	}

	protected readonly Config = Config;

	public NgxPopperjsTriggers = NgxPopperjsTriggers;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;
	public enums = enumsObject;

	/**
	 * The icon to show as trigger element.
	 */
	protected get icon() : FaIcon {
		if (this._icon !== null) return this._icon;
		// eslint-disable-next-line sonarjs/no-small-switch
		switch (this.theme) {
			case enumsObject.PThemeEnum.WARNING:
				return enumsObject.PlanoFaIconPool.TOOLTIP_WARNING;
			default:
				return enumsObject.PlanoFaIconPool.TOOLTIP_INFO;
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onClickIcon(event : Event) : void {
		if (Config.IS_MOBILE) {
			event.stopPropagation();
			if (!this.ngContentRef) {
				this.console.error('ngContentRef not defined');
				return;
			}
			this.modalService.openDefaultModal({
				modalTitle: this.headline,
				contentTemplateRef: this.ngContentRef,
			}, {
				theme: this.theme ?? enumsObject.PThemeEnum.DARK,
				centered: true,
			});
		}
	}
}
