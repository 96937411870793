import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { SchedulingApiBirthdays } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { sortShiftsForListViewFns } from '@plano/client/scheduling/shared/api/scheduling-api.utils';
import { CalendarAllDayItemType } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-all-day-item-layout.service';
import { PAllDayItemsListComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar/p-all-day-items-list/p-all-day-items-list.component';
import { PAlertThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FilterService } from '@plano/client/shared/filter.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiAbsences, SchedulingApiHolidays, SchedulingApiService, SchedulingApiShift, SchedulingApiShifts } from '@plano/shared/api';
import { Data } from '@plano/shared/core/data/data';
import { LogService } from '@plano/shared/core/log.service';
import { PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { Subscription, interval } from 'rxjs';

class DayData {
	public labels : string[] = [];
	public intervalStart : number | null = null;
	public intervalEnd : number | null = null;
	public shifts : SchedulingApiShift[] = [];
	public trackByValue : number | null = null;
	public containsToday : boolean = false;
}

@Component({
	selector: 'p-list-view',
	templateUrl: './list-view.component.html',
	styleUrls: ['./list-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ListViewComponent implements OnDestroy {
	@Input('startOfDay') private set _startOfDay(input : number) {
		Assertions.ensureIsDayStart(input);
		this.startOfDay = input;
	}

	/**
	 * Absences to be added to the list view
	 */
	@Input() public absences : PAllDayItemsListComponent['absences'] = new SchedulingApiAbsences(null, null);

	/**
	 * Holidays to be added to the list view
	 */
	@Input() public holidays : PAllDayItemsListComponent['holidays'] = new SchedulingApiHolidays(null, null);

	/**
	 * Birthdays to be added to the list view
	 */
	@Input() public birthdays : PAllDayItemsListComponent['birthdays'] = new SchedulingApiBirthdays(null, null, null, false);

	/**
	 * Read mode for the all-day items
	 */
	@Input() public readMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private calendarMode : CalendarModes | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts : SchedulingApiShifts | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	/**
	 * If this is true, the shift-items in ui will be just skeletons/placeholders.
	 */
	@Input() public delayIsActive : boolean = false;

	constructor(
		public api : SchedulingApiService,
		private filterService : FilterService,
		private pMoment : PMomentService,
		private datePipe : PDatePipe,
		private console : LogService,
	) {
	}

	public enums = enumsObject;
	public PAlertThemeEnum = PAlertThemeEnum;

	public NgxPopperjsPlacements = NgxPopperjsPlacements;
	public CalendarModes = CalendarModes;

	private startOfDay : number | null = null;

	/**
	 * Array of all timestamps of the days of this date-range based on calendarMode
	 */
	public get days() : number[] {
		if (!this.startOfDay) return [];
		let startOfMoment = this.pMoment.m(this.startOfDay).startOf(this.calendarMode);
		const result = [];
		assumeDefinedToGetStrictNullChecksRunning(this.calendarMode, 'this.calendarMode');
		const currentWeek : number = this.pMoment.m(this.startOfDay).get(this.calendarMode);
		while (startOfMoment.get(this.calendarMode) === currentWeek) {
			result.push(+startOfMoment.startOf('day'));
			startOfMoment = startOfMoment.add(1, 'day');
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showDayHeader() : boolean {
		return this.calendarMode !== CalendarModes.DAY;
	}

	private formatDay(timestamp : number) : string {
		// get weekday without dot
		let result = this.pMoment.m(timestamp).format('ddd');

		const REGEX_FOR_TRAILING_DOT = /\.$/g;
		result = result.replace(REGEX_FOR_TRAILING_DOT, '');

		// add date
		result += `, ${this.datePipe.transform(timestamp, 'veryShortDate')}`;

		return result;
	}

	private _daysData : Data<DayData[]> = new Data<DayData[]>(this.api, this.filterService);
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get daysData() : DayData[] {
		return this._daysData.get(() => {
			const days = this.days;
			const result = new Array<DayData>();

			// get shifts for each day
			const daysShifts = new Array<SchedulingApiShift[]>();

			for (const day of days) {
				const shifts = this.shifts!.
					getByDay(day);
				for (const compareFn of sortShiftsForListViewFns) {
					shifts.sort(compareFn);
				}
				daysShifts.push([...shifts.iterable()]);
			}

			// create days data array
			for (let dayIndex = 0; dayIndex < days.length; ++dayIndex) {
				const dayData = new DayData();
				result.push(dayData);

				dayData.trackByValue = days[dayIndex];
				dayData.shifts = daysShifts[dayIndex];

				const now = this.pMoment.m();

				// if day has no shifts then summarize with following days which also have no shifts
				if (daysShifts[dayIndex].length === 0) {
					const noShiftsFirstDayIndex = dayIndex;

					while (dayIndex < days.length - 1 && daysShifts[dayIndex + 1].length === 0) {
						++dayIndex;
					}

					const noShiftsLastDayIndex = dayIndex;

					// Range of only one day?
					if (noShiftsFirstDayIndex === noShiftsLastDayIndex) {
						dayData.labels.push(this.formatDay(days[noShiftsFirstDayIndex]));
					} else { // Otherwise range of several days
						dayData.intervalStart = days[noShiftsFirstDayIndex];
						dayData.intervalEnd = this.pMoment.m(days[noShiftsLastDayIndex]).endOf('day').valueOf();
						dayData.labels.push(this.formatDay(days[noShiftsFirstDayIndex]), '-');
						dayData.labels.push(this.formatDay(days[noShiftsLastDayIndex]));
					}

					if (now.isSameOrAfter(days[noShiftsFirstDayIndex], 'day') && now.isSameOrBefore(days[noShiftsLastDayIndex], 'day')) {
						dayData.containsToday = true;
					}
				} else {
					dayData.labels.push(this.formatDay(days[dayIndex]));
					if (now.isSame(days[dayIndex], 'day')) {
						dayData.containsToday = true;
					}
				}
			}

			return result;
		});
	}

	/**
	 * Get all days events for interval described in dayData
	 */
	public allDayEventsInInterval(dayData : DayData) : CalendarAllDayItemType[] {
		if (!dayData.intervalStart || !dayData.intervalEnd) return [];
		const result : CalendarAllDayItemType [] = [];
		for (const absence of this.absences.iterable()) {
			if (absence.time.start <= dayData.intervalEnd &&
				absence.time.end > dayData.intervalStart &&
				this.filterService.isVisible(absence)) {
				result.push(absence);
			}
		}
		for (const holiday of this.holidays.iterable()) {
			if (holiday.time.start <= dayData.intervalEnd &&
				holiday.time.end > dayData.intervalStart &&
				this.filterService.isVisible(holiday)) {
				result.push(holiday);
			}
		}
		for (const birthday of this.birthdays.iterable()) {
			if (birthday.time.start <= dayData.intervalEnd &&
				birthday.time.end > dayData.intervalStart &&
				this.filterService.isVisible(birthday)) {
				result.push(birthday);
			}
		}

		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public trackByDayData(_index : number, item : DayData) : number {
		return item.trackByValue!;
	}

	private firstShiftAfterNow : SchedulingApiShift | null = null;

	private updateFirstShiftAfterNow(shifts : SchedulingApiShift[]) : void {
		const now = Date.now();
		this.firstShiftAfterNow = shifts.sort((a, b) => {
			if (!a.rawData || !b.rawData) {
				this.clearNowLineUpdateIntervals();
				return 0;
			}
			return a.start - b.start;
		}).find((item) => {
			if (!item.rawData) return null;
			return item.start > now;
		}) ?? null;
	}

	private nowLineUpdateIntervals : {[key : string] : Subscription | undefined} = {};

	public ngOnDestroy() : void {
		this.clearNowLineUpdateIntervals();
	}

	private clearNowLineUpdateIntervals() : void {
		for (const key of Object.keys(this.nowLineUpdateIntervals)) {
			this.nowLineUpdateIntervals[key]?.unsubscribe();
			this.nowLineUpdateIntervals[key] = undefined;
		}
	}

	/** Should the now line be visible? */
	public showNowLine(dayData : DayData, shift : SchedulingApiShift) : boolean {
		if (!dayData.containsToday) return false;
		if (this.firstShiftAfterNow === null && !this.nowLineUpdateIntervals[`${dayData.trackByValue}${dayData.shifts.length}`]) {
			if (!this.api.isBackendOperationRunning) {
				this.updateFirstShiftAfterNow(dayData.shifts);
				this.nowLineUpdateIntervals[`${dayData.trackByValue}${dayData.shifts.length}`] = interval(2000).pipe().subscribe(() => {
					this.updateFirstShiftAfterNow(dayData.shifts);
				});
			}
			return false;
		}

		if (this.firstShiftAfterNow === null) return false;

		if (this.firstShiftAfterNow.rawData === null) {
			this.console.error('firstShiftAfterNow.rawData is null [PRODUCTION-505]');
			return false;
		}

		// eslint-disable-next-line sonarjs/prefer-immediate-return
		const isSameId = shift.id.equals(this.firstShiftAfterNow.id);
		return isSameId;
	}

	/** Should the now line be visible? */
	public showNowLineAtBottomOfDay(dayData : DayData) : boolean {
		if (this.api.isBackendOperationRunning) return false;
		if (!dayData.containsToday) return false;
		if (this.firstShiftAfterNow) return false;
		return true;
	}
}
