import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { SchedulingApiService, SchedulingApiShiftModel } from '@plano/shared/api';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-age-limit-section[shiftModel][formGroup][api]',
	templateUrl: './age-limit-section.component.html',
	styleUrls: ['./age-limit-section.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AgeLimitSectionComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftModel ! : SchedulingApiShiftModel;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public formGroup ! : FormGroup;
	@Input() public api ! : SchedulingApiService;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public userCanWrite : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public initFormGroup = new EventEmitter();

	public SectionWhitespace = SectionWhitespace;
	public enums = enumsObject;

	/** Which limits are there for the booking person? Improves readability of the template file. */
	public get bookingPersonAgeLimits() : 'minLimit' | null {
		const hasMinLimit = (() : boolean => {
			return this.shiftModel.bookingPersonMinAge !== null;
		})();
		if (hasMinLimit) return 'minLimit';
		return null;
	}

	/** Which limits are there for the participants? Improves readability of the template file. */
	public get participantAgeLimits() : 'minLimit' | 'maxLimit' | 'minAndMaxLimit' | null {
		const hasMinLimit = this.shiftModel.participantMinAge !== null;
		const hasMaxLimit = this.shiftModel.participantMaxAge !== null;
		if (hasMinLimit) {
			if (hasMaxLimit) return 'minAndMaxLimit';
			return 'minLimit';
		}
		if (hasMaxLimit) return 'maxLimit';
		return null;
	}
}
