<div class="card" *ngIf="showAssignmentProcessesCard" aria-labelledby="shift-scheduling-section-label">
	<section>
		<list-headline
			[text]="listHeadline"
			id="shift-scheduling-section-label"
			[textTooltipHtml]="listHeadlineTooltip"
		>
			<button type="button"
				*ngIf="showAddButton"
				class="card-option btn btn-sm btn-outline-secondary py-1 d-flex align-items-center justify-content-center"
				[disabled]="!api.isLoaded()"
				(click)="createNewAssignmentProcess(modalContent.template)"
				title="Hinzufügen" i18n-title
			><fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon></button>
		</list-headline>
	</section>
	<section>
		<div class="card-body p-0 position-relative">
			<p-spinner [size]="enums.BootstrapSize.LG" *ngIf="!api.isLoaded() || !meService.isLoaded() || api.isLoadOperationRunning" class="area-blocking-spinner"></p-spinner>

			<ng-template [ngIf]="!noItemsYet" [ngIfElse]="noItemsYetTemplate">
				<p-assignment-process
					*ngFor="let assignmentProcess of assignmentProcessesForList.iterable()"
					class="m-2"
					[process]="assignmentProcess"
					(selectProcess)="toggleRelatedShifts($event)"
					(onClickProcess)="onClickProcess($event)"
				></p-assignment-process>
			</ng-template>
			<ng-template #noItemsYetTemplate>
				<div class="flex-grow-1 text-center text-muted small p-2">
					<span *ngIf="showAddButton" i18n>☝️ Klicke auf <fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon> um eine neue Schichtverteilung zu beginnen.</span>
					<span *ngIf="!showAddButton" i18n>Aktuell keine Schichtverteilungen vorhanden.</span>
				</div>
			</ng-template>

			<div class="m-2" *ngIf="showRemainingProcessesButton">
				<button type="button"
					*ngIf="api.isLoaded()"
					class="btn w-100 p-2"
					style="white-space: normal;"
					[class.btn-primary]="allShiftsRemainingWithEmptyMemberSlotsSelected"
					[class.btn-outline-primary]="!allShiftsRemainingWithEmptyMemberSlotsSelected"
					[class.text-dark]="!allShiftsRemainingWithEmptyMemberSlotsSelected"
					[class.affected]="!allShiftsRemainingWithEmptyMemberSlotsSelected && someShiftsRemainingWithEmptyMemberSlotsSelected"
					(click)="toggleShiftsRemainingWithEmptyMemberSlots()"
				>
					<ng-template [ngIf]="remainingEmptySlotsCounter">
						<div i18n class="mb-2"><strong>Unbesetzt & in keinem Vorgang</strong></div>
						<div class="d-flex justify-content-between align-items-center flex-wrap gap-2 text-transform-none">
							<div class="d-flex align-items-center gap-1">
								<span i18n>Stunden</span>
								<code>{{remainingEmptySlotsHoursCounter}}</code>
							</div>
							<div class="d-flex align-items-center gap-1">
								<span i18n>Schichtplätze</span>
								<p-member-badge class="flex" [text]="remainingEmptySlotsCounter.toString()" size="small"></p-member-badge>
							</div>
						</div>
					</ng-template>
					<ng-container *ngIf="!remainingEmptySlotsCounter">
						<div class="d-flex align-items-center gap-2">
							<span class="text-left" i18n>Alle Schichtplätze sind besetzt oder in einem Verteilungsvorgang 🎉</span>
							<fa-icon icon="check-circle" class="fa-2x" style="color: #6c757d;"></fa-icon>
						</div>
					</ng-container>
				</button>
			</div>
		</div>
	</section>
</div>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Neue Schichtverteilung" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<p-bootstrap-form-group
			label="Name des Vorgangs" i18n-label
		>
			<input
				type="text"
				autocomplete="off"
				class="form-control"
				[(ngModel)]="newProcessName"
				name="newProcessName"
			>
		</p-bootstrap-form-group>
		<p-bootstrap-form-group
			label="Art der Verteilung" i18n-label
		>
			<p-radios
				class="mb-3"
				[(ngModel)]="newProcessType"
				[inline]="false"
			>
				<p-radios-radio
					[value]="TYPES.DR_PLANO"
					label="Automatische Verteilung durch Dr.&nbsp;Plano" i18n-label
					[icon]="enums.PlanoFaIconPool.AUTO_SCHEDULING"
				></p-radios-radio>
				<p-radios-radio
					[value]="TYPES.EARLY_BIRD"
					label="Der Frühe Vogel fängt den Wurm" i18n-label
					[icon]="enums.PlanoFaIconPool.EARLY_BIRD"
				></p-radios-radio>
				<p-radios-radio
					[value]="TYPES.MANUAL"
					label="Schichtwunschabfrage & manuelle Verteilung" i18n-label
					[icon]="enums.PlanoFaIconPool.MANUAL_SCHEDULING"
				></p-radios-radio>
			</p-radios>

			<ng-template [ngIf]="newProcessType !== undefined && newProcessType !== null">
				<p-alert
					[theme]="enums.PThemeEnum.INFO"
					[icon]="newProcessType===TYPES.DR_PLANO ? enums.PlanoFaIconPool.AUTO_SCHEDULING : (newProcessType===TYPES.EARLY_BIRD ? enums.PlanoFaIconPool.EARLY_BIRD : enums.PlanoFaIconPool.MANUAL_SCHEDULING)"
					[dismissable]="false"
				>
					<h5 class="d-inline text-white">{{ titleForType(newProcessType) | localize }}</h5>
					<p class="mb-0 mt-2" [innerHTML]="textForType(newProcessType) | localize | pSafeHtml"></p>
					<ul
						*ngIf="newProcessType===TYPES.DR_PLANO"
						[listIcon]="enums.PlanoFaIconPool.SUCCESS" class="mt-2 mb-0"
					>
						<li i18n>Wunsch- und Maximallöhne</li>
						<li i18n>Schichtwünsche der Mitarbeitenden</li>
						<li i18n>Eingetragene Urlaubs- und Krankheitszeiten</li>
						<li i18n>Gesetzliche Ruhezeiten</li>
						<li i18n>Zuweisung von beieinander liegenden kurzen Schichten (z.B. Kursen) an dieselben Mitarbeitenden</li>
						<li i18n>Zuweisung von Schicht-Paketen (z.B. Kursen) an dieselben Mitarbeitenden</li>
					</ul>
				</p-alert>
			</ng-template>
		</p-bootstrap-form-group>

	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="d($event)" i18n>Abbrechen</button>
		<button type="button" class="btn ml-auto"
			[disabled]="!newProcessName!.length"
			[class.btn-outline-secondary]="!newProcessName!.length"
			[class.btn-outline-primary]="newProcessName!.length"
			(click)="c($event);"
		><fa-icon [icon]="enums.PlanoFaIconPool.ADD" class="mr-2"></fa-icon><ng-container i18n>Verteilungsvorgang anlegen</ng-container></button>
	</div>
</ng-template>
