<div class="card mt-1 mb-4 o-hidden">
	<div
		class="justify-content-between align-items-center w-100 position-relative p-2"
		[class.d-flex]="!CONFIG.IS_MOBILE"
	>
		<p-color-marker
			[isLoading]="isLoading"
			[title]="shiftName"
			[hexColor]="shiftColor"
			[isPacket]="item ? item.isPacket : false"
		></p-color-marker>
		<h2 class="m-0 text-left pl-2"
			[class.text-muted]="!shiftModel"
		>{{ shiftName }}</h2>
		<a
			*ngIf="shiftStart && !item?.isNewItem(); else shiftDateDiv"
			[routerLink]="'/client/scheduling/'+schedulingService.urlParam.calendarMode+'/'+shiftStart"
			[fragment]="item?.id?.toPrettyString()"
			class="pl-2 text-body-color min-w-fit-content"
			[class.text-right]="!CONFIG.IS_MOBILE"
			[class.text-left]="CONFIG.IS_MOBILE"
			title="Schicht im Kalender ansehen" i18n-title
		>
			<ng-template *ngTemplateOutlet="item ? shiftDateTemplate : noShift"></ng-template>
		</a>
	</div>
</div>

<ng-template #shiftDateDiv>
	<div
		class="pl-2"
		[class.text-right]="!CONFIG.IS_MOBILE"
		[class.text-left]="CONFIG.IS_MOBILE">
		<ng-template *ngTemplateOutlet="item ? shiftDateTemplate : noShift"></ng-template>
	</div>
</ng-template>

<ng-template #shiftDateTemplate>
	<span *ngIf="item!.isCourseCanceled"><span style="vertical-align: middle;" class="badge-danger badge-pill nowrap" i18n>fällt aus</span><span class="text-monospace"> | </span></span>
	<span class="text-monospace" *ngIf="courseCode"><code style="line-height: 0;" class="nowrap">{{ courseCode }}</code> | </span>
	<span class="text-monospace"><span class="nowrap">{{formattedDateTimePipe.getFormattedDateInfo(item!.start, item!.end, true).full}}</span> | </span>
	<span class="nowrap text-monospace">{{headlineTime}}</span>
</ng-template>
<ng-template #noShift>
	<span>{{ shiftName }}</span>
</ng-template>

<p-spinner *ngIf="!item" [size]="enums.BootstrapSize.LG" class="m-3"></p-spinner>

<p-shift-and-shiftmodel-form
	*ngIf="!!item"
	[shift]="item"
	[shiftModel]="item.model"
	(dismiss)="navBack()"
	(add)="saveItem()"
></p-shift-and-shiftmodel-form>
