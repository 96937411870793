<div class="mr-2 d-flex">
	<p-button
		[size]="size"
		[theme]="PBtnThemeEnum.OUTLINE_SECONDARY"
		(triggerClick)="navPrev()"
		[disabled]="disabled"
		[badge]="todoLeftView"
		btnTitle="Zurück" i18n-btnTitle
		[badgeAlign]="'left'"
	><fa-icon [icon]="enums.PlanoFaIconPool.PREV"></fa-icon><span class="d-none d-xl-inline crop-on-overflow"
		*ngIf="!hideLabels"
	>&nbsp;<ng-container i18n>Zurück</ng-container></span></p-button>
	<div class="btn-group ml-1">
		<p-button
			class="button-today"
			[size]="size"
			[theme]="viewDateIsToday ? enums.PThemeEnum.PRIMARY : PBtnThemeEnum.OUTLINE_SECONDARY"
			[class.active]="viewDateIsToday"
			[disabled]="disabled"
			[badge]="todoCurrentView"
			(triggerClick)="navToToday()"
		>
			<fa-icon [icon]="viewDateIsToday ? enums.PlanoFaIconPool.RADIO_SELECTED : enums.PlanoFaIconPool.RADIO_UNSELECTED"
				*ngIf="hideLabels"
			></fa-icon>
			<fa-icon [icon]="viewDateIsToday ? enums.PlanoFaIconPool.RADIO_SELECTED : enums.PlanoFaIconPool.RADIO_UNSELECTED" class="d-inline d-xl-none"
				*ngIf="!hideLabels"
			></fa-icon>
			<span class="d-none d-xl-inline"
				*ngIf="!hideLabels"
				i18n="Button in calendar-nav"
			>Heute</span>
		</p-button>
	</div>
	<p-button
		class="ml-1"
		[size]="size"
		[theme]="PBtnThemeEnum.OUTLINE_SECONDARY"
		(triggerClick)="navNext()"
		[disabled]="disabled"
		[badge]="todoRightView"
		btnTitle="Vor" i18n-btnTitle
		[badgeAlign]="'right'"
	><span class="d-none d-xl-inline crop-on-overflow"
		*ngIf="!hideLabels"
	><ng-container i18n>Vor</ng-container> </span><fa-icon [icon]="enums.PlanoFaIconPool.NEXT"></fa-icon>
	</p-button>
</div>
