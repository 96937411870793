import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApiListWrapper, SchedulingApiAbsence, SchedulingApiAbsences } from '@plano/shared/api';

@Component({
	selector: 'p-shift-exchange-related-absences[absences]',
	templateUrl: './shift-exchange-related-absences.component.html',
	styleUrls: ['./shift-exchange-related-absences.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeRelatedAbsencesComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private absences ! : SchedulingApiAbsences;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get absencesForList() : ApiListWrapper<SchedulingApiAbsence> {
		return this.absences.sortedBy(item => item.time.start);
	}
}
