<div
	class="d-flex" style="{{heightStylePdfTemplate}}">
	<div class="overflow-hidden" style="width: 100%;position: relative;">
		<!-- the following div has a tiny margin at the top to ensure it's box-shadow stays visible -->
		<div
			tabindex="0"
			class="pdf-template-wrapper shadow-lg mt-1"
			style="transform: scale(calc({{maxPdfWidth}} / 1000));"
			(click)="zoomOnClick ? openZoomedPdf(dialogElement) : onClick.emit($event)"
			#pdfViewer>
			<pdf-viewer
				title="Vorschau" i18n-title
				[class.zoomOnClick]="zoomOnClick"
				tabindex="0"
				[class.clickable]="isClickable"
				[render-text]="false"
				[original-size]="false"
				[src]="templatePdf"
			>
			</pdf-viewer>
			<ng-template
				[ngIf]="overlayTemplate"
				*ngTemplateOutlet="overlayTemplate; context: { $implicit: isClickable, hideTemporaryInfo, zoomOnClick, pdfWidth: pdfViewer.offsetWidth}"
			></ng-template>
		</div>
	</div>
</div>
<!-- eslint-disable-next-line @angular-eslint/template/accessibility-interactive-supports-focus -- dialogs can't have tabindex -->
<dialog #dialogElement class="border-0 bg-none" (cancel)="$event.preventDefault();" (keydown)="handleKeyDownOnDialog($event, dialogElement)" (click)="dialogElement.close(); currentPdfZoomValue = 1;">
	<ng-container *ngTemplateOutlet="dialogElement.open ? insideDialogTemplate : null"></ng-container>
	<ng-template #insideDialogTemplate>
		<div class="d-flex flex-column position-absolute media-viewer-wrapper">
			<button class="bg-none align-self-end btn text-white w-fit-content"><fa-icon type="solid" [size]="enums.BootstrapSize.LG" [icon]="enums.PlanoFaIconPool.DISMISS"/></button>
			<div
				[class.overflow-scroll]="Config.IS_MOBILE"
				[class.overflow-x-hidden]="!Config.IS_MOBILE"
				[class.flex-column]="!Config.IS_MOBILE"
				[class.d-flex]="!Config.IS_MOBILE"
				class="flex-grow-1">
					<p-pdf-template
						(onClick)="$event.stopPropagation()"
						[pdfSrc]="pdfSrc"
						#pdfTemplateZoomElement
						class="mx-auto my-0 d-flex justify-content-center"
						[class.overflow-hidden]="Config.IS_MOBILE"
						[style.width]="Config.IS_MOBILE ? (pdfTemplateZoomElement.elementRef.nativeElement.querySelector('canvas')?.getBoundingClientRect()?.width ?? 0)+'px' : '100%'"
						[style.height]="Config.IS_MOBILE ? (pdfTemplateZoomElement.elementRef.nativeElement.querySelector('canvas')?.getBoundingClientRect()?.height ?? 0)+'px' : 'auto'"
						[templatePdf]="templatePdf"
						[overlayTemplate]="overlayTemplate"
						[hideTemporaryInfo]="true"
						[maxPdfWidth]="calculatedZoomedPdfWidth"
					></p-pdf-template>
				</div>
				<div class="zoom-btn-group my-2">
					<button (click)="increasePdfZoom($event)" class="bg-none btn text-white">
						<fa-icon type="solid" [size]="enums.BootstrapSize.LG" [icon]="enums.PlanoFaIconPool.ZOOM_IN"/>
					</button>
					<button (click)="decreasePdfZoom($event)" class="bg-none btn text-white">
						<fa-icon type="solid" [size]="enums.BootstrapSize.LG" [icon]="enums.PlanoFaIconPool.ZOOM_OUT"/>
					</button>
				</div>
		</div>
	</ng-template>
</dialog>
