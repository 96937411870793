import { ChangeDetectionStrategy, Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PTypeOfChange } from '@plano/client/shared/p-transmission/change-selectors-modal.component';
import { RightsService, SchedulingApiService } from '@plano/shared/api';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-multi-selection-btn',
	templateUrl: './multi-selection-btn.component.html',
	styleUrls: ['./multi-selection-btn.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,

	// animations: [
	// 	trigger('popOpen', [
	// 		state('true', style({
	// 		})),
	// 		state('false', style({
	// 			display: 'none',
	// 			height: '0',
	// 		})),
	// 		transition('true => false', animate('100ms ease-in')),
	// 		transition('false => true', animate('300ms ease-out')),
	// 	]),
	// 	trigger('scale', [
	// 		state('false', style({
	// 			transform: 'scale(0)',
	// 		})),
	// 		state('true', style({
	// 		})),
	//
	// 		transition('false => true', [
	// 			animate(150),
	// 		]),
	// 		transition('true => false', [
	// 			animate(150),
	// 		]),
	// 	]),
	// ],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class MultiSelectionBtnComponent {

	@HostBinding('class.d-flex')
	@HostBinding('class.justify-content-between')
	@HostBinding('class.align-items-stretch')
	@HostBinding('class.my-1')
	private readonly alwaysTrue = true;

	constructor(
		public api : SchedulingApiService,
		private localize : LocalizePipe,
		private modalService : ModalService,
		private toastsService : ToastsService,
		private rightsService : RightsService,
	) {
	}

	public enums = enumsObject;
	public PTypeOfChange = PTypeOfChange;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showContent() : boolean {
		if (!this.api.isLoaded()) return false;
		return this.api.hasSelectedItems;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get borderPrimary() : boolean | null {
		if (!this.api.isLoaded()) return null;
		return this.api.data.shifts.hasSelectedItem;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onClickDeselect() : void {
		if (!this.api.isLoaded()) return;
		this.api.deselectAllSelections();
	}

	/**
	 * Handle the click to remove multiple shifts
	 */
	public onClickRemove(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		if (this.api.data.shifts.selectedItems.length > 30) return;
		this.api.createDataCopy();

		// You would probably expect
		// this.pDetailFormUtilsService.onRemoveClick(…);
		// here, but since we need a change-selectors-modal, things are different.
		const modalRef = this.modalService.openModal(modalContent, {
			size: 'lg',
		});
		void modalRef.result.then(value => {
			if (value.modalResult === 'success') {
				this.api.mergeDataCopy();
				const amountOfSelectedShifts = this.api.data.shifts.selectedItems.length;
				this.api.data.shifts.removeItems(this.api.data.shifts.selectedItems);
				void this.api.save({
					success: () => {
						this.toastsService.addToast({
							title: null,
							content: amountOfSelectedShifts > 1 ?
								this.localize.transform({ sourceString: '${amount} Schichten wurden gelöscht.', params: {
									amount : `${amountOfSelectedShifts}`,
								}}) :
								this.localize.transform('Eine Schicht wurde gelöscht.'),
							theme: enumsObject.PThemeEnum.DANGER,
							visibilityDuration: 'medium',
						});
					},
				});
			} else {
				this.api.dismissDataCopy();
			}
		});
	}

	/**
	 * We need to disable the removal of shifts if the amount of selected shifts is too large. This has two reasons:
	 * - The created database data is more efficient when for large number of shifts, the deletion is transmitted to other shifts
	 * - We need to honor request URL length limits
	 */
	public get isRemoveBtnDisabled() : boolean {
		return this.api.data.shifts.selectedItems.length > 15;
	}

	/**
	 * Get the number of selected shifts
	 */
	public get selectedAmount() : string | null {
		if (!this.api.isLoaded()) return null;
		if (!this.api.data.shifts.hasSelectedItem) return `${0}`;

		return `${this.api.data.shifts.selectedItems.length}`;
	}

	/**
	 * If there is a shift that can not be removed by the current user don't show the button
	 */
	public get showRemoveShiftsBtn() : boolean {
		return !this.api.data.shifts.selectedItems.some(shift => !this.rightsService.userCanWrite(shift.model));
	}

}
