import { Directive, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';

/**
 * A directive that can be used to open a modal.
 *
 * @example
 * 			<div
 * 				pModal
 * 				[modalContentOptions]="modalContentOptions"
 * 				[modalServiceOptions]="modalServiceOptions"
 * 			>Or click here</div>
 */
@Directive({
	selector: '[pModal]',

	// exportAs: 'pModal',
})
export class ModalDirective {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private modalContent ?: TemplateRef<PModalTemplateDirective>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private modalContentOptions : ModalContentOptions = {
		modalTitle: 'Sicher?',
		description: 'Echt jetzt?',
		closeBtnLabel: 'Okay …',
		dismissBtnLabel: 'Never ever!',
		hideDismissBtn: false,
		icon: undefined!,
	};
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private modalServiceOptions : ModalServiceOptions = {};
	@Input() private disabled : boolean | null = null;

	/**
	 * Triggers when modal gets opened
	 */
	@Output() private onModalOpen = new EventEmitter<Event>();

	@HostListener('click', ['$event']) private onClick(event : Event) : void {
		if (this.disabled) return;
		this.openModal(event);
	}

	constructor(
		private modalService : ModalService,
	) {
	}

	private openModal(event : Event) : void {
		this.onModalOpen.emit(event);
		if (!this.modalContent) {
			this.modalService.openDefaultModal(this.modalContentOptions, this.modalServiceOptions);
			return;
		}
		this.modalService.openModal(this.modalContent, this.modalServiceOptions);
	}
}
