import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: '.col-12',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class RowIsMissingDirective implements AfterViewInit {
	constructor(
		private console : LogService,
		public el : ElementRef<HTMLElement>,
	) {
	}

	public ngAfterViewInit() : void {
		this.validateParentElementsInDebugMode();
	}

	private validateParentElementsInDebugMode() : void {
		if (!Config.DEBUG) return;
		const classes = (() => {
			let result = '';
			const id = this.el.nativeElement.id;
			if (id) result += `#${id}`;
			for (const item of this.el.nativeElement.classList) result += `.${item}`;
			return result;
		})();
		const element = document.querySelector(classes);
		if (!element) return;
		if (!element.parentElement) return;
		if (element.parentElement.tagName === 'P-GRID') return;
		if (element.parentElement.classList.contains('row')) return;

		this.console.error(`Please remove unused class="row" or add .col-* to the children on the element with text content: ${element.textContent}`);
	}
}
