/**
 * All options that are available to the user to sort Transactions in a list
 */
export enum TransactionsSortedByEmum {
	DATE_TIME = 'dateTime',
	BOOKING_NUMBER = 'bookingNumber',
	AMOUNT = 'amount',
	TYPE = 'type',
	PAYMENT_METHOD_TYPE = 'paymentMethodType',
	CREATED_BY = 'createdBy',
	OFFER_NAME = 'offerName',
	INTERNAL_COMMENT = 'internalComment',
}
