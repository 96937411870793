<div
	class="d-flex flex-grow-1 dropdown"
>
	<ng-template [ngIf]="readMode" [ngIfElse]="realModeTemplate">
		<ng-template *ngTemplateOutlet="triggerReadModeTemplate ? triggerReadModeTemplate : defaultReadModeTemplate"></ng-template>
		<ng-template #defaultReadModeTemplate>
			<div class="form-control-read-mode crop-on-overflow">
				<ng-container *ngIf="triggerLabelTemplate"><ng-template [ngTemplateOutlet]="triggerLabelTemplate"></ng-template></ng-container>
				<ng-container *ngIf="!triggerLabelTemplate">{{ triggerLabel }}</ng-container>
			</div>
		</ng-template>
	</ng-template>
	<ng-template #realModeTemplate>
		<p-button
			*ngIf="!triggerTemplate"
			[class.p-editable-active]="pEditable"
			[btnClasses]="(pEditable ? 'p-editable-active' : '') + ' ' + (pEditable && !!saveChangesHook ? 'p-editable-has-hook'
			: '') + ' ' + (borderStyle === 'none' ? 'border-0' : '')"

			#triggerRef
			class="d-flex flex-grow-1 no-overflow-wrap"
			[show]="show"
			[canSet]="canSet"
			(triggerClick)="onClickTrigger(modalContent.template)"
			[theme]="btnStyle"
			[isActiveButton]="isHighlighted"
			[class.card-option]="triggerIsCardOption"
			[class.border-bottom-0]="triggerIsCardOption"
			[rounded]="rounded"
			[size]="size ?? null"
			[disabled]="disabled || !!readMode"
			[required]="hasRequiredError"
			[hasDanger]="hasDanger"
			[class.has-warning]="hasWarning"
			(focus)="focus.emit()"
			(blur)="onBlur()"

			role="combobox"
			[attr.aria-expanded]="this.dropdownMenuVisible"
		>
			<div class="d-flex justify-content-center align-items-center flex-grow-1">
			<ng-template
				[ngTemplateOutlet]="dropdownTriggerBtnContentTemplate ? dropdownTriggerBtnContentTemplate : defaultDropdownTriggerTemplate"
			></ng-template>
			<!-- [ngTemplateOutletContext]="{$implicit : item, close: c, dismiss: d}" -->
			<ng-template #defaultDropdownTriggerTemplate let-item let-c="close" let-d="dismiss">
				<div class="d-flex align-items-center flex-grow-1" [class.justify-content-center]="!!triggerIcon && !showLabel">
					<fa-icon
						*ngIf="!!triggerIcon && triggerIcon !== 'member-badge'"
						[class.mr-2]="showLabel"
						[fixedWidth]="true"
						[icon]="triggerIcon"
						[spin]="iconSpin"
					></fa-icon>
					<span *ngIf="showFilterLed" class="mr-1"><p-led
						[off]="!badgeContent"
						[theme]="enums.PThemeEnum.PRIMARY"
						[size]="size"

						[pTooltip]="itemsFilterTitle ? itemsFilterTitlePopover : null"
						[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
					></p-led><ng-template #itemsFilterTitlePopover><span *ngIf="itemsFilterTitle" class="p-2" [innerHTML]="itemsFilterTitle | pSafeHtml"></span></ng-template></span>
					<!-- <span *ngIf="showBadge" class="badge badge-primary mr-1">{{ badgeContent }}</span> -->
					<span class="text-left" [class.nowrap]="!wrapLabel" *ngIf="showLabel">
						<ng-container *ngIf="triggerLabelTemplate"><ng-template [ngTemplateOutlet]="triggerLabelTemplate"></ng-template></ng-container>
						<ng-container *ngIf="!triggerLabelTemplate">{{ triggerLabel }}</ng-container>
					</span>
				</div>
			</ng-template>

			<fa-icon *ngIf="!hideDropdownToggleTriangle" class="ml-1 {{dropdownToggleTriangleClasses}}" [icon]="dropdownMenuVisible ? enums.PlanoFaIconPool.DROPDOWN_OPEN : enums.PlanoFaIconPool.DROPDOWN_CLOSE"></fa-icon>
			</div>
		</p-button>

		<div *ngIf="triggerTemplate" #triggerRef>
			<ng-template [ngTemplateOutlet]="triggerTemplate"></ng-template>
		</div>

		<ng-template [ngIf]="!appendDropdownOnBody">
			<div
				*ngIf="dropdownMenuVisible"
				class="d-flex flex-column dropdown-menu dropdown-normal dropdown-menu-{{dropdownMenuAlignment}}"
				[class.border-0]="borderStyle === 'none'"
				role="menu"
				[@collapseOnLeave]
				[@expandOnEnter]
			>
				<ng-container *ngTemplateOutlet="labelTemplate; context: {$implicit: label}"></ng-container>
				<ng-template [ngTemplateOutlet]="listTemplate.template"></ng-template>
			</div>
		</ng-template>
		<ng-template #dropdownMenuRef>
			<div
				class="d-flex flex-column dropdown-menu dropdown-menu-{{dropdownMenuAlignment}}"
				[class.border-0]="borderStyle === 'none'"
				role="menu"
			>
				<div
					*ngIf="dropdownMenuVisible"
					class="d-flex flex-column"
					[@collapseOnLeave]
					(@collapseOnLeave.done)="removeDropdownElementAfterAnimation()"
					[@expandOnEnter]>
					<ng-container *ngTemplateOutlet="labelTemplate; context: {$implicit: label}"></ng-container>
					<ng-container *ngTemplateOutlet="listTemplate.template"></ng-container>
				</div>
			</div>
		</ng-template>
		<!-- We explicitly use "visibility: hidden" to reduce "update layer tree" time on mobile devices -->

		<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
			<p-modal-content
				[modalTitle]="label ?? ('Bitte wählen…' | localize)"
				[icon]="!!triggerIcon && triggerIcon !== 'member-badge' ? triggerIcon : null"
				closeBtnLabel="OK" i18n-closeBtnLabel
				[hideDismissBtn]="true"
				[showCustomFooter]="true"
				[size]="'frameless'"
				(onClose)="c($event)"
				(onDismiss)="d($event)"
			>
				<p-modal-content-body
					class="d-flex flex-column"
				>
					<ng-container *ngTemplateOutlet="listTemplate.template; context: {close: c, dismiss: d}"></ng-container>
				</p-modal-content-body>
				<p-modal-content-footer
					*ngIf="isMultiSelect"
				>
					<p-button
						class="ml-auto"
						(triggerClick)="c($event)"
					>OK</p-button>
				</p-modal-content-footer>
			</p-modal-content>
		</ng-template>

		<ng-template #labelTemplate let-text let-horizontalPadding="horizontalPadding" let-verticalPadding="verticalPadding">
			<div
				*ngIf="CONFIG.IS_MOBILE && text">
					<label
						[class.px-3]="horizontalPadding !== false"
						[class.py-2]="verticalPadding !== false"
						class="m-0"
					><fa-icon
						*ngIf="!!triggerIcon && triggerIcon !== 'member-badge'"
						class="mr-2"
						[icon]="triggerIcon"
						[spin]="iconSpin"
					/>{{text}}</label
					>
			</div>
		</ng-template>

		<ng-template #listTemplate="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
			<ng-template
				ngFor let-item
				[ngForOf]="items"
			>
				<ng-container *ngIf="item.show===true">
					<ng-template
						[ngTemplateOutlet]="dropdownItemTemplate ? dropdownItemTemplate : defaultDropdownItemTemplate"
						[ngTemplateOutletContext]="{$implicit : item.dropdownItemTemplateContext ?? item, close: c, dismiss: d}"
					></ng-template>
				</ng-container>
			</ng-template>
		</ng-template>

		<ng-template #innerDefaultDropdownItemTemplate let-item let-c="close" let-d="dismiss">
				<p-spinner
					[size]="enums.BootstrapSize.SM"
					*ngIf="item.isLoading; else loaded"
					class="d-inline"
					[class.text-white]="isPrimary(item)"
				></p-spinner>
				<ng-template #loaded>
					<span
						class="d-flex align-items-center"
						[class.pt-1]="!!item.description"
						[class.justify-content-end]="!item.isAlignedLeft">
						<ng-template [ngIf]="isMultiSelect && !item.isLoading">
							<fa-icon *ngIf="!item.hideIcon" class="mr-2" [class.text-primary]="dropdownType === DropdownTypeEnum.FILTER && !isActive(item)" [icon]="multiSelectIcon(item)"></fa-icon>
						</ng-template>
						<ng-container *ngIf="item.hasPrependedIcon; else dropdownItemPrependTemplate">
							<fa-icon [class.d-flex]="item.customIconWidth" [class.justify-content-center]="item.customIconWidth" [style]="item.customIconWidth ? 'width:'+item.customIconWidth+'px' : ''" [class.mr-2]="!item.isLoading" [icon]="item.prependedItem"></fa-icon>
						</ng-container>
						<ng-template #dropdownItemPrependTemplate>
							<ng-template *ngTemplateOutlet="item.prependedItem"></ng-template>
						</ng-template>
						<span class="text-left" style="white-space: normal;"><ng-template [ngTemplateOutlet]="item.innerTemplate"></ng-template>{{ item.label }}</span>
					</span>
					<ng-template [ngIf]="!!item.descriptionString">
						<span class="text-left text-style-reset-to-body text-muted item-sub-text pt-1 pb-1 d-flex" style="white-space: normal;">{{ item.descriptionString }}</span>
					</ng-template>
					<ng-template [ngIf]="!!item.descriptionTemplateRef">
						<span class="text-left text-style-reset-to-body text-muted item-sub-text pt-1 pb-1 d-flex" style="white-space: normal;"><ng-container *ngTemplateOutlet="item.descriptionTemplateRef"></ng-container></span>
					</ng-template>
					<ng-template [ngIf]="!!item.disabled && item.cannotSetHint">
						<span class="text-left text-style-reset-to-body item-sub-text pt-1 pb-1 d-flex" style="white-space: normal;"><fa-icon icon="ban"></fa-icon>&nbsp;{{ item.cannotSetHint }}</span>
					</ng-template>
				</ng-template>
		</ng-template>

		<ng-template #defaultDropdownItemTemplate let-item let-c="close" let-d="dismiss">
			<hr *ngIf="item.hasSeparator" class="m-0"/>
			<button
				*ngIf="!item.link"
				type="button"
				[pEditable]="pEditable"
				[api]="api"
				[valid]="true"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"
				pEditableInstantSaveOnClick
				(triggerClick)="clickItem(item, c, $event)"

				[disabled]="item.disabled"
				[class]="dropdownItemClasses(item)"
				(blur)="onBlur()"
				role="option"
				[attr.aria-selected]="isPrimary(item)"
			>
				<ng-template *ngTemplateOutlet="innerDefaultDropdownItemTemplate; context: {$implicit: item}"></ng-template>
			</button>
			<a
				*ngIf="item.link"
				(blur)="onBlur()"
				(click)="CONFIG.IS_MOBILE ? undefined : clickItem(item, c, $event)"
				[class]="dropdownItemClasses(item)"
				[routerLink]="item.link"
				[queryParams]="item.linkQueryParams"
				[fragment]="item.linkFragment"
				[target]="item.openInNewTab ? '_blank' : '_self'"
				rel="noopener"
				role="button"
				routerLinkActive>
				<ng-template *ngTemplateOutlet="innerDefaultDropdownItemTemplate; context: {$implicit: item}"></ng-template>
			</a>
		</ng-template>
	</ng-template>

</div>
<ng-template [ngIf]="!!control">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	></p-validation-hint>
</ng-template>
