/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays, import/no-relative-parent-imports -- Remove this before you work here. */
import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientSharedModule } from '@plano/client/shared/client-shared.module';
import { PListsModule } from '@plano/client/shared/p-lists/p-lists.module';
import { ScrollShadowBoxModule } from '@plano/shared/core/component/scroll-shadow-box/scroll-shadow-box.module';
import { CoreModule } from '@plano/shared/core/core.module';
import { PFormsModule } from '@plano/shared/p-forms/p-forms.module';
import { PCalendarModule } from '../shared/p-calendar/p-calendar.module';
import { PMemberModule } from '../shared/p-member/p-member.module';
import { PMemoModule } from '../shared/p-memo/p-memo.module';
import { PShiftModule } from '../shared/p-shift-module/p-shift.module';
import { PSidebarModule } from '../shared/p-sidebar/p-sidebar.module';
import { SchedulingComponent } from './scheduling.component';
import { PBookingsModule } from './shared/p-bookings/p-bookings.module';
import { PSchedulingCalendarModule } from './shared/p-scheduling-calendar/p-calendar.module';

// import { SchedulingRoutingModule } from './scheduling-routing.module';

@NgModule({
	imports: [

		// SchedulingRoutingModule,
		// ShiftExchangesModule,
		ClientSharedModule,
		CoreModule,
		PBookingsModule,
		PCalendarModule,
		PFormsModule,
		PListsModule,
		PMemberModule,
		PSchedulingCalendarModule,
		PShiftModule,
		PMemoModule,
		PSidebarModule,
		ScrollShadowBoxModule,
	],
	declarations: [
		SchedulingComponent,

		// PShiftExchangeMarketComponent,
	],
	providers: [
		NgbActiveModal,
	],
	exports: [
		SchedulingComponent,
	],
})
export class SchedulingModule {}
