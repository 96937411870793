import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { PTabsComponent } from '@plano/client/shared/p-tabs/p-tabs/p-tabs.component';
import { PBadgeComponentInterface } from '@plano/client/shared/shared/p-badge/p-badge.types';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { BadgeDirective } from '@plano/shared/core/directive/badge.directive';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PTabSizeEnum {
	LG = 'lg',
	SM = 'sm',
	FRAMELESS = 'frameless',
}

@Component({
	selector: 'p-tab',
	templateUrl: './p-tab.component.html',
	styleUrls: ['./p-tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTabComponent extends AttributeInfoBaseComponentDirective implements AfterContentInit {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public label : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasDanger : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('badgeContent') private _badgeContent : PBadgeComponentInterface['content'] = null;

	/**
	 * Id to be used for the tab element
	 */
	@Input() public innerTabId : string | undefined = undefined;

	/** @see PBadgeComponent['isDisabled'] */
	@Input() public badgeDisabled : boolean = false;

	/**
	 * This input is necessary to avoid endless spinners if your tab has a pTabs inside (nested tabs),
	 * this way we can remove the /undefined part of the url if this is set to false or not set.
	 * For more details: @see {@link PTabsComponent#cleanUpUrl}
	 */
	@Input() public hasPTabsChild : boolean = false;

	/**
	 * Theme to be used on the badge
	 */
	@Input() public badgeTheme : PThemeEnum = enumsObject.PThemeEnum.DANGER;

	/** @see {@link BadgeDirective#infoBadge} */
	@Input() public infoBadge : BadgeDirective['infoBadge'] = false;

	/** Should the badge be appended on the headline of the tab? */
	@Input() public badgeOnHeadline : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasFilter : boolean | null = null;

	/**
	 * Should this tab be active by default?
	 * Note that if there are multiple tabs where this is set to true, the first one will be active.
	 */
	@Input() public initialActiveTab : boolean = false;

	@Input() public icon : PFaIcon | null = null;

	/** The text that describes the filter setting */
	@Input() public filterDescription : PDictionarySourceString | null = null;

	@HostBinding('attr.role') private readonly role = 'tabpanel';

	@HostBinding('attr.aria-label')
	private get ariaLabel() : string | null {
		return this.label;
	}

	/**
	 * Is the content of this tab scrollable?
	 */
	@Input() public scrollableTab : boolean | null = null;

	/**
	 * Should we show a tab headline for this tab?
	 */
	@Input() public showTabHeadline : boolean = false;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public select : EventEmitter<boolean> = new EventEmitter<boolean>();

	@HostBinding('class.flex-grow-1')
	private _active : boolean = false;

	@HostBinding('class.p-4')
	private get _p4() : boolean {
		return this.size === PTabSizeEnum.LG;
	}
	@HostBinding('class.p-3')
	private get _p3() : boolean {
		return this.size === PTabSizeEnum.SM;
	}
	@HostBinding('class.p-0')
	private get _p0() : boolean {
		return this.size === PTabSizeEnum.FRAMELESS;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : PTabSizeEnum = PTabSizeEnum.LG;

	/**
	 * This id is used to find tabs by url param.
	 * If this is set, selecting a tab will change the url to this id.
	 * If this is not set, the url will be touched/read.
	 */
	@Input() public urlName : string | null = null;

	@HostBinding('hidden') private get isHidden() : boolean {
		return !this.active;
	}

	constructor(
		public el : ElementRef<HTMLElement>,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override console : LogService,
		private pTabsParent : PTabsComponent,
	) {
		super(true, changeDetectorRef, console);
	}

	public hover : boolean = false;
	public Config = Config;
	protected override attributeInfoRequired = false;

	/**
	 * Should there be a little dot on this tab? Maybe with a icon or a number inside?
	 */
	public get badgeContent() : PBadgeComponentInterface['content'] {
		if (this._badgeContent !== null) return this._badgeContent;
		return this.hasDanger;
	}

	/**
	 * Is this the currently selected tab?
	 */
	public get active() : boolean {
		return this._active;
	}

	/**
	 * Should this be the initially/currently selected tab?
	 */
	public set active(input : boolean) {
		this.select.emit(input);
		this._active = input;
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (this.label === null && this.icon === null) throw new Error('[label] OR [icon] must be set in p-tab');
		return super.ngAfterContentInit();
	}

	/**
	 * Should the tab render its content?
	 */
	public get shouldRenderContent() : boolean {
		return !this.pTabsParent.renderOnlyContentOfActiveTab || this.pTabsParent.activeTab === this;
	}
}
