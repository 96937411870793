<p-dropdown
	class="ml-2 d-flex"
	*ngIf="!Config.IS_MOBILE || showShiftsFilterBtn"
	[size]="enums.BootstrapSize.SM"
	label="Filter" i18n-label
	[itemsFilterTitle]="itemsFilterTitle"
	[dropdownType]="DropdownTypeEnum.FILTER"
	[highlightDropdownOnFilterActive]="false"
	[icon]="enums.PlanoFaIconPool.FILTER"
	[hideTriggerLabel]="course.bookingsVisible || Config.IS_MOBILE"
	[hideBadge]="Config.IS_MOBILE"
>
	<!-- ICU selection messages marked for translation -->
	<div hidden><span #translatedHolydayFilterLabel i18n>Geburtstage, Feiertage {!!hasSchulferienData, select, true {& Schulferien}}</span></div>
	<p-dropdown-item
		(onClick)="hideAllHolidaysAndBirthdays()"
		[active]="!schedulingFilterService.hideAllHolidays && !schedulingFilterService.hideAllBirthdays"
	>
		<span>{{translatedHolydayFilterLabel.textContent!}}</span>
	</p-dropdown-item>

	<p-dropdown-item
		(onClick)="schedulingFilterService.hideAllAbsences=!schedulingFilterService.hideAllAbsences"
		[active]="!schedulingFilterService.hideAllAbsences"
	>
		<span i18n>Abwesenheiten</span>
	</p-dropdown-item>

	<ng-template [ngIf]="showShowOnlyMemberBtn">
		<!-- ICU selection messages marked for translation -->
		<p-dropdown-item
			(onClick)="schedulingFilterService.hideAllShiftsFromOthers=!schedulingFilterService.hideAllShiftsFromOthers"
			[active]="!schedulingFilterService.hideAllShiftsFromOthers"
			[isLoading]="!meService.isLoaded() || !api.isLoaded()"
		>
			<span i18n>Schichten von anderen</span>
		</p-dropdown-item>
		<p-dropdown-item
			(onClick)="schedulingFilterService.hideAllShiftsFromMe=!schedulingFilterService.hideAllShiftsFromMe"
			[active]="!schedulingFilterService.hideAllShiftsFromMe"
			[isLoading]="!meService.isLoaded() || !api.isLoaded()"
		>
			<span i18n>Meine Schichten</span>
		</p-dropdown-item>
	</ng-template>

	<p-dropdown-item
		*ngIf="showCourseViewOptionsDropdown && showShowStatusBtn"
		(onClick)="toggleCourseVisible()"
		[active]="courseService.courseVisible"
	>
	<span i18n>Status buchbarer Angebote</span>
	</p-dropdown-item>
	<ng-template [ngIf]="showSidebarFilters">
		<p-dropdown-item
			*ngVar="filterService.hiddenItemsCount('shiftModels') as hiddenShiftModels"
			[active]="hiddenShiftModels <= 0"
			[hasSeparator]="true"
			[link]="Config.IS_MOBILE ? '/client/mobile-sidebar' : '.'"
			[isAlignedLeft]="true"
			additionalClasses="btn-lg"
			[linkQueryParams]="{'showShiftModelTab':'true', 'activateFilter':'true'}"
			linkFragment="{{filterService.firstHiddenShiftModelId}}"
			[hideIcon]="true">
			<ng-container i18n><span
				class="mr-2" [class.text-primary]="hiddenShiftModels > 0">{{hiddenShiftModels}}</span
				>Tätigkeiten ausgeblendet</ng-container
			><fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.NAV_FORWARD"></fa-icon>
		</p-dropdown-item>
		<p-dropdown-item
			*ngVar="filterService.hiddenItemsCount('members') as hiddenMembers"
			[active]="hiddenMembers <= 0"
			[link]="Config.IS_MOBILE ? '/client/mobile-sidebar' : '.'"
			[isAlignedLeft]="true"
			additionalClasses="btn-lg"
			[linkQueryParams]="{'showMembersTab':'true', 'activateFilter':'true'}"
			linkFragment="{{filterService.firstHiddenMemberId}}"
			[hideIcon]="true">
			<ng-container i18n><span
				class="mr-2" [class.text-primary]="hiddenMembers > 0">{{hiddenMembers}}</span
				>User ausgeblendet</ng-container
			><fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.NAV_FORWARD"></fa-icon>
		</p-dropdown-item>
	</ng-template>
</p-dropdown>

