/* eslint-disable import/no-relative-parent-imports -- Remove this before you work here. */
import { NgModule } from '@angular/core';
import { PEditableModalFormComponent } from '@plano/client/shared/p-editable/p-editable-modal-form/p-editable-modal-form.component';
import { PEditableShowroomComponent } from '@plano/client/shared/p-editable/p-editable-showroom/p-editable-showroom.component';
import { CoreModule } from '@plano/shared/core/core.module';
import { PFaIconModule } from '../../../shared/core/component/fa-icon/fa-icon.module';
import { EditableDirective, EditableDismissButtonDirective, EditableInstantSaveOnClickDirective, EditableSuccessButtonDirective, EditableTriggerClickableDirective, EditableTriggerFocussableDirective, HiddenInEditModeDirective, PVisibleInEditModeDirective } from './editable/editable.directive';
import {
	PEditableBoxComponent,
	PEditableBoxFormComponent, PEditableBoxHeaderComponent,
} from './p-editable-box/p-editable-box.component';
import {
	PEditableModalButtonComponent,
	PEditableModalButtonHeaderComponent,
} from './p-editable-modal-button/p-editable-modal-button.component';

@NgModule({
	imports: [
		CoreModule,
		PFaIconModule,
	],
	declarations: [
		EditableDirective,
		EditableDismissButtonDirective,
		EditableInstantSaveOnClickDirective,
		EditableSuccessButtonDirective,
		EditableTriggerClickableDirective,
		EditableTriggerFocussableDirective,
		HiddenInEditModeDirective,
		PEditableBoxComponent,
		PEditableBoxFormComponent,
		PEditableBoxHeaderComponent,
		PEditableModalButtonComponent,
		PEditableModalButtonHeaderComponent,
		PEditableModalFormComponent, PEditableShowroomComponent, PVisibleInEditModeDirective,
	],
	exports: [
		EditableDirective,
		EditableDismissButtonDirective,
		EditableInstantSaveOnClickDirective,
		EditableSuccessButtonDirective,
		EditableTriggerClickableDirective,
		EditableTriggerFocussableDirective,
		HiddenInEditModeDirective,
		PEditableBoxComponent,
		PEditableBoxFormComponent,
		PEditableBoxHeaderComponent, PEditableModalButtonComponent,
		PEditableModalButtonHeaderComponent,
		PEditableModalFormComponent, PEditableShowroomComponent, PVisibleInEditModeDirective,
	],
})
export class PEditableModule {}
