import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { menuNavigationItemsOrder } from '@plano/client/shared/menu-navigation-items';
import { SchedulingApiRightGroup, SchedulingApiRightGroupRole, SchedulingApiService, SchedulingApiShiftModels } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-accesscontrol',
	templateUrl: './accesscontrol.component.html',
	styleUrls: ['./accesscontrol.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AccessControlComponent {
	@HostBinding('class.flex-grow-1')
	@HostBinding('class.d-flex')
	@HostBinding('class.position-relative') protected _alwaysTrue = true;

	constructor(
		public api : SchedulingApiService,
		public toasts : ToastsService,
		private localize : LocalizePipe,
		private console : LogService,
	) {
		const queryParams = new HttpParams()
			.set('data', 'rights');

		void this.api.load({
			searchParams: queryParams,
			success: () => {
				const firstRightGroup = api.data.rightGroups.get(0);
				if (firstRightGroup === null) {
					this.console.error('There should have been at least one rightGroup');
					return;
				}
				this.selectedRightGroup = firstRightGroup;
			},
		});
	}

	public CONFIG = Config;

	public selectedRightGroup : SchedulingApiRightGroup | null = null;
	public isSaving : boolean = false;
	public selectedTab : string = 'settings';

	public enums = enumsObject;

	/**
	 * Open Toast that tells user that data has been saved
	 */
	public openToast() : void {
		this.toasts.addToast({
			content: this.localize.transform('Änderung der Rechte wurde gespeichert'),
			theme: enumsObject.PThemeEnum.SUCCESS,
		});
	}

	// HACK: PLANO-9873 direct binding of groupByParentName causes problems
	private _shiftModelsForList : SchedulingApiShiftModels[] | null = null;

	/**
	 * Sort given shiftModels by name
	 */
	public sortedShiftModels(shiftModels : SchedulingApiShiftModels) : SchedulingApiShiftModels {
		return shiftModels.sortedBy(shiftModel => shiftModel.name);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftModelsForList() : SchedulingApiShiftModels[] {
		if (!this._shiftModelsForList) {
			this._shiftModelsForList = this.api.data.shiftModels.groupByParentName;
		}
		return this._shiftModelsForList;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isOwnerGroup(rightGroup : SchedulingApiRightGroup) : boolean {
		return rightGroup.role === SchedulingApiRightGroupRole.CLIENT_OWNER;
	}

	private _headlineIcon : PlanoFaIconPoolValues | null = null;

	/**
	 * Get headline icon for page
	 */
	public get headlineIcon() : PlanoFaIconPoolValues {
		if (!this._headlineIcon) {
			const menuItem = menuNavigationItemsOrder.find(item => item.label === 'Rechteverwaltung');
			this._headlineIcon = menuItem!.icon!;
		}
		return this._headlineIcon;
	}
}
