import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PDetailFormUtilsService } from '@plano/client/shared/detail-form-utils.service';
import { EditableHookType } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PMoment, PMomentService } from '@plano/client/shared/p-moment.service';
import { PTypeOfChange } from '@plano/client/shared/p-transmission/change-selectors-modal.component';
import { PageWithDetailFormComponentInterface } from '@plano/client/shared/page-with-detail-form-component.interface';
import { MeService, RightsService, SchedulingApiCourseType, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel, SchedulingApiShiftModels, ShiftId } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PPushNotificationsService, PRequestWebPushNotificationPermissionContext } from '@plano/shared/core/p-push-notifications.service';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { DropdownTypeEnum } from '@plano/shared/p-forms/p-dropdown/p-dropdown.component';
import { SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW } from './shift-modal-sizes';

@Component({
	selector: 'p-shift',
	templateUrl: './shift.component.html',
	styleUrls: ['./shift.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ShiftComponent implements OnInit, OnDestroy, PageWithDetailFormComponentInterface<SchedulingApiShift> {
	@HostBinding('class.flex-grow-1')
	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column') protected _alwaysTrue = true;

	// NOTE: I think this does not need to be an @Input() ^nn
	@Input() public item : SchedulingApiShift | null = null;

	constructor(
		private activatedRoute : ActivatedRoute,
		public api : SchedulingApiService,
		public schedulingUrlParams : SchedulingService,
		private meService : MeService,
		private pWishesService : PWishesService,
		private pRouterService : PRouterService,
		private pDetailFormUtilsService : PDetailFormUtilsService<SchedulingApiShift>,
		private pPushNotificationsService : PPushNotificationsService,
		private pMoment : PMomentService,
		private rightsService : RightsService,

		public modalService : ModalService,
		private localize : LocalizePipe,
		private toastsService : ToastsService,
	) {
	}

	public DropdownTypeEnum = DropdownTypeEnum;
	public enums = enumsObject;
	public PBtnThemeEnum = PBtnThemeEnum;
	public PTypeOfChange = PTypeOfChange;

	public ngOnInit() : void {
		void this.initComponent();
	}

	/**
	 * Could this item be found?
	 */
	public get requestedItemCouldNotBeFound() : boolean {
		return !this.item && !!this.routeId && !this.api.isBackendOperationRunning;
	}

	/**
	 * Load and set everything that is necessary for this component
	 */
	public async initComponent(id : Id | null = null) : Promise<void> {
		this.getRouteShiftModelId(id);
		const result = this.getItem();
		this.api.isLoaded(() => {
			this.getWritableShiftModelsForMember();
		});
		this.initWishes();
		return result;
	}

	private initWishes() : void {
		this.pWishesService.item = this.item ?? null;
	}

	public ngOnDestroy() : void {
		this.pDetailFormUtilsService.onDestroy(this.api);
		this.pWishesService.resetToPreviousItem();
	}

	/** @see PageWithDetailFormComponentInterface#routeId */
	public get routeId() : ShiftId | null {
		if (!this.activatedRoute.snapshot.paramMap.has('id')) return null;
		const paramId = this.activatedRoute.snapshot.paramMap.get('id');
		if (paramId === '0') return null;
		if (paramId === null) throw new Error('Param id is allowed to be `0`, but should never be null');
		return ShiftId.fromUrl(paramId);
	}

	public routeShiftModelId : Id | null = null;

	/**
	 * Check if url has shiftModelId
	 */
	public getRouteShiftModelId(id : Id | null = null) : void {
		if (id) {
			this.routeShiftModelId = id;
			return;
		}

		const idAsString = this.activatedRoute.snapshot.paramMap.get('shiftmodelid');
		this.routeShiftModelId = idAsString === null ? null : Id.create(+idAsString);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get routeStartTimestamp() : number | null {
		if (!this.activatedRoute.snapshot.paramMap.has('start')) return null;

		const startParam = this.activatedRoute.snapshot.paramMap.get('start')!;
		return +startParam;
	}

	private get getDefaultTimeForNewShift() : PMoment.Moment | undefined {
		const shiftModel = this.api.data.shiftModels.get(this.routeShiftModelId);
		if (!shiftModel) return undefined;

		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		const timestamp = this.routeStartTimestamp ? this.routeStartTimestamp : undefined;
		return this.pMoment.m(+this.pMoment.m(timestamp).startOf('day') + shiftModel.time.start);
	}

	/**
	 * Create new item which than can be filled with data from the form
	 */
	public createNewItem() : void {
		// Usually i would create a new item here. But shift is different. I need a shiftModelId first.
		// Search for `createNewShift` in this document.
	}

	/**
	 * Get the item by the provided id
	 */
	private async getByRouteId() : Promise<boolean | null> {
		if (!this.routeId) return false;

		let item : SchedulingApiShift | null = null;

		// TODO: PLANO-156519
		if (this.api.data.attributeInfoShifts.isAvailable) item = this.api.data.shifts.get(this.routeId);

		if (!item) {
			assumeDefinedToGetStrictNullChecksRunning(this.routeId, `routeId`, 'Given id is not defined [PLANO-FE-2RA]');
			await SchedulingApiShift.loadDetailed(this.api, this.routeId);
			if (!this.api.data.attributeInfoShifts.isAvailable) return false;
			item = this.api.data.shifts.get(this.routeId);
			this.item = item;
			return true;
		}
		if (item.isNewItem()) {
			this.item = item;
			return true;
		}

		await item.loadDetailed();
		this.item = item;
		return true;
	}

	/**
	 * Create new item by the provided ShiftId
	 */
	private async createByRouteShiftModelId() : Promise<boolean> {
		// User navigated from e.g. shift-tooltip to this "Create Shift Exchange" form

		if (this.routeShiftModelId === null) return false;

		await SchedulingApiShiftModel.loadDetailed(this.api, this.routeShiftModelId);
		const timeForNewShift = this.getDefaultTimeForNewShift;

		const shiftModel = this.api.data.shiftModels.get(this.routeShiftModelId);
		if (shiftModel) {
			this.api.createDataCopy();
			assumeDefinedToGetStrictNullChecksRunning(timeForNewShift, 'timeForNewShift');
			this.item = await this.api.data.shifts.createNewShift(shiftModel, timeForNewShift, null);
		}

		return true;
	}

	/**
	 * Get Item for this detail page
	 * If id is available load the item
	 * Else create a new item by provided shiftmodel
	 */
	public async getItem() : Promise<void> {
		const getIt = async () : Promise<boolean> => {
			if (await this.getByRouteId()) return true;
			if (await this.createByRouteShiftModelId()) return true;
			return false;
		};

		if (await getIt()) return;

		// Make sure we have some data as basis for this item
		if (!this.api.isLoaded()) {
			this.schedulingUrlParams.updateQueryParams();
			await this.api.load({
				searchParams: this.schedulingUrlParams.queryParams,
			});
			if (await getIt()) return;
			await this.createNewItem();
		} else {
			await this.createNewItem();
		}

		this.initWishes();
	}

	/**
	 * Create Item after shiftModel has been selected
	 */
	public onSelectShiftModelId(id : Id | null = null) : void {
		if (id === null) return;

		if (this.routeShiftModelId?.equals(id)) return;

		if (this.item) {
			this.item = null;
			this.api.dismissDataCopy();
		}

		assumeDefinedToGetStrictNullChecksRunning(id, 'id');
		let url = `/client/shift/create/shiftmodel/${id.toString()}`;
		if (this.routeStartTimestamp) url += `/start/${this.routeStartTimestamp}`;

		// eslint-disable-next-line ban/ban -- create a shift for a specific shiftModel, doesn't make sense to open in a new tab
		void this.pRouterService.navigate([url], { replaceUrl: true, queryParamsHandling: 'preserve' });
	}

	/**
	 * get all writable shiftModels for the current user.
	 * Needed for the shiftmodel list when creating new shifts.
	 */
	public writableShiftModelsForMember : SchedulingApiShiftModels = new SchedulingApiShiftModels(null, null);
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getWritableShiftModelsForMember() : void {
		this.meService.isLoaded(() => {
			if (this.meService.data.isOwner) {
				this.writableShiftModelsForMember = this.api.data.shiftModels.filterBy(item => !item.trashed);
				return;
			}

			const result : SchedulingApiShiftModels = new SchedulingApiShiftModels(this.api, null);
			const member = this.api.data.members.get(this.meService.data.id);
			assumeDefinedToGetStrictNullChecksRunning(member, 'member', 'PRODUCTION-52P');

			for (const shiftModel of this.api.data.shiftModels.iterable()) {
				if (shiftModel.trashed) continue;
				if (!member.canWrite(shiftModel)) continue;
				result.push(shiftModel);
			}
			this.writableShiftModelsForMember = result;
		});
	}

	private askForNotificationPermissionIfNecessary(item : SchedulingApiShift) : void {
		assumeDefinedToGetStrictNullChecksRunning(item.model, 'item.model');
		if (item.model.courseType !== SchedulingApiCourseType.ONLINE_INQUIRY) return;
		this.pPushNotificationsService.requestWebPushNotificationPermission(
			PRequestWebPushNotificationPermissionContext.ONLINE_INQUIRY_SHIFT_CREATED,
		);
	}

	/**
	 * Save the provided new shift to the database
	 */
	public saveNewItem(item : SchedulingApiShift) : void {
		this.askForNotificationPermissionIfNecessary(item);

		// PLANO-170639 remove skipInternalNavigation usage
		void this.pDetailFormUtilsService.saveNewItem(this.api, item, `»${item.name}«`,null, null, true);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showShiftModelInputSection() : boolean | null {
		if (!this.api.isLoaded()) return null;
		if (this.routeId) return false;
		if (this.routeShiftModelId) return false;
		return true;
	}

	/**
	 * Handle click on cancel course button
	 */
	public async onCancelClick(modalContent : TemplateRef<PModalTemplateDirective>) : EditableHookType {
		this.api.createDataCopy();

		// You would probably expect
		// this.pDetailFormUtilsService.onRemoveClick(…);
		// here, but since we need a change-selectors-modal, things are different.
		const promise = this.modalService.openModal(modalContent, {
			size: this.item ? SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW : undefined!,
		}).result;
		const promiseResult = await promise;
		if (promiseResult.modalResult === 'dismiss') {
			this.api.dismissDataCopy();
		} else {
			assumeNonNull(this.item);
			this.api.mergeDataCopy();
			this.item.isCourseCanceled = true;
			void this.api.save();
		}
		return promise;
	}

	/**
	 * Handle Click on delete course button
	 */
	public async onRemoveClick(modalContent : TemplateRef<PModalTemplateDirective>) : EditableHookType {
		this.api.createDataCopy();

		// You would probably expect
		// this.pDetailFormUtilsService.onRemoveClick(…);
		// here, but since we need a change-selectors-modal, things are different.
		const promise = this.modalService.openModal(modalContent, {
			size: this.item ? SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW : undefined!,
		}).result;
		const promiseResult = await promise;
		if (promiseResult.modalResult === 'dismiss') {
			this.api.dismissDataCopy();
		} else {
			assumeNonNull(this.item);
			this.api.mergeDataCopy();
			this.api.data.shifts.removeItem(this.item);
			await this.api.save({
				success: () => {
					this.toastsService.addToast({
						title: null,
						content: this.localize.transform({
							sourceString: '»${item}« wurde gelöscht.',
							params: {item : this.item!.name},
						}),
						theme: enumsObject.PThemeEnum.DANGER,
						visibilityDuration: 'medium',
					});
				},
			});
			this.api.data.shiftChangeSelector.addChangeSelectors = false;
			this.pRouterService.navBack();

		}
		return promise;
	}

	/**
	 * get showRemoveButton
	 */
	public get showRemoveButton() : boolean | undefined {
		assumeNonNull(this.item);
		return !this.item.isNewItem() && this.rightsService.userCanWrite(this.item.model);
	}

	/**
	 * get showCancelButton
	 */
	public get showCancelButton() : boolean | undefined {
		assumeNonNull(this.item);
		if (this.item.model.courseType === SchedulingApiCourseType.NO_BOOKING) return false;
		return this.item.isCourse && (!this.item.isNewItem() && this.rightsService.userCanWrite(this.item));
	}

	/**
	 * Is this a shift that can have bookings and is not canceled?
	 */
	public get cancellationSettingsIsPossible() : boolean {
		assumeNonNull(this.item);
		return !this.item.isNewItem() && !!this.item.isCourse && this.item.isCourseCanceled === false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get basissettingsTabLabel() : PDictionarySource {
		return 'Grundeinstellungen';
	}

	public selectedShiftModelToCopy : SchedulingApiShiftModel | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onSelectShiftModel(event : Id) : void {
		this.onSelectShiftModelId(event);
	}
}
