import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { ExtendedToastObject, ToastsService } from '@plano/client/service/toasts.service';
import { PAlertTheme, PAlertThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgProgressComponent } from 'ngx-progressbar';
import { Subscription } from 'rxjs';
import { Md5 } from 'ts-md5';

@Component({
	selector: 'p-toast[toast]',
	templateUrl: './p-toast.component.html',
	styleUrls: ['./p-toast.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PToastComponent implements AfterViewInit, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public toast ! : ExtendedToastObject;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('theme') public _theme ?: PAlertTheme;

	@HostBinding('class.d-md-block')
	@HostBinding('class.d-none') private get _hasFoo() : boolean {
		return !this.toast.visibleOnMobile;
	}

	@HostBinding('class.mb-3') protected _alwaysTrue = true;

	@ViewChild('progressBar') public progressBar : NgProgressComponent | null = null;

	@HostListener('mouseover') private _mouseover(_event : MouseEvent) : void {
		this.toast.progressPaused$.next(true);
	}
	@HostListener('mouseleave') private _mouseleave(_event : MouseEvent) : void {
		this.toast.progressPaused$.next(false);
	}

	constructor(
		public toasts : ToastsService,
		private localize : LocalizePipe,
		private changeDetectorRef : ChangeDetectorRef,
	) {
	}

	public enums = enumsObject;

	private subscription : Subscription | null = null;

	/** @see NgProgressComponent#speed */
	public get progressbarSpeed() : NgProgressComponent['speed'] {
		return this.toasts.PROGRESSBAR_SPEED;
	}

	public ngAfterViewInit() : void {
		const visibilityDuration = this.toasts.visibilityDurationToNumber(this.toast.visibilityDuration ?? 'infinite');
		if (visibilityDuration !== null) {
			if (!this.notFirefox) return;
			assumeNonNull(this.progressBar);
			this.progressBar.color = '#ffffffaa';

			this.subscription = this.toast.progressChange$.subscribe((input) => {
				switch (input) {
					case 'start':
						this.progressBar?.start();
						break;
					case 'complete':
						this.progressBar?.complete();
						break;
					default:
						this.progressBar?.set(input);
				}

				this.changeDetectorRef.detectChanges();
			});
		}
	}

	public visible : boolean = true;

	/**
	 * A type for Alerts, that defines mainly the the color of a alert component
	 */
	public get theme() : PAlertTheme {
		if (this._theme !== undefined) return this._theme;
		if (this.toast.theme !== undefined) return this.toast.theme;
		return enumsObject.PThemeEnum.PRIMARY;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get icon() : FaIcon | null {
		if (this.toast.icon !== undefined) return this.toast.icon;
		if (this.theme === enumsObject.PThemeEnum.PRIMARY) return enumsObject.PlanoFaIconPool.PUSH_NOTIFICATION;
		if (this.theme === enumsObject.PThemeEnum.WARNING) return 'exclamation-triangle';
		if (this.theme === enumsObject.PThemeEnum.INFO) return enumsObject.PlanoFaIconPool.MORE_INFO;
		if (this.theme === enumsObject.PThemeEnum.DANGER) return enumsObject.PlanoFaIconPool.NOT_POSSIBLE;
		if (this.theme === enumsObject.PThemeEnum.SUCCESS) return 'thumbs-up';
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get title() : string | null {
		if (this.toast.title !== undefined) return this.toast.title;
		switch (this.theme) {
			case enumsObject.PThemeEnum.WARNING:
				return this.localize.transform('Achtung');
			case enumsObject.PThemeEnum.INFO:
				return this.localize.transform('Info');
			case enumsObject.PThemeEnum.DANGER:
				return this.localize.transform('Fehler!');
			case enumsObject.PThemeEnum.SUCCESS:
				return this.localize.transform('Yeah!');
			case enumsObject.PThemeEnum.PRIMARY:
			default:
				return this.localize.transform('Hey…');
		}
	}

	/**
	 * when user clicked dismiss cross or btn
	 */
	public onDismiss() : void {
		this.visible = false;
		this.toasts.removeToast(this.toast);
	}

	/**
	 * when user clicked close btn
	 */
	public onClose() : void {
		this.onDismiss();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get textWhite() : boolean {
		return this.theme !== PAlertThemeEnum.PLAIN && this.theme !== enumsObject.PThemeEnum.LIGHT;
	}

	public ngOnDestroy() : void {
		this.subscription?.unsubscribe();
	}

	/**
	 * Create unique hash that can be used as id
	 */
	public get titleHash() : string {
		const unHashedString = this.toast.title ?? this.toast.content;
		return Md5.hashStr(unHashedString);
	}

	/** Only show progressbar if this is not Firefox PLANO-149796 */
	public get notFirefox() : boolean {
		return Config.browser.name !== 'firefox';
	}
}
