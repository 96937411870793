import { CurrencyPipe, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SchedulingApiPaymentMethodType, SchedulingApiTransactionPaymentMethodType } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ClientCurrency, Euro, PApiPrimitiveTypes, PSupportedCurrencyCodes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { FaIcon, PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

// eslint-disable-next-line @angular-eslint/no-pipe-impure -- as we can pass objects as variables we need the pipe to be impure (https://github.com/angular/angular/issues/16595)
@Pipe({ name: 'currency', pure: false })

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PCurrencyPipe implements PipeTransform {
	constructor(
		// eslint-disable-next-line @typescript-eslint/ban-types
		private currencyPipe : CurrencyPipe | null,
		private console : LogService | null,
	) {
	}

	// If you pass a string or number, currency code is required.
	// If input is not null, result can not be null.
	public transform(
		value : string | number,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro> | null,
		display ?: Parameters<PCurrencyPipe['transform']>[2],
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string;

	// If you pass a string or number, currency code is required.
	// If input can be null, result can be null.
	public transform(
		value : string | number | null,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro> | null,
		display ?: Parameters<PCurrencyPipe['transform']>[2],
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string | null;

	// If you pass a AI, currency code is not required.
	public transform(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value : ApiAttributeInfo<any, ClientCurrency | Euro>,

		currencyCode ?: PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | null,
		// eslint-disable-next-line @typescript-eslint/ban-types
		display ?: Parameters<CurrencyPipe['transform']>[2],
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string;

	/* eslint sonarjs/cognitive-complexity: ["warn"] -- Remove this before you work here. */
	/**
	 * Turn a number into a country-specific currency format.
	 * @param value amount of money as float or attributeInfo of type ClientCurrency or Euro
	 * @param currencyCode the code of the currency
	 * @param display a formatting
	 * @param digitsInfo TODO: Description missing here
	 * @param locale information about the country
	 * @param trimUnnecessaryZeros removes 00 from 2,00 €
	 * @param alwaysShowPrependingSign Shows prepending sign no matter if positive or negative amount.
	 * 		Turns 2€ and -3,50€ into +2€ and -3,50€
	 */
	public transform(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value : string | number | null | ApiAttributeInfo<any, ClientCurrency | Euro>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		currencyCode ?: PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro> | null,
		// eslint-disable-next-line @typescript-eslint/ban-types
		display ?: Parameters<CurrencyPipe['transform']>[2],
		digitsInfo ?: string,
		locale ?: PSupportedLocaleIds,
		trimUnnecessaryZeros ?: boolean,
		alwaysShowPrependingSign ?: boolean,
	) : string | null {
		if (!this.currencyPipe) throw new Error('currencyPipe is not available');
		if (value === null) return null;

		if (typeof value === 'string' && value.match(/\d+[,.]/)) {
			value = value.replace(/[,.]/, '');
		}

		if (typeof value === 'string' && Number.isNaN(+value)) value = null;

		let code = (() => {
			if (currencyCode) {
				return this.handleCurrencyCode(currencyCode);
			}
			if (value instanceof ApiAttributeInfo && value.primitiveType === PApiPrimitiveTypes.Euro) return 'EUR';
			if (locale) return Config.getCurrencyCode(locale);
			return Config.CURRENCY_CODE;
		})();
		if (code === null) {
			code = PSupportedCurrencyCodes.EUR;
			if (Config.DEBUG) this.console?.error('currencyCode could not be determined');
		}

		if (value instanceof ApiAttributeInfo) {
			value = value.value;
		}

		if (value !== null && Number.isNaN(+value)) return null;

		let result : string | null = null;
		if (alwaysShowPrependingSign && value !== null && value > 0) {
			// If the currency sign is at the front of the string, we have to place the prepending sign between currency
			// symbol and value.
			const invertedResult = this.currencyPipe.transform(-value, code.toString(), display, digitsInfo, locale);
			if (!invertedResult) throw new Error('invertedResult is not defined');
			result = invertedResult.replace('-', `+`);
		} else {
			result = this.currencyPipe.transform(value, code.toString(), display, digitsInfo, locale);
		}

		result = this.trimObsoleteChars(result, trimUnnecessaryZeros, locale);

		if (result && getLocaleNumberSymbol(locale ?? Config.LOCALE_ID, NumberSymbol.Group) === '’')
			result = result.replace(/’/g, '\'');

		return result;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleCurrencyCode(currencyCode : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | ApiAttributeInfo<any, ClientCurrency | Euro>) : PSupportedCurrencyCodes | `${PSupportedCurrencyCodes}` | null {
		if (currencyCode instanceof ApiAttributeInfo)
			return currencyCode.primitiveType === PApiPrimitiveTypes.Euro ? PSupportedCurrencyCodes.EUR : Config.CURRENCY_CODE;
		return currencyCode;
	}

	private trimObsoleteChars(
		input : string | null,
		trimUnnecessaryZeros ?: boolean,
		locale ?: PSupportedLocaleIds,
	) : string | null {
		if (!input) return input;

		let result = input;

		// Remove all unnecessary whitespace
		result = result.trim();

		// Remove trailing `,00` if trimUnnecessaryZeros is true
		if (result && trimUnnecessaryZeros) {
			const decimalSeparator = getLocaleNumberSymbol(locale ?? Config.LOCALE_ID, NumberSymbol.Decimal);
			const thousandsSeparator = getLocaleNumberSymbol(locale ?? Config.LOCALE_ID, NumberSymbol.Group);
			const REGEX_STRING = `((?:.*\u00A0)?-?)?(0|(?:[1-9](?:[0-9]{1,2})?(?:[\\${thousandsSeparator}]?[0-9]{0,3})*))([\\${decimalSeparator}]00)?(\u00A0.*)?`;
			result = result.replace(new RegExp(REGEX_STRING), '$1$2$4');
		}

		return result;
	}

	/**
	 * Get a fontawesome icon name for provided currency
	 */
	public getCurrencyIcon(currencyCode ?: PSupportedCurrencyCodes | null) : PFaIcon {
		let code = currencyCode ?? Config.CURRENCY_CODE;
		if (code === null) {
			this.console?.error(`Currency code should be defined here. Currency code is »null«`);
			code = PSupportedCurrencyCodes.EUR;
		}
		switch (code) {
			case PSupportedCurrencyCodes.EUR : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.EUR] :
				return enumsObject.PlanoFaIconPool.EUR;
			case PSupportedCurrencyCodes.GBP : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.GBP] :
				return enumsObject.PlanoFaIconPool.GBP;
			case PSupportedCurrencyCodes.CHF : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.CHF] :
				return ['solid','chf-sign'];
			case PSupportedCurrencyCodes.CZK : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.CZK] :
				return 'czk-sign';
			case PSupportedCurrencyCodes.SEK : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.SEK] :
				return 'kr';
			case PSupportedCurrencyCodes.RON : case PSupportedCurrencyCodes[PSupportedCurrencyCodes.RON] :
				return 'RON';
			default :
				if (Config.DEBUG) {
					const missingCode : never = code;
					this.console?.error(`Currency not supported yet: »${missingCode}«`);
				}
				return 'coins';
		}
	}

	/**
	 * Get a fontawesome icon name for provided payment method
	 */
	public getPaymentMethodIcon(
		paymentMethodType : SchedulingApiTransactionPaymentMethodType | SchedulingApiPaymentMethodType | null,
		name : string | null,
	) : FaIcon | null {
		switch (paymentMethodType) {
			case SchedulingApiPaymentMethodType.ONLINE_PAYMENT:
			case SchedulingApiTransactionPaymentMethodType.ONLINE_PAYMENT:
				return enumsObject.PlanoFaIconPool.ONLINE_PAYMENT;
			case SchedulingApiTransactionPaymentMethodType.POS:
				return enumsObject.PlanoFaIconPool.PAY_BY_CASH;
			case SchedulingApiTransactionPaymentMethodType.GIFT_CARD:
				return enumsObject.PlanoFaIconPool.GIFT_CARD;
			case SchedulingApiTransactionPaymentMethodType.MARKETING_GIFT_CARD:
				return enumsObject.PlanoFaIconPool.MARKETING;
			case SchedulingApiPaymentMethodType.MISC:
			case SchedulingApiTransactionPaymentMethodType.MISC:
			case null:
				break;
		}

		if (!name) return null;
		return this.determinePaymentMethodIconByName(name);
	}

	private isSomeKindOfCashRegisterPayment(name : string) : boolean {
		if (
			name.includes('kasse') ||
			name.includes('vor ort') ||
			name.includes('vorort') ||
			name.includes('vor-ort') ||
			name.includes('on-site') ||
			name.includes('bar') ||
			name.includes('theke') ||
			name.includes('halle') ||
			name.includes('counter') ||
			name.includes('wettkampftag')
		) return true;
		return false;
	}

	private determinePaymentMethodIconByName(name : string) : FaIcon {
		// Write the phrases in lower case as all payment methods are set to lower case before the matching starts.
		const NAME = name.toLowerCase();
		if (this.isSomeKindOfCashRegisterPayment(NAME)) return 'cash-register';
		if (NAME.includes('er karte') || NAME.includes('abo') || NAME.includes('dauerkarte') || NAME.includes('mitglied')) return 'address-card';
		if (NAME.includes('überweisung')) return 'money-check';
		if (NAME.includes('bezahlung')) return 'wallet';
		if (NAME.includes('lastschrift') || NAME.includes('einzug') || NAME.includes('sepa')) return 'university';
		if (NAME.includes('gutschein') || NAME.includes('giftCard') || NAME.includes('gift')) return 'gift';
		if (NAME.includes('rechnung') || NAME.includes('invoice') || NAME.includes('bill')) return 'file-alt';
		if (NAME.includes('urban')) return 'barcode';
		if (NAME.includes('mastercard')) return ['brands', 'cc-mastercard'];
		if (NAME.includes('visa')) return ['brands', 'cc-visa'];
		if (NAME.includes('google')) return ['brands', 'google-pay'];
		if (NAME.includes('apple')) return ['brands', 'apple-pay'];
		if (NAME.includes('amazon')) return ['brands', 'amazon-pay'];
		if (NAME.includes('ideal')) return ['brands', 'ideal'];
		if (NAME.includes('kreditkarte') || NAME.includes('credit card')) return 'credit-card';
		if (NAME.includes('bitcoin')) return ['brands', 'bitcoin'];
		if (NAME.includes('paypal')) return enumsObject.PlanoFaIconPool.BRAND_PAYPAL;
		return 'piggy-bank';
	}
}
