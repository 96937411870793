import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { menuNavigationItemsOrder } from '@plano/client/shared/menu-navigation-items';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

@Component({
	selector: 'p-page-headline',
	templateUrl: './page-headline.component.html',
	styleUrls: ['./page-headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PPageHeadlineComponent {

	/**
	 * Headline text to be displayed
	 */
	@Input() public headline ! : PDictionarySourceString;

	private _headlineIcon : PlanoFaIconPoolValues | null = null;

	/**
	 * Get headline icon for page
	 */
	public get headlineIcon() : PlanoFaIconPoolValues {
		if (!this._headlineIcon) {
			const menuItem = menuNavigationItemsOrder.find(item => item.label === this.headline);
			this._headlineIcon = menuItem!.icon!;
		}
		return this._headlineIcon;
	}

}
