/* eslint "@typescript-eslint/no-restricted-imports": ["error", {
	"name": "./scheduling-api.service",
	"message": "Need a class as type? Add an Interface for it in scheduling-api.interfaces.ts"
}, {
	"name": "./@plano/shared/api",
	"message": "This adds a huge import chain. Avoid it!."
}] */
import { IShiftData } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.types';
import { PTextColor, PTextColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PlanoFaIconPool, PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ISchedulingApiShift } from './scheduling-api.interfaces';

/**
 * Enum defining possible payment status of a bookable.
 */
export enum PPaymentStatusEnum {

	/**
	 * Money need to be refunded to booking person.
	 */
	REFUND_NEEDED = 'REFUND_NEEDED',

	/**
	 * A bookable with a positive `amountToPay` and `openAmount` equal zero.
	 * I.e. the booking person had to pay something and paid it completely.
	 */
	PAID = 'PAID',

	/**
	 * Bookable has been paid partially.
	 */
	PARTIALLY_PAID = 'PARTIALLY_PAID',

	/**
	 * A bookable with a positive `amountToPay` and `currentlyPaid` equal 0.
	 * I.e. the booking person has to pay something but has not paid anything yet.
	 */
	UNPAID = 'UNPAID',

	/**
	 * A bookable with `price` and `openAmount` are 0.
	 * I.e. a free bookable where nothing was paid.
	 */
	PAID_FREE_BOOKABLE = 'PAID_FREE_BOOKABLE',

	/**
	 * A bookable where both `amountToPay` and `openAmount` are 0.
	 * I.e. a bookable where currently nothing has to be paid (e.g. because cancelled) and also nothing was paid.
	 */
	PAID_NO_AMOUNT_TO_PAY = 'PAID_NO_AMOUNT_TO_PAY',
}

/**
 * Get a icon for paymentStatus
 */
export const getPaymentStatusIcon = (paymentStatus : PPaymentStatusEnum | null) : PlanoFaIconPoolValues => {
	if (paymentStatus === null) return PlanoFaIconPool.LOADING;
	if (paymentStatus === PPaymentStatusEnum.REFUND_NEEDED) return PlanoFaIconPool.REFUND;
	return PlanoFaIconPool.BOOKING_PAYMENT_STATUS;
};

/**
 * Get a theme / color for paymentStatus icon
 */
export const getPaymentStatusIconStyle = (paymentStatus : PPaymentStatusEnum | null) : PTextColor | null => {
	switch (paymentStatus) {
		case PPaymentStatusEnum.PAID_FREE_BOOKABLE :
			return PTextColorEnum.MUTED;
		case PPaymentStatusEnum.PAID :
			return enumsObject.PThemeEnum.SUCCESS;
		case PPaymentStatusEnum.UNPAID :
		case PPaymentStatusEnum.REFUND_NEEDED :
			return enumsObject.PThemeEnum.DANGER;
		case PPaymentStatusEnum.PARTIALLY_PAID :
			return enumsObject.PThemeEnum.WARNING;
  		case PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY:
		case null:
			return null;
	}
};

/**
 * Get the tooltip theme for the current payment status
 */
export const getPaymentStatusTooltipTheme = (paymentStatus : PPaymentStatusEnum) : PThemeEnum => {
	switch (paymentStatus) {
		case PPaymentStatusEnum.PAID_FREE_BOOKABLE :
			return enumsObject.PThemeEnum.LIGHT;
		case PPaymentStatusEnum.PAID :
			return enumsObject.PThemeEnum.SUCCESS;
		case PPaymentStatusEnum.UNPAID :
		case PPaymentStatusEnum.REFUND_NEEDED :
			return enumsObject.PThemeEnum.DANGER;
		case PPaymentStatusEnum.PARTIALLY_PAID :
			return enumsObject.PThemeEnum.WARNING;
		case PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY:
			return enumsObject.PThemeEnum.DARK;
	}
};

/**
 * getter for the title of the status of payment
 */
export const paymentStatusTitle = (paymentStatus : PPaymentStatusEnum) : PDictionarySourceString => {
	switch (paymentStatus) {
		case PPaymentStatusEnum.REFUND_NEEDED :
			return 'Rückerstattung fällig';
		case PPaymentStatusEnum.PAID_NO_AMOUNT_TO_PAY :
			return 'Keine Zahlungen offen';
		case PPaymentStatusEnum.PAID_FREE_BOOKABLE :
			return 'Kostenlos';
		case PPaymentStatusEnum.PAID :
			return 'Komplett bezahlt';
		case PPaymentStatusEnum.UNPAID :
			return 'Noch nicht bezahlt';
		case PPaymentStatusEnum.PARTIALLY_PAID :
			return 'Teils bezahlt';
	}
};

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const compareFnName = (a : string | null, b : string | null) : number => {
	if (!a) return -1;
	if (!b) return 1;
	return a.localeCompare(b);
};

/**
 * A array of sort fn’s that can be used to sort shifts in some kind of list view.
 * @example
 *   for (const compareFn of sortShiftsForListViewFns) {
 *     shifts.sort(compareFn)
 *   }
 */
export const sortShiftsForListViewFns : ((a : ISchedulingApiShift,b : ISchedulingApiShift) => number)[] = [
	(itemA, itemB) => itemA.id.seriesId - itemB.id.seriesId,
	(itemA, itemB) => compareFnName(itemA.name ?? null, itemB.name ?? null),
	(aShift, bShift) => aShift.end - bShift.end,
	(aShift, bShift) => aShift.start - bShift.start,
];

/**
 * A array of sort fn’s that can be used to sort shifts in some kind of timeline view.
 * @example
 *   for (const compareFn of sortShiftsForTimelineViewFns) {
 *     shifts.sort(compareFn)
 *   }
 */
export const sortShiftsForTimelineViewFns : (Parameters<Array<IShiftData>['sort']>[0])[] = [
	(a, b) => a.shift!.id.seriesId - b.shift!.id.seriesId,
	(a, b) => compareFnName(a.shift!.name ?? null, b.shift!.name ?? null),
	(a, b) => a.shift!.end - b.shift!.end,
	(a, b) => a.shift!.start - b.shift!.start,
];
