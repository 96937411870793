<fa-icon
	*ngIf="!!icon"
	[class.text-primary]="iconStyle === enums.PThemeEnum.PRIMARY"
	[class.text-warning]="iconStyle === enums.PThemeEnum.WARNING"
	[class.text-danger]="iconStyle === enums.PThemeEnum.DANGER"
	[icon]="icon"
></fa-icon>
<img
	*ngIf="avatar && !avatarImgHasErrors"
	(load)="avatarImgLoaded()"
	(error)="avatarImgError($event)"
	[src]="avatar"
	loading="lazy"
	alt="Avatar" i18n-alt
/>
<span *ngIf="(!avatar || avatarImgHasErrors)" class="member-badge-text">{{ badgeText }}</span>
<ng-template *ngIf="overlayTemplate" [ngTemplateOutlet]="overlayTemplate"></ng-template>
