import { ApiBase } from '@plano/shared/api/base/api-base/api-base';
import { ApiDataWrapperBase } from '@plano/shared/api/base/api-data-wrapper-base';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipeParamsType, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { NonNullAndNonUndefined } from '@plano/shared/core/utils/null-type-utils';

/**
 * Base class for additional parameters which can be stored in an attribute-info.
 */
export class AttributeInfoVarsBase<ParentType extends ApiDataWrapperBase> {
	/**
	 * A hint to be shown to the user when this attribute cannot be edited.
	 * Set a function here, if the text has potential to change under a certain condition.
	 */
	public cannotSetHint ?: (this : ParentType) => PDictionarySourceString | null;

	/**
	 * Parameters needed for translating `cannotSetHint`.
	 */
	public cannotSetHintParams ?: (this : ParentType) => LocalizePipeParamsType;
}

/**
 * Base class for arguments defining the logic of an attribute-info.
 */
export interface ApiAttributeInfoArgsBase<ParentType extends ApiDataWrapperBase, VarsType extends AttributeInfoVarsBase<ParentType>> {
	apiObjWrapper : ParentType;

	/**
	 * Condition which defines when this value is available by business logic.
	 * See {@link ApiAttributeInfoBase.isAvailableByBusinessLogic}.
	 */
	isAvailableByBusinessLogic ?: ((this : ParentType) => boolean | undefined);

	/**
	 * Condition which defines when this value is editable according to business logic.
	 * See {@link ApiAttributeInfoBase.canSetByBusinessLogic}.
	 */
	canSetByBusinessLogic ?: ((this : ParentType) => boolean | undefined);

	vars ?: VarsType;
}

/**
 * Base class for attribute-info classes
 */
export abstract class ApiAttributeInfoBase<ParentType extends ApiDataWrapperBase, ArgsType
	extends ApiAttributeInfoArgsBase<ParentType, AttributeInfoVarsBase<ParentType>>> {

	constructor(protected args : ArgsType) {
	}

	/**
	 * The wrapper object to which this attribute-info belongs.
	 */
	public get apiObjWrapper() : ParentType {
		return this.args.apiObjWrapper;
	}

	/**
	 * The api object.
	 */
	public get api() : ApiBase | null {
		return this.args.apiObjWrapper.api;
	}

	/**
	 * @returns Is the wrapper of this attribute-info a new item?
	 */
	public get isNewItem() : boolean {
		return this.args.apiObjWrapper.isNewItem();
	}

	/**
	 * @returns Is the data-set for this attribute currently available? Following values can be returned:
	 * - `true`: The data-set is available. Any component visualizing the attribute can be shown now.
	 * - `false`: The data-set is not available. Any component visualizing the attribute should be hidden.
	 * - `undefined`: The data-set is in a loading process as it is currently not available but a api load is running.
	 * 		In this case you should either not show any component visualizing the attribute or alternatively show a loading
	 * 		skeleton.
	 *
	 * @example Example code how to visualize an attribute with the name "value" with a loading skeleton:
	 *
	 * <div *ngIf="attributeInfoValue.show !== false">
	 * 		{{attributeInfoValue.show === undefined ? '<loading-skeleton>' : value}}
	 * </div>
	 */
	public get isAvailable() : boolean | undefined {
		return this.isAvailableByBusinessLogic;
	}

	/**
	 * @returns Should this attribute be available according to business logic? This returns the result of the conditions
	 * in `<get><if-business>…</if-business></get>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute is currently available includes further criteria.
	 * See {@link isAvailable}.
	 */
	protected get isAvailableByBusinessLogic() : boolean | undefined {
		return this.args.isAvailableByBusinessLogic ? this.args.isAvailableByBusinessLogic.call(this.args.apiObjWrapper) : true;
	}

	/**
	 * @returns Can this attribute currently be set?
	 */
	public get canSet() : boolean {
		return this.canSetByBusinessLogic === true;
	}

	/**
	 * @returns Can this attribute be set according to business logic? This returns the result of the conditions
	 * in `<set><if-business>…</if-business></set>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute can be set now, includes further criteria. See {@link canSet}.
	 */
	protected get canSetByBusinessLogic() : boolean | undefined {
		return this.args.canSetByBusinessLogic ? this.args.canSetByBusinessLogic.call(this.args.apiObjWrapper) : true;
	}

	/**
	 * Additional vars for this attribute-info. See `AttributeInfoVars` for possible values.
	 */
	public get vars() : NonNullAndNonUndefined<ArgsType['vars']> {
		return this.args.vars ?? {};
	}

	/**
	 * The current value of `vars.cannotSetHint`. Note, that you should not store this value permanently as it might change.
	 */
	public get cannotSetHint() : PDictionarySource | null {
		const cannotSetHintVar = this.vars.cannotSetHint;

		if (!cannotSetHintVar)
			return null;

		const cannotSetHint = cannotSetHintVar.call(this.args.apiObjWrapper);

		if (!cannotSetHint)
			return null;

		const params = this.vars.cannotSetHintParams ?
			this.vars.cannotSetHintParams.call(this.args.apiObjWrapper) :
			this.vars.cannotSetHintParams;
		return params ? {sourceString: cannotSetHint, params: params} : cannotSetHint;
	}
}
