import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { PossibleShiftPickerValueItemType } from '@plano/client/shared/p-shift-picker/p-shift-picker/p-shift-picker.component';
import { SchedulingApiService, SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeShiftRefs, SchedulingApiWarnings } from '@plano/shared/api';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';

@Component({
	selector: 'p-picked-offer',
	templateUrl: './picked-offer.component.html',
	styleUrls: ['./picked-offer.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PickedOfferComponent {
	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public offer : PossibleShiftPickerValueItemType | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public addToOfferBtnDisabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public selectedOffer : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public affectedOffer : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public addToOffer : EventEmitter<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs | SchedulingApiShiftExchangeShiftRefs> =
		new EventEmitter<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs | SchedulingApiShiftExchangeShiftRefs>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemoveOffer =
		new EventEmitter<PossibleShiftPickerValueItemType>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemoveFromOffer : EventEmitter<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef> =
		new EventEmitter<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef>();

	constructor( public api : SchedulingApiService ) {
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get warnings() : SchedulingApiWarnings {
		const emptyList = new SchedulingApiWarnings(null, null);
		if (!this.api.isLoaded()) return emptyList;
		if (!(this.offer instanceof SchedulingApiShiftExchangeCommunicationSwapOffer)) return emptyList;
		assumeDefinedToGetStrictNullChecksRunning(this.api.data.warnings, 'api.data.warnings');
		return this.api.data.warnings.getByOffer(this.offer);
	}
}
