/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "export_bookings_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	BOOKING_IDS = 1;
	SHIFT_MODEL_IDS = 2;
}


@Injectable({
  providedIn: 'root',
})
export class ExportBookingsExcelApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_bookings_excel');
	}

	protected version() : string {
		return 'f33a5c3c66e66b01b4488f391f2a3efc,88f8d2ec72339ac86826e5f36a8cb545';
	}

	private dataWrapper = new ExportBookingsExcelApiRoot(this, );

	get data() : ExportBookingsExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportBookingsExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportBookingsExcelApiRoot(this, );
	}
}

		 
export class ExportBookingsExcelApiRoot extends ApiObjectWrapper<ExportBookingsExcelApiRoot>
{
	constructor(override readonly api : ExportBookingsExcelApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportBookingsExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportBookingsExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportBookingsExcelApiRoot, ExportBookingsExcelApiRoot> = new ApiAttributeInfo<ExportBookingsExcelApiRoot, ExportBookingsExcelApiRoot>({
			apiObjWrapper: this as any as ExportBookingsExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToGet: function(this : ExportBookingsExcelApiRoot) {
				return ((this.api!.rightsService.hasManagerRights));
			},
			hasRightToSet: function(this : ExportBookingsExcelApiRoot) {
				return ((this.api!.rightsService.hasManagerRights));
			},
			defaultValue: function(this : ExportBookingsExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private bookingIdsWrapper : ExportBookingsExcelApiBookingIds = new ExportBookingsExcelApiBookingIds(this.api,
		this as unknown as ExportBookingsExcelApiRoot, false);
	public attributeInfoBookingIds = this.bookingIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the bookings to be exported.
     */
	get bookingIds() : ExportBookingsExcelApiBookingIds {
		this.getterDebugValidations(this.attributeInfoBookingIds, false);
		return this.bookingIdsWrapper;
	}

	set bookingIdsTestSetter(v : ExportBookingsExcelApiBookingIds) {
        this.setterImpl(1, v.rawData, 'bookingIds', true, null, () => {this.bookingIdsWrapper = v;});
	}

	private shiftModelIdsWrapper : ExportBookingsExcelApiShiftModelIds = new ExportBookingsExcelApiShiftModelIds(this.api,
		this as unknown as ExportBookingsExcelApiRoot, false);
	public attributeInfoShiftModelIds = this.shiftModelIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the shift model to be exported.
     */
	get shiftModelIds() : ExportBookingsExcelApiShiftModelIds {
		this.getterDebugValidations(this.attributeInfoShiftModelIds, false);
		return this.shiftModelIdsWrapper;
	}

	set shiftModelIdsTestSetter(v : ExportBookingsExcelApiShiftModelIds) {
        this.setterImpl(2, v.rawData, 'shiftModelIds', true, null, () => {this.shiftModelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.bookingIdsWrapper._fixIds(_idReplacements);
		this.shiftModelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.bookingIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.shiftModelIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportBookingsExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportBookingsExcelApiBookingIds extends ApiListWrapper<ExportBookingsExcelApiBookingId>
{
	constructor(override readonly api : ExportBookingsExcelApiService | null,
		override readonly parent : ExportBookingsExcelApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'bookingIds');
	}

	override wrapRawData(itemRawData : any) : ExportBookingsExcelApiBookingId {
		return new ExportBookingsExcelApiBookingId(this.api, this as unknown as ExportBookingsExcelApiBookingIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportBookingsExcelApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportBookingsExcelApiBookingIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : ExportBookingsExcelApiBookingId) => void) | null = null, _backendId : Id | null = null) : ExportBookingsExcelApiBookingId {
		const newItem = new ExportBookingsExcelApiBookingId(this.api, this as unknown as ExportBookingsExcelApiBookingIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('bookingIds');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportBookingsExcelApiBookingIds, ExportBookingsExcelApiBookingIds> = new ApiAttributeInfo<ExportBookingsExcelApiBookingIds, ExportBookingsExcelApiBookingIds>({
			apiObjWrapper: this as any as ExportBookingsExcelApiBookingIds,
			name: 'bookingIds',
			nodeName: 'BOOKING_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportBookingsExcelApiBookingIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 1,
		});
	attributeInfoBookingId : ApiAttributeInfo<ExportBookingsExcelApiBookingIds, ExportBookingsExcelApiBookingId> = new ApiAttributeInfo<ExportBookingsExcelApiBookingIds, ExportBookingsExcelApiBookingId>({
			apiObjWrapper: this as any as ExportBookingsExcelApiBookingIds,
			name: 'bookingId',
			nodeName: 'BOOKING_ID',
			defaultValue: function(this : ExportBookingsExcelApiBookingIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportBookingsExcelApiBookingId extends ApiObjectWrapper<ExportBookingsExcelApiBookingId>
{
	constructor(override readonly api : ExportBookingsExcelApiService | null,
		override readonly parent : ExportBookingsExcelApiBookingIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportBookingsExcelApiBookingId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportBookingsExcelApiBookingId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportBookingsExcelApiBookingId, ExportBookingsExcelApiBookingId> = new ApiAttributeInfo<ExportBookingsExcelApiBookingId, ExportBookingsExcelApiBookingId>({
			apiObjWrapper: this as any as ExportBookingsExcelApiBookingId,
			name: 'bookingId',
			nodeName: 'BOOKING_ID',
			defaultValue: function(this : ExportBookingsExcelApiBookingId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('BOOKING_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '4';
	}

	static async loadDetailed(	api : ExportBookingsExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '4', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportBookingsExcelApiShiftModelIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportBookingsExcelApiService | null,
		override readonly parent : ExportBookingsExcelApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'shiftModelIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportBookingsExcelApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportBookingsExcelApiShiftModelIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override attributeInfoThis : ApiAttributeInfo<ExportBookingsExcelApiShiftModelIds, ExportBookingsExcelApiShiftModelIds> = new ApiAttributeInfo<ExportBookingsExcelApiShiftModelIds, ExportBookingsExcelApiShiftModelIds>({
			apiObjWrapper: this as any as ExportBookingsExcelApiShiftModelIds,
			name: 'shiftModelIds',
			nodeName: 'SHIFT_MODEL_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportBookingsExcelApiShiftModelIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 2,
		});
	attributeInfoShiftModelId : ApiAttributeInfo<ExportBookingsExcelApiShiftModelIds, Id> = new ApiAttributeInfo<ExportBookingsExcelApiShiftModelIds, Id>({
			apiObjWrapper: this as any as ExportBookingsExcelApiShiftModelIds,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			
		});
}

		

