<p-editable-modal-box
	[pEditable]="!formItem.isNewItem()"
	[api]="api"
	[saveChangesHook]="assignMembersHook(assignMembersHookContent.template)"
	(onModalOpen)="updateListCopies()"
	[valid]="true"
	[disabled]="!canSetBox"

	label="Zugewiesene Personen" i18n-label
	modalTitle="Zugewiesene Personen" i18n-modalTitle
>
	<p-editable-modal-box-showroom>
		<p-alert [theme]="enums.PThemeEnum.INFO" *ngIf="cannotSetHint">{{cannotSetHint | localize}}</p-alert>
		<p *ngIf="showNoAssignableMembersHint" class="m-0" i18n>Es wurden noch keine Personen eingetragen, die {!!formItem.name, select, true {»{{formItem.name}}«} other {diese Tätigkeit}} machen dürfen.</p>
		<div class="pb-4" *ngIf="hasAssignedMembersForList" [attr.aria-label]="assignedHeadline.textContent">
			<p-assign-members-headline
				[showHearts]="hasAssignedMembersForList && showHearts"
				[editMode]="false"
			><span #assignedHeadline i18n>Wird <strong>gemacht</strong> von</span></p-assign-members-headline>

			<p-list *ngIf="hasAssignedMembersForList">
				<ng-template
					ngFor let-member
					[ngForOf]="assignedMembersForList"
				>
					<ng-container *ngTemplateOutlet="memberListItemForShowroomTemplate; context: {$implicit: member, hasDanger: absenceTypeIconName(member.id)}"></ng-container>
				</ng-template>
			</p-list>
		</div>
		<div *ngIf="hasAssignableMembersForList" [attr.aria-label]="assignableHeadline.textContent">
			<p-assign-members-headline
				[showHearts]="showHearts"
				[editMode]="false"
			><span #assignableHeadline i18n><strong>Darf</strong> gemacht werden von</span></p-assign-members-headline>
			<p-list>
				<ng-template
					ngFor let-member
					[ngForOf]="assignableMembersForList"
				>
					<ng-container *ngTemplateOutlet="memberListItemForShowroomTemplate; context: {$implicit: member, hasDanger: false}"></ng-container>
				</ng-template>
			</p-list>
		</div>
	</p-editable-modal-box-showroom>
	<p-editable-modal-form>
		<div class="pb-4" *ngIf="!!tempAssignedMembersForModal && tempAssignedMembersForModal.length">
			<p-assign-members-headline
				[showHearts]="showHearts"
				[editMode]="true"
				i18n
			>Wird <strong>gemacht</strong> von</p-assign-members-headline>
			<p-list>
				<ng-template
					ngFor let-member
					[ngForOf]="tempAssignedMembersForModal"
				>
					<ng-container *ngTemplateOutlet="listItemForFormTemplate; context: {$implicit: member, hasDanger: absenceTypeIconName(member.id)}"></ng-container>
				</ng-template>
			</p-list>
		</div>
		<div class="pb-4" *ngIf="!!tempAssignableMembersForModal && tempAssignableMembersForModal.length">
			<p-assign-members-headline
				[showHearts]="showHearts"
				[editMode]="true"
			><ng-container i18n><strong>Darf</strong> gemacht werden von</ng-container></p-assign-members-headline>
			<p-list>
				<ng-template
					ngFor let-member
					[ngForOf]="tempAssignableMembersForModal"
				>
					<ng-container *ngTemplateOutlet="listItemForFormTemplate; context: {$implicit: member, hasDanger: false}"></ng-container>
				</ng-template>
			</p-list>
		</div>
		<div class="pb-4" *ngIf="showOtherMembersForModal">
			<p-assign-members-headline
				[showHearts]="showHearts"
				[editMode]="true"
				i18n
			><strong>Nicht</strong> zugelassene User</p-assign-members-headline>
			<p-list>
				<ng-template
					ngFor let-member
					[ngForOf]="tempOtherMembersForModal"
				>
					<ng-container *ngTemplateOutlet="listItemForFormTemplate; context: {$implicit: member, hasDanger: false}"></ng-container>
				</ng-template>
			</p-list>
		</div>
	</p-editable-modal-form>
</p-editable-modal-box>

<ng-template #memberListItemForShowroomTemplate let-member let-hasDanger="hasDanger">
	<p-list-item
		class="hover-hide-trashed"
		size="frameless">
		<div
			class="d-flex justify-content-between align-items-stretch position-relative p-0 card-options w-100">
			<!-- [class.bg-danger]="hasDanger"
			[class.text-white]="hasDanger" -->
			<ng-container *ngTemplateOutlet="memberLabelTemplate; context: {$implicit: member, isInShowRoom: true}"></ng-container>
			<div *ngIf="isOwner" class="d-flex align-items-center flex-shrink-0">
				<div *ngVar="earningsForMember(member) as memberEarnings" class="text-monospace p-2">{{ (memberEarnings | currency:CONFIG.CURRENCY_CODE) ?? '–' }}</div>
			</div>
			<div
				*ngIf="showHearts"
				class="card-option heart-preference pt-2 pb-2 d-none d-sm-flex align-items-center justify-content-center flex-shrink-0"
			><p-member-wish-icon
				[member]="member"
			></p-member-wish-icon></div>
		</div>
	</p-list-item>
</ng-template>

<ng-template #listItemForFormTemplate let-member let-hasDanger="hasDanger">
	<p-list-item
		class="hover-hide-trashed"
		size="frameless">
		<div [class.flex-wrap]="CONFIG.IS_MOBILE" class="d-flex justify-content-between align-items-stretch position-relative p-0 card-options w-100">
			<!-- [class.bg-danger]="hasDanger"
			[class.text-white]="hasDanger" -->
			<div class="w-100 d-flex justify-content-between align-items-center">
				<ng-container *ngTemplateOutlet="memberLabelTemplate; context: {$implicit: member, isInShowRoom: false}"></ng-container>
			</div>
			<div *ngIf="CONFIG.IS_MOBILE" class="d-flex w-100 justify-content-end bg-light-cold border-top">
				<div *ngIf="isOwner" class="d-flex align-items-center p-2">
					<div class="d-flex w-100">
						<p-input-member-earnings
							class="w-100"
							[member]="member"
							[formItem]="formItem"
						></p-input-member-earnings>
					</div>
				</div>
				<div
					*ngIf="showHearts && CONFIG.IS_MOBILE"
					class="card-option btn p-1 d-flex align-items-center justify-content-center min-w-50 flex-shrink-0"
				>
					<p-member-wish-icon
						[member]="member"
					/>
				</div>
				<ng-container *ngTemplateOutlet="buttonGroup; context: {$implicit: member}"></ng-container>
			</div>
			<p-input-member-earnings
				*ngIf="isOwner && !CONFIG.IS_MOBILE"
				class="d-flex align-items-center p-2 flex-shrink-0"
				[member]="member"
				[formItem]="formItem"
			/>
			<div
				*ngIf="showHearts && !CONFIG.IS_MOBILE"
				class="card-option btn p-1 d-flex align-items-center justify-content-center min-w-50 flex-shrink-0"
			><p-member-wish-icon
				[member]="member"
			></p-member-wish-icon></div>
			<div class="d-flex align-items-stretch" *ngIf="!CONFIG.IS_MOBILE">
				<ng-container *ngTemplateOutlet="buttonGroup; context: {$implicit: member}"></ng-container>
			</div>

		</div>
	</p-list-item>
</ng-template>

<ng-template #buttonGroup let-member>
	<div class="d-flex bg-light">
		<button
			*ngIf="formItem.attributeInfoAssignableMembers.canSet"
			type="button"
			role="checkbox"
			title="darf" i18n-title
			[attr.aria-checked]="!!formItem.assignableMembers.getByMember(member)"
			(click)="toggleAssignableMember(member)"
			[class.text-primary]="!!formItem.assignableMembers.getByMember(member)"
			class="card-option btn pt-1 pb-1 d-flex align-items-center justify-content-center btn-outline-secondary min-w-50 border-bottom-0"
		><fa-icon [icon]="!!formItem.assignableMembers.getByMember(member) ? enums.PlanoFaIconPool.CHECKBOX_SELECTED : enums.PlanoFaIconPool.CHECKBOX_UNSELECTED"></fa-icon></button>
		<button
			*ngIf="formItem.attributeInfoAssignedMemberIds.canSet"
			type="button"
			role="checkbox"
			title="macht" i18n-title
			[attr.aria-checked]="!!formItem.assignedMemberIds.get(member.id)"
			(click)="toggleAssignedMember(member)"
			[class.text-primary]="!!formItem.assignedMemberIds.get(member.id)"
			class="card-option btn pt-1 pb-1 d-flex align-items-center justify-content-center btn-outline-secondary min-w-50 border-bottom-0"
		><fa-icon [icon]="!!formItem.assignedMemberIds.get(member.id) ? enums.PlanoFaIconPool.CHECKBOX_SELECTED : enums.PlanoFaIconPool.CHECKBOX_UNSELECTED"></fa-icon></button>
	</div>
</ng-template>

<ng-template #memberLabelTemplate let-member let-isInShowRoom="isInShowRoom">
	<span class="card-option d-flex align-items-center w-100 border-0 p-2">
		<p-member-badge
			[memberId]="member.id"
			[firstName]="member.firstName"
			[lastName]="member.lastName"
			[isMe]="isMe(member)"
			[absenceType]="member.trashed ? 'trashed' : $any(absenceService.absenceType(member.id, shift))"
			size="normal"
		></p-member-badge>
		<div class="mr-auto ml-2" [class.crop-on-overflow]="isInShowRoom">
			<span [class.crop-on-overflow]="isInShowRoom"><strong [class.crop-on-overflow]="isInShowRoom">{{ member.firstName }}</strong> {{ member.lastName }}</span>
			<!-- <fa-icon *ngIf="absenceTypeIconName(member.id)" class="mr-2 ml-2" [icon]="absenceTypeIconName(member.id)"></fa-icon> -->
		</div>
	</span>
</ng-template>

<ng-template #assignMembersHookContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		[shiftChangeSelector]="api!.data.shiftChangeSelector"
		title="Neue Liste auf andere Bereiche übertragen?" i18n-title
		[close]="c"
		[dismiss]="d"
		[shift]="shift!"
		[shiftModel]="shiftModel"
		[defaultStart]="shift ? shift.start : undefined"
		customWarningAlert="Achtung: Es wird nicht nur das übertragen, was du soeben geändert hast, sondern <mark>die gesamte Liste</mark> mit allen eingetragenen Mitarbeitenden." i18n-customWarningAlert
		[customDangerAlert]="dangerTextOverridingOtherShiftsAssignment"
		[members]="api!.data.members"
		[showSendMailCheckbox]="showSendMailCheckbox"
	>
	</p-change-selectors-modal>
</ng-template>
