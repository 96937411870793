import { SchedulingApiMember } from '@plano/shared/api';

/**
 * The default to apply to all sortedBy method calls for lists of {@link SchedulingApiMember}
 * @param item The {@link SchedulingApiMember} to sort
 */
export const defaultSortingForMembers = [
	(item : Pick<SchedulingApiMember, 'firstName' | 'lastName'>) => item.firstName.toLowerCase(),
	(item : Pick<SchedulingApiMember, 'firstName' | 'lastName'>) => item.lastName.toLowerCase(),
];
