<p-subheader [isNewItem]="!!item && item.isNewItem()" [routeId]="routeId">
	<ng-container *ngIf="!!item">
		<p-dropdown
			*ngIf="showRemoveButton || showCancelButton"
			[dropdownType]="DropdownTypeEnum.ACTIONS"
			label="Aktionen" i18n-label
			[icon]="enums.PlanoFaIconPool.MORE_ACTIONS"
			[size]="enums.BootstrapSize.SM"
			[showActiveItem]="false"
		>
			<div hidden><span i18n #translatedBtnLabel>{cancellationSettingsIsPossible, select, true {Termin Löschen & Buchungen stornieren} other {Schicht löschen}}</span></div>
			<p-dropdown-item
				(onClick)="onRemoveClick(modalContent.template)"
				[disabled]="false"
				[label]="translatedBtnLabel.textContent!"
				[prependedItem]="enums.PlanoFaIconPool.DELETE"
				[theme]="PBtnThemeEnum.OUTLINE_DANGER"
			></p-dropdown-item>
			<p-dropdown-item
				*ngIf="showCancelButton"
				(onClick)="onCancelClick(modalIsCourseCanceled.template)"
				[disabled]="this.item.isCourseCanceled"
				label="Termin & Buchungen stornieren" i18n-label
				[prependedItem]="enums.PlanoFaIconPool.DISMISS"
				[theme]="PBtnThemeEnum.OUTLINE_DANGER"
			></p-dropdown-item>
			<p-dropdown-item
				*ngIf="!item.isNewItem()"
				[link]="'/client/scheduling/'+schedulingUrlParams.urlParam.calendarMode+'/'+this.item.start"
				[linkFragment]="routeId?.toPrettyString()"
				[disabled]="false"
				[isAlignedLeft]="true"
				label="Im Kalender ansehen" i18n-label
				[prependedItem]="enums.PlanoFaIconPool.AREA_SCHEDULING"
			></p-dropdown-item>
		</p-dropdown>

		<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
			<p-change-selectors-modal
				[showApplyToShiftModelCheckbox]="false"
				[shiftChangeSelector]="api.data.shiftChangeSelector"
				[close]="c"
				[dismiss]="d"
				[shift]="item!"
				[shiftModel]="item.model"
				[defaultStart]="(item ? item.start : undefined)"
				[typeOfChange]="PTypeOfChange.DELETE"
				[members]="api.data.members"
			></p-change-selectors-modal>
		</ng-template>
	</ng-container>

</p-subheader>

<div class="flex-grow-1 d-flex position-relative">
	<scroll-shadow-box class="flex-grow-1">
		<div class="container pb-tawk">
			<ng-template [ngIf]="requestedItemCouldNotBeFound" [ngIfElse]="detailFormTemplate">
				<p-no-item></p-no-item>
			</ng-template>
			<ng-template #detailFormTemplate>
				<ng-template [ngIf]="showShiftModelInputSection">
					<p-tabs>
						<p-tab
							[label]="basissettingsTabLabel | localize"
						>
							<div class="row d-flex align-items-center ng-star-inserted justify-content-center">
								<ng-template [ngIf]="!writableShiftModelsForMember.length" [ngIfElse]="shiftsAreAvailable">
									<p-alert [theme]="enums.PThemeEnum.WARNING" i18n>Neue Schichten basieren auf Tätigkeiten. Lege bitte erst eine Tätigkeit an. Anschließend kannst du sie als Vorlage für neue Schichten benutzen.</p-alert>
								</ng-template>
								<ng-template #shiftsAreAvailable>
									<p-bootstrap-form-group
										label="Wähle eine Tätigkeit als Vorlage für deine Schicht" i18n-label
									>
										<p-input-shiftmodel-id-modal
											class="flex-grow-1"
											[icon]="enums.PlanoFaIconPool.COPY_TO_CLIPBOARD"
											[shiftModels]="writableShiftModelsForMember"
											[ngModel]="selectedShiftModelToCopy"
											(ngModelChange)="onSelectShiftModel($event)"
										></p-input-shiftmodel-id-modal>
									</p-bootstrap-form-group>
								</ng-template>
							</div>
						</p-tab>
					</p-tabs>
				</ng-template>

				<detail-form
					*ngIf="showShiftModelInputSection === false"
					[isLoading]="!item"
					[item]="item ?? null"
					(onAddItem)="saveNewItem($event)"
					[writableShiftModelsForMember]="writableShiftModelsForMember"
				></detail-form>
			</ng-template>
		</div>
	</scroll-shadow-box>
</div>

<ng-template #modalIsCourseCanceled="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		[showApplyToShiftModelCheckbox]="false"
		[shiftChangeSelector]="api.data.shiftChangeSelector"
		[close]="c"
		[dismiss]="d"
		[shift]="item!"
		[shiftModel]="item!.model!"
		[defaultStart]="item!.start"
		[typeOfChange]="PTypeOfChange.CANCEL"
		[members]="api.data.members"
	></p-change-selectors-modal>
</ng-template>
