import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingSystemRights } from '@plano/client/accesscontrol/rights-enums';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { SchedulingApiBooking } from '@plano/client/scheduling/shared/api/scheduling-api-booking.service';
import { BookingsService } from '@plano/client/scheduling/shared/p-bookings/bookings.service';
import { RightsService, SchedulingApiShifts } from '@plano/shared/api';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';

@Component({
	selector: 'p-booking-item[booking]',
	templateUrl: './booking-item.component.html',
	styleUrls: ['./booking-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PBookingItemComponent implements PComponentInterface {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public booking ! : SchedulingApiBooking;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts : SchedulingApiShifts = new SchedulingApiShifts(null, null);

	/** @see PComponentInterface#isLoading */
	@Input('isLoading') private _isLoading : PComponentInterface['isLoading'] = false;

	constructor(
		private schedulingService : SchedulingService,
		private bookingsService : BookingsService,
		private rightsService : RightsService,
	) {
	}

	/** @see PComponentInterface#isLoading */
	public get isLoading() : PComponentInterface['isLoading'] {
		return this._isLoading;
	}

	/**
	 * Router link for 'view in calendar'
	 */
	public get onSelectShiftsLink() : string {
		return `/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${this.booking.firstShiftStart!.toString()}`;
	}

	/**
	 * Check if user can edit this shift or model
	 */
	public get userEditBookings() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.booking, 'booking');
		return !!this.rightsService.can(BookingSystemRights.editBookings, this.booking.model);
	}
}
