/* eslint max-lines: ["error", 1000] */
import { AfterContentChecked, AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, HostBinding, Input, OnDestroy, Optional, Output, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PTabsComponent } from '@plano/client/shared/p-tabs/p-tabs/p-tabs.component';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ValidationHintComponent } from '@plano/shared/core/component/validation-hint/validation-hint.component';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';
import { PInputImageComponent } from '@plano/shared/p-forms/input-image/input-image.component';
import { PInputPdfComponent } from '@plano/shared/p-forms/input-pdf/input-pdf.component';
import { PAISwitchItemComponent } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch-item/p-ai-switch-item.component';
import { PBootstrapFormGroupComponent } from '@plano/shared/p-forms/p-bootstrap-form-group/p-bootstrap-form-group.component';
import { PCheckboxComponent } from '@plano/shared/p-forms/p-checkbox/p-checkbox.component';
import { PDropdownComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown.component';
import { PFormControl, PFormGroupBasedOnAI } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentChildInterface, PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { PInputCopyStringComponent } from '@plano/shared/p-forms/p-input-copy-string/p-input-copy-string.component';
import { PInputDateComponent, PInputDateTypes } from '@plano/shared/p-forms/p-input-date/p-input-date.component';
import { PInputComponent } from '@plano/shared/p-forms/p-input/p-input.component';
import { PRadiosComponent } from '@plano/shared/p-forms/p-radios/p-radios.component';
import { Subscription } from 'rxjs';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum AISwitchUIType {
	CHECKBOX = 'CHECKBOX',
	INPUT = 'INPUT',
	TEXTAREA = 'TEXTAREA',
	TEXT_EDITOR = 'TEXT_EDITOR',
	RADIOS = 'RADIOS',
	DROPDOWN = 'DROPDOWN',
	DATE_PICKER = 'DATE_PICKER',
	IMAGE_UPLOAD = 'IMAGE_UPLOAD',
	PDF_UPLOAD = 'PDF_UPLOAD',
	COLOR = 'COLOR',
}

/**
 * If you have a nullable ai with a dropdown, you should provide two options.
 * One with the value AI_SWITCH_OPTION_REQUIRED, one with the value null
 */
export const AI_SWITCH_OPTION_REQUIRED = 'required';

type Option = {
	text : PDictionarySourceString,

	/**
	 * The value that should be set to the assigned attributeInfos value, when the user selects
	 * this option.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
	value : any | typeof AI_SWITCH_OPTION_REQUIRED,
};

/**
 * A component that decides which form-control to show based on the attribute type.
 * The default (e.g. <input> for attribute type 'string') can be overwritten with e.g. type="TEXTAREA"
 *
 * Sometimes you will reach the limits of this component. If you need a more customized form-control like for example a
 * p-input with a custom icon and a type-ahead array, you will need to
 * a: use a <p-bootstrap-form-group> with a <p-input> inside, instead of <p-ai-switch>
 * or
 * b: Ask a Frontend-Dev to implement the feature into <p-ai-switch>
 *
 * NOTE: 	To the frontend dev’s: Be careful with new flags and features. This component tends to get cluttered quickly,
 * 				since it has to solve so many sub-cases.
 *
 * @example
 * shows a input
 * <p-ai-switch
 * 	label="Name" i18n-label
 * 	[attributeInfo]="item.attributeInfoName"
 * 	[group]="formGroup"
 * ></p-ai-switch>
 *
 * @example
 * shows a checkbox
 * <p-ai-switch
 * 	label="Is Awesome" i18n-label
 * 	description="Because I'm a cute text over an info-circle."
 * 	[attributeInfo]="item.attributeInfoIsAwesome"
 * 	[group]="formGroup"
 * ></p-ai-switch>
 *
 * @example
 * shows a editable input
 * <p-ai-switch
 * 	[pEditable]="true"
 * 	[api]="api"
 *
 * 	label="Is Awesome" i18n-label
 * 	[attributeInfo]="item.attributeInfoIsAwesome"
 * 	[group]="formGroup"
 * ></p-ai-switch>
 */
@Component({
	selector: 'p-ai-switch[attributeInfo][group]',
	templateUrl: './p-ai-switch.component.html',
	styleUrls: ['./p-ai-switch.component.scss'],
	exportAs: 'switchRef',
})
export class PAISwitchComponent extends AttributeInfoBaseComponentDirective
	implements AfterContentInit, PFormControlComponentInterface, OnDestroy, AfterContentChecked, AfterViewInit, EditableControlInterface {
	/** {@link PFormControlComponentInterface#readMode} */
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;

	/**
	 * Should this ai-switch only allow the user to copy the value of the attribute info?
	 */
	@Input() protected onlyCopyString : boolean = false;

	/** @see PInputCopyStringComponent#appendButtonText */
	@Input() protected appendButtonText : PInputCopyStringComponent['appendButtonText'] = null;

	/**
	 * Bellow this size the label is not rendered to the screen. (null by default)
	 */
	@Input() public minimumLabelDisplayBootstrapSize : PBootstrapFormGroupComponent['minimumLabelDisplayBootstrapSize'] = null;

	/** @see PBootstrapFormGroupComponent#keepAlwaysAsFootnote */
	@Input() public keepAlwaysAsFootnote = false;

	/** @see PBootstrapFormGroupComponent#footnoteIcon */
	@Input() public footnoteIcon : PBootstrapFormGroupComponent['footnoteIcon'] = enumsObject.PlanoFaIconPool.MORE_INFO;

	/**
	 * The attributeInfo that should be added to the form.
	 * @example
	 *   attributeInfo="member.attributeInfoFirstName"
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public override attributeInfo! : ApiAttributeInfo<any, any>;

	/**
	 * FormGroup for the PFormControl that is related to the provided attributeInfo.
	 */
	@Input() private group! : FormGroup | PFormGroupBasedOnAI;

	/**
	 * The default ui element (e.g. <input> for attribute type 'string') can be overwritten here.
	 * @example
	 *   type="TEXTAREA"
	 */
	@Input('type') public _type ?: AISwitchUIType;

	/**
	 * The text that should be shown if there is no value yet.
	 * Obviously has no effect to non-string and non-number inputs like checkbox, radios, dropdown, etc.
	 */
	@Input() public placeholder : string | null = null;

	/**
	 * This happens if the value of the model changes.
	 */
	@Output() private valueChange = new EventEmitter<unknown>();

	/** @see PCheckboxComponent#valueText */
	@Input() public valueText : PCheckboxComponent['valueText'] = null;

	/** @see PCheckboxComponent#hasButtonStyle */
	@Input() public hasButtonStyle : PCheckboxComponent['hasButtonStyle'] = true;

	/** @see PCheckboxComponent#hideValueIcon */
	@Input() public hideValueIcon : PCheckboxComponent['hideValueIcon'] = false;

	/** @see PCheckboxComponent#cannotSetHintTemplate */
	@Input() public cannotSetHintTemplate : PCheckboxComponent['cannotSetHintTemplate'] | PRadiosComponent['cannotSetHintTemplate'] = null;

	/**
	 * @see PInputDateComponent#size
	 * @see PCheckboxComponent#size
	 */
	@Input() public size : PCheckboxComponent['size'] | PInputDateComponent['size'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public bsFormGroupClasses = '';

	/**
	 * The label of the input field
	 * Don't forget to append i18n-label
	 */
	@Input('label') private _label : PBootstrapFormGroupComponent['label'] | null = null;

	/** @see PBootstrapFormGroupComponent#description */
	@Input() public description : PBootstrapFormGroupComponent['description'] = null;

	/** @see PInputComponent#durationUIType */
	@Input() public durationUIType : PInputComponent['durationUIType'] = null;

	/** @see PInputComponent#maxDecimalPlacesCount */
	@Input() public maxDecimalPlacesCount : PInputComponent['maxDecimalPlacesCount'] = null;

	/**
	 * By default, if there is an appendText then the prependIcon is placed
	 * on the append. Set this to true to disable this behaviour
	 */
	@Input() public forcePrependIconOnPrepend : PInputComponent['forcePrependIconOnPrepend'] = false;

	/**
	 * Options that gets available in the appending dropdown
	 */
	@Input() public options : Option[] | null = null;

	/**
	 * @see PDropdownComponent#appendDropdownOnBody
	 */
	@Input() public appendDropdownOnBody : PDropdownComponent['appendDropdownOnBody'] = false;

	/** @see PInputDateComponent#supportsUnset */
	@Input() public supportsUnset : PInputDateComponent['supportsUnset'] = null;

	/** @see PInputDateComponent#_showEraseValueBtn */
	@Input() public showEraseValueBtn ?: PInputDateComponent['_showEraseValueBtn'];

	/** @see PInputDateComponent#eraseValueBtnLabel */
	@Input() public eraseValueBtnLabel : PInputDateComponent['eraseValueBtnLabel'] = null;

	/** @see PInputDateComponent#closeBtnDisabled */
	@Input() public closeBtnDisabled : PInputDateComponent['_closeBtnDisabled'] = null;

	/** @see PInputComponent#inputGroupAppendText */
	@Input() public inputGroupAppendText : PInputComponent['inputGroupAppendText'] = null;

	/** @see PInputComponent#inputGroupAppendIcon */
	@Input() public inputGroupAppendIcon : PInputComponent['inputGroupAppendIcon'] = null;

	/** @see PInputComponent#typeahead */
	@Input() public typeahead : PInputComponent['typeahead'] = [];

	/** @see PInputComponent#appendOnBody */
	@Input() public appendOnBody : PInputComponent['appendOnBody'] = false;

	/** @see PInputPdfComponent#datePickerOptions */
	@Input() public pdfInputDescription : PInputPdfComponent['inputDescription'] = null;

	/** @see PInputPdfComponent#pdfDescription */
	@Input() public pdfDescription : PInputPdfComponent['pdfDescription'] = null;

	/** @see PInputPdfComponent#modalTemplate */
	@Input() public pdfModalTemplate : PInputPdfComponent['modalTemplate'] = null;

	/** @see PInputPdfComponent#overlayTemplate */
	@Input() public pdfOverlayTemplate : PInputPdfComponent['overlayTemplate'] = null;

	/** @see PCheckboxComponent#theme */
	@Input() public theme : PCheckboxComponent['theme'] = null;

	/**
	 * Reference to the input image inside this p-ai-switch, if any
	 */
	@ViewChild('inputImageRef') public inputImageRef ?: PInputImageComponent;

	/**
	 * Reference to the input image inside this p-ai-switch, if any
	 */
	@ViewChild('inputDateRef') public inputDateRef ?: PInputDateComponent;

	/**
	 * The template that should be rendered instead of the default template
	 */
	@Input() public triggerTemplate : PDropdownComponent['triggerTemplate'] = null;

	/**
	 * Template to be rendered when the dropdown is on readMode, this needs to be provided if we
	 * expect the dropdown to be on read mode and have provided a trigger custom template
	 */
	@Input() public triggerReadModeTemplate : PDropdownComponent['triggerReadModeTemplate'] = null;

	/** @see PInputDateComponent#type */
	@Input('inputDateType') public _inputDateType : PInputDateComponent['type'] = null;

	/** @see PInputDateComponent#label */
	@Input() public inputDateLabel : PInputDateComponent['label'] = null;

	/** @see PInputDateComponent#icon */
	@Input() public icon : PInputDateComponent['icon'] = null;

	/** @see PInputDateComponent#suggestionTimestamp */
	@Input() public suggestionTimestamp : PInputDateComponent['suggestionTimestamp'] = null;

	/** @see PInputDateComponent#suggestionLabel */
	@Input() public suggestionLabel : PInputDateComponent['suggestionLabel'] = null;

	/** @see PInputDateComponent#showDaysBeforeInput */
	@Input() public showDaysBeforeInput : PInputDateComponent['showDaysBeforeInput'] = false;

	/** @see PInputDateComponent#daysBefore */
	@Input() public daysBefore : PInputDateComponent['daysBefore'] = null;

	/** @see PInputDateComponent#daysBeforeChange */
	@Output() public daysBeforeChange : PInputDateComponent['daysBeforeChange'] = new EventEmitter<number | null>();

	/** @see PInputDateComponent#innerInputChanged */
	@Output() public innerInputChanged : EventEmitter<string | null> = new EventEmitter<string | null>();

	/** @see PInputDateComponent#daysBeforeLabel */
	@Input() public daysBeforeLabel : PInputDateComponent['daysBeforeLabel'] = null;

	/** @see PInputImageComponent#previewTemplate */
	@Input() public previewTemplate : PInputImageComponent['previewTemplate'] = null;

	/**
	 * Template to be used to render the upload interface of the image input. This template needs to handle
	 * all the required logic to make the upload work. (i.e. call the addImage or removeImage on the correct
	 * triggers)
	 */
	@Input() public imageUploadTemplate : PInputImageComponent['imageUploadTemplate'] = null;

	/** @see PInputDateComponent#showTimeInput */
	@Input() public showTimeInDateInput : PInputDateComponent['showTimeInput'] = null;

	@Input('cannotSetHint') public override _cannotSetHint : PFormControlComponentChildInterface['cannotSetHint'] =
		null;

	/**
	 * Should the password strength meter be visible?
	 * Only use this if type is Password.
	 */
	@Input() public showPasswordMeter : PInputComponent['showPasswordMeter'] = false;

	/* eslint-disable-next-line @typescript-eslint/no-explicit-any, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Input() public dropdownValue : any;

	/** @see PInputComponent#type */
	@Input('pInputType') private _pInputType : PInputComponent['type'] | null = null;

	/* eslint-disable-next-line @typescript-eslint/no-explicit-any, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public dropdownValueChange = new EventEmitter<any>();

	/**
	 * emit after the fist afterViewInit so we know that the component was created
	 */
	@Output() public onCreation = new EventEmitter<null>();

	@ContentChildren(PAISwitchItemComponent) public items ?: QueryList<PAISwitchItemComponent>;

	/**
	 * Should this be an pEditable?
	 * Here you can overwrite the internal pEditable logic.
	 * @see PAISwitchComponent#pEditable
	 */
	@Input('pEditable') public _pEditable : EditableControlInterface['pEditable'] | null = null;

	@Input() public valid : EditableControlInterface['valid'] = null;
	@Input() public saveChangesHook ?: EditableControlInterface['saveChangesHook'];
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	/** Makes the frontend developers life easier */
	@HostBinding('attr.data-ai-id') private get atId() : string | void {
		if (!Config.DEBUG) return;
		return this.attributeInfo.id.toString();
	}

	constructor(
		protected override console : LogService,
		private pFormsService : PFormsService,
		protected override changeDetectorRef : ChangeDetectorRef,
		private validators : ValidatorsService,
		private pMomentService : PMomentService,
		private parentEditable ?: EditableDirective,
		@Optional() private pTabsParent ?: PTabsComponent,
	) {
		super(true, changeDetectorRef, console);
	}

	public enums = enumsObject;

	/** @see PInputDateComponent#type */
	protected get inputDateType() : PInputDateComponent['type'] {
		if (this._inputDateType) {
			return this._inputDateType;
		}
		if (this.attributeInfo.primitiveType === PApiPrimitiveTypes.DateExclusiveEnd) return PInputDateTypes.deadline;
		return null;
	}

	/**
	 * The api to be used for the pEditable’s
	 */
	public get api() : EditableControlInterface['api'] {
		return this.attributeInfo.api;
	}

	public ngAfterViewInit() : void {
		this.onCreation.emit(null);

		if (this.pTabsParent?.renderOnlyContentOfActiveTab) {
			/**
			 * At the moment this throw line was written, we did not have a use case for it,
			 * but if the answer to the following question is 'yes', then you can try to add an exception
			 * here, maybe add another input to be prioritized here. Something like: allowAISwitchesInsideRenderActiveTabOnlyTabs,
			 * and then change the condition above to check for the current boolean AND the new input. Then in your case you
			 * can just pass the value of true to the newly created input.
			 */
			throw new Error('You are using ai-switches inside tabs that only render the active tab, is this intended? If so, check the code for this error message on how to handle it.');
		}
	}

	/**
	 * The attribute info probably contains a validator that gives us all information we need to calculate the date
	 * which the calendar of the p-input-date should be limited to.
	 * @see PInputDateComponent#min
	*/
	public get min() : PInputDateComponent['min'] {
		// Get object that contains the validator information
		const validatorObject = this.control.validatorObjects[PPossibleErrorNames.MIN];
		if (!validatorObject) return null;
		const comparedConst = validatorObject.comparedConst as number | null | (() => number | null) | undefined;
		const result = comparedConst instanceof Function ? comparedConst() : comparedConst;
		if (result === undefined) return null;
		if (result === null) return null;

		const equalIsAllowed = validatorObject.additionalParams?.['equalIsAllowed'];
		if (equalIsAllowed === false) {
			// If equal is not allowed, then the result does not contain the limitation that the calendar needs.
			// So we need to manipulate it according to the type of the validated value.
			switch (this.attributeInfo.primitiveType) {
				case PApiPrimitiveTypes.Date:
					// If user should not be able to select the same day, we need to add one day to the result
					return this.pMomentService.m(result).add(1, 'day').valueOf();
				case PApiPrimitiveTypes.DateExclusiveEnd:
					return result + 1;
				case PApiPrimitiveTypes.DateTime:
					// If user should not be able to select the same time, it will almost always still be possible to set another
					// time at the same day. So the only relevant case here is:
					// If the time is 23:59, and equal is not allowed,
					// then the first selectable day should be the next one.
					return this.pMomentService.m(result).add(1, 'minute').valueOf();
				default:
					return result;
			}
		}

		return result;
	}

	/**
	 * The attribute info probably contains a validator that gives us all information we need to calculate the date
	 * which the calendar of the p-input-date should be limited to.
	 * @see PInputDateComponent#max
	*/
	public get max() : PInputDateComponent['max'] {
		// Get object that contains the validator information
		const validatorObject = this.control.validatorObjects[PPossibleErrorNames.MAX];
		if (!validatorObject) return null;
		const comparedConst = validatorObject.comparedConst as number | null | (() => number | null) | undefined;
		const result = comparedConst instanceof Function ? comparedConst() : comparedConst;
		if (result === undefined) return null;
		if (result === null) return null;

		const equalIsAllowed = validatorObject.additionalParams?.['equalIsAllowed'];
		if (equalIsAllowed === false) {
			// If equal is not allowed, then the result does not contain the limitation that the calendar needs.
			// So we need to manipulate it according to the type of the validated value.
			switch (this.attributeInfo.primitiveType) {
				case PApiPrimitiveTypes.Date:
				case PApiPrimitiveTypes.DateExclusiveEnd:
					// If user should not be able to select the same day, we need to subtract one day from the result
					return this.pMomentService.m(result).subtract(1, 'day').valueOf();
				case PApiPrimitiveTypes.DateTime:
					// If user should not be able to select the same time, it will almost always still be possible to set another
					// time at the same day. So the only relevant case here is:
					// If the time is 00:00, and equal is not allowed,
					// then the last selectable day should be the previous one.
					return this.pMomentService.m(result).subtract(1, 'minute').valueOf();
				default:
					return result;
			}
		}

		return result;
	}

	private subscriptions : Subscription[] = [];

	private setApiSubscribers() : void {
		const subscription = this.attributeInfo.api?.onChange.subscribe((changeTerm) => {
			if (
				changeTerm === 'dismissCopy' ||
				changeTerm === 'onBackendResponse'
			) {
				this.refreshOptionsDropdown();
			}
		});
		if (subscription) this.subscriptions.push(subscription);
	}

	private previousPrimitiveType : PApiPrimitiveTypes | null = null;

	/**
	 * Is the input in only read mode or is editable (default)?
	 */
	public get readMode() : boolean {
		if (this._readMode !== null) return this._readMode;
		return this.attributeInfo.readMode;
	}

	/**
	 * Is the input in only read mode or is editable (default)?
	 */
	public override get cannotSetHint() : PFormControlComponentChildInterface['cannotSetHint'] {
		if (this._cannotSetHint !== null) return this._cannotSetHint;
		return this.attributeInfo.cannotSetHint;
	}

	/**
	 * The label of the input field
	 * Don't forget to append i18n-label
	 */
	public get label() : string | null {
		if (this._label) return this._label;
		const control = this.group.controls[this.attributeInfo.id] as PFormControl | undefined;
		if (control?.labelText) return control.labelText;
		return null;
	}

	private _control : PFormControl | null = null;

	/**
	 * The PFormControl that is related to the provided attributeInfo.
	 */
	public get control() : PFormControl {
		assumeDefinedToGetStrictNullChecksRunning(this.group, 'group');
		const validatorObjects = this.getComponentBasedValidators();
		this._control = this.pFormsService.getByAI(this.group, this.attributeInfo, this._label ?? undefined, validatorObjects);
		return this._control;
	}

	private removeFormControl() : void {
		const currentControl = this.group.controls[this.attributeInfo.id] as PFormControl | undefined;
		if (!currentControl) return;
		currentControl.unsubscribe();

		// Removing the form-control will trigger all validators in the form-group. So, make sure they are up-to-date
		for (const control of Object.values(this.group.controls)) {
			if (control instanceof PFormControl && control !== this.group.get(this.attributeInfo.id)) control.updateValidators();
		}

		this.group.removeControl(this.attributeInfo.id);

		// update ui
		requestAnimationFrame(() => {
			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnDestroy() : void {
		this.removeFormControl();
		this.changeDetectorRef.detectChanges();
		this._selectedOptionValue = undefined;
		for (const subscription of this.subscriptions) subscription.unsubscribe();
	}

	/**
	 * Turns a multiple input field from checkboxes into a dropdown
	 * if there is more than 3 items
	 */
	private multiSelectUiFormControl() :
		| AISwitchUIType.RADIOS
		| AISwitchUIType.DROPDOWN
		| AISwitchUIType {
		if (this._type) return this._type;
		if (this.attributeInfo.primitiveType === PApiPrimitiveTypes.string) return AISwitchUIType.INPUT;
		if (this.attributeInfo.primitiveType === PApiPrimitiveTypes.Integer) return AISwitchUIType.INPUT;
		if (this.items && this.items.length <= 3) return AISwitchUIType.RADIOS;
		return AISwitchUIType.DROPDOWN;
	}

	private determineTypeBasedOnAI() : AISwitchUIType {
		switch (this.attributeInfo.primitiveType) {
			case PApiPrimitiveTypes.string:
			case PApiPrimitiveTypes.ShiftId:
			case PApiPrimitiveTypes.ShiftSelector:
			case PApiPrimitiveTypes.Id:
			case PApiPrimitiveTypes.any:
			case PApiPrimitiveTypes.number:
			case PApiPrimitiveTypes.ClientCurrency:
			case PApiPrimitiveTypes.Euro:
			case PApiPrimitiveTypes.Password:
			case PApiPrimitiveTypes.PostalCode:
			case PApiPrimitiveTypes.Minutes:
			case PApiPrimitiveTypes.Hours:
			case PApiPrimitiveTypes.Days:
			case PApiPrimitiveTypes.Percent:
			case PApiPrimitiveTypes.Months:
			case PApiPrimitiveTypes.Years:
			case PApiPrimitiveTypes.Email:
			case PApiPrimitiveTypes.Integer:
			case PApiPrimitiveTypes.LocalTime:
			case PApiPrimitiveTypes.Tel:
			case PApiPrimitiveTypes.Duration:
			case PApiPrimitiveTypes.Search:
			case PApiPrimitiveTypes.Url:
			case PApiPrimitiveTypes.Iban:
			case PApiPrimitiveTypes.Bic:
				return AISwitchUIType.INPUT;
			case PApiPrimitiveTypes.boolean:
				return AISwitchUIType.CHECKBOX;
			case PApiPrimitiveTypes.DateTime:
			case PApiPrimitiveTypes.DateExclusiveEnd:
			case PApiPrimitiveTypes.Date:
				return AISwitchUIType.DATE_PICKER;
			case PApiPrimitiveTypes.Enum:
				if (this.items?.length && this.items.length <= 3) return AISwitchUIType.RADIOS;
				return AISwitchUIType.DROPDOWN;
			case PApiPrimitiveTypes.Image:
				return AISwitchUIType.IMAGE_UPLOAD;
			case PApiPrimitiveTypes.Pdf:
				return AISwitchUIType.PDF_UPLOAD;
			case PApiPrimitiveTypes.Color:
				return AISwitchUIType.COLOR;
			case PApiPrimitiveTypes.ApiList:
				throw new Error(`PAISwitchComponent does not support visualization of »${this.attributeInfo.primitiveType}«`);
			case null:
				throw new Error(`could not get ai-switch-type for primitiveType »${this.attributeInfo.primitiveType}«`);
		}
	}

	/**
	 * Determines the type of an input a return a matching input field for
	 * @example a datepicker or a password fiel with hidden input
	 */
	public get typeOfUIFormControl() : AISwitchUIType {
		if (this._type !== undefined) return this._type;

		// The user added items?
		// Then the user seems to want some kind of multi-select ui form control,
		// no matter if it fits to the provided primitiveType or not.
		if (this.items?.length) return this.multiSelectUiFormControl();

		return this.determineTypeBasedOnAI();
	}

	private validateImageUploadAttributes() : void {
		if (this.typeOfUIFormControl !== AISwitchUIType.IMAGE_UPLOAD) return;
		if (
			this.saveChangesHook !== undefined ||
			!!this.onSaveStart.observers.length ||
			!!this.onDismiss.observers.length ||
			!!this.onLeaveCurrent.observers.length ||
			!!this.editMode.observers.length ||
			this._checkTouched !== null
		) {
			this.console.error('Not implemented yet.');
		}
	}

	public override ngAfterContentChecked() : void {
		// has the primitive type changed?
		const currentPrimitiveType = this.attributeInfo.primitiveType;

		if (currentPrimitiveType !== this.previousPrimitiveType) {
			this.previousPrimitiveType = currentPrimitiveType;

			// Then reset the currently stored value because we assume that it will not be convertible to the new primitive type
			if (this.attributeInfo.isAvailable && this.attributeInfo.canSet) this.control.setValue(this.attributeInfo.defaultValue);
		}
	}

	/**
	 * Is there a dropdown option for this input which has the value null?
	 */
	public get hasNullableOptions() : boolean {
		return !!this.options?.some(item => item.value === null);
	}

	private getComponentBasedValidators() : PValidatorObject[] | null {
		if (this.hasNullableOptions) {
			return [
				new PValidatorObject({
					name: PPossibleErrorNames.REQUIRED,
					fn: (control) => {
						if (this.selectedOptionValue === null) return null;
						return this.validators.required(this.attributeInfo.primitiveType!).fn(control);
					},
				}),
			] satisfies PValidatorObject[];
		}
		return null;
	}

	private refreshOptionsDropdown() : void {
		// If there is no null option, the dropdown should not be refreshed automatically.
		if (!this.hasNullableOptions) return;

		if (!this.attributeInfo.isAvailable) return;
		if (this.attributeInfo.value === null) {
			this._selectedOptionValue = null;

			// TODO: 	This should not be necessary, but without it the _disabled attribute of p-input was not the same as
			// 				defined in the ai-switch component. I assume thats an issue of p-input, not ai-switch.
			this.control.disable({emitEvent: false});
		} else {
			assumeNonNull(this.options);
			this._selectedOptionValue = this.options.find(item => item.value !== null)?.value;

			// TODO: 	This should not be necessary, but without it the _disabled attribute of p-input was not the same as
			// 				defined in the ai-switch component. I assume thats an issue of p-input, not ai-switch.
			this.control.enable({emitEvent: false});
		}
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.validateImageUploadAttributes();
		const typeOfUI = this.typeOfUIFormControl;
		if (typeOfUI !== AISwitchUIType.INPUT && !!this.inputGroupAppendText) {
			this.console.error(
				`<p-ai-switch [inputGroupAppendText]="…" is only available if typeOfUIFormControl is ${AISwitchUIType.INPUT}.`,
			);
		}
		if (typeOfUI !== AISwitchUIType.IMAGE_UPLOAD && !!this.previewTemplate) {
			this.console.error(
				`<p-ai-switch [previewTemplate]="…" is only available if typeOfUIFormControl is ${AISwitchUIType.IMAGE_UPLOAD}.`,
			);
		}

		if (this.items && (typeOfUI === AISwitchUIType.RADIOS || typeOfUI === AISwitchUIType.DROPDOWN)) {
			for (const switchItem of this.items) {
				const attributeInfoValue = this.attributeInfo.getAttributeValueInfo(switchItem.value);
				switchItem.attributeInfo = attributeInfoValue;
			}
		}

		if (this.placeholder !== null) {
			switch (typeOfUI) {
				case AISwitchUIType.CHECKBOX:
				case AISwitchUIType.RADIOS:
				case AISwitchUIType.IMAGE_UPLOAD:
					throw new Error(`Placeholder is not available on type ${this.typeOfUIFormControl}.`);
				default:
			}
		}

		this.initValues();
		this.validateValues();
		this.refreshOptionsDropdown();
		this.setApiSubscribers();

		return super.ngAfterContentInit();
	}

	/** Only for internal template use */
	public get internalValue() : unknown {
		return this.attributeInfo.value;
	}

	public set internalValue(input : unknown) {
		this.valueChange.emit(input);
	}

	private validateValues() : void {
		if (this.typeOfUIFormControl === AISwitchUIType.DROPDOWN && !!this.cannotSetHint) throw new Error('Not supported yet');
	}

	private _selectedOptionValue : Option['value'] = undefined;

	/** If options are provided, get the selected option. */
	public get selectedOptionValue() : Option['value'] {
		return this._selectedOptionValue;
	}
	public set selectedOptionValue(input : Option['value']) {
		this._selectedOptionValue = input;
		if (input === null || this.control.value === null) {
			this.control.setValue(input === AI_SWITCH_OPTION_REQUIRED ? null : input);
		}
		this.control.markAsDirty();
		this.control.markAsTouched();
	}

	/**
	 * @see ValidationHintComponent#checkTouched
	 * If checkTouched is not overwritten with an @Input(), then it determine the return value with
	 * attributeInfo data.
	 */
	protected override get checkTouched() : ValidationHintComponent['checkTouched'] {
		return this._checkTouched ?? this.attributeInfo.isNewItem;
	}

	private initValues() : void {
		this.previousPrimitiveType = this.attributeInfo.primitiveType;
	}

	/**
	 * Can the user edit the input field?
	 * This defaults to false if its a new item, and true if its an existing item.
	 * This logic can be overwritten with the input {@link PAISwitchComponent#_pEditable}.
	 */
	public get pEditable() : boolean {
		if (this._pEditable !== null) {
			return this._pEditable;
		} else if (this.parentEditable?.pEditable) {
			return false;
		} else return !this.attributeInfo.isNewItem;
	}

	/**
	 * Show milliseconds since 1970
	 */
	public get showTimeInput() : PInputDateComponent['showTimeInput'] {
		if (this.showTimeInDateInput !== null) return this.showTimeInDateInput;
		if (
			!this.showDaysBeforeInput &&
			this.attributeInfo.primitiveType === PApiPrimitiveTypes.DateTime
		) return true;
		return null;
	}

	/**
	 * Decide if the circle-icons of radio buttons should be visible.
	 */
	public get hideRadioCircles() : PRadiosComponent['hideRadioCircles'] {
		// If every item has an defined icon, there is no need for radio-circles.
		return !this.items?.find((item) => !item.icon);
	}

	/**
	 * A getter that returns the primitive type for <p-input>. It throws if <p-input> does not support ai’s primitive type.
	 */
	public get pInputType() : PInputComponent['type'] {
		if (this._pInputType) return this._pInputType;
		switch (this.attributeInfo.primitiveType) {
			case PApiPrimitiveTypes.Enum:
			case PApiPrimitiveTypes.Date:
			case PApiPrimitiveTypes.DateExclusiveEnd:
			case PApiPrimitiveTypes.DateTime:
			case PApiPrimitiveTypes.Id:
			case PApiPrimitiveTypes.Image:
			case PApiPrimitiveTypes.Pdf:
			case PApiPrimitiveTypes.ShiftId:
			case PApiPrimitiveTypes.ShiftSelector:
			case PApiPrimitiveTypes.any:
			case PApiPrimitiveTypes.boolean:
			case PApiPrimitiveTypes.Color:
			case PApiPrimitiveTypes.ApiList:
				throw new Error('unsupported primitiveType for p-input');
			default:
				assumeDefinedToGetStrictNullChecksRunning(this.attributeInfo.primitiveType, 'this.attributeInfo.primitiveType');
				return this.attributeInfo.primitiveType;
		}
	}

	/**
	 * Handle dropdown click
	 */
	public onInputItemClick(item : PAISwitchItemComponent, event : unknown) : void {
		item.onClick.emit(event);
	}
}
