import { DecimalPipe, Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { PBackgroundColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PSimpleChanges } from '@plano/shared/api';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS } from '@plano/shared/core/pipe/decimal-pipe.consts';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { PBadgeAlign, PBadgeComponentInterface, PBadgeContent } from './p-badge.types';

@Component({
	selector: 'p-badge',
	templateUrl: './p-badge.component.html',
	styleUrls: ['./p-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PBadgeComponent implements PBadgeComponentInterface, OnInit, AfterViewInit, PComponentInterface, OnChanges {
	@HostBinding('class.border')
	@HostBinding('class.badge') private _alwaysTrue = true;
	@HostBinding('role') private role = 'status';

	@HostBinding('class.text-skeleton-animated')

	/** @see PComponentInterface#isLoading */
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isLoading : PComponentInterface['isLoading'] = null;

	/**
	 * If a routerLink is provided to the badge it will be clickable and wrapped in an anchor tag
	 */
	@HostBinding('class.clickable')
	@Input() public badgeRouterLink : string | null = null;

	/**
	 * fragment to be added to the router link of the badge
	 */
	@Input() public badgeFragment : string | null = null;

	/**
	 * Icon to be displayed inside the badge
	 */
	@Input('icon') private iconInput : PlanoFaIconPoolValues | null = null;

	/**
	 * Is this badge disabled?
	 */
	@HostBinding('class.disabled')
	@Input() public isDisabled : boolean = false;

	/**
	 * The badge title
	 */
	@HostBinding('attr.title')
	@Input() public badgeTitle : string = '';

	/**
	 * Does this badge serve to provide information about the state of the element?
	 */
	@HostBinding('class.info-badge')
	@HostBinding('class.px-2')
	@HostBinding('class.badge-pill')
	@HostBinding('class.text-transform-none')
	@Input() public infoBadge : boolean = false;

	/**
	 * Should have the todo-badge class
	 */
	@HostBinding('class.todo-badge')
	@Input() public todoBadge : boolean = true;

	@Input() public theme : PThemeEnum | 'reverse-primary' = enumsObject.PThemeEnum.DANGER;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public content : PBadgeContent | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public align : PBadgeAlign = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	/**
	 * Classes to be added to the badge
	 */
	@Input() public badgeClasses : string | null = null;

	@HostBinding('class.small') private get hasClassSmall() : boolean {
		return this.size === enumsObject.BootstrapSize.SM || this.infoBadge;
	}
	@HostBinding('class.p-1') private get hasClassLarge() : boolean {
		return this.size === enumsObject.BootstrapSize.LG;
	}

	@HostBinding('class.empty') private get isEmpty() : boolean {
		return this.content === null || this.content === true;
	}

	@HostBinding('class.no-text') private get noText() : boolean {
		return !this.text;
	}

	constructor(
		private renderer : Renderer2,
		public element : ElementRef<HTMLElement>,
		public cdr : ChangeDetectorRef,
		private decimalPipe : DecimalPipe,
		private location : Location,
		private console : LogService,
	) {
	}

	public enums = enumsObject;

	/**
	 * Return the badgeRouterLink to be used in the template.
	 *
	 * If a fragment is passed but no routerLink, it will assume '.' as the default.
	 */
	protected get badgeRouterLinkForTemplate() : string | null {
		if (this.badgeRouterLink === null && !!this.badgeFragment) {
			return '.';
		} else return this.badgeRouterLink;
	}

	public ngOnInit() : void {
		if (this.badgeClasses) {
			for (const badgeClass of this.badgeClasses.split(' '))
				this.renderer.addClass(this.element.nativeElement, badgeClass);
		}
	}

	public ngOnChanges(changes : PSimpleChanges<PBadgeComponent>) : void {
		if (changes.theme) {
			const previousTheme = changes.theme.previousValue;
			this.applyThemeClasses(null, previousTheme);
		}
		if (changes.badgeClasses) {
			if (changes.badgeClasses.previousValue) {
				for (const badgeClass of changes.badgeClasses.previousValue.split(' '))
					this.renderer.removeClass(this.element.nativeElement, badgeClass);
			}
			if (changes.badgeClasses.currentValue) {
				for (const badgeClass of changes.badgeClasses.currentValue.split(' '))
					this.renderer.addClass(this.element.nativeElement, badgeClass);
			}
		}
	}

	/**
	 * Apply all classes related to the theme of the badge
	 */
	public applyThemeClasses(newTheme : PThemeEnum | 'reverse-primary' | null = null, previousTheme : PThemeEnum | 'reverse-primary' | null = null) : void {
		if (previousTheme) {
			this.renderer.removeClass(this.element.nativeElement,`badge-${previousTheme}`);
			this.renderer.removeClass(this.element.nativeElement,`border-${this.borderColor(previousTheme)}`);
			this.renderer.removeClass(this.element.nativeElement,`text-${this.textColor(previousTheme)}`);
		}
		if (newTheme) this.theme = newTheme;
		if (!this.element.nativeElement.classList.contains(`badge-${this.theme}`)) {
			this.renderer.addClass(this.element.nativeElement, `badge-${this.theme}`);
		}

		if (!this.element.nativeElement.classList.contains(`border-${this.borderColor(this.theme)}`)) {
			this.renderer.addClass(this.element.nativeElement, `border-${this.borderColor(this.theme)}`);
		}

		if (this.textColor(this.theme) && !this.element.nativeElement.classList.contains(`text-${this.textColor(this.theme)}`)) {
			this.renderer.addClass(this.element.nativeElement, `text-${this.textColor(this.theme)}`);
		}

		if (this.textColor(this.theme) === this.theme) {
			const content = this.content ? `Content: »${this.content}«` : '';
			this.console.error(`p-badge: Icon color AND theme is: »${this.icon}«. Icon is probably invisible. ${content}`);
		}
	}

	public ngAfterViewInit() : void {
		if (this.infoBadge && this.todoBadge) {
			this.todoBadge = false;
		}
		this.applyThemeClasses();
		if (this.align && !this.element.nativeElement.classList.contains(`align-${this.align}`)) {
			this.renderer.addClass(this.element.nativeElement, `align-${this.align}`);
		}
	}

	private borderColor(theme : typeof this.theme) : PThemeEnum | PBackgroundColorEnum.WHITE {
		if (theme === 'danger') return enumsObject.PThemeEnum.DANGER;
		if (theme === 'reverse-primary') return PBackgroundColorEnum.WHITE;
		if (JSON.stringify(this.icon) === JSON.stringify(enumsObject.PlanoFaIconPool.DISMISS)) return enumsObject.PThemeEnum.DANGER;
		if (this.icon === 'check') return enumsObject.PThemeEnum.SUCCESS;
		return theme;
	}

	private textColor(theme : typeof this.theme) : PThemeEnum | undefined {
		const borderColor = this.borderColor(theme);
		if (this.icon && borderColor !== theme && borderColor !== PBackgroundColorEnum.WHITE) return borderColor;
		return undefined;
	}

	/**
	 * Does the content of the badge match an icon?
	 */
	public get isContentAnIcon() : boolean {
		return	this.content === enumsObject.PlanoFaIconPool.CANCELED ||
				this.content === enumsObject.PlanoFaIconPool.SUCCESS ||
				this.content === enumsObject.PlanoFaIconPool.QUESTION ||
				this.content === enumsObject.PlanoFaIconPool.EDIT;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get icon() : PlanoFaIconPoolValues | null {
		if (this.iconInput) return this.iconInput;
		if (typeof this.content === 'number') {
			return null;
		}
		if (this.content === enumsObject.PlanoFaIconPool.CANCELED ||
			this.content === enumsObject.PlanoFaIconPool.SUCCESS ||
			this.content === enumsObject.PlanoFaIconPool.QUESTION ||
			this.content === enumsObject.PlanoFaIconPool.EDIT) {
			return this.content;
		}
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get text() : string | null {
		if (this.isContentAnIcon) return null;
		if (typeof this.content === 'boolean') return `&nbsp;`;
		if (typeof this.content === 'number') return this.decimalPipe.transform(this.content, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS);
		if (typeof this.content === 'string') return this.content;
		return null;
	}

}
