import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PCheckboxComponent } from '@plano/shared/p-forms/p-checkbox/p-checkbox.component';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

type ValueType = string;

/**
 * This component is a color input.
 */
@Component({
	selector: 'p-input-color',
	templateUrl: './p-input-color.component.html',
	styleUrls: ['./p-input-color.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputColorComponent),
			multi: true,
		},
	],
})
export class PInputColorComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterContentInit, EditableControlInterface, PFormControlComponentInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public valueText : PCheckboxComponent['valueText'] = null;

	@HostBinding('class.flex-grow-1') protected _alwaysTrue = true;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		public element : ElementRef<HTMLElement>,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public enums = enumsObject;
	public PBtnThemeEnum = PBtnThemeEnum;

	public readonly CONFIG = Config;

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (this.saveChangesHook !== undefined) throw new Error('Not implemented yet');
		if (this.onSaveStart.observers.length) throw new Error('Not implemented yet');
		if (this.pEditable) throw new Error('Not implemented yet');
		if (this.onSaveSuccess.observers.length) throw new Error('Not implemented yet');
		if (this.onDismiss.observers.length) throw new Error('Not implemented yet');
		if (this.onLeaveCurrent.observers.length) throw new Error('Not implemented yet');
		if (this.editMode.observers.length) throw new Error('Not implemented yet');
		if (this.checkTouched !== null) throw new Error('Not implemented yet');

		// TODO: [PLANO-53381]
		if (this.cannotSetHint) throw new Error('cannotSetHint not implemented yet in this component. See PLANO-53381');

		if (!this.control) throw new Error('Currently it is not possible to use input-color without [formControl]. Please make sure the formControl has the right validators.');

		this.isCheckedBox = this.control.value !== null;

		return super.ngAfterContentInit();
	}

	public _disabled : boolean = false;

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to make [(ngModel)] and [formControl] work.
	 */
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}
	@Input('disabled') public override set disabled(input : boolean) {
		this._disabled = input;
		super.disabled = input;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	private previousColor : Color | null = null;

	/**
     * handler for when the checkbox is clicked. Responsible for setting the value to null,
     * when the checkbox is unchecked
     */
	public checkBoxClicked() : void {
		if (this.isCheckedBox) {
			this.value = this.previousColor ?? '#f69a38';
		} else {
			this.value = null;
		}
	}

	private _value : ValueType | null = null;
	public override _onChange : (value : ValueType | null) => void = () => {};
	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void { this._onChange((event.target as HTMLInputElement).value); this.keyup.emit(event); }
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public blur = new EventEmitter<FocusEvent>();

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched(event);
		this.blur.emit(event);
	}

	/**
     * boolean that represents if the checkbox is checked or not
     */
	public isCheckedBox ! : boolean;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<Event> = new EventEmitter<Event>();

	/** Get change event from inside this component, and pass it on. */
	public onChange(event : Event) : void {
		this._onChange((event.target as HTMLInputElement).value);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = (_event : Event) : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (this._value === value) return;
		this.previousColor = this._value;
		this._value = value;
		this.changeDetectorRef.markForCheck();

		// TODO: Still necessary? p-input don’t has this
		if (this.control) {
			this.control.markAsTouched();
			this.control.markAsDirty();
			this.control.updateValueAndValidity();
		}
		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		this._value = value ? value : '';
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled !== isDisabled) this.changeDetectorRef.markForCheck();
		this.disabled = isDisabled;
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		assumeNonNull(this.control);
		return this.pFormsService.visibleErrors(this.control);
	}
}
