<ng-template #defaultTemplate>
	<div class="bg-light rounded p-4 o-hidden">
		<div class="row justify-content-stretch align-items-stretch p-3">
			<div class="col-lg-6 d-flex justify-content-center align-items-center">
				<div class="p-3">
					<p-button
						*ngIf="!value; else hasValueTemplate"
						[disabled]="disabled"
						[theme]="enums.PThemeEnum.DARK"
						(triggerClick)="fileInput.click()"
					><fa-icon [icon]="enums.PlanoFaIconPool.UPLOAD"></fa-icon>&nbsp;<ng-container i18n>Bild auswählen</ng-container></p-button>

					<ng-template #hasValueTemplate>
						<p-button
							class="mb-2"
							[disabled]="disabled"
							[theme]="PBtnThemeEnum.OUTLINE_SECONDARY"
							(triggerClick)="fileInput.click()"
						><fa-icon [icon]="enums.PlanoFaIconPool.UPLOAD"></fa-icon>&nbsp;<ng-container i18n>Neues Bild</ng-container></p-button>
						<p-button
							class="mb-2"
							[disabled]="disabled"
							[theme]="PBtnThemeEnum.OUTLINE_DANGER"
							(triggerClick)="removeImage()"
						><fa-icon [icon]="enums.PlanoFaIconPool.DELETE"></fa-icon>&nbsp;<ng-container i18n>Bild Löschen</ng-container></p-button>
					</ng-template>
					<div class="form-text">
						<ng-template *ngTemplateOutlet="supportedFormatsDescriptions"></ng-template>
					</div>
				</div>
			</div>
			<div [style.opacity]="disabled ? '0.6' : '1'" class="col-lg-6 d-flex justify-content-center align-items-center">
				<ng-template [ngIf]="showPreview">
					<ng-template
						[ngTemplateOutlet]="previewTemplate ? previewTemplate : defaultPreviewTemplate"
						[ngTemplateOutletContext]="{
							$implicit: value,
							aspectRatio: imageRatio,
							minWidth: minWidth,
							minHeight: minHeight
						}"
					></ng-template>
				</ng-template>
			</div>
		</div>
	</div>
</ng-template>

<ng-template *ngTemplateOutlet="imageUploadTemplate ? imageUploadTemplate : defaultTemplate" ></ng-template>

<ng-template #supportedFormatsDescriptions>
	<div><span i18n>Unterstützte Formate: <strong>JPG</strong> & <strong>PNG</strong></span><ng-template
		[ngIf]="!!minWidth && !!minHeight"><br/><span i18n>Breite min. <strong>{{minWidth}}&nbsp;px</strong>, Höhe min. <strong>{{minHeight}}&nbsp;px</strong></span></ng-template>
	</div>
</ng-template>


<ng-template #defaultPreviewTemplate let-imageSrc>
	<img *ngIf="!!value" [src]="imageSrc" alt="Vorschau des hochgeladenen Bildes" i18n-alt>
	<fa-icon
		*ngIf="!value"
		class="deco-icon mx-auto d-block text-center"
		[class.text-primary]="true"
		[style.opacity]="disabled ? '0.6' : '1'"
		[icon]="enums.PlanoFaIconPool.IMAGE_UPLOAD"
	></fa-icon>
</ng-template>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-content
		*ngIf="!!control"
		modalTitle="Bild-Upload" i18n-modalTitle
		(onDismiss)="d($event)"
		(onClose)="c(cropperRef);"
		closeBtnLabel="Hochladen" i18n-closeBtnLabel
		[closeBtnTheme]="enums.PThemeEnum.SUCCESS"
		dismissBtnLabel="Abbrechen" i18n-dismissBtnLabel
		[closeBtnDisabled]="closeBtnDisabled(cropperRef)"
	>
		<p-modal-content-body>
			<p-input-image-cropper #cropperRef
				[control]="control"
				(croppedImageChange)="value=$event"
				[imageChangedEvent]="imageChangedEvent"
				[imageFile]="imageAsBlob"
			></p-input-image-cropper>
		</p-modal-content-body>
	</p-modal-content>
</ng-template>

<input
	class="d-none"
	type="file" (change)="fileChangeEvent($event, modalContent.template)"
	accept="image/png, image/jpeg"
	[formGroup]="$any(control)"
	#fileInput
>
