import { ModalRef } from '@plano/shared/core/p-modal/modal.service';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface ModalContentComponent<SuccessValueType = Event> {

	/**
	 * Initialize this component. Fill all necessary properties with values.
	 * First param is the reference to the Modal, needed in order to be able to close and dismiss inside the component.
	 */
	initModalContentComponent : (
		modalRef : ModalRef<SuccessValueType>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		...other : any[] // beliebig viele weitere params
	) => void;

	/**
	 * Dismiss the modal
	 */
	dismissModal : (input : Event) => void,

	/**
	 * Close the modal
	 */
	closeModal : (reason : SuccessValueType) => void,
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum ModalContentComponentCloseReason {
	REMOVE,
	ADD,
}
