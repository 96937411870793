<ng-template [ngIf]="!collapsed" [ngIfElse]="collapsedTemplate">
	<button
		*ngIf="showCollapseButton"
		title="Sidebar zuklappen" i18n-title
		class="sidebar-toggle d-flex align-items-center justify-content-center btn btn-frameless btn-light border-right p-0 shadow"
		(click)="toggleCollapsed($event)"
	><fa-icon [icon]="enums.PlanoFaIconPool.COLLAPSIBLE_CLOSE_TO_LEFT"></fa-icon></button>
	<ng-content></ng-content>
</ng-template>
<ng-template #collapsedTemplate>
	<div *ngIf="!config.IS_MOBILE" class="flex-grow-0 d-flex align-items-center justify-content-center flex-column">

		<fa-icon
			class="mt-2"
			[attr.aria-label]="showFilterIconPopover"
			[pTooltip]="showFilterIconPopover"
			[pTooltipPlacement]="NgxPopperjsPlacements.RIGHT"

			[class.text-primary]="showFilterIcon"
			[style.opacity]="showFilterIcon ? '1' : '0.1'" [icon]="enums.PlanoFaIconPool.FILTER"
		></fa-icon>

		<div
			class="todo-badge badge border mt-2"
			[attr.aria-label]="badgeContentPopover"
			[pTooltip]="badgeContentPopover"
			[pTooltipPlacement]="NgxPopperjsPlacements.RIGHT"

			[class.badge-danger]="badgeContent"
			[class.badge-light]="!badgeContent"
			[class.border-dark]="badgeContent"
			[style.opacity]="badgeContent ? '1' : '0.1'"
		>
			<code class="text-white">{!!badgeContent, select, true {{{ badgeContent }}} other {&nbsp;}}</code>
		</div>

		<p-sticky-note
			class="mt-2"
			[attr.aria-label]="showStickyNoteIconPopover"
			[pTooltip]="showStickyNoteIconPopover"
			[pTooltipPlacement]="NgxPopperjsPlacements.RIGHT"

			[style.opacity]="showStickyNoteIcon ? '1' : '0.1'"
			[backgroundColor]="showStickyNoteIcon ? enums.PThemeEnum.DARK : null"
			[showDot]="showStickyNoteIconDot"
		></p-sticky-note>

	</div>

	<div
		role="button"
		title="Sidebar aufklappen" i18n-title
		class="flex-grow-1 d-flex align-items-center justify-content-center">
		<fa-icon [icon]="'chevron-right'"></fa-icon>
	</div>
</ng-template>
