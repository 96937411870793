import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PTabsModule } from '@plano/client/shared/p-tabs/p-tabs.module';
import { PageModule } from '@plano/client/shared/page/page.module';
import { PComponentShowcaseComponent } from '@plano/p-component-showcase/p-component-showcase.component';
import { CoreComponentsModule } from '@plano/shared/core/component/core-components.module';
import { CoreModule } from '@plano/shared/core/core.module';
import { PFormsModule } from '@plano/shared/p-forms/p-forms.module';

@NgModule({
	declarations: [
		PComponentShowcaseComponent,
	],
	imports: [
		CommonModule,
		CoreComponentsModule,
		CoreModule,
		PageModule,
		PFormsModule,
		PTabsModule,
	],
	providers: [
	],
	exports: [
		PComponentShowcaseComponent,
	],
})
export class PAiComponentShowcaseModule {}
