import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

/**
 * Add the class.disabled to elements that have the disabled property
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[disabled]',
	standalone: true,
})
export class DisabledDirective {
	@HostBinding('attr.aria-disabled')
	@HostBinding('disabled')
	@HostBinding('class.disabled')
	@Input() protected disabled : boolean | null = null;

	constructor(elementRef : ElementRef<HTMLElement>) {
		const htmlElement = elementRef.nativeElement;
		if (htmlElement.classList.contains('disabled')) {
			throw new Error("DON'T PASS THE CLASS.DISABLED DIRECTLY! use disabled instead");
		}
	}
}
