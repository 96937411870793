import { AfterViewInit, Directive, Input } from '@angular/core';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: '[fragment]',
	standalone: true,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ValidFragmentsDirective implements AfterViewInit {
	/**
	 * Fragment to be added to any url or routerLink usage
     *
     * NOTE: Needs to be undefined to match routerLink type
	 */
	@Input() private fragment : string | undefined = undefined;

	public ngAfterViewInit() : void {
		if (this.fragment?.startsWith('scroll-to-')) {
			throw new Error(`Do not use 'scroll-to-' in fragments! (fragment: ${this.fragment})`);
		}
	}
}
