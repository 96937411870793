<div>
	<div class="row justify-content-stretch align-items-center overflow-hidden">
		<div class="col-lg-6">
			<div *ngIf="inputDescriptionString && !isInputDescriptionTemplate" [innerHTML]="inputDescriptionString | pSafeHtml"> </div>
			<ng-template [ngIf]="isInputDescriptionTemplate" *ngTemplateOutlet="inputDescriptionTemplate"> </ng-template>
			<div class="d-none d-lg-block">
				<ng-template *ngTemplateOutlet="btnsTemplate"></ng-template>
			</div>
		</div>
		<div
			[style.opacity]="disabled ? '0.6' : '1'"
			class="col-lg-6 d-flex justify-content-center align-items-center flex-column overflow-hidden"
			#colPdfWrapper
		>
			<p-pdf-template
				[overlayTemplate]="overlayTemplate"
				[pdfSrc]="pdfSrc"
				[zoomOnClick]="true"
				class="mt-lg-0 mt-2"
				[maxPdfWidth]="maxPdfTemplateWidth(colPdfWrapper)"
				[templatePdf]="templatePdf"
			></p-pdf-template>
			<div *ngIf="!isPdfDescriptionTemplate" class="mt-3 pdf-max-width form-text" [innerHTML]="pdfDescriptionString | pSafeHtml"></div>
			<p-button
				*ngIf="!!pdfSrc"
				[disabled]="disabled"
				class="mt-3 color-button"
				[theme]="PBtnThemeEnum.OUTLINE_PRIMARY"
				(triggerClick)="openEditModal($event)"
			>
				<fa-icon [icon]="enums.PlanoFaIconPool.EDIT_COLOR"></fa-icon>&nbsp;<ng-container i18n>Farben ändern</ng-container>
			</p-button>
		</div>
	</div>
	<div class="d-lg-none d-block">
		<ng-template *ngTemplateOutlet="btnsTemplate"></ng-template>
	</div>
</div>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<ng-container
		*ngTemplateOutlet="modalTemplate; context: { $implicit: value, formControl: control, c, d, errors: visibleErrors}"
	></ng-container>
</ng-template>

<ng-template #btnsTemplate>
	<div class="d-flex flex-column align-items-center w-100 justify-content-lg-start align-items-lg-start mt-4">
		<div *ngIf="!pdfSrc; else hasValueTemplate">
			<p-button
				class="mb-2"
				[disabled]="disabled"
				[theme]="enums.PThemeEnum.DARK"
				(triggerClick)="fileInput.click()"
			>
				<fa-icon [icon]="enums.PlanoFaIconPool.UPLOAD"></fa-icon>&nbsp;<ng-container i18n
					>Neue Vorlage hochladen</ng-container
				>
			</p-button>
			<div class="form-text mx-auto">
				<ng-container i18n>Anforderungen: <strong>PDF</strong>, DIN <strong>A4</strong> (210 x 297 mm)</ng-container>
			</div>
		</div>
		<ng-template #hasValueTemplate>
			<div class="d-flex flex-column flex-xxl-row w-100 align-items-xxl-start flex-wrap gap-3">
				<div class="d-flex flex-column flex-grow-1">
					<p-button
						class="mb-2"
						[disabled]="disabled"
						[theme]="PBtnThemeEnum.OUTLINE_SECONDARY"
						(triggerClick)="fileInput.click()"
					>
						<fa-icon [icon]="enums.PlanoFaIconPool.UPLOAD"></fa-icon>&nbsp;<ng-container i18n
							>Neue Vorlage hochladen</ng-container
						>
					</p-button>
					<div class="form-text mx-auto">
						<ng-container i18n
							>Anforderungen: <strong>PDF</strong>, DIN <strong>A4</strong> (210 x 297 mm)</ng-container
						>
					</div>
				</div>
				<p-button
					class="mb-2 flex-grow-1"
					[disabled]="disabled"
					[theme]="PBtnThemeEnum.OUTLINE_DANGER"
					(triggerClick)="removePdf($event)"
				>
					<fa-icon [icon]="enums.PlanoFaIconPool.DELETE"></fa-icon>&nbsp;<ng-container i18n>Vorlage löschen</ng-container>
				</p-button>
			</div>
		</ng-template>
	</div>
</ng-template>

<input
	class="d-none"
	type="file"
	(change)="fileChangeEvent($event)"
	accept="application/pdf"
	[formGroup]="$any(control)"
	#fileInput
/>
