/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { POP_SHOW_EFFECT_ON_NGIF_TRIGGER } from '@plano/animations';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { ApiListWrapper, ApiObjectWrapper } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { LogService } from '@plano/shared/core/log.service';
import { PAISwitchComponent } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';

/**
 * Nils’ Magic
 * Throw a AttributeInfo on it and it will create the whole form section for you.
 *
 * @example
 * 	<p-ai-form-builder
 * 		[attributeInfo]="member.attributeInfoThis"
 * 	></p-ai-form-builder>
 *
 * @example
 * 	<p-ai-form-builder
 * 		[formGroup]="myFormGroup"
 * 		[attributeInfo]="member.attributeInfoThis"
 * 	></p-ai-form-builder>
 */
@Component({
	selector: 'p-ai-form-builder[attributeInfo]',
	templateUrl: './p-ai-form-builder.component.html',
	animations: [POP_SHOW_EFFECT_ON_NGIF_TRIGGER],
})
export class PAIFormBuilderComponent extends AttributeInfoBaseComponentDirective implements OnDestroy {

	/**
	 * The attributeInfo that should be added to the form.
	 * @example
	 *   attributeInfo="member.attributeInfoFirstName"
	 */

	@Input() public override attributeInfo! : ApiAttributeInfo<any, any>;

	/**
	 * The formGroup which the new generated content should be applied to
	 * This is optional. If you dont provide it from outside, a new one will be created.
	 */
	@Input() public formGroup = new FormGroup({});

	/**
	 * Overwrites ai-switch’es internal logic to determine if this component has the EditableDirective functionality.
	 * @see PAISwitchComponent#_pEditable
	 */
	@Input() protected pEditable : PAISwitchComponent['_pEditable'] = false;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
	) {
		super(true, changeDetectorRef, console);
	}

	public PApiPrimitiveTypes = PApiPrimitiveTypes;

	/**
	 * @returns The child attribute-infos of `ai` which represent a primitive.
	 */
	public getChildAttributeInfos(ai : ApiAttributeInfo<any, ApiObjectWrapper<any>>) : ApiAttributeInfo<any, any>[] {
		const result : ApiAttributeInfo<any, any>[] = [];
		const objectWrapper = ai.value;

		if (!objectWrapper)
			return [];

		const childNames = objectWrapper.getChildPrimitiveNames().concat(objectWrapper.getChildWrapperNames());

		for (const childName of childNames) {
			result.push(objectWrapper.getAttributeInfo(childName));
		}

		return result;
	}

	/** Get a iterable for the list that is inside this ai */
	public getIterableValuesOfList(ai : ApiAttributeInfo<any, ApiListWrapper<any>>) : ApiObjectWrapper<any>[] {
		if (ai.isObjectWrapper) throw new Error(`${ai.id} is not an ApiListWrapper`);
		if (!ai.isAvailable) return [];

		const wrapper = ai.value;
		if (!wrapper)
			return [];

		const entries = Object.entries(wrapper);
		const filteredEntries = entries
			.filter(([key, value] : [string, any]) => {
				if (key === 'attributeInfoThis') return false;
				if (key === 'parent') return false;
				return value instanceof ApiAttributeInfo<any, any>;
			});
		return filteredEntries.map(([_key, value] : [string, any]) => value);
	}

	public ngOnDestroy() : void {
		this.changeDetectorRef.detectChanges();
	}
}
