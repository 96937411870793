import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { Config } from '@plano/shared/core/config';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';

/**
 * The available styles for the backend color
 */
export type BackgroundColorStyle = ExtractFromUnion<'light' | 'dark' | 'primary', PThemeEnum> | 'darker' | 'white';

@Component({
	/* eslint-disable-next-line @angular-eslint/component-selector */
	selector: 'scroll-shadow-box',
	templateUrl: './scroll-shadow-box.component.html',
	styleUrls: ['./scroll-shadow-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ScrollShadowBoxComponent implements AfterViewInit, OnDestroy {
	@ViewChild('contentElement') private contentElement ?: ElementRef<HTMLElement>;

	@ViewChild('innerContentElement') private innerContentElement ?: ElementRef<HTMLElement>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private backgroundStyle : BackgroundColorStyle | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public alwaysShowScrollbar : boolean = false;

	/**
	 * Should this scroll box disable the scrollbar gutter?
	 */
	@Input() public disableScrollbarGutter : boolean = false;

	/**
	 * Should there be no shadows?
	 */
	@Input() public noShadows : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public contentContainerStyles : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public fixedFooterTemplate : TemplateRef<unknown> | null = null;

	constructor(
		private zone : NgZone,
		private changeDetectorRef : ChangeDetectorRef,
	) {

	}

	/**
	 * Add the resize observer that will check if we need to recalculate the height
	 * of the scrolling element.
	 */
	private addResizeObserverToContent() : void {
		this.resizeObserver = new ResizeObserver(() => {
			if (this.contentElement) {
				this.contentHeight = this.contentElement.nativeElement.clientHeight;
				this.contentScrollHeight = this.contentElement.nativeElement.scrollHeight;
			}
		});
		this.resizeObserver.observe(this.innerContentElement!.nativeElement);
	}

	private addScrollEventListener() : void {
		this.zone.runOutsideAngular(() => {
			if (this.contentElement) {
				this.contentElement.nativeElement.addEventListener('scroll', () => {
					this.handleScrollEvent();
				});
			}
		});
	}

	public ngAfterViewInit() : void {
		if (!this.noShadows) {
			this.addResizeObserverToContent();
			this.addScrollEventListener();
		}

	}

	public ngOnDestroy() : void {
		this.resizeObserver?.disconnect();
	}

	private resizeObserver : ResizeObserver | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showFooterAsFixed() : boolean {
		if (Config.IS_MOBILE) return false;
		return !!this.fixedFooterTemplate;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get backgroundColor() : BackgroundColorStyle | undefined {
		if (this.backgroundStyle) return this.backgroundStyle;
		return undefined;
	}

	private contentScrollHeight : number | null = null;
	private contentHeight : number | null = null;

	private _showShadowTop : boolean = false;
	private _showShadowBottom : boolean = true;

	/**
	 * Should the shadow top be visible?
	 */
	protected get showShadowTop() : boolean {
		if (this.noShadows) return false;
		else return this._showShadowTop;
	}

	/**
	 * Should the shadow bottom be visible?
	 */
	protected get showShadowBottom() : boolean {
		if (this.noShadows) return false;
		else if (this.contentElement && this.contentScrollHeight && this.contentHeight) {
			// Needed because we don't want to show the shadow bottom if the element is not scrollable
			const isScrollable = this.contentScrollHeight > this.contentHeight;
			if (isScrollable)
				return this._showShadowBottom;
			return false;
		} else return false;
	}

	/**
	 * Handle the scroll event on the scroll box
	 */
	protected handleScrollEvent() : void {
		if (!this.contentElement) return;

		const element = this.contentElement.nativeElement;

		this.zone.runOutsideAngular(() => {
			const scrollPositionIsAtTop = element.scrollTop === 0;
			if (this._showShadowTop !== !scrollPositionIsAtTop) {
				this._showShadowTop = !scrollPositionIsAtTop;
				this.changeDetectorRef.detectChanges();
			}

			const scrollPositionIsAtBottom = Math.ceil(element.scrollTop) >= (element.scrollHeight - element.clientHeight);
			if (this._showShadowBottom !== !scrollPositionIsAtBottom) {
				this._showShadowBottom = !scrollPositionIsAtBottom;
				this.changeDetectorRef.detectChanges();
			}
		});
	}
}
