<div
	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
	(editMode)="editMode.emit($event)"
>
	<div class="input-group"
		[class.input-group-sm]="size === enums.BootstrapSize.SM"
		[class.input-group-lg]="size === enums.BootstrapSize.LG"
		[class.has-danger]="control && control.touched && control.invalid"
	>
		<ng-container *ngTemplateOutlet="prependItem"></ng-container>
		<textarea
			[class.d-none]="readMode"
			pAutoFocus

			autosize
			[maxHeight]="'300px'"

			pEditableTriggerFocussable

			[(ngModel)]="value"
			[placeholder]="placeholder ?? ''"
			class="form-control"
			[disabled]="control ? control.disabled : disabled"
			[required]="hasRequiredError"
			[class.required]="hasRequiredError"
			(focusout)="focusout.emit($event)"
			(focus)="focus.emit($event)"

			(change)="onChange($event)"
			(keyup)="onKeyUp($event)"
			(blur)="onBlur($event)"
		></textarea>
		<ng-template [ngIf]="readMode">
			<blockquote class="form-control-read-mode" [class.text-muted]="!value"><span [innerHTML]="valueAsHtml | pSafeHtml"></span></blockquote>
		</ng-template>
		<div class="input-group-append"
			*ngIf="pEditable"
			pVisibleInEditMode
		>
			<button
				type="button"
				class="btn btn-outline-secondary"
				pEditableDismissButton
			><fa-icon [icon]="enums.PlanoFaIconPool.UNDO"></fa-icon></button>
			<button
				type="button"
				class="btn btn-outline-secondary"
				pEditableSuccessButton
			><fa-icon [icon]="enums.PlanoFaIconPool.SUCCESS"></fa-icon></button>
		</div>
		<ng-container *ngTemplateOutlet="appendItem"></ng-container>
	</div>
</div>

<ng-template [ngIf]="!!control">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	></p-validation-hint>
</ng-template>

<ng-template #prependItem>
	<ng-content select=".input-group-prepend"></ng-content>
</ng-template>
<ng-template #appendItem>
	<ng-content select=".input-group-append"></ng-content>
</ng-template>
