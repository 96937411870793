import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { Config } from '@plano/shared/core/config';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';

/**
 * @deprecated
 * Use a p-dropdown in the p-subheader instead.
 *
 * TODO: find another solution for p-form-delete-section for tariffs or payment methods
 */
@Component({
	selector: 'p-form-delete-section',
	templateUrl: './p-form-delete-section.component.html',
	styleUrls: ['./p-form-delete-section.component.scss'],
})
export class PFormDeleteSectionComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemove : EventEmitter<Event> = new EventEmitter<Event>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('label') private _label : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('btnLabel') private _btnLabel : string | null = null;
	@Input() public disabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('modalText') private _modalText : string | null = null;

	constructor(
		private localize : LocalizePipe,
	) {}

	public readonly CONFIG = Config;

	public SectionWhitespace = SectionWhitespace;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get label() : string {
		if (this._label) return this._label;
		if (this._btnLabel) return this._btnLabel;
		return this.localize.transform('Löschen');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get btnLabel() : string {
		if (this._btnLabel) return this._btnLabel;
		return this.label;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get modalText() : string {
		if (this._modalText) return this._modalText;
		if (this._label) return this._label;
		return this.localize.transform('Löschen');
	}
}
