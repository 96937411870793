import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { PBtnThemeEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { FaIconComponent } from '@plano/shared/core/component/fa-icon/fa-icon.component';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { NonNullAndNonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { PFormControlComponentChildInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-dropdown-item',
	templateUrl: './p-dropdown-item.component.html',
	styleUrls: ['./p-dropdown-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PDropdownItemComponent extends AttributeInfoBaseComponentDirective
	implements PComponentInterface, PFormControlComponentChildInterface, AfterContentInit {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public value : PFormControlComponentChildInterface['value'];

	/**
	 * prepended Item of the the dropdown item, can be either an icon or a template ref
	 */
	@Input() public prependedItem : PlanoFaIconPoolValues | TemplateRef<unknown> | null = null;

	/**
	 * Custom icon width, used to align with other items
	 */
	@Input() public customIconWidth : number | null = null;

	/**
	 * @deprecated use
	 * <p-dropdown-item>Hello</p-dropdown-item>
	 * instead of
	 * <p-dropdown-item label="Hello"></p-dropdown-item>
	 */
	@Input() public label : PFormControlComponentChildInterface['label'] = '';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public description : PFormControlComponentChildInterface['description'] = '';

	/** Should this be visualized as the selected dropdown item? */
	@Input() public active : PFormControlComponentChildInterface['active'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('disabled') private _disabled : boolean = false;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick : PFormControlComponentChildInterface['onClick'] = new EventEmitter();

	/**
	 * Classes to be added
	 */
	@Input() public additionalClasses : string | null = null;

	/**
	 * Link to be used as routerLink in the anchor tag of this p-dropdown-item
	 */
	@Input() public link : string | null = null;

	/**
	 * Same as angular router fragment
	 */
	@Input() public linkFragment ?: string ;

	/**
	 * Same as angular queryParams
	 */
	@Input() public linkQueryParams : Params | null = null;

	/**
	 * Should this dropdown item have a separator on top?
	 */
	@Input() public hasSeparator : boolean = false;

	/**
	 * Should the icon be hidden?
	 */
	@Input() public hideIcon : boolean = false;

	/**
	 * This id is used to find tabs by url param
	 */
	@Input() public urlName : string | null = null;

	/**
	 * The bootstrap button style for this checkbox
	 */
	@Input() public theme : ExtractFromUnion<'danger', PThemeEnum> | PBtnThemeEnum.OUTLINE_DANGER | null = null;

	/**
	 * Check if the dropdown item should be aligned left or right inside the dropdown
	 */
	@Input('isAlignedLeft') public _isAlignedLeft : boolean = false;

	/**
	 * In case the dropdown item has a link, should it open on a new tab?
	 */
	@Input() public openInNewTab : boolean = false;

	/**
	 * The variable to be passed as the implicit context of the dropdownItemTemplate, usually this will be the value that is
	 * passed in the dropdown item, however sometimes we may want to pass a different context, for example
	 * when the dropdown item is a link instead of an option
	 */
	@Input() public dropdownItemTemplateContext : NonNullAndNonUndefined<unknown> | null = null;

	@ViewChild('innerTemplate') public innerTemplate ?: TemplateRef<ElementRef<HTMLElement>>;

	constructor(
		public el : ElementRef<HTMLElement>,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override console : LogService,
	) {
		super(true, changeDetectorRef, console);
	}

	/**
	 * @deprecated
	 * used only to fulfill requirements from PFormControlComponentChildInterface
	 * Use prependedItem instead
	 */
	public icon : FaIconComponent['icon'] | null = null;

	protected override attributeInfoRequired = false;

	/**
	 * Is this dropdown-item disabled?
	 */
	public get disabled() : boolean {
		if (this.isLoading) return true;
		return this._disabled;
	}

	/** get description if available and string */
	public get descriptionString() : string | null {
		if (this.description === null) return null;
		if (typeof this.description !== 'string') return null;
		return this.description;
	}

	/** get description if available and type templateRef */
	public get descriptionTemplateRef() : TemplateRef<unknown> | null {
		if (this.description === null) return null;
		if (typeof this.description === 'string') return null;
		return this.description;
	}

	/**
	 * Should the item be aligned left or right?
	 * By default links will be aligned right and buttons will be aligned left.
	 */
	public get isAlignedLeft() : boolean {
		if (this._isAlignedLeft) return this._isAlignedLeft;
		if (this.link) return false;
		return true;
	}

	/**
	 * Does this dropdown item have a prepend icon?
	 */
	public get hasPrependedIcon() : boolean {
		return !!this.prependedItem && !(this.prependedItem instanceof TemplateRef);
	}

}
