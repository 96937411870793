import { Injectable } from '@angular/core';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { MeService } from '@plano/shared/api';
import { DateTime } from '@plano/shared/api/base/generated-types.ag';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PExportService {
	constructor(
		private pMoment : PMomentService,
		private localize : LocalizePipe,
		private meService : MeService,
	) {
	}

	/**
	 * Get Date in a format that can be used for a file-name
	 */
	public getFormattedDate(date : number) : string {
		const day = this.pMoment.m(date).get('date');
		const month = this.pMoment.m(date).get('month') + 1;
		const year = this.pMoment.m(date).get('year');

		return this.localize.transform({
			sourceString: '${day}_${month}_${year}',
			params: {
				day: day.toString().padStart(2, '0'),
				month: month.toString().padStart(2, '0'),
				year: year.toString(),
			},
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getFileName(name : string, start : DateTime | null = null, end : DateTime | null = null) : string {
		const company = this.meService.data.locationName;
		let result = '';
		result += `${company}_`;
		result += `${name}`;

		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		if (start || end) {
			if (!(start && end))
				throw new Error('Start/end should be both provided or not at all.');

			result += `_${this.getFormattedDate(start)}`;
			result += `_${this.localize.transform('bis')}_`;
			result += `${this.getFormattedDate(end)}`;
		}

		return result;
	}

}
