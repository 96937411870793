import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { AnchorLinkDirective } from './p-anchor.directive';

/**
 * Directive to deal with pAnchorLinks inside p-collapsible,
 * this will ensure that the anchor button sticks with the button
 * while scrolling.
 */
@Directive({
	selector: 'p-collapsible[pAnchorLink]',
})
export class AnchorLinkAtCollapsibleDirective extends AnchorLinkDirective implements OnDestroy, AfterViewInit {

	private buttonInsideCollapsible : HTMLButtonElement | null = null;

	protected override appendAnchorLinkButton() : void {
		const htmlElement = this.elementRef.nativeElement;
		this.buttonInsideCollapsible = htmlElement.querySelector('button');
		if (!this.buttonInsideCollapsible) {
			// We had a case where the button was not found, because the PCollapsibleModule was not imported.
			// Usually we would get an error like this: 'p-collapsable is not a known element'
			// But in this case we got no error, because the directive’s selector fulfilled it.
			// So we throw an error here to make it easier to debug.
			throw new Error('Could not find button inside collapsible. Forgot to import PCollapsibleModule?');
		}
		this.buttonInsideCollapsible.appendChild(this.anchorLinkButton);
	}

	public override ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
    	return super.ngAfterViewInit();
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
    	this.buttonInsideCollapsible?.removeChild(this.anchorLinkButton);
    	return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}
}
