<ng-template [ngIf]="showDot" [ngIfElse]="showDefaultIcon">
	<svg width="16px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<defs></defs>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g fill-rule="nonzero">
				<path
					[class.text-white]="backgroundColor !== PBackgroundColorEnum.WHITE"
					fill="currentColor"
					d="M17,1.0625 L17,12.3958333 C17,12.6909722 16.9262153,13.015625 16.7786458,13.3697917 C16.6310764,13.7239583 16.4539931,14.0043403 16.2473958,14.2109375 L14.2109375,16.2473958 C14.0043403,16.4539931 13.7239583,16.6310764 13.3697917,16.7786458 C13.015625,16.9262153 12.6909722,17 12.3958333,17 L1.0625,17 C0.76736111,17 0.51649306,16.8967014 0.30989583,16.6901042 C0.10329861,16.4835069 0,16.2326389 0,15.9375 L0,1.0625 C0,0.76736111 0.10329861,0.51649306 0.30989583,0.30989583 C0.51649306,0.10329861 0.76736111,0 1.0625,0 L15.9375,0 C16.2326389,0 16.4835069,0.10329861 16.6901042,0.30989583 C16.8967014,0.51649306 17,0.76736111 17,1.0625 Z M12.3958333,11.3333333 L15.5833333,11.3333333 L15.5833333,1.41666667 L1.41666667,1.41666667 L1.41666667,15.5833333 L11.3333333,15.5833333 L11.3333333,12.3958333 C11.3333333,12.1006944 11.4366319,11.8498264 11.6432292,11.6432292 C11.8498264,11.4366319 12.1006944,11.3333333 12.3958333,11.3333333 Z M15.4947917,12.75 L12.75,12.75 L12.75,15.4947917 C12.9639757,15.4210069 13.1152344,15.3398438 13.203776,15.2513021 L15.2513021,13.203776 C15.3398438,13.1152344 15.4210069,12.9639757 15.4947917,12.75 Z M4,6 L4,5 L7,5 L7,6 L4,6 Z M4,9 L4,8 L7,8 L7,9 L4,9 Z M4,12 L4,11 L9,11 L9,12 L4,12 Z"
				></path>
				<circle
					[class.text-dark]="backgroundColor === enums.PThemeEnum.PRIMARY"
					[class.text-primary]="backgroundColor === enums.PThemeEnum.DARK || backgroundColor === PBackgroundColorEnum.WHITE"

					fill="currentColor"
					cx="11.105491" cy="6.63937461" r="3.14999986"
				></circle>
			</g>
		</g>
	</svg>
</ng-template>
<ng-template #showDefaultIcon>
	<span
		[class.text-white]="backgroundColor !== PBackgroundColorEnum.WHITE"
	>
		<svg width="16px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
			<desc>Created with Sketch.</desc>
			<defs></defs>
			<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="Kommentar" fill="currentColor" fill-rule="nonzero">
					<path d="M17,1.0625 L17,12.3958333 C17,12.6909722 16.9262153,13.015625 16.7786458,13.3697917 C16.6310764,13.7239583 16.4539931,14.0043403 16.2473958,14.2109375 L14.2109375,16.2473958 C14.0043403,16.4539931 13.7239583,16.6310764 13.3697917,16.7786458 C13.015625,16.9262153 12.6909722,17 12.3958333,17 L1.0625,17 C0.76736111,17 0.51649306,16.8967014 0.30989583,16.6901042 C0.10329861,16.4835069 0,16.2326389 0,15.9375 L0,1.0625 C0,0.76736111 0.10329861,0.51649306 0.30989583,0.30989583 C0.51649306,0.10329861 0.76736111,0 1.0625,0 L15.9375,0 C16.2326389,0 16.4835069,0.10329861 16.6901042,0.30989583 C16.8967014,0.51649306 17,0.76736111 17,1.0625 Z M12.3958333,11.3333333 L15.5833333,11.3333333 L15.5833333,1.41666667 L1.41666667,1.41666667 L1.41666667,15.5833333 L11.3333333,15.5833333 L11.3333333,12.3958333 C11.3333333,12.1006944 11.4366319,11.8498264 11.6432292,11.6432292 C11.8498264,11.4366319 12.1006944,11.3333333 12.3958333,11.3333333 Z M15.4947917,12.75 L12.75,12.75 L12.75,15.4947917 C12.9639757,15.4210069 13.1152344,15.3398438 13.203776,15.2513021 L15.2513021,13.203776 C15.3398438,13.1152344 15.4210069,12.9639757 15.4947917,12.75 Z M4,6 L4,5 L13.4936252,5 L13.4936252,6 L4,6 Z M4,9 L4,8 L13.4936252,8 L13.4936252,9 L4,9 Z M4,12 L4,11 L9,11 L9,12 L4,12 Z"></path>
				</g>
			</g>
		</svg>
	</span>
</ng-template>
