import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { ShiftAndShiftModelFormTabs } from '@plano/client/shared/component/p-shift-and-shiftmodel-form/p-shift-and-shiftmodel-form.component';
import { ShiftCommentModalDirective } from '@plano/client/shared/p-shift-module/p-shift-comments/shift-comment-modal.directive';
import { SchedulingApiMembers, SchedulingApiShift, SchedulingApiTodaysShiftDescription } from '@plano/shared/api';
import { DateTime } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { ModalContentComponent, ModalContentComponentCloseReason } from '@plano/shared/core/p-modal/modal-content-component.interface';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-shift-comment-modal-content',
	templateUrl: './shift-comment-modal-content.component.html',
	styleUrls: ['./shift-comment-modal-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftCommentModalContentComponent implements ModalContentComponent<ModalContentComponentCloseReason> {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift : ShiftCommentModalDirective['shift'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public userCanWrite : boolean | null = null;

	constructor(
		private textToHtmlService : TextToHtmlService,
		private modalService : ModalService,
	) {
	}

	public CONFIG = Config;

	public enums = enumsObject;

	private modalRef ! : ModalRef<ModalContentComponentCloseReason>;

	/** @see ModalContentComponent#initModalContentComponent */
	public initModalContentComponent(modalRef : ModalRef<ModalContentComponentCloseReason>, shift : ShiftCommentModalDirective['shift'], userCanWrite : boolean) : void {
		this.modalRef = modalRef;
		this.shift = shift;
		this.userCanWrite = userCanWrite;
	}

	private textToHtml(text : string, doNotCut ?: boolean) : string {
		return this.textToHtmlService.textToHtml(text, doNotCut, doNotCut);
	}

	/**
	 * Get content for blockquote innerHTML.
	 */
	public get commentAsBlockquote() : string {
		if (this.shift!.description) return this.textToHtml(this.shift!.description, false);
		return '…';
	}

	/** @see ModalContentComponent#dismissModal */
	public dismissModal() : void {
		this.modalRef.dismiss();
	}

	/** @see ModalContentComponent#closeModal */
	public closeModal() : void {
		const success = () : void => {
			if (!this.userCanWrite) return;
			this.modalRef.close(ModalContentComponentCloseReason.ADD);
		};

		success();
	}

	/**
	 * Remove this item and close modal
	 */
	public onRemoveItem(removeDescriptionModalContent : TemplateRef<PModalTemplateDirective>) : void {
		void this.modalService.openModal(removeDescriptionModalContent, {
			theme: enumsObject.PThemeEnum.DANGER,

			// TODO: PLANO-171520 re-add the animation
			animation: false,
		}).result.then(value => {
			if (value.modalResult === 'success') {
				this.modalRef.close(ModalContentComponentCloseReason.REMOVE);
			}
		});
	}

	/**
	 * Get start - no matter if SchedulingApiShift or SchedulingApiTodaysShiftDescription is provided
	 */
	public get start() : DateTime | null {
		if (!this.shift) return null;
		if (this.shift instanceof SchedulingApiShift) {
			if (!this.shift.attributeInfoStart.isAvailable) return null;
			return this.shift.start;
		}
		if (this.shift instanceof SchedulingApiTodaysShiftDescription) {
			if (!this.shift.attributeInfoShiftStart.isAvailable) return null;
			return this.shift.shiftStart;
		}
		return null;
	}

	/**
	 * Get end - no matter if SchedulingApiShift or SchedulingApiTodaysShiftDescription is provided
	 */
	public get end() : DateTime | null {
		if (!this.shift) return null;
		if (this.shift instanceof SchedulingApiShift) return this.shift.end;
		if (this.shift instanceof SchedulingApiTodaysShiftDescription) return this.shift.shiftEnd;
		return null;
	}

	/**
	 * Get assignedMembers if SchedulingApiShift is provided
	 */
	public get assignedMembers() : SchedulingApiMembers | null {
		if (this.shift instanceof SchedulingApiTodaysShiftDescription) return this.shift.assignedMembers;
		if (this.shift instanceof SchedulingApiShift) return this.shift.assignedMembers;
		return null;
	}

	/**
	 * Navigate to the comment editing area of this shifts form
	 */
	public navToShiftFormLink() : string {
		return `/client/shift/${this.shift!.id.toUrl()}/${ShiftAndShiftModelFormTabs.basissettings}`;
	}
}
