import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { AnchorLinkDirective } from './p-anchor.directive';

@Directive({
	selector: 'span[pAnchorLink]',
	exportAs: 'pAnchorLinkAtSpan',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AnchorLinkAtSpanDirective extends AnchorLinkDirective implements OnDestroy, AfterViewInit {

	/**
	 * anchorPos is set to center right by default when used with a span
	 */
	@Input() protected override anchorPos : 'center-right' | 'bottom-right' | 'top-right' = 'center-right';

	public override ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		return super.ngAfterViewInit();
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		return super.ngOnDestroy();
	}
}
