// cSpell:ignore launchdarkly
import { Injectable } from '@angular/core';
import { PSeverity } from '@plano/global-error-handler/error-utils';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { errorUtils } from '@plano/shared/core/utils/error-utils';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { AvailableLaunchDarklyFlags } from './launch-darkly.types';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type LDDeskCardValue = {
	cardId : number | null,
	content ?: {

		// NOTE: The user groups are exclusive, so no admin will get content which is for employees and vice-versa
		userGroups ?: ('ADMINS_AND_MANAGERS' | 'EMPLOYEES') [],
		de : string,
		en : string
	} [],
};

/**
 * Everything we do with LaunchDarkly should go here.
 */
@Injectable({ providedIn: 'root' })
export class PLaunchDarklyService {
	constructor(
		private console : LogService,
		private pSentryService : PSentryService,
	) {
	}

	private lDClient : LDClient.LDClient | null = null;

	/**
	 * Init LaunchDarkly
	 */
	public async init(clientId : string | null = null) : Promise<void> {
		// For all anonymous users we assign the key 0. This is important so not each of them
		// creates a new user in launch-darkly so we remain in the limits of the billing plan.
		const user : LDClient.LDSingleKindContext = {
			kind: 'user',

			key: clientId ?? '0',
		};

		this.lDClient = LDClient.initialize(Config.LAUNCH_DARKLY_CLIENT_ID, user);
		try {
			await this.lDClient.waitForInitialization();

			// initialization succeeded, flag values are now available

		} catch (error) {
			this.console.warn(error);

			const typedError = error as Error;
			const isAdblockerError = (
				errorUtils.isTypeError(typedError) && (
					typedError.message.includes('network error') ||
					typedError.name.includes('LaunchDarklyFlagFetchError')
				)
			);

			// Dont track this error if we assume it is caused by an adblocker/tracking-blocker
			if (isAdblockerError) return;

			await this.pSentryService.captureMessage(
				'Launch darkly could not be initialized.',
				{
					level: PSeverity.INFO,
					extra: {
						error,
					},
				},
			);
		}
	}

	public get<T = undefined>(
		_id : 'desk-card',
		_defaultValue ?: T,
	) : LDDeskCardValue | T;
	public get<T = undefined>(
		_id : 'block-sepa-payments' | 'jobs-button',
		_defaultValue ?: T,
	) : boolean | T;

	/**
	 * Get the value from LaunchDarkly.
	 * @returns The flags value. undefined if LaunchDarkly is not available and no default value is defined
	 */
	public get<T>(
		id : AvailableLaunchDarklyFlags,
		defaultValue ?: T,
	) : T | null {
		if (this.lDClient === null) return null;
		return this.lDClient.variation(id, defaultValue);
	}
}
