import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PContainerDirective } from '@plano/client/shared/page/container/p-container.directive';
import { CoreComponentsModule } from '@plano/shared/core/component/core-components.module';
import { PFaIconModule } from '@plano/shared/core/component/fa-icon/fa-icon.module';
import { PGridModule } from '@plano/shared/core/component/grid/grid.module';
import { ScrollShadowBoxModule } from '@plano/shared/core/component/scroll-shadow-box/scroll-shadow-box.module';
import { PageComponent } from './page.component';
import { PSectionComponent } from './section/section.component';

@NgModule({
	imports: [
		CommonModule,
		CoreComponentsModule,
		PFaIconModule,
		PGridModule,
		ScrollShadowBoxModule,
	],
	declarations: [
		PageComponent,
		PContainerDirective,
		PSectionComponent,
	],
	exports: [
		PageComponent,
		PContainerDirective,
		PSectionComponent,
	],
})
export class PageModule { }
