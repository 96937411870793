import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { ListSortDirection } from '@plano/client/shared/p-lists/list-headline-item/list-headline-item.component';
import { ApiListWrapper, PShiftExchangeConceptService, SchedulingApiService, SchedulingApiShiftExchange, SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeSwappedShiftRef, SchedulingApiShiftExchanges } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';
import { takeCareOfCaseWhereDateIsNotPreciseReverse } from '@plano/shared/core/utils/sorting-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PShiftExchangeListService } from './p-shift-exchange-list.service';

@Component({
	selector: 'p-shift-exchange-list[shiftExchanges]',
	templateUrl: './p-shift-exchange-list.component.html',
	styleUrls: ['./p-shift-exchange-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,

	// animations: [SLIDE_ON_NGIF_TRIGGER]
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeListComponent implements OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public addItemIds : boolean | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shiftExchanges ! : SchedulingApiShiftExchanges;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public calendarBtnClick : EventEmitter<SchedulingApiShiftExchangeShiftRef | SchedulingApiShiftExchangeSwappedShiftRef> =
		new EventEmitter<SchedulingApiShiftExchangeShiftRef | SchedulingApiShiftExchangeSwappedShiftRef>();

	/**
	 * Should this component show all items or only those that are marked as »for desk«?
	 */
	@Input() public showOnlyItemsForDesk : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showDetails : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideAddBtn : boolean = false;

	constructor(
		public api : SchedulingApiService,
		private router : Router,
		private highlightService : HighlightService,
		private console : LogService,
		private pShiftExchangeConceptService : PShiftExchangeConceptService,
		public pShiftExchangeListService : PShiftExchangeListService,
	) {
		this.setRouterListener();
	}

	public ListSortDirection = ListSortDirection;
	public enums = enumsObject;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public isMuted(shiftExchange : SchedulingApiShiftExchange) : boolean | undefined {
		// if (shiftExchange.isClosed) return true;

		if (!shiftExchange.indisposedMember) return undefined;
		if (!shiftExchange.shiftModel) return undefined;

		if (this.highlightService.isMuted(shiftExchange.indisposedMember)) return true;
		if (this.highlightService.isMuted(shiftExchange.shiftModel)) return true;

		return false;
	}

	/**
	 * Listen to NavigationEnd to navigate somewhere if no url params are provided or load data if params are provided.
	 */
	private setRouterListener() : void {
		// eslint-disable-next-line rxjs/no-ignored-subscription -- Remove this before you work here.
		this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(event) => {
				if (!(event instanceof NavigationEnd)) return;

				this.highlightService.setHighlighted(null);
			},
			(error : unknown) => {
				this.console.error(error);
			},
		);
	}

	private ngUnsubscribe : Subject<void> = new Subject<void>();

	public ngOnDestroy() : void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	private get sortedByFns() : Parameters<SchedulingApiShiftExchanges['sortedBy']>[0] {
		switch (this.pShiftExchangeListService.key) {
			case 'shiftRefs' :
				return [
					(item : SchedulingApiShiftExchange) => item.shiftRefs.earliestStart ?? Number.POSITIVE_INFINITY,
					(item : SchedulingApiShiftExchange) => item.shiftRefs.earliestEnd ?? Number.POSITIVE_INFINITY,
					(item : SchedulingApiShiftExchange) => item.shiftRefs.latestStartDateTime ?? Number.POSITIVE_INFINITY,
					(item : SchedulingApiShiftExchange) => item.shiftRefs.latestEndDateTime ?? Number.POSITIVE_INFINITY,
					takeCareOfCaseWhereDateIsNotPreciseReverse,
				];
			case 'lastUpdate' :
				return [
					(item : SchedulingApiShiftExchange) => item.lastUpdate,
					takeCareOfCaseWhereDateIsNotPreciseReverse,
				];
			default:
				return () => true;
		}
	}

	/**
	 * All shiftExchanges that should be visible in this list.
	 */
	public get shiftExchangesForList() : ApiListWrapper<SchedulingApiShiftExchange> {
		if (!this.shiftExchanges.length) return new SchedulingApiShiftExchanges(null, null);

		if (this.pShiftExchangeListService.key === 'state') {
			return this.shiftExchanges
				.filterBy((shiftExchange) => {
					if (this.showOnlyItemsForDesk && !shiftExchange.showOnDesk) return false;
					return true;
				})
				.sortedBy((item : SchedulingApiShiftExchange) => {
					return this.pShiftExchangeConceptService.getStateStyle(item);
				}, {inPlace: true})
				.sortedBy(
					(item : SchedulingApiShiftExchange) => {
						return this.pShiftExchangeConceptService.getStateText(item);
					},
					{removeDestroyedItems: true, inPlace: true, reverse: this.pShiftExchangeListService.reverse ?? undefined},
				);
		}

		return this.shiftExchanges.filterBy((shiftExchange) => {
			if (this.showOnlyItemsForDesk && !shiftExchange.showOnDesk) return false;
			return true;
		}).sortedBy(this.sortedByFns, {removeDestroyedItems: true, inPlace: true, reverse: this.pShiftExchangeListService.reverse ?? undefined});
	}

	/**
	 * Show the related shift(s) in a calendar
	 */
	public onCalendarBtnClick(item : SchedulingApiShiftExchangeShiftRef | SchedulingApiShiftExchangeSwappedShiftRef) : void {
		this.calendarBtnClick.emit(item);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasCalendarBtn() : boolean {
		return this.calendarBtnClick.observers.length > 0;
	}

	// /**
	//  * Navigate to the page with the list of shift-exchanges
	//  */
	//
	// public navToShiftExchanges() : void {
	// 	this.api.deselectAllSelections();
	// 	this.api.deselectAllSelections();
	// 	this.router.navigate(['client/shift-exchanges']);
	// }

	/**
	 * Open Modal with for for a new assignment process
	 */
	public pathToCreateNewShiftExchange() : string {
		return '/client/shift-exchange/create';
	}

}
