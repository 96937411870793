<h4
	*ngIf="label || description"
	[class.text-danger]="childArray.invalid"
><span>{{label}}</span> <p-icon-tooltip *ngIf="description" [class.ml-2]="Config.IS_MOBILE" i18n>{{description}}</p-icon-tooltip></h4>

<ng-content></ng-content>
<!-- <p-no-items *ngIf="!childArray.length"></p-no-items> -->

<!-- Normally you would expect a p-validation-hint here. But on lists we want it a bit more prominent -->
<p-alert
	*ngFor="let error of visibleErrors"
	[theme]="enums.PThemeEnum.DANGER"
	i18n
><span [innerHTML]="validationHintService.getErrorText(error.value, null)"></span></p-alert>

<p-button
	*ngIf="attributeInfo.canSet && !hideAddBtn"
	(triggerClick)="addItem()"
	[theme]="PBtnThemeEnum.OUTLINE_PRIMARY"
	[disabled]="addButtonDisabled"
	[cannotSetHint]="addBtnCannotSetHint"
	i18n
><fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon>&nbsp;<ng-container *ngIf="addBtnText; else defaultBtnText">{{ addBtnText }}</ng-container><ng-template #defaultBtnText>Hinzufügen</ng-template></p-button>
