/**
 * All themes that are available in the bootstrap framework
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const PThemePool = {
	/** Use for call-to-action, highlighting, etc. and everything corporate-identity-related as it represents »Plano’s orange« */
	PRIMARY: 'primary',

	/** Use for successful/positive events/options/… */
	SUCCESS: 'success',

	/** Use for optional/additional events/options/… */
	INFO: 'info',

	/** Use for unexpected but non-critical events/options/… */
	WARNING: 'warning',

	/** Use only for critical/unexpected events/options/… */
	DANGER: 'danger',

	SECONDARY: 'secondary',
	LIGHT: 'light',
	DARK: 'dark',
	PURPLE: 'purple',
} as const;

/**
 * Type of all themes that are available in the bootstrap framework
 * If you want to limit the possible values, do this: `ExtractFromUnion<'danger' | 'primary', PThemeEnum>`
 */
export type PThemeEnum = typeof PThemePool[keyof typeof PThemePool];

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PTextColorEnum {
	MUTED = 'muted',
	WHITE = 'white',
}
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PTextColor = PTextColorEnum | PThemeEnum;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PBackgroundColorEnum {
	WHITE = 'white',
}
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PBackgroundColor = PBackgroundColorEnum | PThemeEnum;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PBtnThemeEnum {
	OUTLINE_LIGHT = 'outline-light',
	OUTLINE_SECONDARY = 'outline-secondary',
	OUTLINE_PRIMARY = 'outline-primary',
	OUTLINE_DANGER = 'outline-danger',
	OUTLINE_DARK = 'outline-dark',
}
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PBtnTheme = PBtnThemeEnum | PThemeEnum;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PAlertThemeEnum {
	PLAIN = 'plain',
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PAlertTheme = PAlertThemeEnum | PThemeEnum;

/**
 * All themes that are available in the bootstrap framework
 */
export enum BootstrapRounded {

	/** only on the left side */
	LEFT = 'left',

	/** only on the right side */
	RIGHT = 'right',

	/** no rounding */
	NONE = 'none',
}

/**
 * Different available amount of padding
 */
export type BootstrapPaddings = 1 | 2 | 3 | 4 | 5;

/**
 * Very common sizes in bootstrap
 */
export enum BootstrapSizePool {

	// xxs,

	XS = 'xs',

	/** Primary */
	SM = 'sm',

	MD = 'md',

	/** Something normal */
	LG = 'lg',

	/** Something normal */
	XL = 'xl',

	/** Something big */
	XXL = 'xxl',
}

/**
 * Type of all themes that are available in the bootstrap framework
 */
export type BootstrapSize = typeof BootstrapSizePool[keyof typeof BootstrapSizePool];

/**
 * @deprecated YOU SHOULD PREFER PThemeEnum
 * All themes that are available in the bootstrap framework
 */
export enum BootstrapStyles {

	/** Primary */
	PRIMARY = 'primary',

	/** Something normal */
	SECONDARY = 'secondary',

	/** Light */
	LIGHT = 'light',

	/** Dark */
	DARK = 'dark',

	/** Danger */
	DANGER = 'danger',
}

/**
 * The possible width of a col in bootstrap.
 * Can be used for classes like class="col-{{bootstrapWidth}}"
 */
export type BootstrapGridWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
