import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DateInfo, FormattedDateTimePipe } from '@plano/client/shared/formatted-date-time.pipe';
import { PSimpleChanges, RightsService, SchedulingApiAbsence } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-absence-list-item[absence][min][max]',
	templateUrl: './absence-list-item.component.html',
	styleUrls: ['./absence-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AbsenceListItemComponent implements OnChanges {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absence ! : SchedulingApiAbsence;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public min ! : number;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public max ! : number;

	constructor(
		private formattedDateTimePipe : FormattedDateTimePipe,
		public rightsService : RightsService,
	) {}

	public readonly CONFIG = Config;

	public ngOnChanges(changes : PSimpleChanges<AbsenceListItemComponent>) : void {
		if (changes.absence) {
			this._dateInfo = this.getDateInfo();
		}
	}

	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	private _dateInfo : DateInfo | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public getDateInfo() : DateInfo {
		return this.formattedDateTimePipe.getFormattedDateInfo(
			this.absence.time.start,
			this.absence.time.end,
			true,
			this.absence.isFullDay,
			!Config.IS_MOBILE,
		);
	}

	/**
	 * Id for this absence
	 */
	public get absenceId() : string {
		return `${this.absence.id.toString()}`;
	}

	/**
	 * Get the formatted date info between the given dates
	 */
	public get dateInfo() : DateInfo {
		if (!this._dateInfo) {
			this._dateInfo = this.getDateInfo();
		}
		return this._dateInfo;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get absenceIsOutsideRange() : boolean {
		if (this.absence.isFullDay && this.max < this.absence.time.end) return true;
		if (!this.absence.isFullDay && this.max <= this.absence.time.end) return true;
		return false;
	}

	/**
	 * Shorthand for the title
	 */
	public get title() : PDictionarySourceString {
		assumeNonNull(this.absence.title);
		return this.absence.title;
	}
}
