import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { AbsenceService } from '@plano/client/shared/absence.service';
import { AuthenticatedApiRootBase, SchedulingApiAbsenceType, SchedulingApiMember, SchedulingApiMembers, SchedulingApiService, SchedulingApiShift } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Data } from '@plano/shared/core/data/data';
import { MemberBadgeComponent } from './member-badge/member-badge.component';

@Component({
	selector: 'p-member-badges[members]',
	templateUrl: './member-badges.component.html',
	styleUrls: ['./member-badges.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PMemberBadgesComponent {
	@HostBinding('class.d-flex')
	@HostBinding('class.badges-stack') protected _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public members ! : SchedulingApiMembers;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public emptyMemberSlots : number | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftStart : SchedulingApiShift['start'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftEnd : SchedulingApiShift['end'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftId : SchedulingApiShift['id'] | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private myId : AuthenticatedApiRootBase['id'] | null = null;

	constructor(
		private absenceService : AbsenceService,
		private api : SchedulingApiService,
	) {}

	public SchedulingApiAbsenceType = SchedulingApiAbsenceType;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public absenceType(memberId : Id) : MemberBadgeComponent['absenceType'] {
		if (this.members.get(memberId)?.trashed) return 'trashed';
		if (this.shiftStart === null) return null;
		if (this.shiftEnd === null) return null;
		if (this.shiftId === null) return null;
		const type = this.absenceService.absenceType(memberId, {
			start: this.shiftStart,
			end: this.shiftEnd,
			id: this.shiftId,
		});
		return type === SchedulingApiAbsenceType.OTHER ? null : type ?? null;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public isMe(member : SchedulingApiMember) : boolean | undefined {
		if (this.myId === null) return undefined;
		if (member.isNewItem()) return undefined;
		return this.myId.equals(member.id);
	}

	private _membersForList = new Data<readonly SchedulingApiMember []>(this.api);

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get membersForList() : readonly SchedulingApiMember[] {
		return this._membersForList.get(() => {
			return this.members.sortedBy([
				item => (this.isMe(item) ? 0 : 1),
				item => !item.trashed,
				...defaultSortingForMembers,
			], {removeDestroyedItems: true}).iterable();
		});
	}
}
