import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SchedulingApiShiftModelCancellationPolicyFeePeriod } from '@plano/shared/api';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-fee-period-time-text',
	templateUrl: './fee-period-time-text.component.html',
	styleUrls: ['./fee-period-time-text.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class FeePeriodTimeTextComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public start : SchedulingApiShiftModelCancellationPolicyFeePeriod['start'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public end : SchedulingApiShiftModelCancellationPolicyFeePeriod['end'] = null;

	public enums = enumsObject;

	/**
	 * Some identifier for the text that should be shown.
	 * The decision which text should be shown is quite complex. So we need this method.
	 * But we don’t want to translate in ts. We want the i18n feature of angular templates. Thus we needed a Id.
	 */
	public get textId() : 'zeroToNull' | 'nullToY' | 'XToY' | 'XToNull' | null {
		if (this.start === 0 && this.end === null) return 'zeroToNull';
		if ((this.start === null || this.start >= 1) && this.end === null) return 'XToNull';
		if (this.start === null && this.end !== null) return 'nullToY';
		if (this.start !== null && this.end !== null) return 'XToY';

		// throw new Error('could not calculate text id');
		return null;
	}
}
