<ng-container *ngTemplateOutlet="((Config.IS_MOBILE && !hasNotSideTheme) || (hasNotSideTheme && !this.activeTab)) ? null : tabOptions; context:{$implicit: !activeTab}">
</ng-container>
<div class="p-0 tabs-body" [class.card-body]="theme !== PTabsTheme.CLEAN" [class.side-theme]="!hasNotSideTheme" #tabsBody>
	<p-nav-back-headline
		[class.shadow-sm]="!activeTab"
		[class.lift-tabs]="!activeTab"
		*ngIf="showNavBackHeadlineBtn"
		[label]="activeTab ? activeTab.label : (tabsHeadline! | localize)"/>
	<div
		*ngIf="isLoading || findingActiveTab; else contentTemplate"
		class="flex-grow-1 d-flex align-items-center justify-content-center"
	>
		<p-spinner [size]="enums.BootstrapSize.LG" class="p-3"></p-spinner>
	</div>
	<ng-template #contentTemplate>
		<p-alert
			class="m-4"
			[theme]="enums.PThemeEnum.DANGER"
			*ngIf="!!activeTab && activeTab.show === false"
			i18n
		>Dir fehlen die Rechte für »{{activeTab.label}}«. Wende dich bitte an deine Personalleitung oder Administrator*in.</p-alert>
		<div
			*ngIf="!this.activeTab && hasNotSideTheme"
			class="flex-grow-1 d-flex align-items-center justify-content-center"
		>
			<p-spinner [size]="enums.BootstrapSize.LG" class="p-3"></p-spinner>
		</div>
		<div class="content-wrapper" [hidden]="!this.activeTab || !!activeTab && activeTab.show === false">
			<ng-content></ng-content>
		</div>
		<ng-container *ngIf="!activeTab && !hasNotSideTheme">
			<ng-container *ngTemplateOutlet="tabOptions; context:{$implicit: !activeTab}"></ng-container>
		</ng-container>
	</ng-template>
</div>

<ng-template #tabOptions>
	<scroll-shadow-box [noShadows]="true" *ngIf="Config.IS_MOBILE && !hasNotSideTheme; else tabOptionsDivElementTemplate" class="flex-grow-1 d-flex h-100 flex-column position-relative">
		<ng-container *ngTemplateOutlet="tabOptionsDivElementTemplate"></ng-container>
	</scroll-shadow-box>

	<ng-template #tabOptionsDivElementTemplate>
		<ng-template #innerTabOptions>
		<div
			*ngIf="!(isLoading || findingActiveTab)"
			#topAnchor
			(mousedown)="handleDownOnTabs($event, topAnchor)"
			(touchstart)="handleDownOnTabs($event, topAnchor)"
			(mouseleave)="isDownOnWrapper = false"
			(mouseup)="isDownOnWrapper = false"
			(touchend)="isDownOnWrapper = false"
			(wheel)="handleHorizontalWheelScroll($event, topAnchor)"
			(mousemove)="handleMoveOnTabs($event, topAnchor)"
			(touchmove)="handleMoveOnTabs($event, topAnchor)"
			class="d-flex flex-column overflow-hidden position-relative border-bottom-w-0 nav-tabs"
			[class.not-mobile-tabs]="!Config.IS_MOBILE"
			[class.overflow-x-hidden]="containerTabs"
			[class.sticky-clean]="theme === PTabsTheme.CLEAN"
			[class.side]="!hasNotSideTheme && !isSelectingTabFromSideThemedTabs"
			[class.h-100]="isSelectingTabFromSideThemedTabs"
			[class.bg-light-cold]="Config.IS_MOBILE && !hasNotSideTheme"
			[class.lift-tabs]="!Config.IS_MOBILE && !hasNotSideTheme"
			[class.shadow]="!hasNotSideTheme"
			[class.py-5]="!hasNotSideTheme && !isSelectingTabFromSideThemedTabs"
			[class.pt-3]="!hasNotSideTheme && isSelectingTabFromSideThemedTabs"
			[class.px-3]="!hasNotSideTheme && !isSelectingTabFromSideThemedTabs"
			[class.px-0]="size !== enums.BootstrapSize.SM && (theme === PTabsTheme.DEFAULT || theme === PTabsTheme.CLEAN)"
			[class.px-4]="containerTabs && !Config.IS_MOBILE"
			[class.clean-theme]="theme === PTabsTheme.CLEAN"
			[class.card-header]="theme === PTabsTheme.DEFAULT"
			[class.justify-content-end]="hasNotSideTheme"
			[class.p-2]="size === enums.BootstrapSize.SM && theme !== PTabsTheme.CLEAN"
			[style.min-height.px]="minHeaderTabBar"
			role="tablist"
		>
			<h5 *ngIf="tabsHeadline && !isSelectingTabFromSideThemedTabs">{{tabsHeadline | localize}}</h5>

			<ul class="nowrap flex-nowrap list-unstyled" #tabsWrap
				[class.min-w-max-content]="hasNotSideTheme"
				[class.nav]="hasNotSideTheme"
				[class.d-flex]="!hasNotSideTheme"
				[class.flex-column]="!hasNotSideTheme"
				[class.dark-mode]="darkMode"
				[class.mx-0]="!containerTabs && !Config.IS_MOBILE"
				[class.px-container]="containerTabs && !Config.IS_MOBILE"
				[class.px-0]="(theme === PTabsTheme.CLEAN || theme === PTabsTheme.DEFAULT) && !containerTabs"
				[class.container]="containerTabs && !Config.IS_MOBILE"
				[class.pt-0]="containerTabs"
				[class.mt-2]="containerTabs"
				[class.mobile-tabs-wrap]="Config.IS_MOBILE"
				[class.card-header-tabs]="theme === PTabsTheme.DEFAULT"
				[class.header-tabs]="theme !== PTabsTheme.DEFAULT"
				role="navigation"
			>
				<li class="nav-item text-center"
					#liElements
					*ngFor="let tab of visibleTabs; let i = index"
					[@flexGrow]="growListItem(tab)"
					(mouseenter)="tab.hover=true"
					(mouseleave)="tab.hover=false"
					[class.can-show-icon-only]="showIconOnlyBtns"
					[class.px-4]="isSelectingTabFromSideThemedTabs && !Config.IS_MOBILE"
					[class.px-0]="isSelectingTabFromSideThemedTabs && Config.IS_MOBILE && theme !== PTabsTheme.DEFAULT"
					[class.px-3]="!isSelectingTabFromSideThemedTabs && theme === PTabsTheme.CLEAN && Config.IS_MOBILE"
					[class.rounded-0]="isSelectingTabFromSideThemedTabs"
					[attr.aria-selected]="tab.active"
					[class.has-danger]="tab.hasDanger && !tab.active"
					role="tab"
					[attr.aria-label]="tab.label"
				>
					<a
						#tabAnchorElement
						class="navigate-inside-app"
						*ngVar="tabUrlForAnchorElement(tab) as tabUrl"
						[routerLink]="tabUrl"
						(click)="onChange.emit(tab)"
						[replaceUrl]="hasNotSideTheme ? true : false"
						(touchend)="(tabUrl && !draggedOnTabs) ? tabAnchorElement.click() : undefined"
						queryParamsHandling="merge"
						[queryParams]="{scrollToTabIfNotInView: 'true'}">
							<span
								class="clickable position-relative d-flex align-items-center inner-tab"
								[id]="tab.innerTabId ?? (tab.urlName ?? '')"
								[class.nav-link]="theme === PTabsTheme.DEFAULT"
								[class.btn]="theme === PTabsTheme.CLEAN || theme === PTabsTheme.SIDE"
								[class.btn-outline-dark]="!hasNotSideTheme"
								[class.justify-content-center]="hasNotSideTheme"
								[class.justify-content-start]="!hasNotSideTheme"
								[class.btn-menu]="!hasNotSideTheme"
								[class.border-0]="!hasNotSideTheme"

								[class.px-2]="showFilterLed(tab) || theme === PTabsTheme.DEFAULT && growListItem(tab)"
								[class.px-3]="!showFilterLed(tab) && theme === PTabsTheme.DEFAULT && !growListItem(tab)"
								[class.px-4]="!hasNotSideTheme && isSelectingTabFromSideThemedTabs && Config.IS_MOBILE"
								[class.active]="tab.active"
								[class.btn-sm]="size === enums.BootstrapSize.SM"
								[disabled]="isLoading"
								[title]="tab.label"
								(click)="tabUrl ? undefined : selectTab(tab)"
								(touchend)="tabUrl ? undefined : selectTab(tab)"
							>
								<div class="d-flex">
									<label
										class="mb-0 flex-grow-1 crop-on-overflow"
										[class.dark-mode]="darkMode"><fa-icon
											class="d-inline-block"
											*ngIf="!!tab.icon"
											[icon]="tab.icon"
											[class.mr-2]="showLabel(tab) && hasNotSideTheme"
											[class.mr-3]="showLabel(tab) && !hasNotSideTheme"/><span class="crop-on-overflow"
										*ngIf="showLabel(tab)"
									>{{ tab.label }}</span></label>
									<p-led
										*ngIf="showFilterLed(tab)"
										[class.ml-1]="!growListItem(tab)"
										[class.ml-2]="growListItem(tab)"
										class="flex-grow-0 p-0"
										[off]="!tab.hasFilter"
										[theme]="enums.PThemeEnum.PRIMARY"
										[pTooltip]="tab.filterDescription ? (tab.filterDescription | localize) : null"
									></p-led>
								</div>
								<p-badge
									*ngIf="!tab.badgeOnHeadline"
									[content]="tab.badgeContent"
									align="right"
									[size]="theme === PTabsTheme.CLEAN ? enums.BootstrapSize.SM : null"
								></p-badge>
							</span>
					</a>
				</li>
			</ul>
			<div
				*ngIf="!isSelectingTabFromSideThemedTabs && hasNotSideTheme"
				class="active-bottom-border"
				[style.width.px]="maxScrollLeft + topAnchor.offsetWidth"
				#activeTabBottomBorder>
			</div>
		</div>
		</ng-template>
		<ng-template #mobileShadows>
			<div class="shadow-left" #shadowLeft></div>
			<div class="shadow-right" #shadowRight></div>
		</ng-template>
		<ng-template #desktopChevrons>
			<fa-icon
				role="button"
				title="Tabs links anzeigen" i18n-title
				tabindex="0"
				#chevronLeft
				(click)="handlePrevTabClicked()"
				class="tabs-navigator"
				[icon]="enums.PlanoFaIconPool.PREV"
			/>
			<fa-icon
				role="button"
				title="Tabs rechts anzeigen" i18n-title
				tabindex="0"
				#chevronRight
				(click)="handleNextTabClicked()"
				class="tabs-navigator d-flex justify-content-end"
				[icon]="enums.PlanoFaIconPool.NEXT"
			/>
		</ng-template>
		<div
			*ngIf="!isSelectingTabFromSideThemedTabs; else innerTabOptions"
			class="shadows-chevrons-tabs-wrapper"
			[class.is-full-width]="isFullWidth"
			[class.sticky-clean]="theme === PTabsTheme.CLEAN">
			<div
				[style.height.px]="liElementsHeight"
				class="shadows-chevrons-wrapper o-hidden">
				<ng-container *ngTemplateOutlet="Config.IS_MOBILE ? mobileShadows : desktopChevrons"></ng-container>
			</div>
			<ng-container *ngTemplateOutlet="innerTabOptions"></ng-container>
		</div>
	</ng-template>
</ng-template>

<ng-template #spinnerTemplate>
	<div
		*ngIf="isLoading; else contentTemplate"
		class="flex-grow-1 d-flex align-items-center justify-content-center"
	>
		<p-spinner [size]="enums.BootstrapSize.LG" class="p-3"></p-spinner>
	</div>
</ng-template>
