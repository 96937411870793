import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PDropdownItemComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown-item/p-dropdown-item.component';

/**
 * Order on which the navigation items should be rendered
 */
export const menuNavigationItemsOrder : {label : PDictionarySourceString, icon ?: PlanoFaIconPoolValues} [] = [
	{label: 'Mein Profil'},
	{label: 'Account & Standort', icon: enumsObject.PlanoFaIconPool.HOUSE},
	{label: 'Rechteverwaltung', icon: enumsObject.PlanoFaIconPool.RIGHTS},
	{label: 'Kalender-Synchronisation', icon: enumsObject.PlanoFaIconPool.SYNCING},
	{label: 'Benachrichtigungs-Einstellungen', icon: enumsObject.PlanoFaIconPool.PUSH_NOTIFICATION},
	{label: 'Stempeluhr-Einstellungen', icon: enumsObject.PlanoFaIconPool.AREA_TIME_STAMP},
	{label: 'Buchungssystem-Einstellungen', icon: enumsObject.PlanoFaIconPool.ITEMS_SALES},
	{label: 'Abmelden', icon: enumsObject.PlanoFaIconPool.LOGOUT},
];

/**
 *
 * Sort a given list according to the menuNavigationItemsOrder array
 *
 * @param listOfMenuItems List to be sorted
 */
export const sortMenuItems =
	(listOfMenuItems : {label : PDictionarySourceString, prependedItem : PDropdownItemComponent['prependedItem']} []) : void => {
		const menuItemsOnlyWithLabels = menuNavigationItemsOrder.map(el => el.label);
		listOfMenuItems.sort((itemA, itemB) => {
			return menuItemsOnlyWithLabels.indexOf(itemA.label) - menuItemsOnlyWithLabels.indexOf(itemB.label);
		});
		for (const [index, el] of listOfMenuItems.entries()) {
			const indexOfElement = menuItemsOnlyWithLabels.indexOf(el.label);
			const elementIcon = menuNavigationItemsOrder.at(indexOfElement)!.icon;
			if (elementIcon) {
				listOfMenuItems[index] = {...el, prependedItem: elementIcon};
			}
		}
	};