import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PBadgeContent } from '@plano/client/shared/shared/p-badge/p-badge.types';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { PTooltipDirective } from '@plano/shared/core/p-tooltip/tooltip.directive';
import { PFormControlComponentChildInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-radios-radio[label]',
	templateUrl: './p-radios-radio.component.html',
	styleUrls: ['./p-radios-radio.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PRadiosRadioComponent extends UniqueAriaLabelByDirective implements PFormControlComponentChildInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public value : PFormControlComponentChildInterface['value'];
	@Input() public icon : PFormControlComponentChildInterface['icon'] = null;

	/**
	 * Sometimes we have radios that are bigger than others, and we might want
	 * them to be all the same size, to do so, we can pass true to this property
	 * which will add a 'h-100' class to the radio.
	 *
	 * NOTE: We can't make this true by default because of cases where radios have descriptions
	 * which would make the radios without descriptions wrongly stretch
	 */
	@Input() public stretchHeight : boolean = false;

	/**
	 * Theme to be added to the icon
	 */
	@Input() public iconTheme : PThemeEnum | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public label ! : PFormControlComponentChildInterface['label'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public description : PFormControlComponentChildInterface['description'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public active : PFormControlComponentChildInterface['active'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public cannotSetHint : PFormControlComponentChildInterface['cannotSetHint'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick : PFormControlComponentChildInterface['onClick'] = new EventEmitter();

	@Input() public disabled : boolean = false;

	/**
	 * Classes to be added to the wrapper element of the radio.
	 * NOTE: This will remove the col classes, so please re-add it manually if needed
	 */
	@Input() public wrapperClasses : string | null = null;

	/**
	 * pTooltip element
	 */
	@Input() public pTooltip : PTooltipDirective['pTooltip'] | null = null;

	/**
	 * pTooltip placement
	 */
	@Input() public pTooltipPlacement : PTooltipDirective['pTooltipPlacement'] | null = null;

	/**
	 * pTooltip hover
	 */
	@Input() public pTooltipHover : PTooltipDirective['pTooltipHover'] | null = null;

	/**
	 * pTooltip theme
	 */
	@Input() public pTooltipTheme : PTooltipDirective['pTooltipTheme'] | null = null;

	/**
	 * Badge to be added to the item
	 */
	@Input() public badgeOnRadio : PBadgeContent = null;

	/**
	 * Badge theme to be added to the item
	 */
	@Input() public badgeTheme : PThemeEnum | 'reverse-primary' = 'primary';

	/** get description if available and string */
	public get descriptionString() : string | null {
		if (this.description === null) return null;
		if (typeof this.description !== 'string') return null;
		return this.description;
	}

	/** get description if available and type templateRef */
	public get descriptionTemplateRef() : TemplateRef<unknown> | null {
		if (this.description === null) return null;
		if (typeof this.description === 'string') return null;
		return this.description;
	}
}
