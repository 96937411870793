import { Location } from '@angular/common';
import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { PAppStartupService } from '@plano/app-startup.service';
import { CourseFilterService } from '@plano/client/scheduling/course-filter.service';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { AssignmentProcessesService } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-processes.service';
import { PSidebarService } from '@plano/client/shared/p-sidebar/p-sidebar.service';
import { MeService, SchedulingApiAbsences, SchedulingApiAssignmentProcessShiftRef, SchedulingApiAssignmentProcessState, SchedulingApiAssignmentProcessType, SchedulingApiAssignmentProcesses, SchedulingApiHolidays, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel, SchedulingApiShifts, ShiftId } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { NonEmptyArray, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subscription } from 'rxjs';
import { CalendarModes } from './calendar-modes';
import { DetailObjectType } from './scheduling-api-based-pages.service';
import { SchedulingFilterService } from './scheduling-filter.service';
import { BirthdayService } from './shared/api/birthday.service';
import { SchedulingApiBirthdays } from './shared/api/scheduling-api-birthday.service';
import { sortShiftsForListViewFns } from './shared/api/scheduling-api.utils';
import { PSchedulingCalendarComponent } from './shared/p-scheduling-calendar/p-calendar/p-calendar.component';

@Component({
	selector: 'p-scheduling',
	templateUrl: './scheduling.component.html',
	styleUrls: ['./scheduling.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingComponent implements OnDestroy, AfterContentChecked, AfterContentInit {
	@HostBinding('class.o-hidden')
	@HostBinding('class.flex-grow-1')
	@HostBinding('class.d-flex') protected _alwaysTrue = true;

	@ViewChild(PSchedulingCalendarComponent) public calendar : PSchedulingCalendarComponent | null = null;

	// eslint-disable-next-line max-params
	constructor(
		public api : SchedulingApiService,
		private meService : MeService,
		private pRouterService : PRouterService,
		private filterService : FilterService,
		public schedulingService : SchedulingService,
		public courseFilterService : CourseFilterService,
		private highlightService : HighlightService,
		private console : LogService,
		private pSidebarService : PSidebarService,
		private schedulingFilterService : SchedulingFilterService,
		private pMomentService : PMomentService,
		private birthdayService : BirthdayService,
		private localize : LocalizePipe,
		private pAppStartupService : PAppStartupService,
		private changeDetectorRef : ChangeDetectorRef,
		private assignmentProcessesService : AssignmentProcessesService,
		private activatedRoute : ActivatedRoute,
		private location : Location,
		private toastsService : ToastsService,
	) {
		this.now = +this.pMomentService.m();
	}

	protected readonly Config = Config;
	public now ! : number;
	public searchVisible : boolean = false;

	public enums = enumsObject;
	public CalendarModes = CalendarModes;

	public ngAfterContentChecked() : void {
		this.handleIncompleteUrl();
	}

	/**
	 * If this url has no defined date, navigate to a default-value.
	 */
	private handleIncompleteUrl() : void {
		if (this.activatedRoute.snapshot.params['date'] !== '0') return;

		const date = (this.schedulingService.urlParam.date ) ?? +this.pMomentService.m().startOf('day');
		const calendarMode = this.schedulingService.urlParam.calendarMode;
		// eslint-disable-next-line ban/ban -- intended navigation
		this.pRouterService.navigate([`/client/scheduling/${calendarMode}/${date}`], {replaceUrl: true});
	}

	private isToday(date : Exclude<SchedulingService['urlParam'], null>['date']) : boolean {
		return this.pMomentService.m(date).isSame(this.now, 'date');
	}

	/**
	 * Should the sidebar be fullscreen or part of screen.
	 */
	public get hasFullscreenSideBar() : boolean {
		return Config.IS_MOBILE && !this.pSidebarService.mainSidebarIsCollapsed;
	}

	/**
	 * This makes it possible to have meService private.
	 */
	public get showContent() : boolean {
		return this.meService.isLoaded();
	}

	/** AfterContentInit */
	public ngAfterContentInit() : void {
		this.meService.isLoaded(() => {
			// TODO: PLANO-174371 remove this block
			if (this.meService.showExpiredClientViewForOwner) {
				// eslint-disable-next-line ban/ban -- this will be removed anyway when PLANO-174371 is done
				void this.pRouterService.navigate(['/client/testaccount']);
			}
		});

		this.setRouterListener();
		this.loadInitialData();
		this.api.isLoaded(() => {
			this.handleQueryParams();
		});
	}

	private handleQueryParams() : void {
		// watch query params
		this.subscriptions.push(this.activatedRoute.queryParams.subscribe((inputQueryParams : Params) => {
			if (!Object.values(inputQueryParams).length) return;
			let foundParam = false;
			const assignmentProcessShiftParam = inputQueryParams['assignmentProcessShift'];
			if (assignmentProcessShiftParam) {
				const prettyId = ShiftId.fromUrl(assignmentProcessShiftParam).toPrettyString();
				this.pRouterService.scrollToSelector(`#${prettyId}`);
				foundParam = true;
			}

			let startModeParam : 'early-bird' | 'wish-picker' | null = null;
			if (inputQueryParams['startEarlyBirdMode'] === 'true') {
				startModeParam = 'early-bird';
				foundParam = true;
			} else if (inputQueryParams['startShiftWishesMode'] === 'true') {
				startModeParam = 'wish-picker';
				foundParam = true;
			}
			if (startModeParam) {
				switch (startModeParam) {
					case 'early-bird':
						this.toggleEarlyBirdMode();
						if (!assignmentProcessShiftParam) this.scrollToFirstShiftWithTodo(startModeParam);
						foundParam = true;
						break;
					case 'wish-picker':
						this.toggleWishPickerMode();
						if (!assignmentProcessShiftParam) this.scrollToFirstShiftWithTodo(startModeParam);
						foundParam = true;
						break;
					default:
						this.console.error(`Unsupported showMode value: »${startModeParam}«<br>Available values: 'early-bird' and 'wish-picker'`);
				}
			}

			if (foundParam) {
				// remove query params so reloading page does not trigger this again.
				// We use this.location which also removes the params from browsers history stack.
				const urlWithoutParams = this.pRouterService.url.split('?')[0] ;
				this.location.replaceState(urlWithoutParams);
			}
		}));
	}

	private loadInitialData() : void {
		this.pAppStartupService.isReady(() => {
			this.schedulingService.writeUrlParamsToService(this.activatedRoute.	snapshot.params);

			this.meService.isLoaded(() => {
				this.loadNewData(() => {
					this.runAllCallbacks();
					this.scrollToCurrentDate();
				});
			});
		});
	}

	/**
	 * Update values if url changed
	 */
	private loadNewData(success ?: () => void) : void {
		this.schedulingService.updateQueryParams(!this.courseFilterService.bookingsVisible);

		// TODO: Move the following code to p-calendar.component as well as the html:
		// <p-spinner [size]="BootstrapSize.LG" *ngIf="api.isLoadOperationRunning" class="area-blocking-spinner"></p-spinner>
		// Maybe i can rewrite it in p-calendar.component to something like
		// this.api.onChange.subscribe(() => {
		// 	if (this.api.isLoadOperationRunning) {
		// 	this.changeDetectorRef.detach();
		// 	this.api.isLoaded(() => { this.changeDetectorRef.reattach() });
		// }
		// })

		// When starting loading of a view we disable whole change-detection for calendar to:
		// 1. prevent calendar from showing intermediate data (e.g. api has still data of old view but the SchedulingService
		// contains the queryParams for next view)
		// 2. Improve performance as the calendar does not destroy/recreate views during loading process

		if (this.calendar) {
			this.console.log('CalenderComponent > changeDetectorRef.detach()');
			this.calendar.changeDetectorRef.detach();
			window.setTimeout(() => {
				this.changeDetectorRef.detach();
			}, 1);
		}

		// Load data
		void this.api.load({
			searchParams: this.schedulingService.queryParams,
			success: () => {

				if (!this.filterService.cookiesHaveBeenRead) {
					this.filterService.readCookies();
					this.filterService.initValues();
				}

				if ( this.filterService.isHideAll(this.api.data.members, this.api.data.shiftModels) ) {
					this.filterService.unload();
					this.filterService.initValues();
				}

				this.showDetailView(
					this.schedulingService.urlParam.detailObject,
					this.schedulingService.urlParam.detailObjectId,
				);

				if (success) success();

				this.schedulingService.schedulingApiHasBeenLoadedOnSchedulingComponent.next();

				if (this.calendar) {
					this.calendar.resetDelayIsActiveStore();
					this.calendar.scrollToTop();
					window.setTimeout(() => {
						assumeNonNull(this.calendar);
						this.calendar.changeDetectorRef.reattach();
						this.changeDetectorRef.reattach();
					}, 1);

					// this.console.log('CalenderComponent > changeDetectorRef.reattach()');
				}
			},
		});
	}

	/**
	 * check if date has been switched within visible range
	 */
	private newDateIsInVisibleRange(oldCalendarMode : CalendarModes, oldDate : number) : boolean {
		if (oldCalendarMode !== this.schedulingService.urlParam.calendarMode) return false;

		if (oldDate === this.schedulingService.urlParam.date) return true;
		return this.pMomentService.m(oldDate).isSame(this.schedulingService.urlParam.date, oldCalendarMode);
	}

	/**
	 * Set listeners for router changes
	 */
	private setRouterListener() : void {
		this.subscriptions.push(this.pRouterService.events.subscribe(
			(event) => {
				// don’t listen to any other events then NavigationEnd
				if (!(event instanceof NavigationEnd)) return;

				this.highlightService.setHighlighted(null);
				const prevCalendarMode = this.schedulingService.urlParam.calendarMode;
				const prevDate = this.schedulingService.urlParam.date;

				this.schedulingService.writeUrlParamsToService(this.activatedRoute.	snapshot.params);

				if (this.newDateIsInVisibleRange(prevCalendarMode, prevDate!)) return;
				this.loadNewData(() => {
					this.runAllCallbacks();
					this.scrollToCurrentDate();
				});

				if (this.isToday(this.schedulingService.urlParam.date)) this.scrollToToday();
			},
			(error : unknown) => { this.console.error(error); },
		));
	}

	private scrollToCurrentDate() : void {
		if (
			this.dateIsInsideRange(
				this.activatedRoute.snapshot.paramMap.get('calendarMode') as CalendarModes,
				+this.activatedRoute.snapshot.paramMap.get('date')!,
			)
		) {
			this.scrollToToday(false, false);
		}
	}

	private get timelineViewIsActive() : boolean {
		return (
			this.schedulingService.urlParam.calendarMode === CalendarModes.DAY && !this.schedulingService.showDayAsList ||
			this.schedulingService.urlParam.calendarMode === CalendarModes.WEEK && !this.schedulingService.showWeekAsList
		);
	}

	/**
	 * Scroll to today
	 */
	public scrollToToday(
		ignoreScrollPosition : boolean = true,
		waitForApiLoaded : boolean = false,
	) : void {
		const date = (this.schedulingService.urlParam.date ) ?? +this.pMomentService.m().startOf('day');
		if ((this.schedulingService.urlParam.calendarMode === CalendarModes.MONTH && !Config.IS_MOBILE) || !this.isToday(date)) {
			this.pRouterService.scrollToSelector('.cal-today', {behavior: 'smooth', block: 'start'}, false, ignoreScrollPosition, waitForApiLoaded);
			return;
		}
		const urlFragment = this.activatedRoute.snapshot.fragment;
		if (urlFragment) return;
		this.pRouterService.scrollToSelector('.now-line', !Config.IS_MOBILE ? { block: 'center' } : undefined, false, ignoreScrollPosition, waitForApiLoaded);
	}

	/**
	 * Check if given timestamp is inside the current range
	 */
	private dateIsInsideRange(calendarMode : CalendarModes, timestamp : number) : boolean {
		return this.pMomentService.m(timestamp).isSame(this.now, calendarMode);
	}

	private runAllCallbacks() : void {
		for (const callback of this.schedulingService.afterNavigationCallbacks) {
			callback();
		}
		this.schedulingService.afterNavigationCallbacks = [];
	}

	/**
	 * If there is a detailObject in the url, show the related details
	 */
	private showDetailView(object : DetailObjectType | null, objectId : number | null) : void {
		if (objectId === null) return;
		const id = Id.create(objectId);
		switch (object) {
			case 'shiftModel':
				const shiftModel = this.api.data.shiftModels.get(id);
				if (shiftModel) {
					this.showShiftModelDetails(shiftModel);
				}
				break;
			case 'member':
				const member = this.api.data.members.get(id);
				if (member) {
					this.showMemberDetails(member);
				}
				break;
			default:

		}
	}

	private subscriptions : Subscription[] = [];

	public ngOnDestroy() : void {
		for (const subscription of this.subscriptions) subscription.unsubscribe();
	}

	/**
	 * Open shiftModel form
	 */
	private async showShiftModelDetails(shiftModel : SchedulingApiShiftModel) : Promise<void> {
		// eslint-disable-next-line ban/ban -- intended navigation
		await this.pRouterService.navigate([`/client/shiftmodel/${shiftModel.id.toString()}`]);
	}

	/**
	 * Show details for specific member
	 */
	private async showMemberDetails(member : SchedulingApiMember) : Promise<void> {
		// eslint-disable-next-line ban/ban -- intended navigation
		await this.pRouterService.navigate([`/client/member/${member.id.toString()}`]);
	}

	/**
	 * Determine if this shift is selectable
	 */
	private shiftIsSelectable(shift : SchedulingApiShift) : boolean {
		if (!this.schedulingService.wishPickerMode) {
			return true;
		}
		const process = this.api.data.assignmentProcesses.getByShiftId(shift.id);
		if (
			process &&
			process.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES
		) {
			return shift.assignableMembers.containsMemberId(this.meService.data.id);
		}
		return false;
	}

	private _shiftsForCalendar : Data<SchedulingApiShifts> = new Data<SchedulingApiShifts>(this.api, this.filterService, this.schedulingFilterService);

	/**
	 * Get all shifts for calendar that are visible by the current filter settings.
	 */
	public get shiftsForCalendar() : SchedulingApiShifts {
		return this._shiftsForCalendar.get(() => {
			if (!this.api.data.attributeInfoShifts.isAvailable) return new SchedulingApiShifts(null, null);
			const filteredItems = this.api.data.shifts.filterBy((shift) => {
				return this.filterService.isVisible(shift);
			});
			for (const compareFn of sortShiftsForListViewFns) {
				filteredItems.sort(compareFn);
			}
			return filteredItems;
		});
	}

	private filterAndSortAllDayItems<T extends SchedulingApiAbsences | SchedulingApiHolidays>(items : T) : T {

		// This type cast seems unintuitive, but sortedBy has issues with the abstract T type, if we dont do this.
		// Feel free to try to remove the cast.
		const typedItems = (items as SchedulingApiAbsences);

		return typedItems
			.filterBy((item) => this.filterService.isVisible(item))
			.sortedBy([
				(item) => item.time.start,
				(item) => {
					try {
						return item.time.end;
					} catch (error) {
						this.console.error('PRODUCTION-4T0', error);
						return null;
					}
				}]) as T;

	}

	private _absencesForCalendar = new Data<SchedulingApiAbsences>(this.api, this.filterService, this.schedulingFilterService);

	/**
	 * Get all absences for calendar
	 */
	public get absencesForCalendar() : SchedulingApiAbsences {
		return this._absencesForCalendar.get(() => {
			if (!this.api.data.attributeInfoAbsences.isAvailable) return new SchedulingApiAbsences(null, null);
			return this.filterAndSortAllDayItems(this.api.data.absences);
		});
	}

	private _holidaysForCalendar : Data<SchedulingApiHolidays> = new Data<SchedulingApiHolidays>(this.api, this.filterService, this.schedulingFilterService);

	/**
	 * Get all holidays for calendar
	 */
	public get holidaysForCalendar() : SchedulingApiHolidays {
		return this._holidaysForCalendar.get(() => {
			if (!this.api.data.attributeInfoHolidays.isAvailable) return new SchedulingApiHolidays(null, null);
			return this.filterAndSortAllDayItems(this.api.data.holidays);
		});
	}

	/**
	 * Get list of birthdays of members in a p-calendar consumable way.
	 */
	public get birthdaysForCalendar() : SchedulingApiBirthdays {
		return this.birthdayService.birthdays;
	}

	/**
	 * Handle dayClick of calendar
	 */
	public async onDayClick(timestamp : number) : Promise<void> {
		// eslint-disable-next-line ban/ban -- intended navigation
		await this.pRouterService.navigate([`/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${timestamp}`]);
	}

	/**
	 * handle shiftClick of calendar
	 */
	public onShiftSelect(input : {
		shift : SchedulingApiShift,
		event : MouseEvent,
	}) : void {
		input.event.stopPropagation();
	}

	/**
	 * Select all shifts that have the same weekday as the given timestamp
	 */
	public selectAllShiftsOfThisWeekday(timestamp : number) : void {
		const shifts = this.shiftsForCalendar.filterBy((shift) => {
			if (this.pMomentService.m(shift.start).isoWeekday() === this.pMomentService.m(timestamp).isoWeekday()) return true;
			return false;
		}).filterBy((shift) => {
			return this.shiftIsSelectable(shift);
		});

		if (shifts.length === shifts.selectedItems.length) {
			shifts.setSelected(false);
		} else {
			shifts.setSelected();
		}
	}

	/**
	 * Navigate to a new date
	 */
	public async onChangeDate(input : number) : Promise<void> {
		this.api.deselectAllSelections();
		// eslint-disable-next-line ban/ban -- intended navigation
		await this.pRouterService.navigate([`/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${input}`]);
	}

	/**
	 * Navigate to a new calendarMode
	 */
	public onChangeMode(mode : CalendarModes) : void {
		this.api.deselectAllSelections();
		this.highlightService.setHighlighted(null);

		// eslint-disable-next-line ban/ban -- intended navigation
		this.pRouterService.navigate([`/client/scheduling/${mode}/${this.schedulingService.urlParam.date}`]);
	}

	/**
	 * Toggle show as List
	 */
	public onChangeShowDayAsList(newValue : boolean) : void {
		this.schedulingService.showDayAsList = newValue;
		this.api.data.shifts.setSelected(false);
	}

	/**
	 * Toggle show as List
	 */
	public onChangeShowWeekAsList(newValue : boolean) : void {
		this.schedulingService.showWeekAsList = newValue;
		this.api.data.shifts.setSelected(false);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public selectRelatedShifts(triggerItem : SchedulingApiShiftModel | SchedulingApiMember) : void {
		let shifts : SchedulingApiShifts;

		if (this.schedulingService.wishPickerMode) {
			shifts = this.shiftsForCalendar.filterBy(shift => {
				if (!this.filterService.isVisible(shift)) return false;

				// only select items that are selectable
				if (!shift.assignableMembers.contains(this.meService.data.id)) return false;

				const process = this.api.data.assignmentProcesses.getByShiftId(shift.id);
				if (process && process.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES) {
					if (!process.onlyAskPrefsForUnassignedShifts) return true;
					if (shift.emptyMemberSlots) return true;
					return false;
				}
				return false;
			}).filterBy(shift => {
				return this.shiftIsSelectable(shift);
			});
		} else {
			shifts = this.shiftsForCalendar.filterBy(shift => {
				return this.filterService.isVisible(shift);
			}).filterBy(shift => {
				return this.shiftIsSelectable(shift);
			});
		}

		shifts.toggleSelectionByItem(triggerItem);

		const selectedShifts = shifts.filterBy(item => item.selected);
		this.pRouterService.scrollToFirstShift(selectedShifts);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get itemsFilterTitle() : string | null {
		if (!this.api.data.attributeInfoShifts.isAvailable) return null;
		return this.localize.transform({
			sourceString: 'Ausgeblendete Schichten: ${counter} von ${amount}',
			params: {
				counter: (this.api.data.shifts.length - this.shiftsForCalendar.length).toString(),
				amount: this.api.data.shifts.length.toString(),
			},
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onClickWishPickerButton() : void {
		const hasBeenEnabled = this.toggleWishPickerMode();
		if (hasBeenEnabled) this.getToFirstShift('wish-picker');
	}

	/** Returns true if mode has been enabled */
	private toggleWishPickerMode() : boolean {
		if (!this.api.data.assignmentProcesses.filterBy(item => item.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES).length) {
			this.toastsService.addToast({
				content: this.localize.transform('Diese Funktion steht aktuell nicht zur Verfügung, weil es keinen passenden Verteilungsvorgang gibt.'),
				theme: enumsObject.PThemeEnum.WARNING,
			});
			return false;
		}
		this.resetSelection();
		this.schedulingService.wishPickerMode = !this.schedulingService.wishPickerMode;
		if (Config.IS_MOBILE) {
			this.filterService.showOnlyWishPickerAssignmentProcesses(this.schedulingService.wishPickerMode);
			this.filterService.showOnlyEarlyBirdAssignmentProcesses(false);
		}
		if (!this.schedulingService.wishPickerMode) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onClickEarlyBirdButton() : void {
		const hasBeenEnabled = this.toggleEarlyBirdMode();
		if (hasBeenEnabled) this.getToFirstShift('early-bird');
	}

	/** Returns true if mode has been enabled */
	private toggleEarlyBirdMode() : boolean {
		if (!this.api.data.assignmentProcesses.filterBy(item => item.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING).length) {
			this.toastsService.addToast({
				content: this.localize.transform('Diese Funktion steht aktuell nicht zur Verfügung, weil es keinen passenden Verteilungsvorgang gibt.'),
				theme: enumsObject.PThemeEnum.WARNING,
			});
			return false;
		}
		this.resetSelection();
		this.schedulingService.earlyBirdMode = !this.schedulingService.earlyBirdMode;
		if (Config.IS_MOBILE) {
			this.filterService.showOnlyWishPickerAssignmentProcesses(false);
			this.filterService.showOnlyEarlyBirdAssignmentProcesses(this.schedulingService.earlyBirdMode);
		}
		if (!this.schedulingService.earlyBirdMode) return false;
		return true;
	}

	private resetSelection() : void {
		if (this.api.isLoaded()) this.api.data.shifts.setSelected(false);
	}

	private getToFirstShift(input : 'early-bird' | 'wish-picker') : void {
		const shiftWasAlreadyFoundOnCurrentView = this.scrollToFirstShiftWithTodo(input);
		if (shiftWasAlreadyFoundOnCurrentView) return;
		this.navigateToFirstTodo(input);
	}

	private navigateToFirstTodo(input : 'early-bird' | 'wish-picker') : boolean {
		const earliestStart = this.getEarliestStart(input, this.api.data.assignmentProcesses);

		if (earliestStart === 0) {
			const hasShiftOnProcessInView = this.scrollToFirstShift(input);
			if (!hasShiftOnProcessInView) {
				const newEarliestStart = this.getEarliestStart(input, this.api.data.assignmentProcesses, true);
				// eslint-disable-next-line ban/ban
				void this.pRouterService.navigate([`/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${newEarliestStart}`]);
				const tempSubscriber = this.api.onDataLoaded.subscribe(() => {
					this.scrollToFirstShift(input);
					tempSubscriber.unsubscribe();
				});
			}
			return false;
		}

		const scrollWasSuccessful = this.scrollToFirstShiftWithTodo(input);
		if (!scrollWasSuccessful) {
			// eslint-disable-next-line ban/ban -- intended navigation
			void this.pRouterService.navigate([`/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${earliestStart}`]);
			const tempSubscriber = this.api.onDataLoaded.subscribe(() => {
				this.scrollToFirstShiftWithTodo(input);
				tempSubscriber.unsubscribe();
			});
		}
		return true;
	}

	/** returns true if shift with todo could be found on the view */
	private scrollToFirstShiftWithTodo(input : 'early-bird' | 'wish-picker') : boolean {
		const shifts = this.getAllShiftsForMode(input, true);
		return this.pRouterService.scrollToFirstShift(shifts);
	}

	private scrollToFirstShift(input : 'early-bird' | 'wish-picker') : boolean {
		const shifts = this.getAllShiftsForMode(input, false);
		return this.pRouterService.scrollToFirstShift(shifts);
	}

	private getEarliestStart(input : 'early-bird' | 'wish-picker', processes : SchedulingApiAssignmentProcesses, ignoreFirstToDo : boolean = false) : number {
		const now = this.pMomentService.m().startOf('D').valueOf();
		const allShiftRefs = processes
			.filterBy(item => {
				if (!ignoreFirstToDo && !item.firstDayStartWithTodo) return false;
				if (input === 'early-bird' && item.type !== SchedulingApiAssignmentProcessType.EARLY_BIRD) return false;
				if (input === 'wish-picker' && item.type === SchedulingApiAssignmentProcessType.EARLY_BIRD) return false;
				return true;
			})
			// eslint-disable-next-line unicorn/prefer-array-flat-map
			.map(item => item.shiftRefs.filterBy(
				shiftRef => shiftRef.id.start >= now && (ignoreFirstToDo || shiftRef.id.start >= item.firstDayStartWithTodo!),
			).iterable()).flat().filter(item => {
				if (input === 'early-bird') return true;
				return !!item.requesterCanSetPref;
			});
		if (allShiftRefs.length === 0) return 0;
		return this.assignmentProcessesService.getEarliestStartOfShiftRefs(allShiftRefs as NonEmptyArray<SchedulingApiAssignmentProcessShiftRef>);
	}

	/**
	 * Get the shifts related to the early bird process
	 */
	private getShiftsForEarlyBird(onlyWithTodos : boolean) : SchedulingApiShifts {
		return this.api.data.shifts.filterBy((item) => {
			const process = this.api.data.assignmentProcesses.getByShiftId(item.id);
			if (!process) return false;
			if (!onlyWithTodos) return true;
			if (item.start < process.firstDayStartWithTodo!) return false;

			// backend decides if user can pick the shift
			return process.shiftRefs.get(item.id)?.requesterCanDoEarlyBird === true || false;
		});
	}

	/**
	 * Get the shifts related to the wish picker process
	 */
	private getShiftsForWishPicker(onlyWithTodos : boolean) : SchedulingApiShifts {
		return this.api.data.shifts.filterBy((item) => {
			const process = this.api.data.assignmentProcesses.getByShiftId(item.id);
			if (!process) return false;
			if (process.state !== SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES) return false;
			if (!item.assignableMembers.containsMemberId(this.meService.data.id)) return false;
			if (!onlyWithTodos) return true;
			if (item.start < process.firstDayStartWithTodo!) return false;

			return process.shiftRefs.get(item.id)?.requesterCanSetPref === true && item.myPref === null;
		});
	}

	private getAllShiftsForMode(input : 'early-bird' | 'wish-picker', onlyWithTodos : boolean) : SchedulingApiShifts {
		let shiftsOfProcess : SchedulingApiShifts;
		if (input === 'early-bird') {
			shiftsOfProcess = this.getShiftsForEarlyBird(onlyWithTodos);
		} else {
			shiftsOfProcess = this.getShiftsForWishPicker(onlyWithTodos);
		}

		const now = +this.pMomentService.m().startOf('D');
		return shiftsOfProcess.filterBy(item => item.start > now);
	}
}
