import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PFaIconModule } from '@plano/shared/core/component/fa-icon/fa-icon.module';
import { PAutoFocusInsideModalDirective } from '@plano/shared/core/p-auto-focus/p-auto-focus.directive';
import { PCollapsibleComponent } from './p-collapsible.component';

@NgModule({
	imports: [
		CommonModule,
		PAutoFocusInsideModalDirective,
		PFaIconModule,
	],
	exports: [PAutoFocusInsideModalDirective, PCollapsibleComponent],
	declarations: [PCollapsibleComponent],
	providers: [],
})
export class PCollapsibleModule { }
