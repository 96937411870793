<div
	*ngIf="!hasCourseDatesData"
	class="mb-0 text-wrap"
	i18n
>Tarif ist zeitlich unbeschränkt gültig</div>
<ng-template [ngIf]="hasCourseDatesData">
	<div
		*ngIf="negateForCourseDatesInterval === true && !forCourseDatesFrom && !forCourseDatesUntil; else hasStartAndOrEndTemplate"
		class="mb-0 text-wrap"
		i18n
	>Tarif ist dauerhaft ungültig!</div>

	<ng-template #hasStartAndOrEndTemplate>
		<ng-template [ngIf]="negateForCourseDatesInterval!==undefined">
			<ng-container i18n>{!negateForCourseDatesInterval, select, true {Gilt} other {Gilt nicht}}</ng-container>
			<ng-template [ngIf]="longText">&nbsp;<ng-container i18n>für Termine</ng-container></ng-template>
		</ng-template>

		<ng-template [ngIf]="forCourseDatesFrom">&nbsp;<ng-container i18n>ab</ng-container>&nbsp;<code>{{ forCourseDatesFrom | date:'shortDate' }}</code></ng-template>

		<ng-template [ngIf]="forCourseDatesUntil">&nbsp;<ng-container i18n>bis</ng-container>&nbsp;<code>{{ forCourseDatesUntil - 1 | date:'shortDate' }}</code></ng-template>
	</ng-template>
</ng-template>
<ng-template [ngIf]="isInternal">
	<ng-template [ngIf]="hasCourseDatesData"><span class="d-none d-lg-inline-block">,&nbsp;</span></ng-template>
	<span class="text-nowrap d-block d-lg-inline-block" i18n>{{ isInternalLabel }}</span>
</ng-template>
