import { HttpParams } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationExtras } from '@angular/router';
import { ReportUrlParamsService } from '@plano/client/report/report-url-params.service';
import { ReportService } from '@plano/client/report/report.service';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FilterService } from '@plano/client/shared/filter.service';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PExportService } from '@plano/client/shared/p-export.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PSidebarService } from '@plano/client/shared/p-sidebar/p-sidebar.service';
import { AccountApiService, ExportReportingExcelApiService, MeService, RightsService, SchedulingApiAbsences, SchedulingApiMember, SchedulingApiMembers, SchedulingApiService, SchedulingApiWorkingTimes } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { DropdownTypeEnum } from '@plano/shared/p-forms/p-dropdown/p-dropdown.component';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PInputDateTypes } from '@plano/shared/p-forms/p-input-date/p-input-date.component';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportFilterService } from './report-filter.service';

@Component({
	selector: 'p-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ReportComponent implements AfterContentInit, OnDestroy, AfterViewInit {
	@HostBinding('class.flex-grow-1')
	@HostBinding('class.d-flex')
	@HostBinding('class.position-relative') protected _alwaysTrue = true;

	// eslint-disable-next-line max-params
	constructor(
		public api : SchedulingApiService,
		public meService : MeService,
		private route : ActivatedRoute,
		private pRouterService : PRouterService,
		private validators : ValidatorsService,
		private exportExcelApi : ExportReportingExcelApiService,
		public reportService : ReportService,
		public reportUrlParams : ReportUrlParamsService,
		private accountApiService : AccountApiService,
		private pFormsService : PFormsService,
		public filterService : FilterService,
		public highlightService : HighlightService,
		public pSidebarService : PSidebarService,
		public rightsService : RightsService,
		private changeDetectorRef : ChangeDetectorRef,
		private console : LogService,
		private elementRef : ElementRef<HTMLElement>,
		public reportFilterService : ReportFilterService,
		private localize : LocalizePipe,
		private pMoment : PMomentService,
		private pExport : PExportService,
	) {
	}

	protected readonly CONFIG = Config;
	protected readonly PInputDateTypes = PInputDateTypes;
	protected readonly enums = enumsObject;
	protected readonly DropdownTypeEnum = DropdownTypeEnum;
	protected readonly PBtnThemeEnum = PBtnThemeEnum;
	protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;

	/**
	 * Height of the list headline
	 */
	public listHeadlineHeight : number = 0;

	/**
	 * Should the height of the headline be recalculated?
	 */
	public shouldRecalculateListHeadlineHeight() : void {
		if (this.listHeadlineHeight !== 0) return;
		this.recalculateListHeadlineHeight();
	}

	private recalculateListHeadlineHeight() : void {
		const stickyHeadlineElement = this.elementRef.nativeElement.querySelector('.sticky-headline');
		if (stickyHeadlineElement) {
			this.listHeadlineHeight = stickyHeadlineElement.clientHeight;
		}
	}

	public ngAfterViewInit() : void {
		window.addEventListener('resize', () => {
			this.recalculateListHeadlineHeight();
		});
		this.recalculateListHeadlineHeight();
	}

	public formGroup : FormGroup<{
		'maxDate' : PFormControl,
		'minDate' : PFormControl,
	}> | null = null;

	private _workingTimes : Data<SchedulingApiWorkingTimes> = new Data<SchedulingApiWorkingTimes>(this.api, this.filterService, this.reportFilterService);

	public ngAfterContentInit() : void {
		this.setRouterListener();
		this.initValues();
	}

	public _totalEarningsBetween = new Data<number>(this.api, this.filterService, this.reportFilterService);

	/**
	 * Total earning between the start and end
	 */
	public get totalEarningsBetween() : number {
		return this._totalEarningsBetween.get(() => {
			return this.absences.totalEarningsBetween(this.reportUrlParams.urlParam.start, this.reportUrlParams.urlParam.end) +
			this.workingTimes.totalEarningsBetween(this.reportUrlParams.urlParam.start, this.reportUrlParams.urlParam.end);
		});
	}

	private refresh() : void {
		this.refreshStartAndEnd((reloadNecessary) => {
			if (!reloadNecessary) {
				this.loadNewData();
			} else {
				this.navTo({
					start: this.reportUrlParams.urlParam.start ?? null,
					end: this.reportUrlParams.urlParam.end ?? null,
				}, { replaceUrl: true });
			}
			this._totalEarningsBetween.forceUpdate();
		});
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		this.refresh();
		this._totalEarningsBetween.forceUpdate();
		this.reportFilterService.initValues();
	}

	private _memberForListSortedByName : Data<readonly SchedulingApiMember[]> =
		new Data<readonly SchedulingApiMember[]>(this.api, this.filterService, this.reportFilterService);

	/**
	 * Sorted members for list iterable
	 */
	public get memberForListSortedByName() : readonly SchedulingApiMember[] {
		return this._memberForListSortedByName.get(() => {
			return this.membersForList.sortedBy(defaultSortingForMembers).iterable();
		});
	}

	private getStart( success : (reloadNecessary : boolean | 'scilently') => void ) : void {
		// Check if user navigated to a specific date
		if (this.readStartParam) {

			// User came via direct link
			if (this.reportUrlParams.urlParam.start === undefined) {
				this.reportUrlParams.urlParam.start = this.readStartParam;
				success(false);
				return;
			}

			// Check if start is still "up-to-date"
			if (this.reportUrlParams.urlParam.start === this.readStartParam) {
				success(false);
				return;
			}

			this.reportUrlParams.urlParam.start = this.readStartParam;
			success(true);
			return;
		}

		// User did not nav to a specific date.
		// Is a date stored from a previous visit?
		if (this.reportUrlParams.urlParam.start) {
			// Send the user to the previous start
			success(true);
			return;
		}

		// There is no data available. Calculate a default date based on the accountingPeriodStartDay
		this.meService.isLoaded(() => {
			this.accountApiService.isLoaded(() => {
				this.reportUrlParams.urlParam.start = this.getDefaultStart();
				success(true);
			});
			if (!this.accountApiService.isLoaded()) void this.accountApiService.load();
		});
	}

	private getEnd( success : (reloadNecessary : boolean) => void ) : void {
		// Check if user navigated to a specific date
		if (this.readEndParam) {

			// User came via direct link
			if (this.reportUrlParams.urlParam.end === undefined) {
				this.reportUrlParams.urlParam.end = this.readEndParam;
				success(false);
				return;
			}

			// Check if end is still "up-to-date"
			if (this.reportUrlParams.urlParam.end === this.readEndParam) {
				success(false);
				return;
			}

			this.reportUrlParams.urlParam.end = this.readEndParam;
			success(true);
			return;
		}

		// User did not nav to a specific date.
		// Is a date stored from a previous visit?
		if (this.reportUrlParams.urlParam.end) {
			// Send the user to the previous start
			success(true);
			return;
		}

		// There is no data available. Calculate a default date based on the accountingPeriodStartDay
		this.meService.isLoaded(() => {
			this.accountApiService.isLoaded(() => {
				this.reportUrlParams.urlParam.end = this.getDefaultEnd();
				success(true);
			});
			if (!this.accountApiService.isLoaded()) void this.accountApiService.load();
		});
	}

	private refreshStartAndEnd(success : (reloadNecessary : boolean) => void) : void {
		let reloadNecessary : boolean = false;
		this.getStart((reloadNecessaryFromGetStart : boolean | 'scilently') => {
			if (reloadNecessaryFromGetStart) reloadNecessary = true;
			this.getEnd((reloadNecessaryFromGetEnd : boolean) => {
				if (reloadNecessaryFromGetEnd) reloadNecessary = true;
				success(reloadNecessary);
			});
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasSomeFilterSettings() : boolean {
		if (!this.filterService.isSetToShowAll) return true;
		return false;
	}

	private _membersForList : Data<SchedulingApiMembers> = new Data<SchedulingApiMembers>(this.api, this.filterService, this.reportFilterService);

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get membersForList() : SchedulingApiMembers {
		return this._membersForList.get(() => {
			return this.api.data.attributeInfoMembers.isAvailable && !this.api.currentlyDetailedLoaded ? this.api.data.members.filterBy(
				(member) => {
					// Members only get their own entry
					if (!this.rightsService.isOwner && !this.rightsService.isMe(member.id)) return false;

					if (!this.filterService.isVisible(member)) return false;

					// Does this member has a visible absence?
					const visibleAbsence = this.absences.findBy((item) => item.memberId.equals(member.id));
					if (visibleAbsence) return true;

					// Does this member has a visible workingTime?
					const visibleWorkingTime = this.workingTimes.findBy((item) => item.memberId.equals(member.id));
					if (visibleWorkingTime) return true;

					// If deleted users don't have any working time or absence we don't want to show them
					const trashedMember = member.trashed;
					if (trashedMember) return false;

					// User wants to see members without entries?
					if (this.reportFilterService.showUsersWithoutEntries) return true;

					return false;
				},
			) : new SchedulingApiMembers(null, null);
		});
	}

	/**
	 * Shortcut to reset all Filters.
	 */
	public resetFilter() : void {
		this.reportFilterService.unload();
		this.reportFilterService.initValues();
		this.filterService.unload();
		this.filterService.initValues();
	}

	// public get showContent() : boolean {
	// 	return this.api.isLoaded();
	// }

	/**
	 * Initialize the formGroup for this component
	 */
	private initFormGroup() : void {
		if (this.formGroup) { this.formGroup = null; }
		const tempFormGroup = this.pFormsService.group({});
		this.pFormsService.addControl(
			tempFormGroup,
			'minDate',
			{
				value : this.reportUrlParams.urlParam.start,
				disabled: false,
			},
			[this.validators.required(PApiPrimitiveTypes.number)],
			(value) => {
				this.reportUrlParams.urlParam.start = value;
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'maxDate',
			{
				value : this.reportUrlParams.urlParam.end,
				disabled: false,
			},
			[this.validators.required(PApiPrimitiveTypes.number)],
			(value) => {
				this.reportUrlParams.urlParam.end = value;
			},
		);
		this.formGroup = tempFormGroup;
	}

	/**
	 * Load new absences and workingTimes
	 */
	private loadNewData() : void {
		this.initFormGroup();
		this.reportUrlParams.updateQueryParams();

		this.api.load({
			searchParams: this.reportUrlParams.queryParams,
			success: () => {
				this.uncollapseMemberCollapsibleIfProvided();
			},
		});
	}

	private uncollapseMemberCollapsibleIfProvided() : void {
		const routeParams = this.route.snapshot.paramMap;

		// If memberId is provided, uncollapse the desired member
		const memberId = Number(routeParams.get('memberId'));
		this.reportService.uncollapse(Id.create(memberId));
	}

	/**
	 * Determine the default date range for the report page.
	 * @returns the current accounting period month, if that information is available.
	 * If the accounting period start day is not available, the current month is returned.
	 */
	private getDefaultStart() : number {
		const newMoment = this.pMoment.m().startOf('day');
		if (!this.meService.isLoaded()) throw new Error('Make sure meService is loaded here.');
		if (!this.accountApiService.isLoaded()) throw new Error('Make sure accountApi is loaded here.');
		if (this.accountApiService.data.attributeInfoAccountingPeriodStartDay.value !== null) {
			if (newMoment.date() >= this.accountApiService.data.accountingPeriodStartDay) newMoment.add(1, 'month');
			newMoment.date(this.accountApiService.data.accountingPeriodStartDay);
		} else {
			newMoment.add(1, 'day');
		}
		return +this.pMoment.m(newMoment).subtract(1, 'month');
	}

	private getDefaultEnd() : number {
		const start = this.getDefaultStart();
		const newMoment = this.pMoment.m(start);
		return +this.pMoment.m(newMoment).add(1, 'month');
	}

	private ngUnsubscribe : Subject<null> = new Subject<null>();

	public ngOnDestroy() : void {
		this.ngUnsubscribe.next(null);
		this.ngUnsubscribe.complete();
		this.changeDetectorRef.detach();
	}

	private get readStartParam() : number | undefined {
		assumeDefinedToGetStrictNullChecksRunning(this.route, 'route');
		if (this.route.snapshot.params['start'] === undefined) return undefined;
		if (this.route.snapshot.params['start'] === '0') return undefined;
		return +this.route.snapshot.params['start'];
	}

	private get readEndParam() : number | undefined {
		assumeDefinedToGetStrictNullChecksRunning(this.route, 'route');
		if (this.route.snapshot.params['end'] === undefined) return undefined;
		if (this.route.snapshot.params['end'] === '0') return undefined;
		return +this.route.snapshot.params['end'];
	}

	/**
	 * Listen to NavigationEnd to navigate somewhere if no url params are provided or load data if params are provided.
	 */
	private setRouterListener() : void {
		// eslint-disable-next-line rxjs/no-ignored-subscription -- Remove this before you work here.
		this.pRouterService.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(event) => {
				if (!(event instanceof NavigationEnd)) return;

				this.highlightService.setHighlighted(null);
				this.refresh();
			},
			(error : unknown) => {
				this.console.error(error);
			},
		);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public navToNewRange() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		if (!this.formGroup.valid) return;

		const newStart = this.formGroup.get('minDate')!.value;
		const newEnd = +this.pMoment.m(this.formGroup.get('maxDate')!.value).startOf('day');
		this.navTo({ start: newStart, end: newEnd });
	}

	private navTo(input : { start ?: number | null, end ?: number | null }, extras ?: NavigationExtras) : void {
		const newUrl = `/client/report/${input.start}/${input.end}`;
		// eslint-disable-next-line ban/ban -- intended navigation
		void this.pRouterService.navigate([newUrl], extras);
	}

	/**
	 * Create a new absence entry for a member
	 */
	public createAbsenceEntry() : string {
		return '/client/absence/';
	}

	/**
	 * Create a new workingTime entry for a member
	 */
	public createWorkingTimeEntry() : string {
		return '/client/workingtime/';
	}

	private _absences : Data<SchedulingApiAbsences> = new Data<SchedulingApiAbsences>(this.api, this.filterService, this.reportFilterService);

	/**
	 * Get absences
	 */
	public get absences() : SchedulingApiAbsences {
		return this._absences.get(() => {
			if (!this.reportFilterService.showAbsences) {
				return new SchedulingApiAbsences(null, null);
			} else {
				return this.api.data.attributeInfoAbsences.isAvailable ? this.api.data.absences.filterBy((item) => {
					return this.filterService.isVisible(item);
				}) : new SchedulingApiAbsences(null, null);
			}
		});
	}

	/**
	 * Get workingTimes
	 */
	public get workingTimes() : SchedulingApiWorkingTimes {
		return this._workingTimes.get(() => {
			if (!this.reportFilterService.showWorkingTimes) {
				return new SchedulingApiWorkingTimes(null, null);
			} else {
				return this.api.data.attributeInfoWorkingTimes.isAvailable ? this.api.data.workingTimes.filterBy((item) => {
					return this.filterService.isVisible(item);
				}) : new SchedulingApiWorkingTimes(null, null);
			}
		});
	}

	/**
	 * is the api currently loading?
	 */
	public get isApiLoading() : boolean {
		return this.api.isLoadOperationRunning;
	}

	public exportIsRunning = false;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public export() : void {
		// set workingTimes and absences to be exported
		this.exportExcelApi.setEmptyData();

		for (const workingTime of this.api.data.workingTimes.iterable()) {
			if (!this.filterService.isVisible(workingTime)) continue;
			this.exportExcelApi.data.workingTimeIds.createNewItem(null, workingTime.id);
		}

		for (const absence of this.api.data.absences.iterable()) {
			if (!this.filterService.isVisible(absence)) continue;
			this.exportExcelApi.data.absenceIds.createNewItem(null, absence.id);
		}

		// set members to be exported
		for (const member of this.api.data.members.iterable()) {
			if (this.filterService.isVisible(member))
				this.exportExcelApi.data.memberIds.push(member.id);
		}

		// set shiftModel to be exported
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			if (this.filterService.isVisible(shiftModel))
				this.exportExcelApi.data.shiftModelIds.push(shiftModel.id);
		}

		assumeDefinedToGetStrictNullChecksRunning(this.reportUrlParams.urlParam.start, 'reportUrlParams.urlParam.start');
		assumeDefinedToGetStrictNullChecksRunning(this.reportUrlParams.urlParam.end, 'reportUrlParams.urlParam.end');

		// get query params
		let queryParams = new HttpParams()
			.set('start', (this.reportUrlParams.urlParam.start).toString())
			.set('end', (this.reportUrlParams.urlParam.end).toString());

		if (this.reportFilterService.showWorkingTimes) {
			queryParams = queryParams.set('includeWorkingTimes', '');
		}
		if (this.reportFilterService.showWorkingTimesForecast) {
			queryParams = queryParams.set('includeWorkingTimesForecast', '');
		}

		if (this.reportFilterService.showAbsences) {
			queryParams = queryParams.set('includeAbsences', '');
		}
		if (this.reportFilterService.showUnpaidAbsences) {
			queryParams = queryParams.set('includeUnpaidAbsences', '');
		}

		// cSpell:ignore auswertungsexport
		const fileName = this.pExport.getFileName(this.localize.transform('auswertungsexport'), this.reportUrlParams.urlParam.start, this.reportUrlParams.urlParam.end - 1);

		// download file
		this.exportIsRunning = true;
		this.exportExcelApi.downloadFile(fileName, 'xlsx', queryParams, 'PUT', () => {
			this.exportIsRunning = false;
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get fromLabelText() : string | undefined {
		if (Config.IS_MOBILE) return undefined;
		if (!this.pSidebarService.mainSidebarIsCollapsed) {
			return this.localize.transform('Auswertung vom');
		}
		return this.localize.transform('vom');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get tillLabelText() : string {
		if (Config.IS_MOBILE) return ' – ';
		return this.localize.transform('bis');
	}
}
