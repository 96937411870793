<div class="stopwatch card mb-5">
	<div class="card-body">
		<p-grid>
			<div class="col-sm-4 pt-3 pb-3 d-flex align-items-center justify-content-center">
				<p-stopwatch-image></p-stopwatch-image>
			</div>
			<div
				*ngIf="formGroup"
				class="col-sm-8 d-flex flex-column justify-content-between gap-3"
			>
				<p-button
					class="h-100"
					[theme]="startButtonDisabled ? enums.PThemeEnum.SECONDARY : enums.PThemeEnum.PRIMARY"
					(triggerClick)="onStartTracking()"
					[disabled]="startButtonDisabled"
					textAlign="left"
				>
					<div class="d-flex align-items-center">
						<fa-icon [icon]="enums.PlanoFaIconPool.AREA_TIME_STAMP" size="2x"></fa-icon><span class="ml-3" [style.font-size.rem]="1" i18n>Einstempeln</span>
					</div>
				</p-button>
				<p-ai-switch
					#inputDateStartAiSwitchRef
					style="display: none !important;"
					[group]="formGroup"
					[attributeInfo]="api.data.attributeInfoStart"
					[closeBtnDisabled]="api.data.start === null"
					[suggestionTimestamp]="suggestionTimestampForStart"
					suggestionLabel="Geplante Zeit" i18n-suggestionLabel
					label="Einstempeln" i18n-label
				></p-ai-switch>

				<ng-template [ngIf]="isAddPauseMode" [ngIfElse]="noAddPauseMode">
					<p-button
						class="h-100"
						[theme]="pauseButtonDisabled ? enums.PThemeEnum.SECONDARY : enums.PThemeEnum.PRIMARY"
						(triggerClick)="!pauseButtonDisabled ? addPause(modalContent.template) : undefined"
						[disabled]="pauseButtonDisabled"
						textAlign="left"
					>
						<div class="d-flex align-items-center">
							<fa-icon [icon]="'mug-hot'" size="2x"></fa-icon><span class="ml-3" [style.font-size.rem]="1" i18n>{!api.data.completedRegularPausesDuration && !api.isPausing, select, true {Pause hinzufügen} other {Pause bearbeiten}}</span>
						</div>
					</p-button>
				</ng-template>
				<ng-template #noAddPauseMode>
					<p-button
						class="h-100"
						[theme]="pauseButtonDisabled ? enums.PThemeEnum.SECONDARY : enums.PThemeEnum.PRIMARY"
						(triggerClick)="!pauseButtonDisabled ? togglePause() : undefined"
						[disabled]="pauseButtonDisabled"
						textAlign="left"
					>
						<div class="d-flex align-items-center">
							<fa-icon [icon]="'mug-hot'" size="2x"></fa-icon><span class="ml-3" [style.font-size.rem]="1" i18n>{api.isPausing, select, true {Pause beenden} other {Pause machen}}</span>
						</div>
					</p-button>
				</ng-template>

				<p-button
					class="h-100"
					[theme]="stopButtonDisabled ? enums.PThemeEnum.SECONDARY : enums.PThemeEnum.PRIMARY"
					(triggerClick)="onStopTracking()"
					[disabled]="stopButtonDisabled"
					textAlign="left"
				>
					<div class="d-flex align-items-center">
						<fa-icon [icon]="enums.PlanoFaIconPool.AREA_TIME_STAMP" size="2x"></fa-icon><span class="ml-3" [style.font-size.rem]="1" i18n>Ausstempeln</span>
					</div>
				</p-button>
				<p-ai-switch
					#inputDateEndAiSwitchRef
					style="display: none !important;"
					[group]="formGroup"
					[attributeInfo]="api.data.attributeInfoEnd"
					[closeBtnDisabled]="api.data.end === null"
					[suggestionTimestamp]="suggestionTimestampForEnd"
					suggestionLabel="Geplante Zeit" i18n-suggestionLabel
					label="Ausstempeln" i18n-label
				></p-ai-switch>
			</div>
		</p-grid>
	</div>
</div>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Pause hinzufügen" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<p-bootstrap-form-group
			*ngIf="!!formGroup"
			label="Dauer in Minuten" i18n-label
			[control]="formGroup.controls['duration']!"
		>
			<p-input
				[formControl]="formGroup.controls['duration']!"
				[type]="PApiPrimitiveTypes.Duration"
				[durationUIType]="PApiPrimitiveTypes.Minutes"
				[inputGroupAppendIcon]="'mug-hot'"
				placeholder="z.B. »30«" i18n-placeholder
			></p-input>
		</p-bootstrap-form-group>
	</div>
	<div class="modal-footer">
		<p-button
			[theme]="PBtnThemeEnum.OUTLINE_SECONDARY"
			(triggerClick)="d($event)"
			[icon]="enums.PlanoFaIconPool.UNDO"
			i18n
		>Verwerfen</p-button>
		<p-button
			class="ml-auto"
			[theme]="enums.PThemeEnum.PRIMARY"
			[disabled]="formGroup!.controls['duration']!.invalid"
			(triggerClick)="c($event)"
			[icon]="enums.PlanoFaIconPool.SUCCESS"
			i18n
		>Speichern</p-button>
	</div>
</ng-template>
