import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';

/**
 * A grid component which is used to display a grid of columns.
 * It makes sure that all children have the necessary (col, col-*) classes.
 *
 * <p-grid> syntax is deprecated. Please use <div class="row"> instead.
 */
@Component({
	selector: 'p-grid,.row',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'pGrid',
})
export class GridComponent implements PComponentInterface, AfterContentInit {
	@HostBinding('class.row') private _alwaysTrue = true;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public justifyContent : 'center' | 'stretch' | null = null;

	@HostBinding('class.align-items-center') private get _hasClassAlignItemsCenter() : boolean {
		return this.justifyContent === 'center';
	}
	@HostBinding('class.align-items-stretch') private get _hasClassAlignItemsStretch() : boolean {
		return this.justifyContent === 'stretch';
	}

	constructor(
		public elementRef : ElementRef<HTMLElement>,
		private console : LogService,
	) {
	}

	public colsElements : HTMLElement [] = [];

	public ngAfterContentInit() : void {
		if (Config.DEBUG) this.validateContent();
	}

	private validateContent() : void {
		if (this.elementRef.nativeElement.tagName === 'P-GRID') {
			// eslint-disable-next-line literal-blacklist/literal-blacklist
			this.console.deprecated('p-grid is deprecated. Please use <div class="row"> instead.');
		}

		if (!this.elementRef.nativeElement.children.length) return;
		for (const child of this.elementRef.nativeElement.children) {
			this.colsElements.push(child as HTMLElement);
			if (child.classList.contains('col')) continue;
			let debugHint = child.textContent ?? child.classList.value;
			const hint = child.innerHTML;
			if (!debugHint) debugHint = `${hint.slice(0, 100)}${hint.length > 100 ? '…' : ''}`;
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
			if (child.classList.value.match(/col-(?:1-9|10|11)/) || child.classList.value.match(/col-[A-Za-z]*-\d{1,2}/)) {
				if (child.classList.contains('col-12')) this.console.error(`col-12 is not necessary. col-* already adds the necessary base styles. This may help to find it: ${debugHint}`);
				continue;
			}
			if (child.classList.value.match(/col-*/)) continue;
			this.console.error(`Ups. There is a child of a <p-grid> or element with class 'row' which has no 'col-*' class. This may help to find it: ${debugHint}`);
		}
	}
}
