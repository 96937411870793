import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SLIDE_IN_HORIZONTAL_ON_NGIF_TRIGGER } from '@plano/animations';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { CourseFilterService } from '@plano/client/scheduling/course-filter.service';
import { EarlyBirdService } from '@plano/client/scheduling/early-bird.service';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { BootstrapRounded, PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FilterService } from '@plano/client/shared/filter.service';
import { PExportService } from '@plano/client/shared/p-export.service';
import { PMoment, PMomentService } from '@plano/client/shared/p-moment.service';
import { ExportShiftsExcelApiService, MeService, RightsService, SchedulingApiAssignmentProcessState, SchedulingApiMemo, SchedulingApiService, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PPushNotificationsService, PRequestWebPushNotificationPermissionContext } from '@plano/shared/core/p-push-notifications.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { DropdownTypeEnum } from '@plano/shared/p-forms/p-dropdown/p-dropdown.component';
import { PInputDateComponent } from '@plano/shared/p-forms/p-input-date/p-input-date.component';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-calendar-title-bar[selectedDate]',
	templateUrl: './calendar-title-bar.component.html',
	styleUrls: ['./calendar-title-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [ SLIDE_IN_HORIZONTAL_ON_NGIF_TRIGGER ],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CalendarTitleBarComponent {
	@ViewChild('inputDateRef', { static: true }) private inputDateRef ! : PInputDateComponent;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public selectedDate ! : number;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public selectedDateChange : EventEmitter<number> = new EventEmitter<number>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onStartWishesMode = new EventEmitter<boolean>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onStartEarlyBirdMode = new EventEmitter<boolean>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public calendarMode : CalendarModes = CalendarModes.DAY;

	constructor(
		public api : SchedulingApiService,
		private meService : MeService,
		private toastsService : ToastsService,
		private filterService : FilterService,
		public courseService : CourseFilterService,
		private exportShiftsExcelApi : ExportShiftsExcelApiService,
		public schedulingService : SchedulingService,
		private textToHtmlService : TextToHtmlService,
		private pWishesService : PWishesService,
		private earlyBirdService : EarlyBirdService,
		private modalService : ModalService,
		public rightsService : RightsService,
		private pPushNotificationsService : PPushNotificationsService,
		private localize : LocalizePipe,
		private pMoment : PMomentService,
		private pExport : PExportService,
	) {
		this.now = +this.pMoment.m().startOf('day');
	}

	public enums = enumsObject;
	public BootstrapRounded = BootstrapRounded;
	public PBtnThemeEnum = PBtnThemeEnum;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;
	public DropdownTypeEnum = DropdownTypeEnum;
	public states = SchedulingApiAssignmentProcessState;

	public config : typeof Config = Config;

	private now ! : number;

	/**
	 * Open the Modal with a calendar to select a date
	 */
	public inputDateClick() : void {
		this.inputDateRef.pEditableModalButtonRef?.openEditableModal();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showMemoOfTodayBar() : boolean {
		return this.calendarMode === CalendarModes.DAY && this.api.isLoaded() && !!this.memoOfToday && !!this.memoOfToday.message;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public async onStickyNoteClick(modalContent : TemplateRef<PModalTemplateDirective>) : Promise<void> {
		if (this.calendarMode !== CalendarModes.DAY) return;
		await this.editMemo(modalContent);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get userCanManageBookings() : boolean {
		return this.rightsService.userCanManageBookings;
	}

	/**
	 * Should the evaluate and export buttons be hidden?
	 */
	public get hideEvaluateAndExportButtons() : boolean {
		return this.schedulingService.earlyBirdMode || this.freeWishesCount !== undefined;
	}

	/**
	 * Should the wish picker button be hidden?
	 */
	public get hideWishPickerButton() : boolean {
		return this.schedulingService.earlyBirdMode;
	}

	/**
	 * Open form with the details of a shift
	 */
	public showShiftDetails() : string {
		return `/client/shift/create/start/${this.selectedDate}`;
	}

	/**
	 * Open form with the details of a absence
	 */
	public showAbsenceDetails() : string {
		return '/client/absence/0';
	}

	/**
	 * Open form with the details of a booking
	 */
	public showBookingDetails() : string {
		return '/client/booking/create/';
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showMemoOfTodayButton() : boolean {
		if (this.calendarMode !== CalendarModes.DAY) return false;
		if (!this.api.isLoaded()) return false;
		if (!this.rightsService.isOwner) return false;

		if (this.memoOfToday?.message) return false;

		return true;
	}

	/**
	 * Get memo for this day
	 */
	public get memoOfToday() : SchedulingApiMemo | null {
		return this.api.data.memos.getByDay(this.selectedDate);
	}

	/**
	 * Turn the text into html [and crop it if wanted]
	 */
	public textToHtml(text : string) : string {
		return this.textToHtmlService.textToHtml(text, false, 1, false);
	}

	/**
	 * Check if given timestamp is today
	 */
	public dateIsToday(timestamp : number) : boolean {
		return this.pMoment.m(timestamp).isSame(this.now, 'day');
	}

	/**
	 * is the api currently loading?
	 */
	public get isApiLoading() : boolean {
		return this.api.isLoadOperationRunning;
	}

	/**
	 * Export for people who like microsoft more than us...
	 */
	public exportIsRunning = false;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public exportShifts() : void {
		// set shifts to be exported
		this.exportShiftsExcelApi.setEmptyData();

		for (const item of this.api.data.shifts.iterable()) {
			if (this.filterService.isVisible(item)) {
				this.exportShiftsExcelApi.data.shiftIds.createNewItem(null, item.id);
			}
		}

		// set members to be exported
		for (const member of this.api.data.members.iterable()) {
			if (this.filterService.isVisible(member))
				this.exportShiftsExcelApi.data.memberIds.push(member.id);
		}

		// set shiftModel to be exported
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			if (this.filterService.isVisible(shiftModel))
				this.exportShiftsExcelApi.data.shiftModelIds.push(shiftModel.id);
		}

		// get query parameters
		const queryParams = new HttpParams()
			.set('start', (this.schedulingService.shiftsStart).toString())
			.set('end', (this.schedulingService.shiftsEnd).toString());

		// cSpell:ignore kalenderexport
		const fileName = this.pExport.getFileName(this.localize.transform('kalenderexport'), this.schedulingService.shiftsStart, this.schedulingService.shiftsEnd - 1);

		// download file
		this.exportIsRunning = true;
		this.exportShiftsExcelApi.downloadFile(fileName, 'xlsx', queryParams, 'PUT', () => {
			this.exportIsRunning = false;
		});
	}

	/**
	 * Sends evaluation report to the user.
	 */
	public evaluateShiftPlan() : void {
		this.toastsService.addToast({
			title: this.localize.transform('Prüfung läuft…'),
			content: this.localize.transform({
				sourceString: 'Dr.&nbsp;Plano prüft deinen Plan und schickt dir das Ergebnis per Email an <strong>${email}</strong>',
				params: {
					email: this.meService.data.email,
				},
			}),
			theme: enumsObject.PThemeEnum.SUCCESS,
			visibilityDuration: 'long',
		});
		this.api.data.evaluation.generate = true;
		void this.api.save();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public stopMode() : void {
		this.resetSelection();
		if (this.config.IS_MOBILE) {
			this.filterService.showOnlyEarlyBirdAssignmentProcesses(false);
		}
		this.schedulingService.earlyBirdMode = false;
		this.askForNotificationPermissionIfNecessary();
	}

	private askForNotificationPermissionIfNecessary() : void {
		this.pPushNotificationsService.requestWebPushNotificationPermission(
			PRequestWebPushNotificationPermissionContext.CLOSED_UI_EARLY_BIRD_MODE,
		);
	}

	private resetSelection() : void {
		if (this.api.isLoaded()) this.api.data.shifts.setSelected(false);
	}

	/**
	 * Get the amount of free wishes of all time and all processes.
	 * Returns undefined if there is no process in ASKING_MEMBER_PREFERENCES state.
	 */
	public get freeWishesCount() : number | undefined {
		return this.pWishesService.freeWishesCount;
	}

	/**
	 * Get the amount of available seats for logged in member of all time and all processes.
	 * Returns undefined if there is no process in EARLY_BIRD_SCHEDULING state.
	 */
	public get freeEarlyBirdSeatsCount() : number | undefined {
		return this.earlyBirdService.freeEarlyBirdSeatsCount;
	}

	/**
	 * Space in this title-bar is rare if a lot of buttons, sidebars and stuff like this is visible.
	 */
	public get spaceIsRare() : boolean {
		if (this.config.IS_MOBILE) return true;
		if (this.api.hasSelectedItems) return true;
		return false;
	}

	/**
	 * Open form for changing the memo content
	 */
	public async editMemo(modalContent : TemplateRef<PModalTemplateDirective>) : Promise<unknown> {
		this.api.createDataCopy();
		const modalRef = this.modalService.openModal(modalContent);

		const modalRefClosingPromise = modalRef.result;

		const value = await modalRefClosingPromise;
		if (value.modalResult === 'success') {
			this.api.mergeDataCopy();
			if (!this.memoOfToday!.message.length) this.removeEditableMemo(this.memoOfToday!);

			// HACK: Sometimes there are memos without a message. i don’t know why. ¯\_(ツ)_/¯
			for (const item of this.api.data.memos.iterable()) {
				if (item.message.length) continue;
				this.api.data.memos.removeItem(item);
			}

			void this.api.save();
		} else {
			this.api.dismissDataCopy();
			if (this.memoOfToday?.isNewItem()) {
				this.removeEditableMemo(this.memoOfToday);
				void this.api.save();
			}
		}
		return value;
	}

	/**
	 * Remove a memo
	 */
	private removeEditableMemo(memo : SchedulingApiMemo) : void {
		// HACK: Sometimes there are memos without a message. i don’t know why. ¯\_(ツ)_/¯
		for (const item of this.api.data.memos.iterable()) {
			if (item.message.length) continue;
			this.api.data.memos.removeItem(item);
		}
		this.api.data.memos.removeItem(memo);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get memoModalDefaultDate() : number | undefined {
		if (this.calendarMode !== CalendarModes.DAY) return undefined;
		return this.selectedDate;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get memoDatepickerMin() : number {
		return +this.pMoment.m(this.selectedDate).startOf(this.calendarMode);
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get memoDatepickerMax() : number {
		return +this.pMoment.m(this.selectedDate).endOf(this.calendarMode);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftsForCommentsModal() : SchedulingApiShifts {
		if (!this.memoModalDefaultDate || !this.api.isLoaded()) return new SchedulingApiShifts(null, null);
		return this.api.data.shifts.filterBy(item => !!item.description);
	}

	/**
	 * Navigate to next day|week|month…
	 */
	public navTo(input : number) : void {
		const goal = this.pMoment.m(input);
		this.selectedDateChange.emit(this.cleanUpUrlTimestamp(goal));
	}

	private cleanUpUrlTimestamp(input : PMoment.Moment) : number {
		if (!input.isSame(this.now, this.calendarMode)) return +input.startOf(this.calendarMode);
		return +this.pMoment.m(this.now).startOf('day');
	}
}
