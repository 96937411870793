<div hidden><span i18n #translatedAddAbsenceLabel>{shiftRefs!.length > 1 && value!.mode === generateAbsencesModesEnum.ONE_ABSENCE_FOR_EACH, select, true {Abwesenheiten} other {Abwesenheit}} eintragen</span></div>
<p-section *ngIf="formGroup" [label]="translatedAddAbsenceLabel.textContent!" pAnchorLink="create-leave-of-absence" [whitespace]="SectionWhitespace.NONE">
	<p-bootstrap-form-group>
		<p-checkbox
			[formControl]="formGroup.controls['generateItems']!"
			[(ngModel)]="value!.generateItems"
			valueText="Im Kalender & in der Auswertung eintragen" i18n-valueText
		></p-checkbox>
	</p-bootstrap-form-group>

	<div
		*ngIf="value && value.generateItems"
		[@slideVertical]
	>
		<p-bootstrap-form-group
			*ngIf="showModeInput"
			label="Abwesenheitseintrag pro betroffene Schicht oder für alle en bloc?" i18n-label
		>
			<p-radios
				[valid]="!formGroup.controls['mode'].invalid"
				[formControl]="formGroup.controls['mode']!"
				[(ngModel)]="value.mode"
			>
				<p-radios-radio
					class="mb-2"
					label="Pro Schicht ein Eintrag" i18n-label
					[value]="generateAbsencesModesEnum.ONE_ABSENCE_FOR_EACH"
				></p-radios-radio>
				<p-radios-radio
					class="mb-2"
					label="Ein Eintrag für alle Schichten" i18n-label
					[value]="generateAbsencesModesEnum.ONE_ABSENCE_FOR_ALL"
				></p-radios-radio>
			</p-radios>
		</p-bootstrap-form-group>

		<div *ngIf="showTimeBox" class="card pt-2 px-3">

			<p-bootstrap-form-group
				*ngIf="showTimeSettingInput"
				[@slideVertical]
				label="Zeit-Angaben" i18n-label
			>
				<p-radios [formControl]="formGroup.controls['timeSetting']!">
					<div hidden><span i18n #translatedRadioLabel>{shiftRefs!.length > 1, select, true {Jeweils die } other {Die}} Schichtzeit übernehmen</span></div>
					<p-radios-radio
						class="mb-2"
						[label]="translatedRadioLabel.textContent!"
						[value]="timeSettingsEnum.TAKE_DURATION_FROM_SHIFT"
					></p-radios-radio>
					<p-radios-radio
						class="mb-2"
						label="Zeit selbst festlegen" i18n-label
						[value]="timeSettingsEnum.OVERWRITE_DURATION"
					></p-radios-radio>
				</p-radios>
			</p-bootstrap-form-group>

			<p-bootstrap-form-group
				[label]="averageWorkingTimePerDayInputLabel"
				*ngIf="showAverageWorkingTimePerDayInput"
				[@slideVertical]
			>
				<p-input
					[type]="PApiPrimitiveTypes.Duration"
					[durationUIType]="PApiPrimitiveTypes.Hours"
					[formControl]="formGroup.controls['averageWorkingTimePerDay']!"
				></p-input>
			</p-bootstrap-form-group>

			<div
				*ngIf="showAbsenceStartAndEndDateInput"
				[@slideVertical]
			>
				<p-grid>
					<p-bootstrap-form-group
						label="Von" i18n-label
						class="col"
					>
						<p-input-date
							[formControl]="formGroup.controls['absenceStartDate']!"
							[max]="$any(value.absenceEndDate)"
						></p-input-date>
					</p-bootstrap-form-group>
					<p-bootstrap-form-group
						label="Bis" i18n-label
						class="col"
					>
						<p-input-date
							[formControl]="formGroup.controls['absenceEndDate']!"
							[min]="value.absenceStartDate"
						></p-input-date>
					</p-bootstrap-form-group>
				</p-grid>
			</div>

		</div>

		<div class="card pt-2 px-3">

			<p-bootstrap-form-group label="Vergütung" i18n-label
				[control]="formGroup.controls['paid']!"
			>
				<div hidden><span i18n #translatedEarningsSettingValueText>Bezahlte {shiftRefs!.length > 1 && value.mode === generateAbsencesModesEnum.ONE_ABSENCE_FOR_EACH, select, true {Abwesenheiten} other {Abwesenheit}}</span></div>
				<p-checkbox
					[formControl]="formGroup.controls['paid']!"
					[valueText]="translatedEarningsSettingValueText.textContent!"
				></p-checkbox>
			</p-bootstrap-form-group>

			<p-bootstrap-form-group
				label="Stundenlohn" i18n-label
				*ngIf="showEarningSettingInput"
				[@slideVertical]
			>
				<p-radios [formControl]="formGroup.controls['earningSetting']!">
					<div hidden><span i18n #translatedTakeFromEachShiftRadioLabel>Stundenlohn der {shiftRefs!.length, plural, one {Schicht} other {jeweiligen Schicht}} übernehmen</span></div>
					<p-radios-radio
						class="mb-2"
						[label]="translatedTakeFromEachShiftRadioLabel.textContent!"
						[value]="earningSettingsEnum.TAKE_EARNING_FROM_EACH_SHIFT"
					></p-radios-radio>
					<p-radios-radio
						class="mb-2"
						label="Stundenlohn selbst festlegen" i18n-label
						[value]="earningSettingsEnum.OVERWRITE_EARNING"
					></p-radios-radio>
				</p-radios>
			</p-bootstrap-form-group>

			<p-bootstrap-form-group
				label="Stundenlohn" i18n-label
				*ngIf="showEarningsPerHourInput"
				[@slideVertical]
			>
				<p-input
					[type]="PApiPrimitiveTypes.ClientCurrency"
					[formControl]="formGroup.controls['earningsPerHour']!"
				></p-input>
			</p-bootstrap-form-group>
		</div>

		<p-bootstrap-form-group
			label="Sichtbarkeit des Abwesenheitseintrags" i18n-label
			description="Zur <strong>Leitung</strong> gehören User, die für eine oder mehrere Tätigkeiten, die die abwesende Person machen darf, Schreibrecht haben, sowie Admins. Zu <strong>»anderen Team-Mitgliedern«</strong> gehören alle User ohne Schreibrecht, die in mindestens einer Tätigkeit mit der abwesenden Person zusammenarbeiten dürfen oder in mindestens einer der Tätigkeiten, die die abwesende Person machen darf, ein Leserecht haben." i18n-description
		>
			<p-radios
				[valid]="!formGroup.controls['visibleToTeamMembers'].invalid"
				(onDismiss)="initFormGroup()"
				[formControl]="formGroup.controls['visibleToTeamMembers']!"
			>
				<p-radios-radio
					[value]="false"
					label="Nur für die Leitung sichtbar" i18n-label
				></p-radios-radio>
				<p-radios-radio
					[value]="true"
					label="Für Leitung & andere Team-Mitglieder sichtbar" i18n-label
				></p-radios-radio>
			</p-radios>
		</p-bootstrap-form-group>
	</div>
</p-section>
