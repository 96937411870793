import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { ApiDataWrapperBase, ApiListWrapper, PSimpleChanges } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ValidationHintService } from '@plano/shared/core/component/validation-hint/validation-hint.service';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PPossibleErrorNames } from '@plano/shared/core/validators.types';
import { PFormArrayBasedOnAI } from '@plano/shared/p-forms/p-form-control';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TListItem<List extends ApiListWrapper<any>> = Exclude<List['first'], null>;

/**
 * A component that creates a FormArray, and adds it to the provided parent.
 * Useful for attributeInfos that contain a ApiListWrapper as value.
 *
 * @example
 * ShiftModel has attribute .courseTariffs which is a list of tariffs.
 * In that case your form could look like this:
 *
 * <form [formGroup]="formGroup">
 * 	<p-ai-switch
 * 		[group]="formGroup"
 * 		[attributeInfo]="shiftModel.attributeInfoName"
 * 	></p-ai-switch>
 * 	<p-ai-form-array
 * 		[formParent]="formGroup"
 * 		[attributeInfo]="shiftModel.attributeInfoCourseTariffs"
 * 		#aiFormArrayRef
 * 	>
 * 		<p-ai-form-group
 * 			*ngFor="let tariff of shiftModel.courseTariffs.iterable()"
 * 			[formParent]="aiFormArrayRef.childArray"
 * 			[attributeInfo]="tariff.attributeInfoThis"
 * 			#aiFormGroupRef="aiFormGroupRef"
 * 		>
 * 			<p-ai-switch
 * 				[group]="aiFormGroupRef.group"
 * 				[attributeInfo]="tariff.attributeInfoName"
 * 			></p-ai-switch>
 * 			<p-ai-switch
 * 				[group]="aiFormGroupRef.group"
 * 				[attributeInfo]="tariff.attributeInfoPrice"
 * 			></p-ai-switch>
 * 		</p-ai-form-group>
 * 	</p-ai-form-array>
 */
@Component({
	selector: 'p-ai-form-array[attributeInfo][formParent]',
	templateUrl: './p-ai-form-array.component.html',
})
export class PAIFormArrayComponent<
	TParent extends ApiDataWrapperBase,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TList extends ApiListWrapper<any>,
> extends AttributeInfoBaseComponentDirective implements OnDestroy, OnChanges {
	@HostBinding('attr.aria-label') private get ariaLabel() : string | null {
		return this.label;
	}

	/**
	 * The attributeInfo that should be added to the form.
	 * @example
	 *   attributeInfo="member.attributeInfoFirstName"
	 */
	@Input() public override attributeInfo! : ApiAttributeInfo<TParent, TList>;

	/**
	 * FormGroup for the PFormControl that is related to the provided attributeInfo.
	 */
	@Input() public formParent ! : FormGroup;

	/** The headline of this component */
	@Input() public label : string | null = null;

	/** A text describing the content of this component */
	@Input() public description : string | null = null;

	/**
	 * Should the add-item button inside this component be invisible?
	 */
	@Input() public hideAddBtn : boolean = false;

	/**
	 * This component will add a add-item button. This is the text shown to the user.
	 */
	@Input() public addBtnText : string | null = null;

	/**
	 * If your add-item button is disabled, this text will be shown.
	 */
	@Input('addBtnCannotSetHint') public _addBtnCannotSetHint : PDictionarySource | null = null;

	/** When a new item gets added via the add button inside this component */
	@Output() public onAdd = new EventEmitter<void>();

	/** The code that runs when a new item gets created */
	@Input() public addItemInitCode : ((item : TListItem<TList>) => void) = () => {};

	constructor(
		protected override console : LogService,
		private pFormsService : PFormsService,
		protected override changeDetectorRef : ChangeDetectorRef,
		public validationHintService : ValidationHintService,
	) {
		super(true, changeDetectorRef, console);
	}

	public enums = enumsObject;
	public PBtnThemeEnum = PBtnThemeEnum;
	public Config = Config;

	private _childArray : PFormArrayBasedOnAI | null = null;

	public ngOnChanges(changes : PSimpleChanges<PAIFormArrayComponent<TParent, TList>>) : void {
		if (changes.formParent) {
			this.updateChildArrayFormParent();
		}
	}

	/**
	 * Update the value of the childArray whenever the formParent changes
	 */
	private updateChildArrayFormParent() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.formParent, 'group');
		this._childArray = this.pFormsService.getFormArrayByAI(this.formParent, this.attributeInfo);
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * The PFormControl that is related to the provided attributeInfo.
	 */
	public get childArray() : PFormArrayBasedOnAI {
		if (this._childArray === null) {
			// we need to update the childArray everytime the formParent changes
			this.updateChildArrayFormParent();
		}
		return this._childArray!;
	}

	private removeFormGroup() : void {
		const control = this.formParent.controls[this.attributeInfo.id] as FormGroup | undefined;
		if (!control) return;
		this.formParent.removeControl(this.attributeInfo.id);
		this.formParent.updateValueAndValidity();
		requestAnimationFrame(() => {
			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnDestroy() : void {
		this.removeFormGroup();
		this.changeDetectorRef.detectChanges();
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.childArray);
	}

	/** If user wants to add a new item, we do that here. */
	public addItem() : void {
		const newItem = this.attributeInfo.value!.createNewItem();
		this.addItemInitCode(newItem);
		this.onAdd.emit();
	}

	/** Should the internal add button be disabled? */
	public get addButtonDisabled() : boolean {
		// if (this.childArray.disabled) return true;
		if (this.childArray.hasError(PPossibleErrorNames.MAX)) return true;
		return this.childArray.controls.some(item => item.invalid);
	}

	/** cannot edit hint for the addButton */
	public get addBtnCannotSetHint() : PDictionarySource {
		if (this._addBtnCannotSetHint) return this._addBtnCannotSetHint;
		if (this.childArray.disabled) {
			this.console.warn('This case should never happen. There should always be a cannotSetHint on the ai, when the array is disabled.');
			return 'Fehler!';
		}
		return 'Einer der Einträge ist fehlerhaft.';
	}
}
