/* eslint complexity: ["error", 50]  */
import { DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PApiPrimitiveTypes, PSupportedCurrencyCodes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS } from '@plano/shared/core/pipe/decimal-pipe.consts';
import { PDurationTimePipe } from '@plano/shared/core/pipe/duration-time.pipe';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe, LocalizePipeParamsType } from '@plano/shared/core/pipe/localize.pipe';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { AngularDatePipeFormat, PDateFormat, PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidationErrorValue, ValidatorsServiceReturnType } from '@plano/shared/core/validators.types';

@Injectable({providedIn: 'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ValidationHintService {
	constructor(
		private localize : LocalizePipe,
		private decimalPipe : DecimalPipe,
		private console : LogService,
		private datePipe : PDatePipe,
		private currencyPipe : PCurrencyPipe,
		private percentPipe : PercentPipe,
		private pDurationTimePipe : PDurationTimePipe,
	) { }

	// error : ValidatorsServiceReturnType<'min'>
	// TODO: min() now returns PValidatorObject. So ValidatorsServiceReturnType only works for PValidatorFn.
	private getMinText(error : ValidatorsServiceReturnType<'min'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) {
			return this.localize.transform({
				sourceString: 'Diese Eingabe darf nicht kleiner sein als »${labelOfComparedControl}«.',
				params: {labelOfComparedControl: labelOfComparedControl},
			});
		}

		if (!error.errorText && error.min === 0) return this.localize.transform('Bitte keine negativen Zahlen.');

		let errorText : PDictionarySourceString | undefined = undefined;
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		type TMinErrorObj = Omit<ValidatorsServiceReturnType<'min'>, 'min'> & {min : number | string};
		let errorObj : TMinErrorObj = { ...error, min: error['min'] };
		switch (error.primitiveType) {
			case PApiPrimitiveTypes.LocalTime:
				errorText = errorText ?? 'Bitte mindestens die Zeit »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(
					error.min as number,
					PDateFormat.VERY_SHORT_TIME,
					true,
				)};
				break;
			case PApiPrimitiveTypes.DateTime:
				errorText = errorText ?? 'Bitte mindestens die Zeit »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(error.min as number, AngularDatePipeFormat.SHORT)};
				break;
			case PApiPrimitiveTypes.Date:
				errorText = errorText ?? 'Bitte mindestens das Datum »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(error.min as number)};
				break;
			case PApiPrimitiveTypes.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte mindestens das Datum »${min}« eingeben.';
				errorObj = {...error, min: this.datePipe.transform(error.min as number - 1)};
				break;
			case PApiPrimitiveTypes.Days:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				break;
			case PApiPrimitiveTypes.ClientCurrency:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: this.currencyPipe.transform(error['min'] as number, Config.CURRENCY_CODE)};
				break;
			case PApiPrimitiveTypes.Euro:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: this.currencyPipe.transform(error['min'] as number, 'EUR')};
				break;
			case PApiPrimitiveTypes.Percent:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				const min = this.percentPipe.transform((error.min as number));
				assumeNonNull(min);
				errorObj = {...error, min: min};
				break;
			case PApiPrimitiveTypes.Duration:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: this.pDurationTimePipe.transform(error['min'], false, false, true)};
				break;
			case PApiPrimitiveTypes.Integer:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: `${Math.floor(+error['min'])}`};
				break;
			case PApiPrimitiveTypes.ApiList:
				errorText = errorText ?? 'Bitte mindestens »${min}« anlegen.';
				errorObj = {...error, min: `${Math.floor(+error['min'])}`};
				break;
			default:
				errorText = errorText ?? 'Bitte mindestens »${min}« eingeben.';
				errorObj = {...error, min: !Number.isNaN(error['min']) ? this.decimalPipe.transform(error['min'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)! : error['min']};
				break;
		}
		return this.localize.transform({
			sourceString: errorText,
			params: errorObj,
		}, false);
	}

	// error : ValidatorsServiceReturnType<'min'>
	private getMaxText(error : ValidatorsServiceReturnType<'max'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) {
			return this.localize.transform({
				sourceString: 'Diese Eingabe darf nicht größer sein als »${labelOfComparedControl}«.',
				params: {labelOfComparedControl: labelOfComparedControl},
			});
		}

		let errorText : PDictionarySourceString | undefined = undefined;
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		let errorObj : ValidatorsServiceReturnType<'max'> = { ...error };
		switch (error.primitiveType) {
			case PApiPrimitiveTypes.LocalTime:
				errorText = errorText ?? 'Bitte höchstens die Uhrzeit »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'] as number, PDateFormat.VERY_SHORT_TIME, true)};
				break;
			case PApiPrimitiveTypes.DateTime:
				errorText = errorText ?? 'Bitte höchstens die Zeit »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'] as number, AngularDatePipeFormat.SHORT)};
				break;
			case PApiPrimitiveTypes.Date:
				errorText = errorText ?? 'Bitte höchstens das Datum »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'] as number)};
				break;
			case PApiPrimitiveTypes.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte höchstens das Datum »${max}« eingeben.';
				errorObj = {...error, max: this.datePipe.transform(error['max'] as number - 1)};
				break;
			case PApiPrimitiveTypes.Days:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				break;
			case PApiPrimitiveTypes.ClientCurrency:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.currencyPipe.transform(error['max'] as number, Config.CURRENCY_CODE)};
				break;
			case PApiPrimitiveTypes.Euro:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.currencyPipe.transform(error['max'] as number, 'EUR')};
				break;
			case PApiPrimitiveTypes.Percent:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.percentPipe.transform((error['max'] as number))};
				break;
			case PApiPrimitiveTypes.Duration:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: this.pDurationTimePipe.transform(error['max'], false, false, true)};
				break;
			case PApiPrimitiveTypes.Integer:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				errorObj = {...error, max: `${Math.floor(+error['max'])}`};
				break;
			case PApiPrimitiveTypes.ApiList:
				errorText = errorText ?? 'Bitte höchstens »${max}« anlegen.';
				errorObj = {...error, max: `${Math.floor(+error['max'])}`};
				break;
			default:
				errorText = errorText ?? 'Bitte höchstens »${max}« eingeben.';
				if (!Number.isNaN(error['max'])) {
					errorObj = {...error, max: this.decimalPipe.transform(error['max'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!};
				} else errorObj = error;
		}

		return this.localize.transform({
			sourceString: errorText,
			params: errorObj,
		}, false);
	}

	private getGreaterThanText(error : ValidatorsServiceReturnType<'greaterThan'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) return this.localize.transform({
			sourceString: 'Eingabe muss größer sein als der Wert von »${labelOfComparedControl}«.',
			params: {labelOfComparedControl: labelOfComparedControl},
		});

		let errorText : PDictionarySourceString | undefined = undefined;
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		switch (error.primitiveType) {
			case PApiPrimitiveTypes.LocalTime:
				errorText = errorText ?? 'Bitte eine Zeit später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan, PDateFormat.VERY_SHORT_TIME, true)},
				});
			case PApiPrimitiveTypes.DateTime:
				errorText = errorText ?? 'Bitte eine Zeit später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan, AngularDatePipeFormat.SHORT)},
				});
			case PApiPrimitiveTypes.Date:
				errorText = errorText ?? 'Bitte ein Datum später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan)},
				});
			case PApiPrimitiveTypes.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte ein Datum später als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: this.datePipe.transform(error.greaterThan - 1)},
				});
			case PApiPrimitiveTypes.Percent:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				const greaterThan = this.percentPipe.transform(error.greaterThan);
				assumeNonNull(greaterThan);
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: greaterThan},
				});
			case PApiPrimitiveTypes.ClientCurrency:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: { greaterThan: this.currencyPipe.transform(error['greaterThan'], Config.CURRENCY_CODE)},
				});
			case PApiPrimitiveTypes.Euro:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: { greaterThan: this.currencyPipe.transform(error['greaterThan'], 'EUR')},
				});
			case PApiPrimitiveTypes.Integer:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: `${Math.floor(+error['greaterThan'])}`},
				});
			case PApiPrimitiveTypes.Duration:
				errorText = errorText ?? 'Bitte einen Wert größer als »${greaterThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {greaterThan: `${this.pDurationTimePipe.transform(+error['greaterThan'], false, false, true)}`},
				});
			default:
				errorText = errorText ?? 'Bitte eine Zahl größer als »${greaterThan}« eingeben.';
				return this.localize.transform({sourceString: errorText, params: error}, false);
		}
	}

	private fromKbToMb(kb : number) : number {
		return kb / 1024.0;
	}

	private getLessThanText(error : ValidatorsServiceReturnType<'lessThan'>, labelOfComparedControl : string | null) : string {
		if (labelOfComparedControl) return this.localize.transform({
			sourceString: 'Eingabe muss kleiner sein als »${labelOfComparedControl}«.',
			params: {labelOfComparedControl: labelOfComparedControl},
		});

		let errorText : PDictionarySourceString | undefined = undefined;
		if (error.errorText) errorText = typeof error.errorText === 'string' ? error.errorText : error.errorText();

		switch (error.primitiveType) {
			case PApiPrimitiveTypes.LocalTime:
				errorText = errorText ?? 'Bitte eine Zeit früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'] as number, PDateFormat.VERY_SHORT_TIME, true)},
				});
			case PApiPrimitiveTypes.DateTime:
				errorText = errorText ?? 'Bitte eine Zeit früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'] as number, AngularDatePipeFormat.SHORT)},
				});
			case PApiPrimitiveTypes.Date:
				errorText = errorText ?? 'Bitte ein Datum früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'] as number)},
				});
			case PApiPrimitiveTypes.DateExclusiveEnd:
				errorText = errorText ?? 'Bitte ein Datum früher als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: this.datePipe.transform(error['lessThan'] as number - 1)},
				});
			case PApiPrimitiveTypes.Percent:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				const lessThan = this.percentPipe.transform((error['lessThan'] as number));
				assumeNonNull(lessThan);
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: lessThan},
				});
			case PApiPrimitiveTypes.ClientCurrency:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({ sourceString: errorText, params: { lessThan: this.currencyPipe.transform(error['lessThan'] as number, Config.CURRENCY_CODE) }});
			case PApiPrimitiveTypes.Euro:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({ sourceString: errorText, params: { lessThan: this.currencyPipe.transform(error['lessThan'] as number, 'EUR')}});
			case PApiPrimitiveTypes.Integer:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: `${this.decimalPipe.transform(Math.floor(+error['lessThan']), DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!}`},
				});
			case PApiPrimitiveTypes.number:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: `${this.decimalPipe.transform(+error['lessThan'], DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!}`},
				});
			case PApiPrimitiveTypes.Duration:
				errorText = errorText ?? 'Bitte einen Wert kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({
					sourceString: errorText,
					params: {lessThan: `${this.pDurationTimePipe.transform(+error['lessThan'], false, false, true)}`},
				});
			default:
				errorText = errorText ?? 'Bitte eine Zahl kleiner als »${lessThan}« eingeben.';
				return this.localize.transform({sourceString: errorText, params: error}, false);
		}
	}

	/* eslint complexity: ["error", 150] sonarjs/cognitive-complexity: ["error", 180] */
	/**
	 * Get an error value object, and generate a human readable error message text from it.
	 *
	 * @param error The error value object
	 * @param labelOfComparedControl If the error is compared to another control, this is the label of the other control.
	 */
	public getErrorText(error : PValidationErrorValue, labelOfComparedControl : string | null) : string {
		const name : PValidationErrorValue['name'] = error.name;
		const customErrorText = error.errorText ? (typeof error.errorText === 'string' ? error.errorText : error.errorText()) : null;
		const customMsg = customErrorText ? this.localize.transform({sourceString: customErrorText, params: error as LocalizePipeParamsType}, false) : null;
		switch (name) {
			case PPossibleErrorNames.MIN_LENGTH :
				if (customMsg) return customMsg;
				return this.minLengthText(error);
			case PPossibleErrorNames.MAX :
				return this.getMaxText(error as ValidatorsServiceReturnType<'max'>, labelOfComparedControl);
			case PPossibleErrorNames.MIN :
				return this.getMinText(error as ValidatorsServiceReturnType<'min'>, labelOfComparedControl);
			case PPossibleErrorNames.REQUIRED :
			case PPossibleErrorNames.ID_DEFINED :
			case PPossibleErrorNames.NOT_UNDEFINED :
				if (customMsg) return customMsg;
				return this.localize.transform('Diese Eingabe ist Pflicht.');
			case PPossibleErrorNames.PLZ :
				if (customMsg) return customMsg;
				return this.localize.transform('Das ist keine gültige Postleitzahl.');
			case PPossibleErrorNames.UPPERCASE :
				if (customMsg) return customMsg;
				return this.localize.transform('Nur Großbuchstaben erlaubt.');
			case PPossibleErrorNames.GREATER_THAN :
				return this.getGreaterThanText(error as ValidatorsServiceReturnType<'greaterThan'>, labelOfComparedControl);
			case PPossibleErrorNames.LESS_THAN :
				return this.getLessThanText(error, labelOfComparedControl);
			case PPossibleErrorNames.FLOAT :
				if (customMsg) return customMsg;
				return this.floatText();
			case PPossibleErrorNames.INTEGER :
				if (customMsg) return customMsg;
				return this.localize.transform({
					sourceString: 'Bitte nur ganze Zahlen eingeben, z.B. »${example}«.',
					params: { example: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)! },
				});
			case PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT :
				if (customMsg) return customMsg;
				return this.maxDecimalPlacesCountText(error);
			case PPossibleErrorNames.CURRENCY :
				if (customMsg) return customMsg;
				return this.currencyText(error);
			case PPossibleErrorNames.TIME :
				if (customMsg) return customMsg;
				return this.timeText();
			case PPossibleErrorNames.EMAIL_WITHOUT_AT :
				if (customMsg) return customMsg;
				return this.localize.transform('Es fehlt das »@« Zeichen.');
			case PPossibleErrorNames.URL_PROTOCOL_MISSING :
				if (customMsg) return customMsg;
				return this.localize.transform('Bitte mit »http://« oder »https://« beginnen.');
			case PPossibleErrorNames.DOMAIN :
			case PPossibleErrorNames.URL :
			case PPossibleErrorNames.EMAIL :
			case PPossibleErrorNames.IBAN :
			case PPossibleErrorNames.BIC :
				if (customMsg) return customMsg;
				return this.localize.transform('Falsches Format eingegeben.');
			case PPossibleErrorNames.PHONE :
				if (customMsg) return customMsg;
				return this.localize.transform('Falsches Format eingegeben. Richtiges Beispiel: »+491230000000«');
			case PPossibleErrorNames.MAX_LENGTH :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Bitte maximal ${requiredLength} Zeichen eingeben. ${actualLength} ist zu viel.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.WHITESPACE :
				if (customMsg) return customMsg;
				return this.whitespaceText(error);
			case PPossibleErrorNames.NUMBERS_REQUIRED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Passwort muss eine Zahl enthalten.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.LETTERS_REQUIRED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Passwort muss einen Buchstaben enthalten.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.UPPERCASE_REQUIRED :
				if (customMsg) return customMsg;
				return this.localize.transform('Das Passwort muss einen Großbuchstaben enthalten.');
			case PPossibleErrorNames.EMAIL_INVALID :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Diese Email-Adresse scheint nicht zu existieren.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.EMAIL_USED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Diese Email Adresse ist bereits benutzt.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.NUMBER_NAN :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: '»${actual}« ist keine Zahl.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.PASSWORD_UNCONFIRMED :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Die Passwort-Wiederholung stimmt nicht.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.IMAGE_MIN_HEIGHT :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Bild ist nicht hoch genug.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.IMAGE_MIN_WIDTH :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Das Bild ist nicht breit genug.', params: error as LocalizePipeParamsType}, false);
			case PPossibleErrorNames.MAX_FILE_SIZE :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Deine Datei ist zu groß. Die maximal zulässige Größe beträgt ${expected} MB.', params: {expected: `${this.fromKbToMb(error['expected'])}`}}, false);
			case PPossibleErrorNames.IMAGE_RATIO :
				if (customMsg) return customMsg;
				return PPossibleErrorNames.IMAGE_RATIO;
			case PPossibleErrorNames.URL_INCOMPLETE:
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'URL unvollständig', params: error as LocalizePipeParamsType}, false);
			case undefined :
				this.console.error('error.name should never be undefined');
				return 'undefined';
			case PPossibleErrorNames.PATTERN :
			case PPossibleErrorNames.PASSWORD :
			case PPossibleErrorNames.PDF_MAX_PAGES :
				if (customMsg) return customMsg;
				return this.localize.transform({sourceString: 'Lade bitte eine Datei mit einer Seite hoch; deine Datei hat ${actual} Seiten.', params: {actual: error.actual}});
			case PPossibleErrorNames.PDF_PAGE_DIMENSION :
				if (customMsg) return customMsg;
				return this.localize.transform('Lade bitte eine Datei in DIN A4 Format (210 x 297 mm) hoch.');
			case PPossibleErrorNames.IMAGE_MAX_HEIGHT :
			case PPossibleErrorNames.IMAGE_MAX_WIDTH :
				if (customMsg) return customMsg;
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.FIRST_FEE_PERIOD_START_IS_NULL :
				if (customMsg) return customMsg;
				return this.localize.transform('Der Start deiner letzten Zeitspanne sollte »UNBEGRENZT« sein.');
			case PPossibleErrorNames.FREE_PRICE_CHOICE_OR_PREDEFINED_PRICES_NOT_SET :
				if (customMsg) return customMsg;
				return this.localize.transform('Bitte entweder vordefinierte Werte angeben oder die Wahl von individuellen Werten erlauben.');
			case PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD :
				if (customMsg) return customMsg;
				return this.localize.transform('Diese Zahlungsart existiert bereits.');
			case PPossibleErrorNames.DUPLICATE_PREDEFINED_PRICES :
				if (customMsg) return customMsg;
				return this.localize.transform('Dieser Wert existiert bereits.');
			case PPossibleErrorNames.ONE_WEEK_DAY_IS_SELECTED :
				if (customMsg) return customMsg;
				return this.localize.transform('Bitte mindestens einen Wochentag auswählen.');
			case PPossibleErrorNames.OCCUPIED :
			case PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME :
				if (customMsg) return customMsg;
				return this.localize.transform('Ist bereits vergeben.');
			case PPossibleErrorNames.ENSURE_NULL :
				this.console.warn('ENSURE_NULL should never touch the UI');
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
			case PPossibleErrorNames.EMAIL_HAS_ERROR :
				this.console.warn('EMAIL_HAS_ERROR should never touch the UI');
				return this.localize.transform('Überprüfe bitte deine Eingabe.');
		}
	}

	private minLengthText(error : PValidationErrorValue) : string {
		if (error.primitiveType === PApiPrimitiveTypes.ApiList) return this.localize.transform({sourceString: 'Mindestens ${requiredLength} Einträge nötig (aktuell nur ${actualLength} vorhanden).', params: error as LocalizePipeParamsType}, false);
		return this.localize.transform({sourceString: 'Bitte mindestens ${requiredLength} Zeichen eingeben. ${actualLength} ist zu wenig.', params: error as LocalizePipeParamsType}, false);
	}

	private floatText() : string {
		return this.localize.transform({
			sourceString: 'Bitte nur Zahlen eingeben, z.B. »${example1}«.',
			params: {
				example1: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!,
			},
		});
	}

	private maxDecimalPlacesCountText(error : PValidationErrorValue) : string {
		if (error['maxDigitsLength'] === 0) {
			return this.localize.transform({
				sourceString: 'Bitte nur ganze Zahlen eingeben, z.B. »${example}«.',
				params: { example: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)! },
			});
		}
		return this.localize.transform({sourceString: 'Bitte höchstens ${maxDigitsLength} Nachkommastellen eintragen.', params: error as LocalizePipeParamsType}, false);
	}

	private currencyText(error : PValidationErrorValue) : string {
		const example1 = this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!;
		let example2 = this.currencyPipe.transform(12.5, (error['currencyCode'] ?? Config.CURRENCY_CODE) as PSupportedCurrencyCodes, '');
		if (example2) example2 = example2.trim();
		return this.localize.transform({
			sourceString: 'Falsches Format eingegeben. Richtig wären z.B. »${example1}« oder »${example2}«.',
			params: {
				example1: example1,
				example2: example2,
			},
		});
	}

	private timeText() : string {
		return this.localize.transform({
			sourceString: 'Bitte eine Uhrzeit eingeben, z.B. »${example1}« oder »${example2}«.',
			params: {
				example1: this.datePipe.transform(34200000, 'veryShortTime'), // ➡ 9:30
				example2: this.datePipe.transform(86340000, 'veryShortTime'), // ➡ 23:59
			},
		});
	}

	private whitespaceText(error : PValidationErrorValue) : string {
		if (error.primitiveType === PApiPrimitiveTypes.Tel) {
			return this.localize.transform({sourceString: 'Bitte keine Leerzeichen am Anfang oder Ende der Telefonnummer eingeben.', params: error as LocalizePipeParamsType}, false);
		}
		return this.localize.transform({sourceString: 'Bitte keine Leerzeichen eingeben.', params: error as LocalizePipeParamsType}, false);
	}
}
