import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { enumsObject } from './the-enum-object';

// It is necessary to wrap PlanoFaIconPool into this fn
// in order to create a PlanoFaIconPoolValues later

// https://stackoverflow.com/a/53662389/1537751
const typeForceFAIcon = <T extends { [key : string] : PFaIcon }>(i : T) : T => {
	return i;
};

const actions = typeForceFAIcon({
	ADD: ['solid', 'plus'],
	EDIT: 'pen',
	EDIT_COLOR: 'paintbrush',
	UNDO: 'arrow-rotate-left',
	SEARCH: ['solid', 'magnifying-glass'],
	ZOOM_IN: 'magnifying-glass-plus',
	ZOOM_OUT: 'magnifying-glass-minus',
	UPLOAD: 'upload',
	EXECUTE: 'play',
	PAUSE: 'pause',
	DOWNLOAD: 'download',
	DISMISS: ['solid', 'xmark'], // cSpell:ignore xmark
	SUCCESS: 'check',
	MORE_INFO: 'info',
	TOOLTIP_INFO: ['regular', 'circle-info'],
	TIP: 'lightbulb',
	TOOLTIP_WARNING: ['regular', 'circle-question'],
	DELETE: ['solid', 'trash'],
	RESTORE: 'trash-arrow-up',
	MORE_ACTIONS: ['solid', 'ellipsis'],
	EXTERNAL_LINK: 'up-right-from-square',
	LINK: 'link',
	UNLINK: 'link-slash',
	SEND_EMAIL: 'paper-plane',
	EVALUATE_SHIFT_PLAN: 'flask',
	STATISTICS_EXPORT: 'chart-pie',
	THANK: 'hand-holding-heart',

	COPY_TO_CLIPBOARD: 'copy',
	LOGOUT: 'arrow-right-from-bracket',
	LOGIN: 'right-to-bracket',

	EXPORT: 'up-right-from-square',
	PDF_FILE: 'file-pdf',
	EXCEL_FILE: 'file-excel',
	CSV_FILE: 'file-csv',

	SORT_ASCENDING: 'caret-down',
	SORT_DESCENDING: 'caret-up',

	NAV_BACK: ['solid', 'arrow-left'],
	NAV_FORWARD: 'arrow-right',
	NAV_NEW_TAB: 'arrow-up-right-from-square',
	NAV_EXTERNAL: 'sign-in-alt',
	SCROLL_DOWN: 'arrow-down',
	SCROLL_UP: 'arrow-up',
	NEXT: 'angle-right',
	PREV: 'angle-left',
	INCREASE: 'circle-arrow-up',
	DECREASE: 'circle-arrow-down',

	HOURGLASS_HALF: 'hourglass',
	OPEN_MAIL: 'envelope-open-text',

	PARTICIPATED: ['solid', 'trophy'],
});

const rights = typeForceFAIcon({
	CAN_READ: ['duotone', 'eye'],
});

const states = typeForceFAIcon({
	TRASHED: ['solid', 'trash'],
	SYNCING: 'arrows-rotate',
	CANCELED: ['solid', 'xmark'],
	LOADING: 'spinner-third',
	NOT_POSSIBLE: 'circle-xmark',
	LOCKED: ['solid', 'lock'],
	EXPIRED: 'alarm-clock',
	OK: 'circle-check',
	VALUE_UNKNOWN: ['regular', 'circle-question'],
	ACKNOWLEDGED:  ['regular', 'circle-check'],

	ALLOWED: 'badge-check',
	NOT_ALLOWED: ['solid', 'ban'],

	COLLAPSIBLE_CLOSE: 'chevron-down',
	COLLAPSIBLE_CLOSE_TO_LEFT: 'chevron-left',
	COLLAPSIBLE_OPEN: 'chevron-up',

	DROPDOWN_CLOSE: 'caret-down',
	DROPDOWN_OPEN: 'caret-up',

	CHECKBOX_SELECTED: ['solid', 'square-check'],
	CHECKBOX_UNSELECTED: ['regular', 'square'],

	RADIO_SELECTED: ['solid', 'circle'],
	RADIO_UNSELECTED: ['regular', 'circle'],

	VISIBLE: ['duotone', 'eye'],
	INVISIBLE: 'eye-slash',

	BOOKING_BOOKED: 'check',
	BOOKING_CANCELED: ['solid', 'xmark'],
	BOOKING_DECLINED: ['solid', 'xmark'],
	BOOKING_INQUIRY: 'inbox',
	BOOKING_PAYMENT_STATUS: 'coins',
	BOOKING_COMMENT: ['regular', 'comment-dots'],

	CALENDAR_SELECT_RELATED_SHIFTS: 'calendar-check',
	CALENDAR_DAY: 'calendar-day',
	CALENDAR_WEEK: 'calendar-week',
	CALENDAR_MONTH: 'calendar',
	CALENDAR_EXCLUDE: 'calendar-times',
	CLOCK:'clock',

	STATE_DATE_PICKED: 'calendar-days',
	STATE_DATE_EMPTY: ['regular', 'calendar-days'],

	EXCHANGE_SHIFT: 'right-left',
	EXCHANGE_SHIFT_OFFER: 'right-long',

	ORDER_ASCENDING: 'arrow-down-1-9',

	EARLY_BIRD: ['duotone', 'crow'],
	AUTO_SCHEDULING: 'wand-magic-sparkles',
	MANUAL_SCHEDULING: ['regular','hand-point-up'],
});

const apiObjects = typeForceFAIcon({
	ITEMS_MEMBER: 'user',
	ITEMS_MEMBERS: 'users',
	ITEMS_PARTICIPANT: 'users',
	ITEMS_ABSENCE_ILLNESS: ['solid','briefcase-medical'],
	ITEMS_ABSENCE_VACATION: 'umbrella-beach',
	ITEMS_ASSIGNMENT_PROCESS: ['solid', 'heart'],
	ITEMS_SHIFT_EXCHANGE: 'handshake-angle',
	ITEMS_BOOKING_PERSON: 'user',
	ITEMS_PARTICIPANTS: 'user-group',
	ITEMS_SALES: ['solid', 'bag-shopping'],
	ITEMS_SHIFTS: ['regular', 'calendar-days'],
	ITEMS_SHIFT_MODELS: 'layer-group',
});

const areasMenus = typeForceFAIcon({
	AREA_SCHEDULING: 'calendar-days',
	AREA_TIME_STAMP: 'stopwatch',
	AREA_TUTORIALS: actions.TIP,
	AREA_TRANSACTIONS: 'coins',
	AREA_FAQ: ['solid', 'circle-question'],
	DESK: 'inbox',
	HOUSE: 'house',
});

const tabs = typeForceFAIcon({
	TAB_BASIC_DATA: 'square-info',
	TAB_EMAIL_TEMPLATES: 'envelopes',
	TAB_PLUGIN: 'browser',
	TAB_ACCOUNTING: 'book-section',
	TAB_DATA_PROTECTION: 'user-secret',
});

const brands = typeForceFAIcon({
	BRAND_YOUTUBE: ['brands', 'youtube'],
	BRAND_INSTAGRAM: ['brands', 'instagram'],
	BRAND_FACEBOOK: ['brands', 'facebook-square'],
	BRAND_PAYPAL: ['brands', 'paypal'],
	BRAND_INTERNET_EXPLORER: ['brands', 'internet-explorer'],
	BRAND_ANDROID: ['brands', 'android'],
	BRAND_APPLE: ['brands', 'apple'],
	CREATIVE_COMMONS: ['brands', 'creative-commons-nc-eu'],
});

const currencies = typeForceFAIcon({
	EUR: ['solid', 'euro-sign'],
	GBP: 'sterling-sign',
});

const components = typeForceFAIcon({
	IMAGE_UPLOAD: ['solid', 'image'],
	FILTER: ['solid', 'filter'],
	LIST: 'list',
	NOTE : 'note-sticky',
});

const other = typeForceFAIcon({
	INTERNET: ['solid', 'earth-americas'],
	PUSH_NOTIFICATION: ['solid', 'bell'],
	NO_PUSH_NOTIFICATION: 'bell-slash',
	EMAIL_NOTIFICATION: 'envelope',
	THUMBS_UP: 'thumbs-up',
	TEXT_AREA: 'money-check-pen',
	EMAIL_AT: 'at',
	QUESTION: 'question',

	BINARY_FILE: 'file-binary',

	MARKETING: 'megaphone',
	MARKETING_BADGE: ['solid', 'megaphone'],
	DIFFERENT: ['solid', 'not-equal'],

	RISING: 'chart-line-up',

	MOBILE_MENU: 'bars',
	PAYMENT: 'share',
	REFUND: 'reply',
	VIRUS: 'virus',
	CHARGEBACK_FLOW: 'arrow-right',
	BIRTHDAY: 'cake-candles',
	TEXT_LEFT: 'align-left',
	GRID_MENU: ['solid','grid-dividers'],
	BAR_CODE: 'barcode',
	SCHEDULING: 'user-group',
	ONLINE_PAYMENT: 'laptop',
	GIFT_CARD: 'gift-card',
	PAY_BY_CASH: 'cash-register',

	SOMETHING_WENT_WRONG: 'cat',
	EQUALS: ['solid', 'equals'],
	PERCENTAGE: 'percentage',
	CALCULATOR: 'calculator',
	NUMBER: ['solid', 'hashtag'],
	RIGHTS: 'shield-check',

	PRINTER: 'print',
	CODE_GENERATOR: 'slot-machine',

	CAMERA: 'camera',
	API: 'webhook',
	RELAXED: ['solid', 'face-smile-relaxed'],
	PAY_ATTENTION: ['solid', 'hand-point-up'],
	PIPE_SEPARATOR: ['solid', 'pipe'],
	NO_ACCESS: 'hand',
});

const alerts = typeForceFAIcon({
	WARNING: ['solid', 'exclamation-triangle'],
	SECURITY: 'shield-blank',
	DANGER: 'bolt',
});

  type AllTheIconsObjects = typeof actions & typeof rights & typeof states & typeof apiObjects & typeof areasMenus
	& typeof brands & typeof currencies & typeof components & typeof other & typeof alerts & typeof tabs;

/**
 * A Set of all Icons available in our App.
 * @example
 *   In ts file:
 *     public ICONS = PlanoFaIconPool;
 *   In template:
 *     <fa-icon [icon]="enums.PlanoFaIconPool.ITEMS_SHIFT_EXCHANGE"></fa-icon>
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const PlanoFaIconPool : AllTheIconsObjects = {
	...actions,
	...rights,
	...states,
	...apiObjects,
	...areasMenus,
	...brands,
	...currencies,
	...components,
	...other,
	...alerts,
	...tabs,
} as const;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PlanoFaIconPoolKeys = keyof typeof enumsObject.PlanoFaIconPool;

/**
 * All available icons in our pool
 * @example
 *   @Input() public icon : PlanoFaIconPoolValues | `${IconName}`;
 */
export type PlanoFaIconPoolValues = typeof enumsObject.PlanoFaIconPool[PlanoFaIconPoolKeys];
