<div
	[class]="'o-hidden '+bgClass+' '+formControlStyles"
	[class.mb-1]="cannotSetHint && (disabled || readMode)"
	[class.card]="hasCardStyle"
	[class.border-primary]="hasClassBorderPrimary"
	[class.required]="hasClassRequired"
	[class.border-danger]="hasClassBorderDanger"
>
<ng-template [ngIf]="showSimpleReadOnlyMode" [ngIfElse]="showHeader ? showComplexModeTemplate : null">
	<div [id]="ariaLabelHtmlId" class="form-control-read-mode">
		<ng-template [ngIf]="!label && !headless"><ng-container *ngTemplateOutlet="header"></ng-container></ng-template>
		<ng-template [ngIf]="!!label">
			<div
				[class.text-white]="theme==='dark'"
				class="flex-grow-1 d-flex justify-content-start align-items-center crop-on-overflow"
			>
				<span class="mr-2 mb-0 overflow-hidden text-wrap" [title]="label">{{ label }}</span>
				<span><ng-container *ngTemplateOutlet="header"></ng-container></span>
			</div>
		</ng-template>
	</div>
</ng-template>
<ng-template #showComplexModeTemplate>
	<div
		*ngIf="!headless"
		[id]="ariaLabelHtmlId"
		class="card-options align-items-stretch o-hidden flex-grow-1"
		[class.border-bottom-0]="!showShowroom || disabled"
		[class.card-header]="hasCardStyle"
	>
		<div class="flex-grow-1 d-flex justify-content-between align-items-stretch crop-on-overflow"
			[style.padding]="!!label ? editableBoxHeaderPadding : ''"
		>
			<ng-template [ngIf]="!label"><ng-container *ngTemplateOutlet="header"></ng-container></ng-template>
			<ng-template [ngIf]="!!label">
				<div
					[class.text-white]="theme==='dark'"
					class="flex-grow-1 d-flex justify-content-start align-items-center crop-on-overflow"
					[class.text-danger]="isValid===false"
				>
					<label class="mr-2 mb-0 overflow-hidden text-wrap" [title]="label">{{ label }}</label>
					<span><ng-container *ngTemplateOutlet="header"></ng-container></span>
				</div>
			</ng-template>
		</div>
		<ng-container *ngTemplateOutlet="showShowroom ? null : buttonForModal; context: {$implicit: false}"></ng-container>
		<div class="ml-auto d-flex"
			[pEditable]="pEditable"
			[api]="api"
			[valid]="isValid"
			[saveChangesHook]="saveChangesHook"
			(onSaveStart)="onSaveStart.emit($event)"
			(onSaveSuccess)="onSaveSuccess.emit($event)"
			(onDismiss)="onDismiss.emit($event)"
			(onLeaveCurrent)="onLeaveCurrent.emit()"
			(editMode)="updateEditMode($event)"
		>
			<p-delete-button
				*ngIf="!disabled && onRemoveItemClick.observers.length > 0 && !hideRemoveBtn"
				class="card-option rounded-0 nowrap btn-light"
				[modalText]="removeModalText"
				(onModalSuccess)="onRemoveItemClick.emit($event)"
				[label]="removeModalButtonLabel"
				[disabled]="boxEditMode || removeButtonDisabled"
			></p-delete-button>
		</div>
	</div>
</ng-template>
<div #showroom [class.d-none]="!showShowroom">
	<ng-container *ngTemplateOutlet="buttonForModal; context: {$implicit: true}"></ng-container>
</div>
<ng-template #header>
	<ng-content select="p-editable-modal-box-header"></ng-content>
</ng-template>

<ng-template #buttonForModal let-showroomInside>
	<div
		type="button"
		role="button"
		[disabled]="disabled || boxEditMode"
		[tabindex]="disabled || boxEditMode ? -1 : 0"
		[class.editable-box-cursor]="!disabled && !readMode && !btnIcon"
		style="opacity: 1 !important;"
		[class]="'d-flex align-items-stretch ml-auto card-option btn-light ' + btnClassesInsideShowroom"
		title="{{(disabled || readMode) ? null : ariaLabel}}"
		attr.aria-label="{{ariaLabel}}"

		[hidden]="hideEditButton === true"

		[pTooltip]="showEditButtonPopover ? editButtonPopover : null"
		[pTooltipPlacement]="showShowroom ? NgxPopperjsPlacements.BOTTOM : NgxPopperjsPlacements.LEFT"

		#modalButtonRef
		pEditableModalButton
		[modalSize]="size"
		[modalWhitespace]="modalWhitespace"
		[backdrop]="backdrop"
		[centered]="centered"

		[pEditable]="pEditable"
		[api]="api"
		[valid]="isValid"
		[saveChangesHook]="saveChangesHook"
		[beforeEditHook]="beforeEditHook"
		(onSaveStart)="onSaveStart.emit($event)"
		(onSaveSuccess)="onSaveSuccess.emit($event)"
		(onDismiss)="onDismiss.emit($event)"
		(onLeaveCurrent)="onLeaveCurrent.emit()"
		(editMode)="updateEditMode($event)"

		[waitForEditableCompleteBeforeClose]="waitForEditableCompleteBeforeClose"

		(onModalOpen)="onModalOpen.emit($event)"
		(onModalClosed)="onModalClosed.emit()"
		(onModalDismissed)="onModalDismissed.emit()"
		[beforeModalClose]="beforeModalClose"

		[saveButtonPopover]="saveButtonPopover"
		[closeBtnDisabled]="!isValid"
		[label]="btnLabel"
		[modalTitle]="modalTitle"
		[icon]="btnIcon"
		[showBtnIcon]="showBtnIcon"
		[showBtnLabel]="showBtnLabel"
	>
		<p-editable-modal-form>
			<ng-content select="p-editable-modal-form"></ng-content>
		</p-editable-modal-form>
		<p-editable-showroom>
			<ng-container *ngIf="!!label && headless && !showShowroom">
				<label
					[class.text-danger]="!isValid"
					class="pl-2 mb-0">{{label}}</label>
			</ng-container>
			<ng-content select="p-editable-modal-box-showroom"></ng-content>
		</p-editable-showroom>
	</div>
</ng-template>
</div>

<ng-container *ngIf="cannotSetHint && (disabled || readMode)">
	<div class="d-flex mb-3">
		<fa-icon [verticalAlignTextBottom]="true" size="xs" [icon]="enums.PlanoFaIconPool.MORE_INFO"/>
		<span class="form-text ml-1">{{cannotSetHint}}</span>
	</div>
</ng-container>
