import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { PRouterService } from '@plano/shared/core/router.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-nav-back-headline',
	templateUrl: './p-nav-back-headline.component.html',
	styleUrls: ['./p-nav-back-headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PNavBackHeadlineComponent {

	/**
	 * label to be displayed
	 */
	@Input('label') public label : string | null = null;

	/**
	 * Icon to be displayed
	 */
	@Input() public icon : PFaIcon | null = null;

	@HostBinding('class.bg-light-cold')
	@HostBinding('class.w-100')
	@HostBinding('class.d-flex')
	@HostBinding('class.align-items-center') private _alwaysTrue = true;

	constructor(public pRouterService : PRouterService) {
	}

	public Config = Config;
	public enums = enumsObject;
}
