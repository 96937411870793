import { AfterViewInit, Directive, ElementRef, Injector, Input, OnChanges, ViewContainerRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PBadgeComponent } from '@plano/client/shared/shared/p-badge/p-badge.component';
import { PBadgeAlign, PBadgeContent } from '@plano/client/shared/shared/p-badge/p-badge.types';
import { PSimpleChanges } from '@plano/shared/api';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

/**
 * Add the class.disabled to anchor elements that have the disabled property
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[p-badge]',
	standalone: true,
})
export class BadgeDirective implements AfterViewInit, OnChanges {

	/**
	 * Is the badge disabled?
	 */
	@Input() public badgeDisabled : boolean = false;

	/**
	 * Title to be added to the badge
	 */
	@Input() public badgeTitle : string = '';

	/**
	 * Is the badge loading?
	 */
	@Input() public isLoading : PComponentInterface['isLoading'] = null;

	@Input() public theme : PThemeEnum | 'reverse-primary' = enumsObject.PThemeEnum.DANGER;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('p-badge') public badge : PBadgeContent | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('badge-align') public align : PBadgeAlign = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	/**
	 * Should have the todo-badge class
	 */
	@Input() public todoBadge : boolean = true;

	/** @see PBadgeComponent.infoBadge */
	@Input() public infoBadge : PBadgeComponent['infoBadge'] = false;

	constructor(
		private injector : Injector,
		private el : ElementRef<HTMLElement>,
		private viewContainerRef : ViewContainerRef,
	) {

	}

	private badgeComponent : PBadgeComponent | null = null;

	public ngOnChanges(changes : PSimpleChanges<BadgeDirective>) : void {
		if (this.badgeComponent) {
			if (changes.align)
				this.badgeComponent.align = changes.align.currentValue;
			if (changes.theme)
				this.badgeComponent.applyThemeClasses(changes.theme.currentValue, changes.theme.previousValue);
			if (changes.badge)
				this.badgeComponent.content = changes.badge.currentValue;
			if (changes.size)
				this.badgeComponent.size = changes.size.currentValue;
			if (changes.isLoading)
				this.badgeComponent.isLoading = changes.isLoading.currentValue;
			if (changes.todoBadge)
				this.badgeComponent.todoBadge = changes.todoBadge.currentValue;
			if (changes.infoBadge)
				this.badgeComponent.infoBadge = changes.infoBadge.currentValue;
			if (changes.badgeDisabled)
				this.badgeComponent.isDisabled = changes.badgeDisabled.currentValue;
			if (changes.badgeTitle)
				this.badgeComponent.badgeTitle = changes.badgeTitle.currentValue;
			this.badgeComponent.cdr.detectChanges();
		}
	}

	public ngAfterViewInit() : void {
		this.badgeComponent = this.viewContainerRef.createComponent(PBadgeComponent, {injector: this.injector}).instance;
		this.badgeComponent.align = this.align;
		this.badgeComponent.theme = this.theme;
		this.badgeComponent.todoBadge = this.todoBadge;
		this.badgeComponent.content = this.badge;
		this.badgeComponent.infoBadge = this.infoBadge;
		this.badgeComponent.size = this.size;
		this.badgeComponent.badgeTitle = this.badgeTitle;
		this.badgeComponent.isDisabled = this.badgeDisabled;
		this.badgeComponent.isLoading = this.isLoading;
		this.badgeComponent.cdr.detectChanges();
		this.el.nativeElement.appendChild(this.badgeComponent.element.nativeElement);
	}
}
