import { SchedulingApiAutomaticBookingCancellationSettingsBase, SchedulingApiShiftModel } from '@plano/shared/api';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

/**
 * Settings for automatic cancelation of bookings when cancelling/deleting shifts.
 */
export class SchedulingApiAutomaticBookingCancellationSettings extends SchedulingApiAutomaticBookingCancellationSettingsBase {

	/**
	 * A list of shift-model names for which the logged in member does not have the right to online refund.
	 * The shift-models to be checked are retrieved from `affectedShiftsApi.data.shifts`.
	 */
	public get listOfShiftModelsWithMissingOnlineRefundRight() : string {
		let result = '';
		const shifts = this.api!.affectedShiftsApi.data.shifts;
		const alreadyAddedShiftModels : SchedulingApiShiftModel[] = [];

		for (const shift of shifts.iterable()) {
			const shiftModelId = shift.id.shiftModelId;
			const shiftModel = notNull(this.api!.data.shiftModels.get(shiftModelId));

			if (!this.api!.rightsService.userCanOnlineRefund(shiftModel) && !alreadyAddedShiftModels.includes(shiftModel)) {
				alreadyAddedShiftModels.push(shiftModel);
				result += (result ? `, ${shiftModel.name}` : shiftModel.name);
			}
		}

		return result;
	}
}
