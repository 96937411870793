/* eslint-disable no-warning-comments */
/* eslint max-lines: ["error", 900] */

import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EmbeddedViewRef, EventEmitter, forwardRef, HostBinding, Input, NgZone, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { BootstrapRounded, PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { MeService } from '@plano/shared/api';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, notUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PButtonComponent } from '@plano/shared/p-forms/p-button/p-button.component';
import { PDropdownItemComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown-item/p-dropdown-item.component';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { collapseOnLeaveAnimation, expandOnEnterAnimation } from 'angular-animations';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { Subscription } from 'rxjs';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum DropdownTypeEnum {
	FILTER = 'filter',
	MULTI_SELECT = 'multiSelect',
	SELECT = 'select',
	ACTIONS = 'actions',
}

const COLLAPSE_ANIMATION_DURATION = 75;

type ValueType = unknown;

/**
 * <p-dropdown> is like <select> with all the options for pEditables
 * @example with PFormControl binding
 * 	<form [formGroup]="myFormGroup">
 * 		<p-dropdown
 * 			[formControl]="myFormGroup.get('favoriteFood')"
 * 		>
 * 			<p-dropdown-item
 * 				value="unhealthy"
 * 				i18n
 * 			>Pizza</p-dropdown-item>
 * 			<p-dropdown-item
 * 				value="healthy"
 * 				i18n
 * 			>Salat</p-dropdown-item>
 * 		</p-dropdown>
 * 	</form>
 * @example with model binding
 * 	<p-dropdown
 * 		[(ngModel)]="member.favoriteFood"
 * 	>
 * 		<p-dropdown-item
 * 			value="unhealthy"
 * 			i18n
 * 		>Pizza</p-dropdown-item>
 * 		<p-dropdown-item
 * 			value="healthy"
 * 			i18n
 * 		>Salat</p-dropdown-item>
 * 	</p-dropdown>
 * @example as editable
 * 	<form [formGroup]="myFormGroup">
 * 		<p-dropdown
 * 			[pEditable]="!member.isNewItem()"
 * 			[api]="api"
 *
 * 			[formControl]="myFormGroup.get('favoriteFood')"
 * 			label="Plano" i18n-label
 * 		>
 * 			<p-dropdown-item
 * 				value="unhealthy"
 * 				i18n
 * 			>Pizza</p-dropdown-item>
 * 			<p-dropdown-item
 * 				value="healthy"
 * 				i18n
 * 			>Salat</p-dropdown-item>
 * 		</p-dropdown>
 * 	</form>
 */
@Component({
	selector: 'p-dropdown',
	templateUrl: './p-dropdown.component.html',
	styleUrls: ['./p-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PDropdownComponent),
			multi: true,
		},
	],
	animations: [
		collapseOnLeaveAnimation({
			animateChildren: 'before',
			duration: COLLAPSE_ANIMATION_DURATION,
		}),
		expandOnEnterAnimation(),
	],
})
export class PDropdownComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterViewInit, AfterContentChecked, OnDestroy, OnInit, EditableControlInterface,
PFormControlComponentInterface {
	@HostBinding('attr.aria-label') private get ariaLabel() : string | null {
		return this.triggerLabel;
	}

	/**
	 * the label should wrap if there is not enough space
	 */
	@Input() public wrapLabel = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public readMode : PFormControlComponentInterface['readMode'] = null;

	@ContentChildren(PDropdownItemComponent) public items ?: QueryList<PDropdownItemComponent>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onSelect = new EventEmitter<unknown>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public btnStyle : PButtonComponent['theme'] = enumsObject.PThemeEnum.SECONDARY;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public borderStyle : 'secondary' | 'none' | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public triggerIsCardOption : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dropdownMenuAlignment : 'right' | 'left' = 'right';

	/**
	 * Label for the dropdown trigger button
	 * If not set, a placeholder will be taken
	 */
	@Input() public label : string | null = null;

	// TODO: Change type of icon to PlanoFaIconPool
	@Input() private icon : PlanoFaIconPoolValues | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public iconSpin : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dropdownType : DropdownTypeEnum = DropdownTypeEnum.SELECT;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideTriggerLabel : boolean = false;

	/**
	 * Should this dropdown be appended directly on the body?
	 *
	 * We want it to be false for almost every situation, this attribute serves to fix issues where the dropdown is, for
	 * example, at the bottom of a modal and we want the options to overlap the footer of the modal. We can not set this
	 * everywhere because the options get separated from the trigger on scroll, so we decided to just close the dropdown
	 * on such cases.
	 */
	@Input() public appendDropdownOnBody : boolean = false;

	/**
	 * Use this if you want the dropdown look like a button.
	 * This can be used if you want to show a single-icon button in a list-item.
	 *
	 * NOTE: It is considered bad UX if the user can not know/be sure what the interactive element does
	 * For example if the user can not know if it triggers an action or will open a dropdown menu.
	 * So in general you should avoid to use this.
	 */
	@Input('hideDropdownToggleTriangle') public _hideDropdownToggleTriangle : boolean | null = null;

	/**
	 * Classes to be added to the toggle triangle of this dropdown
	 */
	@Input() public dropdownToggleTriangleClasses : string = '';

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideBadge : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideFilterLed : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public rounded : BootstrapRounded | null = null;

	/**
	 * Set this to false if the dropdown-items should just act like buttons
	 */
	@Input() private showActiveItem : boolean = true;

	/**
	 * Make it possible to choose if the dropdown button should be active or not on filter selection
	 */
	@Input() private highlightDropdownOnFilterActive : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dropdownMenuVisible : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private dropdownMenuVisibleChanged = new EventEmitter<boolean>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dropdownItemTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dropdownTriggerBtnContentTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public triggerTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public triggerReadModeTemplate : TemplateRef<unknown> | null = null;

	@ViewChild('modalContent') public modalContent : PModalTemplateDirective | null = null;
	@ViewChild('dropdownMenuRef') public dropdownMenuRef : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public itemsFilterTitle : string | null = null;

	@ViewChild('triggerRef', {read:ElementRef}) private triggerRef ?: ElementRef<HTMLElement>;

	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() public focus = new EventEmitter<MouseEvent>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() public blur = new EventEmitter<MouseEvent>();

	/**
	* This is the minimum code that is required for a custom control in Angular.
	* Its necessary to make [(ngModel)] and [formControl] work.
	*/
	public override get disabled() : boolean {
		return this._disabled;
	}
		@Input('disabled') public override set disabled(input : boolean) {
		this.setDisabledState(input);
		this._disabled = input;
		super.disabled = input;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	constructor(
		private el : ElementRef<HTMLElement>,
		private zone : NgZone,
		protected override changeDetectorRef : ChangeDetectorRef,
		private modalService : ModalService,
		private localize : LocalizePipe,
		public meService : MeService,
		private router : Router,
		protected override pFormsService : PFormsService,
		protected override console : LogService,
		private viewContainerRef : ViewContainerRef,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	/**
	* Returns a icon fitting to the state and type of the multi-select.
	*/
	public multiSelectIcon(item : PDropdownItemComponent) : FaIcon {
		if (!this.isMultiSelect) this.console.error('Don’t use this method if this is not a multi-select dropdown');
		if (this.dropdownType === DropdownTypeEnum.FILTER)
			return item.active === true ? enumsObject.PlanoFaIconPool.VISIBLE : enumsObject.PlanoFaIconPool.INVISIBLE;
		return item.active === true ? enumsObject.PlanoFaIconPool.CHECKBOX_SELECTED : enumsObject.PlanoFaIconPool.CHECKBOX_UNSELECTED;
	}

	/**
	 * Is it mobile? If so don't show the dropdown arrow
	 */
	public get hideDropdownToggleTriangle() : boolean {
		if (this._hideDropdownToggleTriangle) return this._hideDropdownToggleTriangle;
		return false;
	}

	/**
	* There are several dropdownTypes that represent a multi-select.
	* This getter returns true if it is any of them.
	*/
	public get isMultiSelect() : boolean {
		return this.dropdownType === DropdownTypeEnum.MULTI_SELECT || this.dropdownType === DropdownTypeEnum.FILTER;
	}

	private timeoutMouseLeave : number | null = null;
	public PBtnThemeEnum = PBtnThemeEnum;
	public enums = enumsObject;
	public CONFIG = Config;
	public DropdownTypeEnum = DropdownTypeEnum;

	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	private modalClosedSubscription : Subscription | null = null;

	public override ngOnInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		// make sure these events do not trigger change detection for performance reasons
		this.zone.runOutsideAngular(() => {
			this.modalClosedSubscription = this.modalService.modalStateCloseSubject.subscribe(() => {
				this.handleHideDropdownMenu();
			});

			this.el.nativeElement.addEventListener('mouseenter', this.onMouseEnter, false);
			this.el.nativeElement.addEventListener('mouseleave', this.onMouseLeave, false);
		});
		return super.ngOnInit();
	}

	/**
	 * Set the label for the dropdown trigger button.
	 */
	private initLabel() : void {
		if (this.label === null) {
			this.label = this.localize.transform('Bitte wählen…');
		}
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		window.clearTimeout(this.timeoutMouseLeave ?? undefined);
		this.el.nativeElement.removeEventListener('mouseenter', this.onMouseEnter);
		this.el.nativeElement.removeEventListener('mouseleave', this.onMouseLeave);
		if (this.dropdownMenuElement) {
			this.dropdownMenuElement.removeEventListener('mouseenter', this.onMouseEnter);
			this.dropdownMenuElement.removeEventListener('mouseleave', this.onMouseLeave);
		}
		this.modalClosedSubscription?.unsubscribe();
		return super.ngOnDestroy();
	}

	private onMouseLeave = () : void => {
		// Running the timeout outside angular and trigger changeDetection of this component manually
		// makes it possible to use ChangeDetectionStrategy.OnPush on this component.
		this.zone.runOutsideAngular(() => {
			this.timeoutMouseLeave = window.setTimeout(() => {
				// Does 'this' dropdown still exist? It’s possible that the component has already been destroyed in the meantime.
				// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
				if (!this) return;

				this.handleHideDropdownMenu();
			}, 1000);
		});
	};

	private handleHideDropdownMenu() : void {
		if (this.dropdownMenuVisible) {
			this.dropdownMenuVisible = false;
			if (this.dropdownEmbeddedView) {
				this.dropdownEmbeddedView.detectChanges();
			}
		}
	}

	/**
	 * Remove the dropdown menu element only after the animation has finished
	 */
	public removeDropdownElementAfterAnimation() : void {
		if (!this.appendDropdownOnBody) return;
		if (!this.dropdownMenuVisible)
			this.dropdownMenuElement?.remove();
	}

	private onMouseEnter = () : void => {
		window.clearTimeout(this.timeoutMouseLeave ?? undefined);
	};

	/**
	 * Should this dropdown item be visually highlighted?
	 */
	public isPrimary(item : PDropdownItemComponent) : boolean {
		if (!this.showActiveItem) return false;

		// Our filter drop-downs say 'hide X' when INACTIVE. Therefore inactive items must be
		// the highlighted ones.
		// if (this.dropdownType === DropdownTypeEnum.FILTER) return !item.active;
		if (this.dropdownType === DropdownTypeEnum.FILTER) return false;

		return this.isActive(item);
	}

	/**
	 * Get the classes to be added to a dropdown item
	 *
	 * @param item The PDropdownItem to which we want to know the classes
	 * @returns the classes string
	 */
	public dropdownItemClasses(item : PDropdownItemComponent) : string {
		let baseClasses = 'btn btn-lg btn-frameless dropdown-item-btn';
		const optionalClasses = {
			/* eslint-disable @typescript-eslint/naming-convention */
			'text-left': !item.link,
			'text-right': item.link,
			'btn-outline-secondary': !item.theme && !this.isPrimary(item),
			'btn-primary': !item.theme && this.isPrimary(item),
			'btn-danger': item.theme === enumsObject.PThemeEnum.DANGER,
			'btn-outline-danger': item.theme === PBtnThemeEnum.OUTLINE_DANGER,
			active: item.theme !== null && this.isPrimary(item),
			'py-3': Config.IS_MOBILE,
			disabled: item.disabled,
			/* eslint-enable @typescript-eslint/naming-convention */
		};

		for (const [className, isToBeAdded] of Object.entries(optionalClasses)) {
			if (isToBeAdded) {
				baseClasses += ` ${className}`;
			}
		}
		return item.additionalClasses ? `${baseClasses} ${item.additionalClasses}` : baseClasses;
	}

	/**
	 * This method checks if the given item is in a active state.
	 */
	public isActive(item : PDropdownItemComponent) : boolean {
		// If set, the item.checked value has a higher priority then the other expression
		if (item.active !== null) return item.active;
		if (item.link) {
			return this.router.url.includes(item.link);
		}
		if (item.value === undefined) return false;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (this.value && (this.value as any).equals) return (this.value as any).equals(item.value);
		return this.value === item.value;
	}

	/**
	 * If this is a multi select dropdown and if there is at least one selected item
	 * then the button needs some kind of highlighting
	 */
	public get isHighlighted() : boolean {
		return this.highlightDropdownOnFilterActive && this.dropdownType === DropdownTypeEnum.FILTER && !!this.inactiveItemsCounter;
	}

	/**
	 * This returns a icon for the trigger button. Can be set from outside, but can also be the icon of
	 * the selected item, if this is a dropdown with one possible active item. ..aka. "single select dropdown".
	 */
	public get triggerIcon() : PlanoFaIconPoolValues | 'member-badge' | undefined {
		if (this.icon) return this.icon;
		if (this.activeItem?.prependedItem && !(this.activeItem.prependedItem instanceof TemplateRef)) {
			return this.activeItem.prependedItem;
		}
		return undefined;
	}

	/**
	 * The label of the trigger button.
	 */
	public get triggerLabel() : string | null {
		if (this.showActiveItem && this.activeItem?.label) return this.activeItem.label;

		if (this.label) return this.label;

		return this.localize.transform('Bitte wählen…');
	}

	/**
	 * Is it now possible to use ng-content of p-dropdown-item instead of a simple string (label property)
	 * Therefore we need this getter
	 */
	public get triggerLabelTemplate() : TemplateRef<ElementRef<HTMLElement>> | null {
		if (!this.showActiveItem) return null;
		if (!this.activeItem) return null;
		if (!this.activeItem.innerTemplate?.elementRef.nativeElement.childNodes.length) return null;
		return this.activeItem.innerTemplate;
	}

	/**
	 * Get all items with the stat active
	 */
	private get activeItems() : PDropdownItemComponent[] {
		return this.items!.filter((item) => this.isActive(item));
	}

	/**
	 * Get the selected item, if its not a multi-select-dropdown
	 */
	public get activeItem() : PDropdownItemComponent | null {
		if (this.isMultiSelect) return null;
		if (!this.items) return null;
		return this.activeItems[0];
	}

	/**
	 * This can be used for e.g. filter dropdown’s to show if one of the list-items is active.
	 */
	public get badgeContent() : number {
		return this.inactiveItemsCounter;
	}

	/**
	 * Counts how many of the containing items are inactive. useful for e.g. filter dropdown’s.
	 */
	private get inactiveItemsCounter() : number {
		return this.items!.length - this.activeItems.length;
	}

	/**
	 * This happens when user clicks one of the list-items inside dropdown’s.
	 */
	public clickItem(
		clickedItem : PDropdownItemComponent,
		success : (value : Event) => void,
		event : Event,
	) : void {
		// Don‘t close list on click if its a multiSelect
		if (!this.isMultiSelect) {
			this.handleHideDropdownMenu();
			this.triggerRef?.nativeElement.querySelector('button')?.focus();
		}

		// don’t do anything if dropdown-item is disabled
		if (clickedItem.disabled) return;

		// activate the tab the user has clicked on.
		if (this.dropdownType === DropdownTypeEnum.ACTIONS && clickedItem.onClick.observers.length > 0) {
			clickedItem.onClick.emit();
		} else if (clickedItem.link === null) {
			let newActiveState : boolean = false;
			if (this.isMultiSelect) {
				newActiveState = !clickedItem.active;
			} else {
				newActiveState = true;
			}
			if (clickedItem.active !== newActiveState) {
				clickedItem.active = newActiveState;
				clickedItem.onClick.emit();
			}
		} else clickedItem.onClick.emit();

		this.handleNonMultiSelect(clickedItem, success, event);
		this.value = clickedItem.value;
	}

	private handleNonMultiSelect(
		clickedItem : PDropdownItemComponent | null,
	) : void;
	private handleNonMultiSelect(
		clickedItem : PDropdownItemComponent | null,
		success : (value : Event) => void,
		event : Event,
	) : void;
	private handleNonMultiSelect(
		clickedItem : PDropdownItemComponent | null,
		success ?: (value : Event) => void,
		event ?: Event,
	) : void {
		if (this.isMultiSelect) return;

		// deactivate all other tabs except the clicked one
		for (const item of this.items!.toArray()) {
			if (item !== clickedItem && item.active) item.active = false;
		}
		if (clickedItem) {
			assumeDefinedToGetStrictNullChecksRunning(clickedItem, 'clickedItem');
			this.onSelect.emit(clickedItem.value);
		}
		if (success) {
			notUndefined(event);
			success(event!);
		}
	}

	private modalRef : ModalRef | null = null;

	private waitForScroll : boolean = false;

	private handleEventListeners() : void {
		if (this.dropdownMenuVisible) {
			document.addEventListener('scroll', this.handlerCheckIfScrollAndClose, true);
			document.addEventListener('wheel', this.handlerCheckIfScrollAndClose, true);
		} else {
			document.removeEventListener('wheel', this.handlerCheckIfScrollAndClose, true);
			document.removeEventListener('scroll', this.handlerCheckIfScrollAndClose, true);
		}
	}

	/**
		 * Handle the click on the trigger of a dropdown that should be appended to the body
		 */
	private handleClickOnBodyDropdown() : void {
		const handleDropdownChanges = () : void => {
			if (this.dropdownMenuElement) {
				this.dropdownMenuVisible = !this.dropdownMenuVisible;
				if (this.appendDropdownOnBody) {
					if (this.dropdownMenuVisible) {
						this.calculateDropdownMenuLocation();
						this.dropdownMenuElement.ariaLabel = 'dropdown-menu-appended-to-body';
						document.body.append(this.dropdownMenuElement);
					}
					this.handleEventListeners();
				}
			}
		};

		const observer = new IntersectionObserver((entries, innerObserver) => {
			if (entries[0].isIntersecting) {
				if (this.waitForScroll) {
					this.zone.runOutsideAngular(() => {
						window.setTimeout(() => {
							handleDropdownChanges();
							this.waitForScroll = false;
						},200);
					});
				} else {
					handleDropdownChanges();
				}
				this.triggerRef?.nativeElement.querySelector('button')?.focus();
				this.dropdownMenuVisibleChanged.emit(this.dropdownMenuVisible!);
				innerObserver.disconnect();
			}
		}, {threshold: 0.9});

		const scrollObserver = new IntersectionObserver((entries, innerObserver) => {
			if (entries[0].isIntersecting && entries[0].intersectionRatio < 1.0) {
				this.waitForScroll = true;
				this.triggerRef?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
				innerObserver.disconnect();
			}
		});

		if (this.triggerRef) {
			scrollObserver.observe(this.triggerRef.nativeElement);
			observer.observe(this.triggerRef.nativeElement);
		}
	}

	/**
	 * User clicked the button that should open the dropdown thing
	 */
	public onClickTrigger(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		if (Config.IS_MOBILE) {
			this.modalRef = this.modalService.openModal(modalContent, {
				size: enumsObject.BootstrapSize.SM,
			});
			void this.modalRef.result.then(_value => {
				this.modalRef = null;
			});
			return;
		}

		if (this.appendDropdownOnBody) {
			this.handleClickOnBodyDropdown();
		} else {
			this.dropdownMenuVisible = !this.dropdownMenuVisible;
			this.triggerRef?.nativeElement.querySelector('button')?.focus();
			this.dropdownMenuVisibleChanged.emit(this.dropdownMenuVisible);
		}
	}

	// public get showBadge() : boolean {
	// 	if (this.hideBadge) return false;
	// 	if (this.dropdownType !== 'filter') return false;
	// 	return !!this.badgeContent;
	// }

	/**
	 * Should the filter-led be visible?
	 */
	public get showFilterLed() : boolean {
		if (this.hideFilterLed) return false;
		if (this.dropdownType !== DropdownTypeEnum.FILTER) return false;

		// if (this.dropdownMenuVisible) return true;
		// return !!this.badgeContent;
		return true;
	}

	/**
	 * Should the label be visible?
	 */
	public get showLabel() : boolean {
		if (this.hideTriggerLabel) return false;
		return !!this.triggerLabel;
	}

	public _disabled : boolean = false;

	private dropdownMenuElement : HTMLDivElement | null = null;

	private dropdownEmbeddedView : EmbeddedViewRef<unknown> | null = null;

	public ngAfterViewInit() : void {
		if (this.triggerTemplate && this.readMode && !this.triggerReadModeTemplate) {
			throw new Error('readMode is true but no triggerReadMode was passed along with triggerTemplate!');
		}
		if (this.value) {
			if (!this.activeItem) this.console.warn('activeItem needs to defined after init');
			this.handleNonMultiSelect(this.activeItem);
		}
		this.initLabel();
		this.validateValues();
		if (this.dropdownMenuRef && this.appendDropdownOnBody) {
			this.dropdownEmbeddedView = this.viewContainerRef.createEmbeddedView(this.dropdownMenuRef, null);
			this.dropdownMenuElement = this.dropdownEmbeddedView.rootNodes[0];
			if (this.dropdownMenuElement) {
				this.dropdownMenuElement.remove();
				this.dropdownMenuElement.addEventListener('mouseenter', this.onMouseEnter);
				this.dropdownMenuElement.addEventListener('mouseleave', this.onMouseLeave);
			}
		}
	}

	private handlerCheckIfScrollAndClose = this.checkIfScrollAndClose.bind(this);

	/**
	 * Close the dropdown if scroll affected the position
	 */
	private checkIfScrollAndClose() : void {
		if (this.dropdownMenuVisible) {
			const boundingRect = this.el.nativeElement.getBoundingClientRect();
			const top = boundingRect.top + boundingRect.height;
			const right = window.innerWidth - (boundingRect.left + boundingRect.width);
			const left = boundingRect.left;
			if (top !== this.topDropdownMenu ||
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
				(this.rightDropdownMenu && this.rightDropdownMenu !== right) ||
				(this.leftDropdownMenu && this.leftDropdownMenu !== left)) {
				this.handleHideDropdownMenu();
			}
		}
	}

	private topDropdownMenu : number | null = null;
	private rightDropdownMenu : number | null = null;
	private leftDropdownMenu : number | null = null;

	/**
	 * Calculate the position where the dropdown menu should be
	 */
	private calculateDropdownMenuLocation() : void {
		if (this.dropdownMenuVisible && this.appendDropdownOnBody) {
			const boundingRect = this.el.nativeElement.getBoundingClientRect();
			this.topDropdownMenu = boundingRect.top + boundingRect.height;
			if (this.dropdownMenuAlignment === 'right')
				this.rightDropdownMenu = window.innerWidth - (boundingRect.left + boundingRect.width);
			else this.leftDropdownMenu = boundingRect.left;
			if (this.dropdownMenuElement) {
				if (this.dropdownMenuAlignment === 'right')
					this.dropdownMenuElement.style.right = `${this.rightDropdownMenu}px`;
				else this.dropdownMenuElement.style.left = `${this.leftDropdownMenu}px`;
				this.dropdownMenuElement.style.minWidth = `${boundingRect.width}px`;
				this.dropdownMenuElement.style.top = `${this.topDropdownMenu}px`;
			}
		}
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateValues() : void {
		if (Config.DEBUG && this.dropdownType === DropdownTypeEnum.ACTIONS && this.items) {
			for (const item of this.items.toArray()) {
				if (item.link && item.onClick.observers.length > 0) {
					throw new Error(`All dropdown-items need to have either (onClick)="…" or link="…" binding if dropdown has dropdownType »ACTIONS«, but not both`);
				}
				if (!item.onClick.observers.length && item.value === undefined && !item.link) throw new Error(`All dropdown-items need to have (onClick)="…" or link="…" binding if dropdown has dropdownType »ACTIONS«`);
			}
		}
		if (this.items!.filter(item => {
			return item.show === true || item.show === undefined;
		}).length === 0) this.console.warn(`Dropdown is empty. Label: »${this.label}«`);
	}

	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	private _value : ValueType | null = null;
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public override _onChange : (value : ValueType | null) => void = () => {};

	/** Get change event from inside this component, and pass it on. */
	public onChange(value : ValueType) : void {
		this._onChange(value);
	}

	public onTouched = () : void => {};

	/** the value of this control */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public get value() : ValueType | null { return this._value; }
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public set value(value : ValueType | null) {
		if (value === this._value) return;
		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		if (this.activeItem && this.activeItem.value !== value) this.activeItem.active = false;
		this._value = value;
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute
		this._disabled = isDisabled;
		this._disabled ? this.control?.disable() : this.control?.enable();
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.control!);
	}

	/**
	 * Check if focus is anywhere inside dropdown. If not, blur
	 */
	public onBlur() : void {
		this.control?.markAsTouched();

		this.zone.runOutsideAngular(() => {
			window.setTimeout(() => {
				if (this.el.nativeElement.contains(document.activeElement)) return;
				if (this.modalRef !== null) return;
				this.blur.emit();
			}, 200);
		});
	}

	/** get description if available and string */
	public descriptionString(item : PDropdownItemComponent) : string | null {
		if (item.description === null) return null;
		if (typeof item.description !== 'string') return null;
		return item.description;
	}

	/** get description if available and type templateRef */
	public descriptionTemplateRef(item : PDropdownItemComponent) : TemplateRef<unknown> | null {
		if (item.description === null) return null;
		if (typeof item.description === 'string') return null;
		return item.description;
	}
}
