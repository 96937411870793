import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'pSafeHtml' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitized : DomSanitizer) {}

	/**
	 * Sanitize given html
	 */
	public transform(html : string) : SafeHtml {
		return this.sanitized.bypassSecurityTrustHtml(html);
	}
}
