import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Input, NgZone, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { CourseFilterService } from '@plano/client/scheduling/course-filter.service';
import { SchedulingApiBirthdays } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { CalenderTimelineLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.service';
import { PCalendarShiftStyle, ShiftItemViewStyles } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-shift-item-module/shift-item/shift-item-styles';
import { ShiftItemComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-shift-item-module/shift-item/shift-item.component';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiAbsences, SchedulingApiHolidays, SchedulingApiService, SchedulingApiShift, SchedulingApiShifts, ShiftId } from '@plano/shared/api';
import { DateTime } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { PScrollToSelectorService } from '@plano/shared/core/scroll-to-selector.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { CalendarMonthViewDay } from 'angular-calendar';
import { DAYS_OF_WEEK } from 'calendar-utils';
import { getWeekOfMonth } from 'date-fns';
import * as moment from 'moment-timezone';
import { Subject, Subscription } from 'rxjs';
import { PAllDayItemsListComponent } from './p-all-day-items-list/p-all-day-items-list.component';

type ValueType = ShiftId | null;

@Component({
	selector: 'p-calendar[shifts]',
	templateUrl: './p-calendar.component.html',
	styleUrls: ['./p-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PSchedulingCalendarComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PSchedulingCalendarComponent implements PComponentInterface, OnDestroy, OnInit, AfterViewInit, ControlValueAccessor {
	@HostBinding('attr.role') private _role = 'grid';

	@ViewChild('topAnchor') public topAnchor ?: ElementRef<HTMLElement>;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts ! : SchedulingApiShifts;

	@Input('selectedStartOfDay') private set _selectedStartOfDay(input : number) {
		Assertions.ensureIsDayStart(input);
		this.selectedStartOfDay = input;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public calendarMode : CalendarModes | null = CalendarModes.MONTH;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showAsList : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('neverShowDayTools') private _neverShowDayTools : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftStyle : PCalendarShiftStyle = PCalendarShiftStyle.FULL;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public multiSelect : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absences : PAllDayItemsListComponent['absences'] = new SchedulingApiAbsences(null, null);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public holidays : PAllDayItemsListComponent['holidays'] = new SchedulingApiHolidays(null, null);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public birthdays : PAllDayItemsListComponent['birthdays'] = new SchedulingApiBirthdays(null, null, null, false);

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public dayClick : EventEmitter<number> = new EventEmitter<number>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onShiftClick = new EventEmitter<{shift : SchedulingApiShift, event : MouseEvent}>();

	/** @see ShiftItemListComponent#onDismissShiftSelected */
	@Output() public onDismissShiftSelected = new EventEmitter<SchedulingApiShifts>();

	/**
	 * With this boolean the multi-select checkboxes can be turned off for all shifts
	 */
	@Input() public shiftIsSelectable : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	/**
	 * The following code is for the case that this is a shiftPicker
	 */
	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to make [(ngModel)] and [formControl] work.
	 */
	@Input() public disabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private formControl : PFormControl | null = null;

	@HostListener('click') private onClick() : void {
		this.highlightService.setHighlighted(null);
	}

	constructor(
		public api : SchedulingApiService,
		private highlightService : HighlightService,
		private console : LogService,
		public changeDetectorRef : ChangeDetectorRef,
		private pMoment : PMomentService,
		private courseService : CourseFilterService,
		private pScrollToSelector : PScrollToSelectorService,
		private zone : NgZone,
		private calendarTimelineLayoutService : CalenderTimelineLayoutService,
	) {
		if (!this.selectedStartOfDay) this.selectedStartOfDay = +this.pMoment.m().startOf('day');

		moment.updateLocale(Config.getLanguageCode(Config.LOCALE_ID), {
			week: {
				dow: DAYS_OF_WEEK.MONDAY,
				doy: 0,
			},
		});

		this.today = +this.pMoment.m().startOf('day');

		this.apiLoadSubscription = this.api.onDataLoaded.subscribe(() => {
			this.updateStartAndEndOfMonth();
		});

		this.courseService.runBeforeChange = this.findFirstShiftOnWindow.bind(this);

		this.courseFilterSub = this.courseService.onChange.subscribe(()=>{
			if (!Config.IS_MOBILE) return;
			if (!this.topMostShiftIdOnWindow) return;
			window.requestAnimationFrame(()=>{
				// eslint-disable-next-line unicorn/prefer-query-selector -- needed to handle numeric id
				const shiftElement : HTMLElement = document.getElementById(this.topMostShiftIdOnWindow!)!;

				// get the scrollable parent
				const scrollableParent : HTMLElement | null = this.pScrollToSelector.nearestScrollableParent(shiftElement);
				if (scrollableParent) {
					const totalRowHeight = this.rowSeparatorOffset ?? 0;
					const errorFixingOffset = 5; // +5 to be sure shift is the same
					scrollableParent.scrollTop = shiftElement.offsetTop - totalRowHeight + errorFixingOffset;

					// reset the most top shift
					this.topMostShiftIdOnWindow = null;
				}
			});
		});
	}
	public readonly CONFIG = Config;

	private resizeObserver : ResizeObserver | null = null;

	/**
	 * Subscriber for the course filter change, so we can maintain correct scrolling
	 */
	private courseFilterSub : Subscription | null = null;

	/**
	 * Id of the top most shift visible on the window
	 */
	private topMostShiftIdOnWindow : string | null = null;

	/**
	 * If there is a row separator between shifts on the list, this is the height of that element
	 */
	private rowSeparatorOffset : number | null = null;

	public ngAfterViewInit() : void {
		this.addResizeObserverToCalendar();
	}

	/**
	 * Set an interval until the topAnchor element is visible to set a resizeObserver so we
	 * can check the container resize.
	 */
	private addResizeObserverToCalendar() : void {
		this.zone.runOutsideAngular(() => {
			const intervalToFindCalendar = window.setInterval(() => {
				if (!this.topAnchor) return;
				window.clearInterval(intervalToFindCalendar);
				this.resizeObserver = new ResizeObserver(() => {
					this.calendarTimelineLayoutService.checkContainerResize();
				});
				this.resizeObserver.observe(this.topAnchor.nativeElement);
			}, 100);
		});
	}

	private findFirstShiftOnWindow() : void {
		if (this.CONFIG.IS_MOBILE) {
			// get the top of the calendar height so we can calculate the correct shift
			const calendarNavElement : HTMLElement = document.querySelector('p-calendar-title-bar')!;

			// iterate over all shifts
			for (const shift of this.shifts.iterable()) {
			// use the selector to get the shift element
				const shiftSelector : string = shift.id.toPrettyString();
				// eslint-disable-next-line unicorn/prefer-query-selector -- needed to handle numeric id
				const shiftElement : HTMLElement | null = document.getElementById(shiftSelector);
				if (!shiftElement) return;
				const boundedRect = shiftElement.getBoundingClientRect();

				// check if this element has a sibling that is not p-shift-item
				const shiftParentElement = shiftElement.parentElement!;
				const rowSeparatorElement : Element | null = shiftParentElement.querySelector(':not(p-shift-item)');

				let offset = calendarNavElement.getBoundingClientRect().bottom;

				// if there is a sibling that is not p-shift-item and it is the first child of the parent
				// add the height to the offset
				if (rowSeparatorElement && shiftParentElement.firstElementChild === rowSeparatorElement) {
					this.rowSeparatorOffset = rowSeparatorElement.getBoundingClientRect().height;
					offset += this.rowSeparatorOffset;
				} else {
					this.rowSeparatorOffset = null;
				}

				// check if it is visible on window
				if (boundedRect.bottom > offset) {
					// store the selector so we scroll after the filter is applied
					this.topMostShiftIdOnWindow = shiftSelector;

					// leave the loop
					break;
				}
			}
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public neverShowDayTools(day : CalendarMonthViewDay) : boolean {
		if (this._neverShowDayTools === true) return true;
		if (this.isOutsideCurrentMonth(+day.date)) return true;
		return false;
	}

	public selectedStartOfDay ! : number;

	/**
	 * CalendarMonthViewComponent needs in in date format
	 */
	public get selectedStartOfDayAsDate() : Date {
		const newDate = new Date(this.selectedStartOfDay);
		if (!this.storedAsDate || newDate.toString() !== this.storedAsDate.toString())
			this.storedAsDate = newDate;
		return this.storedAsDate;
	}
	private storedAsDate : Date | null = null;

	public ShiftItemViewStyles = ShiftItemViewStyles;

	private today ! : number;
	private startOfMonth : DateTime | null = null;
	private endOfMonth : DateTime | null = null;
	private apiLoadSubscription : Subscription | null = null;

	public enums = enumsObject;
	public CalendarModes = CalendarModes;

	private delayIsActiveStore : { [key : number] : boolean | undefined } = {};

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public resetDelayIsActiveStore() : void {
		this.delayIsActiveStore = {};
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * It was so slow to render the month view if there are a lot of shifts, that we invented this hack…
	 * After data is loaded we immediately show the content for the first week, but…
	 * most likely the other weeks are not visible yet. They are outside the scroll-area. So we fill these areas with
	 * skeletons, and fill these skeletons with content week by week.
	 */
	public delayIsActive(day : CalendarMonthViewDay<unknown> | number) : boolean {
		const date : Date = typeof day === 'number' ? new Date(day) : day.date;
		const weekInMonth = getWeekOfMonth(date, { weekStartsOn: 1 });
		if (weekInMonth === 1) return false;
		if (this.delayIsActiveStore[weekInMonth] === undefined) {
			this.delayIsActiveStore[weekInMonth] = true;
			window.setTimeout(() => {
				this.delayIsActiveStore[weekInMonth] = false;
			}, 0);
			return true;
		} else {
			const result = this.delayIsActiveStore[weekInMonth];
			assumeDefinedToGetStrictNullChecksRunning(result, 'result');
			return result;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public scrollToTop() : void {
		this.zone.runOutsideAngular(() => {
			requestAnimationFrame(() => {
				if (!this.topAnchor) return;

				// In case of showAsList, other components will handle the scroll
				if (!this.showAsList) return;
				const el = this.topAnchor.nativeElement;
				el.scrollIntoView();
			});
		});
	}

	public ngOnInit() : void {
		this.initNeverShowDayTools();
	}

	private initNeverShowDayTools() : void {
		switch (this.shiftStyle) {
			case PCalendarShiftStyle.OVERVIEW :
			case PCalendarShiftStyle.SHIFT_PICKER :
				this._neverShowDayTools = true;
				break;
			case PCalendarShiftStyle.FULL :
				this._neverShowDayTools = false;
				break;
			default :
				const RESULT : never = this.shiftStyle;
				throw new Error(RESULT);
		}
	}

	/**
	 * If this calendar is in month mode - in wich mode should the shifts be viewed?
	 */
	public get monthShiftStyle() : ShiftItemComponent['viewStyle'] | undefined {
		switch (this.shiftStyle) {
			case PCalendarShiftStyle.SHIFT_PICKER :
				if (this.multiSelect) return ShiftItemViewStyles.MULTI_SELECT;
				return ShiftItemViewStyles.SMALL;
			case PCalendarShiftStyle.OVERVIEW :
			case PCalendarShiftStyle.FULL :
				return ShiftItemViewStyles.SMALL;
		}
	}

	/**
	 * If this calendar is in week mode - in wich mode should the shifts be viewed?
	 */
	public get weekShiftStyle() : ShiftItemComponent['viewStyle'] | undefined {
		switch (this.shiftStyle) {
			case PCalendarShiftStyle.SHIFT_PICKER :
				if (this.multiSelect) return ShiftItemViewStyles.MULTI_SELECT;
				return this.ShiftItemViewStyles.SMALL;
			case PCalendarShiftStyle.OVERVIEW :
			case PCalendarShiftStyle.FULL :
				return ShiftItemViewStyles.SMALL;
		}
	}

	/**
	 * If this calendar is in day mode - in wich mode should the shifts be viewed?
	 */
	public get dayShiftStyle() : ShiftItemComponent['viewStyle'] | undefined {
		switch (this.shiftStyle) {
			case PCalendarShiftStyle.SHIFT_PICKER :
				if (this.multiSelect) return ShiftItemViewStyles.MEDIUM_MULTI_SELECT;
				return ShiftItemViewStyles.MEDIUM;
			case PCalendarShiftStyle.OVERVIEW :
			case PCalendarShiftStyle.FULL :
				return ShiftItemViewStyles.DETAILED;
		}
	}

	/**
	 * Smartphone users get a simpler list-mode then desktop users.
	 * E.g.
	 * Smartphone week: All days in on column
	 * Desktop week: All days in a row
	 */
	public get simpleListMode() : boolean {
		if (Config.IS_MOBILE) return true;
		if (this.calendarMode === CalendarModes.DAY && this.dayShiftStyle === ShiftItemViewStyles.MEDIUM) return true;

		// if (this.showAsList) {
		// 	if (this.calendarMode !== CalendarModes.WEEK && this.calendarMode !== CalendarModes.MONTH) return true;
		// }
		return false;
	}

	private ngUnsubscribe : Subject<void> = new Subject<void>();

	/**
	 * Destroy
	 */
	public ngOnDestroy() : void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();

		this.resizeObserver?.disconnect();

		this.apiLoadSubscription?.unsubscribe();

		this.courseService.runBeforeChange = null;

		this.courseFilterSub?.unsubscribe();

		// window.clearInterval(this.interval ?? undefined);

		if (this.api.isLoaded()) {
			this.api.data.shifts.setSelected(false);
		}
	}

	/**
	 * Highlight selected Day
	 */
	public beforeMonthViewRender( { body } : { body : CalendarMonthViewDay[] } ) : void {
		for (const day of body) {
			if (
				this.pMoment.m(day.date).format('DD.MM.YYYY') === this.pMoment.m(this.selectedStartOfDay).format('DD.MM.YYYY')
			) {
				day.cssClass = 'cal-day-selected';
			} else {
				day.cssClass = '';
			}
		}
	}

	/**
	 * on day click emit event if there is a (dayClick)="…" binding
	 * else just refresh the internal selectedStartOfDay
	 */
	public onDayClick(timestamp : number) : void {
		if (this.dayClick.observers.length > 0) {
			this.dayClick.emit(timestamp);
		} else {
			this.selectedStartOfDay = timestamp;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onSelectedChange(shift : SchedulingApiShift) : void {
		if (this.shiftStyle !== PCalendarShiftStyle.SHIFT_PICKER) return;
		if (shift.selected) {
			this.api.deselectAllSelections();
			shift.selected = true;
			this.value = shift.id;
			return;
		}
		this.api.deselectAllSelections();
		this.value = null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public markShiftItemAsSelected(shift : SchedulingApiShift) : boolean {
		if (this.shiftStyle !== PCalendarShiftStyle.SHIFT_PICKER) return false;
		if (!this.value) return false;
		return shift.selected;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readMode() : boolean {
		return this.shiftStyle === PCalendarShiftStyle.SHIFT_PICKER;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isBeforeToday(
		startOfDay : number,
	) : boolean {
		return this.pMoment.m(startOfDay).isBefore(this.today);
	}

	private isOutsideCurrentMonth(start : number) : boolean {
		const end = start + 1;
		if (this.startOfMonth !== null && this.startOfMonth >= end) {
			// HACK: startOfMonth should never be greater then selectedStartOfDay
			if (this.startOfMonth > this.selectedStartOfDay) {
				this.console.warn('startOfMonth should never be greater then selectedStartOfDay');
				this.updateStartAndEndOfMonth();
			}
			return true;
		}
		if (this.endOfMonth === null || this.endOfMonth < start) {
			// HACK: endOfMonth should never be less then selectedStartOfDay
			if (this.endOfMonth === null || this.endOfMonth < this.selectedStartOfDay) {
				this.console.warn('endOfMonth should never be less then selectedStartOfDay');
				this.updateStartAndEndOfMonth();
			}
			return true;
		}
		return false;
	}

	private updateStartAndEndOfMonth() : void {
		this.startOfMonth = +this.pMoment.m(this.selectedStartOfDay).startOf(CalendarModes.MONTH);
		this.endOfMonth = +this.pMoment.m(this.selectedStartOfDay).endOf(CalendarModes.MONTH);
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.formControl && this.formControl.disabled !== this.disabled) {
			this.disabled ? this.formControl.disable() : this.formControl.enable();
		}
	}
}
