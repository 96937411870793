import { AfterViewInit, Directive, ElementRef, Input, NgZone, ViewContainerRef } from '@angular/core';
import { FaIconComponent } from '@plano/shared/core/component/fa-icon/fa-icon.component';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ul[listIcon]',
	standalone: true,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class IconListDirective implements AfterViewInit {

	/**
     * Icon to be used in the list items inside the list
     */
	@Input() public listIcon ! : PlanoFaIconPoolValues;

	constructor(
        private el : ElementRef<HTMLUListElement>,
		private viewContainerRef : ViewContainerRef,
		private zone : NgZone,
	) {

	}

	public ngAfterViewInit() : void {
		this.zone.runOutsideAngular(() => {
			const ulElement = this.el.nativeElement;
			ulElement.classList.add('fa-ul');
			const liElements = ulElement.querySelectorAll('li');
			for (const liElement of liElements) {
				window.requestAnimationFrame(() => {
					const faIconComponent = this.viewContainerRef.createComponent(FaIconComponent).instance;
					faIconComponent._icon = this.listIcon;
					faIconComponent.isListIcon = true;
					liElement.insertBefore(faIconComponent.el.nativeElement, liElement.firstChild);
				});
			}
		});
	}
}
