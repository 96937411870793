import { AfterContentInit, ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { NgbFormatsService } from '@plano/client/service/ngbformats.service';
import { AbsenceService } from '@plano/client/shared/absence.service';
import { EditableControlInterface, EditableHookType } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PShiftService } from '@plano/client/shared/p-shift-module/p-shift.service';
import { PTypeOfChange } from '@plano/client/shared/p-transmission/change-selectors-modal.component';
import { SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW } from '@plano/client/shift/shift-modal-sizes';
import { RightsService, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-assign-members[shiftModel]',
	templateUrl: './assign-members.component.html',
	styleUrls: ['./assign-members.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AssignMembersComponent implements AfterContentInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftModel ! : SchedulingApiShiftModel;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift : SchedulingApiShift | null = null;
	@Input() public saveChangesHook ?: EditableControlInterface['saveChangesHook'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showHearts : boolean = false;

	constructor(
		public ngbFormats : NgbFormatsService,
		private modalService : ModalService,
		public absenceService : AbsenceService,
		private rightsService : RightsService,
		private console : LogService,
		private localize : LocalizePipe,
		protected api : SchedulingApiService,
		private pShiftService : PShiftService,
	) {
	}

	public readonly CONFIG = Config;

	public readonly enums = enumsObject;
	public readonly PTypeOfChange = PTypeOfChange;

	private readonly sortAssignedFnArray = [
		(item : SchedulingApiMember) => !item.trashed,
		...defaultSortingForMembers,
	];

	private readonly sortAssignableFnArray = [
		(item : SchedulingApiMember) => item.trashed,
		...defaultSortingForMembers,
	];

	/**
	 * If this is a user with rights to manage activities, then he can only assign
	 * members which are assignable, but can not change the list of assignable members.
	 * Since tempAssignableMembersForModal is a pure frontend copy of filtered api.data.members, we dont use AI logic here.
	 */
	protected get showOtherMembersForModal() : boolean | undefined {
		// If list is empty, we dont show it in the ui.
		if (!this.tempOtherMembersForModal?.length) return false;

		return this.formItem.attributeInfoAssignableMembers.canSet;
	}

	/** Should the user be allowed to edit the assigned or assignable members? */
	protected get canSetBox() : boolean {
		return this.formItem.attributeInfoAssignedMemberIds.canSet || this.formItem.attributeInfoAssignableMembers.canSet;
	}

	/**
	 * Icon of the members absence
	 * @param memberId Id of the member to check for absences
	 */
	public absenceTypeIconName(memberId : Id) : PlanoFaIconPoolValues | null {
		return this.absenceService.absenceTypeIconName(memberId, {
			start : this.shift ? this.shift.start : this.shiftModel.time.start,
			end : this.shift ? this.shift.end : this.shiftModel.time.end,
			id : this.shift ? this.shift.id : null,
		});
	}

	/**
	 * The assignments when the edit modal is opened. Is `null`
	 * when the modal is not open.
	 */
	public tempAssignedMembersForModal : readonly SchedulingApiMember[] | null = null;

	/**
	 * The assignments when the edit modal is opened. Is `null`
	 * when the modal is not open.
	 */
	public tempAssignableMembersForModal : readonly SchedulingApiMember[] | null = null;

	/**
	 * The members that are not assignable when the edit modal is opened. Is `null`
	 * when the modal is not open.
	 */
	public tempOtherMembersForModal : readonly SchedulingApiMember[] | null = null;

	public ngAfterContentInit() : void {
		this.updateListCopies();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public assignMembersHook(modalContent : TemplateRef<PModalTemplateDirective>) : () => EditableHookType {
		return this.modalService.getEditableHookModal(modalContent, {
			size: SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW,
		});
	}

	private updateTempAssignedMembersForModal() : void {
		this.tempAssignedMembersForModal = this.formItem.assignedMembers
			.sortedBy(this.sortAssignedFnArray)
			.iterable();
	}

	private updateTempAssignableMembersForModal() : void {
		this.tempAssignableMembersForModal = this.api.data.members
			.filterBy(item => {
				if (this.tempAssignedMembersForModal?.includes(item)) return false;
				if (!this.formItem.assignableMembers.getByMember(item)) return false;
				return true;
			})
			.sortedBy(this.sortAssignableFnArray)
			.iterable();
	}

	private updateTempOtherMembersForModal() : void {
		if (this.api.data.members.length <= this.formItem.assignableMembers.length) {
			this.tempOtherMembersForModal = null;
			return;
		}
		this.tempOtherMembersForModal = this.api.data.members
			.filterBy(item => {
				if (!this.shift && item.trashed) return false;

				if (this.tempAssignedMembersForModal?.includes(item)) return false;
				if (this.tempAssignableMembersForModal?.includes(item)) return false;
				return true;
			})
			.sortedBy(this.sortAssignableFnArray)
			.iterable();
	}

	/**
	 * Copy some initial data from the shift.
	 * We need them to build lists from it in the UI.
	 * We can not use e.g. shift.assignedMemberIds to build a list, because it would immediately change when user hits checkbox.
	 */
	public updateListCopies() : void {
		this.updateTempAssignedMembersForModal();
		this.updateTempAssignableMembersForModal();
		this.updateTempOtherMembersForModal();
	}

	/**
	 * determine if the "send mail to members" checkbox should be visible or not
	 */
	public get showSendMailCheckbox() : boolean {
		// Never inform a member if ShiftModels gets edited [PLANO-15402]
		if (this.formItem instanceof SchedulingApiShiftModel) return false;

		// Changing assignedMembers ➡ Show Checkbox
		if (this.assignedMembersChanged) return true;

		if (this.changingAssignableMembers) return true;

		return false;
	}

	private get assignedMembersChanged() : boolean {
		if (this.tempAssignedMembersForModal === null)
			return false;

		return !this.formItem.assignedMembers.equalsIgnoringOrder(this.tempAssignedMembersForModal);
	}

	/**
	 * A danger text to be shown when user is changing the assignments and transferring it to further shifts.
	 */
	public get dangerTextOverridingOtherShiftsAssignment() : string | null {
		if (!this.assignedMembersChanged)
			return null;

		const newAssignments = this.formItem.assignedMembers;

		if (newAssignments.length === 0) {
			return this.localize.transform('Du möchtest eine geänderte »Macht«-Einstellung übertragen. Damit entfernst du bei allen Schichten, auf die du diese Änderung überträgst, die ggf. vorhandene Besetzung ⚡');
		} else {
			// serialize the name of assigned members
			let newAssignmentsString = '';
			for (const newAssignedMember of newAssignments.iterable()) {
				if (newAssignmentsString !== '')
					newAssignmentsString += ' & ';

				newAssignmentsString += `<mark>${newAssignedMember.firstName}</mark>`;
			}

			// return danger text
			return this.localize.transform({
				sourceString: 'Alle Schichten, auf die du diese Änderung überträgst, werden mit ${newAssignments} besetzt werden. Ihre ggf. vorhandene Besetzung geht verloren ⚡',
				params: { newAssignments : newAssignmentsString },
			});
		}
	}

	private get changingAssignableMembers() : boolean {
		// Changing "assignableMembers" and applying to other shifts?
		if (!this.api.data.shiftChangeSelector.isChangingShifts) return false;

		// Changing "assignableMembers" but invalid data
		if (!this.api.data.shiftChangeSelector.start) return false;

		// Changing "assignableMembers" but invalid data
		if (!this.api.data.shiftChangeSelector.end) return false;

		// Changing "assignableMembers"
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get formItem() : SchedulingApiShift | SchedulingApiShiftModel {
		if (this.shift) return this.shift;
		return this.shiftModel;
	}

	private _assignableMembersForList = new Data<readonly SchedulingApiMember[]>(this.api);

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get assignableMembersForList() : readonly SchedulingApiMember[] {
		return this._assignableMembersForList.get(() => {
			if (!this.formItem.attributeInfoAssignableMembers.isAvailable) return [];
			if (this.formItem.assignableMembers.length === 0) return [];
			return this.api.data.members
				.filterBy(member => {
					if (this.formItem.attributeInfoAssignedMemberIds.isAvailable && this.formItem.assignedMemberIds.contains(member.id)) return false;
					return this.formItem.assignableMembers.some(item => item.memberId.equals(member.id));
				})
				.sortedBy(this.sortAssignableFnArray)
				.iterable();
		});
	}

	private _assignedMembersForList = new Data<readonly SchedulingApiMember[]>(this.api);

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get assignedMembersForList() : readonly SchedulingApiMember[] {
		return this._assignedMembersForList.get(() => {
			if (!this.formItem.attributeInfoAssignedMemberIds.isAvailable) return [];
			if (this.formItem.assignedMemberIds.length === 0) return [];
			return this.api.data.members
				.filterBy(item => {
					return this.formItem.assignedMemberIds.contains(item.id);
				})
				.sortedBy(this.sortAssignedFnArray)
				.iterable();
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasAssignedMembersForList() : boolean {
		// TODO: PLANO-156519
		if (!this.formItem.attributeInfoAssignedMemberIds.isAvailable) return false;
		return this.formItem.assignedMemberIds.length > 0;
	}

	/** Are there any assignable members right now? */
	public get hasAssignableMembersForList() : boolean {
		return this.assignableMembersForList.length > 0;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public earningsForMember(member : SchedulingApiMember) : number | null {
		const ASSIGNABLE_MEMBER = this.formItem.assignableMembers.getByMember(member);
		if (ASSIGNABLE_MEMBER === null) return null;
		const earnings = ASSIGNABLE_MEMBER.attributeInfoHourlyEarnings.value;
		if (earnings === null) {
			this.console.warn(`Member »${ASSIGNABLE_MEMBER.id.toString()}«: `, ASSIGNABLE_MEMBER);
			this.console.error('earningsForMember could not be calculated. Not sure if this should happen. STAGING-2N');
		}
		return earnings;
	}

	/**
	 * Toggle passed item in List of Assignable ShiftModels
	 * @param member Selected item
	 */
	public toggleAssignableMember(member : SchedulingApiMember) : void {
		if (!this.formItem.assignableMembers.containsMember(member)) {
			this.formItem.assignableMembers.addNewMember(member);
			return;
		}

		if (this.formItem.assignedMemberIds.contains(member.id)) {
			this.formItem.assignedMemberIds.removeItem(member.id);
		}
		this.formItem.assignableMembers.removeMember(member);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public toggleAssignedMember(member : SchedulingApiMember) : void {
		if (this.formItem.assignedMemberIds.contains(member.id)) {
			this.formItem.assignedMemberIds.removeItem(member.id);
			return;
		}

		this.formItem.assignableMembers.addNewMember(member);
		this.formItem.assignedMemberIds.push(member.id);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public isMe(member : SchedulingApiMember) : boolean {
		const result = this.rightsService.isMe(member.id);
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get isOwner() : boolean {
		const result = this.rightsService.isOwner;
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	/** @see ApiAttributeInfoBase#cannotSetHint */
	public get cannotSetHint() : PDictionarySource | null {
		if (this.shift === null) return null;
		return !this.shift.attributeInfoAssignedMemberIds.canSet ? this.shift.attributeInfoAssignedMemberIds.cannotSetHint : null;
	}

	/**
	 * We want to show a hint if no one is set as assignable yet.
	 * This is done to prevent that we have an empty preview area of the modal-box.
	 */
	public get showNoAssignableMembersHint() : boolean | undefined {
		// If there is a cannotSetHint, then the box will not be empty anyway.
		if (this.cannotSetHint) return false;

		return !this.hasAssignedMembersForList && !this.hasAssignableMembersForList;
	}
}
