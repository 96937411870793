import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { PBadgeContent } from '@plano/client/shared/shared/p-badge/p-badge.types';
import { ApiDataWrapperBase } from '@plano/shared/api';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PFormControlComponentChildInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { PRadiosRadioComponent } from '@plano/shared/p-forms/p-radios/p-radios-radio/p-radios-radio.component';

/**
 * This is a child of a p-ai-switch
 * It can be used to provide/bind the necessary values for the children of e.g. dropdowns or radios.
 *
 * @example
 * <p-ai-switch
 * 	label="Gender" i18n-label
 * 	[attributeInfo]="item.attributeInfoGender"
 * 	[group]="formGroup"
 * >
 * 	<p-ai-switch-item
 * 		label="Male" i18n-label
 * 		[value]="'male'"
 * 	></p-ai-switch-item>
 * 	<p-ai-switch-item
 * 		label="Female" i18n-label
 * 		[value]="'female'"
 * 	></p-ai-switch-item>
 * 	<p-ai-switch-item
 * 		label="Other" i18n-label
 * 		[value]="null"
 * 	></p-ai-switch-item>
 * </p-ai-switch>
 */
@Component({
	selector: 'p-ai-switch-item[label]',
	templateUrl: './p-ai-switch-item.component.html',
	styleUrls: ['./p-ai-switch-item.component.scss'],
})
export class PAISwitchItemComponent extends AttributeInfoBaseComponentDirective implements PFormControlComponentChildInterface {

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public value : PFormControlComponentChildInterface['value'];

	@Input() public icon : PFormControlComponentChildInterface['icon'] = null;

	/** @see PRadiosRadioComponent#iconTheme */
	@Input() public iconTheme : PRadiosRadioComponent['iconTheme'] = null;

	/** @see PRadiosRadioComponent#stretchHeight */
	@Input() public stretchHeight : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public label ! : PFormControlComponentChildInterface['label'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public description : PFormControlComponentChildInterface['description'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public active : PFormControlComponentChildInterface['active'] = null;
	@Input('disabled') public set disabled(input : boolean) {
		this._disabled = input;
	}

	/**
	 * Is this item disabled?
	 */
	public get disabled() : boolean {
		return this._disabled || (this.attributeInfo ? !this.attributeInfo.canSet : false);
	}
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick = new EventEmitter<unknown>();

	/** @see AttributeInfoBaseComponentDirective#attributeInfo */
	@Input() public override attributeInfo : ApiAttributeValueInfo<ApiDataWrapperBase> | null = null;

	/**
	 * Classes to be added to the wrapper element of the radio.
	 * NOTE: This will remove the col classes, so please re-add it manually if needed
	 */
	@Input() public wrapperClasses : string | null = null;

	/**
	 * Badge to be added to the item
	 */
	@Input() public badgeOnItem : PBadgeContent = null;

	/**
	 * Badge theme to be added to the item
	 */
	@Input() public badgeTheme : PThemeEnum | 'reverse-primary' = 'primary';

	private _disabled : boolean = false;

	public override attributeInfoRequired = false;

	/** get description if available and string */
	public get descriptionString() : string | null {
		if (this.description === null) return null;
		if (typeof this.description !== 'string') return null;
		return this.description;
	}

	/** get description if available and type templateRef */
	public get descriptionTemplateRef() : TemplateRef<unknown> | null {
		if (this.description === null) return null;
		if (typeof this.description === 'string') return null;
		return this.description;
	}
}
