import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SchedulingApiBooking } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-persons',
	templateUrl: './persons.component.html',
	styleUrls: ['./persons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PPersonsComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public count : number | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public tariffName : string | null = '?';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public ageMin : number | null | '?' = '?';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public ageMax : number | null | '?' = '?';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public additionalFieldLabel : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public additionalFieldValue : SchedulingApiBooking['additionalFieldValue'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public price : number | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public tariffNotAvailableThatTime : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public ageLimitWarning : string | null = null;

	constructor( private localize : LocalizePipe ) {}

	public CONFIG = Config;
	public enums = enumsObject;

	/**
	 * Get the age range text
	 */
	public get ageRangeText() : string {
		if (this.ageMin !== this.ageMax) {
			return this.localize.transform({
				sourceString: '${ageMin} – ${ageMax} Jahre',
				params: {ageMin:`${this.ageMin ?? '-'}`, ageMax:`${this.ageMax ?? '-'}`},
			});
		} else {
			return this.localize.transform({sourceString: '${age} Jahre', params: {age: `${this.ageMin ?? '-'}`}});
		}
	}
}
