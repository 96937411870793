<a [routerLink]="pathToDetailPage" class="card-option btn-group border-0">
	<p-button
		class="d-flex"
		[btnTitle]="title"
		btnClasses="card-option border-bottom-0 rounded-0"
		[theme]="style"
		[badge]="badgeValue"
		(triggerClick)="preventNavigationIfNecessary($event)"
	><fa-icon [class.text-primary]="iconHasTextPrimaryClass" [icon]="icon"></fa-icon></p-button>
</a>

