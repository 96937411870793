<div style=" position: relative;z-index: 1;"
	[class.lastActionTime-spacer]="showLastActionTime"
>
	<ng-template [ngIf]="communications" [ngIfElse]="singleMemberTemplate">
		<div class="d-flex align-items-left gap-2"
			[class.nowrap]="!showNames"
			[class.flex-column]="showNames"
		>
			<div class="badges d-flex align-items-center flex-grow-0 flex-shrink-1 position-relative gap-2"
				[class.o-hidden]="!showNames"
				[class.pr-4]="!showNames"
				[class.flex-wrap]="showNames"
			>
				<ng-template
					ngFor let-communicationsCommunication
					[ngForOf]="communications.iterable()"
				>
					<div
						[style.margin-right.px]="showNames ? 0 : -20"
						class="d-flex align-items-center nowrap hover-hide-trashed gap-2"
						[class.py-1]="showNames"
						[class.px-2]="showNames"
						[class.bg-light]="showNames"
						[class.rounded]="showNames"
						[class.clickable]="communications.length > 1 && !showNames"
						(click)="communications.length > 1 ? showNames=true : undefined"
					>
						<p-member-badge
							*ngIf="communicationsCommunication.communicationPartner"
							[alwaysShowMemberInitials]="true"
							[memberId]="communicationsCommunication.communicationPartner.id"
							[firstName]="communicationsCommunication.communicationPartner.firstName"
							[lastName]=" communicationsCommunication.communicationPartner.lastName"
							[isMe]="isMe(communicationsCommunication.communicationPartner)!"
							size="normal"
						></p-member-badge>
						<div *ngIf="showNames || communications.length === 1" class="crop-on-overflow">{{communicationsCommunication.communicationPartner!.firstName}} {{communicationsCommunication.communicationPartner!.lastName}}</div>
					</div>
				</ng-template>
				<div class="too-long-list-shadow"></div>
			</div>
			<a
				*ngIf="communications.length > 1"
				class="text-primary small clickable min-w-fit-content"
				[class.mb-3]="showNames"
				[class.align-self-center]="!showNames"
				(click)="showNames=!showNames"
			>
				<fa-icon [icon]="showNames ? 'eye-slash' : 'eye'" class="mr-1"></fa-icon><ng-container i18n>Namen {showNames, select, true {verbergen} other {anzeigen}}</ng-container>
			</a>

		</div>
	</ng-template>
	<ng-template #singleMemberTemplate>
		<div class="d-flex align-items-center hover-hide-trashed gap-2">
			<p-member-badge
				[alwaysShowMemberInitials]="true"
				[memberId]="communication.communicationPartner ? communication.communicationPartner.id : null"
				[firstName]="communication.communicationPartner ? communication.communicationPartner.firstName : undefined"
				[lastName]="communication.communicationPartner ? communication.communicationPartner.lastName : undefined"
				[isMe]="isMe(communication.communicationPartner)!"
				size="normal"
			></p-member-badge>
			<div class="crop-on-overflow">{{communication.communicationPartner!.firstName}} {{communication.communicationPartner!.lastName}}</div>
		</div>
	</ng-template>
</div>
<div style=" position: relative;z-index: 0;" class="card bg-light shadow-sm communication-card mb-0">
	<ng-template [ngIf]="!!communication.hasTodo">
		<div
			*ngIf="!!badgeIcon; else badgeTextTemplate"
			class="todo-badge badge badge-light border"
			[class.border-danger]="badgeIcon === enums.PlanoFaIconPool.CANCELED"
			[class.text-danger]="badgeIcon === enums.PlanoFaIconPool.CANCELED"
			[class.border-success]="badgeIcon === enums.PlanoFaIconPool.SUCCESS"
			[class.text-success]="badgeIcon === enums.PlanoFaIconPool.SUCCESS"
			[class.border-primary]="badgeIcon === enums.PlanoFaIconPool.QUESTION"
			[class.text-primary]="badgeIcon === enums.PlanoFaIconPool.QUESTION"
			[class.align-left]="badgeAlign === 'left'"
			[class.align-right]="badgeAlign === 'right'"
		>
			<fa-icon [icon]="badgeIcon" style="font-size: 0.6rem;"></fa-icon>
		</div>
		<ng-template #badgeTextTemplate>
			<div
				class="todo-badge badge badge-danger border-danger"
				[class.align-left]="badgeAlign === 'left'"
				[class.align-right]="badgeAlign === 'right'"
			>
				<code class="text-white">1</code>
			</div>
		</ng-template>
	</ng-template>

	<div
		*ngIf="showLastActionTime"
		class="badge badge-light border last-action-time"
	>Update: {{ communication.lastActionTime | pTimeAgo }}</div>
	<div [class.p-4]="!CONFIG.IS_MOBILE" [class.p-3]="CONFIG.IS_MOBILE">
		<div class="d-block d-md-flex align-items-center gap-3">
			<ng-container *ngTemplateOutlet="stateInfo"></ng-container>
			<p-badge
				[theme]="enums.PThemeEnum.WARNING"
				[todoBadge]="false"
				[content]="needsReviewLabel ?? null"/>

			<ng-template [ngIf]="communication.attributeInfoLastActionComment.isAvailable">
				<div class="d-block d-md-flex align-items-center mt-3 mt-md-0 gap-3">
					<label class="mb-0" i18n>Kommentar an {!!indisposedMemberPerformedLastAction, select, true {{{communication.communicationPartner!.firstName}}} other {{{shiftExchange!.indisposedMember!.firstName}}}}:</label>
					<blockquote [class.clickable]="communication.attributeInfoLastActionComment.canSet && !showTextarea" (click)="onStartEditComment()">
						<span [class.text-muted]="!communication.lastActionComment">{!!communication.lastActionComment, select, true {{{ communication.lastActionComment }}} other {-}}</span>
						<fa-icon class="ml-2" icon="pen" title="Bearbeiten" i18n-title *ngIf="communication.attributeInfoLastActionComment.canSet && !showTextarea"></fa-icon>
					</blockquote>
				</div>
			</ng-template>
		</div>
		<ng-template [ngIf]="showTextarea">
			<div hidden><span i18n #translatedLastActionCommentLabel>Kommentar an {!!indisposedMemberPerformedLastAction, select, true {{{communication.communicationPartner!.firstName}}} other {{{shiftExchange!.indisposedMember!.firstName}}}}</span></div>
			<p-bootstrap-form-group
				class="mt-3"
				[label]="translatedLastActionCommentLabel.textContent!"
			>
				<p-textarea
					[pEditable]="!shiftExchange!.isNewItem()"
					[api]="api"
					(onDismiss)="showTextarea=false"
					(onSaveSuccess)="showTextarea=false"

					[(ngModel)]="communication.lastActionComment"
					[disabled]="!communication.attributeInfoLastActionComment.canSet"
				></p-textarea>
			</p-bootstrap-form-group>
		</ng-template>
		<!-- <ng-template [ngTemplateOutlet]="shiftInfoTemplate"></ng-template> -->
	</div>
</div>

<ul class="p-0 mx-3 mt-0 shadow-sm rounded-bottom d-flex align-items-stretch flex-row border-bottom border-left border-right row" *ngIf="availableActionDataArray.length">
	<button
		type="button"
		*ngFor="let availableActionData of availableActionDataArray; let i = index"
		class="action-button btn btn-outline-secondary btn-frameless d-flex justify-content-start align-items-center"
		[class.col-12]="CONFIG.IS_MOBILE"
		[class.col]="!CONFIG.IS_MOBILE"
		[class.btn-lg]="CONFIG.IS_MOBILE"
		[class.justify-content-start]="CONFIG.IS_MOBILE"
		[class.justify-content-center]="!CONFIG.IS_MOBILE"
		[class.border-left]="i!==0 && !CONFIG.IS_MOBILE"
		[class.border-top]="i!==0 && CONFIG.IS_MOBILE"
		(click)="onPerformAction(availableActionData)"
	>
		<fa-icon
			*ngIf="availableActionIcon(availableActionData.action)"
			class="mr-2 flex-grow-0"
			[class.text-success]="availableActionIconStyle(availableActionData.action) === 'success'"
			[class.text-danger]="availableActionIconStyle(availableActionData.action) === 'danger'"
			[fixedWidth]="true"
			[icon]="availableActionIcon(availableActionData.action)!"
		></fa-icon>
		<span class="text-left">{{ availableActionText(availableActionData.action, shiftExchange!) }}</span>
	</button>
</ul>

<ng-template #stateInfo>
	<p-shift-exchange-state-badge
		class="d-flex"
		*ngIf="!shiftExchange!.isNewItem()"
		[shiftExchange]="shiftExchange!"
		[communication]="communication"
		[text]="lastActionStateText"
		[theme]="stateTheme"
	></p-shift-exchange-state-badge>
</ng-template>

<!-- <ng-template #shiftInfoTemplate>
	<ng-template ngFor let-swapOffer [ngForOf]="communication.swapOffers.iterable()" let-i="index">
		<p-shifts-info
			*ngIf="swapOffer.shiftRefs.length"
			class="border-top bg-white"
			[class.border-bottom]="!!communication.lastActionComment && i === communication.swapOffers.length - 1"
			[shiftRefs]="swapOffer.shiftRefs"
		>
			<p-shift-info-content-right
				*ngIf="communication.indisposedMembersSelectedSOId.equals(swapOffer.id)"
			>
				<fa-icon class="mr-2 text-success" [icon]="enums.PlanoFaIconPool.SUCCESS"></fa-icon>
			</p-shift-info-content-right>
		</p-shifts-info>
	</ng-template>
</ng-template> -->
