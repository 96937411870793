<div class="modal-header pl-3 position-relative">
	<div class="d-flex justify-content-between align-items-center w-100">
		<div class="mr-auto w-100" *ngIf="headline || item">
			<h4 *ngIf="headline"
				[class.text-white]="textWhite"
				[class.text-danger]="hasDanger"
			>{{ headline }}</h4>
			<ng-template [ngIf]="item">
				<span class="text-monospace nowrap mt-2" [class.text-white]="textWhite" *ngIf="start">{{ start | date:'shortDate' }} | {{ start | date:'shortTime' }}</span>
			</ng-template>
		</div>
		<ng-content></ng-content>
		<button type="button" class="btn large p-0" title="Schließen" i18n-title (click)="onClose.emit($event)">
			<fa-icon aria-hidden="true" [theme]="textWhite ? PTextColorEnum.WHITE : null" [icon]="enums.PlanoFaIconPool.DISMISS"></fa-icon>
		</button>
	</div>
</div>
