import { Location } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, Component, Injector, OnInit, enableProdMode } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { BookingsService } from '@plano/client/scheduling/shared/p-bookings/bookings.service';
import { SchedulingApiService, ShiftSelector } from '@plano/shared/api';
import { PExternalLinkComponent } from '@plano/shared/core/external-link/p-external-link.component';
import { PAutoFocusService } from '@plano/shared/core/p-auto-focus/p-auto-focus.service';
import { PWindowSizeService } from '@plano/shared/core/window-size.service';
import { ENVIRONMENT } from './environments/environment';
import { Config } from './shared/core/config';
import { PFingerprintService } from './shared/core/fingerprint.service';
import { ModalService } from './shared/core/p-modal/modal.service';
import { PPushNotificationsService } from './shared/core/p-push-notifications.service';
import { PRouterService } from './shared/core/router.service';
import { PScrollToSelectorService } from './shared/core/scroll-to-selector.service';
import { SeoService } from './shared/core/seo.service';
import { PSentryService } from './shared/sentry/sentry.service';

if (ENVIRONMENT.APPLICATION_MODE === 'PROD') {
	enableProdMode();
}

@Component({
	selector: 'p-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AppComponent implements OnInit, AfterViewInit, AfterContentChecked {
	private get _classIsMobile() : boolean {
		return Config.IS_MOBILE;
	}
	private get _classIsNotMobile() : boolean {
		return !Config.IS_MOBILE;
	}

	constructor(
		private activatedRoute : ActivatedRoute,
		private modalService : ModalService,
		private location : Location,
		private router : Router,
		private pRouterService : PRouterService,
		private api : SchedulingApiService,
		private bookingsService : BookingsService,
		private pScrollToSelectorService : PScrollToSelectorService,
		private readonly seoService : SeoService, // ensure service is created on client open
		private pushNotifications : PPushNotificationsService, // ensure service is created on client open
		private _fingerprintService : PFingerprintService, // Ensure service is created on startup to improve identification accuracy
		private _pAutoFocusService : PAutoFocusService, // ensure service is created on client open
		private pSentryService : PSentryService,
		private schedulingService : SchedulingService,
		private pWindowSizeService : PWindowSizeService,
		private injector : Injector,
	) {
		Config.setWindowWidthServiceAccess(this.pWindowSizeService);

		// eslint-disable-next-line rxjs/no-ignored-subscription -- App component will only be initialized once
		this.activatedRoute.queryParams.subscribe((inputQueryParams : Params) => {
			this.toggleShiftsOnParamsChange(inputQueryParams);

			const { requiresLargeScreen } : Params = inputQueryParams;

			if (requiresLargeScreen) {
				// remove query params so reloading page does not trigger this again.
				// We use this.location which also removes the params from browsers history stack.
				const { url } : AppComponent['router'] = this.router;
				const baseUrl = url.slice(0, Math.max(0, url.indexOf('?')));
				this.location.replaceState(baseUrl);

				// show info that user should use his PC?
				if (Config.IS_MOBILE) {
					this.modalService.info(
						{
							description: 'Für diese Funktion nutze bitte deinen PC.',
						},
					);
				}
			}
		});
		this.pSentryService.init();

		this.pRouterService.handleAnchorLinks();

		this.pRouterService.setNavigationSentryTransactionName();
	}

	public ngOnInit() : void {
		// add the pExternalLinkComponent as an element, so we can use it in external HTML as a normal element
		const pExternalLinkElement = createCustomElement(PExternalLinkComponent, {injector: this.injector});
		customElements.define('ld-p-external-link', pExternalLinkElement);
	}

	public ngAfterContentChecked() : void {
		this.pScrollToSelectorService.changeDetectionTriggered.next();
	}

	/**
	 * It is important that the scheduling api has been loaded on
	 * the scheduling component so we ensure that all relevant shifts are
	 * loaded onto the api.
	 *
	 * @param inputQueryParams the new query params
	 */
	private toggleShiftsOnParamsChange(inputQueryParams : Params) : void {
		const tempSubscription = this.schedulingService.schedulingApiHasBeenLoadedOnSchedulingComponent.subscribe(() => {
			if (inputQueryParams['bookingCourseSelector']) {
				const bookingCourseSelector = JSON.parse(inputQueryParams['bookingCourseSelector']);
				this.api.isLoaded(()=>{
					this.bookingsService.onSelectShifts(ShiftSelector.create(bookingCourseSelector.data), this.api.data.shifts);
				});
			}
			tempSubscription.unsubscribe();
		});
	}

	public ngAfterViewInit() : void {
		if (this._classIsNotMobile) {
			document.body.classList.add('is-desktop');
		} else if (this._classIsMobile) {
			document.body.classList.add('is-mobile');
		}
	}

}
