import { Directive, ElementRef } from '@angular/core';

/**
 * The info regarding a child of an odd even parent
 */
export type OddOrEvenInfo = {oddOrEven : 'odd' | 'even', assignedIndex : number};

/**
 * This directive should be used in a parent of a list of elements
 * that should have an alternating style.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[oddEvenParent]',
	standalone: true,
})
export class OddEvenDirective {

	constructor(
          public elementRef : ElementRef<HTMLElement>,
	) {

	}

	/**
	 * Counter responsible for deciding if the element is odd or even.
	 */
	private oddOrEvenCounter = 0;

	/**
	 * Counter for total amount of elements ( even the ones that don't increase the counter )
	 */
	private elementsCounter = 0;

	private indexToOddEvenArray : ('odd' | 'even') [] = [];

	/**
     * Method to be used by the children of the element that use this directive.
     *
     * It will tell the child element if it is in a odd or even position
     * which will allow it to make decisions based on if the index is
     * odd or even allowing for alternating layouts.
     *
     * Make sure that this method is only called once per child.
	 *
	 * If the section should not change the current counter, pass 'false' as an argument.
     */
	public requestOddOrEven(increaseCounter : boolean = true) : OddOrEvenInfo {
		const state = increaseCounter ? this.currentState() : this.previousState();
		this.indexToOddEvenArray.push(state);
		if (increaseCounter)
			this.oddOrEvenCounter++;
    	return {oddOrEven: state, assignedIndex: this.elementsCounter++};
	}

	/**
      * The current state of the oddEvenParent
      */
	public previousState() : 'odd' | 'even' {
		return ((this.oddOrEvenCounter-1) % 2 === 0 ? 'even' : 'odd');
	}

	/**
      * The current state of the oddEvenParent
      */
	public currentState() : 'odd' | 'even' {
		return (this.oddOrEvenCounter % 2 === 0 ? 'even' : 'odd');
	}

	/**
	 * Is the next element different?
	 */
	public isNextDifferent(index : number) : boolean {
		if (this.indexToOddEvenArray.length === index + 1) return true;
		return this.indexToOddEvenArray.at(index) !== this.indexToOddEvenArray.at(index+1);
	}

	/**
	 * Is the previous element different?
	 */
	public isPreviousDifferent(index : number) : boolean {
		if (index === 0) return true;
		return this.indexToOddEvenArray.at(index) !== this.indexToOddEvenArray.at(index-1);
	}

}
