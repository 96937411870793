import { Component, Input } from '@angular/core';
import { PPricesService } from '@plano/client/shared/p-prices.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NonEmptyString } from '@plano/shared/core/utils/typescript-utils-types';

/**
 * <p-marketing-gift-cards> provides the collapsible with the information about marketing gift-cards.
 */
@Component({
	selector: 'p-marketing-gift-cards-faq[anchorLink]',
	templateUrl: './p-marketing-gift-cards-faq.component.html',
	styleUrls: ['./p-marketing-gift-cards-faq.component.scss'],
})
export class PMarketingGiftCardsFaqComponent {
	/**
	 * link to be added to the collapsible
	 */
	@Input() public anchorLink ! : NonEmptyString;

	/** @see PCollapsibleComponent#stickyOffset */
	@Input() public stickyOffset : number = 0;

	constructor(
		public pPricesService : PPricesService,
	) {

	}

	public enums = enumsObject;
}
