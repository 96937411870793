/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';

import { AffectedShiftsApiShifts } from '@plano/shared/api';
import { AffectedShiftsApiShift } from '@plano/shared/api';


/**
 * This service enables access to the api "affected_shifts".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	SHIFTS = 1;
	BOOKINGS_CANCELED_COUNT = 2;
	BOOKINGS_DECLINED_COUNT = 3;
	SHIFT_START = 1;
	SHIFT_END = 2;
	SHIFT_ASSIGNED_MEMBER_IDS = 3;
	SHIFT_NEEDED_MEMBERS_COUNT = 4;
	SHIFT_IS_COURSE_CANCELED = 5;
	SHIFT_IS_COURSE_ONLINE = 6;
	SHIFT_MIN_COURSE_PARTICIPANT_COUNT = 7;
	SHIFT_MAX_COURSE_PARTICIPANT_COUNT = 8;
	SHIFT_CURRENT_COURSE_PARTICIPANT_COUNT = 9;
}


@Injectable({
  providedIn: 'root',
})
export class AffectedShiftsApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'affected_shifts');
	}

	protected version() : string {
		return '8e8f18fdce97b0ad4eacf9565b059349,a411df5cf73044f047bbeaf4edce3428';
	}

	private dataWrapper = new AffectedShiftsApiRoot(this, );

	get data() : AffectedShiftsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : AffectedShiftsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new AffectedShiftsApiRoot(this, );
	}
}

		 
export class AffectedShiftsApiRoot extends ApiObjectWrapper<AffectedShiftsApiRoot>
{
	constructor(override readonly api : AffectedShiftsApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AffectedShiftsApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, AffectedShiftsApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AffectedShiftsApiRoot, AffectedShiftsApiRoot> = new ApiAttributeInfo<AffectedShiftsApiRoot, AffectedShiftsApiRoot>({
			apiObjWrapper: this as any as AffectedShiftsApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToSet: () => false,
			defaultValue: function(this : AffectedShiftsApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoBookingsCanceledCount : ApiAttributeInfo<AffectedShiftsApiRoot, Integer> = new ApiAttributeInfo<AffectedShiftsApiRoot, Integer>({
			apiObjWrapper: this as any as AffectedShiftsApiRoot,
			name: 'bookingsCanceledCount',
			nodeName: 'BOOKINGS_CANCELED_COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			hasRightToSet: () => false,
			rawDataIndex: 2,
		});
	attributeInfoBookingsDeclinedCount : ApiAttributeInfo<AffectedShiftsApiRoot, Integer> = new ApiAttributeInfo<AffectedShiftsApiRoot, Integer>({
			apiObjWrapper: this as any as AffectedShiftsApiRoot,
			name: 'bookingsDeclinedCount',
			nodeName: 'BOOKINGS_DECLINED_COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});

	private shiftsWrapper : AffectedShiftsApiShifts = new AffectedShiftsApiShifts(this.api,
		this as unknown as AffectedShiftsApiRoot, false);
	public attributeInfoShifts = this.shiftsWrapper.attributeInfoThis;

	/**
     *  List of affected shifts. This list returns all shifts defined by "currentShiftIds" and shifts defined by "shiftChangeSelector", both limited to range "start" and "end".
     */
	get shifts() : AffectedShiftsApiShifts {
		this.getterDebugValidations(this.attributeInfoShifts, false);
		return this.shiftsWrapper;
	}

	set shiftsTestSetter(v : AffectedShiftsApiShifts) {
        this.setterImpl(1, v.rawData, 'shifts', true, null, () => {this.shiftsWrapper = v;});
	}

	/**
     *  How many bookings will be canceled? Those will have the previous state "BOOKED". Always sends 0 when "action" query-parameter is "other". This value is not affected by the query parameters "start" and "end".
	 *
	 * @type {Integer}
     */
	get bookingsCanceledCount() : Integer {
		this.getterDebugValidations(this.attributeInfoBookingsCanceledCount, false);
		return this.data[2];
	}

	set bookingsCanceledCountTestSetter(v : Integer) {
        this.setterImpl(2, v, 'bookingsCanceledCount', true, null, null);
	}

	/**
     *  How many bookings will be declined? Those will have the previous state "INQUIRY". Always sends 0 when "action" query-parameter is "other". This value is not affected by the query parameters "start" and "end".
	 *
	 * @type {Integer}
     */
	get bookingsDeclinedCount() : Integer {
		this.getterDebugValidations(this.attributeInfoBookingsDeclinedCount, false);
		return this.data[3];
	}

	set bookingsDeclinedCountTestSetter(v : Integer) {
        this.setterImpl(3, v, 'bookingsDeclinedCount', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.shiftsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.shiftsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : AffectedShiftsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class AffectedShiftsApiShiftsBase extends ApiListWrapper<AffectedShiftsApiShift>
{
	constructor(override readonly api : AffectedShiftsApiService | null,
		override readonly parent : AffectedShiftsApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'shifts');
	}

	override wrapRawData(itemRawData : any) : AffectedShiftsApiShift {
		return new AffectedShiftsApiShift(this.api, this as unknown as AffectedShiftsApiShifts, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : AffectedShiftsApiRoot | null, removeDestroyedItems : boolean) : this {
		return new AffectedShiftsApiShifts(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : AffectedShiftsApiShift) => void) | null = null, _backendId : ShiftId | null = null) : AffectedShiftsApiShift {
		const newItem = new AffectedShiftsApiShift(this.api, this as unknown as AffectedShiftsApiShifts, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('shifts');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<AffectedShiftsApiShifts, AffectedShiftsApiShifts> = new ApiAttributeInfo<AffectedShiftsApiShifts, AffectedShiftsApiShifts>({
			apiObjWrapper: this as any as AffectedShiftsApiShifts,
			name: 'shifts',
			nodeName: 'SHIFTS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			hasRightToSet: () => false,
			defaultValue: function(this : AffectedShiftsApiShifts, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 1,
		});
}

				 
export class AffectedShiftsApiShiftBase extends ApiObjectWrapper<AffectedShiftsApiShift>
{
	constructor(override readonly api : AffectedShiftsApiService | null,
		override readonly parent : AffectedShiftsApiShifts | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AffectedShiftsApiShift> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AffectedShiftsApiShift as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AffectedShiftsApiShift, AffectedShiftsApiShift> = new ApiAttributeInfo<AffectedShiftsApiShift, AffectedShiftsApiShift>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'shift',
			nodeName: 'SHIFT',
			hasRightToSet: () => false,
			defaultValue: function(this : AffectedShiftsApiShift, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoStart : ApiAttributeInfo<AffectedShiftsApiShift, DateTime> = new ApiAttributeInfo<AffectedShiftsApiShift, DateTime>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'start',
			nodeName: 'SHIFT_START',
			primitiveType: PApiPrimitiveTypes.DateTime,
			hasRightToSet: () => false,
			rawDataIndex: 1,
		});
	attributeInfoEnd : ApiAttributeInfo<AffectedShiftsApiShift, DateTime> = new ApiAttributeInfo<AffectedShiftsApiShift, DateTime>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'end',
			nodeName: 'SHIFT_END',
			primitiveType: PApiPrimitiveTypes.DateTime,
			hasRightToSet: () => false,
			rawDataIndex: 2,
		});
	attributeInfoNeededMembersCount : ApiAttributeInfo<AffectedShiftsApiShift, Integer> = new ApiAttributeInfo<AffectedShiftsApiShift, Integer>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'neededMembersCount',
			nodeName: 'SHIFT_NEEDED_MEMBERS_COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			hasRightToSet: () => false,
			rawDataIndex: 4,
		});
	attributeInfoIsCourseCanceled : ApiAttributeInfo<AffectedShiftsApiShift, boolean> = new ApiAttributeInfo<AffectedShiftsApiShift, boolean>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'isCourseCanceled',
			nodeName: 'SHIFT_IS_COURSE_CANCELED',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : AffectedShiftsApiShift, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	attributeInfoIsCourseOnline : ApiAttributeInfo<AffectedShiftsApiShift, boolean> = new ApiAttributeInfo<AffectedShiftsApiShift, boolean>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'isCourseOnline',
			nodeName: 'SHIFT_IS_COURSE_ONLINE',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : AffectedShiftsApiShift, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});
	attributeInfoMinCourseParticipantCount : ApiAttributeInfo<AffectedShiftsApiShift, Integer> = new ApiAttributeInfo<AffectedShiftsApiShift, Integer>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'minCourseParticipantCount',
			nodeName: 'SHIFT_MIN_COURSE_PARTICIPANT_COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			hasRightToSet: () => false,
			rawDataIndex: 7,
		});
	attributeInfoMaxCourseParticipantCount : ApiAttributeInfo<AffectedShiftsApiShift, Integer> = new ApiAttributeInfo<AffectedShiftsApiShift, Integer>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'maxCourseParticipantCount',
			nodeName: 'SHIFT_MAX_COURSE_PARTICIPANT_COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			hasRightToSet: () => false,
			rawDataIndex: 8,
		});
	attributeInfoCurrentCourseParticipantCount : ApiAttributeInfo<AffectedShiftsApiShift, Integer> = new ApiAttributeInfo<AffectedShiftsApiShift, Integer>({
			apiObjWrapper: this as any as AffectedShiftsApiShift,
			name: 'currentCourseParticipantCount',
			nodeName: 'SHIFT_CURRENT_COURSE_PARTICIPANT_COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			hasRightToSet: () => false,
			rawDataIndex: 9,
		});

	/**
     *  Start of shift (inclusive).
	 *
	 * @type {DateTime}
     */
	get start() : DateTime {
		this.getterDebugValidations(this.attributeInfoStart, false);
		return this.data[1];
	}

	set startTestSetter(v : DateTime) {
        this.setterImpl(1, v, 'start', true, null, null);
	}

	/**
     *  End of shift (exclusive).
	 *
	 * @type {DateTime}
     */
	get end() : DateTime {
		this.getterDebugValidations(this.attributeInfoEnd, false);
		return this.data[2];
	}

	set endTestSetter(v : DateTime) {
        this.setterImpl(2, v, 'end', true, null, null);
	}

	private assignedMemberIdsWrapper : AffectedShiftsApiShiftAssignedMemberIds = new AffectedShiftsApiShiftAssignedMemberIds(this.api,
		this as unknown as AffectedShiftsApiShift, false);
	public attributeInfoAssignedMemberIds = this.assignedMemberIdsWrapper.attributeInfoThis;

	/**
     *  A list of the assigned members' ids.
     */
	get assignedMemberIds() : AffectedShiftsApiShiftAssignedMemberIds {
		this.getterDebugValidations(this.attributeInfoAssignedMemberIds, false);
		return this.assignedMemberIdsWrapper;
	}

	set assignedMemberIdsTestSetter(v : AffectedShiftsApiShiftAssignedMemberIds) {
        this.setterImpl(3, v.rawData, 'assignedMemberIds', true, null, () => {this.assignedMemberIdsWrapper = v;});
	}

	/**
     *  How many members should be assigned to this shift?
	 *
	 * @type {Integer}
     */
	get neededMembersCount() : Integer {
		this.getterDebugValidations(this.attributeInfoNeededMembersCount, false);
		return this.data[4];
	}

	set neededMembersCountTestSetter(v : Integer) {
        this.setterImpl(4, v, 'neededMembersCount', true, null, null);
	}

	/**
     *  Is the course canceled?
	 *
	 * @type {boolean}
     */
	get isCourseCanceled() : boolean {
		this.getterDebugValidations(this.attributeInfoIsCourseCanceled, false);
		return this.data[5];
	}

	set isCourseCanceledTestSetter(v : boolean) {
        this.setterImpl(5, v, 'isCourseCanceled', true, null, null);
	}

	/**
     *  Is the course online?
	 *
	 * @type {boolean}
     */
	get isCourseOnline() : boolean {
		this.getterDebugValidations(this.attributeInfoIsCourseOnline, false);
		return this.data[6];
	}

	set isCourseOnlineTestSetter(v : boolean) {
        this.setterImpl(6, v, 'isCourseOnline', true, null, null);
	}

	/**
     *  Minimal course participant count.
	 *
	 * @type {Integer}
     */
	get minCourseParticipantCount() : Integer {
		this.getterDebugValidations(this.attributeInfoMinCourseParticipantCount, false);
		return this.data[7];
	}

	set minCourseParticipantCountTestSetter(v : Integer) {
        this.setterImpl(7, v, 'minCourseParticipantCount', true, null, null);
	}

	/**
     *  Maximal course participant count.
	 *
	 * @type {Integer}
     */
	get maxCourseParticipantCount() : Integer | null {
		this.getterDebugValidations(this.attributeInfoMaxCourseParticipantCount, false);
		return this.data[8];
	}

	set maxCourseParticipantCountTestSetter(v : Integer | null) {
        this.setterImpl(8, v, 'maxCourseParticipantCount', true, null, null);
	}

	/**
     *  Current course participant count? Being determined by bookings.
	 *
	 * @type {Integer}
     */
	get currentCourseParticipantCount() : Integer {
		this.getterDebugValidations(this.attributeInfoCurrentCourseParticipantCount, false);
		return this.data[9];
	}

	set currentCourseParticipantCountTestSetter(v : Integer) {
        this.setterImpl(9, v, 'currentCourseParticipantCount', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.assignedMemberIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.assignedMemberIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '5';
	}

	static async loadDetailed(	api : AffectedShiftsApiService
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '5', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class AffectedShiftsApiShiftAssignedMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : AffectedShiftsApiService | null,
		override readonly parent : AffectedShiftsApiShift | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'assignedMemberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : AffectedShiftsApiShift | null, removeDestroyedItems : boolean) : this {
		return new AffectedShiftsApiShiftAssignedMemberIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '8';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override attributeInfoThis : ApiAttributeInfo<AffectedShiftsApiShiftAssignedMemberIds, AffectedShiftsApiShiftAssignedMemberIds> = new ApiAttributeInfo<AffectedShiftsApiShiftAssignedMemberIds, AffectedShiftsApiShiftAssignedMemberIds>({
			apiObjWrapper: this as any as AffectedShiftsApiShiftAssignedMemberIds,
			name: 'assignedMemberIds',
			nodeName: 'SHIFT_ASSIGNED_MEMBER_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			hasRightToGet: function(this : AffectedShiftsApiShiftAssignedMemberIds) {
				return true;
			},
			hasRightToSet: () => false,
			defaultValue: function(this : AffectedShiftsApiShiftAssignedMemberIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 3,
		});
	attributeInfoShiftAssignedMemberId : ApiAttributeInfo<AffectedShiftsApiShiftAssignedMemberIds, Id> = new ApiAttributeInfo<AffectedShiftsApiShiftAssignedMemberIds, Id>({
			apiObjWrapper: this as any as AffectedShiftsApiShiftAssignedMemberIds,
			name: 'shiftAssignedMemberId',
			nodeName: 'SHIFT_ASSIGNED_MEMBER_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			hasRightToSet: () => false,
			
		});
}

		

