<div
	class="flex-grow-1 btn-group"
	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
	(editMode)="editMode.emit($event)"
>
	<ng-template [ngIf]="readMode" [ngIfElse]="realModeTemplate">
		<div
			class="form-control-read-mode gap-2"
			role="checkbox"
			[title]="checkboxTitle"
			[attr.aria-checked]="value"
			aria-disabled="true"
			[class.disabled]="true"
		>
			<fa-icon
				*ngIf="!hideValueIcon"
				[fixedWidth]="true" [icon]="valueIcon"
			></fa-icon>
			<ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
		</div>
	</ng-template>
	<ng-template #realModeTemplate>
		<button
			type="button"

			pAutoFocus

			pEditableInstantSaveOnClick
			(triggerClick)="onClickCheckbox($event)"

			class="flex-grow-1 d-flex text-left gap-2"
			[class.checked]="!!value"
			[class.align-items-center]="checkboxIconPosition === 'center'"
			[class.align-items-start]="checkboxIconPosition === 'start'"
			[class.btn]="hasButtonStyle"
			[class.px-0]="!hasPaddingX"
			[class.px-1]="hasPaddingX && size === enums.BootstrapSize.SM"
			[class.px-2]="hasPaddingX && (size === enums.BootstrapSize.MD || size === null)"
			[class.px-3]="hasPaddingX && size === enums.BootstrapSize.LG"
			[class.p-1]="hasPaddingY && hasPaddingX && size === enums.BootstrapSize.SM"
			[class.p-2]="hasPaddingY && hasPaddingX && (size === enums.BootstrapSize.MD || size === null)"
			[class.p-3]="hasPaddingY && hasPaddingX && size === enums.BootstrapSize.LG"
			[class.py-1]="hasPaddingY && size === enums.BootstrapSize.SM"
			[class.py-2]="hasPaddingY && (size === enums.BootstrapSize.MD || size === null)"
			[class.py-3]="hasPaddingY && size === enums.BootstrapSize.LG"
			[style.color]="hasButtonStyle ? '' : 'inherit'"
			[class.btn-frameless]="!hasButtonStyle"
			[class.btn-primary]="theme==='primary'"
			[class.btn-secondary]="!theme || theme==='secondary'"
			[class.btn-success]="theme==='success'"
			[class.btn-dark]="theme==='dark'"
			[class.btn-info]="theme===enums.PThemeEnum.INFO"
			[class.btn-warning]="theme==='warning'"
			[class.btn-light]="theme==='light'"
			[class.btn-danger]="theme==='danger'"
			[class.btn-outline-secondary]="theme==='outline-secondary'"
			[class.btn-outline-danger]="theme==='outline-danger'"
			[class.btn-outline-primary]="theme==='outline-primary'"
			[class.btn-outline-dark]="theme==='outline-dark'"
			[class.bg-transparent]="!hasButtonStyle"

			[disabled]="isLoading || disabled"
			[class.required]="hasRequiredError"
			[class.has-danger]="hasDanger"
			[class.has-warning]="hasWarning"

			[class.muted-item]="hasButtonStyle && (isLoading || disabled)"
			[class.disable-muted-item-hover]="hasButtonStyle && (isLoading || disabled)"

			role="checkbox"
			[attr.aria-checked]="value"
			[title]="checkboxTitle"
		>
			<p-spinner [size]="enums.BootstrapSize.SM"
				*ngIf="isLoading"
				class="d-inline"
				[class.text-white]="textWhite"
			></p-spinner>
			<fa-icon
				*ngIf="!isLoading && !hideValueIcon"
				[fixedWidth]="true" [icon]="value ? enums.PlanoFaIconPool.CHECKBOX_SELECTED : enums.PlanoFaIconPool.CHECKBOX_UNSELECTED"
				[class.text-primary]="value && !textWhite && (theme==='light' || theme==='outline-secondary')"
				[class.text-white]="textWhite"
				[class.muted-item]="!hasButtonStyle && (isLoading || disabled)"
				[class.disable-muted-item-hover]="!hasButtonStyle && (isLoading || disabled)"
			></fa-icon>
			<ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
		</button>
		<div
			*ngIf="!!cannotSetHint && !!disabled"
			class="btn flex-grow-0 d-flex align-items-center"
			[class.btn-frameless]="!hasButtonStyle"
			[class.btn-primary]="theme==='primary'"
			[class.btn-secondary]="(hasButtonStyle && !theme) || theme==='secondary'"
			[class.btn-success]="theme==='success'"
			[class.btn-dark]="theme==='dark'"
			[class.btn-info]="theme===enums.PThemeEnum.INFO"
			[class.btn-warning]="theme==='warning'"
			[class.btn-light]="theme==='light'"
			[class.btn-danger]="theme==='danger'"
			[class.btn-outline-secondary]="theme==='outline-secondary'"
			[class.btn-outline-danger]="theme==='outline-danger'"
			[class.btn-outline-primary]="theme==='outline-primary'"
			[class.btn-outline-dark]="theme==='outline-dark'"

			tabindex="0"
			(click)="openCannotSetHint(cannotSetHintTemplate ? innerCannotSetHintTemplate : null)"
			title="Info" i18n-title
		><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon></div>
	</ng-template>
</div>

<ng-template #contentTemplate>
	<fa-icon *ngIf="!!icon" [icon]="icon"></fa-icon>
	<span class="mr-auto" [class.ml-1]="hideValueIcon" *ngIf="!!valueTextString">{{valueTextString}}</span>
	<ng-container *ngIf="valueTextTemplateRef"><ng-template [ngTemplateOutlet]="valueTextTemplateRef"></ng-template></ng-container>
	<ng-content></ng-content>
</ng-template>

<ng-template [ngIf]="!!control">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	></p-validation-hint>
</ng-template>

<input class="d-none"
	type="checkbox"
	[(ngModel)]="value"
	(change)="onChange($event)"
	(keyup)="onKeyUp($event)"
	[disabled]="isLoading || disabled"
/>

<ng-template #innerCannotSetHintTemplate>
	<ng-container *ngTemplateOutlet="cannotSetHintTemplate, context: {$implicit: cannotSetHint}"></ng-container>
</ng-template>
