import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FilterService } from '@plano/client/shared/filter.service';
import { MeService, RightsService, SchedulingApiService, SchedulingApiShift, SchedulingApiShifts, SchedulingApiTodaysShiftDescription, SchedulingApiTodaysShiftDescriptions } from '@plano/shared/api';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-shift-comments',
	templateUrl: './p-shift-comments.component.html',
	styleUrls: ['./p-shift-comments.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftCommentsComponent implements OnInit {
	public showAllDescriptions : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private date : number | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftsForList : SchedulingApiShifts = new SchedulingApiShifts(null, null);

	constructor(
		public me : MeService,
		private api : SchedulingApiService,
		private filterService : FilterService,
		public rightsService : RightsService,
	) {
	}

	public enums = enumsObject;

	public ngOnInit() : void {
		this.showAllDescriptions = !this.isForDesk;
	}

	private get isForDesk() : boolean {
		return !this.date;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shiftDescriptionsForList() : SchedulingApiTodaysShiftDescriptions {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		if (this.date || !this.api.isLoaded() || !this.api.data.attributeInfoTodaysShiftDescriptions.isAvailable) {
			return new SchedulingApiTodaysShiftDescriptions(null, null);
		}
		return this.api.data.todaysShiftDescriptions.filterBy((item) => {
			return this.filterService.isVisible(item);
		});
	}

	/**
	 * Sorted shift descriptions by shift start
	 */
	public get sortedShiftDescriptionsForListByShiftStart() : SchedulingApiTodaysShiftDescriptions {
		return this.shiftDescriptionsForList.sortedBy(shift => shift.shiftStart);
	}

	/**
	 * Sorted shift by start
	 */
	public get sortedShiftForListByStart() : SchedulingApiShifts {
		return this.shiftsForList.sortedBy(shift => shift.start);
	}

	/**
	 * get shifts for this day for current user
	 */
	public get shiftDescriptionsForMember() : SchedulingApiTodaysShiftDescriptions {
		if (!this.api.isLoaded()) return new SchedulingApiTodaysShiftDescriptions(null, null);

		return this.shiftDescriptionsForList.filterBy((item) => {
			return this.shiftIsForMe(item);
		});
	}

	/**
	 * get shifts for this day for current user
	 */
	public get shiftsForMember() : SchedulingApiShifts {
		if (!this.api.isLoaded()) return new SchedulingApiShifts(null, null);

		return this.shiftsForList.filterBy((item) => {
			return item.assignedMemberIds.contains(this.me.data.id);
		});
	}

	/**
	 * get visible descriptions
	 */
	public get visibleDescriptions() : SchedulingApiTodaysShiftDescriptions {
		if (!this.api.isLoaded()) return new SchedulingApiTodaysShiftDescriptions(null, null);

		if (this.showAllDescriptions) return this.shiftDescriptionsForList;
		return this.shiftDescriptionsForMember;
	}

	/**
	 * Check if the shift comment of this shift is relevant for me
	 * This can also be used in the template to highlight shift comments that are relevant for the user
	 */
	public shiftIsForMe(input : SchedulingApiTodaysShiftDescription) : boolean {
		return input.isRequesterAssigned;
	}

	/**
	 * Check if that day has more shift comments than the comments for current user
	 * @returns difference
	 */
	public get hasMoreComments() : number {
		if (this.isForDesk) {
			return this.shiftDescriptionsForList.length - this.shiftDescriptionsForMember.length;
		}
		return this.shiftsForList.length - this.shiftsForMember.length;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get isAdmin() : boolean {
		return this.me.data.isOwner;
	}

	/**
	 * Should all shift-comments be visible? Is not, only the shift-comments for members shifts are visible.
	 */
	public onToggleShowAll() : void {
		this.showAllDescriptions = !this.showAllDescriptions;
	}

	/**
	 * Check if user can edit this items comment
	 */
	public userCanWrite(item : SchedulingApiShift | SchedulingApiTodaysShiftDescription) : boolean | undefined {
		return this.rightsService.userCanWrite(item);
	}
}
