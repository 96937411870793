import { Directive, HostListener, Input } from '@angular/core';
import { Config } from '@plano/shared/core/config';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[externalLink]',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ExternalLinkDirective {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private externalLink ! : string;

	// eslint-disable-next-line jsdoc/require-jsdoc
	@HostListener('click') public onClick() : void {
		// let app handle this?
		if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).nsWebViewInterface.emit('externalLink', this.externalLink);
		} else {
			// otherwise just open
			if (this.externalLink.startsWith('mailto:'))
				window.location.href = this.externalLink;
			else
				window.open(this.externalLink, '_blank');
		}
	}
}
