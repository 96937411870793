import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

/**
 * A summary of the content of the modal that opens, when you start to edit these values.
 * Depending on where it is implemented, this could be the content of e.g. a button or a card.
 */
@Component({
	selector: 'p-editable-showroom',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PEditableShowroomComponent {
	@HostBinding('class.position-relative')
	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column')
	@HostBinding('class.justify-content-center')
	@HostBinding('class.align-self-stretch')
	@HostBinding('class.w-100') protected _alwaysTrue = true;
}
