<button type="button" class="btn btn-dark btn-sm btn-frameless border-right"
	(click)="assignMe($event, assignMeModalContent.template)"
>
	<div class="d-flex justify-content-between align-items-center crop-on-overflow">
		<p-assignment-process-icon
			class="text-white"
			[state]="states.EARLY_BIRD_SCHEDULING"
			[isOwner]="!!rightsService.isOwner"
		></p-assignment-process-icon>
	</div>
</button>
<ng-template #assignMeModalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<div hidden><span i18n #translated>{!!otherPacketShifts, select, true {Schicht-Paket} other {Schicht}} übernehmen</span></div>
	<p-modal-content
		[modalTitle]="translated.textContent!"
		(onDismiss)="d($event)"
		(onClose)="c($event);"
	>
		<p-modal-content-body>
			<div class="mb-3"><p-shift-comment-meta
				*ngIf="shift.rawData; else spinnerTemplate"
				[name]="shift.name"
				[start]="shift.start"
				[end]="shift.end"
				[assignedMembers]="shift.assignedMembers"
				class="font-weight-bold"
			></p-shift-comment-meta></div>
			<ng-template #spinnerTemplate>
				<p-spinner></p-spinner>
			</ng-template>

			<div *ngIf="otherPacketShifts" class="mb-3">
				<ng-container i18n>Zu der gewählten Schicht gehören noch weitere:</ng-container>
				<ul>
					<li *ngFor="let packetShift of otherPacketShifts.iterable()">{{ packetShift.start | date:'shortDate' }}</li>
				</ul>
			</div>
			<ng-template
				ngFor let-error
				[ngForOf]="errors"
			>
				<p-alert [theme]="error.type" [icon]="error.icon">{{ error.text }}</p-alert>
			</ng-template>
			<p i18n>Möchtest du {!!otherPacketShifts, select, true {das gesamte Schicht-Paket} other {diese Schicht}} wirklich übernehmen? Das kannst du nicht mehr rückgängig machen.</p>
		</p-modal-content-body>
		<p-modal-content-footer>
			<button type="button" class="btn btn-outline-secondary mr-auto"
				(click)="d($event);"
				i18n
			>Abbrechen</button>
			<button type="button" class="btn btn-primary ml-auto"
				(click)="c($event)"
				i18n
			>Ja</button>
		</p-modal-content-footer>
	</p-modal-content>
</ng-template>
