import { Injectable } from '@angular/core';
import { PBookingFormService } from '@plano/client/booking/booking-form.service';
import { ParticipantsFormArray } from '@plano/client/booking/detail-form/detail-form.component';
import { ParticipantFormGroup } from '@plano/client/booking/detail-form/p-participants/p-participants.component';
import { defaultSortingForShiftModelCourseTariffs } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model-course-tariffs-sorting.const';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiBooking, SchedulingApiBookingParticipant, SchedulingApiShiftModelCourseTariffs } from '@plano/shared/api';
import { Date, Years } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { AsyncValidatorsService } from '@plano/shared/core/async-validators.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';
import { PShiftmodelTariffService } from '@plano/shared/p-forms/p-shiftmodel-tariff.service';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PParticipantsService {
	constructor(
		private pFormsService : PFormsService,
		private validators : ValidatorsService,
		private asyncValidators : AsyncValidatorsService,
		private pShiftmodelTariffService : PShiftmodelTariffService,
		private pBookingFormService : PBookingFormService,
		private pMomentService : PMomentService,
		private localizePipe : LocalizePipe,
	) {
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public initParticipant(
		booking : SchedulingApiBooking,
		participant : SchedulingApiBookingParticipant | null,
		array : ParticipantsFormArray,
	) : void {
		assumeNonNull(participant);
		const formGroup = this.getParticipantFormGroup(booking, participant);
		array.push(formGroup);
		array.updateValueAndValidity();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc, max-lines-per-function -- NOTE: This will be solved automatically when we switch to more p-ai-switch’es
	public getParticipantFormGroup(
		booking : SchedulingApiBooking,
		participant : SchedulingApiBookingParticipant,
	) : ParticipantFormGroup {
		const tempFormGroup = this.pFormsService.group({}) as ParticipantFormGroup;
		this.pFormsService.addControl(
			tempFormGroup,
			'reference',
			{
				value: participant,
				disabled: false,
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'id',
			{
				value: participant.id,
				disabled: false,
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'isBookingPerson',
			{
				value: participant.isBookingPerson,
				disabled: false,
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'firstName',
			{
				value: participant.firstName,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					// HACK: PLANO-13217
					if (!participant.rawData) return null;

					if (participant.isBookingPerson) return null;
					return this.validators.required(participant.attributeInfoFirstName.primitiveType).fn(control);
				}}),
			],
			(value : string) => {
				// HACK: PLANO-13217
				if (!participant.rawData) return null;

				participant.firstName = value;
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'lastName',
			{
				value: participant.lastName,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					// HACK: PLANO-13217
					if (!participant.rawData) return null;

					if (participant.isBookingPerson) return null;
					return this.validators.required(participant.attributeInfoLastName.primitiveType).fn(control);
				}}),
			],
			(value : string) => {
				// HACK: PLANO-13217
				if (!participant.rawData) return null;

				participant.lastName = value;
			},
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'email',
			{
				value: participant.email,
				disabled: false,
			},
			[
				this.validators.email(),
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					// HACK: PLANO-13217
					if (!participant.rawData) return null;

					if (participant.isBookingPerson) return null;
					return this.validators.required(participant.attributeInfoEmail.primitiveType).fn(control);
				}}),
			],
			(value : string) => {
				// HACK: PLANO-13217
				if (!participant.rawData) return null;

				participant.email = value;
			},
			this.asyncValidators.emailValidAsync(),
		);
		this.pFormsService.addControl(
			tempFormGroup,
			'tariffId',
			{
				value: participant.tariffId,
				disabled: false,
			},
			[
				new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
					// FIXME: PLANO-15096
					// HACK: PLANO-13217
					if (!participant.rawData) return null;

					// TODO: We need the same rules in participant > tariffId as in bookingPerson > tariffId as in overallTariffId
					if (!this.hasInBookingPluginVisibleTariff(booking)) return null;

					// NOTE: 	If this is an existing booking, we can not be sure if this was a free course at time of creation
					// 				If a tariff gets selected it can not be deselected anymore since its a radio-button.
					if (!booking.isNewItem()) return null;

					return this.validators.required(participant.attributeInfoTariffId.primitiveType).fn(control);
				}}),
				new PValidatorObject({name: PPossibleErrorNames.ID_DEFINED, fn: (control) => {
					// FIXME: PLANO-15096
					// HACK: PLANO-13217
					if (!participant.rawData) return null;

					// TODO: We need the same rules in participant > tariffId as in bookingPerson > tariffId as in overallTariffId
					if (!this.hasInBookingPluginVisibleTariff(booking)) return null;

					// NOTE: 	If this is an existing booking, we can not be sure if this was a free course at time of creation
					// 				If a tariff gets selected it can not be deselected anymore since its a radio-button.
					if (!booking.isNewItem()) return null;

					return this.validators.idDefined().fn(control);
				}}),
			],
			(value : Id) => {
				// HACK: PLANO-13217
				if (!participant.rawData) return null;

				participant.tariffId = value;
			},
		);

		this.pFormsService.addControl(
			tempFormGroup,
			'attended',
			{
				value: participant.attended,
				disabled: false,
			},
			[],
			(value : boolean) => {
				participant.attended = value;
			},
		);

		this.pBookingFormService.initFormControlAdditionalFieldValue(
			tempFormGroup,
			participant,
			participant,
			booking.model.courseTariffs,
		);

		return tempFormGroup;
	}

	private hasInBookingPluginVisibleTariff(
		booking : SchedulingApiBooking | undefined,
	) : boolean | undefined {
		if (!booking) return undefined;
		return this.pShiftmodelTariffService.hasInBookingPluginVisibleTariff(booking.model.courseTariffs);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public courseTariffsForList(booking : SchedulingApiBooking, tariffId : Id | null) : SchedulingApiShiftModelCourseTariffs {
		const courseTariffs = booking.model.courseTariffs;
		return courseTariffs.sortedBy(defaultSortingForShiftModelCourseTariffs).filterBy((courseTariff) => {
			if (!courseTariff.trashed) return true;
			if (tariffId?.equals(courseTariff.id)) return true;
			return false;
		});
	}

	/** Should the min age limit warning for a specific participant be visible? */
	public showParticipantMinAgeLimitWarning(booking : SchedulingApiBooking, dateOfBirth : Date) : boolean {
		const minAge = booking.model.participantMinAge;
		const start = booking.firstShiftStart ?? booking.courseSelector?.start ?? null;
		if (start === null) return false;
		return this.dateOfBirthReachedMinAgeLimit(dateOfBirth, start, minAge);
	}

	/** Should the max age limit warning for a specific participant be visible? */
	public showParticipantMaxAgeLimitWarning(booking : SchedulingApiBooking, dateOfBirth : Date) : boolean {
		const maxAge = booking.model.participantMaxAge;
		const start = booking.firstShiftStart ?? booking.courseSelector?.start ?? null;
		if (start === null) return false;
		return this.dateOfBirthReachedMaxAgeLimit(dateOfBirth, start, maxAge);
	}

	/** Text for the age limit warning */
	public bookingPersonAgeLimitWarning(booking : SchedulingApiBooking) : string | null {
		if (booking.model.bookingPersonMinAge === null) return null;
		const params = {
			min: booking.model.bookingPersonMinAge.toString(),
		};

		const minAge = booking.model.attributeInfoBookingPersonMinAge.value;
		const referenceDate = booking.isNewItem() ? Date.now() : booking.dateOfBooking;
		if (!booking.dateOfBirth) return null;
		const showBookingPersonAgeLimitWarning = minAge ? this.dateOfBirthReachedMinAgeLimit(booking.dateOfBirth, referenceDate, minAge) : null;

		if (showBookingPersonAgeLimitWarning) return this.localizePipe.transform({sourceString: 'Die Person sollte zum Buchungszeitpunkt mindestens ${min} Jahre alt sein.', params: params});

		if (!booking.onlyWholeCourseBookable) {
			// If the booking person is also participant, the limit for participants applies to the booking person, too
			const bookingPersonIsParticipant = booking.participants.some(item => item.isBookingPerson);
			if (bookingPersonIsParticipant) {
				return this.participantAgeLimitWarning(booking, booking.dateOfBirth);
			}
		}

		return null;
	}

	/**
	 * Check if date of birth exceeds the min age limit and get warning text if true
	 * @param booking - The booking to check
	 */
	public participantsMinAgeLimitWarning(booking : SchedulingApiBooking) : string | null {
		if (booking.ageMin === null) return null;

		if (booking.model.participantMinAge === null) return null;
		if (booking.model.participantMinAge <= booking.ageMin) return null;

		return this.localizePipe.transform({sourceString: 'Die Personen sollten mindestens ${min} Jahre alt sein (zum Datum des gebuchten Angebots).', params: {
			min: booking.model.participantMinAge.toString(),
		}});
	}

	/**
	 * Check if date of birth exceeds the max age limit and get warning text if true
	 * @param booking - The booking to check
	 */
	public participantsMaxAgeLimitWarning(booking : SchedulingApiBooking) : string | null {
		if (booking.ageMax === null) return null;

		if (booking.model.participantMaxAge === null) return null;
		if (booking.model.participantMaxAge >= booking.ageMax) return null;

		return this.localizePipe.transform({sourceString: 'Die Personen sollten höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).', params: {
			max: booking.model.participantMaxAge.toString(),
		}});
	}

	/**
	 * Text for the age limit.
	 * @param booking - the booking of kind `onlyWholeCourseBookable` to be checked
	 */
	public participantsAgeLimitWarning(booking : SchedulingApiBooking) : string | null {
		const minWarning = this.participantsMinAgeLimitWarning(booking);
		const maxWarning = this.participantsMaxAgeLimitWarning(booking);
		if (minWarning && maxWarning) {
			return this.localizePipe.transform({sourceString: 'Die Personen sollten mindestens ${min} und höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).', params: {
				min: booking.model.participantMinAge!.toString(),
				max: booking.model.participantMaxAge!.toString(),
			}});
		} else {
			return minWarning ?? maxWarning;
		}
	}

	/**
	 * Calculate if the date of birth is above the limit
	 * @param dateOfBirth The timestamp that should be checked (e.g. birthday of a booking person)
	 * @param referenceDate the timestamp that should be checked against (date of booking)
	 * @param limit How old a person should be at the time of `referenceDate` (e.g. 18)
	 */
	public dateOfBirthReachedMinAgeLimit(
		dateOfBirth : Date,
		referenceDate : Date,
		limit : Years | null,
	) : boolean {
		if (limit === null) return false;
		if (!referenceDate) return false;
		const participantMoment = this.pMomentService.m(dateOfBirth);
		const age = this.pMomentService.m(referenceDate).diff(participantMoment, 'years');
		return age < limit;
	}

	/**
	 * Calculate if the date of birth is above the limit
	 * @param dateOfBirth The timestamp that should be checked (e.g. birthday of a booking person)
	 * @param referenceDate the timestamp that should be checked against (date of booking)
	 * @param limit How old a person should be at the time of `referenceDate` (e.g. 18)
	 */
	public dateOfBirthReachedMaxAgeLimit(
		dateOfBirth : Date,
		referenceDate : Date,
		limit : Years | null,
	) : boolean {
		if (limit === null) return false;
		if (!referenceDate) return false;
		const participantMoment = this.pMomentService.m(dateOfBirth);
		const age = this.pMomentService.m(referenceDate).diff(participantMoment, 'years');
		return age > limit;
	}

	/**
	 * Should the participants age limit warning be visible?
	 * @param booking The booking the participant wants to participate in
	 * @param dateOfBirth The date of birth of the participant
	 * @returns the text of the warning or null if no warning should be shown
	 */
	public participantAgeLimitWarning(booking : SchedulingApiBooking, dateOfBirth : Date) : string | null {
		const hasMinLimitWarning = this.showParticipantMinAgeLimitWarning(booking, dateOfBirth);
		const hasMaxLimitWarning = this.showParticipantMaxAgeLimitWarning(booking, dateOfBirth);

		if (!hasMinLimitWarning && !hasMaxLimitWarning) return null;

		if (hasMinLimitWarning) {
			if (hasMaxLimitWarning) return this.localizePipe.transform({sourceString: 'Die Person sollte mindestens ${min} und höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).', params: {
				min: booking.model.participantMinAge!.toString(),
				max: booking.model.participantMaxAge!.toString(),
			}});
			return this.localizePipe.transform({ sourceString: 'Die Person sollte mindestens ${min} Jahre alt sein (zum Datum des gebuchten Angebots).', params: {
				min: booking.model.participantMinAge!.toString(),
			}});
		}
		return this.localizePipe.transform({sourceString: 'Die Person sollte höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).', params: {
			max: booking.model.participantMaxAge!.toString(),
		}});
	}
}
