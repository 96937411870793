import { Injectable } from '@angular/core';

// cSpell:ignore jira
import { JiraTicketId } from '@plano/global-error-handler/jira-ticket-id.enum';
import { SentryTicketId } from '@plano/global-error-handler/sentry-ticket-id.enum';
import { AffectedShiftsApiShifts, AuthenticatedApiRole, MeService, SchedulingApiAssignmentProcess, SchedulingApiBooking, SchedulingApiMember, SchedulingApiRightGroupShiftModelRight, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange, SchedulingApiShiftExchangeShiftRefs, SchedulingApiShiftModel, SchedulingApiShiftModels, SchedulingApiTodaysShiftDescription } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { LogService } from '@plano/shared/core/log.service';
import { assume, assumeDefinedToGetStrictNullChecksRunning, notNull } from '@plano/shared/core/utils/null-type-utils';
import { PPermissionService, RightEnums } from './permission.service';
import { BookingSystemRights } from './rights-enums';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class RightsService {
	constructor(
		private meService : MeService,
		private api : SchedulingApiService,
		private console : LogService,
		private pPermissionService : PPermissionService,
	) {
	}

	/**
	 * Is given member the logged in user?
	 */
	public isMe(input : SchedulingApiMember | Id) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;

		const ID = input instanceof SchedulingApiMember ? input.id : input;
		return this.meService.data.id.equals(ID);
	}

	/**
	 * Check if this Member is Client Owner.
	 * Returns undefined, if it is currently not possible to figure it out. E.g. because meService is not loaded.
	 */
	public get isOwner() : boolean | undefined {
		return this.requesterIs(AuthenticatedApiRole.CLIENT_OWNER);
	}

	/**
	 * @returns member object of Scheduling Api of currently logged in member.
	 */
	public get loggedInMember() : SchedulingApiMember | null {
		if (!this.meService.isLoaded()) return null;

		const result = this.getCurrentMember(this.meService.data.id);
		if (!result) return null;
		return result;
	}

	/**
	 * Check if the member has a particular rights for a given shiftModel
	 */
	public can(name : RightEnums, shiftModel : SchedulingApiShiftModel) : boolean | null {
		if (!this.loggedInMember) return null;
		for (const RIGHT_GROUP of this.loggedInMember.rightGroups.iterable()) {

			let shiftModelRight = RIGHT_GROUP.shiftModelRights.getByShiftModel(shiftModel.id);
			if (!shiftModelRight) shiftModelRight = this.getDefaultShiftModelRight(shiftModel);

			if (!shiftModel.attributeInfoAssignableMembers.isAvailable) return false;
			const CAN = this.pPermissionService.getPermission(
				name,
				RIGHT_GROUP.role,
				shiftModelRight,
				!!shiftModel.assignableMembers.getByMemberId(this.meService.data.id),
			);
			if (CAN) return true;
		}
		return false;
	}

	/**
	 * If there is no specific set of rights we generate a set of default rights.
	 * These differ between members and owners.
	 */
	private	getDefaultShiftModelRight(
		shiftModel : SchedulingApiShiftModel,
	) : SchedulingApiRightGroupShiftModelRight {
		return {
			canGetManagerNotifications: this.userCanGetManagerNotifications(shiftModel),
			canRead: this.userCanRead(shiftModel),
			canWrite: this.userCanWrite(shiftModel),
			canWriteBookings: this.userCanWriteBookingsOfShiftModel(shiftModel),
		} as unknown as SchedulingApiRightGroupShiftModelRight;
	}

	/**
	 * get the current logged in member
	 */
	private getCurrentMember(id : Id) : SchedulingApiMember | null {
		assume(this.api.isLoaded(), 'this.api.isLoaded()', 'Api must be loaded to get a current member');

		const MEMBERS = this.api.data.members;
		if (!MEMBERS.length) {
			// This case can happen when someone is using "schedulingApi.setEmptyData()"
			return null;
		}

		return MEMBERS.get(id);
	}

	/**
	 * @returns Can logged in user edit given `input`? null means that it can not be determined.
	 */
	public userCanWrite(
		input : SchedulingApiShift | SchedulingApiShiftModel | SchedulingApiTodaysShiftDescription,
	) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (this.meService.data.isOwner) return true;

		/*
			HACK: When we create a shift we currently create a copy of the shiftmodel
			on which the shift is based, but as members with writing rights don't have the permission
			to create shiftmodels, there would be a throw. So, to enable the creation of shifts from
			members with the correct rights we add the following line.
		 */
		if (input instanceof SchedulingApiShiftModel && input.isNewItem()) return true;

		if (!this.loggedInMember) return false;
		return this.loggedInMember.canWrite(input);
	}

	/**
	 * Check if user can read this shift-model
	 */
	public userCanRead(shiftModel : SchedulingApiShiftModel) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;

		// Owners can read everything
		if (this.meService.data.isOwner) return true;

		// This is a member
		if (!this.loggedInMember) return false;
		return this.loggedInMember.canRead(shiftModel.id);
	}

	/**
	 * @returns Can the logged in user write `shiftExchange`?
	 */
	public userCanWriteShiftExchange(shiftExchange : SchedulingApiShiftExchange) : boolean {
		return shiftExchange.indisposedMember === this.loggedInMember || this.hasManagerRightsForShiftExchange(shiftExchange);
	}

	/**
	 * Check if user can read this shift-model
	 */
	public userCanGetManagerNotifications(item : SchedulingApiShiftModel) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;

		// Owners can get everything
		if (this.meService.data.isOwner) return true;

		// This is a member
		const member = this.api.data.members.get(this.meService.data.id);
		if (!member) throw new Error('Could not find member');
		return member.canGetManagerNotifications(item.id);
	}

	/**
	 * Check if user can write bookings for given shift-model.
	 */
	public userCanWriteBookingsOfShiftModel(item : SchedulingApiShiftModel) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;

		// Owners can write everything
		if (this.meService.data.isOwner) return true;

		// This is a member
		const member = this.api.data.members.get(this.meService.data.id);
		if (!member) throw new Error('Could not find member');
		return member.canWriteBookings(item);
	}

	/**
	 * @returns Can this Member write `booking`?
	 */
	public userCanWriteBooking(booking : SchedulingApiBooking) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;

		// If this member is on a shift detail page, this user probably wants to set the attended flag.
		// The canSet logic of that flag will ask for the parent canSet, which is the bookings list.
		if (this.api.currentlyDetailedLoaded instanceof SchedulingApiShift) return true;

		const member = this.api.data.members.get(this.meService.data.id);
		if (!member) throw new Error('Could not find member');
		return member.canWriteBookings(booking.model);
	}

	/**
	 * Check if user can write bookings of at least one non trashed shift-model.
	 */
	public userCanWriteBookings() : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (!this.api.isLoaded()) return undefined;

		// If this member is on a shift detail page, this user probably wants to set the attended flag.
		// The canSet logic of that flag will ask for the parent canSet, which is the bookings list.
		if (this.api.currentlyDetailedLoaded instanceof SchedulingApiShift) return true;

		// Can current member write at least one shift-model?
		if (!this.loggedInMember) return false;
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			if (!shiftModel.trashed && this.loggedInMember.canWriteBookings(shiftModel))
				return true;
		}

		return false;
	}

	/**
	 * @returns Can this Member execute online-refunds?
	 */
	public userCanOnlineRefund(item : SchedulingApiBooking | SchedulingApiShiftModel | AffectedShiftsApiShifts) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		const member = this.api.data.members.get(this.meService.data.id);
		if (!member) throw new Error('Could not find member');

		if (item instanceof AffectedShiftsApiShifts) {
			// Can logged in member online refund all shifts?
			for (const shift of item.iterable()) {
				const shiftModel = notNull(this.api.data.shiftModels.get(shift.id.shiftModelId));

				if (!member.canOnlineRefund(shiftModel))
					return false;
			}

			return true;
		} else {
			const shiftModel = item instanceof SchedulingApiBooking ? item.model : item;
			assumeDefinedToGetStrictNullChecksRunning(shiftModel, 'shiftModel');
			return member.canOnlineRefund(shiftModel);
		}
	}

	/**
	 * Check if user can read any of these shift-models
	 */
	public userCanReadAny(items : SchedulingApiShiftModels) : boolean {
		for (const ITEM of items.iterable()) {
			if (this.userCanRead(ITEM)) return true;
		}
		return false;
	}

	/**
	 * Check if user can read and write booking settings
	 */
	public get canReadAndWriteBookingSystemSettings() : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (!this.api.isLoaded()) return undefined;
		if (!this.loggedInMember) return false;
		const RIGHT_GROUP_IDS = this.loggedInMember.rightGroupIds;
		for (const ID of RIGHT_GROUP_IDS.iterable()) {
			const rightGroup = this.api.data.rightGroups.get(ID);
			if (!rightGroup) throw new Error('Could not find rightGroup');
			if (!rightGroup.canReadAndWriteBookingSystemSettings) continue;
			return true;
		}
		return false;
	}

	/**
	 * @param shiftModelId Id of the shift
	 * Check if this Member has manager rights for this particular shiftModel.
	 */
	public hasManagerRightsForShiftModel(shiftModelId : Id) : boolean {
		if (this.requesterIs(AuthenticatedApiRole.CLIENT_OWNER)) return true;

		if (!this.loggedInMember) return false;
		if (!this.loggedInMember.canWrite(shiftModelId)) return false;
		if (!this.loggedInMember.canGetManagerNotifications(shiftModelId)) return false;
		return true;
	}

	/**
	 * @returns Has the logged in user manager rights for `shiftExchange`?
	 * To avoid that nobody will have manager rights it is enough to have manager rights for one of the shift-models
	 */
	public hasManagerRightsForShiftExchange(shiftExchange : SchedulingApiShiftExchange) : boolean {
		for (const shiftRef of shiftExchange.shiftRefs.iterable()) {
			if (this.hasManagerRightsForShiftModel(shiftRef.id.shiftModelId))
				return true;
		}

		return false;
	}

	/**
	 * @returns Has the logged in user manager-rights for "member"? This returns true when logged in user has
	 * manager rights for at least one shift-model to which member is assignable.
	 */
	public hasManagerRightsForMember(member : SchedulingApiMember) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (this.meService.data.isOwner) return true;

		for (const ASSIGNABLE_SHIFT_MODEL of member.assignableShiftModels.iterable()) {
			const SHIFT_MODEL = ASSIGNABLE_SHIFT_MODEL.shiftModel;

			// member might not have read-right for the shift-model and so SHIFT_MODEL might be "null"
			if (!SHIFT_MODEL.trashed && this.hasManagerRightsForShiftModel(SHIFT_MODEL.id))
				return true;
		}

		return false;
	}

	/**
	 * Does the logged in user have managerRights?
	 * Note that a user can be owner without manager rights
	 */
	public hasManagerRightsForAllShiftRefs(shiftRefs : SchedulingApiShiftExchangeShiftRefs) : boolean | undefined {
		if (this.requesterIs(AuthenticatedApiRole.CLIENT_OWNER)) return true;
		for (const SHIFT_REF of shiftRefs.iterable()) {
			if (!this.meService.isLoaded()) {
				this.console.error(`[${JiraTicketId.PLANO_17845}] [${SentryTicketId.PLANO_FE_59}] load meService before call hasManagerRightsForShiftModel`);
				return undefined;
			}
			if (!this.hasManagerRightsForShiftModel(SHIFT_REF.id.shiftModelId)) continue;
			return true;
		}
		return false;
	}

	/**
	 * @returns Is there at least one not trashed shift-model where the logged in user has manager rights?
	 */
	public get hasManagerRights() : boolean | undefined {
		if (this.requesterIs(AuthenticatedApiRole.CLIENT_OWNER)) return true;
		if (!this.api.isLoaded()) return undefined;
		if (!this.meService.isLoaded()) return undefined;
		for (const SHIFT_MODEL of this.api.data.shiftModels.iterable()) {
			if (!SHIFT_MODEL.trashed && this.hasManagerRightsForShiftModel(SHIFT_MODEL.id)) return true;
		}
		return false;
	}

	/**
	 * Check if user can get Admin Notifications for this shift or shift-model
	 */
	public canGetManagerNotifications(input : SchedulingApiShift | SchedulingApiShiftModel) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (this.meService.data.isOwner) return true;
		const member = this.api.data.members.get(this.meService.data.id);
		if (!member) throw new Error('Could not find member');
		return member.canGetManagerNotifications(input);
	}

	/**
	 * Check if user is allowed to write assignmentProcesses
	 */
	public get userCanSetAssignmentProcesses() : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;

		// Owners can do everything
		if (this.meService.data.isOwner) return true;

		return this.hasManagerRights;
	}

	/**
	 * Check if user is allowed to write given assignmentProcess
	 */
	public userCanSetAssignmentProcess(process : SchedulingApiAssignmentProcess) : boolean | null {
		if (!this.api.isLoaded()) return null;
		if (!this.meService.isLoaded()) return null;

		// Owners can do everything
		if (this.meService.data.isOwner) return true;

		return process.canEdit;
	}

	/**
	 * Check if user is allowed to write absences
	 */
	public get userCanWriteAbsences() : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (this.meService.data.isOwner) return true;
		return false;
	}

	/**
	 * Check if user is allowed to see earnings
	 */
	public userCanSeeEarningsForShiftModel(shiftModelId : Id, member ?: SchedulingApiMember) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		if (this.meService.data.isOwner) return true;
		if (member?.id.equals(this.meService.data.id)) return true;
		if (this.hasManagerRightsForShiftModel(shiftModelId)) return true;
		return false;
	}

	/**
	 * @param member The member whose earnings should be shown.
	 * @returns Can logged in user see earnings of "member"? This is true when logged in user is the member himself or
	 * has manager-rights for that member.
	 */
	public userCanSeeEarningsOfMember(member : SchedulingApiMember) : boolean | undefined {
		if (this.isMe(member)) return true;
		return this.hasManagerRightsForMember(member);
	}

	/**
	 * Check if this Member can edit bookings.
	 */
	public get userCanManageBookings() : boolean {
		if (!this.api.data.attributeInfoShiftModels.isAvailable) return false;
		for (const SHIFT_MODEL of this.api.data.shiftModels.filterBy(item => item.isCourse).iterable()) {
			if (this.can(BookingSystemRights.editBookings, SHIFT_MODEL)) return true;

			// TODO: Next line is probably obsolete
			if (this.userCanWrite(SHIFT_MODEL)) return true;

		}
		return false;
	}

	/**
	 * Check if this Member can write at least one shiftModel.
	 */
	public get userCanWriteAnyShiftModel() : boolean | undefined {
		if (this.requesterIs(AuthenticatedApiRole.CLIENT_OWNER)) return true;
		if (!this.meService.isLoaded()) return undefined;
		if (!this.api.isLoaded()) return undefined;
		if (!this.loggedInMember) return false;
		if (this.loggedInMember.canWriteAnyShiftModel) return true;
		return false;
	}

	/**
	 * Does the requester match at least one of the provided roles?
	 * You can also provide a Id of a Member to check if the requester equals this id.
	 */
	public requesterIs(...inputArray : (AuthenticatedApiRole | Id)[]) : boolean | undefined {
		if (!this.meService.isLoaded()) return undefined;
		for (const INPUT of inputArray) {
			if (INPUT instanceof Id) {
				if (!this.isMe(INPUT)) continue;
				return true;
			}

			if (INPUT === this.meService.data.role)
				return true;
		}
		return false;
	}
}
