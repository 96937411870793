import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { CalendarDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CustomDateFormatter extends CalendarDateFormatter {
	constructor(
		protected override dateAdapter : DateAdapter,
	) {
		super(dateAdapter);
	}

	// you can override any of the methods defined in the parent class

	public override monthViewColumnHeader({ date, locale } : DateFormatterParams) : string {
		const result = new DatePipe(locale!).transform(date, 'EEE', locale);
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	public override monthViewTitle({ date, locale } : DateFormatterParams) : string {
		const result = new DatePipe(locale!).transform(date, 'MMM y', locale);
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	public override weekViewColumnHeader({ date, locale } : DateFormatterParams) : string {
		const result = new DatePipe(locale!).transform(date, 'EEE', locale);
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	public override dayViewHour({ date, locale } : DateFormatterParams) : string {
		const result = new DatePipe(locale!).transform(date, 'HH:mm', locale);
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}
}
