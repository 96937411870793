<button *ngIf="dismissable" type="button" class="close ml-3" data-dismiss="alert" title="Schließen" i18n-title
	(click)="onClose()"
>
	<fa-icon aria-hidden="true" [icon]="enums.PlanoFaIconPool.DISMISS"></fa-icon>
</button>
<fa-icon
	*ngIf="icon"
	class="mr-2"
	[icon]="icon"
></fa-icon> <ng-content></ng-content>
