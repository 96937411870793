<p-modal-content
	[modalTitle]="modalContentOptions.modalTitle"
	[closeBtnLabel]="modalContentOptions.closeBtnLabel ?? null"
	[closeBtnIcon]="modalContentOptions.closeBtnIcon ?? null"
	[closeBtnTheme]="modalContentOptions.closeBtnTheme"
	[dismissBtnLabel]="modalContentOptions.dismissBtnLabel ?? null"
	[hideDismissBtn]="!!modalContentOptions.hideDismissBtn"
	[theme]="theme ?? null"
	(onDismiss)="onDismiss()"
	(onClose)="onClose()"
>
	<p-modal-content-body>
		<h4 *ngIf="modalContentOptions.icon" class="text-center mt-1 mb-3">
			<fa-icon [icon]="modalContentOptions.icon"></fa-icon>
		</h4>
		<p *ngIf="modalContentOptions.description && !modalContentDescriptionTemplate"
			[innerHtml]="modalContentOptions.description"></p>
		<ng-container *ngTemplateOutlet="modalContentDescriptionTemplate" ></ng-container>
	</p-modal-content-body>
</p-modal-content>
