import { ChangeDetectionStrategy, Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { MeService, SchedulingApiMemo, SchedulingApiService } from '@plano/shared/api';
import { Data } from '@plano/shared/core/data/data';
import { PrimitiveDataInput } from '@plano/shared/core/data/primitive-data-input';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-day-comment',
	templateUrl: './day-comment.component.html',
	styleUrls: ['./day-comment.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class DayCommentComponent implements OnChanges {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dayStart : number | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showDateInput : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public clickable : boolean = true;

	// TODO: Its not intuitive that these are true by default
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private maxLines : number | boolean = true;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private maxTextLength : number | boolean = true;

	constructor(
		public me : MeService,
		public api : SchedulingApiService,
		private modalService : ModalService,
		private textToHtmlService : TextToHtmlService,
		private pMoment : PMomentService,
	) {}

	public enums = enumsObject;

	/** ngOnChanges */
	public ngOnChanges() : void {
		// When nothing is set it means "today"
		if (!this.dayStart) {
			this.dayStart = +this.pMoment.m().startOf('day');
		}

		Assertions.ensureIsDayStart(this.dayStart);
	}

	private _memo : Data<SchedulingApiMemo> =
		new Data<SchedulingApiMemo>(this.api, new PrimitiveDataInput<number>(() => this.dayStart!));
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get memo() : SchedulingApiMemo | null {
		const memo = this.api.data.memos.getByDay(this.dayStart!);
		if (!memo) return null;
		return this._memo.get(() => memo);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasMemo() : boolean {
		return this.api.isLoaded() && !!this.memo && !!this.memo.message;
	}

	/**
	 * Modal with question if memo should be removed
	 */
	public removeMemoPrompt(removeMemoModalContent : TemplateRef<PModalTemplateDirective>) : void {
		void this.modalService.openModal(removeMemoModalContent, {
			theme: enumsObject.PThemeEnum.DANGER,
		}).result.then(value => {
			if (value.modalResult === 'success') {
				assumeNonNull(this.memo);
				this.removeEditableMemo(this.memo);
				void this.api.save();
			}
		});
	}

	/**
	 * Turn the text into html [and crop it if wanted]
	 */
	public textToHtml(text : string) : string {
		return this.textToHtmlService.textToHtml(text, this.maxTextLength, this.maxLines);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get blockquoteIsClickable() : boolean {
		if (!this.clickable) return false;
		if (!this.userCanSetMemos && !this.hasMemo) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get userCanSetMemos() : boolean | undefined {
		return this.api.data.attributeInfoMemos.canSet;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public openMemo(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		this.api.createDataCopy();
		if (!this.hasMemo) this.createMemo();
		void this.modalService.openModal(modalContent).result.then(value => {
			if (value.modalResult === 'success') {
				this.api.mergeDataCopy();
				assumeNonNull(this.memo);
				if (!this.memo.message.length) this.removeEditableMemo(this.memo);

				// HACK: Sometimes there are memos without a message. i don’t know why. ¯\_(ツ)_/¯
				for (const item of this.api.data.memos.iterable()) {
					if (item.message.length) continue;
					this.api.data.memos.removeItem(item);
				}

				void this.api.save();
			} else {
				this.api.dismissDataCopy();
			}
		});
	}

	private createMemo() : void {
		const newMemo = this.api.data.memos.createNewItem();
		newMemo.start = this.dayStart!;
		newMemo.end = this.pMoment.m(this.dayStart).add(1, 'day').valueOf();
		newMemo.message = '';
	}

	/**
	 * Remove a memo
	 */
	private removeEditableMemo(memo : SchedulingApiMemo) : void {
		// HACK: Sometimes there are memos without a message. i don’t know why. ¯\_(ツ)_/¯
		for (const item of this.api.data.memos.iterable()) {
			if (item.message.length) continue;
			this.api.data.memos.removeItem(item);
		}
		this.api.data.memos.removeItem(memo);
	}
}
