import { SchedulingApiPaymentMethodType, SchedulingApiShiftModelCoursePaymentMethodBase, SchedulingApiShiftModelCoursePaymentMethodsBase } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';
import { PShiftmodelTariffService } from '@plano/shared/p-forms/p-shiftmodel-tariff.service';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModelCoursePaymentMethods extends SchedulingApiShiftModelCoursePaymentMethodsBase {

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}

	/** Check if the minimum amount of paymentMethods is present */
	public minPaymentMethods() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.MIN_LENGTH, fn: (_control) => {
			const model = this.parent!;
			const noChargeableTariff = !PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(model.courseTariffs);
			if (noChargeableTariff) return null;
			if (PShiftmodelTariffService.hasVisiblePaymentMethod(model.coursePaymentMethods)) return null;

			let minPaymentMethodsErrorText : PDictionarySource | null = null;
			if (!model.coursePaymentMethods.hasUntrashedItem) {
				minPaymentMethodsErrorText = 'Dieses Angebot ist kostenpflichtig. Daher muss eine Zahlungsart vorhanden sein, die Kunden bei der Online-Buchung auswählen können.';
			} else if (!PShiftmodelTariffService.hasVisiblePaymentMethod(model.coursePaymentMethods)) {
				minPaymentMethodsErrorText = 'Dieses Angebot ist kostenpflichtig. Daher muss eine Zahlungsart vorhanden sein, die Kunden bei der Online-Buchung auswählen können. Aktuell sind alle Zahlungsarten als <mark>interne Zahlungsarten</mark> eingestellt und damit bei der Online-Buchung nicht verfügbar.';
			}
			assumeNonNull(minPaymentMethodsErrorText, 'minPaymentMethodsErrorText', 'Could not figure out the reason for the validation error.');

			return { [PPossibleErrorNames.MIN_LENGTH] : {
				name: PPossibleErrorNames.MIN_LENGTH,
				primitiveType: PApiPrimitiveTypes.ApiList,
				actual: 0,
				expected: 1,
				errorText: minPaymentMethodsErrorText,
			} };
		}});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModelCoursePaymentMethod extends SchedulingApiShiftModelCoursePaymentMethodBase {
	/**
     * Check if there are no payment methods with the same name
     */
	public checkNoDuplicatePaymentMethodNames() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME, fn: (_control) => {
			const name = this.name;
			if (this.parent) {
				for (const paymentMethod of this.parent.iterable()) {
					if (paymentMethod === this)
						continue;

					if (paymentMethod.name === name) {
						// We found a duplicate price
						return { [PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME]: {
							name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD_NAME,
							primitiveType: null,
						}};
					}
				}
			}

			return null;
		}});
	}

	/**
	 * Check if there are no payment methods with the same type, when the type is online payment
	 */
	public checkNoRepeatedPaymentMethodTypes() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD, fn: (_control) => {
			const type = this.type;

			if (type === SchedulingApiPaymentMethodType.ONLINE_PAYMENT && this.parent) {
				for (const paymentMethod of this.parent.iterable()) {
					if (paymentMethod === this)
						continue;
					if (paymentMethod.type === type) {
						// We found a duplicate price
						return { [PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD]: {
							name: PPossibleErrorNames.DUPLICATE_PAYMENT_METHOD,
							primitiveType: null,
						}};
					}
				}
			}
			return null;

		}});
	}
}
