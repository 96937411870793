import { Component, EventEmitter, HostBinding, Injector, Input, Output } from '@angular/core';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { DumbBookingItemComponent } from '@plano/client/scheduling/shared/p-bookings/booking-item/dumb-booking-item/dumb-booking-item.component';
import { PSidebarService } from '@plano/client/shared/p-sidebar/p-sidebar.service';
import { SchedulingApiBooking, SchedulingApiBookings } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum BookingsSortedByEmum {
	PAYMENT_STATUS = 'paymentStatus',
	STATE = 'state',
	DATE_OF_BOOKING = 'dateOfBooking',
	FIRST_SHIFT_START = 'firstShiftStart',
	BOOKING_CODE = 'bookingNumber',
	PRICE = 'price',
}

@Component({
	selector: 'p-booking-list[bookings]',
	templateUrl: './booking-list.component.html',
	styleUrls: ['./booking-list.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class BookingListComponent implements PComponentInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isLoading : PComponentInterface['isLoading'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private bookings ! : SchedulingApiBookings;

	// @Input() public searchString ! : string;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public maxVisibleItems ?: number;

	/**
	 * User has clicked a "edit this booking" button.
	 */
	@Output() public onEdit : EventEmitter<Id> = new EventEmitter<Id>();

	/**
	 * Should firstShiftStart be visible?
	 * @example Usually [showFirstShiftStart]="!groupByCourses" should be bound here.
	 */
	@Input() public showFirstShiftStart : DumbBookingItemComponent['showFirstShiftStart'] = false;

	@HostBinding('class.table-alike-list') protected _alwaysTrue = true;

	constructor(
		public pSidebarService : PSidebarService,
		injector : Injector,
	) {
		this.schedulingService = injector.get(SchedulingService);
	}

	private schedulingService : SchedulingService;
	public Config = Config;

	/**
	 * Get the bookings that should currently be shown
	 */
	public get bookingsForList() : readonly SchedulingApiBooking[] {
		if (this.maxVisibleItems === undefined) return this.bookings.iterable();
		return this.bookings.slice(0, this.maxVisibleItems);
	}

	/**
	 * Get router link for view in calendar for a specific shift
	 */
	public onShiftSelectedLink(booking : SchedulingApiBooking) : string {
		return `/client/scheduling/${this.schedulingService.urlParam.calendarMode}/${booking.firstShiftStart!.toString()}`;
	}

	/**
	 * Are there any shifts available that are related to this booking?
	 * If not we must disable the button to select those shifts in the calendar-view.
	 */
	public noRelatedShiftsAvailable(booking : SchedulingApiBooking) : DumbBookingItemComponent['noRelatedShiftsAvailable'] {
		if (booking.allShiftsRemoved) return true;
		return booking.courseSelector === null;
	}

	/**
	 * Check if the user can write any bookings.
	 */
	protected get userCanWriteSomeBookings() : boolean | null {
		return this.bookings.some(item => item.attributeInfoThis.canSet);
	}
}
