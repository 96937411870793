import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { SchedulingApiMember, SchedulingApiShift, SchedulingApiShiftAssignableMember, SchedulingApiShiftModel, SchedulingApiShiftModelAssignableMember } from '@plano/shared/api';
import { ClientCurrency, PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';

@Component({
	selector: 'p-input-member-earnings[formItem][member]',
	templateUrl: './p-input-member-earnings.component.html',
	styleUrls: ['./p-input-member-earnings.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputMemberEarningsComponent implements AfterContentInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private formItem ! : SchedulingApiShiftModel | SchedulingApiShift;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() protected member ! : SchedulingApiMember;

	constructor(
		private pFormsService : PFormsService,
	) {
	}

	public Config = Config;
	public PApiPrimitiveTypes = PApiPrimitiveTypes;

	private _earnings : number | null = null;
	public formGroup : FormGroup<{
		'hourlyEarnings' : PFormControl<ClientCurrency>
	}> | null = null;

	public ngAfterContentInit() : void {
		this.initFormGroup();
	}

	private get assignableMember() : SchedulingApiShiftAssignableMember | SchedulingApiShiftModelAssignableMember | null {
		return this.formItem.assignableMembers.getByMember(this.member);
	}

	/**
	 * Initialize the formGroup for this component
	 */
	public initFormGroup() : void {
		if (this.formGroup) { this.formGroup = null; }

		const newFormGroup = this.pFormsService.group({});

		this.pFormsService.addPControl(
			newFormGroup,
			'hourlyEarnings',
			{
				formState: {
					value: this.assignableMember ? this.assignableMember.hourlyEarnings : undefined,
					disabled: false,
				},
				subscribe: (value) => {
					if (typeof value !== 'number') {
						if (value === undefined || value === null) {
							this.formItem.assignableMembers.removeMember(this.member);

							// TODO: PLANO-156519
							if (!this.formItem.attributeInfoAssignedMemberIds.isAvailable) return;
							this.formItem.assignedMemberIds.removeItem(this.member.id);
						}
						return;
					}

					if (!this.assignableMember) {
						this.formItem.assignableMembers.addNewMember(this.member, value);
						return;
					}
					if (this.assignableMember.hourlyEarnings !== value) this.assignableMember.hourlyEarnings = value;
				},
			},

		);

		this.formGroup = newFormGroup;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get earnings() : number {
		if (this.assignableMember !== null) {
			const earnings = this.assignableMember.attributeInfoHourlyEarnings.value;
			if (earnings !== null) this._earnings = earnings;
		}
		return this._earnings!;
	}

	public set earnings(value : number) {
		if (
			this.assignableMember !== null &&
			typeof this._earnings === 'number' &&
			this.assignableMember.hourlyEarnings !== value
		) {
			this.assignableMember.hourlyEarnings = value;
		}
		this._earnings = value;
	}

}
