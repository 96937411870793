import {
	Directive,
	EmbeddedViewRef,
	Input,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';

  /**
   * Directive for storing variables in template files
   */
  @Directive({
  	// eslint-disable-next-line @angular-eslint/directive-selector
  	selector: '[ngVar]',
  	standalone: true,
  })
export class NgVarDirective<T = unknown> {

	/**
	 * ngVar input to be used as a variable
	 *
	 * Just write the expression and at the end use "as nameOfVariable" and use nameOfVariable in the template normally
	 */
	@Input()
	public set ngVar(context : T) {
		this._context.$implicit = context;
		this._context.ngVar = context;
		if (!this._viewRef) {
			this._viewRef = this._viewContainer.createEmbeddedView(
				this._templateRef,
				this._context,
			);
		}
	}

	constructor(
		private _viewContainer : ViewContainerRef,
		private _templateRef : TemplateRef<NgVarContext<T>>,
	  ) {}

	/**
   * Assert the correct type of the expression bound to the `ngVar` input within the template.
   *
   * The presence of this static field is a signal to the Ivy template type check compiler that
   * when the `NgVar` structural directive renders its template, the type of the expression bound
   * to `ngVar` should be narrowed in some way. For `NgVar`, the binding expression itself is used to
   * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `NgVar`.
   */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	private static ngTemplateGuard_ngVar : 'binding';

	/**
   * Asserts the correct type of the context for the variable that `NgVar` will declare.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgVar` structural directive declares its variable with a specific context type.
   */
	private static ngTemplateContextGuard<T>(
	_directive : NgVarDirective<T>,
	_context : unknown,
	) : _context is NgVarContext<T> {
		return true;
	}

	private _context : NgVarContext<T> = new NgVarContext<T>();
	private _viewRef : EmbeddedViewRef<NgVarContext<T>> | null = null;
}

/**
   Context of ngVar directive
*/
export class NgVarContext<T = unknown> {
	public $implicit : T = null!;
	public ngVar : T = null!;
}