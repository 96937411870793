/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "email_valid".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	INVALID = 1;
	USED = 2;
	FAULTY_EMAIL = 3;
}


@Injectable({
  providedIn: 'root',
})
export class EmailValidApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'email_valid');
	}

	protected version() : string {
		return 'afd2426e43b85d8523149fb4951acb5e,c5cae2f8148bc336b3f78f169be259cf';
	}

	private dataWrapper = new EmailValidApiRoot(this, );

	get data() : EmailValidApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : EmailValidApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new EmailValidApiRoot(this, );
	}
}

		 
export class EmailValidApiRoot extends ApiObjectWrapper<EmailValidApiRoot>
{
	constructor(override readonly api : EmailValidApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<EmailValidApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, EmailValidApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<EmailValidApiRoot, EmailValidApiRoot> = new ApiAttributeInfo<EmailValidApiRoot, EmailValidApiRoot>({
			apiObjWrapper: this as any as EmailValidApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToSet: () => false,
			defaultValue: function(this : EmailValidApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoInvalid : ApiAttributeInfo<EmailValidApiRoot, boolean> = new ApiAttributeInfo<EmailValidApiRoot, boolean>({
			apiObjWrapper: this as any as EmailValidApiRoot,
			name: 'invalid',
			nodeName: 'INVALID',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : EmailValidApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	attributeInfoUsed : ApiAttributeInfo<EmailValidApiRoot, boolean> = new ApiAttributeInfo<EmailValidApiRoot, boolean>({
			apiObjWrapper: this as any as EmailValidApiRoot,
			name: 'used',
			nodeName: 'USED',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : EmailValidApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	attributeInfoFaultyEmail : ApiAttributeInfo<EmailValidApiRoot, Email> = new ApiAttributeInfo<EmailValidApiRoot, Email>({
			apiObjWrapper: this as any as EmailValidApiRoot,
			name: 'faultyEmail',
			nodeName: 'FAULTY_EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});

	/**
     *  Is a passed email address not valid?
	 *
	 * @type {boolean}
     */
	get invalid() : boolean {
		this.getterDebugValidations(this.attributeInfoInvalid, false);
		return this.data[1];
	}

	set invalidTestSetter(v : boolean) {
        this.setterImpl(1, v, 'invalid', true, null, null);
	}

	/**
     *  Is a passed email address already used?
	 *
	 * @type {boolean}
     */
	get used() : boolean {
		this.getterDebugValidations(this.attributeInfoUsed, false);
		return this.data[2];
	}

	set usedTestSetter(v : boolean) {
        this.setterImpl(2, v, 'used', true, null, null);
	}

	/**
     *  When one of the error booleans are true then this value contains the email address which triggered the error. Note, that this api stops checking finding the first error. So, this value is not a list but a potentially only a single email address.
	 *
	 * @type {Email}
     */
	get faultyEmail() : Email {
		this.getterDebugValidations(this.attributeInfoFaultyEmail, false);
		return this.data[3];
	}

	set faultyEmailTestSetter(v : Email) {
        this.setterImpl(3, v, 'faultyEmail', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : EmailValidApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}



