/* eslint-disable unicorn/prevent-abbreviations */
import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, NgZone } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastsService } from '@plano/client/service/toasts.service';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { SchedulingApiAssignmentProcessState, SchedulingApiService, SchedulingApiShift } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';
import { PCookieService } from '@plano/shared/core/p-cookie.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';

type ValueType = boolean;

/**
 * <p-notification-conf-form> provides form controls to set notification configuration values.
 */
@Component({
	selector: 'p-notification-conf-form',
	templateUrl: './p-notification-conf-form.component.html',
	styleUrls: ['./p-notification-conf-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PNotificationConfFormComponent),
			multi: true,
		},
		EditableDirective,
	],
})
export class PNotificationConfFormComponent extends EditableDirective
	implements ControlValueAccessor, EditableControlInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shift : SchedulingApiShift | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public valueText : string | null = null;

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public override pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public override api : EditableControlInterface<SchedulingApiService>['api'] = null;
	@Input() public override valid : EditableControlInterface['valid'] = null;

	/** Is this valid? [valid]="…" overwrites the valid state if the formControl. */
	public get isValid() : boolean {
		if (this.valid !== null) return this.valid;
		return !this.formControl?.invalid;
	}

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to make [(ngModel)] and [formControl] work.
	 */
	@Input() public disabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public formControl : PFormControl | null = null;

	constructor(
		protected override readonly changeDetectorRef : ChangeDetectorRef,
		public override el : ElementRef<HTMLElement>,
		protected override readonly zone : NgZone,
		public override applicationRef : ApplicationRef,
		protected override console : LogService,
		protected override toastsService : ToastsService,
		private pCookieService : PCookieService,
		protected override localize : LocalizePipe,
	) {
		super(changeDetectorRef, el, zone, applicationRef, console, toastsService, localize);
		if (this.valueText === null) this.valueText = this.localize.transform('In Schichten eingeteilte User informieren');
	}

	public enums = enumsObject;

	/**
	 * Is this control visible or not?
	 */
	public get visible() : boolean {
		if (!this.shift) return true;
		if (this.api!.data.shiftChangeSelector.isChangingShifts) return true;
		if (!this.shift.assignmentProcess) return true;
		if (this.shift.assignmentProcess.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING) return true;
		if (this.shift.assignmentProcess.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_FINISHED) return true;
		return 	false;
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null {
		if (this.pCookieService.has({name: 'notifyAssignedUsers', prefix: 'NotificationConf'})) {
			const cookie = this.pCookieService.get({name: 'notifyAssignedUsers', prefix: 'NotificationConf'}) === 'true';
			if (this._value !== cookie) this.value = cookie;
		}
		return this._value;
	}
	public set value(value : ValueType | null) {
		if (value !== null) {
			if (this.pCookieService.has({name: 'notifyAssignedUsers', prefix: 'NotificationConf'})) {
				this.pCookieService.remove({name: 'notifyAssignedUsers', prefix: 'NotificationConf'});
			}
			this.pCookieService.put({name: 'notifyAssignedUsers', prefix: 'NotificationConf'}, value);
		}
		if (value === this._value) return;

		this._value = !!value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = !!value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.formControl && this.formControl.disabled !== this.disabled) {
			this.disabled ? this.formControl.disable() : this.formControl.enable();
		}
	}
}
