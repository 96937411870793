import { Directive, ElementRef, HostBinding } from '@angular/core';

/**
 * Directive that adds a spinner while a save operation is running to a button that
 * triggers the save and navigates then to a new page. For example, the submit buttons
 * in the detail forms.
 *
 * NOTE: This directive will get obsolete when we fix this ticket: https://drplano.atlassian.net/browse/PLANO-170856
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'button[asyncSaveButton]',
	standalone: true,
})
export class AsyncSaveButtonDirective {

	@HostBinding('class.d-flex') private _alwaysTrue = true;

	constructor(
		el : ElementRef<HTMLButtonElement>,
	) {
		el.nativeElement.addEventListener('click', () => {
			el.nativeElement.classList.add('progress-bar-striped', 'progress-bar-animated');
		});
	}
}
