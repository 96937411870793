import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { GenerateShiftExchangesMode, GenerateShiftExchangesOptions, SchedulingApiService, SchedulingApiShiftExchange, SchedulingApiShiftExchangeShiftRef } from '@plano/shared/api';
import { Date, DateExclusiveEnd, PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PInputDateTypes } from '@plano/shared/p-forms/p-input-date/p-input-date.component';
import { Subscription } from 'rxjs';

type ValueType = GenerateShiftExchangesOptions;

/**
 * Get a number of days, turn it into milliseconds and remove it from the given timestamp
 *
 * @example
 * 		You have a shift at the `10. January`, and the user wants to set some deadline which takes place two days before,
 * 	 	then you need to provide the `10. January` (as timestamp), and the `2` and function will return the timestamp
 * 		for the `08. January`.
 *
 * @param timestamp - Timestamp to subtract days from
 * @param daysBefore - Number of days to subtract
 * @param pMoment - An instance of the PMomentService
 * @returns timestamp with days subtracted
 */
export const removeDaysFromTimestamp = (
	timestamp : number,
	daysBefore : number | null,
	pMoment : PMomentService,
) : Date => {
	const daysAsDuration = daysBefore ? pMoment.duration(+daysBefore, 'days') : 0 as unknown as moment.Duration;
	const daysAsTimestamp = +daysAsDuration;
	return +pMoment.m(timestamp - daysAsTimestamp).add(1, 'day').startOf('day');
};

@Component({
	selector: 'p-generate-shift-exchanges-options[shiftExchange]',
	templateUrl: './generate-shift-exchanges-options.component.html',
	styleUrls: ['./generate-shift-exchanges-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PGenerateShiftExchangesOptionsComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PGenerateShiftExchangesOptionsComponent extends PAbstractControlComponentBaseDirective
	implements ControlValueAccessor, AfterContentInit, AfterContentChecked, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftExchange ! : SchedulingApiShiftExchange;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		public api : SchedulingApiService,
		private validators : ValidatorsService,
		private pShiftExchangeService : PShiftExchangeService,
		private pMoment : PMomentService,
		private localize : LocalizePipe,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public PInputDateTypes = PInputDateTypes;
	public enums = enumsObject;
	public SectionWhitespace = SectionWhitespace;
	public GenerateShiftExchangesMode = GenerateShiftExchangesMode;

	public daysBefore : number | null = null;

	public formGroup : FormGroup<{
		'illnessResponderCommentToMembers' : PFormControl,
		'deadline' : PFormControl<DateExclusiveEnd>,
		'mode' : PFormControl<GenerateShiftExchangesMode>,
	}> | null = null;

	public now ! : number;

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		return super.ngAfterContentInit();
	}

	public override ngAfterContentChecked() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initNow();
		this.initAfterValueHack();
		return super.ngAfterContentChecked();
	}

	private initAfterValueHack() : void {
		// HACK: this.value is not in the first run here. Therefore i ask for (this._value && !this.formGroup)
		// It is not clear why this.value is not defined. I added a post to StackOverflow for this:
		// https://stackoverflow.com/questions/57918712/why-is-this-value-undefined-null-on-every-lifecycle-hook-when-using-ngmodel-t

		if (!this.value) return;
		if (this.formGroup) return;

		assumeDefinedToGetStrictNullChecksRunning(this.shiftExchange, 'this.shiftExchange');
		this.initValues();
		this.initFormGroup();
		this.setChangesListenerForControlError();
	}

	/**
	 * Set some default values for properties that are not defined yet
	 */
	public initValues() : void {
		assumeNonNull(this.value);
		if (this.value.mode === null) this.value.mode = GenerateShiftExchangesMode.ONE_SHIFT_EXCHANGE_FOR_EACH;
	}

	private subscription : Subscription | null = null;

	private setChangesListenerForControlError() : void {
		if (!this.control) return;

		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		this.subscription = this.formGroup.valueChanges.subscribe(() => {
			assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
			this.control!.setErrors(this.formGroup.valid ? null : { invalid : true });
		});

		// window.setInterval(() => {
		// 	this.console.log('!!formGroup.errors', !!this.formGroup.errors);
		// 	this.console.log('formGroup', this.formGroup);
		// },1000)
	}

	private initNow() : void {
		this.now = +this.pMoment.m();
	}

	/**
	 * Initialize the formGroup for this component
	 */
	public initFormGroup() : void {
		if (this.formGroup) { this.formGroup = null; }

		const newFormGroup = this.pFormsService.group({}) as Exclude<PGenerateShiftExchangesOptionsComponent['formGroup'], null>;

		this.pFormsService.addControl(
			newFormGroup,
			'mode',
			{
				value: this.value!.mode,
				disabled: false,
			},
			[
			],
			(value : GenerateShiftExchangesMode | null) => {
				assumeNonNull(this.value);
				if (this.value.mode === value) return;
				this.value.mode = value;
				this.formGroup!.controls['deadline'].setValue(null);
			},
		);
		assumeDefinedToGetStrictNullChecksRunning(this.now, 'now');

		// When mode is ONE_SHIFT_EXCHANGE_FOR_EACH the limitations (/validations) might be more narrow than the current
		// value of this.shiftExchange.deadline. We dont want to set something as default which has validation errors.
		const INITIAL_DEADLINE = this.value!.mode === GenerateShiftExchangesMode.ONE_SHIFT_EXCHANGE_FOR_EACH ? null : this.shiftExchange.deadline;

		this.pFormsService.addControl(
			newFormGroup,
			'deadline',
			{
				value: INITIAL_DEADLINE,
				disabled: false,
			},
			[
				this.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.Integer),
				this.validators.min(
					() => this.now,
					false,
					PApiPrimitiveTypes.DateTime,
					PApiPrimitiveTypes.Date,
				),
				this.validators.max(
					() => this.deadlineMax,
					true,
					PApiPrimitiveTypes.DateExclusiveEnd,
				),
			],
			(value) => {
				this.value!.daysBefore = this.daysBefore;
				this.value!.deadline = value;
			},
		);
		this.pFormsService.addControl(
			newFormGroup,
			'illnessResponderCommentToMembers',
			{
				value: this.shiftExchange.illnessResponderCommentToMembers,
				disabled: false,
			},
			[],
			(value : string) => {
				this.shiftExchange.illnessResponderCommentToMembers = value;
			},
		);
		this.formGroup = newFormGroup;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftRefsHasMultipleShiftsOfSamePacket() : boolean {
		for (const shiftRef of this.shiftExchange.shiftRefs.iterable()) {
			const relatedShift = this.api.data.shifts.get(shiftRef.id);

			if (!relatedShift) throw new Error('Could not find relatedShift');

			// shift is not a package? Then skip to next loop.
			if (!relatedShift.packetShifts.length) continue;

			// shift is a package!
			// Iterate all packetShifts and check if one of them is inside this shiftExchange.shiftRefs
			for (const packetShift of relatedShift.packetShifts.iterable()) {
				// Skip equal shift.
				if (packetShift.id.equals(shiftRef.id)) continue;
				if (this.shiftExchange.shiftRefs.contains(packetShift.id)) return true;
			}
		}
		return false;
	}

	private get responder() : string {
		if (this.shiftExchange.memberIdAddressedTo !== null) {
			const MEMBER_ID_ADDRESSED_TO = this.api.data.members.get(this.shiftExchange.memberIdAddressedTo);
			if (MEMBER_ID_ADDRESSED_TO) return `${MEMBER_ID_ADDRESSED_TO.firstName}`;
		} else {
			return this.localize.transform('die Mitarbeitenden');
		}
		return '…';
	}

	private get sender() : string | null {
		// TODO: PLANO-156519
		if (!this.shiftExchange.attributeInfoCommunications.isAvailable) return null;

		// WARNING: This methods exists two times
		if (!this.pShiftExchangeService.iAmTheNewResponsiblePersonForThisIllness(this.shiftExchange)) {
			if (!this.shiftExchange.communications.managerResponseCommunication) {
				return this.localize.transform('der Leitung');
			}
			if (!this.shiftExchange.communications.managerResponseCommunication.communicationPartner) throw new Error('communicationPartner undefined');
			return this.shiftExchange.communications.managerResponseCommunication.communicationPartner.firstName;
		}
		return this.localize.transform('dir');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get illnessResponderCommentToMembersLabel() : string {
		// WARNING: This methods exists two times
		return this.localize.transform({
			sourceString: 'Kommentar von ${sender} an ${responder}',
			params: {
				sender: this.sender ?? 'ERROR',
				responder: this.responder,
			},
		});
	}

	/** @see removeDaysFromTimestamp */
	public removeDaysFromTimestamp(timestamp : number, daysBefore : number | null) : number {
		return removeDaysFromTimestamp(timestamp, daysBefore, this.pMoment);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showModeInput() : boolean {
		return this.shiftExchange.shiftRefs.length > 1;
	}

	/**
	 * The max date for the input which defines
	 * either
	 * - the deadline for the generated shift-exchange
	 * or
	 * - the deadline for each generated shift-exchange
	 */
	public get deadlineMax() : number | null {
		if (this.value!.mode === GenerateShiftExchangesMode.ONE_SHIFT_EXCHANGE_FOR_EACH) {
			// The defined date might come from a days before input. So the generated shift exchange with the timewise closest
			// date defines the max limit.
			return this.endOfEarliestShift;
		} else {
			// Here the same rules as to SHIFT_EXCHANGE_DEADLINE apply. The last shift in the stack defines the max limit.
			// This is the same limitation as SHIFT_EXCHANGE_DEADLINE has.
			return this.endOfLatestShift;
		}
	}

	private get endOfLatestShift() : number | null {
		if (!this.shiftExchange.shiftRefs.latestEnd) return null;
		const pMoment = new PMomentService(Config.LOCALE_ID);
		return +pMoment.m(this.shiftExchange.shiftRefs.latestEnd).startOf('day');
	}

	private get endOfEarliestShift() : number | null {
		if (!this.shiftExchange.shiftRefs.earliestEnd) return null;
		const pMoment = new PMomentService(Config.LOCALE_ID);
		return +pMoment.m(this.shiftExchange.shiftRefs.earliestEnd).startOf('day');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get deadlineIsDisabled() : boolean {
		if (this.deadlineMax === null) return false;
		return this.now > this.deadlineMax;
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get sortedShiftRefs() : readonly SchedulingApiShiftExchangeShiftRef[] {
		return this.shiftExchange.shiftRefs.sortedBy(item => {
			const shift = this.api.data.shifts.get(item.id);
			if (!shift) throw new Error('shift could not be found');
			return shift.start;
		}).iterable();
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.subscription?.unsubscribe();
		return super.ngOnDestroy();
	}

	/**
	 * Should the deadline be set as daysBefore or as a date?
	 */
	protected get showDaysBeforeForDeadline() : boolean {
		return this.formGroup!.controls['mode']!.value !== null && this.shiftExchange.shiftRefs.length > 1;
	}

}
