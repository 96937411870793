<ng-container
	[pEditable]="pEditable"
	[api]="api"
	[valid]="valid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit($event)"
	(editMode)="editMode.emit($event)"
>
	<button
		[title]="btnTitle ?? ''"
		[style.display]="!show ? 'none!important' : undefined"
		type="button"
		[class.progress-bar-striped]="isLoading"
		[class.progress-bar-animated]="isLoading"
		class="btn nowrap d-flex {{alignItemsClass}} {{justifyClass}} {{flexGrowClass}} {{btnClasses}}"

		pAutoFocus

		(focus)="focus.emit()"
		(blur)="blur.emit()"

		pEditableInstantSaveOnClick
		(triggerClick)="isLoading ? undefined : triggerClick.emit($event)"

		[class.required]="required"
		[class.has-danger]="hasDanger"

		[class.dark-mode]="darkMode"

		[class.btn-sm]="size === enums.BootstrapSize.SM"
		[class.btn-lg]="size === enums.BootstrapSize.LG"
		[class.badge-pill]="hasBadgePillClass"
		[class.active]="isActiveButton"
		[class.btn-toggle]="buttonType === PButtonType.TOGGLE"
		[class.btn-filter]="buttonType === PButtonType.FILTER"

		[class.striped-info]="stripesTheme === enums.PThemeEnum.INFO"

		[class.btn-purple]="theme === enums.PThemeEnum.PURPLE"
		[class.btn-light]="theme === enums.PThemeEnum.LIGHT"
		[class.btn-danger]="theme === enums.PThemeEnum.DANGER"
		[class.btn-success]="theme === enums.PThemeEnum.SUCCESS"
		[class.btn-info]="theme === enums.PThemeEnum.INFO"
		[class.btn-warning]="theme === enums.PThemeEnum.WARNING"
		[class.btn-outline-light]="theme === PBtnThemeEnum.OUTLINE_LIGHT"
		[class.btn-outline-secondary]="theme === PBtnThemeEnum.OUTLINE_SECONDARY"
		[class.btn-outline-primary]="theme === PBtnThemeEnum.OUTLINE_PRIMARY"
		[class.btn-outline-danger]="theme === PBtnThemeEnum.OUTLINE_DANGER"
		[class.btn-outline-dark]="theme === PBtnThemeEnum.OUTLINE_DARK"
		[class.btn-primary]="theme === enums.PThemeEnum.PRIMARY"
		[class.btn-secondary]="theme === enums.PThemeEnum.SECONDARY"
		[class.btn-dark]="theme === enums.PThemeEnum.DARK"
		[class.text-muted]="textStyle === PTextColorEnum.MUTED"
		[class.text-primary]="textStyle === enums.PThemeEnum.PRIMARY"
		[class.text-secondary]="textStyle === enums.PThemeEnum.SECONDARY"
		[class.text-success]="textStyle === enums.PThemeEnum.SUCCESS"
		[class.text-info]="textStyle === enums.PThemeEnum.INFO"
		[class.text-warning]="textStyle === enums.PThemeEnum.WARNING"
		[class.text-light]="textStyle === enums.PThemeEnum.LIGHT"
		[class.text-dark]="textStyle === enums.PThemeEnum.DARK"
		[class.text-danger]="textStyle === enums.PThemeEnum.DANGER"
		[class.text-white]="textStyle === PTextColorEnum.WHITE"
		[class.text-left]="textAlign === 'left'"
		[class.text-right]="textAlign === 'right'"
		[class.position-relative]="!!badge"
		[class.rounded-right]="rounded === BootstrapRounded.RIGHT"
		[class.rounded-left]="rounded === BootstrapRounded.LEFT"
		[class.rounded-0]="rounded === BootstrapRounded.NONE"

		[disabled]="disabled || !canSet"
		[attr.aria-label]="ariaLabel"
	>
		<ng-template [ngIf]="!!badge">
			<p-badge
				[content]="badge"
				[align]="badgeAlign"
				[size]="size"
				class="align-{{badgeAlign}}"
			></p-badge>
		</ng-template>
		<fa-icon *ngIf="!!icon" [theme]="iconTheme" [icon]="icon" class="mr-2"></fa-icon><label class="m-0 flex-grow-1" #content [class.d-none]="!showContent" [class.nowrap]="!wrapLabel"><ng-content></ng-content></label>
	</button>
	<div *ngIf="!!disabled && !!cannotSetHint && !isLoading"
		class="btn flex-grow-0 d-flex align-items-center"
		[class.btn-purple]="theme === enums.PThemeEnum.PURPLE"
		[class.btn-light]="theme === enums.PThemeEnum.LIGHT"
		[class.btn-danger]="theme === enums.PThemeEnum.DANGER"
		[class.btn-success]="theme === enums.PThemeEnum.SUCCESS"
		[class.btn-info]="theme === enums.PThemeEnum.INFO"
		[class.btn-warning]="theme === enums.PThemeEnum.WARNING"
		[class.btn-outline-light]="theme === PBtnThemeEnum.OUTLINE_LIGHT"
		[class.btn-outline-secondary]="theme === PBtnThemeEnum.OUTLINE_SECONDARY"
		[class.btn-outline-primary]="theme === PBtnThemeEnum.OUTLINE_PRIMARY"
		[class.btn-outline-danger]="theme === PBtnThemeEnum.OUTLINE_DANGER"
		[class.btn-outline-dark]="theme === PBtnThemeEnum.OUTLINE_DARK"
		[class.btn-primary]="theme === enums.PThemeEnum.PRIMARY"
		[class.btn-secondary]="theme === enums.PThemeEnum.SECONDARY"
		[class.btn-dark]="theme === enums.PThemeEnum.DARK"

		(click)="openCannotSetHint()"
		title="Info" i18n-title
	><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon></div>
</ng-container>
