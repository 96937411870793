<p-bootstrap-form-group
	*ngIf="show"
	[label]="label"
	[ariaLabel]="label ?? inputGroupAppendText ?? placeholder"
	[minimumLabelDisplayBootstrapSize]="minimumLabelDisplayBootstrapSize"

	[footnoteIcon]="footnoteIcon"
	[description]="description"
	[class]="bsFormGroupClasses"
	[keepAlwaysAsFootnote]="keepAlwaysAsFootnote"
>
	<ng-container [ngSwitch]="typeOfUIFormControl">
		<ng-container *ngSwitchCase="'CHECKBOX'">
			<p-checkbox
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[cannotSetHintTemplate]="cannotSetHintTemplate"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"

				[valueText]="valueText"
				[hasButtonStyle]="hasButtonStyle"
				[hideValueIcon]="hideValueIcon"
				[theme]="theme"

				[(ngModel)]="internalValue"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"

				[size]="size"
				[icon]="icon"
			>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-checkbox>
			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
		</ng-container>
		<ng-container *ngSwitchCase="'TEXTAREA'">
			<p-textarea
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"

				[(ngModel)]="internalValue"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"

				[placeholder]="placeholder"
			>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-textarea>
			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
		</ng-container>
		<ng-container *ngSwitchCase="'TEXT_EDITOR'">
			<p-text-editor
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"

				[(ngModel)]="internalValue"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"

				[placeholder]="placeholder"
			>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-text-editor>
		</ng-container>
		<ng-container *ngSwitchCase="'DATE_PICKER'">
			<p-input-date
				#inputDateRef
				class="{{theme ? 'btn-'+theme : 'btn-outline-secondary'}}"
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"

				[(ngModel)]="internalValue"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"

				[placeholder]="placeholder"

				[showTimeInput]="showTimeInput"
				[type]="inputDateType"
				[icon]="icon"
				[label]="inputDateLabel"
				[size]="size"
				[suggestionTimestamp]="suggestionTimestamp"
				[suggestionLabel]="suggestionLabel"
				[showDaysBeforeInput]="showDaysBeforeInput"
				[daysBefore]="daysBefore"
				(innerInputChanged)="innerInputChanged.emit($event)"
				(daysBeforeChange)="daysBeforeChange.emit($event)"
				[min]="min"
				[max]="max"
				[daysBeforeLabel]="daysBeforeLabel"
				[eraseValueBtnLabel]="eraseValueBtnLabel"
				[supportsUnset]="supportsUnset"
				[showEraseValueBtn]="showEraseValueBtn !== undefined ? showEraseValueBtn : null"
				[closeBtnDisabled]="closeBtnDisabled"
			>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-input-date>
		</ng-container>
		<ng-container *ngSwitchCase="'RADIOS'">
			<p-radios
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"
				[cannotSetHintTemplate]="cannotSetHintTemplate"

				[(ngModel)]="internalValue"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"

				[cannotSetHint]="cannotSetHint"

				[hideRadioCircles]="hideRadioCircles"
			>
				<ng-template
					ngFor let-item
					[ngForOf]="items"
				>
					<p-radios-radio
						*ngIf="item.attributeInfo ? item.attributeInfo.isAvailable : true"
						[value]="item.value"
						[icon]="item.icon"
						[iconTheme]="item.iconTheme"
						[badgeOnRadio]="item.badgeOnItem"
						[badgeTheme]="item.badgeTheme"

						[stretchHeight]="item.stretchHeight"

						[wrapperClasses]="item.wrapperClasses"
						[label]="item.label"
						[description]="item.description"
						[active]="item.active"
						[disabled]="item.disabled"
						[cannotSetHint]="item.cannotSetHint"
						(onClick)="onInputItemClick(item, $event)"
					></p-radios-radio>
				</ng-template>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-radios>
			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
		</ng-container>
		<ng-container *ngSwitchCase="'DROPDOWN'">
			<p-dropdown
				[triggerTemplate]="triggerTemplate"
				[triggerReadModeTemplate]="triggerReadModeTemplate"
				[pEditable]="pEditable"
				[appendDropdownOnBody]="appendDropdownOnBody"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				[label]="placeholder"
				(editMode)="editMode.emit($event)"

				[(ngModel)]="internalValue"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"
			>
				<ng-template
					ngFor let-item
					[ngForOf]="items"
				>
					<p-dropdown-item
						*ngIf="item.attributeInfo ? item.attributeInfo.isAvailable : true"
						[value]="item.value"
						[prependedItem]="$any(item.icon)"

						[label]="item.label"
						[description]="item.description"
						[active]="item.active"
						[disabled]="item.disabled"
						[cannotSetHint]="item.cannotSetHint"
						></p-dropdown-item>
				</ng-template>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-dropdown>
			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
		</ng-container>
		<ng-container *ngSwitchCase="'IMAGE_UPLOAD'">
			<!--
			[active]="pEditable"
			[saveChangesHook]="saveChangesHook"
			(onSaveStart)="onSaveStart.emit($event)"
			(onSaveSuccess)="onSaveSuccess.emit($event)"
			(onDismiss)="onDismiss.emit($event)"
			(onLeaveCurrent)="onLeaveCurrent.emit($event)"
			(editMode)="editMode.emit($event)" -->
			<!-- [checkTouched]="checkTouched" -->
			<!-- [ngModel]="attributeInfo.value" Two way binding is not allowed in i-input-image -->

			<p-input-image
				#inputImageRef
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"

				(onSaveSuccess)="onSaveSuccess.emit()"

				[previewTemplate]="previewTemplate"
				[imageUploadTemplate]="imageUploadTemplate"
			>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-input-image>

			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
		</ng-container>
		<ng-container *ngSwitchCase="'PDF_UPLOAD'">
			<p-input-pdf
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[modalTemplate]="pdfModalTemplate"
				(ngModelChange)="internalValue = $event"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"

				[inputDescription]="pdfInputDescription"
				[pdfDescription]="pdfDescription"
				[overlayTemplate]="pdfOverlayTemplate"
			>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-input-pdf>

			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
		</ng-container>

		<ng-container *ngSwitchCase="'COLOR'">
			<p-input-color
				[valid]="valid"
				[valueText]="valueText"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
			/>
		</ng-container>

		<ng-container *ngSwitchDefault>
			<p-input
				*ngIf="!onlyCopyString; else onlyCopyStringTemplate"
				#pInputRef
				[pEditable]="pEditable"
				[api]="attributeInfo.api!"
				[valid]="valid"
				[inputHidden]="hasNullableOptions && selectedOptionValue === null"

				[saveChangesHook]="saveChangesHook"
				(onSaveStart)="onSaveStart.emit($event)"
				(onSaveSuccess)="onSaveSuccess.emit($event)"
				(onDismiss)="onDismiss.emit($event)"
				(onLeaveCurrent)="onLeaveCurrent.emit($event)"
				(editMode)="editMode.emit($event)"

				[type]="pInputType"
				[disabled]="!canSet || !!options && selectedOptionValue === null"
				[(ngModel)]="internalValue"
				[dropdownValue]="dropdownValue"
				(dropdownValueChange)="dropdownValue=$event;dropdownValueChange.emit($event)"

				[formControl]="control"
				[attributeInfo]="attributeInfo"
				[canSet]="canSet"
				[readMode]="readMode"
				[checkTouched]="checkTouched"

				[placeholder]="placeholder"
				[durationUIType]="durationUIType"
				[maxDecimalPlacesCount]="maxDecimalPlacesCount"
				[supportsUnset]="supportsUnset"
				[forcePrependIconOnPrepend]="forcePrependIconOnPrepend"

				[showPasswordMeter]="showPasswordMeter"
				[typeahead]="typeahead"
				[appendOnBody]="appendOnBody"
				[inputGroupAppendText]="inputGroupAppendText"
				[inputGroupAppendIcon]="inputGroupAppendIcon"
			>
				<div class="input-group-prepend">
					<ng-content select=".input-group-prepend"/>
				</div>
				<div class="input-group-append">
					<ng-content select=".input-group-append"/>
				</div>
				<p-dropdown
					*ngIf="options"
					class="input-group-append"
					[appendDropdownOnBody]="appendDropdownOnBody"
					[class.input-hidden]="pInputRef.inputHidden"
					[class.w-100]="hasNullableOptions && selectedOptionValue === null"
					[(ngModel)]="selectedOptionValue"
					[ngModelOptions]="{standalone: true}"
					[pEditable]="pEditable"
					[api]="attributeInfo.api!"
				>
					<p-dropdown-item
						*ngFor="let option of options"
						[active]="option.value === null ? selectedOptionValue === null : selectedOptionValue !== null"
						[value]="option.value"
						[label]="option.text | localize"
					></p-dropdown-item>
				</p-dropdown>
				<ng-template
					ngFor let-item
					[ngForOf]="items"
				>
					<p-dropdown-item
						[value]="item.value"
						[prependedItem]="$any(item.icon)"

						[label]="item.label"
						[description]="item.description"
						[active]="item.active"
						[disabled]="item.disabled"
						[cannotSetHint]="item.cannotSetHint"
						(onClick)="onInputItemClick(item, $event)"
					></p-dropdown-item>
				</ng-template>
				<ng-template [ngTemplateOutlet]="ngContentRef"></ng-template>
			</p-input>
			<!-- XXX: Not implemented yet -->
			<!-- [cannotSetHint]="'Lorem Ipsum'" -->
			<ng-template #onlyCopyStringTemplate>
				<p-input-copy-string [appendButtonText]="appendButtonText" [valueToCopy]="attributeInfo.value"></p-input-copy-string>
			</ng-template>
		</ng-container>
	</ng-container>
</p-bootstrap-form-group>

<ng-template #ngContentRef>
	<ng-content></ng-content>
</ng-template>
