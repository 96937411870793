import { ChangeDetectorRef, Directive, Input } from '@angular/core';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: '[changeDetection]',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ChangeDetectionDirective {
	constructor(private changeDetectorRef : ChangeDetectorRef) {}

	/**
	 * Should changes be detected?
	 */
	@Input() public set changeDetection(enable : boolean) {
		if (enable) {
			this.changeDetectorRef.reattach();
		} else {
			this.changeDetectorRef.detach();
		}
	}

}
