import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

/**
 * The form that will be shown inside the modal
 * @deprecated use [contentTemplateRef] instead
 */
@Component({
	selector: 'p-editable-modal-form',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class PEditableModalFormComponent {
	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column')
	@HostBinding('class.w-100') protected _alwaysTrue = true;
}
