import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { PPossibleErrorNames, PValidationErrorValue } from '@plano/shared/core/validators.types';
import { ValidationHintService } from './validation-hint.service';

@Component({
	selector: 'p-validation-hint',
	templateUrl: './validation-hint.component.html',
	styleUrls: ['./validation-hint.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ValidationHintComponent {
	@HostBinding('class.form-control-feedback') protected _alwaysTrue = true;

	/** The Error-Text */
	@Input() private text : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public errorValue : PValidationErrorValue | null = null;

	// TODO: remove | 'min' | 'max'
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public validationName : PPossibleErrorNames | 'pattern' | 'noRecipient' | 'occupied' | 'time' | 'number' | 'min' | 'max' = PPossibleErrorNames.REQUIRED;

	@Input() public theme : ExtractFromUnion<'danger' | 'warning' | 'info' | 'secondary' | 'light', PThemeEnum> = enumsObject.PThemeEnum.DANGER;

	/**
	 * The FormComponent to be validated
	 * Alternatively you can set a errorValue
	 */
	@Input() private control : AbstractControl | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private isInvalid : boolean = false;

	/**
	 * Should validation be initially visible.
	 * If true, it will become visible after the user touched the form control.
	 */
	@Input() private checkTouched : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private touched : boolean | null = null;

	constructor(
		private console : LogService,
		public validationHintService : ValidationHintService,
	) {
	}

	/**
	 * Is this error visible?
	 */
	public get visible() : boolean {
		let invalid = false;
		if (this.control) {
			// NOTE: Angular has lowercase-error-keys. We should stay as close to angular implementation as possible.
			if ((this.validationName as string) !== this.validationName.toLowerCase()) throw new Error(`Keys of form errors must be lowercase. Please fix ${this.validationName}`);
			invalid = this.control.hasError(this.validationName);
		} else {
			invalid = this.isInvalid;
		}
		if (!this.checkTouched) {
			return invalid;
		}

		let touched = true;
		if (this.touched !== null) {
			touched = this.touched;
		} else if (this.control) {
			touched = this.control.touched;
		}
		return invalid && touched;
	}

	/**
	 * Get the text to displayed inside this hint
	 */
	public get errorText() : string | null {
		let errorValue : PValidationErrorValue | null = null;

		if (this.text) {
			if (this.control) this.console.debug(`TODO: Remove text="${this.text}" and create new dedicated PPossibleErrorNames`);
			return this.text;
		}

		if (this.errorValue) errorValue = this.errorValue;

		if (this.control?.errors) errorValue = this.control.errors[this.validationName];

		let label : string | null = null;
		if (errorValue?.comparedAttributeName) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const comparedControl = (this.control?.parent?.controls as any)[errorValue.comparedAttributeName];
			label = comparedControl?.labelText;
		}

		// NOTE: Looking for a way to prioritize validation hints? -> Check PFormsService.visibleErrors()
		if (errorValue) return this.validationHintService.getErrorText(errorValue, label);
		return null;
	}
}
