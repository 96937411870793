import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { CssStyleClass, IconName, IconStyle } from '@fortawesome/fontawesome-common-types';
import { PTextColor } from '@plano/client/shared/bootstrap-styles.enum';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { PlanoFaIconPoolKeys, PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PTextAlignType } from '@plano/shared/p-forms/p-input/p-input.types';
import _ from 'underscore';
import { PFaIcon, PFaIconName } from './fa-icon-types';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'fa-icon',
	templateUrl: './fa-icon.component.html',
	styleUrls: ['./fa-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class FaIconComponent implements PComponentInterface {

	/**
	 * When set to true, the component will have a fixed width instead of one that orientates on the width of the icon.
	 * Note that some icons are wider, and some are vertically shorter.
	 * @default true
	 */
	@Input() private fixedWidth : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private textAlign : PTextAlignType | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('spin') private _spin : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private flip : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private size : 'xs' | 'sm' | 'lg' | 'xl' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | null = null;

	/** Should the icon be rotated? */
	@Input() private rotate : '90' | '180' | '270' | null = null;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;
	@Input() private theme : PTextColor | null = null;

	/**
	 * Should the icon be aligned with the bottom of the text?
	 */
	@Input() public verticalAlignTextBottom : boolean = false;

	/**
	 * Is the component being rendered as a list icon?
	 */
	@Input() public isListIcon : boolean = false;

	/**
	 * The icon to show in the UI.
	 * Can be null while the icon is loading.
	 */
	// TODO: Get rid of type PFaIcon here and use PlanoFaIconPoolValues only.
	@Input('icon') public _icon : PlanoFaIconPoolValues | PFaIcon | null = null;

	constructor(
		private console : LogService,
		public el : ElementRef<HTMLElement>,
	) {
	}

	private get spin() : boolean {
		if (this.isLoading) return false;
		return this._spin;
	}

	/**
	 * Fontawesome Style for this icon
	 * @default 'duotone' in most cases. 'solid' for some icons where 'duotone' did not look good.
	 */
	private get iconStyle() : IconStyle {
		// If the provided icons is an array, it contains the style information already.
		if (Array.isArray(this.icon)) return this.icon[0];

		switch (this.icon) {
			case 'minus':
			case 'hand-point-left':
			case 'wrench':
			case 'money-check':
				// TODO: PLANO-169525 remove exceptions. Use the icon pool everywhere.
				return 'solid';
			case 'czk-sign':
			case 'kr':
			case 'RON':
				return 'solid';
			default:
				return 'duotone';
		}
	}

	private get icon() : FaIconComponent['_icon'] {
		if (this.isLoading) return 'circle';
		if (Config.DEBUG) {
			const keys = Object.keys(enumsObject.PlanoFaIconPool);
			const possibleEnumKey = keys.find(
				(x : string) => _.isEqual(enumsObject.PlanoFaIconPool[x as PlanoFaIconPoolKeys], this._icon),
			);

			if (!possibleEnumKey) {
				this.console.deprecated(`\`${this._icon}\` is missing in PlanoFaIconPool`);
			}
		}
		return this._icon;
	}

	private get isTextIcon() : boolean | null {
		if (this.isLoading) return null;
		if (this.icon === 'czk-sign') return true;
		if (this.icon === 'kr') return true;
		if (this.icon === 'RON') return true;
		return false;
	}

	/**
	 * Some icons are not available in FontAwesome, like Kč for Czech koruna.
	 * In these cases we show simple ascii chars.
	 */
	public get iconText() : string | undefined {
		if (this.isLoading) return undefined;
		if (this.icon === 'czk-sign') return 'Kč';
		if (this.icon === 'kr') return 'kr';
		if (this.icon === 'RON') return 'RON';
		return undefined;
	}

	/**
	 * A list of fontawesome classes. Generated by component properties.
	 */
	public get iconClasses() : string[] {
		const result = [];

		if (this.isLoading) result.push('text-skeleton-animated');

		if (this.theme !== null) result.push(`text-${this.theme}`);

		if (this.isTextIcon === true) result.push(`fa-text-icon`);

		if (this.fixedWidth && !this.isTextIcon) result.push('fa-fw');
		if (this.textAlign) result.push(`text-${this.textAlign}`);
		if (this.spin) result.push('fa-spin');

		if (this.size) result.push(`fa-${this.size}`);
		if (this.rotate) result.push(`fa-rotate-${this.rotate}`);

		if (this.flip) result.push('fa-flip-horizontal');

		if (this.isListIcon) {
			result.push('fa-li');
			result.push('text-primary');
		}

		result.push(this.styleClass);
		result.push(this.iconNameClass);

		return result;
	}

	private get iconNameClass() : `fa-${IconName | PFaIconName}`	{
		if (this.isLoading) return 'fa-refresh';
		if (Config.DEBUG && this.icon === null) this.console.error('Icon should not be null here');
		if (Array.isArray(this.icon)) return `fa-${this.icon[1]}`;
		return `fa-${this.icon!}`;
	}

	private get styleClass() : CssStyleClass {
		return `fa-${this.iconStyle}`;
	}
}
