import { Injectable } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { MeService, SchedulingApiPushTokenAction, SchedulingApiPushTokenType, SchedulingApiService } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';
import { assumeNonNull, assumeNotUndefined } from '@plano/shared/core/utils/null-type-utils';
import { initializeApp } from 'firebase/app';
import { Messaging, getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Config } from './config';
import { PCookieService } from './p-cookie.service';
import { PPushNotificationsServiceCookieKeyDataType } from './p-push-notifications.service.types';
import { LocalizePipe } from './pipe/localize.pipe';
import { enumsObject } from './utils/the-enum-object';

/**
 * Typing for the browser permission state.
 * See https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus/state
 */
enum PermissionState {
	GRANTED = 'granted',
	DENIED = 'denied',
	PROMPT = 'prompt',
}

/**
 * Push token of this device. "null" there is no permission to send push notifications to this device.
 */
let currentPushToken : string | null = null;
let browserPermission : PermissionState | undefined = undefined;

// Create here all handlers which should start independent of the service creation.
let serviceInstance : PPushNotificationsService | null = null;

if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).nsWebViewInterface.on('pushToken', (data : any) => {
		currentPushToken = data.pushToken;

		if (serviceInstance)
			serviceInstance.performQueuedPushTokenAction();
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).nsWebViewInterface.emit('pushNotificationServiceReady');
}

const permissions = window.navigator.permissions as Permissions | undefined;
if (permissions) {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const options : PermissionDescriptor = {
		name: 'push',
		userVisibleOnly : true,

	// This type cast is necessary because userVisibleOnly is not supported by every browser and therefore not part of
	// the PermissionDescriptor type.
	} as PermissionDescriptor;
	permissions.query(options)
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		.then((permissionStatus : any) => {
			browserPermission = permissionStatus.state;
		}).catch((error) => {
			throw new Error(`Failed to query permission state: ${error}`);
		});
} else {
	// permissions is not supported on all browsers (e.g. app webview browser and safari).
	// See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/permissions
	// For these browsers no web push-notification is supported. Safari doesn't support Push Api at all
	// And for apps we instead use native app notifications.
	browserPermission = PermissionState.DENIED;
}

/**
 * Contexts in which the user can request web push notification permission.
 */
export enum PRequestWebPushNotificationPermissionContext {
	USER_REQUEST
	,	SHIFT_EXCHANGE_CREATED
	,	ILLNESS_ACCEPTED_WITH_SHIFT_EXCHANGE
	,	MANAGER_STARTED_ASKING_MEMBER_PREFERENCES
	,	MANAGER_STARTED_EARLY_BIRD_SCHEDULING
	,	CLOSED_UI_WISH_PICKER_MODE
	,	CLOSED_UI_EARLY_BIRD_MODE
	,	STAMPED_PAST_SHIFT
	,	ONLINE_INQUIRY_SHIFT_CREATED,
}

/**
 * Service handling push notifications.
 */
@Injectable({ providedIn: 'root' })
export class PPushNotificationsService {
	constructor(
		private me : MeService,
		private pCookieService : PCookieService,
		public toasts : ToastsService,
		private localize : LocalizePipe,
		private console : LogService,
	) {
		void this.init();
	}

	/**
	 * The firebase messaging object to access firebase push notification functionality.
	 */
	private messaging ! : Messaging;

	/**
	 * The service-worker responsible to show web push-notifications.
	 * Can be null if it could not be initialized.
	 */
	private serviceWorker : ServiceWorkerRegistration | null = null;

	/**
	 * Vapid key is the public key to authenticate push-notifications from backend.
	 * See https://firebase.google.com/docs/cloud-messaging/js/client
	 */
	private VAPID_KEY = 'BIeEcHwkx1_XLnFA0uVXe2PqfRTzGI7VznEjBJmP7y3ae2a9VNdp9jIsC5wt2kesrVX8hCtYDZhdhTffrjwKL2s'; // cspell:disable-line

	private async init() : Promise<void> {
		// eslint-disable-next-line unicorn/no-this-assignment, @typescript-eslint/no-this-alias
		serviceInstance = this;

		// login/logout handlers. Note, that for app these will create/remove the push-token
		// from backend. On the other hand, on browser this will enable/disable the push-token on backend.
		// I.e. if the push-token is not available yet in backend this will have no effect.
		this.me.afterLogin.subscribe((loggedInFromLoginForm : boolean) => {
			if (loggedInFromLoginForm) {
				this.queuePushTokenAction(Config.platform === 'browser' ? 	SchedulingApiPushTokenAction.ENABLE	:
					SchedulingApiPushTokenAction.CREATE);
			}

			this.performQueuedPushTokenAction();
		});

		//
		// Web Push Token
		//
		if (Config.WEB_PUSH_NOTIFICATIONS_ENABLED) {
			initializeApp({
				apiKey: 'AIzaSyD_KJMCAxuPYrN9QeKu7ANJbUOv8ua04kE', // cspell:disable-line
				authDomain: 'dr-plano.firebaseapp.com', // cspell:disable-line
				databaseURL: 'https://dr-plano.firebaseio.com',
				projectId: 'dr-plano',
				storageBucket: 'dr-plano.appspot.com', // cspell:disable-line
				messagingSenderId: '507269381082',
				appId: '1:507269381082:web:51de47b5a59daeb0',
			});

			this.messaging = getMessaging();

			// register service worker
			const countryCode = Config.getCountryCode();
			let serviceWorkerRelativePath = countryCode ? `/${countryCode.toLowerCase()}` : '';
			serviceWorkerRelativePath += '/firebase-messaging-sw.js';

			try {
				this.serviceWorker = await window.navigator.serviceWorker.register(serviceWorkerRelativePath);
			} catch (error) {
				// We don’t know why this sometimes fails.
				// But till now we never got complains from users. So in this case we should not block users from working,
				// and just stop the initialization here.
				// Related Sentry entry: PRODUCTION-4ZZ
				this.console.error(error);
				return;
			}

			// get web push token
			if (browserPermission === PermissionState.GRANTED) {
				getToken(this.messaging, {
					vapidKey: this.VAPID_KEY,
					serviceWorkerRegistration: this.serviceWorker,
				}).then((token) => {
					currentPushToken = token;
					this.performQueuedPushTokenAction();
				}).catch(() => {
					currentPushToken = null;
				});
			}

			// listen for web push notifications when application is in foreground.
			onMessage(this.messaging, (payload : {
				data ?: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					click_action ?: string,
				},
				notification ?: {
					title ?: string,
					body ?: string,
					icon ?: string,
				}
			}) => {
				assumeNotUndefined(payload.notification);

				// In this case, the web-worker does not show a notification automatically.
				// So, we show it manually.
				const notification = new Notification(
					payload.notification.title!,
					{
						body: payload.notification.body,
						icon: payload.notification.icon,
					},
				);

				const clickAction = payload.data?.click_action;
				if (clickAction) {
					notification.addEventListener('click', event => {
						event.preventDefault(); // prevent the browser from focusing the Notification's tab
						window.open(clickAction, '_blank');
						notification.close();
					});
				}
			});

		}
	}

	/**
	 * See setApi() for details.
	 */
	private api : SchedulingApiService | null = null;

	/**
	 * HACK: We must ensure that PPushNotificationService is instantiated is available when app starts
	 * (to listen to me.afterLogin events).
	 * So, we inject this service in AppComponent. But there, SchedulingApiService is not available.
	 * So, we do a hack and set this later in ClientComponent, when it is available.
	 * Some better solution should be found.
	 */
	public setApi(api : SchedulingApiService) : void {
		this.api = api;
		this.performQueuedPushTokenAction();
	}

	/**
	 * This will request push-notification permission from the browser.
	 */
	private async requestWebPushNotificationPermissionFromBrowser(
		serviceWorker : ServiceWorkerRegistration,
	) : Promise<void> {
		const permission = await Notification.requestPermission();

		if (permission === 'granted') {
			const pushToken = await getToken(this.messaging, {
				vapidKey: this.VAPID_KEY,
				serviceWorkerRegistration: serviceWorker,
			});
			currentPushToken = pushToken;
			void this.queuePushTokenAction(SchedulingApiPushTokenAction.CREATE);
		} else {
			browserPermission = PermissionState.DENIED;
			currentPushToken = null;

			this.toasts.addToast(
				{
					content: this.localize.transform('Schade. Falls du dich doch dafür entscheidest, gib uns bei deinem Browser das Recht, dir Benachrichtigungen zu schicken.'),
					theme: enumsObject.PThemeEnum.SECONDARY,
					visibilityDuration: 'long',
				},
			);
		}
	}

	private getCustomWebNotificationPermissionDialogText(
		context : PRequestWebPushNotificationPermissionContext,
	) : string {
		if (!context)
			throw new Error('You must provide a context value.');

		// get context description
		const contextDescMap = new Map<PRequestWebPushNotificationPermissionContext, string>([
			[
				PRequestWebPushNotificationPermissionContext.SHIFT_EXCHANGE_CREATED,
				this.localize.transform('Neuigkeiten zu deiner Ersatzsuche'),
			],
			[
				PRequestWebPushNotificationPermissionContext.ILLNESS_ACCEPTED_WITH_SHIFT_EXCHANGE,
				this.localize.transform('weitere Krankmeldungen'),
			],
			[
				PRequestWebPushNotificationPermissionContext.MANAGER_STARTED_ASKING_MEMBER_PREFERENCES,
				this.localize.transform('den Status der Schichtverteilung'),
			],
			[
				PRequestWebPushNotificationPermissionContext.MANAGER_STARTED_EARLY_BIRD_SCHEDULING,
				this.localize.transform('den Status der Schichtverteilung'),
			],
			[
				PRequestWebPushNotificationPermissionContext.CLOSED_UI_WISH_PICKER_MODE,
				this.localize.transform('den Status der Schichtverteilung'),
			],
			[
				PRequestWebPushNotificationPermissionContext.CLOSED_UI_EARLY_BIRD_MODE,
				this.localize.transform('den Status der Schichtverteilung'),
			],
			[
				PRequestWebPushNotificationPermissionContext.STAMPED_PAST_SHIFT,
				this.localize.transform('fällige Zeiterfassung'),
			],
			[
				PRequestWebPushNotificationPermissionContext.ONLINE_INQUIRY_SHIFT_CREATED,
				this.localize.transform('neue Buchungsanfragen'),
			],
		]);

		const contextDesc = contextDescMap.get(context);
		if (!contextDesc) throw new Error(`No context-desc could be found for value ${context}.`);

		// return text
		return this.localize.transform({
			sourceString: 'Um über ${context} und ähnliche wichtige Dinge informiert zu werden, empfehlen wir dir Push-Nachrichten für diesen Browser einzuschalten.',
			params: {context: contextDesc},
		});
	}

	/**
	 * Requests permission to send push notifications in browser.
	 * @param context Provide this when onUserRequest === false. Then this string describes the context
	 * 		in which the dialog is shown to construct an appropriate dialog text.
	 */
	public async requestWebPushNotificationPermission(context : PRequestWebPushNotificationPermissionContext) : Promise<void> {

		// In case the service-worker could not be registered for some reason, we just dont do browser-push related stuff.
		if (this.serviceWorker === null) return;

		// validation tests
		if (!this.me.isLoaded())
			throw new Error('Only request web push-notification permission when user is logged in.');

		// only state where we should ask for permission is 'not_receiving'
		if (this.thisDeviceState !== 'not_receiving')
			return;

		//
		// Execute
		//
		if (context === PRequestWebPushNotificationPermissionContext.USER_REQUEST) {
			// when user requested it directly show browser permission dialog
			await this.requestWebPushNotificationPermissionFromBrowser(this.serviceWorker);
			return;
		}

		// Show custom permission dialog.
		// But only once (until user answers it).
		const userAnsweredCustomWebNotificationPermissionDialogCookieData : PPushNotificationsServiceCookieKeyDataType = { name: 'userAnsweredCustomWebNotificationPermissionDialog', prefix: null };

		if (this.pCookieService.get(userAnsweredCustomWebNotificationPermissionDialogCookieData) === 'true') return;

		const DELAY = 3000;
		window.setTimeout(() => {
			// ok now we ask for permission using our custom dialog.
			// This is to avoid that the user denies permission on browser level.
			this.toasts.addToast({
				title: this.localize.transform('Browser-Benachrichtigungen'),
				content: this.getCustomWebNotificationPermissionDialogText(context),
				icon: enumsObject.PlanoFaIconPool.PUSH_NOTIFICATION,
				theme: enumsObject.PThemeEnum.PRIMARY,
				visibilityDuration: 'infinite',
				visibleOnMobile: true,
				closeBtnLabel: this.localize.transform('Ja, bitte'),
				dismissBtnLabel: this.localize.transform('Nein, danke'),
				close: () => {
					assumeNonNull(this.serviceWorker);

					// User has accepted our own custom permission dialog.
					// Now show browser permission dialog.
					void this.requestWebPushNotificationPermissionFromBrowser(this.serviceWorker);
					this.pCookieService.put(userAnsweredCustomWebNotificationPermissionDialogCookieData, 'true');
					this.toasts.addToast({
						content: this.localize.transform('Einstellung wurde gespeichert'),
						theme: enumsObject.PThemeEnum.SUCCESS,
						visibilityDuration: 'short',
					});
				},
				dismiss: () => {
					this.pCookieService.put(userAnsweredCustomWebNotificationPermissionDialogCookieData, 'true');
					this.toasts.addToast({
						// eslint-disable-next-line literal-blacklist/literal-blacklist
						content: this.localize.transform('Falls du dich doch dafür entscheidest, kannst du die Nachrichten unter <a class="nowrap" href="client/notifications">Benachrichtigungs-Einstellungen</a> einschalten.'),
						theme: enumsObject.PThemeEnum.SECONDARY,
						visibilityDuration: 'long',
					});
				},
			});
		}, DELAY);
	}

	/**
	 * The current push-notification state of this device. Note, that this can also be "undefined" when
	 * the state could not be determined yet.
	 */
	public get thisDeviceState() : 'receiving' | 'blocked_in_browser' | 'not_receiving' | undefined {
		// blocked in browser?
		if (browserPermission === PermissionState.DENIED || !Config.WEB_PUSH_NOTIFICATIONS_ENABLED)
			return 'blocked_in_browser';

		// cant determine value yet?
		if (browserPermission === undefined) {
			return undefined;
		}

		// is current push token in backend?
		const storedInBackend = this.currentPushTokenIsStoredInBackend;
		if (storedInBackend === undefined)
			return undefined;

		return storedInBackend ? 'receiving' : 'not_receiving';
	}

	/**
	 * Is the current push-token stored in backend?
	 * Can be undefined if this can currently not be determined.
	 */
	private get currentPushTokenIsStoredInBackend() : boolean | undefined {
		if (!this.api!.isLoaded())
			return undefined;

		if (currentPushToken === null)
			return false;

		for (const pushToken of this.api!.data.notificationSettings.pushTokens.iterable()) {
			if (pushToken.token === currentPushToken) {
				return true;
			}
		}

		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public unregisterThisDeviceFromPushNotifications() : void {
		this.queuePushTokenAction(SchedulingApiPushTokenAction.REMOVE);
	}

	private get pushTokenType() : SchedulingApiPushTokenType {
		switch (Config.platform) {
			case 'browser':
				return SchedulingApiPushTokenType.WEB;

			case 'appAndroid':
				return SchedulingApiPushTokenType.ANDROID;

			case 'appIOS':
				return SchedulingApiPushTokenType.IOS;

			default:
				throw new Error('Unsupported case');
		}
	}

	private queuedAction : SchedulingApiPushTokenAction | undefined;

	/**
	 * performing an action has some requirements (currentPushToken, me.isLoaded(), api).
	 * So, we queue any desired actions. When all requirements are met the action will be performed.
	 */
	private queuePushTokenAction(action : SchedulingApiPushTokenAction) : void {
		// these are write-operations. So, we need to skip this when logged in with the read-only master password
		if (this.me.data.loggedInWithMasterPasswordReadOnly)
			return;

		// queue action
		this.queuedAction = action;
		this.performQueuedPushTokenAction();
	}

	/**
	 * Perform the queuedAction if there is any.
	 * Also checks if the meService is loaded, as well as setting empty data to the api if
	 * it is not loaded yet.
	 */
	public performQueuedPushTokenAction() : void {
		// nothing queued?
		if (!this.queuedAction)
			return;

		// requirements available?
		if (!this.me.isLoaded() || !currentPushToken || !this.api)
			return;

		// To be more efficient, this action does not require that api is loaded.
		// If it is not loaded we just set some empty data. So, because we
		// should not need to load api all actions are done by calling "createNewItem()".
		// Internally backend will not create a new item when the push-token already exists.
		if (!this.api.isLoaded())
			this.api.setEmptyData();

		const pushToken = this.api.data.notificationSettings.pushTokens.createNewItem();
		pushToken.token = currentPushToken;
		pushToken.type = this.pushTokenType;
		pushToken.action = this.queuedAction;

		// Because we don’t know when save is executed and if all data are valid
		// only save this change
		this.api.save(
			{
				onlySavePath: [
					this.api.consts.NOTIFICATION_SETTINGS,
					this.api.consts.NOTIFICATION_SETTINGS_PUSH_TOKENS,
				],
			},
		);

		// reset queue
		this.queuedAction = undefined;
	}

	/**
	 * Perform the required logout logic
	 */
	public logout() : void {
		const action = Config.platform === 'browser' ?
			SchedulingApiPushTokenAction.DISABLE :
			SchedulingApiPushTokenAction.REMOVE;

		this.queuePushTokenAction(action);
	}
}
