import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { PEditableModalButtonComponent } from '@plano/client/shared/p-editable/p-editable-modal-button/p-editable-modal-button.component';
import { SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';

type ValueType = Id | null;

@Component({
	selector: 'p-input-shiftmodel-id-modal[shiftModels]',
	templateUrl: './input-shiftmodel-id-modal.component.html',
	styleUrls: ['./input-shiftmodel-id-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputShiftModelIdModalComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputShiftModelIdModalComponent extends PAbstractControlComponentBaseDirective implements AfterContentInit, ControlValueAccessor {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('label') private _label : string | null = null;
	@HostBinding('class.d-flex')
	@HostBinding('class.btn-group') private _alwaysTrue = true;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shiftModels ! : SchedulingApiShiftModels;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public searchTerm : string | null = null;

	@Input() protected icon : FaIcon | null = null;

	@ViewChild('pEditableModalButtonRef', { static: true }) private pEditableModalButtonRef ! : PEditableModalButtonComponent;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,
		private localizePipe : LocalizePipe,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public enums = enumsObject;
	public PApiPrimitiveTypes = PApiPrimitiveTypes;

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initValues();
		this.selectedItemId = this.value;
		return super.ngAfterContentInit();
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		if (this._label === null) this._label = this.localizePipe.transform('Tätigkeit wählen …');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get valueItem() : SchedulingApiShiftModel | null {
		if (!this.value) return null;
		return this.shiftModels.get(this.value);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get label() : string | null {
		// if (this.value !== null) return this.shiftModelsForList.get(this.value).name;
		if (this.value !== null) return null;
		return this._label;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onItemClick(item : SchedulingApiShiftModel | null) : void {
		if (item === null) {
			this.selectedItemId = null;
			return;
		}
		this.selectedItemId = item.id;
		if (!this.pEditableModalButtonRef.pEditable) {
			this.pEditableModalButtonRef.modalRef?.close();
		}
	}

	protected selectedItemId : ValueType = null;
	private initialValue : ValueType = null;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onModalClosed() : void {
		this.value = this.selectedItemId;
		this.resetValues();
	}

	/** Handle modal dismiss */
	public onModalDismiss() : void {
		this.value = this.initialValue;
		this.resetValues();
	}

	/** Handle modal open */
	protected onModalOpen() : void {
		this.storeInitialValue();
		this.selectedItemId = this.value;
	}

	/** Store the initial value, so it can be reset on dismiss. */
	protected storeInitialValue() : void {
		this.initialValue = this.value;
	}

	/** Reset all values that should not live longer than the modal exists. */
	private resetValues() : void {
		this.searchTerm = null;
		this.selectedItemId = this.initialValue;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public isSelected(item : SchedulingApiShiftModel) : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.selectedItemId, 'selectedItemId');
		return this.selectedItemId.equals(item.id);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shiftModelsForList() : SchedulingApiShiftModels {
		return this.shiftModels.search(this.searchTerm);
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}
}
