import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { MeService, PSimpleChanges, SchedulingApiAbsence, SchedulingApiAbsenceType, SchedulingApiMember, SchedulingApiService } from '@plano/shared/api';
import { Image } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { PMemberBadgeService } from './p-member-badge.service';

@Component({
	selector: 'p-member-badge',
	templateUrl: './member-badge.component.html',
	styleUrls: ['./member-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class MemberBadgeComponent implements AfterContentChecked, OnChanges {

	/**
	 * Title will be used instead of member name if defined
	 */
	@Input('text') public _text : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public member : SchedulingApiMember | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('borderStyle') private _borderStyle : PThemeEnum | undefined = undefined;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private absenceType : 'trashed' | SchedulingApiAbsenceType.ILLNESS | SchedulingApiAbsenceType.VACATION | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('icon') private _icon : MemberBadgeComponent['icon'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('iconStyle') private _iconStyle : PThemeEnum | undefined = undefined;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public overlayTemplate : TemplateRef<unknown> | null = null;

	// TODO: use BootstrapSize here
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : typeof enumsObject.BootstrapSize.SM | typeof enumsObject.BootstrapSize.MD | typeof enumsObject.BootstrapSize.LG | typeof enumsObject.BootstrapSize.XL | 'small' | 'normal' = enumsObject.BootstrapSize.SM;

	@HostBinding('class.shadow')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shadow : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('firstName') private _firstName ?: string;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('lastName') private _lastName ?: string;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('memberId') public _memberId : Id | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('avatar') public _avatar : Image | null = null;

	/**
	 * Should the dr-plano icon be shown as avatar when user is logged in with our internal master password?
	 */
	@Input() public showPIconWhenLoggedInWithMasterPassword = false;

	@HostBinding('class.is-me')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isMe : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public alwaysShowMemberInitials : boolean | null = null;

	@HostBinding('class.small') private get sizeSM() : boolean { return this.size === enumsObject.BootstrapSize.SM || this.size === 'small'; }
	@HostBinding('class.normal') private get sizeMD() : boolean { return this.size === enumsObject.BootstrapSize.MD || this.size === 'normal';}
	@HostBinding('class.large') private get sizeLG() : boolean { return this.size === enumsObject.BootstrapSize.LG; }
	@HostBinding('class.xl') private get sizeXL() : boolean { return this.size === enumsObject.BootstrapSize.XL;}
	@HostBinding('class.badge')
	@HostBinding('class.badge-pill')
	@HostBinding('class.badge-info') protected _alwaysTrue = true;

	@HostBinding('class.shadow-success') private get borderSuccess() : boolean { return this.borderStyle === enumsObject.PThemeEnum.SUCCESS; }
	@HostBinding('class.shadow-primary') private get borderPrimary() : boolean { return this.borderStyle === enumsObject.PThemeEnum.PRIMARY; }
	@HostBinding('class.shadow-danger') private get borderDanger() : boolean { return this.borderStyle === enumsObject.PThemeEnum.DANGER; }
	@HostBinding('class.shadow-warning') private get borderWarning() : boolean { return this.borderStyle === enumsObject.PThemeEnum.WARNING; }
	@HostBinding('class.bg-primary') private get bgPrimary() : boolean { return this.backgroundStyle === enumsObject.PThemeEnum.PRIMARY; }
	@HostBinding('class.bg-light') private get bgLight() : boolean { return this.backgroundStyle === enumsObject.PThemeEnum.LIGHT; }
	@HostBinding('class.text-success') private get textSuccess() : boolean { return this.borderStyle === enumsObject.PThemeEnum.SUCCESS; }
	@HostBinding('class.text-white') private get textWhite() : boolean { return this.backgroundStyle === enumsObject.PThemeEnum.PRIMARY; }

	/**
	 * Emit if something went wrong while downloading the image.
	 * This is useful so we can show the initials of members instead of the
	 * alt text of the image tag if some error happens.
	 */
	@Output() public onAvatarImgError = new EventEmitter<Event>();

	@HostBinding('class.is-empty')
	private get isEmpty() : boolean {
		if (this.badgeIsLoading && !this.memberName && !this.avatar) {
			this.badgeIsLoading = false;
			this._isEmpty = true;
			this.badgeTextCache = this._text;
			this.cdRef.detectChanges();
			return true;
		}
		return this._isEmpty;
	}

	@HostBinding('title')
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get memberName() : string {
		let result = '';
		if (this.firstName) result += `${this.firstName}`;
		if (this.lastName) result += ` ${this.lastName}`;
		return result;
	}

	@HostBinding('class.d-none')
	private badgeIsHidden : boolean = false;

	/**
	 * Is the badge loading?
	 */
	@HostBinding('class.skeleton-animated')
	public get badgeIsLoading() : boolean {
		return this._badgeIsLoading;
	}
	public set badgeIsLoading(input : boolean) {
		this._badgeIsLoading = input;
	}

	@HostBinding('class.is-trashed')
	private get trashed() : boolean | null {
		if (this.absenceType === 'trashed') return true;
		if (!this.member) {
			if (this.memberId) {
				if (!this.api.data.attributeInfoMembers.isAvailable) return null;
				const member = this.api.data.members.get(this.memberId);
				// the member can be null if the response for the first load as not arrived yet when this is called
				if (member === null) {
					const subscriptionForLoad = this.api.onDataLoaded.subscribe(() => {
						this.badgeIsLoading = true;
						this.cdRef.detectChanges();
						subscriptionForLoad.unsubscribe();
					});
				} else {
					return member.trashed;
				}
			}
			return null;
		}
		this.debugHint();
		return this.member.attributeInfoTrashed.isAvailable ? this.member.trashed : null;
	}

	/**
	 * Get the avatar for the badge
	 */
	@HostBinding('class.has-avatar')
	public get avatar() : Image | null {
		if (this.showPIconWhenLoggedInWithMasterPassword && this.me.data.loggedInWithMasterPassword)
			return 'images/plano-logo/p-with-dark-background.png';

		if (this.avatarImgHasErrors) {
			this.badgeIsLoading = false;
			return null;
		}
		if (this._avatar) {
			this.badgeIsLoading = false;
			return this._avatar;
		} else if (this.member?.avatar) {
			this.badgeIsLoading = false;
			return this.member.avatar;
		} else if (this.memberId && this.api.data.attributeInfoMembers.isAvailable) {
			const member = this.api.data.members.get(this.memberId);
			if (member?.attributeInfoAvatar.isAvailable) {
				if (member.avatar)
					this.badgeIsLoading = false;
				return member.avatar;
			}
		}
		return null;
	}

	/**
	 * Are the initials shown for this member-badge?
	 */
	@HostBinding('class.has-initials')
	private get _hasInitials() : boolean {
		return !this.avatar && !!this.badgeText;
	}

	@HostBinding('class.has-icon')
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get icon() : SchedulingApiAbsence['typeIconName'] | typeof enumsObject.PlanoFaIconPool.TRASHED | typeof enumsObject.PlanoFaIconPool.SUCCESS | undefined {
		if (this._icon) return this._icon;
		if (this.trashed) return enumsObject.PlanoFaIconPool.TRASHED;
		switch (this.absenceType) {
			case 'trashed':
				return enumsObject.PlanoFaIconPool.TRASHED;
			case SchedulingApiAbsenceType.ILLNESS:
				return enumsObject.PlanoFaIconPool.ITEMS_ABSENCE_ILLNESS;
			case SchedulingApiAbsenceType.VACATION:
				return enumsObject.PlanoFaIconPool.ITEMS_ABSENCE_VACATION;
			case null:
				return undefined;
		}
	}

	constructor(
		private pMemberBadgeService : PMemberBadgeService,
		private console : LogService,
		public elementRef : ElementRef<HTMLElement>,
		private me : MeService,

		private cdRef : ChangeDetectorRef,
		private api : SchedulingApiService,
	) {

	}

	public ngOnChanges(changes : PSimpleChanges<MemberBadgeComponent>) : void {
		if (changes._text && !this.badgeIsLoading) {
			this.badgeTextCache = changes._text.currentValue;
		}

		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		if (changes._avatar && !changes._avatar.isFirstChange() ||
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
			changes.member && !changes.member.isFirstChange() && !changes.member.previousValue?.id.equals(changes.member.currentValue?.id ?? null) ||
			changes._memberId && !changes._memberId.isFirstChange() && !changes._memberId.previousValue?.equals(changes._memberId.currentValue ?? null) ) {
			this.badgeIsLoading = true;
		}
	}

	private _isEmpty : boolean = false;

	public enums = enumsObject;

	/** ngAfterContentChecked */
	public ngAfterContentChecked() : void {
		this.initValues();
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		if (this.absenceType !== null && this._icon !== null) {
			if (JSON.stringify(this._icon) === JSON.stringify(enumsObject.PlanoFaIconPool.TRASHED)) {
				this.console.error('Remove [icon] here. Set absenceType »trashed«.');
				return;
			}
			this.console.error('You already set absenceType. Icon is most likely unnecessary.');
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get backgroundStyle() : ExtractFromUnion<'primary' | 'light', PThemeEnum> | null {
		if (this.isMe) return enumsObject.PThemeEnum.PRIMARY;
		if (this._borderStyle === enumsObject.PThemeEnum.SUCCESS) return enumsObject.PThemeEnum.LIGHT;
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get borderStyle() : PThemeEnum | undefined {

		// don’t set the border style here. Instead use styles from .is-me
		if (this.isMe) return undefined;

		if (this._borderStyle) return this._borderStyle;
		if (this.trashed) return enumsObject.PThemeEnum.DANGER;
		switch (this.absenceType) {
			case 'trashed':
			case SchedulingApiAbsenceType.ILLNESS:
			case SchedulingApiAbsenceType.VACATION:
				return enumsObject.PThemeEnum.DANGER;
			case null:
				return undefined;
		}
	}

	/**
	 * If the avatar loaded successfully set badgeIsLoading to false
	 */
	public avatarImgLoaded() : void {
		this.avatarImgHasErrors = false;
		if (this.badgeIsLoading) {
			this.badgeIsLoading = false;
			this.cdRef.detectChanges();
		}
	}

	public avatarImgHasErrors : boolean = false;

	/**
	 * If for some reason the img couldn't be loaded set the avatar to null and emit
	 * a imgError event
	 */
	public avatarImgError(event : Event) : void {
		this.avatarImgHasErrors = true;
		if (this.badgeIsLoading) {
			this.badgeIsLoading = false;
			this.cdRef.detectChanges();
		}
		this.onAvatarImgError.emit(event);
	}

	private _badgeIsLoading : boolean = true;

	private badgeTextCache : string | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get badgeText() : string | null {
		if (this.badgeIsLoading) {
			let newBadgeText : string;
			if (this.icon && !this.alwaysShowMemberInitials) {
				this.badgeIsLoading = false;
				return null;
			}
			if (this._text !== null) {
				newBadgeText = this._text;
			}
			if (this.api.data.attributeInfoMembers.isAvailable && this.api.data.members.length === 0) return null;
			const initials = this.memberInitials;
			if (!initials) {
				this.api.isLoaded(() => {
					if (!this.memberInitials) {
						this.console.error('No member initials could be loaded here!');
					} else {
						newBadgeText = this.memberInitials;
						this.badgeTextCache = newBadgeText;
						this.badgeIsLoading = false;
						this.cdRef.detectChanges();
					}
				});
			} else {
				newBadgeText = initials;
				this.badgeIsLoading = false;
				if (newBadgeText !== this.badgeTextCache) {
					this.badgeTextCache = newBadgeText;
				}
			}

		}
		return this.badgeTextCache;
	}

	private debugHint() : void {
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		this.console.warn('IMPROVE: Add [memberId]="member.id" [firstName]="member.firstName" [lastName]="member.lastName" [absenceType]="\'trashed\'" to make this a dump component.');
	}

	private get firstName() : string | null {
		if (this._firstName !== undefined) return this._firstName;
		if (!this.member) return null;
		this.debugHint();
		return this.member.firstName;
	}

	private get memberId() : Id | null {
		if (this._memberId !== null) return this._memberId;
		if (!this.member) return null;
		this.debugHint();
		return this.member.id;
	}

	private get lastName() : string | null {
		if (this._lastName !== undefined) return this._lastName;
		if (!this.member) return null;
		this.debugHint();
		return this.member.lastName;
	}

	private get memberInitials() : string | null {
		return this.pMemberBadgeService.getInitials(
			{
				id: this.memberId,
				firstName: this.firstName,
				lastName: this.lastName,
				trashed: this.trashed,
			},
			this.api.isLoaded() ? this.api.data.members : null,
		);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get iconStyle() : PThemeEnum | undefined {
		if (this._iconStyle) return this._iconStyle;
		return this.borderStyle;
	}

	private pixelToNumber(styleInPixels : string) : number {
		return +styleInPixels.slice(0, styleInPixels.indexOf('px'));
	}

	/**
	 * Badge width in the DOM (including margin)
	 */
	public get badgeNativeWidth() : number {
		const nativeElement = this.elementRef.nativeElement;
		return nativeElement.offsetWidth +
			this.pixelToNumber(window.getComputedStyle(this.elementRef.nativeElement).margin) * 2;
	}
}
