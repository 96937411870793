import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-no-item',
	templateUrl: './p-no-item.component.html',
	styleUrls: ['./p-no-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PNoItemComponent {
	@HostBinding('class.card')
	@HostBinding('class.text-muted')
	@HostBinding('class.text-center')
	private _alwaysTrue : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : typeof enumsObject.BootstrapSize.SM | null = null;

	public enums = enumsObject;
}
