<ng-template [ngIf]="isLoaded">
	<ng-template
		[ngIf]="showAsList"
		[ngIfElse]="timelineItem"
	>
		<!-- HACK: we need *ngIf="shift.rawData" here because when one navigates fast through calendar, i get errors like: Cannot read 'name' of undefined -->
		<p-shift-item-list
			*ngIf="shift && !!shift.rawData"
			class="hover-hide-trashed"

			[isLoading]="isLoading"
			[readMode]="readMode"
			[shift]="shift"
			[assignedMembers]="assignedMembers"
			[muteItem]="muteItem"
			[isInThePast]="isInThePast"

			[shiftIsSelectable]="shiftIsSelectable"
			[selectable]="selectable"

			[process]="process"
			(onClickRemove)="removeShiftItem($event)"
			[meIsAssignable]="meIsAssignable"
			[showAssignMeButton]="showAssignMeButton"
			[showMultiSelectCheckbox]="showMultiSelectCheckbox"
			[showProcessStatusIcon]="showProcessStatusIcon"
			[showCourseInfo]="showCourseInfo"

			(onDismissShiftSelected)="onDismissShiftSelected.emit($event)"
			(selectedChange)="selectedChange.emit($event)"
			(onClick)="onClick.observers.length ? onClick.emit($event) : undefined"
			[viewStyle]="viewStyle"
			[processStatusIconTemplate]="processStatusIconTemplate"
			[memberBadgesTemplate]="memberBadgesTemplate"
			[quickAssignmentTemplate]="quickAssignmentTemplate"
			[shiftExchangeIconsTemplate]="shiftExchangeIconsTemplate"
			[linkedCourseInfoTemplate]="linkedCourseInfoTemplate"
		></p-shift-item-list>
	</ng-template>
	<ng-template #timelineItem>
		<!-- HACK: we need *ngIf="shift.rawData" here because when one navigates fast through calendar, i get errors like: -->
		<!-- Cannot read 'name' of undefined -->
		<p-shift-item-timeline
			class="h-100"
			*ngIf="shift && shift.rawData && layout.getLayout(shift).show"

			[isLoading]="isLoading"
			[readMode]="readMode"
			[shift]="shift"
			[assignedMembers]="assignedMembers"
			[muteItem]="muteItem"
			[isInThePast]="isInThePast"

			[shiftIsSelectable]="shiftIsSelectable"
			[selectable]="selectable"

			[process]="process"
			(onClickRemove)="removeShiftItem($event)"
			[meIsAssignable]="meIsAssignable"
			[showAssignMeButton]="showAssignMeButton"
			[showMultiSelectCheckbox]="showMultiSelectCheckbox"
			[showProcessStatusIcon]="showProcessStatusIcon"
			[showCourseInfo]="showCourseInfo"

			(selectedChange)="selectedChange.emit($event)"
			[processStatusIconTemplate]="processStatusIconTemplate"
			[memberBadgesTemplate]="memberBadgesTemplate"
			[quickAssignmentTemplate]="quickAssignmentTemplate"
			[shiftExchangeIconsTemplate]="shiftExchangeIconsTemplate"
			[linkedCourseInfoTemplate]="linkedCourseInfoTemplate"
		></p-shift-item-timeline>
	</ng-template>

	<ng-template #processStatusIconTemplate>
		<p-assignment-process-icon
			*ngIf="showProcessStatusIcon && process"
			class="small p-1 pr-0 align-items-center d-flex"
			[class.text-muted]="true"
			[process]="process"
			[state]="process.state !== states.NEEDING_APPROVAL ? process.state : states.APPROVE"
			[isOwner]="!!rightsService.isOwner"
		></p-assignment-process-icon>
	</ng-template>

	<ng-template #memberBadgesTemplate>
		<p-member-badges
			*ngIf="shift"
			class="mx-1"
			[members]="shift.assignedMembers"
			[emptyMemberSlots]="emptyMemberSlots"

			[shiftStart]="shift.start"
			[shiftEnd]="shift.end"
			[shiftId]="shift.id"

			[myId]="meService.isLoaded() ? meService.data.id : null"
		></p-member-badges>
	</ng-template>

	<ng-template #quickAssignmentTemplate>
		<ng-template [ngIf]="emptyMemberSlots > 0">
			<section>
				<p-quick-assignment
					*ngIf="shift"
					[size]="enums.BootstrapSize.SM"
					[readMode]="readMode"
					[shift]="shift"
				></p-quick-assignment>
			</section>
		</ng-template>
	</ng-template>

	<ng-template #shiftExchangeIconsTemplate>
		<div class="d-flex align-items-center text-muted small" *ngIf="showShiftExchangeIcon || showIllnessIcon">
			<fa-icon *ngIf="showShiftExchangeIcon" class="mx-1" icon="hands-helping" title="Ersatzsuche vorhanden" i18n-title></fa-icon>
			<fa-icon *ngIf="showIllnessIcon" class="mx-1" icon="briefcase-medical" title="Krankmeldung vorhanden" i18n-title></fa-icon>
		</div>
	</ng-template>

	<ng-template #linkedCourseInfoTemplate>
		<section *ngIf="shift && (shift.isCourse || shift.currentCourseParticipantCount)">
			<a
				[routerLink]="openShiftItemLink({shift: shift, openTab: ShiftAndShiftModelFormTabs.bookingsettings})"
				class="btn btn-sm btn-frameless w-100 d-flex justify-content-center"
				[class.btn-outline-secondary]="!readMode"
				(click)="!readMode ? clickOpenShiftItem($event) : undefined"
				title="Buchungsdetails anzeigen" i18n-title
			>
				<p-course-info
					[isCourse]="shift.isCourse"
					[isCourseCanceled]="shift.isCourseCanceled"
					[currentCourseParticipantCount]="shift.currentCourseParticipantCount"
					[minCourseParticipantCount]="shift.minCourseParticipantCount"
					[maxCourseParticipantCount]="shift.maxCourseParticipantCount"
					[courseType]="shift.model ? shift.model.courseType : undefined!"
					[isCourseOnline]="shift.isCourseOnline"
					[onlyWholeCourseBookable]="shift.model.onlyWholeCourseBookable"
				></p-course-info>
			</a>
		</section>
	</ng-template>
</ng-template>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		[showApplyToShiftModelCheckbox]="false"
		[shiftChangeSelector]="api.data.shiftChangeSelector"
		[close]="c"
		[dismiss]="d"
		[shift]="shift!"
		[shiftModel]="shift!.model"
		[defaultStart]="(shift ? shift.start : undefined)"
		[typeOfChange]="PTypeOfChange.DELETE"
		[shiftSelectedFromTooltip]="true"
		[members]="api.data.members"
	></p-change-selectors-modal>
</ng-template>
