<p-headline
	*ngIf="label && label.length && !isDescriptiveVisualGrid"
	[level]="headlineLevel"
	[headlineClasses]="headlineClasses"
	[class.text-danger]="hasDanger"
	[pContainer]="containerSize"
><div class="d-flex align-items-start justify-content-start"><fa-icon *ngIf="sectionIcon" [icon]="sectionIcon" class="mr-3 text-primary"/><span>{{ label }}</span></div></p-headline>
<div
	*ngIf="containerSize; else (isDescriptiveVisualGrid ? wrapContentTemplate : contentTemplate)"
	[pContainer]="containerSize"
	class="d-flex flex-column flex-grow-1 pt-0 position-relative"
	><ng-container *ngTemplateOutlet="(isDescriptiveVisualGrid ? wrapContentTemplate : contentTemplate)"></ng-container></div>
<ng-template #wrapContentTemplate>
	<p-grid justifyContent="center">
		<ng-container *ngTemplateOutlet="isDescriptiveLeft ? descriptiveTemplate : visualTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="isDescriptiveLeft ? visualTemplate : descriptiveTemplate"></ng-container>
	</p-grid>
	<ng-template #descriptiveTemplate>
		<div
			class="col-{{gridBreakpoint}}-{{gridDescriptiveSize}} order-{{descriptiveTemplateOrder}} order-{{gridBreakpoint}}-0 mb-{{descriptiveTemplateMarginBottom}} mb-{{gridBreakpoint}}-0"
		>
			<p-headline
				*ngIf="label && label.length"
				[level]="headlineLevel"
				[headlineClasses]="headlineClasses"
				[class.text-danger]="hasDanger"
			><div class="d-flex align-items-start justify-content-start"><fa-icon *ngIf="sectionIcon" [icon]="sectionIcon" class="mr-3 text-primary"/><span>{{ label }}</span></div></p-headline>
			<ng-content select="[descriptive]"></ng-content>
		</div>
	</ng-template>
	<ng-template #visualTemplate>
		<div
			class="col-{{gridBreakpoint}}-{{gridVisualSize}} order-{{visualTemplateOrder}} order-{{gridBreakpoint}}-0 mb-{{visualTemplateMarginBottom}} mb-{{gridBreakpoint}}-0 d-flex align-items-center justify-content-center"
		>
			<ng-content select="[visual]"></ng-content>
		</div>
	</ng-template>
</ng-template>

<ng-template #contentTemplate>
	<ng-content></ng-content>
</ng-template>
