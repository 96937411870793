/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "generate_gift_card_codes".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	COUNT = 1;
	GENERATOR_TYPE = 2;
}


@Injectable({
  providedIn: 'root',
})
export class GenerateGiftCardCodesApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'generate_gift_card_codes');
	}

	protected version() : string {
		return '8c7036de6b8133e955ae30d7cf9c4266,020f1baf98db1733e8a09eaed3b025da';
	}

	private dataWrapper = new GenerateGiftCardCodesApiRoot(this, );

	get data() : GenerateGiftCardCodesApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : GenerateGiftCardCodesApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new GenerateGiftCardCodesApiRoot(this, );
	}
}

		 
export class GenerateGiftCardCodesApiRoot extends ApiObjectWrapper<GenerateGiftCardCodesApiRoot>
{
	constructor(override readonly api : GenerateGiftCardCodesApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<GenerateGiftCardCodesApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, GenerateGiftCardCodesApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<GenerateGiftCardCodesApiRoot, GenerateGiftCardCodesApiRoot> = new ApiAttributeInfo<GenerateGiftCardCodesApiRoot, GenerateGiftCardCodesApiRoot>({
			apiObjWrapper: this as any as GenerateGiftCardCodesApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToSet: function(this : GenerateGiftCardCodesApiRoot) {
				return ((this.api!.rightsService.canReadAndWriteBookingSystemSettings));
			},
			canSetByBusinessLogic: function(this : GenerateGiftCardCodesApiRoot) {
				return (((
						this.api!.schedulingApi.data.giftCardSettings.isSaleEnabled &&
						// TODO: PLANO-174740 Remove this
						Config.platform === 'browser'
					)));
			},
			defaultValue: function(this : GenerateGiftCardCodesApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
			vars: {
				cannotSetHint: function(this : GenerateGiftCardCodesApiRoot) {
					
				if(!this.api!.schedulingApi.data.giftCardSettings.isSaleEnabled)
					return 'Der Code-Generator steht nur zur Verfügung, wenn du den <a href="client/plugin/gift-cards/settings">Gutscheinverkauf von Dr. Plano</a> nutzt.';
				else if (Config.platform !== 'browser')
					// TODO: PLANO-174740 Remove this
					return 'Nutze bitte einen Computer, um die Codes generieren und herunterladen zu können.';
				else
					return null;
			
				},
			},
		});
	attributeInfoCount : ApiAttributeInfo<GenerateGiftCardCodesApiRoot, Integer> = new ApiAttributeInfo<GenerateGiftCardCodesApiRoot, Integer>({
			apiObjWrapper: this as any as GenerateGiftCardCodesApiRoot,
			name: 'count',
			nodeName: 'COUNT',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : GenerateGiftCardCodesApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Integer, undefined);						return null;
					},
					() => {
return this.api!.validators.min(1, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.max(2500, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : GenerateGiftCardCodesApiRoot) {
				return [
				];
			}
			,
			defaultValue: function(this : GenerateGiftCardCodesApiRoot, _nodeId : string) {return 800;},
			rawDataIndex: 1,
		});
	attributeInfoGeneratorType : ApiAttributeInfo<GenerateGiftCardCodesApiRoot, GenerateGiftCardCodesApiGeneratorType> = new ApiAttributeInfo<GenerateGiftCardCodesApiRoot, GenerateGiftCardCodesApiGeneratorType>({
			apiObjWrapper: this as any as GenerateGiftCardCodesApiRoot,
			name: 'generatorType',
			nodeName: 'GENERATOR_TYPE',
			primitiveType: PApiPrimitiveTypes.Enum,
			validations: function(this : GenerateGiftCardCodesApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : GenerateGiftCardCodesApiRoot) {
				return [
				];
			}
			,
			defaultValue: function(this : GenerateGiftCardCodesApiRoot, _nodeId : string) {return GenerateGiftCardCodesApiGeneratorType.READY_TO_PRINT_PDF;},
			rawDataIndex: 2,
		});

	/**
     *  How many codes should be generated?
	 *
	 * @type {Integer}
     */
	get count() : Integer {
		this.getterDebugValidations(this.attributeInfoCount, false);
		return this.data[1];
	}

	set count(v : Integer) {
        this.setterImpl(1, v, 'count', false, null, null);
	}

	/**
     *  We offer two generator types. Easy solution: A already formatted PDF with codes and barcodes which can directly be printed on stickers. Or the custom solution: An Excel Export with the raw generated codes, which can be processed further as needed.
	 *
	 * @type {GenerateGiftCardCodesApiGeneratorType}
     */
	get generatorType() : GenerateGiftCardCodesApiGeneratorType {
		this.getterDebugValidations(this.attributeInfoGeneratorType, false);
		return this.data[2];
	}

	set generatorType(v : GenerateGiftCardCodesApiGeneratorType) {
        this.setterImpl(2, v, 'generatorType', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : GenerateGiftCardCodesApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

export enum GenerateGiftCardCodesApiGeneratorType {
	READY_TO_PRINT_PDF = 1,
	IMPORTABLE_EXCEL = 2,
}


