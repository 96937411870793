import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { ShiftId } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-subheader[routeId]',
	templateUrl: './p-subheader.component.html',
	styleUrls: ['./p-subheader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PSubheaderComponent {

	/** @see PNavBackAnchorComponent#forceNavBackLink */
	@Input() public forceNavBackLink : string | null = null;

	/**
     * Query params to be added to the navigation back
     */
	@Input() public queryParamsForNavBack : Params | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isNewItem ?: boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onNavBack : EventEmitter<undefined> = new EventEmitter<undefined>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public containerSize ?: typeof enumsObject.BootstrapSize.SM;

	/**
	 * route id to scroll to after navigation
	 */
	@Input() public routeId! : Id | ShiftId | null;

	public enums = enumsObject;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get text() : PDictionarySourceString {
		return this.isNewItem ? 'Verwerfen' : 'Zurück';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public navBack() : void {
		this.onNavBack.emit();
	}
}
