import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
	selector: 'p-list-item',
	templateUrl: './p-list-item.component.html',
	styleUrls: ['./p-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PListItemComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('class') private cssClasses : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private hideListItemStyle : boolean = false;

	@HostBinding('class.list-group-item') private get _hasListGroupItem() : boolean {
		return !this.hideListItemStyle;
	}

	@HostBinding('class.list-group-item-action')
	@HostBinding('class.clickable') private get _hasOnClickBinding() : boolean {
		return this.onClick.observers.length > 0;
	}

	@HostBinding('class.p-0') private get _hasP0() : boolean { return this.size === 'frameless'; }
	@HostBinding('class.p-1') private get _hasP1() : boolean { return this.size === 'small'; }
	@HostBinding('class.p-2') private get _hasP2() : boolean { return this.size === 'medium'; }
	@HostBinding('class.p-3') private get _hasP3() : boolean { return this.size === 'large'; }
	@HostBinding('role') private role = 'listitem';

	@HostBinding('class.align-items-stretch') private get _hasAlignItemsStretch() : boolean {
		if (!this.cssClasses) return false;
		if (this.cssClasses.includes('align-items-')) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private size : 'small' | 'medium' | 'large' | 'frameless' = 'medium';

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onClick = new EventEmitter<MouseEvent>();

	@HostListener('click', ['$event']) private _handleClick(event : MouseEvent) : void {
		this.onClick.emit(event);
	}

}

@Component({
	selector: 'p-list-item-append',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PListItemAppendAppendComponent {
	@HostBinding('class.p-list-item-append') private _alwaysTrue = true;
}
