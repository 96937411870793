import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, NgZone, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { EditableControlInterface, EditableTriggerFocussableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { ApiObjectWrapper, TimeStampApiShiftModel, TimeStampApiShiftModels } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

type ValueType = Id | null;

@Component({
	selector: 'p-input-shiftmodel-id[shiftModels]',
	templateUrl: './p-input-shiftmodel-id.component.html',
	styleUrls: ['./p-input-shiftmodel-id.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputShiftModelIdComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputShiftModelIdComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterContentInit, EditableControlInterface, PFormControlComponentInterface, AfterViewInit, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public readMode : PFormControlComponentInterface['readMode'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shiftModels ! : SchedulingApiShiftModels | TimeStampApiShiftModels;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('placeholder') private _placeholder : string | null = null;
	@ViewChild('inputRef') public inputRef ?: ElementRef<HTMLInputElement>;
	@Input() public icon : FaIcon = ['solid', 'search'];

	@HostBinding('attr.aria-label') private get ariaLabel() : string | null {
		return this.placeholder;
	}

	/**
	 * Should this typeahead be appended to the body?
	 * Useful to prevent cropping by modal footers
	 */
	@Input() public appendOnBody : boolean = false;

	/**
	 * How one item should look like.
	 */
	@Input() public itemTemplate : TemplateRef<ResultTemplateContext> | null = null;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public focusout = new EventEmitter<FocusEvent>();
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public focus = new EventEmitter<FocusEvent>();

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<Event> = new EventEmitter<Event>();

	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public blur = new EventEmitter<FocusEvent>();

	@ViewChild('instance') public instance ?: NgbTypeahead;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		private localizePipe : LocalizePipe,
		private zone : NgZone,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public enums = enumsObject;

	/** @see NgbTypeahead#popupClass */
	protected get popupClass() : string {
		return this.appendOnBody ? 'body-typeahead' : '';
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public changeEditMode(event : boolean) : void {
		this.editMode.emit(event);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get placeholder() : string {
		if (this._placeholder !== null) return this._placeholder;
		return this.localizePipe.transform('Wähle eine Tätigkeit…');
	}

	private mutationObserver : MutationObserver | null = null;

	public ngAfterViewInit() : void {
		if (this.appendOnBody) {
			this.mutationObserver = new MutationObserver(() => {
				const typeaheadPopup = document.querySelector<HTMLElement>('ngb-typeahead-window.body-typeahead');
				if (typeaheadPopup) {
					typeaheadPopup.style.maxWidth = `${this.inputRef?.nativeElement.offsetWidth}px`;
					typeaheadPopup.style.minWidth = `${this.inputRef?.nativeElement.offsetWidth}px`;
				}
			});
			this.mutationObserver.observe(document.body, {childList: true});
		}
	}

	public override ngOnDestroy() : 'TypeToEnsureLifecycleHooksHaveBeenCalled' {
		const result = super.ngOnDestroy();
		this.mutationObserver?.disconnect();
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onFocus(event : FocusEvent) : void {
		this.focus.emit(event);

		this.focus$.next((event.target as HTMLTextAreaElement).value);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onClick(
		event : MouseEvent,
	) : void {
		const TARGET = event.target as HTMLTextAreaElement;
		this.click$.next(TARGET.value);
	}

	// I am not quite sure how this works. It was copy paste ¯\_(ツ)_/¯ ^nn
	public focus$ : Subject<string> = new Subject<string>();
	public click$ : Subject<string> = new Subject<string>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public search = (text$ : Observable<string>) : Observable<readonly ApiObjectWrapper<any>[]> => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !!(this.instance && !this.instance.isPopupOpen())));
		const inputFocus$ = this.focus$;

		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			map((term : string) => {
				const items = this.shiftModels.search(term).filterBy((item : SchedulingApiShiftModel | TimeStampApiShiftModel) => {
					if (!item.trashed) return true;
					if (this.value && item.id.equals(this.value)) return true;
					return false;
				});
				return items.sortedBy((item : SchedulingApiShiftModel | TimeStampApiShiftModel) => item.parentName).iterable();
			}),
		);
	};

	/** @see NgbTypeahead#inputFormatter */
	public formatter = (item : SchedulingApiShiftModel) : string => {
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		return item.name ? item.name : '';
	};

	private _tempValue : string | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get valueItem() : SchedulingApiShiftModel | TimeStampApiShiftModel | string | null {
		if (this._tempValue) return this._tempValue;
		if (this.value === null) return null;
		return this.shiftModels.get(this.value);
	}

	public set valueItem(input : SchedulingApiShiftModel | TimeStampApiShiftModel | string | null) {
		if (
			input instanceof SchedulingApiShiftModel ||
			input instanceof TimeStampApiShiftModel
		) {
			this.value = input.id;
			this._tempValue = null;
		} else {
			this._tempValue = input;

			assumeDefinedToGetStrictNullChecksRunning(input, 'input');
			const ID = this.getIdByInput(input);
			if (ID === '') {
				this._tempValue = null;
				this.onClickItem(null);
			} else if (ID instanceof Id) {
				this._tempValue = null;
				this.onClickItem(ID);
				this.instance!.dismissPopup();
			}
		}
	}

	private getIdByInput(searchString : string) : Id | string {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const searchedShiftModels = this.shiftModels.filterBy((item : any) => item.name.toLowerCase().includes(searchString.toLowerCase()));
		if (searchedShiftModels.length === 1) {
			const firstItem = searchedShiftModels.get(0);
			if (!firstItem) throw new Error('Could not get firstItem');
			if (firstItem.name.toLowerCase() === searchString.toLowerCase()) return firstItem.id;
		}
		return searchString;
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		return super.ngAfterContentInit();
	}

	/**
	 * Set new selected member as value
	 */
	private onClickItem(id : Id | null) : void {
		this.value = id;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public typeaheadOnSelect(
		input : NgbTypeaheadSelectItemEvent<SchedulingApiShiftModel>,
		inputRef : HTMLInputElement,
	) : void {
		inputRef.value = input.item.name;

		// A Blur on a active editable would trigger a save every time the user selects a member.
		if (!this.pEditable) {
			this.zone.runOutsideAngular(() => {
				window.requestAnimationFrame(() => {
					// Blur needs to happen after the typeahead plugin has finished its work
					$(inputRef).trigger('blur');
				});
			});
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setFocus(
		inputRef : TemplateRef<EditableTriggerFocussableDirective> | HTMLInputElement,
	) : void {
		$(inputRef).trigger('focus');
	}

	private _value : ValueType | null = null;

	/**
	 * Transform input into fitting output format
	 * @param searchString The users input
	 */
	private stringToOutput(searchString : string) : Id | null {
		const idOrString = this.getIdByInput(searchString);
		if (idOrString instanceof Id) return idOrString;
		return null;
	}

	private updateOutput(event : Event) : void {
		const value = (event.target as HTMLInputElement).value;
		const output = this.stringToOutput(value);

		if (!value) this._value = output;
		this._onChange(output);
	}

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void {
		this.updateOutput(event);
		this.keyup.emit(event);
	}

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched();
		this.blur.emit(event);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc, @typescript-eslint/no-explicit-any */
	public onChange(event : any) : void {
		this.updateOutput(event);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}
}
