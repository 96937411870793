<ng-template
	ngFor let-assignedMember
	[ngForOf]="assignedMembersForList"
>
	<section>
		<div class="d-flex justify-content-between align-items-stretch card-options">
			<div
				class="d-flex align-items-center flex-grow-1 pt-1 pl-2 pb-1 pr-2 crop-on-overflow"
				[class.small]="size==='sm'"
				[title]="assignedMember.firstName + ' ' + assignedMember.lastName"
			>
				<p-member-badge
					[memberId]="assignedMember.id"
					[firstName]="assignedMember.firstName"
					[lastName]="assignedMember.lastName"
					[isMe]="!!isMe(assignedMember)"
					[absenceType]="absenceType(assignedMember.id)"
					size="small"
				></p-member-badge>
				<span class="crop-on-overflow ml-2"><strong>{{ assignedMember.firstName }}</strong> {{ assignedMember.lastName }}</span>
			</div>

			<p-shift-exchange-btn
				*ngIf="showExchangeBtn(assignedMember)"
				class="ml-auto align-self-stretch d-flex"
				[shiftExchange]="getShiftExchangeForMember(assignedMember.id)"
				[shiftId]="shift.id"
				[assignedMember]="assignedMember"
				[colorizeIconIfShiftExchangeExists]="true"
			></p-shift-exchange-btn>

			<button type="button" class="btn btn-outline-secondary btn-light btn-frameless p-0 crop-on-overflow card-option"
				[class.btn-sm]="size==='sm'"
				(click)="openDeleteModal($event, assignedMember, modalContent.template)"
				*ngIf="showDeleteButton"
				title="Aus Schicht entfernen" i18n-title
			>
				<fa-icon class="p-1" [icon]="['solid', 'user-times']"></fa-icon>
			</button>
		</div>
	</section>
</ng-template>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Zuweisung entfernen" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<ng-template [ngIf]="isMe(deletableMember)" [ngIfElse]="notMe">
			<p i18n>Du willst raus aus der Schicht?</p>
		</ng-template>
		<ng-template #notMe>
			<p i18n>{{ deletableMember.firstName }} {{ deletableMember.lastName }} soll aus dieser Schicht entfernt werden?</p>
		</ng-template>
		<p-notification-conf-form
			[(ngModel)]="api.data.notificationsConf.sendEmail"
			[api]="api"
			[shift]="shift"
		></p-notification-conf-form>
	</div>
	<div class="modal-footer">
		<button type="button"
			class="btn btn-outline-secondary mr-auto"
			(click)="d($event)"
			i18n
		>Abbrechen</button>
		<button type="button"
			class="btn btn-danger ml-auto"
			(click)="c($event)"
			i18n
		>Ja, entfernen</button>
	</div>
</ng-template>
