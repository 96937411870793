import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PBtnThemeEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { PBadgeContent } from '@plano/client/shared/shared/p-badge/p-badge.types';
import { PShiftExchangeConceptService, RightsService, SchedulingApiMember, SchedulingApiShiftExchange, ShiftId } from '@plano/shared/api';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';

@Component({
	selector: 'p-shift-exchange-btn',
	templateUrl: './p-shift-exchange-btn.component.html',
	styleUrls: ['./p-shift-exchange-btn.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeBtnComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shiftExchange : SchedulingApiShiftExchange | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shiftId : ShiftId | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private assignedMember : SchedulingApiMember | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private hideNonCounterBadges : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private colorizeIconIfShiftExchangeExists : boolean = false;

	constructor(
		private pShiftExchangeConceptService : PShiftExchangeConceptService,
		private rightsService : RightsService,
		private pShiftExchangeService : PShiftExchangeService,
		private localize : LocalizePipe,
		private console : LogService,
	) {
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get badgeValue() : PBadgeContent {
		if (!this.shiftExchange) return null;
		const icon = this.pShiftExchangeConceptService.getBadgeIcon(this.shiftExchange);
		if (icon && !this.hideNonCounterBadges) return icon;
		if (this.shiftExchange.todoCount) return this.shiftExchange.todoCount;
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get icon() : FaIcon {
		if (!this.shiftExchange) {
			if (!this.assignedMember) this.console.error('provide assigned member here');
			if (this.rightsService.isMe(this.assignedMember!.id)) return 'hands-helping';
			return 'briefcase-medical';
		} else if (this.shiftExchange.isIllness && !this.shiftExchange.isBasedOnIllness) {
			return 'briefcase-medical';
		}
		return 'hands-helping';
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get title() : string {
		let result : string = '';
		if (this.icon === 'hands-helping') {
			result += this.shiftExchange ? this.localize.transform('Ersatzsuche anzeigen') : this.localize.transform('Ersatz suchen');
		} else if (this.icon === 'briefcase-medical') {
			if (this.shiftExchange) {
				result += this.localize.transform('Krankmeldung anzeigen');
			} else {
				result += this.localize.transform('Krank melden');
				if (
					/** If shiftId exists, check if user is allowed to manage it */
					this.shiftId &&
					this.rightsService.hasManagerRightsForShiftModel(this.shiftId.shiftModelId)
				) {
					result += ` & ${this.localize.transform('Ersatz suchen')}`;
				}
			}
		} else {
			this.console.error('Icon unknown');
		}

		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get style() : PBtnThemeEnum.OUTLINE_DANGER | PBtnThemeEnum.OUTLINE_PRIMARY | ExtractFromUnion<'secondary', PThemeEnum> {
		if (this.icon === 'briefcase-medical' && this.shiftExchange) return PBtnThemeEnum.OUTLINE_DANGER;
		return enumsObject.PThemeEnum.SECONDARY;
	}

	/**
	 * Prevent the navigation if its for some reason currently not possible.
	 * For possible reasons, @see PShiftExchangeService#blockedByAssignmentProcessWarningModal
	 * @param event The event that triggered the method
	 */
	public preventNavigationIfNecessary(event : Event) : void {
		if (this.shiftExchange) return;

		assumeNonNull(this.shiftId);
		if (this.pShiftExchangeService.blockedByAssignmentProcessWarningModal(this.shiftId)) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	 * Path to the detail page of this item
	 */
	public get pathToDetailPage() : string | null {
		let lastPartOfRoute : string;
		if (this.shiftExchange) {
			lastPartOfRoute = this.shiftExchange.id.toString();
		} else if (this.shiftId) {
			lastPartOfRoute = `create/${this.shiftId.toUrl()}`;
			if (this.assignedMember) {
				lastPartOfRoute += `/member/${this.assignedMember.id.toString()}`;
			}
		} else {
			throw new Error('Could not navigate. Id is missing.');
		}
		return `/client/shift-exchange/${lastPartOfRoute}/`;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get iconHasTextPrimaryClass() : boolean {
		return this.icon === 'hands-helping' && !!this.shiftExchange && this.colorizeIconIfShiftExchangeExists;
	}
}
