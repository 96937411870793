import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormArray } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { PBackgroundColorEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface, EditableHookType } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { PossibleShiftPickerValueItemType, PShiftPickerComponent } from '@plano/client/shared/p-shift-picker/p-shift-picker/p-shift-picker.component';
import { PossibleShiftPickerValueType } from '@plano/client/shared/p-shift-picker/shift-picker-picked-offers/shift-picker-picked-offers.component';
import { PSimpleChanges, RightsService, SchedulingApiBooking, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange, SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeShiftRefs, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';

type ValueType = PossibleShiftPickerValueType;

@Component({
	selector: 'p-shift-picker-modal-box[availableShifts][offersRef]',
	templateUrl: './p-shift-picker-modal-box.component.html',
	styleUrls: ['./p-shift-picker-modal-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PShiftPickerModalBoxComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftPickerModalBoxComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, EditableControlInterface, OnChanges {
	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public calendarBtnClick : EventEmitter<SchedulingApiShiftExchangeShiftRef> = new EventEmitter<SchedulingApiShiftExchangeShiftRef>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public loadDetailedItem : SchedulingApiBooking | SchedulingApiShiftExchange | SchedulingApiShift | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public member : SchedulingApiMember | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public availableShifts ! : SchedulingApiShifts;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public offersRef ! : SchedulingApiShiftExchangeShiftRefs;

	@ViewChild('shiftPickerRef') private shiftPickerRef ?: PShiftPickerComponent;

	/**
	 * Triggers when a shift gets added to the shift exchange.
	 */
	@Output() private addItem = new EventEmitter<PossibleShiftPickerValueItemType>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onAddShifts : EventEmitter<SchedulingApiShifts> = new EventEmitter<SchedulingApiShifts>();

	/**
	 * A hook which can get used to open a modal to confirm the change offer action.
	 */
	@Input() protected changeOfferHook : null | (() => EditableHookType | null) = null;

	/**
	 * Triggers when modal gets opened
	 */
	@Output() private onModalOpen = new EventEmitter<undefined>();

	/**
	 * Triggers when modal gets closed
	 */
	@Output() private onModalClosed = new EventEmitter<undefined>();

	/**
	 * With this boolean you can hide the calendarBtn even if calendarBtnClick has observers
	 */
	@Input('showCalendarBtn') private _showCalendarBtn : boolean | null = null;

	@Input() public override api : SchedulingApiService | null = null;

	/* eslint-disable-next-line @typescript-eslint/ban-types -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Input('formArray') public override control : UntypedFormArray | null = null;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		public pShiftExchangeService : PShiftExchangeService,
		private modalService : ModalService,
		private localize : LocalizePipe,
		private rightsService : RightsService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	protected readonly PBackgroundColorEnum = PBackgroundColorEnum;
	public readonly CONFIG = Config;
	public enums = enumsObject;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftRefs() : PossibleShiftPickerValueType {
		const array = new SchedulingApiShiftExchangeShiftRefs(null, null);
		for (const control of this.control!.controls) {
			array.push(control.value);
		}
		return array;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get sortedShiftRefs() : readonly SchedulingApiShiftExchangeShiftRef[] {
		return this.offersRef.sortedBy((item : SchedulingApiShiftExchangeShiftRef) => {
			const shift = this.api!.data.shifts.get(item.id);
			if (!shift) throw new Error('Could not find shift');

			return shift.start;
		}).iterable();
	}

	public ngOnChanges(changes : PSimpleChanges<PShiftPickerModalBoxComponent>) : void {
		if (changes.control) {
			const formArray = this.control;
			if (formArray) {
				formArray.clear();
				for (const offerRef of this.offersRef.iterable()) {
					this.addItem.emit(offerRef);
				}
				formArray.updateValueAndValidity();
			}
		}
	}

	/**
	 * Load necessary data for this modal
	 */
	public openModalSubscriber() : void {
		if (this.shiftPickerRef) {
			void this.shiftPickerRef.loadNewData();
		}
		this.onModalOpen.emit();
	}

	private get giveUserAHintAboutUnusedSelectedShifts() : boolean {
		return !!this.availableShifts.selectedItems.length;
	}

	/**
	 * We want the user to be sure he/she doesn't want to add the shift he/she has already
	 * selected in the calendar.
	 */
	public async beforeModalClose() : Promise<boolean> {
		if (!this.giveUserAHintAboutUnusedSelectedShifts) {
			return true;
		}
		const openedModalPromise = this.modalService.confirm({
			modalTitle: this.localize.transform('Sicher?'),
			description: this.localize.transform('Du hast Schichten im Kalender selektiert, aber sie nicht der Tauschbörse hinzugefügt.'),
			closeBtnLabel: this.localize.transform('Trotzdem schließen'),
			dismissBtnLabel: this.localize.transform('Zurück'),
		}, {
			theme: enumsObject.PThemeEnum.WARNING,
			size: enumsObject.BootstrapSize.MD,
		}).result;

		const value = await openedModalPromise;
		if (value.modalResult === 'success') {
				this.api!.data.shifts.selectedItems.setSelected(false);
				return true;
		}
		return false;
	}

	public boundBeforeModalClose = this.beforeModalClose.bind(this);

	/**
	 * Save changes
	 */
	public closeModalSubscriber() : void {
		this.console.debug('close Modal');
		this.onModalClosed.emit();
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formArray. #two-way-binding
		this.disabled ? this.control?.disable() : this.control?.enable();
	}

	/**
	 * Highlight the related shift in the calendar. This action is made for the Scheduling site.
	 */
	public onCalendarClick(shiftRef : SchedulingApiShiftExchangeShiftRef) : void {
		this.calendarBtnClick.emit(shiftRef);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showCalendarBtn() : boolean {
		if (this._showCalendarBtn !== null) return this._showCalendarBtn;
		return !!this.calendarBtnClick.observers.length;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isMe() : boolean | null {
		if (!this.member) return null;
		return this.rightsService.isMe(this.member.id) ?? null;
	}
}
