import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { BootstrapRounded } from '@plano/client/shared/bootstrap-styles.enum';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PButtonType } from '@plano/shared/p-forms/p-button/p-button.component';

@Component({
	selector: 'p-calendar-view-settings',
	templateUrl: './calendar-view-settings.component.html',
	styleUrls: ['./calendar-view-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CalendarViewSettingsComponent implements PComponentInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showListBtn : boolean = true;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideLabels : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public calendarMode : CalendarModes | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private calendarModeChange = new EventEmitter<CalendarModes>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showDayAsList : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public showDayAsListChange = new EventEmitter<boolean>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showWeekAsList : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public showWeekAsListChange = new EventEmitter<boolean>();

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/**
	 * Navigate to 'month', 'week', 'day'…
	 */
	public switchCalendarMode(input : CalendarModes) : void {
		this.calendarMode = input;
		this.calendarModeChange.emit(this.calendarMode);
	}

	public BootstrapRounded = BootstrapRounded;
	public enums = enumsObject;
	public CalendarModes = CalendarModes;
	public PButtonType = PButtonType;

	public config : typeof Config = Config;
}
