<div class="sticky-top border-bottom">
	<div class="navbar navbar-dark bg-light">
		<div class="container p-0 pl-md-2 pr-md-2"
			[class.container]="!containerSize"
			[class.container-sm]="containerSize==='sm'"
		>
			<p-nav-back-anchor
				[forceNavBackLink]="forceNavBackLink"
				[hasTextDanger]="!!isNewItem"
				[icon]="isNewItem ? enums.PlanoFaIconPool.DELETE : enums.PlanoFaIconPool.NAV_BACK"
				[text]="text"
				[routeId]="routeId"
				[queryParamsForNavBack]="queryParamsForNavBack"
				(onNavBack)="navBack()">
			</p-nav-back-anchor>
			<ng-content></ng-content>
		</div>
	</div>
</div>
