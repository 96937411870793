import { Directive, TemplateRef } from '@angular/core';
import { ModalDismissParam } from '@plano/shared/core/p-modal/modal.service.options';

// eslint-disable-next-line jsdoc/require-jsdoc
export interface PModalTemplateContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $implicit : any;
  close : (value : unknown) => void;
  dismiss : (value : ModalDismissParam) => void;
}

/**
 * You can use this directive to improve the type checks in template files.
 * @example
 * 	<ng-template #myModalContent pModalTemplate="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
 * 	<p-modal-content
 * 		(onDismiss)="d('clicked dismiss')" // ERROR: Argument of type '"clicked dismiss"' is not assignable to parameter of type 'ModalDismissParam'
 * 		(onClose)="c($event);"
 */
@Directive({
	selector: 'ng-template[pModalTemplate]',
	exportAs: 'pModalTemplateExportAsRef',
	standalone: true,
})
export class PModalTemplateDirective {
	constructor(public template : TemplateRef<PModalTemplateDirective>) {}

	/** Overwrite the types */
	public static ngTemplateContextGuard(
    // eslint-disable-next-line unicorn/prevent-abbreviations
    _dir : PModalTemplateDirective,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    _ctx : unknown,
	) : _ctx is PModalTemplateContext {
		return true;
	}
}
