import { Injectable } from '@angular/core';
import { SchedulingApiCustomBookableMailEventType } from '@plano/shared/api';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

interface EventTypesObject {
	type : SchedulingApiCustomBookableMailEventType;
	title : PDictionarySourceString;
	description : PDictionarySourceString | null;
	icon : FaIcon | null;
	bookableType : 'booking' | 'giftCard'
}

/**
 * Contains shared methods concerning custom-bookable-mail event-types.
 */
@Injectable( { providedIn: 'root' } )
export class EventTypesService {
	public eventTypesObjects : EventTypesObject[] = [
		{
			type : SchedulingApiCustomBookableMailEventType.INQUIRY_ARRIVAL_NOTICE,
			title : 'Buchungsanfrage eingegangen',
			description : 'Diese Email erhalten deine Kunden, nachdem sie online eine Buchungsanfrage gesendet haben.',
			icon : 'inbox',
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.INQUIRY_DECLINED,
			title : 'Buchungsanfrage abgelehnt',
			description: null,
			icon : enumsObject.PlanoFaIconPool.CANCELED,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.BOOKING_BOOKED,
			title : 'Erfolgreich gebucht',
			description: 'Diese Email erhalten Kunden bei direkter Online-Buchung oder bei Bestätigung ihrer Buchungsanfrage.',
			icon : 'check',
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.BOOKING_CANCELED,
			title : 'Buchung storniert',
			description: null,
			icon : enumsObject.PlanoFaIconPool.CANCELED,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.PAYMENT_PARTIAL,
			title : 'Teilzahlung erhalten',
			description: null,
			icon : enumsObject.PlanoFaIconPool.BOOKING_PAYMENT_STATUS,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.PAYMENT_COMPLETE,
			title : 'Buchungspreis komplett bezahlt',
			description: null,
			icon : enumsObject.PlanoFaIconPool.BOOKING_PAYMENT_STATUS,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.ONLINE_PAYMENT_FAILED,
			title : 'Online-Zahlung fehlgeschlagen',
			description: 'Diese Email erhalten Kunden, deren Online-Zahlung nachträglich fehlgeschlagen ist (z.B. weil ihr Konto nicht gedeckt war). Die Email enthält einen Button, worüber deine Kunden erneut zahlen können.',
			icon : enumsObject.PlanoFaIconPool.BOOKING_PAYMENT_STATUS,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.REFUNDED,
			title : 'Geld erstattet',
			description: null,
			icon : enumsObject.PlanoFaIconPool.BOOKING_PAYMENT_STATUS,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.AMOUNT_TO_PAY_CHANGED,
			title : 'Buchungspreis geändert',
			description: null,
			icon : enumsObject.PlanoFaIconPool.BOOKING_PAYMENT_STATUS,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.PAYMENT_METHOD_CHANGED,
			title : 'Präferierte Zahlungsart geändert',
			description: null,
			icon : enumsObject.PlanoFaIconPool.BOOKING_PAYMENT_STATUS,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.COURSE_REMINDER,
			title : 'Termin-Erinnerung',
			description : '2 Tage vor dem ersten Termin',
			icon : enumsObject.PlanoFaIconPool.PUSH_NOTIFICATION,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.DATE_CHANGED,
			title : 'Angebotstermin geändert',
			description : 'Diese Email wird verschickt, wenn sich die Uhrzeit oder das Datum eines gebuchten Angebots ändert. Oder einer der Termine eines mehrtägigen Angebots gelöscht wird.',
			icon : 'exchange-alt',
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.PARTICIPATED,
			title : 'Teilnahme abgeschlossen',
			description : 'Diese Email wird spätestens am Tag nach der Teilnahme an einem gebuchten Angebot verschickt.',
			icon : enumsObject.PlanoFaIconPool.PARTICIPATED,
			bookableType: 'booking',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.GIFT_CARD_BOOKED,
			title : 'Gutschein gebucht',
			description : null,
			icon : enumsObject.PlanoFaIconPool.GIFT_CARD,
			bookableType: 'giftCard',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.REFUND_GIFT_CARD_BOOKED,
			title : 'Rückzahlung per Gutschein',
			description : null,
			icon : enumsObject.PlanoFaIconPool.GIFT_CARD,
			bookableType: 'giftCard',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.MARKETING_GIFT_CARD_BOOKED,
			title : 'Marketing-Gutschein',
			description : null,
			icon : enumsObject.PlanoFaIconPool.GIFT_CARD,
			bookableType: 'giftCard',
		},
		{
			type : SchedulingApiCustomBookableMailEventType.GIFT_CARD_CANCELED,
			title : 'Gutschein storniert',
			description : null,
			icon : enumsObject.PlanoFaIconPool.CANCELED,
			bookableType: 'giftCard',
		},
	];

	/**
	 * Get item/object by eventType
	 */
	private getItem(eventType : SchedulingApiCustomBookableMailEventType) : EventTypesObject {
		const result = this.eventTypesObjects.find(item => item.type === eventType);

		if (!result)
			throw new Error('Please add missing object for event-type');

		return result;
	}

	/**
	 * Get title by eventType
	 */
	public getTitle(eventType : SchedulingApiCustomBookableMailEventType) : PDictionarySourceString {
		return this.getItem(eventType).title;
	}

	/**
	 * Get icon by eventType
	 */
	public getIcon(eventType : SchedulingApiCustomBookableMailEventType) : FaIcon | null {
		return this.getItem(eventType).icon;
	}

	/**
	 * Get description by eventType
	 */
	public getDescription(eventType : SchedulingApiCustomBookableMailEventType) : string | null {
		return this.getItem(eventType).description;
	}

}
