<p-modal-header
	[headline]="title"
	(onClose)="dismissModal($event)"
></p-modal-header>

<ng-template [ngIf]="componentInitialized && (!!(shift && shiftChangeSelector) || (!!shiftModel || !shift) || (!!shifts))">
<div class="modal-body pb-0">
	<section class="form">
		<ng-wizard [config]="config" (stepChanged)="stepChanged($event)">
			<ng-wizard-step title="Übertragung" i18n-title
				#changeSelectorStep
				*ngIf="showTransmissionSettings"
				[canEnter]="isValidTypeBoolean"
				[canExit]="isValidFunctionReturnsBoolean.bind(this)"
			>
				<!-- When wizard opens with this step unfortunately "currentStepChange" is undefined. -->
				<ng-container *ngIf="!currentStepChange || currentStepChange.step.index === changeSelectorStep.index">
					<p-bootstrap-form-group>
						<p-radios
							class="max-width-600"
							[ngModel]="shiftChangeSelector.addChangeSelectors"
							(ngModelChange)="setAddChangeSelectors($event)"
							[inline]="false"
						>
							<p-radios-radio
								[value]="false"
								[label]="translatedAddChangeSelectorsRadioValue"
							></p-radios-radio>
							<p-radios-radio
								[value]="true"
								label="Ja, auf weitere Bereiche übertragen…" i18n-label
								[disabled]="typeOfChange===PTypeOfChange.CANCEL"
								cannotSetHint="Bei dieser Aktion steht die Übertragung aus technischen Gründen leider noch nicht zur Verfügung. Wenn du doch viele Stornierungen auf einmal vornehmen möchtest, könntest du die dazugehörigen Schichten löschen und das Löschen übertragen – falls Löschen eine Option ist."
							></p-radios-radio>
						</p-radios>
					</p-bootstrap-form-group>

					<div *ngIf="shiftChangeSelector.addChangeSelectors">

						<div *ngIf="shiftChangeSelector.addChangeSelectors && customWarningAlert" class="mb-3">
							<div class="max-width-600 mt-3">
								<p-alert class="mt-3"><span [innerHtml]="customWarningAlert | pSafeHtml"></span></p-alert>
							</div>
						</div>
						<div *ngIf="shiftChangeSelector.addChangeSelectors && customDangerAlert" class="mb-3">
							<div class="max-width-600 mt-3">
								<p-alert [theme]="enums.PThemeEnum.DANGER" [icon]="enums.PlanoFaIconPool.WARNING" class="mt-3"><span [innerHtml]="customDangerAlert | pSafeHtml"></span></p-alert>
							</div>
						</div>
						<hr class="mt-3 mb-0">
						<p-grid>
							<ng-template [ngIf]="!shift">
								<div class="col pt-3">
									<div class="max-width-600">
										<p-bootstrap-form-group>
											<label class="m-0" i18n>Auf alle mit der Vorlage <code>{{ shiftModel!.name }}</code> erstellten Schichten ab</label>
											<p-ai-switch
												[pEditable]="false"
												[group]="formGroup"
												[attributeInfo]="shiftModel!.changeSelector.attributeInfoStart"
												[placeholder]="changeSelectorStartPlaceholder"
												[readMode]="false"
												[supportsUnset]="false"
												[showEraseValueBtn]="false"
											></p-ai-switch>
										</p-bootstrap-form-group>
									</div>
								</div>
							</ng-template>
							<ng-template [ngIf]="!!shift">
								<div class="col pt-3">
									<div class="max-width-600">
										<p-section [whitespace]="SectionWhitespace.NONE" label="Auf bereits erstellte Schichten…" i18n-label pAnchorLink="apply-to-created-shifts">

											<div class="mb-3" *ngIf="showApplyToShiftPacketCheckbox">
												<p-checkbox
													[readMode]="false"
													[disabled]="applyToShiftPacketCheckboxIsDisabled"
													[ngModel]="optionShiftsOfPacket"
													(ngModelChange)="setOptionShiftsOfPacket(!optionShiftsOfPacket)"
													valueText="Aus diesem Schicht-Paket" i18n-valueText
												></p-checkbox>
											</div>
											<div class="mb-3" *ngIf="showApplyToShiftsOfSeriesCheckbox">
												<p-checkbox
													[readMode]="false"
													[disabled]="shiftChangeSelector!.shiftsOfPacketIndex === null && shiftChangeSelector!.shiftsOfSeriesId === null && shiftChangeSelector!.shiftsOfShiftModelId !== null"
													[ngModel]="optionShiftsOfSeries"
													(ngModelChange)="setOptionShiftsOfSeries(!optionShiftsOfSeries)"
													valueText="Aus dieser Wiederholung" i18n-valueText
												></p-checkbox>
											</div>
											<div class="mb-3">
												<p-checkbox
													[ngModel]="optionShiftsOfModel"
													(ngModelChange)="setOptionShiftsOfModel(!optionShiftsOfModel)"
													valueText="Mit der Vorlage »{{ shiftModel!.name }}«" i18n-valueText
												></p-checkbox>
											</div>
										</p-section>
										<p-section [whitespace]="SectionWhitespace.NONE" *ngIf="shiftChangeSelector!.shiftsOfShiftModelId !== null && showDateRangeSelection" label="Übertragungszeitraum" i18n-label pAnchorLink="period-of-application">
											<p-grid>
												<div class="col-lg-6">
													<p-ai-switch
														[pEditable]="false"
														[theme]="shiftChangeSelector.attributeInfoStart.isAvailable && shiftChangeSelector!.start === null ? enums.PThemeEnum.PRIMARY : null"
														label="Von einschließlich" i18n-label
														[group]="formGroup"
														[attributeInfo]="shiftChangeSelector!.attributeInfoStart"
														[placeholder]="changeSelectorStartPlaceholder"
														eraseValueBtnLabel="Ohne Begrenzung" i18n-eraseValueBtnLabel
														[showEraseValueBtn]="true"
														[supportsUnset]="true"
														(valueChange)="getAffectedShifts()"
													></p-ai-switch>
												</div>
												<div class="col-lg-6">
													<p-ai-switch
														[pEditable]="false"
														[theme]="shiftChangeSelector!.attributeInfoEnd.isAvailable && shiftChangeSelector!.end === null ? enums.PThemeEnum.PRIMARY : null"
														label="Bis einschließlich" i18n-label
														[group]="formGroup"
														[attributeInfo]="shiftChangeSelector!.attributeInfoEnd"
														[placeholder]="changeSelectorEndPlaceholder"
														eraseValueBtnLabel="Ohne Begrenzung" i18n-eraseValueBtnLabel
														[showEraseValueBtn]="true"
														[supportsUnset]="true"
														(valueChange)="getAffectedShifts()"
														[readMode]="false"
													></p-ai-switch>
												</div>
											</p-grid>
										</p-section>
									</div>
								</div>
							</ng-template>
							<div class="col-lg-6 pt-3 pb-3 bg-light border-left"
								*ngIf="showTransmissionPreview && affectedShiftsApiService.data.attributeInfoShifts.isAvailable"
							>
								<p-section [whitespace]="SectionWhitespace.NONE" label="Betroffene Schichten" i18n-label pAnchorLink="affected-shifts">
									<p-transmission-preview
										[isLoading]="affectedShiftsApiService.isLoadOperationRunning"
										[disabled]="!!formGroup.invalid"
										[timestamp]="shift ? shift!.start : today"
										(timestampChanged)="transmissionPreviewTimestampChanged($event)"
										[affectedShifts]="affectedShiftsApiService.data.shifts"
										[shiftModel]="shiftModel ? shiftModel : shift!.model!"
										[members]="members"
										[myId]="meService.isLoaded() ? meService.data.id : null"
									></p-transmission-preview>
								</p-section>
							</div>
						</p-grid>
					</div>
				</ng-container>
			</ng-wizard-step>

			<ng-wizard-step
				*ngIf="showCancellationSettings"
				title="Stornierung" i18n-title
				#cancellationStep

				[canExit]="isValidFunctionReturnsBoolean.bind(this)"
			>
				<ng-template #spinnerTemplate><p-spinner [size]="enums.BootstrapSize.LG"/></ng-template>
				<!--PLANO-171520-->
				<ng-container *ngIf="isOnCancellationStep && (isChangeSelectorModalForShiftModel || (affectedShiftsApiService.isLoaded() && !affectedShiftsApiService.isLoadOperationRunning)); else spinnerTemplate">
					<ng-container *ngIf="affectedShiftsApiService.isLoaded() && api.data.attributeInfoAutomaticBookingCancellationSettings.isAvailable; else dontShowCancellationSettingsTemplate">
						<p-section [ngSwitch]="typeOfChange">
							<p *ngSwitchCase="PTypeOfChange.DELETE">Durch das Löschen {affectedShiftsApiService.data.bookingsCanceledCount, plural, one {wird} other {werden}}&nbsp;<mark>{{ affectedShiftsApiService.data.bookingsCanceledCount }}</mark>&nbsp;<strong>{affectedShiftsApiService.data.bookingsCanceledCount, plural, one {Buchung} other {Buchungen}} storniert</strong>.<!--PLANO-171520--><span *ngIf="affectedShiftsApiService.isLoaded() && affectedShiftsApiService.data.bookingsDeclinedCount > 0">&ngsp;Und <mark>{{ affectedShiftsApiService.data.bookingsDeclinedCount }}</mark><strong> {affectedShiftsApiService.data.bookingsDeclinedCount, plural, one {Anfrage wird} other {Anfragen werden}} abgelehnt</strong>.</span>&ngsp;Hier kannst du damit zusammenhängende Einstellungen vornehmen.</p>
							<p *ngSwitchCase="PTypeOfChange.CANCEL">Durch die Stornierung des Angebots {affectedShiftsApiService.data.bookingsCanceledCount, plural, one {wird} other {werden}}&nbsp;<mark>{{ affectedShiftsApiService.data.bookingsCanceledCount }}</mark>&nbsp;<strong>{affectedShiftsApiService.data.bookingsCanceledCount, plural, one {Buchung} other {Buchungen}} storniert</strong>.<!--PLANO-171520--><span *ngIf="affectedShiftsApiService.isLoaded() && affectedShiftsApiService.data.bookingsDeclinedCount > 0">&ngsp;Und <mark>{{ affectedShiftsApiService.data.bookingsDeclinedCount }}</mark>&nbsp;<strong>{affectedShiftsApiService.data.bookingsDeclinedCount, plural, one {Anfrage wird} other {Anfragen werden}} abgelehnt</strong>.</span>&ngsp;Hier kannst du damit zusammenhängende Einstellungen vornehmen.</p>
							<p *ngSwitchDefault><p-alert>Error</p-alert></p>
						</p-section>
						<p-section
							label="Stornogebühren" i18n-label
							pAnchorLink="cancellation-fees"
							*ngIf="api.data.automaticBookingCancellationSettings.attributeInfoNoCancellationFees.isAvailable"
						>
							<p i18n>Falls Stornogebühren in Dr.&nbsp;Plano hinterlegt sind, können diese bei den zu stornierenden Buchungen angerechnet werden. Bei kostenlosen Buchungen oder Stornierungen innerhalb der hinterlegten Widerrufsfrist werden generell keine Stornogebühren angerechnet.</p>
							<p-ai-switch
								[pEditable]="false"
								[group]="formGroup"
								[attributeInfo]="api.data.automaticBookingCancellationSettings.attributeInfoNoCancellationFees"
							>
								<p-ai-switch-item
									label="Hinterlegte Stornogebühren anrechnen" i18n-label
									[value]="false"
								></p-ai-switch-item>
								<p-ai-switch-item
									label="Keine Stornogebühren" i18n-label
									[value]="true"
								></p-ai-switch-item>
							</p-ai-switch>
						</p-section>
						<p-section
							label="Automatische Rückzahlung" i18n-label
							pAnchorLink="automatic-refund"
						>
							<p i18n>Wenn du unten die automatische Rückzahlung auswählst, rechnet Dr.&nbsp;Plano für alle betroffenen Buchungen den zu erstattenden Betrag aus: Dazu wird die ggf. anfallende Stornogebühr von der bereits bezahlten Buchungsgebühr abgezogen. Anschließend wird der Betrag automatisch an deine Kunden zurückgezahlt. Du musst nichts weiter tun! 💫</p>
							<p-alert [theme]="enums.PThemeEnum.INFO" i18n>Diese Funktionalität steht nur bei Buchungen zur Verfügung, die per <a routerLink="/client/plugin/payments" target="_blank" rel="noopener">Online-Zahlung</a> bezahlt worden sind und aktuell noch <a routerLink="/client/plugin/faq-online-payment" fragment="refund" target="_blank" rel="noopener">genügend Online-Guthaben</a> aufweisen, um den gesamten zu erstattenden Betrag zurückzuzahlen. Online-Zahlungen, die vor mehr als 180 Tagen getätigt worden sind, können nicht zurückerstattet werden, da die Bank sie nicht mehr akzeptiert – Dr.&nbsp;Plano siebt so alte Zahlungen automatisch aus.<div><fa-icon class="mr-2" [icon]="enums.PlanoFaIconPool.EMAIL_NOTIFICATION"/>Dr.&nbsp;Plano informiert dich per Email darüber, welche Buchungen erstattet werden konnten.</div></p-alert>
							<p-ai-switch
								[pEditable]="false"
								label="Automatische Online-Rückzahlung"
								[group]="formGroup"
								[attributeInfo]="api.data.automaticBookingCancellationSettings.attributeInfoAutomaticOnlineRefund"
							>
								<p-ai-switch-item
									label="Automatisch zurückzahlen, falls nötig" i18n-label
									[value]="true"
								></p-ai-switch-item>
								<p-ai-switch-item
									label="Keine automatische Rückzahlung" i18n-label
									[value]="false"
								></p-ai-switch-item>
							</p-ai-switch>
							<p-ai-switch
								[pEditable]="false"
								label="Interne Notiz für die Rückzahlung" i18n-label
								description="Interne Notizen werden bei der Zahlung abgespeichert und können nur von Admins oder Usern eingesehen werden, die Buchungen verwalten dürfen. Außerdem werden die Notizen im Excel-Export der Zahlungen mit exportiert und können so für die Kommunikation mit der Buchhaltung verwendet werden." i18n-description
								[group]="formGroup"
								[attributeInfo]="api.data.automaticBookingCancellationSettings.attributeInfoTransactionInternalComment"
								[type]="AISwitchUIType.TEXTAREA"
							></p-ai-switch>

							<h5 class="text-transform-none mt-3" i18n>Gutschein statt Rückzahlung</h5>
							<p i18n>Sollte dein Kunde eine Buchung stornieren, die teils oder ganz über einen Gutschein bezahlt worden war, kann Dr.&nbsp;Plano anstatt einer Rückzahlung einen Gutschein ausstellen.</p>
							<p-collapsible>
								<span trigger><fa-icon class="mr-2" [icon]="enums.PlanoFaIconPool.AREA_TUTORIALS"/><span i18n>Möchtest du wissen, wie das genau funktioniert?</span></span>
								<div content class="card-body">
									<p><strong i18n>Dr.&nbsp;Plano erledigt automatisch folgende Schritte:</strong></p>
									<ol>
										<li class="pl-0" i18n>Dr.&nbsp;Plano erstellt einen neuen Gutschein: mit einem Guthaben in Höhe des Betrags, den der Kunde ursprünglich über einen oder mehrere Gutscheine eingelöst hatte (wie immer werden ggf. anfallende Stornogebühren vorher abgezogen).</li>
										<li class="pl-0" i18n>Dein Kunde erhält automatisch eine Email mit dem neuen Gutschein.</li>
										<li class="pl-0" i18n>Der Zahlungsstatus der stornierten Buchung wird automatisch korrigiert.</li>
										<li class="pl-0" i18n>Der ausgestellte Gutschein wird mit der stornierten Buchung verbunden, sodass der Vorgang jederzeit nachvollziehbar bleibt.</li>
										<li class="pl-0" i18n>Du wirst per Email über den Vorgang benachrichtigt, falls du das möchtest.</li>
									</ol>
									<p i18n>Und all das ohne dein Zutun! 💫</p>
								</div>
							</p-collapsible>
							<p-alert [theme]="enums.PThemeEnum.INFO" i18n>Der ausgestellte Gutschein hat dieselben Eigenschaften wie ein gewöhnlich über Dr.&nbsp;Plano erworbener Gutschein: Er entspricht deinen <a target="_blank" rel="noopener" routerLink="/client/plugin/gift-cards/settings">Gutschein-Einstellungen<fa-icon [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB" class="ml-1"/></a> und wird mit der üblichen <a target="_blank" rel="noopener" routerLink="/client/plugin/gift-cards/introduction" fragment="gift-card-pricing">Gebühr<fa-icon [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB" class="ml-1"/></a> abgerechnet. ☝️ Wir empfehlen die Rückzahlung per Gutschein nur, wenn deine Stornobedingungen oder eine individuelle Absprache mit deinem Kunden es zulassen.</p-alert>
							<p-ai-switch
								[pEditable]="false"
								label="Automatische Gutschein-Ausstellung"
								[group]="formGroup"
								[attributeInfo]="api.data.automaticBookingCancellationSettings.attributeInfoAutomaticGiftCardRefund"
							>
								<p-ai-switch-item
									label="Automatisch Gutscheine ausstellen, falls nötig" i18n-label
									[value]="true"
								></p-ai-switch-item>
								<p-ai-switch-item
									label="Keine automatische Gutschein-Ausstellung" i18n-label
									[value]="false"
								></p-ai-switch-item>
							</p-ai-switch>
							<p-alert
								*ngIf="api.data.automaticBookingCancellationSettings.automaticOnlineRefund && !api.data.automaticBookingCancellationSettings.automaticGiftCardRefund && api.data.giftCardSettings.isSaleEnabled"
								i18n
							>Wenn du die automatische Gutschein-Ausstellung deaktivierst, können Buchungen, die teils oder ganz über einen Gutschein bezahlt worden sind, in der Regel nicht mehr automatisch erstattet werden. Das liegt daran, dass automatische Rückzahlungen nur erfolgen, wenn der gesamte zu erstattende Betrag zurückgezahlt werden kann. Wenn allerdings eine Buchung teils oder ganz über einen Gutschein bezahlt worden ist, liegt in der Regel nicht genügend Online-Guthaben vor, um den gesamten Betrag zu erstatten.</p-alert>
							<p-ai-switch
								[pEditable]="false"
								label="Interne Notiz für den ausgestellten Gutschein" i18n-label
								description="Diese Notiz wird im neu ausgestellten Gutschein gespeichert. Sie ist nur für Dr.&nbsp;Plano-User sichtbar, aber nicht für deinen Kunden." i18n-description
								[group]="formGroup"
								[attributeInfo]="api.data.automaticBookingCancellationSettings.attributeInfoGiftCardInternalComment"
								[type]="AISwitchUIType.TEXTAREA"
							></p-ai-switch>
						</p-section>
					</ng-container>
					<ng-template #dontShowCancellationSettingsTemplate>
						<p-section>
							<p-alert [theme]="enums.PThemeEnum.INFO">Bei den von dir beabsichtigten Änderungen werden <mark>keine Buchungen betroffen</mark> sein. Daher gibt es hier nichts zu tun und du kannst weiter zum nächsten Schritt.</p-alert>
						</p-section>
					</ng-template>
				</ng-container>
			</ng-wizard-step>

			<ng-wizard-step
				title="Zusammenfassung" i18n-title
				#overviewStep
				[state]="STEP_STATE.normal"
			>
				<ng-container *ngIf="currentStepChange?.step?.index === overviewStep.index">
					<p-section [whitespace]="SectionWhitespace.NONE" class="px-md-5 py-4">
						<ng-container [ngSwitch]="typeOfChange">
							<h4 *ngSwitchCase="PTypeOfChange.CANCEL" i18n>Angebote stornieren</h4>
							<h4 *ngSwitchCase="PTypeOfChange.DELETE" i18n>Schichten löschen</h4>
							<h4 *ngSwitchDefault i18n>Zu ändernde Schichten</h4>
						</ng-container>

						<ng-container *ngIf="typeOfChange===PTypeOfChange.CANCEL; else listForNonCancelTemplate">
							<ul class="list-unstyled">
								<li *ngIf="!shift!.packetShifts.length"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>{{ shift!.model.name }} <mark>{{ shift!.start | date:'veryShortDate' }}, {{ shift!.start | date:AngularDatePipeFormat.SHORT_TIME }}</mark></ng-container></li>
								<li *ngFor="let packetShift of shift!.packetShifts.iterable()"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>{{ shift!.model.name }} <mark>{{ packetShift.start | date:'veryShortDate' }}</mark></ng-container></li>
							</ul>

							<p-alert *ngIf="!!shift!.packetShifts.length" i18n>Es werden <mark>alle Schichten des Pakets</mark> storniert. Wenn du einen einzelnen Termin stornieren möchtest, dann lösche die Schicht statt sie zu stornieren.</p-alert>
						</ng-container>
						<p-alert *ngIf="showManyShiftsAlert" [icon]="enums.PlanoFaIconPool.AREA_TUTORIALS" [theme]="enums.PThemeEnum.INFO" i18n><strong>Tipp fürs nächste Mal:</strong> Wenn du mehrere Schichten derselben Tätigkeit bearbeiten möchtest, kannst du unter Umständen viel Zeit sparen, indem du die Übertragungsfunktion nutzt. Dazu gehst du in irgendeine der zu ändernden Schichten, nimmst deine gewünschte Änderung vor und schon bekommst du die Abfrage, ob du die Änderung auf weitere Schichten übertragen möchtest. So brauchst du nicht mehr jede der Schichten einzeln zu selektieren 👍</p-alert>
						<ng-template #listForNonCancelTemplate>
							<p-grid *ngIf="!!shifts">
								<div class="col-lg-4 col-md-6" *ngFor="let shiftsByShiftModel of shiftsGroupedByShiftModel">
									<p-collapsible
										iconClasses="ml-2"
										triggerBtnClasses="pr-2"
										class="shadow-none"
										*ngIf="shiftsByShiftModel.get(0) as shifts">
										<div trigger class="d-flex w-100 align-items-center justify-content-between" style="min-width: 0;">
											<p-color-marker
												[hexColor]="shifts.model.color ? '#' + shifts.model.color : null"
												[isPacket]="shifts.model.isPacket ?? null"
												[title]="shifts.model.name"
											></p-color-marker>
											<div class="crop-on-overflow">{{shifts.model.name}}</div>
											<mark><code>{{shiftsByShiftModel.length}}</code></mark>
										</div>

										<div content>
											<div *ngFor="let shiftInGroup of sortedShiftsByDate(shiftsByShiftModel)" class="ml-3 my-2">
												<fa-icon class="text-danger" [icon]="enums.PlanoFaIconPool.TRASHED"></fa-icon> {{shiftInGroup.start | date:'veryShortDate' }}, {{shiftInGroup.start | date:'shortTime' }}
											</div>
										</div>
									</p-collapsible>
								</div>
							</p-grid>
							<ul *ngIf="!shift && !shifts" class="list-unstyled">
								<li><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>Die Vorlage <mark>{{ shiftModel!.name }}</mark></ng-container></li>
								<li *ngIf="!!shiftModel!.changeSelector.attributeInfoStart.isAvailable && !!shiftModel!.changeSelector.start"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>Bereits erstellte Schichten <mark>mit der Vorlage {{ shiftModel!.name }}</mark></ng-container>&ngsp;<ng-template [ngTemplateOutlet]="durationTemplate" [ngTemplateOutletContext]="{start: shiftModel!.changeSelector.start}"></ng-template></li>
							</ul>
							<ul *ngIf="!!shift" class="list-unstyled">
								<li><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>{{ shiftModel!.name }} <mark>{{ shift!.start | date:'veryShortDate' }}, {{ shift!.start | date:AngularDatePipeFormat.SHORT_TIME }}</mark></ng-container></li>
								<li *ngIf="!!optionToShiftModel"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>Die Vorlage <mark>{{ shiftModel!.name }}</mark></ng-container></li>
								<li *ngIf="showApplyToShiftPacketCheckbox && !!optionShiftsOfPacket"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>Bereits erstellte Schichten <mark>aus demselben Schicht-Paket</mark></ng-container>&ngsp;<ng-template [ngTemplateOutlet]="durationTemplate" [ngTemplateOutletContext]="{start: shiftChangeSelector!.attributeInfoStart.isAvailable ? shiftChangeSelector!.start : null, end: shiftChangeSelector!.attributeInfoEnd.isAvailable ? shiftChangeSelector!.end : null}"></ng-template></li>
								<!--PLANO-171520-->
								<li *ngIf="!!optionShiftsOfSeries && shiftChangeSelector!.attributeInfoStart.isAvailable && shiftChangeSelector!.attributeInfoEnd.isAvailable"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>Bereits erstellte Schichten <mark>aus derselben Wiederholung</mark></ng-container>&ngsp;<ng-template [ngTemplateOutlet]="durationTemplate" [ngTemplateOutletContext]="{start: shiftChangeSelector!.start, end: shiftChangeSelector!.end}"></ng-template></li>
								<!--PLANO-171520-->
								<li *ngIf="!!optionShiftsOfModel && shiftChangeSelector!.attributeInfoStart.isAvailable && shiftChangeSelector!.attributeInfoEnd.isAvailable"><fa-icon [icon]="typeRelatedIcon" [theme]="typeRelatedIconTheme"></fa-icon>&ngsp;<ng-container i18n>Bereits erstellte Schichten <mark>mit der Vorlage »{{ shiftModel!.name }}«</mark></ng-container>&ngsp;<ng-template [ngTemplateOutlet]="durationTemplate" [ngTemplateOutletContext]="{start: shiftChangeSelector!.start, end: shiftChangeSelector!.end}"></ng-template></li>
							</ul>
						</ng-template>

						<ng-template #durationTemplate let-start="start" let-end="end">
							<ng-container *ngIf="start!==null">&ngsp;<ng-container i18n>ab</ng-container>&ngsp;<mark>{{start | date:'veryShortDate'}}</mark></ng-container>
							<ng-container *ngIf="!!end && end!==null">&ngsp;<ng-container i18n>bis</ng-container>&ngsp;<mark>{{end - 1 | date:'veryShortDate'}}</mark></ng-container>
						</ng-template>

					</p-section>

					<p-section
						*ngIf="showCancellationSettings"
						label="Buchungen stornieren" i18n-label
						pAnchorLink="cancel-bookings"
						[whitespace]="SectionWhitespace.NONE"
						class="px-md-5 py-4"
					>
						<ul class="list-unstyled">
							<!--PLANO-171520-->
							<li *ngIf="affectedShiftsApiService.isLoaded()"><fa-icon [icon]="!affectedShiftsApiService.data.attributeInfoBookingsCanceledCount.isAvailable || !affectedShiftsApiService.data.bookingsCanceledCount ? 'minus' : enums.PlanoFaIconPool.CANCELED" [theme]="!affectedShiftsApiService.data.attributeInfoBookingsCanceledCount.isAvailable || !affectedShiftsApiService.data.bookingsCanceledCount ? null : enums.PThemeEnum.DANGER"></fa-icon>&ngsp;<span *ngIf="affectedShiftsApiService.data.attributeInfoBookingsCanceledCount.isAvailable && affectedShiftsApiService.data.bookingsCanceledCount > 0"><mark>{{ affectedShiftsApiService.data.bookingsCanceledCount }}</mark></span> {!affectedShiftsApiService.data.attributeInfoBookingsCanceledCount.isAvailable || affectedShiftsApiService.data.bookingsCanceledCount, plural, =0 {Es werden keine Buchungen} one {Buchung wird} other {Buchungen werden}} storniert.</li>
							<li *ngIf="affectedShiftsApiService.isLoaded() && affectedShiftsApiService.data.bookingsDeclinedCount > 0"><fa-icon [icon]="!affectedShiftsApiService.data.attributeInfoBookingsDeclinedCount.isAvailable || !affectedShiftsApiService.data.bookingsDeclinedCount ? 'minus' : enums.PlanoFaIconPool.CANCELED" [theme]="!affectedShiftsApiService.data.attributeInfoBookingsDeclinedCount.isAvailable || !affectedShiftsApiService.data.bookingsDeclinedCount ? null : enums.PThemeEnum.DANGER"></fa-icon>&ngsp;<mark *ngIf="affectedShiftsApiService.data.attributeInfoBookingsDeclinedCount.isAvailable">{{ affectedShiftsApiService.data.bookingsDeclinedCount }}</mark> {affectedShiftsApiService.data.attributeInfoBookingsDeclinedCount.isAvailable ? affectedShiftsApiService.data.bookingsDeclinedCount : 0, plural, one {Anfrage wird} other {Anfragen werden}} abgelehnt.</li>
						</ul>
					</p-section>
					<p-section *ngIf="showCancellationSettings && affectedShiftsApiService.isLoaded() && affectedShiftsApiService.data.attributeInfoBookingsCanceledCount.isAvailable && !!affectedShiftsApiService.data.bookingsCanceledCount" label="Gebühren & Rückerstattungen" i18n-label pAnchorLink="fees-and-refunds" [whitespace]="SectionWhitespace.NONE" class="px-md-5 py-4">
						<ul class="list-unstyled">
							<li *ngIf="!api.data.automaticBookingCancellationSettings.attributeInfoNoCancellationFees.value; else noCancellationFeeResultTemplate"><fa-icon [icon]="enums.PlanoFaIconPool.SUCCESS" [theme]="enums.PThemeEnum.SUCCESS"></fa-icon>&ngsp;<ng-container i18n>Hinterlegte Stornogebühren werden angerechnet.</ng-container></li>
							<ng-template #noCancellationFeeResultTemplate>
								<li><fa-icon [icon]="'minus'"></fa-icon>&ngsp;<ng-container i18n>Es werden keine Stornogebühren angerechnet.</ng-container></li>
							</ng-template>
							<li *ngIf="!!api.data.automaticBookingCancellationSettings.attributeInfoAutomaticOnlineRefund.value; else noAutomaticOnlineRefundTemplate"><fa-icon [icon]="enums.PlanoFaIconPool.SUCCESS" [theme]="enums.PThemeEnum.SUCCESS"></fa-icon>&ngsp;<ng-container i18n>Automatische Online-Rückerstattung erfolgt bei Buchungen, wo es nötig ist.</ng-container></li>
							<ng-template #noAutomaticOnlineRefundTemplate>
								<li><fa-icon [icon]="'minus'"></fa-icon>&ngsp;<ng-container i18n>Es erfolgt keine automatische Online-Rückerstattung.</ng-container></li>
							</ng-template>
							<li *ngIf="!!api.data.automaticBookingCancellationSettings.attributeInfoAutomaticGiftCardRefund.value; else noAutomaticGiftCardRefundTemplate"><fa-icon [icon]="enums.PlanoFaIconPool.SUCCESS" [theme]="enums.PThemeEnum.SUCCESS"></fa-icon>&ngsp;<ng-container i18n>Automatische Gutschein-Ausstellung erfolgt bei Buchungen, wo es nötig ist.</ng-container></li>
							<ng-template #noAutomaticGiftCardRefundTemplate>
								<li><fa-icon [icon]="'minus'"></fa-icon>&ngsp;<ng-container i18n>Es erfolgt keine automatische Gutschein-Ausstellung.</ng-container></li>
							</ng-template>
							<li *ngIf="!!api.data.automaticBookingCancellationSettings.attributeInfoAutomaticOnlineRefund.value" i18n class="form-text">Rückerstattungen erfolgen nur bei ausreichendem Online-Guthaben. Nach der Ausführung erhältst du eine Email darüber, welche Buchungen erstattet werden konnten.</li>
						</ul>
					</p-section>

					<p-section *ngIf="showNotificationCheckbox" label="User Informieren" i18n-label pAnchorLink="notify-user" [whitespace]="SectionWhitespace.NONE" class="px-md-5 py-4">
						<!-- <ul class="list-unstyled">
							<li *ngIf="api.data.notificationsConf.sendEmail"><fa-icon [icon]="enums.PlanoFaIconPool.PUSH_NOTIFICATION" [theme]="enums.PThemeEnum.PRIMARY"></fa-icon>&ngsp;<ng-container i18n>Ja</ng-container></li>
							<li *ngIf="!api.data.notificationsConf.sendEmail"><fa-icon [icon]="enums.PlanoFaIconPool.NO_PUSH_NOTIFICATION"></fa-icon>&ngsp;<ng-container i18n>Nein</ng-container></li>
						</ul> -->

						<div class="row">
							<div class="col-lg-6">
								<p-notification-conf-form
									class="mb-3 d-block"
									[(ngModel)]="api.data.notificationsConf.sendEmail"
									[api]="api"
									[shift]="shift"
								></p-notification-conf-form>
							</div>
						</div>
						<p-alert [theme]="enums.PThemeEnum.INFO" *ngIf="typeOfChange===PTypeOfChange.CANCEL && api.data.notificationsConf.sendEmail" i18n>Eingeteilte User werden aus ihren Schichten <mark>nicht</mark> entfernt, sondern lediglich über die Stornierung informiert.</p-alert>
					</p-section>

					<p-section [@shakeSideways]="shakeSidewaysTrigger" [background]="enums.PThemeEnum.LIGHT" class="card unlock-section mx-md-5" *ngIf="!!showCaptureRequest">
						<label class="capture-request-hint d-flex flex-row align-items-center flex-wrap gap-2 m-0" aria-labelledby="confirmation-input-label">
							<span id="confirmation-input-label">
								<fa-icon [icon]="'lock'" class="unlock mr-2 align-self-center"></fa-icon>
								<ng-container i18n>Tippe hier <strong>»{{ captureRequest }}«</strong> ein, um die Aktion freizuschalten</ng-container>
							</span>
							<p-input class="flex-grow-1" [hasAutoFocusPriority]="true" [(ngModel)]="captureInput" (onKeyUp)="onCaptureInputKeyUp($event)"></p-input>
						</label>
					</p-section>
				</ng-container>
			</ng-wizard-step>
		</ng-wizard>
	</section>
</div>
</ng-template>

<div #ngContent [class.d-none]="!hasNgContent" class="mt-3">
	<ng-content></ng-content>
</div>

<div class="modal-footer">
	<button
		type="button"
		class="btn btn-outline-secondary"
		(click)="dismissModal($event)"
		title="Abbrechen" i18n-title
	>
		<fa-icon class="d-xs-none" [icon]="enums.PlanoFaIconPool.UNDO"/>
		<span class="d-xs-block d-none" i18n>Abbrechen</span>
	</button>

	<div class="btn-group">
		<button
			*ngIf="numberOfSteps > 1"
			[disabled]="currentStepChange?.position === undefined || currentStepChange?.position === STEP_POSITION.first"
			type="button" class="btn btn-outline-secondary ml-auto"
			(click)="showPreviousStep()"
			i18n
		>Zurück</button>
		<button
			*ngIf="currentStepChange?.position !== STEP_POSITION.final && numberOfSteps > 1"
			type="button" class="btn btn-primary ml-auto"
			[disabled]="continueButtonDisabled"
			(click)="showNextStep()"
			i18n
			[hasAutoFocusPriority]="true"
			pAutoFocus
		>Weiter</button>
		<button
			*ngIf="(currentStepChange?.position === STEP_POSITION.final || numberOfSteps === 1)"
			pAutoFocus
			type="button" class="btn ml-auto"
			[class.btn-success]="typeOfChange === PTypeOfChange.EDIT"
			[class.btn-danger]="typeOfChange !== PTypeOfChange.EDIT"
			[disabled]="submitBtnIsDisabled"
			(click)="submit();"
			i18n
		>{typeOfChange === PTypeOfChange.DELETE && affectedShiftsApiService.isLoaded() && affectedShiftsApiService.data.attributeInfoBookingsCanceledCount.isAvailable && affectedShiftsApiService.data.bookingsCanceledCount > 0, select, true {Löschen & Stornieren} other {{{captureRequest}}}}</button>
	</div>

</div>
