<div
	class="o-hidden pl-2 h-100 shift-item-timeline"
	[class.selectable]="selectable"
	[style.border-color]="highlightService.isHighlighted(shift) ? '#' + hexColor : ''"
	[style.background-color]="shift.selected ? '#f3dbc3' : ''"

	[pTooltip]="shiftItemTooltip"
	[pTooltipShow]="showTooltip"
	[pTooltipPlacement]="NgxPopperjsPlacements.AUTOSTART"
	[pTooltipAppendToBody]="false"
	pTooltipTrigger="custom"
	[pTooltipTheme]="null"
	[pTooltipArrowBehind]="true"
	[pTooltipArrowColor]="shift.color ? '#' + shift.color : null"

	[class.bg-muted]="!shift.selected && muteItem"
	[class.muted-item]="!CONFIG.IS_MOBILE ? muteItem : false"
	[class.is-in-the-past]="!CONFIG.IS_MOBILE && isInThePast && !highlightService.isHighlighted(shift)"
>
	<p-color-marker
		[rounded]="BootstrapRounded.LEFT"
		[item]="shift"
		[title]="shift.name"
		[hexColor]="shift.color ? '#' + shift.color : null"
		[isPacket]="shift.isPacket"
	></p-color-marker>
	<div class="crop-on-overflow d-flex">
		<p-multi-select-checkbox
			[@slideHorizontal]
			*ngIf="multiSelectIsPossible"
			class="border-bottom border-right"
			[class.d-none]="CONFIG.IS_MOBILE && !showMultiSelectCheckbox"
			[class.show-on-hover]="showMultiSelectCheckbox === 'only-on-hover'"
			[ngModel]="shift.selected"
			(ngModelChange)="shift.selected=!shift.selected;selectedChange.emit(shift.selected);"
			[myPref]="shift.myPref"
			[meIsAssignable]="meIsAssignable"
			[earlyBirdMode]="schedulingService.earlyBirdMode"
			[wishPickerMode]="schedulingService.wishPickerMode"
			[disabled]="!shiftIsSelectable"
			(onClick)="$event.stopPropagation()"
		></p-multi-select-checkbox>
		<p-assign-me-button
			*ngIf="showAssignMeButton"
			[shift]="shift"
		></p-assign-me-button>

		<div
			*ngIf="highlightService.showWishIcon(shift)"
			class="pl-2 pr-2 pt-1 pb-1 d-flex align-items-center"
		>
			<p-member-wish-icon [shift]="shift"></p-member-wish-icon>
		</div>
		<div class="pl-2 pr-2 pt-2 pb-1">
			<span class="text-monospace">{{ shift.start | date:'veryShortTime' }}</span>
			<strong class="ml-1">{{ shift.name }}</strong>
		</div>
	</div>
	<p-course-info
		*ngIf="showCourseInfo"
		[isCourse]="!!shift.isCourse"
		[isCourseCanceled]="shift.isCourseCanceled"
		[currentCourseParticipantCount]="shift.currentCourseParticipantCount"
		[minCourseParticipantCount]="shift.minCourseParticipantCount"
		[maxCourseParticipantCount]="shift.maxCourseParticipantCount"
		[courseType]="shift.model ? shift.model.courseType : undefined!"
		[isCourseOnline]="shift.isCourseOnline"
		[onlyWholeCourseBookable]="shift.model.onlyWholeCourseBookable"
	></p-course-info>

	<div class="card-body pt-1">

		<div class="d-flex align-items-center mb-1">
			<!-- NOTE: Niedrige prio hier -->
			<ng-container *ngTemplateOutlet="memberBadgesTemplate"></ng-container>
			<ng-container *ngTemplateOutlet="processStatusIconTemplate"></ng-container>
			<ng-container *ngTemplateOutlet="shiftExchangeIconsTemplate"></ng-container>
			<!-- NOTE: Hohe prio hier -->
		</div>
		<div class="shift-comment text-muted" [innerHTML]="textToHtml(shift.description ?? '')"></div>
	</div>
</div>

<ng-template #shiftItemTooltip>
	<p-shift-item-tooltip
		[readMode]="readMode"
		[shift]="shift"
		(onClickRemove)="onClickRemove.emit($event)"
		(onClose)="onCloseShiftTooltip($event)"
		[showProcessStatusIcon]="showProcessStatusIcon"
		[quickAssignmentTemplate]="quickAssignmentTemplate"
		[linkedCourseInfoTemplate]="linkedCourseInfoTemplate"
		[processInfoTemplate]="null"
	></p-shift-item-tooltip>
</ng-template>

