/* eslint-disable unicorn/prevent-abbreviations */
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Config } from '@plano/shared/core/config';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/**
 * This component let you show a dr-plano email. It is save against spam crawlers.
 */
@Component({
	selector: 'p-you-know-what[user]',
	templateUrl: './you-know-what.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class YouKnowWhatComponent {

	/**
	 * The user name of the email e.g. "john.doe". The produced
	 * email will then be "john.doe@dr-plano.com".
	 */
	@Input() private user ! : string;

	/**
	 * The subject of the email.
	 */
	@Input() private topic : string | null = null;

	/**
	 * The text inside the a tag, that the user should click on.
	 * If not set, the email address will be shown.
	 */
	@Input() private content : string | null = null;

	/**
	 * Css classes for the a tag.
	 */
	@Input() private linkClass : string = '';

	/**
	 * A appended icon.
	 */
	@Input() private icon : PlanoFaIconPoolValues | null = null;

	/**
	 * Open email client with a new email draft
	 */
	@HostListener('click') protected onClick() : void {
		const s = this.topic ? `?subject=${this.topic}` : '';

		const link = `mailto:${this.user}@dr-plano.com${s}`;

		// let app handle this?
		if (Config.platform === 'appAndroid' || Config.platform === 'appIOS') {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).nsWebViewInterface.emit('externalLink', link);
		} else {
			// otherwise just open
			window.location.href = link;
		}
	}

	constructor(
		private domSanitizer : DomSanitizer,
	) {}

	private get iconCode() : string | null {
		const eIcon = Array.isArray(this.icon) ? this.icon[1] : this.icon;
		if (eIcon) {
			const eIconStyle = Array.isArray(this.icon) ? this.icon[0] : 'duotone';
			return `<span class="ml-1 fa-fw fa-${eIconStyle} fa-${eIcon}"></span>`;
		}
		return null;
	}

	private get contentCode() : string {
		if (this.content) {
			return this.content;
		} else {
			const e2 = 'dr-plano';
			const e3 = 'com';
			return `${this.user}<span>&#64;</span>${e2}&#46;${e3}`; // NOTE: No i18n
		}
	}

	/**
	 * This makes it difficult for parsers to get our email address
	 */
	public get getATagCode() : SafeHtml {
		// This is the text shown on the website. By default the email address is shown.
		const e = this.contentCode;

		// This is the icon appended to the text.
		const i = this.iconCode ?? '';

		const a1 = `<a class="nowrap ${this.linkClass}">`;
		const a2 = '</a>';

		const result = a1 + e + i + a2;
		return this.domSanitizer.bypassSecurityTrustHtml(result);
	}
}
