import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Directive to wrap elements with the class .input-group-append
 */
@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'input[ngbTypeahead]',
})
export class ClearOnFocusInputDirective {
	@HostListener('focus', ['$event']) private onFocus() : void {
		this.clear();
	}

	constructor(
		private elementRef : ElementRef<HTMLInputElement>,
	) {}

	private clear() : void {
		this.elementRef.nativeElement.value = '';
		const event = new Event('change');
		this.elementRef.nativeElement.dispatchEvent(event);
	}
}
