import { Component, Input } from '@angular/core';
import { MeService, RightsService } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';

@Component({
	selector: 'p-external-link',
	templateUrl: './p-external-link.component.html',
	styleUrls: ['./p-external-link.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PExternalLinkComponent {

	/**
	 * The link to be used in the external link, this will override the owner_link and
	 * employee_link if passed.
	 */
	@Input() public link : string | null = null;

	/**
	 * Link to be used when the logged in user is an owner
	 */
	@Input('link_for_admins_and_managers') public linkForAdminsAndManagers : string | null = null;

	/**
	 * Link to be used when the logged in user is an employee
	 */
	@Input('link_for_employees') public linkForEmployees : string | null = null;

	constructor(
		private meService : MeService,
		private console : LogService,
		private rightsService : RightsService,
	) {

	}

	/**
	 * Get the external link to be used in this component
	 */
	public get externalLink() : string | null {
		if (this.link) return this.link;
		if (!this.meService.isLoaded()) return '';
		if (this.linkForAdminsAndManagers && (this.meService.data.isOwner || this.rightsService.hasManagerRights)) return this.linkForAdminsAndManagers;
		if (this.linkForEmployees && !(this.meService.data.isOwner || this.rightsService.hasManagerRights)) return this.linkForEmployees;
		this.console.error('No matching link was found for external link!');
		return null;
	}
}
