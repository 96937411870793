/* eslint max-lines: ["error", 130] */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CanActivateAccessControlGuard } from '@plano/client/can-activate-access-control.guard';
import { CanActivateAccountGuard } from '@plano/client/can-activate-account.guard';
import { CanActivateDevicesGuard } from '@plano/client/can-activate-devices.guard';
import { SettingsMenuForMobileComponent } from '@plano/client/menu/settings-menu-for-mobile/settings-menu-for-mobile.component';
import { PageNotFoundComponent } from '@plano/shared/page-not-found/page-not-found.component';
import { PRoutes } from '@plano/template-page-title-strategy.service';
import { AccessControlComponent } from './accesscontrol/accesscontrol.component';
import { ClientComponent } from './client.component';
import { LogoutComponent } from './header/logout/logout.component';
import { ReportComponent } from './report/report.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { ShiftExchangeComponent } from './shift-exchange/shift-exchange.component';
import { ShiftComponent } from './shift/shift.component';
import { TimeStampComponent } from './time-stamp/time-stamp.component';

export const ROUTES : PRoutes = [
	{
		path: 'client',
		component: ClientComponent,
		children:
		[
			// { path: 'time-stamp', loadChildren: () => import('./time-stamp/time-stamp.module').then( (m) => m.TimeStampModule ) },
			{ path: 'time-stamp', title:'Stempeluhr', component: TimeStampComponent },
			{ path: 'tutorials', title:'Tutorial Videos', loadChildren: () => import('./tutorials/tutorials.module').then( m => m.TutorialsModule ) },
			{ path: 'menu', loadChildren: () => import('./menu/menu.module').then( m => m.MenuModule ) },
			{ path: 'desk', redirectTo: 'mobile-sidebar'},
			{ path: 'mobile-sidebar', loadChildren: () => import('./mobile-sidebar/mobile-sidebar.module').then( m => m.MobileSidebarModule ) },
			{ path: 'profile-settings', title: 'Profil & Einstellungen', component: SettingsMenuForMobileComponent},
			{ path: 'absence', loadChildren: () => import('./absence/absence.module').then( m => m.AbsenceModule ) },
			{ path: 'booking', loadChildren: () => import('./booking/booking.module').then( m => m.BookingModule ) },
			{ path: 'sales', title:'Verkäufe', loadChildren: () => import('./sales/sales.module').then( m => m.SalesModule ) },
			{ path: 'shiftmodel', title:'Tätigkeit', loadChildren: () => import('./shiftmodel/shiftmodel.module').then( m => m.ShiftModelModule ) },
			{ path: 'email', loadChildren: () => import('./email/email.module').then( m => m.EmailModule ) },
			{ path: 'member', loadChildren: () => import('./member/member.module').then( m => m.MemberModule ) },
			{ path: 'workingtime', loadChildren: () => import('./workingtime/workingtime.module').then( m => m.WorkingtimeModule ) },
			{ path: 'ical', title: 'Kalender-Synchronisation', loadChildren: () => import('./ical/ical.module').then( m => m.IcalModule ) },
			{ path: 'notifications', title:'Benachrichtigungs-Einstellungen', loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsModule ) },
			{ path: 'account', canActivate:[CanActivateAccountGuard], title:'Account & Standort', loadChildren: () => import('./account/account.module').then( m => m.AccountModule ) },
			{ path: 'shift-exchanges', title:'Tauschbörse', loadChildren: () => import('./shift-exchanges/shift-exchanges.module').then( m => m.ShiftExchangesModule ) },

			{ path: 'gift-card', loadChildren: () => import('./gift-card/gift-card.module').then( m => m.GiftCardModule ) },
			{ path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then( m => m.TransactionModule ) },

			// FIXME: Something with providedIn: 'root' is broken
			{ path: 'shift-exchange', title:'Ersatzsuche', component: ShiftExchangeComponent },
			{ path: 'shift-exchange/create/:shiftId', title:'Neue Ersatzsuche', component: ShiftExchangeComponent },
			{ path: 'shift-exchange/create/:shiftId/member/:memberId', title:'Neue Ersatzsuche', component: ShiftExchangeComponent },
			{ path: 'shift-exchange/:id', title:'Ersatzsuche', component: ShiftExchangeComponent },

			// FIXME: Something with providedIn: 'root' is broken
			// { path: 'shift-exchange', loadChildren: () => import('./shift-exchange/shift-exchange.module').then( (m) => m.ShiftExchangeModule ) },
			// eslint-disable-next-line literal-blacklist/literal-blacklist
			{ path: 'testaccount', title:'Echt-Account bestellen', loadChildren: () => import('./test-account/test-account.module').then( m => m.TestAccountModule ) },
			{ path: 'devices', canActivate:[CanActivateDevicesGuard], title:'Stempeluhr-Einstellungen', loadChildren: () => import('./devices/devices.module').then( m => m.DevicesModule ) },

			{
				path: 'shift',
				children: [
					{ path: '', redirectTo: '0', pathMatch: 'full' },
					{ path: '0', title:'Neue Schicht', component: ShiftComponent },
					{ path: 'create', children: [
						{ path: '', title:'Neue Schicht', redirectTo: '0', pathMatch: 'full' },
						{ path: 'shiftmodel', children: [
							{ path: '', title:'Neue Schicht', redirectTo: '0', pathMatch: 'full' },
							{ path: ':shiftmodelid', children: [
								{ path: '', redirectTo: 'undefined', title:'Neue Schicht', pathMatch: 'full'},
								{ path: ':openTab', title:'Neue Schicht', component: ShiftComponent },
								{ path: 'start', children: [
									{ path: '', redirectTo: '0', pathMatch: 'full' },
									{ path: ':start/:openTab', title:'Neue Schicht', component: ShiftComponent },
									{ path: ':start', redirectTo: ':start/undefined', title:'Neue Schicht', pathMatch: 'full'},
								] },
							] },
						] },
						{ path: 'start', children: [
							{ path: ':start/:openTab', title:'Neue Schicht', component: ShiftComponent },
							{ path: ':start', redirectTo: ':start/undefined', pathMatch: 'full', title:'Neue Schicht' },
						] },
					] },
					{ path: '0/:openTab', title:'Neue Schicht', component: ShiftComponent },
					{ path: ':id/:openTab', title:'Schicht', component: ShiftComponent },
					{ path: ':id', redirectTo: ':id/undefined', pathMatch: 'full', title:'Schicht'},

				],
			},

			{ path: 'scheduling', title:'Kalender', component: SchedulingComponent, pathMatch: 'full' },
			{
				path: 'scheduling/:calendarMode',
				redirectTo: 'scheduling/:calendarMode/0',
				pathMatch: 'full',
			},
			{
				path: 'scheduling/undefined/:date',
				redirectTo: 'scheduling/month/:date',
				pathMatch: 'full',
			},
			{
				title:'Kalender',
				path: 'scheduling/:calendarMode/:date',
				component: SchedulingComponent,
			},
			{ path: 'report/:start/:end/:memberId', title:'Auswertung', component: ReportComponent },
			{ path: 'report/:start/:end', title:'Auswertung', component: ReportComponent },
			{ path: 'report/:start', redirectTo: 'report/:start/0', pathMatch: 'full' },
			{ path: 'report', redirectTo: 'report/0/0', pathMatch: 'full' },
			{ path: 'plugin', title:'Buchungssystem-Einstellungen', loadChildren: () => import('./plugin/plugin.module').then( m => m.PluginModule ) },
			{ path: 'rightgroups/:openTab', title:'Rechteverwaltung', canActivate:[CanActivateAccessControlGuard], component: AccessControlComponent },
			{ path: 'rightgroups', redirectTo: 'rightgroups/undefined', title:'Rechteverwaltung'},
			{ path: 'logout', component: LogoutComponent },
			{ path: '', redirectTo: 'scheduling', pathMatch: 'full' },
			{ path: '**', component: PageNotFoundComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(ROUTES)],
	exports: [RouterModule],
})
export class ClientRoutingModule {}
