import { Injectable } from '@angular/core';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { MeService, RightsService, SchedulingApiService, SchedulingApiShifts } from '@plano/shared/api';
import { ThreeParameterData } from '@plano/shared/core/data/parameter-data/three-parameter-data';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PCalendarService {
	constructor(
		public api : SchedulingApiService,
		private meService : MeService,
		private schedulingService : SchedulingService,
		private filterService : FilterService,
		private rightsService : RightsService,
		private pMoment : PMomentService,
	) {
	}

	/**
	 * Check if there is a memo for given day or a shift-description for the current user for the shifts of the given day.
	 */
	public hasImportantNoteForDay(startOfDay ?: number, onlyForUser : boolean = true) : boolean | null {
		// If no startOfDay is defined, today will be checked.

		if (!this.api.isLoaded()) return null;

		// Has comment for the day?
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		const memo = this.api.data.memos.getByDay(startOfDay ? startOfDay : +this.pMoment.m().startOf('day'));
		if (memo?.message) return true;

		const hasImportantShiftDescriptions = this.shiftsOfDayHaveDescriptions(startOfDay, {
			onlyForUser : onlyForUser,
			onlyIfNotInAProcess : !this.rightsService.userCanSetAssignmentProcesses,
		});

		if (hasImportantShiftDescriptions) return true;
		return false;
	}

	private _shiftsOfDayHaveDescriptions : ThreeParameterData<boolean, number, boolean, boolean> =
		new ThreeParameterData<boolean, number, boolean, boolean>(this.api, this.filterService);

	/**
	 * Check if shiftsOfDay have any descriptions
	 */
	public shiftsOfDayHaveDescriptions(startOfDay : number | undefined, input : {
		onlyForUser ?: boolean,
		onlyIfNotInAProcess ?: boolean,
	} = {}) : boolean | null {
		// If no startOfDay is defined, today will be checked.
		if (!this.meService.isLoaded()) return null;
		if (startOfDay === undefined) {
			// TODO: PLANO-156519
			if (!this.api.data.attributeInfoTodaysShiftDescriptions.isAvailable) return false;
			const relatedDescriptions = this.api.data.todaysShiftDescriptions.findBy((item) => {
				return !input.onlyForUser || item.isRequesterAssigned;
			});
			return !!relatedDescriptions;
		}

		input.onlyForUser = input.onlyForUser !== undefined ? input.onlyForUser : false;
		input.onlyIfNotInAProcess = !!input.onlyIfNotInAProcess;

		return this._shiftsOfDayHaveDescriptions.get(
			startOfDay,
			input.onlyForUser,
			input.onlyIfNotInAProcess,
			() : boolean => {
				if (!startOfDay) return false;

				const shifts = this.shiftsOfDay(startOfDay);
				if (!shifts.length) return false;

				let shiftsToCheck : SchedulingApiShifts;
				if (input.onlyForUser) {
					if (!this.meService.isLoaded()) return false;
					shiftsToCheck = shifts.filterBy((item) =>
						!!item.attributeInfoAssignedMemberIds.isAvailable && item.assignedMemberIds.contains(this.meService.data.id));
				} else {
					shiftsToCheck = shifts;
				}

				// TODO: PLANO-156519
				if (this.api.data.attributeInfoAssignmentProcesses.isAvailable) {
					const assignmentProcesses = this.api.data.assignmentProcesses;
					const result = shiftsToCheck.filterBy((item) => !!item.description).iterable().find(shift => {
						return !input.onlyIfNotInAProcess || !assignmentProcesses.getByShiftId(shift.id);
					});
					return !!result;
				}

				return false;
			},
		);
	}

	/**
	 * Get all shifts if a day
	 * @param day - timestamp of the day to get the shifts of
	 */
	private shiftsOfDay(day ?: number) : SchedulingApiShifts {
		const timestamp = day ?? this.schedulingService.urlParam.date!;
		if (!this.api.isLoaded()) return new SchedulingApiShifts(null, null);
		if (!this.schedulingService.urlParam.date) return new SchedulingApiShifts(null, null);

		// TODO: Can this be replaced by
		// const filteredShifts = this.api.data.shifts.filterBy((item) => this.filterService.isVisible(item));
		// ?
		const filteredShifts = this.api.data.shifts.filterByFilterService(this.filterService);

		return filteredShifts.getByDay(timestamp);
	}
}
