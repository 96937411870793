
<p class="p-4">This page visualizes some attributes in <code>ai_switch_showcase.xml</code> in specific modes, that a plain ai could not capture. We can execute here general tests of the ai-switch component's behaviour (e.g. validation error tests).</p>
<p-ai-form-group
	*ngIf="aiSwitchShowcaseApi.isLoaded()"
	class="p-5 w-50"
	[formParent]="formGroup"
	[attributeInfo]="aiSwitchShowcaseApi.data.attributeInfoThis"
	#AIFormArrayRef
>
	<p-ai-switch
		[pEditable]="false"
		label="Input for a deadline in »Days Before« mode which primitive type »DateTime«"
		description="Falls du keine Frist setzt, läuft die Ersatzsuche bis zum Ende der betroffenen Schicht.<br/>Bei einer Ersatzsuche für <strong>mehrere Schichten</strong> bezieht sich die Frist auf die Schicht, die als <strong>letztes</strong> startet; endet eine der Schichten, dann wird sie automatisch aus der Ersatzsuche <strong>entfernt</strong>, aber die Suche für die verbleibenden Schichten läuft weiter." i18n-description
		[inputDateType]="PInputDateTypes.deadline"
		[group]="AIFormArrayRef.childGroup"
		[attributeInfo]="aiSwitchShowcaseApi.data.validations.max.valid.compareConst.attributeInfoDateTimeThisIsNull"
		[showDaysBeforeInput]="true"
		[(daysBefore)]="daysBefore"
		daysBeforeLabel="Tage vor Schicht"
	></p-ai-switch>

	<p-ai-switch
		label="Duration-Input with a dropdown which contains a option for »null«"
		[group]="AIFormArrayRef.childGroup"
		[attributeInfo]="aiSwitchShowcaseApi.data.isNull.attributeInfoDuration"
		[pEditable]="true"
		[options]="[{
			text : 'Jahre ab Ende des Buchungsjahres',
			value : AI_SWITCH_OPTION_REQUIRED
		}, {
			text : 'Unbegrenzt',
			value : null
		}]"
	></p-ai-switch>

	<p-ai-switch
		label="Input with a modal as saveChangesHook"
		[group]="AIFormArrayRef.childGroup"
		[attributeInfo]="aiSwitchShowcaseApi.data.allTypes.attributeInfoString"
		[pEditable]="true"
		[saveChangesHook]="saveChangesHook"
	></p-ai-switch>

	<!-- NOTE: Use this as a starting-point when you want to add a specific ai temporarily to the custom page -->
	<!-- <p-ai-switch
		[label]="aiSwitchShowcaseApi.data.validations.integer.fails.attributeInfoHasDecimalPlaces.id"
		[group]="AIFormArrayRef.childGroup"
		[attributeInfo]="aiSwitchShowcaseApi.data.validations.integer.fails.attributeInfoHasDecimalPlaces"
		[pEditable]="true"
	></p-ai-switch> -->
</p-ai-form-group>
