<!-- style="background: linear-gradient(#f0f0f0, #e0e0e0)" -->
<li
	class="card-options p-0 border-0"
	[class.list-group-item]="hasOnClickBinding"
	[class.list-group-item-action]="hasOnClickBinding"
	[class.bg-light]="!hasOnClickBinding"
	tabindex="0"
	(click)="onClick.emit($event)"
>
	<div class="py-1 px-2 w-100 d-flex align-items-center justify-content-center gap-2"
	[class.btn-light]="hasOnClickBinding"
	style="min-height:27px">
		<ng-content select="[headline-icon]"></ng-content>
		<h6
			[class.flex-grow-1]="headlineIconAlign === 'left'"
			[class.text-danger]="theme === enums.PThemeEnum.DANGER"
		><ng-container
				*ngIf="textTemplate; else simpleTextTemplate">
				<ng-container *ngTemplateOutlet="textTemplate"></ng-container>
			</ng-container><ng-template #simpleTextTemplate>{{ textString }}</ng-template></h6
		>
		<p-icon-tooltip *ngIf="textTooltipHtml"><div [innerHTML]="textTooltipHtml | pSafeHtml"></div></p-icon-tooltip>
	</div>

	<ng-content></ng-content>
	<span *ngIf="showHearts" class="card-option pt-0 pb-0 d-flex align-items-center justify-content-center"
	[pTooltip]="tooltipHeart"
	><fa-icon icon="heart"></fa-icon></span>
	<!-- placement="bottom" -->
	<ng-template #tooltipHeart>
		<div class="d-block clickable card m-0 text-left o-hidden">
			<div class="card-body p-1 pl-2 pr-2">
				<div i18n>Schichtwünsche deiner Mitarbeitenden, die von Dr.&nbsp;Plano bei der automatischen Verteilung berücksichtigt werden. Auch du kannst sie nutzen, wenn du jemanden händisch für eine Schicht eintragen möchtest.</div>
			</div>
		</div>
	</ng-template>
	<ng-template [ngIf]="showMembersHeadlineCardOptions">

		<span class="card-option pt-0 pb-0 d-flex align-items-center justify-content-center pl-1 pr-1 no-overflow-wrap"
			[pTooltip]="tooltipDarf"
			i18n
		>darf</span>
		<!-- placement="bottom" -->
		<ng-template #tooltipDarf>
			<div class="d-block clickable card m-0 text-left o-hidden"
			>
				<div class="card-body p-1 pl-2 pr-2">
					<div i18n>Wähle die Personen, die diese Tätigkeit generell ausüben dürfen. Sie werden bei der automatischen Schichtverteilung von Dr.&nbsp;Plano berücksichtigt.</div>
				</div>
			</div>
		</ng-template>

		<span class="card-option pt-0 pb-0 d-flex align-items-center justify-content-center pl-1 pr-1 no-overflow-wrap"
			[pTooltip]="tooltipMacht"
			i18n
		>macht</span>
		<ng-template #tooltipMacht>
			<div class="d-block clickable card m-0 text-left o-hidden"
			>
				<div class="card-body p-1 pl-2 pr-2">
					<div i18n>Bestimme, ob diese Tätigkeit immer von einer bestimmten Person ausgeführt werden soll. Dr.&nbsp;Plano berücksichtigt das bei der automatischen Schichtverteilung.</div>
				</div>
			</div>
		</ng-template>
	</ng-template>
</li>
