import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'p-now-line',
	templateUrl: './now-line.component.html',
	styleUrls: ['./now-line.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PNowLineComponent {
	@HostBinding('class.now-line') protected _alwaysTrue = true;

}
