/* NOTE: Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-lines: ["error", 1220] */

/**	NOTE: Do not make this service more complex than it already is */
/* eslint complexity: ["error", 12]  */
import { PercentPipe } from '@angular/common';
import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { POP_SHOW_EFFECT_ON_NGIF_TRIGGER, SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { BookingSystemRights } from '@plano/client/accesscontrol/rights-enums';
import { EventTypesService } from '@plano/client/plugin/p-custom-course-emails/event-types.service';
import { defaultSortingForShiftModelCoursePaymentMethods } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model-course-payment-methods-sorting.const';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PAlertThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FilterService } from '@plano/client/shared/filter.service';
import { PCollapsibleComponent } from '@plano/client/shared/p-collapsible/p-collapsible.component';
import { PEditableModalBoxComponent } from '@plano/client/shared/p-editable-forms/p-editable-modal-box/p-editable-modal-box.component';
import { EditableHookType } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PPricesService } from '@plano/client/shared/p-prices.service';
import { PTabSizeEnum } from '@plano/client/shared/p-tabs/p-tabs/p-tab/p-tab.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW } from '@plano/client/shift/shift-modal-sizes';
import { RightsService, SchedulingApiBooking, SchedulingApiBookingDesiredDateSetting, SchedulingApiCourseType, SchedulingApiPaymentMethodType, SchedulingApiPosSystem, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel, SchedulingApiShiftModelCoursePaymentMethod, SchedulingApiShiftModelCoursePaymentMethods, SchedulingApiShiftModelCourseTariff, SchedulingApiShiftModelCourseTariffs, SchedulingApiShiftModelRepetition, SchedulingApiShiftModelRepetitionPacket, SchedulingApiShiftRepetition, SchedulingApiShiftRepetitionPacket, SchedulingApiShiftRepetitionType, SchedulingApiWorkingTimeCreationMethod, ShiftModelRepetitionEndMode } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PPossibleErrorNames } from '@plano/shared/core/validators.types';
import { PAIFormArrayComponent } from '@plano/shared/p-forms/p-ai-form-array/p-ai-form-array.component';
import { AISwitchUIType, AI_SWITCH_OPTION_REQUIRED } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';
import { PShiftmodelTariffService } from '@plano/shared/p-forms/p-shiftmodel-tariff.service';
import { Subscription } from 'rxjs';
import { SHIFT_MODEL_COLOR_SHADES } from './available-color-shades';
import { NeededMembersCountConfFormGroup, PShiftAndShiftmodelFormService, ShiftAndShiftModelFormType } from './p-shift-and-shiftmodel-form.service';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum ShiftAndShiftModelFormTabs {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	basissettings = 'basissettings',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	bookingsettings = 'bookingsettings',
}

type RepetitionOptionsType = {
	title : 'Tage' | 'Wochen' | 'Monate' | 'Jahre',
	enum : SchedulingApiShiftRepetitionType,
}[];
type Weekday = 'Mo' | 'Di' | 'Mi' | 'Do' | 'Fr' | 'Sa' | 'So';

@Component({
	selector: 'p-shift-and-shiftmodel-form[shiftModel]',
	templateUrl: './p-shift-and-shiftmodel-form.component.html',
	styleUrls: ['./p-shift-and-shiftmodel-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER, POP_SHOW_EFFECT_ON_NGIF_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftAndShiftmodelFormComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftModel ! : SchedulingApiShiftModel;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift : SchedulingApiShift | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public add = new EventEmitter<undefined>();

	@ViewChild('onlyWholeCourseBookableModalBoxRef') private onlyWholeCourseBookableModalBoxRef ! : PEditableModalBoxComponent;
	@ViewChildren(PCollapsibleComponent) public collapsibles ?: QueryList<PCollapsibleComponent>;
	@ViewChild('paymentMethodsFormArrayRef') private paymentMethodsFormArrayRef ?: PAIFormArrayComponent<SchedulingApiShiftModelCoursePaymentMethods, SchedulingApiShiftModelCoursePaymentMethods>;

	constructor(
		public api : SchedulingApiService,
		private modalService : ModalService,
		public eventTypes : EventTypesService,
		public service : PShiftAndShiftmodelFormService,
		private pWishesService : PWishesService,
		private rightsService : RightsService,
		private pShiftModelTariffService : PShiftmodelTariffService,
		private localize : LocalizePipe,
		private percentPipe : PercentPipe,
		private pMoment : PMomentService,
		private toastsService : ToastsService,
		public pFormsService : PFormsService,
		private pCurrencyPipe : PCurrencyPipe,
		private console : LogService,
		private filterService : FilterService,
		public pPricesService : PPricesService,
	) {
	}

	public readonly Config = Config;

	public courseTypes : typeof SchedulingApiCourseType = SchedulingApiCourseType;
	public bookingDesiredDateSettings : typeof SchedulingApiBookingDesiredDateSetting =
		SchedulingApiBookingDesiredDateSetting;

	public formGroup : ShiftAndShiftModelFormType | null = null;
	public typeAheadShiftModelParentContent : string[] = [];
	public typeAheadCourseGroupContent : string[] = [];
	public now ! : number;

	public shiftModelColorShades : typeof SHIFT_MODEL_COLOR_SHADES = SHIFT_MODEL_COLOR_SHADES;
	public shiftModelColorShadeKeys = Object.keys(SHIFT_MODEL_COLOR_SHADES) as (keyof typeof SHIFT_MODEL_COLOR_SHADES)[];

	public repetitionOptions : RepetitionOptionsType = [
		{ title: 'Tage', enum: SchedulingApiShiftRepetitionType.EVERY_X_DAYS },
		{ title: 'Wochen', enum: SchedulingApiShiftRepetitionType.EVERY_X_WEEKS },
		{ title: 'Monate', enum: SchedulingApiShiftRepetitionType.EVERY_X_MONTHS },
		{ title: 'Jahre', enum: SchedulingApiShiftRepetitionType.EVERY_X_YEARS },
	];

	public enums = enumsObject;
	public PApiPrimitiveTypes = PApiPrimitiveTypes;
	public AISwitchUIType = AISwitchUIType;
	public PAlertThemeEnum = PAlertThemeEnum;
	public PTabSizeEnum = PTabSizeEnum;
	public PPossibleErrorNames = PPossibleErrorNames;
	public SectionWhitespace = SectionWhitespace;
	public SchedulingApiShiftRepetitionType = SchedulingApiShiftRepetitionType;
	public ShiftModelRepetitionEndMode = ShiftModelRepetitionEndMode;
	public SchedulingApiWorkingTimeCreationMethod = SchedulingApiWorkingTimeCreationMethod;
	public AI_SWITCH_OPTION_REQUIRED = AI_SWITCH_OPTION_REQUIRED;

	/**
	 * Should the bookingsettings be visible or not?
	 */
	public get showBookingsettingsTab() : boolean {
		// TODO: This should be replaced by rightsService.can()
		if (!this.userCanReadShiftModel) return false;

		// const MODEL = this.formItem instanceof SchedulingApiShift ? this.formItem.model : this.formItem;
		// if (!this.rightsService.can(ShiftsAndShiftModelsRights.readBookingSettings, MODEL)) return false;

		if (!this.formGroup) return false;

		// if there are already linked bookings to this shift, we always want to show the booking settings tab
		if (this.shift && this.api.data.bookings.length > 0) return true;

		if (this.formGroup.get('isCourse')!.value) return true;
		if (this.modeIsEditShiftModel(this.formItem)) return true;
		if (this.modeIsCreateShiftModel(this.formItem)) return true;

		return false;
	}

	/**
	 * When changing the parent name of this shiftModel, verify if all shiftModel with the same
	 * parent name are hidden and if so, hide this one too.
	 */
	public matchVisibilityOfGroup() : void {
		if (!this.filterService.isVisible(
			this.api.data.shiftModels.filterBy(shiftModel =>
				shiftModel.parentName === this.shiftModel.parentName &&
				!shiftModel.id.equals(this.shiftModel.id)),
		) && this.filterService.isVisible(this.shiftModel))
			this.filterService.toggleItem(this.shiftModel);

	}

	/**
	 * Should the accounting be visible or not?
	 */
	public get showAccountingTab() : boolean {
		if (!this.isOwner) return false;
		if (!this.showBookingsettingsTab) return false;

		if (this.modeIsEditShiftModel(this.formItem) || this.modeIsCreateShiftModel(this.formItem)) return true;

		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public modeIsEditShift(_formItem : SchedulingApiShift | SchedulingApiShiftModel | null) : _formItem is SchedulingApiShift | null {
		return !!this.shift && !this.shift.isNewItem();
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public modeIsEditShiftModel(_formItem : SchedulingApiShift | SchedulingApiShiftModel | null) : _formItem is SchedulingApiShiftModel | null {
		return !this.shift && !this.shiftModel.isNewItem();
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public modeIsCreateShift(_formItem : SchedulingApiShift | SchedulingApiShiftModel | null) : _formItem is SchedulingApiShift | null {
		return !!this.shift && this.shift.isNewItem();
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public modeIsCreateShiftModel(_formItem : SchedulingApiShift | SchedulingApiShiftModel | null) : _formItem is SchedulingApiShift | null {
		return !this.shift && this.shiftModel.isNewItem();
	}

	public ngOnInit() : void {
		// NOTE: Create-shift-Modal gets initialized without shift and shiftModel
		// Create-shiftModel-Modal gets initialized with a new and empty shiftModal item

		this.service.modeIsEditShift = !!this.shift && !this.shift.isNewItem();
		this.service.modeIsEditShiftModel = !this.shift && !this.shiftModel.isNewItem();
		this.service.modeIsCreateShift = !!this.shift && this.shift.isNewItem();
		this.service.modeIsCreateShiftModel = !this.shift && this.shiftModel.isNewItem();

		this.now = +this.pMoment.m();
		this.service.now = this.now;
	}

	public ngAfterContentInit() : void {
		void this.initComponent();
	}

	private _noChargeableTariff : boolean = false;

	/** ngAfterContentChecked */
	public ngAfterContentChecked() : void {
		this.runPaymentMethodsValidatorIfNecessary();
	}

	private runPaymentMethodsValidatorIfNecessary() : void {
		const noChargeableTariff = !PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(this.shiftModel.courseTariffs);
		if (noChargeableTariff !== this._noChargeableTariff) {
			this._noChargeableTariff = noChargeableTariff;
			this.paymentMethodsFormArrayRef?.childArray.updateValueAndValidity();
		}
	}

	/**
	 * Load and set everything that is necessary for this component
	 */
	public async initComponent() : Promise<void> {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		if (this.formItem.isNewItem()) {
			this.initValues();
			this.initFormGroup();
		} else {

			// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
			if (this.formItem.id === null) throw new Error('Can not load details if formItem has no defined id [PLANO-FE-3HA]');

			await this.formItem.loadDetailed();
			if (this.formItem instanceof SchedulingApiShift) {
				this.shiftModel = this.formItem.model;
			}
			this.initValues();
			this.initFormGroup();
		}
	}

	private addTypeAheadValue(value : string | null, array : string[]) : void {
		if (value && !array.includes(value))
			array.push(value);
	}

	private _costCentreTypeAheadArray : string[] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get costCentreTypeAheadArray() : string[] {
		if (this._costCentreTypeAheadArray === null) {
			this.initCostCentreTypeAheadArray();
		}
		return this._costCentreTypeAheadArray!;
	}
	private initCostCentreTypeAheadArray() : void {
		this._costCentreTypeAheadArray = [];
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			this.addTypeAheadValue(shiftModel.costCentre, this._costCentreTypeAheadArray);
		}
	}

	private _articleGroupTypeAheadArray : string[] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get articleGroupTypeAheadArray() : string[] {
		if (this._articleGroupTypeAheadArray === null) {
			this.initArticleGroupTypeAheadArray();
		}
		return this._articleGroupTypeAheadArray!;
	}
	private initArticleGroupTypeAheadArray() : void {
		this._articleGroupTypeAheadArray = [];
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			this.addTypeAheadValue(shiftModel.articleGroup, this._articleGroupTypeAheadArray);
		}
	}

	private _posAccountTypeAheadArray : string[] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get posAccountTypeAheadArray() : string[] {
		if (this._posAccountTypeAheadArray === null) {
			this.initPosAccountTypeAheadArray();
		}
		return this._posAccountTypeAheadArray!;
	}
	private initPosAccountTypeAheadArray() : void {
		this._posAccountTypeAheadArray = [];
		for (const shiftModel of this.api.data.shiftModels.iterable()) {
			for (const posAccount of shiftModel.posAccounts.iterable())
				this.addTypeAheadValue(posAccount.name, this._posAccountTypeAheadArray);
		}
	}

	private initShiftModelValues() : void {
		if (this.shiftModel.posAccounts.length === 0) {
			for (const possibleVatPercent of this.api.data.possibleVatPercents.iterable()) {
				const newPosAccount = this.shiftModel.posAccounts.createNewItem();
				newPosAccount.vatPercent = possibleVatPercent;
				newPosAccount.name = this.localize.transform({
					sourceString: 'Buchungen ${vatPercent}',
					params: {
						vatPercent: this.percentPipe.transform(possibleVatPercent, '0.0-1') ?? '…',
					},
				});
			}
		}
	}

	private initWishesServiceValue() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		if (this.formItem.isNewItem() && this.formItem instanceof SchedulingApiShiftModel) return;
		this.pWishesService.item = this.formItem as SchedulingApiShift;
	}

	private subscription : Subscription | null = null;

	/**
	 * Init all necessary values for this class
	 */
	public initValues() : void {

		this.sortTariffs();
		this.sortPaymentMethods();
		this.subscription = this.api.onBackendResponse.subscribe(() => {
			this.sortTariffs();
			this.sortPaymentMethods();
		});

		this.typeAheadShiftModelParentContent = this.api.data.shiftModels.parentNames;
		this.typeAheadCourseGroupContent = this.api.data.shiftModels.courseGroups;
		this.initStartOfDay();
		this.initEndOfDay();
		this.initShiftModelValues();
		this.initWishesServiceValue();

		if (this.shiftModel.attributeInfoOnlyWholeCourseBookable.isAvailable) {
			this.canSetOnlyWholeCourseBookable.initial = this.shiftModel.attributeInfoOnlyWholeCourseBookable.value === null;
			this.canSetOnlyWholeCourseBookable.current = this.shiftModel.attributeInfoOnlyWholeCourseBookable.value === null;
		}
		if (this.shiftModel.attributeInfoCourseType.isAvailable) {
			this.initialCourseType = this.shiftModel.attributeInfoCourseType.value;
		}
	}

	/**
	 * It should only once be possible to set this attribute.
	 */
	public canSetOnlyWholeCourseBookable : {
		initial : boolean | null,
		current : boolean | null,
	} = { initial: null, current: null };

	public initialCourseType : SchedulingApiCourseType | null = null;

	public ngOnDestroy() : void {
		this.subscription?.unsubscribe();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get formItem() : SchedulingApiShift | SchedulingApiShiftModel | null {
		if (this.shift) return this.shift;
		return this.shiftModel;
	}

	/**
	 * Initialize the formGroup for this component
	 */
	public initFormGroup() : void {
		if (this.formItem === null) throw new Error(`never call initFormGroup() without a defined formItem`);

		if (this.formGroup) {
			this.formGroup = null;
		}
		const model = this.formItem instanceof SchedulingApiShift ? this.formItem.model : this.formItem;

		this.formGroup = this.service.initFormGroup(
			this.formItem,
			this.userCanWrite,
			this.api.data.notificationsConf,
			model,
		);

		this.pFormsService.addFormGroup(this.formGroup, 'currentCancellationPolicy', new FormGroup({}));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public removeTariff(
		tariff : SchedulingApiShiftModelCourseTariff,
	) : void {
		assumeDefinedToGetStrictNullChecksRunning(this.shiftModel, 'shiftModel');
		this.pShiftModelTariffService.removeTariff(tariff, this.shiftModel);
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public removeCoursePaymentMethod(
		_formGroup : ShiftAndShiftModelFormType,
		_index : number,
		coursePaymentMethod : SchedulingApiShiftModelCoursePaymentMethod,
	) : void {
		this.shiftModel.coursePaymentMethods.removeItem(coursePaymentMethod);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public trimParentName() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		this.trim(this.shiftModel.attributeInfoParentName);
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public trimCourseGroup() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		this.trim(this.shiftModel.attributeInfoCourseGroup);
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private trim(attributeInfo : ApiAttributeInfo<any, unknown>) : void {
		if (!attributeInfo.value) return;
		if (typeof attributeInfo.value !== 'string') throw new Error('Unexpected input. Can not be trimmed.');
		attributeInfo.value = attributeInfo.value.trim();
		this.formGroup?.updateValueAndValidity();
	}

	/**
	 * List of available payment methods
	 */
	public get availablePaymentMethods() : readonly SchedulingApiShiftModelCoursePaymentMethod[] {
		return this.shiftModel.coursePaymentMethods.filterBy(item => !item.trashed).iterable();
	}

	/**
	 * Sort the tariffs in-place. It is important to do this this way, because a change in the name-input would otherwise
	 * lead to a resorting of the list.
	 */
	public sortTariffs() : void {
		this.shiftModel.courseTariffs
			.sortedBy([
				item => (item.name as SchedulingApiShiftModelCourseTariff['name'] | null)?.toLowerCase(),
			], { inPlace: true });
	}

	/**
	 * Sort the payment methods in-place. It is important to do this this way, because a change in the name-input would otherwise
	 * lead to a resorting of the list.
	 */
	public sortPaymentMethods() : void {
		this.shiftModel.coursePaymentMethods
			.sortedBy(defaultSortingForShiftModelCoursePaymentMethods, { inPlace: true });
	}

	/**
	 * List of available tariffs
	 */
	public get availableTariffs() : SchedulingApiShiftModelCourseTariffs {
		if (!this.shiftModel.attributeInfoCourseTariffs.isAvailable) return new SchedulingApiShiftModelCourseTariffs(null, null);

		return this.shiftModel.courseTariffs
			.filterBy(item => !item.trashed);
	}

	/**
	 * Check if color is selected
	 * @param availableColor color that needs to be checked
	 */
	public isSelectedColor( availableColor : string ) : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shiftModel, 'shiftModel');
		return !!this.shiftModel.color && availableColor.toUpperCase() === this.shiftModel.color.toUpperCase();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get packetRepetitionTitle() : string | boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		switch (this.formItem!.repetition.packetRepetition.type) {
			case SchedulingApiShiftRepetitionType.EVERY_X_DAYS:
				return this.localize.transform(this.repetitionOptions[0].title);
			case SchedulingApiShiftRepetitionType.EVERY_X_WEEKS:
				return this.localize.transform(this.repetitionOptions[1].title);
			case SchedulingApiShiftRepetitionType.EVERY_X_MONTHS:
				return this.localize.transform(this.repetitionOptions[2].title);
			case SchedulingApiShiftRepetitionType.EVERY_X_YEARS:
				return this.localize.transform(this.repetitionOptions[3].title);
			case SchedulingApiShiftRepetitionType.NONE:
				return this.localize.transform('Wähle…');
			default:
				this.formItem!.repetition.packetRepetition.attributeInfoType.value = null;
				return 'Wähle…';
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get repetitionTitle() : string | boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		switch (this.formItem.repetition.type) {
			case SchedulingApiShiftRepetitionType.EVERY_X_DAYS:
				return this.localize.transform(this.repetitionOptions[0].title);
			case SchedulingApiShiftRepetitionType.EVERY_X_WEEKS:
				return this.localize.transform(this.repetitionOptions[1].title);
			case SchedulingApiShiftRepetitionType.EVERY_X_MONTHS:
				return this.localize.transform(this.repetitionOptions[2].title);
			case SchedulingApiShiftRepetitionType.EVERY_X_YEARS:
				return this.localize.transform(this.repetitionOptions[3].title);
			case SchedulingApiShiftRepetitionType.NONE:
				return this.localize.transform('Wähle…');
			default:
				this.formItem.repetition.attributeInfoType.value = null;
				return this.localize.transform('Wähle…');
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get repetitionEndDateModeTitle() : string {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		switch (this.formItem.repetition.repetitionEndMode) {
			case ShiftModelRepetitionEndMode.NEVER:
				return this.service.intervalEndDateModesIterable[0].title;
			case ShiftModelRepetitionEndMode.AFTER_X_TIMES:
				if (this.formItem.repetition.attributeInfoEndsAfterRepetitionCount.isAvailable === false) return '…';
				if (this.formItem.repetition.endsAfterRepetitionCount > 1) {
					if (this.formItem.isPacket) {
						return this.localize.transform('Paketen');
					} else {
						return this.localize.transform('Schichten');
					}
				} else {
					if (this.formItem.isPacket) {
						return this.localize.transform('Paket');
					} else {
						return this.localize.transform('Schicht');
					}
				}
			case ShiftModelRepetitionEndMode.ENDS_AFTER_DATE:
				return ('');
			default:
				return this.localize.transform('Wähle…');
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get packetIsEditableOrAlreadySet() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		return (
			this.modeIsEditShift(this.formItem) && this.formItem.isPacket
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		) || !this.modeIsEditShift(this.formItem);
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get intervalIsEditableOrAlreadySet() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		if (
			this.modeIsEditShift(this.formItem) &&
			this.formItem!.hasRepetition
		) return true;
		return !this.modeIsEditShift(this.formItem);
	}

	/**
	 * Handle click on delete button
	 */
	public getChangeSelectorModalAsHook(
		modalContent : TemplateRef<PModalTemplateDirective>,
	) : () => EditableHookType {
		return async () => {
			const promise = this.modalService.openModal(modalContent, {
				size: this.shift ? SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW : null,
			}).result;
			const promiseResult = await promise;
			if (promiseResult.modalResult === 'dismiss') this.initFormGroup();
			return promise;
		};
	}

	/** The first time this activity gets set to »isCourse«, the user needs to decide onlyWholeCourseBookable */
	public get isCourseHook() : () => EditableHookType | null {
		// eslint-disable-next-line @typescript-eslint/promise-function-async -- Remove this before you work here.
		return () => {
			if (this.shiftModel.onlyWholeCourseBookable !== null) return null;
			if (this.shiftModel.attributeInfoOnlyWholeCourseBookable.value !== null) return null;
			if (!this.shiftModel.courseTitle) this.shiftModel.courseTitle = this.shiftModel.parentName;
			const modalReturnPromise = this.onlyWholeCourseBookableModalBoxRef.modalButtonRef!.openEditableModal().result;
			void modalReturnPromise.then((value) => {
				if (value.modalResult === 'success') {
					this.onSaveOnlyWholeCourseBookable();
					this.canSetOnlyWholeCourseBookable.current = false;
				}
			});
			return modalReturnPromise;
		};
	}

	/**
	 * Confirmation hook when the user decides to set this activity as not-bookable
	 */
	public get confirmationHook() : () => EditableHookType | null {
		return async () => {
			return this.modalService.openDefaultModal({
				description: this.localize.transform(this.shiftModel.marketingGiftCardSettings.activated ? 'Bist du sicher, dass dieses Angebot nicht mehr buchbar sein soll? Falls ja, kontrolliere bitte auch die Einstellungen unter dem Tab <mark>Marketing-Gutscheine</mark>, denn der Versand von Marketing-Gutscheinen ist aktuell aktiviert.' : 'Bist du sicher, dass dieses Angebot nicht mehr buchbar sein soll?'),
				hideDismissBtn: false,
				dismissBtnLabel: this.localize.transform('Nein'),
				closeBtnLabel: this.localize.transform('Ja'),
			}, {
				theme: enumsObject.PThemeEnum.WARNING,
				size: enumsObject.BootstrapSize.SM,
			}).result;
		};
	}

	/**
	 * We want to have the same behaviour on new activities as if this would be a existing one.
	 * So we add the same modal hook for inactive editables
	 */
	public isCourseHookForInactiveEditable(newValue : boolean) : void {
		if (!this.formItem!.isNewItem()) return;
		const hook = newValue ? this.isCourseHook() : this.confirmationHook();
		if (hook === null) return;
		void hook.then((value) => {
			if (value.modalResult === 'dismiss') {
				if (newValue) {
					this.shiftModel.onlyWholeCourseBookable = null;
				}
				this.formGroup!.controls['isCourse'].setValue(!this.formGroup!.controls['isCourse'].value);
			}
		});
	}

	/** Show some hint when courseType gets changed. */
	public courseTypeHook(template : TemplateRef<PModalTemplateDirective>) : () => EditableHookType | null {
		// eslint-disable-next-line @typescript-eslint/promise-function-async -- Remove this before you work here.
		return () => {
			// Has been set to isCourse during this session
			if (this.canSetOnlyWholeCourseBookable.initial === true && this.canSetOnlyWholeCourseBookable.current === false) return null;
			return this.modalService.openModal(template, {
				size: enumsObject.BootstrapSize.SM,
			}).result;
		};
	}

	/** Show some hint when bookingDesiredDateSetting gets changed. */
	public bookingDesiredDateSettingHook(template : TemplateRef<PModalTemplateDirective>) : () => EditableHookType | null {
		// eslint-disable-next-line @typescript-eslint/promise-function-async -- Remove this before you work here.
		return () => {
			// Has been set to isCourse during this session
			if (this.canSetOnlyWholeCourseBookable.initial === true && this.canSetOnlyWholeCourseBookable.current === false) return null;

			// The booking type has been changed to »customers send a booking request« in the same session
			if (this.initialCourseType !== SchedulingApiCourseType.ONLINE_INQUIRY) return null;

			return this.modalService.openModal(template, {
				size: enumsObject.BootstrapSize.SM,
			}).result;
		};
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onDismiss() : void {
		// TODO: (PLANO-1868)
		if (this.shift) {
			this.shift = this.api.data.shifts.get(this.shift.id);
		}
		this.initFormGroup();
	}

	/**
	 * Check if user can read this shift
	 */
	public get userCanReadShiftModel() : boolean | undefined {
		const MODEL = this.formItem instanceof SchedulingApiShift ? this.formItem.model : this.formItem;
		if (!MODEL) throw new Error('model can not be found');
		return this.rightsService.userCanRead(MODEL);
	}

	/**
	 * path to open detail view to edit booking
	 */
	public pathToBookingLink(booking ?: SchedulingApiBooking) : string {
		if (booking) {
			assumeDefinedToGetStrictNullChecksRunning(booking.id, 'booking.id');
			return `/client/booking/${booking.id.toString()}`;
		} else if (this.shift) {
			return `/client/booking/create/${this.shift.id.toUrl()}`;
		} else {
			return '/client/booking/';
		}
	}

	/**
	 * Check if user can edit this shift
	 */
	public get userCanWrite() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'this.formItem');
		return !!this.rightsService.userCanWrite(this.formItem);
	}

	/**
	 * Check if user can edit this shift
	 */
	public get userCanWriteCurrentCancellationPolicy() : boolean {
		if (!this.userCanWrite) return false;
		if (
			!this.shiftModel.attributeInfoCancellationPolicies.canSet &&
			!this.shiftModel.attributeInfoOnlineCancellationForFreeBookingsEnabled.canSet &&
			!this.shiftModel.attributeInfoOnlineCancellationForFreeBookingsDeadline.canSet &&
			!this.shiftModel.attributeInfoOnlineCancellationForChargeableBookingsEnabled.canSet &&
			!this.shiftModel.attributeInfoOnlineCancellationForChargeableBookingsDeadline.canSet &&
			!this.shiftModel.attributeInfoOnlineCancellationForWithdrawableBookingsAlwaysEnabled.canSet &&
			!this.shiftModel.attributeInfoOnlineCancellationAutomaticOnlineRefundEnabled.canSet
		) return false;
		return true;
	}

	/**
	 * Check if user can edit this shift
	 */
	public get isOwner() : boolean | undefined {
		return this.rightsService.isOwner;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showRelatedBookings() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');

		if (this.formItem.isNewItem()) return false;
		if (this.modeIsEditShiftModel(this.formItem)) return false;
		if (this.modeIsCreateShiftModel(this.formItem)) return false;
		if (!this.userCanReadShiftModel) return false;

		assumeDefinedToGetStrictNullChecksRunning(this.shiftModel, 'shiftModel');

		if (this.shiftModel.courseType === SchedulingApiCourseType.NO_BOOKING) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showNeededMembersCountConfShowroom() : boolean {
		assumeNonNull(this.formItem);

		if (!this.formItem.isCourse) return false;
		if (!this.modeIsEditShift(this.formItem) && !this.modeIsCreateShift(this.formItem)) return false;
		const config = this.formItem.neededMembersCountConf;
		if (config.isZeroNotReachedMinParticipantsCount) return true;
		if (config.perXParticipants === null) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get neededMembersCountConfNotReached() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'shift');

		const tempFormGroup = this.formGroup.get('neededMembersCountConf') as NeededMembersCountConfFormGroup;
		assumeDefinedToGetStrictNullChecksRunning(tempFormGroup, 'tempFormGroup');
		return (
			this.formItem.neededMembersCountConf.isZeroNotReachedMinParticipantsCount &&
			this.shift.currentCourseParticipantCount < this.shift.minCourseParticipantCount
		);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showSendEmailSetting() : boolean {
		if (!this.shift) return false;
		return this.shift.assignedMemberIds.length > 0;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get basisSettingsTabLabel() : PDictionarySource {
		return 'Grundeinstellungen';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get bookingsettingsTabLabel() : string {
		return this.localize.transform('Buchungseinstellungen');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showQuickAssignment() : boolean {
		if (!this.shift) return false;

		if (this.shift.assignedMemberIds.length > 0) return true;
		if (this.shift.emptyMemberSlots > 0) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showBookingOptionsSection() : boolean {
		if (this.modeIsEditShiftModel(this.formItem)) return true;
		if (this.modeIsCreateShiftModel(this.formItem)) return true;
		if (this.userCanWrite) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get addButtonIsDisabled() : boolean {
		return !this.formGroup || this.formGroup.invalid || this.api.isBackendOperationRunning;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showControlCourseCode() : boolean {
		return (
			this.formGroup!.get('isCourse')!.value &&
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
			this.modeIsEditShift(this.formItem) || this.modeIsCreateShift(this.formItem)
		);
	}

	public startOfDay ! : number;
	private initStartOfDay() : void {
		this.startOfDay = this.pMoment.duration('00:00').asMilliseconds();
	}

	public endOfDay ! : number;
	private initEndOfDay() : void {
		this.endOfDay = this.pMoment.duration('23:59').asMilliseconds();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get translatedIsIntervalValueText() : string {
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		assumeDefinedToGetStrictNullChecksRunning(this.shiftModel, 'shiftModel');

		if (this.formItem.isPacket) {
			return this.localize.transform({
				sourceString: 'Das Schicht-Paket »${shiftModelName}« wiederholt sich.',
				params: {
					shiftModelName: this.shiftModel.attributeInfoName.value ?? '…',
				},
			});
		}
		return this.localize.transform({
			sourceString: 'Die Schicht »${shiftModelName}« wiederholt sich.',
			params: {
				shiftModelName: this.shiftModel.attributeInfoName.value ?? '…',
			},
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get translatedNeededMembersCountModalBoxLabel() : string {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		const neededMembersCount = this.formItem?.neededMembersCountConf.neededMembersCount;
		let result = '';
		switch (neededMembersCount) {
			case null :
				result += this.localize.transform('Bitte wählen…');
				break;
			case 0 :
				result += this.localize.transform('0 Mitarbeitende');
				break;
			case 1 :
				result += this.localize.transform('1 Mitarbeitende');
				break;
			default :
				result += this.localize.transform({sourceString: '${counter} Mitarbeitende', params: { counter: `${neededMembersCount ?? '–'}` }});
		}
		const modeIsFixedMembersCount = (() => {
			if (!this.formItem!.neededMembersCountConf.attributeInfoPerXParticipants.isAvailable) return null;
			return !this.formItem!.neededMembersCountConf.attributeInfoPerXParticipants.value;
		})();
		if (!modeIsFixedMembersCount) {
			if (!this.formItem) return result;
			result += ' ';
			if (!this.formItem.neededMembersCountConf.attributeInfoPerXParticipants.isAvailable) return `${result}?`;
			result += this.localize.transform({
				sourceString: 'pro ${x} Teilnehmende',
				params: {
					x: `${this.formItem.neededMembersCountConf.perXParticipants ?? '…'}`,
				},
			});
		}
		return result;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get packetEndsAfterRepetitionCountLabel() : string {
		// TODO: Check if FormRecord helps to improve weekdays structure inside angular’s form structure
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		if (this.formItem.repetition.packetRepetition.endsAfterRepetitionCount > 1) {
			return this.localize.transform('Verteilt auf die Tage');
		}
		return this.localize.transform('Am');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get isPacketLabel() : string {
		// TODO: Check if FormRecord helps to improve weekdays structure inside angular’s form structure
		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');
		if (!this.formItem.isPacket) return this.localize.transform('An jedem');
		return this.localize.transform('Eine neue Wiederholung startet am');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showFreeclimberSettings() : boolean {
		if (this.shift) return false;
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		if (!this.formGroup.get('isCourse')!.value) return false;
		if (this.api.data.posSystem !== SchedulingApiPosSystem.FREECLIMBER) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get formItemName() : string {
		if (!this.formItem?.name) return this.localize.transform('Neue Tätigkeit');
		return this.formItem.name;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public copyTariff(event : MouseEvent, courseTariff : SchedulingApiShiftModelCourseTariff) : void {
		event.preventDefault();
		event.stopPropagation();

		// Create a new tariff based on the clicked one
		const NEW_TARIFF : SchedulingApiShiftModelCourseTariff = courseTariff.copy();

		// Check if a copy already exists.
		const NEW_TARIFF_NAME = `${NEW_TARIFF.name} – ${this.localize.transform('Kopie')}`;

		assumeDefinedToGetStrictNullChecksRunning(this.shiftModel, 'shiftModel');

		if (this.shiftModel.courseTariffs.findBy(item => !item.trashed && item.name === NEW_TARIFF_NAME)) {
			this.toastsService.addToast({
				theme: enumsObject.PThemeEnum.DANGER,
				title: this.localize.transform('Momentchen …'),
				content: this.localize.transform({
					sourceString: 'Ein Tarif mit dem Namen »${name}« existiert schon.',
					params: {
						name : NEW_TARIFF_NAME,
					},
				}),
			});
			return;
		}

		// Edit necessary values
		NEW_TARIFF.name = NEW_TARIFF_NAME;

		// Add the new tariff to the existing ones
		this.shiftModel.courseTariffs.push(NEW_TARIFF);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public hasCourseDatesData(courseTariff : SchedulingApiShiftModelCourseTariff) : boolean {
		return this.pShiftModelTariffService.hasCourseDatesData(
			courseTariff.negateForCourseDatesInterval,
			courseTariff.forCourseDatesFrom,
			courseTariff.forCourseDatesUntil,
		);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public forCourseDatesPlaceholder(time : number | null) : string | null {
		return time ? null : this.localize.transform('Unbegrenzt');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public paymentMethodIcon(paymentMethod : SchedulingApiShiftModelCoursePaymentMethod) : FaIcon | null {
		return this.pCurrencyPipe.getPaymentMethodIcon(paymentMethod.type, paymentMethod.name);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get saveChangesHook() : () => EditableHookType | null {
		// eslint-disable-next-line @typescript-eslint/promise-function-async -- Remove this before you work here.
		return () => {
			if (!this.isFreeCourse) return null;

			let text : string = '';

			if (this.isFreeCourseReasonText) {
				text += `${this.localize.transform(this.isFreeCourseReasonText)} `;
			}

			text += this.localize.transform({
				sourceString: '»${name}« wird bei der Online-Buchung als kostenlos angezeigt werden.',
				params: { name : this.shiftModel.name },
			});
			return this.modalService.confirm({
				description: text,
				modalTitle: 'Sicher?',
				closeBtnLabel: 'Ja',
			}, {
				theme: enumsObject.PThemeEnum.WARNING,
			}).result.then(value => {
				return value;
			});
		};
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasTariffWithNoCosts() : boolean {
		for (const tariff of this.shiftModel.courseTariffs.iterable()) {
			if (tariff.isInternal) continue;
			if (tariff.trashed) continue;
			if (tariff.fees.findBy(fee => fee.fee > 0)) continue;
			return true;
		}
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasTariffWithCosts() : boolean {
		for (const tariff of this.shiftModel.courseTariffs.iterable()) {
			if (tariff.isInternal) continue;
			if (tariff.trashed) continue;
			if (!tariff.fees.findBy(fee => fee.fee > 0)) continue;
			return true;
		}
		return false;
	}

	public addItemInitCode = (input : SchedulingApiShiftModelCourseTariff) : void => {
		if (input.fees.length === 0) input.fees.createNewItem();
	};

	/** Collapse every collapsible that is a child of this component */
	public collapseAllCollapsibles() : void {
		if (this.collapsibles) {
			for (const collapsible of this.collapsibles.filter(item => !item.collapsed)) {
				collapsible.toggle(null);
			}
		}
	}

	/**
	 * Check if the content of this tab is invalid.
	 */
	// TODO: Make this obsolete PLANO-165161
	// eslint-disable-next-line complexity
	public get bookingsettingsTabHasDanger() : boolean {
		if (this.formGroup?.get(this.shiftModel.attributeInfoIsCourse.id)?.invalid) return true;

		if (this.formGroup?.get(this.shiftModel.attributeInfoIsCourseOnline.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseTitle.id)?.invalid) return true;

		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseSubtitle.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseGroup.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseCodePrefix.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseDescription.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseSkillRequirements.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseEquipmentRequirements.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseLocation.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseContactName.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseType.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoBookingDesiredDateSetting.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseBookingDeadlineFrom.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoCourseBookingDeadlineUntil.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoMinCourseParticipantCount.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoMaxCourseParticipantCount.id)?.invalid) return true;
		if (this.formGroup?.get(this.shiftModel.attributeInfoOnlyWholeCourseBookable.id)?.invalid) return true;

		if (!this.tariffAndPaymentMethodsModalIsValid) return true;

		return false;
	}

	/**
	 * Check if the content of this tab is invalid.
	 */
	// TODO: Make this obsolete PLANO-165161
	public get shiftModelBasisSettingsIsInvalid() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		if (this.formGroup.get(this.shiftModel.attributeInfoName.id)?.invalid) return true;
		if (this.formGroup.get(this.shiftModel.attributeInfoParentName.id)?.invalid) return true;
		if (this.formGroup.get(this.shiftModel.attributeInfoColor.id)?.invalid) return true;

		if (this.formGroup.get(this.shiftModel.attributeInfoNeededMembersCountConf.id)?.invalid) return true;

		if (this.formGroup.get(this.shiftModel.attributeInfoDescription.id)?.invalid) return true;
		if (this.formGroup.get(this.shiftModel.attributeInfoTime.id)?.invalid) return true;
		if (this.formGroup.get(this.shiftModel.attributeInfoRepetition.id)?.invalid) return true;
		if (this.formGroup.get(this.shiftModel.repetition.attributeInfoPacketRepetition.id)?.invalid) return true;

		if (this.formGroup.get(this.shiftModel.attributeInfoWorkingTimeCreationMethod.id)?.invalid) return true;

		if (this.formGroup.get(this.shiftModel.attributeInfoAssignedMemberIds.id)?.invalid) return true;
		if (this.formGroup.get(this.shiftModel.attributeInfoAssignableMembers.id)?.invalid) return true;

		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showAddBookingsBtn() : boolean | null {
		if (!this.modeIsEditShift(this.formItem)) return false;
		if (!this.rightsService.can(BookingSystemRights.createBookings, this.shiftModel)) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shiftStart() : number | undefined {
		if (!this.shift) return undefined;
		if (!this.shift.rawData) throw new Error('Can not get start. Shift is lost. [PLANO-FE-3FN]');
		return this.shift.start;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public showPaymentMethodNameAndDescriptionFormFields(paymentMethod : SchedulingApiShiftModelCoursePaymentMethod) : boolean | null {
		return paymentMethod.type !== SchedulingApiPaymentMethodType.ONLINE_PAYMENT;
	}

	/**
	 * Text for the case that online-payment is not available
	 */
	public get cannotSetOnlinePaymentHint() : PDictionarySourceString {
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		if (!this.pPricesService.currencyIsSupportedForOnlinePayment) return 'Online-Zahlung steht für deine Landeswährung noch nicht zur Verfügung. Falls du die Online-Zahlung nutzen möchtest, melde dich gerne bei uns im Chat oder per <a href="mailto:service@dr-plano.com">Email</a>.';
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		return 'Bitte erst für deinen Account die <a href="client/plugin/payments" target="_blank">Online-Zahlung aktivieren</a>, um die Zahlungsart hier verwenden zu können.';
	}

	private addDayToResult(day : Weekday, result : string) : string {
		if (result.length > 0) result += ', ';
		return result + this.localize.transform(day);
	}

	private affectedWeekdays(
		repetition :
			SchedulingApiShiftModelRepetition | SchedulingApiShiftRepetition |
			SchedulingApiShiftRepetitionPacket | SchedulingApiShiftModelRepetitionPacket,
	) : string | null {
		let result : string | null = '';
		if (repetition.isRepeatingOnMonday) result = this.addDayToResult('Mo', result);
		if (repetition.isRepeatingOnTuesday) result = this.addDayToResult('Di', result);
		if (repetition.isRepeatingOnWednesday) result = this.addDayToResult('Mi', result);
		if (repetition.isRepeatingOnThursday) result = this.addDayToResult('Do', result);
		if (repetition.isRepeatingOnFriday) result = this.addDayToResult('Fr', result);
		if (repetition.isRepeatingOnSaturday) result = this.addDayToResult('Sa', result);
		if (repetition.isRepeatingOnSunday) result = this.addDayToResult('So', result);
		if (result === '') result = null;
		return result;
	}

	/** Get weekdays in a human readable format like e.g. `Mo, Tu, Sa` */
	public get affectedPacketRepetitionWeekdays() : string | null {
		if (this.formItem!.repetition.packetRepetition.type !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) return null;

		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');

		return this.affectedWeekdays(this.formItem.repetition.packetRepetition);
	}

	/** Is the shift/model repeating on that day? */
	public get affectedRepetitionWeekdays() : string | null {
		if (this.formItem!.repetition.type !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) return null;

		assumeDefinedToGetStrictNullChecksRunning(this.formItem, 'formItem');

		return this.affectedWeekdays(this.formItem.repetition);
	}

	/** Should this box be visible? */
	public get showAssignMembers() : boolean | undefined {
		assumeNonNull(this.formItem);
		if (
			this.formItem.attributeInfoAssignedMemberIds.isAvailable === undefined ||
			this.formItem.attributeInfoAssignableMembers.isAvailable === undefined
		) return undefined;
		return this.formItem.attributeInfoAssignedMemberIds.isAvailable || this.formItem.attributeInfoAssignableMembers.isAvailable;
	}

	/** Is the content of the tariffs and payment methods modal valid? */
	public get tariffAndPaymentMethodsModalIsValid() : boolean {
		assumeNonNull(this.formGroup, 'this.formGroup', 'Dont use this getter when you dont have a formGroup');
		if (this.formGroup.get(this.shiftModel.attributeInfoCoursePaymentMethods.id)?.invalid) return false;
		const tariffsArray = this.formGroup.get(this.shiftModel.attributeInfoCourseTariffs.id) as FormArray | null;
		if (tariffsArray?.errors) return false;
		if (tariffsArray?.controls.some(item => item.invalid)) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public resetPerXParticipants(input : boolean) : void {
		if (input === false) return;
		if (!this.formItem!.neededMembersCountConf.attributeInfoPerXParticipants.isAvailable) return;
		this.formItem!.neededMembersCountConf.perXParticipants = null;
	}

	/** Set some data according to the onlyWholeCourseBookable decision */
	// eslint-disable-next-line @typescript-eslint/require-await
	public onSaveOnlyWholeCourseBookable = async () : Promise<boolean> => {
		if (!this.shiftModel.onlyWholeCourseBookable) this.shiftModel.clearMisfittingTariffs();
		return true;
	};

	/** @see PShiftmodelTariffService#isFreeCourse */
	protected get isFreeCourse() : boolean {
		return this.pShiftModelTariffService.isFreeCourse(this.shiftModel.courseTariffs);
	}

	/**
	 * Describes why this is a free course
	 */
	protected get isFreeCourseReasonText() : PDictionarySource | null {
		if (!this.shiftModel.courseTariffs.hasUntrashedItem) return 'Es ist kein Tarif angelegt.';
		if (!this.pShiftModelTariffService.hasVisibleCourseTariffWithCosts(this.shiftModel.courseTariffs)) {
			return {
				sourceString: 'Alle Tarife sind entweder als <mark>interne Tarife</mark> eingestellt oder ihre Kosten liegen bei <mark>${amount}</mark>.',
				params: {
					amount: this.pCurrencyPipe.transform(0, Config.CURRENCY_CODE, 'symbol', '1.0-0'),
				},
			};
		}

		// NOTE: In case this error gets removed, then *ngIf="isFreeCourseReasonText" can be removed from the template.
		this.console.error('Could not find reason, but course is free(?)');

		return null;
	}
}
