<div
	*ngIf="formGroup"

	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit($event)"
	(editMode)="editMode.emit($event)"

	[class.required]="hasRequiredError"
	[class.has-danger]="hasDanger"
	[class.has-warning]="hasWarning"
	[disabled]="readMode"

	class="input-group"
	[class.input-group-sm]="size === enums.BootstrapSize.SM"
	[class.input-group-lg]="size === enums.BootstrapSize.LG"
>
	<ng-template [ngIf]="readMode" [ngIfElse]="realModeTemplate">
		<div class="form-control-read-mode">{{ formattedDateTime ? formattedDateTime : placeholder }}</div>
	</ng-template>
	<ng-template #realModeTemplate>
		<ng-template [ngIf]="formGroup && showDaysBeforeInput" [ngIfElse]="notADeadlineTemplate">
			<input
				pEditableTriggerFocussable
				class="form-control"
				[class.rounded-right-0]="daysBeforeAppendText"
				[defaultValue]="timestampToDaysBefore(control!.value) ?? ''"
				(input)="handleInputChange(inputRef.value)"
				[required]="hasRequiredError"
				[class.has-warning]="hasWarning"
				pAutoFocus
				[disabled]="daysBeforeInputDisabled ?? disabled"
				#inputRef
			/>

			<div class="input-group-append"
				*ngIf="pEditable"
				pVisibleInEditMode
			>
				<button
					type="reset"
					class="btn btn-outline-secondary"
					pEditableDismissButton
				><fa-icon [icon]="enums.PlanoFaIconPool.UNDO"></fa-icon></button>
				<button
					type="submit"
					class="btn btn-outline-secondary"
					pEditableSuccessButton
				><fa-icon [class.text-warning]="hasWarning" [icon]="successBtnIcon" [spin]="successBtnIconSpin"></fa-icon></button>
			</div>
		</ng-template>

		<ng-template #notADeadlineTemplate>
			<button
				type="button"
				class="form-control text-left formatted clickable pl-2 pr-2 d-flex align-items-center btn {{btnStyles}}"
				pAutoFocus
				[class.pulse-success]="isValid"
				[class.pulse]="pEditable"
				[class.show-animation]="pEditable && valueHasChangedSuccessfully"

				[class.btn-secondary]="!btnStyles"
				[class.show-time]="showTimeInput"

				#pEditableModalButtonRef
				pEditableModalButton
				[hideDismissBtn]="!showTimeInput && !showDaysBeforeInput"
				[hideCloseBtn]="!showTimeInput && !showDaysBeforeInput"
				[closeBtnDisabled]="closeBtnDisabled"
				[modalTitle]="modalTitle"
				[showBtnIcon]="false"
				[pEditable]="pEditable"
				[api]="api"
				[valid]="isValid"
				(onModalOpen)="onModalOpen()"
				(onModalClosed)="onModalClosed($event)"
				[modalSize]="this.showTimeInput ? null : enums.BootstrapSize.SM"
				[disabled]="disabled || (control?.disabled ?? false)"
				windowClass="date-picker-modal"
				(onModalDismissed)="onModalDismiss($event)"
			>
				<p-editable-modal-button-header>
					<fa-icon
						type="solid"
						[fixedWidth]="true"
						[icon]="prependIcon"
					></fa-icon><span
						[class.text-skeleton-animated]="isLoading"
						[style.font-size.rem]="size === enums.BootstrapSize.SM ? undefined : 1"
						class="ml-2 w-100"
					>{{ label ? label : (formattedDateTime ? formattedDateTime : placeholder) }}</span>
				</p-editable-modal-button-header>
				<p-editable-modal-form>
					<form [formGroup]="$any(formGroup)">
						<div class="form-group" aria-label="Datum" i18n-aria-label>
							<div class="d-flex justify-content-end mb-3">
								<button
									*ngIf="showEraseValueBtn"
									class="btn btn-outline-secondary mr-auto"
									(click)="unsetData($event)"
									type="button"
								><ng-template [ngIf]="showEraseValueBtnIcon"><fa-icon [icon]="enums.PlanoFaIconPool.DELETE"></fa-icon>&nbsp;</ng-template>{{ eraseValueBtnLabel }}</button>
								<button
									*ngIf="showSuggestionButton"
									type="button"
									[class.btn-primary]="suggestionButtonTheme === enums.PThemeEnum.PRIMARY"
									[class.btn-outline-secondary]="suggestionButtonTheme === PBtnThemeEnum.OUTLINE_SECONDARY"
									class="btn px-3 badge-pill mr-auto"
									[disabled]="suggestionBtnIsDisabled"
									(click)="onClickSuggestion()"
									i18n
								>{{suggestionLabel}}</button>
								<button
									*ngIf="showNowButton && !nowButtonIsDisabled"
									type="button"
									[class.btn-primary]="isCurrentDateTime && !nowButtonIsDisabled"
									[class.btn-outline-secondary]="!isCurrentDateTime || nowButtonIsDisabled"
									[class.btn-outline-primary]="!isCurrentDateTime && !nowButtonIsDisabled"
									class="btn badge-pill ml-auto"
									[disabled]="nowButtonIsDisabled"
									(click)="onClickNow()"
									i18n
								>{!!showTimeInput, select, true {Jetzt} other {Heute}}</button>
							</div>
							<div class="d-flex justify-content-center align-items-center">
								<ngb-datepicker
									#datepickerRef
									[class.ngb-dp-range-day]="range === 'day'"
									[class.ngb-dp-range-week]="range === 'week'"
									[class.ngb-dp-range-month]="range === 'month'"
									[class.has-danger]="formGroup!.controls['date']!.invalid && formGroup!.controls['date']!.touched || (!!control && hasDanger)"
									[class.required]="formGroup!.controls['date']!.invalid && formGroup!.controls['date']!.hasError('required')"
									formControlName="date"
									[startDate]="startDate === '-' ? undefined! : startDate!"
									[maxDate]="ngbMaxDate === '-' ? undefined! : ngbMaxDate"
									[minDate]="ngbMinDate === '-' ? undefined! : ngbMinDate"
									[dayTemplate]="dayTemplate"
								></ngb-datepicker>

								<ng-template
									#dayTemplate
									let-date="date"
									let-currentMonth="currentMonth"
									let-selected="selected"
									let-disabled="disabled"
									let-focused="focused"
								>
									<div
										ngbDatepickerDayView
										class="rounded-0 day-btn"
										[class.focused]="focused"
										[class.btn-primary]="isHighlighted(date)"
										[class.bg-primary]="isHighlighted(date)"
										[class.text-white]="isHighlighted(date)"
										[class.text-muted]="disabled"
										[class.muted-item]="date.month !== currentMonth"
										[class.outside-current-month]="date.month !== currentMonth"
									>{{ date.day }}</div>
								</ng-template>
							</div>
							<p-validation-hint
								*ngIf="formGroup!.controls['date']!.touched"
								[control]="formGroup!.controls['date']!"
							></p-validation-hint>
						</div>
						<p-bootstrap-form-group
							*ngIf="showTimeInput"
							label="Uhrzeit" i18n-label
							[hasDanger]="formGroup!.controls['time']!.invalid && (formGroup!.controls['time']!.touched || formGroup!.controls['time']!.dirty) || (!!control && hasDanger)"
						>
							<p-input
								[type]="PApiPrimitiveTypes.LocalTime"
								[formControl]="formGroup!.controls['time']!"
								[valid]="formGroup!.controls['time']!.valid && (!control || !hasDanger)"
								autocomplete="off"
							></p-input>
						</p-bootstrap-form-group>

						<ng-container *ngTemplateOutlet="controlErrors"></ng-container>
					</form>
				</p-editable-modal-form>
			</button>
		</ng-template>

		<div class="input-group-append" *ngIf="type === 'deadline' && showDaysBeforeInput === true">
			<span class="input-group-text">{{ daysBeforeAppendText }}</span>
		</div>
		<div
			#cannotSetHintTemplate
			[class.has-space-left]="!!cannotSetHintTemplate.offsetLeft"
			class="input-group-append cannot-set-hint"
			*ngIf="disabled && cannotSetHint">
			<button
				*ngIf="!!cannotSetHint && !!disabled"
				class="btn btn-outline-secondary"
				type="button"
				(click)="openCannotSetHint()"
				title="Info" i18n-title
			><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon></button>
		</div>

	</ng-template>
</div>

<ng-container *ngTemplateOutlet="controlErrors"></ng-container>
<ng-template #controlErrors>
	<ng-template [ngIf]="!!control && hasDanger">
		<ng-template
			ngFor let-error
			[ngForOf]="visibleErrors(control)"
		>
			<p-validation-hint
				[control]="control"
				[isInvalid]="true"
				[validationName]="error.key"
				[errorValue]="error.value"
			></p-validation-hint>
		</ng-template>
	</ng-template>
</ng-template>

<ng-content></ng-content>
