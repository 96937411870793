import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { SchedulingApiRightGroup, SchedulingApiRightGroupRole, SchedulingApiRightGroups, SchedulingApiService } from '@plano/shared/api';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-rightgroup-header[rightGroups]',
	templateUrl: './rightgroup-header.component.html',
	styleUrls: ['./rightgroup-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class RightgroupHeaderComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public rightGroup : SchedulingApiRightGroup | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private rightGroupChange : EventEmitter<SchedulingApiRightGroup | null> = new EventEmitter<SchedulingApiRightGroup | null>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public rightGroups ! : SchedulingApiRightGroups;

	@HostBinding('class.card') private _alwaysTrue = true;
	@HostBinding('class.bg-dark') private get hasBgDark() : boolean {
		return !!this.rightGroup;
	}

	constructor(
		public _api : SchedulingApiService,
		private modalService : ModalService,
		private localize : LocalizePipe,
	) {
	}

	public schedulingApiRightGroupRole : typeof SchedulingApiRightGroupRole = SchedulingApiRightGroupRole;

	/**
	 * Sorted Right groups by name
	 */
	public get sortedRightGroupsByName() : SchedulingApiRightGroups {
		return this.rightGroups.sortedBy(rightGroup => rightGroup.name);
	}

	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get api() : SchedulingApiService { return this._api; }

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public selectRightGroup(rightGroupItem : SchedulingApiRightGroup) : void {
		this.rightGroup = rightGroupItem;
		this.rightGroupChange.emit(rightGroupItem);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isAdminRightGroup(rightGroup : SchedulingApiRightGroup | null) : boolean {
		return !!rightGroup && rightGroup.role === SchedulingApiRightGroupRole.CLIENT_OWNER;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setToAdminRightGroup() : void {
		assumeNonNull(this.rightGroup);
		this.rightGroup.role = SchedulingApiRightGroupRole.CLIENT_OWNER;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isMemberRightGroup(rightGroup : SchedulingApiRightGroup | null) : boolean {
		return !!rightGroup && rightGroup.role === SchedulingApiRightGroupRole.CLIENT_DEFAULT;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setToMemberRightGroup() : void {
		assumeNonNull(this.rightGroup);
		this.rightGroup.role = SchedulingApiRightGroupRole.CLIENT_DEFAULT;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public addRightGroup(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		const previousRightGroup = this.rightGroup;
		this.rightGroup = null;
		this.rightGroup = this.rightGroups.createNewItem(item => {
			item.role = SchedulingApiRightGroupRole.CLIENT_DEFAULT;
			item.name = '';
		});
		const modalRef = this.modalService.openModal(modalContent);
		void modalRef.result.then(value => {
			if (value.modalResult === 'success') {
				void this._api.save();
				this.rightGroupChange.emit(this.rightGroup);
			} else {
				assumeNonNull(this.rightGroup);
				this.rightGroups.removeItem(this.rightGroup);
				this.rightGroup = previousRightGroup;
			}
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasRightGroupChangeBinding() : boolean {
		return this.rightGroupChange.observers.length > 0;
	}

	/**
	 * Check if this is the last existing admin group
	 */
	public lastExistingAdminGroup(rightGroup : SchedulingApiRightGroup) : boolean {
		if (rightGroup.role !== SchedulingApiRightGroupRole.CLIENT_OWNER) {
			return false;
		}
		let result = 0;
		for (const rightGroupItem of this.rightGroups.iterable()) {
			if (rightGroupItem.role === SchedulingApiRightGroupRole.CLIENT_OWNER) {
				++result;
			}
		}
		return result === 1;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getTitleForRightGroup(rightGroup : SchedulingApiRightGroup | null) : string {
		return rightGroup?.name ?? this.localize.transform('Bitte wählen');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getTitleForTypeOfRightGroup(rightGroup : SchedulingApiRightGroup) : string {
		if (this.isAdminRightGroup(rightGroup)) return this.localize.transform('Admins');
		if (this.isMemberRightGroup(rightGroup)) return this.localize.transform('Mitarbeitende');
		return '-';
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get editableIsActive() : boolean {
		if (!this.rightGroup) return false;
		if (this.rightGroup.isNewItem()) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get translatedLabelForRole() : string {
		assumeNonNull(this.rightGroup);
		return this.localize.transform({
			sourceString: 'Für wen ist ${name} gedacht?',
			params: {name: this.rightGroup.attributeInfoName.value ?? this.localize.transform('sie')},
		});
	}
}
