<div
	class="card mb-1 mt-1"
	[class.shadow-sm]="!process.collapsed"
	[class.is-closed]="process.collapsed"
>
	<div class="card-header p-2 d-flex align-items-center justify-content-start"
		role="button"
		[title]="processName"
		tabindex="0"
		[class.shadow-sm]="!process.collapsed"
		[class.is-closed]="process.collapsed"
		[class.border-bottom-0]="process.collapsed"
		[class.clickable]="true"
		[class.btn-light]="userCanSetAssignmentProcess"

		[selected]="process.selected"
		[affected]="process.affected"

		(click)="onClickProcess.emit(process)"
	>
		<fa-icon
			*ngIf="isCollapsible"
			class="ml-1 mr-3"
			[fixedWidth]="true"
			[icon]="!process.collapsed ? enums.PlanoFaIconPool.COLLAPSIBLE_OPEN : enums.PlanoFaIconPool.COLLAPSIBLE_CLOSE"
		></fa-icon>
		<div style="word-break: break-word;"
			[class.mr-1]="process.missingAssignmentsCount && process.shiftRefs.length && userCanSetAssignmentProcess"
		>
			<h6 class="mb-0" [id]="ariaLabelHtmlId">{{processName}}</h6>
			<div *ngIf="!userCanSetAssignmentProcess || process.collapsed" class="small pt-1 d-flex align-items-start">
				<p-assignment-process-icon
					class="mr-1"
					[process]="process"
					[state]="process.state !== states.NEEDING_APPROVAL ? process.state : states.APPROVE"
					[isOwner]="!!rightsService.isOwner"
				></p-assignment-process-icon>
				<div class="flex-grow-1">
					<div class="mb-0 text-left text-style-reset-to-body">{{ processTitleForState(process) | localize }}</div>
					<div class="text-style-reset-to-body" *ngIf="(!userCanSetAssignmentProcess || process.collapsed) && !!process.deadline"><ng-container i18n>Frist</ng-container>: {{ process.deadline | date:'shortDate' }}</div>
				</div>
			</div>
		</div>
		<ng-template [ngIf]="process.missingAssignmentsCount && process.shiftRefs.length && userCanSetAssignmentProcess">
			<p-member-badge
				class="ml-auto"
				pTooltip="Anzahl unbesetzter Schichtplätze in diesem Vorgang" i18n-pTooltip

				[text]="process.missingAssignmentsCount.toString()"
				size="small"
			></p-member-badge>
		</ng-template>
		<ng-template [ngIf]="!process.missingAssignmentsCount && process.shiftRefs.length">
			<p-member-badge
				[borderStyle]="enums.PThemeEnum.SUCCESS"
				class="ml-auto"
				pTooltip="Alle Schichtplätze in diesem Vorgang besetzt" i18n-pTooltip
				icon="check"
				size="small"
			></p-member-badge>
		</ng-template>
	</div>

	<!-- For performance optimization we also added *ngIf -->
	<div
		class="collapse"
		[class.show]="!process.collapsed"
		*ngIf="userCanSetAssignmentProcess && !process.collapsed"
	>
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</div>

	<p-badge
		*ngIf="!isCollapsible"
		[content]="process.todoShiftsCountTotal"
		[align]="'right'"
		class="align-right"
	></p-badge>

</div>

<ng-template #contentTemplate>
	<section>
		<p-assignment-process-type-caption
			[process]="process"
			[type]="process.type"
			[state]="process.state"
		></p-assignment-process-type-caption>
	</section>

	<section class="card-options">
		<!-- linthtml-configure tag-req-attr="false" -->
		<button
			type="button"
			class="btn p-2 clickable flex-grow-1 text-left border-0 rounded-0 d-block crop-on-overflow btn-light"
			[selected]="process.selected"
			[affected]="process.affected"
			[title]="selectShiftRefsBtnLabel"
			(click)="selectProcess.emit(process)"
		>
			<div hidden><span i18n #translatedProcessButtonTitle>{!!process.shiftRefs, select, true {{{process.shiftRefs.length}}}} Schichten im Kalender anzeigen</span></div>
			<div
				style="line-height: 1.8;"
				class="crop-on-overflow"
				[title]="translatedProcessButtonTitle.textContent!"
			>{{ selectShiftRefsBtnLabel }}</div>
		</button>

		<div
			[pEditable]="true"
			class="d-flex align-items-stretch"
			[api]="api"
			[valid]="true"

			[pTooltip]="addAndRemovePopover"
		>
			<button
				type="button"
				pEditableInstantSaveOnClick
				class="card-option clickable btn btn-frameless d-block w-100"
				style="min-width: 56px;"
				title="Selektierte Schichten hinzufügen" i18n-title
				(triggerClick)="onAddSelectedShifts(noShiftsHint, addShiftsWarnings)"
				[class.btn-outline-secondary]="!someSelectedShiftsAreAddable"
				[class.btn-primary]="someSelectedShiftsAreAddable"
				[disabled]="showYouHaveNoRightToDoThisAlert || process.isNewItem()"
			>
				<span
					style="line-height: 1.7;"
					class="nowrap"
				><fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon><span
					*ngIf="addableSelectedShiftsAmount"
					class="text-monospace"
				> {{addableSelectedShiftsAmount}}</span></span>
			</button>

		</div>

		<div
			[pEditable]="true"
			class="d-flex align-items-stretch"
			[api]="api"
			[valid]="true"
			[saveChangesHook]="removeSelectedShiftsRequest(removeShiftsWarnings.template)!"

			[pTooltip]="addAndRemovePopover"
		>
			<button
				type="button"
				pEditableInstantSaveOnClick
				class="card-option clickable btn btn-frameless d-block w-100"
				style="min-width: 56px;"
				title="Selektierte Schichten entfernen" i18n-title
				(triggerClick)="removeSelectedShifts(noShiftsHint)"
				[class.btn-outline-secondary]="!someSelectedShiftsAreRemovable"
				[class.btn-primary]="someSelectedShiftsAreRemovable"
				[disabled]="showYouHaveNoRightToDoThisAlert || process.isNewItem()"
			>
			<!-- [disabled]="!someSelectedShiftsAreRemovable" -->
				<span
					style="line-height: 1.7;"
					class="nowrap"
				><fa-icon icon="minus"></fa-icon><span
					*ngIf="removableSelectedShiftsAmount"
					class="text-monospace"
				> {{removableSelectedShiftsAmount}}</span></span>
			</button>
		</div>
	</section>

		<ng-template [ngIf]="!!missingPrefsMemberIdsLength && (
			process.state === states.NEEDING_APPROVAL || process.state === states.ASKING_MEMBER_PREFERENCES
		)">
			<section class="p-3">
				<div class="text-right">
					<p class="mb-0" style="text-overflow: ellipsis; overflow: hidden;" i18n>Bei {missingPrefsMemberIdsLength > 1, select, true {diesen {{missingPrefsMemberIdsLength}} Personen} other {dieser Person}} ist die Wunschabgabe unvollständig: {{namesOfMembersThatMissedToSetWishes}}</p>
				</div>
			</section>
		</ng-template>

		<ng-template [ngIf]="showErneutAnfordernBtn">
			<section class="p-3">
				<ng-template #popoverReopenEarlyBird>
					<span i18n>Alle Schichten dieses Verteilungsvorgangs sind bereits verteilt.</span>
				</ng-template>
				<div
					[pTooltip]="(process.state === states.EARLY_BIRD_FINISHED && assignmentStateIsAllAssigned) ? popoverReopenEarlyBird : null"
					[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
				>
					<!-- linthtml-configure tag-req-attr="false" -->
					<button
						type="button"
						class="clickable btn w-100 pl-2 pr-2"
						(click)="onStartProcess(modalContent.template)"
						[class.btn-outline-secondary]="!missingPrefsMemberIdsLength"
						[class.btn-primary]="missingPrefsMemberIdsLength"
						[disabled]="!process.shiftRefs.length || (process.state === states.EARLY_BIRD_FINISHED && assignmentStateIsAllAssigned)"
					>
						<span *ngIf="process.state === states.EARLY_BIRD_FINISHED"><fa-icon [icon]="enums.PlanoFaIconPool.EARLY_BIRD" class="mr-1"/><span i18n>Erneut freigeben</span></span>
						<span *ngIf="process.state === states.NEEDING_APPROVAL || process.state === states.MANUAL_SCHEDULING"><fa-icon icon="heart"></fa-icon>&nbsp;<span i18n>Erneut anfordern</span></span>
					</button>
				</div>
			</section>
		</ng-template>


	<ng-template [ngIf]="(
		process.state === states.ASKING_MEMBER_PREFERENCES ||
		process.state === states.EARLY_BIRD_SCHEDULING
	)">
		<section class="card-body p-0">
			<div class="d-flex justify-content-between card-options">
				<div class="p-2 text-left" i18n>Frist</div><div class="p-2 ml-auto">{{ process.deadline | date:'shortDate' }}</div>
				<!-- linthtml-configure tag-req-attr="false" -->
				<button
					type="button"
					*ngIf="userCanSetAssignmentProcess"
					class="btn btn-outline-secondary card-option border-left border-bottom-0"
					style="line-height: 1.7;"
					(click)="onEditDeadline(modalContent.template)"
					title="Frist bearbeiten" i18n-title
				><fa-icon icon="pen"></fa-icon></button>
			</div>
		</section>
	</ng-template>

	<section class="card-body p-2">
		<div class="d-flex">
			<p-delete-button
				class="mr-2"
				modalText="Willst du diesen Vorgang wirklich löschen? Dieser Schritt ist unwiderruflich." i18n-modalText
				(onModalSuccess)="removeProcess()"
			></p-delete-button>

			<ng-template [ngIf]="process.state === states.NOT_STARTED">
				<div
					class="flex-grow-1"
					[pTooltip]="!processHasWishesMode && assignmentStateIsAllAssigned ? 'Du kannst die Verteilung noch nicht freigeben, da alle Plätze der in diesem Vorgang enthaltenen Schichten bereits besetzt sind.' : null"
				>
					<!-- linthtml-configure tag-req-attr="false" -->
					<button
						type="button"
						class="clickable btn w-100 pl-2 pr-2"
						(click)="onStartProcess(modalContent.template)"
						[class.btn-outline-secondary]="!process.shiftRefs.length"
						[class.btn-primary]="process.shiftRefs.length"
						[disabled]="api.isBackendOperationRunning || !process.shiftRefs.length || !processHasWishesMode && assignmentStateIsAllAssigned"
					>
						<span><p-assignment-process-icon
							[process]="process"
							[state]="process.type === types.EARLY_BIRD ? states.EARLY_BIRD_SCHEDULING : states.ASKING_MEMBER_PREFERENCES"
							[isOwner]="!!rightsService.isOwner"
						></p-assignment-process-icon>&nbsp;<ng-container i18n>{process.type === types.EARLY_BIRD, select, true {Schichten freigeben} other {Wünsche anfordern}}</ng-container></span>
					</button>
				</div>
			</ng-template>
			<div
				[class.d-none]="!(
					process.state === states.ASKING_MEMBER_PREFERENCES ||
					process.state === states.EARLY_BIRD_SCHEDULING ||
					process.state === states.NEEDING_APPROVAL ||
					process.state === states.APPROVE ||
					process.state === states.MANUAL_SCHEDULING ||
					process.state === states.EARLY_BIRD_FINISHED
				)"
				[pEditable]="process.state !== states.EARLY_BIRD_FINISHED"
				class="w-100"
				[api]="api"
				[valid]="!(process.state === states.ASKING_MEMBER_PREFERENCES || process.state === states.EARLY_BIRD_SCHEDULING)"

				[saveChangesHook]="modalService.getEditableHookModal(publishWarning.template)"
			>
				<div
					[pTooltip]="process.state === states.ASKING_MEMBER_PREFERENCES || process.state === states.EARLY_BIRD_SCHEDULING ? tooltipPublish : null"
					[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
				>
					<!-- linthtml-configure tag-req-attr="false" -->
					<button
						type="button"
						class="clickable btn w-100 pl-2 pr-2"
						[class.btn-outline-secondary]="missingPrefsMemberIdsLength"
						[class.btn-primary]="!missingPrefsMemberIdsLength"
						[disabled]="process.state === states.ASKING_MEMBER_PREFERENCES || process.state === states.EARLY_BIRD_SCHEDULING"

						pEditableInstantSaveOnClick
						(triggerClick)="confirmProcess()"
					>
						<span *ngIf="process.state === states.EARLY_BIRD_FINISHED || process.state === states.EARLY_BIRD_SCHEDULING"><fa-icon [icon]="enums.PlanoFaIconPool.SUCCESS"></fa-icon>&nbsp;<ng-container i18n>Abschließen</ng-container> </span>
						<span *ngIf="!(process.state === states.EARLY_BIRD_FINISHED || process.state === states.EARLY_BIRD_SCHEDULING)"><fa-icon icon="bullhorn"></fa-icon>&nbsp;<ng-container i18n>Veröffentlichen</ng-container> </span>
					</button>
				</div>

				<ng-template #tooltipPublish>
					<div class="d-block clickable card m-0 text-left o-hidden"
					>
						<div class="card-body p-1 pl-2 pr-2">
							<div [ngSwitch]="process.type">
								<div *ngSwitchCase="types.EARLY_BIRD" i18n>Den Vorgang kannst du erst abschließen, wenn die Eintragungsfrist für deine Angestellten abgelaufen ist.</div>
								<div *ngSwitchCase="types.MANUAL" i18n>Die Schichten kannst du erst veröffentlichen, wenn die Wunschabgabe beendet ist und du die Schichten besetzt hast.</div>
								<div *ngSwitchDefault i18n>Die Schichten kannst du erst veröffentlichen, wenn die Wunschabgabe beendet ist und Dr.&nbsp;Plano alles verteilt hat.</div>
							</div>
						</div>
					</div>
				</ng-template>

			</div>
		</div>
	</section>
</ng-template>

<ng-template #publishWarning="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Beachte!" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<section class="modal-body">
		<div class="p-3">
			<p i18n>Wenn du <code>{{processName}}</code> veröffentlichst, wird die Schichtverteilung für alle an diesem Vorgang beteiligten Personen sichtbar und es können per Stempeluhr Arbeitszeiten erfasst werden.</p>
			<p i18n><code>{{processName}}</code> ist damit abgeschlossen und wird aus deinem Schreibtisch entfernt. Dieser Schritt ist unwiderruflich. Du kannst aber jederzeit neue Vorgänge anlegen.</p>
		</div>
		<p-notification-conf-form
			[(ngModel)]="api.data.notificationsConf.sendEmail"
			[api]="api"
			valueText="Betroffene User benachrichtigen" i18n-valueText
		></p-notification-conf-form>
	</section>
	<div class="modal-footer">
		<!-- linthtml-configure tag-req-attr="false" -->
		<button
			type="button"
			class="btn btn-outline-secondary" (click)="d($event)"
			i18n
		>Abbrechen</button>
		<!-- linthtml-configure tag-req-attr="false" -->
		<button type="button"
			class="btn btn-primary ml-auto"
			(click)="c($event);"
			i18n
		>OK</button>
	</div>
</ng-template>

<ng-template #removeShiftsWarnings="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Beachte!" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<ng-container *ngTemplateOutlet="partOfPaketAlertTemplate"></ng-container>
	</div>
	<div class="modal-footer">
		<!-- linthtml-configure tag-req-attr="false" -->
		<button type="button" class="btn btn-outline-secondary" (click)="d($event)" i18n>Abbrechen</button>
		<!-- linthtml-configure tag-req-attr="false" -->
		<button type="button"
			class="btn btn-primary ml-auto"
			(click)="c($event);"
			i18n
		>OK</button>
	</div>
</ng-template>

<ng-template #addShiftsWarnings let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Beachte!" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<div *ngIf="someSlotsAreEmptyButProcessHasEnded(api.data.shifts.selectedItems)">
			<p-alert>
				<h5 class="d-inline" i18n>Bereits verteilt</h5>
				<p i18n>Eine oder mehrere der Schichten, die du hinzufügen möchtest, sind nicht vollständig besetzt. Die Schichten von <mark>{{processName}}</mark> sind aber schon verteilt worden.</p>
			</p-alert>
		</div>
		<div *ngIf="someShiftsArePartOfAProcess(api.data.shifts.selectedItems)">
			<p-alert>
				<h5 class="d-inline" i18n>Bereits in einem Vorgang</h5>
				<p i18n>Eine oder mehrere Schichten sind bereits in einem anderen Vorgang enthalten. Wenn du fortfährst, werden sie dort rausgenommen.</p>
			</p-alert>
		</div>
		<ng-container *ngTemplateOutlet="partOfPaketAlertTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="usersWillGetNotificationsAboutChangesTemplate"></ng-container>
	</div>
	<div class="modal-footer">
		<!-- linthtml-configure tag-req-attr="false" -->
		<button type="button" class="btn btn-outline-secondary" (click)="d($event)" i18n>Abbrechen</button>
		<!-- linthtml-configure tag-req-attr="false" -->
		<button type="button"
			class="btn btn-primary ml-auto"
			(click)="c($event);"
			i18n
		>OK</button>
	</div>
</ng-template>

<ng-template #noShiftsHint let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Schichtumfang ändern" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<div class="p-3">
			<span i18n>Selektiere im Kalender die Schichten, die du diesem Vorgang hinzufügen oder aus ihr rausnehmen möchtest. Klicke anschließend auf <fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon> oder <fa-icon icon="minus"></fa-icon></span>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary ml-auto" (click)="d($event)">OK</button>
	</div>
</ng-template>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<div hidden><span i18n #translatedTitleForModalHeader>Frist {process.state === states.NEEDING_APPROVAL || process.state === states.EARLY_BIRD_FINISHED || process.state === states.MANUAL_SCHEDULING, select, true {erneut }}{process.state === states.ASKING_MEMBER_PREFERENCES, select, true {bearbeiten} other {setzen}}</span></div>
	<p-modal-header
		[headline]="translatedTitleForModalHeader.textContent!"
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<ng-template [ngIf]="showOnlyAskPrefsForUnassignedShiftsFormElement">
			<section>
				<p-radio
					class="d-block mb-3"
					[checked]="process.onlyAskPrefsForUnassignedShifts!"
					(checkedChange)="process.onlyAskPrefsForUnassignedShifts=true"
					[card]="true"
				>
					<div style="border-left: 1px solid #dfdfdf;" class="pl-3">
						<span i18n>Nur für unbesetzte Schichtplätze</span><br/>
						<small i18n>Deine Angestellten geben ihre Schichtwünsche nur für Schichten mit freien Plätzen ab. Besetzte Schichtplätze werden nicht neu verteilt. Eventuell vorhandene Schichtwünsche bleiben erhalten und können korrigiert werden.</small>
					</div>
				</p-radio>

				<p-radio
					class="d-block mb-3"
					[checked]="!process.onlyAskPrefsForUnassignedShifts"
					(checkedChange)="process.onlyAskPrefsForUnassignedShifts=false"
					[card]="true"
				>
					<div style="border-left: 1px solid #dfdfdf;" class="pl-3">
						<span i18n>Für alle Schichten dieses Verteilungsvorgangs</span><br/>
						<small i18n>Deine Angestellten geben ihre Schichtwünsche für alle Schichten ab - egal ob besetzt oder unbesetzt. Eventuell vorhandene Schichtwünsche bleiben erhalten und können korrigiert werden.</small>
					</div>
				</p-radio>
				<p-alert *ngIf="showAllAssignmentsGetLostWarning" [theme]="enums.PThemeEnum.DANGER" i18n>Die aktuelle Besetzung der Schichten in diesem Verteilungsvorgang geht verloren.</p-alert>
				<p-alert *ngIf="showAssignmentsRemainUntouchedHint" [theme]="enums.PThemeEnum.INFO" i18n>In diesem Vorgang befinden sich besetzte Schichtplätze. Diese bleiben unangetastet.</p-alert>
			</section>
		</ng-template>
		<section *ngIf="allOrNoneAssigned && (assignmentStateIsNoneAssigned || showReAssignmentWarning)">
			<p-alert *ngIf="assignmentStateIsNoneAssigned">
				<span i18n>Keine der Schichten in diesem Vorgang ist besetzt.</span>
				&nbsp;
				<span i18n>Alle Schichtplätze werden nun verteilt.</span>
			</p-alert>
			<p-alert *ngIf="showReAssignmentWarning" [theme]="enums.PThemeEnum.DANGER">
				<span i18n>Alle Schichten in diesem Vorgang sind vollständig besetzt.</span>&nbsp;<span i18n>Wenn du fortfährst, geht die aktuelle Schichtbesetzung verloren. Bereits abgegebene Schichtwünsche der Mitarbeitenden bleiben allerdings erhalten und können korrigiert werden.</span>
			</p-alert>
		</section>
		<section>
			<label i18n>Setze eine Frist für deine Mitarbeitenden:</label>
			<div class="row mb-3">
				<div class="col d-flex justify-content-center align-items-center">
					<ngb-datepicker
						[(ngModel)]="deadlineObject"
						[startDate]="deadlineObject === '-' ? undefined! : deadlineObject"
					></ngb-datepicker>
				</div>
			</div>
			<p class="mb-2"><ng-container i18n>Deine Mitarbeitenden bekommen Zeit bis zum {{ deadlineTimestamp | date:'shortDate' }}, {{lastMinuteOfDayInTimeFormat}}.</ng-container>&nbsp;<ng-template [ngIf]="!(daysTillDeadline >= 0)"><span class="text-danger" i18n>Achtung: der ausgewählte Tag liegt in der Vergangenheit!</span>&nbsp;</ng-template><span *ngIf="daysTillDeadline >= 0" i18n>Also insgesamt <code
				[class.text-danger]="daysTillDeadline <= 0"
			>{{daysTillDeadlineText}}</code>.</span></p>

			<p-bootstrap-form-group>
				<p-checkbox
					[(ngModel)]="process.assignMembersWhenNoPrefAvailable"
					*ngIf="process.type === types.DR_PLANO"
					icon="heart"
					valueText="Mitarbeitende einteilen, wenn sie es trotz Erinnerung versäumen, ihre Schichtwünsche abzugeben" i18n-valueText
				></p-checkbox>
			</p-bootstrap-form-group>
			<p-bootstrap-form-group>
				<p-notification-conf-form
					[(ngModel)]="api.data.notificationsConf.sendEmail"
					[api]="api"
					valueText="Betroffene User benachrichtigen" i18n-valueText
				></p-notification-conf-form>
			</p-bootstrap-form-group>
		</section>
	</div>
	<div class="modal-footer">
		<!-- linthtml-configure tag-req-attr="false" -->
		<button
			type="button"
			class="btn btn-outline-secondary" (click)="d($event)"
			i18n
		>Abbrechen</button>
		<!-- linthtml-configure tag-req-attr="false" -->
		<button type="button"
			class="btn btn-primary ml-auto"
			(click)="c($event);"
			i18n
		>Frist setzen</button>
	</div>
</ng-template>

<ng-template #partOfPaketAlertTemplate>
	<div *ngIf="removePackagePromptIsNecessary || addPackagePromptIsNecessary(api.data.shifts.selectedItems)" class="mb-3">
		<p-alert>
			<h5 class="d-inline" i18n>Teil eines Pakets</h5>
			<p i18n>Deine aktuelle Selektion beinhaltet Schichten, die Teil eines Pakets sind. Der Rest dieses Pakets liegt möglicherweise außerhalb des im Kalender angezeigten Zeitraums. Soll nur die aktuelle Selektion {removePackagePromptIsNecessary, select, true {aus dem Verteilungsvorgang entfernt}}{addPackagePromptIsNecessary(api.data.shifts.selectedItems), select, true {dem Verteilungsvorgang hinzugefügt}} werden oder das ganze Paket?</p>
		</p-alert>
		<p-radios
			[(ngModel)]="wholePackages"
		>
			<p-radios-radio
				label="Nur selektierte Schichten" i18n-label
				[value]="false"
			></p-radios-radio>
			<p-radios-radio
				label="Alle Schichten des Pakets" i18n-label
				[value]="true"
			></p-radios-radio>
		</p-radios>
	</div>
</ng-template>

<ng-template #usersWillGetNotificationsAboutChangesTemplate>
	<div *ngIf="usersWillGetNotificationsAboutChanges" class="mt-2">
		<p i18n>Möchtest du die betroffenen User über die <strong>neuen Schichten</strong> im Verteilungsvorgang benachrichtigen?</p>
		<p-notification-conf-form
			[(ngModel)]="api.data.notificationsConf.sendEmail"
			[api]="api"
			valueText="Betroffene User benachrichtigen" i18n-valueText
		></p-notification-conf-form>
	</div>
</ng-template>
