<p-cell-top
	[id]="htmlElementId"
	(clickCellTop)="onCellTopClick()"
	[dayStart]="dayAsTimestamp"
	dateFormat="dd"
	[shiftsOfDay]="shiftsOfDay"
	[pinStickyNote]="pinStickyNote"
	[neverShowDayTools]="neverShowDayTools"
	[canSetMemos]="api.data.attributeInfoMemos.canSet"
></p-cell-top>
<p-all-day-items-list
	[startOfDay]="dayAsTimestamp"
	[readMode]="readMode"
	[absences]="absences"
	[calendarMode]="CalendarModes.MONTH"
	[holidays]="holidays"
	[birthdays]="birthdays"
></p-all-day-items-list>

<div class="shifts m-1">
	<ng-template
		ngFor let-shift
		[ngForOf]="shiftsOfDay.iterable()"
	>
		<ng-template
			[ngTemplateOutlet]="shiftTemplate"
			[ngTemplateOutletContext]="{$implicit: shift, delayIsActive: delayIsActive}"
		></ng-template>
	</ng-template>

	<ng-template [ngIf]="!shiftsOfDay.length">
		<div class="p-2 text-center">
			<div><fa-icon style="opacity: 0.3;" [icon]="enums.PlanoFaIconPool.DISMISS"></fa-icon></div>
		</div>
	</ng-template>
</div>
