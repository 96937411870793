import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastsService } from '@plano/client/service/toasts.service';
import { BootstrapRounded, PTextColor } from '@plano/client/shared/bootstrap-styles.enum';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PClipboardService } from '@plano/shared/p-forms/p-clipboard.service';
import { PInputComponent } from '@plano/shared/p-forms/p-input/p-input.component';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-input-copy-string',
	templateUrl: './p-input-copy-string.component.html',
	styleUrls: ['./p-input-copy-string.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputCopyStringComponent implements PComponentInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public valueToCopy : string | null = null;

	/**
	 * Text to be appended to the copy button
	 */
	@Input() public appendButtonText : string | null = null;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public type : PInputComponent['type'] = PApiPrimitiveTypes.string;

	constructor(
		public toasts : ToastsService,
		private localize : LocalizePipe,
		private activeModal : NgbActiveModal,
		private clipboard : PClipboardService,
	) {
	}

	public BootstrapRounded = BootstrapRounded;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	/**
	 * Copy string to clipboard
	 */
	public copyString(input : string) : void {
		this.clipboard.copy(input);
		this.toasts.addToast({
			content: this.localize.transform('Wurde in die Zwischenablage kopiert.'),
			theme: enumsObject.PThemeEnum.SUCCESS,
			icon: enumsObject.PlanoFaIconPool.COPY_TO_CLIPBOARD,
			visibilityDuration: 'short',
		});
	}

	/**
	 * Close modal
	 */
	public close() : void {
		this.activeModal.close();
	}

	/**
	 * Dismiss modal
	 */
	public dismiss() : void {
		this.activeModal.dismiss();
	}

	/**
	 * Get icon for the 'copy' button
	 */
	public get icon() : FaIcon {
		if (this.isLoading) return enumsObject.PlanoFaIconPool.SYNCING;
		return this.clipboard.copiedToClipboard === this.valueToCopy ? 'check' : enumsObject.PlanoFaIconPool.COPY_TO_CLIPBOARD;
	}

	/**
	 * Is the icon of the 'copy' button spinning?
	 */
	public get iconSpinning() : boolean {
		if (this.icon === enumsObject.PlanoFaIconPool.SYNCING) return true;
		return false;
	}

	/**
	 * Select whole text if someone sets focus inside.
	 * Be one step ahead the users thoughts ;)
	 */
	public onFocus(event : FocusEvent) : void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if ((event.target as any).select) (event.target as any).select();
	}

	/** The theme color for the copy button */
	protected get copyButtonTextStyle() : PTextColor | null {
		if (this.isLoading) return enumsObject.PThemeEnum.SECONDARY;
		if (this.clipboard.copiedToClipboard === this.valueToCopy) return enumsObject.PThemeEnum.SUCCESS;
		return null;
	}
}
