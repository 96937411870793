import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PConfirmModalComponent {
	constructor(
		private activeModal : NgbActiveModal,
		private localize : LocalizePipe,
	) {}

	public enums = enumsObject;

	public modalContentOptions : ModalContentOptions = {};
	public theme ?: ModalServiceOptions['theme'] = null;

	/**
	 * Is the description a template ref? If so return it, otherwise return null
	 */
	public modalContentDescriptionTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Initializes the Modal with the necessary properties from its parent component.
	 */
	public initModal(
		content : ModalContentOptions,
		theme ?: ModalServiceOptions['theme'],
	) : void {
		this.modalContentOptions = content;
		if (this.modalContentOptions.description instanceof TemplateRef) {
			this.modalContentDescriptionTemplate = this.modalContentOptions.description;
		}
		if (theme) this.theme = theme;
		if (this.modalContentOptions.closeBtnTheme === undefined) this.modalContentOptions.closeBtnTheme = !theme ? enumsObject.PThemeEnum.PRIMARY : undefined;
		if (this.modalContentOptions.closeBtnLabel === undefined) this.modalContentOptions.closeBtnLabel = this.localize.transform('OK');
		if (this.modalContentOptions.hideDismissBtn === undefined) {
			this.modalContentOptions.hideDismissBtn = this.modalContentOptions.dismissBtnLabel === undefined;
		}
	}

	/**
	 * Close this modal
	 */
	public onClose() : void {
		this.activeModal.close();
	}

	/**
	 * Dismiss this modal
	 */
	public onDismiss() : void {
		this.activeModal.dismiss();
	}
}
