import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';

enum TimeRange {
	CURRENT,
	MINUTE,
	MINUTES,
	HOUR,
	HOURS,
	DAY,
	DAYS,
	MONTH,
	MONTHS,
	YEAR,
	YEARS,
}

@Pipe({
	name: 'pTimeAgo',

	// pure: false
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTimeAgoPipe implements PipeTransform, OnDestroy {
	constructor(
		private changeDetectorRef : ChangeDetectorRef,
		private ngZone : NgZone,
		private localize : LocalizePipe,
		private pMoment : PMomentService,
	) {}

	private timer : number | null = null;

	/**
	 * Transform timestamp into human readable format like "vor 5 min"
	 */
	public transform(value : number, shortVersion ?: boolean) : string {
		if (!value) return '';
		this.removeTimer();

		// TODO: remove Date() since it can cause problems in other timezones
		const d = new Date(value);

		const now = +this.pMoment.m();
		const seconds = Math.round(Math.abs((now - d.getTime()) / 1000));
		const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
		this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => {
						this.changeDetectorRef.markForCheck();
					});
				}, timeToUpdate);
			}
			return null;
		});

		if (Number.isNaN(seconds)) return '';

		return this.transformSecondsToText(seconds, shortVersion);
	}

	/**
	 * Return a TimeRange like a human would choose
	 * For example a human would probably not say '240 Minutes', he/she would instead say '2 Hours'.
	 */
	public getTimeRange(duration : number) : TimeRange {
		const minutes = Math.round(Math.abs(duration / 60));
		const hours = Math.round(Math.abs(minutes / 60));
		const days = Math.round(Math.abs(hours / 24));
		const months = Math.round(Math.abs(days / 30.416));

		const input = {
			seconds: duration,
			minutes: minutes,
			hours: hours,
			days: days,
			months: months,
		};

		if (input.seconds <= 45) return TimeRange.CURRENT;

		if (input.seconds <= 90) return TimeRange.MINUTE;
		if (input.minutes <= 45) return TimeRange.MINUTES;

		if (input.minutes <= 90) return TimeRange.HOUR;
		if (input.hours <= 22) return TimeRange.HOURS;

		if (input.hours <= 36) return TimeRange.DAY;
		if (input.days <= 25) return TimeRange.DAYS;

		if (input.days <= 40) return TimeRange.MONTH;
		if (input.months <= 12) return TimeRange.MONTHS;

		if (input.days <= 545) return TimeRange.YEAR;
		return TimeRange.YEARS;
	}

	private transformSecondsToText(seconds : number, shortVersion ?: boolean) : string {
		const minutes = Math.round(Math.abs(seconds / 60));
		const hours = Math.round(Math.abs(minutes / 60));
		const days = Math.round(Math.abs(hours / 24));
		const months = Math.round(Math.abs(days / 30.416));
		const years = Math.round(Math.abs(days / 365));

		const timeRange = this.getTimeRange(seconds);

		switch (timeRange) {
			case TimeRange.CURRENT:
				return this.localize.transform('gerade eben');
			case TimeRange.MINUTE:
				return shortVersion ? this.localize.transform('vor 1 Min.') : this.localize.transform('vor 1 Minute');
			case TimeRange.MINUTES:
				return shortVersion ? this.localize.transform({sourceString: 'vor ${minutes} Min.', params: {
					minutes: minutes.toString(),
				}}) : this.localize.transform({sourceString: 'vor ${minutes} Minuten', params: {
					minutes: minutes.toString(),
				}});
			case TimeRange.HOUR:
				return shortVersion ? this.localize.transform('vor 1 Std.') : this.localize.transform('vor 1 Stunde');
			case TimeRange.HOURS:
				return shortVersion ? this.localize.transform({ sourceString: 'vor ${hours} Std.', params: {
					hours: hours.toString(),
				}}) : this.localize.transform({ sourceString: 'vor ${hours} Stunden', params: {
					hours: hours.toString(),
				}});
			case TimeRange.DAY:
				return this.localize.transform('vor 1 Tag');
			case TimeRange.DAYS:
				return this.localize.transform({sourceString: 'vor ${days} Tagen', params: { days: days.toString() }});
			case TimeRange.MONTH:
				return shortVersion ? this.localize.transform('vor 1 Mon.') : this.localize.transform('vor 1 Monat');
			case TimeRange.MONTHS:
				return shortVersion ? this.localize.transform({sourceString: 'vor ${months} Mon.', params: {
					months: months.toString(),
				}}) : this.localize.transform({sourceString: 'vor ${months} Monaten', params: {
					months: months.toString(),
				}});
			case TimeRange.YEAR:
				return this.localize.transform('vor 1 Jahr');
			case TimeRange.YEARS:
				return this.localize.transform({sourceString: 'vor ${years} Jahren', params: { years: years.toString() }});
		}
	}

	public ngOnDestroy() : void {
		this.removeTimer();
	}

	private removeTimer() : void {
		if (!this.timer) return;
		window.clearTimeout(this.timer);
		this.timer = null;
	}

	private getSecondsUntilUpdate(seconds : number) : number {
		const min = 60;
		const hr = min * 60;
		const day = hr * 24;

		// less than 1 min, update every 2 secs
		if (seconds < min) return 2;

		// less than an hour, update every 30 secs
		if (seconds < hr) return 30;

		// less then a day, update every 5 mins
		if (seconds < day) return 300;

		// update every hour
		return 3600;
	}
}
