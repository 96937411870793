/* eslint max-lines: ["error", 1100] */
import { Location } from '@angular/common';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnDestroy, Optional, Output, QueryList, SkipSelf, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationSkipped, RoutesRecognized } from '@angular/router';
import { FLEX_GROW_ON_BOOLEAN_TRIGGER, FLEX_GROW_ON_NGIF_TRIGGER } from '@plano/animations';
import { SchedulingApiService } from '@plano/shared/api';
import { FaIconComponent } from '@plano/shared/core/component/fa-icon/fa-icon.component';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { PRouterService } from '@plano/shared/core/router.service';
import { PScrollToSelectorService } from '@plano/shared/core/scroll-to-selector.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subject, Subscription } from 'rxjs';
import { PTabComponent } from './p-tab/p-tab.component';

/**
 * The different themes supported by the tabs component
 */
export enum PTabsTheme {

	/** The 'default' theme is adds a 'card' looking element around the content
	 * of the tabs, as well as the different tab options. It will be replaced by the
	 * clean theme in the future, so it shouldn't be used in new components/pages.
	 * @deprecated
	 */
	DEFAULT,

	/**
	 * The 'clean' theme adds a subtle line between the content and the different tab options,
	 * leaving the background transparent and without a clear separation from the page underneath it.
	 * The active tab is displayed by adding a border primary bottom to the option.
	 */
	CLEAN,

	/**
	 * The 'side' theme adds a column on the left side of the page, and the content of the tabs on the right.
	 * It is supposed to be used as the main component of a page as it completely changes its layout.
	 * The active tab is displayed by changing the style of the option to outline-dark.
	 */
	SIDE,
}

@Component({
	selector: 'p-tabs',
	templateUrl: './p-tabs.component.html',
	styleUrls: ['./p-tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [ FLEX_GROW_ON_BOOLEAN_TRIGGER, FLEX_GROW_ON_NGIF_TRIGGER ],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTabsComponent implements PComponentInterface, AfterContentInit, AfterViewInit, OnDestroy {

	@ViewChildren('liElements') private liTabElements ?: QueryList<ElementRef<HTMLLIElement>>;

	/**
	 * Shadow left of the tabs
	 */
	@ViewChild('shadowLeft') private shadowLeft ?: ElementRef<HTMLElement>;

	/**
	 * Shadow right of the tabs
	 */
	@ViewChild('shadowRight') private shadowRight ?: ElementRef<HTMLElement>;

	/**
	 * Chevron right of the tabs
	 */
	@ViewChild('chevronRight') private chevronRight ?: FaIconComponent;

	/**
	 * Chevron left of the tabs
	 */
	@ViewChild('chevronLeft') private chevronLeft ?: FaIconComponent;

	/**
	 * Element positioned at the top of the body
	 */
	@ViewChild('tabsBody') private tabsBodyElementRef ?: ElementRef<HTMLElement>;

	/**
	 * Shadow right of the tabs
	 */
	@ViewChild('activeTabBottomBorder') private activeBottomBorderElement ?: ElementRef<HTMLElement>;

	/**
	 * Should the tabs options be wrapped in a container?
	 */
	@Input() public containerTabs : boolean = false;

	/**
	 * Should the tabs have more or less spacing around its content?
	 */
	@Input() public size : typeof enumsObject.BootstrapSize.SM | null = null;

	/**
	 * headline to be used above the tabs
	 */
	@Input() public tabsHeadline : PDictionarySourceString | null = null;

	/**
	 * Only the content of the active tab is rendered to the DOM.
	 * The default for this attribute is set to false, as we currently
	 * have a lot of tabs with ai-switches inside that need to be rendered to the
	 * DOM to be added to the correct formGroups allowing us to show that an error
	 * in present in a certain tab inside a form, for example.
	 * @default false
	 */
	@Input() public renderOnlyContentOfActiveTab : boolean = false;

	/**
	 * Are these tabs full width?
	 *
	 * This should be set to false when the tabs are not supposed to take the whole available width.
	 * This way we know if we should add the ignore-gutter border to the tabs or not.
	 *
	 * NOTE: It won't change any classes you bind to the tabs, it will just add the 'is-full-width' class.
	 */
	@Input() public isFullWidth : boolean = true;

	/**
	 * Is this tabs component used at the top of a page?
	 * Will effect some margins, paddings etc.
	 */
	@HostBinding('class.page-sub-nav')
	@Input() public pageSubNav : boolean = false;

	@HostBinding('class.d-flex') private _alwaysTrue = true;

	/**
	 * Is side theme not selected?
	 */
	@HostBinding('class.flex-column') public get hasNotSideTheme() : boolean {
		return this.theme !== PTabsTheme.SIDE;
	}

	/**
	 * Nav back link to be used when pTabs is of theme SIDE and
	 * we are on mobile view.
	 */
	@Input() public navBackLink : `/${string}` | null = null;

	/**
	 * Should there be a card around this component?
	 * Note that this does not effect the theme of the tabs.
	 */
	@HostBinding('class.card')
	public get hasCard() : boolean {
		if (this._card === null) return this.theme === PTabsTheme.DEFAULT;
		return this._card;
	}

	@Input('card') private set setCard(input : boolean) {
		this._card = input;
	}

	@HostBinding('class.theme-clean')
	private get hasCleanTheme() : boolean {
		return this.theme === PTabsTheme.CLEAN;
	}

	@HostBinding('class.theme-default')
	private get hasDefaultTheme() : boolean {
		return this.theme === PTabsTheme.DEFAULT;
	}

	@HostBinding('class.on-mobile')
	private get onMobile() : boolean {
		return this.Config.IS_MOBILE;
	}

	@Input() public theme : PTabsTheme = PTabsTheme.DEFAULT;

	@HostBinding('class.tabs-dark-mode')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public darkMode : boolean = false;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/**
	 * If the horizontal space is to narrow – should the tab-buttons break into multiple lines?
	 */
	@Input('noWrap') public _noWrap : boolean = false;

	/**
	 * If this is set to true, then
	 * the not-active && not-hovered tabs will only show a icon and the active or hovered
	 * item will take as much space as possible and shows the label.
	 * The second described behavior is also known as 'flexible tabs'.
	 */
	@Input() public showIconOnlyBtns : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public minHeaderTabBar : number | null = null;

	/**
	 * Observable being called whenever api data change.
	 */
	@Output() public onChange : EventEmitter<PTabComponent> = new EventEmitter<PTabComponent>();

	@ContentChildren(PTabComponent) public tabs ?: QueryList<PTabComponent>;
	@ViewChild('tabsWrap') public tabsWrap ?: ElementRef<HTMLUListElement>;

	@ViewChild('topAnchor') public topAnchor ?: ElementRef<HTMLElement>;

	constructor(
		private elementRef : ElementRef<HTMLElement>,
		public activatedRoute : ActivatedRoute,
		private location : Location,
		private pRouterService : PRouterService,
		private zone : NgZone,
		private pScrollToSelectorService : PScrollToSelectorService,
		private console : LogService,
		private api : SchedulingApiService,
		@SkipSelf() @Optional() public pTabsParent ?: PTabsComponent,
		@SkipSelf() @Optional() private pTabParent ?: PTabComponent,
	) {
	}

	/**
	 * Event that emits every time a tab gets set to active
	 */
	public onActiveTabChange = new Subject<PTabComponent>();

	private _card : boolean | null = null;

	public PTabsTheme = PTabsTheme;
	public enums = enumsObject;
	public Config = Config;

	/**
	 * Should the tabs be wrapped?
	 */
	public get noWrap() : boolean {
		if (this._noWrap) return this._noWrap;
		else return Config.IS_MOBILE;
	}

	/**
	 * Get all tabs that should be visible in the dom.
	 */
	public get visibleTabs() : PTabComponent[] {
		return this.tabs!.toArray().filter(tab => tab.active || tab.show !== false);
	}
	public maxScrollLeft : number = 0;

	private _liElementsHeight = 0;

	/**
	 * Get the height of the LI element inside tabs
	 */
	public get liElementsHeight() : number {
		if (!this._liElementsHeight && this.liTabElements?.first) {
			this._liElementsHeight = this.liTabElements.first.nativeElement.getBoundingClientRect().height;
		}
		return this._liElementsHeight;
	}

	private handleMobileShadows() : void {
		if (this.topAnchor) {
			if (this.shadowLeft) {
				this.shadowLeft.nativeElement.style.opacity = (this.topAnchor.nativeElement.scrollLeft === 0) ? '0' : '0.5';
			}
			if (this.shadowRight) {
				this.shadowRight.nativeElement.style.opacity =
				((this.topAnchor.nativeElement.scrollLeft === this.maxScrollLeft) || (this.maxScrollLeft === 0)) ? '0' : '0.5';
			}
		}
	}

	public canScrollRight : boolean = false;

	private handleDesktopChevrons() : void {
		if (this.topAnchor) {
			const backgroundColorOfWrapper = window.getComputedStyle(this.topAnchor.nativeElement).backgroundColor;
			if (this.chevronLeft) {
				this.chevronLeft.el.nativeElement.style.background = `linear-gradient(to right, ${backgroundColorOfWrapper} 80%, transparent)`;
				const hide : boolean = this.topAnchor.nativeElement.scrollLeft === 0;
				this.chevronLeft.el.nativeElement.style.opacity = hide ? '0' : '1';
				this.chevronLeft.el.nativeElement.style.pointerEvents = hide ? 'none' : 'auto';
			}
			if (this.chevronRight) {
				this.chevronRight.el.nativeElement.style.background = `linear-gradient(to left, ${backgroundColorOfWrapper} 80%, transparent)`;
				const roundedScrollLeft = Math.floor(this.topAnchor.nativeElement.scrollLeft);
				const roundMaxScrollLeft = Math.floor(this.maxScrollLeft);
				const hide : boolean = (roundedScrollLeft === roundMaxScrollLeft) || (this.maxScrollLeft === 0);
				this.canScrollRight = !hide;
				this.chevronRight.el.nativeElement.style.opacity =
				hide ? '0' : '1';
				this.chevronRight.el.nativeElement.style.pointerEvents = hide ? 'none' : 'auto';
			}
		}
	}

	private handleScrollIndicatorsOnTabs() : void {
		if (this.showIconOnlyBtns) return;
		this.zone.runOutsideAngular(() => {
			window.requestAnimationFrame(() => {
				if (Config.IS_MOBILE)
					this.handleMobileShadows();
				else this.handleDesktopChevrons();

			});
		});
	}

	private isFirstCalculate : boolean = true;

	private calculateMaxScrollLeft() : void {
		if (this.topAnchor && this.tabsWrap) {
			const tabsWrapFullWidth = this.tabsWrap.nativeElement.offsetWidth;
			const topAnchorFullWidth = this.topAnchor.nativeElement.offsetWidth;
			const topAnchorLeftPadding = Number.parseFloat(window.getComputedStyle(this.topAnchor.nativeElement).paddingLeft);
			this.maxScrollLeft = (tabsWrapFullWidth > topAnchorFullWidth) ? tabsWrapFullWidth - topAnchorFullWidth + 2 * topAnchorLeftPadding : 0;
			this.handleScrollIndicatorsOnTabs();
			if (this.isFirstCalculate && !this.location.path(true).includes('#')) {
				this.scrollHorizontallyToTab(this.activeTab!);
				this.isFirstCalculate = false;
			}
		}
	}

	private resizeObserver : ResizeObserver | null = null;

	public ngAfterViewInit() : void {
		this.zone.runOutsideAngular(() => {
			this.validateValues();
			this.setRouterListenerToListenToOpenTab();
			this.setResizeObserver();
			this.setWrapperElementObserver();
		});
	}

	private setWrapperElementObserver() : void {

		let observer : IntersectionObserver | null = null;
		this.zone.runOutsideAngular(() => {
			if (!this.showIconOnlyBtns) {
				observer = new IntersectionObserver((entries, innerObserver) => {
					if (entries[0].isIntersecting) {
						window.setTimeout(() => {
							if (this.topAnchor && this.tabsWrap) {
								this.topAnchor.nativeElement.addEventListener('scroll',this.boundHandleShadowOnTabs);
								this.calculateMaxScrollLeft();
							}
							innerObserver.disconnect();
						}, 300);
					}
				});
			}
		});

		const observerOfTabElement = new IntersectionObserver((entries, innerObserver) =>{
			if (entries[0].isIntersecting) {
				/*
				This timeout is necessary to make sure that the styles that are decided by the theme and the
				Config are already set on the element, so the calculation of the maxScrollLeft is done correctly.
				 */
				this.zone.runOutsideAngular(() => {
					window.setTimeout(() => {
						if (this.tabsWrap && !this.showIconOnlyBtns) {
							observer!.observe(this.tabsWrap.nativeElement);
						}
						innerObserver.disconnect();
					}, 400);
				});
			}
		});

		observerOfTabElement.observe(this.elementRef.nativeElement);
	}

	private setResizeObserver() : void {
		this.resizeObserver = new ResizeObserver(() => {
			this.calculateMaxScrollLeft();
		});
		this.resizeObserver.observe(document.body);
	}

	private boundHandleShadowOnTabs = this.handleScrollIndicatorsOnTabs.bind(this);

	private subscription : Subscription | null = null;

	/**
	 * Change the active tab if the router changes.
	 *
	 * If the new active tab name would be undefined and in the same path,
	 * we find the first tab of the tabs and select it.
	 */
	private changeActiveTab(eventValue : RoutesRecognized, newActiveTabName : string) : void {
		const NEW_ACTIVE_TAB = this.tabs!.find(item => item.urlName === newActiveTabName);
		if (!NEW_ACTIVE_TAB) {
			if (newActiveTabName === 'undefined' &&
				this.location.path().includes(eventValue.url)) {
				if (!this.hasNotSideTheme && Config.IS_MOBILE && this.activeTab)
					this.activeTab.active = false;
			} else if (!this.tabs!.some(item => !!item.urlName)) {

				this.console.warn(`Route ${NEW_ACTIVE_TAB} not found in tabs of current tabs component.`);

				// If there are no urlNames, then this component is not meant to be controlled by url.
				// It is possible that there are two tab-components on the page. One with urlNames, one without ;)
			}
		} else {
			void this.selectTab(NEW_ACTIVE_TAB);
		}
	}

	private setRouterListenerToListenToOpenTab() : void {

		this.subscription = this.pRouterService.events.subscribe((value) => {

			const paramToLookFor = this.pTabParent ? 'subOpenTab' : 'openTab';

			if (this.draggedOnTabs) {
				this.draggedOnTabs = false;
				// eslint-disable-next-line ban/ban -- needed to stop the navigation if the user has dragged
				void this.pRouterService.navigate([]);
			}

			/*
				This is required as long as we don't change how the behaviour of the p-tabs handles changing of tabs
			*/
			if (value instanceof NavigationSkipped && this.pTabParent) {
				const TAB_TO_NAVIGATE = this.tabs?.find(item => item.urlName === this.activatedRoute.snapshot.
					paramMap.get(paramToLookFor));
				if (TAB_TO_NAVIGATE && TAB_TO_NAVIGATE !== this.activeTab) {
					void this.selectTab(TAB_TO_NAVIGATE);
				}
			}

			if (!(value instanceof RoutesRecognized)) return;

			let itemForOneIteration = value.state.root;
			while (!!itemForOneIteration.children.length && !itemForOneIteration.paramMap.has(paramToLookFor)) {
				itemForOneIteration = itemForOneIteration.children[0];
			}

			const NEW_ACTIVE_TAB_NAME = itemForOneIteration.paramMap.get(paramToLookFor);
			if (!NEW_ACTIVE_TAB_NAME || (NEW_ACTIVE_TAB_NAME === this.activeTab?.urlName)) {
				return;
			}

			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
			if ((this.activeTab && this.activeTab.urlName !== NEW_ACTIVE_TAB_NAME) ||
				(!this.activeTab && !this.hasNotSideTheme && this.Config.IS_MOBILE)) {
				this.changeActiveTab(value, NEW_ACTIVE_TAB_NAME);
			}
		});
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	private validateValues() : void {
		if (!this.showIconOnlyBtns) return;
		const TAB_WITHOUT_ICON = this.tabs!.find(item => !item.icon);
		if (TAB_WITHOUT_ICON) {
			throw new Error(`Icon on tab ${TAB_WITHOUT_ICON.label} needs to be set, when using [showIconOnlyBtns]="true"`);
		}
	}

	/**
	 *	Get the url to be used as the routerLink of each tab with an urlName.
	 *	We also want to avoid that the tabs are openable in a new tab when creating new items.
	 *	@returns the correct url for the tab or null if no urlName is present in the tab or we are creating a new item.
	 */
	public tabUrlForAnchorElement(tab : PTabComponent) : string | null {
		if (this.api.hasDataCopy() && !this.api.currentlyDetailedLoaded) return null;
		if (!tab.urlName) return null;
		if (this.activatedRoute.snapshot.params['subOpenTab']) {
			if (this.pTabsParent)
				return `../${tab.urlName}`;
			else return `../../${tab.urlName}`;
		} else return `../${tab.urlName}`;
	}

	/**
	 * Select a tab.
	 *
	 * Additionally you can pass an url fragment that should be added after the navigation
	 * to the corresponding tab
	 */
	public selectTab(tab : PTabComponent) : void {

		if (this.draggedOnTabs) return;

		// deactivate all tabs
		for (const item of this.tabs!) {
			if (item === tab) continue;
			if (item.active) {
				item.active = false;
				item.el.nativeElement.style.zIndex = '1';
			}
		}

		// activate the tab the user has clicked on.
		tab.active = true;
		this.onActiveTabChange.next(this.activeTab!);

		if (this.activeTab!.scrollableTab) {
			const innerScrollableElement = this.activeTab!.el.nativeElement.querySelector('.content');
			if (innerScrollableElement)
				innerScrollableElement.scrollTop = 0;
		} else {
			const scrollableParent = this.pScrollToSelectorService.nearestScrollableParent(this.activeTab!.el.nativeElement);
			if (scrollableParent && this.tabsBodyElementRef && this.tabsBodyElementRef.nativeElement.getBoundingClientRect().top < 0) {
				scrollableParent.scrollTop = 0;
			}
		}

		if (!this.showIconOnlyBtns) {
			this.scrollHorizontallyToTab(this.activeTab!);
		}

		if (this.size === this.enums.BootstrapSize.SM && !this.showIconOnlyBtns) {
			this.zone.runOutsideAngular(() => {
				window.setTimeout(() => {
					this.calculateMaxScrollLeft();
				}, 100);
			});
		}

		this.cleanUpUrl(tab);

	}

	/**
	 * If the tab doesn't have a pTabs component inside, we can remove the undefined part of the url,
	 * because undefined means some tabs are still loading.
	 *
	 * So, if the tab doesn't have a pTabs component inside, we check if the current tab urlName is already
	 * in the url and if so we can remove any remaining undefined, otherwise we don't remove it and
	 * wait for the respective pTabs to remove it.
	 *
	 * Additionally you can pass a boolean that tells this method the tab should be scrolled into view
	 * after the cleanup
	 */
	private cleanUpUrl(tab : PTabComponent, scrollAfterCleanUp : boolean = false) : void {
		this.zone.runOutsideAngular(() => {
			if (!tab.hasPTabsChild && tab.urlName) {
				const interval = window.setInterval(() => {
					if (this.location.path().includes(`/${tab.urlName}`)) {
						if (this.location.path().includes('/undefined')) {
							this.location.replaceState(this.location.path(true).replace('/undefined', ''));
						}

						window.clearInterval(interval);
					}

				}, 50);
			}
			if (this.location.path().includes('scrollToTabIfNotInView=true')) {
				this.location.replaceState(this.location.path(true).replace(/scrollToTabIfNotInView=true&?/, ''));
			}
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
			if (scrollAfterCleanUp && this.activatedRoute.snapshot.queryParams['scrollToTabIfNotInView'] && (tab.innerTabId || tab.urlName)) {
				if (!this.hasNotSideTheme) return;

				// Here we have to use scrollVertically and scrollHorizontally separately to avoid that page shifts on scroll.
				// This way we target only the scroll box of the scrollable parent of the element instead of the whole page.
				this.scrollVerticallyToTab(tab);
				this.scrollHorizontallyToTab(tab);
				let element : HTMLElement | null = null;
				const interval = window.setInterval(() => {
					// eslint-disable-next-line unicorn/prefer-query-selector -- needed because the id might start with a number
					element = document.getElementById((tab.innerTabId ?? tab.urlName)!);
					if (element) {
						window.clearInterval(interval);
						this.pScrollToSelectorService.animateElement(element);
					}
				});
			}
		});
	}

	/**
	 * Update the url without reloading the page by providing the name of the new tab
	 */
	private async updateLocation(urlName : string | null) : Promise<void> {
		const urlWithQueryParams = this.location.path();
		let newUrl : string = urlWithQueryParams.split('?')[0];

		// If a tab is already written to the url, replace it, else append it.
		const tabWrittenToTheUrl = this.tabs!.find((item) => newUrl.includes(item.urlName!));
		if (tabWrittenToTheUrl) {
			const tabUrlName = urlName ?? '';

			// Replace old tab name from url string with new tab name
			newUrl = newUrl.replace(tabWrittenToTheUrl.urlName!, tabUrlName);

			if (!newUrl.endsWith(tabUrlName)) {
				newUrl = `${newUrl.split(tabUrlName)[0]}${urlName}`;
			}

			// Without the use of router service, there will be this bug: PLANO-49677
			// eslint-disable-next-line ban/ban -- intended navigation
			await this.pRouterService.navigate([newUrl], {replaceUrl: true, queryParamsHandling: 'merge'});

		} else {
			const paramToLookFor = this.pTabParent ? 'subOpenTab' : 'openTab';

			// when the tabs have the side theme, the tab options are shown in a different page that shouldn't be replaced
			// in the history
			const shouldReplaceUrl = !(!this.hasNotSideTheme && Config.IS_MOBILE);
			if (!this.activatedRoute.snapshot.paramMap.has(paramToLookFor)) {
				// Write the tab name to the url
				// eslint-disable-next-line ban/ban -- intended navigation
				await this.pRouterService.navigate([`${newUrl}/${(urlName ?? '')}`], {replaceUrl: shouldReplaceUrl});

			} else {
				const tabName : string | null = this.activatedRoute.snapshot.paramMap.get(paramToLookFor);
				if (tabName === 'undefined' && urlName) {
					newUrl = newUrl.replace('undefined', urlName);
					// eslint-disable-next-line ban/ban -- intended navigation
					await this.pRouterService.navigate([newUrl], {replaceUrl: shouldReplaceUrl});
				} else {
					// eslint-disable-next-line ban/ban -- intended navigation
					await this.pRouterService.navigate([`${newUrl}/${(urlName ?? '')}`], {replaceUrl: shouldReplaceUrl});
				}
			}

		}
	}

	private async updateUrl(tab : PTabComponent | null) : Promise<void> {
		// FIXME: PLANO-7401
		if (tab && !tab.urlName) return;

		if (this.pTabParent && !this.pTabParent.active) return;

		await this.updateLocation(tab ? tab.urlName : null);

		if (!this.activeTab) {
			this.console.warn('Could not get active tab');
			return;
		}
		this.onChange.emit(this.activeTab);

		this.removeObsoleteTabs();
	}

	/**
	 * Returns the active (currently selected) tab.
	 */
	public get activeTab() : PTabComponent | null {
		// Can be null if e.g. someone navigated while a page with tabs was still loading.
		return (this.tabs?.find((item) => item.active)) ?? null;
	}

	/**
	 * Are we still looking for the active tab?
	 */
	public findingActiveTab : boolean = true;

	/**
	 * Find the first active tab, if any, and scroll to it
	 */
	private processFirstActiveTab() : void {
		this.initActiveTab();
		if (!this.showIconOnlyBtns) {
			this.handleScrollIndicatorsOnTabs();
		}
		this.findingActiveTab = false;
	}

	private processFirstActiveTabInterval : number | null = null;

	private deactivateOnParentTabsActiveTabChange : Subscription | null = null;

	private parentTabIntersectionObserver : IntersectionObserver | null = null;

	/**
	 * If there is a pTabParent we should wait until the parent has decided which tab should be active,
	 * otherwise we might have the case that the tab is selected for the child,
	 * then the parent decides rerenders/changes the tabs and then the activeTab is lost and not set again,
	 * causing an infinite load: https://drplano.atlassian.net/browse/PLANO-172902
	 */
	private processFirstTabAfterParent() : void {
		const intervalWaitForParentToDecide = window.setInterval(() => {
			if (!this.pTabsParent!.activeTab) return;

			this.processFirstActiveTab();
			this.parentTabIntersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && !this.activeTab) {
					this.processFirstActiveTab();
				}
			});
			this.parentTabIntersectionObserver.observe(this.elementRef.nativeElement);

			this.deactivateOnParentTabsActiveTabChange = this.pTabsParent!.onActiveTabChange.subscribe(() => {
				if (this.activeTab && this.pTabsParent?.activeTab !== this.pTabParent) {
					this.activeTab.active = false;
				}
			});

			window.clearInterval(intervalWaitForParentToDecide);
		}, 20);
	}

	public ngAfterContentInit() : void {

		this.zone.runOutsideAngular(() => {
			// TODO: Move this to a documented method
			if (!this.pTabParent) {
				this.processFirstActiveTabInterval = window.setInterval(() => {
					if (this.isLoading) return;
					if (this.processFirstActiveTabInterval) {
						window.clearInterval(this.processFirstActiveTabInterval);
						this.processFirstActiveTab();
					}
				}, 20);
			} else {
				this.processFirstTabAfterParent();
			}

			// TODO: Move this to a documented method
			if (!this.hasNotSideTheme && this.tabs) {
				for (const tab of this.tabs) {
					tab.showTabHeadline = true;
					if (tab.scrollableTab === null) {
						tab.scrollableTab = true;
					}
				}
			}
		});

	}

	/**
	 * Some tab must be selected. If not defined from outside, set one.
	 */
	public initActiveTab() : void {
		if (this.activeTab) return;

		/** Try to nav to the openTab from url. If that worked, the method returns true. */
		if (this.tryToNavToUrlOpenTab()) { return; }

		/** Try to nav to the openTab from url. If that worked, the method returns true. */
		if (this.tryToNavToInitialTab()) return;

		/** Try to nav to the first tab. If that worked, the method returns true. */
		// eslint-disable-next-line no-useless-return
		if (!(Config.IS_MOBILE && !this.hasNotSideTheme) && this.tryToNavToFirstTab()) return;
	}

	private removeObsoleteTabs() : void {
		for (const tab of this.tabs!.toArray()) {
			if (tab.active) return;
			if (tab.show !== false) return;
			tab.el.nativeElement.remove();
		}
	}

	private tryToNavToUrlOpenTab() : boolean {
		/**
		 * Param to look for, if there is a pTabs parent it means we need to look for the
		 * subOpenTab.
		 */
		const paramToLookFor = this.pTabParent ? 'subOpenTab' : 'openTab';
		if (!this.activatedRoute.snapshot.paramMap.has(paramToLookFor)) return false;

		const tabName : string | null = this.activatedRoute.snapshot.paramMap.get(paramToLookFor);
		if (tabName && !this.location.path().includes(tabName)) return false;
		const tab = this.tabs!.find((item) => item.urlName === tabName);
		if (!tab) return false;

		// Open the tab in ui
		tab.active = true;
		this.onActiveTabChange.next(this.activeTab!);
		this.cleanUpUrl(tab, true);
		this.onChange.emit(this.activeTab!);
		return true;
	}

	private tryToNavToInitialTab() : boolean {
		// If a initialActiveTab is defined then open it and change the url
		const tab = this.tabs!.find((item) => item.initialActiveTab);
		if (!tab) return false;

		// Open the tab in ui
		void this.selectTab(tab);
		void this.updateUrl(this.activeTab);
		this.onChange.emit(this.activeTab!);
		return true;
	}

	private tryToNavToFirstTab() : boolean {
		// If a initialActiveTab is defined then open it and change the url
		const tab = this.tabs!.first as PTabComponent | undefined;
		if (!tab) return false;

		// Open the tab in ui
		void this.selectTab(tab);
		void this.updateUrl(this.activeTab);
		this.onChange.emit(this.activeTab!);
		return true;
	}

	public ngOnDestroy() : void {
		if (!this.activeTab) {
			this.console.warn('Could not get active tab');
		} else {
			this.onChange.emit(this.activeTab);
		}
		this.parentTabIntersectionObserver?.disconnect();
		this.deactivateOnParentTabsActiveTabChange?.unsubscribe();
		this.resizeObserver?.disconnect();
		this.topAnchor?.nativeElement.removeEventListener('scroll', this.boundHandleShadowOnTabs);
		this.subscription?.unsubscribe();
		if (this.processFirstActiveTabInterval !== null) {
			window.clearInterval(this.processFirstActiveTabInterval);
			this.processFirstActiveTabInterval = null;
		}
	}

	/**
	 * Should the label be visible?
	 * Its e.g. invisible if its an inactive tab with flexible width.
	 */
	public showLabel(tab : PTabComponent) : boolean {
		if (!tab.label) return false;

		if (!this.showIconOnlyBtns) return true;
		const OTHER_ITEM_IS_HOVERED = this.tabs!.find(item => item !== tab && item.hover);
		if (OTHER_ITEM_IS_HOVERED) return false;
		if (tab.active) return true;
		if (tab.hover) return true;
		return false;
	}

	/**
	 * Should this item take as much space as it can get?
	 * Check storybook if you don’t get it.
	 */
	public growListItem(tab : PTabComponent) : boolean {
		if (!this.showIconOnlyBtns) return false;
		if (this.isLoading) return true;
		return this.showLabel(tab);
	}

	/**
	 * Should the filter-led be visible?
	 */
	public showFilterLed(tab : PTabComponent) : boolean {
		if (tab.hasFilter === null) return false;

		// if (this.showLabel(tab)) return true;
		return tab.hasFilter;
	}

	/**
	 * Has the user pressed on top of the tabs options
	 */
	public isDownOnWrapper : boolean = false;
	private startX : number = 0;
	private scrollLeft : number = 0;

	/**
	 * Handle the drag on the tabs by storing the initial touch point and
	 * the initial scrollLeft of the element, while also setting mouseIsDown to true
	 */
	public handleDownOnTabs(event : TouchEvent | MouseEvent, element : HTMLElement) : void {
		if (this.showIconOnlyBtns) return;
		const clickX = ('touches' in event) ? event.touches[0].pageX : event.pageX;
		this.startX = clickX - element.offsetLeft;
		this.scrollLeft = element.scrollLeft;
		this.isDownOnWrapper = true;
		this.draggedOnTabs = false;
	}

	public draggedOnTabs : boolean = false;

	private hasShadowRight : boolean = false;

	/**
	 * Handle the mouse/finger movement over the element while also dealing with the shadows
	 */
	public handleMoveOnTabs(event : TouchEvent | MouseEvent, element : HTMLElement) : void {
		if (this.showIconOnlyBtns) return;
		if (!this.isDownOnWrapper) return;
		if (!this.maxScrollLeft) return;
		event.preventDefault();
		const clickX = ('touches' in event) ? event.touches[0].pageX : event.pageX;
		const eventX = clickX - element.offsetLeft;
		const xDiff = (eventX - this.startX);
		if ((this.scrollLeft - xDiff) < this.maxScrollLeft) {
			element.scrollLeft = this.scrollLeft - xDiff;
			if (this.hasShadowRight && this.shadowRight) {
				this.shadowRight.nativeElement.style.opacity = '1';
				this.hasShadowRight = false;
			}
		} else {
			element.scrollLeft = this.maxScrollLeft;
			if (this.shadowRight) {
				this.shadowRight.nativeElement.style.opacity = '0';
				this.hasShadowRight = true;
			}
		}
		this.draggedOnTabs = true;
	}

	/**
	 * Handle the mouse wheel horizontal scrolling
	 */
	public handleHorizontalWheelScroll(event : WheelEvent, element : HTMLElement) : void {
		if (!event.shiftKey) return;
		if (this.showIconOnlyBtns) return;
		if (!this.maxScrollLeft) return;
		event.preventDefault();
		const xDiff = (event.deltaX > 0 ? 1 : -1) * 30;
		if ((element.scrollLeft + xDiff) < this.maxScrollLeft) {
			element.scrollLeft += xDiff;
		} else {
			element.scrollLeft = this.maxScrollLeft;
		}
	}

	/**
	 * Will return true if there is an active tab and false if none is yet selected.
	 * This way we know that if no active tab is selected, then only the different tab
	 * options should be shown and not their content.
	 *
	 * This is relevant when we use the side themed tabs on mobile.
	 */
	public get isSelectingTabFromSideThemedTabs() : boolean {
		return !this.activeTab;
	}

	/**
	 * Should the button at the top to change options or nav back be visible?
	 */
	public get showNavBackHeadlineBtn() : boolean {
		return Config.IS_MOBILE && !this.hasNotSideTheme && !this.isLoading;
	}

	private isElementOverflowingLeft(el : HTMLElement) : boolean {
		const rect = el.getBoundingClientRect();
		const tabsRect = this.topAnchor!.nativeElement.getBoundingClientRect();
		const faIconRectWidth = this.elementRef.nativeElement.querySelector('.shadows-chevrons-wrapper')?.querySelector('fa-icon')?.getBoundingClientRect().width ?? 0;

		return rect.left < tabsRect.left + faIconRectWidth;
	}

	private isElementOverflowingRight(el : HTMLElement) : boolean {
		const rect = el.getBoundingClientRect();
		const tabsRect = this.topAnchor!.nativeElement.getBoundingClientRect();
		const faIconRectWidth = this.elementRef.nativeElement.querySelector('.shadows-chevrons-wrapper')?.querySelector('fa-icon')?.getBoundingClientRect().width ?? 0;

		return rect.right > tabsRect.right - faIconRectWidth;
	}

	private scrollVerticallyToTab(tab : PTabComponent) : void {
		this.zone.runOutsideAngular(() => {
			const interval = window.setInterval(() => {
				if (this.topAnchor && this.tabsWrap) {
					const scrollableParent = this.pScrollToSelectorService.nearestScrollableParent(tab.el.nativeElement);
					if (scrollableParent) {
						const tabTop = tab.el.nativeElement.getBoundingClientRect().top;
						const scrollableParentTop = scrollableParent.getBoundingClientRect().top;
						const scrollableParentHeight = scrollableParent.getBoundingClientRect().height;
						if (tabTop < scrollableParentTop || tabTop > scrollableParentTop + scrollableParentHeight)
							scrollableParent.scrollTo({top: Math.max(0,tabTop - scrollableParentTop), behavior: 'smooth'});
					}
					window.clearInterval(interval);
				}
			}, 100);
		});
	}

	/**
	 * This method will scroll to a tab horizontally if it overflows the container
	 * either on the left or on the right, if no overflow is visible then no scroll is needed.
	 *
	 * @param tab The tab to scroll to
	 */
	private scrollHorizontallyToTab(tab : PTabComponent) : void {
		if (!this.topAnchor || !this.tabsWrap) return;
		if (!this.hasNotSideTheme) return;
		const scrollableTabsElement = this.topAnchor.nativeElement;
		const tabLiOptions = this.tabsWrap.nativeElement.querySelectorAll('li');
		const indexOfTab = this.visibleTabs.indexOf(tab);
		if (indexOfTab === 0) {
			scrollableTabsElement.scrollTo({left: 0, behavior: 'smooth'});
			return;
		} else if (indexOfTab === tabLiOptions.length - 1) {
			scrollableTabsElement.scrollTo({left: this.maxScrollLeft, behavior: 'smooth'});
			return;
		}
		const tabLiElement = tabLiOptions[indexOfTab];
		const innerTextSpanElement : HTMLElement | null = tabLiElement.querySelector('span');
		if (innerTextSpanElement) {
			if (this.isElementOverflowingLeft(innerTextSpanElement)) {
				const offsetElementLeft = tabLiElement.getBoundingClientRect().left;
				const scrollableElementLeft = scrollableTabsElement.getBoundingClientRect().left;
				const newScrollLeft = scrollableTabsElement.scrollLeft - (scrollableElementLeft - offsetElementLeft) - 20;
				scrollableTabsElement.scrollTo({left: newScrollLeft, behavior: 'smooth'});
			} else if (this.isElementOverflowingRight(innerTextSpanElement)) {
				const offsetElementRight = tabLiElement.getBoundingClientRect().right;
				const scrollableElementRight = scrollableTabsElement.getBoundingClientRect().right;
				const newScrollLeft = scrollableTabsElement.scrollLeft + (offsetElementRight - scrollableElementRight) + 20;
				scrollableTabsElement.scrollTo({left: newScrollLeft, behavior: 'smooth'});
			}
		}

	}

	/**
	 *	Handle click on next chevron
	 */
	public handleNextTabClicked() : void {
		const tabLiOptions = this.tabsWrap!.nativeElement.querySelectorAll('li');
		let i = 0;
		const scrollableTabsElement = this.topAnchor!.nativeElement;
		for (const tabLiOption of tabLiOptions) {
			const innerTextSpanElement : HTMLElement | null = tabLiOption.querySelector('span:not(.clickable)');
			if (innerTextSpanElement && this.isElementOverflowingRight(innerTextSpanElement)) {
				this.scrollHorizontallyToTab(this.visibleTabs[i]);
				return;
			}
			i++;
		}
		scrollableTabsElement.scrollTo({left: this.maxScrollLeft, behavior: 'smooth'});
	}

	/**
	 *	Handle click on prev chevron
	 */
	public handlePrevTabClicked() : void {
		let savedOverflowingLeftTab : HTMLElement | null = null;
		let savedOverflowingTabIndex : number | null = 0;
		let i = 0;
		const scrollableTabsElement = this.topAnchor!.nativeElement;
		const tabLiOptions = this.tabsWrap!.nativeElement.querySelectorAll('li');
		for (const tabLiOption of tabLiOptions) {
			const innerTextSpanElement : HTMLElement | null = tabLiOption.querySelector('span:not(.clickable)');
			if (innerTextSpanElement && this.isElementOverflowingLeft(innerTextSpanElement)) {
				savedOverflowingLeftTab = tabLiOption;
				savedOverflowingTabIndex = i;
			} else break;
			i++;
		}
		if (savedOverflowingLeftTab) {
			this.scrollHorizontallyToTab(this.visibleTabs[savedOverflowingTabIndex]);
		} else scrollableTabsElement.scrollTo({left: 0, behavior: 'smooth'});
	}
}
