import { NgModule } from '@angular/core';
import { CoreModule } from '@plano/shared/core/core.module';
import { AttributeInfoBaseComponentDirective } from './attribute-info-component-base';

@NgModule({
	imports: [
		CoreModule,
	],
	declarations: [
		AttributeInfoBaseComponentDirective,
	],
	providers: [
	],
	exports: [
		AttributeInfoBaseComponentDirective,
	],
})
export class PAttributeInfoModule {}
