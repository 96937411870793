import { Injectable } from '@angular/core';
import { Route, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { PDictionarySourceString } from './shared/core/pipe/localize.dictionary';
import { LocalizePipe } from './shared/core/pipe/localize.pipe';

/**
 * PRoute extends Route to force the use of translatable titles.
 *
 * In order to implement different paths make sure that the more specific paths are above the more general ones.
 *
 * For example:
 * 	{ path: 'shift/:id', title:'Schicht', component: ShiftComponent }
 *
 *	If you have this entry and you want to add a specific title to when the shift id is set to 0, meaning its a new one,
	you should change it to the following:

	{ path: 'shift/0', title:'Neue Schicht', component: ShiftComponent },
	{ path: 'shift/:id', title:'Schicht', component: ShiftComponent },
 *
	And this will make sure that when the id placeholder in the url is 0 the proper title gets used.
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- This is the only place where it is ok to use interface Route
export interface PRoute extends Route {

	/**
	 * The titles of each route can be set dynamically, to do so add it to the desired route where you would
	 * like the title to be displayed as a key-value pair where the key is title and the value is a string present in the
	 * dictionary. Like so:
	 *
	 *     { path: 'time-stamp', title:'Stempeluhr', component: TimeStampComponent }
	 *
	 *  P.S. 	Some titles are defined in the parent route (src\client\client-routing.module.ts), for example tutorial videos, but others require
	 * 				more specific routes inside the parent route, and in those cases, the titles should be set in the routes of the desired component routing module.
	 *       	A good example of this is the AbsenceModule, where we wanted to differentiate between the page for an existing
	 * 			absence and the page for creating a new one, so the titles should be set in src\client\booking\booking-routing.module.ts.
	 * 			In order to implement different paths make sure that the more specific paths are above the more general ones.
	 *  		For example:
	 *
	 * 				{ path: 'shift/:id', title:'Schicht', component: ShiftComponent }
	 *
	 * If you have this entry and you want to add a specific title to when the shift id is set to 0, meaning its a new one,you should change it to the following:
	 *
	 * 				{ path: 'shift/0', title:'Neue Schicht', component: ShiftComponent },
	 * 				{ path: 'shift/:id', title:'Schicht', component: ShiftComponent }
	 *
	 * And this will make sure that when the id placeholder in the url is 0 the proper title gets used.
	 */
	title ?: PDictionarySourceString
	children ?: PRoute[]
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PRoutes = PRoute[];

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class TemplatePageTitleStrategy extends TitleStrategy {

	constructor(private localize : LocalizePipe) {
		super();
	}

	public override updateTitle(routerState : RouterStateSnapshot) : void {
		const title = this.buildTitle(routerState);
		if (title !== undefined) {

			document.title = `${this.localize.transform(title as PDictionarySourceString)} | Dr. Plano` ;
		} else {

			/**
			 * this eslint-disable comment is used because the string needs to match the one present in the localize.dictionary.ts file,
			 * and for that reason must have the space character instead of &nbsp;
			 *  */

			// eslint-disable-next-line literal-blacklist/literal-blacklist
			document.title = this.localize.transform('Dr. Plano – Software für Schichtplanung & Kursbuchung');
		}
	}
}
