<p-input
	[ngModel]="valueToCopy"
	[type]="type"
	(focus)="onFocus($event)"
	[disabled]="isLoading === true"
	[readMode]="false"
	[readonly]="true"
>
	<div class="input-group-append">
		<p-button
			(triggerClick)="copyString(valueToCopy!)"
			[textStyle]="copyButtonTextStyle"
			pTooltip="In die Zwischenablage kopieren."
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

			[rounded]="BootstrapRounded.RIGHT"
			[disabled]="isLoading === true"
		><span class="nowrap d-flex flex-nowrap"><fa-icon [spin]="iconSpinning" [icon]="icon"></fa-icon><span class="nowrap d-none d-lg-inline ml-2" *ngIf="!!appendButtonText">{{appendButtonText}}</span></span></p-button>
	</div>
</p-input>
