import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TitleToAriaLabelDirective } from '@plano/shared/core/directive/title-to-aria-label.directive';

@Component({
	selector: 'p-shiftmodel-list-item[label]',
	templateUrl: './shiftmodel-list-item.component.html',
	styleUrls: ['./shiftmodel-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftmodelListItemComponent extends TitleToAriaLabelDirective implements AfterContentInit {
	@HostBinding('attr.role') private readonly role = 'listitem';
	@HostBinding('class.d-flex')
	@HostBinding('class.justify-content-between')
	@HostBinding('class.align-items-center')
	@HostBinding('class.position-relative') private _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('hasOnClickBinding') public _hasOnClickBinding : boolean | null = null;

	/**
	 * Is there a (onClick)="…" on this component?
	 */
	@HostBinding('class.list-group-item-action')
	public get hasOnClickBinding() : boolean {
		if (this._hasOnClickBinding !== null) return this._hasOnClickBinding;
		return this.onClick.observers.length > 0;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public label ! : string;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public color : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isPacket : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick : EventEmitter<undefined> = new EventEmitter<undefined>();

	public ngAfterContentInit() : void {
		this.initTitle();
	}

	/** Initialize the title if it is not set */
	private initTitle() : void {
		if (!this.title) this.title = this.label;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hexColor() : string | null {
		if (!this.color) return null;
		return `#${this.color}`;
	}
}
