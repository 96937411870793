<p-section>
	<div class="mt-3">
		<p-editable-modal-box
			[pEditable]="!shiftModel.isNewItem()"
			[api]="api"
			[valid]="!formGroup.get(shiftModel.attributeInfoBookingPersonMinAge.id)?.invalid && !formGroup.get(shiftModel.attributeInfoParticipantMinAge.id)?.invalid && !formGroup.get(shiftModel.attributeInfoParticipantMaxAge.id)?.invalid"
			(onDismiss)="initFormGroup.emit()"

			[disabled]="!userCanWrite || !shiftModel.attributeInfoBookingPersonMinAge.canSet && !shiftModel.attributeInfoParticipantMinAge.canSet && !shiftModel.attributeInfoParticipantMaxAge.canSet"
			label="Altersbeschränkung" i18n-label
		>
			<p-editable-modal-box-showroom>
				<p class="m-0" *ngIf="bookingPersonAgeLimits === null && participantAgeLimits === null; else someLimitsTemplate" i18n>Keine Altersbeschränkung vorhanden für buchende oder teilnehmende Personen.</p>
				<ng-template #someLimitsTemplate>
					<p class="m-0 mb-lg-0 mb-2">
						<ng-container [ngSwitch]="bookingPersonAgeLimits">
							<ng-container *ngSwitchCase="'minLimit'"><span i18n>Die <code>buchende Person</code> muss zum Buchungszeitpunkt <code>mindestens {{ shiftModel.bookingPersonMinAge }}</code> Jahre alt sein.</span><br/></ng-container>
						</ng-container>
					</p>
					<p class="m-0 mb-lg-0 mb-2">
						<ng-container [ngSwitch]="participantAgeLimits">
							<ng-container *ngSwitchCase="'minLimit'"><span i18n>Die <code>Teilnehmenden</code> müssen <code>mindestens {{ shiftModel.participantMinAge }}</code> Jahre alt sein (zum Datum des gebuchten Angebots).</span><br/></ng-container>
							<ng-container *ngSwitchCase="'maxLimit'"><span i18n>Die <code>Teilnehmenden</code> dürfen <code>höchstens {{ shiftModel.participantMaxAge }}</code> Jahre alt sein (zum Datum des gebuchten Angebots).</span><br/></ng-container>
							<ng-container *ngSwitchCase="'minAndMaxLimit'"><span>Die <code>Teilnehmenden</code> müssen <code>mindestens {{ shiftModel.participantMinAge }}</code> und dürfen <code>höchstens {{ shiftModel.participantMaxAge }}</code> Jahre alt sein (zum Datum des gebuchten Angebots).</span><br/></ng-container>
						</ng-container>
					</p>
				</ng-template>

			</p-editable-modal-box-showroom>
			<p-editable-modal-form>
				<p-section [whitespace]="SectionWhitespace.MEDIUM">
					<p i18n>Änderungen der Altersbeschränkung gelten sofort für alle neuen Buchungen von <code>{{shiftModel.name}}</code> – unabhängig davon, welchen Tarif deine Kunden bei der Online-Buchung wählen. Wenn du Buchungen manuell anlegst oder bearbeitest, kannst du von diesen Altersbeschränkungen abweichen.</p>
				</p-section>
				<p-section [whitespace]="SectionWhitespace.MEDIUM" label="Buchende Person" i18n-label pAnchorLink="limit-for-booking-person">
					<p i18n>Eine Altersvorgabe für buchende Personen bezieht sich immer auf den <strong>Zeitpunkt der jeweiligen Buchung</strong>. Lasse das Feld einfach leer, wenn du keine Beschränkung möchtest.</p>
					<div class="row">
						<div class="col-md-6">
							<p-ai-switch
								[checkTouched]="false"
								label="Mindestalter" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoBookingPersonMinAge"
							>
								<p-validation-hint
									text="Ein leeres Feld bedeutet keine Altersbeschränkung." i18n-text
									[isInvalid]="formGroup.get(shiftModel.attributeInfoBookingPersonMinAge.id)?.value === null"
									[theme]="enums.PThemeEnum.INFO"
									[checkTouched]="false"
								></p-validation-hint>
							</p-ai-switch>
						</div>
					</div>
				</p-section>
				<p-section [whitespace]="SectionWhitespace.MEDIUM" label="Teilnehmende" i18n-label pAnchorLink="limit-for-participants">
					<p i18n>Altersvorgaben für Teilnehmende beziehen sich immer auf das <strong>Datum des gebuchten Angebots</strong>. Bei Angeboten mit mehreren Terminen gilt das Datum des ersten Termins. Lasse die Felder einfach leer, wenn du keine Beschränkung möchtest.</p>
					<div class="row">
						<div class="col-md-6">
							<p-ai-switch
								[checkTouched]="false"
								label="Mindestalter" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoParticipantMinAge"
							>
								<p-validation-hint
									text="Ein leeres Feld bedeutet keine Altersbeschränkung." i18n-text
									[isInvalid]="formGroup.get(shiftModel.attributeInfoParticipantMinAge.id)?.value === null"
									[theme]="enums.PThemeEnum.INFO"
									[checkTouched]="false"
								></p-validation-hint>
							</p-ai-switch>
						</div>
						<div class="col-md-6">
							<p-ai-switch
								[checkTouched]="false"
								label="Höchstalter" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoParticipantMaxAge"
							>
								<p-validation-hint
									text="Ein leeres Feld bedeutet keine Altersbeschränkung." i18n-text
									[isInvalid]="formGroup.get(shiftModel.attributeInfoParticipantMaxAge.id)?.value === null"
									[theme]="enums.PThemeEnum.INFO"
									[checkTouched]="false"
								></p-validation-hint>
							</p-ai-switch>
						</div>
					</div>
				</p-section>
			</p-editable-modal-form>
		</p-editable-modal-box>
	</div>
</p-section>
