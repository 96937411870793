<p-report-row
	#reportRowRef
	[style]="reportRowRef.uncollapsed ?
		'position:sticky;top:'+(stickyTopOffset > 0 ? stickyTopOffset-10 : 0)+'px;z-index: 1021;' :
		''"
	*ngIf="isOwner"
	[mutedItem]="muteItem"
	[uncollapsed]="isOwner && !reportService.isCollapsed(member.id)"
	[class.border-bottom]="!isOwner"
	[headline]="true"
	[ruler]="false"
	[regularPauseDuration]="memberWorkingTimes.regularPauseDuration"
	[automaticPauseDuration]="memberWorkingTimes.automaticPauseDuration"
	[mergePauseDurations]="true"
	[duration]="totalDuration"
	[warningAmount]="memberWorkingTimes.warningAmount"
	[commentAmount]="commentAmount"
	(onClick)="onClick()"
	[disabled]="disabled"
>
	<span
		report-row-title
		class="crop-on-overflow flex-grow-1 d-flex align-items-center"
	><fa-icon [class.text-muted]="disabled" [icon]="collapsibleLineIcon"></fa-icon>&ngsp;<p-member-badge
		class="mx-2"
		size="normal"
		[memberId]="member.id"
		[firstName]="member.firstName"
		[lastName]="member.lastName"
		[absenceType]="member.trashed ? 'trashed' : null"
		[isMe]="!!rightsService.isMe(member.id)"
	></p-member-badge>&ngsp;<span class="crop-on-overflow"><strong class="crop-on-overflow">{{ member.firstName }}</strong> {{ member.lastName }}</span></span>
	<span *ngIf="hasForecasts" report-row-badges><p-forecast-badge class="ml-1"></p-forecast-badge></span>
	<strong class="nowrap" report-row-earnings>{{ totalEarnings | currency:CONFIG.CURRENCY_CODE:'symbol':'1.2-2' }}</strong>
</p-report-row>
<div
	*ngIf="(!reportService.isCollapsed(member.id) || !isOwner) && (memberWorkingTimes.length || memberAbsences.length)"
	[@slideVertical]
	[class.rounded-bottom]="!reportService.isCollapsed(member.id)"
>
	<ng-template [ngIf]="!!isOwner">
		<div class="member-working-times-details d-none d-sm-block">
			<label class="m-0"><strong i18n>Durchsch. Arbeitszeit</strong> ≅
				<ng-template #tooltipDaysPerWeek><ng-container i18n><strong>= Anzahl der Arbeitstage ÷ Anzahl der Kalenderwochen</strong><br/>Jeweils bezogen auf den oben gewählten Zeitraum. Abwesenheiten werden nicht berücksichtigt.</ng-container></ng-template>
				<span mr-2
					[pTooltip]="tooltipDaysPerWeek"
					[pTooltipPlacement]="NgxPopperjsPlacements.TOP"
					i18n
				>{{ member.avgDaysPerWeek | number:'1.0-2' }} Tage <small>pro</small> Kalenderwoche</span>
				||
				<ng-template #tooltipHoursPerDay><ng-container i18n><strong>= Gesamtarbeitsstunden ÷ Anzahl der Arbeitstage</strong><br/>Jeweils bezogen auf den oben gewählten Zeitraum. Abwesenheiten werden nicht berücksichtigt.</ng-container></ng-template>
				<span ml-2
					[pTooltip]="tooltipHoursPerDay"
					[pTooltipPlacement]="NgxPopperjsPlacements.TOP"
					i18n
				>{{ member.avgHoursPerDay | number:'1.0-2' }} Stunden <small>pro</small> Arbeitstag</span>
			</label>
		</div>
	</ng-template>
	<div class="mb-3">
		<ng-template [ngIf]="!!memberWorkingTimes.length">
			<div class="member-working-times-headline mt-3 mb-1 ml-3 mr-3">
				<div class="d-flex align-items-center justify-content-between">
					<h6 class="m-0 p-0" i18n>Arbeitseinsätze</h6>
				</div>
			</div>
			<ng-template
				ngFor let-workingTime
				[ngForOf]="workingTimesForMember"
			>
				<p-workingtime-list-item
					[workingTime]="workingTime"
					[min]="min!"
					[max]="max!"
				></p-workingtime-list-item>
			</ng-template>
		</ng-template>
		<ng-template [ngIf]="!!memberAbsences.length">
			<h6 class="member-working-times-headline mt-3 mb-1 ml-3 mr-3" i18n>Abwesenheiten</h6>
			<ng-template
				ngFor let-absence
				[ngForOf]="absencesForMember"
			>
				<p-absence-list-item
					[absence]="absence"
					[min]="min!"
					[max]="max!"
				></p-absence-list-item>
			</ng-template>
		</ng-template>
		<ng-template #noItemsTemplate>
			<div class="card-body text-center" i18n>Keine Einträge vorhanden.</div>
		</ng-template>
	</div>
</div>
