<p-collapsible
	[pAnchorLink]="anchorLink"
	[stickyOffset]="stickyOffset"
>
	<span trigger i18n>Wie funktionieren Marketing-Gutscheine?</span>
	<div content class="card-body">
		<p class="mb-5" i18n>Marketing-Gutscheine bieten Kunden einen zusätzlichen Anreiz, deine Angebote zu buchen und können helfen, deine Einnahmen zu steigern. Wenn ein Kunde gerade ein Angebot für z.B. Anfänger absolviert hat, ist es der beste Zeitpunkt, der Person einen Gutschein für das fortführende Aufbau-Angebot zu schicken. Denn zu diesem Zeitpunkt ist die Motivation für eine weitere Buchung am größten. Mit Dr.&nbsp;Plano hast du die Möglichkeit, Marketing-Gutscheine völlig automatisiert zu versenden, sobald ein Kunde die Teilnahme an einem gebuchten Angebot abgeschlossen hat. Wie das funktioniert, erfährst du hier.</p>

		<h5><fa-icon class="text-primary mr-3" [icon]="enums.PlanoFaIconPool.SUCCESS"/><ng-container i18n>Marketing-Gutscheine aktivieren</ng-container></h5>
		<p i18n>Du kannst für jede buchbare Tätigkeit den Versand von Marketing-Gutscheinen separat aktivieren. Dazu öffnest du die gewünschte Tätigkeit und navigierst dort zum Tab »Marketing-Gutscheine«. Hier kannst du nicht nur den Versand aktivieren, sondern auch nützliche Einstellungen vornehmen – z.B. den Gutscheinwert festlegen oder den Mindest-Buchungsbetrag definieren, den deine Kunden erreichen müssen, um den Gutschein einlösen zu können.</p>
		<p class="mb-5" i18n>Da du Marketing-Gutscheine pro buchbare Tätigkeit aktivieren & individuell einstellen kannst, hast du <strong>maximale Flexibilität</strong> und kannst nur jenen Kunden einen Anreiz für weitere Buchungen geben, die du als besonders interessant für deinen Betrieb einschätzt.</p>

		<h5><fa-icon class="text-primary mr-3" [icon]="enums.PlanoFaIconPool.EMAIL_NOTIFICATION"/><ng-container i18n>Versand der Gutscheine</ng-container></h5>
		<p i18n>Wenn du den Versand von Marketing-Gutscheinen aktiviert hast, erhalten deine Kunden kurz nach Ablauf ihres gebuchten Termins (spätestens am Tag darauf) eine <strong>automatische Email</strong> von Dr.&nbsp;Plano, die den Marketing-Gutschein beinhalten wird. Den Text der Email kannst du in der jeweiligen Tätigkeit etwas personalisieren. Die vollständige Anpassbarkeit der Email wird bald mit einem späteren Release möglich sein.</p>
		<p i18n>Der Marketing-Gutschein wird als PDF-Datei der automatischen Email angehängt. Für die Erstellung der PDF-Datei wird dieselbe <a routerLink="/client/plugin/gift-cards/settings" fragment="gift-card-template" target="_blank" rel="noopener">Design-Vorlage<fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB"/></a> verwendet, die du für deine normal zu erwerbenden Gutscheine hochgeladen hast. Falls du keine eigene Vorlage hochgeladen hast, wird die Standard-Vorlage von Dr.&nbsp;Plano verwendet.</p>
		<p class="mb-5"><fa-icon class="mr-1" [icon]="enums.PlanoFaIconPool.MORE_INFO"/><ng-container i18n>Kunden mit Buchungen, die zum Ablauf des gebuchten Termins <strong>storniert</strong> sind, erhalten keine Marketing-Gutscheine. Für den Versand der Gutscheine ist es nicht relevant, ob die <strong>Checkbox „Teilgenommen“</strong> zur Bestätigung der Teilnahme gesetzt ist oder nicht.</ng-container></p>
		<h5><fa-icon class="text-primary mr-3" icon="anchor"/><ng-container i18n>Verbindung mit der Buchung</ng-container></h5>
		<p class="mb-5" i18n>Versendete Marketing-Gutscheine werden in Dr.&nbsp;Plano mit Buchungen verbunden, für die sie verschickt worden sind. So kannst du jederzeit den Vorgang nachvollziehen, die verschickten Marketing-Gutscheine bearbeiten (z.B. stornieren) und bei Bedarf die Email mit dem Gutschein erneut versenden.</p>

		<h5><fa-icon class="text-primary mr-3" icon="hand-holding-box"/><ng-container i18n>Einlösung bei der Online-Buchung</ng-container></h5>
		<p class="mb-5" i18n>Deine Kunden können Marketing-Gutscheine nur bei der Online-Buchung (über Dr.&nbsp;Planos Homepage-Plugin) einlösen. Die Einlösung vor Ort z.B. über die Kassensoftware ist <strong>nicht</strong> möglich. Marketing-Gutscheine können prinzipiell für alle deine online buchbaren Angebote eingelöst werden und sind nicht auf bestimmte Angebote beschränkbar. Allerdings kannst du einen <strong>Mindest-Buchungswert</strong> definieren, den deine Kunden erreichen müssen, um den Gutschein einlösen zu können. So wird garantiert, dass Marketing-Gutscheine nur für Angebote eingelöst werden, die für dich gewinnbringend sind. Außerdem kann nur <strong>ein Marketing-Gutschein pro Zahlungsvorgang</strong> eingelöst werden. Technisch gesehen ist ein Marketing-Gutschein nicht an die Person gebunden, der sie ausgestellt wird.</p>

		<h5><fa-icon class="text-primary mr-3" [icon]="enums.PlanoFaIconPool.EXPIRED"/><ng-container i18n>Erinnerung an die Gültigkeitsdauer</ng-container></h5>
		<p i18n>Du kannst die Gültigkeitsdauer von Marketing-Gutscheinen in der jeweiligen Tätigkeit festlegen. Sie beträgt eine gewünschte Anzahl an Monaten ab der Gutschein-Ausstellung und ist auf dem Gutschein vermerkt. Kurz vor dem Ende der Gültigkeitsdauer wird dein Kunde mit einer kurzen automatischen Email daran erinnert, dass der Gutschein bald ablaufen wird. So hilft dir Dr.&nbsp;Plano dabei, die Effektivität deiner Marketing-Gutscheine zu steigern.</p>
		<p class="mb-5">
			<fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO" class="mr-1"/>
			<ng-container i18n>Der Inhalt der kurzen Erinnerungs-Email ist aktuell nicht anpassbar.</ng-container>
		</p>

		<h5><fa-icon class="text-primary mr-3" [icon]="enums.PlanoFaIconPool.STATISTICS_EXPORT"/><ng-container i18n>Marketing-Statistik</ng-container></h5>
		<p i18n>Damit Marketing nicht zum Glücksspiel wird, schickt dir Dr.&nbsp;Plano <strong>monatlich</strong> eine ausführliche Statistik darüber, wie viele Gutscheine verschickt & eingelöst wurden, wie viel Umsatz darüber generiert wurde und vieles mehr. So kannst du mühelos entscheiden, ob du deine Marketing-Gutscheine zukünftig einschränken oder ausweiten möchtest.</p>
		<p class="mb-5" i18n>Die Marketing-Statistik kannst du auch jederzeit selbst für deinen gewünschten Zeitraum herunterladen. Gehe dazu zur <a routerLink="/client/sales/gift-cards" target="_blank" rel="noopener" fragment="download-marketing-stats">Liste deiner Gutscheine<fa-icon [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB" class="ml-1"/></a></p>

		<h5><fa-icon class="text-primary mr-3" icon="scale-unbalanced-flip"/><ng-container i18n>Kosten vs. Nutzen – wann lohnen sich Marketing-Gutscheine?</ng-container></h5>
		<p i18n>Der Versand von Marketing-Gutscheinen ist verhältnismäßig preiswert, da er vollständig automatisiert und ohne Personaleinsatz/-kosten erfolgt. Du bezahlst lediglich die Gebühren für Marketing-Gutscheine an Dr.&nbsp;Plano: <mark class="font-weight-bold">{{pPricesService.giftCardPercent}}</mark>&nbsp;<strong>vom Startguthaben</strong> der ausgestellten Gutscheine. Damit sind Marketing-Gutscheine <strong>günstiger</strong> als die normal zu erwerbenden Gutscheine, da für sie die übliche fixe Gebühr von {{pPricesService.giftCardFixedAmount}} pro Gutschein nicht erhoben wird – zum Vergleich findest du oben die <a routerLink="." fragment="faq-gift-card-pricing">üblichen Gebühren<fa-icon [icon]="enums.PlanoFaIconPool.SCROLL_UP" class="ml-1"/></a></p>
		<p i18n>So können Marketing-Gutscheine bereits bei wenigen Einlösungen zusätzlichen Gewinn einbringen – wie das folgende Rechenbeispiel zeigt:</p>
		<p-alert
			[theme]="enums.PThemeEnum.LIGHT"
		>
			<div class="d-flex gap-2">
				<fa-icon [icon]="enums.PlanoFaIconPool.CALCULATOR" style="line-height: 1.5rem;"/>
				<div>
					<h5 style="line-height: 1.5rem;" i18n>Ein Rechenbeispiel</h5>
					<p i18n><strong>Gutschein-Menge:</strong> Nehmen wir an, du verschickst pro Quartal <code>300</code> Marketing-Gutscheine mit einem Guthaben von je <code>{{5 | currency:'EUR':undefined:'1.0'}}</code>. Als Mindest-Buchungswert setzen wir <code>{{50 | currency:'EUR':undefined:'1.0'}}</code>.</p>
					<p i18n><strong>Gebühr an Dr.&nbsp;Plano:</strong> {{pPricesService.giftCardPercent}} von {{1500 | currency:'EUR':undefined:'1.0'}} (300 Marketing-Gutscheine x {{5 | currency:'EUR':undefined:'1.0'}}) = <code>{{(1500 * pPricesService.giftCardPercentValue) | currency:'EUR':undefined:'1.2'}}</code></p>
					<p i18n><strong>Umsatz:</strong> Nehmen wir an, nur <code>5 %</code> der verschickten Gutscheine – also 15 von den 300 –  führen zu einer Buchung, die ohne den Gutschein nicht erfolgt wäre. Als Buchungsbetrag nehmen wir einfachheitshalber den Mindest-Buchungswert von <code>{{50 | currency:'EUR':undefined:'1.0'}}</code>, wobei <code>{{5 | currency:'EUR':undefined:'1.0'}}</code> per Gutschein bezahlt werden. Damit erhalten wir einen zusätzlich generierten Umsatz von: 15 x {{45 | currency:'EUR':undefined:'1.0'}} = <code>{{675 | currency:'EUR':undefined:'1.0'}}</code>.</p>
					<p class="mb-0" i18n><strong>Zusätzliche Einnahme dank Marketing-Gutscheinen:</strong> {{675 | currency:'EUR':undefined:'1.0'}} Umsatz - {{17.85 | currency:'EUR':undefined:'1.2'}} Dr.&nbsp;Plano-Gebühr = <code class="font-weight-bold">{{657.15 | currency:'EUR':undefined:'1.2'}}</code></p>
				</div>
			</div>
		</p-alert>
		<p i18n>🎉 Es ist also sehr wahrscheinlich, dass sich Marketing-Gutscheine rentieren. Probiere es einfach aus und werte das Ergebnis mithilfe der Marketing-Statistik aus, die du monatlich automatisch zugeschickt bekommst.</p>

	</div>
</p-collapsible>
