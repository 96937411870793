import { ChangeDetectionStrategy, Component, EmbeddedViewRef, HostBinding, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalContentComponent } from '@plano/shared/core/p-modal/p-modal-content/p-modal-content.component';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';

/** All the properties that can be set for e.g. open a confirm modal. */
export interface ModalContentOptions {
	description ?: string | TemplateRef<unknown> | null;
	modalTitle ?: string | TemplateRef<unknown> | null;

	/** @see {@link PModalContentComponent['closeBtnLabel']} */
	closeBtnLabel ?: PModalContentComponent['closeBtnLabel'];

	/** @see {@link PModalContentComponent['closeBtnIcon']} */
	closeBtnIcon ?: PModalContentComponent['closeBtnIcon'];

	/** @see {@link PModalContentComponent['closeBtnTheme']} */
	closeBtnTheme ?: PModalContentComponent['closeBtnTheme'];

	/**
	 * Is the close button disabled?
	 */
	closeBtnDisabled ?: () => boolean;

	dismissBtnLabel ?: string;
	dismissBtnIcon ?: PlanoFaIconPoolValues;

	/**
	 * The template that should be rendered as content of this component.
	 * @deprecated Use .description instead. Related: PLANO-178066
	 */
	contentTemplateRef ?: TemplateRef<unknown>;

	/**
	 * The template that should be rendered as footer of this component.
	 */
	footerTemplateRef ?: TemplateRef<unknown>;

	/**
	 * The context of .contentTemplateRef
	 * More Info: https://blog.angular-university.io/angular-ng-template-ng-container-ngtemplateoutlet/#templatecontext
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	contentTemplateContext ?: {[key : string] : any};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	footerTemplateContext ?: {[key : string] : any};

	/**
	 * Should the dismiss button be visible on the footer?
	 * If set to true it will still be possible to dismiss with the × button or a click outside the modal.
	 * @default true
	 */
	hideDismissBtn ?: boolean;

	/** PModalContentComponent#closeBtnTheme */
	icon ?: PModalContentComponent['icon'];
}

@Component({
	selector: 'p-modal-default-template',
	templateUrl: './modal-default-template.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PModalDefaultTemplateComponent {
	@HostBinding('class.flex-grow-1') protected _alwaysTrue = true;

	@ViewChild('content', { read: ViewContainerRef, static: true }) private contentViewContainerRef ! : ViewContainerRef;
	@ViewChild('footer', { read: ViewContainerRef, static: true }) private footerViewContainerRef ! : ViewContainerRef;

	constructor(
		private activeModal : NgbActiveModal,
		private localize : LocalizePipe,
	) {}

	public modalContentOptions : ModalContentOptions = {};
	public theme ?: ModalServiceOptions['theme'] = null;

	/**
	 * Initializes the Modal with the necessary properties from its parent component.
	 * Set necessary default values, if not provided by the given options.
	 */
	public initModal(
		options : ModalContentOptions,
		theme : ModalServiceOptions['theme'],
	) : void {
		if (!!options.description === !!options.contentTemplateRef) throw new Error('Please set either description or contentTemplateRef');

		this.modalContentOptions = options;
		if (
			this.modalContentOptions.hideDismissBtn === undefined
		) {
			this.modalContentOptions.hideDismissBtn = true;
		}
		if (!this.modalContentOptions.dismissBtnLabel) this.modalContentOptions.dismissBtnLabel = this.localize.transform('Nein');
		if (theme) this.theme = theme;
		if (!this.modalContentOptions.closeBtnLabel) this.modalContentOptions.closeBtnLabel = this.localize.transform('OK');

		const contentTemplateRef = options.contentTemplateRef ?? (options.description instanceof TemplateRef ? options.description : null);
		if (contentTemplateRef) {
			// eslint-disable-next-line no-extra-boolean-cast
			this.embeddedContentView = contentTemplateRef.createEmbeddedView(!!options.contentTemplateContext ? options.contentTemplateContext : {});
			this.contentViewContainerRef.insert(this.embeddedContentView);
		}

		if (options.footerTemplateRef) {
			// eslint-disable-next-line no-extra-boolean-cast
			this.embeddedFooterView = options.footerTemplateRef.createEmbeddedView(!!options.footerTemplateContext ? options.footerTemplateContext : {});
			this.footerViewContainerRef.insert(this.embeddedFooterView);
		}

		this.showCustomFooter = !!options.footerTemplateRef;
	}

	/** @deprecated HACK: quick fix for modals that have no footer,
	 *  since i implemented support for .footerViewContainerRef in modal-default-template */
	public showCustomFooter : boolean | null = null;

	/**
	 * A handle to control the embedded view inside this component.
	 *
	 * NOTE: 	Do not handle this.embeddedView ( e.g. .destroy() ) inside this component.
	 *  			Handle it where a modal has been opened.
	 */
	public embeddedContentView : EmbeddedViewRef<unknown> | undefined;

	public embeddedFooterView : EmbeddedViewRef<unknown> | undefined;

	/**
	 * Close this modal
	 */
	public onClose() : void {
		this.activeModal.close();
	}

	/**
	 * Dismiss this modal
	 */
	public onDismiss() : void {
		this.activeModal.dismiss();
	}

	/** Is close button disabled? */
	public get closeBtnDisabled() : boolean {
		return !!this.modalContentOptions.closeBtnDisabled?.();
	}
}
