import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { notNull } from '@plano/shared/core/utils/null-type-utils';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PClipboardService } from '@plano/shared/p-forms/p-clipboard.service';
import { AnchorLinkDirective } from './p-anchor.directive';

@Directive({
	selector: '[pAnchorLink][label]',
	exportAs: 'pAnchorLinkAtLabel',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AnchorLinkAtLabelDirective extends AnchorLinkDirective implements OnDestroy, AfterViewInit {

	/**
	 * Anchor Link can be used in elements that have a label. The anchor button will be added to the element which
	 * has the label as its content. The only requirement is that the element on which we want to use it has
	 * a defined label and one of the html children of that element has the label as its content.
	 */
	@Input() public label ! : string;

	/**
	 * anchorPos is set to center right by default when there is a label
	 */
	@Input() protected override anchorPos : 'center-right' | 'bottom-right' | 'top-right' = 'center-right';

	/**
	 * This boolean allows us to specify if the scroll should be targeted to whole section or just to the label.
	 * zB. When the section is too big. By default it is true.
	 */
	@Input() public labelAsTarget : boolean = true;

	constructor(
		protected override elementRef : ElementRef<HTMLElement>,
		console : LogService,
		localize : LocalizePipe,
		clipboard : PClipboardService,
		toasts : ToastsService,
		private zone : NgZone,
	) {
		super(elementRef, console, localize, clipboard, toasts);

	}

	private labelElement : Element | null = null;

	public override ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.zone.runOutsideAngular(() => {
			/* we need this request animation frame so we ensure that
			 the view has been rendered on the element with the label
			 for example, sections with the isDescriptiveVisualGrid property
			 only display their elements after checking the position of both the description
			 and the visual element, and so if this runs before the id's get lost when the section
			 rerenders */
			window.requestAnimationFrame(() => {
				super.ngAfterViewInit();
			});
		});
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	private removeCommentTagsFromHTML(text : string) : string {
		return text.replace('<!---->','');
	}

	private replaceHtmlEntities(text : string) : string {
		const translateRegex = /&(nbsp|amp|quot|lt|gt);/g;
		text = this.removeCommentTagsFromHTML(text);
		return ( text.replace(translateRegex, (_match, entity) => {
			switch (entity) {
				case 'nbsp': return ' ';
				case 'amp': return '&';
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				case 'quot': return '"';
				case 'lt': return '<';
				case 'gt': return '>';
				default: return entity;
			}
		}) );
	}

	private getChildElementWithLabel(htmlElement : Element) : Element | null {
		for (const childElement of htmlElement.children) {
			if (!childElement.innerHTML.startsWith('<') && this.replaceHtmlEntities(childElement.innerHTML) === this.replaceHtmlEntities(this.label)) {
				return childElement;
			} else {
				const childResult = this.getChildElementWithLabel(childElement);

				if (childResult)
					return childResult;
			}
		}

		return null;
	}

	private spanInsideElement ! : HTMLSpanElement;

	protected override addIdToTargetElement(newId : string) : void {

		const htmlElement = this.elementRef.nativeElement;
		this.labelElement = notNull(this.getChildElementWithLabel(htmlElement));

		if (this.labelAsTarget) {
			this.labelElement.id = newId;
		} else super.addIdToTargetElement(newId);
	}

	protected override addEventListeners() : void {
		// Add the event listeners to the span element
		this.spanInsideElement.addEventListener('mouseenter', this.showAnchorLink.bind(this));
		this.spanInsideElement.addEventListener('mouseleave', this.hideAnchorLink.bind(this));
	}

	protected override appendAnchorLinkButton() : void {

		const labelText = this.labelElement!.textContent;

		// Create the span element that will hold the anchor link
		this.spanInsideElement = document.createElement('span');
		this.spanInsideElement.classList.add('d-inline-block', 'position-relative');
		this.spanInsideElement.innerHTML = labelText!;
		this.spanInsideElement.appendChild(this.anchorLinkButton);

		// Remove the existing text since it will be added by the span
		this.labelElement!.textContent = '';

		this.labelElement!.appendChild(this.spanInsideElement);
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (this.labelElement && !this.hidePAnchorLinkButton)
			this.labelElement.removeChild(this.spanInsideElement);

		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}
}
