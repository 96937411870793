import { SchedulingApiGiftCardSettingsBase, SchedulingApiGiftCardSettingsPredefinedPriceBase } from '@plano/shared/api';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiGiftCardSettings extends SchedulingApiGiftCardSettingsBase {

	/**
	 * Throws a validation error when free price choice and predefined prices are both not set.
	 */
	public checkFreePriceChoiceOrPredefinedPricesIsAvailable() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.FREE_PRICE_CHOICE_OR_PREDEFINED_PRICES_NOT_SET, fn: (_control) => {
			if (this.freePriceChoice || this.predefinedPrices.length > 0)
				return null;

			return { [PPossibleErrorNames.FIRST_FEE_PERIOD_START_IS_NULL]: {
				name: PPossibleErrorNames.FIRST_FEE_PERIOD_START_IS_NULL,
				primitiveType: null,
			}};
		}});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiGiftCardSettingsPredefinedPrice extends SchedulingApiGiftCardSettingsPredefinedPriceBase {

	/**
	 * Throws a validation error when the `price` value is already used.
	 */
	public checkNoDuplicatePredefinedPrices() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.DUPLICATE_PREDEFINED_PRICES, fn: (_control) => {
			const price = this.price;
			if (this.parent) {
				for (const predefinedPrice of this.parent.iterable()) {
					if (predefinedPrice === this)
						continue;

					if (predefinedPrice.price === price) {
						// We found a duplicate price
						return { [PPossibleErrorNames.DUPLICATE_PREDEFINED_PRICES]: {
							name: PPossibleErrorNames.DUPLICATE_PREDEFINED_PRICES,
							primitiveType: null,
						}};
					}
				}
			}

			return null;
		}});
	}
}
