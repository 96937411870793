import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { SLIDE_HORIZONTAL_ON_NGIF_TRIGGER } from '@plano/animations';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { ShiftItemViewStyles } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-shift-item-module/shift-item/shift-item-styles';
import { IShiftItemComponent, ShiftItemComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-shift-item-module/shift-item/shift-item.component';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { NgbFormatsService } from '@plano/client/service/ngbformats.service';
import { BootstrapRounded } from '@plano/client/shared/bootstrap-styles.enum';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { AssignmentProcessesService } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-processes.service';
import { ApiListWrapper, MeService, RightsService, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchanges, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'p-shift-item-list[shift][showCourseInfo][processStatusIconTemplate][memberBadgesTemplate][quickAssignmentTemplate][shiftExchangeIconsTemplate][linkedCourseInfoTemplate]',
	templateUrl: './shift-item-list.component.html',
	styleUrls: ['./shift-item-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [ SLIDE_HORIZONTAL_ON_NGIF_TRIGGER ],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ShiftItemListComponent implements PComponentInterface, OnDestroy, IShiftItemComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('isLoading') public _isLoading : PComponentInterface['isLoading'] = false;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public processStatusIconTemplate ! : TemplateRef<unknown>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public memberBadgesTemplate ! : TemplateRef<unknown>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public quickAssignmentTemplate ! : TemplateRef<unknown>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftExchangeIconsTemplate ! : TemplateRef<unknown>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public linkedCourseInfoTemplate ! : TemplateRef<unknown>;

	@ViewChild('shiftItemTooltip', {static: true}) private shiftItemTooltip ! : TemplateRef<unknown>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public muteItem : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftIsSelectable : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public selectable : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public selectedChange : EventEmitter<boolean> = new EventEmitter<boolean>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public process : SchedulingApiAssignmentProcess | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public meIsAssignable : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showAssignMeButton : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showMultiSelectCheckbox : ShiftItemComponent['showMultiSelectCheckbox'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showProcessStatusIcon : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showCourseInfo ! : boolean;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ! : SchedulingApiShift;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public assignedMembers : ApiListWrapper<SchedulingApiMember> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public viewStyle : ShiftItemViewStyles = ShiftItemViewStyles.SMALL;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick = new EventEmitter<{shift : SchedulingApiShift, event : MouseEvent}>();

	/**
	 * Remove shift
	 */
	@Output() public onClickRemove = new EventEmitter<{
		shift : SchedulingApiShift,
		event : MouseEvent,
	}>();

	/**
	 * Event that emits the shifts that were previously selected, before this shift
	 * gets selected
	 */
	@Output() public onDismissShiftSelected = new EventEmitter<SchedulingApiShifts>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isInThePast : boolean = false;

	constructor(
		public api : SchedulingApiService,
		public meService : MeService,
		public ngbFormats : NgbFormatsService,
		public highlightService : HighlightService,
		private assignmentProcessesService : AssignmentProcessesService,
		public rightsService : RightsService,
		public schedulingService : SchedulingService,
		private modalService : ModalService,
		private pWishesService : PWishesService,
	) {
		// update tooltip visibility
		this.subscription = this.highlightService.onChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe( () => {
			const highlighted = this.highlightService.isHighlighted(this.shift);
			if (highlighted !== this.showTooltip) this.showTooltip = highlighted;
			if (Config.IS_MOBILE && highlighted) {
				const modalRef = this.modalService.openModal(this.shiftItemTooltip, {
					centered:true,
					windowClass:'transparent-background',
				});
				void modalRef.result.then(value => {
					if (value.modalResult === 'dismiss') {
						this.modalService.removeBlockHighlightModalCount();
						this.highlightService.setHighlighted(null);
					}
				});
				const modalWindow : HTMLElement|null = document.querySelector('ngb-modal-window');
				modalWindow?.focus();
				this.modalService.addBlockHighlightModalCount();
			}
		});
	}

	public readonly CONFIG = Config;

	/**
	 * Should the tooltip be shown?
	 */
	public showTooltip : boolean = false;

	public states : typeof SchedulingApiAssignmentProcessState = SchedulingApiAssignmentProcessState;
	public ShiftItemViewStyles = ShiftItemViewStyles;

	private ngUnsubscribe : Subject<void> = new Subject<void>();

	public NgxPopperjsTriggers = NgxPopperjsTriggers;
	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;
	public BootstrapRounded = BootstrapRounded;

	private subscription : Subscription | null = null;

	/** @see PComponentInterface#isLoading */
	public get isLoading() : PComponentInterface['isLoading'] {
		return this._isLoading;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get name() : string | null {
		if (this.isLoading) return null;
		return this.shift.model.attributeInfoName.value;
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get start() : number | null {
		if (this.isLoading) return null;
		return this.shift.start;
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get end() : number | null {
		if (this.isLoading) return null;
		return this.shift.end;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasIllnessShiftExchanges() : boolean | undefined {
		if (this.isLoading) return undefined;
		return !!this.illnessShiftExchanges.length;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get illnessShiftExchanges() : SchedulingApiShiftExchanges {
		if (this.isLoading) return new SchedulingApiShiftExchanges(null, null);
		return this.shiftExchanges.filterBy(item => {
			if (!item.isIllness) return false;

			// Only get the items where the illness is confirmed.
			if (this.shift.attributeInfoAssignedMemberIds.isAvailable && this.shift.assignedMemberIds.contains(item.indisposedMemberId)) return false;

			return true;
		});
	}

	private get shiftExchanges() : SchedulingApiShiftExchanges {
		if (this.isLoading) return new SchedulingApiShiftExchanges(null, null);
		return this.api.data.shiftExchanges.filterBy(item => item.shiftRefs.contains(this.shift.id));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onClickEditTooltip() : void {
		if (this.modalService.modalRef) this.modalService.modalRef.dismiss();
	}

	/**
	 * Dismiss the current modal if any and propagate the event
	 *
	 * @param event Event containing the shift and mouse event
	 * @param event.shift The shift associated with the event
	 * @param event.event The mouse event that triggered the event
	 */
	public onClickRemoveTooltip( event : {
		shift : SchedulingApiShift;
		event : MouseEvent;
	} ) : void {
		if (this.modalService.modalRef) this.modalService.modalRef.dismiss();
		this.onClickRemove.emit(event);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onCloseShiftTooltip(event : MouseEvent) : void {
		if (!Config.IS_MOBILE) {
			this.showTooltip = false;
			if (
				// this.viewStyle !== shiftItemViewStyles.button &&
				this.viewStyle !== ShiftItemViewStyles.MEDIUM
			) {
				if (this.highlightService.isHighlighted(this.shift)) {
					this.highlightService.setHighlighted(null);
					this.pWishesService.item = null;
				} else {
					this.highlightService.setHighlighted(this.shift);
					this.pWishesService.item = this.shift;
				}
			}
		} else {
			// it can happen that users click the background of the modal
			// multiple times quickly, causing them to occasionally hit the dismiss
			// button when the tooltip is already getting closed, so we can't be sure
			// that there is a modalRef still
			if (this.modalService.modalRef) {

				this.modalService.modalRef.dismiss();
				this.highlightService.setHighlighted(null);
				this.pWishesService.item = null;
			}
		}

		// a click on the calendar removes the highlightedShift.
		event.stopPropagation();

		this.onClick.emit({
			shift: this.shift,
			event: event,
		});

	}

	public ngOnDestroy() : void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.subscription?.unsubscribe();
	}

	// TODO: needs to be excluded in a own service?
	/**
	 * Get a title of the related process
	 */
	public processTitleForState(process : SchedulingApiAssignmentProcess) : PDictionarySourceString | null {
		if (this.isLoading) return null;
		const state = process.state !== SchedulingApiAssignmentProcessState.NEEDING_APPROVAL ? process.state : SchedulingApiAssignmentProcessState.APPROVE;
		return this.assignmentProcessesService.getDescription(state, this.rightsService.userCanSetAssignmentProcess(process)!);
	}

	/**
	 * Check if user can edit this shift
	 */
	public get userCanWrite() : boolean | null | undefined {
		if (this.isLoading) return undefined;
		return this.rightsService.userCanWrite(this.shift);
	}

	/**
	 * Check if user can read this shift
	 */
	public get userCanRead() : boolean | undefined {
		if (this.isLoading) return undefined;
		return this.rightsService.userCanRead(this.shift.model);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get memberIsHighlighted() : boolean {
		if (!this.highlightService.highlightedItem) return false;
		if (!(this.highlightService.highlightedItem instanceof SchedulingApiMember)) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showWishesIconForMember() : boolean | undefined {
		if (this.isLoading) return undefined;
		if (!this.meService.isLoaded()) return false;
		if (!this.memberIsHighlighted) return false;
		if (
			!this.meService.data.isOwner &&

			// me has no right to write this shift
			!this.rightsService.userCanWrite(this.shift) &&

			// me is not highlighted
			!this.meService.data.id.equals(this.highlightService.highlightedItem!.id)
		) return false;
		if (!this.highlightService.showWishIcon(this.shift)) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get multiSelectIsPossible() : ShiftItemListComponent['showMultiSelectCheckbox'] {
		// Shift-Related rules
		if (!this.selectable) return false;

		// Environment-Related rules
		return !!this.showMultiSelectCheckbox;
	}
}
