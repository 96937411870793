<form [formGroup]="$any(formGroup)"
	*ngIf="!!formItem && !!formGroup && api.isLoaded(); else spinnerTemplate"
>
	<ng-container *ngIf="!formItem.isNewItem() && !api.hasDataCopy()"><ng-container *ngTemplateOutlet="formValidationError"></ng-container></ng-container>

	<p-tabs
		[isLoading]="!api.isLoaded()"
	>
		<p-tab
			[label]="basisSettingsTabLabel | localize"
			urlName="basissettings"
			[hasDanger]="!shift && shiftModelBasisSettingsIsInvalid"
			[size]="PTabSizeEnum.FRAMELESS"
		>
			<ng-template [ngIf]="shiftModel">
				<p-section>
					<ng-template [ngIf]="modeIsEditShiftModel(formItem) || service.modeIsCreateShiftModel">
						<p-grid>
							<div class="col-sm-6">
								<p-ai-switch
									[pEditable]="!shiftModel.isNewItem()"
									[api]="api"

									label="Tätigkeitsbereich" i18n-label
									placeholder="Theke" i18n-placeholder

									[group]="formGroup"
									[attributeInfo]="shiftModel.attributeInfoParentName"

									(onDismiss)="initFormGroup()"
									(onSaveSuccess)="matchVisibilityOfGroup()"

									[typeahead]="typeAheadShiftModelParentContent"
									(focusout)="trimParentName()"
								></p-ai-switch>
							</div>
							<div class="col-sm-6">
								<p-ai-switch
									label="Name der Tätigkeit" i18n-label
									[group]="formGroup"
									[attributeInfo]="shiftModel.attributeInfoName"

									[pEditable]="!shiftModel.isNewItem()"
									[api]="api"

									placeholder="Empfang" i18n-placeholder
								></p-ai-switch>
							</div>
							<div class="col-12" *ngIf="isOwner">
								<p-bootstrap-form-group
									label="Farbe" i18n-label
									[control]="formGroup.controls['color']"
								>
									<div hidden><span i18n #translatedColorModalBoxLabel>{!formGroup.controls['color'].value, select, true {Bitte farbe wählen}}</span></div>
									<p-editable-modal-box
										[pEditable]="!formItem.isNewItem()"
										[api]="api"
										[headless]="true"
										[valid]="!formGroup.controls['color'].invalid"
										(onDismiss)="initFormGroup()"
										[disabled]="formGroup.controls['color'].disabled"
										[label]="translatedColorModalBoxLabel.textContent"
									>
										<p-editable-modal-box-showroom
											size="frameless"
											[wrapWithCardBody]="false">
											<div
												class="w-100"
												*ngIf="formGroup.controls['color'].value"
												style.background-color="#{{formGroup.controls['color'].value}}"
												style="height: 3em;"
											></div>
										</p-editable-modal-box-showroom>
										<p-editable-modal-form>
											<p-grid>
												<div class="col-sm-6" role="grid" *ngFor="let colorKey of shiftModelColorShadeKeys">
													<div class="row no-gutters">
															<button
																*ngFor="let color of shiftModelColorShades[colorKey]"
																role="gridcell"
																pAutoFocus
																[hasAutoFocusPriority]="isSelectedColor(color)"
																type="button"
																class="d-block clickable w-100 btn btn-secondary rounded-0 border-0 col color-box"
																[class.active]="isSelectedColor(color)"
																[style.padding]="'0.5rem'"
																(click)="formGroup.controls['color'].setValue(color)"
																style="transition: opacity 0.5s;"
															>
																<div
																	class="color-box-inner rounded"
																	style.background-color="#{{color}}"
																>
																</div>
															</button>

														</div>
												</div>
											</p-grid>
										</p-editable-modal-form>
									</p-editable-modal-box>
									<p-validation-hint
										[control]="formGroup.controls['color']"
									></p-validation-hint>
								</p-bootstrap-form-group>
							</div>
						</p-grid>
					</ng-template>
					<p-grid>
							<div class="col-md-6" *ngIf="userCanReadShiftModel">
								<ng-template [ngIf]="service.modeIsCreateShift && !!shift">
									<p-ai-switch
									label="Startdatum"
									[group]="formGroup"
									[showTimeInDateInput]="false"
									[attributeInfo]="shift.attributeInfoStart">
									</p-ai-switch>
								</ng-template>

								<ng-template [ngIf]="modeIsEditShiftModel(formItem) || service.modeIsCreateShiftModel || modeIsEditShift(formItem)">
									<p-bootstrap-form-group
										label="Startdatum" i18n-label [control]="formGroup.controls['startDate']"
										[description]="(!service.modeIsCreateShift && userCanWrite) ? ('Das Datum kann nur beim Erstellen einer Schicht vergeben und nachträglich nicht mehr verändert werden.' | localize) : null"
									>
										<p-input-date
											[ngModel]="shift ? shift.start : undefined"
											[readMode]="true"
											[ngModelOptions]="{standalone: true}"
										></p-input-date>
									</p-bootstrap-form-group>
								</ng-template>
							</div>
							<div class="col-md-6" *ngIf="userCanReadShiftModel">
								<ng-template
									[ngIf]="formGroup.controls['isCourse'].value"
									[ngIfElse]="neededMembersCountTemplate"
								>
								<p-bootstrap-form-group
									[control]="formGroup.controls['neededMembersCountConf']"
									*ngVar="formGroup.controls['neededMembersCountConf'].controls['reference'].value as conf"
									label="Benötigte Mitarbeitende" i18n-label
								>
									<p-editable-modal-box
										[pEditable]="!formItem.isNewItem()"
										[api]="api"
										[valid]="!formGroup.controls['neededMembersCountConf'].invalid"
										[saveChangesHook]="getChangeSelectorModalAsHook(modalContent.template)"
										(onDismiss)="initFormGroup()"
										[headless]="true"

										[disabled]="!conf?.attributeInfoNeededMembersCount?.canSet"
										[label]="translatedNeededMembersCountModalBoxLabel"
									>
										<p-editable-modal-box-showroom
											[wrapWithCardBody]="showNeededMembersCountConfShowroom">
											<div [class.without-card-body]="!showNeededMembersCountConfShowroom">{{translatedNeededMembersCountModalBoxLabel}}</div>
											<ng-container *ngIf="showNeededMembersCountConfShowroom">
												<ng-template
													[ngIf]="neededMembersCountConfNotReached"
													[ngIfElse]="otherTemplate"
												>
													<p class="m-0" i18n><fa-icon [icon]="enums.PlanoFaIconPool.WARNING" class="mr-1"></fa-icon>Aktuell <code>0</code> {shift!.neededMembersCount, plural, zero {Mitarbeitende} one {Mitarbeitende} two {Mitarbeitende} other {Mitarbeitende} }, da die Mindestteilnehmendenzahl nicht erreicht ist.</p>
												</ng-template>
												<ng-template #otherTemplate>
													<div *ngIf="shift!.neededMembersCountConf.perXParticipants !== null"><p class="m-0" i18n="Plural caused problems here. So we need to translate Trainer to e.g. Instructor(s)">Aktuell <code>{{ shift!.neededMembersCount }}</code> Mitarbeitende für <code>{!!shift!.currentCourseParticipantCount, select, true {{{shift!.currentCourseParticipantCount}}} other {0}}</code> gebuchte Teilnehmende.</p></div>
												</ng-template>
											</ng-container>
										</p-editable-modal-box-showroom>
										<p-editable-modal-form>
											<p-section
												label="Fixe oder variable Anzahl" i18n-label
												pAnchorLink="no-of-employees"
											>
												<ng-container *ngIf="formItem.isCourse">
													<p i18n>Bei buchbaren Angeboten kannst du eine <strong>variable Mitarbeitendenzahl</strong> einstellen: Du gibst an, wie viele Mitarbeitende für je wie viele Teilnehmende benötigt werden und Dr.&nbsp;Plano errechnet für alle Termine / Schichten die konkret benötigte Anzahl. So sparst du Zeit und vermeidest eine mögliche Fehlerquelle im Alltag <fa-icon [icon]="enums.PlanoFaIconPool.RELAXED" class="text-primary"></fa-icon></p>

													<p i18n>Natürlich kannst du auch eine <strong>feste Mitarbeitendenzahl</strong> eintragen, die für beliebig viele Teilnehmende gleich bleibt.</p>
												</ng-container>
												<p-grid class="flex-column flex-md-row">
													<div class="col-md-6">
														<p-ai-switch
															label="Benötigte Mitarbeitende" i18n-label
															[pEditable]="false"
															[group]="formGroup.controls['neededMembersCountConf']"
															[attributeInfo]="$any(conf?.attributeInfoNeededMembersCount)"
														></p-ai-switch>
													</div>
													<div class="col-md-6">
														<p-ai-switch
															label="Für wie viele Teilnehmende?" i18n-label
															[group]="formGroup.controls['neededMembersCountConf']"
															[attributeInfo]="$any(conf?.attributeInfoPerXParticipants)"
															[options]="[{
																text : 'Für je X Teilnehmende',
																value : AI_SWITCH_OPTION_REQUIRED
															}, {
																text : 'Für beliebig viele Teilnehmende',
																value : null
															}]"
														></p-ai-switch>
													</div>
												</p-grid>
											</p-section>
											<p-section
												label="Bei zu wenigen Teilnehmenden" i18n-label
												pAnchorLink="insufficient-participants"
											>
												<p-ai-switch
													[group]="formGroup.controls['neededMembersCountConf']"
													[attributeInfo]="$any(formItem.neededMembersCountConf.attributeInfoIsZeroNotReachedMinParticipantsCount)"
													valueText="Anzahl der Mitarbeitenden auf 0 setzen, solange die Mindestteilnehmendenzahl des Angebots nicht erreicht ist." i18n-valueText
												></p-ai-switch>
											</p-section>
										</p-editable-modal-form>
									</p-editable-modal-box>
								</p-bootstrap-form-group>
								</ng-template>
								<ng-template #neededMembersCountTemplate>
									<p-ai-switch
										*ngVar="formGroup.controls['neededMembersCountConf'].controls['reference'].value as conf"
										[pEditable]="!formItem.isNewItem()"
										[api]="api"

										[saveChangesHook]="getChangeSelectorModalAsHook(modalContent.template)"
										(onDismiss)="initFormGroup()"

										label="Benötigte Mitarbeitende" i18n-label
										placeholder="?"

										[group]="formGroup"
										[attributeInfo]="$any(conf?.attributeInfoNeededMembersCount)"
									></p-ai-switch>
								</ng-template>
							</div>
					</p-grid>
					<p-ai-switch
						label="Kommentar / ToDo für’s Personal" i18n-label
						[group]="$any(formGroup)"
						[attributeInfo]="$any(formItem.attributeInfoDescription)"

						[type]="AISwitchUIType.TEXTAREA"

						[pEditable]="!formItem.isNewItem()"
						[api]="api"
						(onDismiss)="initFormGroup()"
						[saveChangesHook]="getChangeSelectorModalAsHook(modalContent.template)"
					></p-ai-switch>

				</p-section>
			</ng-template>
			<p-section *ngIf="api.isLoaded()" label="Zeit-Einstellungen" i18n-label pAnchorLink="time-settings">
				<ng-template #popTemplate><div class="p-2" i18n><strong *ngIf="!shift">Überträgst du diese Einstellungen auf Schichten,</strong><strong *ngIf="!!shift">Änderst du diese Einstellungen,</strong> werden buchende & teilnehmende Personen informiert, <strong>falls</strong> du die automatischen Emails aktiviert hast.</div></ng-template>
				<p-ai-form-group
					[formParent]="formGroup"
					[attributeInfo]="$any(formItem.attributeInfoTime)"
					#timeFormGroupRef>

					<p-editable-modal-box
						[saveButtonPopover]="!formItem.isNewItem() && shiftModel!.isCourse ? popTemplate : null"

						label="Start- und End-Zeit" i18n-label

						[pEditable]="!formItem.isNewItem()"
						[api]="api"
						[valid]="service.startAndEndIsValid(timeFormGroupRef.childGroup, formItem)"
						[disabled]="!!(formGroup.get(formItem.attributeInfoTime.id)?.disabled)"
						(onLeaveCurrent)="formGroup.updateValueAndValidity()"
						[saveChangesHook]="getChangeSelectorModalAsHook(modalContent.template)"
						[size]="enums.BootstrapSize.SM"
					>
						<p-editable-modal-box-showroom>
							<p class="m-0" i18n>{!!shiftModel.attributeInfoName.value, select, true {<code>{{shiftModel.name}}</code>} other {Diese Tätigkeit}} startet um <code>{{formItem.time.start | pDuration:'shortTime'}}</code> und endet um <code>{{formItem.time.end | pDuration:'shortTime'}}</code>.</p>
						</p-editable-modal-box-showroom>
						<p-editable-modal-form>
							<p-grid>
								<div class="col-md-6">
									<p-ai-switch
										label="Startet um" i18n-label
										[group]="timeFormGroupRef.childGroup"
										[attributeInfo]="$any(formItem.time.attributeInfoStart)"
									/>
								</div>
								<div class="col-md-6">
									<p-ai-switch
										label="Endet um" i18n-label
										[group]="timeFormGroupRef.childGroup"
										[attributeInfo]="$any(formItem.time.attributeInfoEnd)"
									/>
								</div>
							</p-grid>
						</p-editable-modal-form>
					</p-editable-modal-box>
				</p-ai-form-group>
				<ng-template [ngIf]="packetIsEditableOrAlreadySet && userCanReadShiftModel">
					<p-ai-form-group
						[formParent]="formGroup"
						[attributeInfo]="$any(formItem.repetition.attributeInfoPacketRepetition)"
						#packetRepetitionFormGroupRef
					>
						<p-editable-modal-box
							[pTooltip]="(userCanWrite && modeIsEditShift(formItem) && !formItem.repetition.attributeInfoPacketRepetition.canSet) ? (formItem.repetition.attributeInfoPacketRepetition.cannotSetHint! | localize) : null"

							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							[valid]="!packetRepetitionFormGroupRef.childGroup.invalid"
							(onDismiss)="initFormGroup()"

							[disabled]="!formItem.repetition.attributeInfoPacketRepetition.canSet"

							label="Schicht-Paket-Einstellungen" i18n-label
							[size]="enums.BootstrapSize.SM"
							[centered]="false"
						>
							<p-editable-modal-box-header>
								<p-icon-tooltip
									i18n
								>Ein Schicht-Paket eignet sich für buchbare Angebote mit mehreren verbundenen Terminen. Ein Beispiel: Ein Kurs mit drei Terminen lässt sich sehr gut als Schicht-Paket anlegen. Ändert sich die leitende Person des Kurses, dann korrigierst du das nur einmal für das gesamte Schicht-Paket und nicht mehrmals in jeder einzelnen Schicht.</p-icon-tooltip>
							</p-editable-modal-box-header>
							<p-editable-modal-box-showroom>
								<div class="row d-flex justify-content-between align-items-start flex-column flex-md-row gap-3">
									<div class="col-md-8 col-lg-9">
										<p i18n>{!!shiftModel.attributeInfoName.value, select, true {<code>{{shiftModel.name}}</code>} other {Diese Tätigkeit}} ist {formItem.isPacket, select, true {ein <code>Schicht-Paket</code>} other {eine <code>Einzelschicht</code>}}.</p>
										<ng-container *ngIf="formItem.isPacket">
											<p i18n>Das Schicht-Paket besteht aus <code>{{ formItem.repetition.packetRepetition.endsAfterRepetitionCount }}</code> Schichten.</p>
											<p i18n>Die Schichten aus diesem Paket finden alle <code>{{ formItem.repetition.packetRepetition.x }} {{ packetRepetitionTitle }}</code> statt.</p>
											<p *ngIf="formItem.repetition.packetRepetition.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS">
												<span *ngIf="formItem.repetition.packetRepetition.endsAfterRepetitionCount > 1" i18n>Jeweils am</span>
												<span *ngIf="!(formItem.repetition.packetRepetition.endsAfterRepetitionCount > 1)" i18n>Am</span>&nbsp;<code i18n>{{ affectedPacketRepetitionWeekdays ?? '–' }}</code>.
											</p>
										</ng-container>
									</div>
									<ng-container *ngIf="modeIsEditShift(formItem) && shift!.packetShifts.length > 1">
										<div class="col">
											<label i18n>Termine des Pakets</label>
											<p-packet-shifts
												[clickable]="false"
												[collapsed]="false"
												[currentShiftId]="shift!.id"
												[packetShifts]="shift!.packetShifts"
											></p-packet-shifts>
										</div>
									</ng-container>
								</div>
							</p-editable-modal-box-showroom>
							<p-editable-modal-form>
								<div hidden><span i18n #isPacketLabelRef>{ !!shiftModel.attributeInfoName.value, select, true {{{'»'+shiftModel.name+'«'}}} other {Diese Tätigkeit} } ist</span></div>
								<p-bootstrap-form-group
									[label]="isPacketLabelRef.textContent" i18n-label
								>
									<p-dropdown
										[(ngModel)]="formItem.isPacket"
										[ngModelOptions]="{standalone: true}"
										[appendDropdownOnBody]="true"
									>
										<p-dropdown-item
											[value]="false"
											label="eine Einzelschicht" i18n-label
										></p-dropdown-item>
										<p-dropdown-item
											[value]="true"
											label="ein Schicht-Paket" i18n-label
										></p-dropdown-item>
									</p-dropdown>
								</p-bootstrap-form-group>
								<p-ai-switch
									[pEditable]="false"
									label="Das Paket besteht aus" i18n-label
									[checkTouched]="false"
									[group]="packetRepetitionFormGroupRef.childGroup"
									[attributeInfo]="$any(formItem.repetition.packetRepetition.attributeInfoEndsAfterRepetitionCount)"
									inputGroupAppendText="Schichten" i18n-inputGroupAppendText
								></p-ai-switch>
								<p-ai-switch
									[pEditable]="false"
									label="Die Schichten des Pakets wiederholen sich alle" i18n-label
									[group]="packetRepetitionFormGroupRef.childGroup"
									[attributeInfo]="$any(formItem.repetition.packetRepetition.attributeInfoX)"
								>
									<p-ai-switch
										*ngIf="formItem.repetition.packetRepetition.attributeInfoX.isAvailable"
										[pEditable]="false"
										class="input-group-append"
										[appendDropdownOnBody]="true"
										[group]="packetRepetitionFormGroupRef.childGroup"
										[attributeInfo]="$any(formItem.repetition.packetRepetition.attributeInfoType)"
										bsFormGroupClasses="mb-0"
									>
										<p-ai-switch-item
											*ngFor="let packetRepetitionOption of repetitionOptions"
											[label]="packetRepetitionOption.title | localize"
											[value]="packetRepetitionOption.enum"
										></p-ai-switch-item>
									</p-ai-switch>
								</p-ai-switch>

								<ng-template [ngIf]="formItem.repetition.packetRepetition.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS">
									<p-weekdays
										[label]="packetEndsAfterRepetitionCountLabel"
										[disabled]="!!modeIsEditShift(formItem)"
										[group]="packetRepetitionFormGroupRef.childGroup"
										[repetition]="formItem.repetition.packetRepetition"
									></p-weekdays>
								</ng-template>

								<p-validation-hint
									*ngFor="let error of pFormsService.visibleErrors(packetRepetitionFormGroupRef.childGroup)"
									[control]="packetRepetitionFormGroupRef.childGroup"
									[isInvalid]="true"
									[validationName]="error.key"
									[errorValue]="error.value"
									[checkTouched]="false"
								></p-validation-hint>

							</p-editable-modal-form>
						</p-editable-modal-box>
					</p-ai-form-group>
				</ng-template>
				<ng-template [ngIf]="intervalIsEditableOrAlreadySet && userCanReadShiftModel">
					<ng-template #youCanNotEditTheRepetition>
						<div class="d-block clickable card m-0 text-left o-hidden">
							<div class="card-body p-1 pl-2 pr-2">
								<span i18n>Die Wiederholung kannst du nur in der Schichtvorlage oder beim Erstellen neuer Schichten bearbeiten. Hier ist es leider nicht mehr möglich.</span>
							</div>
						</div>
					</ng-template>

					<p-ai-form-group
						[formParent]="formGroup"
						[attributeInfo]="$any(formItem.attributeInfoRepetition)"
						#repetitionFormGroupRef
					>
						<p-editable-modal-box
							[pTooltip]="(userCanWrite && modeIsEditShift(formItem)) ? youCanNotEditTheRepetition : null"

							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							[valid]="!repetitionFormGroupRef.childGroup.invalid"
							(onDismiss)="initFormGroup()"
							[disabled]="!formItem.attributeInfoRepetition.canSet"
							label="Wiederholungs-Einstellungen" i18n-label
							[size]="enums.BootstrapSize.SM"
							[centered]="false"
						>
							<p-editable-modal-box-header>
								<p-icon-tooltip
									i18n
								>Eine Wiederholung unterscheidet sich von einem Schicht-Paket dadurch, dass die wiederholenden Schichten <strong>nicht verbunden</strong> sind. Du solltest also bspw. einen Kurs mit drei Terminen als Schicht-Paket anlegen. Das Schicht-Paket selbst kannst du natürlich wiederholen lassen, wenn du den Kurs in regelmäßigen Abständen anbietest.</p-icon-tooltip>
							</p-editable-modal-box-header>
							<p-editable-modal-box-showroom *ngIf="!repetitionFormGroupRef.childGroup.invalid">
								<ng-template [ngIf]="formItem.hasRepetition"><p i18n>{formItem.isPacket, select, true {Das Schicht-Paket } other {Die Schicht }}<code>{{ shiftModel!.name }}</code> wiederholt sich alle <code>{!!formItem.repetition.x, select, true {{{formItem.repetition.x}}} other {?}}</code> <code> {{ repetitionTitle }}</code>.</p></ng-template>
								<ng-template [ngIf]="!formItem.hasRepetition"><p i18n>{formItem.isPacket, select, true {Das Schicht-Paket } other {Die Schicht }}<code>{{ shiftModel!.name }}</code> wiederholt sich nicht.</p></ng-template>
								&ngsp;
								<ng-template [ngIf]="formItem.hasRepetition">
									<p *ngIf="affectedRepetitionWeekdays" i18n>{formItem.isPacket, select, true {Eine neue Wiederholung startet jeweils am} other {Wiederhole an jedem}} <code>{{affectedRepetitionWeekdays}}</code>.</p>
									<!-- TODO: PLANO-156907 -->
									<p
										*ngIf="shift && !shift.isNewItem() && shift.attributeInfoSeriesStart.isAvailable && shift.seriesStart"
										i18n
									>Die Wiederholung startet am <code>{{ shift.seriesStart | date:'shortDate' }}</code>.</p>
									<ng-container *ngIf="formItem.repetition.repetitionEndMode !== ShiftModelRepetitionEndMode.NEVER">
										<p class="m-0" *ngIf="formItem.repetition.attributeInfoRepetitionEndMode.isAvailable">
											<span i18n>Die Wiederholung {formItem.isPacket, select, true {des Schicht-Pakets} other {der Schicht}} endet nach <ng-container *ngIf="formItem.repetition.repetitionEndMode === ShiftModelRepetitionEndMode.AFTER_X_TIMES"><code>{{ formItem.repetition.attributeInfoEndsAfterRepetitionCount.isAvailable ? formItem.repetition.endsAfterRepetitionCount : '…' }}</code> {{ repetitionEndDateModeTitle }}</ng-container><ng-container *ngIf="formItem.repetition.repetitionEndMode === ShiftModelRepetitionEndMode.ENDS_AFTER_DATE">dem <code>{{ repetitionEndDateModeTitle }} {{ formItem.repetition.endsAfterDate === null ? '–' : (formItem.repetition.endsAfterDate - 1 | date:'shortDate') }}</code></ng-container>.</span>
										</p>
									</ng-container>
								</ng-template>
							</p-editable-modal-box-showroom>
							<p-editable-modal-form>
								<div
									[class.form-group]="formItem.hasRepetition"
									[class.has-danger]="repetitionFormGroupRef.childGroup.get(formItem.repetition.attributeInfoType.id)?.invalid"
								>
									<p-checkbox
										[(ngModel)]="formItem.hasRepetition"
										[ngModelOptions]="{standalone: true}"
										[valueText]="translatedIsIntervalValueText"
									></p-checkbox>
								</div>
								<div *ngIf="formItem.hasRepetition">
									<div [class.has-danger]="repetitionFormGroupRef.childGroup.get(formItem.repetition.attributeInfoX.id)?.invalid">
										<p-ai-switch
											[pEditable]="false"
											label="Wiederhole alle" i18n-label
											[group]="repetitionFormGroupRef.childGroup"
											[attributeInfo]="$any(formItem.repetition.attributeInfoX)"
										>
											<p-ai-switch
												*ngIf="formItem.repetition.attributeInfoX.isAvailable"
												[pEditable]="false"
												[appendDropdownOnBody]="true"
												class="input-group-append"
												[group]="repetitionFormGroupRef.childGroup"
												[attributeInfo]="$any(formItem.repetition.attributeInfoType)"
												bsFormGroupClasses="mb-0"
											>
												<p-ai-switch-item
													*ngFor="let repetitionOption of repetitionOptions"
													[label]="repetitionOption.title | localize"
													[value]="repetitionOption.enum"
												></p-ai-switch-item>
											</p-ai-switch>
										</p-ai-switch>
									</div>
									<ng-template [ngIf]="formItem.repetition.type === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS">
										<p-weekdays
											[label]="isPacketLabel"
											[disabled]="!!modeIsEditShift(formItem)"
											[group]="repetitionFormGroupRef.childGroup"
											[repetition]="formItem.repetition"
										></p-weekdays>
									</ng-template>
									<div>

										<p-ai-switch
											[pEditable]="false"
											label="Ende der Wiederholungen" i18n-label
											[group]="formGroup"
											[appendDropdownOnBody]="true"
											[attributeInfo]="$any(formItem.repetition.attributeInfoRepetitionEndMode)"
											[type]="AISwitchUIType.DROPDOWN"
										>
											<p-ai-switch-item
												*ngFor="let mode of service.intervalEndDateModesIterable"
												[label]="mode.title"
												[value]="mode.enum"
											></p-ai-switch-item>
										</p-ai-switch>

										<p-ai-switch
											[pEditable]="false"
											label="Endet am"
											[group]="repetitionFormGroupRef.childGroup"
											[attributeInfo]="$any(formItem.repetition.attributeInfoEndsAfterDate)"
										></p-ai-switch>

										<p-ai-switch
											[pEditable]="false"
											label="Nach" i18n-label
											[checkTouched]="false"
											[group]="repetitionFormGroupRef.childGroup"
											[attributeInfo]="$any(formItem.repetition.attributeInfoEndsAfterRepetitionCount)"
											[inputGroupAppendText]="(formItem.isPacket ? 'Paketen' : 'Schichten') | localize"
										></p-ai-switch>
									</div>
								</div>

								<p-validation-hint
									*ngFor="let error of pFormsService.visibleErrors(repetitionFormGroupRef.childGroup)"
									[control]="repetitionFormGroupRef.childGroup"
									[isInvalid]="true"
									[validationName]="error.key"
									[errorValue]="error.value"
									[checkTouched]="false"
								></p-validation-hint>

							</p-editable-modal-form>
						</p-editable-modal-box>
					</p-ai-form-group>
				</ng-template>
			</p-section>
			<p-section label="Arbeitszeiterfassung für die Auswertung" i18n-label pAnchorLink="time-tracking-for-reports" *ngIf="formItem.attributeInfoWorkingTimeCreationMethod.isAvailable">
				<p-ai-switch
					[group]="formGroup"
					[attributeInfo]="$any(formItem).attributeInfoWorkingTimeCreationMethod"

					[pEditable]="!formItem.isNewItem()"
					[api]="api"
					(onDismiss)="initFormGroup()"
					[saveChangesHook]="getChangeSelectorModalAsHook(workingTimeCreationMethodIsViaTimestampHook.template)"
				>
					<ng-template #timeStampDescriptionTemplateRef>
						<p>
							<ng-container i18n>Deine Team-Mitglieder erfassen per Stempeluhr ihre tatsächlichen Arbeitszeiten. Diese landen in der Auswertung. Vergisst mal jemand eine Schicht zu stempeln, helfen unsere automatischen Erinnerungsmails weiter.</ng-container>
							<span class="d-inline-block mt-2">
								<fa-icon class="mr-1" icon="exclamation-triangle"/>
								<ng-container i18n>Die Planzeiten aus dem Kalender werden nicht in die Auswertung übertragen.</ng-container>
							</span>
						</p>
					</ng-template>
					<p-ai-switch-item
						label="Per Stempeluhr" i18n-label
						[description]="timeStampDescriptionTemplateRef"
						[value]="SchedulingApiWorkingTimeCreationMethod.TIME_STAMP"
					></p-ai-switch-item>
					<ng-template #automaticDescriptionTemplateRef>
						<p>
							<ng-container i18n>Die Arbeitszeiten werden gemäß Schichtplan automatisch in die Auswertung übertragen. Das geschieht, sobald eine Schicht angefangen hat; aber auch, wenn bei vergangenen Schichten auf diese Zeiterfassungsmethode umgestellt wird.</ng-container>
							<span class="d-inline-block mt-2">
								<fa-icon class="mr-1" icon="exclamation-triangle"/>
								<ng-container i18n>Über- oder Unterstunden sowie Pausenzeiten müssen händisch ergänzt werden. Änderungen im Schichtplan führen zu Änderungen in der Auswertung.</ng-container>
							</span>
						</p>
					</ng-template>
					<p-ai-switch-item
						label="Per Schichtplan" i18n-label
						[description]="automaticDescriptionTemplateRef"
						[value]="SchedulingApiWorkingTimeCreationMethod.AUTOMATIC"
					></p-ai-switch-item>
				</p-ai-switch>

				<ng-template #workingTimeCreationMethodIsViaTimestampHook="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
					<p-change-selectors-modal
						[shiftChangeSelector]="api.data.shiftChangeSelector"
						[close]="c"
						[dismiss]="d"
						[shift]="shift!"
						[shiftModel]="shiftModel!"
						[defaultStart]="shiftStart"
						[minDate]="now"
						[minEndDate]="now"
						[members]="api.data.members"
					></p-change-selectors-modal>
				</ng-template>
			</p-section>
			<ng-container *ngIf="showAssignMembers !== undefined; spinnerTemplate">
				<p-section
					*ngIf="!!showAssignMembers"
				>
					<p-assign-members
						[shift]="shift!"
						[shiftModel]="shiftModel!"
						[showHearts]="userCanWrite && !!modeIsEditShift(formItem)"
					></p-assign-members>
				</p-section>
			</ng-container>
		</p-tab>
		<p-tab
			*ngIf="showBookingsettingsTab"
			[label]="bookingsettingsTabLabel"
			urlName="bookingsettings"
			[hasDanger]="bookingsettingsTabHasDanger"
			[size]="PTabSizeEnum.FRAMELESS"
		>
			<p-section>
				<div hidden><span i18n #isCourseValueTextRef>{ !!shiftModel.attributeInfoName.value, select, true {{{'»'+shiftModel.name+'«'}}} other {Diese Tätigkeit} } ist ein online buchbares Angebot</span></div>

				<p-bootstrap-form-group
					[control]="formGroup.controls['isCourse']"
					class="mb-5"
				>
					<label>&nbsp;</label>
					<p-checkbox
						[pEditable]="!formItem.isNewItem()"
						[api]="api"
						[valid]="!formGroup.controls['isCourse'].invalid"
						(onDismiss)="initFormGroup()"
						[saveChangesHook]="!formItem.isCourse ? isCourseHook : confirmationHook"
						(ngModelChange)="isCourseHookForInactiveEditable($event)"
						id="is-course-checkbox"

						[formControl]="formGroup.controls['isCourse']"
						[disabled]="formGroup.controls['isCourse'].disabled || !formItem.isNewItem() && formGroup.invalid"
						[readMode]="formGroup.controls['isCourse'].disabled"
						[valueText]="isCourseValueTextRef.textContent"
					></p-checkbox>
					<small>
						<span *ngIf="modeIsEditShift(formItem) || service.modeIsCreateShift" i18n>Diese Einstellung kannst du nur in der Tätigkeit <a *ngIf="isOwner" rel="noopener" [target]="!!formItem.isNewItem() ? '_blank' : '_self'" routerLink="/client/shiftmodel/{{ shiftModel!.id.toString() }}/bookingsettings">{{ formItem.name }}<fa-icon [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB" class="ml-1"/></a><span *ngIf="!isOwner">»{{ formItem.name }}«</span> ändern.</span>
					</small>
				</p-bootstrap-form-group>
				<div
					*ngIf="!formGroup.controls['isCourse'].value"
					[@slideVertical]
				>
					<p-alert
						[theme]="enums.PThemeEnum.INFO"
						[icon]="enums.PlanoFaIconPool.LOCKED"
						i18n
					>Die unten folgenden Einstellungen kannst du erst vornehmen, nachdem du dieses Angebot als buchbar definiert hast.</p-alert>
					<fa-icon
						class="d-flex align-items-center justify-content-center mb-3 pointing-downwards"
						[icon]="enums.PlanoFaIconPool.SCROLL_DOWN"
						[size]="'2x'"
					></fa-icon>
				</div>

				<p-bootstrap-form-group>
					<label i18n>Sichtbarkeit im <a *ngIf="isOwner" rel="noopener" target="_blank" routerLink="/client/plugin/setup">Homepage-Plugin</a>{!isOwner, select, true {Homepage-Plugin}}</label>
					<p-grid>
						<div class="col-md-6">
							<p-ai-switch
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								[saveChangesHook]="modeIsEditShift(formItem) ? getChangeSelectorModalAsHook(modalContentForIsCourseOnline.template) : undefined"

								[group]="formGroup"
								[attributeInfo]="$any(formItem).attributeInfoIsCourseOnline"

								valueText="jetzt im Plugin anzeigen" i18n-valueText
							></p-ai-switch>
						</div>
						<div class="col-md-6">
							<p-led
								class="d-inline-block"
								[off]="!formItem.isCourseOnline"
							>
								<span
									class="ml-2"
									*ngIf="!shift && formItem.isCourseOnline"
									i18n
								>Das Angebot<span *ngIf="formItem.name"> »{{ formItem.name }}«</span> ist im Homepage-Plugin sichtbar. Falls du den Plugin auf deiner Homepage integriert hast, können sich Kunden über <strong>Inhalt und Preise</strong> informieren. Erstelle noch im Kalender <strong>Schichten</strong> für »{{ formItem.name }}« und setze sie auf online, damit deinen Kunden buchbare Angebotstermine angezeigt werden.</span>

								<span
									class="ml-2"
									*ngIf="!shift && !formItem.isCourseOnline"
									i18n
								>Aktuell ist das Angebot <span *ngIf="formItem.name">»{{ formItem.name }}«</span> im Homepage-Plugin <strong>nicht sichtbar</strong>. Kunden können sich weder über den Angebotsinhalt und Preise informieren, noch Termine buchen.</span>


								<span
									class="ml-2"
									*ngIf="!!shift && formItem.isCourseOnline"
									i18n
								>Diese Schicht<span *ngIf="formItem.name"> von »{{ formItem.name }}«</span> ist online und wird deinen Kunden als Termin angeboten. <strong>Tipp:</strong> Bei Bedarf kannst du einzelne Schichten/Termine offline setzen, ohne das gesamte Angebot <a *ngIf="isOwner" rel="noopener" [target]="!!formItem.isNewItem() ? '_blank' : '_self'" routerLink="/client/shiftmodel/{{ shiftModel!.id.toString() }}/bookingsettings">»{{ formItem.name }}«</a><span *ngIf="!isOwner">»{{ formItem.name }}«</span> offline zu setzen.</span>

								<span
									class="ml-2"
									*ngIf="!!shift && formItem.isCourseOnline === false && shiftModel!.isCourseOnline"
									i18n
								>Diese Schicht<span *ngIf="formItem.name"> von »{{ formItem.name }}«</span> ist offline und für deine Kunden weder sichtbar noch buchbar. <strong>Tipp:</strong> Bei Bedarf kannst du ausgewählte Schichten online setzen und alle anderen offline lassen.</span>

								<span
									class="ml-2"
									*ngIf="!!shift && formItem.isCourseOnline === false && shiftModel!.isCourseOnline === false"
									i18n
								>Da aktuell das gesamte Angebot<span *ngIf="formItem.name"> »{{ formItem.name }}«</span> offline ist, steht auch diese Schicht auf offline. Sie ist für deine Kunden nicht buchbar. Um das zu ändern, musst du zunächst die Tätigkeit <a *ngIf="isOwner" class="nowrap" rel="noopener" [target]="!!formItem.isNewItem() ? '_blank' : '_self'" routerLink="/client/shiftmodel/{{ shiftModel!.id.toString() }}/">»{{ formItem.name }}«</a><span *ngIf="!isOwner">»{{ formItem.name }}«</span> online setzen.</span>
							</p-led>
						</div>
					</p-grid>
				</p-bootstrap-form-group>
			</p-section>
			<ng-template [ngIf]="showFreeclimberSettings">
				<p-section>
					<p-grid>
						<div class="col-md-6">
							<p-bootstrap-form-group
								[control]="formGroup.controls['freeclimberArticleId']"
							>
								<label><span i18n>Freeclimber Artikel-ID</span> <p-icon-tooltip [class.ml-2]="Config.IS_MOBILE" i18n>Die Kassensoftware Freeclimber benötigt die Artikel-ID, um Kassenzahlungen für die per Schnittstelle übertragenen Buchungen annehmen zu können. Die hier eingegebene ID muss der jeweiligen Artikel-ID der Kasse entsprechen.</p-icon-tooltip></label>
								<p-input
									[type]="PApiPrimitiveTypes.string"
									[pEditable]="!formItem.isNewItem()"
									[api]="api"
									(onDismiss)="initFormGroup()"

									[formControl]="formGroup.controls['freeclimberArticleId']"
								></p-input>
							</p-bootstrap-form-group>
						</div>

						<div class="col-md-6"
							*ngIf="!formGroup.controls['freeclimberArticleId'].disabled"
						>
							<label>&nbsp;</label>
							<p-alert
								[theme]="enums.PThemeEnum.WARNING"
								[dismissable]="false"
								i18n
							>Die Freeclimber Artikel-ID ist notwendig, um die Zahlung von Buchungspreisen an der Kasse zu ermöglichen.</p-alert>
						</div>
					</p-grid>
				</p-section>
			</ng-template>
			<ng-template [ngIf]="!shift && userCanWrite">
				<p-section label="Online-Präsentation" i18n-label pAnchorLink="online-presentation" >
					<p-grid>
						<div class="col-lg-6">
							<p-ai-switch
								label="Angebotstitel im Plugin" i18n-label

								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="z.B. »Kindergeburtstag«" i18n-placeholder

								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseTitle"
							></p-ai-switch>
						</div>
						<div class="col-lg-6">
							<p-ai-switch
								label="Untertitel" i18n-label

								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="Spaß für die ganze Familie …" i18n-placeholder

								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseSubtitle"
							></p-ai-switch>
						</div>
						<div class="col-lg-6">
							<p-ai-switch
								label="Angebotsgruppe" i18n-label
								description="Hiermit lassen sich einzelne Angebote im Buchungsplugin auf eurer Homepage gruppieren. Dadurch wird eure Homepage übersichtlicher. Die Angebotsgruppen haben keine Relevanz, wenn ihr die Angebote einzeln auf eurer Homepage einbindet." i18n-description
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseGroup"

								[pEditable]="!shiftModel.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="z.B. »Kinderkurse«" i18n-placeholder
								[typeahead]="typeAheadCourseGroupContent"
								(focusout)="trimCourseGroup()"
							></p-ai-switch>
						</div>

						<div id="course-code-prefix" class="col-lg-6">
							<p-ai-switch
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								label="Angebotsnummer-Präfix" i18n-label
								description="Abgesehen vom Präfix wird die Angebotsnummer automatisch vergeben. Jeder buchbare Angebotstermin dieser Tätigkeit bekommt dadurch eine einzigartige Nummer. Änderungen des Präfixes werden auf vergangene und zukünftige Angebote übertragen." i18n-description
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseCodePrefix"
								[checkTouched]="false"
							></p-ai-switch>
							<div class="form-text" *ngIf="shiftModel.attributeInfoCourseCodePrefix.isAvailable"><p class="m-0"><ng-container i18n>Beispiel:</ng-container>&nbsp;<code>{!!shiftModel.courseCodePrefix, select, true {{{shiftModel.courseCodePrefix?.toUpperCase() ?? 'XX'}}} other {XX}}-123-4</code></p></div>
							<p-bootstrap-form-group
								*ngIf="showControlCourseCode"
								label="Angebotsnummer" i18n-label
								[control]="formGroup.controls['courseCode']"
							>
								&nbsp;<code [class.text-muted]="!formGroup.controls['courseCode'].value" style="line-height: 0;"> {!!formGroup.controls['courseCode'].value, select, true {{{formGroup.controls['courseCode'].value}}} other {-}}</code>
							</p-bootstrap-form-group>
						</div>

						<div class="col-12">
							<p-ai-switch
								label="Angebotsbeschreibung" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseDescription"

								[type]="AISwitchUIType.TEXT_EDITOR"
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="Das ist ein …" i18n-placeholder
							></p-ai-switch>
						</div>
						<div class="col-lg-6">

							<p-ai-switch
								label="Anforderungen" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseSkillRequirements"

								[type]="AISwitchUIType.TEXT_EDITOR"
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="Ehrgeiz …" i18n-placeholder
							></p-ai-switch>
						</div>
						<div class="col-lg-6">
							<p-ai-switch
								label="Mitzubringen" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseEquipmentRequirements"

								[type]="AISwitchUIType.TEXT_EDITOR"
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="Sporthose …" i18n-placeholder
							></p-ai-switch>
						</div>
						<div class="col-12">
							<p-ai-switch
								label="Ort" i18n-label
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseLocation"

								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								(onDismiss)="initFormGroup()"

								placeholder="Gleis 9 3/4" i18n-placeholder
							></p-ai-switch>
						</div>
					</p-grid>
				</p-section>
				<p-section label="Kontakt für Kundenfragen" i18n-label pAnchorLink="contact-customer-request" >
					<p-grid>
						<div class="col-lg-4">
							<p-ai-switch
								label="Name" i18n-label
								class="d-block mb-2"
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								placeholder="Peter Plano" i18n-placeholder
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseContactName"
							></p-ai-switch>
						</div>
						<div class="col-md-6 col-lg-4">
							<p-ai-switch
								label="Email" i18n-label

								[pEditable]="!formItem.isNewItem()"
								[api]="api"
								placeholder="service@…" i18n-placeholder

								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseContactEmail"
							></p-ai-switch>
						</div>
						<div class="col-md-6 col-lg-4">
							<p-ai-switch
								label="Telefon" i18n-label

								[pEditable]="!formItem.isNewItem()"
								[api]="api"

								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoCourseContactPhone"
							></p-ai-switch>
						</div>
					</p-grid>
				</p-section>
			</ng-template>
			<p-section label="Buchungsoptionen" i18n-label *ngIf="showBookingOptionsSection" pAnchorLink="booking-options" >
				<p-grid *ngIf="(modeIsEditShiftModel(formItem) || service.modeIsCreateShiftModel)">
					<div class="col-12">
						<ng-template #courseTypeHookTemplate="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
							<p-modal-content
								[theme]="enums.PThemeEnum.INFO"
								(onDismiss)="d($event)"
								(onClose)="c($event);"
								dismissBtnLabel="Abbrechen" i18n-dismissBtnLabel
								closeBtnLabel="OK" i18n-closeBtnLabel
							>
								<p-modal-content-body>
									<p i18n>Die Änderung der Buchungsart wirkt sich <strong>nur auf neue Buchungen</strong> aus. Gegebenenfalls vorhandene Buchungen bleiben unangetastet.</p>
									<p class="m-0" i18n>Vergewissere dich, dass deine <a routerLink="." fragment="automatic-emails" (click)="d($event)">automatischen Emails</a> sowohl für die neuen Buchungen als auch für die bestehenden Buchungen passend eingestellt sind.</p>
								</p-modal-content-body>
							</p-modal-content>
						</ng-template>
						<p-bootstrap-form-group
							label="Buchungsart" i18n-label
							[control]="formGroup.controls['courseType']"
						>
							<p-grid>
								<div class="col-12">
									<p-radio
										[pEditable]="!formItem.isNewItem()"
										[api]="api"
										[valid]="!formGroup.controls['courseType'].invalid"
										(onDismiss)="initFormGroup()"
										[saveChangesHook]="courseTypeHook(courseTypeHookTemplate.template)"

										class="d-block mb-2"
										[checked]="formGroup.controls['courseType'].value === courseTypes.ONLINE_BOOKABLE"
										(checkedChange)="formGroup.controls['courseType'].setValue(courseTypes.ONLINE_BOOKABLE)"
										[card]="true"
										[disabled]="formGroup.controls['courseType'].disabled || !shiftModel.isCourse"
										i18n
									>Kunden buchen das Angebot direkt online</p-radio>
								</div>
								<div class="col-12">
									<p-radio
										[pEditable]="!formItem.isNewItem()"
										[api]="api"
										[valid]="!formGroup.controls['courseType'].invalid"
										(onDismiss)="initFormGroup()"
										[saveChangesHook]="courseTypeHook(courseTypeHookTemplate.template)"

										class="d-block mb-2"
										[checked]="formGroup.controls['courseType'].value === courseTypes.ONLINE_INQUIRY"
										(checkedChange)="formGroup.controls['courseType'].setValue(courseTypes.ONLINE_INQUIRY)"
										[card]="true"
										[disabled]="formGroup.controls['courseType'].disabled || !shiftModel.isCourse"
										i18n
									>Kunden schicken online eine Buchungsanfrage und warten auf deine Bestätigung</p-radio>
								</div>
								<div class="col-12">
									<p-radio
										[pEditable]="!formItem.isNewItem()"
										[api]="api"
										[valid]="!formGroup.controls['courseType'].invalid"
										(onDismiss)="initFormGroup()"
										[saveChangesHook]="courseTypeHook(courseTypeHookTemplate.template)"

										class="d-block mb-2"
										[checked]="formGroup.controls['courseType'].value === courseTypes.NO_BOOKING"
										(checkedChange)="formGroup.controls['courseType'].setValue(courseTypes.NO_BOOKING)"
										[card]="true"
										[disabled]="formGroup.controls['courseType'].disabled || !shiftModel.isCourse"
										i18n
									>Offenes Angebot: Teilnahme ohne vorherige Buchung möglich</p-radio>
								</div>
							</p-grid>
						</p-bootstrap-form-group>
					</div>
					<div class="col-12"
						[class.d-none]="formGroup.controls['courseType'].value !== courseTypes.ONLINE_INQUIRY"
					>
						<ng-template #bookingDesiredDateSettingHookRef="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
							<p-modal-content
								[theme]="enums.PThemeEnum.INFO"
								(onDismiss)="d($event)"
								(onClose)="c($event);"
								dismissBtnLabel="Abbrechen" i18n-dismissBtnLabel
								closeBtnLabel="OK" i18n-closeBtnLabel
							>
								<p-modal-content-body>
									<p i18n>Die Änderung der Wunschtermin-Einstellung wirkt sich <strong>nur auf neue Buchungen</strong> aus. Gegebenenfalls vorhandene Buchungen bleiben unangetastet.</p>
								</p-modal-content-body>
							</p-modal-content>
						</ng-template>

						<p-ai-switch
							label="Wunschtermin" i18n-label
							description="Deine Kunden können ihre Wunschzeit frei in ein Textfeld reinschreiben: z.B. »lieber nachmittags« oder »in den nächsten 2 Wochen«. <br/>Der Wunschtermin kann eine zusätzliche Option zu den konkreten Terminen sein. Du kannst aber auch deine Angebote nur nach Wunschterminen anbieten." i18n-description
							[group]="formGroup"
							[attributeInfo]="shiftModel.attributeInfoBookingDesiredDateSetting"

							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							[saveChangesHook]="bookingDesiredDateSettingHook(bookingDesiredDateSettingHookRef.template)"
						>
							<p-ai-switch-item
								label="Nicht erlaubt" i18n-label
								[value]="bookingDesiredDateSettings.DESIRED_DATE_NOT_ALLOWED"
							></p-ai-switch-item>
							<p-ai-switch-item
								label="Optional zu Terminen" i18n-label
								[value]="bookingDesiredDateSettings.DESIRED_DATE_OPTIONAL"
							></p-ai-switch-item>
							<p-ai-switch-item
								label="Nur Wunschtermin" i18n-label
								[value]="bookingDesiredDateSettings.ONLY_DESIRED_DATES"
							></p-ai-switch-item>
						</p-ai-switch>
					</div>
					<ng-template [ngIf]="formGroup.controls['courseType'].value !== courseTypes.NO_BOOKING">
						<div class="col-md-6">
							<p-bootstrap-form-group
								[control]="formGroup.controls['courseBookingDeadlineFrom']"
								label="Buchungsfrist für Kunden startet" i18n-label
								description="Vor dieser Frist können Kunden die Termine zwar sehen, aber nicht buchen oder anfragen. Bei Slot-Buchungen startet die Frist zur gleichen Uhrzeit, wie der jeweilige Slot startet; bei anderen buchbaren Angeboten startet die Frist jeweils um {{startOfDay | pDuration:'shortTime'}}." i18n-description
							>
								<p-input
									[type]="PApiPrimitiveTypes.Duration"
									[durationUIType]="PApiPrimitiveTypes.Days"
									[pEditable]="!formItem.isNewItem()"
									[api]="api"
									(onDismiss)="initFormGroup()"
									placeholder="z.B. »14«" i18n-placeholder
									inputGroupAppendText="Tage vorher" i18n-inputGroupAppendText

									[formControl]="formGroup.controls['courseBookingDeadlineFrom']"
									[disabled]="!formItem.isCourse"
									[readMode]="false"
								></p-input>
							</p-bootstrap-form-group>
						</div>
						<div class="col-md-6">
							<p-bootstrap-form-group
								[control]="formGroup.controls['courseBookingDeadlineUntil']"
								label="Buchungsfrist für Kunden endet" i18n-label
								description="Bis zu dieser Frist können Kunden die Angebotstermine sehen, buchen oder anfragen. Die Frist endet jeweils um {{endOfDay | pDuration:'shortTime'}}. Lässt du das Feld für die Frist leer, dann bleiben bei der Slot-Buchung die Slots bis zu deren End-Uhrzeit buchbar; bei anderen buchbaren Angeboten bis zu deren Start-Uhrzeit." i18n-description
							>
								<p-input
									[type]="PApiPrimitiveTypes.Duration"
									[durationUIType]="PApiPrimitiveTypes.Days"
									[pEditable]="!formItem.isNewItem()"
									[api]="api"
									(onDismiss)="initFormGroup()"
									placeholder="z.B. »7«" i18n-placeholder
									inputGroupAppendText="Tage vorher" i18n-inputGroupAppendText

									[formControl]="formGroup.controls['courseBookingDeadlineUntil']"
									[disabled]="!formItem.isCourse"
									[readMode]="false"
								></p-input>
							</p-bootstrap-form-group>
						</div>
						<div class="col-12 pt-2 pb-3 px-6" *ngIf="shiftModel.attributeInfoUseBookingDeadlinesForSlots.isAvailable">
							<hr style="margin: 0; padding: 0">
							<div class="arrow-up"></div><!-- NOTE: The same separator exists on other areas in our app. Next time you need it, create a Component for it. -->
						</div>
						<div class="col-12 d-flex justify-content-center mb-4">
							<p-ai-switch
								valueText="Spezielle Buchungsfristen für die Slot-Buchung verwenden" i18n-valueText
								label="Spezielle Buchungsfristen" i18n-label
								description="<p><strong>Buchungsfrist startet:</strong> Zur gleichen Uhrzeit, wie der jeweilige Slot startet (bei normalen buchbaren Angeboten startet die Frist jeweils um {{startOfDay | pDuration:'shortTime'}}).</p><p><strong>Buchungsfrist endet:</strong> Wenn das Feld leer gelassen wird, dann bleiben bei der Slot-Buchung die Slots bis zu deren End-Uhrzeit buchbar (normale buchbare Angebote bleiben lediglich bis zu ihrer Start-Uhrzeit buchbar).</p>" i18n-description
								[group]="formGroup"
								[attributeInfo]="shiftModel.attributeInfoUseBookingDeadlinesForSlots"
								[pEditable]="!formItem.isNewItem()"
								[api]="api"
							></p-ai-switch>
						</div>
					</ng-template>
				</p-grid>
				<p-grid *ngIf="userCanReadShiftModel">
					<div class="col-md-6">
						<p-ai-switch
							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							[saveChangesHook]="getChangeSelectorModalAsHook(modalContentForCourseRelatedValues.template)"
							placeholder=""

							label="Mindestteilnehmendenzahl" i18n-label
							[group]="formGroup"
							[attributeInfo]="$any(formItem).attributeInfoMinCourseParticipantCount"
						></p-ai-switch>
					</div>
					<div class="col-md-6">
						<p-ai-switch
							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							[saveChangesHook]="getChangeSelectorModalAsHook(modalContentForCourseRelatedValues.template)"
							placeholder=""


							label="Höchstteilnehmendenzahl" i18n-label
							description="Für unbeschränkte Teilnehmendenzahl lasse bitte das Feld einfach leer." i18n-description
							[group]="formGroup"
							[attributeInfo]="$any(formItem).attributeInfoMaxCourseParticipantCount"

							#maxCourseParticipantCountSwitchRef
						></p-ai-switch>
						<p-validation-hint
							text="Für unbeschränkt bitte das Feld einfach leer lassen." i18n-text
							[isInvalid]="formItem.maxCourseParticipantCount === 0"
							[theme]="enums.PThemeEnum.INFO"
							[checkTouched]="false"
						></p-validation-hint>
						<p-validation-hint
							text="➡ Anzahl ist unbeschränkt" i18n-text
							[isInvalid]="maxCourseParticipantCountSwitchRef.control.valid && maxCourseParticipantCountSwitchRef.control.value !== 0 && !maxCourseParticipantCountSwitchRef.control.value"
							[theme]="enums.PThemeEnum.SECONDARY"
							[checkTouched]="false"
						></p-validation-hint>
					</div>
				</p-grid>
			</p-section>
			<p-section *ngIf="!shift">
				<ng-template #singleSlotBookableOption><p i18n>Unterschiedliche Kunden können einen oder mehrere Plätze des Angebots buchen, bis die maximale Teilnehmendenzahl erreicht ist. So nehmen in aller Regel unterschiedliche – sich fremde – Kunden am selben Angebotstermin teil.<br/><strong>Typische Beispiele:</strong> Einführungskurse, Technik-Kurse, Eintritt-Slots, Exkursionen.</p></ng-template>
				<ng-template #wholeCourseBookableOption><p i18n>Ein Kunde bucht mit einer Buchung den gesamten Angebotstermin exklusiv für sich, sodass andere Kunden nicht mehr denselben Termin buchen können – selbst wenn die maximale Teilnehmendenzahl noch nicht erreicht sein sollte.<br/><strong>Typische Beispiele:</strong> Familien-Events wie Kindergeburtstage, Firmenevents.</p></ng-template>
				<p-editable-modal-box
					[pEditable]="!formItem.isNewItem() && canSetOnlyWholeCourseBookable.current === true"
					[beforeModalClose]="onSaveOnlyWholeCourseBookable"
					[api]="api"
					id="only-whole-course-bookable-box"
					label="Platzbuchung" i18n-label
					[btnIcon]="formItem.isCourse ? enums.PlanoFaIconPool.MORE_INFO : null"
					btnLabel="Info" i18n-btnLabel
					[showBtnLabel]="false"

					[size]="enums.BootstrapSize.XL"
					[disabled]="!formItem.isCourse"
					[readMode]="false"
					[valid]="!formItem.isCourse || shiftModel.attributeInfoOnlyWholeCourseBookable.value !== null"
					#onlyWholeCourseBookableModalBoxRef
				>
					<p-editable-modal-box-showroom>
						<ng-container *ngIf="shiftModel.attributeInfoOnlyWholeCourseBookable.value === null; else hasOnlyWholeCourseBookableValueTemplate" i18n>Wähle bitte, ob {{ formItem.name ? '»' + formItem.name + '«' : 'dieses Angebot' }} ein exklusiv buchbares Angebot ist, dessen Termine jeweils nur von einem Kunden gebucht werden können (wie z.B. bei Familienfeiern, Firmen-Events); oder können mehrere – sich fremde – Kunden einzelne Plätze desselben Termins buchen (wie z.B. bei Einführungskursen, Eintritt-Slots)?</ng-container>
						<ng-template #hasOnlyWholeCourseBookableValueTemplate><p class="m-0">
							<ng-container *ngIf="!shiftModel.onlyWholeCourseBookable" i18n>Ein Kunde bucht <code>einzelne Plätze</code> dieses Angebots. Weitere Kunden können auch Plätze desselben Angebots buchen.</ng-container>
							<ng-container *ngIf="shiftModel.onlyWholeCourseBookable" i18n>Ein Kunde bucht mit einer Buchung das <code>gesamte Angebot</code>. Weitere Plätze desselben Angebots können nicht von anderen Kunden gebucht werden.</ng-container></p>
						</ng-template>
					</p-editable-modal-box-showroom>
					<p-editable-modal-form>
						<p i18n *ngIf="canSetOnlyWholeCourseBookable.current === true">Um {!!shiftModel.attributeInfoName.value, select, true {»{{shiftModel.name}}«} other {diese Tätigkeit}} buchbar machen zu können, musst du zunächst festlegen, ob es sich dabei um ein exklusives Angebot handelt, dessen Termine immer nur von einem Kunden gebucht werden können oder ob mehrere Kunden einzelne Plätze desselben Termins buchen können.</p>
						<p-ai-switch
							[pEditable]="false"
							label="Platzbuchung" i18n-label
							[group]="formGroup"
							[attributeInfo]="shiftModel.attributeInfoOnlyWholeCourseBookable"
							[canSet]="canSetOnlyWholeCourseBookable.current"
						>
							<p-ai-switch-item
								[description]="singleSlotBookableOption"
								[value]="false"
								label="Ein Kunde bucht einzelne Plätze des Angebots" i18n-label
							></p-ai-switch-item>
							<p-ai-switch-item
								[description]="wholeCourseBookableOption"
								[value]="true"
								label="Ein Kunde bucht mit einer Buchung das gesamte Angebot" i18n-label
							></p-ai-switch-item>
						</p-ai-switch>
						<p-alert *ngIf="canSetOnlyWholeCourseBookable.current" i18n>Bitte vergewissere dich, dass deine Eingabe korrekt ist, denn diese Einstellung für »{{shiftModel.name}}« lässt sich später <strong>nicht</strong> ändern.</p-alert>
						<p-alert
							*ngIf="!canSetOnlyWholeCourseBookable.current"
							[theme]="enums.PThemeEnum.INFO"
							i18n
							class="mt-3"
						>Die Einstellung oben wurde ausgewählt, als die Buchbarkeit von »{{shiftModel.name}}« erstmalig aktiviert wurde. Die Einstellung lässt sich <strong>nicht</strong> mehr ändern, da ggf. vorhandene Buchungen und Kurse nicht mehr korrekt funktionieren würden. Du kannst aber eine neue buchbare Tätigkeit erstellen und dort die gewünschte Einstellung vornehmen. Tipp: Beim Erstellen neuer Tätigkeiten kannst du eine bereits bestehende Tätigkeit kopieren und so viel Zeit sparen.</p-alert>
					</p-editable-modal-form>
				</p-editable-modal-box>
			</p-section>
			<p-age-limit-section
				*ngIf="modeIsEditShiftModel(formItem) || service.modeIsCreateShiftModel"
				[shiftModel]="$any(formItem)"
				[formGroup]="formGroup"
				[api]="api"
				[userCanWrite]="userCanWrite"
				(initFormGroup)="initFormGroup()"
			></p-age-limit-section>

			<ng-template [ngIf]="modeIsEditShiftModel(formItem) || service.modeIsCreateShiftModel">
				<p-section>
					<div class="mt-3">
						<p-editable-modal-box
							id="tariffs-and-payment-methods"
							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							[valid]="tariffAndPaymentMethodsModalIsValid"
							(onDismiss)="initFormGroup()"
							(onModalClosed)="initFormGroup()"
							(onModalOpen)="sortTariffs()"

							[saveChangesHook]="saveChangesHook"
							[disabled]="!userCanWrite || (!shiftModel.attributeInfoCourseTariffs.canSet && !shiftModel.attributeInfoCoursePaymentMethods.canSet)"
							label="Tarife & Zahlungsarten" i18n-label

							size="lg"
						>
							<p-editable-modal-box-showroom>
								<ng-template *ngTemplateOutlet="courseAlert"></ng-template>
								<p-bootstrap-form-group
									[control]="formGroup.get(shiftModel.attributeInfoCourseTariffs.id) ?? null"
									label="Tarife" i18n-label>
									<div class="list-group">
										<div *ngFor="let courseTariff of availableTariffs.iterable()" class="list-group-item">
											<div class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
												<label class="m-0 crop-on-overflow mr-md-2">{{ courseTariff.name ? courseTariff.name : '-' }}</label>
												<span *ngIf="hasCourseDatesData(courseTariff)">
													<p-tariff-meta-info
														[negateForCourseDatesInterval]="courseTariff.negateForCourseDatesInterval"
														[forCourseDatesFrom]="courseTariff.forCourseDatesFrom"
														[forCourseDatesUntil]="courseTariff.forCourseDatesUntil"
														isInternalLabel="Interner Tarif" i18n-isInternalLabel
														[isInternal]="courseTariff.isInternal"
													></p-tariff-meta-info>
												</span>
											</div>
										</div>
										<p-alert
											*ngIf="availableTariffs.length === 0"
											[theme]="PAlertThemeEnum.PLAIN"
											[dismissable]="false"
											class="text-muted"
											i18n
										>Keine Tarife definiert</p-alert>
									</div>
								</p-bootstrap-form-group>
								<p-bootstrap-form-group
									[control]="formGroup.get(shiftModel.attributeInfoCoursePaymentMethods.id) ?? null"
									label="Zahlungsarten" i18n-label
								>
									<div class="list-group">
										<div *ngFor="let paymentMethod of availablePaymentMethods" class="list-group-item crop-on-overflow">
											<div
												class="d-flex justify-content-sm-between align-items-sm-center flex-column flex-sm-row crop-on-overflow">
												<label class="mr-3 mb-0 crop-on-overflow"><ng-template [ngIf]="paymentMethodIcon(paymentMethod)"><fa-icon [icon]="$any(paymentMethodIcon(paymentMethod))"/>&nbsp;</ng-template><ng-container i18n>{!!(paymentMethod.name), select, true {{{paymentMethod.name}}} other {Neue Zahlungsart}}</ng-container></label><span *ngIf="paymentMethod.isInternal" i18n>Interne Zahlungsart</span>
											</div>
										</div>
										<p-alert
											*ngIf="availablePaymentMethods.length === 0"
											[theme]="PAlertThemeEnum.PLAIN"
											[dismissable]="false"
											class="text-muted"
											i18n
										>Keine Zahlungsart definiert</p-alert>
									</div>
								</p-bootstrap-form-group>
							</p-editable-modal-box-showroom>
							<p-editable-modal-form>
								<p-section
									[whitespace]="SectionWhitespace.MEDIUM"
								>
									<p-alert
										*ngIf="!shiftModel.isNewItem()"
										[theme]="enums.PThemeEnum.INFO"
										[dismissable]="false"
										class="mb-5"
										i18n
									>Hinweis: Änderungen an Tarifen oder Zahlungsarten wirken sich nur auf zukünftige Buchungen aus. Bereits vorhandene Buchungen bleiben davon unberührt.</p-alert>
									<ng-template *ngTemplateOutlet="courseAlert"></ng-template>
								</p-section>
								<p-section
									label="Tarife" i18n-label
									pAnchorLink="offer-fees"
									[hasDanger]="!!formGroup.get(shiftModel.attributeInfoCourseTariffs.id)?.invalid"
									[whitespace]="SectionWhitespace.MEDIUM"
								>
									<p-ai-form-array
										addBtnText="Tarif hinzufügen" i18n-addBtnText
										[formParent]="formGroup"
										[attributeInfo]="shiftModel.attributeInfoCourseTariffs"
										[addItemInitCode]="addItemInitCode"
										#tariffsFormArrayRef
										(onAdd)="collapseAllCollapsibles()"
									>
										<p-ai-form-group
											*ngFor="let tariff of availableTariffs.iterable()"
											[@popShow]
											[formParent]="tariffsFormArrayRef.childArray"
											[attributeInfo]="tariff.attributeInfoThis"
											#tariffFormGroupRef
										>
										<p-collapsible
											[hasDanger]="tariffFormGroupRef.childGroup.invalid"
											[collapsed]="!tariff.isNewItem()"
										>
											<span trigger class="w-100 mr-3 d-flex align-items-sm-center flex-column flex-sm-row crop-on-overflow">
												<div class="mr-3 mb-2 mb-sm-0 flex-column flex-lg-row flex-grow-1 d-flex justify-content-lg-between align-items-lg-center crop-on-overflow">
													<label class="m-0 crop-on-overflow"><strong>{{tariff.name ? tariff.name : ('Tarif' | localize)}}</strong></label>
													<span *ngIf="hasCourseDatesData(tariff)">
														<p-tariff-meta-info
															[negateForCourseDatesInterval]="tariff.negateForCourseDatesInterval"
															[forCourseDatesFrom]="tariff.forCourseDatesFrom"
															[forCourseDatesUntil]="tariff.forCourseDatesUntil"
															isInternalLabel="Interner Tarif" i18n-isInternalLabel
															[isInternal]="tariff.isInternal"
														></p-tariff-meta-info>
													</span>
												</div>
												<button *ngIf="!tariff.isNewItem()" type="button" class="flex-grow-0 btn btn-light btn-sm mr-3 nowrap"
													(click)="copyTariff($event, tariff)"
													[disabled]="tariffFormGroupRef.childGroup.invalid"
												><fa-icon icon="copy"></fa-icon>&nbsp;<ng-container i18n>Tarif Duplizieren</ng-container></button>
											</span>
											<div content class="card-body">
												<form *ngIf="!!tariffFormGroupRef.childGroup" [formGroup]="tariffFormGroupRef.childGroup">
													<section class="mb-3">
														<p-ai-switch
															valueText="Nur für interne Nutzung / nicht sichtbar für Kunden bei der Online-Buchung" i18n-valueText
															[group]="tariffFormGroupRef.childGroup"
															[attributeInfo]="tariff.attributeInfoIsInternal"
														></p-ai-switch>

														<p-editable-modal-box [valid]="true">
															<p-editable-modal-box-showroom [wrapWithCardBody]="false" class="px-3">
																<label class="m-0">
																	<p-tariff-meta-info
																		[negateForCourseDatesInterval]="tariff.negateForCourseDatesInterval"
																		[forCourseDatesFrom]="tariff.forCourseDatesFrom"
																		[forCourseDatesUntil]="tariff.forCourseDatesUntil"
																		[longText]="true"
																	></p-tariff-meta-info>
																</label>
															</p-editable-modal-box-showroom>
															<p-editable-modal-form>
																<p-alert [theme]="enums.PThemeEnum.INFO" class="mt-3" i18n>Die Zeitangaben beziehen sich auf das <mark>Datum des gebuchten Angebots</mark> und nicht auf das Buchungsdatum – bei mehrtägigen Angeboten ist der erste Termin relevant.</p-alert>
																<p-bootstrap-form-group>
																	<p-ai-switch
																		[group]="tariffFormGroupRef.childGroup"
																		[attributeInfo]="tariff.attributeInfoNegateForCourseDatesInterval"
																		[readMode]="false"
																	>
																		<p-ai-switch-item
																			label="Tarif gilt im folgenden Zeitraum" i18n-label
																			[icon]="enums.PlanoFaIconPool.CALENDAR_SELECT_RELATED_SHIFTS"
																			[value]="false"
																		></p-ai-switch-item>
																		<p-ai-switch-item
																			label="Tarif gilt nicht im folgenden Zeitraum" i18n-label
																			[icon]="enums.PlanoFaIconPool.CALENDAR_EXCLUDE"
																			[value]="true"
																		></p-ai-switch-item>
																	</p-ai-switch>
																</p-bootstrap-form-group>
																<p-grid>
																	<div class="col-md-6">
																		<p-ai-switch
																			label="Start" i18n-label
																			[group]="tariffFormGroupRef.childGroup"
																			[attributeInfo]="tariff.attributeInfoForCourseDatesFrom"
																			eraseValueBtnLabel="Ohne Begrenzung" i18n-eraseValueBtnLabel
																			[placeholder]="forCourseDatesPlaceholder(tariff.forCourseDatesFrom)"
																		></p-ai-switch>
																	</div>
																	<div class="col-md-6">
																		<p-ai-switch
																			label="Ende" i18n-label
																			[group]="tariffFormGroupRef.childGroup"
																			[attributeInfo]="tariff.attributeInfoForCourseDatesUntil"
																			eraseValueBtnLabel="Ohne Begrenzung" i18n-eraseValueBtnLabel
																			[placeholder]="forCourseDatesPlaceholder(tariff.forCourseDatesUntil)"
																		></p-ai-switch>
																	</div>
																</p-grid>
															</p-editable-modal-form>
														</p-editable-modal-box>
														<p-tariff-form
															[formGroup]="tariffFormGroupRef.childGroup"
															[tariff]="tariff"
															[shiftModel]="shiftModel!"
															(onDismissFeeBox)="initFormGroup()"
															[api]="api"
														></p-tariff-form>
														<p-form-delete-section
															*ngIf="userCanWrite"
															(onRemove)="removeTariff(tariff)"
															label="Tarif löschen" i18n-label
															modalText="Möchtest du den Tarif »{{ tariff.name }}« wirklich löschen?" i18n-modalText
														>
															<div i18n>Den Tarif »{{ tariff.name }}« löschen?</div>
														</p-form-delete-section>
													</section>
												</form>
											</div>
											</p-collapsible>
										</p-ai-form-group>
									</p-ai-form-array>
								</p-section>
								<p-section
									label="Zahlungsarten" i18n-label
									pAnchorLink="payment-methods"
									[hasDanger]="!!formGroup.get(shiftModel.attributeInfoCoursePaymentMethods.id)?.invalid"
									[whitespace]="SectionWhitespace.MEDIUM"
								>
									<p i18n>Hast du Fragen zur Online-Zahlung? Besuche gerne unsere <a routerLink="/client/plugin/faq-online-payment" rel="noopener" target="_blank"><fa-icon [icon]="enums.PlanoFaIconPool.AREA_FAQ" class="mr-1"></fa-icon>FAQ zur Online-Zahlung</a>
									</p>
									<p-ai-form-array
										[formParent]="formGroup"
										addBtnText="Zahlungsart hinzufügen" i18n-addBtnText
										[attributeInfo]="shiftModel.attributeInfoCoursePaymentMethods"
										#paymentMethodsFormArrayRef>
										<p-ai-form-group
											*ngFor="let paymentMethod of availablePaymentMethods; let i = index"
											[@popShow]
											[formParent]="paymentMethodsFormArrayRef.childArray"
											[attributeInfo]="paymentMethod.attributeInfoThis"
											#paymentMethodFormGroupRef>
											<ng-container *ngVar="paymentMethodFormGroupRef.childGroup as paymentMethodFormGroup">
												<p-collapsible
												[hasDanger]="paymentMethodFormGroup.invalid"
												[collapsed]="!paymentMethod.isNewItem()"
											>
												<div trigger class="w-100 mr-3 crop-on-overflow">
														<div class="d-flex justify-content-sm-between align-items-sm-center align-item crop-on-overflow flex-column flex-sm-row">
														<label class="mr-3 mb-0 crop-on-overflow">
															<ng-template [ngIf]="paymentMethodIcon(paymentMethod)"
																><fa-icon [icon]="$any(paymentMethodIcon(paymentMethod))"/>&nbsp;</ng-template>
															<strong>
																<ng-container i18n>{!!paymentMethod.name, select, true {{{paymentMethod.name}}} other {Neue Zahlungsart}}</ng-container>
															</strong>
														</label>
														<span *ngIf="paymentMethodFormGroup.get(paymentMethod.attributeInfoIsInternal.id)?.value" i18n>Interne Zahlungsart</span>
													</div>
												</div>
												<div content class="card-body">
													<p-ai-switch
														[group]="$any(paymentMethodFormGroup)"
														[attributeInfo]="paymentMethod.attributeInfoIsInternal"
														valueText="Nur für interne Nutzung / nicht sichtbar für Kunden bei der Online-Buchung" i18n-valueText>
													</p-ai-switch>
													<p-ai-switch
														label="Typ" i18n-label
														[group]="paymentMethodFormGroup"
														[attributeInfo]="paymentMethod.attributeInfoType"
														[pEditable]="false"
														[type]="AISwitchUIType.RADIOS"
														class="px-0"
													>
														<p-ai-switch-item
															label="Benutzerdefiniert" i18n-label
															[value]="service.paymentMethodTypes.MISC"
														></p-ai-switch-item>

														<p-ai-switch-item
															label="Online-Zahlung" i18n-label
															[icon]="enums.PlanoFaIconPool.ONLINE_PAYMENT"
															[value]="service.paymentMethodTypes.ONLINE_PAYMENT"
															[disabled]="!api.data.isOnlinePaymentAvailable"
															[cannotSetHint]="cannotSetOnlinePaymentHint"
														></p-ai-switch-item>
													</p-ai-switch>
													<p-ai-switch
														*ngIf="showPaymentMethodNameAndDescriptionFormFields(paymentMethod)"
														label="Name der Zahlungsart" i18n-label
														description="Dieser Name wird euren Kunden beim Buchen über das Homepage-Plugin angezeigt. Er sollte möglichst kurz sein und keine Anweisungen für die Zahlung enthalten. Für Zahlungsanweisungen nutze bitte unten das Feld »Hinweise zur Zahlung«." i18n-description
														placeholder="z.B. »Zahlung vor Ort«" i18n-placeholder
														[checkTouched]="false"
														[group]="paymentMethodFormGroup"
														[attributeInfo]="paymentMethod.attributeInfoName">
													</p-ai-switch>
													<label *ngIf="showPaymentMethodNameAndDescriptionFormFields(paymentMethod)"><span i18n>Hinweise zur Zahlung</span><fa-icon [icon]="enums.PlanoFaIconPool.TOOLTIP_INFO" class="ml-1"
														pTooltip="Hier kannst du optional weitere Hinweise zu dieser Zahlungsart geben: z.B. deine Kontodaten hinterlegen, wenn du »Banküberweisung« als Zahlungsart anbieten möchtest. Die Hinweise werden deinen Kunden beim Buchen über das Homepage-Plugin angezeigt. Außerdem erscheinen sie in den automatischen Buchungs-Emails an deine Kunden, wenn du in den Email-Vorlagen den Platzhalter <mark>#ZAHLUNGSAUFFORDERUNG#</mark> verwendest." i18n-pTooltip
													></fa-icon></label>
													<p-ai-switch
														*ngIf="showPaymentMethodNameAndDescriptionFormFields(paymentMethod)"
														[group]="paymentMethodFormGroup"
														[type]="AISwitchUIType.TEXT_EDITOR"
														[checkTouched]="false"
														placeholder="z.B. »Du bezahlst am Veranstaltungstag an der Kasse in bar oder per EC-Zahlung.«" i18n-placeholder
														[attributeInfo]="paymentMethod.attributeInfoDescription">
													</p-ai-switch>
													<p-form-delete-section
														(onRemove)="removeCoursePaymentMethod(formGroup, i, paymentMethod)"
														label="Zahlungsart löschen" i18n-label
														modalText="Möchtest du die Zahlungsart »{{ paymentMethod.name }}« wirklich löschen?" i18n-modalText
													>
														<div i18n>Die Zahlungsart »{{ paymentMethod.name }}« löschen?</div>
													</p-form-delete-section>
												</div>
											</p-collapsible>
											</ng-container>
										</p-ai-form-group>
									</p-ai-form-array>
								</p-section>
							</p-editable-modal-form>
						</p-editable-modal-box>
						<ng-template #courseAlert>
							<p-alert
								*ngIf="isFreeCourse"
								[theme]="enums.PThemeEnum.WARNING"
							>
								<ng-container *ngIf="isFreeCourseReasonText"><span [innerHTML]="isFreeCourseReasonText | localize"></span></ng-container>&ngsp;<span i18n>Somit wird »{{ shiftModel!.name }}« bei der Online-Buchung als <mark>kostenlos</mark> angezeigt.</span>
							</p-alert>
						</ng-template>
					</div>
				</p-section>

				<p-section>
					<p-cancellation-policy
						id="cancellation-policy"
						*ngIf="shiftModel !== null"
						[shiftModel]="shiftModel"
						[formGroup]="formGroup.controls['currentCancellationPolicy']"
						[userCanWrite]="userCanWriteCurrentCancellationPolicy"
						(initFormGroup)="initFormGroup()"
					></p-cancellation-policy>
				</p-section>

				<p-section *ngIf="userCanWrite" label="Automatischer Email-Versand" i18n-label pAnchorLink="automatic-emails" >
					<p-grid>
						<div class="col-12">

							<p class="mb-4" i18n>Lege fest, zum welchen Zeitpunkt automatische Emails verschickt werden sollen. Die Email-Vorlagen kannst du über <a *ngIf="isOwner" class="nowrap" rel="noopener" target="_blank" routerLink="/client/plugin/emails">Buchungssystem-Einstellungen</a>{!isOwner, select, true {Buchungssystem-Einstellungen}} anlegen oder bearbeiten. Möchtest du eine Kopie dieser automatischen Emails erhalten, kannst du dies über <a *ngIf="isOwner" class="nowrap" rel="noopener" target="_blank" routerLink="/client/notifications">Benachrichtigungs-Einstellungen</a>{!isOwner, select, true {Benachrichtigungs-Einstellungen}} festlegen.</p>
							<p-bootstrap-form-group *ngIf="api.data.customBookableMails.length; else noMailsTemplate" >
								<ng-template
									ngFor let-eventTypesObject
									[ngForOf]="eventTypes.eventTypesObjects"
								>
									<section *ngIf="eventTypesObject.bookableType === 'booking'" class="mb-3 pb-3 row">
										<div class="col-md-4">
											<div><fa-icon
												*ngIf="eventTypesObject.icon"
												[fixedWidth]="true" [icon]="eventTypesObject.icon"
												class="mr-1"
											></fa-icon>{{ eventTypesObject.title | localize }}</div>
											<small *ngIf="eventTypesObject.description" class="mt-2 form-text">{{ eventTypesObject.description | localize }}</small>
										</div>
										<div class="col-md-8 mt-md-0 mt-2">
											<div
												class="mb-1"
												*ngFor="let customBookableMail of api.data.customBookableMails.getByEventType(eventTypesObject.type).iterable()"
											>
												<p-checkbox
													*ngIf="customBookableMail.eventType === eventTypesObject.type"

													[pEditable]="!formItem.isNewItem()"
													[api]="api"
													(onDismiss)="initFormGroup()"

													(ngModelChange)="$event ? shiftModel!.automaticBookableMailIds.push(customBookableMail.id) : shiftModel!.automaticBookableMailIds.removeItem(customBookableMail.id)"
													[ngModel]="shiftModel!.automaticBookableMailIds.contains(customBookableMail.id)"
													[ngModelOptions]="{standalone: true}"
													[disabled]="!userCanWrite || !shiftModel!.attributeInfoAutomaticBookableMailIds.canSet"
													[readMode]="false"
													[cannotSetHint]="shiftModel!.attributeInfoAutomaticBookableMailIds.cannotSetHint"
												>
													<div>
														<div><i>»{{ customBookableMail.name }}«</i></div>
														<div>➡ <ng-container i18n>{customBookableMail.sendToParticipants, select, true {Teilnehmende Person(en)}}</ng-container> {customBookableMail.sendToParticipants && customBookableMail.sendToBookingPerson, select, true {&nbsp;&&nbsp;}} <ng-container i18n>{customBookableMail.sendToBookingPerson, select, true {Buchende Person}}</ng-container></div>
													</div>
												</p-checkbox>
											</div>
										</div>
									</section>
								</ng-template>
							</p-bootstrap-form-group>
							<ng-template #noMailsTemplate>
								<div class="d-block card card-body text-center text-muted" i18n>Es sind keine Email-Vorlagen vorhanden, die hier ausgewählt werden können.<br/>Du kannst jetzt eine <a rel="noopener" target="_blank" routerLink="/client/plugin/emails">Email-Vorlage erstellen<fa-icon [icon]="enums.PlanoFaIconPool.NAV_FORWARD" class="ml-1"></fa-icon></a></div>
							</ng-template>

						</div>
					</p-grid>
				</p-section>
			</ng-template>
			<ng-template [ngIf]="showRelatedBookings">
				<p-section>
					<p-bootstrap-form-group>
						<p-shift-bookings
							[shift]="shift!"
						></p-shift-bookings>
						<a
							type="button"
							class="btn btn-lg btn-outline-primary w-100"
							*ngIf="showAddBookingsBtn"
							[routerLink]="shift!.isCourseCanceled ? null : pathToBookingLink()"
							[disabled]="shift!.isCourseCanceled"
						><fa-icon [icon]="enums.PlanoFaIconPool.ADD" class="mr-2"></fa-icon><ng-container i18n>Buchung hinzufügen</ng-container></a>
					</p-bootstrap-form-group>
				</p-section>
			</ng-template>
		</p-tab>
		<p-tab
			*ngIf="!shift && shiftModel.attributeInfoMarketingGiftCardSettings.isAvailable"
			label="Marketing-Gutscheine" i18n-label
			urlName="marketing-gift-card-settings"
			[size]="PTabSizeEnum.FRAMELESS"
			[hasDanger]="marketingGiftCardSettings.formGroup.invalid"
		>
			<p-marketing-gift-card-settings #marketingGiftCardSettings [marketingGiftCardSettings]="shiftModel.marketingGiftCardSettings" />
		</p-tab>
		<p-tab
			*ngIf="showAccountingTab"
			label="Buchhaltung" i18n-label
			urlName="accounting"
			[size]="PTabSizeEnum.FRAMELESS"
		>
			<p-section label="Erlöskonten" i18n-label pAnchorLink="revenue-accounts">
				<div class="mb-4" i18n>Lege hier für diese Tätigkeit fest, welche Kontobezeichnungen für Erlöse aus deinen Buchungen verwendet werden sollen:</div>
				<p-grid>
					<div class="col-lg-4" *ngFor="let posAccount of shiftModel!.posAccounts.iterable()">
						<p-bootstrap-form-group label="Erlöse mit {{posAccount.vatPercent | percent: '0.0-1' }} MwSt." i18n-label>
							<p-input
								[pEditable]="!formItem.isNewItem()"
								[api]="api"

								[(ngModel)]="posAccount.name"
								[ngModelOptions]="{standalone: true}"
								placeholder="z.B. »0000«" i18n-placeholder
								[typeahead]="posAccountTypeAheadArray"
								[appendOnBody]="true"
							></p-input>
						</p-bootstrap-form-group>
					</div>
				</p-grid>
			</p-section>
			<p-section label="Controlling" i18n-label pAnchorLink="controlling">
				<p-grid>
					<div class="col-md-6">
						<p-ai-switch
							label="Kostenstelle" i18n-label
							[group]="formGroup"
							[attributeInfo]="shiftModel.attributeInfoCostCentre"

							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							(onDismiss)="initFormGroup()"

							[typeahead]="costCentreTypeAheadArray"
							[appendOnBody]="true"
						></p-ai-switch>
					</div>
					<div class="col-md-6">
						<p-ai-switch
							label="Warengruppe" i18n-label
							[group]="formGroup"
							[attributeInfo]="shiftModel.attributeInfoArticleGroup"

							[pEditable]="!formItem.isNewItem()"
							[api]="api"
							(onDismiss)="initFormGroup()"

							[typeahead]="articleGroupTypeAheadArray"
						></p-ai-switch>
					</div>
				</p-grid>
			</p-section>
		</p-tab>
	</p-tabs>

	<ng-container *ngIf="formItem.isNewItem()"><ng-container *ngTemplateOutlet="formValidationError"></ng-container></ng-container>

	<ng-template #formValidationError>
		<!-- HACK: We had a *ngIf="formGroup.invalid" here, but that caused a changeDetection error -->
		<div class="pb-0" [class.d-none]="!formGroup.invalid">
			<p-alert [theme]="enums.PThemeEnum.DANGER" i18n>Hoppla. Deine Eingabe ist unvollständig oder nicht korrekt. Bitte schau nochmal drüber.</p-alert>
		</div>
	</ng-template>

	<div
		*ngIf="formItem.isNewItem()"
		class="d-flex flex-md-row justify-content-md-end align-items-md-center flex-column justify-content-center align-items-end gap-3">
		<p-notification-conf-form
			*ngIf="showSendEmailSetting"
			[formControl]="formGroup.controls['sendEmail']"
			[api]="api"
			[shift]="shift"
		></p-notification-conf-form>
		<button
			[type]="formGroup.invalid ? 'submit' : 'button'"
			class="btn btn-lg btn-primary"
			[disabled]="addButtonIsDisabled"
			asyncSaveButton
			(click)="add.emit()"
		><fa-icon [icon]="enums.PlanoFaIconPool.ADD" class="mr-2"></fa-icon><ng-container i18n>{shift !== null, select, true {Schicht} other {Tätigkeit}} anlegen</ng-container></button>
	</div>

</form>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		[shiftChangeSelector]="api.data.shiftChangeSelector"
		[close]="c"
		[dismiss]="d"
		[shift]="shift!"
		[shiftModel]="shiftModel!"
		[defaultStart]="shiftStart"
		[members]="api.data.members"
	></p-change-selectors-modal>
</ng-template>

<ng-template #modalContentForIsCourseOnline="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		[shiftChangeSelector]="api.data.shiftChangeSelector"
		[close]="c"
		[dismiss]="d"
		[shift]="shift!"
		[shiftModel]="shiftModel!"
		[defaultStart]="shiftStart"
		[showApplyToShiftModelCheckbox]="false"
		[modalForCourseRelatedValues]="true"
		[members]="api.data.members"
	></p-change-selectors-modal>
</ng-template>

<ng-template #modalContentForCourseRelatedValues="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		[shiftChangeSelector]="api.data.shiftChangeSelector"
		[close]="c"
		[dismiss]="d"
		[shift]="shift!"
		[shiftModel]="shiftModel!"
		[defaultStart]="shiftStart"
		[modalForCourseRelatedValues]="true"
		[members]="api.data.members"
	></p-change-selectors-modal>
</ng-template>

<ng-template #spinnerTemplate>
	<p-spinner [size]="enums.BootstrapSize.LG" class="m-5 h-100"></p-spinner>
</ng-template>
