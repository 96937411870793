/* eslint-disable max-lines -- Remove this before you work here. */
import { PercentPipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { AffectedShiftsApiService, ApiDataWrapperBase, ApiErrorHandler, ApiListWrapper, ApiLoadArgs, ApiPostArgs, ApiSaveArgs, MeService, PShiftExchangeConceptService, RightsService, SchedulingApiService } from '@plano/shared/api';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { ApiDataStack, Stack } from '@plano/shared/api/base/api-base/api-data-stack';
import { ApiDataCopyAttribute } from '@plano/shared/api/base/api-data-copy-attribute/api-data-copy-attribute';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { NOT_CHANGED } from '@plano/shared/api/base/object-diff/object-diff';
import { AsyncValidatorsService } from '@plano/shared/core/async-validators.service';
import { Config } from '@plano/shared/core/config';
import { DataInput } from '@plano/shared/core/data/data-input';
import { PFingerprintService } from '@plano/shared/core/fingerprint.service';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { errorUtils } from '@plano/shared/core/utils/error-utils';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { Subject, firstValueFrom } from 'rxjs';

/**
 * In case a value is not nullable but the initial value is being set by backend,
 * it is initialized with the value "INITIALIZED_IN_BACKEND".
 * This is a special value which will be ignored during deserialization by backend.
 * At the same time, it fulfills the "required" validator and null annotation in frontend.
 */
export const INITIALIZED_IN_BACKEND = '\uE002';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export abstract class ApiBase extends DataInput {
	constructor(
		protected http : HttpClient,
		protected router : Router,
		protected apiError : ApiErrorService,
		zone : NgZone,
		protected injector : Injector,
		private apiPath : string,
		_data : any[] | null = null,
	) {
		super(zone);

		this.apiUrl = `${Config.BACKEND_URL}/${this.apiPath}`;
		if (this.apiUrl.includes('999.999.999.999'))
			this.console.error('Backend ip is 999.999.999.999 which is the dummy ip for the public profile. Looks like the script to start the public profile has not replaced this by your real ip.');

		// init data stack
		this.dataStack = new ApiDataStack(this, (change : string) => {
			// Is the root wrapper not pointing to top data?
			const top = this.dataStack.getTop();

			if (this.getRootWrapper().rawData !== top) {
				// Then update wrappers data
				// Currently we don't want to generate missing data for incoming api data because:
				// 1. The api currently always send full structure for the requested detail
				// 2. Current data generation implementation always also generates detailed nodes which is not desired.
				this.getRootWrapper()._updateRawData(top, false);

				// notify observers after change happened
				this.changed(change);
			}
		});
	}

	/**
	 * Called when a load operation starts.
	 */
	public readonly onDataLoadStart : Subject<void> = new Subject<void>();

	/**
	 * Called when a save operation starts.
	 * @emits dataToSave Raw-data being sent to backend to be saved. Is `NOT_CHANGED` when no backend call happened.
	 */
	public readonly onDataSaveStart = new Subject<(any[] | typeof NOT_CHANGED)>();

	/**
	 * Called after any backend response to a load or save operation.
	 */
	public readonly onBackendResponse : Subject<void> = new Subject<void>();

	/**
	 * Called when a load operation was successful.
	 */
	public readonly onDataLoaded : Subject<void> = new Subject<void>();

	/**
	 * The full url of the api.
	 */
	protected apiUrl : string;

	/**
	 * api data.
	 */
	public dataStack : ApiDataStack;

	/**
	 * Search params of the last load operation which was executed. Note, that in contrast to `lastExecutedLoadSearchParams`
	 * this is updated immediately when calling `load()`.
	 *
	 * `lastExecutedLoadSearchParams` in contrast is updated when the response from backend is received which guarantees that
	 * `lastExecutedLoadSearchParams` is always reflected by current data.
	 */
	protected lastExecutedLoadSearchParams : HttpParams | null = null;

	/**
	 * The search params of the currently loaded data. See `lastExecutedLoadSearchParams` for further information.
	 */
	protected currentDataSearchParams : HttpParams | null = null;

	private isLoadedSubject : Subject<void> = new Subject<void>();

	/**
	 * The id of the last load operation.
	 */
	private lastLoadOperationId = 0;

	private _rightsService : RightsService | null = null;
	private _validators : ValidatorsService | null = null;
	private _asyncValidators : AsyncValidatorsService | null = null;
	private _localizePipe : LocalizePipe | null = null;
	private _pMoment : PMomentService | null = null;
	private _shiftExchangeConceptService : PShiftExchangeConceptService | null = null;
	private _me : MeService | null = null;
	private _toasts : ToastsService | null = null;
	private _schedulingApi : SchedulingApiService | null = null;
	private _affectedShiftsApi : AffectedShiftsApiService | null = null;
	private _datePipe : PDatePipe | null = null;
	private _percentPipe : PercentPipe | null = null;
	private _fingerprintService : PFingerprintService | null = null;
	private _console : LogService | null = null;

	/**
	 * The id of the last save operation.
	 */
	private lastSaveOperationId = 0;

	private runningSaveOperationCount = 0;
	private runningLoadOperationCount = 0;
	private runningPostOperationCount = 0;

	private mockMode = false;

	public currentlyDetailedLoaded : ApiDataWrapperBase | null = null;

	public abstract get data() : ApiDataWrapperBase;

	protected abstract version() : string;

	/**
	 * The global `NgZone`.
	 */
	public getZone() : NgZone {
		return this.zone;
	}

	/**
	 * The global `RightsService`.
	 */
	public get rightsService() : RightsService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._rightsService)
			this._rightsService = this.injector.get(RightsService);

		return this._rightsService;
	}

	/**
	 * The global `ValidatorsService`.
	 */
	public get validators() : ValidatorsService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._validators)
			this._validators = this.injector.get(ValidatorsService);

		return this._validators;
	}

	/**
	 * The global `AsyncValidatorsService`.
	 */
	public get asyncValidators() : AsyncValidatorsService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._asyncValidators)
			this._asyncValidators = this.injector.get(AsyncValidatorsService);

		return this._asyncValidators;
	}

	/**
	 * The global `LocalizePipe`.
	 */
	public get localizePipe() : LocalizePipe {
		// To avoid circular dependencies we use lazy initialization
		if (!this._localizePipe)
			this._localizePipe = this.injector.get(LocalizePipe);

		return this._localizePipe;
	}

	/**
	 * The global `LogService`.
	 */
	public get console() : LogService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._console)
			this._console = this.injector.get(LogService);

		return this._console;
	}

	/**
	 * The global `PMomentService`.
	 */
	public get pMoment() : PMomentService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._pMoment)
			this._pMoment = this.injector.get(PMomentService);

		return this._pMoment;
	}

	/**
	 * The global `PShiftExchangeConceptService`.
	 */
	public get shiftExchangeConceptService() : PShiftExchangeConceptService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._shiftExchangeConceptService)
			this._shiftExchangeConceptService = this.injector.get(PShiftExchangeConceptService);

		return this._shiftExchangeConceptService;
	}

	/**
	 * The global `MeService`.
	 */
	public get me() : MeService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._me)
			this._me = this.injector.get(MeService);

		return this._me;
	}

	/**
	 * The global `ToastsService`.
	 */
	public get toasts() : ToastsService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._toasts)
			this._toasts = this.injector.get(ToastsService);

		return this._toasts;
	}

	/**
	 * The global `SchedulingApiService`.
	 */
	public get schedulingApi() : SchedulingApiService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._schedulingApi)
			this._schedulingApi = this.injector.get(SchedulingApiService);

		return this._schedulingApi;
	}

	/**
	 * The global `AffectedShiftsApiService`.
	 */
	public get affectedShiftsApi() : AffectedShiftsApiService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._affectedShiftsApi)
			this._affectedShiftsApi = this.injector.get(AffectedShiftsApiService);

		return this._affectedShiftsApi;
	}

	/**
	 * The global `PDatePipe`.
	 */
	public get datePipe() : PDatePipe {
		// To avoid circular dependencies we use lazy initialization
		if (!this._datePipe)
			this._datePipe = this.injector.get(PDatePipe);

		return this._datePipe;
	}

	/**
	 * The global `PercentPipe`.
	 */
	public get percentPipe() : PercentPipe {
		// To avoid circular dependencies we use lazy initialization
		if (!this._percentPipe)
			this._percentPipe = this.injector.get(PercentPipe);

		return this._percentPipe;
	}

	/**
	 * The global `PFingerprintService`.
	 */
	public get fingerprintService() : PFingerprintService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._fingerprintService)
			this._fingerprintService = this.injector.get(PFingerprintService);

		return this._fingerprintService;
	}

	/**
	 * Enables/Disables mock-mode.
	 * @param mockMode Set "true" to enable mock-mode. In mock-mode all backend calls will silently be ignored.
	 * Furthermore, when activated it ensures that some empty data are set
	 * (and optionally default values when "tsDefaultValue" is used in api-generator).
	 */
	public enableMockMode(mockMode : boolean) : void {
		this.mockMode = mockMode;

		if (this.mockMode && !this.isLoaded())
			this.setEmptyData();
	}

	/**
	 * Returns the search params of the last load() call.
	 */
	public getLastLoadSearchParams() : HttpParams | null {
		return this.lastExecutedLoadSearchParams;
	}

	/**
	 * @returns The search params of the currently loaded data. See `lastExecutedLoadSearchParams` for further information.
	 */
	public getCurrentDataSearchParams() : HttpParams | null {
		return this.currentDataSearchParams;
	}

	/**
	 * @returns Returns the value of the load-param `key`.
	 * If api has not been loaded or no such key exists then `null` is returned.
	 */
	public getCurrentDataSearchParamValue(key : string) : string | null {
		if (this.currentDataSearchParams === null)
			return null;

		return this.currentDataSearchParams.get(key);
	}

	/**
	 * Removes item with key `paramToRemove` from `lastExecutedLoadSearchParams`.
	 */
	public removeParamFromLastLoadSearchParams(paramToRemove : string) : void {
		if (this.lastExecutedLoadSearchParams)
			this.lastExecutedLoadSearchParams = this.lastExecutedLoadSearchParams.delete(paramToRemove);
	}

	/**
	 * Is a backend api operation (load, save, post) running now?
	 */
	public get isBackendOperationRunning() : boolean {
		return 	this.runningLoadOperationCount > 0 	||
			this.runningSaveOperationCount > 0	||
			this.runningPostOperationCount > 0;
	}

	/**
	 * Is a backend load operation running now?
	 */
	public get isLoadOperationRunning() : boolean {
		return this.runningLoadOperationCount > 0;
	}

	/**
	 * Is a backend save operation running now?
	 */
	public get isSaveOperationRunning() : boolean {
		return this.runningSaveOperationCount > 0;
	}

	private getUrl(searchParams : HttpParams | null) : string {
		let result = this.apiUrl;

		// add search params
		result += '?';

		if (searchParams) {
			for (const key of Array.from(searchParams.keys())) {
				const paramValue = searchParams.get(key)!;
				result += `${key}=${paramValue}&`;
			}
		}

		// to easier be able to link network calls to tests we add test name as query parameter
		const testNameQueryParamValue = ApiBase.getTestNameQueryParam();
		if (testNameQueryParamValue)
			result += `testName=${ testNameQueryParamValue }&`;

		// add api version
		result += `v=${ this.version()}`;

		return result;
	}

	private static getTestNameQueryParam() : string | null {
		const specName : string | null = (window as any).currentSpecName;

		// somehow the url is cut off when there is a '#'. So, we remove them.
		return specName ? encodeURI(specName.replace('#', '')) : null;
	}

	/**
	 * @returns Returns merged result of "params1" and "params2". Both these parameters can
	 * be "null".
	 */
	private mergeHttpParams(params1 : HttpParams | null, params2 : HttpParams | null) : HttpParams {
		// start with values of params1
		let result = params1 ?? new HttpParams();

		// add values of params2
		if (params2) {
			for (const key of params2.keys()) {
				const paramValue = params2.get(key)!;
				result = result.set(key, paramValue);
			}
		}

		return result;
	}

	/* eslint-disable jsdoc/check-param-names -- Remove this before you work here. */
	/**
	 * Downloads a file assuming to be delivered by the GET method of this api.
	 * @param fileName File-name which will be used to save the delivered file.
	 * @param searchParams Additional search-params passed to the called GET method.
	 */
	public downloadFile(
		fileName : string,
		fileEnding : string,
		searchParams : HttpParams | null,
		httpMethod : 'GET' | 'PUT' | 'POST' = 'GET',
		success ?: () => void,
	) : void {
		// make fileName safe to use as file-name
		fileName = fileName.toLowerCase();
		fileName = fileName.replace('ä', 'ae');
		fileName = fileName.replace('ö', 'oe');
		fileName = fileName.replace('ü', 'ue');
		fileName = fileName.replace('ß', 'ss');
		fileName = fileName.replace(/[^\da-z]/gi, '_');

		// We cannot trigger browsers download process by calling ajax function. So, instead we get the file content
		// and download it manually by creating a "blob" object.
		// Code is copied from https://stackoverflow.com/a/23797348/3545274 with some modifications.
		const xhr = new XMLHttpRequest();
		xhr.open(httpMethod, this.getUrl(searchParams), true);
		xhr.responseType = 'arraybuffer';

		if (Config.HTTP_AUTH_CODE)
			xhr.setRequestHeader('Authorization', Config.HTTP_AUTH_CODE);
		xhr.addEventListener('load', () => {
			if (xhr.status === 200) {
				// make company name safe to use as file-name
				const type = xhr.getResponseHeader('Content-Type')!;

				// download file (copied from https://github.com/mholt/PapaParse/issues/175)
				const blob = new Blob([xhr.response], { type: type });
				const microsoftWindowNavigator = window.navigator as any as {
					msSaveOrOpenBlob : any,
					msSaveBlob : ((blob : Blob, fileName : string) => void) | undefined,
				};
				if (microsoftWindowNavigator.msSaveOrOpenBlob && microsoftWindowNavigator.msSaveBlob) { // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
					microsoftWindowNavigator.msSaveBlob(blob, fileName);
				} else {
					const a = window.document.createElement('a');
					a.href = window.URL.createObjectURL(blob);
					a.download = `${fileName }.${ fileEnding}`;
					document.body.appendChild(a);

					// IE: "Access is denied"; see:
					// https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
					a.click();

					document.body.removeChild(a);
				}
				if (success) {
					success();
				}
			} else {
				const httpErrorResponse = new HttpErrorResponse({
					status: xhr.status,
					statusText: xhr.statusText,
					url: xhr.responseURL,
				});

				this.onError(null, httpErrorResponse);
			}
		});

		xhr.setRequestHeader('Content-type', (httpMethod !== 'GET' ? 	'application/json;charset=utf-8' 	:
			'application/x-www-form-urlencoded'));
		xhr.send(httpMethod !== 'GET' ? JSON.stringify(this.dataStack.getCurrent()) : undefined);
	}
	/* eslint-enable jsdoc/check-param-names */

	/**
	 * Set empty api data. This is for example useful when the api does not support "load()" operation
	 * and so we need other means to fill the api with data.
	 */
	public setEmptyData() : void {
		// remove old data
		this.dataStack.clear();
		this.dataStack.onLoadResponse([]);

		// recreate data
		this.recreateRootWrapper();
		this.dataStack.onLoadResponse(this.getRootWrapper().rawData);
	}

	/**
	 * @returns Does this api currently has a data copy()?
	 */
	public hasDataCopy() : boolean {
		return this.dataStack.hasCopy();
	}

	/**
	 * Creates a working copy of the data. All data wrappers will now point on this new copy.
	 * Note that this working copy will be ignored for backend operations. To save changes
	 * done to this working copy to backend you must first merge these changes (by calling mergeDataCopy())
	 * and then call save().
	 */
	public createDataCopy() : void {
		this.dataStack.createCopy();

		// inform all frontend-attributes
		this.executeForAllDataCopyAttributes((attribute) => attribute.createDataCopy());
	}

	/**
	 * Dismisses any changes done to the working copy.
	 */
	public dismissDataCopy() : void {
		this.dataStack.dismissCopy();

		// inform all frontend-attributes
		this.executeForAllDataCopyAttributes((attribute) => attribute.dismissDataCopy());
	}

	/**
	 * Merges any changes done to the working copy with the current data.
	 */
	public mergeDataCopy() : void {
		this.dataStack.mergeCopy();

		// inform all frontend-attributes
		this.executeForAllDataCopyAttributes((attribute) => attribute.mergeDataCopy());
	}

	private executeForAllDataCopyAttributes(executeCode : (apiDataCopyAttribute : ApiDataCopyAttribute<unknown>) => void) : void {
		this.executeForAllApiNodes(
			this.data,
			(
_parentDataWrapper : ApiDataWrapperBase,
			attributeInfo : ApiAttributeInfo<any, any>,
			) => {
				// execute code for all child data-copy attributes
				if (attributeInfo.isWrapper) {
					const objectWrapper = attributeInfo.apiObjWrapper;

					// eslint-disable-next-line no-restricted-syntax
					for (const name in objectWrapper) {
						const attribute = objectWrapper[name];

						if (attribute instanceof ApiDataCopyAttribute)
							executeCode(attribute);
					}
				}
			},
		);
	}

	/**
	 * @returns Has the data copy changed compared to original data?
	 */
	public hasDataCopyChanged() : boolean {
		this.ensureRawDataIsUpToDate();
		return this.dataStack.hasCopyChanged();
	}

	/**
	 * @returns Does the api have unsaved changes?
	 */
	public hasChanges() : boolean {
		this.ensureRawDataIsUpToDate();
		return this.dataStack.hasTopChanged();
	}

	/**
	 * Loads the api with the previous search params.
	 */
	public async reload({success = null, error = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return this.load({success: success, error: error, searchParams: this.getLastLoadSearchParams() });
	}

	/* eslint-disable jsdoc/check-param-names */
	/**
	 * Loads api data from backend. This method loads a piece of data depending on the search params.
	 * These piece of data can be modified then by calling the save() method.
	 * Note that this load() method will discard all unsaved api data changes.
	 * This include changes done before and during the load operation. This is needed
	 * because a load operation can get data which has no relationship with the old data (because of new search params).
	 * Thus, before the load operation is executed all old data will be unloaded to prevent user
	 * from accessing/modifying the data during the operation.
	 * @param success Success handler.
	 * @param error Error handler.
	 * @param paramData Parameters to be passed to the api.
	 */
	public async load({success = null, error = null, searchParams = new HttpParams()} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		if (this.mockMode) {
			// store search-params
			this.currentDataSearchParams = searchParams;
			this.lastExecutedLoadSearchParams = searchParams;

			// generate missing data based on new search-params (e.g. detailed-fields)
			const root = this.getRootWrapper();
			root._updateRawData(root.rawData, true);

			// return
			const result = new HttpResponse();

			if (success)
				success(result);

			// eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
			return Promise.resolve(result);
		}

		if (this.isLoadOperationRunning && Config.APPLICATION_MODE !== 'TEST')
			this.console.warn(`There are overlapping load() calls for api »${this.apiPath}«. Is this intended?`);

		++this.lastLoadOperationId;
		++this.runningLoadOperationCount;
		this.lastExecutedLoadSearchParams = searchParams;

		// execute
		const loadOperationId = this.lastLoadOperationId;
		this.zone.runOutsideAngular(() => {
			this.onDataLoadStart.next();
		});
		return firstValueFrom(this.http.get<any[] | undefined>(this.apiUrl, this.getRequestOptions(this.lastExecutedLoadSearchParams)))
			.then((response) => {
				const isLastLoadOperation = (loadOperationId === this.lastLoadOperationId);

				// When there are overlapping load operations only consider the results of the last load operation.
				// All previous loads will silently be ignored.
				if (isLastLoadOperation) {

					// reset currentlyDetailedLoaded.
					// dataStack.onLoadResponse() will set this with a new value if a detailed is loaded.
					this.currentlyDetailedLoaded = null;

					const isLoadedBefore = this.isLoaded();

					// update wrappers with new data
					const newData = response.body;
					this.dataStack.onLoadResponse(newData ?? null);

					// Now that the api data has been updated, update "currentDataSearchParams"
					this.currentDataSearchParams = searchParams;

					// call handlers
					this.zone.runOutsideAngular(() => {
						this.onDataLoaded.next();
						this.onBackendResponse.next();
					});

					if (success)
						success(response);

					// isLoaded promise fulfilled now?
					if (!isLoadedBefore) {
						this.isLoadedSubject.next();

						// all registered handlers should only be called once. So, reset the subject.
						// This will also prevent memory leaks
						this.isLoadedSubject = new Subject<void>();
					}
				}

				--this.runningLoadOperationCount;

				// check for show consistency.
				// Currently, this test does not work when local changes to the backend response exist as
				// any changing "show" values based on the local changes are not automatically updated in the raw-data
				// (i.e. setting the dependent values to undefined or initialize them with
				// default values). So, skip the debug test in that case.
				if (Config.DEBUG && isLastLoadOperation && !this.dataStack.hasTopChanged())
					this.checkShowLogicOfBackendAndFrontendAreConsistent();

				return response;
			})
			// eslint-disable-next-line unicorn/catch-error-name
			.catch((errorResponse : HttpResponse<any>) => {
				--this.runningLoadOperationCount;
				this.onError(error, errorResponse);

				return errorResponse;
			});
	}
	/* eslint-enable jsdoc/check-param-names */

	/**
	 * Current implementation does not automatically set `undefined` in raw-data when `isAvailable` becomes false.
	 * So, when doing operations solely based on raw-data we can call this to ensure raw-data will have
	 * `undefined` where needed.
	 *
	 * See also `checkShowLogicOfBackendAndFrontendAreConsistent()`.
	 */
	private ensureRawDataIsUpToDate() : void {
		this.executeForAllApiNodes(
			this.data,
			(parentDataWrapper : ApiDataWrapperBase, attributeInfo : ApiAttributeInfo<any, unknown>) => {
				const rawDataIndex = attributeInfo.rawDataIndex;

				if (parentDataWrapper.rawData && rawDataIndex &&
					parentDataWrapper.rawData[rawDataIndex] !== undefined &&
					attributeInfo.isAvailable === false
				) {
					parentDataWrapper.rawData[rawDataIndex] = undefined;
				}
			},
		);
	}

	/**
	 * Executes `executeCode` for all api-nodes (i.e. lists, objects, primitives) which are descendants of `root`.
	 */
	private executeForAllApiNodes(
root : ApiDataWrapperBase,
		executeCode : (parentDataWrapper : ApiDataWrapperBase, attributeInfo : ApiAttributeInfo<any, unknown>) => void,
	) : void {
		// execute for all primitive children
		for (const primitiveChildName of root.getChildPrimitiveNames()) {
			const attributeInfo = root.getAttributeInfo(primitiveChildName);
			executeCode(root, attributeInfo);
		}

		// continue recursively
		if (root instanceof ApiListWrapper) {
			for (const child of root.iterable()) {
				if (child instanceof ApiDataWrapperBase) {
					executeCode(root, child.attributeInfoThis);

					this.executeForAllApiNodes(child, executeCode);
				}
			}
		} else {
			for (const wrapperChildName of root.getChildWrapperNames()) {
				const attributeInfo = root.getAttributeInfo(wrapperChildName);

				if (attributeInfo.isWrapper) {
					executeCode(root, attributeInfo);

					// dont continue further if that wrapper is not available.
					if (attributeInfo.isAvailable)
						this.executeForAllApiNodes(attributeInfo.apiObjWrapper, executeCode);
				}
			}
		}
	}

	/**
	 * When api sends `undefined` it indicates that for backend we have `isAvailable === false`.
	 * Vice versa when value not equal `undefined`
	 * is sent then for backend we have `isAvailable === true`.
	 * So, we check that frontend logic also comes to the same result. This consistency is very important
	 * because depending on `isAvailable` frontend initializes an attribute with a default value or resets it to `undefined`.
	 * So, when there would be a discrepancy, after load() data would immediately get automatically modified.
	 *
	 * See also `ensureRawDataIsUpToDate()`.
	 */
	private checkShowLogicOfBackendAndFrontendAreConsistent() : void {
		this.executeForAllApiNodes(
			this.data,
			(parentDataWrapper : ApiDataWrapperBase, attributeInfo : ApiAttributeInfo<any, unknown>) => {
				const rawDataIndex = attributeInfo.rawDataIndex;

				if (parentDataWrapper.rawData && rawDataIndex) {
					if (attributeInfo.isAvailable === true && parentDataWrapper.rawData[rawDataIndex] === undefined)
						this.console.error(`Backend and frontend don't have the same isAvailable-logic for ${attributeInfo.id}. Backend did not send a value although attributeInfo.isAvailable is ${attributeInfo.isAvailable}`);

					if (attributeInfo.isAvailable === false && parentDataWrapper.rawData[rawDataIndex] !== undefined) {
						this.console.error(`Backend and frontend don't have the same isAvailable-logic for ${attributeInfo.id}. Backend send a value although attributeInfo.isAvailable is ${attributeInfo.isAvailable}.`);
					}
				}
			},
		);
	}

	/**
	 * Saves the api data to backend. Any changes done during this save operation are not lost
	 * but will be merged to the backend response. To enable this we must ensure that
	 * the returned data is the same "piece" of data as we were saving. Thus, no search params
	 * are allowed for this operation but the search params of last load() operation will be adopted.
	 *
	 * @returns Returns "false" when no save was done because no changes were found. Otherwise "true" is returned.
	 */
	public async save({
		success = null,
		error = null,
		additionalSearchParams = null,
		saveEmptyData = false,
		sendRootMetaOnEmptyData = false,
		onlySavePath = null,
	} : ApiSaveArgs = {}) : Promise<HttpResponse<unknown>> {
		if (this.mockMode) {
			const result = new HttpResponse();

			if (success)
				success(result, NOT_CHANGED);

			return result;
		}

		// we need data to do any saving
		if (!this.isLoaded())
			throw new Error('You cannot call save() when api is not loaded.');

		// get data to save
		this.ensureRawDataIsUpToDate();
		let dataToSave = this.dataStack.getDataToSave(onlySavePath);

		// cancel saving?
		if (dataToSave === NOT_CHANGED && !saveEmptyData) {
			// When there is nothing to save is still success
			if (success)
				success.call(this, null, NOT_CHANGED);

			return new HttpResponse();
		}

		// forced send of root meta?
		if (dataToSave === NOT_CHANGED && sendRootMetaOnEmptyData) {
			dataToSave = [ this.dataStack.getCurrent()![0] ];
		}

		//
		// 	Async save
		//
		++this.lastSaveOperationId;
		++this.runningSaveOperationCount;

		try {
			const executedForLoadOperationId = this.lastLoadOperationId;
			const saveOperationId = this.lastSaveOperationId;
			this.dataStack.onSaveOperation(dataToSave);

			const searchParams = this.mergeHttpParams(this.lastExecutedLoadSearchParams, additionalSearchParams);

			this.zone.runOutsideAngular(() => {
				this.onDataSaveStart.next(dataToSave);
			});

			const response = await firstValueFrom(this.http.put<any[] | undefined>(this.apiUrl, dataToSave, this.getRequestOptions(searchParams)));
			--this.runningSaveOperationCount;
			if (this.isLoaded()) {
				const newData = response.body;

				const isForLastLoadOperation = (executedForLoadOperationId === this.lastLoadOperationId);
				const isForLastSaveResponse = (saveOperationId === this.lastSaveOperationId);
				const isForLastApiCall = isForLastLoadOperation && isForLastSaveResponse;

				const apiDataUpdated = this.dataStack.onSaveResponse(newData ?? null, isForLastApiCall);

				// When api-data is not updated then "schedulingApi.data.messages" is swallowed,
				// as those values are only sent in this specific api call
				// and later calls will not return the values anymore.
				// So, we make sure that they are always visible by manually copying them.
				// What could be a better solution?
				const dataStackCurrent = this.dataStack.stack[Stack.CURRENT];

				if (!apiDataUpdated && newData && this instanceof SchedulingApiService && dataStackCurrent) {
					const rawDataIndex = this.consts.MESSAGES;

					dataStackCurrent[rawDataIndex] = structuredClone(newData[rawDataIndex]);
					this.data.messages._updateRawData(dataStackCurrent[rawDataIndex], false);
				}
			}

			// call handlers
			this.zone.runOutsideAngular(() => {
				this.onBackendResponse.next();
			});

			if (success)
				success(response, dataToSave);

			return response;
		} catch (error_) {
			--this.runningSaveOperationCount;
			this.onError(error, error_);
			return error_ as HttpResponse<unknown>;
		}
	}

	/**
	 * Executes a post operation. This operation is completely independent of the load/save operations.
	 * It does not expect a response from backend. So, it will also not override the data depending on
	 * any backend response.
	 */
	public async post({
		success = null,
		error = null,
		searchParams = null,
	} : ApiPostArgs = {}) : Promise<HttpResponse<unknown>> {
		if (this.mockMode) {
			const result = new HttpResponse();

			if (success)
				success(result);

			// eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
			return Promise.resolve(result);
		}

		// we need data to do any saving
		if (!this.isLoaded())
			throw new Error('You cannot call post() when api is not loaded.');

		// get data to save
		this.ensureRawDataIsUpToDate();
		const dataToSave = this.dataStack.getCurrent();

		//
		// 	Async post
		//
		++this.runningPostOperationCount;

		return firstValueFrom(this.http.post(this.getUrl(searchParams), dataToSave, this.getRequestOptions(this.lastExecutedLoadSearchParams)))
			.then((response : HttpResponse<any>) => {
				--this.runningPostOperationCount;

				// callback...
				if (success)
					success(response);

				return response;
			})
			// eslint-disable-next-line unicorn/catch-error-name
			.catch((errorResponse : HttpResponse<any>) => {
				--this.runningPostOperationCount;
				this.onError(error, errorResponse);

				return errorResponse;
			});
	}

	/**
	 * Handler being called on api error response.
	 */
	protected onError(handler : ApiErrorHandler | null, response : any) : void {
		// we are not interested in errors which are not concerned api
		if (!errorUtils.isTypeHttpErrorResponse(response))
			throw response;

		this.dataStack.onBackendError();
		this.apiError.error.next(response);

		if (handler)
			handler(response);
		else if (response.status !== 401)
			throw response;
	}

	/**
	 * @param success Handler being called when api is successfully loaded.
	 * @returns {Boolean} any api data is loaded
	 * @deprecated Please use PAttributeInfo.show() instead. This way, you can check if specific parts of the data are available.
	 */
	public isLoaded(success ?: () => void) : boolean {
		const isLoaded = this.dataStack.isDataLoaded();

		if (success) {
			if (isLoaded)
				success();
			else

				// TODO: We recreate isLoadedSubject to ensure that subscribers are only called once and to avoid memory leaks.
				// Think there should be a better solution for that ;)
				// eslint-disable-next-line rxjs/no-ignored-subscription
				this.isLoadedSubject.subscribe(success);
		}

		return isLoaded;
	}

	/**
	 * @param searchParams The desired query-parameters.
	 * @returns The api request-options to be used for an api call. This adds generic information live api-version and
	 * authentication data.
	 */
	protected getRequestOptions(searchParams : HttpParams | null) : {
		headers : HttpHeaders,
		params : HttpParams,
		observe : 'response',
	} {
		return ApiBase.getRequestOptions(searchParams, this.version());
	}

	/**
	 * @returns Returns the request-options to be passed to Angular´s `HttpClient`.
	 */
	public static getRequestOptions(searchParams : HttpParams | null, apiVersion : string | null) : {
		headers : HttpHeaders,
		params : HttpParams,
		observe : 'response',
	} {
		// headers
		let headers = new HttpHeaders(
			{
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'Content-Type': 'application/json; charset=utf-8',
			},
		);

		if (Config.HTTP_AUTH_CODE)
			headers = headers.set('Authorization', Config.HTTP_AUTH_CODE);

		// search params
		if (!searchParams)
			searchParams = new HttpParams();

		if (apiVersion)
			searchParams = searchParams.set('v', apiVersion);

		// to easier be able to link network calls to tests we add test name as query parameter
		const testNameQueryParamValue = ApiBase.getTestNameQueryParam();
		if (testNameQueryParamValue)
			searchParams = searchParams.set('testName', testNameQueryParamValue);

		// return result
		return {
			headers: headers,
			params: searchParams,
			observe: 'response',
		};
	}

	/**
	 * Has this api been called in context of our karma tests?
	 */
	public get isTestingApiCall() : boolean {
		return !!ApiBase.getTestNameQueryParam();
	}

	/**
	 * Unload all currently loaded api data.
	 */
	public unload() : void {
		this.dataStack.clear();
		this.lastExecutedLoadSearchParams = null;
		this.currentlyDetailedLoaded = null;
	}

	protected abstract getRootWrapper() : ApiDataWrapperBase;

	/**
	 * Recreates the root wrapper. By using the default constructor all the default values will be set.
	 */
	protected abstract recreateRootWrapper() : void;
}
