import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

/**
 * @deprecated remove the darf/macht stuff from this component or use another one.
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'list-headline',
	templateUrl: './list-headline.component.html',
	styleUrls: ['./list-headline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListHeadlineComponent {
	/**
	 * Text that will be rendered inside the list-headline
	 */
	@Input() public text : string | TemplateRef<unknown> = '';

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public textTooltipHtml : string | undefined = undefined;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showMembersHeadlineCardOptions : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showHearts : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public headlineIconAlign : 'left' | null = null;
	@Input() public theme : ExtractFromUnion<'danger', PThemeEnum> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick : EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	/**
	 * Is there a (onClick)="…" on this component?
	 */
	public get hasOnClickBinding() : boolean {
		return this.onClick.observers.length > 0;
	}

	/**
	 * Get the text provided from outside as string
	 */
	public get textString() : string | null {
		return typeof this.text === 'string' ? this.text : null;
	}

	/**
	 * Get the text provided from outside as a template
	 */
	public get textTemplate() : TemplateRef<unknown> | null {
		return typeof this.text !== 'string' ? this.text : null;
	}
}
