import { Directive, HostBinding, Input } from '@angular/core';

/** Handles the container classes that are available in our app */
@Directive({
	selector: '[pContainer]',
})
export class PContainerDirective {
	/** The maximum size of the container */
	@Input() public pContainer : 'default' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | null = 'default';

	/** Add class .pt-0 */
	@HostBinding('class.pt-0') public get pt0() : boolean { return true; }

	/** Bind css class .container */
	@HostBinding('class.container') public get container() : boolean {
		return this.pContainer === 'default';
	}

	/** Bind css class .container-xs */
	@HostBinding('class.container-xs') public get containerXs() : boolean {
		return this.pContainer === 'xs';
	}

	/** Bind css class .container-sm */
	@HostBinding('class.container-sm') public get containerSm() : boolean {
		return this.pContainer === 'sm';
	}

	/** Bind css class .container-md */
	@HostBinding('class.container-md') public get containerMd() : boolean {
		return this.pContainer === 'md';
	}

	/** Bind css class .container-lg */
	@HostBinding('class.container-lg') public get containerLg() : boolean {
		return this.pContainer === 'lg';
	}

	/** Bind css class .container-xl */
	@HostBinding('class.container-xl') public get containerXl() : boolean {
		return this.pContainer === 'xl';
	}
}
