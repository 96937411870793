import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { AngularDatePipeFormat, PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';

type PDurationPipeFormats = AngularDatePipeFormat.SHORT_TIME | 'shortTime';

@Pipe({ name: 'pDuration' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PDurationPipe implements PipeTransform {
	constructor(
		@Inject(LOCALE_ID) private locale : PSupportedLocaleIds,
		private datePipe : PDatePipe,
	) {}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public transform(duration : number, format ?: PDurationPipeFormats) : string | null {
		if (!format) format = AngularDatePipeFormat.SHORT_TIME;
		return this.datePipe.transform(duration, format, true, this.locale);
	}
}
