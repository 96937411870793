import { PopoverConfig } from 'ngx-bootstrap/popover';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';

// eslint-disable-next-line jsdoc/require-jsdoc
export function getPopoverConfig() : PopoverConfig {
	return Object.assign(new PopoverConfig(), {
		container: 'body',
		triggers: 'mouseenter:mouseleave',
	});
}

// eslint-disable-next-line jsdoc/require-jsdoc
export function getTooltipConfig() : TooltipConfig {
	return Object.assign(new TooltipConfig(), {
		container: 'body',
		triggers: 'mouseenter:mouseleave',
	});
}
