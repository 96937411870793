import { Injectable } from '@angular/core';
import { SchedulingApiCourseType, SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftService {

	/**
	 * Calculate color
	 */
	public participantsCountTheme(
		{
			isCourseCanceled,
			maxCourseParticipantCount,
			currentCourseParticipantCount,
			minCourseParticipantCount,
		} : Pick<SchedulingApiShift, 'isCourseCanceled' | 'maxCourseParticipantCount' | 'currentCourseParticipantCount' | 'minCourseParticipantCount'>,
		{
			courseType,
			onlyWholeCourseBookable,
		} : Pick<SchedulingApiShiftModel, 'courseType' | 'onlyWholeCourseBookable'>,
	) :
	typeof enumsObject.PThemeEnum.SUCCESS |
	typeof enumsObject.PThemeEnum.DANGER |
	typeof enumsObject.PThemeEnum.WARNING {
		if (courseType === SchedulingApiCourseType.NO_BOOKING) return enumsObject.PThemeEnum.SUCCESS;
		if (isCourseCanceled) return enumsObject.PThemeEnum.DANGER;

		if (
			maxCourseParticipantCount !== null &&
			currentCourseParticipantCount > maxCourseParticipantCount
		) {
			// Is over max
			return enumsObject.PThemeEnum.DANGER;
		}

		// fully booked but not over max
		if (this.isCourseFullyBooked({
			maxCourseParticipantCount: maxCourseParticipantCount,
			currentCourseParticipantCount: currentCourseParticipantCount,
			onlyWholeCourseBookable: onlyWholeCourseBookable,
		})) return enumsObject.PThemeEnum.SUCCESS;

		if (maxCourseParticipantCount === null && currentCourseParticipantCount < minCourseParticipantCount) {
			// No max and currentCourseParticipantCount under min.
			return enumsObject.PThemeEnum.DANGER;
		}

		// Is under min
		if (currentCourseParticipantCount < minCourseParticipantCount) return enumsObject.PThemeEnum.DANGER;

		// Is exactly max
		if (currentCourseParticipantCount === maxCourseParticipantCount) return enumsObject.PThemeEnum.SUCCESS;

		// Other
		return enumsObject.PThemeEnum.WARNING;
	}

	private isCourseFullyBooked(
		{
			maxCourseParticipantCount,
			currentCourseParticipantCount,
			onlyWholeCourseBookable,
		} : {
			maxCourseParticipantCount : SchedulingApiShift['maxCourseParticipantCount'],
			currentCourseParticipantCount : SchedulingApiShift['currentCourseParticipantCount'],
			onlyWholeCourseBookable : SchedulingApiShiftModel['onlyWholeCourseBookable'],
		},
	) : boolean {
		if (currentCourseParticipantCount > 0 && onlyWholeCourseBookable) return true;
		if (
			maxCourseParticipantCount &&
			currentCourseParticipantCount >= maxCourseParticipantCount
		) return true;
		return false;
	}
}
