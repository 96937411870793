import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { TimeStampApiService, TimeStampApiStampedMembers } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';

@Component({
	selector: 'p-stamped-members',
	templateUrl: './stamped-members.component.html',
	styleUrls: ['./stamped-members.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class StampedMembersComponent implements OnDestroy {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public members ! : TimeStampApiStampedMembers;

	constructor(public api : TimeStampApiService) {
		// don’t start automatic api update for tests to prevent 401 errors.
		// Because there we cannot know who is currently logged in.
		if (Config.APPLICATION_MODE !== 'TEST') this.setUpdateInterval(120000);
	}

	private interval : number | null = null;

	public ngOnDestroy() : void {
		window.clearInterval(this.interval ?? undefined);
	}

	private setUpdateInterval(intervalDuration : number) : void {
		this.interval = window.setInterval(() => {
			if (!this.api.isLoaded()) return;

			/*
			 * If the user is about to edit e.g. the start or end,
			 * we immediately write the new values to the AI
			 * Thus a api.save(…) would save the data before the
			 * user confirms the date-picker modal
			 */
			if (this.api.hasDataCopy()) return;

			/*
			 * We do a save instead of a load here, because
			 * it sends the id of the currently running workingtime
			 * tracking, so the backend know which date it has to
			 * send back when it responds.
			 */
			void this.api.save({
				saveEmptyData: true,
				sendRootMetaOnEmptyData: true,
			});
		}, intervalDuration);
	}
}
