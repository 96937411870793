import { Injectable } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { SchedulingApiService } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Injectable({providedIn: 'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PreventNavigationService {
	constructor(
        private pRouter : PRouterService,
        private toastsService : ToastsService,
        private localize : LocalizePipe,
        private api : SchedulingApiService,
	) {

	}

	/**
     * Method to check if the current user can visit a given page based on a passed AI,
     * if not, the user is navigated to the calendar and a toast is shown saying the user has no right
     * to visit that page.
     */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public async preventUserWithoutRightsInPageWithAI(attributeInfo : ApiAttributeInfo<any, any>) : Promise<boolean> {
		return new Promise((resolve) => {
			this.api.isLoaded(() => {
				const canAccess = attributeInfo.hasRightToSet;
				if (!canAccess) {
					// eslint-disable-next-line ban/ban -- intended navigation
					void this.pRouter.navigate(['/client/scheduling'], {replaceUrl: true});
					this.toastsService.addToast({
						theme: enumsObject.PThemeEnum.WARNING,
						icon: enumsObject.PlanoFaIconPool.NO_ACCESS,
						title: this.localize.transform('Kein Zugang'),
						content: this.localize.transform('Dir fehlt die Berechtigung für die aufgerufene Seite. Wende dich bitte an einen Admin.'),
						visibilityDuration: 'infinite',
					});

					resolve(false);
				} else resolve(true);
			});
		});
	}
}
