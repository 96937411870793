import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalDismissParam, ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PPossibleErrorNames } from '@plano/shared/core/validators.types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { PInputImageCropperComponent } from './input-image-cropper/input-image-cropper.component';

type ValueType = string;

/**
 * This component is for images in forms.
 * To use it you need to bind a PFormControl to [formControl] which MUST have all validators with the names image*.
 * The initial value of the control can be a url to an image.
 * The value that gets set by user interaction will always be a base64 string.
 *
 * @example
 * 	<p-input-image
 * 		[formControl]="someFormControl"
 * 	></p-input-image>
 * @example
 * 	<p-input-image
 * 		[pEditable]="true"
 * 		[api]="api"
 * 		[formControl]="pFormsService.getByAI(formGroup, api.data.attributeInfoCompanyLogo)"
 * 	></p-input-image>
 */
@Component({
	selector: 'p-input-image',
	templateUrl: './input-image.component.html',
	styleUrls: ['./input-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputImageComponent),
			multi: true,
		},
	],
})
export class PInputImageComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterContentInit, EditableControlInterface, PFormControlComponentInterface {

	/**
	 * Should the image be shown to the user?
	 */
	@Input() public showPreview : boolean = true;

	// NOTE: I think this should not be necessary
	@Input() public override saveChangesHook ?: EditableControlInterface['saveChangesHook'];

	/**
	 * Template for the preview of the image.
	 * If not set, a simple img tag will be shown.
	 */
	@Input() public previewTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public imageUploadTemplate : TemplateRef<unknown> | null = null;

	@HostBinding('class.flex-grow-1') protected _alwaysTrue = true;

	@ViewChild('fileInput') public fileInput ?: ElementRef<HTMLInputElement>;

	// TODO: Obsolete?
	@ViewChild('modalContent', { static: true }) public modalContent ! : PModalTemplateDirective;
	@ViewChild('supportedFormatsDescriptions') public supportedFormatsDescriptions ! : TemplateRef<unknown>;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<Event> = new EventEmitter<Event>();

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public blur = new EventEmitter<FocusEvent>();

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to make [(ngModel)] and [formControl] work.
	 */
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}
	@Input('disabled') public override set disabled(input : boolean) {
		this._disabled = input;
		super.disabled = input;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	constructor(
		public element : ElementRef<HTMLElement>,
		protected override console : LogService,
		private localizePipe : LocalizePipe,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,
		private imageCompress : NgxImageCompressService,
		private modalService : ModalService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public readonly CONFIG = Config;

	public PBtnThemeEnum = PBtnThemeEnum;

	public enums = enumsObject;

	private modalServiceOptions : ModalServiceOptions & {
		success ?: (input : PInputImageCropperComponent) => void;
		dismiss ?: (keyEvent : ModalDismissParam) => void;
	} = {
			size: enumsObject.BootstrapSize.LG,
		};

	private previousValue : string | null = null;

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {

		if (this.saveChangesHook !== undefined) throw new Error('Not implemented yet');
		if (this.onSaveStart.observers.length) throw new Error('Not implemented yet');
		if (this.onDismiss.observers.length) throw new Error('Not implemented yet');
		if (this.onLeaveCurrent.observers.length) throw new Error('Not implemented yet');
		if (this.editMode.observers.length) throw new Error('Not implemented yet');
		if (this.checkTouched !== null) throw new Error('Not implemented yet');

		// TODO: [PLANO-53381]
		if (this.cannotSetHint) throw new Error('cannotSetHint not implemented yet in this component. See PLANO-53381');

		if (!this.control) throw new Error('Currently it is not possible to use image-upload without [formControl]. Please make sure the formControl has the image* validators.');

		this.previousValue = this.value;
		this.modalServiceOptions = {
			success: async (cropperRef : PInputImageCropperComponent) => {
				this.value = cropperRef.croppedImage;
				this.previousValue = this.value;

				// handle compress image
				const compressedImage = await this.imageCompress.compressFile(this.value!, DOC_ORIENTATION.Default, 100, 80);

				this.value = compressedImage;

				try {
					if (this.pEditable && this.api) this.api.mergeDataCopy();
					if (this.pEditable && this.api) {
						await this.api.save();
						this.onSaveSuccess.emit();
					}
					this.imageAsBlob = null;
					assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
					this.fileInput.nativeElement.value = '';
					this.showPreview = true;
				} catch (error) {
					this.console.error(error);
				}
			},
			dismiss: () => {
				if (this.pEditable && this.api) this.api.dismissDataCopy();
				this.value = this.previousValue;
				this.imageAsBlob = null;
				assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
				this.fileInput.nativeElement.value = '';
				this.fileChangeEvent();
				this.showPreview = true;
			},
			size: enumsObject.BootstrapSize.LG,
		};
		return super.ngAfterContentInit();
	}

	public _disabled : boolean = false;

	private _value : ValueType | null = null;
	public override _onChange : (value : ValueType | null) => void = () => {};

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void { this._onChange((event.target as HTMLInputElement).value); this.keyup.emit(event); }

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched(event);
		this.blur.emit(event);
	}

	/** Get change event from inside this component, and pass it on. */
	public onChange(event : Event) : void {
		this._onChange((event.target as HTMLInputElement).value);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = (_event : Event) : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (this._value === value) return;

		this._value = value;
		this.changeDetectorRef.markForCheck();

		// TODO: Still necessary? p-input don’t has this
		if (this.control) {
			this.control.markAsTouched();
			this.control.markAsDirty();
			this.control.updateValueAndValidity();
		}

		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		this._value = value ? value : '';
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled !== isDisabled) this.changeDetectorRef.markForCheck();
		this.disabled = isDisabled;
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		assumeNonNull(this.control);
		return this.pFormsService.visibleErrors(this.control);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public imageChangedEvent : any;

	/**
	 * Set some date for the cropper and open the cropper.
	 */
	public fileChangeEvent(event ?: Event, modalContent ?: TemplateRef<PModalTemplateDirective>) : void {
		this.imageChangedEvent = event;
		if ((!event || !(event.target as HTMLInputElement).files!.length) && (!this.imageAsBlob) || !modalContent) return;
		if (this.pEditable && this.api) this.api.createDataCopy();
		this.control!.updateValueAndValidity();

		void this.modalService.openModal<PInputImageCropperComponent>(modalContent, this.modalServiceOptions).result.then(value => {
			if (value.modalResult === 'success') {
				this.modalServiceOptions.success?.(value.value);
			} else {
				this.modalServiceOptions.dismiss?.(value.value);
			}
		});
	}

	/**
	 * Get image by url
	 */
	public async getImage(imageUrl : string, _success ?: () => void) : Promise<Response> {
		return fetch(imageUrl);
	}

	public imageAsBlob : Blob | null = null;

	/**
	 * Open modal with current image
	 */
	public async editImage() : Promise<unknown> {
		return this.getImage(this.value!)
			.then(async (result) => result.blob())
			.then((blob) => {
				this.imageAsBlob = blob;
				assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
				this.fileInput.nativeElement.dispatchEvent(new Event('change'));
			});
	}

	/**
	 * Alias to click the file
	 */
	public addImage() : void {
		this.fileInput!.nativeElement.click();
	}

	/**
	 * Clear the value
	 */
	public removeImage() : void {
		void this.modalService.openDefaultModal({
			modalTitle: this.localizePipe.transform('Sicher?'),
			description: this.localizePipe.transform('Willst du das aktuelle Bild wirklich löschen?'),
			closeBtnLabel: this.localizePipe.transform('Ja'),
			dismissBtnLabel: this.localizePipe.transform('Abbrechen'),
			hideDismissBtn: false,
		}, {
			centered: true,
			size: enumsObject.BootstrapSize.SM,
			theme: enumsObject.PThemeEnum.DANGER,
		}).result.then(async (value) => {
			if (value.modalResult === 'success') {
				this.value = null;
				this.previousValue = null;
				this.imageAsBlob = null;
				assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
				this.fileInput.nativeElement.value = '';
				if (this.pEditable && this.api) return this.api.save({success: ()=> {this.onSaveSuccess.emit();}});
			}
		});
	}

	/**
	 * Determine if the 'Add this image' button in the modal should be disabled.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public closeBtnDisabled(cropperRef : { imageMinHeightError : any; imageMinWidthError : any; }) : boolean {
		assumeNonNull(this.control);
		if (!this.control.valid) return true;
		if (cropperRef.imageMinHeightError) return true;
		if (cropperRef.imageMinWidthError) return true;
		return false;
	}

	/**
	 * Get min height from the data provided by backend.
	 */
	public get minHeight() : number {
		assumeNonNull(this.control);
		return this.control.asyncValidatorObjects[PPossibleErrorNames.IMAGE_MIN_HEIGHT]?.comparedConst as number;
	}

	/**
	 * Get min width from the data provided by backend.
	 */
	public get minWidth() : number {
		assumeNonNull(this.control);
		return this.control.asyncValidatorObjects[PPossibleErrorNames.IMAGE_MIN_WIDTH]?.comparedConst as number;
	}

	/**
	 * Get image ratio from the data provided by backend.
	 */
	public get imageRatio() : number {
		assumeNonNull(this.control);
		return this.control.validatorObjects[PPossibleErrorNames.IMAGE_RATIO]?.comparedConst as number;
	}
}
