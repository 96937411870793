<a
	[routerLink]="pRouterService.navBackInfo().url"
	(click)="pRouterService.forgetMostRecentHistoryEntry()"
	class="border-0 navigate-inside-app"
>
	<fa-icon
		class="h5 pl-3 pr-4 text-primary"
		[icon]="enums.PlanoFaIconPool.NAV_BACK"
	/></a
>
<h5
	[class.py-5]="!Config.IS_MOBILE"
	[class.m-0]="Config.IS_MOBILE"
	[class.py-3]="Config.IS_MOBILE"
	class="crop-on-overflow font-weight-bold"
>{{label ?? ('Zurück' | localize)}}</h5>


