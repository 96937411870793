/* eslint-disable max-lines -- Remove this before you work here. */
// eslint-disable-next-line no-warning-comments
/* eslint-disable @typescript-eslint/naming-convention */
import { PSupportedLanguageCodes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';

const L : Exclude<typeof PSupportedLanguageCodes, PSupportedLanguageCodes.de> = PSupportedLanguageCodes;
const DICTIONARY = {

	/**
	* Don’t remove this block. It gets used by our automated Tests
	*/
	'Hallo Welt': { [L.en]: 'Hello World' },
	'Hallo ${name}.': { [L.en]: '${name}, yo!' },
	'Hallo ${name}, ${name} ist ein schöner Name!': { [L.en]: 'Yo ${name}, ${name} is a wonderful name!' },
	'Im Deutschen wird ${shiftPluralisation} im Satz groß geschrieben.': { [L.en]: 'In english, ${shiftPluralisation} should be lowercase.' },
	'Im Deutschen wird der erste Buchstabe von ${firstName} immer groß geschrieben.': { [L.en]: 'In english the first letter of ${firstName} will always be uppercase.' },
	'${shiftPluralisation} wird am Anfang eines Satzes immer groß geschrieben.': { [L.en]: '${shiftPluralisation}, if placed at the beginning of a sentence, will always be uppercase.' },

	'Kontakt': {
		[L.en]: 'Contact',
	},

	'Link kopieren':{[L.en]:'Copy Link'},
	'Ausblenden': { [L.en]: 'Hide' },
	'Einblenden': { [L.en]: 'Show' },

	'${ageMin} – ${ageMax} Jahre': {[L.en]: '${ageMin} – ${ageMax} years old' },
	'${age} Jahre': {[L.en]: '${age} years old' },

	'Mo': { [L.en]: 'Mon' },
	'Di': { [L.en]: 'Tue' },
	'Mi': { [L.en]: 'Wed' },
	'Do': { [L.en]: 'Thu' },
	'Fr': { [L.en]: 'Fri' },
	'Sa': { [L.en]: 'Sat' },
	'So': { [L.en]: 'Sun' },
	'Montag': { [L.en]: 'Monday' },
	'Dienstag': { [L.en]: 'Tuesday' },
	'Mittwoch': { [L.en]: 'Wednesday' },
	'Donnerstag': { [L.en]: 'Thursday' },
	'Freitag': { [L.en]: 'Friday' },
	'Samstag': { [L.en]: 'Saturday' },
	'Sonntag': { [L.en]: 'Sunday' },

	'Jan': { [L.en]: 'Jan' },
	'Feb': { [L.en]: 'Feb' },
	'Mär': { [L.en]: 'Mar' },
	'Apr': { [L.en]: 'Apr' },
	'Mai': { [L.en]: 'May' },
	'Jun': { [L.en]: 'Jun' },
	'Jul': { [L.en]: 'Jul' },
	'Aug': { [L.en]: 'Aug' },
	'Sep': { [L.en]: 'Sep' },
	'Okt': { [L.en]: 'Oct' },
	'Nov': { [L.en]: 'Nov' },
	'Dez': { [L.en]: 'Dec' },

	'Browser': { [L.en]: 'Browser' },
	'App': { [L.en]: 'App' },

	'Die angefragte Schicht ist aktuell ausgeblendet. Ändere deine Filter-Einstellungen, falls du sie anzeigen möchtest.': { [L.en]: 'The requested shift is currently hidden. Please change your filter settings.' },
	'Manche der angefragten Schichten sind aktuell ausgeblendet. Ändere deine Filter-Einstellungen, falls du sie anzeigen möchtest.': { [L.en]: 'Some of the requested shifts are currently hidden. Change your filter settings if you want to see them.' },

	/**
	 * Pricing
	 */
	'ggf. Zusatzgebühren': { [L.en]: 'extra fees may apply' },

	/**
	 * mobile menu
	 */
	'Hauptmenü':{[L.en]: 'Main menu'},
	'Abmelden':{[L.en]: 'Log out'},

	/**
	 * Tooltip for birthdays
	 */
	'${name} hat am ${date} Geburtstag 🎈': { [L.en]: '${name}’s birthday is on ${date} 🎈' },

	/**
	 * tab names
	 */
	'Kalender':{[L.en]: 'Calendar'},
	'Auswertung':{[L.en]: 'Reports'},
	'Tutorial Videos':{[L.en]:'Tutorial Videos'},
	'Mein Profil':{[L.en]: 'My profile'},
	'Account & Standort':{[L.en]: 'Account & location'},
	'Benachrichtigungs-Einstellungen':{[L.en]:'Notification settings'},
	'Stempeluhr-Einstellungen':{[L.en]:'Time clock settings'},
	'Schreibtisch':{[L.en]:'Desk'},
	'Sidebar':{[L.en]: 'Sidebar'},
	'Dein Schreibtisch':{[L.en]:'Your desk'},
	'Tätigkeit':{[L.en]:'Activity'},
	'Tätigkeiten':{[L.en]:'Activities'},
	'Profil & Einstellungen': {[L.en]: 'Profile & settings'},
	'Ai Switch Showcase > default': {[L.en]: 'Ai Switch Showcase > default'},
	'Ai Switch Showcase > custom': {[L.en]: 'Ai Switch Showcase > custom'},
	'Ai Switch Showcase > editable': {[L.en]: 'Ai Switch Showcase > editable'},
	'Neuer User':{[L.en]:'New user'},
	'Neuer Arbeitseinsatz':{[L.en]:'Add worked hours'},
	'Benachrichtigungen':{[L.en]:'Notifications'},
	'Schichtwechsel':{[L.en]:'Shift swap'},
	'Neue Ersatzsuche':{[L.en]:'New swap request'},
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Echt-Account bestellen':{[L.en]:'Upgrade your plan'},

	// error modal
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Ich hatte einen Fehler in Dr. Plano': { [L.en]: 'I found an error on Dr. Plano' },
	'Folgende Fehlermeldung wurde mir angezeigt: ${error}': { [L.en]: 'The following error message was shown to me: ${error}' },

	/**
	 * no rights to access page message
	 */
	'Dir fehlt die Berechtigung für die aufgerufene Seite. Wende dich bitte an einen Admin.': { [L.en]: 'Sorry, you do not have access to that page. Please get in touch with your admins.' },

	/**
	 * scheduler.frontend.ng2/src/admin/clients/clients.component.ts
	 */
	'Neu': { [L.en]: 'New' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/seo.service.ts
	 */
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Dr. Plano – Online-Software für Schichtplanung & Kursbuchung in Kletterhallen, Boulderhallen und Sportstätten. Automatische Schichtverteilung, Arbeitszeiterfassung, Schichttausch, online Buchungssystem für Slots, Kurse & Gutscheine samt Online-Zahlung und automatischen Emails. Alles bequem auch über mobile Apps fürs Handy bedienbar.': { [L.en]: 'Dr. Plano – The all-in-one application for staff scheduling & class booking and online payment system. It comes along with a lot of features such as automated scheduling, time tracking, shift swapping; online booking system for classes, time-slots, and gift cards; online payment system with time-saving features like automated refunds to your customers after booking cancelations — all also from the convenience of your smartphone via mobile apps for iOS and Android.' },
	'Schichtplanung & Kursbuchung in Kletterhallen, Boulderhallen und Sportstätten': { [L.en]: 'Staff scheduling & class booking & online payment system for bouldering gyms and climbing gyms' },
	'Autom. Schichtverteilung, Arbeitszeiterfassung, Schichttausch, online Buchungssystem für Kurse & Gutscheine …': { [L.en]: 'Automated scheduling, Time tracking, Shift swapping, online booking system for classes & gift cards, online payment system…' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Dr. Plano – Software für Schichtplanung & Kursbuchung': { [L.en]: 'Dr. Plano – App for staff scheduling & class booking & online payment system' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Dr. Plano – Schichtplanung & Kursbuchung': { [L.en]: 'Dr. Plano – Staff scheduling & class booking & online payment system' },

	/**
	 * scheduler.frontend.ng2/src/client/absence/absence-routing.module.ts
	 */
	'Neue Abwesenheit': { [L.en]: 'New absence' },
	'Abwesenheit': { [L.en]: 'Absence' },

	/**
	 * scheduler.frontend.ng2/src/client/absence/absence.component.ts
	 */
	'Abwesenheitseintrag': { [L.en]: 'Leave of absence' },
	'Willst du diesen Abwesenheitseintrag wirklich löschen?': { [L.en]: 'Are you sure you want to delete this leave of absence?' },

	/**
	 * scheduler.frontend.ng2/src/client/accesscontrol/rights-array.ts
	 */
	'Abwesenheiten': { [L.en]: 'Absence entries' },
	'Eigene Abwesenheiten sehen': { [L.en]: 'See own absences' },
	'Abwesenheiten anderer sehen': { [L.en]: 'See someone else’s absences' },
	'Abwesenheiten editieren': { [L.en]: 'Edit absence entries' },
	'Neue Abwesenheiten erstellen': { [L.en]: 'Add absence entries' },
	'Schichtwünsche abgeben': { [L.en]: 'Submit shift preferences' },

	/**
	 * scheduler.frontend.ng2/src/client/accesscontrol/accesscontrol.component.ts
	 */
	'Änderung der Rechte wurde gespeichert': { [L.en]: 'Your changes have been saved' },

	/**
	 * scheduler.frontend.ng2/src/client/accesscontrol/rights-table/rights-table.component.ts
	 */
	'Die Gruppe hat Leserecht für Tätigkeiten.': { [L.en]: 'The group has read permission for activities.' },
	'Die Gruppe hat Schreibrecht für Tätigkeiten.': { [L.en]: 'The group has write permission for activities.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Die Gruppe bekommt von Dr. Plano die Nachrichten für Bereichsleitende.': { [L.en]: 'The group receives notifications for managers from Dr. Plano.' },
	'Die Gruppe darf Buchungen eines Angebots verwalten.': { [L.en]: 'The group has permission to manage bookings of an offer.' },
	'Die Gruppe darf Tätigkeiten ausführen. Das wird in den Tätigkeiten festgelegt.': { [L.en]: 'The group is allowed to do activities. That is set in the activities.' },
	'Die Gruppe darf Online-Rückerstattungen an Kunden veranlassen.': { [L.en]: 'The group is permitted to initiate online refunds to clients.' },
	'Darf': { [L.en]: 'Allowed' },

	/**
	 * scheduler.frontend.ng2/src/client/accesscontrol/rightgroup-header/rightgroup-header.component.ts
	 */
	'Bitte wählen': { [L.en]: 'Please select' },
	'Für wen ist ${name} gedacht?': { [L.en]: '${name} is meant for' },
	'sie': { [L.en]: 'It' },
	'Admins': { [L.en]: 'Admins' },
	'Mitarbeitende': { [L.en]: 'Employees' },

	/**
	 * scheduler.frontend.ng2/src/client/menu/settings-menu-for-mobile/settings-menu-for-mobile.component.ts
	 */
	'Möchtest du dich wirklich ausloggen?': { [L.en]: 'Are you sure you want to logout?' },

	/**
	 * scheduler.frontend.ng2/src/client/accesscontrol/rights-enums.ts
	 */
	'Tätigkeiten & Schichten': { [L.en]: 'Activities & shifts' },
	'Tätigkeit erstellen': { [L.en]: 'Create activities' },
	'Tätigkeit editieren': { [L.en]: 'Edit activities' },
	'Mitarbeitende und Lohndaten einer Tätigkeit sehen': { [L.en]: 'See users qualified for an activity (incl. wages)' },
	'Mitarbeitende und Lohndaten einer Tätigkeit editieren': { [L.en]: 'Edit users qualified for an activity (incl. wages)' },
	'Buchungseinstellungen sehen': { [L.en]: 'See booking settings' },
	'Buchungseinstellungen editieren': { [L.en]: 'Edit booking settings' },
	'Schichten erstellen': { [L.en]: 'Create shifts' },
	'Schichten editieren': { [L.en]: 'Edit shifts' },
	'Schichtbesetzung ändern': { [L.en]: 'Edit shift assignments' },
	'Eigene Schicht abgeben': { [L.en]: 'Drop own shifts' },
	'Schichten anderer übernehmen': { [L.en]: 'Pick up shifts from others' },
	'Benachrichtigung bei eigenem Schichttausch': { [L.en]: 'Notification of own shift swaps' },
	'Benachrichtigung bei Schichttausch anderer': { [L.en]: 'Notification of shift swaps from others' },
	'Statistik für Schichttausch exportieren': { [L.en]: 'Export shift swap statistics' },
	'Grunddaten für Online-Zahlung ändern': { [L.en]: 'Change basic settings for online payment' },
	'Online-Zahlung für den Account aktivieren': { [L.en]: 'Activate online payment for this account' },

	'User': { [L.en]: 'Users' },
	'Eigene Daten editieren': { [L.en]: 'Edit own profile' },
	'Vollständige Daten anderer sehen': { [L.en]: 'See someone else’s complete profile' },
	'Kontaktdaten anderer sehen (Name, Email, Telefon)': { [L.en]: 'See someone else’s contact details (name, email, phone)' },
	'Daten anderer editieren': { [L.en]: 'Edit someone else’s profile' },
	'Neue User-Accounts erstellen': { [L.en]: 'Create new user accounts' },
	'User exportieren': { [L.en]: 'Export users' },

	'Buchungssystem': { [L.en]: 'Booking system' },
	'Buchungssystem-Einstellungen': { [L.en]: 'Booking system settings' },
	'Buchungsstatus eines Angebots sehen': { [L.en]: 'See booking status of offers' },
	'Buchungen eines Angebots sehen': { [L.en]: 'See booking of an offer' },
	'Buchungen sehen': { [L.en]: 'See bookings' },
	'Verkäufe': { [L.en]: 'Sales' },
	'Buchungen editieren': { [L.en]: 'Edit bookings' },
	'Online-Rückerstattung veranlassen': { [L.en]: 'Initiate an online refund' },
	'Gutschein ausstellen als Rückerstattung': { [L.en]: 'Issue a gift card as a refund' },
	'Preise & Infos von Tarifen ändern': { [L.en]: 'Change fee amount and info' },
	'Buchungen manuell erstellen': { [L.en]: 'Create bookings manually' },
	'Buchungen exportieren': { [L.en]: 'Export bookings' },
	'${bookingsCount} Buchungen exportieren': { [L.en]: 'Export ${bookingsCount} bookings' },
	'Buchungen löschen': { [L.en]: 'Delete booking' },
	'Benachrichtigung über Buchungen': { [L.en]: 'Notification of new bookings' },
	'Übersicht aller Zahlungen sehen': { [L.en]: 'See overview of all transactions' },
	'Gutscheine sehen oder editieren': { [L.en]: 'See and edit gift cards' },
	'Stornogebühr einer Buchung ändern': { [L.en]: 'Change cancelation fee of a booking' },
	'Tarife in einer Tätigkeit ändern': { [L.en]: 'Edit fees of an activity' },

	'Schichtverteilungsvorgänge': { [L.en]: 'Shift scheduling' },
	'Verteilungsvorgang erstellen': { [L.en]: 'Create scheduling tasks' },
	'Schichtwünsche anfordern': { [L.en]: 'Request shift preferences' },
	'Deine Schichtwünsche abgeben': { [L.en]: 'Submit your shift preferences' },
	'Deine Schichtwünsche ändern': { [L.en]: 'Change your shift preferences' },
	'Eigene Schichtwünsche sehen': { [L.en]: 'See own shift preferences' },
	'Eigene Schichtwünsche editieren': { [L.en]: 'Edit own shift preferences' },
	'Schichtwünsche anderer sehen': { [L.en]: 'See someone else’s shift preferences' },
	'Schichtwünsche anderer editieren': { [L.en]: 'Edit someone else’s shift preferences' },
	'Erinnerung an Schichtwunschabgabe': { [L.en]: 'Reminder of submitting shift preferences' },
	'Mindest- & Maximallöhne anderer editieren': { [L.en]: 'Edit someone else’s earning limits' },
	'Eigenen Wunschlohn editieren': { [L.en]: 'Edit own desired earnings' },
	'Wunschlohn anderer editieren': { [L.en]: 'Edit someone else’s desired earnings' },
	'Verteilungsbericht empfangen': { [L.en]: 'Receive scheduling reports' },
	'Schichtplan veröffentlichen': { [L.en]: 'Publish schedules' },
	'Kalender-Synchronisation': { [L.en]: 'Calendar sync' },
	'Eigene Schichten synchronisieren': { [L.en]: 'Sync own shifts' },
	'Andere Schichten synchronisieren': { [L.en]: 'Sync other shifts' },

	'Tageskommentar': { [L.en]: 'Day note' },
	'Tageskommentare sehen': { [L.en]: 'See day notes' },
	'Tageskommentare erstellen': { [L.en]: 'Create day notes' },
	'Tageskommentare editieren': { [L.en]: 'Edit day notes' },

	'Stempeluhr': { [L.en]: 'Time clock' },
	'Eigene Schicht stempeln': { [L.en]: 'Clock in own shifts' },
	'Ungeplanten Einsatz stempeln': { [L.en]: 'Clock in unscheduled hours' },
	'Stempelerinnerung für eigene Schichten': { [L.en]: 'Reminder of tracking own shifts' },
	'Benachrichtigung über versäumtes Stempeln der Angestellten': { [L.en]: 'Notification of employees who failed to track hours' },
	'Auswertung & Verdienst': { [L.en]: 'Reporting & earnings' },
	'Eigene Einträge sehen': { [L.en]: 'See own timesheet' },
	'Einträge anderer sehen': { [L.en]: 'See someone else’s timesheet' },
	'Einträge editieren': { [L.en]: 'Edit timesheets' },
	'Neue Einträge erstellen': { [L.en]: 'Create timesheet entries' },
	'Eigene Auswertungsdaten exportieren': { [L.en]: 'Export own timesheet' },
	'Auswertungsdaten anderer exportieren': { [L.en]: 'Export someone else’s timesheet' },

	'Rechteverwaltung': { [L.en]: 'Permission settings' },
	'Eigene Rechtegruppe sehen': { [L.en]: 'See own user group' },
	'Rechtegruppen anderer sehen': { [L.en]: 'See someone else’s user group' },
	'Rechtegruppen erstellen': { [L.en]: 'Create user groups' },
	'Rechtegruppen editieren': { [L.en]: 'Edit user groups' },

	'Firmen- & Account-Einstellungen': { [L.en]: 'Account & company settings' },
	'Firma/Account-Daten sehen': { [L.en]: 'See account & company settings' },
	'Firma/Account-Daten editieren': { [L.en]: 'Edit account & company settings' },

	'Gilt nur, wenn die Person Schreibrecht hat und zugleich die Nachrichten für Bereichsleitende bekommt.': { [L.en]: 'Permission only granted if a user group has write permission as well as notifications permission.' },

	/**
	 * scheduler.frontend.ng2/src/client/booking/booking.component.ts
	 */
	'Buchung': { [L.en]: 'Booking' },
	'Interne Zahlungsart': { [L.en]: 'Internal payment method' },

	/**
	 * scheduler.frontend.ng2/src/client/booking/detail-form/detail-form.component.ts
	 */
	'Buchende & teilnehmende Personen werden informiert, <strong>falls</strong> du automatische Emails aktiviert hast.': { [L.en]: 'Participants & the person who booked will be notified <strong>if</strong> you have enabled automated email.' },
	'Ein oder mehrere ausgewählte Tarife sind zu diesem Zeitpunkt nicht verfügbar.': { [L.en]: 'At least one of the chosen fees isn’t available at that time.' },
	'veranlassen': { [L.en]: 'initiate' },
	'Stornogebühr speichern': { [L.en]: 'Save cancelation fee' },
	'erfassen': { [L.en]: 'record' },
	'${action} & keine Rückerstattung': { [L.en]: '${action} & no refund' },
	'Buchung stornieren': { [L.en]: 'cancel booking' },

	// cSpell:ignore zahlungsexport_buchung, paymentexport
	'zahlungsexport_buchung_':{[L.en]:'paymentexport_booking_'},
	'zahlungsexport_gutschein_':{[L.en]:'paymentexport_gift_card_'},
	'Anfragen können nur auf »abgelehnt« oder »gebucht«, aber nicht auf »storniert« gesetzt werden. Nur verbindliche Buchungen dürfen »storniert« werden.': { [L.en]: 'Requests can only be set to »declined« or »booked« but not to »canceled«. Only binding bookings may be canceled.' },
	'Bitte verknüpfe zuerst diese Buchung mit einem Angebot, bevor du diesen Status setzt.': { [L.en]: 'Please link the booking to a suitable shift first before setting this status.' },
	'Nur Buchungen mit dem Status »gebucht« können storniert werden.': { [L.en]: 'Only bookings with the status »booked« may be canceled.' },
	'Die Personen sollten mindestens ${min} und höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).': { [L.en]: 'Should be at least ${min} and at most ${max} years old by the date of the booked offer.' },
	'Die Personen sollten mindestens ${min} Jahre alt sein (zum Datum des gebuchten Angebots).': { [L.en]: 'Should be at least ${min} years old by the date of the booked offer.' },
	'Die Personen sollten höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).': { [L.en]: 'Should be at most ${max} years old by the date of the booked offer.' },
	'Die Person sollte mindestens ${min} und höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).': { [L.en]: 'The person should be at least ${min} and at most ${max} years old by the date of the booked offer.' },
	'Die Person sollte mindestens ${min} Jahre alt sein (zum Datum des gebuchten Angebots).': { [L.en]: 'The person should be at least ${max} years old by the date of the booked offer.' },
	'Die Person sollte zum Buchungszeitpunkt mindestens ${min} Jahre alt sein.': { [L.en]: 'The person should be at least ${max} years old when booking.' },
	'Die Person sollte höchstens ${max} Jahre alt sein (zum Datum des gebuchten Angebots).': { [L.en]: 'The person should be at most ${max} years old by the date of the booked offer.' },

	/**
	 * scheduler.frontend.ng2/src/client/booking/detail-form/p-shift-selection/p-shift-selection.component.ts
	 */
	'Dieses Angebot ist kostenpflichtig. Daher muss eine Zahlungsart vorhanden sein, die Kunden bei der Online-Buchung auswählen können. Aktuell sind alle Zahlungsarten als <mark>interne Zahlungsarten</mark> eingestellt und damit bei der Online-Buchung nicht verfügbar.': { [L.en]: 'This offer is not for free. Therefore, a payment method must be provided that customers can choose when booking online. Currently, all payment methods are set as <mark>internal payment methods</mark> and therefore not available for online booking.' },
	'Dieses Angebot ist kostenpflichtig. Daher muss eine Zahlungsart vorhanden sein, die Kunden bei der Online-Buchung auswählen können.': { [L.en]: 'This offer is not for free. Therefore, a payment method must be provided that customers can choose when booking online.' },

	/**
	 * scheduler.frontend.ng2/src/client/can-activate-access-control.guard.ts
	 */
	'Die aufgerufene Seite ist über mobile Geräte nicht zugänglich. Bitte nutze einen Computer oder einen größeren Laptop.': { [L.en]: 'The requested page is not accessible via mobile devices. Please use a computer or a larger laptop.' },

	/**
	 * scheduler.frontend.ng2/src/client/devices/devices.component.ts
	 */
	'Gerät Nr. ${deviceCount}': { [L.en]: 'Device No. ${deviceCount}' },

	/**
	 * scheduler.frontend.ng2/src/client/email/detail-form/p-custom-course-email-placeholders/p-custom-course-email-placeholders.component.ts
	 */
	'Wurde in die Zwischenablage kopiert.': { [L.en]: 'Copied to the clipboard.' },

	/**
	 * scheduler.frontend.ng2/src/client/email/email.component.ts
	 */
	'Email': { [L.en]: 'Email' },
	'Willst du die ${itemName} wirklich löschen?': { [L.en]: 'Are you sure you want to delete ${itemName}?' },
	'Email-Vorlage »${name}«': { [L.en]: 'Email template »${name}«' },
	'neue Email-Vorlage': { [L.en]: 'new email template' },
	'Neue Email': { [L.en]: 'New email' },
	'Email bearbeiten': { [L.en]: 'Edit email' },

	/**
	 * scheduler.frontend.ng2/src/client/gift-card/gift-card.component.ts
	 */
	'Gutschein': { [L.en]: 'gift card' },
	'Gutschein nicht einlösbar': { [L.en]: 'Gift card not redeemable' },
	'Für Online-Rückerstattungen an die buchende Person steht maximal dieses Guthaben zur Verfügung. Das Guthaben ergibt sich aus der Differenz ein- und abgegangener <strong>Online-Zahlungen</strong>, die unten aufgelistet sind.': { [L.en]: 'A maximum of this balance is available for online refunds to the booking person – the balance results from the difference of incoming and outgoing online payments shown below.' },
	'Status ändern?': { [L.en]: 'Change the status?' },
	'Wenn du den Buchungsstatus änderst, wird die buchende Person automatisch darüber informiert.': { [L.en]: 'The booking person will be notified automatically if you change the booking status.' },
	'Gutschein stornieren': { [L.en]: 'Cancel the gift card' },

	/**
	 * scheduler.frontend.ng2/src/client/gift-card/gift-card-manual-redemption-modal-content/gift-card-manual-redemption-modal-content.component.ts
	 */
	'Tipp': { [L.en]: 'Tip' },
	'<p>Möchtest du eventuell eine Gutschein-Einlösung für eine Buchung teils oder ganz rückgängig machen, weil die Buchung z.B. storniert wurde? Dann empfehlen wir dir, zur betroffenen Buchung zu gehen und dort eine Rückzahlung per Gutschein-Ausstellung vorzunehmen.</p><strong>Dein Vorteil</strong>: Mit einer Aktion bekommt dein Kunde einen neuen Gutschein und der Zahlungsstatus der Buchung wird aktualisiert – außerdem werden der neue Gutschein und die Buchung in Dr.&nbsp;Plano miteinander verbunden, sodass der Vorgang jederzeit nachvollziehbar bleibt.': { [L.en]: 'Would you like to cancel a gift card redemption for a booking partially or entirely, maybe because the booking was canceled? If so, we recommend that you go to the affected booking and make a refund by issuing a gift card. <strong>Your advantage</strong>: With one action, your customer gets a new gift card, and the payment status of the booking gets updated. Additionally, the new gift card and the booking are linked in Dr.&nbsp;Plano, ensuring the whole activity can be traced at any time.' },

	/**
	 * scheduler.frontend.ng2/src/client/gift-card/gift-card.service.ts
	 */
	'nicht einlösbar': { [L.en]: 'not redeemable' },
	'nicht online einlösbar': { [L.en]: 'not redeemable online' },
	'nicht für alle Angebote einlösbar': { [L.en]: 'not redeemable for all offers' },

	/**
	 * scheduler.frontend.ng2/src/client/gift-card/can-activate-gift-card.guard.ts
	 */
	'Die eingegebene URL ist ungültig.': { [L.en]: 'The entered URL is not valid.' },

	/**
	 * scheduler.frontend.ng2/src/client/header/header.component.ts
	 */
	'S': { [L.en]: 'Sec' },
	'Sek.': { [L.en]: 'Sec.' },
	'Sekunden': { [L.en]: 'Seconds' },
	'M': { [L.en]: 'Min' },
	'Min.': { [L.en]: 'Min.' },
	'Minute': { [L.en]: 'Minute' },
	'Minuten': { [L.en]: 'Minutes' },
	'Std': { [L.en]: 'Hrs' },
	'Std.': { [L.en]: 'Hrs.' },
	'Stunde': { [L.en]: 'Hour' },
	'Stunden': { [L.en]: 'Hours' },
	'Tage': { [L.en]: 'Days' },
	'Wochen': { [L.en]: 'Weeks' },
	'Monate': { [L.en]: 'Months' },
	'Jahre': { [L.en]: 'Years' },
	'KW': { [L.en]: 'CW' },
	'Test: noch ${time}': { [L.en]: 'Free trial: ${time} left' },

	/**
	 * scheduler.frontend.ng2/src/client/ical/ical.component.ts
	 */
	'Sonstige': { [L.en]: 'Other' },
	'In die Zwischenablage kopieren': { [L.en]: 'Copy to clipboard' },
	'URL kopieren': { [L.en]: 'Copy link' },

	/**
	 * scheduler.frontend.ng2/src/client/member/detail-form/detail-form.component.ts
	 */
	'Möchtest du dein Passwort wirklich ändern? Falls ja, wirst du aus Sicherheitsgründen abgemeldet und kannst dich mit deinen neuen Daten wieder anmelden.': { [L.en]: 'Are you sure you want to change your password? If so, you’ll be logged out for security reasons and can log in again with your new credentials.' },
	'Möchtest du deine Email-Adresse wirklich ändern? Falls ja, wirst du aus Sicherheitsgründen abgemeldet und kannst dich mit deinen neuen Daten wieder anmelden.': { [L.en]: 'Are you sure you want to change your email-address? If so, you’ll be logged out for security reasons and can log in again with your new credentials.' },
	'${name} wurde wiederhergestellt und per Email benachrichtigt.': { [L.en]: '${name} has been reactivated and notified via email.' },
	'Deine Kolleginnen und Kollegen, mit denen du in mindestens einer Tätigkeit zusammenarbeiten darfst, sehen dein Geburtsdatum im Kalender – allerdings nicht das Jahr und somit nicht dein genaues Alter 😉 Leitende Personen sehen das komplette Geburtsdatum.': { [L.en]: 'Your co-workers, who are allowed to work with you in at least one activity, will see your date of birth in their calendar – however, not the year and therefore not your exact age 😉 Managers will see the complete date.' },

	'Kontoinhaber': { [L.en]: 'account holder' },
	'Kontoinhaberin': { [L.en]: 'account holder' },
	'Der/Die Kontoinhabende': { [L.en]: 'account holder' },

	/**
	 * scheduler.frontend.ng2/src/client/member/member.component.ts
	 */
	'Der User-Account': { [L.en]: 'The user' },
	'Willst du den Account von ${firstName} ${lastName} wirklich löschen?': { [L.en]: 'Are you sure you want to delete the user ${firstName} ${lastName}?' },
	'Dieser User kann aktuell nicht gelöscht werden, da es keinen weiteren User mit Admin-Rechten gibt. Es muss immer mindestens ein Admin vorhanden sein.': { [L.en]: 'This user cannot be deleted because there is no other user with administration rights. At least one admin must always exist.' },
	'Keine Sorge! Gelöschte User werden nicht aus den vergangenen Schichten entfernt. Außerdem kannst du weiterhin auf ihre Auswertungsdaten zugreifen.': { [L.en]: 'Don’t worry. Deleted users will not be taken out of past shifts. You will also be able to access their evaluation data.' },

	/**
	 * scheduler.frontend.ng2/src/client/notifications/notifications.component.ts
	 */
	'Push-Nachricht per mobile App': { [L.en]: 'Push notifications on the mobile app' },
	'Push-Nachricht im Browser': { [L.en]: 'Push notifications in your browser' },
	'Grundlegendes für Schichtverteilungen': { [L.en]: 'General notifications for scheduling' },
	'Erinnerung an Schichtverteilungen': { [L.en]: 'Reminder for scheduling tasks' },
	'Schichtverteilung abgeschlossen': { [L.en]: 'Scheduling completed' },
	'Änderungen in deiner Schichtbesetzung': { [L.en]: 'Changes to your shift assignment' },
	'Schicht-Erinnerung': { [L.en]: 'Shift reminder' },
	'Vergessen zu stempeln': { [L.en]: 'Time clock reminder' },
	'Jemand hat nicht gestempelt': { [L.en]: 'Someone failed to clock in/out' },
	'Mindestteilnehmendenzahl nicht erreicht': { [L.en]: 'Minimum participants haven’t been reached' },
	'Mindestteilnehmendenzahl erreicht': { [L.en]: 'Minimum participants have been reached' },
	'Neue Buchungsanfrage eingegangen': { [L.en]: 'New booking request' },
	'Buchungsanfrage abgelehnt': { [L.en]: 'Booking request declined' },
	'Neue Buchung': { [L.en]: 'New booking' },
	'Gebucht': { [L.en]: 'Booked' },
	'Neue Buchung bzw. Anfrage mit Kundenkommentar': { [L.en]: 'New booking or request with customer’s comment' },
	'Buchung storniert': { [L.en]: 'Booking canceled' },
	'Buchung storniert durch User': { [L.en]: 'Booking canceled by a user'},
	'Buchung storniert vom Kunden selbst': { [L.en]: 'Booking canceled by the customer'},
	'Buchung storniert und manuelle Rückerstattung nötig': { [L.en]: 'Booking canceled and manual refund required'},
	'Storniert': { [L.en]: 'canceled' },
	'Gutschein gebucht': { [L.en]: 'Gift card sold' },
	'Gutschein storniert': { [L.en]: 'Gift card canceled' },
	'Grundlegende Nachrichten': { [L.en]: 'General notifications' },
	'Erinnerung: Bitte antworten!': { [L.en]: 'Reminder to reply' },
	'Jede neue Ersatzsuche': { [L.en]: 'Each new swap request' },
	'Jede abgeschlossene Ersatzsuche mit Problemen': { [L.en]: 'Only shift swaps with warnings' },
	'Jede abgeschlossene Ersatzsuche': { [L.en]: 'Each shift swap' },
	'Jede gescheiterte Ersatzsuche': { [L.en]: 'Each failed swap request' },
	'Deine Schichten': { [L.en]: 'Your shifts' },
	'Schichtbesetzung': { [L.en]: 'Shift assignments' },
	'Tauschbörse': { [L.en]: 'Swap shop' },
	'Kursbuchung': { [L.en]: 'Online booking' },

	'Nachrichten zu deiner eigenen Ersatzsuche oder Krankmeldung. Sowie wichtige Infos zur Ersatzsuche von anderen, wo du involviert bist.': { [L.en]: 'Notifications of your own swap or leave requests. Also of swap request from others if you’re involved.' },
	'Wenn jemand seit Tagen wegen einer Ersatzsuche auf deine Antwort wartet.': { [L.en]: 'In case somebody has been waiting for days for your reply.' },
	'Tipp: Die Email-Benachrichtigung enthält auch eine Kopie der automatischen Emails, die an deine Kunden verschickt wurden.': { [L.en]: 'Tip: The email notification includes a copy of the automated emails that has been sent to your customers.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Bei Gutscheinbuchungen über das Homepage-Plugin von Dr.&nbsp;Plano oder über die Kassensoftware Boulderado, falls die <a href="client/plugin/gift-cards/synchronization" target="_blank" rel="noopener">Gutschein-Synchronisation</a> mit Boulderado aktiviert ist.': { [L.en]: 'For gift card bookings via Dr.&nbsp;Plano’s website plugin or via the POS software Boulderado, if the <a href="client/plugin/gift-cards/synchronization" target="_blank" rel="noopener">gift card sync</a> with Boulderado is activated.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Benachrichtigung über jede Rückzahlung an Kunden in Form eines Gutscheins. Eine solche Gutschein-Ausstellung kann von einem User in Dr.&nbsp;Plano oder automatisch nach einem Online-Storno durch deine Kunden veranlasst werden. Mehr Infos dazu erhältst du <a href="client/plugin/gift-cards/introduction#refund-gift-cards" target="_blank" rel="noopener">hier</a>.': { [L.en]: 'Notification about every refund to clients via a gift card. Such gift card issuance can be initiated by a user in Dr.&nbsp;Plano or automatically after an online cancelation by your customers. Get more info <a href="client/plugin/gift-cards/introduction#refund-gift-cards" target="_blank" rel="noopener">here</a>.' },
	'Bei manueller Stornierung einer Buchung in Dr.&nbsp;Plano durch dich selbst oder einen anderen User.': {[L.en]:'When manually canceling a booking in Dr.&nbsp;Plano by yourself or another user.'},
	'Ein Kunde hat selbst online storniert.': {[L.en]:'A customer has canceled online.'},
	'Nach einer Stornierung ist eine Rückerstattung fällig, die manuell ausgeführt werden muss. Entweder, weil die automatische Rückerstattung deaktiviert ist, oder weil nicht genügend Online-Guthaben dafür zur Verfügung steht.': {[L.en]:'After a cancelation, a refund is due, which you need to do manually. Either because the automatic refund is deactivated or because there is not enough online balance available.'},
	'Deine Kunden können im Buchungsprozess einen Kommentar hinterlegen. Falls du dich nicht über jede Buchung & Anfrage benachrichtigen lässt, empfehlen wir dir diese Benachrichtigung einzuschalten.': { [L.en]: 'Your customers may add a comment to their booking. If you’ve turned off notifications for each booking, we highly recommend to activate this notification. Tip: The email notification includes a copy of the automated emails that has been sent to your customers.' },
	'Nach Ablauf der Buchungsfrist oder spätestens zwei Tage vor Angebotstermin.': { [L.en]: 'After the booking deadline has passed, but no later than two days before the offer’s date.' },
	'Sobald die Mindestteilnehmendenzahl erreicht wurde.': { [L.en]: 'As soon as the minimum participant number has been reached.' },
	'Falls du vergisst, eine geplante Schicht ein- oder auszustempeln, bekommst du spätestens am Folgetag eine Erinnerung.': { [L.en]: 'If you forget to track your time, Dr.&nbsp;Plano will remind you the next day at the latest.' },
	'Nach dem Gesetz müssen Angestellte innerhalb von 7 Tagen nach einer Schicht ihre Arbeitszeit gestempelt haben. Tun sie es trotz Erinnerung von Dr.&nbsp;Plano nicht, wirst du benachrichtigt.': { [L.en]: 'If employees fail to track their time despite reminders, you’ll be notified.' },
	'Du wirst über jeden neuen gebuchten Gutschein informiert.': { [L.en]: 'You’ll be notified whenever a new gift card is booked.' },
	'Probleme können sein: Überschreitung des Maximallohns, Schichtabstände etc.': { [L.en]: 'Warnings such as exceeding the earnings limit or not enough rest time between shifts etc.' },
	'Nachrichten wie: Bitte Schichtwünsche abgeben; bitte in Schichten / Kursen eintragen; Schichtplan veröffentlicht etc.': { [L.en]: 'Notifications like: please submit shift preferences; please pick up shifts; the schedule has been published etc.' },
	'Wenn du bis kurz vor Ablauf der Frist noch keine Schichtwünsche abgegeben oder vergessen hast, dich in Schichten einzutragen.': { [L.en]: 'If the deadline is about to expire and you haven’t either submitted your shift preferences or picked up empty shifts yet.' },
	'Du erhältst wichtige Berichte im Rahmen von Verteilungsvorgängen.': { [L.en]: 'You’ll receive important scheduling reports.' },
	'Wenn deine Personalleitung dich aus einer Schicht entfernt oder dir eine neue zuweist.': { [L.en]: 'In case you’ve been either scheduled for a new shift or removed from one.' },
	'Einmal pro Tag wirst du an deine morgigen Schichten erinnert.': { [L.en]: 'Once a day a kind reminder of your shifts for the next day.' },
	'Falls ein von dir geleitetes Angebot (z.B. ein Kurs) storniert wird.': { [L.en]: 'In case one of your bookable offers (e.g., a class) gets canceled.' },
	'Du wirst benachrichtigt, wenn die Online-Zahlung eines Kunden fehlschlägt und der Kunde die Zahlung erneut vornehmen muss.': {[L.en]:'You will be notified if a customer’s online payment fails and the customer needs to make the payment again.'},
	'Benachrichtigung über jede Online-Rückerstattung an Kunden, die von einem <strong>anderen</strong> User in Dr.&nbsp;Plano veranlasst wird.': { [L.en]: 'Get notified about every online refund to a customer that <strong>another</strong> user initiates.' },
	'Benachrichtigung über jede fehlgeschlagene Online-Rückerstattung.': {[L.en]:'Notification of any failed online refund.'},

	/**
	 * scheduler.frontend.ng2/src/client/plugin/p-plugin-settings/p-plugin-settings.component.ts
	 */
	'Anonymisierte Daten können nicht wiederhergestellt werden.': { [L.en]: 'Anonymized data cannot be restored.' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/bookings/bookings.component.ts
	 */
	'Freeclimber': { [L.en]: 'Freeclimber' },
	'BETA7': { [L.en]: 'BETA7' },
	'Routes-Manager': { [L.en]: 'Routes-Manager' },
	'Kletterszene.com': { [L.en]: 'Kletterszene.com' },
	'FAQ Online-Zahlung': { [L.en]: 'FAQ online payment' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/boulderado/boulderado.component.ts
	 */
	'Schnittstelle zu ${posName} verwenden': { [L.en]: 'Use the ${posName} interface' },
	'Boulderado': { [L.en]: 'Boulderado' },
	'Damit wird ab sofort jegliche Kommunikation zwischen »${posName}« und Dr.&nbsp;Plano eingestellt. Das betrifft je nach euren Einstellungen den Informationsaustausch über Buchungen, Zahlungen und Gutscheine.<br><br>Keine Sorge: Die bisher über die Schnittstelle erfassten Daten bleiben erhalten.': { [L.en]: 'This will immediately stop all communication between »${posName}« and Dr.&nbsp;Plano. Depending on your settings, it may affect sharing information about bookings, payments, and gift cards.<br><br>Don’t worry: data already captured via the interface will remain unchanged.' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/p-custom-course-emails/event-types.service.ts
	 */
	'Buchungsanfrage eingegangen': { [L.en]: 'Booking request received' },
	'Diese Email erhalten deine Kunden, nachdem sie online eine Buchungsanfrage gesendet haben.': { [L.en]: 'Your customers receive this email after requesting a booking online.' },
	'Diese Email erhalten Kunden bei direkter Online-Buchung oder bei Bestätigung ihrer Buchungsanfrage.': { [L.en]: 'Your customers receive this email after booking online or after you’ve accepted their booking requests.' },
	'Diese Email erhalten Kunden, deren Online-Zahlung nachträglich fehlgeschlagen ist (z.B. weil ihr Konto nicht gedeckt war). Die Email enthält einen Button, worüber deine Kunden erneut zahlen können.': { [L.en]: 'This email is sent to customers whose online payment failed afterward (e.g., because their account was not in credit). The email includes a button that allows your customers to pay again.' },
	'Erfolgreich gebucht': { [L.en]: 'Booked successfully' },
	'Teilzahlung erhalten': { [L.en]: 'Received partial payment' },
	'Buchungspreis komplett bezahlt': { [L.en]: 'Paid in full' },
	'Geld erstattet': { [L.en]: 'Fee refunded' },
	'Buchungspreis geändert': { [L.en]: 'Fee has changed' },
	'Termin-Erinnerung': { [L.en]: 'Reminder' },
	'2 Tage vor dem ersten Termin': { [L.en]: '2 days before the booked offer starts' },
	'Angebotstermin geändert': { [L.en]: 'Offer’s date changed' },
	'Diese Email wird verschickt, wenn sich die Uhrzeit oder das Datum eines gebuchten Angebots ändert. Oder einer der Termine eines mehrtägigen Angebots gelöscht wird.': { [L.en]: 'This email will either be sent out when date or time of an booked offer changes or when one session of a multi-day offer gets canceled.' },
	'Teilnahme abgeschlossen': { [L.en]: 'After attendance' },
	'Diese Email wird spätestens am Tag nach der Teilnahme an einem gebuchten Angebot verschickt.': { [L.en]: 'This email will be sent out the day after the booked offer.' },
	'Neuer Gutschein': { [L.en]: 'New gift card' },
	'Rückzahlung per Gutschein': { [L.en]: 'Refund via a gift card' },
	'Marketing-Gutschein': { [L.en]: 'Marketing gift card' },
	'Präferierte Zahlungsart geändert': { [L.en]: 'Preferred payment method changed' },
	'Online-Zahlung fehlgeschlagen': { [L.en]: 'Online payment failed' },
	'Online-Rückerstattung veranlasst': { [L.en]: 'Online refund initiated' },
	'Online-Rückerstattung fehlgeschlagen': { [L.en]: 'Online refund failed' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/plugin.component.ts
	 */
	// cSpell:ignore gutscheine
	'Einfach: viele Zahlungsarten, ein Preis': { [L.en]: 'Simple: many payment methods, one fee' },
	'Keine Fix-Kosten, sondern nur eine Gebühr pro Transaktion': { [L.en]: 'No fixed costs, just a fee per transaction' },
	'Transparente Anzeige der angefallenen Gebühr pro Transaktion': { [L.en]: 'Transparent presentation of the applied fee per transaction' },
	'keine Fix-Kosten, sondern nur eine Gebühr pro Gutschein': { [L.en]: 'no fixed costs, just a fee per gift card' },
	'gutscheine': { [L.en]: 'gift_cards' },
	'OK!': { [L.en]: 'OK!' },
	'Noch nicht eingerichtet': { [L.en]: 'Not yet activated.' },
	'Eine Datenänderung muss von Adyen, unserem Partner für die Online-Zahlung, geprüft und genehmigt werden. Über das Ergebnis der Prüfung wirst du automatisch benachrichtigt. Bis dahin steht dir die Online-Zahlung weiterhin zur Verfügung.':{ [L.en]: 'A modification of data must be approved by Adyen, our partner for the online payment. We’re going to notify you about the result of the review. Until then, online payment will continue to be available to your customers.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Wenn du fortfährst, wird für dich ein Buchungskonto bei unserem Partner Adyen erstellt. Dafür fällt eine einmalige <mark>Einrichtungsgebühr von ${onboardingPrice}</mark> an, die mit deiner nächsten Rechnung abgerechnet wird (siehe <a href="online-payment-pricing#onboarding" target="_blank" rel="noopener">Gebühren Online-Zahlung</a>).</br></br>Anschließend fragt Adyen nach einigen Angaben zu dir und deinem Betrieb. 👩‍🦰👨‍🦱 Stelle dabei sicher, dass du unter der Rubrik <mark>ULTIMATE BENEFICIAL OWNER (UBO) UND UNTERZEICHNER</mark> <b>mindestens eine/n Inhaber/in</b> und <b>mindestens eine/n Unterzeichner/in</b> einträgst. Sonst kann das Onboarding nicht vollständig abgeschlossen werden. Beide Rollen können übrigens von derselben Person erfüllt sein.</br></br>Möchtest du fortfahren?': {[L.en]: 'If you proceed, an online payment account will be created with our partner Adyen. For this, you’ll be charged a one-time setup fee of ${onboardingPrice}, which will be included in your next invoice (see <a href="online-payment-pricing#onboarding" target="_blank" rel="noopener">online payment fees</a>).</br></br>Adyen will then ask for some information about you and your company. 👩‍🦰👨‍🦱 Please make sure that you enter <b>at least one owner</b> and <b>at least one signatory</b> in the section <mark>ULTIMATE BENEFICIAL OWNER (UBO) AND SIGNATORY</mark>. Otherwise, onboarding cannot be completed. Both roles can be covered by the same person.</br></br>Do you want to ge ahead?' },
	'Du hast leider keine Berechtigung hierfür. Wende dich bitte an deine Admins.': { [L.en]: 'Sorry, you do not have permission to do this. Please get in touch with your admins.' },
	'Bevor du weitermachen kannst, musst du die Nutzungsbedingungen akzeptieren und die Checkboxen ankreuzen.': { [L.en]: 'Before continuing, you need to agree to the terms & conditions and check the checkboxes.' },
	'Dein Account ist zurzeit leider gesperrt! Falls du die Online-Zahlung wieder nutzen möchtest, melde dich bitte bei uns.': { [L.en]: 'Your account is currently suspended! If you wish to resume using the online payment, please let us know.' },
	'Anfrage läuft. Du wirst gleich weiter geleitet.': { [L.en]: 'Loading… You will be forwarded shortly.' },
	'Buchungskonto wird erstellt': { [L.en]: 'Your online payment account is being created' },
	'Dein Buchungskonto wird gerade noch erstellt. Das kann einige Minuten dauern. Wir werden dich per Email benachrichtigen, sobald das Konto zur Verfügung steht und du fortfahren kannst.': { [L.en]: 'Your booking account is being created, which may take a few moments. We will notify you by email as soon as the account is ready, and you can proceed.' },
	'Hohe Auslastung': {[L.en]:'High server load'},
	'Die Onboarding-Seite kann gerade aufgrund hoher Auslastung nicht aufgerufen werden. Versuche es bitte später nochmal.': {[L.en]:'Unfortunately, the onboarding page cannot be accessed right now due to the high load. Please try again later.'},
	'Okay': { [L.en]: 'Okay' },
	'Möchtest du den Rhythmus der Auszahlungen wirklich ändern?': { [L.en]: 'Are you sure you want to change the frequency of payouts?' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/gift-card-settings/gift-card-settings.component.ts
	 */
	'inaktiv': { [L.en]: 'turned off' },
	'aktiv': { [L.en]: 'turned on' },
	'Einlösung inaktiv': { [L.en]: 'Redemption disabled' },
	'Bitte nur folgende Zeichen benutzen: A-Z, 0-9, -, _.': { [L.en]: 'Bitte nur folgende Zeichen benutzen: A-Z, 0-9, -, _.' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/gift-card-settings/tabs/gift-card-settings-tab-settings/gift-card-settings-tab-settings.component.html
	 */
	'Jahre ab Ende des Buchungsjahres': { [L.en]: 'Years after the end of the booking year' },
	'Beschreibung des ungefähren Gegenwerts': { [L.en]: 'Description of the approximate value' },
	'Ungefährer Gegenwert': { [L.en]: 'Approximate value' },
	'Verzichte falls möglich auf die Buchstaben O, Y und Z. Manche Barcode-Scanner haben Probleme, sie korrekt zu erkennen.': { [L.en]: 'Please avoid using the letters O, Y, and Z whenever possible, as some barcode scanners have difficulty recognizing them.' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/gift-card-settings/tabs/gift-card-settings-tab-settings/gift-card-settings-tab-settings.component.ts
	 */
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'<p>Bitte vergewissere dich, dass alle Einstellungsmöglichkeiten auf dieser Seite deinem Wunsch entsprechen, bevor du den Gutscheinverkauf aktivierst.</p><p>Mit der Aktivierung akzeptierst du unsere <a href="client/plugin/gift-cards/introduction#pricing" target="_blank">Preise</a></p>': { [L.en]: '<p>Before activating the gift card sale, please ensure that all settings on this page match your preferences.</p><p>By activating, you agree to our <a href="client/plugin/gift-cards/introduction#pricing" target="_blank">pricing</a></p>' },
	'Aktivieren': { [L.en]: 'Activate' },
	'Ändern': { [L.en]: 'Change' },
	'<p>Wenn du die Erlaubnis für die Synchronisation entziehst, können deine Gutscheine ausschließlich online oder vor Ort erworben sowie eingelöst werden. Das ist eine sehr starke Einschränkung, die deine Kunden wahrscheinlich nicht erwarten.</p><p>Die Einschränkung gilt <strong>sofort für alle Gutscheine</strong> – für bereits bestehende sowie neu verkaufte.</p>': { [L.en]: '<p>If you revoke the permission for synchronization, your gift cards can be purchased and redeemed only online or on-site. That’s an extreme restriction that your customers probably don’t expect.</p><p>The restriction applies <strong>immediately for all gift cards</strong> – for already sold and new ones.</p>' },
	'Ja, Erlaubnis entziehen': { [L.en]: 'Yes, revoke permission' },
	'Ja, fortfahren': { [L.en]: 'Yes, continue' },
	'Deaktivieren': { [L.en]: 'Deactivate' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/gift-card-settings/tabs/gift-card-settings-tab-pos-synchronization/gift-card-settings-tab-pos-synchronization.component.ts
	 */
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Wenn du die Synchronisation erlaubst, erklärst du dich mit den auf dieser Seite angegebenen Preisen und Bestimmungen einverstanden. Der Datenverkehr zwischen Dr.&nbsp;Plano und Boulderado erfolgt gemäß unserer <a href="privacy" target="_blank" rel="noopener">Datenschutzerklärung</a>.': { [L.en]: 'By allowing the synchronization, you agree to the pricing and terms provided on this page. The communication between Dr.&nbsp;Plano and Boulderado will be in compliance with our <a href="privacy" target="_blank" rel="noopener">privacy policy</a>.' },
	'Synchronisation erlauben': { [L.en]: 'Permit synchronization' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/gift-card-settings/tabs/gift-card-settings-tab-pos-synchronization/gift-card-settings-tab-pos-synchronization.component.html
	 */
	'Aktivierung der Synchronisation': { [L.en]: 'Activation of the synchronization' },
	'Infos & Einstellungen': { [L.en]: 'Details & settings' },

	/**
	 * scheduler.frontend.ng2/src/client/gift-card/gift-card.component.ts
	 */
	'Verfallen': { [L.en]: 'Expired' },
	'Online via Homepage-Plugin von Dr.&nbsp;Plano': { [L.en]: 'Online via Dr.&nbsp;Plano’s website plugin' },
	'Nur vor Ort via Kasse »Boulderado«': { [L.en]: 'Only on-site via the POS »Boulderado«' },
	'Online via Dr.&nbsp;Plano + vor Ort via Kasse »Boulderado«': { [L.en]: 'Online via Dr.&nbsp;Plano + on-site via the POS »Boulderado«' },
	'Sonstiger Weg der Einlösung': { [L.en]: 'Other redemption method' },
	'Einlösung deaktiviert': { [L.en]: 'Redemption disabled' },

	/**
	 * scheduler.frontend.ng2/src/client/report/report.component.ts
	 */
	// cSpell:ignore auswertungsexport
	'auswertungsexport': { [L.en]: 'reporting_export' },
	'Auswertung vom': { [L.en]: 'Reporting from' },
	'vom': { [L.en]: 'from' },
	'bis': { [L.en]: 'to' },

	/**
	 * scheduler.frontend.ng2/src/client/report/shared/report-row/report-row.component.ts
	 */
	'Diese Prognose für die Zukunft basiert auf dem aktuellen Schichtplan und kann nicht bearbeitet werden.': { [L.en]: 'The forecast is based on actual scheduling, and therefore it cannot be edited.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-bookings/bookings.service.ts
	 */
	'Die Anwesenheit darfst du nur am Tag des Termins selbst bearbeiten. Wende dich bitte ansonsten an einen Admin.': { [L.en]: 'You may only edit this on the day of the event itself. Please contact an admin, if it needs to be changed now.' },
	'Die Anwesenheit wurde automatisch bestätigt aufgrund eines Vermerks von der Kasse, dass die Person die fällige Gebühr am Tag des Termins an der Kasse entrichtet hat.': { [L.en]: 'The participant has been automatically marked as »attended« due to his or her on-site payment on the day of the event.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/scheduling.component.ts
	 */
	'Diese Funktion steht aktuell nicht zur Verfügung, weil es keinen passenden Verteilungsvorgang gibt.': { [L.en]: 'This is currently unavailable as no matching scheduling task exists.' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/bookings/bookings.component.ts
	 */
	'Bei aktiver Suche steht der Statistik-Export nicht zur Verfügung.': { [L.en]: 'Statistics export is not available while the search is active.' },
	'Eine Buchung exportieren': { [L.en]: 'Export one booking' },
	'Statistik für eine Buchung exportieren': { [L.en]: 'Export statistics for one booking' },
	'Statistik für ${bookingsCount} Buchungen exportieren': { [L.en]: 'Export statistics for ${bookingsCount} bookings' },
	'Ja, ${action}': { [L.en]: 'Yes, ${action}' },
	'Angebote': { [L.en]: 'offers' },
	'Buchungen der folgenden Tätigkeiten werden <mark>nicht exportiert</mark>, weil dir dafür die Rechte fehlen:': { [L.en]: 'Bookings of the following activities <mark>will not</mark> be exported because you lack permission to do so:' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/gift-cards/gift-cards.component.ts
	 */
	'Gutscheine': { [L.en]: 'gift cards' },
	'Um die Korrektheit der Gutschein-Restwerte garantieren zu können, solltest du deine Gutscheine niemals woanders importieren (z.B. in eine Kassensoftware), um sie dort einlösbar zu machen.': { [L.en]: 'To guarantee the validity of the remaining balance of your gift cards, you should never import them somewhere else (e.g., into a POS software) to make them redeemable there.' },
	'marketing_gutschein_statistik': { [L.en]: 'marketing_gift_card_stats' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/transactions/transactions.component.html
	 */
	'Du hast nicht die Berechtigung dazu. Bitte wende dich an einen Admin.': { [L.en]: 'You do not have permission for this. Please refer to an admin.' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/transactions/transactions.component.ts
	 */
	'Rückbuchungen (Chargebacks)': { [L.en]: 'Chargebacks' },

	'Rückbuchung (Chargeback)': { [L.en]: 'Chargeback' },
	'Zahlungen': { [L.en]: 'Transactions' },
	'Eingegangene Zahlung': { [L.en]: 'Incoming payment' },
	'Eingegangene Zahlungen': { [L.en]: 'Incoming payments' },
	'Auszahlung des Guthabens': { [L.en]: 'Balance payout' },
	'Auszahlungen des Guthabens': { [L.en]: 'Balance payouts' },
	'${vatPercent} USt. auf die Online-Zahlungsgebühr': { [L.en]: '${vatPercent} VAT on the online payment fee' },
	'USt. auf die Online-Zahlungsgebühr': { [L.en]: 'VAT on the online payment fee' },
	'Aufladung des Guthabens': { [L.en]: 'Balance top-up' },
	'Aufladungen des Guthabens': { [L.en]: 'Balance top-ups' },
	'Automatische Rückerstattung': { [L.en]: 'Automated refund' },
	'Rückerstattung': { [L.en]: 'Refund' },
	'Rückerstattungen': { [L.en]: 'Refunds' },
	'Korrektur': { [L.en]: 'Correction' },
	'Kassenzahlung': { [L.en]: 'POS payment' },
	'Benutzerdefinierte Zahlungsarten': { [L.en]: 'Custom payment methods' },
	'Online-Zahlung': { [L.en]: 'Online payment' },
	'Neue Zahlungsart': { [L.en]: 'New payment method' },
	'von ${firstName} ${lastName}': { [L.en]: 'from ${firstName} ${lastName}' },
	'durch ${firstName} ${lastName}': { [L.en]: 'by ${firstName} ${lastName}' },
	'an ${firstName} ${lastName}': { [L.en]: 'to ${firstName} ${lastName}' },
	'automatisch durch Dr.&nbsp;Plano': { [L.en]: 'automatically by Dr.&nbsp;Plano' },
	'einer fehlgeschlagenen Einzahlung': { [L.en]: 'for a failed incoming payment' },
	'einer fehlgeschlagenen Guthabenauszahlung': { [L.en]: 'for a failed balance payout' },
	'einer fehlgeschlagenen Rückerstattung': { [L.en]: 'for a failed refund' },
	'einer fehlgeschlagenen Guthaben-Aufladung': { [L.en]: 'for a failed balance top-up' },
	'einer angefochtenen Rückbuchung': { [L.en]: 'for a defended chargeback' },
	'einer Gutschein-Einlösung': { [L.en]: 'of a gift card redemption' },
	'Zahlungsexport': { [L.en]:'Payment export'},
	'Payment-Seite': { [L.en]: 'Payment page' },
	'Aktivierung: Gutschein-Synchronisation': { [L.en]: 'Gift card sync activation' },
	'Dr.&nbsp;Plano-User': { [L.en]: 'Dr.&nbsp;Plano user' },
	'Release: Gutschein-Einlösung': { [L.en]: 'Release: gift card redemption' },
	'Dr.&nbsp;Plano': { [L.en]: 'Dr.&nbsp;Plano' },
	'Online-Storno durch Kunden': { [L.en]: 'Online cancelation by customers' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Marketing-Gutscheine bieten Kunden einen zusätzlichen Anreiz, deine Angebote online zu buchen. Nutze diesen Filter, um herauszufinden, wie viele deiner Marketing-Gutscheine eingelöst worden sind. <a href="client/plugin/gift-cards/introduction#marketing-gift-cards" target="_blank" rel="noopener">Mehr über Marketing-Gutscheine erfahren</a> →': { [L.en]: 'Marketing gift cards give customers an additional incentive to book your offers online. Use this filter to track how many marketing gift cards have been redeemed. <a href="client/plugin/gift-cards/introduction#marketing-gift-cards" target="_blank" rel="noopener">Learn more about marketing gift cards</a> →' },
	'Benutzerdefinierte Zahlungsarten können optional in den buchbaren Tätigkeiten angelegt werden. Ein typisches Beispiel dafür ist die »Zahlung vor Ort«. Benutzerdefinierte Zahlungsarten stehen den Kunden bei der Online-Buchung zur Verfügung – aber auch den Dr.&nbsp;Plano-Usern beim manuellen Anlegen von Buchungen.': { [L.en]: 'User-defined payment methods can optionally be created in the bookable activities. A typical example is the »on-site payment«. User-defined payment methods are available to customers when booking online - but also to Dr.&nbsp;Plano users when creating bookings manually.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Die von Dr.&nbsp;Plano angebotene Online-Zahlung sichert dir schnelle Zahlungen und sorgt zugleich für zufriedenere Kunden. <a href="client/plugin/payments" target="_blank" rel="noopener">Mehr zur Online-Zahlung erfahren</a> →': { [L.en]: 'Online payment offered by Dr.&nbsp;Plano ensures you rapid payments and, at the same time, keeps your customers happier. <a href="client/plugin/payments" target="_blank" rel="noopener">Learn more about online payments</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Wenn die Schnittstelle zwischen Dr.&nbsp;Plano und einer Kassensoftware verbunden ist, können an der Kasse Zahlungen für Buchungen angenommen werden, die über Dr.&nbsp;Plano getätigt worden sind. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Mehr zu den Schnittstellen erfahren</a> →': { [L.en]: 'Once the interface is linked between Dr.&nbsp;Plano and a POS software, payments can be accepted at the POS for bookings made through Dr.&nbsp;Plano. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Learn more about the integrations</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Kunden können ihre Buchungen durch das Einlösen von Gutscheinen, die sie über Dr.&nbsp;Plano erworben haben, bezahlen. Wenn die Gutschein-Synchronisation mit der Kassensoftware Boulderado aktiviert ist, können Gutscheine sowohl an der Kasse als auch über Dr.&nbsp;Plano erworben sowie eingelöst werden. <a href="client/plugin/gift-cards/introduction" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'Customers can pay for their bookings by redeeming gift cards purchased through Dr.&nbsp;Plano. If gift card synchronization with Boulderado POS software is enabled, gift cards can be purchased as well as redeemed both at the POS and through Dr.&nbsp;Plano. <a href="client/plugin/gift-cards/introduction" target="_blank" rel="noopener">Learn more</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Bei kostenpflichtigen Buchungen werden deine Kunden auf die sichere Payment-Seite von Dr.&nbsp;Plano weitergeleitet, wo sie über die Zahlungsart ihrer Wahl bezahlen können. <a href="client/plugin/basis" target="_blank" rel="noopener">Payment-Seite personalisieren</a> →': { [L.en]: 'For chargeable bookings, your customers will be forwarded to Dr.&nbsp;Plano’s secure payment page, where they can pay via the payment method of their choice. <a href="client/plugin/basis" target="_blank" rel="noopener">Personalize your payment page</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Automatisch erstellte Zahlungen oder Gutschein-Einlösungen, die notwendig wurden durch die Aktivierung der Gutschein-Synchronisation mit der Kassensoftware Boulderado. <a href="client/plugin/gift-cards/faq#faq-corrections-after-pos-sync" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'Automatically created payments or gift card redemptions needed after enabling gift card sync with Boulderado POS software. <a href="client/plugin/gift-cards/faq#faq-corrections-after-pos-sync" target="_blank" rel="noopener">Learn More</a> →' },
	'Durch Dr.&nbsp;Plano-User manuell erstellt oder veranlasst.': { [L.en]: 'Created or initiated manually by a Dr.&nbsp;Plano user.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Automatische Gutschein-Einlösungen, die notwendig wurden, weil seit dem Release im Oktober 2023 für Gutschein-Einlösungen Transaktionen erstellt werden. <a href="client/plugin/gift-cards/faq#faq-corrections-after-release" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'Automatic gift card redemptions became needed because, since the release in October 2023, transactions have been created for gift card redemptions. <a href="client/plugin/gift-cards/faq#faq-corrections-after-release" target="_blank" rel="noopener">Learn More</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Über die Schnittstelle zwischen Dr.&nbsp;Plano und der Kassensoftware Boulderado empfangen. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Mehr zur Schnittstelle erfahren</a> →': { [L.en]: 'Received via the interface between Dr.&nbsp;Plano and the Boulderado POS software. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Find out more about the integrations</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Über die Schnittstelle zwischen Dr.&nbsp;Plano und der Kassensoftware Freeclimber empfangen. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Mehr zur Schnittstelle erfahren</a> →': { [L.en]: 'Received via the interface between Dr.&nbsp;Plano and the Freeclimber POS software. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Find out more about the integrations</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Über die Schnittstelle zwischen Dr.&nbsp;Plano und der Kassensoftware BETA7 empfangen. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Mehr zur Schnittstelle erfahren</a> →': { [L.en]: 'Received via the interface between Dr.&nbsp;Plano and the BETA7 POS software. <a href="client/plugin/interfaces" target="_blank" rel="noopener">Find out more about the integrations</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Wenn deine Kunden ihre Buchungen selbstständig online stornieren, kann Dr.&nbsp;Plano bei entsprechender Einstellung automatisch Rückzahlungen oder Gutscheine für die Kunden erstellen. <a href="client/plugin/gift-cards/introduction#refund-gift-cards" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'If your customers cancel their bookings online on their own, Dr.&nbsp;Plano may automatically generate refunds or gift cards for the customers if configured to do so. <a href="client/plugin/gift-cards/introduction#refund-gift-cards" target="_blank" rel="noopener">Learn More</a> →' },
	'Automatisch von Dr.&nbsp;Plano erstellte Zahlungen.': { [L.en]: 'Automatically created payments by Dr.&nbsp;Plano.' },
	'Wenn eine Online-Zahlung erfolgreich beauftragt wird, anschließend aber doch fehlschlägt, wird die Zahlung nicht einfach verworfen, sondern als »fehlgeschlagen« markiert. Das gewährleistet eine lückenlose Nachverfolgbarkeit.': { [L.en]: 'If an online payment is initiated successfully but fails afterward, the payment is not simply discarded but marked as »failed«. That ensures complete traceability.' },
	'Korrektur-Zahlungen werden automatisch erstellt, um fehlgeschlagene Zahlungen auszugleichen. Denn fehlgeschlagene Zahlungen werden nicht einfach verworfen, sondern in der Zahlungsübersicht behalten, um eine lückenlose Nachverfolgbarkeit zu gewährleisten.': { [L.en]: 'Correction payments are created automatically to balance failed payments. That is because failed payments are discarded and kept in the payment list to ensure full traceability.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Rückbuchungen – so genannte Chargebacks – entstehen meist dadurch, dass der Endkunde bei der Bank die Zahlung reklamiert und zurückfordert. <a href="client/plugin/faq-online-payment#chargeback" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'Chargebacks usually occur because the customer complains to the bank about the payment and requests a refund. <a href="client/plugin/faq-online-payment#chargeback" target="_blank" rel="noopener">Find out more</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Im Zuge der Aktivierung der Online-Zahlung wird für dich ein Buchungskonto bei Adyen, unserem Partner für die Online-Zahlungsfunktion, errichtet. Auf diesem Konto wird dein Online-Guthaben gesammelt. Das Guthaben wird regelmäßig & automatisch auf dein bei Adyen hinterlegtes Bankkonto ausgezahlt. <a href="client/plugin/faq-online-payment#payout" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'As part of activating the online payment, an account will be created for you with Adyen, our partner for the online payment functionality. Your online balance will be collected on that account. The balance will be paid out regularly & automatically to your bank account on file with Adyen. <a href="client/plugin/faq-online-payment#payout" target="_blank" rel="noopener">Learn More</a> →' },
	'Einzahlungen durch deine Kunden.': { [L.en]: 'Payments from your customers.' },
	'Rückzahlungen an deine Kunden.': { [L.en]: 'Refunds to your customers.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Sollte dein Online-Guthaben mal durch beispielsweise viele Rückzahlungen in kurzer Zeit deutlich reduziert sein, veranlasst Dr.&nbsp;Plano eine automatische Guthaben-Aufladung per Lastschrifteinzug von deinem Bankkonto. Damit wird die Liquidität deines Online-Kontos sichergestellt, sodass du jederzeit beispielsweise Online-Rückzahlungen an deine Kunden vornehmen kannst. <a href="client/plugin/faq-online-payment#auto-debit" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'If your online balance is significantly reduced, for example, due to many refunds in a short time, Dr.&nbsp;Plano will initiate an automatic top-up by debit from your bank account. That ensures the liquidity of your online account so you can make online refunds to your customers anytime. <a href="client/plugin/faq-online-payment#auto-debit" target="_blank" rel="noopener">Find out more</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Falls dein Betrieb umsatzsteuerpflichtig ist, fällt für die Gebühren der Online-Zahlung über Dr.&nbsp;Plano Umsatzsteuer an, die einmal monatlich direkt von deinem Online-Guthaben abzogen wird. Dafür findest du eigene Zahlungseinträge in der Zahlungsübersicht. <a href="client/plugin/faq-online-payment#vat" target="_blank" rel="noopener">Mehr erfahren</a> →': { [L.en]: 'If your company is subject to VAT, you need to pay VAT for the online payment fees through Dr.&nbsp;Plano, which is deducted once a month right away from your online balance. You will find separate payment records for that in the payment list. <a href="client/plugin/faq-online-payment#vat" target="_blank" rel="noopener">Find out more</a> →' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Hierunter fallen Einlösungen von Gutscheinen, die gewöhnlich über Dr.&nbsp;Plano erworben wurden, aber auch von Marketing-Gutscheinen oder von Gutscheinen, die über die Kassensoftware Boulderado verkauften wurden. <a href="client/plugin/gift-cards/introduction" target="_blank" rel="noopener">Mehr über die Gutscheinfunktionen erfahren</a> →': { [L.en]: 'This includes redemptions of gift cards usually purchased through Dr.&nbsp;Plano and marketing gift cards or gift cards sold through the Boulderado POS software. <a href="client/plugin/gift-cards/introduction" target="_blank" rel="noopener">Learn more about the gift card features</a> →' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-bookings/booking-item/booking-item.component.ts
	 */
	'Rückerstattung fällig': { [L.en]: 'Refund is needed' },
	'Keine Zahlungen offen': { [L.en]: 'No payments due' },
	'Kostenlos': { [L.en]: 'For free' },
	'Noch nicht bezahlt': { [L.en]: 'Not yet paid' },
	'Komplett bezahlt': { [L.en]: 'Paid in full' },
	'Teils bezahlt': { [L.en]: 'Paid partially' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-bookings/booking-item/booking-details-modal/p-tariff-input/p-tariff-input.component.ts
	 */
	'Bist du sicher?': { [L.en]: 'Are you sure?' },
	'Dieser Tarif gilt nicht zum gewählten Angebotsdatum.': { [L.en]: 'This fee is not applicable on the selected offer date.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-bookings/booking-item/dumb-booking-item/dumb-booking-item.component.ts
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-bookings/booking-item/dumb-booking-item/dumb-booking-item.component.html
	 */
	'Anfrage': { [L.en]: 'Booking request' },
	'Anfrage abgelehnt': { [L.en]: 'Booking request declined' },
	'Keine Schicht im Kalender': { [L.en]: 'No shifts in the calendar' },
	'Im Kalender ansehen': { [L.en]: 'View on calendar' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-bookings/booking-list/booking-list.component.ts
	 */
	// cSpell:ignore buchungsstatistik, buchungsexport
	'buchungsexport': { [L.en]: 'bookings_export' },
	'buchungsstatistik': { [L.en]: 'bookings_statistics' },
	'Stelle bitte oben ein, dass sich der Zeitraum auf das »Angebotsdatum« bezieht, um die Statistik herunterladen zu können.': { [L.en]: 'Please change the period above to refer to the »offer date« to be able to download the statistics.' },
	'Lade ${label} ${index1} – ${index2} von ${index3}': { [L.en]: 'Show ${label} ${index1} – ${index2} of ${index3}' },
	'Buchungen': { [L.en]: 'Bookings' },
	'Kurse': { [L.en]: 'Classes' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-scheduling-calendar/calendar-title-bar/calendar-title-bar.component.ts
	 */
	'Hinzufügen': { [L.en]: 'Create' },

	// cSpell:ignore kalenderexport
	'kalenderexport': { [L.en]: 'calendar_export' },
	'Prüfung läuft…': { [L.en]: 'Check started…' },
	'Dr.&nbsp;Plano prüft deinen Plan und schickt dir das Ergebnis per Email an <strong>${email}</strong>': { [L.en]: 'Dr.&nbsp;Plano will check your plan and send you the result by email to <strong>${email}</strong>' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-scheduling-calendar/calendar-title-bar/deselect-btn/deselect-btn.component.ts
	 */
	'Eine Schicht wurde gelöscht.': { [L.en]: 'One shift has been deleted.' },
	'${amount} Schichten wurden gelöscht.': { [L.en]: '${amount} shifts has been deleted.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-scheduling-calendar/calendar-title-bar/wish-picker/wish-picker.component.ts
	 */
	'Danke für deine Schichtwünsche.': { [L.en]: 'Thanks for your preferences' },
	'Schichtwunsch ist noch offen.': { [L.en]: 'shift preference is left.' },
	'Schichtwünsche sind noch offen.': { [L.en]: 'shift preferences are left.' },
	'Bitte erst im Kalender Schichten selektieren.': { [L.en]: 'First, select shifts in the calendar.' },
	'Schichtwunsch entfernen': { [L.en]: 'Remove the set preference.' },
	'Ich möchte die Schicht!': { [L.en]: 'I want it!' },
	'Ich nehme die Schicht, wenn es sein muss.': { [L.en]: 'I’ll take it if need be…' },
	'Ich kann auf keinen Fall.': { [L.en]: 'There’s no way I can do it.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/p-scheduling-calendar/p-calendar/p-all-day-items-list/p-all-day-item/p-all-day-item.component.ts
	 */
	'Schulferien': { [L.en]: 'School holidays' },
	'Ist ein Festtag und kein gesetzlicher Feiertag.': { [L.en]: 'It’s not a legal holiday.' },
	'Ist ein gesetzlicher Feiertag.': { [L.en]: 'It’s a legal holiday.' },

	/**
	 * scheduler.frontend.ng2/src/shared/api.ts
	 */
	'Krankheit': { [L.en]: 'Sickness' },
	'Urlaub': { [L.en]: 'Vacation' },
	'Sonstiges': { [L.en]: 'Other' },
	'Geburtstag': { [L.en]: 'Birthday' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/text-to-html.service.ts
	 */
	'…mehr anzeigen': { [L.en]: '…show more' },

	/**
	 * Ausgeblendet: ${counter} von ${amount} Schichten
	 */
	'Ausgeblendete Schichten: ${counter} von ${amount}': { [L.en]: 'Hidden shifts: ${counter} of ${amount}' },

	/**
	 * scheduler.frontend.ng2/src/client/service/toasts.service.ts
	 */
	'OK': { [L.en]: 'OK' },
	'Schließen': { [L.en]: 'Close' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/change-selectors-modal/change-selectors-modal.component.ts
	 */
	'Nein, nur auf ${thisThing}': { [L.en]: 'No, just ${thisThing}' },
	'diese Vorlage': { [L.en]: 'this template' },
	'dieses Schicht-Paket': { [L.en]: 'this shift package' },
	'diese Schicht': { [L.en]: 'this shift' },
	'Das Löschen auf andere Bereiche übertragen?': { [L.en]: 'Apply the deletion to other areas?' },
	'Die Stornierung auf andere Bereiche übertragen?': { [L.en]: 'Apply the cancelation to other areas?' },
	'Änderung auf andere Bereiche übertragen?': { [L.en]: 'Apply this change to other areas?' },
	'Unbegrenzt': { [L.en]: 'Unlimited' },
	'Unbegrenzt bis zum Angebotsbeginn': { [L.en]: 'Unlimited till the start of the offer' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-shift-and-shiftmodel-form/assign-members/assign-members.component.ts
	 */
	'Bist du sicher, dass dieses Angebot nicht mehr buchbar sein soll? Falls ja, kontrolliere bitte auch die Einstellungen unter dem Tab <mark>Marketing-Gutscheine</mark>, denn der Versand von Marketing-Gutscheinen ist aktuell aktiviert.': { [L.en]: 'Should this offer no longer be bookable? If so, please also check the settings in the <mark>Marketing gift cards</mark> tab, as the sending of marketing gift cards is currently activated.' },
	'Bist du sicher, dass dieses Angebot nicht mehr buchbar sein soll?': { [L.en]: 'Should this offer no longer be bookable?' },
	'Du möchtest eine geänderte »Macht«-Einstellung übertragen. Damit entfernst du bei allen Schichten, auf die du diese Änderung überträgst, die ggf. vorhandene Besetzung ⚡': { [L.en]: 'You are about to apply a new assignment setting to other shifts. By doing so, you’ll remove any existing assignees on those shifts. ' },
	'Alle Schichten, auf die du diese Änderung überträgst, werden mit ${newAssignments} besetzt werden. Ihre ggf. vorhandene Besetzung geht verloren ⚡': { [L.en]: 'All shifts you apply this change to will be assigned to ${newAssignments}. The existing shift assignment, if any, will be removed ⚡.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-notification-conf-form/p-notification-conf-form.component.ts
	 */
	'In Schichten eingeteilte User informieren': { [L.en]: 'Notify the assigned user' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-shift-and-shiftmodel-form/p-shift-and-shiftmodel-form.component.html
	 */
	'ein Schicht-Paket': { [L.en]: 'a shift package' },
	'eine Einzelschicht': { [L.en]: 'a single shift' },
	'Das Datum kann nur beim Erstellen einer Schicht vergeben und nachträglich nicht mehr verändert werden.': { [L.en]: 'The date can only be set when creating a shift and cannot be changed afterward.' },
	'Tarif': { [L.en]: 'Fee' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-shift-and-shiftmodel-form/p-shift-and-shiftmodel-form.component.ts
	 */
	'Kopie': { [L.en]: 'Copy' },
	'Momentchen …': { [L.en]: 'Not possible' },
	'Ein Tarif mit dem Namen »${name}« existiert schon.': { [L.en]: 'There’s already a fee called »${name}«.' },
	'»${name}« wird bei der Online-Buchung als kostenlos angezeigt werden.': { [L.en]: '»${name}« will be shown as free when booking online.' },

	'Wähle…': { [L.en]: 'Select…' },
	'Paketen': { [L.en]: 'Packages' },
	'Schichten': { [L.en]: 'shifts' },
	'Paket': { [L.en]: 'Package' },
	'Schicht': { [L.en]: 'shift' },
	'Schichtinfo': { [L.en]: 'Shift details' },
	'Grundeinstellungen': { [L.en]: 'General settings' },
	'Einstellungen': { [L.en]: 'Settings' },
	'Buchungsinfo': { [L.en]: 'Booking status' },
	'Buchungseinstellungen': { [L.en]: 'Booking settings' },
	'Das Schicht-Paket »${shiftModelName}« wiederholt sich.': { [L.en]: 'The shift package ${shiftModelName} repeats.' },
	'Die Schicht »${shiftModelName}« wiederholt sich.': { [L.en]: 'The shift ${shiftModelName} repeats.' },
	'0 Mitarbeitende': { [L.en]: '0 instructor' },
	'1 Mitarbeitende': { [L.en]: 'One instructor' },
	'${counter} Mitarbeitende': { [L.en]: '${counter} instructors' },
	'pro ${x} Teilnehmende': { [L.en]: 'per ${x} participants' },
	'Verteilt auf die Tage': { [L.en]: 'Repeat on' },
	'Am': { [L.en]: 'On' },
	'An jedem': { [L.en]: 'On every' },
	'Eine neue Wiederholung startet am': { [L.en]: 'This recurrence starts on' },
	'Neue Tätigkeit': { [L.en]: 'New activity' },
	'diese Tätigkeit': { [L.en]: 'this activity' },
	'Diese Tätigkeit': { [L.en]: 'This activity' },
	'Buchungen ${vatPercent}': { [L.en]: 'Bookings ${vatPercent}' },

	'Du hast diese Zahlungsart bereits hinzugefügt.': { [L.en]: 'This payment method has already been added.'},
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Bitte erst für deinen Account die <a href="client/plugin/payments" target="_blank">Online-Zahlung aktivieren</a>, um die Zahlungsart hier verwenden zu können.': { [L.en]: 'To enable this payment method here, you need to <a href="client/plugin/payments" target="_blank">activate online payment</a> for your account first.'},
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Online-Zahlung steht für deine Landeswährung noch nicht zur Verfügung. Falls du die Online-Zahlung nutzen möchtest, melde dich gerne bei uns im Chat oder per <a href="mailto:service@dr-plano.com">Email</a>.': { [L.en]: 'Online payment is not yet available for your local currency. However, if you would like to use online payment, please contact us in the chat or via <a href="mailto:service@dr-plano.com">email</a>.' },

	'Es ist kein Tarif angelegt.': { [L.en]: 'No fees set up yet.' },
	'Alle Tarife sind entweder als <mark>interne Tarife</mark> eingestellt oder ihre Kosten liegen bei <mark>${amount}</mark>.': { [L.en]: 'All fees are either set as <mark>internal fees</mark> or their amount is <mark>${amount}</mark>.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-shift-and-shiftmodel-form/cancellation-policy/cancellation-policy.component.html
	 */
	'Um diese Funktion nutzen zu können, musst du erst deine Stornogebühren unter dem Reiter »Stornogebühren« angeben.': { [L.en]: 'You need to enter your cancelation fees on the tab »Cancelation fees« to use this feature.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-shift-and-shiftmodel-form/cancellation-policy/cancellation-policy.component.ts
	 */
	'Bedenke!': { [L.en]: 'Keep in mind!' },
	'Tage vor dem Angebotstag': { [L.en]: 'days before the offer day' },
	'Damit hier eine weitere Zeitspanne hinzugefügt werden kann, ändere bitte den Start der vorherigen Zeitspanne von »UNBEGRENZT« in eine konkrete Anzahl an Tagen.': { [L.en]: 'To add another period here, please change the start of the previous period from »UNLIMITED« to a set number of days.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-shift-and-shiftmodel-form/p-shift-and-shiftmodel-form.service.ts
	 */
	'Schichten/Termine von »${name}« können nicht im Plugin angezeigt werden, da entsprechend der Einstellungen in der Tätigkeit die Kunden nur Anfragen mit Wunschterminen schicken sollen – es sollen keine vorgegebenen Termine zur Auswahl stehen.': { [L.en]: 'According to the activity settings, customers must indicate an individual date with booking requests for »${name}«. Therefore, existing shifts/dates won’t be shown in the plugin calendar.' },
	'Uhrzeit muss vor »${max}« liegen': { [L.en]: 'Choose a time before »${max}«' },
	'Die Zeit muss vor »${max}« liegen': { [L.en]: 'Choose a time before »${max}«' },
	'Wähle bitte eine Zeit später als »${min}«.': { [L.en]: 'Choose a time after »${min}«.' },
	'Uhrzeit muss später als »${min}« sein': { [L.en]: 'Choose a time after »${min}«' },
	'Nie': { [L.en]: 'Never' },
	'Nach X Wiederholungen': { [L.en]: 'After X occurrences' },
	'An dem Datum X': { [L.en]: 'On X' },
	'Nach X Paketen': { [L.en]: 'After X packages' },
	'Nach X Schichten': { [L.en]: 'After X shifts' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/p-subheader/p-subheader.component.ts
	 */
	'Verwerfen': { [L.en]: 'Discard' },
	'Zurück': { [L.en]: 'Back' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/detail-form-utils.service.ts
	 */
	'${itemTitle} wurde angelegt.': { [L.en]: '${itemTitle} has been created.' },
	'Fehler beim Anlegen.': { [L.en]: 'Failed to create.' },
	'»${item}« wurde gelöscht.': { [L.en]: '»${item}« has been deleted.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/filter.service.ts
	 */
	'User ausgeblendet': { [L.en]: 'User hidden' },
	'… und die dazugehörigen Schichten, Tauschbörsen-Einträge, Arbeitseinsätze in der Auswertung, Buchungen etc.': { [L.en]: '… and the related shifts, swap shop entries, entries in the reporting, etc.' },
	'Tätigkeit ausgeblendet': { [L.en]: 'Activity hidden' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-account-form/p-account-form.service.ts
	 */
	'${monthlyPrice} zzgl. MwSt.': { [L.en]: '${monthlyPrice}' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-delete-button/p-delete-button.component.ts
	 */
	'Löschen': { [L.en]: 'Delete' },
	'Ja, ${label}': { [L.en]: 'Yes, ${label}' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-editable-forms/p-editable-modal-box/p-editable-modal-box.component.html
	 */
	'Inhalte bearbeiten': { [L.en]: 'Edit the content' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-editable/p-editable-modal-button/p-editable-modal-button.component.ts
	 */
	'Speichern': { [L.en]: 'Save' },
	'${action} & Rückerstattung veranlassen': { [L.en]: '${action} & initiate the refund' },
	'${action} & Rückerstattung ausführen': { [L.en]: '${action} & make the refund' },
	'Rückerstattung veranlassen': { [L.en]: 'Initiate the refund' },
	'Rückerstattung ausführen': { [L.en]: 'Refund now' },
	'Einzahlung erfassen': { [L.en]: 'Record an incoming payment' },
	'Rückerstattung erfassen': { [L.en]: 'Record a refund' },
	'${action} & Rückerstattung erfassen': { [L.en]: '${action} & Record a refund' },
	'Neue Rückerstattung': { [L.en]: 'New refund' },
	'Neue Einzahlung': { [L.en]: 'New incoming payment' },
	'Neue Zahlung': { [L.en]: 'New payment' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-ai-switch/p-ai-switch.component.html
	 */
	'Angebotsbeginn & danach': { [L.en]: 'Offer start & afterward' },

	/**
	 * scheduler.frontend.ng2/src/shared/p-forms/p-ai-switch/p-ai-switch.component.ts
	 */
	'Für je X Teilnehmende': { [L.en]: 'For each X participants' },
	'Für beliebig viele Teilnehmende': { [L.en]: 'For any number of participants' },

	/**
	 * scheduler.frontend.ng2/src/shared/p-forms/p-ai-form-array/p-ai-form-array.component.html
	 */
	'Einer der Einträge ist fehlerhaft.': { [L.en]: 'One of the entries is invalid.' },

	/**
	 * scheduler.frontend.ng2/src/shared/p-forms/p-input-date/p-input-date.component.ts
	 */
	'Es kann kein Datum ausgewählt werden, da bei der aktuellen Konstellation das späteste zulässige Datum – <mark>${max}</mark> – vor dem frühesten Datum liegt, das zulässig ist: <mark>${min}</mark>.': { [L.en]: 'No date can be selected as, in the current constellation, the latest possible date – <mark>${max}</mark> – is earlier than the earliest date allowed: <mark>${min}</mark>.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-recover-button/p-recover-button.component.ts
	 */
	'Wiederherstellen': { [L.en]: 'Restore' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-export.service.ts
	 */
	'${day}_${month}_${year}': { [L.en]: '${day}_${month}_${year}' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-dropdown/p-dropdown.component.ts
	 */
	'Bitte wählen…': { [L.en]: 'Please select…' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-input-date/p-input-date.component.ts
	 */
	'--.--.----': { [L.en]: '--.--.----' },
	'--:--': { [L.en]: '--:--' },
	'Datum': { [L.en]: 'Date' },
	'Tag': { [L.en]: 'Day' },
	'Tagen': { [L.en]: 'Days' },
	'${dayText} vor Schicht': { [L.en]: '${dayText} before shift' },

	'Bitte eine Uhrzeit eintragen. Z.B. ${example1} oder ${example2}.': { [L.en]: 'Please enter a time, like ${example1} or ${example2}.' },
	'Eingabe löschen': { [L.en]: 'Delete entry' },
	'Setze eine Frist von <strong>mindestens »${max}«</strong> ${daysText}.': { [L.en]: 'Set a deadline of <strong>at least »${max}«</strong> ${daysText}.' },
	'Setze eine Frist von <strong>höchstens »${min}«</strong> ${daysText}.': { [L.en]: 'Set a deadline of <strong>a maximum of »${min}«</strong> ${daysText}.' },
	'Lasse das Feld leer, falls du keine Frist setzen möchtest.': { [L.en]: 'Leave the field empty if you do not want to set a deadline.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-input-member-id/p-input-member-id.component.ts
	 */
	'Alle': { [L.en]: 'All' },
	'Berechtigten': { [L.en]: 'Eligible users' },
	'Alle Berechtigten': { [L.en]: 'All eligible users' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-input/p-input.component.ts
	 */
	'+491230000000': { [L.en]: '+491230000000' },
	'z.B. »${example1}«': { [L.en]: 'e.g., »${example1}«' },
	'z.B. »${example1}« oder »${example2}«': { [L.en]: 'e.g., »${example1} or »${example2}«' },
	'z.B. »${example1}«, »${example2}« oder »${example3}«': { [L.en]: 'e.g., »${example1}«, »${example2}« or »${example3}«' },
	'Suche…': { [L.en]: 'Search…' },
	'beispiel@domain.com': { [L.en]: 'example@domain.com' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-input/p-input.service.ts
	 */
	'Bitte eine Uhrzeit eingeben, z.B. »${example1}« oder »${example2}«.': { [L.en]: 'Please enter a time, like »${example1}« or »${example2}«.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-input/p-password-strength-meter.ts
	 */
	'Schwach': { [L.en]: 'Weak'},

	'Mäßig' : { [L.en]: 'Fair'},
	'Stark': { [L.en]: 'Strong'},

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-password-strength-meter/p-password-strength-meter.component.ts
	 */
	'min. ${minLength} Zeichen': { [L.en]: 'at least ${minLength} chars' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-member/p-member.service.ts
	 */
	// cSpell:ignore genitiv
	'${genitivName}’': { [L.en]: '${genitivName}’s' },
	'${genitivName}s': { [L.en]: '${genitivName}’s' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-exchange/deadline/deadline.component.ts
	 */
	'Frist für die Ersatzsuche: ${date}, ${time}': { [L.en]: 'Request’s deadline: ${date}, ${time}' },
	'Frist': { [L.en]: 'Deadline' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-exchange/p-shift-exchange-btn/p-shift-exchange-btn.component.ts
	 */
	'Ersatzsuche anzeigen': { [L.en]: 'Show swap request' },
	'Ersatz suchen': { [L.en]: 'Request shift swap' },
	'Krankmeldung anzeigen': { [L.en]: 'Show leave request' },
	'Krank melden': { [L.en]: 'Call in sick' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-exchange/p-shifts-info/p-shifts-info.component.ts
	 */
	'${amountOfShiftRefs} von ${amountOfShiftsInPackets} Schichten': { [L.en]: '${amountOfShiftRefs} from ${amountOfShiftsInPackets} shifts' },
	'Eine Schicht': { [L.en]: 'One shift' },
	'${counter} Schichten': { [L.en]: '${counter} shifts' },
	'aus ${shiftModelName}': { [L.en]: 'of ${shiftModelName}' },
	'aus ${counter} Tätigkeiten': { [L.en]: 'of ${counter} activities' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-exchange/shift-exchange.service.ts
	 */
	'Vorgang nicht möglich': { [L.en]: 'Action not possible' },
	'Solange sich die Schicht im Verteilungsvorgang <mark>${assignmentProcessName}</mark> befindet, kann sie nicht in die Tauschbörse gestellt werden.': { [L.en]: 'This shift is part of the scheduling task <mark>${assignmentProcessName}</mark>, and therefore it cannot be added to the swap shop.' },
	'${name} am ${startDate} um ${startTime}': { [L.en]: '${name} on ${startDate} at ${startTime}' },
	'Folgende Schichten können keinem Verteilungsvorgang hinzugefügt werden, weil sie sich in der Tauschbörse befinden. Entferne bitte zuerst die Schichtbesetzungen, um die Schichten aus der Tauschbörse zu nehmen: ${shiftListAsText}': { [L.en]: 'The following shifts cannot be added to a scheduling, as they are in the swap shop. To take them out, you have to remove the assigned employees beforehand: ${shiftListAsText}' },
	'Folgende Schicht kann keinem Verteilungsvorgang hinzugefügt werden, weil sie sich in der Tauschbörse befindet. Entferne bitte zuerst die Schichtbesetzung, um sie aus der Tauschbörse zu nehmen: ${shiftListAsText}': { [L.en]: 'The following shift cannot be added to a scheduling, as it’s in the swap shop. To take it out, you have to remove the assigned employees beforehand: ${shiftListAsText}' },
	'Aktuell ist ${firstName} der Schicht nicht zugewiesen.': { [L.en]: '${firstName} isn’t assigned to the shift currently.' },
	'Aktuell ist ${firstName} mindestens einer der Schichten nicht zugewiesen.': { [L.en]: 'Currently, ${firstName} isn’t assigned to at least one of the shifts.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/shift-exchanges/shift-exchanges.component.ts
	 */
	'Tauschbörsen Statistik': { [L.en]: 'Swap shop statistics' },
	'Bitte erst oben bei »Zeitraum der angezeigten Einträge« auf »benutzerdefiniert« umstellen und ein Start- sowie Enddatum festlegen.': { [L.en]: 'Please switch the »Period of shown requests« to »custom« and set a start and end date.' },
	'Statistik exportieren': { [L.en]: 'Export statistics' },
	'Statistik': { [L.en]: 'Statistics' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-module/p-course-info/p-course-info.component.ts
	 */
	'Stornierung': { [L.en]: 'Cancelation' },
	'Stornogebühr': { [L.en]: 'Cancelation fee' },
	'Angebot fällt aus': { [L.en]: 'canceled' },
	'Offenes Angebot – benötigt keine Buchungen': { [L.en]: 'Open class – attending without prior booking' },
	'Mindestens': { [L.en]: 'Minimum' },
	'Maximal': { [L.en]: 'Maximum' },
	'Angebot ist online nicht sichtbar': { [L.en]: 'Class isn’t visible on website' },
	'Angebot ist online sichtbar': { [L.en]: 'Class is shown on website' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-module/shift-item-list/shift-item-list.component.ts
	 */
	'fällt aus': { [L.en]: 'canceled' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-module/shift-member-exchange.service.ts
	 */
	'Du arbeitest zu der Zeit schon in einer anderen Schicht.': { [L.en]: 'You do another shift at the same time.' },
	'${firstName} arbeitet zu der Zeit schon in einer anderen Schicht.': { [L.en]: '${firstName} does another shift at the same time.' },
	'Du hast für die gewählte Zeit einen Abwesenheitseintrag.': { [L.en]: 'You have a leave of absence at the same time.' },
	'${firstName} hat für die gewählte Zeit einen Abwesenheitseintrag.': { [L.en]: '${firstName} has a leave of absence at the same time.' },
	'würdest': { [L.en]: 'would' },
	'würde': { [L.en]: 'would' },
	'weiter': { [L.en]: 'by a greater amount' },
	'${MEMBER} ${WOULD} mit dieser Schicht den Maximallohn ${FURTHER} überschreiten.': { [L.en]: 'By picking up this shift, ${MEMBER} ${WOULD} exceed the maximum earnings ${FURTHER}.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-picker/p-shift-picker-modal-box/p-shift-picker-modal-box.component.ts
	 */
	'Sicher?': { [L.en]: 'Sure?' },
	'Du hast Schichten im Kalender selektiert, aber sie nicht der Tauschbörse hinzugefügt.': { [L.en]: 'You’ve selected shifts in the calendar but haven’t added them to the swap shop yet.' },
	'Trotzdem schließen': { [L.en]: 'Close anyway' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-picker/p-shift-picker/p-shift-picker.component.ts
	 */
	'Schichten für den Tausch': { [L.en]: 'Shifts to swap' },
	'Schichten für Krankmeldung': { [L.en]: 'Shifts to call in sick' },
	'Schichten für Ersatzsuche': { [L.en]: 'Shifts to give away' },
	'Schicht-Auswahl': { [L.en]: 'Shift selection' },
	'Wähle im Kalender die Schichten, die du ${firstName} zum Tausch anbieten möchtest, und füge sie anschließend hier hinzu.': { [L.en]: 'First select shifts in the calendar which you want to swap with ${firstName}, then add them here.' },
	'Wähle im Kalender diejenigen Schichten, für die du ${recipient} krank melden möchtest, und füge sie anschließend hier hinzu.': { [L.en]: 'First select shifts in the calendar which are affected by calling in sick, then add them here.' },
	'Wähle im Kalender die Schichten, für die du Ersatz suchen möchtest, und füge sie anschließend hier hinzu.': { [L.en]: 'First select shifts in the calendar for which you want to request replacement, then add them here.' },
	'dich': { [L.en]: 'you' },
	'Schicht hinzufügen': { [L.en]: 'Add a shift' },
	'${counter} Schichten einzeln hinzufügen': { [L.en]: 'Add ${counter} shifts separately' },
	'${counter} Schichten der Krankmeldung hinzufügen': { [L.en]: 'Add ${counter} shifts to call in sick' },
	'${counter} Schichten der Suche hinzufügen': { [L.en]: 'Add ${counter} shifts to the request' },
	'${counter} Schichten der Auswahl hinzufügen': { [L.en]: 'Add ${counter} shifts to the selection' },
	'Du hast mehrere Schichten hinzugefügt. Sie alle müssen von einer Person komplett übernommen werden. Möchtest du das nicht, solltest du die Schichten einzeln in die Tauschbörse stellen.': { [L.en]: 'You’ve added multiple shifts. These must all be taken by one person. If you want to make these shifts available separately, you should add them individually to the swap shop.' },
	'Bedenke, dass dein Verhandlungspartner ein gebündeltes Schicht-Angebot komplett übernehmen muss und sich nicht einzelne Schichten rauspicken kann.': { [L.en]: 'Please be aware that shifts in a bundle must be taken as a set. Your colleagues can’t only pick the ones they want.' },
	'Zwei Angebote gleichen sich.': { [L.en]: 'Two offers are identical.' },
	'Es gibt Mitarbeitende, die schon auf die bisherige Schicht-Auswahl geantwortet haben. Änderst du die Auswahl, müssen sie erneut antworten.': { [L.en]: 'Some colleagues have already replied. By changing your offer they will be asked to reply again.' },
	'Niemand aus dem Team kann deine aktuelle Schicht-Auswahl übernehmen. Du solltest deine Auswahl ändern.': { [L.en]: 'None of your colleagues is available at the time of the selected shifts. Please change your selection.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-picker/shift-picker-picked-offers/shift-picker-picked-offers.component.ts
	 */
	'Der Auswahl hinzufügen': { [L.en]: 'Add to selection' },
	'Wähle im Kalender mindestens 1 Schicht, die du hinzufügen möchtest.': { [L.en]: 'Select at least 1 shift in the calendar you want to add.' },
	'Fügst du 2 oder mehr Schichten gebündelt hinzu, müssen sie komplett von einer Person übernommen werden.': { [L.en]: 'If you add 2 or more shifts in a bundle, they must all be taken by one person.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-shift-picker/shift-refs/shift-refs.component.ts
	 */
	'Wähle weitere Schichten im Kalender, um sie diesem Angebot gebündelt hinzuzufügen.': { [L.en]: 'Select more shifts in the calendar to add them as as a bundle.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/main-sidebar/main-sidebar.component.ts
	 */
	'user_export': { [L.en]: 'user_export'},
	'Alles per Filter ausgeblendet': { [L.en]: 'All hidden by filters' },
	'Ausgeblendet:': { [L.en]: 'Hidden:' },
	'${counter} von ${amount}': { [L.en]: '${counter} of ${amount}' },
	'Filter nicht aktiv': { [L.en]: 'No filter applied' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/main-sidebar/p-sidebar-shiftmodels/sidebar-shiftmodels.component.ts
	 */
	'Tätigkeitsdetails': { [L.en]: 'Activity details' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/main-sidebar/p-sidebar-members/sidebar-members.component.ts
	 */
	'Aktive User': { [L.en]: 'Active users' },
	'Gelöschte User': { [L.en]: 'Deleted users' },
	'User Bearbeiten': { [L.en]: 'Edit users' },
	'Tätigkeiten bearbeiten': { [L.en]: 'Edit activities' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-assignment-processes/p-assignment-processes.component.html
	 */
	'Diese Verteilungsart kostet dich am wenigsten Zeit und ist zugleich besonders fair für das gesamte Team. Dr.&nbsp;Plano berücksichtigt dabei folgende Faktoren:': { [L.en]: 'This type of scheduling saves you plenty of time and gets the fairest result to the entire team. It bases on the following key factors:' },
	'Deine Angestellten tragen sich selbst für die freigegebenen Schichten ein. Wer am schnellsten ist, bekommt womöglich die besten Schichten. Daher ist das nicht die fairste Verteilungsart, aber sinnvoll, wenn es z.B. mal besonders schnell gehen soll.': { [L.en]: 'Your employees may pick up shared shifts independently. It works on a first come, first served basis. It’s the best way to cover your shifts very quickly.' },
	'Bei dieser Verteilungsart bekommst du die Schichtwünsche deiner Angestellten. Anschließend musst du jede Schicht manuell besetzen. Das ist viel zeitaufwendiger als die automatische Verteilung, aber sinnvoll, wenn du bei der Verteilung sehr viele Sonderwünsche berücksichtigen möchtest.': { [L.en]: 'You’ll get the shift preferences of your employees and can assign shifts by using them. This scheduling type is recommended if there are plenty of special arrangements with your staff which can’t be considered by the automated scheduling.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-assignment-processes/p-assignment-process/p-assignment-process-type-caption/p-assignment-process-type-caption.component.html
	 */
	'Schichten auswählen': { [L.en]: 'Select shifts' },
	'Dr.&nbsp;Plano hat verteilt': { [L.en]: 'Dr.&nbsp;Plano has assigned' },
	'Muss veröffentlicht werden': { [L.en]: 'Needs to be published' },
	'Für Angestellte freigeben': { [L.en]: 'Send out to staff' },
	'Vorgang abschließen': { [L.en]: 'Finish scheduling' },
	'Schichten manuell besetzen': { [L.en]: 'Assign shifts manually' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-assignment-processes/p-assignment-process/p-assignment-process-type-caption/p-assignment-process-type-caption.component.ts
	 */
	'Automatische Verteilung': { [L.en]: 'Automated scheduling' },
	'Der frühe Vogel': { [L.en]: 'Early bird' },
	'Manuelle Verteilung': { [L.en]: 'Manual scheduling' },
	'Schichten für Verteilung auswählen': { [L.en]: 'Select shifts to schedule' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-assignment-processes/p-assignment-process/p-assignment-process.component.ts
	 */
	'Der Vorgang »${name}« wurde gelöscht.': { [L.en]: '»${name}« has been deleted.' },
	'Der Vorgang »${name}« wurde abgeschlossen.': { [L.en]: '»${name}« has been completed.' },
	'0 Schichten drin': { [L.en]: '0 shifts included' },
	'1 Schicht drin': { [L.en]: '1 shift included' },
	'${amount} Schichten drin': { [L.en]: '${amount} shifts included' },
	'bis heute Abend': { [L.en]: 'until tonight' },
	'Deine Auswahl beinhaltet Schichten, für die du nicht als bereichsleitende Person eingetragen bist.': { [L.en]: 'Your selection includes shifts for which you are not listed as the manager.' },
	'Die betroffenen User wurden benachrichtigt.': { [L.en]: 'The affected users have been notified.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-assignment-processes/p-assignment-processes.component.ts
	 */
	'Schichtverteilung': { [L.en]: 'Shift scheduling' },
	'Schichtverteilung ${counter}': { [L.en]: 'Scheduling task ${counter}' },
	'Schichten verteilen': { [L.en]: 'Shift scheduling' },
	'Schichten zu verteilen': { [L.en]: 'Shifts for scheduling' },
	'Erstelle einen neuen Verteilungsvorgang, um deine Schichten zu besetzen.': { [L.en]: 'Start a new scheduling task to get your shifts assigned.' },
	'Sobald deine Personalleitung Schichten verteilen lässt, siehst du das hier und kannst aktiv werden.': { [L.en]: 'Active scheduling task will be shown here. They are your chance to pick up shifts.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-sidebar/p-sidebar.component.ts
	 */
	'Filter aus': { [L.en]: 'Filter deactivated' },
	'Filter sind aktiv': { [L.en]: 'Filter activated' },
	'${amount} To-dos vorhanden': { [L.en]: '${amount} to-dos on the list' },
	'Kein Kommentar für dich vorhanden': { [L.en]: 'No notes for you' },
	'Kommentare für dich vorhanden': { [L.en]: 'Notes for you' },
	'Kommentare für dich und deine Schichten vorhanden': {[L.en]: 'Notes exist for you and your shifts'},

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/shiftmodel-list-item/shiftmodel-list-item.component.html
	 */
	'Details anzeigen': { [L.en]: 'Show details' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-transmission/change-selectors-modal.component.ts
	 */
	'Stornieren': { [L.en]: 'Cancel' },
	'Check deine Emails': { [L.en]: 'Check your emails' },
	'Wir haben dir geschrieben, bei welchen Buchungen eine Rückerstattung veranlasst wurde.': { [L.en]: 'We have listed the bookings that have been refunded.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-transmission/change-selectors-modal.component.html
	 */
	'Bei dieser Aktion steht die Übertragung aus technischen Gründen leider noch nicht zur Verfügung. Wenn du doch viele Stornierungen auf einmal vornehmen möchtest, könntest du die dazugehörigen Schichten löschen und das Löschen übertragen – falls Löschen eine Option ist.': { [L.en]: 'You can’t apply this action to other shifts. However, if you want to make numerous cancelations at once, you could delete the related shifts and apply the deletion to further shifts – if deleting is an option for you.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-transactions/transaction-list/transaction-list.component.ts
	 */
	'zzgl. ${value} USt.': { [L.en]: 'plus ${value} VAT' },
	'+ ${value} USt.': { [L.en]: '+ ${value} VAT' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/pipe/time-ago.pipe.ts
	 */
	'gerade eben': { [L.en]: 'just now' },
	'vor 1 Min.': { [L.en]: '1 min. ago' },
	'vor 1 Minute': { [L.en]: '1 minute ago' },
	'vor ${minutes} Min.': { [L.en]: '${minutes} min. ago' },
	'vor ${minutes} Minuten': { [L.en]: '${minutes} minutes ago' },

	'vor 1 Std.': { [L.en]: '1 hr. ago' },
	'vor 1 Stunde': { [L.en]: '1 hour ago' },
	'vor ${hours} Std.': { [L.en]: '${hours} hrs. ago' },
	'vor ${hours} Stunden': { [L.en]: '${hours} hours ago' },

	'vor 1 Tag': { [L.en]: '1 day ago' },
	'vor ${days} Tagen': { [L.en]: '${days} days ago' },
	'vor ${months} Mon.': { [L.en]: '${months} mths. ago' },
	'vor ${months} Monaten': { [L.en]: '${months} months ago' },

	'vor 1 Mon.': { [L.en]: '1 mth. ago' },
	'vor 1 Monat': { [L.en]: '1 month ago' },

	'vor 1 Jahr': { [L.en]: '1 year ago' },
	'vor ${years} Jahren': { [L.en]: '${years} years ago' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/warnings.service.ts
	 */
	'Deine gestempelte Arbeitszeit weicht von der geplanten ab. Warum?': { [L.en]: 'Your tracked time doesn’t match the scheduled time. Please justify.' },
	'Dein Arbeitseinsatz ist ungeplant? Wie kommt das?': { [L.en]: 'Please justify your unscheduled work.' },
	'Warum hast du nicht die aktuelle Zeit gestempelt?': { [L.en]: 'You failed to track your hours on time? Why?' },

	/**
	 * scheduler.frontend.ng2/src/client/shift/shift.component.ts
	 */
	'Willst du diese Schicht wirklich löschen?': { [L.en]: 'Are you sure you want to delete this shift?' },
	'Wenn du <strong>alle</strong> Schichten eines Angebots löschst, werden dessen Buchungen storniert.': { [L.en]: 'If you delete <strong>all</strong> shifts of an offer, its bookings will be canceled automatically.' },
	'Wenn du ein Angebot löschst, werden dessen Buchungen storniert.': { [L.en]: 'If you delete an offer, its bookings will be canceled automatically.' },

	/**
	 * scheduler.frontend.ng2/src/client/shift/detail-form/detail-form.component.ts
	 */
	'Neue Schicht': { [L.en]: 'New shift' },

	/**
	 * scheduler.frontend.ng2/src/client/shift-exchange/detail-form/p-shift-exchange-communication/p-shift-exchange-communication.component.ts
	 */
	'Ok, weitermachen': { [L.en]: 'OK' },
	'Abbrechen': { [L.en]: 'Cancel' },
	'${firstName} möchte die ${shift} nur abgeben, ohne im Gegenzug was zu übernehmen. Willst du ${firstName} trotzdem einen Tausch anbieten?': { [L.en]: '${firstName} prefers to give away ${shift} without picking up one of your shifts. Do you still want to offer ${firstName} swapping shifts?' },
	'Unterschiedliche Präferenzen': { [L.en]: 'Different preferences' },
	'${firstName} möchte die ${shift} tauschen, anstatt sie nur abzugeben. Wenn du nicht tauschen möchtest, wird ${firstName} deinem Vorschlag erst zustimmen müssen, damit euer Deal zustande kommt.': { [L.en]: '${firstName} prefers to swap ${shift} for one of your shifts. You might still go ahead, but ${firstName} will be asked to confirm or reject your offer.' },

	/**
	 * scheduler.frontend.ng2/src/client/shift-exchange/detail-form/p-shift-exchange-communication/p-shift-exchange-communication-modal/p-shift-exchange-communication-modal.component.ts
	 */
	'Mit ausgewählten Schichten tauschen': { [L.en]: 'Swap with selected shifts' },
	'Tauschen': { [L.en]: 'Swap' },
	'Mit ausgewählter Schicht tauschen': { [L.en]: 'Swap with selected shift' },

	// theShiftPluralisation
	'den Schichten': { [L.en]: 'these shifts' },
	'der Schicht': { [L.en]: 'this shift' },

	'Eine Ersatzsuche wird gestartet. Alle berechtigten Mitarbeitenden werden automatisch gefragt, ob sie die ${shiftPluralisation} übernehmen können. Über eine positive Antwort wirst du benachrichtigt.': { [L.en]: 'A request for replacement will be sent out. Eligible users will be asked to pick up ${shiftPluralisation}. You’ll be notified once someone has done so.' },
	'Hiermit entfernst du ${name} aus ${theShiftPluralisation}. ${name} wird benachrichtigt.': { [L.en]: 'You’re about to unassign ${name} from ${theShiftPluralisation}. ${name} will be notified automatically.' },

	/**
	 * scheduler.frontend.ng2/src/client/shift-exchange/detail-form/shift-exchange-detail-form.component.ts
	 */
	'Wenn du die Schichtauswahl änderst, werden deine vorgenommenen Einstellungen für Abwesenheitseinträge zurückgesetzt.': { [L.en]: 'Changing the shift selection will reset the settings of your sick leave.' },
	'Wenn du die Schichtauswahl änderst, werden die gewählte Frist und deine vorgenommenen Einstellungen für Abwesenheitseinträge zurückgesetzt.': { [L.en]: 'Changing the shift selection will reset the deadline and the settings of your sick leave.' },
	'Wenn du die Schichtauswahl änderst, wird die gewählte Frist zurückgesetzt.': { [L.en]: 'Changing the shift selection will reset the set deadline.' },
	'Erfolgreich wiederhergestellt.': { [L.en]: 'Restored successfully.' },
	'Fehler beim Wiederherstellen.': { [L.en]: 'Failed to restore.' },
	'Du hast die Ersatzsuche von ${name} abgelehnt.': { [L.en]: 'You declined the request from ${name}.' },
	'In Krankmeldung umwandeln': { [L.en]: 'Convert to call in sick' },
	'Ich bin erkrankt und kann nicht arbeiten': { [L.en]: 'I’m ill and can’t make it to work' },
	'${name} ist erkrankt und kann nicht arbeiten': { [L.en]: '${name} is ill and can’t make it to work' },
	'Kommentar von ${sender} an ${responder}': { [L.en]: 'Comment from ${sender} to ${responder}' },
	'dir': { [L.en]: 'you' },
	'der Leitung': { [L.en]: 'the manager' },
	'die Mitarbeitenden': { [L.en]: 'the employees' },
	'die Leitung': { [L.en]: 'the manager' },
	'die zuständige Person': { [L.en]: 'responsible person' },
	'${days} vor Schicht': { [L.en]: '${days} before shift' },
	'${days} vor letzter Schicht': { [L.en]: '${days} before final shift' },

	/**
	 * PLANO-179929
	 */
	'Empfehlung': { [L.en]: 'Recommendation' },
	'Mit einem perfekten Foto hilfst du deinem Team, dich schnell wiederzuerkennen. Das erleichtert den anderen die Arbeit und sie werden es dir danken': { [L.en]: 'A perfect photo helps your team recognize you quickly. That saves others time, and they will be thankful for it' },
	'Wir alle lieben Katzen oder Hunde, aber sie sollen nicht arbeiten oder?': { [L.en]: 'We all love cats or dogs, but they aren’t supposed to work, are they?' },
	'Landschaften sind auch schön, aber du bist schöner! Nur leider kaum sichtbar.': { [L.en]: 'Landscapes are pretty, too, but you are prettier! Sadly, you’re barely visible.' },
	'Voll cool und fast perfekt, aber nur fast.': { [L.en]: 'Cool and almost perfect, but just almost.' },
	// cSpell:ignore Peeerfekt, Peeerfect
	'Peeerfekt! 👍': { [L.en]: 'Peeerfect! 👍' },
	'Perfektes Foto auswählen': { [L.en]: 'Pick your perfect photo' },

	/**
	 * scheduler.frontend.ng2/src/client/shift-exchange/generate-absences-options/generate-absences-options.component.ts
	 */
	'Abwesende Stunden': { [L.en]: 'Hours of absence' },
	'Abwesende Stunden pro ${unit}': { [L.en]: 'Hours of absence per ${unit}' },
	'Eintrag': { [L.en]: 'entry' },

	/**
	 * scheduler.frontend.ng2/src/client/shift-exchange/shift-exchange.component.ts
	 */
	'Krankmeldung': { [L.en]: 'Call in sick' },
	'Ersatzsuche': { [L.en]: 'Replacement request' },
	'Tauschbörsen-Eintrag': { [L.en]: 'Swap shop entry' },
	'Willst du diesen Tauschbörsen-Eintrag wirklich zurückziehen?': { [L.en]: 'Are you sure you want to delete this swap shop entry?' },
	'${others} automatisch benachrichtigt. Du musst weiter nichts tun.': { [L.en]: '${others} notified automatically. You’re all set now.' },
	'Deine Mitarbeitenden werden': { [L.en]: 'Your coworkers will be' },
	'Deine Personalleitung wird': { [L.en]: 'Your manager will be' },

	/**
	 * scheduler.frontend.ng2/src/client/shiftmodel/shiftmodel.component.ts
	 */
	'Nutze nun den Button »Neu«, um für »${name}« Schichten im Kalender zu erstellen.': { [L.en]: 'Hit the button »Create« to add new shifts for »${name}« to the calendar.' },

	'Die Tätigkeit »${name}«': { [L.en]: 'The activity »${name}«' },
	'Bist du dir sicher, dass du die Schicht-Vorlage »${shiftModelName}« löschen willst?': { [L.en]: 'Are you sure you want to delete the template »${shiftModelName}«?' },
	'Keine Sorge! Die mit dieser Vorlage bereits erstellten Schichten gehen nicht verloren.': { [L.en]: 'Don’t worry. The shifts already created with this template won’t be deleted.' },

	/**
	 * scheduler.frontend.ng2/src/client/shiftmodel/detail-form/detail-form.component.ts
	 */
	'Daten erfolgreich kopiert': { [L.en]: 'Data copied successfully' },
	'Das Formular wurde mit den Daten aus der Tätigkeit »${name}« vorausgefüllt.': { [L.en]: 'The form has been prefilled with the data from the activity »${name}«.' },

	/**
	 * scheduler.frontend.ng2/src/client/time-stamp/stopwatch/stopwatch.component.ts
	 */
	'Einstempeln': { [L.en]: 'Clock in' },
	'Ausstempeln': { [L.en]: 'Clock out' },

	/**
	 * scheduler.frontend.ng2/src/client/time-stamp/time-stamp.component.ts
	 */
	'Aktuell bist du keiner Schicht zugewiesen. Also kannst du nur einen ungeplanten Einsatz machen. Wähle dazu eine Tätigkeit und klicke auf »Einstempeln«.': { [L.en]: 'Currently there aren’t any shifts to clock in to. You could clock in unscheduled time, though.' },
	'Du hilfst deinen Kollegen, obwohl du nicht für die Schicht eingetragen warst? Löblich! Deine Arbeitszeit kannst du dann als »ungeplanten Einsatz« stempeln. Wähle dazu eine Tätigkeit und klicke auf »Einstempeln«.': { [L.en]: 'You want to help out, even though you aren’t scheduled? Use »unscheduled work« to track your time.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Deine eingestempelten Zeiten wurden gespeichert! Du kannst sie in der <a href="client/report">Auswertung</a> einsehen.': { [L.en]: 'Your hours have been saved in <a href="client/report">Reporting</a>.' },
	'Du musst unten noch einen Kommentar für die Personalleitung abgeben.': { [L.en]: 'You have to leave a comment below to your manager.' },
	'Du darfst keine Tätigkeiten ausführen. Dementsprechend kannst du keinen ungeplanten Einsatz stempeln.': { [L.en]: 'You aren’t allowed to do any activities. Accordingly, you can’t clock in unscheduled times.' },
	'Keine Schichten zu stempeln': { [L.en]: 'No shifts to clock in for' },

	/**
	 * scheduler.frontend.ng2/src/client/transaction/detail-form/detail-form.component.ts
	 */
	'nicht relevant': { [L.en]: 'not relevant' },

	/**
	 * scheduler.frontend.ng2/src/client/transaction/transaction.component.ts
	 */
	'Zahlung': { [L.en]: 'Payment' },
	'Willst du diese Zahlung wirklich löschen?': { [L.en]: 'Are you sure you want to delete this payment?' },

	/**
	 * scheduler.frontend.ng2/src/client/workingtime/workingtime.component.ts
	 */
	'Willst du diesen Arbeitseinsatz wirklich löschen?': { [L.en]: 'Are you sure you want to delete this entry?' },

	/**
	 * scheduler.frontend.ng2/src/client/workingtime/detail-form/detail-form.component.ts
	 */
	'Arbeitseinsatz': { [L.en]: 'Worked hours' },
	'Arbeitseinsatz ${editOrCreate}': { [L.en]: '${editOrCreate} worked hours' },
	'eintragen': { [L.en]: 'Create' },
	'bearbeiten': { [L.en]: 'Edit' },
	'Bearbeiten': { [L.en]: 'Edit' },
	'Die Pause war länger als die Arbeitszeit? Witzbold ;)': { [L.en]: 'Really? The break was longer than the work duration? Joker ;)' },

	/**
	 * scheduler.frontend.ng2/src/datepicker-i18n.service.ts
	 */
	'${day}-${month}-${year}': { [L.en]: '${day}-${month}-${year}' },

	/**
	 * scheduler.frontend.ng2/src/global-error-handler/global-error-handler.ts
	 */
	'${requestedDate} liegt zu weit in der Zukunft. Du kannst maximal bis zum ${limit} navigieren.': { [L.en]: '${requestedDate} is too far in the future. Therefore, the loading was stopped. The maximum date that you can navigate to is ${limit}.' },
	'${requestedDate} liegt zu weit in der Vergangenheit. Du kannst maximal bis zum ${limit} navigieren.': { [L.en]: '${requestedDate} is too far in the past. Therefore, the loading was stopped. The maximum date that you can navigate to is ${limit}.' },
	'Zum ${limit}': { [L.en]: 'Go to ${limit}' },
	'Gute Nachricht: Soeben sind einige Verbesserungen online gegangen. Lade bitte Dr.&nbsp;Plano neu, damit du mit der verbesserten Version weiterarbeiten kannst.': { [L.en]: 'Good news: A new update to Dr.&nbsp;Plano has been released right now. Please reload the page to get the new features.' },
	'Neu laden': { [L.en]: 'Reload now' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Aufgrund von sehr hoher Serverauslastung kann deine Anfrage momentan nicht bearbeitet werden. Bitte versuche es gleich nochmal. Danke! Checke bei Interesse den <a href="https://status.dr-plano.com/" target="_blank" rel="noopener noreferrer">Systemstatus&nbsp;von&nbsp;Dr.&nbsp;Plano</a>.': { [L.en]: 'Due to the very high server load, we cannot handle your request currently. Please try again shortly. Thank you! Feel free to check our <a href="https://status.dr-plano.com/" target="_blank" rel="noopener noreferrer">system status</a>.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Dr.&nbsp;Plano ist aktuell nicht erreichbar. Kontrolliere bitte deine Internet-Verbindung. Checke gerne auch den <a href="https://status.dr-plano.com/" target="_blank" rel="noopener noreferrer">Systemstatus&nbsp;von&nbsp;Dr.&nbsp;Plano</a>.': { [L.en]: 'Can’t reach Dr.&nbsp;Plano at the moment. Please make sure you are connected to the internet. Feel free to check also our <a href="https://status.dr-plano.com/" target="_blank" rel="noopener noreferrer">status page</a>.' },
	'Du hast zu viele Anfragen an unseren Server gerichtet, weshalb du vorübergehend blockiert wurdest.': { [L.en]: 'You have sent too many requests to our server. Therefore you are temporarily blocked.' },
	'Deine gewünschte Aktion wurde aus Sicherheitsgründen blockiert. Für eine Freigabe melde dich bitte bei unserem Kundenservice.': { [L.en]: 'Your action has been blocked for security reasons. To unblock it, please get in touch with our customer service.' },
	'Kundenservice kontaktieren': { [L.en]: 'Contact customer service' },
	'https://tawk.to/chat/599aa06c1b1bed47ceb05bde/default': { [L.en]: 'https://tawk.to/chat/599aa06c1b1bed47ceb05bde/1e0qd6mh5' },
	'Dieser Zugang hat nur Leserecht.': { [L.en]: 'You have read-only access and cannot edit anything. ' },

	/**
	 * scheduler.frontend.ng2/src/public/main/register-test-account-form/register-test-account-form.component.ts
	 */
	'Land wählen…': { [L.en]: 'Select country…' },

	/**
	 * scheduler.frontend.ng2/src/public/shared/public-newsletter-form/public-newsletter-form.component.ts
	 */
	'Danke! Wir haben dir eine Email geschickt. Dort findest du einen Link, mit dem du dein Abonnement bestätigen kannst. Diese Maßnahme ist zu deinem eigenen Schutz.': { [L.en]: 'Thank you! We have sent you an email with a link to confirm your subscription. We do this to protect your data.' },
	'Fast geschafft': { [L.en]: 'Almost done' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/component/p-toast/p-toast.component.ts
	 */
	'Hey…': { [L.en]: 'Hey…' },
	'Warnung': { [L.en]: 'Warning' },
	'Achtung': { [L.en]: 'Warning' },
	'Info': { [L.en]: 'Info' },
	'Information': { [L.en]: 'Info' },
	'Fehler!': { [L.en]: 'Error!' },
	'Yeah!': { [L.en]: 'Yeah!' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/component/validation-hint/validation-hint.service.ts
	 */
	'Das Bild ist zu groß.': { [L.en]: 'The image is too large.' },
	'Das Bild ist nicht hoch genug.': { [L.en]: 'The image isn’t high enough.' },
	'Das Bild ist nicht breit genug.': { [L.en]: 'The image isn’t wide enough.' },

	'Diese Eingabe ist Pflicht.': { [L.en]: 'This is required.' },
	'Nur Großbuchstaben erlaubt.': { [L.en]: 'Only capital letters allowed.' },
	'Lade bitte eine Datei mit einer Seite hoch; deine Datei hat ${actual} Seiten.': { [L.en]: 'Please upload a file with one page; your file has ${actual} pages.' },
	'Lade bitte eine Datei in DIN A4 Format (210 x 297 mm) hoch.': { [L.en]: 'Please upload a file in DIN A4 size which measures 210 x 297 mm.' },
	'Deine Datei ist zu groß. Die maximal zulässige Größe beträgt ${expected} MB.':{[L.en] : 'Your file is too large. The maximum allowed size is ${expected} MB.'},
	'Überprüfe bitte deine Eingabe.': { [L.en]: 'Please check your entry.' },
	'Zeit-Format falsch': { [L.en]: 'Bad time format' },
	'Bitte nur ganze Zahlen eingeben, z.B. »${example}«.': { [L.en]: 'Please enter whole numbers only. Correct example: »${example}«' },
	'Bitte höchstens ${maxDigitsLength} Nachkommastellen eintragen.': { [L.en]: 'Please enter a maximum of ${maxDigitsLength} decimal places.' },
	'Diese Eingabe darf nicht größer sein als »${labelOfComparedControl}«.': { [L.en]: 'This entry shouldn’t be greater than »${labelOfComparedControl}«.' },
	'Bitte höchstens die Zeit »${max}« eingeben.': { [L.en]: 'Please enter a maximum of »${max}«.' },
	'Bitte höchstens die Uhrzeit »${max}« eingeben.': { [L.en]: 'Please set the time »${max}« maximum.' },
	'Bitte höchstens das Datum »${max}« eingeben.': { [L.en]: 'Please enter a maximum of »${max}«.' },
	'Bitte höchstens »${max}« eingeben.': { [L.en]: 'Please enter a maximum of »${max}«.' },
	'Bitte höchstens »${max}« anlegen.': { [L.en]: 'Please create a maximum of »${max}«.' },
	'Bitte mindestens »${min}« anlegen.': { [L.en]: 'Please create at least »${min}«.' },

	'Diese Eingabe darf nicht kleiner sein als »${labelOfComparedControl}«.': { [L.en]: 'This entry shouldn’t be lesser than »${labelOfComparedControl}«.' },
	'Bitte mindestens die Zeit »${min}« eingeben.': { [L.en]: 'Please enter a minimum of »${min}«.' },
	'Bitte mindestens das Datum »${min}« eingeben.': { [L.en]: 'Please enter a minimum of »${min}«.' },
	'Bitte mindestens »${min}« eingeben.': { [L.en]: 'Please enter a minimum of »${min}«.' },

	'Eingabe muss größer sein als der Wert von »${labelOfComparedControl}«.': { [L.en]: 'Needs to be greater than »${labelOfComparedControl}«.' },
	'Bitte eine Zeit später als »${greaterThan}« eingeben.': { [L.en]: 'Please enter a time later than »${greaterThan}«.' },
	'Bitte ein Datum später als »${greaterThan}« eingeben.': { [L.en]: 'Please enter a date after »${greaterThan}«.' },
	'Bitte eine Zahl größer als »${greaterThan}« eingeben.': { [L.en]: 'Please enter a number greater than »${greaterThan}«.' },
	'Bitte einen Wert größer als »${greaterThan}« eingeben.': { [L.en]: 'Please enter a value greater than »${greaterThan}«.' },
	'Bitte einen Wert kleiner als »${lessThan}« eingeben.': { [L.en]: 'Please enter a value smaller than »${lessThan}«.' },

	'Eingabe muss kleiner sein als »${labelOfComparedControl}«.': { [L.en]: 'Needs to be lesser than »${labelOfComparedControl}«.' },
	'Bitte eine Zeit früher als »${lessThan}« eingeben.': { [L.en]: 'Please enter a time before »${lessThan}«.' },
	'Bitte ein Datum früher als »${lessThan}« eingeben.': { [L.en]: 'Please enter a date before »${lessThan}«.' },
	'Bitte eine Zahl kleiner als »${lessThan}« eingeben.': { [L.en]: 'Please enter a number smaller than »${lessThan}«.' },

	'Bitte maximal ${requiredLength} Zeichen eingeben. ${actualLength} ist zu viel.': { [L.en]: 'Please enter a maximum of ${requiredLength} characters. ${actualLength} are too many.' },
	'Bitte maximal ${max} Nachkommastellen.': { [L.en]: 'Maximum ${max} decimal places, please.' },
	'Bitte einen kürzeren Zeitraum wählen.': { [L.en]: 'Please choose a shorter period.' },

	'Bitte keine Leerzeichen eingeben.': { [L.en]: 'No spaces, please.' },
	'Bitte keine Leerzeichen am Anfang oder Ende der Telefonnummer eingeben.': { [L.en]: 'Please do not enter any spaces before or after the phone number.' },
	'Das Passwort muss eine Zahl enthalten.': { [L.en]: 'The password must contain a number.' },
	'Das Passwort muss einen Buchstaben enthalten.': { [L.en]: 'The password must contain a letter.' },
	'Das Passwort muss einen Großbuchstaben enthalten.': { [L.en]: 'The password must contain an uppercase letter.' },
	'Das angegebene Ende muss nach dem angegebenen Start liegen.': { [L.en]: 'The end must be after the start.' },
	'Auf der Erde hat ein Tag 24 Stunden. Das Team von Dr.&nbsp;Plano ist aufgeschlossen gegenüber neuen Planeten. Melde dich! Wir kommen dich gerne besuchen! 🖖': { [L.en]: 'On Earth, a day has 24 hours. We are open to new planets. Get in touch! We would be glad to visit you! 🖖' },

	'»${actual}« ist keine Zahl.': { [L.en]: '${actual} isn’t a number.' },
	'Die Passwort-Wiederholung stimmt nicht.': { [L.en]: 'Passwords do not match.' },
	'Falsches Format eingegeben. Richtig wären z.B. »${example1}« oder »${example2}«.': { [L.en]: 'Bad format. Example: »${example1}« or »${example2}«.' },
	'Bitte nur Zahlen eingeben, z.B. »${example1}«.': { [L.en]: 'Please enter only numbers. For example »${example1}«.' },

	'Bitte mit »http://« oder »https://« beginnen.': { [L.en]: 'Please start with http:// or https://' },
	'URL unvollständig': { [L.en]: 'URL incomplete' },

	'Bitte keine negativen Zahlen.': { [L.en]: 'Please no negative numbers.' },
	'Ist bereits vergeben.': { [L.en]: 'Is already in use.' },

	'Diese Zahlungsart existiert bereits.': { [L.en]: 'This payment method already exists.' },
	'Die Anzahl der gewählten Wochentage und die Anzahl an Schichten, aus denen dieses Paket besteht, passen nicht zusammen.': { [L.en]: 'The number of selected days of the week does not match the number of shifts this package consists of.' },
	'Die gewünschte Anzahl an Wiederholungen passt nicht zu der Anzahl der ausgewählten Wochentage.': { [L.en]: 'The desired number of recurrences does not match the number of selected days of the week.' },

	/**
	 * scheduler.frontend.ng2/src/shared/p-forms/p-textarea/p-textarea.component.ts
	 * scheduler.frontend.ng2/src/shared/p-forms/p-text-editor/p-text-editor.component.ts
	 */
	'Einfügen nicht möglich': { [L.en]: 'Pasting wasn’t possible'},
	'Es kann nur Text eingefügt werden, aber deine Zwischenablage enthält nicht nur Text.': { [L.en]: 'Only text can be pasted, but your clipboard does include not only text.'},

	/**
	 * scheduler.frontend.ng2/src/shared/core/p-modal/modal.service.ts
	 */
	'Ja': { [L.en]: 'Yes' },
	'Nein': { [L.en]: 'No' },
	'Hinweis': { [L.en]: 'Note' },
	'Nagut 🙄': { [L.en]: 'Well, OK 🙄' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/p-push-notifications.service.ts
	 */
	'Schade. Falls du dich doch dafür entscheidest, gib uns bei deinem Browser das Recht, dir Benachrichtigungen zu schicken.': { [L.en]: 'It’s a pity. If you change your mind, go to your browser settings to activate push notifications.' },
	'Einstellung wurde gespeichert': { [L.en]: 'Saved!' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Falls du dich doch dafür entscheidest, kannst du die Nachrichten unter <a class="nowrap" href="client/notifications">Benachrichtigungs-Einstellungen</a> einschalten.': { [L.en]: 'If you change your mind, go to <a class="nowrap" href="client/notifications">Notification settings</a> to activate notifications.' },
	'Um über ${context} und ähnliche wichtige Dinge informiert zu werden, empfehlen wir dir Push-Nachrichten für diesen Browser einzuschalten.': { [L.en]: 'To be notified about ${context} and similar issues, we highly recommend to activate push notifications for your current browser.' },
	'Ja, bitte': { [L.en]: 'Yes please' },
	'Nein, danke': { [L.en]: 'No thanks' },
	'Neuigkeiten zu deiner Ersatzsuche': { [L.en]: 'Updates on your request' },
	'weitere Krankmeldungen': { [L.en]: 'more call in sick' },
	'den Status der Schichtverteilung': { [L.en]: 'the status of scheduling' },
	'fällige Zeiterfassung': { [L.en]: 'missing hours worked' },
	'neue Buchungsanfragen': { [L.en]: 'new booking request' },
	'Browser-Benachrichtigungen': { [L.en]: 'Browser notifications' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/pipe/translate.pipe.ts
	 */
	'Belgien': { [L.en]: 'Belgium' },
	'Deutschland': { [L.en]: 'Germany' },
	'Italien': { [L.en]: 'Italy' },
	'Rumänien': { [L.en]: 'Romania' },
	'Niederlande': { [L.en]: 'Netherlands' },
	'Österreich': { [L.en]: 'Austria' },
	'Schweiz': { [L.en]: 'Switzerland' },
	'Vereinigtes Königreich': { [L.en]: 'United Kingdom' },
	'Tschechien': { [L.en]: 'Czech Republic' },
	'Schweden': { [L.en]: 'Sweden' },
	'Luxemburg': { [L.en]: 'Luxembourg' },

	'Deutsch': { [L.en]: 'German' },
	'Englisch': { [L.en]: 'English' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/pipe/translate-hdyk.pipe.ts
	 */
	'Ihr habt mich angesprochen': { [L.en]: 'You reached out to me' },
	'Messe / Veranstaltung': { [L.en]: 'Fair / Event' },
	'Internet-Recherche': { [L.en]: 'Internet search' },
	'Empfehlung durch Dritte': { [L.en]: 'Recommendation by others' },
	'Soziale Medien': { [L.en]: 'Social media' },

	/**
	 * FILE: p-shift-exchange-concept.service.ts
	 */
	'Du': { [L.en]: 'You' },
	'wartest': { [L.en]: 'are waiting' },
	'wartet': { [L.en]: 'is waiting' },
	'hast': { [L.en]: 'have' },
	'hat': { [L.en]: 'has' },
	'willst': { [L.en]: 'want' },
	'will': { [L.en]: 'wants' },
	'suchst': { [L.en]: 'are requesting' },
	'sucht': { [L.en]: 'is requesting' },
	'bist': { [L.en]: 'are' },
	'ist': { [L.en]: 'is' },
	'arbeitest': { [L.en]: 'are working' },
	'arbeitet': { [L.en]: 'is working' },

	/**
	 * FILE: p-state-data.ts
	 */
	/** FAILED_DEADLINE_PASSED */
	'Gesetzte Frist verstrichen': { [L.en]: 'Set deadline expired' },

	/** FAILED_EVERYONE_DECLINED */
	'Niemand verfügbar': { [L.en]: 'No one available' },

	/** FAILED_SHIFTS_STARTED */
	'Kein Abnehmer bis Schichtbeginn': { [L.en]: 'No deal until shift start' },

	/** ILLNESS_ACCEPT_WITHOUT_SHIFT_EXCHANGE */
	'Akzeptiert ohne Ersatzsuche': { [L.en]: 'Approved without replacement request' },

	/** ILLNESS_DECLINED */
	'nicht akzeptiert': { [L.en]: 'declined' },
	'Hast nicht akzeptiert': { [L.en]: 'You declined' },

	/** ACTIVE */
	// NO_OFFER_YET
	'noch nichts Passendes': { [L.en]: 'nothing suitable so far' },
	'Bitte antworten': { [L.en]: 'Please reply' },

	// NO_OFFER_YET_CP_CANNOT
	'Bist nicht verfügbar': { [L.en]: 'You aren’t available' },

	// NO_OFFER_YET_CP_RESPONDED_NO
	'Hast abgelehnt': { [L.en]: 'You declined' },

	// NO_OFFER_YET_IM_RESPONDED_NO
	'${INDISPOSED_MEMBER_FIRST_NAME} hat abgelehnt': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} has declined' },

	// IM_MUST_ACCEPT
	'${INDISPOSED_MEMBER_FIRST_NAME} muss antworten': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} must reply' },

	// CP_MUST_ACCEPT
	'Wartest auf Antwort': { [L.en]: 'Waiting for reply' },
	'${RESPONSIBLE_PERSON_FIRST_NAME} ${RESPONSIBLE_PERSON_WAITS} auf Antwort': { [L.en]: '${RESPONSIBLE_PERSON_FIRST_NAME} ${RESPONSIBLE_PERSON_WAITS} for reply' },

	/** ILLNESS_NEEDS_CONFIRMATION */
	'Wartet auf Antwort': { [L.en]: 'Waiting for reply' },

	/** REMOVED_FROM_SHIFT */
	'Wurdest aus der Schicht entfernt': { [L.en]: 'removed from shift' },
	'aus der Schicht entfernt': { [L.en]: 'removed from shift' },

	/** SHIFTS_REMOVED */
	'Schicht gelöscht': { [L.en]: 'Shift deleted' },

	/** SWAP_SUCCESSFUL */
	'Getauscht': { [L.en]: 'Swapped' },

	/** TAKE_SUCCESSFUL */
	'${IM_OFFERED_SHIFTS} abgegeben ': { [L.en]: 'gave away ${IM_OFFERED_SHIFTS}' },
	'${IM_OFFERED_SHIFTS} übernommen': { [L.en]: 'picked up ${IM_OFFERED_SHIFTS}' },

	/** WITHDRAWN */
	'Hast zurückgezogen': { [L.en]: 'You withdrew' },
	'zurückgezogen': { [L.en]: 'withdrawn' },

	/**
	 * FILE: p-action-data.ts
	 */
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WAITS} auf Antwort': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} ${C_WAITS} for reply' },
	'Mein Tauschangebot ändern': { [L.en]: 'Change offered shifts' },
	'Doch nicht tauschen': { [L.en]: 'don’t swap anymore' },
	'Tauschangebot annehmen': { [L.en]: 'Accept swap offer' },
	'Kann doch nicht': { [L.en]: 'not available anymore' },
	'${IM_OFFERED_SHIFTS} abnehmen': { [L.en]: 'Pick up ${IM_OFFERED_SHIFTS}' },
	'Schichten doch tauschen': { [L.en]: 'Swap shifts' },
	'Angebot an ${INDISPOSED_MEMBER_FIRST_NAME} ändern': { [L.en]: 'Change offer to ${INDISPOSED_MEMBER_FIRST_NAME}' },
	'${IM_OFFERED_SHIFTS} doch abnehmen': { [L.en]: 'pick up ${IM_OFFERED_SHIFTS}' },
	'${IM_OFFERED_SHIFTS} doch abgeben': { [L.en]: 'give away ${IM_OFFERED_SHIFTS}' },
	'Schichten tauschen': { [L.en]: 'Swap shifts' },
	'Tauschangebot ansehen': { [L.en]: 'Show swap offer' },
	'Will nicht tauschen': { [L.en]: 'Decline swap' },
	'Ablehnen': { [L.en]: 'Decline' },
	'Akzeptieren & Ersatz suchen': { [L.en]: 'Approve & request replacement' },
	'Doch Ersatz suchen': { [L.en]: 'Request replacement' },
	'Akzeptieren': { [L.en]: 'Approve' },
	'Doch akzeptieren & Ersatz suchen': { [L.en]: 'Approve & request replacement' },
	'Doch akzeptieren': { [L.en]: 'Approve' },
	'kann doch nicht': { [L.en]: 'not available anymore' },
	'${IM_OFFERED_SHIFTS} nicht abgeben': { [L.en]: 'don’t give away ${IM_OFFERED_SHIFTS}' },
	'${IM_OFFERED_SHIFTS} abgeben': { [L.en]: 'give away ${IM_OFFERED_SHIFTS}' },
	'Kann nicht': { [L.en]: 'Unavailable' },

	/**
	 * FILE: p-communication-data.ts
	 */
	/** CP_WANTS_SWAP */
	'Schichten getauscht': { [L.en]: 'Swapped shifts' },
	'${CP_FIRST_NAME} ${CP_HAS} doch abgelehnt': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} declined' },
	'${CP_FIRST_NAME} ${CP_HAS} ein neues Tauschangebot gemacht': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} offered new shifts' },
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} nicht tauschen': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} declined to swap' },
	'${CP_FIRST_NAME} ${CP_WANTS} die ${IM_OFFERED_SHIFTS} doch übernehmen': { [L.en]: '${CP_FIRST_NAME} ${CP_WANTS} to pick up ${IM_OFFERED_SHIFTS}' },
	'${CP_FIRST_NAME} ${CP_HAS} die ${IM_OFFERED_SHIFTS} übernommen': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} picked up ${IM_OFFERED_SHIFTS}' },

	/** A_REPORTED_ILLNESS */
	'${CP_FIRST_NAME} ${CP_HAS} diese Krankmeldung erstellt und ${CP_SEEKS} Ersatz': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} created this sick leave and ${CP_SEEKS} replacement' },

	/** ILLNESS_NEEDS_CONFIRMATION */
	'${CP_FIRST_NAME} ${CP_HAS} die Krankmeldung akzeptiert und ${CP_SEEKS} Ersatz': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} approved leave request and ${CP_SEEKS} replacement' },
	'${CP_FIRST_NAME} ${CP_HAS} die Krankmeldung akzeptiert ohne Ersatzsuche': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} approved leave request without requesting replacement' },
	'${CP_FIRST_NAME} ${CP_HAS} die Krankmeldung akzeptiert': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} approved leave request' },
	'${CP_FIRST_NAME} ${CP_HAS} die Krankmeldung nicht akzeptiert': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} declined leave request' },

	/** ILLNESS_DECLINED */
	'${CP_FIRST_NAME} ${CP_HAS} die Krankmeldung doch akzeptiert': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} approved leave request' },

	/** CP_NOT_RESPONDED */
	'${CP_FIRST_NAME} ${CP_WANTS} tauschen': { [L.en]: '${CP_FIRST_NAME} ${CP_WANTS} to swap' },
	'${CP_FIRST_NAME} ${CP_WANTS} die ${IM_OFFERED_SHIFTS} übernehmen': { [L.en]: '${CP_FIRST_NAME} ${CP_WANTS} to pick up ${IM_OFFERED_SHIFTS}' },
	'${CP_HAS} abgelehnt': { [L.en]: '${CP_HAS} declined' },

	/** CP_RESPONDED_NO */
	'${CP_FIRST_NAME} ${CP_WANTS} doch tauschen': { [L.en]: '${CP_FIRST_NAME} ${CP_WANTS} to swap' },
	'${CP_FIRST_NAME} ${C_HAS} die ${IM_OFFERED_SHIFTS} übernommen': { [L.en]: '${CP_FIRST_NAME} ${C_HAS} picked up ${IM_OFFERED_SHIFTS}' },

	/** C_DECLINED_SWAP */
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} doch tauschen': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} to swap' },
	'${CP_FIRST_NAME} ${CP_HAS} die ${IM_OFFERED_SHIFTS} doch übernommen': { [L.en]: '${CP_FIRST_NAME} ${CP_HAS} picked up ${IM_OFFERED_SHIFTS}' },

	/** CP_WANTS_TAKE */
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} nicht abgeben, sondern tauschen': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} to swap rather than giving away' },

	/** C_DECLINED_TAKE */
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} die ${IM_OFFERED_SHIFTS} doch abgeben': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} give away ${IM_OFFERED_SHIFTS}' },

	/** C_CHANGED_MIND_WANTS_SWAP */
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_HAS} ein anderes Angebot ausgewählt': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} ${C_HAS} chosen another shift offer' },
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} doch nicht tauschen': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} declined to swap' },

	/** IM_CHANGED_MIND_WANTS_TAKE */
	'${INDISPOSED_MEMBER_FIRST_NAME} ${C_WANTS} doch nicht abgeben': { [L.en]: '${INDISPOSED_MEMBER_FIRST_NAME} declined to give away' },

	/** other */
	'${CP_IS_WORKING} zur selben Zeit': { [L.en]: '${CP_IS_WORKING} at the same time' },
	'${CP_IS_WORKING} in derselben Schicht': { [L.en]: '${CP_IS_WORKING} the same shift' },
	'${CP_FIRST_NAME} ${CP_IS} zu der Zeit abwesend': { [L.en]: '${CP_FIRST_NAME} ${CP_IS} absent at that time' },
	'${CP_FIRST_NAME} ${CP_IS} zu der Zeit krank': { [L.en]: '${CP_FIRST_NAME} ${CP_IS} sick at that time' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/validators.service.ts
	 */
	'Falsches Format eingegeben.': { [L.en]: 'Bad format.' },
	'Mit dem Format des IBAN Codes stimmt etwas nicht.': { [L.en]: 'Bad format.' },
	'Mit dem Format des BIC Codes stimmt etwas nicht.': { [L.en]: 'Bad format.' },
	'Diese Email-Adresse scheint nicht zu existieren.': { [L.en]: 'Invalid email address.' },
	'Es fehlt das »@« Zeichen.': { [L.en]: 'The »@« character is missing.' },
	'Diese Email Adresse ist bereits benutzt.': { [L.en]: 'Email address is already in use.' },
	'Falsches Format eingegeben. Richtiges Beispiel: »+491230000000«': { [L.en]: 'Invalid character. Correct example: »+491230000000«' },
	'Das ist keine gültige Postleitzahl.': { [L.en]: 'Invalid zip / postal code.' },
	'Eingabe zu niedrig': { [L.en]: 'Value too low' },
	'Eingabe zu hoch': { [L.en]: 'Value too high' },
	'Bitte mindestens ${requiredLength} Zeichen eingeben. ${actualLength} ist zu wenig.': { [L.en]: 'Please enter a minimum of ${requiredLength} characters. ${actualLength} aren’t enough.' },
	'Mindestens ${requiredLength} Einträge nötig (aktuell nur ${actualLength} vorhanden).': { [L.en]: 'At least ${requiredLength} entries needed (instead of currently only ${actualLength}).' },
	'Eingabe zu gering. Minimum: ${min} Monate.': { [L.en]: 'Please enter a minimum of ${min} months.' },

	'Lädt…': { [L.en]: 'Loading…' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/p-input-shiftmodel-id/p-input-shiftmodel-id.component.ts
	 */
	'Wähle eine Tätigkeit…': { [L.en]: 'Choose an activity…' },

	'Suche': { [L.en]: 'Search' },
	'Suche verlassen (Esc)': { [L.en]: 'Exit search (esc)' },

	/**
	 * scheduler.frontend.ng2/src/public/booking-system/booking-system-footer/booking-system-footer.component.ts
	 */
	'AGB': { [L.en]: 'Disclaimer' },
	'Datenschutzerklärung': { [L.en]: 'Privacy policy' },
	'Stornobedingungen': { [L.en]: 'Cancelation policy' },
	'Teilnahmebedingungen': { [L.en]: 'Terms & conditions' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-forms/input-image/input-image.component.ts
	 */
	'Willst du das aktuelle Bild wirklich löschen?': { [L.en]: 'Are you sure you want to delete the image?' },

	/**
	 * scheduler.frontend.ng2/src/shared/p-forms/input-pdf/input-pdf.component.ts
	 */
	'Willst du die aktuelle Gutschein-Vorlage wirklich löschen?': { [L.en]: 'Are you sure you want to delete the current template?' },

	/**
	 * scheduler.frontend.ng2/src/public/booking-system/booking-system-payment/booking-system-payment.component.ts
	 */
	'Jetzt bezahlen': { [L.en]:'Pay now'},
	'Jetzt bezahlen mit': { [L.en]:'Pay now by'},
	'Kredit- oder Debitkarte' : { [L.en]: 'Credit or debit card'},
	'Filter Aktiv':{[L.en]:'Filter applied'},
	'Es sind Filter aktiv, die möglicherweise Einfluss auf deinen Export haben.':{[L.en]:'There are active filters that may affect your export.'},
	'<mark>${excludedShiftModels}</mark> Tätigkeiten sind ausgeblendet':{[L.en]:'<mark>${excludedShiftModels}</mark> activities hidden'},
	'<mark>eine</mark> Tätigkeit ist ausgeblendet':{[L.en]:'one activity hidden'},
	'Ausgeblendete Zahlungstypen:':{[L.en]:'Hidden transaction types:'},
	'Ausgeblendete Zahlungsstatus:': { [L.en]: 'Hidden payment statuses:' },
	'fehlgeschlagen':{[L.en]:'failed'},
	'erfolgreich': { [L.en]: 'successful' },
	'Ausgeblendete Zahlungsarten:':{[L.en]:'Hidden payment methods:'},
	'Ausgeblendete Zahlungen erstellt durch:': { [L.en]: 'Hidden payments created by:' },
	'nur Zahlungen für die Suche nach <mark>${searchText}</mark> anzeigen':{ [L.en]:'show only transactions for the search for <mark>${searchText}</mark>'},
	'nur Beträge größer als <mark>${amountStart}</mark> anzeigen':{[L.en]:'show only amounts greater than <mark>${amountStart}</mark>'},
	'nur Beträge kleiner als <mark>${amountEnd}</mark> anzeigen':{[L.en]:'show only amounts smaller than <mark>${amountEnd}</mark>'},
	'nur Beträge zwischen <mark>${amountStart}</mark> und <mark>${amountEnd}</mark> anzeigen':{[L.en]:'show only amounts from <mark>${amountStart}</mark> to <mark>${amountEnd}</mark>'},
	'Microsoft Excel' : { [L.en]: 'Microsoft Excel'},
	'Falls du die CSV-Datei mit <strong>Microsoft Excel</strong> öffnen möchtest, klicke bitte in Excel bei der Registerkarte <mark>Daten</mark> auf den Knopf <mark>Aus Text/CSV</mark> und folge anschließend den Hinweisen, um die Daten zu laden. <br>Wir raten dir davon ab, die CSV-Datei direkt <strong>per Doppelklick</strong> in Excel zu öffnen, da manche Excel-Versionen die Formatierung der CSV-Datei verändern und die Datei unbrauchbar machen könnten.' : { [L.en]: 'If you wish to open the CSV file with Microsoft Excel, please go to the <mark>Data</mark> tab, hit the button <mark>From Text/CSV</mark>, and follow the instructions to load the data.<br>We do not recommend opening the CSV file in Excel by <strong>double-clicking</strong>, as some versions of Excel may change the formatting of the CSV file and make the file corrupted.'},

	'Die Zahlung wurde verweigert.': { [L.en]: 'The Payment was refused.' },
	'Die Zahlung schlug fehl aufgrund eines Fehlers beim Zahlungsdienstleister.' : { [L.en]: 'The transaction did not go through due to an error that occurred on the acquirer’s end.' },
	'Die verwendete Karte ist gesperrt und daher nicht einsetzbar.' : { [L.en]: 'The card used for the transaction is blocked. Therefore it is unusable.' },
	'Die verwendete Karte ist abgelaufen und daher nicht einsetzbar.' : { [L.en]: 'The card used for the transaction has expired. Therefore it is unusable.' },
	'Es ist eine Ungereimtheit beim Zahlungsbetrag aufgetreten.' : { [L.en]: 'An inconsistency has occurred in the payment amount.' },
	'Die Kartennummer ist fehlerhaft oder ungültig.' : { [L.en]: 'The specified card number is incorrect or invalid.' },
	'Die Zahlung konnte nicht autorisiert werden, da Ihre Bank nicht erreichbar ist.' : { [L.en]: 'It is not possible to contact your bank to authorize the payment.' },
	'Ihre Bank erlaubt oder unterstützt diese Zahlungsart nicht.' : { [L.en]: 'Your bank does not support or does not allow this type of transaction.' },
	'Die Authentifizierung (per 3D‐Secure‐Verfahren) konnte nicht durchgeführt werden oder ist gescheitert.' : { [L.en]: '3D Secure authentication was not executed, or it did not execute successfully.' },
	'Die verwendete Karte hat nicht ausreichend Guthaben.' : { [L.en]: 'The card does not have enough credit to cover the payable amount.' },
	'Die Zahlung mit dieser Zahlungsart wurde abgelehnt. Wähle bitte eine andere Zahlungsart.' : { [L.en]: 'The payment with this payment method was refused. Please try another payment method.' },
	'Die Zahlung wurde abgebrochen.' : { [L.en]: 'The payment was canceled.' },
	'Du hast den Zahlungsvorgang vorzeitig abgebrochen.' : { [L.en]: 'You canceled the transaction before completing it.' },
	'Die PIN ist falsch oder ungültig.' : { [L.en]: 'The specified PIN is incorrect or invalid.' },
	'Es wurde drei mal hintereinander eine falsche PIN eingegeben.' : { [L.en]: 'You specified an incorrect PIN more that three times in a row.' },
	'Es ist nicht möglich die eingegebene PIN zu validieren.' : { [L.en]: 'It is not possible to validate the specified PIN number.' },
	'Die Zahlung wurde nicht korrekt übermittelt und kann nicht weiterbearbeitet werden.' : { [L.en]: 'The payment was not submitted correctly for processing.' },
	'Die CVC‐Prüfnummer ist falsch.' : { [L.en]: 'The specified CVC (card security code) is invalid.' },
	'Die Karte kann nicht verwendet werden oder ist in diesem Land nicht zugelassen.' : { [L.en]: 'The card cannot be used or is not allowed in this country.' },
	'Du hast die Zahlung oder das Abonnement gestoppt.' : { [L.en]: 'You canceled the payment or requested to stop a subscription.' },
	'Es ist ein generischer Fehler aufgetreten.' : { [L.en]: 'A generic error has occurred.' },
	'Die Zahlung kann nicht durchgeführt werden, da der maximale Abbuchungsbetrag der Karte überschritten wird.' : { [L.en]: 'The withdrawal amount permitted for your card has exceeded.' },
	'Die Zahlung kann nicht durchgeführt werden, da die maximale Anzahl an Abbuchungen der Karte überschritten wird.' : { [L.en]: 'The number of withdrawals permitted for your card has exceeded.' },
	'Die angegebenen Adresse ist fehlerhaft.' : { [L.en]: 'The entered address data is incorrect.' },
	'Deine Bank benötigt die Eingabe deiner Online‐PIN zur Verifizierung.' : { [L.en]: 'Your bank requires you to enter an online PIN.' },
	'Deine Bank benötigt dein Girokonto, um die Zahlung abzuschließen.' : { [L.en]: 'Your bank requires a checking account to complete the purchase.' },
	'Deine Bank benötigt dein Sparkonto, um die Zahlung abzuschließen.' : { [L.en]: 'Your bank requires a savings account to complete the purchase.' },
	'Deine Bank benötigt die Eingabe deiner mobilen PIN zur Verifizierung.' : { [L.en]: 'Your bank requires you to enter a mobile PIN.' },
	'Du hast die Zahlung abgebrochen, nachdem du eine kontaktlose Zahlung versucht hattest und aufgefordert wurdest, eine andere Methode (PIN oder Unterschrift) zu benutzen.' : { [L.en]: 'You abandoned the payment after you attempted a contactless payment and were prompted to try a different card entry method (PIN or swipe).' },
	'Deine Bank benötigt eine zusätzliche Authentifizierung. Versuche es bitte mit dem 3D‐Secure‐Verfahren.' : { [L.en]: 'Your bank declined the authentication exemption request. Please retry with 3D Secure.' },
	'Die Authentifizierung (per 3D‐Secure 2‐Verfahren) wurde nicht korrekt abgeschlossen.' : { [L.en]: 'Authentication (via 3D Secure 2 method) was not completed correctly.' },
	'Der zu zahlende Betrag ist höher, als das dir zur Verfügung stehende Limit für diese Zahlungsart.': {[L.en]: 'The payment amount is higher than your limit for the selected payment method.'},
	'Der zu zahlende Betrag ist leider zu gering für diese Zahlungsart.': {[L.en]: 'The payment amount is too low for the selected payment method.'},
	'Für SEPA-Zahlungen aus bestimmten Ländern ist die Angabe der Adresse des Zahlungspflichtigen erforderlich!': {[L.en]: 'For SEPA-Payments from some countries, the Shopper Address is required!'},

	// default case - should never happen
	'Irgendetwas ist während des Zahlungsvorgangs schiefgelaufen! Bitte überprüfe deine Emails auf eventuelle Benachrichtigungen, bevor du es nochmal probierst.': { [L.en]: 'An error occurred! Please check your emails for possible notifications before retrying.'},

	// refusal reason part two
	'Um die Zahlung abzuschließen, kontrolliere deine Daten und versuche es noch einmal oder wähle eine andere Zahlungsart.' : { [L.en]: 'To complete the payment, please check your data and try again or choose another payment method.' },
	'Um die Zahlung abzuschließen, versuche es bitte erneut oder wähle eine andere Zahlungsart.' : { [L.en]: 'To complete the payment, please try again or choose another payment method.' },
	'Um die Zahlung abzuschließen, wähle bitte eine andere Zahlungsart.' : { [L.en]: 'To complete the payment, please choose another payment method.' },
	'Bitte versuche es mit einer anderen Zahlungsart oder wende dich an deinen Zahlungsdienstleister um das Limit für dein Konto zu erhöhen.':{[L.en]:'Please choose another payment method or contact your provider to raise the limit for your account.'},
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Bitte versuche es mit einer anderen Zahlungsart oder wende dich an <a href="${contactAddress}">${contactName}</a>.':{[L.en]:'Please choose another payment method or contact <a href="${contactAddress}">${contactName}</a>.'},
	'Um die Zahlung abzuschließen, versuche es bitte erneut.' : { [L.en]: 'To complete the payment, please try again.' },
	'Fehlercode für Kundenservice-Anfragen:' : { [L.en]: 'Error code for customer service inquiries:' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/component/input-shiftmodel-id-modal/input-shiftmodel-id-modal.component.ts
	 */
	'Tätigkeit wählen …': { [L.en]: 'Choose an activity …' },

	'Buchungsdatum': { [L.en]: 'Booking date' },

	/**
	 * scheduler.frontend.ng2/src/shared/api/scheduling-api/server-timer-api.service.ag.ts
	 */
	'Im Produktivmodus kann die Serverzeit nicht verändert werden.': { [L.en]: 'You can’t change the server time in production mode.'},

	/**
	 * scheduler.frontend.ng2/src/shared/api/scheduling-api/scheduling-api.service.ag.ts
	 */
	'Um diese Funktionalität nutzen zu können, setze bitte erst eine Widerrufsfrist.': { [L.en]: 'To use this feature, please enter a »cooling-off« period first.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Um diese Funktionalität nutzen zu können, aktiviere bitte erst die <a href="client/plugin/payments" target="_blank">Online-Zahlung</a>.': { [L.en]: 'To use this feature, please activate the <a href="client/plugin/payments" target="_blank">online payment</a> first.' },
	'Das Ende errechnet sich automatisch anhand des Startzeitpunktes der vorherigen Zeitspanne. So werden Lücken zwischen den Zeiträumen vermieden.': { [L.en]: 'The end is automatically calculated based on the starting time of the previous period. This avoids gaps between the periods.' },
	'Das Ende der ersten Zeitspanne muss so definiert sein, um Lücken zu vermeiden und sicherzustellen, dass auch Stornos nach dem gebuchten Termin abgedeckt sind. Bei mehrtägigen Angeboten gilt immer der Starttermin als Referenz.': { [L.en]: 'The end of the first period must be set like this to avoid gaps between the periods and ensure that cancelations after the booked date are still covered. For multi-day offers, the start date is always the relevant date as a reference.' },
	'Der Start deiner letzten Zeitspanne sollte »UNBEGRENZT« sein.': { [L.en]: 'The start of your last period should be set to »UNLIMITED«.' },
	'Bitte entweder vordefinierte Werte angeben oder die Wahl von individuellen Werten erlauben.' : { [L.en]: 'Please specify either predefined values or allow the choice of individual values.'},
	'Dieser Wert existiert bereits.' : { [L.en]: 'This value already exists.'},
	'Diese Email wurde anonymisiert.' : { [L.en]: 'This email has been anonymized.'},
	'Hierfür fehlt dir die Berechtigung.' : { [L.en]: 'You lack the permission for this.'},
	'Bitte mindestens einen Wochentag auswählen.' : { [L.en]: 'Please check at least one day of the week.'},
	'Email an Kunden verschickt' : { [L.en]: 'Email sent to customers'},
	'Keine Email an Kunden verschickt' : { [L.en]: 'No email sent to customers'},
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Entsprechend deiner <a href="client/shiftmodel/${shiftModelId}/bookingsettings#automatic-mails" target="_blank">Einstellungen</a> in der Tätigkeit.' : { [L.en]: 'According to your <a href="client/shiftmodel/${shiftModelId}/bookingsettings#automatic-mails" target="_blank">activity settings</a>.'},
	'An ${recipients}': { [L.en]: 'To the ${recipients}'},
	'buchende Person': { [L.en]: 'booking person'},
	'und': { [L.en]: 'and'},
	'An buchende Person und Teilnehmende': { [L.en]: 'To person booking and participants' },
	'An buchende Person': { [L.en]: 'To person booking' },
	'An Teilnehmende': { [L.en]: 'To participants' },
	'Stornierung der Buchung Nr. ${bookingNumber}': { [L.en]: 'Cancelation of booking no. ${bookingNumber}'},
	'Der eingegebene Betrag ist außergewöhnlich hoch. Falls er mit Sicherheit richtig ist, dann melde dich bitte bei uns für die Weiterbearbeitung.': { [L.en]: 'The entered amount is exceptionally high. If it is definitely correct, please get in touch with us for further processing.' },
	'Du kannst max. ${max} zurückerstatten, da das Online-Guthaben dieser Buchung für mehr nicht ausreicht.': { [L.en]: 'You can refund max. ${max}, as the online balance of this booking doesn’t allow more.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Du kannst max. ${max} zurückerstatten, da das <a href="client/sales/transactions" target="_blank" rel="noopener">Online-Guthaben</a> des gesamten Accounts abzüglich Gebühren für mehr nicht ausreicht.': { [L.en]: 'You can refund max. ${max}, as the online balance of your account minus fees doesn’t allow more.' },
	'Dein Kunde hat insgesamt ${max} eingezahlt. Du kannst nicht mehr als das zurückzahlen.': { [L.en]: 'Your customer has paid a total of ${max}. Unfortunately, you can’t refund more than that.' },
	'Du hast keine Berechtigung, Online-Rückerstattungen an Kunden für folgende Tätigkeiten zu veranlassen: <b>${listOfShiftModelsWithMissingOnlineRefundRight}</b>. Wende dich bitte an deine Admins, falls das geändert werden soll.': { [L.en]: 'You lack permission to initiate online refunds to customers for the following activities: <b>${listOfShiftModelsWithMissingOnlineRefundRight}</b>. Please refer to your admins if this needs to be changed.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Um Gutscheine online verkaufen & bezahlen lassen zu können, aktiviere bitte erst die <a href="client/plugin/payments" target="_blank">Online-Zahlung</a>.': { [L.en]: 'To sell gift cards online, please <a href="client/plugin/payments" target="_blank">enable online payments</a> first.' },
	'Es bleibt nicht genügend Zeit, um eine Frist zu setzen.': { [L.en]: 'There is not enough time to set a deadline.' },
	'Bitte aktiviere den Gutscheinverkauf, um fortfahren zu können.': { [L.en]: 'Please activate the gift card sale to continue.'},
	'Bitte verbinde die Schnittstelle zur Kassensoftware »Boulderado«, um fortfahren zu können.': { [L.en]: 'Please connect the interface to the POS »Boulderado« to continue.'},
	'Bitte aktiviere erst die Kassen-Synchronisation.': { [L.en]: 'Please activate the POS synchronization first.'},
	'Solange Gutscheine online eingelöst werden können, ist es aus Sicherheitsgründen nicht möglich, die Gutschein-Codes auf Ziffern zu beschränken.': { [L.en]: 'Gift card codes cannot be limited to digits for security reasons when they can be redeemed online.'},
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Um diese Option zu nutzen, musst du erst die <a href="client/plugin/gift-cards/synchronization" target="_blank">Synchronisation mit der Kasse »Boulderado« aktivieren</a>.': { [L.en]: 'To use this option, you must first enable the <a href="client/plugin/gift-cards/synchronization" target="_blank">synchronization with the POS »Boulderado«</a>.'},
	'Die Einstellungen für das Schicht-Paket kannst du nur in der Schichtvorlage oder beim Erstellen neuer Schichten bearbeiten. Hier ist es leider nicht mehr möglich.': { [L.en]: 'The shift package settings can only be edited in the shift template or when creating new shifts. Unfortunately, it’s no longer possible here.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Diese Rückzahlungsoption steht zur Verfügung, sobald du den <a href="client/plugin/gift-cards" target="_blank">Gutscheinverkauf über Dr. Plano</a> aktiviert hast.': { [L.en]: 'This refund option will be accessible after activating the <a href="client/plugin/gift-cards" target="_blank">gift card sale with Dr. Plano</a>.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Um diese Funktion nutzen zu können, musst du zuerst den <a href="client/plugin/gift-cards" target="_blank">Gutscheinverkauf aktivieren</a>.': { [L.en]: 'To use this feature, you must first <a href="client/plugin/gift-cards" target="_blank">activate the gift card sale</a>.' },
	'Die automatische Gutschein-Ausstellung steht derzeit nur für Buchungen zur Verfügung, die online bezahlt worden sind. Daher musst du erst die »automatische Rückzahlung per Online-Zahlung« aktivieren, bevor du diese Funktion nutzen kannst.': { [L.en]: 'The automatic gift card creation is only available for bookings paid online. Therefore, you must activate the »automatic refund via online payment« before using this feature.' },
	'Bitte wähle erst eine oder mehrere Schichten, da die Fristsetzung davon beeinflusst werden kann.': { [L.en]: 'Please select one or more shifts first, which may affect the deadline.' },
	'Um Marketing-Gutscheine versenden zu können, aktiviere den Gutschein-Verkauf': { [L.en]: 'To send marketing gift cards, activate the gift card sale' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Um Marketing-Gutscheine versenden zu können, musst du erst die Online-Einlösbarkeit deiner Gutscheine aktivieren, da Marketing-Gutscheine nur online über das Homepage-Plugin von Dr. Plano eingelöst werden können. Ändere jetzt die Einstellung': { [L.en]: 'To send marketing gift cards, you must first activate the online redemption of your gift cards, as marketing gift cards can only be redeemed online via Dr. Plano’s homepage plugin. Change the setting now here' },
	'Entsprechend der Platzbuchungs-Einstellung dieser Tätigkeit buchen Kunden Termine dieses Angebots komplett und keine einzelnen Plätze. Bei solchen Buchungen wird nur die Email-Adresse der buchenden Person, aber keine Email-Adressen der teilnehmenden Personen erfasst. Daher können keine Marketing-Gutscheine an teilnehmende Personen verschickt werden.': { [L.en]: 'According to the place booking setting of this activity, customers book the dates of this offer as a whole and not individual places. For this type of booking, only the email address of the booking person is recorded, but no participants’ email addresses. Therefore, no marketing gift cards can be sent to the participants.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Entsprechend der <a href="client/shiftmodel/${shiftModelId}/bookingsettings#only-whole-course-bookable-box" target="_blank">Platzbuchungs-Einstellung</a> dieser Tätigkeit buchen Kunden Termine dieses Angebots komplett und keine einzelnen Plätze. Bei solchen Buchungen wird nur die Email-Adresse der buchenden Person, aber keine Email-Adressen der teilnehmenden Personen erfasst. Daher können keine Marketing-Gutscheine an teilnehmende Personen verschickt werden.': { [L.en]: 'According to the <a href="client/shiftmodel/${shiftModelId}/bookingsettings#only-whole-course-bookable-box" target="_blank">place booking setting</a> of this activity, customers book the dates of this offer as a whole and not individual places. For this type of booking, only the email address of the booking person is recorded, but no participants’ email addresses. Therefore, no marketing gift cards can be sent to the participants.' },
	'Marketing-Gutscheine werden nicht erworben, sondern von deinem Betrieb für die Kunden ausgestellt. Deshalb steht diese Nachricht nicht zur Verfügung.': { [L.en]: 'Marketing gift cards do not get purchased but are issued by your company. So, this message is not available.' },
	'Die Besetzung dieser Schicht ist gerade in Bearbeitung und wird dir daher nicht angezeigt. Bei Fragen wende dich bitte an deine Admins.': { [L.en]: 'The assignment of this shift is currently being edited and will, therefore, not be shown to you. If you have any questions, please refer to your admins.' },

	/**
	 * scheduler.frontend.ng2/src/shared/api/scheduling-api/generate-gift-card-codes-api.service.ag.ts
	 */
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Der Code-Generator steht nur zur Verfügung, wenn du den <a href="client/plugin/gift-cards/settings">Gutscheinverkauf von Dr. Plano</a> nutzt.': { [L.en]: 'The code generator is only available if you use <a href="client/plugin/gift-cards/settings">Dr. Plano’s gift card sale</a>.'},
	'Nutze bitte einen Computer, um die Codes generieren und herunterladen zu können.': { [L.en]: 'Please use a computer to be able to generate and download these gift card codes.' },

	/**
	 * scheduler.frontend.ng2/src/client/plugin/gift-card-settings/tabs/gift-card-settings-tab-code-generator/gift-card-settings-tab-code-generator.component.ts
	 */
	'gutschein_codes': { [L.en]: 'gift_card_codes'},

	/**
	 * scheduler.frontend.ng2/src/shared/api/scheduling-api/scheduling-api-transactions.service.ts
	 */
	'Konto | Karte': { [L.en]: 'Account | card'},
	'Gutschein-Einlösung': { [L.en]: 'Gift card redemption'},
	'Gutschein-Einlösungen': { [L.en]: 'Gift card redemptions'},
	'Korrektur: Eingegangene Zahlung': { [L.en]: 'Correction: incoming payment'},
	'Korrektur: Gutschein-Einlösung': { [L.en]: 'Correction: gift card redemption'},

	/**
	 * scheduler.frontend.ng2/src/client/sales/shared/booking-transaction-form/booking-transaction-form.component.ts
	 */
	'Es kann nichts erstattet werden, da der Saldo aller bisherigen Einzahlungen und Rückerstattungen <code>0</code> ergibt.': { [L.en]: 'No refund is possible as the balance of all payments and refunds to date is <code>0</code>.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Um bequem Online-Rückerstattungen vornehmen zu können, aktiviere die <a href="client/plugin/payments" target="_blank">Online-Zahlungsfunktion</a>.': { [L.en]: 'To make online refunds conveniently activate the <a href="client/plugin/payments" target="_blank">online payment feature</a>.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Online-Rückerstattung aktuell nicht möglich, da diese Buchung kein Online-Guthaben aufweist. Mehr dazu erfährst du <a href="client/plugin/faq-online-payment#refund" target="_blank">hier</a>.': { [L.en]: 'Online refund currently not available, as this booking holds no online balance. Find out more <a href="client/plugin/faq-online-payment#refund" target="_blank">here</a>.' },
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Online-Rückerstattung aktuell nicht möglich, da dein gesamter Account <a href="client/sales/transactions" target="_blank">kein Online-Guthaben</a> aufweist.': { [L.en]: 'Online refund currently not available, as your entire account doesn’t hold any <a href="client/sales/transactions" target="_blank">online balance</a>.' },
	'Eine Rückerstattung ist aktuell nicht möglich, da keine Zahlungsarten zur Verfügung stehen. Um das zu ändern, aktiviere die Online-Zahlung oder lege in der gewünschten Tätigkeit unter »Buchungseinstellungen« andere Zahlungsarten an.': { [L.en]: 'Refund is currently not possible because there are no payment methods available. To change this, activate online payment or create other payment methods in the desired activity under »Booking settings«.' },
	'Du hast keine Berechtigung, Online-Rückerstattungen an Kunden zu veranlassen. Wende dich bitte an deine Personalleitung, falls das geändert werden soll.': { [L.en]: 'You lack permission to initiate online refunds to clients. Please refer to your admin if this needs to be changed.' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/shared/booking-cancel-form/booking-transaction-form.component.ts
	 */
	'(fix)': { [L.en]: '(fixed)' },
	'${percentage} vom Buchungspreis': { [L.en]: '${percentage} of the booking total' },
	'Die Angabe der Zahlungsart schafft Transparenz in der Kundenkommunikation. Außerdem kannst du so die Zahlungsvorgänge auch zu einem späteren Zeitpunkt gut nachvollziehen. Falls hier die benutzte Zahlungsart fehlt, dann musst du sie bitte erst in der Tätigkeit unter »Buchungseinstellungen« anlegen.': { [L.en]: 'Stating the payment method provides transparency in your customer communication. In addition, you can easily keep track of payment transactions in the future. If the used payment method is missing here, please add it to the activity on the tab »booking settings« first.' },
	'Gib bitte den Namen der verwendeten Zahlungsart an. Das sorgt für Transparenz in der Kundenkommunikation. Außerdem kannst du so die Zahlungsvorgänge zu einem späteren Zeitpunkt besser nachvollziehen.': { [L.en]: 'Stating the payment method provides transparency in your customer communication. In addition, you can easily keep track of payment transactions in the future.' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/shared/booking-cancel-form/booking-transaction-form.component.html
	 */
	// NOTE: We had a type error in the template when we used a semicolon in the string (for Dr.&nbsp;Plano). Could not figure out why.
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Hier kannst du Zahlungen erfassen, die du nicht per Online-Zahlung, sondern über eine andere Zahlungsart vorgenommen hast und die deshalb von Dr. Plano nicht automatisch erfasst werden konnten. Bevor du allerdings eine solche Zahlung eingeben kannst, musst du erst in der Tätigkeit unter »Buchungseinstellungen« die Zahlungsart anlegen. Aktuell sind nämlich keine Zahlungsarten vorhanden.': { [L.en]: 'Here you can record transactions that you haven’t made via online payment but using another payment method, which couldn’t be recorded automatically by Dr. Plano. Before recording such a transaction by yourself, you must create the used payment method in the related activity under »Booking settings«. Currently, there are no payment methods available.' },

	// NOTE: We had a type error in the template when we used a semicolon in the string (for Dr.&nbsp;Plano). Could not figure out why.
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Hier kannst du Einzahlungen erfassen, die nicht per Online-Zahlung, sondern über eine andere Zahlungsart erfolgt sind und die deshalb von Dr. Plano nicht automatisch erfasst werden konnten. Bevor du allerdings eine solche Zahlung eingeben kannst, musst du erst in der Tätigkeit unter »Buchungseinstellungen« die Zahlungsart anlegen. Aktuell sind nämlich keine Zahlungsarten vorhanden.': { [L.en]: 'Here you can record payments that didn’t come in via online payment but another payment method, which couldn’t be recorded automatically by Dr. Plano. Before recording such a payment by yourself, you must create the used payment method in the related activity under »Booking settings«. Currently, there are no payment methods available.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/api/scheduling-api.service.ts
	 */
	'Online-Rückerstattung erfolgreich veranlasst.<br>Das Geld sollte in wenigen Werktagen beim Kunden ankommen.': { [L.en]: 'Online refund initiated successfully. The money should reach your customer in a few business days.' },
	'Die Rückerstattung benötigte mehrere Teilzahlungen. Leider konnten nicht alle davon erfolgreich veranlasst werden. Für mehr Infos siehe »Zahlungen« in der Buchung.': { [L.en]: 'The refund required multiple partial payments. Unfortunately, not all of them could be initiated successfully. For more details, please check the payment details.' },
	'Die Online-Rückerstattung konnte leider nicht veranlasst werden. Bitte versuche es etwas später erneut.': { [L.en]: 'The online refund couldn’t be initiated, unfortunately. Please try again shortly.' },
	'Du brauchst mindestens eine buchbare Tätigkeit, um Buchungen erstellen zu können.': { [L.en]: 'You need at least one bookable activity to create bookings.' },
	'Dir fehlen die Rechte, um Buchungen erstellen zu können.': { [L.en]: 'You lack permission to create bookings.' },
	'Die angegebene Domain war bereits vorhanden und wurde daher automatisch gelöscht.': { [L.en]: 'The entered domain already existed and has been removed automatically.' },
	'Vorhandene Schichtbesetzung entfernt': { [L.en]: 'Existing assignment removed' },
	'Deine Übertragung hat bereits vorhandene Schichtbesetzungen entfernt. Eine genaue Auflistung haben wir an deine Email geschickt <mark>${email}</mark>': { [L.en]: 'Applying your changes to other shifts has removed the existing shift assignment. We have sent a detailed list to your email <mark>${email}</mark>' },
	'Aktuell fehlt die Email-Adresse der buchenden Person. Das erneute Versenden funktioniert erst, nachdem du eine Email-Adresse eingetragen hast.': { [L.en]: 'Currently the booking person’s email address is not available. Resending will only work after you have provided an email address.' },
	'Die Email-Adresse der buchenden Person fehlt.': { [L.en]: 'The booking person’s email is not available.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/api/scheduling-api-transactions.service.ts
	 */
	'für ${month}': { [L.en]: 'for ${month}' },
	'Konto:': { [L.en]: 'Bank account:' },
	'Die Kassensoftware Boulderado übermittelte hierzu keine Daten.': { [L.en]: 'The POS software Boulderado did not provide any information on this.' },
	'Diese manuell erfasste Gutschein-Einlösung wurde keiner Buchung zugeordnet.': { [L.en]: 'This manually recorded gift card redemption is not linked to a booking.' },
	'Bei einer automatisch erstellten Gutschein-Einlösungen liegen hierfür keine Daten vor.': { [L.en]: 'No info available for auto-created gift card redemptions.' },
	'Seit dem Release vom <mark>${date}</mark> werden für alle Gutschein-Einlösungen Transaktionen erstellt. Der hier betroffene Gutschein hatte zum Release-Zeitpunkt ein Restguthaben, das geringer war als sein Startguthaben. Daher erstellte Dr.&nbsp;Plano automatisch diese Einlösungstransaktion, um vom Startguthaben auf das niedrigere Restguthaben zu kommen.': { [L.en]: 'Since the release from <mark>${date}</mark>, transactions are created for all gift card redemptions. At release time, the gift card involved here had a remaining balance less than its starting balance. Therefore, Dr.&nbsp;Plano automatically created this redemption transaction to get from the starting balance to the lower remaining balance.' },
	'In Dr.&nbsp;Plano manuell erstellt durch ${firstName} ${lastName}.': { [L.en]: 'Created manually in Dr.&nbsp;Plano by ${firstName} ${lastName}.' },
	'Bei der Aktivierung der Gutschein-Synchronisation meldete Boulderado für den hier betroffenen Gutschein ein Restguthaben, das geringer war als das in Dr.&nbsp;Plano hinterlegte Restguthaben. Daher erstellte Dr.&nbsp;Plano automatisch diese Einlösungstransaktion, um auf das niedrigere Restguthaben zu kommen.': { [L.en]: 'When activating the gift card synchronization, Boulderado reported a remaining balance for this gift card that was lower than the remaining balance stored in Dr.&nbsp;Plano. Therefore Dr.&nbsp;Plano automatically created this redemption transaction to get to the lower remaining balance.' },
	'Diese eingegangene Zahlung wurde automatisch von Dr.&nbsp;Plano erstellt, um die – im Zuge der Aktivierung der Gutschein-Synchronisation – aus Boulderado kommenden Gutscheine als bezahlt und damit einlösbar markieren zu können.': { [L.en]: 'This payment was automatically created by Dr.&nbsp;Plano to mark the gift cards coming from Boulderado - during the activation of the gift card sync - as paid and, therefore, redeemable.' },
	'Bei kostenpflichtigen Buchungen werden deine Kunden auf die Payment-Seite von Dr.&nbsp;Plano weitergeleitet, wo sie über die Zahlungsart ihrer Wahl bezahlen können. Bei Buchungsanfragen oder anderen Zahlungsarten als die Online-Zahlung entscheiden sich deine Kunden auf der Payment-Seite für eine Zahlungsart, aber bezahlen erst später.': { [L.en]: 'If your clients make chargeable bookings, they will be directed to Dr.&nbsp;Plano’s payment page to pay using their preferred method. If they request a booking or prefer a payment method other than online, they will select a payment method on the payment page but pay later.' },
	'Entsprechend der Einstellungen in der betroffenen Tätigkeit hat Dr.&nbsp;Plano automatisch diese Rückzahlung per Gutschein-Ausstellung vorgenommen.': { [L.en]: 'Following the settings in the involved activity, Dr.&nbsp;Plano has automatically made this refund by issuing a gift card.' },

	/**
	 * scheduler.frontend.ng2/src/public/main/main.component.ts
	 */
	'Wähle deine länderspezifischen Preise': { [L.en]: 'Choose your country’s pricing' },
	'Preise für Betriebe in ${country}': { [L.en]: 'Pricing for companies in ${country}' },
	'${lowerLimit} - ${upperLimit} €': { [L.en]: '${lowerLimit} - ${upperLimit} €' },
	'bis ${upperLimit} €': { [L.en]: 'up to ${upperLimit} €' },
	'ab ${lowerLimit} €': { [L.en]: 'from ${lowerLimit} €' },
	'${lowerLimit} - ${upperLimit}': { [L.en]: '${lowerLimit} - ${upperLimit}' },
	'bis ${upperLimit}': { [L.en]: 'up to ${upperLimit}' },
	'ab ${lowerLimit}': { [L.en]: 'up to ${lowerLimit}' },
	'${lowerLimit} - ${upperLimit} User': { [L.en]: '${lowerLimit} - ${upperLimit} users' },
	'bis ${upperLimit} User': { [L.en]: 'up to ${upperLimit} users' },
	'ab ${lowerLimit} User': { [L.en]: 'from ${lowerLimit} users' },
	'Produkt Manager': { [L.en]: 'Product Manager' },
	'Frontend Lead': { [L.en]: 'Frontend Lead' },
	'Technical Lead': { [L.en]: 'Technical Lead' },
	'Administration & Datenschutz': { [L.en]: 'Administration & data protection' },
	'Customer Service': { [L.en]: 'Customer Service' },
	'Market Research': { [L.en]: 'Market Research' },
	'Frontend Developer': { [L.en]: 'Frontend Developer' },
	'Sales Manager': { [L.en]: 'Sales Manager'},
	'Unterstützung der Online-Zahlung für ${country}': { [L.en]: 'Online payment support for ${country}' },
	'Unterstützung der Online-Zahlung für mein Land': { [L.en]: 'Online payment support for my country' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-sidebar/p-assignment-processes/p-assignment-processes.component.ts
	 */
	'Es gibt keine Schichten mehr, wo du was zu tun hättest. Daher wurdest du zur ersten Schicht des Verteilungsvorgangs navigiert.': { [L.en]: 'There are no shifts left where you need to do something. Therefore, you have been navigated to the first shift of the scheduling task.' },
	'Es gab keine passenden Schichten in der Kalender-Ansicht, wo du dich befandest. Daher wurdest du zur ersten passenden Schicht navigiert.': { [L.en]: 'There were no matching shifts in the calendar view you had before. So, you were navigated to the first matching shift.' },

	/**
	 * scheduler.frontend.ng2/src/client/sales/shared/email-history/email-history.component.ts
	 */
	'Die Email wird an die aktuelle Adresse der buchenden Person verschickt: <strong>${mailBookingPerson}</strong><br/><br/>Der Email-Inhalt <strong>wird nicht aktualisiert</strong>, sondern genau so verschickt, wie er hier gespeichert ist. Falls darin enthaltene Informationen nicht mehr passen, kommuniziere das bitte separat mit deinem Kunden.': { [L.en]: 'The email will go to the current email address of the booking person: <strong>${mailBookingPerson}</strong><br/><br/>The email content <strong>will not be updated</strong> but sent out exactly as it is saved and shown here. If any information is no longer up-to-date, please make sure you inform your client separately.' },
	'Ja, senden': { [L.en]: 'Yes, send now' },

	/**
	 * scheduler.frontend.ng2/src/shared/p-forms/p-anchor/p-anchor.directive.ts
	 */
	'Der Link zum Abschnitt wurde in die Zwischenablage kopiert.': { [L.en]: 'The link to the section has been copied to the clipboard.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/api/scheduling-api-shiftmodel-course.service.ts
	 */
	'Diese Einstellung kannst du in einer bestehenden Buchung nicht ändern. Wenn du die Abfrage dieser zusätzlichen Info für zukünftige Buchungen ändern möchtest, kannst du das in der betroffenen Tätigkeit tun.': { [L.en]: 'You cannot change this setting in an existing booking. If you want to change it for future bookings, you can do so in the related activity.' },

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/api/scheduling-api-shiftmodel-course-payment-methods.service.ts
	 */

	/**
	 * scheduler.frontend.ng2/src/client/scheduling/shared/api/scheduling-api-shift-model.service.ts
	 */
	// eslint-disable-next-line literal-blacklist/literal-blacklist
	'Dieses Präfix wird bereits verwendet für <a href="${activityURL}" target="_blank" rel="noopener">»${activityName}«</a>.': { [L.en]: 'This prefix is already in use for <a href="${activityURL}" target="_blank" rel="noopener">»${activityName}«</a>.' },
	'Dieses Präfix wird bereits verwendet für die gelöschte Tätigkeit »${activityName}«. Gelöschte Tätigkeiten lassen sich nicht mehr ändern.': { [L.en]: 'This prefix is already in use for »${activityName}«. That offer has been deleted and cannot be edited anymore.' },
	'Nur Buchstaben und Zahlen sind erlaubt.': { [L.en]: 'Only letters and numbers are allowed.' },
	'Die kopierten Tarife eigneten sich nicht für deine gewählte Art der Platzbuchung. Sie wurden entfernt. Bitte lege neue Tarife an.': { [L.en]: 'The copied fees were not suitable for your selected type of place booking. They have been removed. Please create new fees.' },
	'Prüfe bitte die <mark>Buchungseinstellungen</mark>, da nicht alles übernommen werden konnte.': { [L.en]: 'Please check the <mark>booking settings</mark>, as not everything could be copied.' },
	'Prüfe bitte die <mark>Buchungseinstellungen</mark> sowie die Einstellung für <mark>Marketing-Gutscheine</mark>, da nicht alles übernommen werden konnte.': { [L.en]: 'Please check the settings for <mark>bookings</mark> and <mark>marketing gift cards</mark>, as not everything could be copied.' },

	/**
	 * scheduler.access/api/implementation_meta/time_stamp.xml
	 */
	'Bei dieser Endzeit wäre die eingetragene Pause länger als die gesamte Arbeitsdauer. Bitte wähle ein Ende nach »${greaterThan}«.': { [L.en]: 'With this end time, the recorded break would be longer than the total working time. Please choose an end after »${greaterThan}«.' },

	/**
	 * scheduler.access/api/implementation_meta/scheduling.xml
	 */
	'Es wurden noch keine Personen eingetragen, die »${name}« machen dürfen. Bei Fragen wende dich bitte an deine Admins.': { [L.en]: 'No users added yet who are allowed to do »${name}«. If you have any questions, please contact your admins.' },
	'Es wurden noch keine Personen eingetragen, die »${name}« machen dürfen.': { [L.en]: 'No users added yet who are allowed to do »${name}«.' },

	/**
	 * scheduler.frontend.ng2/src/client/shared/p-transactions/transactions-export/transactions-export.component.ts
	 */
	'CSV-Export': { [L.en]: 'CSV export' },
	'Excel-Export': { [L.en]: 'Excel export' },
	'PDF-Export': { [L.en]: 'PDF export' },
	'Risiko von doppelter Buchführung': { [L.en]: 'Risk of duplicates in the accounting' },
	'Exportieren': { [L.en]: 'Export' },

	/**
	 * scheduler.frontend.ng2/src/shared/core/prevent-navigation.service.ts
	 */
	'Kein Zugang': { [L.en]: 'No access' },

} as const;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PDictionarySourceString = keyof typeof DICTIONARY;

type TranslationObject<SourceString extends PDictionarySourceString> = typeof DICTIONARY[SourceString];

type PDictionaryPossibleLanguageKeys = keyof TranslationObject<PDictionarySourceString>;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PDictionaryTranslation<
	T extends PDictionarySourceString,
	L extends PDictionaryPossibleLanguageKeys,
> = TranslationObject<T>[L];

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const hasDictionaryEntry = (
	input : string,
) : boolean | undefined => {
	if (!input) {
		// We throw a error here instead of doing LogService.error(…), because it happens before the global error handler
		// is initialized.
		if (Config.DEBUG) throw new Error('input should be defined');
		return undefined;
	}
	return !!DICTIONARY[input as PDictionarySourceString];
};

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const getDictionaryEntry = <SourceString extends PDictionarySourceString, Language extends PDictionaryPossibleLanguageKeys>(
	input : PDictionarySourceString,
	language : Language,
) : PDictionaryTranslation<SourceString, Language> => {
	return DICTIONARY[input][language];
};
