import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { AttributeInfoBaseComponentDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { ApiDataWrapperBase, ApiObjectWrapper, PSimpleChanges } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { LogService } from '@plano/shared/core/log.service';
import { PFormArrayBasedOnAI, PFormGroupBasedOnAI } from '@plano/shared/p-forms/p-form-control';

/**
 * A component that turns a attributInfo of a attribute that has sub-attributes into a FormGroup.
 *
 * @example
 * Member has attribute job which is a object containing attributes like title and company.
 * In that case you want a form like this:
 *
 * <form [formGroup]="formGroup">
 * 	<p-ai-switch
 * 		[group]="formGroup"
 * 		[attributeInfo]="member.attributeInfoFirstName"
 * 	></p-ai-switch>
 * 	<p-ai-form-group
 * 		[group]="formGroup"
 * 		[attributeInfo]="member.job.attributeInfoThis"
 * 		#aiFormGroupRef="aiFormGroupRef"
 * 	>
 * 		<p-ai-switch
 * 			[group]="aiFormGroupRef.group"
 * 			[attributeInfo]="member.job.attributeInfoTitle"
 * 		></p-ai-switch>
 * 		<p-ai-switch
 * 			[group]="aiFormGroupRef.group"
 * 			[attributeInfo]="member.job.attributeInfoCompany"
 * 		></p-ai-switch>
 * 	</p-ai-form-group>
 */
@Component({
	selector: 'p-ai-form-group[attributeInfo][formParent]',
	styles: [':host { display: block; }'],
	templateUrl: './p-ai-form-group.component.html',
})
export class PAIFormGroupComponent<ParentType extends ApiDataWrapperBase> extends AttributeInfoBaseComponentDirective implements OnDestroy, OnChanges {

	/**
	 * The attributeInfo that should be added to the form.
	 * @example
	 *   attributeInfo="member.attributeInfoFirstName"
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() public override attributeInfo! : ApiAttributeInfo<ParentType, ApiObjectWrapper<any>>;

	/**
	 * Parent form element for the new PFormGroup that is related to the provided attributeInfo.
	 */
	@Input() public formParent ! : (
		FormGroup |
		FormArray<PFormGroupBasedOnAI> |
		PFormArrayBasedOnAI<PFormGroupBasedOnAI>
	);

	constructor(
		protected override console : LogService,
		private pFormsService : PFormsService,
		protected override changeDetectorRef : ChangeDetectorRef,
	) {
		super(true, changeDetectorRef, console);
	}

	private _childGroup : PFormGroupBasedOnAI | null = null;

	/**
	 * It is required that we update the childGroup everytime the formParent changes
	 */
	private updateChildGroupFormParent() : void {
		this._childGroup = this.pFormsService.getFormGroupByAI(this.formParent, this.attributeInfo) as PFormGroupBasedOnAI;
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * The PFormControl that is related to the provided attributeInfo.
	 */
	public get childGroup() : PFormGroupBasedOnAI {
		if (this._childGroup === null) {
			this.updateChildGroupFormParent();
		}
		return this._childGroup!;
	}

	private removeFormGroup() : void {
		if (this.formParent instanceof FormArray) {
			const control = this.formParent.controls.find(item => item.ai.id === this.attributeInfo.id);
			if (!control) return;
			const index = this.formParent.controls.indexOf(control);
			this.formParent.removeAt(index);
		} else {
			const control = this.formParent.get(this.attributeInfo.id);
			if (!control) return;
			this.formParent.removeControl(this.attributeInfo.id);
		}
		this.formParent.updateValueAndValidity();
		requestAnimationFrame(() => {
			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnDestroy() : void {
		this.removeFormGroup();
		this.changeDetectorRef.detectChanges();
	}

	public ngOnChanges(changes : PSimpleChanges<PAIFormGroupComponent<ParentType>>) : void {
		if (changes.formParent) {
			this.updateChildGroupFormParent();
		}
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.childGroup);
	}
}
