import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { SchedulingApiShiftMemberPrefValue } from '@plano/shared/api';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PCheckboxComponent } from '@plano/shared/p-forms/p-checkbox/p-checkbox.component';

@Component({
	selector: 'p-multi-select-checkbox',
	templateUrl: './../../../../shared/p-forms/p-checkbox/p-checkbox.component.html',
	styleUrls: ['./../../../../shared/p-forms/p-checkbox/p-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PMultiSelectCheckboxComponent),
			multi: true,
		},
		EditableDirective,
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PMultiSelectCheckboxComponent extends PCheckboxComponent
	implements ControlValueAccessor, AfterContentChecked {
	@HostBinding('class.small')
	@HostBinding('class.justify-content-stretch')
	protected override _alwaysTrue : boolean = true;

	@HostBinding('class.bg-white') private get _bgWhite() : boolean {
		return !this.isColored;
	}

	@HostBinding('style.width') private hasMinWidth : string = '2em';

	@HostBinding('class.not-disabled') private get _enabled() : boolean {
		return !this.disabled;
	}

	@HostBinding('class.bg-success') private get _hasBgSuccess() : boolean {
		return this.isWant;
	}
	@HostBinding('class.bg-warning') private get _hasBgWarning() : boolean {
		return this.isWarning;
	}
	@HostBinding('class.bg-danger') private get _hasBgDanger() : boolean {
		return this.isDanger;
	}
	@HostBinding('class.bg-dark') private get _hasBgDark() : boolean {
		return this.isDark;
	}

	@HostBinding('class.d-none') private get _thereIsNoUsefulMultiSelectCase() : boolean {
		return this.earlyBirdMode;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public meIsAssignable : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private myPref : SchedulingApiShiftMemberPrefValue | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private wishPickerMode : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private earlyBirdMode : boolean = false;

	constructor(
		changeDetectorRef : ChangeDetectorRef,
		console : LogService,
		pFormsService : PFormsService,
		modalService : ModalService,
	) {
		super(changeDetectorRef, console, pFormsService, modalService);

		// NOTE: This causes problems when selecting items in shift-picker on mobile
		// this.onClick.subscribe((event : MouseEvent) => { event.stopPropagation(); });

		this.hasButtonStyle = false;
		this._hasPaddingX = true;
		this._size = enumsObject.BootstrapSize.SM;
		this._readMode = false;
	}

	public override get valueIcon() : FaIcon {
		// if (this.singleSelectMode) return this.value ? PlanoFaIconPool.RADIO_SELECTED : PlanoFaIconPool.RADIO_UNSELECTED;
		return this.value ? enumsObject.PlanoFaIconPool.CHECKBOX_SELECTED : enumsObject.PlanoFaIconPool.CHECKBOX_UNSELECTED;
	}

	protected override attributeInfoRequired = false;

	// @Input() public singleSelectMode : boolean = false;

	public override ngAfterContentChecked() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.textWhite = this.isColored;
		return super.ngAfterContentChecked();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isColored() : boolean {
		return (
			this.wishPickerMode &&
			!this.disabled &&
			this.meIsAssignable
		);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isDark() : boolean {
		return this.isColored && !this.myPref;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isWant() : boolean {
		return this.isColored && this.myPref === SchedulingApiShiftMemberPrefValue.WANT;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isWarning() : boolean {
		return this.isColored && this.myPref === SchedulingApiShiftMemberPrefValue.CAN;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isDanger() : boolean {
		return this.isColored && this.myPref === SchedulingApiShiftMemberPrefValue.CANNOT;
	}
}
