/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "export_reporting_excel".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	WORKING_TIME_IDS = 1;
	ABSENCE_IDS = 2;
	MEMBER_IDS = 3;
	SHIFT_MODEL_IDS = 4;
}


@Injectable({
  providedIn: 'root',
})
export class ExportReportingExcelApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_reporting_excel');
	}

	protected version() : string {
		return 'a745a6329a3160050a6f45f7f26be98d,0505b5eddd380678dd1f4b376cb5c4c5';
	}

	private dataWrapper = new ExportReportingExcelApiRoot(this, );

	get data() : ExportReportingExcelApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportReportingExcelApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportReportingExcelApiRoot(this, );
	}
}

		 
export class ExportReportingExcelApiRoot extends ApiObjectWrapper<ExportReportingExcelApiRoot>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportReportingExcelApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportReportingExcelApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiRoot, ExportReportingExcelApiRoot> = new ApiAttributeInfo<ExportReportingExcelApiRoot, ExportReportingExcelApiRoot>({
			apiObjWrapper: this as any as ExportReportingExcelApiRoot,
			name: '',
			nodeName: 'ROOT',
			defaultValue: function(this : ExportReportingExcelApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private workingTimeIdsWrapper : ExportReportingExcelApiWorkingTimeIds = new ExportReportingExcelApiWorkingTimeIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false);
	public attributeInfoWorkingTimeIds = this.workingTimeIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the working-times to be exported.
     */
	get workingTimeIds() : ExportReportingExcelApiWorkingTimeIds {
		this.getterDebugValidations(this.attributeInfoWorkingTimeIds, false);
		return this.workingTimeIdsWrapper;
	}

	set workingTimeIdsTestSetter(v : ExportReportingExcelApiWorkingTimeIds) {
        this.setterImpl(1, v.rawData, 'workingTimeIds', true, null, () => {this.workingTimeIdsWrapper = v;});
	}

	private absenceIdsWrapper : ExportReportingExcelApiAbsenceIds = new ExportReportingExcelApiAbsenceIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false);
	public attributeInfoAbsenceIds = this.absenceIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the absences to be exported.
     */
	get absenceIds() : ExportReportingExcelApiAbsenceIds {
		this.getterDebugValidations(this.attributeInfoAbsenceIds, false);
		return this.absenceIdsWrapper;
	}

	set absenceIdsTestSetter(v : ExportReportingExcelApiAbsenceIds) {
        this.setterImpl(2, v.rawData, 'absenceIds', true, null, () => {this.absenceIdsWrapper = v;});
	}

	private memberIdsWrapper : ExportReportingExcelApiMemberIds = new ExportReportingExcelApiMemberIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false);
	public attributeInfoMemberIds = this.memberIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the members to be exported.
     */
	get memberIds() : ExportReportingExcelApiMemberIds {
		this.getterDebugValidations(this.attributeInfoMemberIds, false);
		return this.memberIdsWrapper;
	}

	set memberIdsTestSetter(v : ExportReportingExcelApiMemberIds) {
        this.setterImpl(3, v.rawData, 'memberIds', true, null, () => {this.memberIdsWrapper = v;});
	}

	private shiftModelIdsWrapper : ExportReportingExcelApiShiftModelIds = new ExportReportingExcelApiShiftModelIds(this.api,
		this as unknown as ExportReportingExcelApiRoot, false);
	public attributeInfoShiftModelIds = this.shiftModelIdsWrapper.attributeInfoThis;

	/**
     *  Id list of the shift model to be exported.
     */
	get shiftModelIds() : ExportReportingExcelApiShiftModelIds {
		this.getterDebugValidations(this.attributeInfoShiftModelIds, false);
		return this.shiftModelIdsWrapper;
	}

	set shiftModelIdsTestSetter(v : ExportReportingExcelApiShiftModelIds) {
        this.setterImpl(4, v.rawData, 'shiftModelIds', true, null, () => {this.shiftModelIdsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.workingTimeIdsWrapper._fixIds(_idReplacements);
		this.absenceIdsWrapper._fixIds(_idReplacements);
		this.memberIdsWrapper._fixIds(_idReplacements);
		this.shiftModelIdsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.workingTimeIdsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.absenceIdsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.memberIdsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		this.shiftModelIdsWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportReportingExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportReportingExcelApiWorkingTimeIds extends ApiListWrapper<ExportReportingExcelApiWorkingTimeId>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		override readonly parent : ExportReportingExcelApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'workingTimeIds');
	}

	override wrapRawData(itemRawData : any) : ExportReportingExcelApiWorkingTimeId {
		return new ExportReportingExcelApiWorkingTimeId(this.api, this as unknown as ExportReportingExcelApiWorkingTimeIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiWorkingTimeIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '2';
	}

	override createNewItem(_initCode : ((newItem : ExportReportingExcelApiWorkingTimeId) => void) | null = null, _backendId : Id | null = null) : ExportReportingExcelApiWorkingTimeId {
		const newItem = new ExportReportingExcelApiWorkingTimeId(this.api, this as unknown as ExportReportingExcelApiWorkingTimeIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('workingTimeIds');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeIds> = new ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiWorkingTimeIds,
			name: 'workingTimeIds',
			nodeName: 'WORKING_TIME_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportReportingExcelApiWorkingTimeIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 1,
		});
	attributeInfoWorkingTimeId : ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeId> = new ApiAttributeInfo<ExportReportingExcelApiWorkingTimeIds, ExportReportingExcelApiWorkingTimeId>({
			apiObjWrapper: this as any as ExportReportingExcelApiWorkingTimeIds,
			name: 'workingTimeId',
			nodeName: 'WORKING_TIME_ID',
			defaultValue: function(this : ExportReportingExcelApiWorkingTimeIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportReportingExcelApiWorkingTimeId extends ApiObjectWrapper<ExportReportingExcelApiWorkingTimeId>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		override readonly parent : ExportReportingExcelApiWorkingTimeIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportReportingExcelApiWorkingTimeId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportReportingExcelApiWorkingTimeId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiWorkingTimeId, ExportReportingExcelApiWorkingTimeId> = new ApiAttributeInfo<ExportReportingExcelApiWorkingTimeId, ExportReportingExcelApiWorkingTimeId>({
			apiObjWrapper: this as any as ExportReportingExcelApiWorkingTimeId,
			name: 'workingTimeId',
			nodeName: 'WORKING_TIME_ID',
			defaultValue: function(this : ExportReportingExcelApiWorkingTimeId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('WORKING_TIME_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '6';
	}

	static async loadDetailed(	api : ExportReportingExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '6', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportReportingExcelApiAbsenceIds extends ApiListWrapper<ExportReportingExcelApiAbsenceId>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		override readonly parent : ExportReportingExcelApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'absenceIds');
	}

	override wrapRawData(itemRawData : any) : ExportReportingExcelApiAbsenceId {
		return new ExportReportingExcelApiAbsenceId(this.api, this as unknown as ExportReportingExcelApiAbsenceIds, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiAbsenceIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '3';
	}

	override createNewItem(_initCode : ((newItem : ExportReportingExcelApiAbsenceId) => void) | null = null, _backendId : Id | null = null) : ExportReportingExcelApiAbsenceId {
		const newItem = new ExportReportingExcelApiAbsenceId(this.api, this as unknown as ExportReportingExcelApiAbsenceIds, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('absenceIds');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiAbsenceIds, ExportReportingExcelApiAbsenceIds> = new ApiAttributeInfo<ExportReportingExcelApiAbsenceIds, ExportReportingExcelApiAbsenceIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiAbsenceIds,
			name: 'absenceIds',
			nodeName: 'ABSENCE_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportReportingExcelApiAbsenceIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 2,
		});
	attributeInfoAbsenceId : ApiAttributeInfo<ExportReportingExcelApiAbsenceIds, ExportReportingExcelApiAbsenceId> = new ApiAttributeInfo<ExportReportingExcelApiAbsenceIds, ExportReportingExcelApiAbsenceId>({
			apiObjWrapper: this as any as ExportReportingExcelApiAbsenceIds,
			name: 'absenceId',
			nodeName: 'ABSENCE_ID',
			defaultValue: function(this : ExportReportingExcelApiAbsenceIds, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
}

				 
export class ExportReportingExcelApiAbsenceId extends ApiObjectWrapper<ExportReportingExcelApiAbsenceId>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		override readonly parent : ExportReportingExcelApiAbsenceIds | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportReportingExcelApiAbsenceId> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ExportReportingExcelApiAbsenceId as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiAbsenceId, ExportReportingExcelApiAbsenceId> = new ApiAttributeInfo<ExportReportingExcelApiAbsenceId, ExportReportingExcelApiAbsenceId>({
			apiObjWrapper: this as any as ExportReportingExcelApiAbsenceId,
			name: 'absenceId',
			nodeName: 'ABSENCE_ID',
			defaultValue: function(this : ExportReportingExcelApiAbsenceId, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ABSENCE_ID should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '7';
	}

	static async loadDetailed(	api : ExportReportingExcelApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '7', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class ExportReportingExcelApiMemberIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		override readonly parent : ExportReportingExcelApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'memberIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiMemberIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '4';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiMemberIds, ExportReportingExcelApiMemberIds> = new ApiAttributeInfo<ExportReportingExcelApiMemberIds, ExportReportingExcelApiMemberIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiMemberIds,
			name: 'memberIds',
			nodeName: 'MEMBER_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportReportingExcelApiMemberIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 3,
		});
	attributeInfoMemberId : ApiAttributeInfo<ExportReportingExcelApiMemberIds, Id> = new ApiAttributeInfo<ExportReportingExcelApiMemberIds, Id>({
			apiObjWrapper: this as any as ExportReportingExcelApiMemberIds,
			name: 'memberId',
			nodeName: 'MEMBER_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			
		});
}

				 export class ExportReportingExcelApiShiftModelIds extends ApiListWrapper<Id>
{
	constructor(override readonly api : ExportReportingExcelApiService | null,
		override readonly parent : ExportReportingExcelApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'shiftModelIds');
	}

	override wrapRawData(itemRawData : any) : Id {
		return Id.create(itemRawData);
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return true;
	}

	protected createInstance(_parent : ExportReportingExcelApiRoot | null, removeDestroyedItems : boolean) : this {
		return new ExportReportingExcelApiShiftModelIds(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '5';
	}

	override createNewItem(_initCode : ((newItem : Id) => void) | null = null) : Id {
		throw new Error('This operation is not supported for primitives. Just call push().');
	}

	override attributeInfoThis : ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, ExportReportingExcelApiShiftModelIds> = new ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, ExportReportingExcelApiShiftModelIds>({
			apiObjWrapper: this as any as ExportReportingExcelApiShiftModelIds,
			name: 'shiftModelIds',
			nodeName: 'SHIFT_MODEL_IDS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : ExportReportingExcelApiShiftModelIds, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 4,
		});
	attributeInfoShiftModelId : ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, Id> = new ApiAttributeInfo<ExportReportingExcelApiShiftModelIds, Id>({
			apiObjWrapper: this as any as ExportReportingExcelApiShiftModelIds,
			name: 'shiftModelId',
			nodeName: 'SHIFT_MODEL_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			
		});
}

		

