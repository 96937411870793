import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAiSwitchShowcaseCustomComponent } from '@plano/p-ai-switch-showcase/p-ai-switch-showcase-custom/p-ai-switch-showcase-custom.component';
import { PAiSwitchShowcaseDefaultComponent } from '@plano/p-ai-switch-showcase/p-ai-switch-showcase-default/p-ai-switch-showcase-default.component';
import { PAiSwitchShowcaseEditableComponent } from '@plano/p-ai-switch-showcase/p-ai-switch-showcase-editable/p-ai-switch-showcase-editable.component';
import { PComponentShowcaseComponent } from '@plano/p-component-showcase/p-component-showcase.component';
import { PRoutes } from './template-page-title-strategy.service';

export const ROUTES = [
	// { path: 'client', loadChildren: () => import('./client/client.module').then( (m) => m.ClientModule ) },
	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then( (m) => m.AdminModule ) },
	{ path: 'ai-switch-showcase/default', title:'Ai Switch Showcase > default', component: PAiSwitchShowcaseDefaultComponent },
	{ path: 'ai-switch-showcase/custom', title:'Ai Switch Showcase > custom', component: PAiSwitchShowcaseCustomComponent },
	{ path: 'ai-switch-showcase/editable', title:'Ai Switch Showcase > editable', component: PAiSwitchShowcaseEditableComponent },
	{ path: 'component-showcase/:openTab', component: PComponentShowcaseComponent},
	{ path: 'component-showcase', component: PComponentShowcaseComponent},
	{ path: '', loadChildren: () => import('./public/public.module').then( (m) => m.PublicModule ) },
] satisfies PRoutes;

@NgModule({
	imports: [RouterModule.forRoot(ROUTES, { useHash: false, anchorScrolling: 'enabled'})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
