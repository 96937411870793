<a
	[routerLink]="forceNavBackLink ?? navBackInfo.url"
	[fragment]="fragmentForNavBack ?? (routeId && routeIdForUrl ? routeIdForUrl : undefined)"
	[relativeTo]="navBackInfo.navigationExtras.relativeTo"
	[queryParams]="queryParams"
	[queryParamsHandling]="navBackInfo.navigationExtras.queryParamsHandling"
	[preserveFragment]="!!navBackInfo.navigationExtras.preserveFragment"
	[skipLocationChange]="!!navBackInfo.navigationExtras.skipLocationChange"
	[replaceUrl]="!!navBackInfo.navigationExtras.replaceUrl"
	[state]="navBackInfo.navigationExtras.state"
	(click)="onNavigation($event)"
	role="button"
	class="btn btn-sm btn-outline-secondary mr-auto">
	<fa-icon
		[fixedWidth]="true"
		[icon]="icon"
		[class.text-danger]="hasTextDanger">
	</fa-icon>
	{{ text | localize }}
</a>
