import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Observable } from 'rxjs';
import { PDictionarySourceString } from './pipe/localize.dictionary';
import { ValidatorsService } from './validators.service';

/**
 * These are all the possible error keys that we have in our app.
 * The values of the enum MUST BE LOWERCASE!
 */
/* cSpell:disable */
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PPossibleErrorNames {
	CURRENCY = 'currency',
	REQUIRED = 'required',
	NOT_UNDEFINED = 'notundefined',
	MIN_LENGTH = 'minlength',
	MAX_LENGTH = 'maxlength',
	MAX_DECIMAL_PLACES_COUNT = 'maxdecimalplacescount',

	MAX_FILE_SIZE = 'maxfilesize',
	IMAGE_RATIO = 'imageratio',
	IMAGE_MIN_WIDTH = 'imageminwidth',
	IMAGE_MIN_HEIGHT = 'imageminheight',
	IMAGE_MAX_WIDTH = 'imagemaxwidth',
	IMAGE_MAX_HEIGHT = 'imagemaxheight',

	PDF_MAX_PAGES = 'pdfmaxpages',
	PDF_PAGE_DIMENSION = 'pdfpagedimension',

	MAX = 'max',
	MIN = 'min',
	GREATER_THAN = 'greaterthan',
	LESS_THAN = 'lessthan',
	UPPERCASE = 'uppercase',
	UPPERCASE_REQUIRED = 'uppercaserequired',
	FLOAT = 'float',
	TIME = 'time',
	INTEGER = 'integer',
	NUMBER_NAN = 'numbernan',
	PHONE = 'phone',
	PASSWORD = 'password',
	PASSWORD_UNCONFIRMED = 'passwordunconfirmed',
	WHITESPACE = 'whitespace',
	PLZ = 'plz',
	EMAIL = 'email',
	URL = 'url',
	DOMAIN = 'domain',
	URL_INCOMPLETE = 'urlincomplete',
	URL_PROTOCOL_MISSING = 'urlprotocolmissing',
	IBAN = 'iban',
	BIC = 'bic',
	EMAIL_WITHOUT_AT = 'emailwithoutat',
	ID_DEFINED = 'iddefined',
	EMAIL_USED = 'emailused',
	EMAIL_INVALID = 'emailinvalid',

	// Only to be used in emailAsync since it can give two different email errors
	EMAIL_HAS_ERROR = 'emailerror',
	NUMBERS_REQUIRED = 'numbersrequired',
	LETTERS_REQUIRED = 'lettersrequired',
	PATTERN = 'pattern',
	FIRST_FEE_PERIOD_START_IS_NULL = 'firstfeeperiodstartisnull',
	DUPLICATE_PAYMENT_METHOD_NAME = 'duplicatepaymentmethodname',
	DUPLICATE_PAYMENT_METHOD = 'duplicatepaymentmethod',
	ONE_WEEK_DAY_IS_SELECTED = 'oneweekdayisselected',
	ENSURE_NULL = 'ensurenull',
	FREE_PRICE_CHOICE_OR_PREDEFINED_PRICES_NOT_SET = 'freepricechoiceorpredefinedpricesnotset',
	DUPLICATE_PREDEFINED_PRICES = 'duplicatepredefinedprices',

	/**
	 * »Is already in use«.
	 * E.g. for shifModel prefix.
	 * For used email address error, see EMAIL_USED.
	 */
	OCCUPIED = 'occupied',
}

/* cSpell:enable */

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface PValidationErrorValue extends ValidationErrors {
	name : PPossibleErrorNames,

	// TODO: get rid of null and undefined here
	primitiveType : PApiPrimitiveTypes | null | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	actual ?: any,
	comparedAttributeName ?: string | undefined,

	/**
	 * Set this to a string, which is translated via translatePipe, or a function which returns such string, if
	 * you have some variables to process/keep up to date in the ui. E.g. a comparedConst which can change sometime.
	 */
	errorText ?: PDictionarySourceString | (() => PDictionarySourceString) | undefined,
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface PValidationErrors<T extends PValidationErrorValue = PValidationErrorValue> extends ValidationErrors {
	[key : string] : T;
}

type ComparedConstValueType = string | number | boolean | null;

// eslint-disable-next-line jsdoc/require-jsdoc -- Remove this before you work here.
export interface AsyncValidatorObject {
	fn : AsyncValidatorFn,
	comparedConst : ComparedConstValueType
}

type FnType<Type = 'async' | 'sync', ValidatorFnReturnType extends PValidationErrors | null = PValidationErrors> = Type extends 'async' ?
	(control : AbstractControl) => Promise<ValidatorFnReturnType | null> | Observable<ValidatorFnReturnType | null> :
	(control : Pick<AbstractControl, 'value'>) => ValidatorFnReturnType | null;

type TName<ValidatorFnReturnType extends PValidationErrors | null> = Exclude<ValidatorFnReturnType, null>[string]['name'];

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PValidatorObject<

	/** Is this an object for async or for sync validators? */
	Type extends 'async' | 'sync' = 'sync',

	/** The type that the validatorFn returns if this is a sync validator */
	ValidatorFnReturnType extends PValidationErrors | null = PValidationErrors | null,

	/** Type of the const that the validator compares against */
	ComparedConstType = ComparedConstValueType | (() => ComparedConstValueType),
> {
	constructor(input : {
		fn : FnType<Type, ValidatorFnReturnType>,

		/** Error-Name */
		name : TName<ValidatorFnReturnType>,
		comparedConst ?: ComparedConstType,
		comparedAttributeName ?: string | undefined,
		additionalParams ?: Record<string, unknown>,
	}) {
		this.name = input.name;
		this.fn = input.fn;
		this.comparedConst = input.comparedConst ?? null;
		this.comparedAttributeName = input.comparedAttributeName;
		this.additionalParams = input.additionalParams;
	}

	public comparedConst ?: ComparedConstType | null;
	public fn : FnType<Type, ValidatorFnReturnType>;
	public name : PPossibleErrorNames | null = null;
	public comparedAttributeName ?: string | undefined;

	/** Additional params that got passed when the validator got attached */
	public additionalParams ?: Record<string, unknown> | undefined;
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PValidatorFn<

	/** Is this an object for async or for sync validators? */
	Type extends 'async' | 'sync' = 'sync',
	ValidatorFnReturnType extends PValidationErrors | null = PValidationErrors,
> = PValidatorObject<Type, ValidatorFnReturnType>['fn'];

/** All possible validator names for our validatorService based helper types */
type ValidatorNames = Exclude<keyof ValidatorsService, 'nullValidator'>;

type ValidatorsServiceFnReturnTypes<FnName extends ValidatorNames> = (
	ReturnType<ValidatorsService[FnName]>['fn']
);

type ValidatorsWithNonDeprecatedReturnType<FnName extends ValidatorNames> =
	Exclude<
		ValidatorsServiceFnReturnTypes<FnName>,
		null | PValidationErrors
	>;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type ValidatorsServiceErrorsType<FnName extends ValidatorNames> =
	Exclude<
		ReturnType<ValidatorsWithNonDeprecatedReturnType<FnName>>,
		null
	>;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type ValidatorsServiceReturnType<FnName extends ValidatorNames> =
	ValidatorsServiceErrorsType<FnName>[PPossibleErrorNames];
