<div class="navbar navbar-expand navbar-dark bg-primary" role="navigation">
	<div class="d-flex align-items-center w-100" >
		<a class="logo d-lg-none navbar-brand text-white" routerLink="/client/">
			<img alt="Dr.&nbsp;Plano App" i18n-alt src="images/plano-logo/p-bg-dark.svg">
		</a>
		<a class="logo d-none d-lg-flex align-items-center navbar-brand text-white" routerLink="/client/">
			<img alt="Dr.&nbsp;Plano App" i18n-alt src="images/plano-logo/logo-bg-primary.svg">
		</a>
		<span
			*ngIf="Config.IS_MOBILE && meService.isLoaded()"
			class="text-white crop-on-overflow"
			i18n
		>Hi {{meService.data.firstName}}</span>

		<ul class="navbar-nav flex-row mt-0 d-flex align-items-stretch justify-content-center flex-grow-1">
			<ng-template [ngIf]="!Config.IS_MOBILE && !meService.showExpiredClientView">
				<li class="nav-item p-0 mr-1 d-flex align-items-stretch min-w-fit-content">
					<a
						class="nav-link btn btn-primary d-flex align-items-center justify-content-center nowrap"
						routerLink="/client/scheduling"
						routerLinkActive="active"
						role="button"
					><fa-icon
						class="header-fa-icon"
						[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.AREA_SCHEDULING"
					></fa-icon><span class="ml-1 d-none d-lg-inline" i18n>Kalender</span></a>
				</li>
				<li class="nav-item p-0 mr-1 d-flex align-items-stretch min-w-fit-content">
					<a
						class="nav-link btn btn-primary d-flex align-items-center justify-content-center nowrap"
						routerLink="/client/time-stamp"
						routerLinkActive="active"
						role="button"
					>
						<span class="stopwatch-image"><p-stopwatch-image [invertedColors]="true"></p-stopwatch-image></span><span class="ml-1 d-none d-lg-inline" i18n>Stempeluhr</span>
					</a>
				</li>
				<li class="nav-item p-0 mr-1 d-flex align-items-stretch min-w-fit-content">
					<a
						class="nav-link btn btn-primary d-flex align-items-center justify-content-center nowrap"
						routerLink="/client/shift-exchanges"
						routerLinkActive="active"
						role="button"
					>
						<fa-icon
							class="header-fa-icon text-right"
							[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.ITEMS_SHIFT_EXCHANGE"
						></fa-icon><span class="ml-1 d-none d-lg-inline" i18n>Tauschbörse</span>
					</a>
				</li>
				<li class="nav-item p-0 mr-1 d-flex align-items-stretch min-w-fit-content">
					<a
						class="nav-link btn btn-primary d-flex align-items-center justify-content-center nowrap"
						routerLink="/client/report"
						routerLinkActive="active"
						role="button"
					><span class="report-icon"><img alt="Auswertung" i18n-alt src="images/icons/report.png"></span><span class="ml-1 d-none d-lg-inline" i18n>Auswertung</span></a>
				</li>
				<li class="nav-item p-0 mr-1 d-flex align-items-stretch min-w-fit-content" *ngIf="showSalesBtn">
					<a
						class="nav-link btn btn-primary d-flex align-items-center justify-content-center nowrap"
						routerLink="/client/sales"
						routerLinkActive="active"
						role="button"
					><fa-icon
						class="header-fa-icon text-right"
						[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.ITEMS_SALES"
					></fa-icon><span class="ml-1 d-none d-lg-inline" i18n>Verkäufe</span></a>
				</li>
				<li class="nav-item p-0 mr-1 d-flex align-items-stretch min-w-fit-content tutorial-videos flex-grow-1">
					<a
						class="nav-link btn btn-primary d-flex align-items-center justify-content-center nowrap"
						routerLink="/client/tutorials"
						routerLinkActive="active"
						role="button"
					><fa-icon
						class="header-fa-icon text-right"
						[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.AREA_TUTORIALS"
					></fa-icon><span class="ml-1 d-none d-lg-inline tutorial-videos-label nowrap" i18n>Tutorials</span></a>
				</li>
			</ng-template>
			<li class="d-none d-sm-flex align-items-stretch nav-item test-account crop-on-overflow mr-1"
				[class.ml-auto]="meService.isLoaded() && meService.data.isOwner"
				*ngIf="meService.isLoaded() && meService.data.isOwner && meService.data.clientType === AuthenticatedApiClientType.TEST"
			>
				<a
					class="d-flex btn btn-outline-light gap-2 align-items-center"
					routerLink="/client/testaccount"
					routerLinkActive="active"
					role="button"
				>
					<fa-icon icon="hourglass-half"></fa-icon>
					<span class="crop-on-overflow">{{ testaccountButtonText }}</span>
				</a>
			</li>
			<li class="d-none d-sm-flex align-items-center nav-item ml-auto badge badge-danger p-3 min-w-fit-content"
				*ngIf="meService.isLoaded() && meService.data.isOwner && meService.data.clientType === AuthenticatedApiClientType.TERMINATED"
			>
				❌ Gekündigt
			</li>

			<li *ngIf="meService.showExpiredClientView; else notExpiredTemplate" class="nav-item ml-auto min-w-fit-content">
				<button type="button" class="nav-link btn clickable" [routerLink]="Config.LOGOUT_PATH" i18n>Abmelden</button>
			</li>
			<ng-template #notExpiredTemplate>
				<ng-template [ngIf]="Config.IS_MOBILE" [ngIfElse]="notMobileTemplate">
					<li class="nav-item p-0 mr-1 ml-auto min-w-fit-content">
						<a
							class="btn btn-primary d-flex align-items-center justify-content-center nowrap"
							[routerLink]="isMenuPage ? pRouterService.navBackInfo().url : '/client/menu'"
							(click)="isMenuPage ? pRouterService.forgetMostRecentHistoryEntry() : undefined"
							[class.active]="isMenuPage"
							role="button"
						><fa-icon
							class="header-fa-icon"
							[fixedWidth]="true" [icon]="isMenuPage ? enums.PlanoFaIconPool.DISMISS : enums.PlanoFaIconPool.MOBILE_MENU"
						></fa-icon><span class="ml-1 d-none d-lg-inline" i18n>Menü</span></a>
					</li>
				</ng-template>

				<ng-template #notMobileTemplate>
					<li class="nav-item d-flex align-items-stretch flex-grow-1 crop-on-overflow no-tooltip-when-cropped justify-content-end p-0 dropdown-on-header">
						<div
							class="d-flex align-items-stretch"
							tabindex="0"
							*ngIf="meService.isLoaded()"
						>
							<ng-template #headerMemberBadge>
								<p-member-badge
									(onAvatarImgError)="handleAvatarImgError()"
									[size]="enums.BootstrapSize.LG"
									[isMe]="true"
									[firstName]="meService.data.firstName"
									[lastName]="meService.data.lastName"
									[avatar]="meService.data.avatar"
									[memberId]="meService.data.id"
									[showPIconWhenLoggedInWithMasterPassword]="true"
								></p-member-badge>
							</ng-template>
							<ng-template #dropdownTriggerBtn>
								<a
									tabindex="0"
									(click)="dropdownRef!.onClickTrigger(dropdownRef!.modalContent!.template)"
									class="dropdown-toggle btn btn-primary h-100 text-white nowrap d-flex align-items-center crop-on-overflow no-tooltip-when-cropped gap-2"
									[attr.role]="'combobox'"
									[attr.aria-expanded]="dropdownRef?.dropdownMenuVisible"
								>
									<span
										*ngIf="avatarHasErrors; else headerMemberBadge" class="crop-on-overflow no-tooltip-when-cropped"
									>
										<strong class="crop-on-overflow">{{ meService.data.firstName }}</strong>
									</span>
									<span
										class="hide-on-container crop-on-overflow no-tooltip-when-cropped gap-2"
									>
										<fa-icon class="text-black-50 d-inline-block" [icon]="enums.PlanoFaIconPool.PIPE_SEPARATOR"></fa-icon>
										<span class="crop-on-overflow no-tooltip-when-cropped">{{ locationName }}</span>
									</span>
								</a>
							</ng-template>

							<p-dropdown
								*ngIf="!Config.IS_MOBILE"
								#dropdownRef
								[label]="locationName"
								dropdownToggleTriangleClasses="hide-on-container"
								[triggerTemplate]="dropdownTriggerBtn"
								[dropdownType]="DropdownTypeEnum.ACTIONS"
								[appendDropdownOnBody]="true"
								class="align-self-center h-100">
								<ng-container *ngFor="let dropdownItem of dropdownNavigationItems">
									<p-dropdown-item
										*ngIf="dropdownItem.condition()"
										[isAlignedLeft]="true"
										[customIconWidth]="memberBadge ? memberBadge.badgeNativeWidth : null"
										[prependedItem]="$any(dropdownItem.prependedItem) ?? memberBadgeDropdownItemTemplate"
										[label]="dropdownItem.label | localize"
										[link]="dropdownItem.link"/>
								</ng-container>
								<p-dropdown-item
									class="bg-secondary"
									*ngIf="meService.data.loggedInWithMasterPassword && !meService.hideDebugInformation"
									(onClick)="meService.hideDebugInformation = true"
									label="Hide debug information" />
								<p-dropdown-item
									*ngIf="devToolsVisible"
									(onClick)="navBack()"
									label="Simulate Android‘s Back-Button"
									icon="bug">
								</p-dropdown-item>
								<p-dropdown-item
									*ngIf="devToolsVisible"
									(onClick)="debugToggleLocalizeLanguage()"
									label="Set Language to {{otherLanguage}} in LocalizePipe"
									icon="bug">
								</p-dropdown-item>
							</p-dropdown>
						</div>

					</li>
				</ng-template>

			</ng-template>

		</ul>
	</div>
</div>

<ng-template #memberBadgeDropdownItemTemplate>
	<p-member-badge
		#memberBadge
		[memberId]="meService.data.id"
		[firstName]="meService.data.firstName"
		[lastName]="meService.data.lastName"
		[avatar]="meService.data.avatar"
		[isMe]="true"
		size="normal"/>&nbsp;</ng-template
>

