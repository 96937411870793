import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-forecast-badge',
	templateUrl: './forecast-badge.component.html',
	styleUrls: ['./forecast-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ForecastBadgeComponent {
	public readonly CONFIG = Config;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;
}
