/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';

import { TimeStampApiRoot } from '@plano/shared/api';
import { TimeStampApiShifts } from '@plano/shared/api';
import { TimeStampApiShift } from '@plano/shared/api';
import { TimeStampApiShiftModels } from '@plano/shared/api';
import { TimeStampApiShiftModel } from '@plano/shared/api';
import { TimeStampApiStampedMember } from '@plano/shared/api';
import { TimeStampApiAllowedTimeStampDevices } from '@plano/shared/api';
import { TimeStampApiAllowedTimeStampDevice } from '@plano/shared/api';


/**
 * This service enables access to the api "time_stamp".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	START = 1;
	END = 2;
	COMMENT = 3;
	COMPLETED_REGULAR_PAUSES_DURATION = 4;
	UNCOMPLETED_REGULAR_PAUSE_START = 5;
	AUTOMATIC_PAUSE_DURATION = 6;
	SELECTED_SHIFT_ID = 7;
	SELECTED_SHIFT_MODEL_ID = 8;
	SHIFTS = 9;
	SHIFT_MODELS = 10;
	STAMPED_MEMBERS = 11;
	ALLOWED_TIME_STAMP_DEVICES = 12;
	WARN_UNPLANNED_WORK = 13;
	WARN_STAMPED_NOT_CURRENT_TIME = 14;
	WARN_STAMPED_NOT_SHIFT_TIME = 15;
	WHEN_MEMBER_STAMPED_START = 16;
	SHIFT_START = 1;
	SHIFT_END = 2;
	SHIFT_MODEL_ID = 3;
	SHIFT_MODEL_COLOR = 1;
	SHIFT_MODEL_TIME = 2;
	SHIFT_MODEL_PARENT_NAME = 3;
	SHIFT_MODEL_NAME = 4;
	SHIFT_MODEL_TRASHED = 5;
	SHIFT_MODEL_ASSIGNABLE = 6;
	SHIFT_MODEL_TIME_START = 1;
	STAMPED_MEMBER_FIRST_NAME = 1;
	STAMPED_MEMBER_LAST_NAME = 2;
	STAMPED_MEMBER_PAUSING = 3;
	STAMPED_MEMBER_ACTIVITY_START = 4;
	STAMPED_MEMBER_AVATAR = 5;
	ALLOWED_TIME_STAMP_DEVICE_NAME = 1;
	ALLOWED_TIME_STAMP_DEVICE_VISITOR_ID = 2;
	ALLOWED_TIME_STAMP_DEVICE_BROWSER_NAME = 3;
}


@Injectable({
  providedIn: 'root',
})
export class TimeStampApiServiceBase extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'time_stamp');
	}

	protected version() : string {
		return 'df90550979f1afdf0a15bfcc4a5d8dc6,4642d2391c9ae73a307464072eaeab32';
	}

	private dataWrapper = new TimeStampApiRoot(this, );

	get data() : TimeStampApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : TimeStampApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new TimeStampApiRoot(this, );
	}
}

		 
export class TimeStampApiRootBase extends ApiObjectWrapper<TimeStampApiRoot>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<TimeStampApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, TimeStampApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiRoot, TimeStampApiRoot> = new ApiAttributeInfo<TimeStampApiRoot, TimeStampApiRoot>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: '',
			nodeName: 'ROOT',
			defaultValue: function(this : TimeStampApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoStart : ApiAttributeInfo<TimeStampApiRoot, DateTime> = new ApiAttributeInfo<TimeStampApiRoot, DateTime>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'start',
			nodeName: 'START',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : TimeStampApiRoot) {
				return [
					() => {
return this.api!.validators.min(+this.api!.pMoment.daysFromNow(-7), true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.max(+this.api!.pMoment.m().add(10, 'minutes'), true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : TimeStampApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoEnd : ApiAttributeInfo<TimeStampApiRoot, DateTime> = new ApiAttributeInfo<TimeStampApiRoot, DateTime>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'end',
			nodeName: 'END',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : TimeStampApiRoot) {
				return [
					() => {
return this.api!.validators.min(() => this.start, false, PApiPrimitiveTypes.DateTime, this.attributeInfoStart.id, undefined);						return null;
					},
					() => {
return this.api!.validators.min(() => this.start !== null && this.end && this.end > this.start ? this.start + this.regularPauseDuration : null, false, PApiPrimitiveTypes.DateTime, undefined, 'Bei dieser Endzeit wäre die eingetragene Pause länger als die gesamte Arbeitsdauer. Bitte wähle ein Ende nach »${greaterThan}«.');						return null;
					},
					() => {
return this.api!.validators.max(+this.api!.pMoment.m().add(10, 'minutes'), true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : TimeStampApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoComment : ApiAttributeInfo<TimeStampApiRoot, string> = new ApiAttributeInfo<TimeStampApiRoot, string>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'comment',
			nodeName: 'COMMENT',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 3,
		});
	attributeInfoCompletedRegularPausesDuration : ApiAttributeInfo<TimeStampApiRoot, Duration> = new ApiAttributeInfo<TimeStampApiRoot, Duration>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'completedRegularPausesDuration',
			nodeName: 'COMPLETED_REGULAR_PAUSES_DURATION',
			primitiveType: PApiPrimitiveTypes.Duration,
			rawDataIndex: 4,
		});
	attributeInfoUncompletedRegularPauseStart : ApiAttributeInfo<TimeStampApiRoot, DateTime> = new ApiAttributeInfo<TimeStampApiRoot, DateTime>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'uncompletedRegularPauseStart',
			nodeName: 'UNCOMPLETED_REGULAR_PAUSE_START',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 5,
		});
	attributeInfoAutomaticPauseDuration : ApiAttributeInfo<TimeStampApiRoot, Duration> = new ApiAttributeInfo<TimeStampApiRoot, Duration>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'automaticPauseDuration',
			nodeName: 'AUTOMATIC_PAUSE_DURATION',
			primitiveType: PApiPrimitiveTypes.Duration,
			rawDataIndex: 6,
		});
	attributeInfoSelectedShiftId : ApiAttributeInfo<TimeStampApiRoot, ShiftId> = new ApiAttributeInfo<TimeStampApiRoot, ShiftId>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'selectedShiftId',
			nodeName: 'SELECTED_SHIFT_ID',
			primitiveType: PApiPrimitiveTypes.ShiftId,
			rawDataIndex: 7,
		});
	attributeInfoSelectedShiftModelId : ApiAttributeInfo<TimeStampApiRoot, Id> = new ApiAttributeInfo<TimeStampApiRoot, Id>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'selectedShiftModelId',
			nodeName: 'SELECTED_SHIFT_MODEL_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			rawDataIndex: 8,
		});
	attributeInfoWarnUnplannedWork : ApiAttributeInfo<TimeStampApiRoot, boolean> = new ApiAttributeInfo<TimeStampApiRoot, boolean>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'warnUnplannedWork',
			nodeName: 'WARN_UNPLANNED_WORK',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : TimeStampApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});
	attributeInfoWarnStampedNotCurrentTime : ApiAttributeInfo<TimeStampApiRoot, boolean> = new ApiAttributeInfo<TimeStampApiRoot, boolean>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'warnStampedNotCurrentTime',
			nodeName: 'WARN_STAMPED_NOT_CURRENT_TIME',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : TimeStampApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 14,
		});
	attributeInfoWarnStampedNotShiftTime : ApiAttributeInfo<TimeStampApiRoot, boolean> = new ApiAttributeInfo<TimeStampApiRoot, boolean>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'warnStampedNotShiftTime',
			nodeName: 'WARN_STAMPED_NOT_SHIFT_TIME',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : TimeStampApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 15,
		});
	attributeInfoWhenMemberStampedStart : ApiAttributeInfo<TimeStampApiRoot, DateTime> = new ApiAttributeInfo<TimeStampApiRoot, DateTime>({
			apiObjWrapper: this as any as TimeStampApiRoot,
			name: 'whenMemberStampedStart',
			nodeName: 'WHEN_MEMBER_STAMPED_START',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 16,
		});

	/**
     *  Start of working time.
	 *
	 * @type {DateTime}
     */
	get start() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoStart, false);
		return this.data[1];
	}

	set start(v : DateTime | null) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
     *  End of working time.
	 *
	 * @type {DateTime}
     */
	get end() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoEnd, false);
		return this.data[2];
	}

	set end(v : DateTime | null) {
        this.setterImpl(2, v, 'end', false, null, null);
	}

	/**
     *  User comment.
	 *
	 * @type {string}
     */
	get comment() : string | null {
		this.getterDebugValidations(this.attributeInfoComment, false);
		return this.data[3];
	}

	set comment(v : string | null) {
        this.setterImpl(3, v, 'comment', false, null, null);
	}

	/**
     *  The duration of all the regular pauses (done by member) which have been completed in milliseconds.
	 *
	 * @type {Duration}
     */
	get completedRegularPausesDuration() : Duration {
		this.getterDebugValidations(this.attributeInfoCompletedRegularPausesDuration, false);
		return this.data[4];
	}

	set completedRegularPausesDuration(v : Duration) {
        this.setterImpl(4, v, 'completedRegularPausesDuration', false, null, null);
	}

	/**
     *  If there is a regular pause (done by member) running then this value tells when it was started.
	 *
	 * @type {DateTime}
     */
	get uncompletedRegularPauseStart() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoUncompletedRegularPauseStart, false);
		return this.data[5];
	}

	set uncompletedRegularPauseStart(v : DateTime | null) {
        this.setterImpl(5, v, 'uncompletedRegularPauseStart', false, null, null);
	}

	/**
     *  Duration of automatically calculated pauses (added by Dr. Plano) in milliseconds.
	 *
	 * @type {Duration}
     */
	get automaticPauseDuration() : Duration {
		this.getterDebugValidations(this.attributeInfoAutomaticPauseDuration, false);
		return this.data[6];
	}

	set automaticPauseDuration(v : Duration) {
        this.setterImpl(6, v, 'automaticPauseDuration', false, null, null);
	}

	private selectedShiftIdWrapper : ShiftId | null = null!;

	/**
     *  Id of selected shift.
	 *
	 * @type {ShiftId}
     */
	get selectedShiftId() : ShiftId | null {
		this.getterDebugValidations(this.attributeInfoSelectedShiftId, false);
		return this.selectedShiftIdWrapper;
	}

	set selectedShiftId(v : ShiftId | null) {
        this.setterImpl(7, v, 'selectedShiftId', false, null, () => {this.selectedShiftIdWrapper = v;});
	}

	private selectedShiftModelIdWrapper : Id | null = null!;

	/**
     *  Id of selected shift model.
	 *
	 * @type {Id}
     */
	get selectedShiftModelId() : Id | null {
		this.getterDebugValidations(this.attributeInfoSelectedShiftModelId, false);
		return this.selectedShiftModelIdWrapper;
	}

	set selectedShiftModelId(v : Id | null) {
        this.setterImpl(8, v, 'selectedShiftModelId', false, null, () => {this.selectedShiftModelIdWrapper = v;});
	}

	private shiftsWrapper : TimeStampApiShifts = new TimeStampApiShifts(this.api,
		this as unknown as TimeStampApiRoot, false);
	public attributeInfoShifts = this.shiftsWrapper.attributeInfoThis;

	/**
     *  A list of the shifts for which the user can time-stamp. If the time-stamp is running only the selected shift will be returned. If it is an unplanned work then no shifts will be returned.
     */
	get shifts() : TimeStampApiShifts {
		this.getterDebugValidations(this.attributeInfoShifts, false);
		return this.shiftsWrapper;
	}

	set shiftsTestSetter(v : TimeStampApiShifts) {
        this.setterImpl(9, v.rawData, 'shifts', true, null, () => {this.shiftsWrapper = v;});
	}

	private shiftModelsWrapper : TimeStampApiShiftModels = new TimeStampApiShiftModels(this.api,
		this as unknown as TimeStampApiRoot, false);
	public attributeInfoShiftModels = this.shiftModelsWrapper.attributeInfoThis;

	/**
     *  A list of user's shift models.
     */
	get shiftModels() : TimeStampApiShiftModels {
		this.getterDebugValidations(this.attributeInfoShiftModels, false);
		return this.shiftModelsWrapper;
	}

	set shiftModelsTestSetter(v : TimeStampApiShiftModels) {
        this.setterImpl(10, v.rawData, 'shiftModels', true, null, () => {this.shiftModelsWrapper = v;});
	}

	private stampedMembersWrapper : TimeStampApiStampedMembers = new TimeStampApiStampedMembers(this.api,
		this as unknown as TimeStampApiRoot, false);
	public attributeInfoStampedMembers = this.stampedMembersWrapper.attributeInfoThis;

	/**
     *  A list of currently stamped members.
     */
	get stampedMembers() : TimeStampApiStampedMembers {
		this.getterDebugValidations(this.attributeInfoStampedMembers, false);
		return this.stampedMembersWrapper;
	}

	set stampedMembersTestSetter(v : TimeStampApiStampedMembers) {
        this.setterImpl(11, v.rawData, 'stampedMembers', true, null, () => {this.stampedMembersWrapper = v;});
	}

	private allowedTimeStampDevicesWrapper : TimeStampApiAllowedTimeStampDevices = new TimeStampApiAllowedTimeStampDevices(this.api,
		this as unknown as TimeStampApiRoot, false);
	public attributeInfoAllowedTimeStampDevices = this.allowedTimeStampDevicesWrapper.attributeInfoThis;

	/**
     *  A list of allowed time-stamp devices. If this list is empty then all devices are allowed to time-stamp.
     */
	get allowedTimeStampDevices() : TimeStampApiAllowedTimeStampDevices {
		this.getterDebugValidations(this.attributeInfoAllowedTimeStampDevices, false);
		return this.allowedTimeStampDevicesWrapper;
	}

	set allowedTimeStampDevicesTestSetter(v : TimeStampApiAllowedTimeStampDevices) {
        this.setterImpl(12, v.rawData, 'allowedTimeStampDevices', true, null, () => {this.allowedTimeStampDevicesWrapper = v;});
	}

	/**
     *  Warning that the member is doing an unplanned work. Member should explain this in the comment.
	 *
	 * @type {boolean}
     */
	get warnUnplannedWork() : boolean {
		this.getterDebugValidations(this.attributeInfoWarnUnplannedWork, false);
		return this.data[13];
	}

	set warnUnplannedWork(v : boolean) {
        this.setterImpl(13, v, 'warnUnplannedWork', false, null, null);
	}

	/**
     *  Warning that the member has not stamped the current time. Member should explain this in the comment.
	 *
	 * @type {boolean}
     */
	get warnStampedNotCurrentTime() : boolean {
		this.getterDebugValidations(this.attributeInfoWarnStampedNotCurrentTime, false);
		return this.data[14];
	}

	set warnStampedNotCurrentTime(v : boolean) {
        this.setterImpl(14, v, 'warnStampedNotCurrentTime', false, null, null);
	}

	/**
     *  Warning that the member has not stamped the shift time. Member should explain this in the comment.
	 *
	 * @type {boolean}
     */
	get warnStampedNotShiftTime() : boolean {
		this.getterDebugValidations(this.attributeInfoWarnStampedNotShiftTime, false);
		return this.data[15];
	}

	set warnStampedNotShiftTime(v : boolean) {
        this.setterImpl(15, v, 'warnStampedNotShiftTime', false, null, null);
	}

	/**
     *  When was the start-time stamped (using time-stamp).
	 *
	 * @type {DateTime}
     */
	get whenMemberStampedStart() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoWhenMemberStampedStart, false);
		return this.data[16];
	}

	set whenMemberStampedStart(v : DateTime | null) {
        this.setterImpl(16, v, 'whenMemberStampedStart', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[7] = Meta.getReplacedId(this.data[7], _idReplacements);
		this.selectedShiftIdWrapper = ShiftId.create(this.data[7]);
		this.data[8] = Meta.getReplacedId(this.data[8], _idReplacements);
		this.selectedShiftModelIdWrapper = Id.create(this.data[8]);
		this.shiftsWrapper._fixIds(_idReplacements);
		this.shiftModelsWrapper._fixIds(_idReplacements);
		this.stampedMembersWrapper._fixIds(_idReplacements);
		this.allowedTimeStampDevicesWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[7] : null, this.selectedShiftIdWrapper))
			this.selectedShiftIdWrapper = data && data[7] ? ShiftId.create(data[7]) : null!;
		if(!Meta.isSameId(data ? data[8] : null, this.selectedShiftModelIdWrapper))
			this.selectedShiftModelIdWrapper = data && data[8] ? Id.create(data[8]) : null!;
		this.shiftsWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
		this.shiftModelsWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
		this.stampedMembersWrapper._updateRawData(data && data[11] !== undefined ? data[11] : null, generateMissingData);
		this.allowedTimeStampDevicesWrapper._updateRawData(data && data[12] !== undefined ? data[12] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : TimeStampApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class TimeStampApiShiftsBase extends ApiListWrapper<TimeStampApiShift>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'shifts');
	}

	override wrapRawData(itemRawData : any) : TimeStampApiShift {
		return new TimeStampApiShift(this.api, this as unknown as TimeStampApiShifts, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : TimeStampApiRoot | null, removeDestroyedItems : boolean) : this {
		return new TimeStampApiShifts(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '10';
	}

	override createNewItem(_initCode : ((newItem : TimeStampApiShift) => void) | null = null, _backendId : ShiftId | null = null) : TimeStampApiShift {
		const newItem = new TimeStampApiShift(this.api, this as unknown as TimeStampApiShifts, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('shifts');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiShifts, TimeStampApiShifts> = new ApiAttributeInfo<TimeStampApiShifts, TimeStampApiShifts>({
			apiObjWrapper: this as any as TimeStampApiShifts,
			name: 'shifts',
			nodeName: 'SHIFTS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : TimeStampApiShifts, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 9,
		});
}

				 
export class TimeStampApiShiftBase extends ApiObjectWrapper<TimeStampApiShift>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiShifts | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<TimeStampApiShift> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, TimeStampApiShift as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : ShiftId | undefined = undefined;
	get id() : ShiftId {
		return this.backendId === undefined ? ShiftId.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiShift, TimeStampApiShift> = new ApiAttributeInfo<TimeStampApiShift, TimeStampApiShift>({
			apiObjWrapper: this as any as TimeStampApiShift,
			name: 'shift',
			nodeName: 'SHIFT',
			defaultValue: function(this : TimeStampApiShift, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoStart : ApiAttributeInfo<TimeStampApiShift, DateTime> = new ApiAttributeInfo<TimeStampApiShift, DateTime>({
			apiObjWrapper: this as any as TimeStampApiShift,
			name: 'start',
			nodeName: 'SHIFT_START',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 1,
		});
	attributeInfoEnd : ApiAttributeInfo<TimeStampApiShift, DateTime> = new ApiAttributeInfo<TimeStampApiShift, DateTime>({
			apiObjWrapper: this as any as TimeStampApiShift,
			name: 'end',
			nodeName: 'SHIFT_END',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 2,
		});
	attributeInfoModelId : ApiAttributeInfo<TimeStampApiShift, Id> = new ApiAttributeInfo<TimeStampApiShift, Id>({
			apiObjWrapper: this as any as TimeStampApiShift,
			name: 'modelId',
			nodeName: 'SHIFT_MODEL_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			rawDataIndex: 3,
		});

	/**
     *  Start time of shift.
	 *
	 * @type {DateTime}
     */
	get start() : DateTime {
		this.getterDebugValidations(this.attributeInfoStart, false);
		return this.data[1];
	}

	set start(v : DateTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}

	/**
     *  End time of shift.
	 *
	 * @type {DateTime}
     */
	get end() : DateTime {
		this.getterDebugValidations(this.attributeInfoEnd, false);
		return this.data[2];
	}

	set end(v : DateTime) {
        this.setterImpl(2, v, 'end', false, null, null);
	}

	private modelIdWrapper : Id = null!;

	/**
     *  Id of the shift model to which this shift belongs.
	 *
	 * @type {Id}
     */
	get modelId() : Id {
		this.getterDebugValidations(this.attributeInfoModelId, false);
		return this.modelIdWrapper;
	}

	set modelId(v : Id) {
        this.setterImpl(3, v, 'modelId', false, null, () => {this.modelIdWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[3] = Meta.getReplacedId(this.data[3], _idReplacements);
		this.modelIdWrapper = Id.create(this.data[3]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : ShiftId.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[3] : null, this.modelIdWrapper))
			this.modelIdWrapper = data && data[3] ? Id.create(data[3]) : null!;
	}

	protected get dni() : string {
		return '18';
	}

	static async loadDetailed(	api : TimeStampApiServiceBase
						,	id : ShiftId
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '18', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class TimeStampApiShiftModelsBase extends ApiListWrapper<TimeStampApiShiftModel>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'shiftModels');
	}

	override wrapRawData(itemRawData : any) : TimeStampApiShiftModel {
		return new TimeStampApiShiftModel(this.api, this as unknown as TimeStampApiShiftModels, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : TimeStampApiRoot | null, removeDestroyedItems : boolean) : this {
		return new TimeStampApiShiftModels(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '11';
	}

	override createNewItem(_initCode : ((newItem : TimeStampApiShiftModel) => void) | null = null, _backendId : Id | null = null) : TimeStampApiShiftModel {
		const newItem = new TimeStampApiShiftModel(this.api, this as unknown as TimeStampApiShiftModels, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('shiftModels');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiShiftModels, TimeStampApiShiftModels> = new ApiAttributeInfo<TimeStampApiShiftModels, TimeStampApiShiftModels>({
			apiObjWrapper: this as any as TimeStampApiShiftModels,
			name: 'shiftModels',
			nodeName: 'SHIFT_MODELS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : TimeStampApiShiftModels, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 10,
		});
}

				 
export class TimeStampApiShiftModelBase extends ApiObjectWrapper<TimeStampApiShiftModel>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiShiftModels | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<TimeStampApiShiftModel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, TimeStampApiShiftModel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiShiftModel, TimeStampApiShiftModel> = new ApiAttributeInfo<TimeStampApiShiftModel, TimeStampApiShiftModel>({
			apiObjWrapper: this as any as TimeStampApiShiftModel,
			name: 'shiftModel',
			nodeName: 'SHIFT_MODEL',
			defaultValue: function(this : TimeStampApiShiftModel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoColor : ApiAttributeInfo<TimeStampApiShiftModel, string> = new ApiAttributeInfo<TimeStampApiShiftModel, string>({
			apiObjWrapper: this as any as TimeStampApiShiftModel,
			name: 'color',
			nodeName: 'SHIFT_MODEL_COLOR',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 1,
		});
	attributeInfoParentName : ApiAttributeInfo<TimeStampApiShiftModel, string> = new ApiAttributeInfo<TimeStampApiShiftModel, string>({
			apiObjWrapper: this as any as TimeStampApiShiftModel,
			name: 'parentName',
			nodeName: 'SHIFT_MODEL_PARENT_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 3,
		});
	attributeInfoName : ApiAttributeInfo<TimeStampApiShiftModel, string> = new ApiAttributeInfo<TimeStampApiShiftModel, string>({
			apiObjWrapper: this as any as TimeStampApiShiftModel,
			name: 'name',
			nodeName: 'SHIFT_MODEL_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 4,
		});
	attributeInfoTrashed : ApiAttributeInfo<TimeStampApiShiftModel, boolean> = new ApiAttributeInfo<TimeStampApiShiftModel, boolean>({
			apiObjWrapper: this as any as TimeStampApiShiftModel,
			name: 'trashed',
			nodeName: 'SHIFT_MODEL_TRASHED',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : TimeStampApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 5,
		});
	attributeInfoAssignable : ApiAttributeInfo<TimeStampApiShiftModel, boolean> = new ApiAttributeInfo<TimeStampApiShiftModel, boolean>({
			apiObjWrapper: this as any as TimeStampApiShiftModel,
			name: 'assignable',
			nodeName: 'SHIFT_MODEL_ASSIGNABLE',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : TimeStampApiShiftModel, _nodeId : string) {return false;},
			rawDataIndex: 6,
		});

	/**
     *  Color of shift model in format "rrggbb".
	 *
	 * @type {string}
     */
	get color() : string {
		this.getterDebugValidations(this.attributeInfoColor, false);
		return this.data[1];
	}

	set color(v : string) {
        this.setterImpl(1, v, 'color', false, null, null);
	}

	private timeWrapper : TimeStampApiShiftModelTime = new TimeStampApiShiftModelTime(this.api,
		this as unknown as TimeStampApiShiftModel, );
	public attributeInfoTime = this.timeWrapper.attributeInfoThis;

	/**
     *  The time when this shift-model will start (relative to current day).
     */
	get time() : TimeStampApiShiftModelTime {
		this.getterDebugValidations(this.attributeInfoTime, false);
		return this.timeWrapper;
	}

	set timeTestSetter(v : TimeStampApiShiftModelTime) {
        this.setterImpl(2, v.rawData, 'time', true, null, () => {this.timeWrapper = v;});
	}

	/**
     *  Name of the parent.
	 *
	 * @type {string}
     */
	get parentName() : string {
		this.getterDebugValidations(this.attributeInfoParentName, false);
		return this.data[3];
	}

	set parentName(v : string) {
        this.setterImpl(3, v, 'parentName', false, null, null);
	}

	/**
     *  Name of the shift model.
	 *
	 * @type {string}
     */
	get name() : string {
		this.getterDebugValidations(this.attributeInfoName, false);
		return this.data[4];
	}

	set name(v : string) {
        this.setterImpl(4, v, 'name', false, null, null);
	}

	/**
     *  Is this shift model trashed?
	 *
	 * @type {boolean}
     */
	get trashed() : boolean {
		this.getterDebugValidations(this.attributeInfoTrashed, false);
		return this.data[5];
	}

	set trashed(v : boolean) {
        this.setterImpl(5, v, 'trashed', false, null, null);
	}

	/**
     *  Is the requester assignable to this shift-model?
	 *
	 * @type {boolean}
     */
	get assignable() : boolean {
		this.getterDebugValidations(this.attributeInfoAssignable, false);
		return this.data[6];
	}

	set assignable(v : boolean) {
        this.setterImpl(6, v, 'assignable', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.timeWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('SHIFT_MODEL should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.timeWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '22';
	}

	static async loadDetailed(	api : TimeStampApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '22', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class TimeStampApiShiftModelTime extends ApiObjectWrapper<TimeStampApiShiftModelTime>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiShiftModel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<TimeStampApiShiftModelTime> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, TimeStampApiShiftModelTime as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiShiftModelTime, TimeStampApiShiftModelTime> = new ApiAttributeInfo<TimeStampApiShiftModelTime, TimeStampApiShiftModelTime>({
			apiObjWrapper: this as any as TimeStampApiShiftModelTime,
			name: 'time',
			nodeName: 'SHIFT_MODEL_TIME',
			defaultValue: function(this : TimeStampApiShiftModelTime, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	attributeInfoStart : ApiAttributeInfo<TimeStampApiShiftModelTime, LocalTime> = new ApiAttributeInfo<TimeStampApiShiftModelTime, LocalTime>({
			apiObjWrapper: this as any as TimeStampApiShiftModelTime,
			name: 'start',
			nodeName: 'SHIFT_MODEL_TIME_START',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			rawDataIndex: 1,
		});

	/**
     *  The time when this shift-model will start as milliseconds since the start of the day.
	 *
	 * @type {LocalTime}
     */
	get start() : LocalTime {
		this.getterDebugValidations(this.attributeInfoStart, false);
		return this.data[1];
	}

	set start(v : LocalTime) {
        this.setterImpl(1, v, 'start', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '24';
	}

	static async loadDetailed(	api : TimeStampApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '24', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class TimeStampApiStampedMembers extends ApiListWrapper<TimeStampApiStampedMember>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'stampedMembers');
	}

	override wrapRawData(itemRawData : any) : TimeStampApiStampedMember {
		return new TimeStampApiStampedMember(this.api, this as unknown as TimeStampApiStampedMembers, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : TimeStampApiRoot | null, removeDestroyedItems : boolean) : this {
		return new TimeStampApiStampedMembers(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '12';
	}

	override createNewItem(_initCode : ((newItem : TimeStampApiStampedMember) => void) | null = null, _backendId : Id | null = null) : TimeStampApiStampedMember {
		const newItem = new TimeStampApiStampedMember(this.api, this as unknown as TimeStampApiStampedMembers, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('stampedMembers');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiStampedMembers, TimeStampApiStampedMembers> = new ApiAttributeInfo<TimeStampApiStampedMembers, TimeStampApiStampedMembers>({
			apiObjWrapper: this as any as TimeStampApiStampedMembers,
			name: 'stampedMembers',
			nodeName: 'STAMPED_MEMBERS',
			primitiveType: PApiPrimitiveTypes.ApiList,
			defaultValue: function(this : TimeStampApiStampedMembers, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 11,
		});
}

				 
export class TimeStampApiStampedMemberBase extends ApiObjectWrapper<TimeStampApiStampedMember>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiStampedMembers | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<TimeStampApiStampedMember> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, TimeStampApiStampedMember as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiStampedMember, TimeStampApiStampedMember> = new ApiAttributeInfo<TimeStampApiStampedMember, TimeStampApiStampedMember>({
			apiObjWrapper: this as any as TimeStampApiStampedMember,
			name: 'stampedMember',
			nodeName: 'STAMPED_MEMBER',
			defaultValue: function(this : TimeStampApiStampedMember, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoFirstName : ApiAttributeInfo<TimeStampApiStampedMember, string> = new ApiAttributeInfo<TimeStampApiStampedMember, string>({
			apiObjWrapper: this as any as TimeStampApiStampedMember,
			name: 'firstName',
			nodeName: 'STAMPED_MEMBER_FIRST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 1,
		});
	attributeInfoLastName : ApiAttributeInfo<TimeStampApiStampedMember, string> = new ApiAttributeInfo<TimeStampApiStampedMember, string>({
			apiObjWrapper: this as any as TimeStampApiStampedMember,
			name: 'lastName',
			nodeName: 'STAMPED_MEMBER_LAST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 2,
		});
	attributeInfoPausing : ApiAttributeInfo<TimeStampApiStampedMember, boolean> = new ApiAttributeInfo<TimeStampApiStampedMember, boolean>({
			apiObjWrapper: this as any as TimeStampApiStampedMember,
			name: 'pausing',
			nodeName: 'STAMPED_MEMBER_PAUSING',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : TimeStampApiStampedMember, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	attributeInfoActivityStart : ApiAttributeInfo<TimeStampApiStampedMember, DateTime> = new ApiAttributeInfo<TimeStampApiStampedMember, DateTime>({
			apiObjWrapper: this as any as TimeStampApiStampedMember,
			name: 'activityStart',
			nodeName: 'STAMPED_MEMBER_ACTIVITY_START',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 4,
		});
	attributeInfoAvatar : ApiAttributeInfo<TimeStampApiStampedMember, Image> = new ApiAttributeInfo<TimeStampApiStampedMember, Image>({
			apiObjWrapper: this as any as TimeStampApiStampedMember,
			name: 'avatar',
			nodeName: 'STAMPED_MEMBER_AVATAR',
			primitiveType: PApiPrimitiveTypes.Image,
			rawDataIndex: 5,
		});

	/**
     *  First-name of the stamped member.
	 *
	 * @type {string}
     */
	get firstName() : string {
		this.getterDebugValidations(this.attributeInfoFirstName, false);
		return this.data[1];
	}

	set firstName(v : string) {
        this.setterImpl(1, v, 'firstName', false, null, null);
	}

	/**
     *  Last-name of the stamped member.
	 *
	 * @type {string}
     */
	get lastName() : string {
		this.getterDebugValidations(this.attributeInfoLastName, false);
		return this.data[2];
	}

	set lastName(v : string) {
        this.setterImpl(2, v, 'lastName', false, null, null);
	}

	/**
     *  Type: Boolean. Is the member taking a pause now? If not then the member is working at the moment.
	 *
	 * @type {boolean}
     */
	get pausing() : boolean {
		this.getterDebugValidations(this.attributeInfoPausing, false);
		return this.data[3];
	}

	set pausing(v : boolean) {
        this.setterImpl(3, v, 'pausing', false, null, null);
	}

	/**
     *  Duration between this date-time and current time represents the duration of current activity (pause/working).
	 *
	 * @type {DateTime}
     */
	get activityStart() : DateTime {
		this.getterDebugValidations(this.attributeInfoActivityStart, false);
		return this.data[4];
	}

	set activityStart(v : DateTime) {
        this.setterImpl(4, v, 'activityStart', false, null, null);
	}

	/**
     *  The profile image of the member.
	 *
	 * @type {Image}
     */
	get avatar() : Image {
		this.getterDebugValidations(this.attributeInfoAvatar, false);
		const path = this.data[5] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set avatar(v : Image) {
        this.setterImpl(5, v, 'avatar', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('STAMPED_MEMBER should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '30';
	}

	static async loadDetailed(	api : TimeStampApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '30', { success: success, error: error, searchParams: searchParams});
	}
}

		 export class TimeStampApiAllowedTimeStampDevicesBase extends ApiListWrapper<TimeStampApiAllowedTimeStampDevice>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiRoot | null,
		removeDestroyedItems : boolean = false
	) {
		super(api, parent, removeDestroyedItems, 'allowedTimeStampDevices');
	}

	override wrapRawData(itemRawData : any) : TimeStampApiAllowedTimeStampDevice {
		return new TimeStampApiAllowedTimeStampDevice(this.api, this as unknown as TimeStampApiAllowedTimeStampDevices, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : TimeStampApiRoot | null, removeDestroyedItems : boolean) : this {
		return new TimeStampApiAllowedTimeStampDevices(this.api, _parent, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '13';
	}

	override createNewItem(_initCode : ((newItem : TimeStampApiAllowedTimeStampDevice) => void) | null = null, _backendId : Id | null = null) : TimeStampApiAllowedTimeStampDevice {
		const newItem = new TimeStampApiAllowedTimeStampDevice(this.api, this as unknown as TimeStampApiAllowedTimeStampDevices, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('allowedTimeStampDevices');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiAllowedTimeStampDevices, TimeStampApiAllowedTimeStampDevices> = new ApiAttributeInfo<TimeStampApiAllowedTimeStampDevices, TimeStampApiAllowedTimeStampDevices>({
			apiObjWrapper: this as any as TimeStampApiAllowedTimeStampDevices,
			name: 'allowedTimeStampDevices',
			nodeName: 'ALLOWED_TIME_STAMP_DEVICES',
			primitiveType: PApiPrimitiveTypes.ApiList,
			hasRightToSet: function(this : TimeStampApiAllowedTimeStampDevices) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			defaultValue: function(this : TimeStampApiAllowedTimeStampDevices, _nodeId : string) {return Meta.createNewList();},
			rawDataIndex: 12,
		});
}

				 
export class TimeStampApiAllowedTimeStampDeviceBase extends ApiObjectWrapper<TimeStampApiAllowedTimeStampDevice>
{
	constructor(override readonly api : TimeStampApiServiceBase | null,
		override readonly parent : TimeStampApiAllowedTimeStampDevices | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<TimeStampApiAllowedTimeStampDevice> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, TimeStampApiAllowedTimeStampDevice as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, TimeStampApiAllowedTimeStampDevice> = new ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, TimeStampApiAllowedTimeStampDevice>({
			apiObjWrapper: this as any as TimeStampApiAllowedTimeStampDevice,
			name: 'allowedTimeStampDevice',
			nodeName: 'ALLOWED_TIME_STAMP_DEVICE',
			defaultValue: function(this : TimeStampApiAllowedTimeStampDevice, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoName : ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, string> = new ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, string>({
			apiObjWrapper: this as any as TimeStampApiAllowedTimeStampDevice,
			name: 'name',
			nodeName: 'ALLOWED_TIME_STAMP_DEVICE_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToSet: function(this : TimeStampApiAllowedTimeStampDevice) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.CLIENT_OWNER)));
			},
			validations: function(this : TimeStampApiAllowedTimeStampDevice) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : TimeStampApiAllowedTimeStampDevice) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoVisitorId : ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, string> = new ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, string>({
			apiObjWrapper: this as any as TimeStampApiAllowedTimeStampDevice,
			name: 'visitorId',
			nodeName: 'ALLOWED_TIME_STAMP_DEVICE_VISITOR_ID',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 2,
		});
	attributeInfoBrowserName : ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, string> = new ApiAttributeInfo<TimeStampApiAllowedTimeStampDevice, string>({
			apiObjWrapper: this as any as TimeStampApiAllowedTimeStampDevice,
			name: 'browserName',
			nodeName: 'ALLOWED_TIME_STAMP_DEVICE_BROWSER_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 3,
		});

	/**
     *  User-given name for the device.
	 *
	 * @type {string}
     */
	get name() : string {
		this.getterDebugValidations(this.attributeInfoName, false);
		return this.data[1];
	}

	set name(v : string) {
        this.setterImpl(1, v, 'name', false, null, null);
	}

	/**
     *  The unique device id.
	 *
	 * @type {string}
     */
	get visitorId() : string {
		this.getterDebugValidations(this.attributeInfoVisitorId, false);
		return this.data[2];
	}

	set visitorId(v : string) {
        this.setterImpl(2, v, 'visitorId', false, null, null);
	}

	/**
     *  Browser name.
	 *
	 * @type {string}
     */
	get browserName() : string {
		this.getterDebugValidations(this.attributeInfoBrowserName, false);
		return this.data[3];
	}

	set browserName(v : string) {
        this.setterImpl(3, v, 'browserName', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ALLOWED_TIME_STAMP_DEVICE should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '36';
	}

	static async loadDetailed(	api : TimeStampApiServiceBase
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '36', { success: success, error: error, searchParams: searchParams});
	}
}



