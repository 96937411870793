import { DataInputBase } from '@plano/shared/core/data/data-input-base';
import { ParameterDataBase } from '@plano/shared/core/data/parameter-data/parameter-data-base';

/**
 * A Data class which uses three parameters to calculate its value.
 * This class caches the result for each parameter combination which is called.
 *
 * When any of the DataInput changes the whole cache is then cleared.
 */
export class ThreeParameterData<TData, TParam1, TParam2, TParam3> extends ParameterDataBase<TData[][][]> {
	constructor(input1 : DataInputBase, input2 : DataInputBase | null = null, input3 : DataInputBase | null = null) {
		super(input1, input2, input3);

		this.cachedValue = new Array<TData[][]>();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get(
		param1 : TParam1,
		param2 : TParam2,
		param3 : TParam3,
		calculateFn : () => TData,
	) : TData {
		// reset all data because input changed?
		if (this.inputsChanged())
			this.cachedValue = new Array<TData[][]>();

		// ensure array structure
		const index1 = this.toNumber(param1);
		const index2 = this.toNumber(param2);
		const index3 = this.toNumber(param3);

		if (!this.cachedValue[index1])
			this.cachedValue[index1] = new Array<TData[]>();

		if (!this.cachedValue[index1][index2])
			this.cachedValue[index1][index2] = new Array<TData>();

		// get value
		let result = this.cachedValue[index1][index2][index3];

		if (result === undefined) {
			result = calculateFn();
			this.cachedValue[index1][index2][index3] = result;
		}

		return result;
	}
}
