<div class="line flex-grow-1 d-flex justify-content-between align-items-stretch pl-3 position-relative crop-on-overflow"
	[class.clickable]="me.data.isOwner && hasOnClickBinding"
	[class.ruler]="ruler"
	tabindex="0"
	[routerLink]="CONFIG.IS_MOBILE && showEditBtn ? linkForItem : null"
	(click)="onClick.emit()"
>
	<div class="crop-on-overflow d-flex align-items-center justify-content-start flex-grow-1" style="min-width: 200px;">
		<ng-content select="[report-row-title]"></ng-content>
	</div>
	<div class="text-monospace d-flex align-items-center justify-content-end">
		<ng-content select="[report-row-badges]"></ng-content>
	</div>
	<div class="min-w-md-100 d-none d-lg-flex text-monospace align-items-center justify-content-end">
		<ng-template [ngIf]="regularPauseDuration !== null || automaticPauseDuration !== null" [ngIfElse]="reportRowDurationTemplate">
			<p-pause-duration
				[duration]="regularPauseDuration"
				[automaticPauseDuration]="automaticPauseDuration"
				[merged]="mergePauseDurations"
				[memberName]="memberName"
				[isForecast]="workingTime?.isExpectedWorkingTime ?? false"
			></p-pause-duration>
		</ng-template>
		<ng-template #reportRowDurationTemplate>
			<ng-content select="[report-row-duration]"></ng-content>
		</ng-template>
	</div>
	<div class="d-none d-sm-flex min-w-lg-140 text-monospace align-items-center justify-content-end">
		<ng-template [ngIf]="!absence">
			<div [innerHTML]="duration | pDurationAsHours: false | pSafeHtml"></div>
		</ng-template>
		<ng-template [ngIf]="absence">
			<span *ngIf="absence.workingTimePerDay !== null">{hasManyDays, select, true {{{absence.totalDaysBetween(min, max) | number:'1.0-2'}} × }}<span [innerHTML]="(absence.workingTimePerDay !== null ? absence.workingTimePerDay : absence.durationBetween(min, max)) | pDurationTime:false | pSafeHtml"></span></span>
			<span *ngIf="absence.workingTimePerDay === null"><span [innerHTML]="absence.durationBetween(min, max) | pDurationTime:false | pSafeHtml"></span></span>
		</ng-template>
	</div>
	<div class="d-flex align-items-stretch justify-content-center pr-3"
		[class.flex-column]="CONFIG.IS_MOBILE"
	>
		<div class="min-w-md-120 text-monospace d-flex align-items-center justify-content-end">
			<ng-content select="[report-row-earnings]"></ng-content>
		</div>
		<div class="min-w-md-140 d-flex flex-column align-items-stretch p-0">
			<ng-container *ngTemplateOutlet="commentBtn"></ng-container>
		</div>
	</div>
	<ng-template [ngIf]="!(isEditable && !hideEditBtn)">
		<div class="d-none d-lg-flex min-w-50 align-items-center justify-content-end">
			<ng-content select="[report-row-button]"></ng-content>
		</div>
	</ng-template>
	<ng-template [ngIf]="showEditBtn && !CONFIG.IS_MOBILE">
		<a
			[routerLink]="linkForItem"
			class="d-flex min-w-50 clickable modal-trigger align-items-center justify-content-center"
			(click)="onEdit.emit()"
		>
			<fa-icon [icon]="enums.PlanoFaIconPool.EDIT"></fa-icon>
		</a>
	</ng-template>
</div>

<ng-template #commentBtn>
	<ng-template [ngIf]="showCommentIcons" [ngIfElse]="isWorkingTimes">
		<a
			tabindex="0"
			class="d-flex align-items-center flex-grow-1"
			[class.pr-2]="!CONFIG.IS_MOBILE"
			[class.clickable]="!CONFIG.IS_MOBILE"
			[class.modal-trigger]="!CONFIG.IS_MOBILE"
			(click)="!CONFIG.IS_MOBILE ? open(modalContent.template) : undefined"
		>
			<div class="text-right ml-auto min-w-fit-content">
				<ng-template [ngTemplateOutlet]="warningIconTemplate"></ng-template>
			</div>
			<div class="min-w-lg-60 text-right min-w-fit-content">
				<fa-icon
					*ngIf="!!comment"
					class="pl-2"
					[class.text-muted]="!comment"
					[fixedWidth]="true" icon="comment"
				></fa-icon>
			</div>
		</a>
	</ng-template>
	<ng-template #isWorkingTimes>
		<ng-template [ngIf]="commentAmount || warningAmount">
			<div class="d-flex align-items-center flex-grow-1 min-w-max-content"
				[class.pr-2]="!CONFIG.IS_MOBILE"
			>
				<div class="text-right ml-auto min-w-fit-content">
					<span
						[pTooltip]="tooltipWarningAmount"
						[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
					><ng-template [ngTemplateOutlet]="warningIconTemplate"></ng-template><div hidden><ng-container *ngTemplateOutlet="tooltipWarningAmount"></ng-container></div>
					</span>
					<ng-template #tooltipWarningAmount>
						<div class="d-block clickable card m-0 text-left o-hidden"
						>
							<div class="card-body p-1 pl-2 pr-2">
								<div i18n>{warningAmount, plural, one {Warnung} other {{{warningAmount}} Warnungen}} für gestempelte Zeiten</div>
							</div>
						</div>
					</ng-template>
				</div>
				<div class="min-w-lg-60 text-right min-w-fit-content">
					<span
						[pTooltip]="tooltipCommentHint"
						[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
					><span
						class="pl-2"
						*ngIf="commentAmount! > 1"
					>{{ commentAmount }} × </span><div i18n hidden>Kommentare</div><fa-icon
						*ngIf="!!commentAmount"
						[fixedWidth]="true" icon="comment"
					></fa-icon></span>

					<ng-template #tooltipCommentHint>
						<div class="d-block clickable card m-0 text-left o-hidden"
						>
							<div class="card-body p-1 pl-2 pr-2" i18n>{commentAmount, plural, one {Kommentare} other {{{commentAmount}} Kommentare}}</div>
						</div>
					</ng-template>

				</div>
			</div>
		</ng-template>
	</ng-template>
</ng-template>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-header
		headline="Kommentar" i18n-headline
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<ng-template [ngIf]="workingTime">
			<p-alert
				*ngFor="let message of workingTime.warningMessages"
			>{{ message | localize }}</p-alert>
		</ng-template>
		<div>
			<label for="comment"><fa-icon
				class="pr-1"
				[fixedWidth]="true" [icon]="comment ? 'comment' : ['regular','comment']"
			></fa-icon>&nbsp;<ng-container i18n>Kommentar an {!!absence, select, true {Mitarbeitende} other {die Personalleitung}}</ng-container></label>
		</div>
		<p-textarea
			[pEditable]="true"
			[api]="api"

			[(ngModel)]="comment"

			[disabled]="true"
		></p-textarea>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary ml-auto" (click)="c($event)">OK</button>
	</div>
</ng-template>

<ng-template #warningIconTemplate>
	<span *ngIf="warningAmount && warningAmount > 1">{{ warningAmount }} × </span><fa-icon
		*ngIf="warningAmount && warningAmount > 0"
		[fixedWidth]="true" icon="exclamation-triangle"
		role="button"
		title="Warnungen" i18n-title
	></fa-icon>
</ng-template>
