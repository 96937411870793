import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PApiPrimitiveTypes, PSupportedCurrencyCodes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { IdBase } from '@plano/shared/api/base/id/id-base';
import { LogService } from '@plano/shared/core/log.service';
import { TIME_REGEXP, ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidationErrors, PValidationErrorValue, PValidatorFn, ValidatorsServiceReturnType } from '@plano/shared/core/validators.types';
import { DurationUIType } from '@plano/shared/p-forms/p-input/p-input.types';

/** The possible types for an <p-input type="…"> */
export type PInputType = (
	PApiPrimitiveTypes.Iban |
	PApiPrimitiveTypes.Bic |
	PApiPrimitiveTypes.Url |
	'Domain' |
	PApiPrimitiveTypes.Search |
	PApiPrimitiveTypes.Minutes |
	PApiPrimitiveTypes.Hours |
	PApiPrimitiveTypes.Integer |
	PApiPrimitiveTypes.LocalTime |
	PApiPrimitiveTypes.Days |
	PApiPrimitiveTypes.Months |
	PApiPrimitiveTypes.Percent |
	PApiPrimitiveTypes.Password |
	PApiPrimitiveTypes.PostalCode |
	PApiPrimitiveTypes.Tel |
	PApiPrimitiveTypes.Years |
	PApiPrimitiveTypes.number |
	PApiPrimitiveTypes.string |
	PApiPrimitiveTypes.Email |
	PApiPrimitiveTypes.Duration |
	PApiPrimitiveTypes.ClientCurrency |
	PApiPrimitiveTypes.Euro |
	'ConfirmPassword'
);

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputService {
	constructor(
		private console : LogService,
		private pMoment : PMomentService,
	) {
	}

	/**
	 * @param locale - The locale id for localization of the input
	 * @param input - The input that the user gave us
	 * @returns a number if can be transformed to it or input as it is otherwise
	 *
	 * @example
	 * Takes input like string '2.000'
	 * and returns number 2000 if locale is Germany
	 * or returns number 2 if locale is en
	 */
	public turnLocaleNumberIntoNumber(locale : PSupportedLocaleIds, input : string) : number | string {
		const decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);

		// If input ends with a decimal separator, we assume that the user is about to type a number with decimal places.
		// So a number that ends with a decimal separator is never a valid number.
		if (input.endsWith(decimalSeparator)) return input;

		let localizedNumberAsString : string | null = null;
		if (decimalSeparator === '.') {
			if (locale === PSupportedLocaleIds.de_CH)
				input = input.replace(/['’]/g,'');
			localizedNumberAsString = input;
		} else {
			localizedNumberAsString = input
				.replace(/\./g, '\uE002')
				.replace(/,/g, '.')
				.replace(/\uE002/g, '');
		}
		return Number.isNaN(+localizedNumberAsString) ? localizedNumberAsString : +localizedNumberAsString;
	}

	/**
	 * Takes input like number 2000.5
	 * and returns locale number as string '2000,5' if locale is Germany
	 * or returns '2000.5' if locale is en
	 */
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	public turnNumberIntoLocaleNumber(locale : PSupportedLocaleIds, input : number | undefined | null) : string | '' {
		if (input === null || input === undefined) return '';
		const decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
		if (decimalSeparator === '.') return input.toString();
		if (decimalSeparator === ',') return input.toString().replace('.', decimalSeparator);
		throw new Error('Unknown separator');
	}

	private static validateLocaleAwareFloatTillSeparatorRegExString(locale : PSupportedLocaleIds) : string {
		const decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
		let thousandsSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Group);
		thousandsSeparator = thousandsSeparator === '’' ? `${thousandsSeparator}'` : thousandsSeparator;
		return `^-?((0|[0-9]+|([1-9]([0-9]{1,2})?([\\${thousandsSeparator}][0-9]{3})*))([\\${decimalSeparator}]`;
	}

	/**
	 * locale get a regex for a float with two digits for provided locale
	 */
	public static localeAwareFloatWithTwoDigitsAfterSeparatorRegEx(locale : PSupportedLocaleIds) : RegExp {
		const REGEX_FLOAT_TILL_SEPARATOR_STRING = PInputService.validateLocaleAwareFloatTillSeparatorRegExString(locale);
		const REGEX_STRING = `${REGEX_FLOAT_TILL_SEPARATOR_STRING}[0-9]{1,2})?)$`;
		return new RegExp(REGEX_STRING);
	}

	/**
	 * Is this a valid currency amount?
	 * OK: "12"
	 * OK: "12,32"
	 * OK: "12.32"
	 * NOT OK: "12.1234"
	 * NOT OK: "12 euro"
	 */
	public validateLocaleAwareCurrency(
		control : Pick<AbstractControl, 'value'>,
		locale : PSupportedLocaleIds,
		currencyCode : PSupportedCurrencyCodes | null = null,
	) : PValidationErrors<PValidationErrorValue & {
		name : PPossibleErrorNames.CURRENCY | PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT
	}> | null {
		if (control.value === undefined) return null;
		if (control.value === '') return null;
		if (control.value === null) return null;

		if (typeof control.value !== 'number') {
			const regex = this.localeAwareFloatRegEx(locale);
			if (!control.value.match(regex)) {
				return {
					[PPossibleErrorNames.CURRENCY]: {
						name: PPossibleErrorNames.CURRENCY,
						actual: control.value,
						primitiveType: PApiPrimitiveTypes.ClientCurrency,
					},
				};
			}
		}

		const MAX = 2;

		// Count digits after decimal separator
		const decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
		const digitsAfterDecimalSeparator = control.value.toString().split(decimalSeparator)[1]?.length ?? 0;
		if (digitsAfterDecimalSeparator > MAX) {
			return { [PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT]: {
				name: PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT,
				primitiveType: PApiPrimitiveTypes.ClientCurrency,
				actual : control.value,
				maxDigitsLength: MAX,
				currencyCode: currencyCode,
			} };
		}

		const patternError = Validators.pattern(PInputService.localeAwareFloatWithTwoDigitsAfterSeparatorRegEx(locale))(control as AbstractControl);
		if (!patternError) return null;

		return { [PPossibleErrorNames.CURRENCY]: {
			name: PPossibleErrorNames.CURRENCY,
			primitiveType: undefined,
			actual : control.value,
			currencyCode: currencyCode,
		} };
	}

	/**
	 * locale get a regex for a float for provided locale
	 */
	public localeAwareFloatRegEx(locale : PSupportedLocaleIds) : RegExp {
		const REGEX_FLOAT_TILL_SEPARATOR_STRING = PInputService.validateLocaleAwareFloatTillSeparatorRegExString(locale);
		const REGEX_STRING = `${REGEX_FLOAT_TILL_SEPARATOR_STRING}[0-9]+)?)$`;
		return new RegExp(REGEX_STRING);
	}

	private get localeAwareTimeRegEx() : RegExp {
		return new RegExp(TIME_REGEXP);
	}

	/**
	 * Is this a valid time input? For germany:
	 * OK: "9:30"
	 * OK: "0:00"
	 * OK: "23:59"
	 * NOT OK: "4,5"
	 * NOT OK: "99:99"
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public validateLocaleAwareTime(control : { value : any }) : PValidationErrors<PValidationErrorValue & {name : PPossibleErrorNames.TIME}> | null {
		if (control.value === undefined) return null;
		if (control.value === '') return null;
		if (control.value === null) return null;

		const patternError = Validators.pattern(this.localeAwareTimeRegEx)(control as AbstractControl);
		if (!patternError) return null;

		return { [PPossibleErrorNames.TIME]	: { name: PPossibleErrorNames.TIME, primitiveType: undefined } };
	}

	/**
	 * Is this a number?
	 * OK: "12"
	 * OK: "12,32" / "12.32"
	 * NOT OK: "12,1234" / "12.1234"
	 * NOT OK: "12 euro"
	 * NOT OK: "zehn"
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public validateLocaleAwareNumber(control : { value : any }, locale : PSupportedLocaleIds) : ValidationErrors | null {
		if (control.value === undefined) return null;
		if (control.value === '') return null;
		if (control.value === null) return null;

		let value = control.value;

		const patternError = Validators.pattern(this.localeAwareFloatRegEx(locale))(control as AbstractControl);
		if (!patternError) {
			if (typeof value !== 'number') value = this.turnLocaleNumberIntoNumber(locale, control.value);

			if (!value || !Number.isNaN(+value)) return null;
		}

		return { [PPossibleErrorNames.NUMBER_NAN]: {
			name: PPossibleErrorNames.NUMBER_NAN,
			actual: control.value,
		} };
	}

	/**
	 * Is this a valid float?
	 * OK: "12"
	 * OK: "12,32"
	 * OK: "12.32"
	 * NOT OK: "12.1234"
	 * NOT OK: "12 euro"
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public validateLocaleAwareFloat(control : { value : any }, locale : PSupportedLocaleIds) : ValidationErrors | null {
		if (control.value === undefined) return null;
		if (control.value === '') return null;
		if (control.value === null) return null;

		const nanError = this.validateLocaleAwareNumber(control, locale);
		if (nanError) return nanError;

		const patternError = Validators.pattern(this.localeAwareFloatRegEx(locale))(control as AbstractControl);
		if (!patternError) return null;

		return { float: { name: PPossibleErrorNames.FLOAT } };
	}

	/**
	 * Turn into number, and then check how many digits are allowed.
	 */
	public maxDecimalPlacesCount(max : number, control : { value : unknown }, locale : PSupportedLocaleIds) : ValidationErrors | null {
		const NUMBER = this.turnLocaleNumberIntoNumber(locale, `${control.value}`);
		return new ValidatorsService().maxDecimalPlacesCount(max, PApiPrimitiveTypes.number).fn({ value: NUMBER });
	}

	/**
	 * Turn into number, and then check min.
	 */
	public min(min : number, control : { value : unknown }, locale : PSupportedLocaleIds) : ValidationErrors | null {
		const NUMBER = this.turnLocaleNumberIntoNumber(locale, `${control.value}`);
		return new ValidatorsService().min(min, true, PApiPrimitiveTypes.number).fn({ value: NUMBER });
	}

	/**
	 * Check if this is a whole number.
	 * OK: "1", "12" or "123"
	 * Not OK: "1,5", "1.5" or "foo"
	 */
	public integer(
		type : PApiPrimitiveTypes | (() => PApiPrimitiveTypes) | null,
		locale : PSupportedLocaleIds,
	) : PValidatorFn<'sync', PValidationErrors<ValidatorsServiceReturnType<'maxDecimalPlacesCount'>>> {
		return (control) => {
			if (control.value === undefined) return null;
			if (control.value === '') return null;
			if (control.value === null) return null;

			let value = control.value;

			const patternError = Validators.pattern(this.localeAwareFloatRegEx(locale))(control as AbstractControl);

			if (typeof value !== 'number') value = this.turnLocaleNumberIntoNumber(locale, control.value);

			const TYPE = (typeof type === 'function') ? type() : (type === null ? PApiPrimitiveTypes.Integer : type);

			// It is possible that the user is about to type 10,5 but only typed 10, yet. This gets handled here.
			if (!!value && (Number.isNaN(+value) || control.value.match(/-?\d+[,.]$/) || patternError)) {
				return { [PPossibleErrorNames.NUMBER_NAN]: {
					name: PPossibleErrorNames.NUMBER_NAN,
					actual: control.value,
					primitiveType: TYPE,
				} };
			}

			// TODO: Remove the null check if possible. It was for backwards compatibility.
			// I replaced this.validators.integer with this.validators.integer(null) in our legacy code.
			if (control.value === null) return null;

			const NUMBER_ERRORS = new ValidatorsService().number(TYPE).fn({ value: value });
			if (NUMBER_ERRORS) return NUMBER_ERRORS;

			if (Number.parseFloat(value.toString()) !== Number.parseInt(value.toString(), 10)) return {
				[PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT]: {
					name: PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT,
					primitiveType: TYPE,
					actual: control.value,
					maxDigitsLength: 0,
				},
			};

			return null;
		};
	}

	private turnLocaleFormattedNumberToFloat(
		input : string,
		locale : PSupportedLocaleIds,
	) : number {
		const SEPARATOR : ',' | '.' = getLocaleNumberSymbol(locale, NumberSymbol.Decimal) as ',' | '.';
		switch (SEPARATOR) {
			case ',' :
				const convertedString = input.replace(/\./g, '').replace(/,/g, '.');
				return +convertedString;
			case '.' :
				if (locale === PSupportedLocaleIds.de_CH)
					input = input.replace(/['’]/g,'');
				return +input;
			default :
				throw new Error('NumberFormat not supported');
		}
	}

	private localeStringToNumber(
		input : number | string | Id,
		locale : PSupportedLocaleIds,
		type : PInputType,
	) : number | string | Id | undefined {
		if (!input) return undefined;
		if (typeof input === 'number') return input;
		if (input instanceof IdBase) {
			this.console.warn('Id in p-input? This must be a mistake, right?');
			return input;
		}

		let regex : RegExp | null = null;
		switch (type) {
			case PApiPrimitiveTypes.Minutes :
			case PApiPrimitiveTypes.Hours :
			case PApiPrimitiveTypes.Days :
			case PApiPrimitiveTypes.Months:
			case PApiPrimitiveTypes.Years :
			case PApiPrimitiveTypes.number :
			case PApiPrimitiveTypes.Integer :
			case PApiPrimitiveTypes.Duration :
			case PApiPrimitiveTypes.Percent:
				regex = this.localeAwareFloatRegEx(locale);
				break;
			case PApiPrimitiveTypes.ClientCurrency :
			case PApiPrimitiveTypes.Euro :
				regex = PInputService.localeAwareFloatWithTwoDigitsAfterSeparatorRegEx(locale);
				break;
			default :

				// throw 'error';
		}

		if (!regex || !input.toString().match(regex)) return input;

		const result = this.turnLocaleFormattedNumberToFloat(input, locale);
		// eslint-disable-next-line unicorn/prefer-number-properties
		return isNaN(result) ? input : result;
	}

	private timeToTimestamp(input : string) : number | null {
		if (!input) return null;
		if (typeof input === 'number') return input;
		const MOMENT = this.pMoment.d(input).asMilliseconds();
		return +MOMENT;
	}

	private toDuration(
		value : number,
		durationUIType : DurationUIType | null,
	) : Duration | null {
		switch (durationUIType) {
			case PApiPrimitiveTypes.Minutes :
				return this.minutesToDuration(value);
			case PApiPrimitiveTypes.Hours :
				return this.hoursToDuration(value);
			case PApiPrimitiveTypes.Days :
				return this.daysToDuration(value);
			default :
				this.console.error(`type »${durationUIType}« unexpected`);
				return value as Duration;
		}
	}

	private minutesToDuration(input : number | null = null) : Duration | null {
		if (input === null) return null;
		return input * 1000 * 60 as Duration;
	}
	private hoursToDuration(input : number | null = null) : Duration | null {
		if (input === null) return null;
		return input * 1000 * 60 * 60 as Duration;
	}
	private daysToDuration(input : number | null = null) : Duration | null {
		if (input === null) return null;
		return input * 1000 * 60 * 60 * 24 as Duration;
	}

	/**
	 * Transform the value that comes from the ui input into a value, that the api can understand.
	 * E.g. if a user types the string '1,5' into a currency input, we want to store the number 1.5 into the AI.
	 * @returns
	 *  - {null} if the value is empty.
	 *  - {number | string | Duration} the transformed value
	 */
	public transformUiValueIntoModelValue(
		value : string,
		locale : PSupportedLocaleIds,
		type : PInputType,
		supportsUnset : boolean | null,
		durationUIType : DurationUIType | null,
	) : string | number | Duration | null {
		const VALUE = typeof value === 'string' ? value.trim() : value;

		// We check isNaN(valueAsFloat) in some cases instead of Number.isNaN(+value), because value can be '1,5'.
		// ..so it can have a german comma-separator.
		const valueAsFloat = this.localeStringToNumber(VALUE, locale, type) as number;

		// Check the description of `supportsUnset`
		// If supportsUnset is true, we want to return undefined in order to trigger the 'notUndefined()' validator.
		if (
			supportsUnset &&
			(VALUE === '' || Number.isNaN(valueAsFloat))
		) return null;

		switch (type) {
			case PApiPrimitiveTypes.LocalTime:
				if (VALUE === '') return null;
				return this.timeToTimestamp(value);
			case PApiPrimitiveTypes.number :
			case PApiPrimitiveTypes.ClientCurrency :
			case PApiPrimitiveTypes.Euro :
			case PApiPrimitiveTypes.Integer :
			case PApiPrimitiveTypes.Minutes :
			case PApiPrimitiveTypes.Hours :
			case PApiPrimitiveTypes.Days :
			case PApiPrimitiveTypes.Years :
			case PApiPrimitiveTypes.Months :
				if (VALUE === '') return null;

				// TODO: PLANO-170336 This type is wrong for the bound AI.
				// eslint-disable-next-line unicorn/prefer-number-properties
				if (isNaN(valueAsFloat)) return value;

				return valueAsFloat;
			case PApiPrimitiveTypes.Percent:
				if (VALUE === '') return null;

				// TODO: PLANO-170336 This type is wrong for the bound AI.
				// eslint-disable-next-line unicorn/prefer-number-properties
				if (isNaN(valueAsFloat)) return value;

				// Api saves percent in range [0, 1] but we visualize it as [0, 100].
				return valueAsFloat / 100;
			case PApiPrimitiveTypes.Duration :
				if (VALUE === '') return null;

				// TODO: PLANO-170336 This type is wrong for the bound AI.
				// eslint-disable-next-line unicorn/prefer-number-properties
				if (isNaN(valueAsFloat)) return value;

				return this.toDuration(valueAsFloat, durationUIType);
			case PApiPrimitiveTypes.string :
			case PApiPrimitiveTypes.Tel :
			case PApiPrimitiveTypes.Email :
			case PApiPrimitiveTypes.Password :
			case 'ConfirmPassword' :
			case PApiPrimitiveTypes.PostalCode :
			case PApiPrimitiveTypes.Search :
			case PApiPrimitiveTypes.Url :
			case PApiPrimitiveTypes.Iban :
			case PApiPrimitiveTypes.Bic :
				return value;
			default :
				return type;
		}
	}
}
