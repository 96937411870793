import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: '[affected]',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AffectedDirective {
	@HostBinding('class.striped-primary')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public affected ! : boolean;
}
