import { Injectable } from '@angular/core';
import { NgbFormatsService, PNgbDateTimeStruct } from '@plano/client/service/ngbformats.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { DateTime, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { PNgbDateStruct } from './p-input-date.component';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputDateService {
	constructor(
		private ngbFormatsService : NgbFormatsService,
	) {
	}

	/**
	 * Combine a NgbDateStruct with a NgbTimeStruct
	 *
	 * @param locale The locale is needed for the PMomentService
	 * @param date The NgbDateStruct to combine and convert
	 * @param time The NgbTimeStruct to combine and convert
	 * @param showTimeInput If the time input is shown
	 *
	 * @returns The combined timestamp or null if the date is not set
	 */
	public convertNgbDateAndNgbTimeToTimestamp(
		locale : PSupportedLocaleIds,
		date : PNgbDateStruct | null,
		time : number | null,
		showTimeInput : boolean | null = null,
	) : number | null {
		if (!date) return null;
		if (date === '-') return null;

		let result : DateTime | null = null;
		result = this.ngbFormatsService.dateTimeObjectToTimestamp(date, locale);
		result = +(new PMomentService(locale).m(result).startOf('day'));
		if (!showTimeInput) return result;

		// time can be null if the user has not entered a time yet.
		if (time !== null) {
			const ngbDateTime : PNgbDateTimeStruct = {
				second : +(new PMomentService(locale)).duration(time).get('seconds'),
				minute : +(new PMomentService(locale)).duration(time).get('minutes'),
				hour : +(new PMomentService(locale)).duration(time).get('hours'),
				...date,
			};
			return this.ngbFormatsService.dateTimeObjectToTimestamp(ngbDateTime, locale);
		}
		return result;
	}
}
