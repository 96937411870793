<ng-container oddEvenParent>
	<p-section
		label="Mehr Buchungen dank Marketing-Gutscheinen" i18n-label
		pAnchorLink="more-bookings-with-marketing-gift-cards"
		[isDescriptiveVisualGrid]="true"
	>
		<div descriptive>
			<p i18n>Wenn dein Kunde gerade ein Angebot für z.B. Anfänger absolviert hat, ist es der beste Zeitpunkt, der Person einen Gutschein für das fortführende Aufbau-Angebot zu schicken. Zu diesem Zeitpunkt ist die Motivation für eine weitere Buchung am stärksten. Mit Dr.&nbsp;Plano hast du die Möglichkeit, solche Marketing-Gutscheine völlig automatisiert zu versenden, sobald ein Kunde die Teilnahme an einem gebuchten Angebot abgeschlossen hat.</p>
			<p class="mb-0" i18n>Mehr darüber, wie Marketing-Gutscheine funktionieren, wie viel sie kosten und warum sie sich wahrscheinlich für dich rentieren werden, erfährst du <a routerLink="." fragment="faq-marketing-gift-cards">unten<fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.SCROLL_DOWN"/></a></p>
		</div>
		<div visual>
			<!-- linthtml-configure img-req-alt="false" --><!-- accessibility rules: a decorative image must have a null alt-attribute -->
			<img alt="" i18n-alt src="images/animated/animation_marketing-gift-cards.webp"/>
		</div>
	</p-section>
	<p-section
		label="Marketing-Gutscheine für diese Tätigkeit aktivieren" i18n-label
		pAnchorLink="activate-marketing-gift-cards"
	>
		<p class="mb-5" i18n>Du kannst für jede buchbare Tätigkeit separat Marketing-Gutscheine aktivieren & individuell einstellen. So bleibst du sehr flexibel 👍</p>
		<div class="row mb-4">
			<ng-template #cannotSetMarketingGiftCardsTemplate let-cannotSetHintSource>
				<p
					*ngVar="linkBasedOnCannotSetHint(cannotSetHintSource) as routerInfo">{{cannotSetHintSource | localize}} <a
					[target]="routerInfo.openInNewTab ? '_blank' : '_self'"
					[routerLink]="routerInfo.link"
					[fragment]="routerInfo.fragment ?? undefined"
				><ng-container i18n>hier</ng-container><fa-icon class="ml-1" [icon]="routerInfo.openInNewTab ? enums.PlanoFaIconPool.NAV_NEW_TAB : enums.PlanoFaIconPool.NAV_FORWARD"/></a></p>
			</ng-template>
			<p-ai-switch
				class="col-lg-6"
				[cannotSetHintTemplate]="cannotSetMarketingGiftCardsTemplate"
				[icon]="enums.PlanoFaIconPool.MARKETING"
				[hideValueIcon]="true"
				label="Versand aktivieren" i18n-label
				valueText="Marketing-Gutscheine für diese Tätigkeit versenden" i18n-valueText
				[attributeInfo]="marketingGiftCardSettings.attributeInfoActivated"
				[group]="formGroup"
			></p-ai-switch>
			<p-ai-switch
				class="col-lg-6"
				label="Guthaben jedes Gutscheins" i18n-label
				[attributeInfo]="marketingGiftCardSettings.attributeInfoInitialBalance"
				[group]="formGroup"
			></p-ai-switch>
			<p-ai-switch
				class="col-lg-6"
				label="Mindest-Buchungswert beim Einlösen" i18n-label
				description="<p>Marketing-Gutscheine können für alle deine online über Dr.&nbsp;Plano buchbaren Angebote eingelöst werden – die Einlösung vor Ort an der Kasse ist <strong>nicht möglich</strong>.</p> Um zu vermeiden, dass die Gutscheine für sehr günstige Angebote eingelöst werden, kannst du hier einen Mindest-Buchungswert definieren, den deine Kunden erreichen müssen, bevor sie den Gutschein einlösen können. Der Mindest-Buchungswert wird auf dem Gutschein vermerkt sein und kann nach der Gutschein-Ausstellung nicht mehr geändert werden. Deshalb wirken sich Änderungen des Werts hier nur auf neu ausgestellte Marketing-Gutscheine aus – bereits verschickte Gutscheine behalten ihren ursprünglichen Wert bei." i18n-description
				[attributeInfo]="marketingGiftCardSettings.attributeInfoMinShoppingCardValue"
				[group]="formGroup"
			></p-ai-switch>
			<p-ai-switch
				class="col-lg-6"
				label="Gültigkeitsdauer eines Marketing-Gutscheins" i18n-label
				description="Es ist empfehlenswert, die Gültigkeitsdauer von Marketing-Gutscheinen zu beschränken. Keine Sorge: Der hier eingetragene Wert bezieht sich nur auf Marketing-Gutscheine, die über diese Tätigkeit verschickt werden; die Gültigkeitsdauer von Marketing-Gutscheinen anderer Tätigkeiten oder gar der normal erwerblichen Gutscheine bleibt davon unberührt." i18n-description
				[attributeInfo]="marketingGiftCardSettings.attributeInfoExpirationDuration"
				inputGroupAppendText="Monate nach Gutschein-Ausstellung" i18n-inputGroupAppendText
				[group]="formGroup"
			></p-ai-switch>
			<p-ai-switch
				label="Empfänger der Marketing-Gutscheine" i18n-label
				description="Entscheide, welche Personen einen Marketing-Gutschein erhalten sollten. Es empfiehlt sich, die Gutscheine an alle teilnehmenden Personen zu schicken, um einen größeren Personenkreis zu erreichen. Oft sind damit auch die buchenden Personen eingeschlossen, da sie selbst am gebuchten Angebot teilnehmen." i18n-description
				class="col-12"
				[attributeInfo]="marketingGiftCardSettings.attributeInfoRecipient"
				[group]="formGroup"
			>
				<p-ai-switch-item
					label="Buchende Person" i18n-label
					[value]="SchedulingApiMarketingGiftCardSettingsRecipient.BOOKING_PERSON"
				/>
				<p-ai-switch-item
					label="Teilnehmende Personen" i18n-label
					[value]="SchedulingApiMarketingGiftCardSettingsRecipient.BOOKING_PARTICIPANTS"
				/>
			</p-ai-switch>
		</div>
		<p-editable-modal-box
			[disabled]="!marketingGiftCardSettings.attributeInfoBookedEmailCustomMessage.canSet"
			[pEditable]="!marketingGiftCardSettings.isNewItem()"
			modalTitle="Email für den Gutschein-Versand" i18n-modalTitle
			[modalWhitespace]="SectionWhitespace.NONE"
			[api]="api"
			label="Email für den Gutschein-Versand" i18n-label
		>
			<p-editable-modal-box-showroom i18n>Kurz nach Ablauf ihres gebuchten Termins erhalten deine Kunden eine automatische Email mit einem Marketing-Gutschein im Anhang. Optional kannst du dem Email-Inhalt zusätzliche Infos hinzufügen.</p-editable-modal-box-showroom>
			<p-editable-modal-form oddEvenParent>
				<p-section>
					<p i18n>Wenn in einer Tätigkeit Marketing-Gutscheine aktiviert sind, erhalten deine Kunden kurz nach Ablauf ihres gebuchten Termins einen Marketing-Gutschein zugeschickt; und zwar als PDF-Datei im Anhang einer automatischen Email. Als Design-Vorlage für die PDF-Datei wird dieselbe <a routerLink="/client/plugin/gift-cards/settings" fragment="gift-card-template" target="_blank" rel="noopener">Vorlage<fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.NAV_NEW_TAB"/></a> verwendet, die für gewöhnlich erworbene Gutscheine benutzt wird.</p>

					<p i18n>Unten auf dieser Seite siehst du den Inhalt der Email, die an deine Kunden geschickt wird. Die darin enthaltenen Daten wie z.B. der Kundenname oder der Gutscheinwert sind Beispieldaten und werden beim Versenden echter Gutscheine durch reale Daten ersetzt.</p>

					<p class="mb-0" i18n>Aktuell kannst du nicht den gesamten Email-Inhalt ändern, aber dem einen <strong>eigenen Text hinzufügen</strong>, falls du das möchtest. Der Text wird später in der verschickten Email genau an derselben Stelle positioniert sein, wie unten in der Vorschau zu sehen ist.</p>
				</p-section>
				<p-section
					label="Email-Inhalt" i18n-label
					pAnchorLink="email-template"
					class="ignore-gutter pb-5-important"
				>
					<div class="email-wrapper pl-3">
						<p i18n>Hallo {{currentMember.firstName}} {{currentMember.lastName}},</p>
						<p i18n>mit dieser Email schenken wir dir einen <strong>Rabatt-Gutschein über {{marketingGiftCardSettings.attributeInfoInitialBalance | currency}}</strong> 🎁 Das ist ein kleines Dankeschön für deine Buchung von »{{marketingGiftCardSettings.parent?.courseTitle ?? 'Anfängerkurs Klettern'}}« (Buchungsnr. 64295)</p>
						<p i18n>Du findest den Gutschein als PDF-Datei im Anhang und kannst ihn bei deiner nächsten Online-Buchung einlösen 😃</p>
						<p-ai-switch
							[type]="AISwitchUIType.TEXT_EDITOR"
							placeholder="An dieser Stelle der Email kannst du optional einen Text hinzufügen…" i18n-placeholder
							[attributeInfo]="marketingGiftCardSettings.attributeInfoBookedEmailCustomMessage"
							[group]="formGroup"
						></p-ai-switch>
						<p><strong [style.textTransform]="'none'" i18n>Mehr Infos zum Gutschein und zur Einlösung:</strong></p>
						<p i18n>Einlösbar nur bei der <strong>Online-Buchung</strong> ab einem <strong>Buchungswert von:</strong> {{marketingGiftCardSettings.attributeInfoMinShoppingCardValue | currency}}</p>
						<p i18n><strong>Gutscheinwert: </strong>{{marketingGiftCardSettings.attributeInfoInitialBalance | currency}}</p>
						<p i18n><strong>Gutschein-Code: </strong>DRP123456789</p>
						<p i18n><strong>Gültig bis: </strong>{{expirationDate | date}}</p>
						<p i18n><strong>Buchungsnummer des Gutscheins: </strong>123123 (bei Rückfragen bitte angeben)</p>
						<p i18n>Der Gutschein lässt sich mit dem obigen Gutschein-Code einlösen, der auch auf deinem Gutschein im Anhang zu finden ist.</p>
						<p i18n>Wir freuen uns auf deine nächste Buchung 😃</p>
						<p i18n>dein Team von {{locationName}}</p>
					</div>
				</p-section>
			</p-editable-modal-form>
		</p-editable-modal-box>
		<p-alert
			[icon]="enums.PlanoFaIconPool.RISING"
			[theme]="marketingGiftCardSettings.isNewItem() ? enums.PThemeEnum.INFO : enums.PThemeEnum.SUCCESS"
			*ngIf="marketingGiftCardSettings.activated && marketingGiftCardSettings.parent!.isCourse"
			class="mt-4 mb-0"
		>
			<ng-container *ngIf="marketingGiftCardSettings.isNewItem()" i18n>Deine Marketing-Kampagne kann starten!</ng-container>
			<ng-container *ngIf="!marketingGiftCardSettings.isNewItem()" i18n>Glückwunsch! Die Marketing-Kampagne läuft!</ng-container>
			<p class="mt-2 mb-0">
				<ng-container *ngIf="!marketingGiftCardSettings.isNewItem() && activatedByMember && marketingGiftCardSettings.activatedDateTime" i18n>Den Versand von Marketing-Gutscheinen {activatedByMySelf, select, true {hast du} other {hat {{activatedByMember.firstName}} {{activatedByMember.lastName}}}} am <mark>{{marketingGiftCardSettings.activatedDateTime | date}}</mark> aktiviert.</ng-container>&ngsp;
				<ng-container i18n>Über das Resultat der Marketing-Kampagne wirst du in der Marketing-Statistik informiert, die dir Dr.&nbsp;Plano automatisch ein Mal pro Monat per Email schickt. Mehr darüber und über die allgemeine Funktionsweise von Marketing-Gutscheinen erfährst du <a routerLink="." fragment="faq-marketing-gift-cards">unten<fa-icon class="ml-1" [icon]="enums.PlanoFaIconPool.SCROLL_DOWN"/></a></ng-container>
			</p>
		</p-alert>
		<p-alert
			[icon]="enums.PlanoFaIconPool.NOT_ALLOWED"
			[theme]="enums.PThemeEnum.WARNING"
			*ngIf="marketingGiftCardSettings.activated && !marketingGiftCardSettings.parent!.isCourse"
			class="mt-4 mb-0"
		>
			<span i18n>Zwar ist der Versand von Marketing-Gutscheinen aktiviert, aber da diese Tätigkeit als nicht buchbar eingestellt ist, können keine neue Buchungen entstehen, für die Marketing-Gutscheine versendet werden. Lediglich für eventuell bereits vorhandene Buchungen werden Marketing-Gutscheine verschickt.</span>
			<p class="mt-2 mb-0" i18n>Um diese Tätigkeit buchbar zu machen, <a routerLink="../bookingsettings" fragment="is-course-checkbox" [replaceUrl]="true" [relativeTo]="activatedRoute">klicke hier<fa-icon [icon]="enums.PlanoFaIconPool.NAV_FORWARD" class="ml-1"></fa-icon></a></p>
		</p-alert>
	</p-section>
	<p-section
		label="Fragen & Antworten" i18n-label
		pAnchorLink="questions-and-answers"
	>
		<p-marketing-gift-cards-faq anchorLink="faq-marketing-gift-cards"/>
	</p-section>
</ng-container>
