import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiWarningBase, SchedulingApiWarningsBase, SchedulingApiWarningSeverity } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiWarnings extends SchedulingApiWarningsBase {

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get withSeverityFatalCount() : number {
		return this.filterBy((item) => item.severity === SchedulingApiWarningSeverity.FATAL).length;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get withSeverityInfoCount() : number {
		return this.filterBy((item) => item.severity === SchedulingApiWarningSeverity.INFO).length;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get withSeverityWarningCount() : number {
		return this.filterBy((item) => item.severity === SchedulingApiWarningSeverity.WARNING).length;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public getByOffer(offer : SchedulingApiShiftExchangeCommunicationSwapOffer) : SchedulingApiWarnings {
		assumeDefinedToGetStrictNullChecksRunning(offer, 'offer');
		return this.filterBy((item) => {
			if (offer.id.equals(item.forSwapOfferId)) return true;
			if (item.forSwapOfferNewItemId === offer.newItemId) return true;
			return false;
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public getByMember(memberId : Id | null) : SchedulingApiWarnings {
		if (memberId === null) return new SchedulingApiWarnings(null, null);
		return this.filterBy((item) => item.concernsMemberId.equals(memberId));
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiWarning extends SchedulingApiWarningBase {

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get theme() : PThemeEnum {
		switch (this.severity) {
			case SchedulingApiWarningSeverity.FATAL :
				return enumsObject.PThemeEnum.DANGER;
			case SchedulingApiWarningSeverity.INFO :
				return enumsObject.PThemeEnum.INFO;
			case SchedulingApiWarningSeverity.WARNING :
				return enumsObject.PThemeEnum.WARNING;
			default :
				throw new Error('unknown severity');
		}
	}

}
