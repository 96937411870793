/* eslint-disable import/no-relative-parent-imports -- Remove this before you work here. */
// cSpell:ignore kolkov
/* eslint max-lines: ["error", 230] */
import { NgModule } from '@angular/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbDatepickerModule, NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PListsModule } from '@plano/client/shared/p-lists/p-lists.module';
import { getPopoverConfig } from '@plano/ngx-bootstrap.config';
import { CoreModule } from '@plano/shared/core/core.module';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { ClearOnFocusInputDirective } from '@plano/shared/p-forms/clear-on-focus-input.directive';
import { InputGroupAppendDirective, InputGroupPrependDirective } from '@plano/shared/p-forms/input-groups.directive';
import { PAIFormArrayComponent } from '@plano/shared/p-forms/p-ai-form-array/p-ai-form-array.component';
import { PAIFormBuilderComponent } from '@plano/shared/p-forms/p-ai-form-builder/p-ai-form-builder.component';
import { AnchorLinkAtCollapsibleDirective } from '@plano/shared/p-forms/p-anchor/p-anchor-collapsible.directive';
import { PTextEditorComponent } from '@plano/shared/p-forms/p-text-editor/p-text-editor.component';
import * as $ from 'jquery';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PAttributeInfoModule } from '../../client/shared/p-attribute-info/attribute-info.module';
import { EditableDirective } from '../../client/shared/p-editable/editable/editable.directive';
import { PEditableModule } from '../../client/shared/p-editable/p-editable.module';
import { PLedModule } from '../../client/shared/p-led/p-led.module';
import { PMemberModule } from '../../client/shared/p-member/p-member.module';
import { PShiftModelModule } from '../../client/shared/p-shiftmodel/p-shiftmodel.module';
import { PageModule } from '../../client/shared/page/page.module';
import { ClientPipesModule } from '../../client/shared/pipe/client-pipes.module';
import { PDurationPipe } from '../../client/shared/pipe/p-duration.pipe';
import { SharedModule } from '../../client/shared/shared/shared.module';
import { PCardModule } from '../core/component/card/card.module';
import { CoreComponentsModule } from '../core/component/core-components.module';
import { PGridModule } from '../core/component/grid/grid.module';
import { AutosizeDirective } from './autosize/autosize.directive';
import { FormControlShowcaseComponent } from './form-control-showcase/form-control-showcase.component';
import { PInputColorComponent } from './input-color/p-input-color.component';
import { PInputImageCropperComponent } from './input-image/input-image-cropper/input-image-cropper.component';
import { PInputImageComponent } from './input-image/input-image.component';
import { InputMemberComponent } from './input-member/input-member.component';
import { PInputPdfComponent } from './input-pdf/input-pdf.component';
import { PPdfTemplateComponent } from './input-pdf/pdf-template/pdf-template.component';
import { PAIFormGroupComponent } from './p-ai-form-group/p-ai-form-group.component';
import { PAISwitchItemComponent } from './p-ai-switch/p-ai-switch-item/p-ai-switch-item.component';
import { PAISwitchComponent } from './p-ai-switch/p-ai-switch.component';
import { AnchorLinkAtLabelDirective } from './p-anchor/p-anchor-label.directive';
import { AnchorLinkAtSpanDirective } from './p-anchor/p-anchor-span.directive';
import { AnchorLinkDirective } from './p-anchor/p-anchor.directive';
import { PBootstrapFormGroupComponent } from './p-bootstrap-form-group/p-bootstrap-form-group.component';
import { PButtonComponent } from './p-button/p-button.component';
import { CustomCheckboxTestComponent } from './p-checkbox/io-spec/p-checkbox-test.component';
import { PCheckboxComponent } from './p-checkbox/p-checkbox.component';
import { PDeleteButtonComponent } from './p-delete-button/p-delete-button.component';
import { PDropdownItemComponent } from './p-dropdown/p-dropdown-item/p-dropdown-item.component';
import { PDropdownComponent } from './p-dropdown/p-dropdown.component';
import { PFormDeleteSectionComponent } from './p-form-delete-section/p-form-delete-section.component';
import { PFormRecoverSectionComponent } from './p-form-recover-section/p-form-recover-section.component';
import { PInputCopyStringComponent } from './p-input-copy-string/p-input-copy-string.component';
import { PInputDateTestComponent } from './p-input-date/io-spec/test.component';
import { PInputDateComponent } from './p-input-date/p-input-date.component';
import { PInputMemberIdComponent } from './p-input-member-id/p-input-member-id.component';
import { PInputSearchComponent } from './p-input-search/input-search.component';
import { PInputShiftModelIdComponent } from './p-input-shiftmodel-id/p-input-shiftmodel-id.component';
import { PInputComponent } from './p-input/p-input.component';
import { CustomMultiValueInputTestComponent } from './p-multi-value-input/io-spec/p-multi-value-input-test.component';
import { PMultiValueInputComponent } from './p-multi-value-input/p-multi-value-input.component';
import { PPasswordStrengthMeterComponent } from './p-password-strength-meter/p-password-strength-meter.component';
import { PRadioComponent } from './p-radios/p-radio/p-radio.component';
import { PRadiosRadioComponent } from './p-radios/p-radios-radio/p-radios-radio.component';
import { PRadiosComponent } from './p-radios/p-radios.component';
import { PRecoverButtonComponent } from './p-recover-button/p-recover-button.component';
import { PTariffFormComponent } from './p-tariff-form/p-tariff-form.component';
import { CustomTextareaTestComponent } from './p-textarea/io-spec/p-textarea-test.component';
import { PTextareaComponent } from './p-textarea/p-textarea.component';

window['$'] = $; window['jQuery'] = $;

declare const window : {
	new() : Window;
	prototype : Window;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key : string] : any; // NOTE: missing index definition
};

@NgModule({
	imports: [
		AngularEditorModule,
		BsDropdownModule.forRoot(),
		ClientPipesModule,
		CoreComponentsModule,
		CoreModule,
		ImageCropperModule,
		NgbDatepickerModule,
		NgbModalModule,
		NgbTypeaheadModule,
		PageModule,
		PAttributeInfoModule,
		PCardModule,
		PdfViewerModule,
		PEditableModule,
		PGridModule,
		PLedModule,
		PListsModule,
		PMemberModule,
		PopoverModule,
		PShiftModelModule,
		SharedModule,
		TypeaheadModule,
	],
	declarations: [
		AnchorLinkAtCollapsibleDirective,
		AnchorLinkAtLabelDirective,
		AnchorLinkAtSpanDirective,
		AnchorLinkDirective,
		AutosizeDirective,
		ClearOnFocusInputDirective,
		CustomCheckboxTestComponent,
		CustomMultiValueInputTestComponent,
		CustomTextareaTestComponent,
		FormControlShowcaseComponent,
		InputGroupAppendDirective,
		InputGroupPrependDirective,
		InputMemberComponent,
		PAIFormArrayComponent,
		PAIFormBuilderComponent,
		PAIFormGroupComponent,
		PAISwitchComponent,
		PAISwitchItemComponent,
		PBootstrapFormGroupComponent,
		PButtonComponent,
		PCheckboxComponent,
		PDeleteButtonComponent,
		PDropdownComponent,
		PDropdownItemComponent,
		PFormDeleteSectionComponent,
		PFormRecoverSectionComponent,
		PInputColorComponent,
		PInputComponent,
		PInputCopyStringComponent,
		PInputDateComponent,
		PInputDateTestComponent,
		PInputImageComponent,
		PInputImageCropperComponent,
		PInputMemberIdComponent,
		PInputPdfComponent,
		PInputSearchComponent,
		PInputShiftModelIdComponent,
		PMultiValueInputComponent,
		PPasswordStrengthMeterComponent,
		PPdfTemplateComponent,
		PRadioComponent,
		PRadiosComponent,
		PRadiosRadioComponent,
		PRecoverButtonComponent,
		PTariffFormComponent,
		PTextareaComponent,
		PTextEditorComponent,
	],
	providers: [
		EditableDirective,
		NgxImageCompressService,
		PCurrencyPipe,
		PDurationPipe,
		{
			provide: PopoverConfig,
			useFactory: getPopoverConfig,
		},
	],
	exports: [
		AnchorLinkAtCollapsibleDirective,
		AnchorLinkAtLabelDirective,
		AnchorLinkAtSpanDirective,
		AnchorLinkDirective,
		ClearOnFocusInputDirective,
		CustomCheckboxTestComponent,
		FormControlShowcaseComponent,
		InputGroupAppendDirective,
		InputGroupPrependDirective,
		InputMemberComponent,
		PAIFormArrayComponent,
		PAIFormBuilderComponent,
		PAIFormGroupComponent,
		PAISwitchComponent,
		PAISwitchItemComponent,
		PAttributeInfoModule,
		PBootstrapFormGroupComponent,
		PButtonComponent,
		PCheckboxComponent,
		PDeleteButtonComponent,
		PDropdownComponent,
		PDropdownItemComponent,
		PFormDeleteSectionComponent,
		PFormRecoverSectionComponent,
		PInputColorComponent,
		PInputComponent,
		PInputCopyStringComponent,
		PInputDateComponent,
		PInputDateTestComponent,
		PInputImageComponent,
		PInputImageCropperComponent,
		PInputMemberIdComponent,
		PInputPdfComponent,
		PInputSearchComponent,
		PInputShiftModelIdComponent,
		PMultiValueInputComponent,
		PPasswordStrengthMeterComponent,
		PPdfTemplateComponent,
		PRadioComponent,
		PRadiosComponent,
		PRadiosRadioComponent,
		PRecoverButtonComponent,
		PTariffFormComponent,
		PTextareaComponent,
		PTextEditorComponent,
	],
})
export class PFormsModule {}
