import { ChangeDetectionStrategy, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SLIDE_RTL_ON_NGIF_TRIGGER } from '@plano/animations';
import { PAppStartupService } from '@plano/app-startup.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { MeService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PPushNotificationsService } from '@plano/shared/core/p-push-notifications.service';
import { PProgressbarService } from '@plano/shared/core/progressbar.service';
import { PLaunchDarklyService } from '@plano/shared/launch-darkly/launch-darkly';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import * as $ from 'jquery';
import { NgProgressComponent } from 'ngx-progressbar';
import { SchedulingService } from './scheduling/scheduling.service';
import { SchedulingApiService } from './scheduling/shared/api/scheduling-api.service';
import { PWishesService } from './scheduling/wishes.service';
import { HighlightService } from './shared/highlight.service';

@Component({
	selector: 'p-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_RTL_ON_NGIF_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ClientComponent implements OnInit, OnDestroy {
	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column') protected _alwaysTrue = true;

	@ViewChild('progressBar', { static: true }) public progressBar ! : NgProgressComponent;

	@HostListener('document:keydown.esc', ['$event']) private _onEsc(event : KeyboardEvent) : void {
		this.console.debug('ClientComponent._onEsc()');
		if (!this.modalService.hasHighlighCancelBlockModals && this.highlightService.highlightedItem) {
			this.highlightService.setHighlighted(null);
			this.pWishesService.item = null;
			event.stopPropagation();
			return;
		}

		if (this.schedulingService.earlyBirdMode) {
			this.schedulingService.earlyBirdMode = false;
			event.stopPropagation();
			return;
		}
		if (this.schedulingService.wishPickerMode) {
			this.schedulingService.wishPickerMode = false;
			event.stopPropagation();
			return;
		}
		if (this.toasts.length > 0) {
			const newestToast = this.toasts.newest;
			this.toasts.removeToast(newestToast);
			event.stopPropagation();
		}
	}

	constructor(
		public meService : MeService,
		public toasts : ToastsService,
		private highlightService : HighlightService,
		private pWishesService : PWishesService,
		private console : LogService,
		public api : SchedulingApiService,
		public pushNotifications : PPushNotificationsService,
		private pAppStartupService : PAppStartupService,
		private pLaunchDarklyService : PLaunchDarklyService,
		private pProgressbarService : PProgressbarService,
		private modalService : ModalService,
		private schedulingService : SchedulingService,
		private pSentryService : PSentryService,
	) {
		pushNotifications.setApi(api);

		// The login form of the client area is on the public page
		Config.LOGIN_PATH = Config.IS_MOBILE ? '/mobile-login' : '/';

		// For this component we need credentials
		this.meService.loginFromCookieCredentials(undefined, () => {
			if (this.meService.showExpiredClientViewForOwner) return;
			this.meService.rememberPathWhenLoginFailed();
			this.meService.openLoginPage();
		});

		// init services after meService is loaded (as it is needed for reading personalized cookies)
		this.meService.isLoaded(async () => {
			await this.pLaunchDarklyService.init(this.meService.data.clientId.toString());
			this.initAllServices();
		});
	}

	private initAllServices() : void {
		// [Read cookies if they implement PServiceWithCookiesInterface and] initialize all Services
		this.pAppStartupService.init();
	}

	public ngOnInit() : void {
		$('html').addClass('client');

		this.pProgressbarService.setSubscriber(this.progressBar);

		// Adding the listener in the capturing phase
		document.addEventListener('click', this.onDocumentClick, true);
	}

	public ngOnDestroy() : void {
		$('html').removeClass('client');

		// Removing the listener when the component is destroyed
		document.removeEventListener('click', this.onDocumentClick, true);

	}

	private onDocumentClick = (event : MouseEvent) : void => {
		if (event.target)
			this.pSentryService.addClickTextInfo(event.target);
	};

}
