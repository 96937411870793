<div
	class="d-flex align-items-stretch wish-picker-wrap justify-content-end btn-group rounded-0"
	[class.expanded]="!collapsed"
>
	<!-- linthtml-configure tag-req-attr="false" -->
	<button
		class="btn btn-outline-secondary btn-sm ml-2 nowrap border-top-0 border-right-0 border-bottom-0" type="button"
		[class.btn-frameless]="CONFIG.IS_MOBILE"
		[class.border-left]="CONFIG.IS_MOBILE"
		[disabled]="!api.isLoaded()"
		(click)="stopMode()"
		title="Abgabe von Schichtwünschen beenden" i18n-title
	><fa-icon
		[icon]="enums.PlanoFaIconPool.DISMISS"
	></fa-icon>&nbsp;<ng-container i18n>Beenden</ng-container></button>

	<!-- linthtml-configure tag-req-attr="false" -->
	<button
		[@slideHorizontal]
		*ngIf="showPickerButtons"
		type="button" class="wishes clickable btn justify-content-center btn-dark text-white"
		[class.btn-frameless]="CONFIG.IS_MOBILE"
		(click)="handleOnPick($event)"
		style="border-top:none;border-bottom:none;border-right:none;"
		[disabled]="pickerButtonsDisabled"

		[attr.aria-label]="popoverForREMOVE"
		[pTooltip]="popoverForREMOVE"
		[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"

	><fa-icon [class.heart-beat]="pickerButtonsDisabled" icon="heart"></fa-icon></button>

	<button type="button" class="wishes clickable btn justify-content-center btn-success text-white border-0"
		[@slideHorizontal]
		*ngIf="showPickerButtons"
		[class.btn-frameless]="CONFIG.IS_MOBILE"
		(click)="handleOnPick($event, memberPrefValues.WANT)"
		style="border-top:none;border-bottom:none;"
		[disabled]="pickerButtonsDisabled"

		[attr.aria-label]="popoverForWANT"
		[pTooltip]="popoverForWANT"
		[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
	><fa-icon [class.heart-beat]="pickerButtonsDisabled" icon="heart"></fa-icon></button>
	<button type="button" class="wishes clickable btn justify-content-center btn-warning text-white border-0"
		[@slideHorizontal]
		*ngIf="showPickerButtons"
		[class.btn-frameless]="CONFIG.IS_MOBILE"
		(click)="handleOnPick($event, memberPrefValues.CAN)"
		style="border-top:none;border-bottom:none;"
		[disabled]="pickerButtonsDisabled"

		[attr.aria-label]="popoverForCAN"
		[pTooltip]="popoverForCAN"
		[pTooltipPlacement]="NgxPopperjsPlacements.LEFT"
	><fa-icon [class.heart-beat]="pickerButtonsDisabled" icon="heart"></fa-icon></button>
	<button type="button" class="wishes clickable btn justify-content-center btn-danger text-white border-0"
		[@slideHorizontal]
		*ngIf="showPickerButtons"
		[class.btn-frameless]="CONFIG.IS_MOBILE"
		(click)="handleOnPick($event, memberPrefValues.CANNOT)"
		style="border-top:none;border-bottom:none;"
		[disabled]="pickerButtonsDisabled"

		[attr.aria-label]="popoverForCANNOT"
		[pTooltip]="popoverForCANNOT"
		[pTooltipPlacement]="NgxPopperjsPlacements.LEFT"
	><fa-icon [class.heart-beat]="pickerButtonsDisabled" icon="heart"></fa-icon></button>
</div>

<!-- [@scaleIn]="collapsed" -->
<!-- <a class="wishes clickable shadow text-white"
	*ngIf="collapsed"
	[class.bg-dark]="!myPref"
	[class.btn-warning]="myPref===memberPrefValues.CAN"
	[class.btn-danger]="myPref===memberPrefValues.CANNOT"
	[class.btn-success]="myPref===memberPrefValues.WANT"
	(click)="onUncollapse($event)"
><fa-icon [icon]="!myPref ? 'heart' : 'heart'"></fa-icon></a> -->
