<div
	*ngIf="show"

	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit()"
	(onSaveSuccess)="onSaveSuccess.emit();"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
	[class.has-wrapped-appended]="isAppendWrapped"
	class="input-group main-div {{!showWholeInputWrapper ? inputWrapperClasses : ''}}"
	[disabled]="disabled || readMode"
>
	<ng-template #wholeInputWrapperContent>
		<ng-container *ngTemplateOutlet="prependItem"></ng-container>
		<input
			*ngIf="!(readMode && type === PApiPrimitiveTypes.Email)"
			#inputEl

			[hasAutoFocusPriority]="hasAutoFocusPriority"
			pAutoFocus

			pEditableTriggerFocussable
			#pEditableTriggerFocussableRef
			class="form-control"
			[attr.role]="ariaRole"
			[class.editable-buttons-visible]="editableButtonsVisible"
			[class.has-non-interactive-prepend]="hasInteractivePrepend === false"
			[class.has-append]="hasInteractiveAppend !== null"
			[class.has-non-interactive-append]="hasInteractiveAppend === false"
			[hidden]="inputHidden === true"
			[type]="inputType"
			[autocomplete]="autocomplete"
			[readonly]="readonly"
			[class.is-ios-app]="Config.platform === 'appIOS'"
			[class.form-control-sm]="size === enums.BootstrapSize.SM"
			[class.form-control-lg]="size === enums.BootstrapSize.LG"
			[class.form-control-read-mode]="readMode"
			[class.form-control-title]="readMode"
			[class.text-left]="textAlign === 'left'"
			[class.text-center]="textAlign === 'center'"
			[class.text-right]="textAlign === 'right'"
			[(ngModel)]="readMode ? readModeValue : value"
			[placeholder]="placeholder !== undefined ? placeholder : ''"
			(focusout)="onFocusOut($event)"
			(focus)="onFocus($event)"
			(change)="onChange($event)"
			(keyup)="keyUp($event)"
			(keydown)="onKeyDown($event)"
			(blur)="onBlur()"
			[disabled]="!!(disabled || readMode)"
			[required]="hasRequiredError"
			[class.d-none]="readMode"
			[typeahead]="typeahead ? typeahead : []"
			[container]="appendOnBody ? 'body' : undefined"
			[typeaheadMinLength]="0"
			(typeaheadOnSelect)="typeaheadOnSelect($event, pEditableTriggerFocussableRef)"
			[min]="min"
			[max]="max"
		/>
		<ng-template
			*ngTemplateOutlet="hasInteractiveAppend === false ? appendTemplate : null"
		></ng-template>
	</ng-template>

	<ng-template #appendTemplate>
		<ng-template [ngIf]="readMode && type === PApiPrimitiveTypes.Email" [ngIfElse]="realModeTemplate">
			<ng-template #emailLinkTemplate>
				<a class="form-control-read-mode crop-on-overflow text-primary" href="mailto:{{value}}">{{ value ? value : '&nbsp;' }}</a>
			</ng-template>
		</ng-template>
		<ng-template #realModeTemplate>
			<div class="input-group-append appended-editable-buttons" #appendEditableButtons pVisibleInEditMode *ngIf="pEditable">
				<button
					type="reset"
					class="btn btn-outline-secondary"
					pEditableDismissButton
				><fa-icon [icon]="enums.PlanoFaIconPool.UNDO"></fa-icon></button>
				<button
					type="submit"
					class="btn btn-outline-secondary"
					pEditableSuccessButton
				><fa-icon [class.text-warning]="hasWarning" [icon]="successBtnIcon" [spin]="successBtnIconSpin"></fa-icon></button>
			</div>
		</ng-template>
		<ng-container *ngTemplateOutlet="appendItem"></ng-container>
	</ng-template>

	<div
		*ngIf="showWholeInputWrapper; else wholeInputWrapperContent"
		[disabled]="disabled || readMode"
		class="whole-input-wrapper input-group form-control {{inputWrapperClasses}}"
		[class.has-wrapped-appended]="isAppendWrapped"
		[class.rounded-right]="inputEl?.nativeElement?.classList?.contains('rounded-right') && (hasInteractiveAppend === null)"
		[class.form-control-read-only]="readonly"
		[class.form-control-read-mode]="readMode"
		[class.editable-buttons-visible]="editableButtonsVisible"

		[class.focus]="isInputFocused">
		<ng-template *ngTemplateOutlet="wholeInputWrapperContent"></ng-template>
	</div>
	<ng-template *ngTemplateOutlet="hasInteractiveAppend !== false ? appendTemplate : null"></ng-template>


</div>

<ng-template [ngIf]="!!control && hasDanger">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[theme]="hintTheme===null ? enums.PThemeEnum.DANGER : hintTheme"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[touched]="control.touched"
	></p-validation-hint>
</ng-template>

<ng-template [ngIf]="showPasswordMeter">
	<p-password-strength-meter
		[passwordToCheck]="value?.toString()"
		[inputValidationFailed]="!!visibleErrors?.length"
	></p-password-strength-meter>
</ng-template>

<ng-template #prependItem>
	<div class="input-group-prepend"
		role="status"
		*ngIf="inputGroupPrependIcon && (!inputGroupAppendText || forcePrependIconOnPrepend)"
	>
		<span
			class="input-group-text"
			*ngIf="type!==PApiPrimitiveTypes.Search"
			(click)="onClickPrependIcon(inputEl)"><fa-icon [spin]="inputGroupPrependIconSpin" type="solid" [icon]="inputGroupPrependIcon"></fa-icon></span>
		<span class="input-group-text" *ngIf="type===PApiPrimitiveTypes.Search && !value"><fa-icon [spin]="inputGroupPrependIconSpin" [icon]="inputGroupPrependIcon"></fa-icon></span>
		<button
			*ngIf="type===PApiPrimitiveTypes.Search && !!value"
			class="input-group-btn btn btn-primary"
			(click)="clearValue()"
		><fa-icon [icon]="'times-circle'"></fa-icon></button>
	</div>
	<ng-content select=".input-group-prepend"></ng-content>
</ng-template>
<ng-template #appendItem>
	<div
		*ngIf="!!dropdownItems?.length ||
				!!inputGroupAppendText ||
				showPasswordVisibilityToggleButton ||
				disabled && cannotSetHint ||
				appendHasContent"
		class="d-flex input-group input-group-main-append"
		[class.input-hidden]="inputHidden === true"
		[class.has-disabled-input]="(disabled || readMode) && !(inputHidden === true)"
		[disabled]="disabled || readMode">
		<div class="input-group-append dropdown-append" *ngIf="!!dropdownItems?.length">
			<div class="d-flex flex-column">
				<p-dropdown
					[ngModel]="dropdownValue"
					(ngModelChange)="dropdownValue=$event;dropdownValueChange.emit($event)"
					class="flex-grow-1 d-flex"
					[rounded]="BootstrapRounded.RIGHT"
				>
					<p-dropdown-item
						*ngFor="let dropdownItem of dropdownItems"
						[value]="dropdownItem.value"
						[label]="dropdownItem.label"
						(onClick)="onDropdownClick(dropdownItem, $event)"
					></p-dropdown-item>
				</p-dropdown>
			</div>
		</div>

		<div class="input-group-append" *ngIf="!!inputGroupAppendText && !inputHidden">
			<span class="input-group-text"><fa-icon *ngIf="inputGroupPrependIcon && !forcePrependIconOnPrepend" [icon]="inputGroupPrependIcon" [size]="'sm'" class="mr-1"></fa-icon> {{ inputGroupAppendText }}</span>
		</div>
		<div class="input-group-append" *ngIf="showPasswordVisibilityToggleButton">
			<button class="btn" [class.btn-secondary]="hidePassword" [class.btn-primary]="!hidePassword" type="button" (click)="hidePassword=!hidePassword"><fa-icon [icon]="hidePassword ? enums.PlanoFaIconPool.VISIBLE : enums.PlanoFaIconPool.INVISIBLE"></fa-icon></button>
		</div>
		<ng-content *ngIf="appendHasContent" select=".input-group-append"></ng-content>
		<div
			#cannotSetHintTemplate
			[class.has-space-left]="!!cannotSetHintTemplate.offsetLeft"
			class="input-group-append cannot-set-hint"
			*ngIf="disabled && cannotSetHint">
			<button
				*ngIf="!!cannotSetHint && !!disabled"
				class="btn btn-outline-secondary"
				type="button"
				(click)="openCannotSetHint()"
				title="Info" i18n-title
			><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon></button>
		</div>
	</div>

</ng-template>
<ng-content></ng-content>
