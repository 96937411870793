<button
	type="button"
	class="btn btn-light btn-outline-danger justify-content-center align-items-center nowrap {{class}}"
	(click)="onRemoveClick($event)"
	[disabled]="disabled"
><fa-icon [class.mr-1]="!!label" icon="trash"></fa-icon>{{ label }}</button>

<ng-template #warningModalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-content
		[theme]="modalTheme ?? null"
		[closeBtnLabel]="translatedDeleteWarningModalCloseBtnLabel"
		(onDismiss)="d($event)"
		(onClose)="c($event);"
	>
		<p-modal-content-body>
			<p class="m-0">{{ modalText }}</p>
		</p-modal-content-body>
	</p-modal-content>
</ng-template>
