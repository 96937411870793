import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// cSpell:ignore datetime
import { DateInfo, FormattedDateTimePipe } from '@plano/client/shared/formatted-date-time.pipe';
import { RightsService, SchedulingApiAbsence } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';

@Component({
	selector: 'p-shift-exchange-related-absence[absence]',
	templateUrl: './shift-exchange-related-absence.component.html',
	styleUrls: ['./shift-exchange-related-absence.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeRelatedAbsenceComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absence ! : SchedulingApiAbsence;

	constructor(
		private rightsService : RightsService,
		private formattedDateTimePipe : FormattedDateTimePipe,
	) {}

	public Config = Config;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hideEditBtn() : boolean {
		return !this.rightsService.userCanWriteAbsences;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public getDateInfo(absence : SchedulingApiAbsence) : DateInfo {
		return this.formattedDateTimePipe.getFormattedDateInfo(
			absence.time.start,
			absence.time.end,
			true,
			absence.isFullDay,
		);
	}
}
