<ng-template [ngIf]="readMode && valueMember" [ngIfElse]="inputTemplate">
	<div class="input-group disabled">
		<div class="input-group-prepend">
			<span *ngIf="!valueMember" class="input-group-text"><fa-icon [icon]="enums.PlanoFaIconPool.ITEMS_MEMBER"></fa-icon></span>
			<ng-container *ngIf="valueMember">
				<span class="input-group-text">
					<ng-template [ngTemplateOutlet]="iconOrBadgeTemplate" [ngTemplateOutletContext]="{$implicit: valueMember}"></ng-template>
				</span>
			</ng-container>
		</div>
		<div class="form-control-read-mode">
			<ng-container *ngIf="valueMember">
				<ng-template [ngTemplateOutlet]="typeaheadItemTextTemplate" [ngTemplateOutletContext]="{$implicit: valueMember}"></ng-template>
			</ng-container>
		</div>
	</div>
</ng-template>
<ng-template #inputTemplate>
	<div
		[class.required]="hasRequiredError"
		[class.has-danger]="hasDanger"
		[class.has-warning]="hasWarning"

		class="input-group position-relative"
		[disabled]="readMode || !!disabled"

		[pEditable]="pEditable"
		[api]="api"
		[valid]="isValid"
		[saveChangesHook]="saveChangesHook"
		(onSaveStart)="onSaveStart.emit($event)"
		(onSaveSuccess)="onSaveSuccess.emit($event)"
		(onDismiss)="onDismiss.emit($event)"
		(onLeaveCurrent)="onLeaveCurrent.emit($event)"
		(editMode)="changeEditMode($event)"
	>
		<div class="input-group-prepend"
			tabindex="-1"
			(click)="setFocus(inputRef)"
		>
			<span *ngIf="!valueMember" class="input-group-text d-flex justify-content-center"><fa-icon [icon]="enums.PlanoFaIconPool.ITEMS_MEMBER" style="min-width: 28px; text-align: center;"></fa-icon></span>
			<ng-container *ngIf="valueMember">
				<span class="input-group-text">
					<ng-template [ngTemplateOutlet]="iconOrBadgeTemplate" [ngTemplateOutletContext]="{$implicit: valueMember}"></ng-template>
				</span>
			</ng-container>
		</div>
		<input
			pEditableTriggerFocussable
			#inputRef

			type="Search"
			autocomplete="off"
			[readonly]="readMode"
			class="form-control"

			[ngModel]="valueMember"
			[placeholder]="placeholder ?? ''"
			(focusout)="focusout.emit($event)"
			(focus)="onFocus($event)"
			(change)="onChange($event)"
			(keyup)="onKeyUp($event)"
			(blur)="onBlur($event)"
			[disabled]="readMode || disabled"
			[required]="hasRequiredError"
			[class.required]="hasRequiredError"
			[class.has-danger]="hasDanger"
			[class.has-warning]="hasWarning"

			[ngbTypeahead]="search"
			#instance="ngbTypeahead"
			placement="bottom-left"
			[resultTemplate]="resultTemplate"
			[inputFormatter]="formatter"
			[editable]="true"
			(selectItem)="typeaheadOnSelect($event, inputRef)"

			(click)="onClick($event)"
		/>

		<div class="input-group-append"
			*ngIf="pEditable"
			pVisibleInEditMode
		>
			<button
				type="reset"
				class="btn btn-outline-secondary"
				pEditableDismissButton
			><fa-icon [icon]="enums.PlanoFaIconPool.UNDO"></fa-icon></button>
			<button
				type="submit"
				class="btn btn-outline-secondary"
				pEditableSuccessButton
			><fa-icon [class.text-warning]="hasWarning" [icon]="successBtnIcon" [spin]="successBtnIconSpin"></fa-icon></button>
		</div>
		<ng-container *ngTemplateOutlet="appendItem"></ng-container>
	</div>
</ng-template>

<ng-template #resultTemplate let-typeaheadItem="result" let-t="term">
	<div class="d-flex align-items-center">
		<ng-template [ngTemplateOutlet]="iconOrBadgeTemplate" [ngTemplateOutletContext]="{$implicit: typeaheadItem}"></ng-template>
		<ng-template [ngTemplateOutlet]="typeaheadItemTextTemplate" [ngTemplateOutletContext]="{$implicit: typeaheadItem}"></ng-template>
	</div>
</ng-template>

<ng-template #iconOrBadgeTemplate let-memberOrString>
	<ng-container *ngIf="!isStringValue(memberOrString)">
		<ng-template [ngTemplateOutlet]="memberBadgeTemplate" [ngTemplateOutletContext]="{$implicit: memberOrString}"></ng-template>
	</ng-container>
	<ng-container *ngIf="isStringValue(memberOrString)">
		<fa-icon class="d-flex justify-content-center" [icon]="enums.PlanoFaIconPool.ITEMS_MEMBERS" style="min-width: 28px; text-align: center;"></fa-icon>
	</ng-container>
</ng-template>

<ng-template #typeaheadItemTextTemplate let-typeaheadItem>
	<div
		class="mr-auto ml-2 text-bold"
	>
		<ng-container *ngIf="isStringValue(typeaheadItem)">
			<strong>{{ typeaheadItem }}</strong>
		</ng-container>
		<ng-container *ngIf="!isStringValue(typeaheadItem)">
			<strong>{{ typeaheadItem.firstName ? typeaheadItem.firstName : '' }}</strong> {{ typeaheadItem.lastName ? typeaheadItem.lastName : '' }}
		</ng-container>
	</div>
</ng-template>
<ng-template #memberBadgeTemplate let-member>
	<p-member-badge
		style="min-width: 28px; height: 28px; text-align: center;"
		[memberId]="member.id"
		[firstName]="member.firstName"
		[lastName]="member.lastName"
		[isMe]="isMe(member)"
		[absenceType]="member.trashed ? 'trashed' : null"
		size="small"
	></p-member-badge>
</ng-template>

<ng-template [ngIf]="!!control">
	<p-validation-hint
		[control]="control"
		[checkTouched]="checkTouched"
	></p-validation-hint>
</ng-template>
<ng-template #prependItem>
	<ng-content select=".input-group-prepend"></ng-content>
</ng-template>
<ng-template #appendItem>
	<ng-content select=".input-group-append"></ng-content>
</ng-template>
