import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { EarlyBirdService } from '@plano/client/scheduling/early-bird.service';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { ErrorArray, ShiftMemberExchangeService } from '@plano/client/shared/p-shift-module/shift-member-exchange.service';
import { MeService, RightsService, SchedulingApiAssignmentProcessState, SchedulingApiShift, SchedulingApiShiftPacketShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';

@Component({
	selector: 'p-assign-me-button[shift]',
	templateUrl: './p-assign-me-button.component.html',
	styleUrls: ['./p-assign-me-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAssignMeButtonComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ! : SchedulingApiShift;

	constructor(
		private modalService : ModalService,
		private me : MeService,
		public rightsService : RightsService,
		private shiftMemberExchangeService : ShiftMemberExchangeService,
		private earlyBirdService : EarlyBirdService,
		private schedulingService : SchedulingService,
	) {
	}

	public states : typeof SchedulingApiAssignmentProcessState = SchedulingApiAssignmentProcessState;

	/**
	 * Assign logged in user and save after user confirmed
	 */
	public assignMe(
		event : Event,
		modalContent : TemplateRef<PModalTemplateDirective>,
	) : void {
		event.stopPropagation();
		void this.modalService.openModal(modalContent).result.then(value => {
			if (value.modalResult === 'success') {
				this.shift.earlyBirdAssignToMe = true;
				void this.shift.saveDetailed().then(() => {
					if (Config.IS_MOBILE && !this.earlyBirdService.freeEarlyBirdSeatsCount) {
						this.schedulingService.earlyBirdMode = false;
					}
				});
			}
		});
	}

	/** @see ShiftMemberExchangeService#errors */
	public get errors() : ErrorArray {
		return this.shiftMemberExchangeService.errors(this.me.data.id, this.shift);
	}

	/**
	 * @returns Returns other shifts from the packet which will also be assigned to user.
	 * "null" is returned if no such shifts exist.
	 */
	public get otherPacketShifts() : SchedulingApiShiftPacketShifts | null {
		if (this.shift.packetShifts.length <= 1) return null;

		const result = new SchedulingApiShiftPacketShifts(null, null);
		const process = this.shift.assignmentProcess;
		if (process === null) return null;

		for (const packetShift of this.shift.packetShifts.iterable()) {
			if (packetShift.id.equals(this.shift.id)) continue;
			if (!process.shiftRefs.contains(packetShift.id)) continue;
			result.push(packetShift);
		}

		return result.length ? result : null;
	}
}
