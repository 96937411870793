import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-led',
	templateUrl: './p-led.component.html',
	styleUrls: ['./p-led.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PLedComponent extends UniqueAriaLabelByDirective {
	/**
	 * Does the LED has electricity?
	 * true if not.
	 */
	@Input() public off : boolean = true;

	/**
	 * What color style should the led have if turned on?
	 */
	@Input() public theme : ExtractFromUnion<'success' | 'info' | 'light' | 'primary' | 'warning' | 'danger', PThemeEnum> = enumsObject.PThemeEnum.SUCCESS;

	/**
	 * Is it a big or small LED?
	 */
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	@HostBinding('class.small') private get hasClassSmall() : boolean {
		return this.size === enumsObject.BootstrapSize.SM;
	}
	@HostBinding('class.large') private get hasClassLarge() : boolean {
		return this.size === enumsObject.BootstrapSize.LG;
	}

	@HostBinding('attr.role') private readonly role = 'status';

	@HostBinding('attr.aria-checked') private get ariaChecked() : boolean {
		return !this.off;
	}

	public enums = enumsObject;
}
