import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { PAlertTheme, PAlertThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/**
 * A type for Alerts, that defines mainly the the color of a alert component
 */
@Component({
	selector: 'p-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [SLIDE_ON_NGIF_TRIGGER],
})
export class AlertComponent {

	/**
	 * Icon to show inside the box.
	 * Can be set to null if you dont want any icon.
	 */
	@Input('icon') private _icon ?: AlertComponent['icon'] | null;

	/**
	 * Some visual style for the overall look of this ui element.
	 */
	@Input() public theme : PAlertTheme = enumsObject.PThemeEnum.WARNING;

	/**
	 * Gets emitted when the user clicks some kind of close-button. E.g. a `×` on the top right.
	 */
	@Output() private dismiss = new EventEmitter<undefined>();

	@HostBinding('class.alert-dismissible')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dismissable : boolean | undefined;

	@HostBinding('attr.role') private _role = 'alert' as const;
	@HostBinding('class.alert')
	private _alwaysTrue : boolean = true;

	@HostBinding('class.d-none')
	@HostBinding('hidden') private get _isHidden() : boolean {
		return !this.visible;
	}

	@HostBinding('class.alert-danger') private get _alertDanger() : boolean { return this.theme === 'danger'; }
	@HostBinding('class.alert-dark') private get _alertDark() : boolean { return this.theme === 'dark'; }
	@HostBinding('class.alert-info') private get _alertInfo() : boolean { return this.theme === enumsObject.PThemeEnum.INFO; }
	@HostBinding('class.alert-light') private get _alertLight() : boolean { return this.theme === 'light'; }
	@HostBinding('class.alert-plain') private get _alertPlain() : boolean { return this.theme === PAlertThemeEnum.PLAIN; }
	@HostBinding('class.alert-primary') private get _alertPrimary() : boolean { return this.theme === 'primary'; }
	@HostBinding('class.alert-secondary') private get _alertSecondary() : boolean {
		return this.theme === 'secondary';
	}
	@HostBinding('class.alert-success') private get _alertSuccess() : boolean { return this.theme === 'success'; }
	@HostBinding('class.alert-warning') private get _alertWarning() : boolean { return this.theme === 'warning'; }

	public visible : boolean = true;

	public enums = enumsObject;

	/**
	 * Icon to show inside the box.
	 */
	public get icon() : FaIcon | null {
		if (this._icon !== undefined) return this._icon;
		if (this.theme === 'warning') return 'exclamation-triangle';
		if (this.theme === enumsObject.PThemeEnum.INFO) return enumsObject.PlanoFaIconPool.MORE_INFO;
		if (this.theme === 'danger') return enumsObject.PlanoFaIconPool.NOT_POSSIBLE;
		if (this.theme === 'success') return 'thumbs-up';
		return null;
	}

	/**
	 * when user clicked close
	 */
	public onClose() : void {
		this.visible = false;
		this.dismiss.emit();
	}
}
