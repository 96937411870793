/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "admin/server_timer".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	PAUSED = 1;
	SPEED = 2;
	CURRENT_TIME = 3;
}


@Injectable({
  providedIn: 'root',
})
export class ServerTimerApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'admin/server_timer');
	}

	protected version() : string {
		return 'f5c37c6a0ffb9e955640c9c72562a3fe,83e6b346a0195b57d3776a7e0ba23bfc';
	}

	private dataWrapper = new ServerTimerApiRoot(this, );

	get data() : ServerTimerApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ServerTimerApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ServerTimerApiRoot(this, );
	}
}

		 
export class ServerTimerApiRoot extends ApiObjectWrapper<ServerTimerApiRoot>
{
	constructor(override readonly api : ServerTimerApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ServerTimerApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ServerTimerApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ServerTimerApiRoot, ServerTimerApiRoot> = new ApiAttributeInfo<ServerTimerApiRoot, ServerTimerApiRoot>({
			apiObjWrapper: this as any as ServerTimerApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToGet: function(this : ServerTimerApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			hasRightToSet: function(this : ServerTimerApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN))&&(Config.DEBUG));
			},
			defaultValue: function(this : ServerTimerApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoPaused : ApiAttributeInfo<ServerTimerApiRoot, boolean> = new ApiAttributeInfo<ServerTimerApiRoot, boolean>({
			apiObjWrapper: this as any as ServerTimerApiRoot,
			name: 'paused',
			nodeName: 'PAUSED',
			primitiveType: PApiPrimitiveTypes.boolean,
			validations: function(this : ServerTimerApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ServerTimerApiRoot) {
				return [
				];
			}
			,
			defaultValue: function(this : ServerTimerApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 1,
			vars: {
				cannotSetHint: function(this : ServerTimerApiRoot) {
					return 'Im Produktivmodus kann die Serverzeit nicht verändert werden.'
				},
			},
		});
	attributeInfoSpeed : ApiAttributeInfo<ServerTimerApiRoot, number> = new ApiAttributeInfo<ServerTimerApiRoot, number>({
			apiObjWrapper: this as any as ServerTimerApiRoot,
			name: 'speed',
			nodeName: 'SPEED',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : ServerTimerApiRoot) {
				return [
					() => {
return this.api!.validators.maxDecimalPlacesCount(10, PApiPrimitiveTypes.number, undefined);						return null;
					},
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ServerTimerApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 2,
			vars: {
				cannotSetHint: function(this : ServerTimerApiRoot) {
					return 'Im Produktivmodus kann die Serverzeit nicht verändert werden.'
				},
			},
		});
	attributeInfoCurrentTime : ApiAttributeInfo<ServerTimerApiRoot, DateTime> = new ApiAttributeInfo<ServerTimerApiRoot, DateTime>({
			apiObjWrapper: this as any as ServerTimerApiRoot,
			name: 'currentTime',
			nodeName: 'CURRENT_TIME',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : ServerTimerApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ServerTimerApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 3,
			vars: {
				cannotSetHint: function(this : ServerTimerApiRoot) {
					return 'Im Produktivmodus kann die Serverzeit nicht verändert werden.'
				},
			},
		});

	/**
     *  Is the server paused?
	 *
	 * @type {boolean}
     */
	get paused() : boolean {
		this.getterDebugValidations(this.attributeInfoPaused, false);
		return this.data[1];
	}

	set paused(v : boolean) {
        this.setterImpl(1, v, 'paused', false, null, null);
	}

	/**
     *  Server timer speed. Default is "1".
	 *
	 * @type {number}
     */
	get speed() : number {
		this.getterDebugValidations(this.attributeInfoSpeed, false);
		return this.data[2];
	}

	set speed(v : number) {
        this.setterImpl(2, v, 'speed', false, null, null);
	}

	/**
     *  Current server time.
	 *
	 * @type {DateTime}
     */
	get currentTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoCurrentTime, false);
		return this.data[3];
	}

	set currentTime(v : DateTime) {
        this.setterImpl(3, v, 'currentTime', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ServerTimerApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}



