import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, Output, TemplateRef, ViewChild } from '@angular/core';
import { SchedulingApiBirthdays } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { PAllDayItemsListComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar/p-all-day-items-list/p-all-day-items-list.component';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { SchedulingApiAbsences, SchedulingApiHolidays, SchedulingApiService, SchedulingApiShift, SchedulingApiShifts } from '@plano/shared/api';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-week-view[absences][shifts]',
	templateUrl: './week-view.component.html',
	styleUrls: ['./week-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class WeekViewComponent implements AfterViewInit, PFormControlComponentInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private viewDate : number | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts ! : SchedulingApiShifts;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absences ! : PAllDayItemsListComponent['absences'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public holidays ! : PAllDayItemsListComponent['holidays'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public birthdays ! : PAllDayItemsListComponent['birthdays'];

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onShiftClick = new EventEmitter<{shift : SchedulingApiShift, event : MouseEvent}>();

	@ViewChild('startOfWorkday', { static: true }) public startOfWorkday ! : ElementRef<HTMLElement>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	constructor(
		private zone : NgZone,
		private pMoment : PMomentService,
		public api : SchedulingApiService,
	) {
		this.today = +this.pMoment.m().startOf('day');
	}

	private today ! : number;

	public ngAfterViewInit() : void {
		this.scrollToStartOfWorkday();
	}

	private scrollToStartOfWorkday() : void {
		this.zone.runOutsideAngular(() => {
			requestAnimationFrame(() => {
				const el = this.startOfWorkday.nativeElement;
				el.scrollIntoView();
			});
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get weekdays() : number[] {
		const result : number[] = [];
		for (let i = 0; i < 7; i++) {
			const dayTimestamp = this.pMoment.m(this.viewDate).startOf('isoWeek').add(i, 'day').valueOf();
			result.push(dayTimestamp);
		}
		return result;
	}

	/**
	 * Get all absences for the selected date
	 */
	public absencesOfDay(timestamp : number) : SchedulingApiAbsences {
		return this.absences.getByDay(timestamp);
	}

	/**
	 * Get all holidays for the selected date
	 */
	public holidaysOfDay(timestamp : number) : SchedulingApiHolidays {
		return this.holidays.getByDay(timestamp);
	}

	/**
	 * Get all birthdays for the selected date
	 */
	public birthdaysOfDay(timestamp : number) : SchedulingApiBirthdays {
		return this.birthdays.getByDay(timestamp);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public isBeforeToday(
		startOfDay : number,
	) : boolean {
		return this.pMoment.m(startOfDay).isBefore(this.today);
	}
}
