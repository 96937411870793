import { LocationChangeEvent, PlatformLocation } from '@angular/common';
import { ElementRef, Injectable, NgZone, OnDestroy, TemplateRef } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isChangeSelectorsModal } from '@plano/client/shared/p-transmission/change-selectors-modal.utils';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subject, Subscription } from 'rxjs';
import { PConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ModalContentOptions, PModalDefaultTemplateComponent } from './modal-default-template/modal-default-template.component';
import { ModalDismissParam, ModalServiceOptions } from './modal.service.options';

/** The possible parameter value for a modal Promise */
export type ModalResult<SuccessValueType = Event> = {
	modalResult : 'success',
	value : SuccessValueType,
} | {
	modalResult : 'dismiss',
	value : ModalDismissParam,
};

/** The return type of a method like openModal() */
export type ModalRef<SuccessValueType = Event> = {
	result : Promise<ModalResult<SuccessValueType>>,
	componentInstance : NgbModalRef['componentInstance'],
	close : NgbModalRef['close'],
	dismiss : NgbModalRef['dismiss'],
};

@Injectable({ providedIn: 'root' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ModalService implements OnDestroy {
	constructor(
		private modal : NgbModal,
		private location : PlatformLocation,
		private localize : LocalizePipe,
		private router : Router,
		private zone : NgZone,
	) {
		this.location.onPopState((event : LocationChangeEvent) => {
			// ensure that modal is opened
			if (this.modalRef === null) return;
			(event as unknown as Event).preventDefault();
			this.modalRef.dismiss();
		});
		this.routerNavigationListener = this.router.events.subscribe((event)=>{
			// don’t listen to any other events than ActivationEnd
			if (!(event instanceof ActivationEnd)) return;

			// we don't want to close the modal if the modal scroll query param is passed
			// we also need to pass the current url as long as we don't change the url handle of p-tabs
			if (event.snapshot.queryParams['modal-scroll']) {
				// Cleanup query-parameter
				const queryParams = new URLSearchParams(window.location.search);
				queryParams.delete('modal-scroll');

				// the removal should not add a browser history stack
				history.replaceState({}, '', `${window.location.pathname}${queryParams.size > 0 ? '?' : ''}${queryParams.toString()}`);
				return;
			}
			while (this.modalRef) {
				this.modalRef.dismiss();
				this.modalStack.pop();
			}
		});
	}

	/**
	 * Probably will never run since the service is provided in root,
	 * however we keep this here in case one day we change the provided in
	 */
	public ngOnDestroy() : void {
		this.routerNavigationListener.unsubscribe();
	}

	private routerNavigationListener! : Subscription;

	private modalStack : NgbModalRef[] = [];

	/**
	 * Number of blocking modals
	 */
	private highlightCancelBlockModals : number = 0;

	public modalServiceOptions : ModalServiceOptions = new ModalServiceOptions();

	/**
	 * Subject that will emit when a modal gets closed/dismissed, with the correct string
	 */
	public modalStateCloseSubject = new Subject<'dismiss'|'success'>();

	/**
	 * Subject that will emit when a modal gets opened
	 */
	public modalStateOpenSubject = new Subject<void>();

	private modalServiceOptionsToNgbModalOptions(input : ModalServiceOptions) : NgbModalOptions {
		const centered = input.centered ?? (input.size === enumsObject.BootstrapSize.SM);
		const animation = input.animation ?? input.size !== 'fullscreen';
		const keyboard = input.keyboard ?? true;

		const theme = input.theme;

		if (theme !== undefined) {
			input.windowClass += ` modal-${theme}`;
		}

		let backdropClass = '';
		backdropClass += (input.backdropClass ?? '');
		backdropClass += (input.backdrop === 'static' ? ' not-clickable bg-white' : '');
		return {
			size: input.size!,
			windowClass: input.windowClass!,
			backdrop: input.backdrop!,
			backdropClass: backdropClass,
			keyboard: keyboard,
			centered: centered,
			scrollable: true,
			animation: animation,
		};
	}

	/**
	 * Method to check if there are any open modals that block the escape listener that removes highlighting.
	 */
	public get hasHighlighCancelBlockModals() : boolean {
		return this.highlightCancelBlockModals > 0;
	}

	/**
	 * Function to get the top modal of the modal stack
	 */
	public get modalRef() : NgbModalRef | null {
		if (this.modalStack.length > 0) {
			return this.modalStack[this.modalStack.length - 1];
		} else return null;
	}

	/**
	 * Add a modal that will block the escape listener from resetting highlighting
	 */
	public addBlockHighlightModalCount() : void {
		this.highlightCancelBlockModals++;
	}

	/**
	 * Remove a modal that would block the escape listener from resetting highlighting
	 */
	public removeBlockHighlightModalCount() : void {
		if (this.highlightCancelBlockModals > 0) {
			this.highlightCancelBlockModals--;
		}
	}

	/**
	 * Open a new Modal with the provided content and options.
	 *
	 * By default, all modals will have scrollbar-gutter stable, to disabled this
	 * pass the inputOptions object with a windowClass "no-gutter-body".
	 */
	public openModal<SuccessValueType = Event>(
		// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
		modalContent : TemplateRef<PModalTemplateDirective> | ElementRef<unknown> | unknown,
		inputOptions : Omit<ModalServiceOptions, 'success' | 'scrollable'> | null = null,
	) : ModalRef<SuccessValueType> {
		if (inputOptions === null) inputOptions = this.modalServiceOptions;

		/* This check ensures that when the p-change-selectors-modal
		is passed inside the templateRef, the keyboard value gets set to false
		so we can't dismiss the modal using the escape key, or clicking outside the modal.
		Doing it here avoids that we have to remember to set the variable from the outside everytime.
		*/
		if (modalContent instanceof TemplateRef) {
			inputOptions.keyboard = isChangeSelectorsModal(modalContent) ? false : inputOptions.keyboard;
		}

		const ngbModalServiceOptions = this.modalServiceOptionsToNgbModalOptions(inputOptions);

		// add animation when user tries to close modal with keyboard
		if (inputOptions.keyboard === false) {
			ngbModalServiceOptions.backdrop = 'static';
		}
		const topModal = this.modal.open(modalContent, ngbModalServiceOptions);
		this.modalStack.push(topModal);
		const openedModals : NodeListOf<HTMLElement> | null = document.querySelectorAll('ngb-modal-window');
		const openedModalWindow : HTMLElement|null = openedModals.item(openedModals.length - 1).querySelector('.modal-dialog') ?? null;

		const openedModalBody : HTMLElement | null = openedModalWindow?.querySelector('.modal-body') ?? null;

		let resizeModalBodyObserver : ResizeObserver | null = null;
		this.zone.runOutsideAngular(() => {
			if (openedModalBody) {
				if (openedModalBody.clientHeight < openedModalBody.scrollHeight) {
						openedModalWindow!.classList.add('modal-has-scroll');
				} else openedModalWindow!.classList.remove('modal-has-scroll');
				resizeModalBodyObserver = new ResizeObserver(() => {
					if (openedModalBody.clientHeight < openedModalBody.scrollHeight) {
							openedModalWindow!.classList.add('modal-has-scroll');
					} else openedModalWindow!.classList.remove('modal-has-scroll');
				});
				resizeModalBodyObserver.observe(openedModalBody);
			}
			window.requestAnimationFrame(() => {
				this.modalStateOpenSubject.next();
			});
			openedModalWindow?.focus();
		});

		const promise = new Promise<ModalResult<SuccessValueType>>(resolve => {
			topModal.result.then((result) => {
				assumeNonNull(inputOptions);
				this.modalStateCloseSubject.next('success');
				this.modalStack.pop();
				resizeModalBodyObserver?.disconnect();
				resolve({
					modalResult: 'success',
					value: result,
				});
			}).catch((error) => {
				assumeNonNull(inputOptions);
				this.modalStateCloseSubject.next('dismiss');
				this.modalStack.pop();
				resizeModalBodyObserver?.disconnect();
				resolve({
					modalResult: 'dismiss',
					value: error,
				});
			}).finally(() => {
				const modalWindow : HTMLElement|null = (document.querySelector('ngb-modal-window'));
				modalWindow?.focus();
				assumeNonNull(inputOptions);
			});
		});

		// HACK: This is a hack for the modal-over-modal scroll issue.
		// More Info: https://github.com/ng-bootstrap/ng-bootstrap/issues/643
		topModal.result.then(() => {
			if (document.querySelector('body > .modal.open')) {
				document.body.classList.add('modal-open');
			}
		}).catch(() => {
			if (document.querySelector('body.modal-open')) {
				document.body.classList.remove('modal-open');
			}
		}).finally(() => {
			const modalWindow : HTMLElement|null = (document.querySelector('ngb-modal-window'));
			modalWindow?.focus();
		});

		return {
			result: promise,
			componentInstance: topModal.componentInstance,
			close: (result) => { topModal.close(result); },
			dismiss: (result) => { topModal.dismiss(result); },
		};
	}

	/**
	 * Opens a confirm modal without the need to provide a template for the inner content
	 * If you don’t provide a dismiss handler, the Dismiss-Button in the footer will be hidden.
	 */
	public confirm(
		content : ModalContentOptions,
		options : Pick<ModalServiceOptions, 'theme' | 'size'> = {},
	) : ModalRef {
		if (content.closeBtnLabel === null) content.closeBtnLabel = this.localize.transform('Ja');
		if (content.dismissBtnLabel === undefined) content.dismissBtnLabel = this.localize.transform('Nein');
		const modalRef = this.openModal(PConfirmModalComponent, {
			size: options.size ?? enumsObject.BootstrapSize.SM,
			theme: options.theme ?? null,
			animation: false,
			centered: true,
		});
		const pConfirmModalComponent = modalRef.componentInstance as PConfirmModalComponent;

		pConfirmModalComponent.initModal(content, options.theme);
		return modalRef;
	}

	/**
	 * Opens a themed modal without the need to provide a template for the inner content
	 * This has no callbacks. It is made for situations where we e.g. just want to block a functionality.
	 */
	public openDefaultModal<T = Event>(
		modalContentOptions : ModalContentOptions,
		options : Omit<ModalServiceOptions, 'success'> | null = null,
	) : ModalRef<T> {
		if (options === null) options = {};
		options.size = options.size ?? enumsObject.BootstrapSize.LG;
		const modalRef = this.openModal<T>(PModalDefaultTemplateComponent, {
			...options,
		});

		void modalRef.result.then(promiseResult => {
			if (promiseResult.modalResult === 'dismiss') {
				assumeNonNull(options);

				// It is important to destroy before you run options.dismiss.
				// Imagine: Dismiss could remove things from api which embeddedContentView relies on.
				defaultModalComponent.embeddedContentView?.destroy();
				defaultModalComponent.embeddedFooterView?.destroy();
			}
		}).finally(() => {
			defaultModalComponent.embeddedContentView?.destroy();
			defaultModalComponent.embeddedFooterView?.destroy();
		});

		const defaultModalComponent = modalRef.componentInstance as PModalDefaultTemplateComponent;
		defaultModalComponent.initModal(modalContentOptions, options.theme);
		return modalRef;
	}

	/**
	 * Shorthand to open a modal for Attribute Info’s `cannotSetHint`
	 * @param cannotSetHint The text to display in the modal
	 * @param cannotSetHintTemplate A template for the modal content if you want to provide one
	 * @param theme The theme of the modal
	 */
	public openCannotSetHintModal(
		cannotSetHint : PDictionarySource,
		cannotSetHintTemplate : TemplateRef<unknown> | null = null,

		// TODO: PLANO-177814 -- set this to theme INFO as default
		theme : typeof enumsObject.PThemeEnum.INFO | null = null,
	) : ModalRef {
		const modalContentOptions : ModalContentOptions = {
			modalTitle: null,
			description: cannotSetHintTemplate ? null : this.localize.transform(cannotSetHint),
			closeBtnLabel: this.localize.transform('Nagut 🙄'),
		};
		if (cannotSetHintTemplate) modalContentOptions.contentTemplateRef = cannotSetHintTemplate;
		return this.openDefaultModal(modalContentOptions, {
			theme: theme,
			size: enumsObject.BootstrapSize.SM,
			centered: true,
		});
	}

	/**
	 * Opens a warning modal without the need to provide a template for the inner content
	 * It is made for situations where we e.g. just want to block a functionality.
	 */
	public warn(
		modalContentOptions : ModalContentOptions,
		modalSize : ModalServiceOptions['size'] = null,
	) : ModalRef {
		if (!modalContentOptions.modalTitle) modalContentOptions.modalTitle = this.localize.transform('Achtung');
		const options : ModalServiceOptions = {
			size: modalSize,
			theme: enumsObject.PThemeEnum.WARNING,
			centered: true,
		};

		return this.openDefaultModal(modalContentOptions, options);
	}

	/**
	 * Opens a info modal without the need to provide a template for the inner content
	 * It is made for situations where we e.g. just want to block a functionality.
	 */
	public info(
		modalContentOptions : ModalContentOptions,
		modalSize : ModalServiceOptions['size'] = null,
	) : ModalRef {
		if (!modalContentOptions.modalTitle) modalContentOptions.modalTitle = this.localize.transform('Hinweis');
		const options : ModalServiceOptions = {
			size: modalSize,
			theme: enumsObject.PThemeEnum.INFO,
			centered: true,
		};

		return this.openDefaultModal(modalContentOptions, options);
	}

	/**
	 * Opens a error modal without the need to provide a template for the inner content
	 * This has no callbacks. It is made for situations where we e.g. just want to block a functionality.
	 */
	public error(
		modalContentOptions : ModalContentOptions,
	) : ModalRef {
		if (!modalContentOptions.modalTitle) modalContentOptions.modalTitle = this.localize.transform('Fehler!');
		return this.openDefaultModal(modalContentOptions, {
			theme: enumsObject.PThemeEnum.DANGER,
			centered: true,
			size: enumsObject.BootstrapSize.MD,
		});
	}

	/**
	 * This is a shorthand for bringing a templateRef of a modal-content into a structure that fits
	 * into the saveChangesHook attribute of an pEditable
	 */
	public getEditableHookModal(
		modalContent : TemplateRef<PModalTemplateDirective>,
		options : Pick<ModalServiceOptions, 'size' | 'theme' | 'keyboard'> = {},
	) : () => ModalRef['result'] {
		return async () => {
			return this.openModal(
				modalContent,
				{
					keyboard: options.keyboard,
					size: options.size ?? null,
					theme: options.theme ?? null,
				},
			).result;
		};
	}
}
