import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { ReportService } from '@plano/client/report/report.service';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PSimpleChanges, RightsService, SchedulingApiAbsence, SchedulingApiAbsences, SchedulingApiMember, SchedulingApiWorkingTime, SchedulingApiWorkingTimes } from '@plano/shared/api';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-member-working-times[member][absences]',
	templateUrl: './member-working-times.component.html',
	styleUrls: ['./member-working-times.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SLIDE_ON_NGIF_TRIGGER], /* cspell:ignore ngif */
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class MemberWorkingTimesComponent implements OnChanges {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public member ! : SchedulingApiMember;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public workingTimes : SchedulingApiWorkingTimes | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absences ! : SchedulingApiAbsences;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public min ?: number = 0;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public max ?: number;

	/**
	 * Offset for sticky position
	 */
	@Input() public stickyTopOffset : number = 0;

	@HostBinding('class.d-block') protected _alwaysTrue = true;

	/**
	 * Emit event when report row is uncollapsed
	 */
	@Output() public onReportUncollapse : EventEmitter<null> = new EventEmitter<null>();

	@HostBinding('class.bg-white') private get _bgWhite() : boolean {
		return !!this.highlightService.highlightedItem && !this.muteItem;
	}

	constructor(
		public reportService : ReportService,
		public highlightService : HighlightService,
		public rightsService : RightsService,
		private pMoment : PMomentService,
	) {
		this.max = +this.pMoment.m();
	}

	public ngOnChanges(changes : PSimpleChanges<MemberWorkingTimesComponent>) : void {

		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		if (changes.workingTimes || changes.absences) {
			if (changes.workingTimes) {
				this.loadMemberWorkingTimes();
				this._workingTimesForMember = this.memberWorkingTimes.sortedBy(item => item.time.start).iterable();
			}
			if (changes.absences) {
				this.memberAbsences = this.absences.getByMember(this.member);
				this._absencesForMember = this.memberAbsences.sortedBy(item => item.time.start).iterable();
			}
			this.calculateTotalEarnings();
			this.calculateTotalDuration();
		}
	}

	public readonly CONFIG = Config;

	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get collapsibleLineIcon() : PFaIcon {
		if (this.disabled) return 'minus';
		return !this.reportService.isCollapsed(this.member.id) ? enumsObject.PlanoFaIconPool.COLLAPSIBLE_OPEN : enumsObject.PlanoFaIconPool.COLLAPSIBLE_CLOSE;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get isOwner() : boolean | undefined {
		return this.rightsService.isOwner;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get disabled() : boolean {
		if (!this.rightsService.isOwner) return false;
		if (!this.memberWorkingTimes.length && !this.memberAbsences.length) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get muteItem() : boolean | null {
		assumeDefinedToGetStrictNullChecksRunning(this.member, 'this.member');
		if (this.member === this.highlightService.highlightedItem) return false;
		if (this.highlightService.isMuted(this.memberWorkingTimes)) return true;
		return false;
	}

	private loadMemberWorkingTimes() : void {
		this._memberWorkingTimes = this.workingTimes!.filterBy(item => {
			if (!item.memberId.equals(this.member.id)) return false;
			if (this.min && this.max && !item.overlaps(this.min, this.max - 1)) return false;
			return true;
		});
		this.hasForecasts = !!this.memberWorkingTimes.findBy(item => item.isExpectedWorkingTime);

	}

	private _memberWorkingTimes : SchedulingApiWorkingTimes | null = null;

	/**
	 * This is a shortcut to get workingTimes by member.
	 * In order to simplify the template content.
	 */
	public get memberWorkingTimes() : SchedulingApiWorkingTimes {
		if (!this._memberWorkingTimes) {
			this.loadMemberWorkingTimes();
		}
		return this._memberWorkingTimes!;
	}

	/**
	 * Absences of the current member
	 */
	public memberAbsences ! : SchedulingApiAbsences;

	/**
	 * Total duration adding the working times and the absences in the given time frame
	 */
	public totalDuration : number | null = null;

	private calculateTotalDuration() : void {
		this.totalDuration =
			this.memberWorkingTimes.durationBetween(this.min, this.max) +
			this.memberAbsences.durationBetween(this.min, this.max);
	}

	/**
	 * Calculate the total earning from absences and working times
	 */
	private calculateTotalEarnings() : void {
		/** cspell:ignore WORKINGTIMES */
		const WORKINGTIMES_EARNINGS = this.memberWorkingTimes.totalEarningsBetween(this.min, this.max);
		const ABSENCES_EARNINGS = this.memberAbsences.totalEarningsBetween(this.min, this.max);
		this.totalEarnings = WORKINGTIMES_EARNINGS + ABSENCES_EARNINGS;
	}

	public totalEarnings : number | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get commentAmount() : number {
		return this.memberWorkingTimes.commentAmount + this.memberAbsences.commentAmount;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onClick() : void {
		if (this.disabled) return;
		this.reportService.toggle(this.member.id);
		if (!this.reportService.isCollapsed(this.member.id)) {
			this.onReportUncollapse.emit();
		}
	}

	public hasForecasts : boolean | null = null;

	private _absencesForMember : readonly SchedulingApiAbsence[] | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get absencesForMember() : readonly SchedulingApiAbsence[] {
		if (!this._absencesForMember) {
			this._absencesForMember = this.memberAbsences.sortedBy(item => item.time.start).iterable();
		}
		return this._absencesForMember;
	}

	private _workingTimesForMember : readonly SchedulingApiWorkingTime[] | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get workingTimesForMember() : readonly SchedulingApiWorkingTime[] {
		if (!this._workingTimesForMember) {
			this._workingTimesForMember = this.memberWorkingTimes.sortedBy(item => item.time.start).iterable();
		}
		return this._workingTimesForMember;
	}
}
