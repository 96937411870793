import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { RightsService, SchedulingApiMember, SchedulingApiMembers } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

type ValueType = Id | null;

/** Item values that are provided to each item of the typeahead. */
type TypeaheadItem = SchedulingApiMember | string;

@Component({
	selector: 'p-input-member-id[members]',
	templateUrl: './p-input-member-id.component.html',
	styleUrls: ['./p-input-member-id.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputMemberIdComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PInputMemberIdComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterContentInit, EditableControlInterface, PFormControlComponentInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public readMode : PFormControlComponentInterface['readMode'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private members ! : SchedulingApiMembers;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public nullValueIsAllowed : boolean | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('placeholder') private _placeholder : string | null = null;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public focusout = new EventEmitter<FocusEvent>();
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public focus = new EventEmitter<FocusEvent>();

	@ViewChild('instance') public instance ?: NgbTypeahead;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public blur = new EventEmitter<FocusEvent>();
	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<Event> = new EventEmitter<Event>();

	@ViewChild('inputRef') private inputRef ?: ElementRef<HTMLInputElement>;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		private rightsService : RightsService,
		private localizePipe : LocalizePipe,
		private zone : NgZone,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	private readonly ALL_MEMBERS_TEXT : PDictionarySource = 'Alle Berechtigten';
	public enums = enumsObject;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public changeEditMode(event : boolean) : void {
		this.editMode.emit(event);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get placeholder() : string | undefined {
		if (this._placeholder !== null) return this._placeholder;
		return this.nullValueIsAllowed ? this.localizePipe.transform(this.ALL_MEMBERS_TEXT) : '';
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onFocus(event : FocusEvent) : void {
		this.focus.emit(event);
		this.focus$.next((event.target as HTMLInputElement).value);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onClick(
		event : MouseEvent,
	) : void {
		const TARGET = event.target as HTMLInputElement;
		this.click$.next(TARGET.value);
	}

	// I am not quite sure how this works. It was copy paste ¯\_(ツ)_/¯ ^nn
	public focus$ : Subject<string> = new Subject<string>();
	public click$ : Subject<string> = new Subject<string>();

	public search = (text$ : Observable<string>) : Observable<readonly TypeaheadItem[]> => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !!(this.instance && !this.instance.isPopupOpen())));
		const inputFocus$ = this.focus$;

		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			map((term : string) => {
				return this.getMembersForTypeahead(term);
			}),
		);
	};

	private getMembersForTypeahead(term : string | null) : readonly TypeaheadItem[] {
		const members = this.members.search(term).filterBy(item => {
			if (!item.trashed) return true;
			if (this.value && item.id.equals(this.value)) return true;
			return false;
		});
		const result : TypeaheadItem[] = [];
		if (this.nullValueIsAllowed) result.push(this.localizePipe.transform(this.ALL_MEMBERS_TEXT));
		result.push(...members.sortedBy([
			...defaultSortingForMembers,
			item => item.trashed,
		]).iterable());
		return result;
	}

	/** @see NgbTypeahead#inputFormatter */
	public formatter = (typeaheadItem : TypeaheadItem | null = null) : string => {
		if (typeof typeaheadItem === 'string') return typeaheadItem;
		if (!typeaheadItem) return '';
		// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
		if (!typeaheadItem.id) return '';
		// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
		let result = typeaheadItem.firstName ?? '';
		if (typeaheadItem.lastName) {
			if (result.length) result += ' ';
			result += typeaheadItem.lastName;
		}
		return result;
	};

	/**
	 * The value of this component transformed to a TypeaheadItem
	 */
	public get valueMember() : TypeaheadItem {
		if (this.value === null) {
			if (this.nullValueIsAllowed) return this.localizePipe.transform(this.ALL_MEMBERS_TEXT);
			return '';
		}
		return this.members.get(this.value)!;
	}

	public set valueMember(input : TypeaheadItem) {
		if (!input && this.nullValueIsAllowed) {
			this.value = null;
			return;
		}
		if (input instanceof SchedulingApiMember) {
			this.value = input.id;
		} else {
			const ID = this.getMemberIdBySearchInput(input);
			if (ID === '') {
				this.value = null;
				this.onClickMember(null);
			} else if (ID instanceof Id) {
				this.onClickMember(ID);
				this.instance!.dismissPopup();
			}
		}
	}

	/**
	 * Take a user input string and find the one fitting Member (or null value).
	 * If no match could be found, it will return the input.
	 */
	public getMemberIdBySearchInput(input : string) : Id | string {
		const searchedItems = this.getMembersForTypeahead(null).filter(typeaheadItem => {
			if (typeof typeaheadItem === 'string') return typeaheadItem.toLowerCase() === input.toLowerCase();
			return `${typeaheadItem.firstName.toLowerCase()} ${typeaheadItem.lastName.toLowerCase()}` === input.toLowerCase();
		});
		if (searchedItems.length === 1) {
			const firstItem = searchedItems[0];
			if (typeof firstItem === 'string') return firstItem;
			return firstItem.id;
		}
		const currentItemInTypeahead = searchedItems.find(item => {
			if (typeof item === 'string') return this.value === null;
			return item.id.equals(this.value);
		});
		if (currentItemInTypeahead) {
			return typeof currentItemInTypeahead === 'string' ? currentItemInTypeahead : currentItemInTypeahead.id;
		}
		return input;
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		return super.ngAfterContentInit();
	}

	/**
	 * Set new selected member as value
	 */
	private onClickMember(memberId : Id | null) : void {
		this.value = memberId;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public typeaheadOnSelect(
		input : NgbTypeaheadSelectItemEvent<TypeaheadItem>,
		inputRef : HTMLInputElement,
	) : void {
		if (typeof input.item === 'string') {
			this.value = null;
		} else {
			this.value = input.item.id;
		}

		// A Blur on a active editable would trigger a save every time the user selects a member.
		if (!this.pEditable) {
			this.zone.runOutsideAngular(() => {
				window.requestAnimationFrame(() => {
					// Blur needs to happen after the typeahead plugin has finished its work
					$(inputRef).trigger('blur');
				});
			});
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setFocus(
		inputRef : HTMLInputElement,
	) : void {
		$(inputRef).trigger('focus');
	}

	private _value : ValueType | null = null;

	/**
	 * Transform input into fitting output format
	 * @param searchString The users input
	 */
	private stringToOutput(searchString : string) : Id | null {
		const idOrString = this.getMemberIdBySearchInput(searchString);
		if (idOrString instanceof Id) return idOrString;
		return null;
	}

	private updateOutput(event : Event) : void {
		const value = (event.target as HTMLInputElement).value;
		let output = this.stringToOutput(value);

		if (!output) output = this.nullValueIsAllowed ? null : output;
		if (!value && !this.nullValueIsAllowed) this._value = output;
		this._onChange(output);
	}

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void {
		this.updateOutput(event);
		this.keyup.emit(event);
	}

	private resetToPrevOrNull(target : HTMLInputElement) : void {
		if (target.value === '') {
			this.value = null;
			target.value = this.valueMember as string;
		} else {
			const prevValue = this.displayName;
			target.value = prevValue;
		}
	}

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched();
		this.blur.emit(event);
		const target = event.target as HTMLInputElement;
		if (this.nullValueIsAllowed && !this.pEditable) this.resetToPrevOrNull(target);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onChange(event : Event) : void {
		this.updateOutput(event);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this._onChange(value);
	}

	private get displayName() : string {
		return typeof this.valueMember === 'string' ? this.valueMember : `${this.valueMember.firstName} ${this.valueMember.lastName}`;
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		if (this.inputRef) this.inputRef.nativeElement.value = this.displayName;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/**
	 * Has a member been chosen?
	 */
	public get hasMemberValue() : boolean {
		if (!this.valueMember) return false;
		if (typeof this.valueMember === 'string') return false;
		return true;
	}

	/** Is this TypeaheadItem of type string? */
	public isStringValue(typeaheadItem : TypeaheadItem) : boolean {
		return typeof typeaheadItem === 'string';
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isMe(member : SchedulingApiMember) : boolean {
		return this.rightsService.isMe(member.id) ?? false;
	}
}
