import { AfterContentInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

/**
 * A directive to narrow down the options to configure the bootstrap card.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '.card',
})
export class CardDirective implements PComponentInterface, AfterContentInit {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/**
	 * The size of the border radius
	 */
	@Input() public radiusSize ?: typeof enumsObject.BootstrapSize.LG;

	@HostBinding('class.rounded-lg') private get _hasClassBorderLG() : boolean {
		return this.radiusSize === enumsObject.BootstrapSize.LG;
	}

	constructor(
		private elementRef : ElementRef<HTMLElement>,
		private console : LogService,
	) {}

	public ngAfterContentInit() : void {
		if (Config.DEBUG) this.validateContent();
	}
	private validateContent() : void {
		if (!this.elementRef.nativeElement.children.length) return;
		if (this.elementRef.nativeElement.classList.value.match(/rounded-(?:xs|sm|md|lg|xl)/)) {
			this.console.error(this.elementRef.nativeElement);
			this.console.error(`Please dont use rounded-* classes on a card. Use radiusSize="{{enums.BootstrapSize.LG}}" instead.`);
		}
	}
}
