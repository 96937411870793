import { ApiDataWrapperBase } from '@plano/shared/api/base/api-data-wrapper-base';
import { ApiAttributeInfoArgsBase, ApiAttributeInfoBase, AttributeInfoVarsBase } from '@plano/shared/api/base/attribute-info/api-attribute-info-base';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { Integer, PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { NonNullAndNonUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PValidatorObject } from '@plano/shared/core/validators.types';

/**
 * Additional parameters which can be stored in an attribute-info.
 */
class AttributeInfoVars<ParentType extends ApiDataWrapperBase> extends AttributeInfoVarsBase<ParentType> {
	/**
	 * The image ration (= width / height). Can only be used with type `Image`
	 */
	public imageRatio ?: number;

	/**
	 * Max file size in kilobytes. Can only be used with type `Image`.
	 */
	public imageMaxSize ?: Integer;

	/**
	 * Min image width in pixels. Can only be used with type `Image`.
	 */
	public imageMinWidth ?: Integer;

	/**
	 * Min image height in pixels. Can only be used with type `Image`.
	 */
	public imageMinHeight ?: Integer;
}

/**
 * Note that the passed methods have a "this" parameter which has the correct Wrapper type (not the base wrapper type).
 */
interface ApiAttributeInfoArgs<ParentType extends ApiDataWrapperBase, ValueType> extends ApiAttributeInfoArgsBase<ParentType, AttributeInfoVars<ParentType>> {
	apiObjWrapper : ParentType;

	/**
	 * The api attribute name.
	 */
	name : string;

	/**
	 * The api node-name. It is unique in the given api.
	 */
	nodeName : string;

	/**
	 * The PrimitiveType of the attributeInfo's value. Is not defined in case of ApiObjectWrapper items.
	 */
	primitiveType ?: PApiPrimitiveTypes | (() => PApiPrimitiveTypes);

	/**
	 * Is this a detailed attribute? If so, it is only sent when `loadDetailed` is called.
	 */
	isDetailedAttribute ?: boolean;

	/**
	 * Does current user generally have the right to get this value?
	 * See {@link ApiAttributeInfo.hasRightToGet}.
	 */
	hasRightToGet ?: ((this : ParentType) => boolean | undefined);

	/**
	 * Does current user generally have the right to set this value?
	 * See {@link ApiAttributeInfo.hasRightToGet}.
	 */
	hasRightToSet ?: ((this : ParentType) => boolean | undefined);

	/**
	 * A list of synchron validators.
	 */
	validations ?: ((this : ParentType) => (() => PValidatorObject | null)[]);

	/**
	 * A list of async validators. See `AsyncValidatorsService`.
	 */
	asyncValidations ?: ((this : ParentType) => (() => PValidatorObject<'async'> | null)[]);

	/**
	 * A method which returns the raw-data default value for this attribute.
	 * If no value is set than it means that the default value will be `null`.
	 */
	defaultValue ?: (this : ParentType, nodeId : string) => any;

	/**
	 * The raw-data index of this attribute.
	 */
	rawDataIndex ?: Integer;

	vars ?: AttributeInfoVars<ParentType>;

	/**
	 * Attribute-infos for possible values of this attribute.
	 */
	attributeValueInfos ?: Map<ValueType, ApiAttributeValueInfo<ParentType>>;
}

/**
 * An object of this class contains meta information about an api attribute. It contains the business logic of an attribute
 * and by "plugging" it into the component, the component will have the desired business functionality. The logic of the
 * attribute-infos is automatically generated from our API XML files.
 */
export class ApiAttributeInfo<ParentType extends ApiDataWrapperBase, ValueType>
	extends ApiAttributeInfoBase<ParentType, ApiAttributeInfoArgs<ParentType, ValueType>> {
	/**
	 * The name of the attribute represented by this attribute-info object.
	 */
	public get name() : string {
		return this.args.name;
	}

	/**
	 * The api node-name. It is unique in the given api.
	 */
	public get nodeName() : string {
		return this.args.nodeName;
	}

	/**
	 * A unique id for the attribute-info. If it is part of a list, the id will be adjusted so it remains unique.
	 */
	public get id() : string {
		let id = this.nodeName;

		const objectWrapper = this.apiObjWrapper;

		if (objectWrapper.id !== null)
			id += `_${objectWrapper.id.toString()}`;

		return id;
	}

	/**
	 * The primitive type of this attribute-info. Note, that when this is a wrapper object
	 * then it does not represent a primitive type and thus this returns `null`.
	 */
	public get primitiveType() : (ValueType extends ApiDataWrapperBase ? null : PApiPrimitiveTypes) {
		if (typeof this.args.primitiveType !== 'function') return this.args.primitiveType as any ?? null;
		return this.args.primitiveType.call(this.args.apiObjWrapper) as any;
	}

	/**
	 * Is this a detailed attribute? If so, it is only sent when `loadDetailed` is called.
	 */
	public get isDetailedAttribute() : boolean {
		return this.args.isDetailedAttribute ?? false;
	}

	/**
	 * @returns Returns the value.
	 */
	public get value() : NonNullAndNonUndefined<ValueType> | null {
		return this.isWrapper ? this.args.apiObjWrapper : (this.args.apiObjWrapper as any)[this.args.name];
	}

	/**
	 * Sets the value.
	 */
	public set value(value : NonNullAndNonUndefined<ValueType> | null) {
		if (this.isWrapper)
			throw new Error('Currently it is not supported to set a wrapper using the attribute-info. If you think you really need this, we need to look in more detail into it.');

		(this.args.apiObjWrapper as any)[this.args.name] = value;
	}

	/**
	 * The raw-data index of this attribute.
	 */
	public get rawDataIndex() : Integer | null {
		return this.args.rawDataIndex ?? null;
	}

	/** @see ApiAttributeInfoBase#isAvailable */
	public override get isAvailable() : boolean | undefined {
		if (this.api !== null) {
			if (!this.api.isLoaded())
				return undefined;

			const rawDataIndex = this.rawDataIndex;
			if (rawDataIndex) {

				// a primitive whose object-wrapper has no raw-data should never been shown
				const objectWrapperRawData = this.apiObjWrapper.rawData;

				if (!this.isWrapper && !objectWrapperRawData)
					return false;

				// For items coming from backend (not new-items) when load operation is running and currently the desired data
				// is not available we cannot answer if this attribute should be shown or not.
				const rawData = this.isWrapper ? objectWrapperRawData : objectWrapperRawData[rawDataIndex];
				if (!this.apiObjWrapper.isNewItem() && rawData === undefined && this.api.isBackendOperationRunning)
					return undefined;

				// return false when this is a detailed field but the wrapper is not loaded detailed
				// and also this is not a newly created item which would also contain any detailed fields.
				if (this.isDetailedAttribute && !this.isLoadedDetailed && !this.isNewItem)
					return false;
			}
		}

		// otherwise check show logic
		return this.hasRightToGet && this.isAvailableByBusinessLogic;
	}

	/**
	 * Is this object-wrapper or one of its ancestors loaded detailed?
	 */
	private get isLoadedDetailed() : boolean {
		let objectWrapper : ApiDataWrapperBase | null = this.apiObjWrapper;
		while (objectWrapper) {
			if (objectWrapper.isDetailedLoaded)
				return true;

			objectWrapper = objectWrapper.parent;
		}

		return false;
	}

	/**
	 * @returns Has user generally the right to get this attribute? This returns the result of the conditions
	 * in `<get><if-rights>…</if-rights></get>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute is currently available includes further criteria.
	 * See {@link isAvailable}.
	 */
	public get hasRightToGet() : boolean | undefined {
		// check parent show state
		const parent = this.parentAttributeInfo;
		if (parent && !parent.hasRightToGet)
			return parent.hasRightToGet;

		// check show state of this attribute-info
		return this.args.hasRightToGet ? this.args.hasRightToGet.call(this.args.apiObjWrapper) : true;
	}

	protected override get isAvailableByBusinessLogic() : boolean | undefined {
		// check parent show state
		const parent = this.parentAttributeInfo;
		if (parent && !parent.isAvailableByBusinessLogic)
			return parent.isAvailableByBusinessLogic;

		// check show state of this attribute-info
		return this.args.isAvailableByBusinessLogic ? this.args.isAvailableByBusinessLogic.call(this.args.apiObjWrapper) : true;
	}

	public override get canSet() : boolean {
		return this.isAvailable === true && this.hasRightToSet === true && this.canSetByBusinessLogic === true;
	}

	/**
	 * @returns Has user generally the right to set this attribute? This returns the result of the conditions
	 * in `<set><if-rights>...</if-rights></set>`.
	 *
	 * When this cannot be decided (e.g. because needed apis are not loaded) then `undefined` is returned.
	 *
	 * Note, that the final calculation if the attribute can be set now, includes further criteria. See {@link canSet}.
	 */
	public get hasRightToSet() : boolean | undefined {
		const parent = this.parentAttributeInfo;
		if (parent && !parent.hasRightToSet)
			return parent.hasRightToSet;

		return this.args.hasRightToSet ? this.args.hasRightToSet.call(this.args.apiObjWrapper) : true;
	}

	protected override get canSetByBusinessLogic() : boolean | undefined {
		const parent = this.parentAttributeInfo;
		if (parent && !parent.canSetByBusinessLogic)
			return parent.canSetByBusinessLogic;

		return this.args.canSetByBusinessLogic ? this.args.canSetByBusinessLogic.call(this.args.apiObjWrapper) : true;
	}

	/**
	 * @returns Is the value of this attribute generally not editable for current user? Then it
	 * is in read-mode and it will be visualized more like a label.
	 */
	public get readMode() : boolean {
		return !this.hasRightToSet;
	}

	/**
	 * @returns Returns a list of validators for this attribute.
	 */
	public get validations() : (() => PValidatorObject | null)[] {
		return this.args.validations ? this.args.validations.call(this.args.apiObjWrapper) : [];
	}

	/**
	 * @returns Returns a list of async validators for this attribute. See `AsyncValidatorsService`.
	 */
	public get asyncValidations() : (() => PValidatorObject<'async'> | null)[] {
		return this.args.asyncValidations ? this.args.asyncValidations.call(this.args.apiObjWrapper) : [];
	}

	/**
	 * The raw-data default value for this attribute.
	 */
	public get defaultValue() : any {
		if (this.args.defaultValue === undefined) {
			return null;
		} else {
			return this.args.defaultValue.call(this.args.apiObjWrapper, this.id);
		}
	}

	private get parentAttributeInfo() : ApiAttributeInfo<any, any> | null {
		// Is this the attribute-info of the object wrapper?
		if (this.args.apiObjWrapper.attributeInfoThis === this) {
			// Then return the attribute info of parent
			const parent = this.args.apiObjWrapper.parent;
			return parent ? parent.attributeInfoThis : null;
		} else {
			// otherwise it a primitive type attribute-info.
			return this.args.apiObjWrapper.attributeInfoThis;
		}
	}

	/**
	 * @param value The value for which the attribute-value-info should be returned.
	 * @returns The attribute-value-info for desired `value`. `null` will
	 * be returned when no logic is defined for desired `value` in the API XML file.
	 */
	public getAttributeValueInfo(value : ValueType) : ApiAttributeValueInfo<ParentType> | null {
		return this.args.attributeValueInfos?.get(value) ?? null;
	}

	/**
	 * A getter to check if this is of type Object.
	 * We can already read if its a List or any kind of Primitive, but not if its an Object.
	 * But for now we know that every ai with primitiveType === null is an Object.
	 * Therefore we use this workaround till we have a better solution.
	 */
	public get isObjectWrapper() : boolean {
		return this.primitiveType === null;
	}

	/**
	 * Does this attribute-info represent an object or list wrapper?
	 */
	public get isWrapper() : boolean {
		return this.isObjectWrapper || this.primitiveType === PApiPrimitiveTypes.ApiList;
	}
}
