import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

/**
 * Directive to force elements to be sticky and add the boundaries
 * of an arbitrary, not necessarily the direct parent. Also could be used
 * when container has overflow: hidden | scroll | auto, since in those situations
 * regular position: sticky doesn't work.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[forceSticky]',
	standalone: true,
})
export class ForceStickyDirective implements AfterViewInit, OnDestroy {

    /**
     * The container element to be considered as the boundaries of the element.
     * If none provided it will take the parent.
     */
    @Input() public containerElement : HTMLElement | null = null;

    /**
     * Color that should be used as background color of the before element attached to the sticky element to
     * fill in gaps. These gaps can be created, for example, when using forceSticky on a table, since the table
     * line are not in the sub elements.
     */
    @Input() public backgroundStickyColor : string | null = null;

    constructor(
        private elementRef : ElementRef<HTMLElement>,
    ) {
    }

    private scrollParentElementToStick : HTMLElement | null = null;

    /**
	 * @param node element we want to check for a scrollable parent
	 * @returns the parent element that is scrollable if any
	 */
    private getScrollParentToStick(node : HTMLElement | null) : void {
    	if (!node) {
    		return;
    	}

    	/**
			 * Condition to find the scrollable parent to stick to.
			 * The content check is needed because some elements won't have all data loaded
			 * when this method is called. And in that case we used the content class to get the element
			 * that will be scrollable immediately (even if it is not scrollable yet).
			 * Be aware that, if the p-collapsible is not sticking as intended, maybe there are parent elements
			 * with the content class defined.
			 */
    	while (node.scrollHeight <= node.clientHeight && !node.classList.contains('content')) {
    		node = node.parentElement;
    		if (!node) {
    			return;
    		}
    	}
    	this.scrollParentElementToStick = node;
    	this.scrollParentElementToStick.addEventListener('scroll', this.scrollHandler);
    }

    private offsetY : number = 0;

    private scrollHandler = () : void => {
    	if (this.scrollParentElementToStick) {
    		const containerElement : HTMLElement = this.containerElement ?? this.elementRef.nativeElement.parentElement!;
    		const scrollParentYOffset = this.scrollParentElementToStick.getBoundingClientRect().y;
    		const topCoord = containerElement.getBoundingClientRect().y;
    		const wholeHeight = containerElement.getBoundingClientRect().height;
    		const bottomCoord = topCoord + wholeHeight;
    		if (bottomCoord > scrollParentYOffset && topCoord < scrollParentYOffset) {
    			// Need to have the -1 due to rounding issues that lead to button being slightly bellow the top element
    			this.offsetY = Math.min(
    				scrollParentYOffset - topCoord - 1,
    				wholeHeight - this.elementRef.nativeElement.getBoundingClientRect().height,
    			);
    			this.elementRef.nativeElement.style.transform = `translateY(${this.offsetY}px)`;
    		} else {
    			this.offsetY = 0;
    			this.elementRef.nativeElement.style.transform = `translateY(0px)`;
    		}
    	}
    };

    public ngAfterViewInit() : void {
    	this.getScrollParentToStick(this.elementRef.nativeElement);
    	this.elementRef.nativeElement.style.position = 'relative';
    	this.elementRef.nativeElement.style.zIndex = '1000000000';
    	if (this.backgroundStickyColor) {
    		const divElement = document.createElement('div');
    		divElement.style.position = 'absolute';
    		divElement.style.top = '0';
    		divElement.style.bottom = '0';
    		divElement.style.left = '0';
    		divElement.style.right = '0';
    		divElement.style.zIndex = '-1';
    		divElement.style.backgroundColor = this.backgroundStickyColor;
    		this.elementRef.nativeElement.insertBefore(divElement, this.elementRef.nativeElement.firstChild);
    	}
    }

    public ngOnDestroy() : void {
    	this.scrollParentElementToStick?.removeEventListener('scroll', this.scrollHandler);
    }
}
