import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { PBackgroundColorEnum, PTextColorEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { PSentryService } from '@plano/shared/sentry/sentry.service';

/**
 * A component that includes usual modal content like a header and a footer with ok and cancel buttons etc.
 *
 * @example
 * 	<p-modal-content
 * 		(onDismiss)="dismiss('no')"
 * 		(onClose)="success('yep')"
 * 	>
 * 		<p-modal-content-body>
 * 			Hallo Welt
 * 		</p-modal-content-body>
 *  </p-modal-content>
 */
@Component({
	selector: 'p-modal-content',
	templateUrl: './p-modal-content.component.html',
	styleUrls: ['./p-modal-content.component.scss'],
})
export class PModalContentComponent implements AfterViewInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public modalBodyHeight : string | null = null;

	/**
	 * Effects spacing inside the modal.
	 */
	@Input() public size ?:
		| 'frameless'
		| ExtractFromUnion<'fullscreen', ModalServiceOptions['size']>
		| typeof enumsObject.BootstrapSize.SM
		| typeof enumsObject.BootstrapSize.MD
		| typeof enumsObject.BootstrapSize.LG;

	@HostBinding('class.h-100') protected _alwaysTrue = true;

	@Input() public theme ?: ModalServiceOptions['theme'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public modalTitle : ModalContentOptions['modalTitle'] = null;

	/** The icon for the modal title */
	@Input() protected icon : PFaIcon | null = null;

	/**
	 * Written to the dismiss button.
	 * Default: No
	 */
	@Input() public dismissBtnLabel : string | null = null;

	/**
	 * The label of the button that closes the modal successfully.
	 * This will be set when user opens the Modal.
	 * If you want the possibility to change the text during a lifecycle of a Modal, provide a fn : () => string here.
	 *
	 * Default: Yes
	 */
	@Input() public closeBtnLabel : (string | (() => string)) | null = null;

	/**
	 * Visual Theme of the close button
	 */
	@Input() public closeBtnTheme ?:
	| typeof enumsObject.PThemeEnum.DARK
	| typeof enumsObject.PThemeEnum.DANGER
	| typeof enumsObject.PThemeEnum.PRIMARY
	| typeof enumsObject.PThemeEnum.LIGHT
	| typeof enumsObject.PThemeEnum.SECONDARY
	| typeof enumsObject.PThemeEnum.SUCCESS;

	/** Icon for the close button */
	@Input() public closeBtnIcon : PlanoFaIconPoolValues | null = null;

	/**
	 * The icon for the dismiss button.
	 * If icon is provided, the dismiss button text will be hidden on mobile.
	 */
	@Input() public dismissBtnIcon : PlanoFaIconPoolValues | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public closeBtnDisabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideDismissBtn ?: boolean = false;

	/**
	 * Happens when the user closes the modal with some kind of success/ok button
	 */
	@Output() public onClose = new EventEmitter<Event>();

	/**
	 * Happens when the user closes the modal with some kind of cancel button.
	 * E.g. a × icon in the top right corner.
	 * This also triggers if the user clicks outside the modal (if outside-click is not disabled by any config)
	 */
	@Output() public onDismiss = new EventEmitter<Event>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ViewChild('footer', { static: true }) private footer! : any;

	// HACK: quick fix for modals that have no footer,
	// since i implemented support for .footerViewContainerRef in modal-default-template
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('showCustomFooter') private _showCustomFooter : boolean | null = null;

	constructor(
		private localize : LocalizePipe,
		public pSentryService : PSentryService,
	) {}

	public Config = Config;
	public enums = enumsObject;
	public PTextColorEnum = PTextColorEnum;

	public ngAfterViewInit() : void {
		this.initValues();
	}

	/**	closeBtnLabel can be a fn which returns a string */
	public get closeBtnLabelAsString() : string | null {
		if (this.closeBtnLabel === null) return null;
		if (typeof this.closeBtnLabel === 'string') return this.closeBtnLabel;
		return this.closeBtnLabel();
	}

	/**
	 * Set some default values for properties that are not defined yet
	 */
	public initValues() : void {
		if (this.modalTitle === undefined) this.modalTitle = this.localize.transform('Sicher?');
		if (this.dismissBtnLabel === null) this.dismissBtnLabel = this.localize.transform('Nein');
		if (this.closeBtnLabel === null) this.closeBtnLabel = this.localize.transform('Ja');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showCustomFooter() : boolean {
		if (this._showCustomFooter !== null) return this._showCustomFooter;
		return this.footer.nativeElement && this.footer.nativeElement.children.length > 0;
	}

	/**
	 * Depending on the theme, should the text be white?
	 */
	public get textWhite() : boolean {
		if (!this.theme) return false;
		switch (this.theme) {
			case enumsObject.PThemeEnum.WARNING:
			case enumsObject.PThemeEnum.LIGHT:
			case PBackgroundColorEnum.WHITE:
				return false;
			case enumsObject.PThemeEnum.PURPLE:
			case enumsObject.PThemeEnum.DARK:
			case enumsObject.PThemeEnum.DANGER:
			case enumsObject.PThemeEnum.PRIMARY:
			case enumsObject.PThemeEnum.SECONDARY:
			case enumsObject.PThemeEnum.SUCCESS:
			case enumsObject.PThemeEnum.INFO:
				return true;
		}
	}
}

@Component({
	selector: 'p-modal-content-body',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PModalContentBodyComponent {
	@HostBinding('class.d-block') protected _alwaysTrue = true;
}

@Component({
	selector: 'p-modal-content-footer',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PModalContentFooterComponent {
	@HostBinding('class.modal-footer')
	@HostBinding('class.d-flex') protected _alwaysTrue = true;
	@HostBinding('class.justify-content-between') private classJustifyContentBetween = true;
	@HostBinding('class.align-items-center') private classAlignItemsCenter = true;
}
