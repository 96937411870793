import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NonEmptyString, TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { PClipboardService } from '@plano/shared/p-forms/p-clipboard.service';

@Directive({
	selector: ':not(p-collapsible):not(span)[pAnchorLink]:not([label])',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AnchorLinkDirective implements OnDestroy, AfterViewInit {

	/**
	 * The anchor string. This is the text that will be used to create the anchor url.
	 */
	@Input() public pAnchorLink ! : NonEmptyString;

	/** The HTML ID. Its forbidden to set it. We listen for it here to make a runtime check. */
	@Input('id') private elementHtmlId : string | null = null;

	/**
	 * Anchor position can be either position in the bottom right,right top or center right.
	 *
	 * By default the anchor position will be top-right, but if used with a label or in a span element
	 * the anchor position will be center-right by default.
	 *
	 * In case the anchor link is supposed to be set
	 * inline (per example with a h3 tag), the anchor should be set using
	 * a span wrapper element.
	 *
	 * So for a h3 tag such as:
	 * <h3>Title</h3>
	 *
	 * the anchor should be used like following:
	 * <h3>
	 * 	<span pAnchorLink="element-id">Title</span>
	 * </h3>
	 *
	 * It is also possible to define the color of the anchor link button,
	 * when position is outside through the use of the anchorColor property.
	 * The possible values are white and black, default being black.
	 * <span pAnchorLink="element-id" anchorColor='white'>Title</span>
	 */
	@Input() protected anchorPos : 'center-right' | 'bottom-right' | 'top-right' = 'top-right';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() protected anchorRelative : 'inside' | 'outside' = 'outside';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private anchorColor : 'black' | 'white' = 'black';

	/**
	 * Should the anchor button be visible on hover?
	 */
	@Input() protected hidePAnchorLinkButton : boolean = false;

	constructor(
		protected elementRef : ElementRef<HTMLElement>,
		private console : LogService,
		private localize : LocalizePipe,
		private clipboard : PClipboardService,
		private toasts : ToastsService,
	) {
	}

	protected anchorLinkButton ! : HTMLButtonElement ;
	private newAnchorId ! : string;

	private hideTimeOut : number | null = null;
	private preventMouseOut : number | null = null;

	/**
	 * Method to handle the display of the anchor link
	 */
	protected showAnchorLink() : void {
		if (this.hideTimeOut !== null) {
			window.clearTimeout(this.hideTimeOut);
			this.hideTimeOut = null;
		}
		if (this.preventMouseOut !== null) {
			window.clearTimeout(this.preventMouseOut);
			this.preventMouseOut = null;
		}
		this.anchorLinkButton.style.visibility = 'visible';
		if (Config.IS_MOBILE) {
			this.anchorLinkButton.classList.add('pl-1');
		} else {
			this.anchorLinkButton.classList.remove('pl-1');
		}
		window.setTimeout(() => {
			this.anchorLinkButton.classList.add('shown');
		}, 10);
	}

	/**
	 * Method to handle hiding the anchor link
	 */
	protected hideAnchorLink() : void {
		this.preventMouseOut = window.setTimeout(() => {
			this.anchorLinkButton.classList.remove('shown');
			this.hideTimeOut = window.setTimeout(() => {
				this.anchorLinkButton.style.visibility = 'hidden';
			}, 300);
		}, 300);
	}

	/**
	 * Adds the target to the desired element
	 */
	protected addIdToTargetElement(newId : string) : void {
		this.elementRef.nativeElement.id = newId;
	}

	/**
	 * Adds the anchor link button to the parent element
	 */
	protected appendAnchorLinkButton() : void {
		const htmlElement : HTMLElement = this.elementRef.nativeElement;
		if (htmlElement.tagName.toLowerCase() === 'span') {
			htmlElement.classList.add('d-inline-block');
		}
		htmlElement.appendChild(this.anchorLinkButton);
		htmlElement.classList.add('position-relative');
	}

	/**
	 * Transform string into a valid html id
	 */
	public static stringToId(value : string) : NonEmptyString {
		if (value.length === 0) throw new Error('Must be a non empty string.');
		const withDashes = value.toLowerCase()
			.replace(/ä/g, 'ae')
			.replace(/ü/g, 'ue')
			.replace(/ö/g, 'oe')
			.replace(/[\s&,.]/g, '-')
			.replace(/[^\w\-]/g, '-');
		return `${encodeURIComponent(withDashes)}` as NonEmptyString;
	}

	/**
	 * Initialize this directive.
	 */
	public ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {

		this.newAnchorId = AnchorLinkDirective.stringToId(this.pAnchorLink);
		this.addIdToTargetElement(this.newAnchorId);
		if (!this.hidePAnchorLinkButton) {
			this.anchorLinkButton = this.createAnchorButton();

			if (this.elementHtmlId) {
				this.console.error(`It is not allowed to have id="…" on the same element that uses [pAnchorLink]="…"`);
			}

			this.appendAnchorLinkButton();
			this.addEventListeners();
		}

		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/**
	 * Method called when component with directive is destroyed
	 */
	public ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (!this.hidePAnchorLinkButton)
			this.anchorLinkButton.remove();
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	public Config = Config;

	private createAnchorButton() : HTMLButtonElement {
		const anchorButton = document.createElement('button');
		anchorButton.type = 'button';

		// Change to white if position inside because it has black background
		this.anchorColor = this.anchorRelative === 'inside' ? 'white' : this.anchorColor;
		anchorButton.innerHTML = `<span style="color:${this.anchorColor}" class='fa-fw fas fa-link'></span>`;
		anchorButton.title = this.localize.transform('Link kopieren');

		switch (this.anchorPos) {
			case 'center-right':
				anchorButton.classList.add('center', 'right');
				break;
			case 'bottom-right': anchorButton.classList.add('bottom', 'right');
				break;
			case 'top-right': anchorButton.classList.add('top', 'right');
		}

		if (this.anchorRelative === 'inside') {
			anchorButton.classList.add('bg-dark', 'inside', 'text-white');
		} else anchorButton.classList.add('outside');

		anchorButton.classList.add('btn', 'anchor-directive', 'position-absolute', 'shadow-none');

		anchorButton.addEventListener('click', (event : MouseEvent) : void => {
			event.preventDefault();
			event.stopPropagation();
			const currentUrl : string = window.location.href;
			const withoutAnchor : string = currentUrl.includes('#') ? currentUrl.slice(0, currentUrl.indexOf('#')) : currentUrl;
			this.clipboard.copy(`${withoutAnchor}#${this.newAnchorId}`);
			this.toasts.addToast({
				content: this.localize.transform('Der Link zum Abschnitt wurde in die Zwischenablage kopiert.'),
				theme: enumsObject.PThemeEnum.SUCCESS,
				icon: enumsObject.PlanoFaIconPool.COPY_TO_CLIPBOARD,
				visibilityDuration: 'short',
			});

		});
		anchorButton.style.visibility = 'hidden';
		return anchorButton;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	protected addEventListeners() : void {
		this.elementRef.nativeElement.addEventListener('mouseenter', this.showAnchorLink.bind(this));
		this.elementRef.nativeElement.addEventListener('mouseleave', this.hideAnchorLink.bind(this));
	}
}
