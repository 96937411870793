<div class="{{theme ? 'modal-'+theme+' '+'bg-'+theme : ''}} d-flex flex-column h-100">
	<div *ngIf="modalTitle" class="modal-header d-flex justify-content-between align-items-stretch">
		<div
			[class.modal-header-content]="size !== 'fullscreen'"
			[class.d-flex]="size === 'fullscreen'"
			[class.w-100]="size === 'fullscreen'"
		>
			<div class="mr-auto w-100 card-header-inner">
				<h4 class="m-0"
					[class.text-white]="textWhite"
				><fa-icon
					*ngIf="!!icon"
					class="mr-2"
					[icon]="icon"
				/><span [innerHTML]="modalTitle"></span></h4>
			</div>
			<button type="button" class="btn large p-0" title="Schließen" i18n-title
				[class.text-white]="textWhite"
				(click)="onDismiss.emit($event)"
			>
				<fa-icon
					aria-hidden="true"
					[theme]="theme===enums.PThemeEnum.DARK ? PTextColorEnum.WHITE : null"
					[icon]="enums.PlanoFaIconPool.DISMISS"
				></fa-icon>
			</button>
		</div>
	</div>

	<div class="modal-body position-relative d-flex"
		[class.p-0]="size === 'frameless' || size === 'fullscreen'"
		[class.p-2]="size === enums.BootstrapSize.SM"
		[class.p-4]="size === enums.BootstrapSize.LG"
		[class.text-white]="textWhite"
		[style.height]="modalBodyHeight"
	>
		<div
			[class.modal-body-content]="size !== 'fullscreen'"
			[class.flex-grow-1]="size !== 'fullscreen'"
			[class.d-flex]="size === 'fullscreen'"
			[class.flex-column]="size === 'fullscreen'"
			[class.w-100]="size === 'fullscreen'"
		>
			<ng-content select="p-modal-content-body"></ng-content>
		</div>
	</div>
	<div #footer [class.d-none]="!showCustomFooter">
		<ng-content select="p-modal-content-footer"></ng-content>
	</div>

	<ng-template [ngIf]="!showCustomFooter">
		<div class="modal-footer d-flex justify-content-between align-items-center">
			<div class="modal-footer-content">
				<button
					*ngIf="!hideDismissBtn"
					pAutoFocus

					type="button"
					class="btn mr-2"
					[class.btn-outline-secondary]="!theme"
					[class.btn-light]="!!theme"
					(click)="onDismiss.emit($event)"
				><fa-icon
					*ngIf="dismissBtnIcon"
					[icon]="dismissBtnIcon"
					[class.pr-md-2]="dismissBtnLabel"
				></fa-icon><span
					[class.d-none]="dismissBtnIcon"
					[class.d-md-inline]="dismissBtnIcon"
				>{{ dismissBtnLabel }}</span></button>
				<button
					pAutoFocus

					type="button"
					class="btn ml-auto"
					[class.btn-outline-secondary]="(!closeBtnTheme && !theme) || closeBtnTheme === enums.PThemeEnum.SECONDARY"
					[class.btn-primary]="closeBtnTheme === enums.PThemeEnum.PRIMARY"
					[class.btn-dark]="closeBtnTheme === enums.PThemeEnum.DARK"
					[class.btn-danger]="closeBtnTheme === enums.PThemeEnum.DANGER"
					[class.btn-success]="closeBtnTheme === enums.PThemeEnum.SUCCESS"
					[class.btn-light]="(!closeBtnTheme && !!theme) || closeBtnTheme === enums.PThemeEnum.LIGHT"
					(click)="onClose.emit($event);"
					[disabled]="closeBtnDisabled"
				><fa-icon *ngIf="closeBtnIcon" [icon]="closeBtnIcon" class="pr-1"></fa-icon> {{ closeBtnLabelAsString }}</button>
			</div>
		</div>
	</ng-template>
</div>
