<!-- NOTE: Do not remove d-inline-block from fa-icon. It will break the tooltip/popover position. -->
<div
	[pTooltip]="tooltipRef"
	[pTooltipPlacement]="placement"
	[pTooltipTheme]="theme ?? enums.PThemeEnum.DARK"
	aria-label="icon-tooltip-trigger-icon"
	class="d-inline-flex align-items-center justify-content-center"
>
	<fa-icon
		[class]="(theme ? 'text-' + theme : '') + ' clickable ' + (spacing === 'default' ? (Config.IS_MOBILE ? 'btn btn-sm m-0' : 'px-2') : '')"
		[class.small]="size === 'small'"
		[icon]="icon"
		(click)="onClickIcon($event)">
	</fa-icon>
</div>

<ng-template #tooltipRef>
	<div class="d-block m-0 text-left o-hidden card">
		<div *ngIf="headline" class="card-header pl-2 pr-2 pt-1 pb-1">
			<span class="crop-on-overflow">{{ headline }}</span>
		</div>
		<div class="card-body p-1 pl-2 pr-2 text-wrap">
			<ng-container *ngTemplateOutlet="ngContentRef"></ng-container>
		</div>
	</div>
</ng-template>

<ng-template #ngContentRef><ng-content></ng-content></ng-template>
