import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'p-list',
	templateUrl: './p-list.component.html',
	styleUrls: ['./p-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PListComponent {
}
