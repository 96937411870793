import { SchedulingApiAccountHolderState, SchedulingApiAdyenAccountBase } from '@plano/shared/api';
import { ApiDataCopyAttribute } from '@plano/shared/api/base/api-data-copy-attribute/api-data-copy-attribute';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAdyenAccount extends SchedulingApiAdyenAccountBase {
	public adyenTermsOfServiceAccepted = new ApiDataCopyAttribute<boolean>(false);
	public adyenContractAccepted = new ApiDataCopyAttribute<boolean>(false);

	/**
	 *	true if all checkboxes are accepted
	 */
	public get allCheckboxesAccepted() : boolean {
		if (this.attributeInfoAdyenTermsOfServiceAccepted.isAvailable && this.attributeInfoAdyenContractAccepted.isAvailable) {
			return this.attributeInfoAdyenTermsOfServiceAccepted.value! && this.attributeInfoAdyenContractAccepted.value!;
		} else return false;
	}

	/**
	 *	true if this account is closed or suspended
	 */
	public get isClosedOrSuspended() : boolean {
		return this.accountHolderState === SchedulingApiAccountHolderState.CLOSED ||
		this.accountHolderState === SchedulingApiAccountHolderState.SUSPENDED;
	}

	/**
	 *	true if the onboarding-button should be disabled
	 */
	public get adyenOnboardingButtonDisabled() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');

		if (this.api.isBackendOperationRunning) return true;
		if (!this.allCheckboxesAccepted) return true;
		if (this.api.me.isTestAccount) return true;
		if (this.accountHolderState === SchedulingApiAccountHolderState.CLOSED) return true;
		if (this.accountHolderState === SchedulingApiAccountHolderState.SUSPENDED) return true;
		return false;
	}

	// FIXME: PLANO-161893
	// eslint-disable-next-line no-restricted-syntax
	public attributeInfoAdyenTermsOfServiceAccepted = new ApiAttributeInfo<SchedulingApiAdyenAccount, boolean>({
		apiObjWrapper: this,
		name: 'adyenTermsOfServiceAccepted',
		nodeName: 'ADYEN_ACCOUNT_ADYEN_TERMS_OF_SERVICE_ACCEPTED',
		primitiveType: PApiPrimitiveTypes.boolean,
		hasRightToSet: () => {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			return !!this.api.rightsService.isOwner;
		},
		canSetByBusinessLogic: () => {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			return !this.api.me.isTestAccount &&
			this.accountHolderState === SchedulingApiAccountHolderState.NOT_INITIALIZED;
		},
	});

	// FIXME: PLANO-161893
	// eslint-disable-next-line no-restricted-syntax
	public attributeInfoAdyenContractAccepted = new ApiAttributeInfo<SchedulingApiAdyenAccount, boolean>({
		apiObjWrapper: this,
		name: 'adyenContractAccepted',
		nodeName: 'ADYEN_ACCOUNT_ADYEN_CONTRACT_ACCEPTED',
		primitiveType: PApiPrimitiveTypes.boolean,
		hasRightToSet: () => {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			return !!this.api.rightsService.isOwner;
		},
		canSetByBusinessLogic: () => {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			return !this.api.me.isTestAccount &&
			this.accountHolderState === SchedulingApiAccountHolderState.NOT_INITIALIZED;
		},
	});
}
