import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { ShiftId } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { NavigationHelperInfo, PRouterService } from '@plano/shared/core/router.service';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import _ from 'underscore';

/**
 * Nav back anchor component to be used whenever we want to have an anchor
 * that will navigate to the previous url
 */
@Component({
	selector: 'p-nav-back-anchor',
	templateUrl: './nav-back-anchor.component.html',
	styleUrls: ['./nav-back-anchor.component.scss'],
})
export class PNavBackAnchorComponent implements AfterContentInit {

	/**
	 *	Sometimes we want to overwrite the default nav back link,
	 *	for that we can use this input.
	 */
	@Input() public forceNavBackLink : string | null = null;

	/**
	 * Fragment to be added to the navigation, it will override all other fragments.
	 * Usually the fragment is set by the routeId, but sometimes we don't have it,
	 * for example on the public page
	 */
	@Input() public fragmentForNavBack : string | null = null;

	/**
	 * Query params to be added to the navigation back
	 */
	@Input() private queryParamsForNavBack : Params | null = null;

	/**
	 * Input to override the usual nav back icon
	 */
	@Input('icon') private _icon : PlanoFaIconPoolValues | null = null;

	/**
	 * Should the text inside the anchor be text-danger?
	 */
	@Input() public hasTextDanger : boolean = false;

	/**
	 * router id to scroll to after navigation
	 */
	@Input() public routeId : Id | ShiftId | null = null;

	/**
	 * Text inside the anchor, by default Zurück
	 */
	@Input('text') private _text : PDictionarySourceString | null = null;

	/**
	 * Event to emit after click
	 */
	@Output() public onNavBack : EventEmitter<undefined> = new EventEmitter<undefined>();

	constructor(
		private pRouterService : PRouterService,
	) {

	}

	public ngAfterContentInit() : void {
		this.updateFragmentInStorage();
	}

	/**
	 * When we have a page that uses this component we need to update the fragment of the stored
	 * url, so that clicking the native nav back button on android has the same behavior as
	 * clicking this button.
	 */
	private updateFragmentInStorage() : void {
		if (this.pRouterService.storedUrls.length <= 1) return;
		const previousUrl = this.pRouterService.storedUrls.previousUrl;
		if (!previousUrl) return;

		// remove the query param so we don't have 2 scroll animations at the same time
		if (previousUrl.queryParams?.['scrollToTabIfNotInView']) {
			delete previousUrl.queryParams['scrollToTabIfNotInView'];
		}
		previousUrl.fragment = this.fragmentForNavBack ?? (this.routeId && this.routeIdForUrl ? this.routeIdForUrl : undefined);
	}

	/**
	 * Get the icon to be displayed in the template
	 */
	public get icon() : PlanoFaIconPoolValues {
		if (this._icon) return this._icon;
		return enumsObject.PlanoFaIconPool.NAV_BACK;
	}

	private _queryParams : Params | null = null;

	/**
	 * Query params
	 */
	public get queryParams() : Params {
		const newParams = {...this.navBackInfo.navigationExtras.queryParams, ...this.queryParamsForNavBack};
		if (!this._queryParams)
			this._queryParams = newParams;
		if (!_.isEqual(newParams, this._queryParams))
			this._queryParams = newParams;
		return this._queryParams;
	}

	/**
	 * Get the text to be shown in the template
	 */
	public get text() : PDictionarySourceString {
		if (this._text) return this._text;
		return 'Zurück';
	}

	/**
     * NavBackInfo represents the information needed to perform the navigation back
     */
	public get navBackInfo() : NavigationHelperInfo {
		return this.pRouterService.navBackInfo();
	}

	/**
	 * Remove the most recent history once the anchor is clicked
	 */
	public onNavigation(event : MouseEvent) : void {
		if (event.button === 0) {
			this.pRouterService.forgetMostRecentHistoryEntry();
			this.onNavBack.emit();
		}
	}

	/**
	 * Get the id to add to the url
	 */
	public get routeIdForUrl() : string | null {
		if (!this.routeId) return null;
		if (this.routeId instanceof ShiftId)
			return `${this.routeId.toPrettyString()}`;
		else return `${this.routeId.toString()}`;
	}
}
