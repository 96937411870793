import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReportUrlParamsService } from './client/report/report-url-params.service';
import { ReportService } from './client/report/report.service';
import { CourseFilterService } from './client/scheduling/course-filter.service';
import { SchedulingService } from './client/scheduling/scheduling.service';
import { BookingsService } from './client/scheduling/shared/p-bookings/bookings.service';
import { PShiftExchangeListService } from './client/shared/p-shift-exchange/p-shift-exchange-list/p-shift-exchange-list.service';
import { PSidebarService } from './client/shared/p-sidebar/p-sidebar.service';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAppStartupService {
	constructor(
		private reportUrlParamsService : ReportUrlParamsService,
		private reportService : ReportService,
		private courseService : CourseFilterService,
		private pSidebarService : PSidebarService,
		private schedulingService : SchedulingService,
		private bookingsService : BookingsService,
		private pShiftExchangeListService : PShiftExchangeListService,
	) {
	}

	private isReadySubject : Subject<void> = new Subject<void>();
	private isInitialized = false;

	/**
	 * Initialize this app.
	 * Read all cookies.
	 * Load all services.
	 */
	public init() : void {
		this.reportUrlParamsService.initValues();
		this.reportService.initValues();

		/**
		 * In case you expected the filterService here, it will take care of itself.
		 * {@see FilterService#constructor}
		 */

		this.courseService.readCookies();
		this.courseService.initValues();

		this.pSidebarService.readCookies();
		this.pSidebarService.initValues();

		this.schedulingService.readCookies();
		this.schedulingService.initValues();

		this.bookingsService.readCookies();
		this.bookingsService.initValues();

		this.pShiftExchangeListService.readCookies();
		this.pShiftExchangeListService.initValues();

		this.isInitialized = true;
		this.isReadySubject.next();
	}

	/**
	 * Is this app ready?
	 * Are all cookies read?
	 * Are all services loaded?
	 */
	public isReady(success ?: () => void) : boolean {
		const isLoaded = this.isInitialized;

		if (success) {
			if (isLoaded)
				success();
			else
				this.isReadySubject.subscribe(success);
		}

		return isLoaded;
	}
}
