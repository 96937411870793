import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TimeStampApiService } from '@plano/shared/api';
import { PreventNavigationService } from '@plano/shared/core/prevent-navigation.service';

@Injectable({providedIn:'root'})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CanActivateDevicesGuard implements CanActivate {

	constructor(
		private api : TimeStampApiService,
		private preventNavigationService : PreventNavigationService,
	) {
	}

	/**
	 * This page should not be accessible to users without the correct rights
	 * @see CanActivate#canActivate
	 */
	public canActivate(
		_route : ActivatedRouteSnapshot | null,
		_state : RouterStateSnapshot | null,
	) : boolean {
		void this.preventNavigationService.preventUserWithoutRightsInPageWithAI(this.api.data.attributeInfoAllowedTimeStampDevices);
		return true;

	}
}
