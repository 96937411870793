import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { SchedulingApiBooking } from '@plano/client/scheduling/shared/api/scheduling-api-booking.service';
import { PPaymentStatusEnum, getPaymentStatusIcon, getPaymentStatusIconStyle, getPaymentStatusTooltipTheme, paymentStatusTitle } from '@plano/client/scheduling/shared/api/scheduling-api.utils';
import { BookingsService } from '@plano/client/scheduling/shared/p-bookings/bookings.service';
import { PTextColor, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { SchedulingApiBookingState, ShiftSelector } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-dumb-booking-item',
	templateUrl: './dumb-booking-item.component.html',
	styleUrls: ['./dumb-booking-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class DumbBookingItemComponent implements PComponentInterface, OnDestroy {
	@HostBinding('id') private get hasId() : string {
		return `${this.id?.toString()}`;
	}

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public model : SchedulingApiBooking['model'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private state : SchedulingApiBooking['state'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private paymentStatus : SchedulingApiBooking['paymentStatus'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('bookingNumber') private _bookingNumber : SchedulingApiBooking['bookingNumber'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dateOfBooking : SchedulingApiBooking['dateOfBooking'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public noRelatedShiftsAvailable : boolean | null = null;

	@HostBinding('role') private _role = 'listitem';

	/**
	 * Course selector for the booking
	 */
	@Input() public bookingCourseSelector : ShiftSelector | null = null;

	/**
	 * If this component is shown inside a 'table' thing, then there need to be min-widths for each column.
	 */
	@Input('careAboutAlignment') public isInsideList : boolean = false;

	/**
	 * Set this to true if user has the rights to edit this booking
	 */
	@Input() public userCanWrite : boolean | null = null;

	/**
	 * Set this to true if user has the rights to edit any booking.
	 * If thats true, the edit button will always be shown, but sometimes be disabled.
	 */
	@Input() public userCanWriteSomeBookings : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public id ?: SchedulingApiBooking['id'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public firstName : SchedulingApiBooking['firstName'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public lastName : SchedulingApiBooking['lastName'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public ownerComment : SchedulingApiBooking['ownerComment'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public bookingComment : SchedulingApiBooking['bookingComment'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public participantCount : SchedulingApiBooking['participantCount'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public price : SchedulingApiBooking['price'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public firstShiftStart : SchedulingApiBooking['firstShiftStart'] | null = null;

	/**
	 * Router link to be used in 'view in calendar' anchor
	 */
	@Input() public onSelectShiftsLink : string | null = null;

	/**
	 * Should firstShiftStart be visible?
	 * @example Usually [showFirstShiftStart]="!groupByCourses" should be bound here.
	 */
	@Input() public showFirstShiftStart : boolean = true;

	@HostBinding('class.position-relative') private _alwaysTrue = true;

	constructor(
		private localize : LocalizePipe,
		private bookingsService : BookingsService,
	) {}

	public tooltipPlacements = NgxPopperjsPlacements;

	public enums = enumsObject;
	public Config = Config;

	private timeout : number | null = null;

	/**
	 * Get the bookingNumber or some placeholder chars
	 */
	public get bookingNumber() : number | string {
		if (this.isLoading) return '███ █████';
		if (this._bookingNumber === null) throw new Error(`[state]="…" must be defined`);
		return this._bookingNumber;
	}

	/**
	 * Get link for router link
	 */
	public get editBookingLink() : string | null {
		if (this.id)
			return this.bookingsService.editBookingLink(this.id);
		else return null;
	}

	/**
	 * Get the color
	 */
	public get modelColor() : string | null {
		if (this.model) {
			if (!this.model.color) return null;
			return `#${this.model.color}`;
		}
		return null;
	}

	/**
	 * Name of the related shiftModel
	 */
	public get modelName() : string {
		if (this.model) {
			return this.model.name;
		}
		return '';
	}

	/**
	 * Stringify the course selector
	 */
	public get bookingCourseSelectorUrl() : string {
		return JSON.stringify(this.bookingCourseSelector);
	}

	/**
	 * getter for the Status-Icon of this booking
	 */
	public get statusIcon() : PlanoFaIconPoolValues | null {
		if (this.isLoading) return null;
		switch (this.state) {
			case null:
			case undefined:
				throw new Error(`[state]="…" must be defined`);
			case SchedulingApiBookingState.BOOKED:
				return enumsObject.PlanoFaIconPool.BOOKING_BOOKED;
			case SchedulingApiBookingState.CANCELED:
				return enumsObject.PlanoFaIconPool.BOOKING_CANCELED;
			case SchedulingApiBookingState.INQUIRY:
				return enumsObject.PlanoFaIconPool.BOOKING_INQUIRY;
			case SchedulingApiBookingState.INQUIRY_DECLINED:
				return enumsObject.PlanoFaIconPool.BOOKING_DECLINED;
			default:
				const NEVER : never = this.state;
				throw new Error(`could not get state ${NEVER}`);
		}
	}

	/**
	 * Theme of the status of this booking
	 */
	public get statusTheme() : PThemeEnum | null {
		if (this.state === SchedulingApiBookingState.CANCELED || this.state === SchedulingApiBookingState.INQUIRY_DECLINED) {
			return this.enums.PThemeEnum.DANGER;
		}
		return null;
	}

	/**
	 * getter for the Status-Icon of this booking
	 */
	public get statusLabel() : string | null {
		switch (this.state) {
			case SchedulingApiBookingState.BOOKED:
				return this.localize.transform('Gebucht');
			case SchedulingApiBookingState.CANCELED:
				return this.localize.transform('Storniert');
			case SchedulingApiBookingState.INQUIRY:
				return this.localize.transform('Anfrage');
			case SchedulingApiBookingState.INQUIRY_DECLINED:
				return this.localize.transform('Anfrage abgelehnt');
			case null:
				return null;
		}
	}

	/**
	 * getter for the title of the status of payment
	 */
	public get paymentStatusTitle() : PDictionarySourceString {
		if (this.isLoading) return 'Lädt…';
		return paymentStatusTitle(this.paymentStatus!);
	}

	/**
	 * Get a icon for paymentStatus
	 */
	public get paymentStatusIcon() : PlanoFaIconPoolValues {
		return getPaymentStatusIcon(this.paymentStatus);
	}

	/**
	 * Get a theme / color for paymentStatus icon
	 */
	public get paymentStatusIconStyle() : PTextColor | null {
		return getPaymentStatusIconStyle(this.paymentStatus);
	}

	/**
	 * Get theme for the paymentstatus tooltip
	 */
	public get paymentStatusTooltipTheme() : PThemeEnum {
		return getPaymentStatusTooltipTheme(this.paymentStatus!);
	}

	/**
	 * Get a class for color for text inside the paymentstatus tooltip
	 */
	public get paymentStatusTooltipTextClass() : 'text-white' | '' {
		switch (this.paymentStatus) {
			case PPaymentStatusEnum.UNPAID:
			case PPaymentStatusEnum.REFUND_NEEDED:
				return 'text-white';
			default:
				return '';
		}
	}

	public ngOnDestroy() : void {
		window.clearTimeout(this.timeout ?? undefined);
	}

	/**
	 * Check if the edit button should be disabled
	 */
	protected get editButtonIsDisabled() : boolean {
		return !!this.isLoading || !this.userCanWrite;
	}
}
