import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PTextColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-modal-header',
	templateUrl: './modal-header.component.html',
	styleUrls: ['./modal-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ModalHeaderComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public headline : string | null = null;
	@Input() public item : SchedulingApiShift | SchedulingApiShiftModel | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClose : EventEmitter<Event> = new EventEmitter<Event>();
	@Input() private theme : PThemeEnum | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasDanger : boolean | null = null;

	public PTextColorEnum = PTextColorEnum;
	public enums = enumsObject;

	/**
	 * Calculate the text color
	 */
	public get textWhite() : boolean {
		return (
			!!this.theme &&
			this.theme !== enumsObject.PThemeEnum.WARNING &&
			this.theme !== enumsObject.PThemeEnum.LIGHT
		);
	}

	/**
	 * Start of item
	 */
	public get start() : number | null {
		if (this.item === null) return null;
		if (this.item instanceof SchedulingApiShiftModel) return null;
		return this.item.start;
	}
}
