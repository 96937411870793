import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { GridComponent } from '@plano/shared/core/component/grid/grid.component';
import { ValidationHintComponent } from '@plano/shared/core/component/validation-hint/validation-hint.component';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { SubscriptionLike as ISubscription } from 'rxjs';

/**
 * A bootstrap inspired wrapper for form-elements like checkbox, input, textarea etc.
 * It adds the Label to the form-element, it highlights the label if
 * the PFormControl is invalid etc.
 * @example
 *   <p-bootstrap-form-group
 *     label="First Name" i18n-label
 *     [control]="formGroup.get('firstName')!"
 *   >
 *     <p-checkbox …></p-checkbox>
 *   </p-bootstrap-form-group>
 */
@Component({
	selector: 'p-bootstrap-form-group',
	templateUrl: './p-bootstrap-form-group.component.html',
	styleUrls: ['./p-bootstrap-form-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PBootstrapFormGroupComponent implements OnInit, OnDestroy, AfterViewInit, PComponentInterface {
	@ViewChild('labelElement') private labelElement ?: ElementRef<HTMLLabelElement>;

	@HostBinding('class.form-group') private _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public readMode = false;

	/**
	 * Is the label of this group supposed to truncate instead of wrapping?
	 */
	@Input() public canTruncateLabel : boolean = false;

	/**
	 * Should the group with the label have a margin bottom? By default yes.
	 */
	@Input() public hasMarginBottom : boolean = true;

	/**
	 * Should the description be a footnote even on desktop?
	 */
	@Input() public keepAlwaysAsFootnote = false;

	/**
	 * Icon to be displayed in the footnote
	 */
	@Input() public footnoteIcon : typeof enumsObject.PlanoFaIconPool.MORE_INFO | typeof enumsObject.PlanoFaIconPool.WARNING =
		enumsObject.PlanoFaIconPool.MORE_INFO;

	/**
	 * Bellow this size the label is not rendered to the screen. (null by default)
	 */
	@Input() public minimumLabelDisplayBootstrapSize : 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | null = null;

	/**
	 * Aria label to be set for the outer HTML element.
	 * Only use this when `label` is `null` but still you want to have an aria-label.
	 */
	@Input() public ariaLabel : string | null = null;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	@HostBinding('attr.aria-label') private get attributeAriaLabel() : string | null {
		return this.label ?? this.ariaLabel;
	}

	/**
	 * Returns true if this is not valid.
	 * Invalid dropdown’s can be bordered red, or something similar that.
	 */
	@HostBinding('class.has-danger') private get hasDanger() : boolean {
		if (this._hasDanger) return true;
		if (this.control && (!this.checkTouched || this.control.touched) && this.control.errors) return true;
		return false;
	}

	/**
	 * Content for the <label> above your input/checkbox/…
	 * Note that the label can also be set here: new PFormControl(labelText : string, …)
	 */
	@Input('label') private _label : string | null = null;

	/**
	 * More infos for the user about how this data is used later.
	 *
	 * Usually the info icon is shown on desktop and the description on mobile,
	 * if you want one or the other you can use keepAlwaysAsFootnote to not change it depending on if is mobile or not.
	 */
	@Input() private description : TemplateRef<HTMLElement> | string | null = null;

	/**
	 * If HTML is provided as a description, than this getter will return it.
	 */
	public get descriptionHTML() : TemplateRef<HTMLElement> | null {
		if (typeof this.description === 'string') return null;
		return this.description;
	}

	/**
	 * If a string is provided as a description, than this getter will return it.
	 */
	public get descriptionText() : string | null {
		if (typeof this.description !== 'string') return null;
		return this.description;
	}

	/**
	 * Visual feedback if there is a problem like e.g. a validation error.
	 * It is not necessary to provide this, if you have provided a [control].
	 */
	@Input('hasDanger') private _hasDanger : boolean = false;

	/** @see ValidationHintComponent#checkTouched */
	@Input() private checkTouched : ValidationHintComponent['checkTouched'] = null;

	/**
	 * Some PFormControl.
	 * Needed to get info about errors.
	 * @deprecated Use the `hasDanger` if you really want to mark it as invalid. In most cases you probably just want to
	 *  remove this, because only the form element inside should indicate that something is invalid, not e.g. the headline
	 *  of {@link PBootstrapFormGroupComponent}.
	 */
	@Input() public control : AbstractControl | null = null;

	@ViewChild('labelElementWrapper') private labelElementWrapper ?: ElementRef<HTMLDivElement>;

	constructor(
		private changeDetectorRef : ChangeDetectorRef,
		private elementRef : ElementRef<HTMLElement>,
		private zone : NgZone,
		@Optional() private gridParent ?: GridComponent,
	) {}

	public Config = Config;

	public ngOnInit() : void {
		this.initFormControlSubscriber();
	}

	public enums = enumsObject;

	public maxSiblingHeight : number | null = null;

	private resizeObserver : ResizeObserver | null = null;

	public ngAfterViewInit() : void {
		if (this.minimumLabelDisplayBootstrapSize && this.labelElement) {
			this.labelElement.nativeElement.parentElement!.classList.add(`d-${this.minimumLabelDisplayBootstrapSize}-flex`);
			this.labelElement.nativeElement.parentElement!.classList.add(`d-none`);
		} else if (this.labelElement) {
			this.labelElement.nativeElement.parentElement!.classList.add('d-flex');
		}

		if (this.gridParent && this.labelElementWrapper) {
			this.handleInputInsideGrid();
			this.resizeObserver = new ResizeObserver(() => {
				this.handleInputInsideGrid();
			});
			this.resizeObserver.observe(this.elementRef.nativeElement);
		}
	}

	private handleInputInsideGrid() : void {
		this.zone.runOutsideAngular(() => {
			window.requestAnimationFrame(() => {
				this.highestHeightOnLine = null;

				// get the sibling columns of this input
				const siblingCols = this.gridParent!.colsElements;

				// get this elements col parent
				const thisCol = siblingCols.find(col => col.contains(this.elementRef.nativeElement));

				// if the element is not there it is because the element is not on the DOM yet (probably inside a modal)
				if (!thisCol) return;

				// loop through the columns and find out the maximum height
				for (const siblingCol of siblingCols) {

					// if the element is not on the same line as this element we can skip it
					if (siblingCol.offsetTop !== thisCol.offsetTop) continue;

					// get the label element from the sibling column
					const labelElementOfSiblingCol = siblingCol.querySelector<HTMLElement>('.label-element-wrapper>label');

					// if the sibling element doesn't have a label we can skip it
					if (!labelElementOfSiblingCol) return;

					// if the maximum height hasn't been set or the current height is bigger than the stored one replace it
					if (!this.maxSiblingHeight || labelElementOfSiblingCol.offsetHeight > this.maxSiblingHeight) {
						this.maxSiblingHeight = labelElementOfSiblingCol.offsetHeight;
					}
					this.highestHeightOnLine =
						(!this.highestHeightOnLine || this.highestHeightOnLine < labelElementOfSiblingCol.offsetHeight) ?
							labelElementOfSiblingCol.offsetHeight :
							this.highestHeightOnLine;
				}
				if (this.highestHeightOnLine !== this.maxSiblingHeight) {
					this.maxSiblingHeight = this.highestHeightOnLine;
				}
				this.changeDetectorRef.detectChanges();
			});
		});
	}

	private highestHeightOnLine : number | null = null;

	private subscription : ISubscription | null = null;

	private initFormControlSubscriber() : void {
		if (!this.control) return;
		this.subscription = this.control.valueChanges.subscribe(() => {
			this.changeDetectorRef.detectChanges();
		});
	}

	public ngOnDestroy() : void {
		this.subscription?.unsubscribe();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get label() : string | null {
		if (this._label) return this._label;
		if (!(this.control instanceof PFormControl)) return null;
		if (this.control.labelText === undefined) return null;
		return this.control.labelText;
	}

	/**
	 * Should the info-circle be shown?
	 * In some cases the description will be shown as a footnote instead.
	 */
	public get showInfoCircle() : boolean {
		return (!!this.descriptionText || !!this.descriptionHTML) &&
			!this.keepAlwaysAsFootnote &&
			!!this.label;
	}
}
