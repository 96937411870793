import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PBackgroundColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type StickyNoteBackgroundStyles = ExtractFromUnion<'primary' | 'dark', PThemeEnum> | PBackgroundColorEnum.WHITE;
const initialBackgroundStyle : StickyNoteBackgroundStyles = PBackgroundColorEnum.WHITE;

@Component({
	selector: 'p-sticky-note',
	templateUrl: './sticky-note.component.html',
	styleUrls: ['./sticky-note.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class StickyNoteComponent {

	@HostBinding('attr.role')
	private _role = 'status';

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showDot : boolean = false;

	/**
	 * The defined backgroundColor decides which icon should be shown for best contrast results
	 */
	@Input() public set backgroundColor(input : StickyNoteBackgroundStyles | null) {
		if (input === null) {
			if (this._backgroundColor !== initialBackgroundStyle) this._backgroundColor = initialBackgroundStyle;
			return;
		}
		this._backgroundColor = input;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get backgroundColor() : StickyNoteBackgroundStyles | null {
		return this._backgroundColor;
	}
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public height : string = '24';
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public displayBlock : boolean = false;

	private _backgroundColor : StickyNoteBackgroundStyles = initialBackgroundStyle;
	public PBackgroundColorEnum = PBackgroundColorEnum;
	public enums = enumsObject;
}
