<div
	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
	(editMode)="editMode.emit($event)"
>
	<div class="input-group" [disabled]="disabled">
		<div pHiddenInEditMode class="w-100 card mb-0">
			<div
				role="button"
				type="button"
				(click)="handleClickToEdit($event)"
				[preventDefault]="preventDefaultTriggerEditable.bind(this)"
				pEditableTriggerClickable
				triggerType="click"
				title="{{disabled || readMode ? '' : 'Bearbeiten'}}" i18n-title
				[class.text-editor-cursor]="!disabled && !readMode"
				class="card-body action-box-wrapper btn btn-light border-0 form-control w-100 text-right"
				[disabled]="control ? control.disabled : disabled"
			><fa-icon class="action-indicator-wrapper" *ngIf="!disabled" [icon]="enums.PlanoFaIconPool.TEXT_AREA"></fa-icon>
				<ng-container *ngTemplateOutlet="innerTextArea"></ng-container>
			</div>
		</div>
		<div
			pVisibleInEditMode
			*ngIf="!(disabled || readMode); else disabledNonEditable"
			class="w-100"
			[class.has-danger]="control && control.touched && control.invalid"
		>
			<ng-container *ngTemplateOutlet="prependItem"></ng-container>
			<angular-editor
				#editor
				class="w-100 p-0 form-control email-and-booking-plugin-styles border-0"
				[(ngModel)]="value"
				[config]="editorConfig"
				[class.required]="hasRequiredError"
				[class.has-danger]="hasDanger"
				[class.has-warning]="hasWarning"
				[class.ng-untouched]="control && control.untouched"
				[class.ng-pristine]="control && control.pristine"
			></angular-editor>
			<ng-container *ngTemplateOutlet="appendItem"></ng-container>
		</div>
		<div class="w-100 d-flex justify-content-between align-items-center">
			<button
				type="button"
				class="btn mr-2 w-100 mt-2"
				pEditableDismissButton
			><fa-icon [icon]="enums.PlanoFaIconPool.UNDO"></fa-icon></button>
			<button
				type="button"
				class="btn ml-2 w-100 mt-2"
				pEditableSuccessButton
			><fa-icon [class.text-warning]="hasWarning" [icon]="successBtnIcon" [spin]="successBtnIconSpin"></fa-icon></button>
		</div>
	</div>
</div>

<ng-template [ngIf]="!!control">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="control"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	></p-validation-hint>
</ng-template>

<ng-template #innerTextArea>
	<div
		[innerHtml]="value === null ? '' : value"
		class="text-style-reset-to-body text-left email-and-booking-plugin-styles w-100"
		[class.rounded-bottom-only]="!disabled"
		[class.rounded]="disabled"
		[disabled]="disabled"
		[class.text-monospace]="disabled && !readMode"
	></div>
</ng-template>

<ng-template #disabledNonEditable>
	<div class="w-100 card mb-0" *ngIf="!pEditable">
			<button
				type="button"
				class="card-body action-box-wrapper btn btn-light border-0 form-control w-100 text-right"
				[disabled]="true"
			><ng-container *ngTemplateOutlet="innerTextArea"></ng-container></button>
		</div>
</ng-template>

<ng-template #prependItem>
	<ng-content select=".input-group-prepend"></ng-content>
</ng-template>
<ng-template #appendItem>
	<ng-content select=".input-group-append"></ng-content>
</ng-template>
