import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface, EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalDismissParam, ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizeFilePipe } from '@plano/shared/core/pipe/localize-file.pipe';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

type ValueType = string;

/**
 * This component is for uploading pdf.
 * The initial value of the control can be a url to a pdf.
 * The value that gets set by user interaction will always be a base64 string.
 *
 * @example
 * 	<p-input-pdf
 * 		[formControl]="someFormControl"
 * 	></p-input-pdf>
 * @example
 * 	<p-input-pdf
 * 		[pEditable]="true"
 * 		[api]="api"
 * 		[formControl]="pFormsService.getByAI(formGroup, api.data.giftCardSettings.attributeInfoCustomTemplate)"
 * 	></p-input-pdf>
 */
@Component({
	selector: 'p-input-pdf',
	templateUrl: './input-pdf.component.html',
	styleUrls: ['./input-pdf.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputPdfComponent),
			multi: true,
		},
	],
})
export class PInputPdfComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, AfterContentInit, EditableControlInterface, PFormControlComponentInterface {

	// NOTE: I think this should not be necessary
	@Input() public override saveChangesHook ?: EditableControlInterface['saveChangesHook'];

	@ViewChild('modalContent', { static: true }) public modalContent ! : PModalTemplateDirective;

	/**
	 * String to add on top of the upload button
	 */
	@Input() private inputDescription : string | TemplateRef<unknown> | null = null;

	/**
	 * Content to add bellow the pdf preview
	 *
	 * @example
	 * 	<p-input-pdf
	 *  	[pdfDescription]="Hello World"
	 *  	…
	 *
	 * @example
	 *  <span #pdfDescriptionContent>Hello <strong>{{ planet }}</strong></span>
	 * 	<p-input-pdf
	 * 		[pdfDescription]="pdfDescriptionContent"
	 * 		…
	 */
	@Input() public pdfDescription : string | TemplateRef<unknown> | null = null;

	/**
	 * Modal template to open when we want to edit the pdf
	 */
	@Input() public modalTemplate : TemplateRef<PModalTemplateDirective> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public overlayTemplate : TemplateRef<unknown> | null = null;

	@HostBinding('class.flex-grow-1') protected _alwaysTrue = true;

	@ViewChild('fileInput') public fileInput ?: ElementRef<HTMLInputElement>;

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to make [(ngModel)] and [formControl] work.
	 */
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}
	@Input('disabled') public override set disabled(input : boolean) {
		this._disabled = input;
		super.disabled = input;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	constructor(
		protected override console : LogService,
		private localizePipe : LocalizePipe,
		private localizeFilePipe : LocalizeFilePipe,
		private modalService : ModalService,
		private editableDirective : EditableDirective,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	public Config = Config;

	/**
	 * Variable that will hold the src to feed the pdf-viewer
	 */
	public pdfSrc : string | null = null;

	/**
	 * Know if the input is a string or a template
	 */
	public get isInputDescriptionTemplate() : boolean {
		return this.inputDescription instanceof TemplateRef<unknown>;
	}

	/**
	 * Know if the input is a string or a template
	 */
	public get isPdfDescriptionTemplate() : boolean {
		return this.pdfDescription instanceof TemplateRef<unknown>;
	}

	/**
	 *	Input description as string
	 */
	public get inputDescriptionString() : string {
		if (this.inputDescription && typeof this.inputDescription === 'string')
			return this.inputDescription;
		return '';
	}

	/**
	 *	Pdf description as string
	 */
	public get pdfDescriptionString() : string {
		if (this.pdfDescription && typeof this.pdfDescription === 'string')
			return this.pdfDescription;
		return '';
	}

	/**
	 * Get the max width of the pdf, limiting it to the parent width
	 */
	public maxPdfTemplateWidth(parent : HTMLElement) : number {
		// take the possible parent padding out
		const parentComputedStyle = window.getComputedStyle(parent, null);
		const parentXPadding = Number.parseFloat(parentComputedStyle.paddingLeft) + Number.parseFloat(parentComputedStyle.paddingRight);
		return Math.min(parent.offsetWidth - parentXPadding, 300);
	}

	/**
	 * Know if the input is a string or a template
	 */
	public get inputDescriptionTemplate() : TemplateRef<unknown> | null {
		if ( this.inputDescription instanceof TemplateRef<unknown> )
			return this.inputDescription;
		else return null;
	}

	/**
	 * Know if the input is a string or a template
	 */
	public get pdfDescriptionTemplate() : TemplateRef<unknown> | null {
		if ( this.pdfDescription instanceof TemplateRef<unknown> )
			return this.pdfDescription;
		else return null;
	}

	public enums = enumsObject;
	public PBtnThemeEnum = PBtnThemeEnum;

	private modalServiceOptions : ModalServiceOptions & {
		success ?: (input : Event) => void;
		dismiss ?: (keyEvent ?: ModalDismissParam) => void;
	} = {
			size: enumsObject.BootstrapSize.LG,
		};

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {

		if (this.saveChangesHook !== undefined) throw new Error('Not implemented yet');
		if (this.onSaveStart.observers.length) throw new Error('Not implemented yet');
		if (this.onSaveSuccess.observers.length) throw new Error('Not implemented yet');
		if (this.onDismiss.observers.length) throw new Error('Not implemented yet');
		if (this.onLeaveCurrent.observers.length) throw new Error('Not implemented yet');
		if (this.editMode.observers.length) throw new Error('Not implemented yet');
		if (this.checkTouched !== null) throw new Error('Not implemented yet');

		// TODO: [PLANO-53381]
		if (this.cannotSetHint) throw new Error('cannotSetHint not implemented yet in this component. See PLANO-53381');

		if (!this.control) throw new Error('Currently it is not possible to use pdf-upload without [formControl]. Please make sure the formControl has the right validators.');

		this.pdfSrc = this.value;

		this.initModalServiceOptions();

		return super.ngAfterContentInit();
	}

	private initModalServiceOptions() : void {
		this.modalServiceOptions = {
			success: (event : Event) => {
				if (this.pEditable && this.api) void this.editableDirective.endEditable(event);
				if (this.pEditable && this.api) this.pdfSrc = this.control!.value;
				assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
				this.fileInput.nativeElement.value = '';
			},
			dismiss: (modalDismissParam : ModalDismissParam) => {
				if (this.pEditable && this.api && modalDismissParam instanceof Event) void this.editableDirective.endEditable(modalDismissParam, true);
				if (this.previousValue) {
					this.value = this.previousValue; this.previousValue = null;
				}
				assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
				this.fileInput.nativeElement.value = '';
			},
			size: enumsObject.BootstrapSize.LG,
			animation: false,
		};
	}

	public _disabled : boolean = false;

	private _value : ValueType | null = null;
	public override _onChange : (value : ValueType | null) => void = () => {};
	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void { this._onChange((event.target as HTMLInputElement).value); this.keyup.emit(event); }
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public blur = new EventEmitter<FocusEvent>();

	/** Get blur event from inside this component, and pass it on. */
	public onBlur(event : FocusEvent) : void {
		this.onTouched(event);
		this.blur.emit(event);
	}

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<Event> = new EventEmitter<Event>();

	/** Get change event from inside this component, and pass it on. */
	public onChange(event : Event) : void {
		this._onChange((event.target as HTMLInputElement).value);
		this.change.emit(event);
	}

	/** onTouched */

	public onTouched = (_event : Event) : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (this._value === value) return;

		this._value = value;
		this.changeDetectorRef.markForCheck();

		// TODO: Still necessary? p-input don’t has this
		if (this.control) {
			this.control.markAsTouched();
			this.control.markAsDirty();
			this.control.updateValueAndValidity();
		}
		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		this._value = value ? value : '';
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled !== isDisabled) this.changeDetectorRef.markForCheck();
		this.disabled = isDisabled;
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		assumeNonNull(this.control);
		return this.pFormsService.visibleErrors(this.control);
	}

	/**
	 * Localize the file to match the current language, in case no file was uploaded yet.
	 */
	public get templatePdf() : string {
		const defaultFile = this.localizeFilePipe.transform('static/gift_card_example.pdf');
		const defaultLink = `${Config.FRONTEND_URL_LOCALIZED }/${defaultFile}`;

		return this.pdfSrc ?? defaultLink;

	}

	private previousValue : string | null = null;

	/**
	 * Set some date for the cropper and open the cropper.
	 */
	public fileChangeEvent(event ?: Event) : void {
		if ((!event || !(event.target as HTMLInputElement).files!.length)) return;
		const reader = new FileReader();

		reader.addEventListener('load', (eventLoad : ProgressEvent<FileReader>) => {
			if (this.control && eventLoad.target) {
				this.editableDirective.startEditable(event);
				this.previousValue = this.value;
				this.control.setValue(eventLoad.target.result);
				if (this.modalTemplate) {
					void this.modalService.openModal(this.modalContent.template, this.modalServiceOptions).result.then(value => {
						if (value.modalResult === 'success') {
							this.modalServiceOptions.success?.(value.value);
						} else {
							this.modalServiceOptions.dismiss?.(value.value);
						}
					});
				}
			}
		});

		reader.readAsDataURL((event.target as HTMLInputElement).files![0]);
	}

	/**
	 * Open modal to edit the pdf
	 */
	public openEditModal(event : Event) : void {
		if (this.pdfSrc) {
			this.editableDirective.startEditable(event);
			void this.modalService.openModal(this.modalContent.template, this.modalServiceOptions).result.then(value => {
				if (value.modalResult === 'success') {
					this.modalServiceOptions.success?.(value.value);
				} else {
					this.modalServiceOptions.dismiss?.(value.value);
				}
			});
		}
	}

	/**
	 * Remove the current uploaded pdf
	 */
	public async removePdf(event : Event) : Promise<void> {
		this.editableDirective.startEditable(event);
		const result = await this.modalService.openDefaultModal({
			modalTitle: this.localizePipe.transform('Sicher?'),
			description: this.localizePipe.transform('Willst du die aktuelle Gutschein-Vorlage wirklich löschen?'),
			closeBtnLabel: this.localizePipe.transform('Ja'),
			dismissBtnLabel: this.localizePipe.transform('Abbrechen'),
			hideDismissBtn: false,
		}, {
			animation: false,
			centered: true,
			size: enumsObject.BootstrapSize.SM,
			theme: enumsObject.PThemeEnum.DANGER,
		}).result;
		if (result.modalResult === 'success') {
			if (this.control) {
				this.control.setValue(null);
			}
			this.value = null;
			this.pdfSrc = null;
			assumeDefinedToGetStrictNullChecksRunning(this.fileInput, 'fileInput');
			this.fileInput.nativeElement.value = '';
		}
		await this.editableDirective.endEditable(event);
	}
}
