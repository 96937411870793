import { PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ClientCurrency, PSupportedCountryCodes, PSupportedCurrencyCodes, Percent } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { TranslatePipe } from '@plano/shared/core/pipe/translate.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';

/**
 * Type for defining strings for each limit of the scale (if any)
 */
export class ScalePriceDescriptionStrings {
	constructor(
		public hasBothLimits : PDictionarySourceString,
		public noLowerLimit : PDictionarySourceString,
		public noUpperLimit : PDictionarySourceString,
	) {

	}
}

/**
 * Type for prices with different scales and limits
 */
export class ScalePrice {
	constructor(
		/**
		* Inclusive lower limit for the scale value.
		*/
		public lowerLimit : number | null,

		/**
		* Inclusive upper limit for the scale value.
		*/
		public upperLimit : number | null,

		/**
		* The price for this scale.
		*/
		public price : number,

		public descriptionStrings : ScalePriceDescriptionStrings,
	) {
	}
}

/**
 * Service that holds all the different prices for Dr. Plano
 */
@Injectable( { providedIn: 'root' } )
export class PPricesService {
	constructor(
		private localize : LocalizePipe,
		private currency : PCurrencyPipe,
		private percent : PercentPipe,
		private translatePipe : TranslatePipe,
		private console : LogService,
	) {
	}

	/**
	 * Is adyen supported in the current selected country?
	 * @returns Boolean, or null if no country is selected yet.
	 */
	public get currencyIsSupportedForOnlinePayment() : boolean | null {
		switch (Config.CURRENCY_CODE) {
			case null:
				return null;
			case PSupportedCurrencyCodes.EUR:
			case PSupportedCurrencyCodes.CHF:
			case PSupportedCurrencyCodes.RON:
				return true;
			case PSupportedCurrencyCodes.GBP:
			case PSupportedCurrencyCodes.SEK:
			case PSupportedCurrencyCodes.CZK:
				return false;
		}
	}

	private get onlinePaymentOrRefundFixedAmountValue() : ClientCurrency {
		const currencyCode = Config.CURRENCY_CODE;
		assumeNonNull(currencyCode);

		switch (currencyCode) {
			case PSupportedCurrencyCodes.EUR:
				return 0.35;
			case PSupportedCurrencyCodes.CHF:
				return 0.39;
			case PSupportedCurrencyCodes.RON:
				return 1.99;
			case PSupportedCurrencyCodes.GBP:
			case PSupportedCurrencyCodes.SEK:
			case PSupportedCurrencyCodes.CZK:
				throw new Error('Online-payment is not supported');
		}
	}

	private get onlinePaymentOrRefundPercentValue() : Percent {
		const currencyCode = Config.CURRENCY_CODE;
		assumeNonNull(currencyCode);

		switch (currencyCode) {
			case PSupportedCurrencyCodes.EUR:
				return 0.0239;
			case PSupportedCurrencyCodes.CHF:
				return 0.0369;
			case PSupportedCurrencyCodes.RON:
				return 0.0299;
			case PSupportedCurrencyCodes.GBP:
			case PSupportedCurrencyCodes.SEK:
			case PSupportedCurrencyCodes.CZK:
				throw new Error('Online-payment is not supported');
		}
	}

	/**
	 * Get fee for online payments or refunds by customers.
	 */
	public calcOnlinePaymentOrRefundFee(paymentAmount : ClientCurrency) : ClientCurrency {
		return paymentAmount * this.onlinePaymentOrRefundPercentValue + this.onlinePaymentOrRefundFixedAmountValue;
	}

	/**
	 * Get fixed fee for online payments or refunds.
	 */
	public get onlinePaymentOrRefundFixedAmount() : string {
		if (!this.currencyIsSupportedForOnlinePayment) {
			this.console.error('Can not be calculated for an unsupported currency');
			return '–';
		}
		return this.currency.transform(this.onlinePaymentOrRefundFixedAmountValue, Config.CURRENCY_CODE, undefined, '1.0-2')!;
	}

	/**
	 * Get percent for payments by customers
	 */
	public get onlinePaymentOrRefundPercent() : string {
		if (!this.currencyIsSupportedForOnlinePayment) {
			this.console.error('Can not be calculated for an unsupported currency');
			return '–';
		}
		return this.percent.transform(this.onlinePaymentOrRefundPercentValue, '1.0-4')!;
	}

	/**
	 * Online payments via Dr. Plano's payment page. The percentage fee applies to the gross payment amount.
	 */
	public get onlinePaymentOrRefund() : string {
		return `${this.onlinePaymentOrRefundPercent} + ${this.onlinePaymentOrRefundFixedAmount}`;
	}

	/**
	 * The fees for a online-payment payout or top-up.
	 */
	public get payoutOrTopUp() : string {
		const currencyCode = Config.CURRENCY_CODE;
		assumeNonNull(currencyCode);

		let fee : ClientCurrency;

		switch (currencyCode) {
			case PSupportedCurrencyCodes.EUR:
				fee = 0.5;
				break;
			case PSupportedCurrencyCodes.CHF:
				fee = 1.99;
				break;
			case PSupportedCurrencyCodes.RON:
				fee = 9.99;
				break;
			case PSupportedCurrencyCodes.GBP:
			case PSupportedCurrencyCodes.SEK:
			case PSupportedCurrencyCodes.CZK:
				throw new Error('Online-payment is not supported');
		}

		return this.currency.transform(fee, Config.CURRENCY_CODE, undefined, '1.0-2');
	}

	/**
	 * Fees for first or second chargeback.
	 */
	public get firstOrSecondChargeback() : string {
		const currencyCode = Config.CURRENCY_CODE;
		assumeNonNull(currencyCode);

		let fee : ClientCurrency;

		switch (currencyCode) {
			case PSupportedCurrencyCodes.EUR:
				fee = 10;
				break;
			case PSupportedCurrencyCodes.CHF:
				fee = 14.99;
				break;
			case PSupportedCurrencyCodes.RON:
				fee = 79.99;
				break;
			case PSupportedCurrencyCodes.GBP:
			case PSupportedCurrencyCodes.SEK:
			case PSupportedCurrencyCodes.CZK:
				throw new Error('Online-payment is not supported');
		}

		return `${this.currency.transform(fee, Config.CURRENCY_CODE, undefined, '1.0-2')} + ${this.localize.transform('ggf. Zusatzgebühren')}`;
	}

	/**
	 * This fee applies when an amount charged back due to a payment dispute is (temporarily) returned
	 * to the merchant's online payment account.
	 */
	// eslint-disable-next-line sonarjs/no-identical-functions
	public get chargebackReversed() : string {
		const currencyCode = Config.CURRENCY_CODE;
		assumeNonNull(currencyCode);

		let fee : ClientCurrency;

		switch (currencyCode) {
			case PSupportedCurrencyCodes.EUR:
				fee = 0.5;
				break;
			case PSupportedCurrencyCodes.CHF:
				fee = 1.99;
				break;
			case PSupportedCurrencyCodes.RON:
				fee = 9.99;
				break;
			case PSupportedCurrencyCodes.GBP:
			case PSupportedCurrencyCodes.SEK:
			case PSupportedCurrencyCodes.CZK:
				throw new Error('Online-payment is not supported');
		}

		return this.currency.transform(fee, Config.CURRENCY_CODE, undefined, '1.0-2');
	}

	/**
	 * A one-time fee for creating an online payment account with Adyen, our online payment partner.
	 * The fee will be charged with the first following invoice.
	 */
	public get adyenOnboarding() : string {
		return this.currency.transform(15, 'EUR', undefined, '1.0-2')!;
	}

	/**
	 * A one-time service fee for reactivation of a closed account. The fee will be charged with the first following invoice.
	 */
	public get adyenAccountReactivation() : string {
		return this.currency.transform(49, 'EUR', undefined, '1.0-2')!;
	}

	/**
	 * Get fixed amount for the gift-cards
	 */
	public get giftCardFixedAmount() : string {
		return this.currency.transform(0.19, 'EUR', undefined, '1.0-2')!;
	}

	/**
	 * Get the percentage of the gift-cards as a number
	 */
	public get giftCardPercentValue() : number {
		return 0.0119;
	}

	/**
	 * Get percent for gift-cards
	 */
	public get giftCardPercent() : string {
		return this.percent.transform(this.giftCardPercentValue, '1.0-4')!;
	}

	/**
	 * Price for gift-cards
	 */
	public get giftCard() : string {
		return `${this.giftCardPercent} + ${this.giftCardFixedAmount}`;
	}

	private bookingSalesPricesDescriptionStrings = new ScalePriceDescriptionStrings(
		'${lowerLimit} - ${upperLimit} €',
		'bis ${upperLimit} €',
		'ab ${lowerLimit} €',
	);

	public bookingSalesPrices : ScalePrice[] = [
		new ScalePrice(null, 5000, 0.0119, this.bookingSalesPricesDescriptionStrings),
		new ScalePrice(5001, 10000, 0.0109, this.bookingSalesPricesDescriptionStrings),
		new ScalePrice(10001, 20000, 0.0099, this.bookingSalesPricesDescriptionStrings),
		new ScalePrice(20001, 30000, 0.0089, this.bookingSalesPricesDescriptionStrings),
		new ScalePrice(30001, 40000, 0.0079, this.bookingSalesPricesDescriptionStrings),
		new ScalePrice(40001, 80000, 0.0075, this.bookingSalesPricesDescriptionStrings),
		new ScalePrice(80001, null, 0.0069, this.bookingSalesPricesDescriptionStrings),
	];

	private participantPricesDescriptionStrings = new ScalePriceDescriptionStrings(
		'${lowerLimit} - ${upperLimit}',
		'bis ${upperLimit}',
		'ab ${lowerLimit}',
	);

	public participantPrices : ScalePrice[] = [
		new ScalePrice(null, 250, 0.19, this.participantPricesDescriptionStrings),
		new ScalePrice(251, 500, 0.17, this.participantPricesDescriptionStrings),
		new ScalePrice(501, 1000, 0.14, this.participantPricesDescriptionStrings),
		new ScalePrice(1001, 1500, 0.11, this.participantPricesDescriptionStrings),
		new ScalePrice(1501, 2000, 0.1, this.participantPricesDescriptionStrings),
		new ScalePrice(2001, 2500, 0.09, this.participantPricesDescriptionStrings),
		new ScalePrice(2501, 3000, 0.08, this.participantPricesDescriptionStrings),
		new ScalePrice(3001, null, 0.07, this.participantPricesDescriptionStrings),
	];

	private personnelPlanningDescriptionStrings = new ScalePriceDescriptionStrings(
		'${lowerLimit} - ${upperLimit} User',
		'bis ${upperLimit} User',
		'ab ${lowerLimit} User',
	);

	public personnelPlanningPrices : ScalePrice[] = [
		new ScalePrice(null, 10, 29, this.personnelPlanningDescriptionStrings),
		new ScalePrice(11, 20, 49, this.personnelPlanningDescriptionStrings),
		new ScalePrice(21, 30, 69, this.personnelPlanningDescriptionStrings),
		new ScalePrice(31, 40, 89, this.personnelPlanningDescriptionStrings),
		new ScalePrice(41, 50, 109, this.personnelPlanningDescriptionStrings),
		new ScalePrice(51, 60, 129, this.personnelPlanningDescriptionStrings),
		new ScalePrice(61, 70, 149, this.personnelPlanningDescriptionStrings),
		new ScalePrice(71, 80, 169, this.personnelPlanningDescriptionStrings),
		new ScalePrice(81, 90, 189, this.personnelPlanningDescriptionStrings),
		new ScalePrice(91, 100, 209, this.personnelPlanningDescriptionStrings),
		new ScalePrice(101, 110, 229, this.personnelPlanningDescriptionStrings),
		new ScalePrice(111, 120, 249, this.personnelPlanningDescriptionStrings),
		new ScalePrice(121, 130, 269, this.personnelPlanningDescriptionStrings),
		new ScalePrice(131, 140, 289, this.personnelPlanningDescriptionStrings),
		new ScalePrice(141, 150, 309, this.personnelPlanningDescriptionStrings),
		new ScalePrice(151, 160, 329, this.personnelPlanningDescriptionStrings),
		new ScalePrice(161, 170, 349, this.personnelPlanningDescriptionStrings),
		new ScalePrice(171, 180, 369, this.personnelPlanningDescriptionStrings),
	];

	/**
	 * Label for the trigger button of the country switch in the pricing section.
	 */
	public get pricePerCountryLabel() : string | null {
		const countryCode = Config.getCountryCode();
		switch (countryCode) {
			case PSupportedCountryCodes.CH:
				return 'Preise für Betriebe in der Schweiz';
			case PSupportedCountryCodes.GB:
				return 'Pricing for companies in the United Kingdom';
			case null:
				// German/Euro is the default for our pricing section
				return this.localize.transform('Wähle deine länderspezifischen Preise');
			case PSupportedCountryCodes.AT:
			case PSupportedCountryCodes.BE:
			case PSupportedCountryCodes.CZ:
			case PSupportedCountryCodes.DE:
			case PSupportedCountryCodes.IT:
			case PSupportedCountryCodes.RO:
			case PSupportedCountryCodes.LU:
			case PSupportedCountryCodes.NL:
			case PSupportedCountryCodes.SE:
				return this.localize.transform({
					sourceString: 'Preise für Betriebe in ${country}',
					params: {
						country: this.translatePipe.transform(countryCode),
					},
				});
		}
	}
}
