/* NOTE: Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-lines: ["error", 1300] -- This is a complex component. Thus it’s ok to have a exception here.  */
import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, NgZone, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalDismissReasons, NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { NgbFormatsService } from '@plano/client/service/ngbformats.service';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PEditableModalButtonComponent } from '@plano/client/shared/p-editable/p-editable-modal-button/p-editable-modal-button.component';
import { PMoment, PMomentService } from '@plano/client/shared/p-moment.service';
import { PSimpleChanges } from '@plano/shared/api';
import { DateTime, PApiPrimitiveTypes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalDismissParam } from '@plano/shared/core/p-modal/modal.service.options';
import { LocalizePipe, PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { AngularDatePipeFormat, DateFormats, PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidationErrors, PValidatorObject } from '@plano/shared/core/validators.types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { PInputService } from '@plano/shared/p-forms/p-input/p-input.service';
import * as $ from 'jquery';
import { PInputDateService } from './p-input-date.service';

type ValueType = DateTime;
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PInputDateTypes {

	/**
	 * @deprecated Do not set this from outside anymore.
	 * It is obsolete, since we check min and max from validators of attributeInfo.
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	birth = 'birth',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	deadline = 'deadline',
}

/**
 * We use this as the 'real' NgbDateStruct as the type information in the ngb plugin is weak
 */
export type PNgbDateStruct = NgbDateStruct | '-';

/**
 * A component like datepicker, but a timestamp (type is DateTime) can be bound to it instead of any ngb formats
 */
@Component({
	selector: 'p-input-date',
	templateUrl: './p-input-date.component.html',
	styleUrls: ['./p-input-date.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputDateComponent),
			multi: true,
		},
	],
})
export class PInputDateComponent extends ControlWithEditableDirective
	implements PComponentInterface, AfterContentInit, AfterContentChecked, OnDestroy, EditableControlInterface,
	ControlValueAccessor, PFormControlComponentInterface, OnChanges {

	@ViewChild('datepickerRef') private datepickerRef : NgbDatepicker | null = null;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] | null = null;

	/**
	 * Should the daysBefore input be shown instead of the usual button to open a calendar?
	 */
	@Input() public showDaysBeforeInput : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('class') private _class : string = '';
	@HostBinding('class') private get _classes() : string {
		if (!this.btnStyles) return this._class;
		return this._class.replace(this.btnStyles, '');
	}

	@HostBinding('class.no-overflow-wrap') private _alwaysTrue = true;

	/**
	 * Days before as number
	 * This is NOT a timestamp
	 */
	@Input() public daysBefore : number | null = null;

	/** Has the value of the daysBefore input changed? */
	@Output() public daysBeforeChange : EventEmitter<number | null> = new EventEmitter<number | null>();

	/** Has the value of inner input changed? */
	@Output() public innerInputChanged : EventEmitter<string | null> = new EventEmitter<string | null>();

	/** When modal has been closed */
	@Output() private onModalClose = new EventEmitter<Event>();

	@ViewChild('pEditableModalButtonRef') public pEditableModalButtonRef : PEditableModalButtonComponent | null = null;

	@ViewChild('inputRef') private inputRef ?: ElementRef<HTMLInputElement>;

	/**
	 * Size of the trigger button.
	 * TODO: PLANO-169284 remove wrong type
	 */
	@Input() public size ?: PFormControlComponentInterface['size'];

	/**
	 * maximum date of this input in timestamp format
	 * null means no limit
	 */
	@Input('max') private set max(input : number | null) {
		if (this._max !== input && this.formGroup && this.daysBefore) {
			this._max = input;

			// daysBefore is calculated based on max. So if max changes, daysBefore needs to be recalculated.
			if (this.type === PInputDateTypes.deadline) {
				this.daysBefore = this.timestampToDaysBefore(this.value);
				this.daysBeforeChange.emit(this.daysBefore);
			}

			// date is calculated by 'daysBefore' and 'max'. If one of them change, the calculated must be repeated.
			this.control?.updateValueAndValidity();
		} else {
			this._max = input;
		}
	}
	private get max() : number | null {
		if (this._max !== null) return this._max;

		const MAX_COMPARED_CONST = this.control?.validatorObjects.max?.comparedConst as number | (() => number) | undefined ?? null;
		if (MAX_COMPARED_CONST !== null) {
			if (typeof MAX_COMPARED_CONST === 'function') return MAX_COMPARED_CONST();
			return MAX_COMPARED_CONST;
		}

		return null;
	}

	/**
	 * Set this to {@link PInputDateTypes.deadline} if you want the selected date to be handled as exclusive end.
	 * @example
	 * 	User decides a Vacation-Entry should go till 29.12.2020. The stored end date will be the millisecond 0
	 * 	of the date 30.12.2020 instead of 29.12.2020
	 * 	Note that this boolean has no effect if showTimeInput is true.
	 */
	@Input() protected type : PInputDateTypes.deadline | null = null;

	/**
	 * Should the »remove/erase value« button be visible?
	 * Setting this to false, overwrites the logics of {@link supportsUnset}
	 */
	@Input('showEraseValueBtn') private _showEraseValueBtn : boolean | null = null;

	/**
	 * Is it possible to unset this value (will show a button that sets the value to null, if there is a value)
	 *
	 * If this is false, the button will never be shown, and the value of the date what is bound to the calendar will
	 *  be required.
	 * If this is true, the button will be shown if possible/makes sense. It e.g. does not make sense to show the
	 *  button, when there was no value set before.
	 *
	 * If this is null, the component will let internal logic {@link showEraseValueBtn} decide if the button should
	 *  be shown. And the value of the date what is bound to the calendar will be required.
	 */
	@Input() private supportsUnset : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() protected showNowButton : boolean | null = null;

	/**
	 * Default date to select when the calendar is first rendered.
	 * Is like {@link startDate}, but contains a timestamp.
	 */
	@Input() private defaultDate : DateTime | null = null;

	/**
	 * Should the timestamp include the exact time? If not it will only represent a date.
	 */
	@Input() public showTimeInput : boolean | null = null;

	/**
	 * The modal with the datepicker includes a input for time. This will get a own validator. Should the time be required?
	 */
	@Input() private timeIsRequired : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('placeholder') private _placeholder : string | null = null;

	/**
	 * A label for the input-area or button.
	 * It will be shown instead of the formatted date, if label is set.
	 */
	@Input() public label : string | null = null;

	// NOTE: Use class="btn-*" instead of [theme]="'"
	// @Input() private theme : enumsObject.PThemeEnum;

	/**
	 * A range like 'week' can be set. If its set, the user can only select a whole week, and not a single date.
	 */
	@Input() public range : CalendarModes = CalendarModes.DAY;

	/**
	 * Minimum date of this input in timestamp format.
	 * In case of a deadline type of input, this needs to be exclusive.
	 */
	@Input('min') public _min ?: PInputDateComponent['min'];

	/** @see PInputDateComponent#_min */
	public get min() : number | null {
		if (this._min !== undefined) {
			if (this._min === null) return null;

			if (

				// In case this is not a deadline, we dont need to add 1 day to make the min value exclusive.
				this.type !== PInputDateTypes.deadline ||

				// In case the time should be considered here, we assume the provided min value includes the time.
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
				this.showTimeInput ||

				// In case this is bound to a attributeInfo, the ai-switch will already take care that the min value is exclusive.
				this.attributeInfo
			) return this._min;

			// This is a deadline input, so we need to turn the provided min value into an exclusive one.
			return this.pMomentService.m(this._min).add(1, 'day').valueOf();
		}

		// TODO: Remove this, when all datepickers are bound to attributeInfo
		const MIN_COMPARED_CONST = this.control?.validatorObjects.min?.comparedConst as number | (() => number) | undefined ?? null;
		if (MIN_COMPARED_CONST !== null) {
			if (typeof MIN_COMPARED_CONST === 'function') return MIN_COMPARED_CONST();
			return MIN_COMPARED_CONST;
		}

		// TODO: Remove this, when all datepickers are bound to attributeInfo
		if (this.type === PInputDateTypes.birth as PInputDateTypes.deadline) { // HACK: PLANO-151942 We dont want developers to set PInputDateTypes.birth from outside anymore.
			return +(new PMomentService(this.locale).m()).subtract(120, 'years');
		}

		return null;
	}

	/**
	 * You can suggest a time. Like the planned time of a shift for the date-input of the time-stamp
	 */
	@Input() private suggestionTimestamp : number | null = null;

	/**
	 * Label of the button that is shown if a suggested timestamp is provided
	 */
	@Input() protected suggestionLabel : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('locale') private _locale : PSupportedLocaleIds | null = null;

	@Input('disabled') public override set disabled(input : boolean) {
		this.setDisabledState(input);
		this._disabled = input;
		super.disabled = input;
	}

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to set this if you want to use [(ngModel)] AND [formControl] together.
	 */
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}

	public override get canSet() : boolean | undefined {
		// If min is higher than max, its not possible to choose a valid date.
		if (this.limitationsHaveNoOverlap) return false;
		return super.canSet;
	}

	public override get cannotSetHint() : PDictionarySource | null {
		if (this.limitationsHaveNoOverlap && !this.showDaysBeforeInput) {
			const min = this.min! + (this.type === PInputDateTypes.deadline ? -1 : 0);
			const max = this.max! + (this.type === PInputDateTypes.deadline ? -1 : 0);
			return {
				sourceString: 'Es kann kein Datum ausgewählt werden, da bei der aktuellen Konstellation das späteste zulässige Datum – <mark>${max}</mark> – vor dem frühesten Datum liegt, das zulässig ist: <mark>${min}</mark>.',
				params: {
					min: this.datePipe.transform(min, this.showTimeInput ? AngularDatePipeFormat.MEDIUM_TIME : AngularDatePipeFormat.MEDIUM_DATE),
					max: this.datePipe.transform(max, this.showTimeInput ? AngularDatePipeFormat.MEDIUM_TIME : AngularDatePipeFormat.MEDIUM_DATE),
				},
			};
		}
		return super.cannotSetHint;
	}

	private get limitationsHaveNoOverlap() : boolean {
		if (this.max === null) return false;
		if (this.min === null) return false;
		return this.max < this.min;
	}

	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public readMode : PFormControlComponentInterface['readMode'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private daysBeforeLabel : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() protected eraseValueBtnLabel : string | null = null;

	@Input('icon') private icon : PlanoFaIconPoolValues | null = null;

	/**
	 * Should the close button of the modal be disabled?
	 * Setting this to true or false overwrites the internal logic for the decision if the close button should be disabled.
	 */
	@Input('closeBtnDisabled') private _closeBtnDisabled : boolean | null = null;

	/**
	 * Should the days before input be disabled?
	 * If this is not set, the disabled state of the control will be used.
	 */
	@Input() public daysBeforeInputDisabled : boolean | null = null;

	/** @see PEditableModalButtonComponent#modalTitle */
	@Input() public modalTitle : string | null = null;

	constructor(
		public modalService : ModalService,
		private zone : NgZone,
		protected override pFormsService : PFormsService,
		private validators : ValidatorsService,
		private ngbFormatsService : NgbFormatsService,
		private localize : LocalizePipe,
		private datePipe : PDatePipe,
		protected override changeDetectorRef : ChangeDetectorRef,
		private pInputDateService : PInputDateService,
		private pInputService : PInputService,
		protected override console : LogService,
		private pMomentService : PMomentService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	protected readonly CONFIG = Config;
	protected readonly enums = enumsObject;
	protected PApiPrimitiveTypes = PApiPrimitiveTypes;
	protected PPossibleErrorNames = PPossibleErrorNames;

	private _max : PInputDateComponent['max'] = null;

	public ngOnChanges(changes : PSimpleChanges<PInputDateComponent>) : void {
		if (changes.canSetInput && typeof changes.canSetInput.currentValue === 'boolean')
			this.disabled = !changes.canSetInput.currentValue;
		if (changes.control) {
			this.addComponentValidatorsToFormControl();
		}
	}

	private addComponentValidatorsToFormControl() : void {
		if (!this.control) return;
		this.control.componentValidators = () => {
			return this.validateInternalValue();
		};
	}

	private minValidator(control : AbstractControl) : PValidationErrors | null {
		if (!this.showDaysBeforeInput) return null;
		if (!control.value) return null;
		if (!this.min) return null;

		// The daysBefore value combined with the max value results to a deadline
		const CALCULATED_DEADLINE = this.daysBeforeToTimestamp(control.value);

		// This happens if the value can not be turned into a number
		if (CALCULATED_DEADLINE === null) return null;

		if (CALCULATED_DEADLINE >= this.min) return null;
		const minText = this.timestampToDaysBefore(this.min);
		return { [PPossibleErrorNames.MIN] : {
			name: PPossibleErrorNames.MIN,
			primitiveType: PApiPrimitiveTypes.Days,
			actual: control.value,
			errorText: minText === 0 ? 'Es bleibt nicht genügend Zeit, um eine Frist zu setzen.' : 'Setze eine Frist von <strong>höchstens »${min}«</strong> ${daysText}.',
			min: minText,
			daysText: this.localize.transform(minText === 1 ? 'Tag' : 'Tagen'),
		} };
	}

	private maxValidator(control : AbstractControl) : PValidationErrors | null {
		if (!this.showDaysBeforeInput) return null;
		if (!control.value) return null;
		if (!this.max) return null;

		// The daysBefore value combined with the max value results to a deadline
		const CALCULATED_DEADLINE = this.daysBeforeToTimestamp(control.value);

		// This happens if the value can not be turned into a number
		if (CALCULATED_DEADLINE === null) return null;

		const MAX = (this.max + (this.type === PInputDateTypes.deadline ? 1 : 0));
		if (CALCULATED_DEADLINE <= MAX) return null;
		const maxText = this.timestampToDaysBefore(this.max);
		return { [PPossibleErrorNames.MAX]: {
			name: PPossibleErrorNames.MAX,
			primitiveType: PApiPrimitiveTypes.Days,
			actual: control.value,
			errorText: +control.value === 0 ? 'Lasse das Feld leer, falls du keine Frist setzen möchtest.' : 'Setze eine Frist von <strong>mindestens »${max}«</strong> ${daysText}.',
			max: maxText,
			daysText: this.localize.transform(maxText === 1 ? 'Tag' : 'Tagen'),
		} };
	}

	private validateInternalValue() : PValidationErrors | null {
		if (!this.inputRef) return null;
		const CONTROL = { value : this.inputRef.nativeElement.value } as unknown as AbstractControl;
		if (this.type === PInputDateTypes.deadline) {
			const numberError = this.validators.number(PApiPrimitiveTypes.Integer).fn(CONTROL);
			if (numberError) return numberError;
			const integerError = this.pInputService.integer(null, this.locale)(CONTROL);
			if (integerError) return integerError;
			const minError = this.minValidator(CONTROL);
			if (minError) return minError;
			const maxError = this.maxValidator(CONTROL);
			if (maxError) return maxError;
			const inputValue = this.inputRef.nativeElement.value;
			const inputValueAsTimestamp = this.daysBeforeToTimestamp(inputValue);
			const controlValue = this.control!.value;
			if (this.max && !(inputValue === '' && controlValue === null) && inputValueAsTimestamp !== controlValue) {
				this.control!.setValue(inputValueAsTimestamp);
			}
			return null;

		} else return null;
	}

	/** @see PInputDateComponent#min */
	protected get ngbMinDate() : PNgbDateStruct {
		if (this.min === null) return '-';

		// If limitation timestamps have no overlap, and min and max gets set to ngb-datepicker,
		// then ngb-datepicker throws. To avoid that, we mark our trigger button as disabled,
		// and don’t pass min/max to ngb-datepicker.
		if (this.limitationsHaveNoOverlap) return '-';

		const min = this.min + (this.type === PInputDateTypes.deadline ? -1 : 0);
		return this.ngbFormatsService.timestampToDateStruct(min, this.locale);
	}

	/** @see PInputDateComponent#max */
	protected get ngbMaxDate() : PNgbDateStruct {
		if (this.max === null) return '-';
		const max = this.max + (this.type === PInputDateTypes.deadline ? -1 : 0);
		return this.ngbFormatsService.timestampToDateStruct(max, this.locale);
	}

	/**
	 * Get the locale. Either from [locale]="…" when this component gets used, or from the global config.
	 */
	private get locale() : PSupportedLocaleIds {
		return this._locale ?? Config.LOCALE_ID;
	}

	/**
	 * Highlight some days based on e.g. the defined range.
	 * Means if the range is e.g. 'week', then
	 * all days of the week will be highlighted instead of only the day that represents the current timestamp value.
	 *
	 * @param input The day that should be highlighted/the highlighting calculations should be based on.
	 */
	protected isHighlighted(input : NgbDate) : boolean {
		if (this.range !== CalendarModes.DAY) {
			const timestamp = this.ngbFormatsService.dateTimeObjectToTimestamp(input, this.locale);
			const selectedTimestamp = this.selectedDateTime;
			return new PMomentService(this.locale).m(timestamp).isSame(selectedTimestamp, this.range);
		}
		return input.equals(this.ngbDate === '-' ? undefined : this.ngbDate);
	}

	private now ! : DateTime;

	private ngbDate : PNgbDateStruct | null = null;
	protected formGroup : FormGroup<{
		'time' : PFormControl<number>,
		'date' : PFormControl<PNgbDateStruct>,
	}> | null = null;

	/**
	 * The date to open calendar with.
	 * @see NgbDatepicker#startDate
	 */
	protected startDate : PNgbDateStruct | null = null;

	/**
	 * Should the suggestion button be clickable?
	 */
	protected get suggestionBtnIsDisabled() : boolean {
		if (this.suggestionTimestamp === null) return true;
		if (this.min && this.suggestionTimestamp < this.min) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	protected get btnStyles() : string | undefined {
		if (!this._class) return undefined;
		const stylesArray = this._class.match(/btn-(?:outline-\w+|\w+)/g);
		if (!stylesArray) return undefined;
		return stylesArray.join(' ');
	}

	/**
	 * Should the erase button be visible?
	 */
	public get showEraseValueBtn() : boolean {
		if (this._showEraseValueBtn === false) return false;

		if (this.supportsUnset === false) return false;
		if (this.initialValue === null) return false;
		if (this.pEditableModalButtonRef?.pEditable) return !this.isFormControlRequired;
		return true;
	}

	/**
	 * Calculates the difference between the maximum date and the given value in days.
	 */
	public timestampToDaysBefore(timestamp : DateTime | null) : number | null {
		if (!this.max) return null;
		if (!timestamp || +timestamp <= 0) return null;
		const N = this.type === PInputDateTypes.deadline ? this.max + 1 : this.max;
		const DURATION = N - +timestamp;
		let days : number = (new PMomentService(this.locale)).duration(DURATION).asDays();
		days = this.type === PInputDateTypes.deadline ? days + 1 : days;
		return Math.floor(days);
	}

	/**
	 * Turn a given "days before" value into a timestamp
	 */
	private daysBeforeToTimestamp(value : number | string | undefined) : DateTime | null {
		if (!this.max) return null;
		if (value === undefined) return null;
		value = this.pInputService.turnLocaleNumberIntoNumber(this.locale, `${value}`);
		if (value === '' || Number.isNaN(+value)) return null;
		let days : number = +value;
		days = this.type === PInputDateTypes.deadline ? days - 1 : days;
		const daysAsTimestamp = +(new PMomentService(this.locale)).duration(days, 'days');
		const MAX_LIMIT = this.type === PInputDateTypes.deadline ? this.max + 1 : this.max;
		let result = MAX_LIMIT - daysAsTimestamp;
		result = +(new PMomentService(this.locale)).m(result).startOf('day');
		return result;
	}

	/**
	 * Initialize the form group for this component.
	 * This form group gets used for internal bindings and holding internal values of this component.
	 * This formGroup and its controls are not directly related to the bound [formControl]="…".
	 */
	// eslint-disable-next-line sonarjs/cognitive-complexity -- NOTE: This will be solved automatically when we switch to more p-ai-switch’es
	private initFormGroup() : void {
		if (this.formGroup) { this.formGroup = null; }

		const tempFormGroup = this.pFormsService.group({});

		this.pFormsService.addPControl(
			tempFormGroup,
			'date',
			{
				formState: {
					value : this.ngbDate,
					disabled: false,
				},
				validatorObjects: [
					new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
						if (control.value !== '-') return null;

						// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
						if ((this.supportsUnset || this._showEraseValueBtn) && control.value === '-') return null;

						/*
					 * If the outer control (the one that is bound to this component) is not required,
					 * we dont want to show the required error for the internal date control.
					 * The internal date control is required to be able to provide a new value to the outer
					 * control, but the validation hint can be confused with the validation hint of the outer
					 * control.
					 *
					 * There is only one case left, where we still want to mark the internal 'date' control as required:
					 * If the user has provided some 'time' data, but has not yet selected a date, the value can not calculated.
					 * In this case we want to show the required error on the date control.
					 */
						const userHasTypedInATime = this.showTimeInput && this.formGroup?.controls['time']?.value !== null;
						if (!this.hasRequiredError && !userHasTypedInATime) return null;

						const emptyControl = new PFormControl({
							formState: {
								value : undefined,
								disabled: true,
							},
						});
						return this.validators.required(PApiPrimitiveTypes.string).fn(emptyControl);
					}}),
				],
				subscribe: (value : PNgbDateStruct | null) => {
					if (this.showTimeInput) {
						const timeControl = tempFormGroup.controls['time'];
						timeControl.markAsTouched();
						timeControl.updateValueAndValidity();
					} else {
						if (!(this.type === PInputDateTypes.deadline && this.showDaysBeforeInput)) {
							this.pEditableModalButtonRef?.modalRef?.close();
						}
						this.setControlValue();
					}

					this.ngbDate = value ?? '-';
				},
			},
		);

		this.pFormsService.addPControl(
			tempFormGroup,
			'time',
			{
				formState: {
					value : this.valueToTimeMilliseconds,
					disabled: false,
				},
				validatorObjects: [
					new PValidatorObject({name: PPossibleErrorNames.REQUIRED, fn: (control) => {
						if (!this.showTimeInput) return null;
						if (this.timeIsRequired === false) return null;
						if (!this.hasRequiredError && this.formGroup?.controls['date'].value === '-') return null;
						return this.validators.required(PApiPrimitiveTypes.LocalTime).fn(control);
					}}),
				],
				subscribe: () => {
					const dateControl = tempFormGroup.controls['date'];
					dateControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
					dateControl.markAsTouched();
					const timeControl = tempFormGroup.controls['time'];
					if (timeControl.valid) this.setControlValue();
				},
			},

		);

		this.formGroup = tempFormGroup;
	}

	/**
	 * Update the date that is selected in ngb-datepicker.
	 */
	private updateSelectedDateByNgbDate() : void {
		/*
		 * There is a bug in ngb-datepicker that causes the datepicker to not update some internal values.
		 * At least the value of model.selectedDate.
		 * Often we don’t see any effets from this, because the ngb-datepicker component seems to be OnPush.
		 * But if that internal change detection gets triggered, it sets our value to the old value again - a old
		 * value that is not stored in any of our attributes or controls anymore. So as a workaround we have to
		 * make sure that there internal value gets updated accordingly.
		 */
		if (this.datepickerRef) {
			if (this.ngbDate === '-' || this.ngbDate === null) {
				this.datepickerRef.model.selectedDate = null;
			} else {
				this.datepickerRef.model.selectedDate = new NgbDate(this.ngbDate.year, this.ngbDate.month, this.ngbDate.day);
			}
		}

		this.formGroup?.controls['date'].setValue(this.ngbDate, { emitEvent : false });
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initValues();
		this.initFormGroup();

		if (this.eraseValueBtnLabel === null) {
			this.showEraseValueBtnIcon = true;
			this.eraseValueBtnLabel = this.localize.transform('Eingabe löschen');
		}
		return super.ngAfterContentInit();
	}

	public override ngAfterContentChecked() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		if (this.suggestionTimestamp !== null && !this.suggestionLabel) throw new Error('suggestionLabel must be defined if suggestionTimestamp is provided');
		return super.ngAfterContentChecked();
	}

	protected showEraseValueBtnIcon : boolean = false;

	private determineType() : void {
		if (this.type !== null) throw new Error('No need to determine type when it is already set');
		if (this.icon === enumsObject.PlanoFaIconPool.BIRTHDAY) {
			this.type = PInputDateTypes.birth as PInputDateTypes.deadline; // HACK: PLANO-151942 We dont want developers to set PInputDateTypes.birth from outside anymore.
		}
		if (this.min !== null && +(new PMomentService(this.locale).m().subtract(80, 'years')) > this.min) {
			this.type = PInputDateTypes.birth as PInputDateTypes.deadline; // HACK: PLANO-151942 We dont want developers to set PInputDateTypes.birth from outside anymore.
		}
	}

	private initStartDateForBirthInput() : void {
		// HACK: Don’t know why but sometimes startDate was 1.1.1970
		// We had this again. We checkt requests and we proofed that new cse does not has anything to do with a
		// birth day in 1970.
		// Conclusion:
		//   - It’s completely a frontend thing
		//   - It does not lead to wrong api-requests
		//   - it has NOTHING TO DO with people that where born on 1.1.1970.
		const VALUE_YEAR = new PMomentService(this.locale).m(this.value).get('year');
		const SOMETHING_IS_BROKEN = (
			this.startDate && this.startDate !== '-' && this.startDate.year === 1970 && VALUE_YEAR !== 1970
		);
		if (SOMETHING_IS_BROKEN && !Config.DEBUG) {
			// this never happened in at last a year
			this.console.error('startDate is defined but 1970. Not sure if this is intended');
		}

		if (this.startDate === null || SOMETHING_IS_BROKEN) {
			if (this.ngbDate !== null && this.ngbDate !== '-') {
				this.startDate = this.ngbDate;
			} else {
				const EIGHTEEN_YEARS_AGO = +(new PMomentService(this.locale).m().subtract(18, 'years'));
				const INITIAL_START_DATE = this.ngbFormatsService.timestampToDateStruct(EIGHTEEN_YEARS_AGO, this.locale);
				this.startDate = INITIAL_START_DATE;
			}
		}
	}

	/** Define what date should be pre-selected in the calendar */
	private initStartDate() : void {
		if (this.defaultDate) {
			// If a default value is set, it will overwrite every other possible startDate
			this.startDate = this.ngbFormatsService.timestampToDateStruct(this.defaultDate, this.locale);
		} else {
			if (this.type === PInputDateTypes.birth as PInputDateTypes.deadline) {
				this.initStartDateForBirthInput();
			} else {
				if (this.ngbDate === '-' && this.startDate === null) return;
				this.startDate = this.ngbDate;
			}
		}
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		this.refreshNow();
		this.refreshNgbDateFromControlValue();
		this.updateSelectedDateByNgbDate();
		if (this.type === null) this.determineType();
		this.initStartDate();
		switch (this.type) {
		// TODO: Remove this, when all datepickers are bound to attributeInfo
			case PInputDateTypes.birth as PInputDateTypes.deadline : // HACK: PLANO-151942 We dont want developers to set PInputDateTypes.birth from outside anymore.
				if (!this.max) this.max = +(new PMomentService(this.locale).m());
				if (this.showNowButton === null) this.showNowButton = false;
				break;
			case PInputDateTypes.deadline :
			default :
				if (this.showNowButton === null) this.showNowButton = true;
		}
		if (this.showTimeInput === null) this.showTimeInput = false;
		if (this.daysBefore === null && this.type === PInputDateTypes.deadline) {
			this.daysBefore = this.timestampToDaysBefore(this.control?.value);
		}
	}

	private refreshNgbDateFromControlValue() : void {
		if (!this.value) {
			this.ngbDate = '-';
			return;
		}
		const valueToCalculateNewNgbDate = this.type === PInputDateTypes.deadline && !this.showTimeInput ? this.value - 1 : this.value;
		this.ngbDate = this.value ? this.ngbFormatsService.timestampToDateStruct(valueToCalculateNewNgbDate, this.locale) : '-';
	}

	/**
	 * Timestamp to 'DD.MM.YYYY[ | HH:mm]'
	 */
	protected get formattedDateTime() : string | null {
		if (!this.value) return '';
		let resultTimestamp : DateTime = this.value;
		if (this.type === PInputDateTypes.deadline && !this.showTimeInput) resultTimestamp = resultTimestamp - 1;

		// eslint-disable-next-line @typescript-eslint/naming-convention
		let DATE_FORMAT : DateFormats;
		if (this.showDaysBeforeInput && this.daysBefore !== null) return `${this.daysBefore} ${this.daysBeforeLabel}`;
		if (this.range === CalendarModes.MONTH) {
			DATE_FORMAT = 'MM.YY';
		} else {
			DATE_FORMAT = Config.IS_MOBILE ? 'veryShortDate' : 'shortDate';
		}

		let result : string | null = this.datePipe.transform(resultTimestamp, DATE_FORMAT, undefined, this.locale);
		if (this.showTimeInput) result += ` | ${this.datePipe.transform(resultTimestamp, 'shortTime', undefined, this.locale)}`;
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	protected get placeholder() : string {
		if (this._placeholder !== null) return this._placeholder;
		let result = this.localize.transform('--.--.----', false, false);
		if (this.showTimeInput) {
			result += ' | ';
			result += this.localize.transform('--:--');
		}
		return result;
	}

	private addExclusiveMillisecondIfNecessary(input : number) : number {
		if (this.type !== PInputDateTypes.deadline) return input;
		if (this.showTimeInput) return input;
		return +(new PMomentService(this.locale)).m(input).add(1, 'day').startOf('day');
	}

	/**
	 * Handle the change on the input of daysBefore
	 */
	public handleInputChange(inputValue : string) : void {
		this.innerInputChanged.emit(inputValue);
		if (inputValue === '')
			this.control!.setValue(null);
		else if (this.validateInternalValue() === null)
			this.control!.setValue(this.daysBeforeToTimestamp(inputValue));
		else this.control!.markAsTouched();
	}

	/**
	 * Set new value to the bound model/control (which also updates the validators).
	 */
	private setControlValue() : void {
		if (!this.formGroup) return;

		this.formGroup.updateValueAndValidity({ onlySelf : true, emitEvent : false});

		if (this.formGroup.invalid && this.type !== PInputDateTypes.deadline) {
			this.value = null;
			return;
		}
		const selectedDateTime = this.selectedDateTime;
		if (!selectedDateTime && this.type !== PInputDateTypes.deadline) {
			this.value = null;
			return;
		} else if (!selectedDateTime) return;

		const valueHasChanged = selectedDateTime !== this.value;
		this.value = this.addExclusiveMillisecondIfNecessary(selectedDateTime);
		if (this.control) this.control.markAsTouched();

		/** No footer that can be clicked. So selecting a day should trigger the save pEditable. */
		if (!this.showTimeInput && this.pEditable && this.isValid) {
			void this.api!.save();
			if (valueHasChanged) this.animateSuccessButton();
		}
	}

	/**
	 * Get a timestamp of the selected time only (without the milliseconds of the selected date)
	 */
	private get valueToTimeMilliseconds() : number | null {
		if (!this.showTimeInput) return null;
		return this.valueToTimeTimestamp(this.value);
	}

	/**
	 * Remove the date milliseconds from a timestamp and leave the time-related milliseconds
	 */
	private valueToTimeTimestamp(input : number | null) : number | null {
		if (input === null) return null;
		const TIME_AS_STRING = (new PMomentService(this.locale)).m(input).format('HH:mm');
		return (new PMomentService(this.locale)).d(TIME_AS_STRING).asMilliseconds();
	}

	/**
	 * Get a timestamp that involves both date and time combined
	 */
	private get selectedDateTime() : DateTime | null {
		assumeNonNull(this.formGroup);

		const time = this.formGroup.controls['time'].value;
		return this.pInputDateService.convertNgbDateAndNgbTimeToTimestamp(
			this.locale,
			this.formGroup.controls['date'].value,
			time,
			this.showTimeInput,
		);
	}

	/**
	 * Set date [and time] to now
	 */
	protected onClickNow() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		this.formGroup.controls['date'].setValue( this.ngbFormatsService.timestampToDateStruct(this.now, this.locale) );
		this.formGroup.controls['time'].setValue( this.valueToTimeTimestamp(this.now) );
		this.datepickerRef!.navigateTo(this.formGroup.controls['date']!.value === '-' ? undefined : (this.formGroup.controls['date']!.value ?? undefined));
	}

	/**
	 * Set date [and time] to now
	 */
	protected onClickSuggestion() : void {
		if (this.suggestionBtnIsDisabled) return;
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		if (this.suggestionTimestamp === null) throw new Error('button should not have been visible, if suggestionTimestamp is not provided');
		if (this.min && this.suggestionTimestamp < this.min) throw new Error('suggestion is before min. Button should not have been clickable');
		this.formGroup.controls['date'].setValue( this.ngbFormatsService.timestampToDateStruct(this.suggestionTimestamp, this.locale) );
		this.formGroup.controls['time'].setValue( this.valueToTimeTimestamp(this.suggestionTimestamp) );
		this.datepickerRef!.navigateTo(this.formGroup.controls['date']!.value === '-' ? undefined : (this.formGroup.controls['date']!.value ?? undefined));
	}

	/**
	 * Remove all input data and remove data from PFormControl
	 * @param event The event that triggered the unset button
	 */
	protected unsetData(event : MouseEvent) : void {
		this.value = null;
		this.refreshNgbDateFromControlValue();

		this.ngbDate = '-';
		this.updateSelectedDateByNgbDate();

		this.animateSuccessButton();
		this.pEditableModalButtonRef?.modalRef?.close(event);
	}

	/**
	 * Reset the value to the initial value.
	 * This includes updating all internal values.
	 */
	private resetToInitialValue() : void {
		this.value = this.initialValue;
		this.refreshNgbDateFromControlValue();
		assumeNonNull(this.formGroup, 'formGroup');
		if (this.showTimeInput) this.formGroup.controls['time'].setValue(this.valueToTimeMilliseconds);
		this.updateSelectedDateByNgbDate();
	}

	/**
	 * Check if user input is the current time (plus/minus a tolerance)
	 */
	protected get isCurrentDateTime() : boolean {
		let result : boolean = false;

		// Tolerance in minutes
		const TOLERANCE : number = 10;

		const formattedDateTime : PMoment.Moment | null = this.selectedDateTime ? (new PMomentService(this.locale)).m(this.selectedDateTime) : null;
		if (formattedDateTime === null) return false;

		if (this.showTimeInput) {
			result = formattedDateTime.isBetween(
				(new PMomentService(this.locale)).m().subtract(TOLERANCE, 'minutes'),
				(new PMomentService(this.locale)).m().add(TOLERANCE, 'minutes'),
			);
		} else {
			result = formattedDateTime.isSame((new PMomentService(this.locale)).m(this.locale), 'day');
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	protected get nowButtonIsDisabled() : boolean {
		let now : DateTime | null = null;
		if (this.showTimeInput) {
			now = this.now;
		} else {
			if (this.type === PInputDateTypes.deadline) {
				now = +(new PMomentService(this.locale)).m(this.now).add(1, 'day').startOf('day');
			} else {
				now = +(new PMomentService(this.locale)).m(this.now).startOf('day');
			}
		}
		const underMin = !!this.min && now < this.min;
		const overMax = !!this.max && now > this.max;
		return underMin || overMax;
	}

	/**
	 * Wait for animation
	 */
	private waitForAnimation(callback : () => void) : void {
		this.zone.runOutsideAngular(() => {
			window.setTimeout(() => {
				this.zone.run(() => {
					callback();
				});
			}, 600);
		});
	}

	protected valueHasChangedSuccessfully : boolean = false;
	private animateSuccessButton() : void {
		this.valueHasChangedSuccessfully = true;
		this.waitForAnimation(() => {
			this.valueHasChangedSuccessfully = false;
		});
	}

	/**
	 * Stores the initial value.
	 * This is necessary so we can reset the inner formGroup it if the user dismisses or closes with an invalid value.
	 */
	private initialValue : DateTime | null = null;

	/**
	 * When the modal opens, make sure the values are initialized and up-to-date
	 */
	public onModalOpen() : void {
		this.initialValue = this.value;
		this.refreshNow();
		this.setFocusToDatepickerPlugin();
	}

	private refreshNow() : void {
		this.now = +(new PMomentService(this.locale).m());
	}

	/**
	 * When the modal gets dismissed, the values and the focus needs to be reset
	 * @param modalDismissParam The event that triggered the modal to dismiss or the reason why the modal got dismissed
	 */
	protected onModalDismiss(modalDismissParam : ModalDismissParam) : void {
		switch (modalDismissParam) {
			case ModalDismissReasons.BACKDROP_CLICK: // This happens then user clicks outside to close the modal.
			case ModalDismissReasons.ESC: // This happens when user hits escape key to close the modal.
			case undefined: // This happens when user has modal open, and navigates via browser-nav-buttons
				break;
			default:
				if (modalDismissParam.target) {
					const target = modalDismissParam.target as HTMLElement | undefined;
					target?.blur();
				} else {
					this.console.error(`Value »${event}« of event is unexpected.`);
				}
				break;
		}
		this.resetToInitialValue();

		if ($('.modal.show').length) {
			$('.modal.show').trigger('focus');
		}

		// Refresh the values inside the formGroup
		if (this.showTimeInput) this.formGroup?.controls['time']?.setValue(this.valueToTimeMilliseconds);

		// Refresh the value inside the date control if its different from the value in the ngbDate
		if (this.ngbDate !== this.formGroup?.controls['date'].value) {
			this.formGroup?.controls['date']?.setValue(this.ngbDate, { emitEvent : false, onlySelf : true });
		}

		this.control?.markAsTouched();
	}

	/**
	 * Reset some values
	 */
	protected onModalClosed(event : Event) : void {
		this.refreshNgbDateFromControlValue();
		this.formGroup?.controls['date']?.setValue(this.ngbDate, { emitEvent : false });
		this.formGroup?.controls['time']?.setValue(this.valueToTimeMilliseconds, { emitEvent : false });

		this.control?.markAsTouched();
		this.onModalClose.emit(event);
	}

	/**
	 * If there is another input like the time input, it will get the first focus.
	 * We want to have the datepicker focused first.
	 */
	private setFocusToDatepickerPlugin() : void {
		// If there is no time input, we don't need to do anything
		if (!this.showTimeInput) return;

		// This is needed to make sure the modal is rendered before we try to focus the datepicker
		// Sadly there is no way to know the moment when the modal is ready.
		window.requestAnimationFrame(() => {
			const datepicker = document.querySelectorAll('ngb-datepicker')[0];
			const dayBtns = datepicker.querySelectorAll<HTMLElement>(`.ngb-dp-day`);

			const firstFocussableElement = Array.from(dayBtns).find(item => item.tabIndex === 0);

			if (!firstFocussableElement) {
				this.console.error('firstFocussableElement is not ready yet');
				return;
			}

			firstFocussableElement.focus();

			this.formGroup?.controls['time'].markAsUntouched();
		});
	}

	public _disabled : boolean = false;

	private _value : ValueType | null = null;
	public override _onChange : (value : ValueType | null) => void = () => {};

	// @Output() public change : EventEmitter<Event> = new EventEmitter<Event>();
	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.changeDetectorRef.markForCheck();
		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;

		if (this.type === PInputDateTypes.deadline) {
			this.daysBefore = this.timestampToDaysBefore(value);
			this.daysBeforeChange.emit(this.daysBefore);
		}

		if (this.inputRef) {
			const inputElementValue = this.inputRef.nativeElement.value;
			if (this.daysBeforeToTimestamp(inputElementValue) !== this.control!.value) {
				this.inputRef.nativeElement.value = value ? `${this.timestampToDaysBefore(value)}` : '';
			}
		}

		// Refresh the internal values based on the bound model that has changed.
		this.initValues();

		// If the value (or the bound formControl) has changed, we need to update all internal controls.
		this.initFormGroup();

		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Set the function to be called
	 * when the control receives a change event.
	 */
	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this._disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			// make sure the formControl value is up-to-date with the AI value
			if (!this.disabled && this.attributeInfo) this.refreshValue();
			if (this.disabled) {
				this.control.disable();
			} else {
				this.control.enable();
			}
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	protected get daysBeforeAppendText() : string {
		if (this.daysBeforeLabel !== null) return this.daysBeforeLabel;
		if (!this.showDaysBeforeInput) return this.localize.transform('Datum');
		assumeDefinedToGetStrictNullChecksRunning(this.formGroup, 'formGroup');
		const daysBeforeValue = this.control?.value === null ? null : +this.control?.value;
		const dayText = daysBeforeValue === 1 ? this.localize.transform('Tag') : this.localize.transform('Tage');
		return this.localize.transform({
			sourceString: '${dayText} vor Schicht',
			params: { dayText: dayText },
		});
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.pEditableModalButtonRef?.modalRef?.dismiss();
		return super.ngOnDestroy();
	}

	/** Filter all errors that should be shown in the ui. */
	protected visibleErrors(formControl : AbstractControl) : VisibleErrorsType {
		return this.pFormsService.visibleErrors(formControl);
	}

	/** Icon to le left of the input */
	protected get prependIcon() : PlanoFaIconPoolValues {
		if (this.icon) return this.icon;

		// TODO: Remove this, when all datepickers are bound to attributeInfo
		if (this.type === (PInputDateTypes.birth as PInputDateTypes.deadline)) return enumsObject.PlanoFaIconPool.BIRTHDAY; // HACK: PLANO-151942 We dont want developers to set PInputDateTypes.birth from outside anymore.
		return this.value ? enumsObject.PlanoFaIconPool.STATE_DATE_PICKED : enumsObject.PlanoFaIconPool.STATE_DATE_EMPTY;
	}

	public override get isValid() : boolean {
		const dateInput = this.formGroup?.get('date');
		if (dateInput?.invalid === true) return false;

		// If the validations of, e.g. the format of the users time input, is wrong, the input should be marked as invalid.
		const timeInput = this.formGroup?.get('time');
		if (timeInput?.invalid === true) return false;

		return super.isValid;
	}

	/**
	 * Open a Modal like info-circle does it when in IS_MOBILE mode.
	 */
	protected openCannotSetHint() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.cannotSetHint, 'this.cannotSetHint');
		this.modalService.openCannotSetHintModal(this.cannotSetHint);
	}

	/** Should the suggestion button be visible? */
	protected get showSuggestionButton() : boolean {
		return !!this.suggestionTimestamp && !this.suggestionBtnIsDisabled;
	}

	/**
	 * Decide which theme the suggestion button should have.
	 */
	protected get suggestionButtonTheme() : typeof enumsObject.PThemeEnum.PRIMARY | PBtnThemeEnum.OUTLINE_SECONDARY {
		if (this.selectedDateTime === this.suggestionTimestamp) return enumsObject.PThemeEnum.PRIMARY;
		return PBtnThemeEnum.OUTLINE_SECONDARY;
	}

	public PBtnThemeEnum = PBtnThemeEnum;

	/** Should the close btn be disabled? */
	protected get closeBtnDisabled() : boolean {
		if (this._closeBtnDisabled !== null) return this._closeBtnDisabled;
		return !this.isValid;
	}
}
