<div class="flex-grow-1 d-flex flex-column">
	<div class="flex-grow-0 d-flex pl-3 pr-3 pt-3 pb-2">
		<div
			class="flex-grow-1 d-flex gap-2"
		>
			<p-button
				[buttonType]="PButtonType.TOGGLE"
				[icon]="null"
				[isActiveButton]="!!editFilterModeActive"
				[darkMode]="true"
				[size]="enums.BootstrapSize.SM"
				(triggerClick)="editFilterModeActive=!editFilterModeActive"

				btnTitle="Tätigkeiten filtern" i18n-btnTitle
				[pTooltip]="!editFilterModeActive ? (filterIsActive ? itemsFilterTitlePopover : 'Filter') : null"
				[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
			><span class="nowrap"><fa-icon
				[class.text-white]="!editFilterModeActive"
				[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.FILTER"
			></fa-icon><span *ngIf="editFilterModeActive || filterIsActive">&nbsp;<p-led [theme]="enums.PThemeEnum.PRIMARY" [off]="!filterIsActive"></p-led></span></span></p-button>
			<ng-template #itemsFilterTitlePopover>
				<span class="p-2" [innerHTML]="itemsFilterTitle! | pSafeHtml"></span>
			</ng-template>
			<p-button
				[buttonType]="PButtonType.TOGGLE"
				[icon]="null"
				[isActiveButton]="!!editListItemsMode"
				[darkMode]="true"
				[size]="enums.BootstrapSize.SM"
				(triggerClick)="editListItemsMode=!editListItemsMode"

				[pTooltip]="editButtonTitle"
				[ariaLabel]="editButtonTitle"
				[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
			><fa-icon
				[class.text-white]="!editListItemsMode"
				[fixedWidth]="true" [icon]="rightsService.userCanWriteAnyShiftModel ? enums.PlanoFaIconPool.EDIT : enums.PThemeEnum.INFO"
			></fa-icon></p-button>
			<a
				[routerLink]="!!isLoading ? null : showDetails(null)"
				[disabled]="!!isLoading"
				class="ml-auto">
				<p-button
					*ngIf="showAddButton"
					[darkMode]="true"
					[theme]="PBtnThemeEnum.OUTLINE_PRIMARY"
					[size]="enums.BootstrapSize.SM"
					pTooltip="Tätigkeit hinzufügen" i18n-pTooltip
					ariaLabel="Tätigkeit hinzufügen" i18n-ariaLabel
					[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
				><fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon></p-button>
			</a>
		</div>
	</div>
	<div class="flex-grow-1 position-relative">
		<scroll-shadow-box [backgroundStyle]="enums.PThemeEnum.DARK">
			<div
				[class.pb-tawk]="config.IS_MOBILE"
				class="pl-3 pr-3 pb-lg-2"
			>
				<p-input
					class="mt-1 mb-3"
					[type]="PApiPrimitiveTypes.Search"
					[(ngModel)]="searchTerm"
					[theme]="!!searchTerm ? null : PBtnThemeEnum.OUTLINE_DARK"
					[class.flex-grow-1]="searchIsActive"
					[size]="enums.BootstrapSize.SM"
				></p-input>

				<div
					*ngIf="editFilterModeActive && itemsFilterTitle"
					id="filter-shiftmodels"
					class="crop-on-overflow my-3"
					aria-labelledby="shiftmodels-hidden-filter-label"
				>
					<span><fa-icon
						[class.text-primary]="filterIsActive"
						[class.text-white]="!filterIsActive"
						[style.opacity]="filterIsActive ? '1' : '0.2'"
						[fixedWidth]="true"
						[icon]="enums.PlanoFaIconPool.FILTER"
					></fa-icon>&nbsp;<span class="text-white" [innerHTML]="itemsFilterTitle | pSafeHtml"></span></span>
				</div>

				<div class="list-group list-group-flush"
					*ngIf="!isLoading; else spinnerTemplate"
				>
					<div *ngIf="noItemsAvailable" class="card p-3">
						<span i18n>Noch keine Tätigkeiten vorhanden.</span>
						<span *ngIf="me.isLoaded() && me.data.isOwner"><br><span i18n>Lege hier die Tätigkeiten und buchbaren Angebote deines Betriebs an – bspw. Frühschicht oder Kindergeburtstag. Diese dienen dann als Vorlagen für die Erstellung neuer Schichten / Termine im Kalender.</span><br/><span i18n>Klick auf’s <fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon> um Tätigkeiten anzulegen.</span></span>
					</div>

					<ng-template
						[ngIf]="!showAllItemsFilteredHint"
						[ngIfElse]="everyItemFilteredTemplate"
					>
						<ng-template
							ngFor let-shiftModelsForList
							[ngForOf]="groupedShiftModelsForList"
						>
							<list-headline
								class="card mb-1 mt-2 o-hidden"
								*ngIf="showHeadline(shiftModelsForList)"
								headlineIconAlign="left"
								[class.muted-item]="highlightService.isMuted(shiftModelsForList)"
								[class.trashed]="shiftModelsForList.hasUntrashedItem"
								[text]="translatedListHeadlineText(shiftModelsForList)"
								(onClick)="toggleList(shiftModelsForList.get(0)!.parentName)"
							>
								<fa-icon headline-icon [icon]="!isCollapsed(shiftModelsForList.get(0)!.parentName) ? enums.PlanoFaIconPool.COLLAPSIBLE_OPEN : enums.PlanoFaIconPool.COLLAPSIBLE_CLOSE"></fa-icon>
								<!-- linthtml-configure tag-req-attr="false" -->
								<button
									*ngIf="editFilterModeActive"
									(click)="onClickFilterParent($event, shiftModelsForList)"
									[class.affected]="!isVisibleItems(shiftModelsForList) && !!filterService.someShiftModelsAreVisible(shiftModelsForList)"
									class="card-option btn btn-sm pt-1 pb-1 d-flex align-items-center justify-content-center btn-secondary"
								><fa-icon [class.text-primary]="!(isVisibleItems(shiftModelsForList) || filterService.someShiftModelsAreVisible(shiftModelsForList))" [icon]="isVisibleItems(shiftModelsForList) || filterService.someShiftModelsAreVisible(shiftModelsForList) ? enums.PlanoFaIconPool.VISIBLE : enums.PlanoFaIconPool.INVISIBLE"></fa-icon></button>
							</list-headline>
							<ng-container *ngIf="!isCollapsed(shiftModelsForList.get(0)!.parentName)">
								<ng-template
									ngFor let-shiftModel
									[ngForOf]="getShiftModelsSorted(shiftModelsForList)"
								>
									<shiftmodel-list-item
										*ngIf="!shiftModel.trashed && (editFilterModeActive || isVisibleItem(shiftModel))"
										class="mb-1"
										[shiftModel]="shiftModel"
										(onItemClick)="handleShiftModelClicked($event)"
										(onSelectInCalendarClick)="onSelectRelatedShifts.emit(shiftModel)"
										[editFilterModeActive]="!!editFilterModeActive"
										[editListItemsMode]="!!editListItemsMode"
										[hideMultiSelectBtn]="hideMultiSelectBtn"
									></shiftmodel-list-item>
								</ng-template>
							</ng-container>
						</ng-template>
					</ng-template>

					<ng-template #everyItemFilteredTemplate>
						<ng-template
							[ngTemplateOutlet]="everythingFilteredTemplate"
							[ngTemplateOutletContext]="{$implicit: shiftModels}"
						></ng-template>
					</ng-template>
				</div>

				<ng-template [ngIf]="showSomeItemsFilteredHint">
					<p-alert
						class="mt-2"
						[theme]="enums.PThemeEnum.SECONDARY"
						[dismissable]="false"
					>
						<div class="mb-2" i18n>Übrigens… {{filterService.hiddenItemsCount(searchedShiftModels)}} {filterService.hiddenItemsCount(searchedShiftModels), plural, =1 {weiteres Suchergebnis ist} other {weitere Suchergebnisse sind}} durch deine Filter-Einstellung nicht sichtbar.</div>
						<p-button
							(triggerClick)="editFilterModeActive=!editFilterModeActive"
							[theme]="enums.PThemeEnum.PRIMARY"
							[darkMode]="true"
							i18n
						>Filter anzeigen</p-button>
					</p-alert>
				</ng-template>
			</div>
		</scroll-shadow-box>
	</div>
</div>

<ng-template #everythingFilteredTemplate let-items>
	<p-alert
		class="mt-2"
		[theme]="enums.PThemeEnum.SECONDARY"
		[dismissable]="false"
	>
		<div class="mb-2">
			<span i18n>Aktuell sind alle <mark>{{filterService.hiddenItemsCount(items)}}</mark> Tätigkeiten mit Hilfe der Filter-Funktion ausgeblendet.</span>
		</div>
		<p-button
			(triggerClick)="editFilterModeActive=!editFilterModeActive"
			[theme]="enums.PThemeEnum.PRIMARY"
			[darkMode]="true"
			i18n
		>Filter anzeigen</p-button>
	</p-alert>
</ng-template>
<ng-template #spinnerTemplate>
	<div class="card m-0">
		<p-spinner [size]="enums.BootstrapSize.LG" class="m-3"></p-spinner>
	</div>
</ng-template>
