import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SLIDE_HORIZONTAL_ON_NGIF_TRIGGER } from '@plano/animations';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { SchedulingApiService, SchedulingApiShift, SchedulingApiShiftMemberPrefValue, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PPushNotificationsService, PRequestWebPushNotificationPermissionContext } from '@plano/shared/core/p-push-notifications.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-wish-picker[api]',
	templateUrl: './wish-picker.component.html',
	styleUrls: ['./wish-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,

	// host: {
	// 	'(dragover)': 'onDragOver()'
	// },
	animations: [
		SLIDE_HORIZONTAL_ON_NGIF_TRIGGER,
	],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class WishPickerComponent {
	@HostBinding('class.d-flex') protected _alwaysTrue = true;

	public memberPrefValues = {
		CAN: SchedulingApiShiftMemberPrefValue.CAN,
		CANNOT: SchedulingApiShiftMemberPrefValue.CANNOT,
		WANT: SchedulingApiShiftMemberPrefValue.WANT,
	};

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift : SchedulingApiShift | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts : SchedulingApiShifts | null = null;
	@Input() public api ! : SchedulingApiService;

	public collapsed : boolean = false;
	public readonly CONFIG = Config;

	constructor(
		private schedulingService : SchedulingService,
		private filterService : FilterService,
		private pPushNotificationsService : PPushNotificationsService,
		private localize : LocalizePipe,
	) {
	}

	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showPickerButtons() : boolean {
		return this.api.isLoaded() && (!!this.shift || !!this.shifts);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onUncollapse(event : Event) : void {
		// this.collapsed = false;
		// this.onCollapseChanges.emit(this.collapsed);

		event.preventDefault();
		event.stopPropagation();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get pickerButtonsDisabled() : boolean {
		if (!this.shift && !this.shifts?.length) return true;
		return false;
	}

	/**
	 * Handle click on one of the whish buttons
	 */
	public handleOnPick(
		$event : Event,
		whish : SchedulingApiShiftMemberPrefValue | null = null,
	) : void {
		if (this.pickerButtonsDisabled) return;

		// this.collapsed = true;
		// this.onCollapseChanges.emit(this.collapsed);

		$event.preventDefault();
		$event.stopPropagation();

		if (this.shift) {
			this.shift.myPref = whish;
		} else if (this.shifts) {
			for (const shift of this.shifts.iterable()) {
				shift.myPref = whish;
			}
		}

		this.shifts!.setSelected(false);

		this.api.save();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get myPref() : SchedulingApiShiftMemberPrefValue | null {
		if (this.shift) return this.shift.myPref;
		if (this.shifts) return this.shifts.myPref ?? null;
		return null;
	}

	private askForNotificationPermissionIfNecessary() : void {
		this.pPushNotificationsService.requestWebPushNotificationPermission(
			PRequestWebPushNotificationPermissionContext.CLOSED_UI_WISH_PICKER_MODE,
		);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public stopMode() : void {
		if (this.api.isLoaded()) {
			this.api.data.shifts.setSelected(false);
		}
		if (Config.IS_MOBILE) {
			this.filterService.showOnlyWishPickerAssignmentProcesses(false);
		}
		this.schedulingService.wishPickerMode = false;
		this.askForNotificationPermissionIfNecessary();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get popoverForREMOVE() : string {
		if (this.pickerButtonsDisabled) return this.localize.transform('Bitte erst im Kalender Schichten selektieren.');
		return this.localize.transform('Schichtwunsch entfernen');
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get popoverForWANT() : string {
		if (this.pickerButtonsDisabled) return this.localize.transform('Bitte erst im Kalender Schichten selektieren.');
		return this.localize.transform('Ich möchte die Schicht!');
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get popoverForCAN() : string {
		if (this.pickerButtonsDisabled) return this.localize.transform('Bitte erst im Kalender Schichten selektieren.');
		return this.localize.transform('Ich nehme die Schicht, wenn es sein muss.');
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get popoverForCANNOT() : string {
		if (this.pickerButtonsDisabled) return this.localize.transform('Bitte erst im Kalender Schichten selektieren.');
		return this.localize.transform('Ich kann auf keinen Fall.');
	}
}
