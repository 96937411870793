import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SchedulingApiShiftExchanges } from '@plano/client/scheduling/shared/api/scheduling-api-shift-exchange.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { MeService, RightsService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { PCookieService } from '@plano/shared/core/p-cookie.service';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { LDDeskCardValue, PLaunchDarklyService } from '@plano/shared/launch-darkly/launch-darkly';

@Component({
	selector: 'p-sidebar-desk[shiftExchanges]',
	templateUrl: './sidebar-desk.component.html',
	styleUrls: ['./sidebar-desk.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PSidebarDeskComponent implements PComponentInterface, OnInit {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftExchanges ! : SchedulingApiShiftExchanges;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public navToShiftExchanges : EventEmitter<undefined> = new EventEmitter<undefined>();

	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column') private _alwaysTrue = true;

	constructor(
		public meService : MeService,
		private pMoment : PMomentService,
		public pShiftExchangeService : PShiftExchangeService,
		private pCookieService : PCookieService,
		private pLaunchDarklyService : PLaunchDarklyService,
		public domSanitizer : DomSanitizer,
		private rightsService : RightsService,
	) {
	}

	public now ! : number;

	public Config = Config;

	/**
	 * First name of the user.
	 */
	public get firstName() : string {
		return this.meService.data.firstName;
	}

	public enums = enumsObject;

	/** OnInit */
	public ngOnInit() : void {
		this.now = +this.pMoment.m().startOf('day');
	}

	/** Should the section be visible? */
	public get deskCardIsVisible() : boolean {
		// Latest card id not available yet?
		if (this.latestDeskCardId === null) return false;
		return this.pCookieService.get({
			prefix : 'LaunchDarkly',
			name : 'lastHiddenDeskCardId',
		}) !== this.latestDeskCardId && this.deskCardIsVisibleForUserGroup;
	}

	/** To which user group should the desk card be shown? */
	private get deskCardIsVisibleForUserGroup() : boolean {
		const content = this.launchDarklyValue('desk-card')?.content;

		if (content) {

			for (const contentForUserGroup of content) {
				if (this.contentVisibleForUserGroup(contentForUserGroup))
					return true;
			}
		}
		return false;
	}

	private contentVisibleForUserGroup(contentWithUserGroup : Exclude<LDDeskCardValue['content'], undefined>[number]) : boolean {
		const isAdminOrManager = this.meService.data.isOwner || this.rightsService.hasManagerRights;
		if (!contentWithUserGroup.userGroups) return true;
		if (isAdminOrManager) {
			return contentWithUserGroup.userGroups.includes('ADMINS_AND_MANAGERS');
		}
		return contentWithUserGroup.userGroups.includes('EMPLOYEES');
	}

	/** Get the content of a flag from LaunchDarkly */
	public launchDarklyValue(id : 'desk-card') : LDDeskCardValue | undefined {
		return this.pLaunchDarklyService.get(id);
	}

	private get latestDeskCardId() : string | null {
		const cardId = this.launchDarklyValue('desk-card')?.cardId;
		if (cardId === undefined || cardId === null) return null;
		return `${cardId}`;
	}

	/** Should the section be visible? */
	public hideSection(_id : 'desk-card') : void {
		// We can expect latestDeskCardId to be available if user was able to click a button that hides the section
		assumeNonNull(this.latestDeskCardId, 'this.latestDeskCardId', 'Card should not have been visible');
		return this.pCookieService.put({
			prefix : 'LaunchDarkly',
			name : 'lastHiddenDeskCardId',
		}, this.latestDeskCardId);
	}

	private _safeHtmlStr : SafeHtml | null = null;

	/** Get LS html as save html */
	public get safeHtmlStr() : SafeHtml | null {
		if (this._safeHtmlStr === null) {
			const unSaveHtml = this.launchDarklyValue('desk-card')?.content?.find(contentWithUserGroup => this.contentVisibleForUserGroup(contentWithUserGroup))![Config.getLanguageCode()];
			if (!unSaveHtml) return null;
			this._safeHtmlStr = this.domSanitizer.bypassSecurityTrustHtml(unSaveHtml);
		}
		return this._safeHtmlStr;
	}
}
