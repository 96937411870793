/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "admin/failed_adyen_events".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	FAILED_ADYEN_EVENT_TRIGGER_PROCESSING = 1;
	FAILED_ADYEN_EVENT_IS_PLATFORM_EVENT = 2;
	FAILED_ADYEN_EVENT_EVENT_JSON = 3;
	FAILED_ADYEN_EVENT_AUTO_RETRY_ENABLED = 4;
	FAILED_ADYEN_EVENT_DATE_TIME = 5;
	FAILED_ADYEN_EVENT_ERROR = 6;
}


@Injectable({
  providedIn: 'root',
})
export class FailedAdyenEventsApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'admin/failed_adyen_events');
	}

	protected version() : string {
		return 'c81a86888ea1a6c1c1b20040f9924ef9,3f6a19f9742cf3bfda094f4af9c8a055';
	}

	private dataWrapper = new FailedAdyenEventsApiRoot(this, false);

	get data() : FailedAdyenEventsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : FailedAdyenEventsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new FailedAdyenEventsApiRoot(this, false);
	}
}

		 export class FailedAdyenEventsApiRoot extends ApiListWrapper<FailedAdyenEventsApiFailedAdyenEvent>
{
	constructor(override readonly api : FailedAdyenEventsApiService | null,
		
		removeDestroyedItems : boolean = false
	) {
		super(api, null, removeDestroyedItems, '');
	}

	override wrapRawData(itemRawData : any) : FailedAdyenEventsApiFailedAdyenEvent {
		return new FailedAdyenEventsApiFailedAdyenEvent(this.api, this as unknown as FailedAdyenEventsApiRoot, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ApiDataWrapperBase | null, removeDestroyedItems : boolean) : this {
		return new FailedAdyenEventsApiRoot(this.api, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '1';
	}

	override createNewItem(_initCode : ((newItem : FailedAdyenEventsApiFailedAdyenEvent) => void) | null = null, _backendId : Id | null = null) : FailedAdyenEventsApiFailedAdyenEvent {
		const newItem = new FailedAdyenEventsApiFailedAdyenEvent(this.api, this as unknown as FailedAdyenEventsApiRoot, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<FailedAdyenEventsApiRoot, FailedAdyenEventsApiRoot> = new ApiAttributeInfo<FailedAdyenEventsApiRoot, FailedAdyenEventsApiRoot>({
			apiObjWrapper: this as any as FailedAdyenEventsApiRoot,
			name: '',
			nodeName: 'ROOT',
			primitiveType: PApiPrimitiveTypes.ApiList,
			hasRightToGet: function(this : FailedAdyenEventsApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			hasRightToSet: function(this : FailedAdyenEventsApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			defaultValue: function(this : FailedAdyenEventsApiRoot, _nodeId : string) {return Meta.createNewList();},
			
		});
}

				 
export class FailedAdyenEventsApiFailedAdyenEvent extends ApiObjectWrapper<FailedAdyenEventsApiFailedAdyenEvent>
{
	constructor(override readonly api : FailedAdyenEventsApiService | null,
		override readonly parent : FailedAdyenEventsApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<FailedAdyenEventsApiFailedAdyenEvent> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, FailedAdyenEventsApiFailedAdyenEvent as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, FailedAdyenEventsApiFailedAdyenEvent> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, FailedAdyenEventsApiFailedAdyenEvent>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'failedAdyenEvent',
			nodeName: 'FAILED_ADYEN_EVENT',
			defaultValue: function(this : FailedAdyenEventsApiFailedAdyenEvent, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoTriggerProcessing : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, boolean> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, boolean>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'triggerProcessing',
			nodeName: 'FAILED_ADYEN_EVENT_TRIGGER_PROCESSING',
			primitiveType: PApiPrimitiveTypes.boolean,
			validations: function(this : FailedAdyenEventsApiFailedAdyenEvent) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : FailedAdyenEventsApiFailedAdyenEvent) {
				return [
				];
			}
			,
			defaultValue: function(this : FailedAdyenEventsApiFailedAdyenEvent, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	attributeInfoIsPlatformEvent : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, boolean> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, boolean>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'isPlatformEvent',
			nodeName: 'FAILED_ADYEN_EVENT_IS_PLATFORM_EVENT',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : FailedAdyenEventsApiFailedAdyenEvent, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	attributeInfoEventJson : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, string> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, string>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'eventJson',
			nodeName: 'FAILED_ADYEN_EVENT_EVENT_JSON',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});
	attributeInfoAutoRetryEnabled : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, boolean> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, boolean>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'autoRetryEnabled',
			nodeName: 'FAILED_ADYEN_EVENT_AUTO_RETRY_ENABLED',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : FailedAdyenEventsApiFailedAdyenEvent, _nodeId : string) {return false;},
			rawDataIndex: 4,
		});
	attributeInfoDateTime : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, DateTime> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, DateTime>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'dateTime',
			nodeName: 'FAILED_ADYEN_EVENT_DATE_TIME',
			primitiveType: PApiPrimitiveTypes.DateTime,
			hasRightToSet: () => false,
			rawDataIndex: 5,
		});
	attributeInfoError : ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, string> = new ApiAttributeInfo<FailedAdyenEventsApiFailedAdyenEvent, string>({
			apiObjWrapper: this as any as FailedAdyenEventsApiFailedAdyenEvent,
			name: 'error',
			nodeName: 'FAILED_ADYEN_EVENT_ERROR',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToSet: () => false,
			rawDataIndex: 6,
		});

	/**
     *  Set this to "true" to process the event now. In case the event could be handled without problems then it will automatically be deleted.
	 *
	 * @type {boolean}
     */
	get triggerProcessing() : boolean {
		this.getterDebugValidations(this.attributeInfoTriggerProcessing, false);
		return this.data[1];
	}

	set triggerProcessing(v : boolean) {
        this.setterImpl(1, v, 'triggerProcessing', false, null, null);
	}

	/**
     *  The type of the failed event.
	 *
	 * @type {boolean}
     */
	get isPlatformEvent() : boolean {
		this.getterDebugValidations(this.attributeInfoIsPlatformEvent, false);
		return this.data[2];
	}

	set isPlatformEventTestSetter(v : boolean) {
        this.setterImpl(2, v, 'isPlatformEvent', true, null, null);
	}

	/**
     *  The body of the webhook event.
	 *
	 * @type {string}
     */
	get eventJson() : string {
		this.getterDebugValidations(this.attributeInfoEventJson, false);
		return this.data[3];
	}

	set eventJsonTestSetter(v : string) {
        this.setterImpl(3, v, 'eventJson', true, null, null);
	}

	/**
     *  Should this event automatically be processed again?
	 *
	 * @type {boolean}
     */
	get autoRetryEnabled() : boolean {
		this.getterDebugValidations(this.attributeInfoAutoRetryEnabled, false);
		return this.data[4];
	}

	set autoRetryEnabledTestSetter(v : boolean) {
        this.setterImpl(4, v, 'autoRetryEnabled', true, null, null);
	}

	/**
     *  When was this event received?
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[5];
	}

	set dateTimeTestSetter(v : DateTime) {
        this.setterImpl(5, v, 'dateTime', true, null, null);
	}

	/**
     *  The error which happened during processing of this event.
	 *
	 * @type {string}
     */
	get error() : string {
		this.getterDebugValidations(this.attributeInfoError, false);
		return this.data[6];
	}

	set errorTestSetter(v : string) {
        this.setterImpl(6, v, 'error', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('FAILED_ADYEN_EVENT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : FailedAdyenEventsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}



