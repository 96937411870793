import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Config } from '@plano/shared/core/config';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-pdf-template',
	templateUrl: './pdf-template.component.html',
	styleUrls: ['./pdf-template.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PPdfTemplateComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public overlayTemplate : TemplateRef<unknown> | null = null;

	/**
	 * Should the dotted border and height info be shown?
	 */
	@Input() public hideTemporaryInfo : boolean = false;

	/**
	 * Pdf src of pdf uploaded by the user
	 */
	@Input() public pdfSrc : string | null = null;

	/**
	 * If the pdf template is clickable, the cursor will change
	 */
	@Input() public isClickable : boolean = false;

	/**
	 * Is the pdf supposed to open a zoomed version on click?
	 */
	@Input() public zoomOnClick : boolean = false;

	/**
	 * Representative of either the pdfSrc or the template pdf (when no pdf was uploaded yet)
	 */
	@Input() public templatePdf ! : string;

	/**
	 * The max width of the pdf
	 */
	@Input() public maxPdfWidth ! : number;

	/**
	 * Emit click event when pdf template is clicked
	 */
	@Output() public onClick = new EventEmitter<MouseEvent>();

	constructor(
		public elementRef : ElementRef<HTMLElement>,
	) {

	}

	public Config = Config;
	public enums = enumsObject;

	public currentPdfZoomValue = 1;

	/**
	 * Increase Pdf zoom
	 */
	public increasePdfZoom(event : Event) : void {
		event.stopPropagation();
		if (this.calculatedZoomedPdfWidth < window.innerWidth * 0.7 || (Config.IS_MOBILE && this.currentPdfZoomValue < 2)) {
			this.currentPdfZoomValue += 0.25;
		}
	}

	/**
	 * Decrease Pdf zoom
	 */
	public decreasePdfZoom(event : Event) : void {
		event.stopPropagation();
		if (this.currentPdfZoomValue > 1) {
			this.currentPdfZoomValue -= 0.25;
		}
	}

	/**
	 * Max pdf width based on window size
	 */
	public get calculatedZoomedPdfWidth() : number {
		const initialWidth = Math.min(window.innerWidth * 0.9, (window.innerHeight * 0.8) / 1.414);
		return initialWidth * this.currentPdfZoomValue;
	}

	/**
	 * Open the pdf viewer modal
	 */
	public openZoomedPdf(dialog : HTMLDialogElement) : void {
		dialog.showModal();
	}

	/**
	 * Get the height of the pdf template, by multiplying the width by 1.414 ( A4 aspect ratio )
	 */
	public get heightStylePdfTemplate() : string {
		return `height: calc(${this.maxPdfWidth} * 1.414px + 10px);`;
	}

	/**
	 * Method to prevent that pressing escape on the zoomed preview will close any other opened modals
	 */
	public handleKeyDownOnDialog(event : KeyboardEvent, dialog : HTMLDialogElement) : void {
		if (dialog.open && event.key === 'Escape') {
			event.preventDefault();
			event.stopImmediatePropagation();
			dialog.close();
			this.currentPdfZoomValue = 1.0;
			const ngbModalWindowElement : HTMLElement | null = document.querySelector('ngb-modal-window');
			if (ngbModalWindowElement) {
				ngbModalWindowElement.focus();
			}
		}
	}

}
