import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Injector, Input, NgZone, OnChanges, OnDestroy, SkipSelf, TemplateRef, ViewContainerRef } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PSimpleChanges } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';

@Directive({
	selector: '[pTooltip]',
	exportAs: 'p-tooltip',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTooltipDirective implements OnDestroy, OnChanges, AfterViewInit {
	/**
	 * String or template ref that should be shown for this tooltip.
	 */
	@Input() public pTooltip ! : string | TemplateRef<unknown> | null;

	/**
	 * Default placement for the tooltip. It takes a member of NgxPopperjsPlacements, to access it
	 * in the component templates we need to make sure that NgxPopperjsPlacements is declared in the component.
	 *
	 * To do so, add:
	 *
	 * public NgxPopperjsPlacements = NgxPopperjsPlacements;
	 *
	 * And make sure that NgxPopperjsPlacements gets imported.
	 */
	@Input() public pTooltipPlacement : NgxPopperjsPlacements = NgxPopperjsPlacements.TOP;

	/**
	 * By default the tooltip will close immediately after the mouse left, but if necessary we should set
	 * this boolean to true to make it hoverable.
	 */
	@Input() public pTooltipHover : boolean = true;

	/**
	 * Tooltip theme. By default dark
	*/
	@Input() public pTooltipTheme : PThemeEnum | null = enumsObject.PThemeEnum.DARK;

	/**
	 * Trigger for the tooltip, by default hover
	 */
	@Input() public pTooltipTrigger : 'hover' | 'custom' = NgxPopperjsTriggers.hover;

	/**
	 * Should the tooltip be shown? Use together with tooltip trigger 'custom'
	 */
	@Input() public pTooltipShow : boolean | null = null;

	/**
	 * Color of the arrow for the tooltip
	 */
	@Input() public pTooltipArrowColor : string | null = null;

	/**
	 * Should the arrow be behind the tooltip?
	 */
	@Input() public pTooltipArrowBehind : boolean = false;

	/**
	 * Should the tooltip be appended to the body? By default, yes
	 */
	@Input() public pTooltipAppendToBody : boolean = true;

	constructor(
		private elementRef : ElementRef<HTMLElement>,
		private changeDetectorRef : ChangeDetectorRef,
		@SkipSelf() private changeDetectorRefParent : ChangeDetectorRef,
		private viewContainerRef : ViewContainerRef,
		private injector : Injector,
		private zone : NgZone,
		private console : LogService,
	) {

		this.disabledObserver = new MutationObserver(() => {
			/*
			If the element changes its disabled state we want to add a
			div over it so we can listen to mouse events on it instead of the
			disabled element.
			*/
			if (elementRef.nativeElement.hasAttribute('disabled') && !this.hasDisabledProperty) {
				this.disabledHoverElement = document.createElement('div');
				this.disabledHoverElement.style.position = 'absolute';
				this.disabledHoverElement.style.width = '100%';
				this.disabledHoverElement.style.top = '0';
				this.disabledHoverElement.style.left = '0';
				this.disabledHoverElement.style.height = '100%';
				this.elementRef.nativeElement.classList.add('position-relative');
				this.elementRef.nativeElement.appendChild(this.disabledHoverElement);
				this.setMouseListeners(this.disabledHoverElement);
				this.hasDisabledProperty = true;
			} else if (this.hasDisabledProperty && !elementRef.nativeElement.hasAttribute('disabled')) {
				this.disabledHoverElement!.remove();
				this.disabledHoverElement = null;
				this.hasDisabledProperty = false;
			}
		});
		this.disabledObserver.observe(elementRef.nativeElement, {attributeFilter: ['disabled']});
	}

	/**
	 * Does the element on which the tooltip is set have the disabled property?
	 */
	private hasDisabledProperty : boolean = false;

	/**
	 * Observer for changes in the disabled attribute of the element
	 */
	private disabledObserver : MutationObserver;

	/**
	 * Div positioned absolutely over a disabled element if any
	 */
	private disabledHoverElement : HTMLElement | null = null;

	public ngOnChanges(changes : PSimpleChanges<PTooltipDirective>) : void {
		if (changes.pTooltip?.previousValue) {
			if (this.popperHtmlElement)
				this.popperHtmlElement.remove();
			this.createTooltip();
			if (!this.hidden) {
				this.handleMouseEnter();
			}
		}

		if (this.pTooltipTrigger === 'custom' && changes.pTooltipShow?.previousValue !== changes.pTooltipShow?.currentValue) {
			const show = changes.pTooltipShow!.currentValue;
			if (show) {
				if (!this.popperHtmlElement) {
					this.createTooltip();
					this.changeDetectorRef.detectChanges();
				}
				this.displayTooltip(this.popperHtmlElement!);
			} else {
				if (this.popperHtmlElement)
					this.hideTooltip(this.popperHtmlElement);
			}
		}
	}

	/**
	 * Timeout to hide the tooltip
	 */
	private hideTimeout : number | null = null;

	/**
	 * Timeout to trigger interaction with the tooltip
	 */
	private interactionTimeout : number | null = null;

	/**
	 * Is the tooltip hidden?
	 */
	private hidden : boolean = true;

	/**
	 * Popper component created with the viewRef of where it was instantiated
	 */
	private popperContent : NgxPopperjsContentComponent | null = null;

	/**
	 * Native element of the popperContent
	 */
	private popperHtmlElement : HTMLElement | null = null;

	/**
	 * Element that will be added as a child of the popperHtmlElement,
	 * this element is the one that contains the content of the tooltip itself.
	 *
	 * It creates the element based on the pTooltip.
	 */
	private tooltipHtmlElement ! : HTMLElement;

	public Config = Config;

	/**
	 * Method responsible for creating an HTMLElement given only a string.
	 *
	 * @param text String received via props in this directive
	 * @returns An HTMLElement that contains the string
	 */
	private createCardForString(text : string) : HTMLElement {
		const wrapperCard : HTMLDivElement = document.createElement('div');
		wrapperCard.classList.add('d-block', 'm-0', 'text-left', 'o-hidden', 'card');
		const cardBodyElement : HTMLDivElement = document.createElement('div');
		cardBodyElement.classList.add('card-body', 'p-1', 'pl-2', 'pr-2', 'text-wrap');
		cardBodyElement.innerHTML = text;
		wrapperCard.appendChild(cardBodyElement);
		return wrapperCard;
	}

	private displayTooltip(popperHtmlElement : HTMLElement) : void {
		if (!this.hidden) return;
		popperHtmlElement.ariaHidden = 'false';
		if (this.pTooltipAppendToBody)
			document.body.appendChild(popperHtmlElement);
		else
			this.elementRef.nativeElement.parentElement!.appendChild(popperHtmlElement);
		this.popperContent!.update();
		window.requestAnimationFrame(() => {
			// as this happens on an animation frame,
			// it is possible that eraseTooltip runs in the meantime
			// setting this.popperContent to null
			if (this.popperContent) {
				this.popperContent.show();
				this.hidden = false;
			}
		});

	}

	private hideTooltip(popperHtmlElement : HTMLElement) : void {
		if (this.hidden) return;
		popperHtmlElement.ariaHidden = 'true';
		popperHtmlElement.remove();
		if (this.popperContent)
			this.popperContent.hide();
		this.hidden = true;
	}

	/**
	 * Property that tells this directive to create a tooltip from the content
	 * of the element if the element is cropped.
	 */
	protected addTooltipIfCropped = false;

	/**
	 * Time to wait before triggering the change of state of the tooltip (hidden or not)
	 */
	private TRIGGER_TOOLTIP_TIMEOUT = 300;

	private croppedContentTooltipNeeded(target : HTMLElement) : boolean {
		if (this.pTooltip && !this.createdPTooltipFromTextContent) return false;
		if (!this.pTooltip && target.scrollWidth <= target.offsetWidth) {
			return false;
		}
		return true;
	}

	/**
	 * @param event Mouse enter event. null if it got called from code instead of a user event.
	 */
	private handleMouseEnter(event : Event | null = null) : void {
		this.zone.runOutsideAngular(() => {
			if (!Config.IS_MOBILE) {

				if (event) {

					const targetElement : HTMLElement = event.target as HTMLElement;
					if (this.addTooltipIfCropped && !this.croppedContentTooltipNeeded(targetElement)) return;

					// If the user moved from trigger to tooltip, and there was a gap between, we dont want the tooltip to be
					// added again. It would blink.
					if (targetElement.tagName === 'POPPER-CONTENT' && this.hiding) return;
				}

				// needed to update the tooltip, as the html content of the element could have changed in the meantime
				if (this.addTooltipIfCropped) {
					this.eraseTooltip();
					this.createTooltip();
				}

				// TODO: Move the code below to a own method.

				if (!this.popperHtmlElement) {
					this.createTooltip();
					this.changeDetectorRef.detectChanges();
					this.changeDetectorRefParent.detectChanges();
				}

				// time to wait before displaying the tooltip
				if (this.hideTimeout !== null) {
					window.clearTimeout(this.hideTimeout);
					this.hideTimeout = null;
				}
				this.interactionTimeout = window.setTimeout(()=>{
					if (this.popperHtmlElement) {
						this.displayTooltip(this.popperHtmlElement);
						this.popperHtmlElement.style.opacity = '100%';
					}
				}, this.TRIGGER_TOOLTIP_TIMEOUT);
			}
		});

	}

	/**
	 * Is the tooltip correctly hiding itself? If so we don't want it to reappear when we hover
	 */
	private hiding : boolean = false;

	private handleMouseLeave() : void {
		this.zone.runOutsideAngular(() => {
			if (this.interactionTimeout) {
				window.clearTimeout(this.interactionTimeout);
				this.interactionTimeout = null;
			}
			if (!Config.IS_MOBILE && this.popperHtmlElement) {
				const popper = this.popperHtmlElement;
				this.hideTimeout = window.setTimeout(() => {
					this.hiding = true;
					if (this.createdPTooltipFromTextContent)
						this.pTooltip = null;
					popper.style.opacity = '0%';
					window.setTimeout(()=>{
						this.hideTooltip(popper);
						this.hiding = false;
					}, this.TRIGGER_TOOLTIP_TIMEOUT);
				}, 50);

			}
		}) ;

	}

	private bindHandleMouseLeave = this.handleMouseLeave.bind(this);
	private bindHandleMouseEnter = this.handleMouseEnter.bind(this);

	/**
	 * Check if there is an existing popper
	 */
	protected hasPopper() : boolean {
		return this.popperContent !== null;
	}

	/**
	 * Add the required classes to the arrow element to change its color
	 */
	private addArrowElementColor(arrowHtmlElement : HTMLElement) : void {
		arrowHtmlElement.classList.add('has-color');
		arrowHtmlElement.style.visibility = 'hidden';
		if (this.pTooltipArrowColor) arrowHtmlElement.style.backgroundColor = `${this.pTooltipArrowColor}`;
		else arrowHtmlElement.classList.add(`bg-${this.pTooltipTheme}`);
		arrowHtmlElement.style.zIndex= this.pTooltipArrowBehind ? '-1' : '1';
		if (this.pTooltipTheme === enumsObject.PThemeEnum.DARK) {
			// We had cases where classList and style was not defined.
			// This is not important enough to throw in such cases. So we added a try/catch.
			try {
				// TODO: PLANO-159385 remove this and use the default border color if we decide to change
				this.tooltipHtmlElement.classList.add('border');
				this.tooltipHtmlElement.style.setProperty('border-color','gray', 'important');
			} catch (error) {
				this.console.error('tooltipHtmlElement is not an html element?', error);
			}
		} else {
			this.tooltipHtmlElement.classList.add('border-0');
		}
	}

	private getTextContextFromNode(node : ChildNode) : string | null {
		const childNodes : ChildNode [] = [node];
		const result : string [] = [];
		while (childNodes.length > 0) {
			const currentNode = childNodes.shift()!;
			if (currentNode.childNodes.length > 1) {
				childNodes.unshift(...Array.from(currentNode.childNodes));
			} else if (currentNode.textContent) {
				result.push(currentNode.textContent.trim());
			}

		}
		return result.length > 0 ? result.join(' ') : null;
	}

	private createdPTooltipFromTextContent = false;

	/**
	 * Create the tooltip and add it to the DOM
	 */
	protected createTooltip() : void {
		if (this.addTooltipIfCropped) {
			this.pTooltip = this.getTextContextFromNode(this.elementRef.nativeElement);
			this.createdPTooltipFromTextContent = true;
		}

		if (!this.pTooltip) return;

		if (! (this.pTooltip instanceof TemplateRef)) {
			const stringToHtml = this.createCardForString(this.pTooltip);
			this.tooltipHtmlElement = stringToHtml;
		} else {

			/**
	 		* We use the viewContainerRef of the element on which we want to add the tooltip and create a view
	  		* using the template ref. This needs to be done this way so we can use the context inside the viewContainerRef.
	  		* For example, it makes sure that when we use template variables in the template ref, those will be linked to the
	  		* ones defined in the html file where this directive was called.
	  		*/
			const viewWithContext = this.viewContainerRef.createEmbeddedView(this.pTooltip, null, {injector: this.injector});
			const renderedComponent = viewWithContext.rootNodes[0];

			// in case the tooltip has a ng-container we need to create a wrapping element to hold the tooltip
			// in such cases the first root node was empty
			if (renderedComponent.nodeName==='#comment') {
				const wrapperElement = document.createElement('span');
				const rootNodes : Node[] = viewWithContext.rootNodes;
				wrapperElement.append(...rootNodes);
				this.tooltipHtmlElement = wrapperElement;

				// some padding when template root node was empty
				this.tooltipHtmlElement.classList.add('p-2');
			} else this.tooltipHtmlElement = renderedComponent;
			this.changeDetectorRef.detectChanges();
		}
		this.popperContent = this.viewContainerRef.createComponent(NgxPopperjsContentComponent, {injector:this.injector}).instance;

		this.popperContent.referenceObject = this.elementRef.nativeElement;

		// Start hidden
		this.popperContent.hide();

		// Default popper options
		this.popperContent.popperOptions = {
			...this.popperContent.popperOptions,
			placement: this.pTooltipPlacement,
			positionFixed: false,
			disableAnimation: true,
			trigger: NgxPopperjsTriggers.none,
		};

		const tempPopperHtmlElement : HTMLElement = this.popperContent.elRef.nativeElement;
		this.popperHtmlElement = tempPopperHtmlElement;

		if (this.pTooltipTrigger === 'hover') {
			// Add the transition to the tooltip so we can change the opacity on mouse leave
			this.popperHtmlElement.style.transition = `opacity ${this.TRIGGER_TOOLTIP_TIMEOUT}ms`;
			this.popperHtmlElement.style.opacity = '0%';

		}

		if (this.pTooltipTheme !== enumsObject.PThemeEnum.LIGHT) {
			const arrowHtmlElement : HTMLElement | null = this.popperHtmlElement.querySelector('.ngxp__arrow');
			if (arrowHtmlElement) {
				this.addArrowElementColor(arrowHtmlElement);
			}
		}

		const innerPopperHtmlElement : HTMLElement = this.popperHtmlElement.querySelector('.ngxp__inner')!;

		// We had cases where classList and style was not defined.
		// This is not important enough to throw in such cases. So we added a try/catch.
		try {
			// Force the tooltip to have the same color as the one defined in the body.
			// We might change this if we restyle the tooltip.
			if (this.pTooltipTheme) {
				this.tooltipHtmlElement.classList.add('d-block', 'card');
				this.tooltipHtmlElement.classList.add('text-style-reset-to-body', `bg-${this.pTooltipTheme}`);

				// set the font-size to be 80% of the normal font-size
				this.tooltipHtmlElement.style.fontSize = '0.8rem';

				// Add class to change width (defined in global-styles)
				this.popperHtmlElement.classList.add('p-tooltip');
				const popperElementContent = this.popperHtmlElement.childNodes[0] as HTMLElement;
				popperElementContent.role = 'tooltip';
			}

			innerPopperHtmlElement.appendChild(this.tooltipHtmlElement);

			if (this.pTooltipHover && this.pTooltipTrigger === 'hover') {
				this.setMouseListeners(this.popperHtmlElement);
			}

			this.popperHtmlElement.remove();
		} catch (error) {
			this.console.error('tooltipHtmlElement is not an html element?', error);
		}
	}

	/**
	 * Reload the tooltip
	 */
	protected reloadTooltip() : void {
		if (this.hasPopper()) {
			this.popperContent!.update();
		}
	}

	/**
	 * Erase the tooltip and remove the event listeners
	 */
	protected eraseTooltip() : void {
		if (this.popperContent) {
			this.popperContent = null;
			if (this.popperHtmlElement)
				this.popperHtmlElement.remove();
			this.popperHtmlElement = null;
			if (!this.pTooltipHover) {
				this.elementRef.nativeElement.removeEventListener('mouseenter', this.bindHandleMouseEnter);
				this.elementRef.nativeElement.removeEventListener('mouseleave', this.bindHandleMouseLeave);
			}
		}
	}

	public ngOnDestroy() : void {
		this.eraseTooltip();
		this.disabledObserver.disconnect();
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		if (this.pTooltipTrigger === 'custom' && this.pTooltipShow === null) {
			throw new Error('No value provided to pTooltipShow even though pTooltipTrigger is set to custom!');
		}
	}

	private setMouseListeners(htmlElement : HTMLElement) : void {
		htmlElement.addEventListener('mouseenter', this.bindHandleMouseEnter);
		htmlElement.addEventListener('mouseleave', this.bindHandleMouseLeave);
	}

	public ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initValues();
		if (this.pTooltipTrigger === 'hover') {
			this.setMouseListeners(this.elementRef.nativeElement);
		}
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}
}
