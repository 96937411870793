<div
	*ngIf="formGroup && item; else itemIsLoading"
	[@slideVertical]
>
	<ng-template [ngIf]="item.behavesAsNewItem" [ngIfElse]="existingItemFormTemplate">
		<ng-template [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{$implicit : false}"></ng-template>
	</ng-template>
	<ng-template #existingItemFormTemplate>
		<p-editable-modal-box
			class="mb-5"
			[size]="enums.BootstrapSize.XL"

			[pEditable]="!item.behavesAsNewItem && !item.isClosed"
			[api]="api"
			[valid]="!formIsInvalid"
			(onSaveStart)="generateItemsIfNecessary()"
			(onDismiss)="initFormGroup()"
			(onSaveSuccess)="initFormGroup()"

			[disabled]="formIsDisabled"

			[btnLabel]="infoBoxEditButtonText"
			[btnIcon]="turnToIllnessIsPossible ? 'briefcase-medical' : null"
			(onModalClosed)="refreshTurnToIllnessIsPossible()"
		>
			<p-editable-modal-box-header class="p-2 pl-3 pr-3 p-md-3">
				<div>
					<h5 class="m-0 d-flex align-items-center flex-wrap gap-2">
						<fa-icon class="d-inline-block" [class.text-danger]="item.isIllness && !item.isBasedOnIllness" [icon]="headlineIcon"></fa-icon>
						<span *ngIf="item.isNewItem()">Neue </span>
						<ng-template [ngIf]="!item.isIllness || item.isBasedOnIllness" [ngIfElse]="notConfirmedTitleTemplate">
							<ng-template [ngIf]="!item.responsibleMember">
								<ng-container i18n>Ersatzsuche</ng-container>
							</ng-template>
							<ng-template [ngIf]="item.responsibleMember">
								<ng-container i18n>Ersatzsuche von</ng-container>
								<span class="nowrap d-inline-flex gap-2">
									<p-member-badge
										[absenceType]="absenceType"
										[memberId]="item.responsibleMemberId"
										[firstName]="item.responsibleMember.firstName"
										[lastName]="item.responsibleMember.lastName"
										[isMe]="!!isMe(item.responsibleMemberId)"
										size="small"
									></p-member-badge>
									<span>
										<strong>{{ item.responsibleMember.firstName }}</strong> {{ item.responsibleMember.lastName }}
									</span>
								</span>
							</ng-template>
						</ng-template>
						<ng-template #notConfirmedTitleTemplate>
							<ng-container i18n>Krankmeldung von</ng-container>
							<ng-template [ngIf]="indisposedMember">
								<span class="nowrap d-inline-flex gap-2">
									<p-member-badge
										[absenceType]="indisposedMember.trashed ? 'trashed' : null"
										[memberId]="item.indisposedMemberId"
										[firstName]="indisposedMember.firstName"
										[lastName]="indisposedMember.lastName"
										[isMe]="isMe(item.indisposedMemberId)!"
										size="small"
									></p-member-badge>
									<span>
										<strong>{{indisposedMember.firstName}}</strong> {{indisposedMember.lastName}}
									</span>
								</span>
							</ng-template>
						</ng-template>
						<div
							*ngIf="item.deadline !== null"
							class="d-flex align-items-center flex-wrap gap-2"
							style="flex-basis: 100%;"
						>
							<p-deadline
								[timestamp]="item.deadline"
							></p-deadline>
							<span *ngIf="isExpired" class="text-danger" i18n>verstrichen</span>
						</div>
					</h5>
				</div>
			</p-editable-modal-box-header>
			<p-editable-modal-box-showroom>
				<ng-template [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{$implicit : true, reduceOpacityIfDisabled: false}"></ng-template>
			</p-editable-modal-box-showroom>
			<p-editable-modal-form>
				<ng-template [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{$implicit : false, reduceOpacityIfDisabled: true}"></ng-template>
			</p-editable-modal-form>
		</p-editable-modal-box>
		<!-- cSpell:disable Abwesenheits -->
		<p-section
			class="mb-5 border-0"
			*ngIf="item.relatedAbsences.length"
			label="Hieraus entstandene Abwesenheits-Einträge" i18n-label
			pAnchorLink="resulting-leave-of-absence"
			[whitespace]="SectionWhitespace.NONE"
		>
			<p-shift-exchange-related-absences
				[absences]="item.relatedAbsences"
			></p-shift-exchange-related-absences>
		</p-section>
	</ng-template>

	<p-section
		*ngIf="item.behavesAsNewItem"
		class="d-flex align-items-end justify-content-end"
	>
		<ng-template [ngIf]="!!warnings.withSeverityInfoCount">
			<div hidden><span i18n #translatedSeverityInfoCountTitle>{warnings.withSeverityInfoCount, plural, one {Ein Hinweis} other {{{warnings.withSeverityInfoCount}} Hinweise}} im Formular</span></div>
			<span class="mr-2" [title]="translatedSeverityInfoCountTitle.textContent!">{{warnings.withSeverityInfoCount}}×<fa-icon class="text-info" [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon></span>
		</ng-template>
		<ng-template [ngIf]="!!warnings.withSeverityWarningCount">
			<div hidden><span i18n #translatedSeverityWarningCountTitle>{warnings.withSeverityWarningCount, plural, one {Eine Warnung} other {{{warnings.withSeverityWarningCount}} Warnungen}} im Formular</span></div>
			<span class="mr-2" [title]="translatedSeverityWarningCountTitle.textContent!">{{warnings.withSeverityWarningCount}}×<fa-icon class="text-warning" icon="exclamation-triangle"></fa-icon></span>
		</ng-template>
		<ng-template [ngIf]="!!warnings.withSeverityFatalCount">
			<div hidden><span i18n #translatedSeverityErrorCountTitle>{warnings.withSeverityFatalCount, plural, one {Ein Error} other {{{warnings.withSeverityFatalCount}} Errors}} im Formular</span></div>
			<span class="mr-2" [title]="translatedSeverityErrorCountTitle.textContent!">{{warnings.withSeverityFatalCount}}×<fa-icon class="text-danger" icon="times-circle"></fa-icon></span>
		</ng-template>
		<button
			[type]="formIsInvalid ? 'submit' : 'button'"
			class="btn btn-lg d-flex align-items-center ml-2"
			[class.btn-primary]="!(item.behavesAsNewItem && !item.isNewItem())"
			[class.btn-success]="item.behavesAsNewItem && !item.isNewItem()"
			[disabled]="formIsInvalid || api.isBackendOperationRunning"
			asyncSaveButton
			(click)="saveItem()"
		>
			<fa-icon [icon]="enums.PlanoFaIconPool.ADD" class="mr-2"></fa-icon>
			<ng-container i18n>{item.isIllness && !item.isBasedOnIllness, select, true {Krankmeldung} other {Suche}} anlegen</ng-container>
		</button>
	</p-section>

	<ng-template [ngIf]="!item.behavesAsNewItem">
		<p-section *ngIf="showCommunicationIllnessResponse" label="Genehmigung der Krankmeldung" i18n-label pAnchorLink="approve-sick-leave" [whitespace]="SectionWhitespace.NONE">
			<ng-template
				ngFor let-communicationIllnessResponseItem
				[ngForOf]="communicationIllnessResponses"
			>
				<ng-template
					[ngTemplateOutlet]="communicationTemplate"
					[ngTemplateOutletContext]="{
						$implicit : communicationIllnessResponseItem
					}"
				></ng-template>
			</ng-template>
		</p-section>

		<p-section
			*ngIf="communicationNonConfirmations.length"
			label="Verhandlungsstatus" i18n-label
			pAnchorLink="negotiotion-state"
			[whitespace]="SectionWhitespace.NONE"
		>
			<p *ngIf="item.isClosed"><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon>&nbsp;<ng-container i18n>Diese {item.isIllness && !item.isBasedOnIllness, select, true {Krankmeldung} other {Suche}} ist beendet. Der Status kann nicht mehr geändert werden.</ng-container></p>
			<ng-template
				ngFor let-communicationNonConfirmationInputItem
				[ngForOf]="communicationNonConfirmations"
			>
				<ng-template
					[ngTemplateOutlet]="communicationTemplate"
					[ngTemplateOutletContext]="{
						$implicit : communicationNonConfirmationInputItem
					}"
				></ng-template>
			</ng-template>
		</p-section>

		<ng-template #communicationTemplate let-input let-isMuted="isMuted">
			<p-shift-exchange-communication
				[shiftExchange]="item"
				[input]="input"
				(onPerformActionModalSuccess)="onPerformActionModalSuccess($event)"
				(onPerformActionModalDismiss)="onPerformActionModalDismiss()"
			></p-shift-exchange-communication>
		</ng-template>

		<div hidden><span i18n #translatedSomeFormSectionLabel>Erneut {item.isIllness && !item.isBasedOnIllness, select, true {krank melden} other {Ersatz suchen}}</span></div>
		<p-section
			*ngIf="item.attributeInfoOpenShiftExchange.isAvailable"
			[@slideVertical]
			[label]="translatedSomeFormSectionLabel.textContent!"
			pAnchorLink="request-again"
			class="border-0 mt-5 mb-5"
			[whitespace]="SectionWhitespace.NONE"
		>
			<div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center gap-3">
				<div>
					<span *ngIf="item.isIllness && !item.isBasedOnIllness; else noIllnessText" i18n>Die Personalleitung wird automatisch informiert und muss darüber entscheiden.</span>
					<ng-template #noIllnessText>
						<span i18n>Bereits gegebene Antworten gehen verloren. Deine Mitarbeitenden werden aufgefordert, erneut zu antworten. Überlege dir gut, ob das nötig ist.</span>
					</ng-template>
				</div>
				<p-button
					class="min-w-max-content"
					(triggerClick)="reOpenForm()"
					[disabled]="allShiftRefsAreInThePast"
					btnTitle="Wiederherstellen" i18n-btnTitle>
					<fa-icon [icon]="enums.PlanoFaIconPool.RESTORE" class="mr-2"></fa-icon>
					<ng-container i18n>Wiederherstellen</ng-container>
				</p-button>
			</div>
		</p-section>

	</ng-template>
</div>

<ng-template #itemIsLoading>
	<p-spinner [size]="enums.BootstrapSize.LG" class="m-5 h-100"></p-spinner>
</ng-template>


<ng-template #formTemplate let-readMode let-reduceOpacityIfDisabled="reduceOpacityIfDisabled">
	<form *ngIf="item && formGroup" [formGroup]="$any(formGroup)">
		<p-section [whitespace]="SectionWhitespace.MEDIUM">
			<div class="row gap-2">
				<p-bootstrap-form-group
					*ngIf="readMode && !item.behavesAsNewItem"
					class="col-12"
				>
					<p-shift-exchange-state-badge
						[shiftExchange]="item"
					></p-shift-exchange-state-badge>
				</p-bootstrap-form-group>
				<p-bootstrap-form-group
					*ngIf="item.isNewItem() && !item.indisposedMemberId"
					class="col-12"
					label="Von" i18n-label
					[control]="formGroup.controls['indisposedMemberId']!"
				>
					<p-input-member-id
						[formControl]="formGroup.controls['indisposedMemberId']!"
						[members]="api.data.members"
					></p-input-member-id>
				</p-bootstrap-form-group>

				<div
					class="col-12"
					[class.col-md]="!Config.IS_MOBILE"
				>
					<div class="d-flex align-items-center">
						<div aria-labelledby="shift-picker-label" class="flex-grow-1 o-hidden">
							<p-bootstrap-form-group>
								<label id="shift-picker-label">
									<span i18n>{item.isIllness, select, true {Betroffene } other {Angebotene }}{item.shiftRefs.length, plural, one {Schicht } other {Schichten }}</span>
									<span class="d-inline-flex align-items-center gap-2" *ngIf="!!indisposedMember && !item.indisposedMemberId.equals(item.responsibleMemberId)">
										<span class="no-overflow-wrap" i18n="Betroffene Schichten _von_">von</span>
										<p-member-badge
											[memberId]="item.indisposedMemberId"
											[absenceType]="absenceTypeForBadgeForShiftsOfIndisposedMember"
											[firstName]="indisposedMember.firstName"
											[lastName]="indisposedMember.lastName"
											[isMe]="isMe(item.indisposedMemberId)!"
											[size]="'small'"
										></p-member-badge>
										<span class="crop-on-overflow"><strong>{{ indisposedMember.firstName }}</strong> {{ indisposedMember.lastName }}</span>
									</span>
								</label>
								<p-ai-form-array
									[hideAddBtn]="true"
									[formParent]="formGroup"
									[attributeInfo]="item.attributeInfoShiftRefs"
									#AIFormArrayRef
								>
									<p-shift-picker-modal-box
										[api]="api"

										[readMode]="readMode"
										[style.opacity]="reduceOpacityIfDisabled && shiftRefsIsDisabled ? 0.5 : 1"

										[loadDetailedItem]="item"
										[offersRef]="item.shiftRefs"

										[formArray]="AIFormArrayRef.childArray"
										(addItem)="onAddShiftRefToFormArray(AIFormArrayRef.childArray, $event)"
										(onAddShifts)="onAddShiftsToFormArray(AIFormArrayRef.childArray, $event)"
										[changeOfferHook]="changeOfferHook"

										[availableShifts]="shiftsForShiftPicker"

										[shiftTemplate]="!Config.IS_MOBILE ? shiftTemplate : undefined!"
										(calendarBtnClick)="pShiftExchangeService.onCalendarBtnClick($event)"

										[disabled]="shiftRefsIsDisabled"
										[showCalendarBtn]="!readMode ? false : null"
									></p-shift-picker-modal-box>
								</p-ai-form-array>
								<ng-template #shiftTemplate let-shift let-viewStyle="viewStyle">
									<ng-template [ngIf]="shiftExchangeExistsForShift(shift.id)" [ngIfElse]="otherShiftTemplate">
										<p-shifts-info
											role="listitem"
											pTooltip="Ist schon in der Tauschbörse"

											class="flex-grow-1 muted-item border"
											[shiftId]="shift.id"
											[showDate]="false"
										>
											<p-shift-info-content-left class="ml-2">
												<fa-icon icon="hands-helping"></fa-icon>
											</p-shift-info-content-left>
										</p-shifts-info>
									</ng-template>

									<ng-template #otherShiftTemplate>
										<p-shift-item
											[readMode]="true"

											[pTooltip]="!shiftIsPickable(shift.id) ? 'Ist schon Teil deiner ' + (item.isIllness ? 'Krankmeldung' : 'Ersatzsuche') : null"

											[class.border-primary]="!shiftIsPickable(shift.id)"
											style="margin-top: 0 !important;"
											[shift]="shift"
											[emptyMemberSlots]="shift.emptyMemberSlots"
											[viewStyle]="Config.IS_MOBILE ? ShiftItemViewStyles.SMALL : viewStyle"
											[selectable]="shiftIsPickable(shift.id)"
											[class.muted-item]="!shiftIsPickable(shift.id)"
											[showAsList]="true"
											[showCourseInfo]="true"
										></p-shift-item>
									</ng-template>
								</ng-template>
							</p-bootstrap-form-group>
							<p-warnings
								class="mb-2"
								[warnings]="warnings.getByMember(item.indisposedMemberId)"
							></p-warnings>
						</div>
					</div>
					<div hidden><span i18n #translatedIsIllness>Krankmeldung {!isManagerForTheseShiftRefs, select, true {an Personalleitung}}</span></div>
					<p-bootstrap-form-group
						*ngIf="showIsIllnessCheckbox(readMode)"
						[label]="translatedIsIllness.textContent!"
						[control]="formGroup!.controls['isIllness']!"
						description="Eine Krankmeldung wird der Personalleitung zugeschickt – nicht den Mitarbeitenden. Sobald die Personalleitung die Krankmeldung bestätigt, wird die erkrankte Person aus der betroffenen Schicht entfernt." i18n-description
					>
						<p-checkbox
							[readMode]="readMode"
							class="flex-grow-1"
							icon="briefcase-medical"
							[valueText]="isIllnessLabel"

							[formControl]="formGroup!.controls['isIllness']!"
						></p-checkbox>
					</p-bootstrap-form-group>
					<p-bootstrap-form-group
						*ngIf="!(readMode && formGroup!.controls['indisposedMemberPrefersSwapping']!.value === undefined)"
						[class.d-none]="formGroup!.controls['isIllness']!.value === undefined || formGroup!.controls['isIllness']!.value === true"
						[class.d-block]="formGroup!.controls['isIllness']!.value === false"
						[@slideVertical]
						[control]="formGroup!.controls['indisposedMemberPrefersSwapping']!"
						label="Präferenz" i18n-label
						description="Die Präferenz ist nicht bindend. Die Mitarbeitenden können einen abweichenden Vorschlag machen, den die Ersatz suchende Person bestätigen muss, damit der Deal zustande kommt." i18n-description
					>
						<p-radios
							[pTooltip]="!readMode && formGroup!.controls['indisposedMemberPrefersSwapping']!.disabled && iAmTheIndisposedMember ? 'Deine Präferenz legst du beim Starten der Ersatzsuche fest. Sie lässt sich nachträglich nicht ändern.' : null"

							[readMode]="readMode"

							[formControl]="formGroup!.controls['indisposedMemberPrefersSwapping']!"
						>
							<p-radios-radio
								icon="exchange-alt"
								label="Im Gegenzug eine Schicht übernehmen" i18n-label
								[value]="true"
							></p-radios-radio>
							<div hidden><span i18n #translatedLabelForRadioForPrefs>meine {item.shiftRefs.length, plural, one {Schicht} other {Schichten}} nur abgeben</span></div>
							<p-radios-radio
								icon="long-arrow-alt-right"
								[label]="translatedLabelForRadioForPrefs.textContent!"
								[value]="false"
							></p-radios-radio>
						</p-radios>
					</p-bootstrap-form-group>
					<ng-container *ngIf="showDeadline">
						<p-ai-switch
							class="mt-3"
							[@slideVertical]
							[pEditable]="false"
							label="Frist für die Ersatzsuche" i18n-label
							description="Falls du keine Frist setzt, läuft die Ersatzsuche bis zum Ende der betroffenen Schicht.<br/>Bei einer Ersatzsuche für <strong>mehrere Schichten</strong>, werden diejenigen Schichten automatisch aus der Ersatzsuche <strong>entfernt</strong>, die in der Vergangenheit liegen. Für die verbleibenden Schichten läuft die Suche weiter." i18n-description
							[readMode]="readMode"
							[inputDateType]="PInputDateTypes.deadline"
							[group]="formGroup!"
							[attributeInfo]="item.attributeInfoDeadline"
							(innerInputChanged)="handleInnerDeadlineInputChanged($event)"
						></p-ai-switch>
						<p-alert [theme]="enums.PThemeEnum.DANGER" *ngIf="innerDeadlineInputHasValue && !item.shiftRefs.length" i18n>Füge zuerst Schichten hinzu, um eine Frist setzen zu können.</p-alert>
					</ng-container>
					<p-ai-switch
						class="mt-3"
						[pEditable]="false"

						[group]="formGroup!"
						[attributeInfo]="item.attributeInfoIndisposedMemberComment"
						[label]="indisposedMemberCommentLabel"
						[readMode]="readMode"
						[type]="AISwitchUIType.TEXTAREA"
					></p-ai-switch>
					<p-bootstrap-form-group
						*ngIf="showIllnessResponderCommentToMembers(readMode)"
						[class.d-none]="!(iAmTheNewResponsiblePersonForThisIllness || formGroup!.controls['illnessResponderCommentToMembers']!.value)"
						[class.d-block]="(iAmTheNewResponsiblePersonForThisIllness || formGroup!.controls['illnessResponderCommentToMembers']!.value)"
						[label]="illnessResponderCommentToMembersLabel"
						[control]="formGroup!.controls['illnessResponderCommentToMembers']!"
					>
						<p-textarea
							[readMode]="readMode"

							[formControl]="formGroup!.controls['illnessResponderCommentToMembers']!"
						></p-textarea>
					</p-bootstrap-form-group>

				</div>
				<div
					class="col-lg-1 d-flex align-items-center justify-content-center"
					*ngIf="item.newAssignedMemberId !== null"
				>
					<fa-icon class="mt-3 mb-3 fa-rotate-90 fa-rotate-md-0" [icon]="item.isSwappedSuccessful ? 'exchange-alt' : 'long-arrow-alt-right'"></fa-icon>
				</div>
				<div
					class="col-12"
					[class.col-md]="!Config.IS_MOBILE"
				>
					<ng-template [ngIf]="item.newAssignedMemberId !== null">
						<p-bootstrap-form-group *ngIf="item.isSwappedSuccessful; else newAssignedMemberTemplate">

							<label>
								<span><ng-container i18n>Getauscht mit</ng-container>&ngsp;</span>
								<span class="d-inline-flex align-items-center gap-2"><ng-container i18n>{item.shiftRefs.length, plural, one {Schicht} other {Schichten}} von</ng-container>
									<p-member-badge
										[absenceType]="item.newAssignedMember!.trashed ? 'trashed' : null"
										[memberId]="item.newAssignedMember!.id"
										[firstName]="item.newAssignedMember!.firstName"
										[lastName]="item.newAssignedMember!.lastName"
										[isMe]="!!isMe(item.newAssignedMember!.id)"
										[size]="'small'"
									></p-member-badge>
									{{item.newAssignedMember!.firstName}} {{item.newAssignedMember!.lastName}}
								</span>
							</label>
							<div class="rounded o-hidden" style="background-color: rgba(0, 0, 0, 0.03) !important;">
								<p-shifts-info
									class="hover-hide-trashed"
									*ngFor="let swappedShiftRef of item.swappedShiftRefs.iterable(); let i = index"
									[class.border-top]="i!==0"
									[shiftId]="swappedShiftRef.id"
								>
									<p-shift-info-content-left class="ml-2" *ngIf="item.newAssignedMember">
										<p-member-badge
											[absenceType]="item.newAssignedMember.trashed ? 'trashed' : null"
											[memberId]="item.newAssignedMember.id"
											[firstName]="item.newAssignedMember.firstName"
											[lastName]="item.newAssignedMember.lastName"
											[isMe]="isMe(item.newAssignedMember.id)!"
											[size]="'small'"
										></p-member-badge>
									</p-shift-info-content-left>
									<p-shift-info-content-right class="align-self-stretch">
										<a
											[routerLink]="pShiftExchangeService.calendarClickLink(swappedShiftRef)"
											class="btn rounded-0 btn-frameless border-left btn-outline-secondary align-self-stretch card-option"
											(click)="$event.stopPropagation();pShiftExchangeService.onCalendarBtnClick(swappedShiftRef)"
											title="Im Kalender ansehen" i18n-title
										>
										<!-- *ngIf="showMultiSelectCheckbox" -->
										<fa-icon [icon]="enums.PlanoFaIconPool.CALENDAR_SELECT_RELATED_SHIFTS"></fa-icon></a>
									</p-shift-info-content-right>
								</p-shifts-info>
							</div>
						</p-bootstrap-form-group>
						<ng-template #newAssignedMemberTemplate>
							<p-bootstrap-form-group label="Übernommen von" i18n-label>
								<p-input-member-id
									[api]="api"
									[readMode]="true"

									[ngModel]="item.newAssignedMemberId"
									[ngModelOptions]="{ standalone: true }"
									[members]="assignableMembersForShiftRefs"
								></p-input-member-id>
							</p-bootstrap-form-group>
						</ng-template>
					</ng-template>
					<p-bootstrap-form-group
						*ngIf="!(readMode && !(formGroup!.controls['memberIdAddressedTo']!.value !== null))"
						[class.d-none]="item.isIllness"
						[class.d-block]="!item.isIllness"
						[@slideVertical]
						label="Suche adressiert an" i18n-label
						[control]="formGroup!.controls['memberIdAddressedTo']!"
					>
						<p-input-member-id
							class="mb-3 d-block"
							[readMode]="readMode"

							[nullValueIsAllowed]="true"
							[formControl]="formGroup!.controls['memberIdAddressedTo']!"
							[members]="assignableMembersForShiftRefs"
						></p-input-member-id>
					</p-bootstrap-form-group>

					<p-bootstrap-form-group
						*ngIf="!readMode && showWillBeSentToManagersHint"
						[@slideVertical]
					>
						<label>&nbsp;</label>
						<p-alert [theme]="enums.PThemeEnum.INFO" i18n>Deine Krankmeldung wird der Personalleitung zugeschickt. Sobald diese bestätigt wird, wirst du aus deiner Schicht entfernt und bekommst darüber eine Benachrichtigung.</p-alert>
					</p-bootstrap-form-group>

					<p-warnings
						class="mb-2"
						[warnings]="warningsForOfferRecipient"
					></p-warnings>
				</div>
				<ng-template [ngIf]="item.behavesAsNewItem || !readMode && item.isIllness && isManagerForTheseShiftRefs">
					<ng-template [ngTemplateOutlet]="managerFormControlsTemplate"></ng-template>
				</ng-template>

			</div>

			<p-warnings
				class="mt-0 mb-2 mr-3 ml-3 rounded-bottom small shadow-sm"
				[disableAnimation]="true"
				[warnings]="nonMemberRelatedWarnings"
			></p-warnings>

		</p-section>

	</form>
</ng-template>


<ng-template #managerFormControlsTemplate>
	<div class="col-12"></div>
	<div class="col-md-6" *ngIf="item && (generateShiftExchangesIsPossible || (item.isNewItem() && iAmTheResponsiblePersonForThisIllness && !iAmTheIndisposedMember))">
		<p-alert
			class="mb-4"
			*ngIf="item.isNewItem() && iAmTheResponsiblePersonForThisIllness && !iAmTheIndisposedMember"
			[theme]="enums.PThemeEnum.INFO"
			[dismissable]="true"
			i18n
		>Hiermit entfernst du {!!indisposedMember, select, true {{{ indisposedMember?.firstName ?? 'die Person' }}} other {?}} aus {item.shiftRefs.length, plural, one {der Schicht} other {den Schichten}} und startest die Ersatzsuche. Alle berechtigten Mitarbeitenden werden automatisch gefragt, ob sie die {item.shiftRefs.length, plural, one {Schicht} other {Schichten}} übernehmen können. Über eine positive Antwort wirst du benachrichtigt.</p-alert>
		<p-generate-shift-exchanges-options
			*ngIf="generateShiftExchangesIsPossible"
			[@slideVertical]
			[formControl]="formGroup!.controls['generateShiftExchangesOptions']!"
			[shiftExchange]="item"
			#pGenerateShiftExchangesOptionsRef
		></p-generate-shift-exchanges-options>
	</div>

	<ng-template [ngIf]="generateShiftExchangesIsPossible && generateAbsencesIsPossible">
		<div class="col-12"><hr></div>
	</ng-template>
	<p-generate-absences-options
		*ngIf="generateAbsencesIsPossible && item"
		#pGenerateAbsencesOptionsRef
		class="col-12"
		[@slideVertical]
		[formControl]="formGroup!.controls['generateAbsencesOptions']!"
		[shiftRefs]="item.shiftRefs"
		[indisposedMemberId]="item.indisposedMemberId"
	></p-generate-absences-options>

</ng-template>
