import { Component, Input } from '@angular/core';

/**
 * Replacement for the usual h1,h2,...
 *
 * With this component you can just pass the hierarchy of the headline that you want,
 * specially useful if used inside other components that allow for different headline hierarchy,
 * as it doesn't force us to write all the possible changes everytime.
 */
@Component({
	selector: 'p-headline[level]',
	templateUrl: './p-headline.component.html',
	styleUrls: ['./p-headline.component.scss'],
})
export class PHeadlineComponent {
	/** The importance of the headline. It will match the html headline levels from h1,h2,h3... */
	@Input() protected level ! : 1 | 2 | 3 | 4 | 5 | 6;

    /** Classes to be added to the header */
    @Input() protected headlineClasses : string = '';
}
