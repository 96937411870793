/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays, import/no-relative-parent-imports -- Remove this before you work here. */
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClientSharedModule } from '@plano/client/shared/client-shared.module';
import { PCardModule } from '@plano/shared/core/component/card/card.module';
import { CoreModule } from '@plano/shared/core/core.module';
import { PFormsModule } from '@plano/shared/p-forms/p-forms.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PageModule } from '../shared/page/page.module';
import { AccessControlToggleComponent } from './accesscontrol-toggle/accesscontrol-toggle.component';
import { AccessControlComponent } from './accesscontrol.component';
import { RightgroupHeaderComponent } from './rightgroup-header/rightgroup-header.component';
import { RightsTableComponent } from './rights-table/rights-table.component';

@NgModule({
	imports: [
		BsDropdownModule.forRoot(),
		CoreModule,
		ClientSharedModule,
		PFormsModule,
		PageModule,
		PCardModule,
		BrowserAnimationsModule,
	],
	declarations: [
		AccessControlComponent,
		AccessControlToggleComponent,
		RightgroupHeaderComponent,
		RightsTableComponent,
	],
	providers: [
	],
	exports: [
		AccessControlComponent,
		RightsTableComponent,
		RightgroupHeaderComponent,
	],
})
export class AccesscontrolModule {}
