<span
	class="led-wrap"
	[class.led-is-on]="!off"
	[class.led-theme-success]="theme === enums.PThemeEnum.SUCCESS"
	[class.led-theme-info]="theme === enums.PThemeEnum.INFO"
	[class.led-theme-light]="theme === enums.PThemeEnum.LIGHT"
	[class.led-theme-primary]="theme === enums.PThemeEnum.PRIMARY"
	[class.led-theme-warning]="theme === enums.PThemeEnum.WARNING"
	[class.led-theme-danger]="theme === enums.PThemeEnum.DANGER"
>
	<span class="led"></span>
</span>
<span [id]="ariaLabelHtmlId">
	<ng-content></ng-content>
</span>
