import { Directive, ElementRef } from '@angular/core';

/**
 * Directive to add functionality to our tabindex elements, making them react to
 * space and enter inputs.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[tabindex]',
	standalone: true,
})
export class TabIndexDirective {

	constructor(private el : ElementRef<HTMLElement>) {
		this.el.nativeElement.addEventListener('keydown', this.handleConfirmationPressed.bind(this));
	}

	private handleConfirmationPressed(event : KeyboardEvent) : void {
		if (event.key===' ' || event.key==='Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.el.nativeElement.click();
		}
	}
}