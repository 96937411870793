import { SchedulingApiAssignmentProcessShiftRefsBase, SchedulingApiShifts } from '@plano/shared/api';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignmentProcessShiftRefs extends SchedulingApiAssignmentProcessShiftRefsBase {
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public containsAnyShift(shifts : SchedulingApiShifts) : boolean {
		return this.some((item) => {
			return shifts.some((shift) => item.id.equals(shift.id));
		});
	}
}
