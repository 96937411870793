import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { CalenderTimelineLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.service';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { NgbFormatsService } from '@plano/client/service/ngbformats.service';
import { MeService, RightsService, SchedulingApiAssignmentProcessState, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchanges } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subscription } from 'rxjs';

@Component({
	selector: 'p-shift-item-tooltip[shift]',
	templateUrl: './p-shift-item-tooltip.component.html',
	styleUrls: ['./p-shift-item-tooltip.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftItemTooltipComponent implements OnInit, OnDestroy {
	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public quickAssignmentTemplate : TemplateRef<unknown> | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public processInfoTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public linkedCourseInfoTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ! : SchedulingApiShift;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showProcessStatusIcon : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClickEdit = new EventEmitter<void>();

	/**
	 * Output for click remove on shift tooltip
	 */
	@Output() public onClickRemove = new EventEmitter<{
		shift : SchedulingApiShift,
		event : MouseEvent
	}>;

	/**
	 * User closes the tooltip
	 */
	@Output() public onClose = new EventEmitter<MouseEvent>();

	constructor(
		public me : MeService,
		public api : SchedulingApiService,
		public layout : CalenderTimelineLayoutService,

		public ngbFormats : NgbFormatsService,
		public rightsService : RightsService,
		private textToHtmlService : TextToHtmlService,
		private changeDetectorRef : ChangeDetectorRef,
	) {
	}

	public states : typeof SchedulingApiAssignmentProcessState = SchedulingApiAssignmentProcessState;
	public enums = enumsObject;

	private subscription : Subscription | null = null;

	public ngOnInit() : void {
		this.subscription = this.api.onChange.subscribe(() => {
			this.changeDetectorRef.markForCheck();
		});
	}

	public ngOnDestroy() : void {
		this.subscription?.unsubscribe();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showEditShiftButton() : boolean {
		if (this.readMode) return false;
		if (!this.rightsService.userCanRead(this.shift.model)) return false;
		return true;
	}

	/**
	 * Should we show the remove shift button on the tooltip?
	 */
	public get showRemoveShiftButton() : boolean {
		if (this.readMode) return false;
		if (!this.rightsService.userCanWrite(this.shift.model)) return false;
		return true;
	}

	/**
	 * Check if user can edit this shift
	 */
	public get userCanWrite() : boolean {
		return !!this.rightsService.userCanWrite(this.shift);
	}

	/**
	 * Turn the text into html [and crop it if wanted]
	 */
	public textToHtml(text : string, maxLength ?: number) : string {
		return this.textToHtmlService.textToHtml(text, maxLength, false);
	}

	/**
	 * Edit shift router link
	 */
	public get editShiftLink() : string {
		return `/client/shift/${this.shift.id.toUrl()}`;
	}

	/**
	* Handle remove click handler
	*/
	public removeClickHandler(event : MouseEvent) : void {
		this.onClickRemove.emit({shift: this.shift, event});
		if (!Config.IS_MOBILE) {
			this.onClose.emit(event);
		}
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get illnessShiftExchanges() : SchedulingApiShiftExchanges {
		return this.shiftExchanges.filterBy(item => {
			if (!item.isIllness) return false;

			// Only get the items where the illness is confirmed.
			if (this.shift.attributeInfoAssignedMemberIds.isAvailable && this.shift.assignedMemberIds.contains(item.indisposedMemberId)) return false;

			return true;
		});
	}

	private get shiftExchanges() : SchedulingApiShiftExchanges {
		if (!this.api.data.attributeInfoShiftExchanges.isAvailable) return new SchedulingApiShiftExchanges(null, null);
		return this.api.data.shiftExchanges.filterBy(item => item.shiftRefs.contains(this.shift.id));
	}
}
