import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { UniqueAriaLabelByWithControlWithEditableDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { FaIconComponent } from '@plano/shared/core/component/fa-icon/fa-icon.component';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';

type ValueType = string;

/**
 * @deprecated Please use `p-radios` in combination with `p-radios-radio` instead.
 * @see PRadiosComponent
 */
@Component({
	selector: 'p-radio',
	templateUrl: './p-radio.component.html',
	styleUrls: ['./p-radio.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PRadioComponent),
			multi: true,
		},
	],
})
export class PRadioComponent extends UniqueAriaLabelByWithControlWithEditableDirective
	implements ControlValueAccessor, EditableControlInterface {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public btn : boolean = true;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public checkedChange : EventEmitter<Event> = new EventEmitter<Event>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public checked : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public card : boolean = false;
	@Input() public icon : FaIconComponent['icon'] | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public formControl : PFormControl | null = null;

	constructor(
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,
		protected override console ?: LogService,
	) {
		super(false, changeDetectorRef, pFormsService, console);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get radioIcon() : FaIconComponent['icon'] | null {
		if (this.icon) return this.icon;
		if (this.checked || (this.hover && !this.disabled)) return enumsObject.PlanoFaIconPool.RADIO_SELECTED;
		return enumsObject.PlanoFaIconPool.RADIO_UNSELECTED;
	}

	public hover : boolean = false;

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.formControl && this.formControl.disabled !== this.disabled) {
			this.disabled ? this.formControl.disable() : this.formControl.enable();
		}
	}
}
