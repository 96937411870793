import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CalenderTimelineLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.service';
import { ILayout } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.types';

@Component({
	selector: 'p-timeline-now-line',
	templateUrl: './timeline-now-line.component.html',
	styleUrls: ['./timeline-now-line.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTimelineNowLineComponent {
	constructor(
		private layoutService : CalenderTimelineLayoutService,
	) {
	}

	/** Get layout data for the now line */
	public get layout() : ILayout {
		return this.layoutService.getLayout(this.layoutService.NOW_LINE);
	}
}
