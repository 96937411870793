import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DateInfo, FormattedDateTimePipe } from '@plano/client/shared/formatted-date-time.pipe';
import { PSimpleChanges, SchedulingApiService, SchedulingApiWorkingTime } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { notNull } from '@plano/shared/core/utils/null-type-utils';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-workingtime-list-item[workingTime][min][max]',
	templateUrl: './workingtime-list-item.component.html',
	styleUrls: ['./workingtime-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class WorkingtimeListItemComponent implements OnChanges {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public workingTime ! : SchedulingApiWorkingTime;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public min ! : number;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public max ! : number;

	constructor(
		private api : SchedulingApiService,
		private formattedDateTimePipe : FormattedDateTimePipe,
	) {}

	public readonly CONFIG = Config;

	public ngOnChanges(changes : PSimpleChanges<WorkingtimeListItemComponent>) : void {
		if (changes.workingTime) {
			this._dateInfo = this.getDateInfo();
		}
	}

	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public getDateInfo() : DateInfo {
		return this.formattedDateTimePipe.getFormattedDateInfo(
			this.workingTime.time.start,
			this.workingTime.time.end,
			true,
			undefined,
			!Config.IS_MOBILE,
		);
	}

	/**
	 * Id for this working time
	 */
	public get workingTimeId() : string {
		return `${this.workingTime.id.toString()}`;
	}

	private _dateInfo : DateInfo | null = null;

	/**
	 * Get the formatted date info for the working time
	 */
	public get dateInfo() : DateInfo {
		if (!this._dateInfo) {
			this._dateInfo = this.getDateInfo();
		}
		return this._dateInfo;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get memberName() : string | null {
		if (!this.workingTime.member) return null;
		return this.workingTime.member.firstName;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shiftModelName() : string | undefined {
		if (!this.workingTime.attributeInfoShiftModelId.value) return undefined;
		if (!this.api.isLoaded()) return undefined;
		const model = notNull(this.api.data.shiftModels.get(this.workingTime.shiftModelId));
		return model.name;

	}
}
