<p-checkbox
	*ngIf="!neverShowDayTools && shiftsAreSelectable"
	[class.only-show-on-hover]="!shiftsAreSelected"
	class="border-right multi-select-checkbox bg-white small o-hidden"
	[size]="enums.BootstrapSize.MD"
	[hasPaddingX]="true"
	[hasPaddingY]="false"
	[hasButtonStyle]="false"
	[ngModel]="shiftsAreSelected"
	(onClick)="selectShifts($event)"
	[disabled]="!shiftsAreSelectable"
></p-checkbox>
<div class="flex-grow-1 d-flex justify-content-center align-items-center">
	<span class="cal-day-number ml-2 mr-2 nowrap">{{ dayStart | date: dateFormat }}</span>
	<span #noteIconTitle hidden i18n>{shiftsOfDayHaveDescriptions, select, true {Schichtkommentar für dich} other {Kommentare}}</span>
	<p-button
		*ngIf="!neverShowDayTools"
		class="mr-2 sticky-note-button"
		[class.d-none]="!canSetMemos && !pinStickyNote"
		[class.only-show-on-hover]="!pinStickyNote"
		[size]="enums.BootstrapSize.SM"
		[theme]="isToday ? PBtnThemeEnum.OUTLINE_SECONDARY : enums.PThemeEnum.LIGHT"
		btnClasses="border-0"
		(triggerClick)="editComments(commentsModalContent.template, $event)"
		[btnTitle]="noteIconTitle.innerText"

		(mouseover)="hover = true"
		(mouseleave)="hover = false"
	>
		<p-sticky-note
			[backgroundColor]="isToday ? (hover ? enums.PThemeEnum.DARK : enums.PThemeEnum.PRIMARY) : null"
			[showDot]="shiftsOfDayHaveDescriptions"
		></p-sticky-note>
	</p-button>
</div>

<ng-template #commentsModalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-content
		modalTitle="Kommentare am {{ dayStart | date:'shortDate' }}" i18n-modalTitle
		closeBtnLabel="OK" i18n-closeBtnLabel
		[hideDismissBtn]="true"
		(onDismiss)="d($event)"
		(onClose)="c($event);"
		size="frameless"
	>
		<p-modal-content-body>
			<div class="m-3" aria-labelledby="day-comment-input-label">
				<label id="day-comment-input-label" i18n>Tageskommentar</label>
				<p-day-comment
					role="listitem"
					class="card o-hidden"
					[clickable]="!!canSetMemos"
					[showDateInput]="true"
					[dayStart]="dayStart"
					[maxTextLength]="false"
					[maxLines]="false"
				></p-day-comment>
			</div>
			<div aria-labelledby="shift-notes-label" class="m-3">
				<label id="shift-notes-label" i18n>Schichtkommentare</label>
				<p-shift-comments
					*ngIf="shiftsForCommentsModal.length; else noShiftComments"
					[shiftsForList]="shiftsForCommentsModal"
					[date]="dayStart"
					class="border-right"
				></p-shift-comments>
				<ng-template #noShiftComments>
					<div class="card card-body text-center text-muted text-small" i18n>Keine Schichtkommentare vorhanden. Um welche zu hinterlassen, gehe in die gewünschte Schicht.</div>
				</ng-template>
			</div>
		</p-modal-content-body>
	</p-modal-content>
</ng-template>
