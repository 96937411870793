import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { SLIDE_HORIZONTAL_ON_NGIF_TRIGGER } from '@plano/animations';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { IShiftItemComponent, ShiftItemComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-shift-item-module/shift-item/shift-item.component';
import { TextToHtmlService } from '@plano/client/scheduling/shared/text-to-html.service';
import { BootstrapRounded } from '@plano/client/shared/bootstrap-styles.enum';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { ApiListWrapper, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState, SchedulingApiMember, SchedulingApiShift } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'p-shift-item-timeline[shift][showCourseInfo][processStatusIconTemplate][memberBadgesTemplate][quickAssignmentTemplate]',
	templateUrl: './shift-item-timeline.component.html',
	styleUrls: ['./shift-item-timeline.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [ SLIDE_HORIZONTAL_ON_NGIF_TRIGGER ],
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ShiftItemTimelineComponent implements PComponentInterface, OnDestroy, IShiftItemComponent {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public processStatusIconTemplate ! : TemplateRef<unknown>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public quickAssignmentTemplate ! : TemplateRef<unknown>;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftExchangeIconsTemplate : TemplateRef<unknown> | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public linkedCourseInfoTemplate : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public memberBadgesTemplate ! : TemplateRef<unknown>;

	@ViewChild('tooltipRef', { static: true }) private popperContent ! : NgxPopperjsContentComponent;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public muteItem : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftIsSelectable : boolean = false;

	/**
	 * With this boolean the multi-select checkboxes can be turned off for this shift
	 */
	@Input() public selectable : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public selectedChange : EventEmitter<boolean> = new EventEmitter<boolean>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public process : SchedulingApiAssignmentProcess | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public meIsAssignable : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showAssignMeButton : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showMultiSelectCheckbox : ShiftItemComponent['showMultiSelectCheckbox'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showProcessStatusIcon : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showCourseInfo ! : boolean;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ! : SchedulingApiShift;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public assignedMembers : ApiListWrapper<SchedulingApiMember> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isInThePast : boolean = false;

	/**
	* Close tooltip if any and open shift removal modal
	*/
	@Output() public onClickRemove = new EventEmitter<{
			shift : SchedulingApiShift,
			event : MouseEvent,
		}>();

	constructor(
		public highlightService : HighlightService,
		private textToHtmlService : TextToHtmlService,
		public schedulingService : SchedulingService,
	) {
		// update tooltip visibility
		this.subscription = this.highlightService.onChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe( () => {
			const highlighted = this.highlightService.isHighlighted(this.shift);
			if (highlighted !== this.showTooltip) this.showTooltip = highlighted;

		});
	}

	public readonly CONFIG = Config;

	public showTooltip : boolean = false;
	public states = SchedulingApiAssignmentProcessState;
	private ngUnsubscribe : Subject<void> = new Subject<void>();
	public NgxPopperjsTriggers = NgxPopperjsTriggers;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;
	public BootstrapRounded = BootstrapRounded;

	private subscription : Subscription | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onCloseShiftTooltip(event : MouseEvent) : void {
		event.stopPropagation();
		this.highlightService.setHighlighted(null);
	}

	public ngOnDestroy() : void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.subscription?.unsubscribe();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hexColor() : string {
		return this.shift.model.color;
	}

	/**
	 * Turn the text into html [and crop it if wanted]
	 */
	public textToHtml(text : string) : string {
		return this.textToHtmlService.textToHtml(text, false, false);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get multiSelectIsPossible() : ShiftItemTimelineComponent['showMultiSelectCheckbox'] {
		// Shift-Related rules
		if (!this.selectable) return false;

		// Environment-Related rules
		return !!this.showMultiSelectCheckbox;
	}
}
