import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { ErrorArray, ShiftMemberExchangeService } from '@plano/client/shared/p-shift-module/shift-member-exchange.service';
import { MeService, SchedulingApiMember, SchedulingApiMembers, SchedulingApiService, SchedulingApiShift } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-quick-assignment[shift]',
	templateUrl: './quick-assignment.component.html',
	styleUrls: ['./quick-assignment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class QuickAssignmentComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private readMode : boolean = false;

	@HostBinding('class.d-flex')
	@HostBinding('class.align-items-stretch') protected _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ! : SchedulingApiShift;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : typeof enumsObject.BootstrapSize.SM | null = null;

	constructor(
		public modalService : ModalService,
		public api : SchedulingApiService,
		public me : MeService,
		private shiftMemberExchangeService : ShiftMemberExchangeService,
		private console : LogService,
	) {
	}

	public readonly CONFIG = Config;
	protected readonly enums = enumsObject;

	public selectedMember : SchedulingApiMember | null = null;

	/**
	 * Get an array of errors
	 */
	public get errors() : ErrorArray {
		if (!this.selectedMember) return [];
		return this.shiftMemberExchangeService.errors(this.selectedMember, this.shift);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public openQuickAssignModal(
		event : Event,
		modalContent : TemplateRef<PModalTemplateDirective>,
	) : void {
		event.stopPropagation();
		void this.modalService.openModal(modalContent).result
			.then(value => {
				if (value.modalResult === 'success') {
					this.assignSelectedMember();
					this.clear();
				} else {
					this.clear();
				}
			});
	}

	/**
	 * create a list of assignable members for the input-member
	 */
	public get membersForList() : SchedulingApiMembers {
		const result = new SchedulingApiMembers(this.api, null);
		for (const member of this.api.data.members.iterable()) {
			if (
				!this.shift.assignedMemberIds.contains(member.id) &&
				this.shift.assignableMembers.contains(member.id)
			) {
				result.push(member);
			}
		}
		return result;
	}

	/**
	 * Assign member to this shift and save it to the database
	 */
	public assignSelectedMember() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.selectedMember, 'selectedMember');
		this.shift.assignedMemberIds.push(this.selectedMember.id);
		this.shift.saveDetailed();
	}

	/**
	 * Clear the temporary data to make the component reusable
	 */
	private clear() : void {
		this.selectedMember = null;
	}

	/**
	 * Should it be possible to the user to assign a member to this shift?
	 */
	public get isEnabled() : boolean {
		if (this.readMode) return false;
		if (!this.shift.attributeInfoAssignedMemberIds.canSet) return false;
		return true;
	}

	/**
	 * Get the cannot set hint
	 * @return The cannot set hint or null if it is not necessary to describe why the user cannot set the value
	 */
	protected get cannotSetHint() : PDictionarySource | null {
		if (this.shift.attributeInfoAssignedMemberIds.canSet) return null;
		return this.shift.attributeInfoAssignedMemberIds.cannotSetHint;
	}

	/**
	 * Open a modal with the text of the cannotSetHint
	 */
	public openCannotSetHint() : void {
		assumeNonNull(this.cannotSetHint);
		this.modalService.openCannotSetHintModal(this.cannotSetHint);
	}
}
