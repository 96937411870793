/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "export_billing_data".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
}


@Injectable({
  providedIn: 'root',
})
export class ExportBillingDataApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'export_billing_data');
	}

	protected version() : string {
		return '054dd9abd563f312df819607206006f0,c74781c76e08af1e0b103c930d7f49b2';
	}

	private dataWrapper = new ExportBillingDataApiRoot(this, );

	get data() : ExportBillingDataApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ExportBillingDataApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ExportBillingDataApiRoot(this, );
	}
}

		 
export class ExportBillingDataApiRoot extends ApiObjectWrapper<ExportBillingDataApiRoot>
{
	constructor(override readonly api : ExportBillingDataApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ExportBillingDataApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, ExportBillingDataApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ExportBillingDataApiRoot, ExportBillingDataApiRoot> = new ApiAttributeInfo<ExportBillingDataApiRoot, ExportBillingDataApiRoot>({
			apiObjWrapper: this as any as ExportBillingDataApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToGet: function(this : ExportBillingDataApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			hasRightToSet: function(this : ExportBillingDataApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			defaultValue: function(this : ExportBillingDataApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : ExportBillingDataApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}



