import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PCalendarService } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { PSidebarService } from '@plano/client/shared/p-sidebar/p-sidebar.service';
import { SidebarTab } from '@plano/client/shared/p-sidebar/p-sidebar.types';
import { PTabComponent, PTabSizeEnum } from '@plano/client/shared/p-tabs/p-tabs/p-tab/p-tab.component';
import { PTabsComponent } from '@plano/client/shared/p-tabs/p-tabs/p-tabs.component';
import { ClientRoutingService, CurrentPageEnum } from '@plano/client/shared/routing.service';
import { MeService, PShiftExchangeConceptService, RightsService, SchedulingApiMember, SchedulingApiMembers, SchedulingApiService, SchedulingApiShiftExchanges, SchedulingApiShiftModel, SchedulingApiShiftModels, SchedulingApiShifts } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { LogService } from '@plano/shared/core/log.service';
import { PRouterService } from '@plano/shared/core/router.service';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subscription } from 'rxjs';

@Component({
	selector: 'p-main-sidebar',
	templateUrl: './main-sidebar.component.html',
	styleUrls: ['./main-sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [],
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class MainSidebarComponent implements OnDestroy {

	@ViewChild('pTabsComponent') private pTabsComponent ?: PTabsComponent;

	@ViewChild('deskTab') private deskTab ?: PTabComponent;
	@ViewChild('shiftModelsTab') private shiftModelsTab ?: PTabComponent;
	@ViewChild('membersTab') private membersTab ?: PTabComponent;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shifts : SchedulingApiShifts | null = null;

	/**
	 * Should the collapse button on the sidebar be shown?
	 */
	@Input() public showCollapseButtonOnSidebar : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onSelectRelatedShifts : EventEmitter<SchedulingApiShiftModel | SchedulingApiMember> =
		new EventEmitter<SchedulingApiShiftModel | SchedulingApiMember>();

	constructor(
		public api : SchedulingApiService,
		public filterService : FilterService,
		public pSidebarService : PSidebarService,
		public me : MeService,
		private pCalendarService : PCalendarService,
		private pShiftExchangeConceptService : PShiftExchangeConceptService,
		private clientRoutingService : ClientRoutingService,
		private console : LogService,
		private rightsService : RightsService,
		private pRouterService : PRouterService,
		private activatedRoute : ActivatedRoute,
	) {
		this.pSidebarServiceSub = this.pSidebarService.onChange.subscribe(() => {
			const currentTab = this.getTabComponentFromCurrentTab();
			if (currentTab && currentTab !== this.pTabsComponent?.activeTab) {
				this.pTabsComponent?.selectTab(currentTab);
			}
		});
	}

	private getTabComponentFromCurrentTab() : PTabComponent | null {
		switch (this.pSidebarService.currentTab) {
			case SidebarTab.DESK: return this.deskTab!;
			case SidebarTab.SHIFT_MODELS: return this.shiftModelsTab!;
			case SidebarTab.MEMBERS: return this.membersTab!;
			default: return null;
		}
	}

	/**
	 * Open the tab passed as a parameter
	 */
	public openTab(sidebarTab : SidebarTab) : void {
		this.pSidebarService.currentTab = sidebarTab;

		const storedRoute = this.pRouterService.storedUrls.mostRecent;
		if (!storedRoute) return;
		switch (sidebarTab) {
			case SidebarTab.DESK:
				storedRoute.queryParams = {...storedRoute.queryParams, showDeskTab: 'true', showMembersTab: 'false', showShiftModelTab : 'false'};
				break;
			case SidebarTab.SHIFT_MODELS:
				storedRoute.queryParams = {...storedRoute.queryParams, showDeskTab: 'false', showMembersTab: 'false', showShiftModelTab : 'true'};
				break;
			case SidebarTab.MEMBERS:
				storedRoute.queryParams = {...storedRoute.queryParams, showDeskTab: 'false', showMembersTab: 'true', showShiftModelTab : 'false'};
				break;
		}
	}

	public ngOnDestroy() : void {
		this.pSidebarServiceSub.unsubscribe();
	}

	private pSidebarServiceSub : Subscription;

	public enums = enumsObject;
	public PTabSizeEnum = PTabSizeEnum;
	public SidebarTab = SidebarTab;

	public config : typeof Config = Config;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onSelectShiftsRelatedToMember(id : Id) : void {
		const member = this.api.data.members.get(id)!;
		this.onSelectRelatedShifts.emit(member);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onSelectShiftsRelatedToShiftModel(id : Id) : void {
		const shiftModel = this.api.data.shiftModels.get(id)!;
		this.onSelectRelatedShifts.emit(shiftModel);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hideMultiSelectBtn() : boolean {
		return this.clientRoutingService.currentPage !== CurrentPageEnum.SCHEDULING;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftExchanges() : SchedulingApiShiftExchanges {
		if (!this.api.isLoaded()) return new SchedulingApiShiftExchanges(null, null);
		return this.api.data.shiftExchanges;
	}

	private _shiftModelsForList = new Data<SchedulingApiShiftModels>(this.api);

	/**
	 * Show shift models for the activities tab.
	 *
	 * @returns the list of shift models or undefined if list is still loading and null if no shift models could be found
	*/
	public get shiftModelsForList() : SchedulingApiShiftModels | undefined | null {
		if (this.api.data.attributeInfoShiftModels.isAvailable === undefined) return undefined;
		else if (!this.api.data.attributeInfoShiftModels.isAvailable) return null;

		// TODO: PLANO-155026
		return this._shiftModelsForList.get(() => {
			return this.api.data.shiftModels.filterBy((item) => this.rightsService.userCanRead(item) === true);
		});
	}

	/**
	 * Show members for the users tab.
	 *
	 * @returns the list of members or undefined if list is still loading and null if no members could be found
	*/
	public get membersForList() : SchedulingApiMembers | undefined | null {
		if (this.api.data.attributeInfoMembers.isAvailable === undefined) return undefined;
		else if (!this.api.data.attributeInfoMembers.isAvailable) return null;
		return this.api.data.members;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasImportantNoteForToday() : boolean | null {
		return this.pCalendarService.hasImportantNoteForDay();
	}

	/**
	 * Navigate to the page with the list of shift-exchanges
	 */
	public navToShiftExchanges() : void {
		this.api.deselectAllSelections();
		this.api.deselectAllSelections();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftExchangesForList() : SchedulingApiShiftExchanges {
		return this.shiftExchanges.filterBy(item => {

			// FIXME: PLANO-1389
			if (!this.api.data.members.length) { this.console.error('Members list is empty. (Probably PLANO-1389)'); return false; }

			assumeDefinedToGetStrictNullChecksRunning(item.indisposedMember, 'item.indisposedMember');
			if (!this.filterService.isVisible(item.indisposedMember)) return false;
			if (item.shiftModel && !this.filterService.isVisible(item.shiftModel)) return false;
			return true;
		});
	}

	private get shiftExchangeTodoCountTotal() : number {
		let result = 0;
		for (const shiftExchange of this.shiftExchangesForList.iterable()) {
			if (!shiftExchange.showOnDesk) continue;
			if (shiftExchange.todoCount) {
				result += shiftExchange.todoCount;

			} else if (this.pShiftExchangeConceptService.getBadgeIcon(shiftExchange)) {
				result += 1;
			}
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get badgeContent() : number {
		let result = this.shiftExchangeTodoCountTotal;

		if (this.api.data.attributeInfoAssignmentProcesses.isAvailable) {
			for (const iterator of this.api.data.assignmentProcesses.iterable()) {
				if (this.rightsService.userCanSetAssignmentProcess(iterator)) continue;
				result += iterator.todoShiftsCountTotal;
			}
		}

		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasTodaysShiftDescriptionsForMember() : boolean {
		return !!this.pCalendarService.shiftsOfDayHaveDescriptions(undefined, { onlyForUser: true });
	}
}
