<p-section *ngIf="formGroup" class="mt-3" label="Ersatzsuche" i18n-label pAnchorLink="replacement-request" [whitespace]="SectionWhitespace.NONE">
	<p-bootstrap-form-group
		label="Wie möchtest du diese {{shiftExchange.shiftRefs.length}} Schichten deinen Mitarbeitenden anbieten?" i18n-label
		*ngIf="showModeInput"
	>
		<p-radios [formControl]="formGroup.controls['mode']!">
			<p-radios-radio
				label="Schichten getrennt voneinander" i18n-label
				[value]="GenerateShiftExchangesMode.ONE_SHIFT_EXCHANGE_FOR_EACH"
				class="mb-2"
			></p-radios-radio>
			<p-radios-radio
				*ngIf="shiftRefsHasMultipleShiftsOfSamePacket"
				label="Jedes Schicht-Paket einzeln" i18n-label
				[value]="GenerateShiftExchangesMode.ONE_SHIFT_EXCHANGE_FOR_EACH_PACKAGE"
				class="mb-2"
			></p-radios-radio>
			<p-radios-radio
				label="Alle Schichten gebündelt" i18n-label
				[value]="null"
				class="mb-2"
			></p-radios-radio>
		</p-radios>

		<p-alert
			class="mt-3"
			*ngIf="value && value.mode === null"
			[theme]="enums.PThemeEnum.INFO"
			[dismissable]="false"
			i18n
		>Ein gebündeltes Schicht-Angebot muss von einer einzigen Person komplett übernommen werden.</p-alert>
	</p-bootstrap-form-group>

	<p-bootstrap-form-group
		label="Frist für die Ersatzsuche" i18n-label
		[control]="formGroup.controls['deadline']"
	>

		<p-input-date
			*ngIf="showDaysBeforeForDeadline"
			[type]="PInputDateTypes.deadline"
			[formControl]="formGroup.controls['deadline']"
			[min]="now"
			[max]="deadlineMax!"

			[showDaysBeforeInput]="true"
			[(daysBefore)]="daysBefore"
			[daysBeforeInputDisabled]="false"
		></p-input-date>
		<p-input-date
			*ngIf="!showDaysBeforeForDeadline"
			[type]="PInputDateTypes.deadline"
			[formControl]="formGroup.controls['deadline']"
			[min]="now"
			[max]="deadlineMax!"
		></p-input-date>
		<p-alert
			class="mt-2"
			*ngIf="formGroup.controls['deadline'].valid && value && daysBefore !== null"
			[@slideVertical]
			[theme]="enums.PThemeEnum.INFO"
		>
			<ng-template [ngIf]="shiftExchange.shiftRefs.length > 1 && value.mode === GenerateShiftExchangesMode.ONE_SHIFT_EXCHANGE_FOR_EACH" [ngIfElse]="singleShiftExchangeTemplate">
				<span i18n>Sich daraus ergebende Fristen</span>
				<ul class="m-0">
					<li *ngFor="let shiftRef of sortedShiftRefs">{{ removeDaysFromTimestamp(api.data.shifts.get(shiftRef.id)!.start, daysBefore) - 1 | date:'shortDate' }}</li>
				</ul>
			</ng-template>
			<ng-template #singleShiftExchangeTemplate>
				<span i18n>Sich daraus ergebende Frist: {{ removeDaysFromTimestamp(api.data.shifts.get(shiftExchange.shiftRefs.get(shiftExchange.shiftRefs.length - 1)!.id)!.start, daysBefore) - 1 | date:'shortDate' }}</span>
			</ng-template>
		</p-alert>
	</p-bootstrap-form-group>

	<p-bootstrap-form-group
		[label]="illnessResponderCommentToMembersLabel"
		[control]="formGroup.controls['illnessResponderCommentToMembers']!"
	>
		<p-textarea
			[formControl]="formGroup.controls['illnessResponderCommentToMembers']!"
		></p-textarea>
	</p-bootstrap-form-group>
</p-section>
