<ng-template [ngIf]="!!calendarMode && !!selectedDate">
	<div class="d-flex align-items-stretch justify-content-between">
		<h5 class="crop-on-overflow mb-0 pt-1 pb-1 pl-2 pl-lg-3 pr-3 d-flex align-items-center">
			<span class="d-xxl-none">{{ selectedDate | calendarTitle:calendarMode:true }}</span>
			<span class="d-none d-xxl-block">{{ selectedDate | calendarTitle:calendarMode:false }}</span>

			<p-button
				class="ml-2 h-100 nowrap"
				[size]="enums.BootstrapSize.SM"
				(triggerClick)="inputDateClick()"
				pTooltip="Anderes Datum auswählen" i18n-pTooltip
				ariaLabel="Anderes Datum auswählen" i18n-ariaLabel
			>
				<fa-icon [icon]="enums.PlanoFaIconPool.STATE_DATE_PICKED"></fa-icon>
			</p-button>
		</h5>

		<p-multi-selection-btn
			*ngIf="!config.IS_MOBILE && api.hasSelectedItems"
			style="max-width: 500px;"
		></p-multi-selection-btn>
		<div
			*ngIf="config.IS_MOBILE || !schedulingService.wishPickerMode && !schedulingService.earlyBirdMode; else someSpecialMode"
			class="d-flex justify-content-end align-items-stretch pt-1 pb-1 pr-2 pr-lg-3"
		>
			<ng-template [ngIf]="showMemoOfTodayButton">
				<button
					type="button"
					[class.d-flex]="!(api.isLoaded() && api.hasSelectedItems || schedulingService.earlyBirdMode)"
					[class.d-none]="api.isLoaded() && api.hasSelectedItems || schedulingService.earlyBirdMode"
					class="btn btn-sm btn-outline-secondary align-items-center d-525px-flex"
					(click)="onStickyNoteClick(modalContent.template)"
					(touchend)="$event.stopPropagation()"
					pTooltip="Einen Tageskommentar hinzufügen, der unabhängig von der Schichtbesetzung für das gesamte Team zu lesen sein wird." i18n-pTooltip
				>
					<!-- [backgroundColor]="dateIsToday(selectedDate) ? enums.PThemeEnum.DARK : null" -->
					<p-sticky-note
						height="16"
						[backgroundColor]="null"
					/>
				</button>
			</ng-template>
			<div class="ml-2 d-flex">
				<ng-template [ngIf]="rightsService.isOwner || rightsService.userCanWriteAnyShiftModel || userCanManageBookings">
					<p-dropdown
						[wrapLabel]="false"
						[icon]="enums.PlanoFaIconPool.ADD"
						[btnStyle]="PBtnThemeEnum.OUTLINE_PRIMARY"
						[size]="enums.BootstrapSize.SM"
						label="Neu" i18n-label
						[hideTriggerLabel]="spaceIsRare"
						[dropdownType]="DropdownTypeEnum.ACTIONS"
					>
						<div hidden><span i18n #translatedAddShift>{!!spaceIsRare, select, true {Neue }}Schicht</span></div>
						<p-dropdown-item
							[isAlignedLeft]="true"
							*ngIf="rightsService.userCanWriteAnyShiftModel"
							[label]="translatedAddShift.textContent!"
							[link]="showShiftDetails()"
							[isLoading]="!api.isLoaded()"
						></p-dropdown-item>
						<div hidden><span i18n #translatedAddAbsence>{!!spaceIsRare, select, true {Neue }}Abwesenheit</span></div>
						<p-dropdown-item
							*ngIf="rightsService.isOwner"
							[label]="translatedAddAbsence.textContent!"
							[isAlignedLeft]="true"
							[link]="showAbsenceDetails()"
							[isLoading]="!api.isLoaded()"
						></p-dropdown-item>
						<div hidden><span i18n #translatedAddBooking>{!!spaceIsRare, select, true {Neue }}Buchung</span></div>
						<p-dropdown-item
							[isAlignedLeft]="true"
							*ngIf="userCanManageBookings"
							[label]="translatedAddBooking.textContent!"
							[link]="showBookingDetails()"
							[isLoading]="!api.isLoaded()"
						></p-dropdown-item>
					</p-dropdown>
				</ng-template>
				<p-button
					*ngIf="rightsService.isOwner"
					[class.d-flex]="!(hideEvaluateAndExportButtons || showMemoOfTodayButton)"
					[class.d-none]="hideEvaluateAndExportButtons || showMemoOfTodayButton"
					class="ml-2 nowrap crop-on-overflow d-525px-flex"
					[size]="enums.BootstrapSize.SM"
					[disabled]="!api.isLoaded()"
					(triggerClick)="evaluateShiftPlan()"
					pTooltip="Schichtplan von Dr.&nbsp;Plano auf Probleme prüfen lassen." i18n-pTooltip
				>
					<fa-icon [icon]="enums.PlanoFaIconPool.EVALUATE_SHIFT_PLAN"></fa-icon>
				</p-button>

				<!--TODO: PLANO-174740-->
				<p-button
					[class.d-flex]="!hideEvaluateAndExportButtons && config.platform==='browser'"
					[class.d-none]="hideEvaluateAndExportButtons || config.platform!=='browser'"
					class="ml-2 nowrap crop-on-overflow d-525px-flex"
					[size]="enums.BootstrapSize.SM"
					[disabled]="isApiLoading"
					(triggerClick)="exportShifts()"
					pTooltip="Angezeigte Schichten als Excel-Datei exportieren." i18n-pTooltip
				>
					<fa-icon [icon]="exportIsRunning ? enums.PlanoFaIconPool.SYNCING : enums.PlanoFaIconPool.EXPORT" [spin]="exportIsRunning"></fa-icon>
				</p-button>
			</div>
			<ng-container *ngTemplateOutlet="earlyBirdButton"></ng-container>
			<ng-container *ngTemplateOutlet="freeWishesButton"></ng-container>
		</div>
		<ng-template #someSpecialMode>
			<ng-container *ngTemplateOutlet="wishPickerAndCloseEarlyBirdBtnTemplate"></ng-container>
		</ng-template>
	</div>
	<div *ngIf="showMemoOfTodayBar" class="border-top p-0 d-flex align-items-stretch">
		<blockquote class="btn-light btn-frameless clickable flex-grow-1 text-style-reset-to-body text-left p-2 px-lg-3 d-flex o-hidden"
			(click)="editMemo(modalContent.template)"
		>
			<p-sticky-note class="mr-2"></p-sticky-note>
			<span
				class="flex-grow-1 crop-on-overflow"
			>{{memoOfToday!.message}}</span>
		</blockquote>
	</div>
	<div *ngIf="config.IS_MOBILE && schedulingService.wishPickerMode">
		<div class="d-flex border-top justify-content-end">
			<ng-container *ngTemplateOutlet="wishPickerAndCloseEarlyBirdBtnTemplate"></ng-container>
		</div>
	</div>
</ng-template>

<ng-template #wishPickerAndCloseEarlyBirdBtnTemplate>
	<!-- [badge]="freeEarlyBirdSeatsCount" -->
	<p-button
		[@slideInHorizontal]
		*ngIf="!config.IS_MOBILE && schedulingService.earlyBirdMode"
		class="ml-2 nowrap flex-nowrap"
		[wrapLabel]="false"
		[rounded]="BootstrapRounded.NONE"
		[theme]="enums.PThemeEnum.DARK"
		[size]="enums.BootstrapSize.SM"
		[disabled]="!api.isLoaded()"
		(triggerClick)="stopMode()"
		btnTitle="Modus beenden" i18n-btnTitle
	><p-assignment-process-icon
		*ngIf="schedulingService.earlyBirdMode"
		class="nowrap d-inline-block"
		[class.text-white]="true"
		[state]="states.EARLY_BIRD_SCHEDULING"
		[isOwner]="!!rightsService.isOwner"
		[animatedBird]="true"
	></p-assignment-process-icon>&nbsp;<span class="nowrap" i18n>Beenden</span></p-button>
	<p-wish-picker
		*ngIf="schedulingService.wishPickerMode"
		[shifts]="api.data.shifts.selectedItems"
		[api]="api"
	></p-wish-picker>
</ng-template>

<ng-template #earlyBirdButton>
	<p-button
		*ngIf="freeEarlyBirdSeatsCount"
		[badge]="schedulingService.earlyBirdMode ? null : freeEarlyBirdSeatsCount"
		class="d-flex ml-2"
		[size]="enums.BootstrapSize.SM"
		[theme]="!schedulingService.earlyBirdMode ? enums.PThemeEnum.SECONDARY : enums.PThemeEnum.DARK"
		pTooltip="Es sind Schichten vorhanden, die du sofort übernehmen kannst. Deine Kollegen aber auch. Also beeil dich: Der frühe Vogel fängt den Wurm!" i18n-pTooltip
		[disabled]="!api.isLoaded()"
		(triggerClick)="onStartEarlyBirdMode.emit(!schedulingService.earlyBirdMode)"
	>
		<div class="d-flex align-items-center">
			<p-assignment-process-icon
				style="position: relative; bottom: 2px;"
				class="nowrap"
				[class.infinite-pulse]="!schedulingService.earlyBirdMode && api.isLoaded() && !!freeEarlyBirdSeatsCount"
				[class.text-white]="schedulingService.earlyBirdMode"
				[state]="states.EARLY_BIRD_SCHEDULING"
				[isOwner]="!!rightsService.isOwner"
				[animatedBird]="true"
			/><span class="nowrap" *ngIf="config.IS_MOBILE && schedulingService.earlyBirdMode">&nbsp;<ng-container i18n>Beenden</ng-container></span>
			<span
				*ngIf="!spaceIsRare"
				class="pl-2 nowrap d-none d-xl-inline"
			><ng-container i18n>Früher Vogel</ng-container></span>
		</div>

	</p-button>
</ng-template>
<ng-template #freeWishesButton>
	<p-button
		*ngIf="freeWishesCount !== undefined"
		[badge]="freeWishesCount"
		[class.d-flex]="!hideWishPickerButton"
		[class.d-none]="hideWishPickerButton"
		class="ml-2 d-525px-flex"
		[size]="enums.BootstrapSize.SM"
		[wrapLabel]="false"
		[disabled]="!api.isLoaded()"
		(triggerClick)="onStartWishesMode.emit(!schedulingService.wishPickerMode)"
		[pTooltip]="!!freeWishesCount ? ('Deine Schichtwünsche abgeben' | localize) : ('Deine Schichtwünsche ändern' | localize)"
	>
		<fa-icon
			class="nowrap"
			[fixedWidth]="true" [icon]="enums.PlanoFaIconPool.ITEMS_ASSIGNMENT_PROCESS"
			[class.infinite-pulse]="!schedulingService.wishPickerMode && api.isLoaded() && !!freeWishesCount"
			style="width: 1.5em;"></fa-icon>

		<span
			*ngIf="!spaceIsRare"
			class="pl-1 nowrap d-none d-xl-inline"
			i18n
		>Schichtwünsche</span>

	</p-button>
</ng-template>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-memo-modal-content
		class="modal-content"
		[memoModalDay]="selectedDate"
		[showDateInput]="false"
		(onClose)="c($event)"
		(dismiss)="d($event)"
		[userCanSetMemos]="api.data.attributeInfoMemos.canSet"
	></p-memo-modal-content>
</ng-template>

<p-input-date
	#inputDateRef
	style="display: none !important;"
	[size]="enums.BootstrapSize.SM"
	[ngModel]="selectedDate"
	(ngModelChange)="navTo($event); api.deselectAllSelections();"
	[showEraseValueBtn]="false"
	[showNowButton]="false"
	[range]="calendarMode"
	title="Datum auswählen" i18n-title
></p-input-date>
