import { ISchedulingApiShiftModel } from '@plano/client/scheduling/shared/api/scheduling-api.interfaces';
import { SchedulingApiMember, SchedulingApiMembers, SchedulingApiShiftModelAssignableMember, SchedulingApiShiftModelAssignableMembersBase, SchedulingApiShiftModelBase, SchedulingApiShiftModelCancellationPolicy, SchedulingApiShiftModelsBase, SchedulingApiShiftRepetitionType } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PPossibleErrorNames, PValidatorObject } from '@plano/shared/core/validators.types';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModel extends SchedulingApiShiftModelBase implements ISchedulingApiShiftModel {

	private selectedState : boolean = false;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public fitsSearch(term : string | null) : boolean {
		if (term === null) return true;
		if (term === '') return true;
		for (const termItem of term.split(' ')) {
			const termLow = termItem.toLowerCase();
			const nameLow = this.name.toLowerCase();
			const parentNameLow = this.parentName.toLowerCase();
			if (nameLow.includes(termLow)) continue;
			if (parentNameLow.includes(termLow)) continue;
			return false;
		}
		return true;
	}

	/**
	 * Does this item belong to an interval?
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get hasRepetition() : boolean | null {
		if (this.repetition.attributeInfoType.value === null) return false;
		return this.repetition.type !== SchedulingApiShiftRepetitionType.NONE;
	}

	public set hasRepetition(value : boolean | null) {
		if (!value) {
			this.clearRepetitionType();
		} else if (
			this.repetition.attributeInfoType.value === SchedulingApiShiftRepetitionType.NONE ||
			this.repetition.attributeInfoType.value === null
		) {
			if (
				this.repetition.packetRepetition.attributeInfoType.value === SchedulingApiShiftRepetitionType.EVERY_X_WEEKS
			) {
				this.initRepetitionTypeMonth();
			} else {
				this.initRepetitionTypeWeek();
			}
		}
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private initRepetitionTypeMonth() : void {
		if (this.repetition.attributeInfoType.value !== SchedulingApiShiftRepetitionType.EVERY_X_MONTHS) {
			this.repetition.attributeInfoType.value = SchedulingApiShiftRepetitionType.EVERY_X_MONTHS;
		}
		this.repetition.attributeInfoX.value = 1;
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private initRepetitionTypeWeek() : void {
		if (this.repetition.attributeInfoType.value !== SchedulingApiShiftRepetitionType.EVERY_X_WEEKS) {
			this.repetition.attributeInfoType.value = SchedulingApiShiftRepetitionType.EVERY_X_WEEKS;
		}
		this.repetition.attributeInfoX.value = 1;
	}

	/**
	 * Update related controls values
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	private clearRepetitionType() : void {
		this.repetition.attributeInfoType.value = SchedulingApiShiftRepetitionType.NONE;
	}

	/**
	 * Should the shifts of this model belong to a packet per default?
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get isPacket() : boolean | null {
		if (!this.repetition.rawData) return null;
		return this.repetition.packetRepetition.type !== SchedulingApiShiftRepetitionType.NONE;
	}

	public set isPacket(input : boolean | null) {
		if (!input) {
			this.repetition.packetRepetition.attributeInfoType.value = SchedulingApiShiftRepetitionType.NONE;
		} else if (
			this.repetition.packetRepetition.attributeInfoType.value === SchedulingApiShiftRepetitionType.NONE ||
			this.repetition.packetRepetition.attributeInfoType.value === null
		) {
			this.repetition.packetRepetition.attributeInfoType.value = SchedulingApiShiftRepetitionType.EVERY_X_WEEKS;
			this.repetition.packetRepetition.attributeInfoX.value = 1;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get affected() : boolean {
		if (this.selected) return false;

		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		for (const shift of this.api.data.shifts.selectedItems.iterable()) {
			if (shift.relatesTo(this)) {
				return true;
			}
		}
		return false;
	}

	/** @see ApiDataWrapperBase#_updateRawData */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public override _updateRawData(data : any[], generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		// A new shift-model must have a cancellation-policy. So, we create it.
		if (this.isNewItem() && this.cancellationPolicies.length === 0) {
			const cancellationPolicy = this.cancellationPolicies.createNewItem();
			this.currentCancellationPolicyId = cancellationPolicy.id;
		}
	}

	public override copy() : SchedulingApiShiftModel {
		// copy
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const copy = super.copy((data : any[]) => {
			// don’t use ids of shift-model assignable members for id replacement as they use member ids
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const dontAddToIdReplacementList : any[] = [];

			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const assignableMembersRawData = data[this.api.consts.SHIFT_MODEL_ASSIGNABLE_MEMBERS];
			for (let i = 1; i < assignableMembersRawData.length; ++i)
				dontAddToIdReplacementList.push(assignableMembersRawData[i]);

			return dontAddToIdReplacementList;
		});

		// When copying a shift-model we don’t need the trashed tariffs/payment-methods anymore
		// as they are only referenced by the bookings of the source shift-model.
		for (let i = copy.coursePaymentMethods.length - 1; i >= 0; --i) {
			const coursePaymentMethod = copy.coursePaymentMethods.get(i);
			if (!coursePaymentMethod) throw new Error('Could not get paymentMethod');
			if (coursePaymentMethod.trashed)
				copy.coursePaymentMethods.remove(i);
		}

		for (let i = copy.courseTariffs.length - 1; i >= 0; --i) {
			const courseTariff = copy.courseTariffs.get(i);
			if (!courseTariff) throw new Error('Could not get tariff');
			if (courseTariff.trashed)
				copy.courseTariffs.remove(i);
		}

		return copy;
	}

	/** Give the user some hints about what has been copied and what not
	 * This method is separated from the copy method as we use the copy method of
	 * shiftmodels when creating shifts but there we don't want to show the copy hints.
	 * For that reason this method needs to be called separately when copying an
	 * existing shiftmodel.
	 *
	 * @param originalShiftModel shiftmodel from which to copy the information
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	*/
	public showCopyHints(originalShiftModel : SchedulingApiShiftModelBase) : void {
		let title : string | null = null;
		title = this.api!.localizePipe.transform('Daten erfolgreich kopiert');
		const description = this.api!.localizePipe.transform({
			sourceString: 'Das Formular wurde mit den Daten aus der Tätigkeit »${name}« vorausgefüllt.',
			params: {name: originalShiftModel.name},
		});
		this.api!.toasts.addToast({
			title: title,
			content: description,
			visibilityDuration: 'infinite',
			theme: enumsObject.PThemeEnum.SUCCESS,
		});

		if (originalShiftModel.isCourse) {
			this.api!.toasts.addToast({
				content: originalShiftModel.marketingGiftCardSettings.activated ?
					this.api!.localizePipe.transform('Prüfe bitte die <mark>Buchungseinstellungen</mark> sowie die Einstellung für <mark>Marketing-Gutscheine</mark>, da nicht alles übernommen werden konnte.') :
					this.api!.localizePipe.transform('Prüfe bitte die <mark>Buchungseinstellungen</mark>, da nicht alles übernommen werden konnte.'),
				visibilityDuration: 'infinite',
				theme: enumsObject.PThemeEnum.WARNING,
			});
		}

	}

	/**
	 * Run this if user decides against "Only whole course is bookable, not single slots".
	 * In case there are tariffs that dont fit to that decision, they will be removed.
	 */
	public clearMisfittingTariffs() : void {
		const fees = this.courseTariffs.iterable().flatMap(item => {
			return item.fees.iterable().map(fee => fee);
		});
		if (fees.some(fee => fee.perXParticipants > 1)) {
			this.courseTariffs.clear();
			this.api!.toasts.addToast({
				content: this.api!.localizePipe.transform('Die kopierten Tarife eigneten sich nicht für deine gewählte Art der Platzbuchung. Sie wurden entfernt. Bitte lege neue Tarife an.'),
				visibilityDuration: 'infinite',
				theme: enumsObject.PThemeEnum.WARNING,
			});
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get selected() : boolean {
		return this.selectedState;
	}
	public set selected( state : boolean ) {
		this.selectedState = state;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateSelectedState() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const relatedShifts = this.api.data.shifts.filterBy(item => item.shiftModelId.equals(this.id));
		if (relatedShifts.length === 0 || relatedShifts.length !== relatedShifts.selectedItems.length) {
			this.selectedState = false;
		}
	}

	/**
	 * @returns The current cancellation policy which should be shown in the shift-model and which will be used
	 * for future bookings.
	 */
	public get currentCancellationPolicy() : SchedulingApiShiftModelCancellationPolicy | null {
		return this.cancellationPolicies.get(this.currentCancellationPolicyId);
	}

	/**
	 * A readonly getter for Members that are assigned.
	 * returns a new instance of SchedulingApiMembers.
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get assignedMembers() : SchedulingApiMembers {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const members = new SchedulingApiMembers(this.api, null);

		// TODO: PLANO-156519
		if (!this.attributeInfoAssignedMemberIds.isAvailable) return new SchedulingApiMembers(this.api, null);
		for (const memberId of this.assignedMemberIds.iterable()) {
			const member = this.api.data.members.get(memberId);
			if (member === null) throw new Error('Could not find assigned member');
			members.push(member);
		}

		// FIXME: PLANO--7458
		// https://drplano.atlassian.net/browse/PLANO-7458

		members.push = () => {
			throw new Error('assignedMembers is readonly');
		};
		members.remove = () => {
			throw new Error('assignedMembers is readonly');
		};

		return members;
	}

	/**
	 * Check if there are other shiftModels with the same prefix
	 */
	public validatePrefixOccupied() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.OCCUPIED, fn: (control) => {
			if (!control.value) return null;
			if (!this.api!.data.shiftModels.prefixIsAlreadyOccupied(control.value, this)) return null;
			const activityWithSamePrefix = this.api!.data.shiftModels.find(
				shiftModel => shiftModel.courseCodePrefix?.toLowerCase() === control.value?.toLowerCase() && !shiftModel.id.equals(this.id),
			)!;
			return { [PPossibleErrorNames.OCCUPIED] : {
				name: PPossibleErrorNames.OCCUPIED,
				primitiveType: PApiPrimitiveTypes.string,
				errorText: () => {
					if (activityWithSamePrefix.trashed)
						return 'Dieses Präfix wird bereits verwendet für die gelöschte Tätigkeit »${activityName}«. Gelöschte Tätigkeiten lassen sich nicht mehr ändern.';
					// eslint-disable-next-line literal-blacklist/literal-blacklist
					return 'Dieses Präfix wird bereits verwendet für <a href="${activityURL}" target="_blank" rel="noopener">»${activityName}«</a>.';
				},
				activityName : activityWithSamePrefix.name,
				activityURL : `${Config.FRONTEND_URL_LOCALIZED}/client/shiftmodel/${activityWithSamePrefix.id.toString()}/bookingsettings#course-code-prefix`,
			} };
		}});
	}

	/**
	 * Check if there are no payment methods with the same type, when the type is online payment
	 */
	public validatePrefixPattern() : PValidatorObject {
		return new PValidatorObject({name: PPossibleErrorNames.PATTERN, fn: (control) => {
			if (!control.value) return null;

			const PATTERN_ERROR = this.api!.validators.pattern(/^[\dA-Za-z]*$/).fn(control);
			if (PATTERN_ERROR === null) return null;

			return {
				[PPossibleErrorNames.PATTERN] : {
					...PATTERN_ERROR[PPossibleErrorNames.PATTERN],
					errorText: 'Nur Buchstaben und Zahlen sind erlaubt.',
				},
			};
		}});
	}

	/**
	 * Are there any members available that can be assigned?
	 * Owners can always assign at least themselves, so this is always true for them.
	 * Members with the write permission can assign members, if an admin has already set some members as assignable.
	 * So there is nothing to do for a member if there are no assignable members.
	 *
	 * Note that there might be more restrictions set on SHIFT_MODEL_ASSIGNED_MEMBER_IDS
	 *
	 * TODO: PLANO-175990 Move this to a static method as there is duplicate code in Shift and ShiftModel
	 */
	public get assignableMembersAvailableForAssignment() : boolean {
		return this.api!.rightsService.isOwner === true || this.assignableMembers.length > 0;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModelAssignableMembers extends SchedulingApiShiftModelAssignableMembersBase {

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getByMemberId(id : Id) : SchedulingApiShiftModelAssignableMember | null {
		return this.findBy(item => item.memberId.equals(id));
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getByMember(item : SchedulingApiMember) : SchedulingApiShiftModelAssignableMember | null {
		return this.getByMemberId(item.id);
	}

	/**
	 * Get Amount of all un-trashed items
	 */
	public get unTrashedItemsAmount() : number {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		let result = 0;
		for (const item of this.iterable()) {
			const member = this.api.data.members.get(item.memberId);
			if (!member) throw new Error('Could not find member');
			if (!member.trashed) {
				result += 1;
			}
		}
		return result;
	}

	/**
	 * Check if there is at least one un-trashed item
	 */
	public get hasUntrashedItem() : boolean {
		return !!this.findBy(item => {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const member = this.api.data.members.get(item.memberId);
			if (!member) throw new Error('Could not find member');
			return !member.trashed;
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get members() : SchedulingApiMembers {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const result = new SchedulingApiMembers(this.api, null);
		for (const assignableMember of this.iterable()) {
			const member = this.api.data.members.get(assignableMember.memberId);
			if (!member) throw new Error('Could not find by member');
			result.push(member);
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public containsMember(item : SchedulingApiMember) : boolean {
		return !!this.getByMember(item);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public addNewMember(member : SchedulingApiMember, earning ?: number) : void {
		// NOTE: duplicate! This method exists here:
		// SchedulingApiAssignableShiftModels
		// SchedulingApiShiftModelAssignableMembers

		if (this.containsMember(member)) return;

		const tempAssignableMember = this.createNewItem();
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		tempAssignableMember.hourlyEarnings = earning ? earning : 0;
		tempAssignableMember.memberId = member.id;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public removeMember(item : SchedulingApiMember) : void {
		const assignableMember = this.getByMember(item);
		if (assignableMember) {
			this.removeItem(assignableMember);
		}
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiShiftModels extends SchedulingApiShiftModelsBase {

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public search(input : Parameters<SchedulingApiShiftModel['fitsSearch']>[0]) : SchedulingApiShiftModels {
		if (input === '') return this;
		return this.filterBy(item => item.fitsSearch(input));
	}

	/**
	 * Set all items to the given value.
	 * value is true by default.
	 */
	public setSelected(value : boolean = true) : void {
		for (const shift of this.iterable()) {
			shift.selected = value;
		}
	}

	/**
	 * Check if at least one item of this list is selected
	 */
	public get hasSelectedItem() : boolean {
		return !!this.findBy(item => item.selected);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get selectedItems() : SchedulingApiShiftModels {
		return this.filterBy(item => item.selected);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get trashedItemsAmount() : number {
		let result = 0;
		for (const shiftModel of this.iterable()) {
			if (shiftModel.trashed) {
				result += 1;
			}
		}
		return result;
	}

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return !!this.findBy(item => !item.trashed);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateSelectedStates() : void {
		for (const item of this.iterable()) {
			item.updateSelectedState();
		}
	}

	public override removeItem(shiftModel : SchedulingApiShiftModel) : void {
		super.removeItem(shiftModel);
	}

	private _groupByParentName = new Data<SchedulingApiShiftModels[]>(this.api);

	/**
	 * @returns Returns a list of lists where each inner list contains the shift-models with the same parent name.
	 * Note: Iterating maps in ng templates seems not be supported. So, instead this list of list structure was used.
	 */
	public get groupByParentName() : SchedulingApiShiftModels[] {
		return this._groupByParentName.get(() => {
			// calculate value of groupedByParentName
			const groupedList : SchedulingApiShiftModels[] = [];
			const getListForParentName = (parentName : string) : SchedulingApiShiftModels => {
				let result : SchedulingApiShiftModels | undefined = undefined;
				for (const list of groupedList) {
					// Does a list already exist for this parent name?
					const firstItem = list.get(0);
					if (firstItem === null) throw new Error('Could not get first item');
					if (parentName === firstItem.parentName) {
						result = list;
						break;
					}
				}

				// Create new list if not already exist for this parent name.
				if (!result) {
					result = new SchedulingApiShiftModels(this.api, null);
					groupedList.push(result);
				}
				return result;
			};

			for ( const shiftModel of this.iterable() ) {
				const parentName = shiftModel.parentName;

				// Does a list already exist for this parent name?
				const listForThisParentName = getListForParentName(parentName);

				// Add shift model to list
				listForThisParentName.push(shiftModel);
			}

			// sort outer list
			groupedList.sort((a : SchedulingApiShiftModels, b : SchedulingApiShiftModels) : number => {
				const firstItemA = a.get(0);
				if (firstItemA === null) throw new Error('Could not get first item of a');
				const firstItemB = b.get(0);
				if (firstItemB === null) throw new Error('Could not get first item of b');
				return firstItemA.parentName.localeCompare(firstItemB.parentName);
			});

			return groupedList;
		});
	}

	/**
	 * @returns Returns a list of list where each inner list contains the shift-models with the same parent name.
	 * Note: Iterating maps in ng templates seems not be supported. So, instead this list of list structure was used.
	 */
	public get parentNames() : string[] {
		const result : string[] = [];
		for (const shiftModel of this.iterable()) {
			const notIncluded = !result.includes(shiftModel.parentName);
			if (notIncluded) {
				result.push(shiftModel.parentName);
			}
		}
		return result;
	}

	/**
	 * @returns Returns a list of all courseGroups
	 */
	public get courseGroups() : string[] {
		const result : string[] = [];
		for (const shiftModel of this.iterable()) {
			if (shiftModel.courseGroup === null) continue;
			const notIncluded = !result.includes(shiftModel.courseGroup);
			if (notIncluded) {
				result.push(shiftModel.courseGroup);
			}
		}
		return result;
	}

	/**
	 * Check if prefix is already used by another shiftModel
	 */
	public prefixIsAlreadyOccupied(
			input : string,
			shiftModel : SchedulingApiShiftModelBase,
	) : boolean {
		for (const item of this.api!.data.shiftModels.iterable()) {
			if (item.courseCodePrefix !== input.toUpperCase()) continue;
			if (item.isNewItem()) continue;
			if (item.id.equals(shiftModel.id)) continue;
			return true;
		}
		return false;
	}

	/**
	 * Generate default value for course prefix
	 */
	public getDefaultPrefix(
		shiftModel : SchedulingApiShiftModelBase,
	) : string {
		let suggestion : string;
		suggestion = shiftModel.name ? `${shiftModel.name.slice(0, 1).toUpperCase()}K` : 'AA';

		const GET_RANDOM_LETTERS = (length : number) : string => {
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

			let letters = '';
			for ( let i = 0; i < length; i++ ) {
				letters += characters.charAt(Math.floor(Math.random() * characters.length));
			}
			return letters;
		};

		while (this.prefixIsAlreadyOccupied(suggestion, shiftModel)) {
			suggestion = `${suggestion}${GET_RANDOM_LETTERS(1)}`;
		}

		let result = suggestion;
		let index = 1;
		while (this.prefixIsAlreadyOccupied(result, shiftModel) && index < 10) {
			// e.g. SH[1…9]
			result = suggestion.slice(0, 2) + index.toString();
			++index;
		}

		if (this.prefixIsAlreadyOccupied(result, shiftModel)) {
			// e.g. S
			while (this.prefixIsAlreadyOccupied(result, shiftModel) && index < 100) {
				// e.g. SH[10…99]
				result = suggestion.slice(0, 1) + index.toString();
				++index;
			}
		}
		return result;
	}
}
