<div
	*ngIf="label"
	[class.mb-2]="hasMarginBottom"
	class="flex-nowrap align-items-center label-element-wrapper"
	#labelElementWrapper
	[style.height.px]="maxSiblingHeight"
>
	<label
		#labelElement
		class="mb-0"
		[class.min-w-fit-content]="canTruncateLabel"
		[class.crop-on-overflow]="canTruncateLabel"
		[class.text-loading]="isLoading" [class.read-mode]="readMode"
		[class.text-danger]="control && control.touched && control.invalid"
		>{{ label }}&nbsp;</label
	>
	<p-icon-tooltip *ngIf="showInfoCircle">
		<ng-template [ngTemplateOutlet]="descriptionWrapper"></ng-template>
	</p-icon-tooltip>
</div>

<ng-content></ng-content>

<div *ngIf="(keepAlwaysAsFootnote || !label) && (descriptionText || descriptionHTML)" class="d-flex form-text mt-2 small">
	<fa-icon [class.text-warning]="footnoteIcon === enums.PlanoFaIconPool.WARNING" class="mr-1" [icon]="footnoteIcon"/>
	<ng-template [ngTemplateOutlet]="descriptionWrapper"></ng-template>
</div>

<ng-template #descriptionWrapper>
	<div *ngIf="descriptionText" [innerHTML]="descriptionText | pSafeHtml"></div>
	<ng-container *ngIf="descriptionHTML">
		<ng-template [ngTemplateOutlet]="descriptionHTML"></ng-template>
	</ng-container>
</ng-template>
