import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PAssignmentProcessIcon } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-process-icon';
import { AssignmentProcessesService } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-processes.service';
import { RightsService, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState } from '@plano/shared/api';
import { LogService } from '@plano/shared/core/log.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-assignment-process-icon',
	templateUrl: './p-assignment-process-icon.component.html',
	styleUrls: ['./p-assignment-process-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAssignmentProcessIconComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public state : SchedulingApiAssignmentProcessState | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private process : SchedulingApiAssignmentProcess | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('isOwner') private _isOwner ?: boolean;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public animatedBird : boolean = false;

	@HostBinding('class.early-bird-icon')
	private get isEarlyBirdIcon() : boolean {
		return this.icon === enumsObject.PlanoFaIconPool.EARLY_BIRD;
	}

	@HostBinding('class.animated')
	private get _isAnimatedBird() : boolean {
		if (this.animatedBird && !this.isEarlyBirdIcon) {
			throw new Error('You are setting the icon as animated even though it is not the early bird!');
		}
		return this.animatedBird;
	}

	constructor(
		private assignmentProcessesService : AssignmentProcessesService,
		private rightsService : RightsService,
		private console : LogService,
	) {
	}

	public enums = enumsObject;

	private get isOwner() : boolean | undefined {
		if (this._isOwner !== undefined) return this._isOwner;
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		this.console.debug('IMPROVE: Add [isOwner]="boolean" to make this a dump component.');
		return this.rightsService.isOwner;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get icon() : PAssignmentProcessIcon | null {
		if (this.state === SchedulingApiAssignmentProcessState.NOT_STARTED) {
			if (this.isOwner) return ['regular', 'clone'];
			if (this.process === null) {
				this.console.error('process is not defined');
				return null;
			}
			if (!this.rightsService.userCanSetAssignmentProcess(this.process)) return null;
			return ['regular', 'clone'];
		}

		if (this.state === null) return null;
		return this.assignmentProcessesService.getIcon(this.state);
	}
}
