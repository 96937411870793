<ng-container *ngIf="shouldRenderContent">
	<ng-container *ngIf="scrollableTab; else headlineAndContentTemplate">
		<scroll-shadow-box
			class="flex-grow-1 d-flex flex-column position-relative h-100">
			<ng-container *ngTemplateOutlet="headlineAndContentTemplate"></ng-container>
		</scroll-shadow-box>
	</ng-container>
</ng-container>

<ng-template #headlineAndContentTemplate>
	<div *ngIf="showTabHeadline && !Config.IS_MOBILE" class="d-block px-4 pt-5">
			<h5
				[p-badge]="badgeOnHeadline ? badgeContent : null"
				[theme]="badgeTheme"
				[badgeTitle]="label ?? ''"
				[badgeDisabled]="badgeDisabled"
				[infoBadge]="infoBadge"
				class="font-weight-bold container pt-0 d-flex align-items-center"
				><fa-icon
					*ngIf="icon"
					class="mr-4"
					[icon]="icon"
				/><span class="mr-3">{{label ?? ''}}</span></h5
				>
	</div>
	<ng-content></ng-content>
</ng-template>


