import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PTabsComponent } from '@plano/client/shared/p-tabs/p-tabs/p-tabs.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { MeService, RightsService, SchedulingApiMarketingGiftCardSettingsRecipient, SchedulingApiMember, SchedulingApiService, SchedulingApiShiftModelMarketingGiftCardSettings } from '@plano/shared/api';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDictionarySource } from '@plano/shared/core/pipe/localize.pipe';
import { PScrollToSelectorService } from '@plano/shared/core/scroll-to-selector.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { AISwitchUIType } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';

/**
 * Implements the content of the marketing gift-card settings tab in the shift-model detail page.
 */
@Component({
	selector: 'p-marketing-gift-card-settings',
	templateUrl: './marketing-gift-card-settings.component.html',
	styleUrls: ['./marketing-gift-card-settings.component.scss'],
})
export class MarketingGiftCardSettingsComponent {
	/**
	 * The shift-model for which the settings should be shown.
	 */
	@Input() public marketingGiftCardSettings ! : SchedulingApiShiftModelMarketingGiftCardSettings;

	constructor(
		public api : SchedulingApiService,
		private rightsService : RightsService,
		private pTabsParent : PTabsComponent,
		private pScrollToSelectorService : PScrollToSelectorService,
		private modalService : ModalService,
		public activatedRoute : ActivatedRoute,
		private meService : MeService,
		public pMomentService : PMomentService,
	) {
	}

	public formGroup = new FormGroup({});
	public SchedulingApiMarketingGiftCardSettingsRecipient = SchedulingApiMarketingGiftCardSettingsRecipient;
	public enums = enumsObject;
	public AISwitchUIType = AISwitchUIType;
	public SectionWhitespace = SectionWhitespace;

	/**
	 * Was the marketing-gift-card activated by myself?
	 */
	public get activatedByMySelf() : boolean | undefined {
		const activatedByMemberId = this.marketingGiftCardSettings.activatedByMemberId;
		return activatedByMemberId ? this.rightsService.isMe(activatedByMemberId) : false;
	}

	/**
	 * The member who activated the marketing gift-cards.
	 */
	public get activatedByMember() : SchedulingApiMember | null {
		const activatedByMemberId = this.marketingGiftCardSettings.activatedByMemberId;
		return this.api.data.members.get(activatedByMemberId);
	}

	/**
	 * The current logged in member
	 */
	public get currentMember() : SchedulingApiMember {
		return this.api.data.members.get(this.meService.data.id)!;
	}

	/**
	 * Get the link based on the cannotSetHint for the marketing gift-card
	 */
	public linkBasedOnCannotSetHint(cannotSetHint : PDictionarySource) : {link : string, fragment : string | null, openInNewTab : boolean} {
		switch (cannotSetHint) {
			case 'Um Marketing-Gutscheine versenden zu können, aktiviere den Gutschein-Verkauf':
				return {
					link: '/client/plugin/gift-cards',
					fragment: null,
					openInNewTab: true,
				} ;
			// eslint-disable-next-line literal-blacklist/literal-blacklist
			case 'Um Marketing-Gutscheine versenden zu können, musst du erst die Online-Einlösbarkeit deiner Gutscheine aktivieren, da Marketing-Gutscheine nur online über das Homepage-Plugin von Dr. Plano eingelöst werden können. Ändere jetzt die Einstellung':
				return {
					link: '/client/plugin/gift-cards/settings',
					fragment: 'ways-to-redeem',
					openInNewTab: true,
				} ;
			default:
				throw new Error(`${cannotSetHint} is not supported here!`);
		}
	}

	/**
	 * Location name of the current client
	 */
	public get locationName() : string {
		return this.meService.data.locationName;
	}

	/**
	 * Expiration date based on the expiration duration and the current date in ms
	 */
	public get expirationDate() : number {
		return this.pMomentService.m().add(this.marketingGiftCardSettings.expirationDuration, 'M').valueOf();
	}
}
