<ng-template [ngIf]="!!members.length">
	<ng-template
		ngFor let-member
		[ngForOf]="membersForList"
		let-i="index"
	>
		<p-member-badge
			class="badges-stack-item"
			[style.z-index]="members.length - i"
			[style.position]="'relative'"
			size="small"
			[memberId]="member.id"
			[firstName]="member.firstName"
			[lastName]="member.lastName"
			[avatar]="member.avatar"
			[isMe]="!!isMe(member)"
			[absenceType]="absenceType(member.id)"
			[alwaysShowMemberInitials]="true"
		></p-member-badge>
	</ng-template>
</ng-template>
<p-empty-member-badges
	*ngIf="emptyMemberSlots"
	style="z-index: 0"
	[emptyMemberSlots]="emptyMemberSlots"
></p-empty-member-badges>
