import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbFormatsService } from '@plano/client/service/ngbformats.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { EditableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { AssignmentProcessesService } from '@plano/client/shared/p-sidebar/p-assignment-processes/assignment-processes.service';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { RightsService, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessAssignmentState, SchedulingApiAssignmentProcessState, SchedulingApiAssignmentProcessType, SchedulingApiMember, SchedulingApiMembers, SchedulingApiService, SchedulingApiShifts, ShiftId } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PPushNotificationsService, PRequestWebPushNotificationPermissionContext } from '@plano/shared/core/p-push-notifications.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PNgbDateStruct } from '@plano/shared/p-forms/p-input-date/p-input-date.component';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
	selector: 'p-assignment-process[process]',
	templateUrl: './p-assignment-process.component.html',
	styleUrls: ['./p-assignment-process.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PAssignmentProcessComponent extends UniqueAriaLabelByDirective implements OnInit {
	@HostBinding('class.d-block') protected _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public selectProcess : EventEmitter<SchedulingApiAssignmentProcess> = new EventEmitter<SchedulingApiAssignmentProcess>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClickProcess : EventEmitter<SchedulingApiAssignmentProcess> = new EventEmitter<SchedulingApiAssignmentProcess>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public process ! : SchedulingApiAssignmentProcess;

	constructor(
		public api : SchedulingApiService,
		private ngbFormats : NgbFormatsService,
		public modalService : ModalService,
		private toasts : ToastsService,
		public rightsService : RightsService,
		private console : LogService,
		private pShiftExchangeService : PShiftExchangeService,
		private pPushNotificationsService : PPushNotificationsService,
		private localize : LocalizePipe,
		private pMoment : PMomentService,
		private pDatePipe : PDatePipe,
		public assignmentProcessesService : AssignmentProcessesService,
	) {
		super();

		this.now = +this.pMoment.m();
	}

	public enums = enumsObject;
	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	public wholePackages : boolean = false;

	/**
	 * All states in a var to use them in the template
	 */
	public states : typeof SchedulingApiAssignmentProcessState = SchedulingApiAssignmentProcessState;

	/**
	 * All types in a var to use them in the template
	 */
	public types : typeof SchedulingApiAssignmentProcessType = SchedulingApiAssignmentProcessType;

	public deadlineObject : PNgbDateStruct = '-';
	private now ! : number;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isCollapsible() : boolean {
		if (Config.IS_MOBILE) return false;
		return this.userCanSetAssignmentProcess;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get userCanSetAssignmentProcess() : boolean {
		return !!this.rightsService.userCanSetAssignmentProcess(this.process);
	}

	public ngOnInit() : void {
		this.refreshMembersThatNeedToSetWishes();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get assignmentStateIsAllAssigned() : boolean | null {
		// As long as an backend operation is running, we can not know the next assignment state.
		if (this.api.isBackendOperationRunning) return null;
		return this.process.assignmentState === SchedulingApiAssignmentProcessAssignmentState.ALL_ASSIGNED;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get assignmentStateIsNoneAssigned() : boolean {
		return this.process.assignmentState === SchedulingApiAssignmentProcessAssignmentState.NONE_ASSIGNED;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get assignmentStateIsPartiallyAssigned() : boolean {
		return this.process.assignmentState === SchedulingApiAssignmentProcessAssignmentState.PARTIALLY_ASSIGNED;
	}

	private setInitialDeadlineObject() : void {
		const nextWeek = this.pMoment.m().add(1, 'week').valueOf();
		this.deadlineObject = this.ngbFormats.timestampToDateStruct(nextWeek);
	}

	/**
	 * Only some processes have a state where members need to set their wishes.
	 */
	public get processHasWishesMode() : boolean {
		if (this.process.type === SchedulingApiAssignmentProcessType.DR_PLANO) return true;
		if (this.process.type === SchedulingApiAssignmentProcessType.MANUAL) return true;
		return false;
	}

	/**
	 * Set new deadline
	 */
	private setProcessDeadline() : void {
		this.process.deadline = +this.pMoment.m(this.deadlineTimestamp).endOf('day');
	}

	private initOnlyAskPrefsForUnassignedShifts() : void {
		assumeNonNull(this.assignmentStateIsAllAssigned, 'assignmentStateIsAllAssigned', 'Can not init default value while assignmentStateIsAllAssigned is not known.');
		if (this.processHasWishesMode && this.assignmentStateIsAllAssigned === true) {
			// If the process is one where whishes gets asked and there are no unassigned shifts,
			// it does not make sense to set the flag to true.
			this.process.onlyAskPrefsForUnassignedShifts = false;
		} else if (this.showOnlyAskPrefsForUnassignedShiftsFormElement) {
			// If the user gets asked for the flag for whatever reason, the default is true.
			this.process.onlyAskPrefsForUnassignedShifts = true;
		}
	}

	/**
	 * Edit deadline
	 */
	public onEditDeadline(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		this.deadlineObject = this.ngbFormats.timestampToDateStruct(this.process.deadline);
		this.initOnlyAskPrefsForUnassignedShifts();
		void this.modalService.openModal(modalContent).result.then(value => {
			if (value.modalResult === 'success') {
				this.setProcessDeadline();
				void this.api.save();
			}
		});
	}

	private askForNotificationPermissionIfNecessary(v : SchedulingApiAssignmentProcessState) : void {
		switch (v) {
			case SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES :
				this.pPushNotificationsService.requestWebPushNotificationPermission(
					PRequestWebPushNotificationPermissionContext.MANAGER_STARTED_ASKING_MEMBER_PREFERENCES,
				);
				break;
			case SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING :
				this.pPushNotificationsService.requestWebPushNotificationPermission(
					PRequestWebPushNotificationPermissionContext.MANAGER_STARTED_EARLY_BIRD_SCHEDULING,
				);
				break;
			default :
		}
	}

	/**
	 * Start the phase where members should act
	 */
	public onStartProcess(modalContent : TemplateRef<PModalTemplateDirective>) : void {
		this.setInitialDeadlineObject();
		this.initOnlyAskPrefsForUnassignedShifts();

		void this.modalService.openModal(modalContent).result.then(value => {
			if (value.modalResult === 'success') {
				this.setProcessDeadline();
				if (this.process.type === SchedulingApiAssignmentProcessType.DR_PLANO) {
					this.process.state = SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES;
				}
				if (this.process.type === SchedulingApiAssignmentProcessType.EARLY_BIRD) {
					this.process.state = SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING;
				}
				if (this.process.type === SchedulingApiAssignmentProcessType.MANUAL) {
					this.process.state = SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES;
				}

				this.askForNotificationPermissionIfNecessary(this.process.state);

				void this.api.save();
			}
		});
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get deadlineTimestamp() : number | null {
		if (this.deadlineObject === '-') return null;
		return this.ngbFormats.dateTimeObjectToTimestamp(this.deadlineObject);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get daysTillDeadline() : number {
		const duration = +this.pMoment.m(this.deadlineTimestamp).endOf('day') - +this.pMoment.m(this.now).endOf('day');
		return Math.floor(this.pMoment.duration(duration).as('days'));
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get daysTillDeadlineText() : string {
		if (this.daysTillDeadline === 0) return this.localize.transform('bis heute Abend');

		let result : string = '';
		result += `${this.daysTillDeadline.toString()} `;
		if (
			this.daysTillDeadline > 1 ||
			this.daysTillDeadline < -1
		) {
			result += this.localize.transform('Tage');
		} else {
			result += this.localize.transform('Tag');
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get addableSelectedShiftsAmount() : number {
		let result = 0;
		for (const selectedShift of this.api.data.shifts.selectedItems.iterable()) {
			if (!this.process.shiftRefs.contains(selectedShift.id)) {
				result++;
			}
		}
		return result;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get removableSelectedShiftsAmount() : number {
		let result = 0;
		for (const selectedShift of this.api.data.shifts.selectedItems.iterable()) {
			if (this.process.shiftRefs.contains(selectedShift.id)) {
				result++;
			}
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get someSelectedShiftsAreAddable() : boolean {
		return !!this.addableSelectedShiftsAmount;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get someSelectedShiftsAreRemovable() : boolean {
		return !!this.removableSelectedShiftsAmount;
	}

	/**
	 * Check if some selected items are part of a package in order to decide if prompt in necessary
	 */
	public addPackagePromptIsNecessary(shifts : SchedulingApiShifts) : boolean {
		for (const shiftForLoop of shifts.iterable()) {
			for (const packetShift of shiftForLoop.packetShifts.iterable()) {
				if (
					// If the shift is already in the process everything is fine
					!this.process.shiftRefs.contains(packetShift.id)
				) {
					const shift = this.api.data.shifts.get(packetShift.id);
					if (

						// if the shift is not in the current view, prompt is necessary
						!shift ||

						// if the shift is in the view but not selected prompt is necessary
						!shift.selected
					) return true;
				}
			}
		}
		return false;
	}

	/**
	 * Determine if this change has potential to trigger notifications to the related employees.
	 */
	public get usersWillGetNotificationsAboutChanges() : boolean {
		if (
			this.process.type === SchedulingApiAssignmentProcessType.EARLY_BIRD &&
			this.process.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING
		) return true;
		if (
			(
				this.process.type === SchedulingApiAssignmentProcessType.DR_PLANO ||
				this.process.type === SchedulingApiAssignmentProcessType.MANUAL
			) &&
			this.process.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES
		) return true;
		return false;
	}

	/**
	 * Check if some selected items are part of a package in order to decide if prompt in necessary
	 */
	public get removePackagePromptIsNecessary() : boolean {
		for (const selectedShift of this.api.data.shifts.selectedItems.iterable()) {
			for (const packetShift of selectedShift.packetShifts.iterable()) {
				if (
					// If the shift is in the process we probably need a prompt
					this.process.shiftRefs.contains(packetShift.id)
				) {
					const shift = this.api.data.shifts.get(packetShift.id);
					if (

						// if the shift is not in the current view, prompt is necessary
						!shift ||

						// if the shift is in the view but not selected prompt is necessary
						!shift.selected
					) return true;
				}
			}
		}
		return false;
	}

	/**
	 * Check if some selected items are already part of another process
	 */
	public someShiftsArePartOfAProcess(shifts : SchedulingApiShifts) : boolean {
		return this.api.data.assignmentProcesses.filterBy(item => !item.id.equals(this.process.id)).containsAnyShift(shifts);
	}

	private addSelectedShiftsRequestIsNeeded(shifts : SchedulingApiShifts) : boolean {
		if (this.usersWillGetNotificationsAboutChanges) return true;
		if (this.someSlotsAreEmptyButProcessHasEnded(shifts)) return true;
		if (this.someShiftsArePartOfAProcess(shifts)) return true;
		if (this.addPackagePromptIsNecessary(shifts)) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public someSlotsAreEmptyButProcessHasEnded(shifts : SchedulingApiShifts) : boolean {
		if (this.process.state !== SchedulingApiAssignmentProcessState.NEEDING_APPROVAL) return false;
		if (!shifts.findBy(item => !!item.emptyMemberSlots)) return false;
		return true;
	}

	/**
	 * Ask User if shiftPaket items should be removed from this process
	 */
	public removeSelectedShiftsRequest(modalContent : TemplateRef<PModalTemplateDirective>) : EditableDirective['saveChangesHook'] {
		// eslint-disable-next-line @typescript-eslint/promise-function-async -- Remove this before you work here.
		return () => {
			if (!this.removePackagePromptIsNecessary) return null;
			return this.modalService.openModal(modalContent).result.then(value => {
				if (value.modalResult === 'success') this.removeAllRelatedPacketItems();
				return value;
			});
		};
	}

	/**
	 * Remove selected shifts from all other processes
	 */
	private removeSelectedShiftsFromAllOtherProcesses(shifts : SchedulingApiShifts) : void {
		for (const selectedShift of shifts.iterable()) {
			for (const assignmentProcess of this.api.data.assignmentProcesses.iterable()) {
				if (
					!assignmentProcess.id.equals(this.process.id) &&
					assignmentProcess.shiftRefs.contains(selectedShift.id)
				) {
					assignmentProcess.shiftRefs.removeItem(selectedShift.id);
				}
			}
		}
	}

	/**
	 * Add shift id to this process
	 */
	private addId(id : ShiftId, success ?: () => void) : void {
		if (this.process.shiftRefs.contains(id)) return;

		const relatedProcess = this.api.data.assignmentProcesses.getByShiftId(id);
		if (relatedProcess) {
			relatedProcess.shiftRefs.removeItem(id);
		}
		this.process.shiftRefs.createNewItem(null, id);
		if (success) {
			success();
		}
	}

	/**
	 * Add all related shiftPaket items to this process
	 * @returns At least one Shift has been added
	 */
	private addAllRelatedPacketItems() : boolean {
		let result = false;
		for (const selectedShift of this.api.data.shifts.selectedItems.iterable()) {
			if (!this.wholePackages) continue;

			for (const packetShift of selectedShift.packetShifts.iterable()) {
				this.addId(packetShift.id, () => {
					result = true;
				});
			}
		}
		return result;
	}

	private addShifts(
		noShiftsHint : TemplateRef<unknown>,
		addShiftsRequestModalContent : TemplateRef<PModalTemplateDirective>,
		shifts : SchedulingApiShifts,
	) : void {
		const success = (
			showNoShiftsHintIfNecessary = true,
			_shiftsHaveBeenAdded = false,
		) : void => {
			let shiftsHaveBeenAdded = _shiftsHaveBeenAdded;
			for (const selectedShift of shifts.selectedItems.iterable()) {
				this.addId(selectedShift.id, () => {
					shiftsHaveBeenAdded = true;
				});
			}
			if (!shiftsHaveBeenAdded) {
				if (showNoShiftsHintIfNecessary) {
					this.modalService.openModal(noShiftsHint);
				}
			} else {
				void this.api.save();
				this.refreshMembersThatNeedToSetWishes();
			}
		};

		if (!this.addSelectedShiftsRequestIsNeeded(shifts)) { success(); return; }

		void this.modalService.openModal(addShiftsRequestModalContent).result.then(value => {
			if (value.modalResult === 'success') {
				if (this.usersWillGetNotificationsAboutChanges && this.api.data.notificationsConf.sendEmail) {
					this.toasts.addToast({
						content: this.localize.transform('Die betroffenen User wurden benachrichtigt.'),
						theme: enumsObject.PThemeEnum.INFO,
					});
				}
				const shiftsHaveBeenAdded = this.addAllRelatedPacketItems();
				this.removeSelectedShiftsFromAllOtherProcesses(shifts);
				success(false, shiftsHaveBeenAdded);
			}
		});
	}

	private addSelectedShifts(
		noShiftsHint : TemplateRef<unknown>,
		addShiftsRequestModalContent : TemplateRef<PModalTemplateDirective>,
		shiftsThatShouldBeSkipped ?: SchedulingApiShifts,
	) : void {
		this.addShifts(noShiftsHint, addShiftsRequestModalContent, this.api.data.shifts.filterBy(item => {
			if (!item.selected) return false;
			if (shiftsThatShouldBeSkipped?.contains(item)) return false;
			return true;
		}));
	}

	/**
	 * Add selectedShifts to this process
	 */
	public onAddSelectedShifts(
		noShiftsHint : TemplateRef<unknown>,
		addShiftsRequestModalContent : TemplateRef<PModalTemplateDirective>,
	) : void {
		this.pShiftExchangeService.blockedByShiftExchangeWarningModal(this.process, shiftsThatShouldBeSkipped => {
			this.addSelectedShifts(noShiftsHint, addShiftsRequestModalContent, shiftsThatShouldBeSkipped);
		});
	}

	/**
	 * Remove shift id from this process
	 */
	private removeId(id : ShiftId, success ?: () => void) : void {
		if (!this.process.shiftRefs.contains(id)) return;

		this.process.shiftRefs.removeItem(id);
		if (success) success();
	}

	/**
	 * Remove all related shiftPaket items from this process
	 */
	private removeAllRelatedPacketItems() : void {
		for (const selectedShift of this.api.data.shifts.selectedItems.iterable()) {
			if (!this.wholePackages) return;
			for (const packetShift of selectedShift.packetShifts.iterable()) {
				this.removeId(packetShift.id);
			}
		}
	}

	/**
	 * Remove selected shifts from this process
	 */
	public removeSelectedShifts(modalContentNoShiftsHint : TemplateRef<unknown>) : void {
		let nothingToDo = true;
		for (const selectedShift of this.api.data.shifts.selectedItems.iterable()) {
			this.removeId(selectedShift.id);
			nothingToDo = false;
		}
		if (nothingToDo) {
			this.modalService.openModal(modalContentNoShiftsHint);
		} else {
			this.refreshMembersThatNeedToSetWishes();
		}
	}

	/**
	 * Remove this process.
	 */
	public async removeProcess() : Promise<HttpResponse<unknown>> {
		const processName = this.process.name;
		this.api.data.assignmentProcesses.removeItem(this.process);

		return this.api.save({
			success : () : void => {
				this.console.log('processName', processName);
				this.console.log('this.process', this.process);
				this.toasts.addToast({
					content: this.localize.transform({
						sourceString: 'Der Vorgang »${name}« wurde gelöscht.',
						params: {name: processName},
					}),
					theme: enumsObject.PThemeEnum.INFO,
				});
			},
		});
	}

	/**
	 * User confirms that this early-bird process should be closed.
	 */
	public async confirmEarlyBirdProcess() : Promise<HttpResponse<unknown>> {
		const processName = this.process.name;
		this.api.data.assignmentProcesses.removeItem(this.process);

		return this.api.save({
			success : () : void => {
				this.toasts.addToast({
					content: this.localize.transform({sourceString: 'Der Vorgang »${name}« wurde abgeschlossen.', params: {name: processName}}),
					theme: enumsObject.PThemeEnum.SUCCESS,
				});
			},
		});
	}

	/**
	 * User approves that the content of this process is what he/she wants
	 */
	public confirmProcess() : void {
		if (this.process.type === SchedulingApiAssignmentProcessType.EARLY_BIRD) {
			void this.confirmEarlyBirdProcess();
		} else if (this.process.type === SchedulingApiAssignmentProcessType.MANUAL) {
			this.process.state = SchedulingApiAssignmentProcessState.APPROVE;
		} else {
			this.process.state = SchedulingApiAssignmentProcessState.APPROVE;
		}
	}

	private membersThatNeedToSetWishes : SchedulingApiMember[] = [];

	private refreshMembersThatNeedToSetWishes() : void {
		this.membersThatNeedToSetWishes = [];
		for (const shiftIdObj of this.process.shiftRefs.iterable()) {
			const shift = this.api.data.shifts.get(shiftIdObj.id);
			if (!shift) continue;

			for (const assignableMember of shift.assignableMembers.iterable()) {
				const member = this.api.data.members.get(assignableMember.memberId);
				if (!member) throw new Error('Could not find by member');
				if (!this.membersThatNeedToSetWishes.includes(member)) {
					this.membersThatNeedToSetWishes.push(member);
				}
			}
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get missingPrefsMemberIdsLength() : number {
		// FIXME: PLANO-1638
		if (
			this.process.missingPrefsMemberIds.rawData !== undefined &&
			this.process.missingPrefsMemberIds.length > 0
		) {
			return this.process.missingPrefsMemberIds.length;
		}
		return 0;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get namesOfMembersThatMissedToSetWishes() : string {
		let result = '';
		let index = 0;
		const members : SchedulingApiMembers = new SchedulingApiMembers(null, null);
		for (const memberId of this.process.missingPrefsMemberIds.iterable()) {
			const member = this.api.data.members.get(memberId);
			if (member) members.push(member);
		}

		for (const member of members.sortedBy(item => item.firstName).iterable()) {
			result += `${member.firstName} ${member.lastName.charAt(0)}.`;
			index++;
			if (index < this.process.missingPrefsMemberIds.length) {
				result += ', ';
			}
		}

		return result;
	}

	/**
	 * Decide if user should be warned that all existing assignments will be re-assigned
	 */
	public get showReAssignmentWarning() : boolean {
		switch (this.process.type) {
			case SchedulingApiAssignmentProcessType.EARLY_BIRD:
			case SchedulingApiAssignmentProcessType.MANUAL:
				return false;
			case SchedulingApiAssignmentProcessType.DR_PLANO:
				return this.assignmentStateIsAllAssigned === true;
		}
	}

	/**
	 * Decide if user should be warned that all existing assignments will get lost
	 */
	public get showAllAssignmentsGetLostWarning() : boolean {
		return (
			!this.process.onlyAskPrefsForUnassignedShifts && this.process.type !== SchedulingApiAssignmentProcessType.MANUAL
		);
	}

	/**
	 * If wishes for all shifts are requested the assignments of the shifts still remain untouched.
	 */
	public get showAssignmentsRemainUntouchedHint() : boolean {
		if (this.process.type === SchedulingApiAssignmentProcessType.DR_PLANO) return false;
		if (!this.assignmentStateIsPartiallyAssigned) return false;
		if (this.process.onlyAskPrefsForUnassignedShifts) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showYouHaveNoRightToDoThisAlert() : boolean {
		for (const shift of this.api.data.shifts.selectedItems.iterable()) {
			if (!this.rightsService.canGetManagerNotifications(shift)) return true;
			if (!this.rightsService.userCanWrite(shift)) return true;
		}
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get allOrNoneAssigned() : boolean {
		if (
			this.process.state === SchedulingApiAssignmentProcessState.NEEDING_APPROVAL &&
			(this.assignmentStateIsNoneAssigned || this.assignmentStateIsAllAssigned)
		) {
			return true;
		}
		if (this.process.state === SchedulingApiAssignmentProcessState.NOT_STARTED && this.assignmentStateIsAllAssigned) {
			return true;
		}
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showErneutAnfordernBtn() : boolean {
		return (
			this.process.state === SchedulingApiAssignmentProcessState.EARLY_BIRD_FINISHED ||
			this.process.state === SchedulingApiAssignmentProcessState.NEEDING_APPROVAL ||
			this.process.state === SchedulingApiAssignmentProcessState.MANUAL_SCHEDULING
		);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get selectShiftRefsBtnLabel() : string {
		if (this.process.shiftRefs.length === 0) return this.localize.transform('0 Schichten drin');
		if (this.process.shiftRefs.length === 1) return this.localize.transform('1 Schicht drin');
		return this.localize.transform({
			sourceString: '${amount} Schichten drin',
			params: {amount: this.process.shiftRefs.length.toString()},
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get addAndRemovePopover() : string | null {
		if (!this.showYouHaveNoRightToDoThisAlert) return null;
		return this.localize.transform('Deine Auswahl beinhaltet Schichten, für die du nicht als bereichsleitende Person eingetragen bist.');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get lastMinuteOfDayInTimeFormat() : string | null {
		return this.pDatePipe.transform(86340000, 'shortTime', true); // 23:59 Uhr
	}

	/**
	 * Get a title of the related process
	 */
	public processTitleForState(process : SchedulingApiAssignmentProcess) : PDictionarySourceString {
		const state = process.state !== SchedulingApiAssignmentProcessState.NEEDING_APPROVAL ? process.state : SchedulingApiAssignmentProcessState.APPROVE;
		const userCanSetAssignmentProcess = !!this.rightsService.userCanSetAssignmentProcess(process);
		const result = this.assignmentProcessesService.getDescription(state, userCanSetAssignmentProcess);
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	/**
	 * Should the radios buttons for process.onlyAskPrefsForUnassignedShifts be visible?
	 */
	public get showOnlyAskPrefsForUnassignedShiftsFormElement() : boolean {
		switch (this.process.type) {
			case SchedulingApiAssignmentProcessType.MANUAL:
			case SchedulingApiAssignmentProcessType.EARLY_BIRD:
				return false;
			case SchedulingApiAssignmentProcessType.DR_PLANO:
				if (this.process.state === SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES) return false;
				return this.processHasWishesMode && this.assignmentStateIsPartiallyAssigned;
		}
	}

	/**
	 * The name (or placeholder) of the assignment process that will be shown to the UI in multiple places
	 */
	protected get processName() : string {
		return this.process.rawData ? this.process.name : '?';
	}
}
