import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { AbsenceService } from '@plano/client/shared/absence.service';
import { MemberBadgeComponent } from '@plano/client/shared/p-member/member-badges/member-badge/member-badge.component';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { RightsService, SchedulingApiAbsenceType, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-assigned-members[shift]',
	templateUrl: './assigned-members.component.html',
	styleUrls: ['./assigned-members.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class AssignedMembersComponent implements OnInit {
	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ! : SchedulingApiShift;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : typeof enumsObject.BootstrapSize.SM | null = null;

	constructor(
		private modalService : ModalService,
		public api : SchedulingApiService,
		private absenceService : AbsenceService,
		private rightsService : RightsService,
		private pMoment : PMomentService,
	) {
	}

	public deletableMember ! : SchedulingApiMember;
	private now ! : number;

	public ngOnInit() : void {
		this.now = +this.pMoment.m();
	}

	/**
	 * Should the button that navigates to the Shift-Exchange form be visible?
	 */
	public showExchangeBtn(assignedMember : SchedulingApiMember) : boolean {
		if (this.readMode) return false;

		if (this.shiftIsInThePast) return false;

		if (this.getShiftExchangeForMember(assignedMember.id)) return true;
		if (this.isMe(assignedMember)) return true;
		if (this.userCanCreateIllness) return true;

		return false;
	}

	private get userCanCreateIllness() : boolean | undefined {
		return this.rightsService.hasManagerRightsForShiftModel(this.shift.shiftModelId);
	}

	private get shiftIsInThePast() : boolean {
		return this.shift.end < this.now;
	}

	/**
	 * Is the given member the logged in user?
	 */
	public isMe(member : SchedulingApiMember) : boolean | undefined {
		return this.rightsService.isMe(member);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showDeleteButton() : boolean | undefined {
		if (this.readMode) return false;
		return this.rightsService.userCanWrite(this.shift);
	}

	/**
	 * Get the related shiftExchange if one is available
	 */
	public getShiftExchangeForMember(memberId : Id) : SchedulingApiShiftExchange | null {
		if (!this.api.isLoaded()) return null;
		return this.api.data.shiftExchanges.getByShiftAndMember(this.shift.id, memberId) ?? null;
	}

	/**
	 * Open some "Are you sure" modal
	 */
	public openDeleteModal(
		event : Event,
		assignedMember : SchedulingApiMember,
		modalContent : TemplateRef<PModalTemplateDirective>,
	) : void {
		event.stopPropagation();
		this.deletableMember = assignedMember;
		void this.modalService.openModal(modalContent).result.then(value => {
			if (value.modalResult === 'success') {
				this.shift.assignedMemberIds.removeItem(assignedMember.id);
				void this.shift.saveDetailed();
			}
		});
	}

	/**
	 * If the member has an absence at the time-range of the shift, then show an absence-icon.
	 */
	public absenceTypeIconName(memberId : Id) : PlanoFaIconPoolValues | null {
		return this.absenceService.absenceTypeIconName(memberId, this.shift);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get assignedMembersForList() : readonly SchedulingApiMember[] {
		return this.shift.assignedMembers.sortedBy(item => item.lastName, {removeDestroyedItems: true})
			.sortedBy([
				item => (this.isMe(item) ? 0 : 1),
				item => !item.trashed,
				...defaultSortingForMembers,
			])
			.iterable();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public absenceType(memberId : Id) : MemberBadgeComponent['absenceType'] {
		if (this.shift.assignedMembers.get(memberId)?.trashed) return 'trashed';
		const type = this.absenceService.absenceType(memberId, this.shift);
		return type === SchedulingApiAbsenceType.OTHER ? null : type ?? null;
	}
}
