<ng-template [ngIf]="isEnabled" [ngIfElse]="disabledTemplate">
	<button
		type="button"
		class="btn btn-outline-secondary btn-frameless w-100"
		[class.btn-sm]="size==='sm'"
		(click)="openQuickAssignModal($event, modalContent.template)"
		*ngIf="shift.emptyMemberSlots > 0"
		[title]="translatedEmptyMemberSlotsTitle.textContent!"
	>
		<div class="d-flex justify-content-between align-items-center crop-on-overflow">
			<div class="d-flex align-items-center crop-on-overflow text-left">
				<p-empty-member-badges
					*ngIf="shift.emptyMemberSlots"
					[emptyMemberSlots]="shift.emptyMemberSlots"
				></p-empty-member-badges>
				<span class="crop-on-overflow ml-2" i18n>{shift.emptyMemberSlots, plural, one {Platz} other {Plätze}} unbesetzt</span>
			</div>
			<fa-icon class="ml-1" [icon]="['solid', 'user-plus']"></fa-icon>
		</div>
	</button>
</ng-template>
<ng-template #disabledTemplate>
	<div
		class="d-flex justify-content-between crop-on-overflow flex-grow-1 card-options"
		[title]="translatedEmptyMemberSlotsTitle.textContent!"
	>
		<div
			class="d-flex crop-on-overflow flex-grow-1 px-1 py-1"
			[class.align-items-center]="!CONFIG.IS_MOBILE"
			[class.flex-column]="CONFIG.IS_MOBILE"
			[class.justify-content-between]="!CONFIG.IS_MOBILE"
			[class.justify-content-start]="!CONFIG.IS_MOBILE"
		>
			<div class="d-flex align-items-center">
				<p-empty-member-badges
					*ngIf="shift.emptyMemberSlots"
					[emptyMemberSlots]="shift.emptyMemberSlots"
				></p-empty-member-badges>
				<span class="crop-on-overflow ml-2" [class.small]="size==='sm'" i18n>{shift.emptyMemberSlots, plural, one {Platz} other {Plätze}} unbesetzt</span>
			</div>
		</div>

		<button *ngIf="cannotSetHint"
			class="btn flex-grow-0 d-flex align-items-center btn-secondary card-option border-bottom-0 rounded-0"
			tabindex="0"
			(click)="openCannotSetHint()"
			title="Info" i18n-title
		><fa-icon [icon]="enums.PlanoFaIconPool.MORE_INFO"></fa-icon></button>
	</div>
</ng-template>
<div hidden><span i18n #translatedEmptyMemberSlotsTitle>{{shift.emptyMemberSlots}} {shift.emptyMemberSlots, plural, one {Platz} other {Plätze}} unbesetzt</span></div>
<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<div hidden><span #translatedEmptySlotsModalHeaderTitle><ng-container *ngIf="!!shift.rawData" i18n>{shift.emptyMemberSlots, plural, one {Ein Platz unbesetzt} other {{{shift.emptyMemberSlots}} Plätze unbesetzt}}</ng-container></span></div>
	<p-modal-header
		[headline]="translatedEmptySlotsModalHeaderTitle.textContent!"
		(onClose)="d($event)"
	></p-modal-header>
	<div class="modal-body">
		<p i18n>Wer soll diese Schicht übernehmen?</p>
		<ng-template [ngIf]="!membersForList.length" [ngIfElse]="availableMembers">
			<div class="card card-body" i18n>Es sind keine Personen für diese Schicht zugelassen. Das kannst du in der dazugehörigen Tätigkeit ändern.</div>
		</ng-template>
		<ng-template #availableMembers>
			<p-input-member
				[shift]="shift"
				class="d-block mb-3"
				[(ngModel)]="selectedMember"
				[members]="membersForList"
			></p-input-member>

		</ng-template>
		<ng-template
			ngFor let-error
			[ngForOf]="errors"
		>
			<p-alert [theme]="error.type" [icon]="error.icon">{{ error.text }}</p-alert>
		</ng-template>

		<ng-template [ngIf]="!!errors.length">
			<p i18n>{{ selectedMember!.firstName }} {{ selectedMember!.lastName }} trotzdem einteilen?</p>
		</ng-template>

		<p-notification-conf-form
			[(ngModel)]="api.data.notificationsConf.sendEmail"
			[api]="api"
			[shift]="shift"
		></p-notification-conf-form>
	</div>
	<div class="modal-footer">
		<button type="button"
			class="btn btn-outline-secondary mr-auto"
			(click)="d($event)"
			i18n
		>Abbrechen</button>
		<button type="button"
			class="btn ml-auto"
			[disabled]="!selectedMember"
			[class.btn-primary]="selectedMember"
			[class.btn-outline-secondary]="!selectedMember"
			(click)="c($event)"
			i18n
		>OK</button>
	</div>
</ng-template>
