import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { PTextColorEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface, EditableDirective, EditableTriggerClickableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PEditableShowroomComponent } from '@plano/client/shared/p-editable/p-editable-showroom/p-editable-showroom.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { SchedulingApiService } from '@plano/shared/api';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { DisabledDirective } from '@plano/shared/core/directive/disabled.directive';
import { LogService } from '@plano/shared/core/log.service';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalRef, ModalResult, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalDismissParam, ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { PTooltipDirective } from '@plano/shared/core/p-tooltip/tooltip.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { Subscription } from 'rxjs';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[pEditableModalButton]',
	templateUrl: './p-editable-modal-button.component.html',
	styleUrls: ['./p-editable-modal-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableModalButtonComponent extends DisabledDirective implements AfterContentInit, EditableControlInterface, OnDestroy {

	@ContentChildren(PEditableShowroomComponent) private contentBoxShowroom ?: QueryList<PEditableShowroomComponent>;

	/**
	 * The title to be used inside the modal
	 */
	@Input() public modalTitle : string | null = null;

	/**
	 * Whether a backdrop element should be created for a given modal (true by default).
	 * Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
	 */
	@Input() public backdrop : ModalServiceOptions['backdrop'];

	/** @see ModalServiceOptions#windowClass */
	@Input() public windowClass : ModalServiceOptions['windowClass'];

	/**
	 * Theme defines the background color/style of the Modal
	 */
	@Input() public theme ?: ModalServiceOptions['theme'] = null;

	/**
	 * size of the modal
	 */
	@Input() private modalSize : ModalServiceOptions['size'];

	/**
	 * position of the modal
	 */
	@Input() public centered : ModalServiceOptions['centered'];

	/**
	 * fa-icon name for the button
	 */
	@Input('icon') private _icon ?: FaIcon | null;

	/** @see PEditableModalButtonComponent#_icon */
	public get icon() : FaIcon | null {
		if (this._icon) return this._icon;
		if (this.disabled) return null;
		return enumsObject.PlanoFaIconPool.EDIT;
	}

	/**
	 * @see ModalContentOptions#closeBtnLabel
	 */
	@Input() public closeBtnLabel : (string | (() => string)) | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public closeBtnTheme : ModalContentOptions['closeBtnTheme'] = enumsObject.PThemeEnum.PRIMARY;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public closeBtnDisabled : ReturnType<Exclude<ModalContentOptions['closeBtnDisabled'], undefined>> = false;

	/**
	 * Should the icon be visible?
	 */
	@Input('showBtnIcon') public _showBtnIcon : boolean | null = null;

	/** @see PEditableModalButtonComponent#_showBtnIcon */
	public get showBtnIcon() : boolean {
		if (this._showBtnIcon !== null) return this._showBtnIcon;
		return !!this.icon;
	}

	/**
	 * Popover for the save-button in the modal.
	 */
	@Input() public saveButtonPopover : PTooltipDirective['pTooltip'] = null;

	/**
	 * If set to true, the modal will close when the pEditable is done with the api-call.
	 */
	@Input() private waitForEditableCompleteBeforeClose : boolean = false;

	/**
	 * Triggers when modal gets opened
	 */
	@Output() private onModalOpen = new EventEmitter<Event>();

	/**
	 * Triggers when modal gets successfully closed.
	 * Note that this is not the case when the modal gets dismissed.
	 * @see onModalDismissed
	 */
	@Output() private onModalClosed = new EventEmitter<Event>();

	/**
	 * Triggers when modal gets dismissed.
	 */
	@Output() private onModalDismissed = new EventEmitter<ModalDismissParam>();

	/**
	 * Triggers before modal gets closed, it should be an async method that return a boolean
	 * saying if the modal can be closed or not.
	 */
	@Input() private beforeModalClose : (() => Promise<boolean>) | null = null;

	/**
	 * How much whitespace should there be inside the modal?
	 */
	@Input() public modalWhitespace : SectionWhitespace = SectionWhitespace.MEDIUM;

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public api : EditableControlInterface['api'] = null;
	@Input() public valid : EditableControlInterface['valid'] = null;
	@Input() public saveChangesHook ?: EditableControlInterface['saveChangesHook'];
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public beforeEditHook : EditableTriggerClickableDirective['beforeEditHook'];

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public btnLabel : string | null = null;

	/**
	 * Label gets used as button-text.
	 * If no label is set, the content of p-editable-modal-button-header gets used.
	 */
	public get label() : string | null {
		return this._label;
	}
	@Input() public set label(input : string | null) {
		this._label = input;
	}

	/**
	 * Should any label be visible?
	 * If false, the trigger-button will only have an icon.
	 */
	public get showBtnLabel() : boolean {
		return this._showBtnLabel;
	}
	@Input() public set showBtnLabel(input : boolean) {
		this._showBtnLabel = input;
	}

	@HostBinding('class.btn') private get hasNoCardClass() : boolean {
		return !this.elementRef.nativeElement.classList.contains('card');
	}
	@HostBinding('class.d-flex')
	@HostBinding('class.align-items-center')
	@HostBinding('class.justify-content-center') protected _alwaysTrue = true;

	@HostBinding('class.p-editable-active') private get pEditableIsActive() : boolean {
		if (!this.pEditable) return false;
		return true;
	}

	@HostBinding('class.p-editable-has-hook') private get _pEditableHasHook() : boolean {
		return this.pEditableIsActive && !!this.saveChangesHook;
	}

	/**
	 * @deprecated use [contentTemplateRef] instead
	 */
	@ViewChild('formInModal', { static: true }) private formInModal ! : PModalTemplateDirective;

	@ViewChild('footerTemplateRef', { static: true }) private footerTemplateRef ! : PModalTemplateDirective;

	/**
	 * This is an alternative way to define a template instead of using <p-editable-modal-form>
	 * Use it if you dont want the template content to be available in the Angular view structure while modal is NOT open.
	 */
	@Input() private contentTemplateRef : TemplateRef<unknown> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private contentTemplateOptions : {[key : string] : unknown} = {};

	/** ModalContentOptions#hideDismissBtn */
	@Input() protected hideDismissBtn : ModalContentOptions['hideDismissBtn'] | null = null;

	/** Should the close button be disabled? Works only if contentTemplateRef is not provided  */
	@Input() protected hideCloseBtn : ModalContentOptions['hideDismissBtn'] | null = null;

	@HostBinding('disabled')
	private get isDisabled() : boolean {
		return !!this.disabled || this.modalIsOpen;
	}

	@HostBinding('tabindex') private get _tabindex() : number {
		if (this.isDisabled) return -1;
		return 0;
	}

	@HostListener('mousedown') private _mouseDownOnElementHandler() : void {
		this.isMouseDownOnElement = true;
	}

	/**
	 * Trigger editable logic
	 * Like .openEditableModal(…) this can also be used to open the modal of an editable button B by a click on another
	 * button. Different to .openEditableModal(…) this will trigger the editable logic
	 * like .saveEditableChanges() or .onUndo().
	 *
	 * But you can not call .onClick(…) directly. You need to call .elementRef.nativeElement.click() instead.
	 *
	 * @param event The MouseEvent that triggered the method
	 * @returns ModalResult<unknown> if a modal has been opened, void if not
	 */
	@HostListener('click', ['$event']) public async onClick(event : MouseEvent) : Promise<ModalResult<unknown> | void> {
		if (this.clickShouldBeBlocked(event)) return;

		await this.beforeEditHook?.().catch(() => {
			this.console.log('editable dismissed');
			return null;
		});

		if (this.pEditableIsActive && !this.pEditableRef.startEditable(event)) return;
		const modalRef = this.openEditableModal();

		const value = await modalRef.result;
		if (value.modalResult === 'success') {
			if (!this.waitForEditableCompleteBeforeClose) {
				if (this.contentTemplateRef) {
					void this.saveEditableChanges(event, () => {
						this.modalRef!.close();
					});
				} else {
					void this.saveEditableChanges(value.value);
				}
			}
			this.onModalClosed.emit(value.value);
		} else {
			this.onModalDismissed.emit(value.value);

			// NOTE:	If you want to change the next line, please check if these tickets are still fixed:
			// 				- PLANO-98740
			// 				- PLANO-92505
			//				- PLANO-174064
			if (!this.contentTemplateRef && this.api?.hasDataCopy()) {
				this.pEditableRef.onUndo();
			}
		}

		return modalRef.result;
	}

	constructor(
		public modalService : ModalService,
		public console : LogService,
		public pEditableRef : EditableDirective,
		private localizePipe : LocalizePipe,
		public elementRef : ElementRef<HTMLElement>,
	) {
		super(elementRef);
		this.updateEditModeSubscription = this.pEditableRef.editMode.asObservable().subscribe((event) => {
			this.updateEditMode(event);
		});
	}

	/**
	 * Checks if there are any reasons to block the click event.
	 * @param event The Event that triggered the method
	 */
	private clickShouldBeBlocked(event : Event) : boolean {
		// If the event was captured by a button element or an anchor one, the modal shouldn't open
		if (this.childHasBeenClicked(event)) return true;

		// If some text is selected inside the modal-box-showroom, then the modal shouldn't open
		if (this.textHasBeenSelectedInsideShowroom(event)) return true;

		return !!this.disabled;
	}

	private childHasBeenClicked(event : Event) : boolean {
		return Array.from(event.composedPath()).some((element) => {
			return element !== this.elementRef.nativeElement && (element instanceof HTMLButtonElement || element instanceof HTMLAnchorElement);
		});
	}

	private textHasBeenSelectedInsideShowroom(event : Event) : boolean {
		const selectedText = window.getSelection();
		const textIsSelected = selectedText && selectedText.type === 'Range';

		// Is child of a element with class .modal-box-showroom
		const isChildOfShowroom = Array.from(event.composedPath()).some((element) => {
			return element instanceof HTMLElement && element.classList.contains('modal-box-showroom');
		});

		const result = isChildOfShowroom && !!textIsSelected && this.isMouseDownOnElement;
		if (result) this.isMouseDownOnElement = false;
		return result;
	}

	private updateEditModeSubscription : Subscription;

	public ngOnDestroy() : void {
		this.updateEditModeSubscription.unsubscribe();
	}

	/**
	 * Handle modal close, we need to wait for the save to finish before closing the modal
	 */
	public async handleModalClose(event : Event, closeMethod : (event : Event) => void) : Promise<void> {
		if (this.beforeModalClose) {
			const canCloseFromBeforeModalCloseCheck = await this.beforeModalClose();
			if (!canCloseFromBeforeModalCloseCheck) return;
		}
		if (this.waitForEditableCompleteBeforeClose) {
			await this.saveEditableChanges(event);
			closeMethod(event);
		} else closeMethod(event);
	}

	private isMouseDownOnElement : boolean = false;

	/**
	 * Does this have a p-editable-showroom child?
	 */
	public get hasEditableBoxShowroom() : boolean {
		if (this.contentBoxShowroom && this.contentBoxShowroom.length > 0)
			return true;
		return false;
	}

	/**
	 * Opens the modal.
	 *
	 * @example If you need to open the modal of an editable button B by a click on another button, which is not part of the
	 * editable button B, you can use this method to open the modal. Note that this will not trigger the editable logic
	 * like .saveEditableChanges() or .onUndo().
	 *
	 * <p-editable-modal-box
	 * 	[pEditable]="true"
	 * 	[api]="api"
	 * 	#expirationDateModalBoxRef
	 * >…</p-editable-modal-box>
	 *
	 * <button
	 * 	(click)="expirationDateModalBoxRef.modalButtonRef.openEditableModal()"
	 * >Open modal</button>
	 */
	public openEditableModal() : ModalRef {
		this.onModalOpen.emit(event);
		const modalServiceOptions = this.getModalServiceOptions();

		if (this.contentTemplateRef) {
			this.modalRef = this.modalService.openDefaultModal({
				contentTemplateRef: this.contentTemplateRef,
				contentTemplateContext: this.contentTemplateOptions,
				dismissBtnLabel: this.localizePipe.transform('Verwerfen'),
				dismissBtnIcon: enumsObject.PlanoFaIconPool.UNDO,
				hideDismissBtn: this.hideDismissBtn ?? undefined,
				closeBtnLabel: this.closeBtnLabel,
				modalTitle: this.label,
				footerTemplateRef: this.footerTemplateRef.template,
				footerTemplateContext: {
					dismiss: () => {
						this.modalRef!.dismiss();
					},
					close: () => {
						this.modalRef!.close();
					},
				},
			}, modalServiceOptions);
		} else {
			// This is deprecated and should be removed in the future (see formInModal)
			this.modalRef = this.modalService.openModal(this.formInModal.template, modalServiceOptions);
		}

		return this.modalRef;
	}

	public enums = enumsObject;
	public SectionWhitespace = SectionWhitespace;
	public PTextColorEnum = PTextColorEnum;

	private _label : string | null = null;
	private _showBtnLabel : boolean = true;

	public saveBtnHasBeenClicked : boolean = false;

	/**
	 * Is the modal open?
	 */
	public modalIsOpen : boolean = false;

	/**
	 * Gets used by e.g. PEditableModalBoxComponent
	 */
	public modalRef : ModalRef | null = null;

	public ngAfterContentInit() : void {
		this.initValues();
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		if (!this.closeBtnLabel) this.closeBtnLabel = this.localizePipe.transform('Speichern');
	}

	/**	closeBtnLabel can be a fn which returns a string */
	public get closeBtnLabelAsString() : string {
		if (typeof this.closeBtnLabel === 'string') return this.closeBtnLabel;
		assumeNonNull(this.closeBtnLabel);
		return this.closeBtnLabel();
	}

	private async saveEditableChanges(
		event : Event,
		closeCallback ?: (result : unknown) => void,
	) : Promise<void> {
		if (this.saveBtnHasBeenClicked) return;
		this.saveBtnHasBeenClicked = true;
		window.setTimeout(() => {
			// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
			if (this) this.saveBtnHasBeenClicked = false;
		}, 200);
		if (!this.pEditableIsActive) {
			if (closeCallback) closeCallback('close');
			return;
		}

		// Editable is active
		// TODO: Check if waitForEditableCompleteBeforeClose can be removed. Backend should be fast anyway.
		if (this.waitForEditableCompleteBeforeClose === true) {
			await this.pEditableRef.onSuccess(event);
		} else {
			void this.pEditableRef.onSuccess(event);
		}
		if (closeCallback) closeCallback('close');
	}

	/**
	 * This happens on dismiss-button and ×-button click
	 */
	public dismissChanges(
		dismissCallback ?: (result : unknown) => void,
	) : void {
		if (!this.pEditableIsActive) {
			if (dismissCallback) dismissCallback('close');
			return;
		}

		// no copy available to dismiss?
		if (!this.api!.hasDataCopy())

			// TODO: Find a way to add information about the source of this throw to the throw text.
			throw new Error('No data copy available. [PLANO-21475]');

		if (dismissCallback) dismissCallback('close');
		this.pEditableRef.onUndo();
	}

	private updateEditMode(event : boolean) : void {
		this.modalIsOpen = event;
		this.editMode.emit(event);
	}

	private getModalServiceOptions() : ModalServiceOptions {
		const options : ModalServiceOptions = new ModalServiceOptions();
		if (this.modalSize !== undefined) options.size = this.modalSize;
		if (this.centered !== undefined) options.centered = this.centered;
		if (this.backdrop !== undefined) options.backdrop = this.backdrop;
		if (this.windowClass !== undefined) options.windowClass = this.windowClass;
		if (!options.windowClass && this.theme) options.theme = this.theme;

		return options;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showSuccessButtonLoadingAnimation() : boolean {
		if (this.api!.isBackendOperationRunning) return true;
		if (this.api instanceof SchedulingApiService && this.api.isUpdatingWarnings) return true;
		if (this.saveBtnHasBeenClicked) return true;
		return false;
	}
}

@Component({
	selector: 'p-editable-modal-button-header',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableModalButtonHeaderComponent {
	@HostBinding('class.w-100')
	@HostBinding('class.d-flex')
	@HostBinding('class.justify-content-between')
	@HostBinding('class.align-items-center') protected _alwaysTrue = true;
}
