<div class="d-flex align-items-stretch flex-grow-1 w-100">
	<div [class.action-box-wrapper]="hasEditableBoxShowroom" class="w-100">
		<div *ngIf="!hasEditableBoxShowroom || !disabled" [class.action-indicator-wrapper]="hasEditableBoxShowroom">
			<ng-container *ngIf="(!!label || !!btnLabel); else noLabel">
				<span
					*ngIf="!!showBtnLabel"
				><fa-icon *ngIf="icon && showBtnIcon" class="mr-2 d-inline-block" [icon]="icon"></fa-icon>{{ btnLabel ? btnLabel : (label !== null ? label : ('Bearbeiten' | localize)) }}</span>
				<span
					*ngIf="!showBtnLabel"
				><fa-icon *ngIf="icon && showBtnIcon" class="d-inline-block" [icon]="icon"></fa-icon></span>
			</ng-container>
			<ng-template #noLabel>
				<fa-icon *ngIf="icon && showBtnIcon" class="d-inline-block" [icon]="icon"></fa-icon>
				<ng-content select="p-editable-modal-button-header"></ng-content>
			</ng-template>
		</div>
		<ng-content select="p-editable-showroom"></ng-content>
	</div>
	<!-- I need to use inline here, because i can not know if a label template is visible or not. So i can not add e.g. .mr-2 to the icon -->
</div>

<!--
	TODO: This div is probably obsolete as we internally grab `public pEditableRef : EditableDirective,`
	I dont want to remove it now, because we are in a 'hot phase' of 4.0 (gift card release)
	When we do we need to replace all the event listeners, as they currently have some problems.
-->
<div
	[pEditable]="pEditable"
	[api]="api"
	[valid]="valid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit()"
>
<!-- [changeDetection]="!!modalRef" -->
	<ng-template #formInModal="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
		<div class="modal-header d-flex align-items-center" [class.text-danger]="valid===false">
			<h4 class="m-0 d-flex align-items-center w-100"
				[class.text-white]="theme===enums.PThemeEnum.DARK"
				[class.text-danger]="valid===false"
			><ng-template [ngIf]="!!label || !!modalTitle" [ngIfElse]="noLabel">{{modalTitle ? modalTitle : label}}</ng-template><ng-template #noLabel><ng-container *ngIf="!modalTitle" i18n>Bearbeiten</ng-container></ng-template></h4>
			<button type="button" class="btn large p-0" title="Schließen" i18n-title
				(click)="d($event)"
			>
				<fa-icon
					aria-hidden="true"
					[theme]="theme===enums.PThemeEnum.DARK ? PTextColorEnum.WHITE : null"
					[icon]="enums.PlanoFaIconPool.DISMISS"
				></fa-icon>
			</button>
		</div>
		<form class="modal-body" [class.p-0]="modalWhitespace===SectionWhitespace.NONE" [class.p-4]="modalWhitespace===SectionWhitespace.LG">
			<ng-content select="p-editable-modal-form"></ng-content>
		</form>
		<ng-template [ngTemplateOutlet]="footerTemplateRef.template" [ngTemplateOutletContext]="{ dismiss: d, close: c }"></ng-template>
	</ng-template>
</div>

<ng-template #footerTemplateRef="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
<div class="modal-footer" *ngIf="!(hideDismissBtn && hideCloseBtn)">
	<div class="flex-grow-1 row">
		<ng-template [ngIf]="pEditable" [ngIfElse]="pEditableNotActive">
			<div class="col" *ngIf="!hideDismissBtn">
				<button
					type="button"

					class="btn btn-lg btn-outline-secondary"
					(click)="d($event)"
				><fa-icon [icon]="enums.PlanoFaIconPool.UNDO"></fa-icon><span class="d-none d-md-inline">&nbsp;<ng-container i18n>Verwerfen</ng-container></span></button>
			</div>
			<div class="col d-flex" *ngIf="!hideCloseBtn">
				<button
					type="button"

					[pTooltip]="saveButtonPopover"

					[class.progress-bar-striped]="showSuccessButtonLoadingAnimation"
					[class.progress-bar-animated]="showSuccessButtonLoadingAnimation"

					class="btn btn-lg ml-auto"

					[class.btn-primary]="closeBtnTheme === enums.PThemeEnum.PRIMARY"
					[class.btn-danger]="closeBtnTheme === enums.PThemeEnum.DANGER"

					(click)="handleModalClose($event, c)"
					[disabled]="valid===false || showSuccessButtonLoadingAnimation || closeBtnDisabled"
				><fa-icon [icon]="enums.PlanoFaIconPool.SUCCESS"></fa-icon><span class="d-none d-md-inline" i18n>&nbsp;{{closeBtnLabelAsString}}</span></button>
			</div>
		</ng-template>
		<ng-template #pEditableNotActive>
			<div class="col d-flex">
				<button
					type="button"
					class="btn btn-lg ml-auto"
					[class.btn-outline-secondary]="valid!==false"
					[class.btn-outline-danger]="valid===false"
					(click)="handleModalClose($event, c)"
					[disabled]="closeBtnDisabled"
					i18n
				>OK</button>
			</div>
		</ng-template>
	</div>
</div>
</ng-template>
