<div class="cal-month-view">
	<mwl-calendar-month-view-header
		[days]="columnHeaders"
		[locale]="locale"
		[customTemplate]="headerTemplate">
	</mwl-calendar-month-view-header>
	<div class="cal-days">
		<ng-template
			ngFor let-rowIndex
			[ngForOf]="view.rowOffsets"
		>
			<div class="cal-cell-row">
				<ng-template
					ngFor let-day
					[ngForOf]="slicedDays(rowIndex)"
					[ngForTrackBy]="trackByDate"
				>
					<!-- cspell:ignore unhighlight -->
					<mwl-calendar-month-cell
						(click)="onClick()"
						[ngClass]="day?.cssClass!"
						[ngStyle]="{ backgroundColor: day.backgroundColor }"
						[day]="day"
						[openDay]="openDay"
						[locale]="locale"
						[tooltipPlacement]="tooltipPlacement"
						[tooltipAppendToBody]="tooltipAppendToBody"
						[tooltipTemplate]="tooltipTemplate"
						[customTemplate]="cellTemplate"
						(mwlClick)="dayClicked.emit({ day: day, sourceEvent: $event })"
						(highlightDay)="toggleDayHighlight($event.event, true)"
						(unhighlightDay)="toggleDayHighlight($event.event, false)"
					></mwl-calendar-month-cell>
				</ng-template>
			</div>
			<mwl-calendar-open-day-events
				[isOpen]="openRowIndex === rowIndex"
				[events]="$any(openDay)?.events ?? []"
				[customTemplate]="openDayEventsTemplate"
				[eventTitleTemplate]="eventTitleTemplate"
				[eventActionsTemplate]="eventActionsTemplate"
				(eventClicked)="eventClicked.emit({event: $event.event, sourceEvent: $event.sourceEvent})"

			>
			<!-- mwlDroppable
			dragOverClass="cal-drag-over"
			(drop)="eventDropped(openDay, $event.dropData.event, $event.dropData.draggedFrom)"	 -->
			</mwl-calendar-open-day-events>
		</ng-template>
	</div>
</div>
