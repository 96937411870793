import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PTabsTheme } from '@plano/client/shared/p-tabs/p-tabs/p-tabs.component';
import { AiSwitchShowcaseApiService } from '@plano/shared/api';
import { Date, DateExclusiveEnd } from '@plano/shared/api/base/generated-types.ag';
import { PInputDateTypes } from '@plano/shared/p-forms/p-input-date/p-input-date.component';

/**
 * TODO: PLANO-172729
 * At the time of implementing this, we dont have access on storybook in Bitbucket pipeline. So, this is a quick solution
 * to show single component and perform tests on them individually.
 *
 * This should be moved to story-book when we have access to storybook in pipeline.
 */
@Component({
	selector: 'p-component-showcase',
	templateUrl: './p-component-showcase.component.html',
	styleUrls: ['./p-component-showcase.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class PComponentShowcaseComponent {
	constructor(
		public aiSwitchShowcaseApi : AiSwitchShowcaseApiService,
		pMomentService : PMomentService,
	) {
		const today = pMomentService.m().startOf('day');
		this.startTimestamp = today.valueOf();
		this.endTimestamp = today.add(1, 'day').valueOf();
		void this.aiSwitchShowcaseApi.load();
	}

	protected readonly PInputDateTypes = PInputDateTypes;
	protected PTabsTheme = PTabsTheme;

	/**
	 *  Create a throw to test the error modal
	 */
	protected throw() : void {
		throw new Error('This error is generated to test the error modal!');
	}

	/** A start timestamp */
	protected startTimestamp : Date;

	/** An end timestamp */
	protected endTimestamp : DateExclusiveEnd;
}
