<div class="d-flex align-items-center flex-wrap">
    <p-checkbox *ngIf="!isFormControlRequired"
    [valueText]="valueText"
    [(ngModel)]="isCheckedBox"
    class="color-checkbox d-inline-block mr-3"
    (onClick)="checkBoxClicked()"
    [hasButtonStyle]="false"/>
    <input *ngIf="hasRequiredError || (!hasRequiredError && value)" type="color"
    [(ngModel)]="value"
    />
</div>
