import { ApiObjectWrapper } from '@plano/shared/api';
import { DataBase } from '@plano/shared/core/data/data-base';
import { DataInputBase } from '@plano/shared/core/data/data-input-base';
import { notNull } from '@plano/shared/core/utils/null-type-utils';

/**
 * Base class for data-objects which uses one parameters to calculate the value.
 * This class caches the result for each parameter value.
 *
 * When any of the DataInput changes the whole cache is then cleared.
 *
 * This class does not support "undefined" values being passed as parameters.
 * Also see toNumber() for currently supported parameter types.
 */
export class ParameterDataBase<CacheType> extends DataBase<CacheType> {
	constructor(input1 : DataInputBase, input2 : DataInputBase | null = null, input3 : DataInputBase | null = null) {
		super(input1, input2, input3);
	}

	/**
	 * Transforms a param to a number which can used as index for the cache.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected toNumber(value : any) : number {
		if (value instanceof ApiObjectWrapper)
			return notNull(value.id).rawData;
		else if (typeof value === 'boolean')
			return value ? 1 : 0;
		else if (typeof value === 'number')
			return value;

		throw new Error('Unsupported parameter type.');
	}
}
