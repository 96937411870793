import { DecimalPipe } from '@angular/common';
import { ApplicationRef, ChangeDetectionStrategy, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { menuNavigationItemsOrder, sortMenuItems } from '@plano/client/shared/menu-navigation-items';
import { MemberBadgeComponent } from '@plano/client/shared/p-member/member-badges/member-badge/member-badge.component';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { AuthenticatedApiClientType, MeService, RightsService, SchedulingApiService } from '@plano/shared/api';
import { PSupportedLanguageCodes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PDropdownItemComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown-item/p-dropdown-item.component';
import { DropdownTypeEnum, PDropdownComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown.component';

type DropdownNavigationItem = {
	label : PDictionarySourceString,
	link : `/${string}`,
	prependedItem : PDropdownItemComponent['prependedItem'],
	condition : () => boolean
};

@Component({
	selector: 'p-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class HeaderComponent implements OnInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showSalesBtn : boolean = false;

	@ViewChild('dropdownRef') public dropdownRef ?: PDropdownComponent;
	@ViewChild('memberBadge') public memberBadge ?: MemberBadgeComponent;

	constructor(
		public meService : MeService,
		public rightsService : RightsService,
		public pRouterService : PRouterService,
		private localize : LocalizePipe,
		private pMoment : PMomentService,
		private appRef : ApplicationRef,
		private decimalPipe : DecimalPipe,
		private schedulingApiService : SchedulingApiService,
		private zone : NgZone,
	) {
	}

	public menuItemsOrder = menuNavigationItemsOrder;

	public AuthenticatedApiClientType = AuthenticatedApiClientType;
	public DropdownTypeEnum = DropdownTypeEnum;

	public enums = enumsObject;
	public Config = Config;

	/**
	 * Should the booking settings button be visible?
	 */
	public get showBookingSystemBtn() : boolean | null {
		if (Config.IS_MOBILE) return false;
		return this.rightsService.canReadAndWriteBookingSystemSettings ?? null;
	}

	/**
	 * Nice and short version of company name
	 */
	public get locationName() : string {
		return this.meService.data.locationName;
	}

	/**
	 * Returns a string with a more readable duration
	 */
	private getTime() : string {
		assumeDefinedToGetStrictNullChecksRunning(this.meService.data.testAccountDeadline, 'meService.data.testAccountDeadline');
		const DURATION = Math.max(0, this.meService.data.testAccountDeadline - Date.now());
		const ONE_HOUR = 1000 * 60 * 60;
		if (DURATION < ONE_HOUR) {
			const AMOUNT = this.pMoment.d(DURATION).asMinutes();
			return `${this.decimalPipe.transform(AMOUNT, '1.0-0')} ${AMOUNT < 2 ? this.localize.transform('Minute') : this.localize.transform('Minuten')}`;
		}
		const ONE_DAY = ONE_HOUR * 24;
		if (DURATION < ONE_DAY) {
			const AMOUNT = this.pMoment.d(DURATION).asHours();
			return `${this.decimalPipe.transform(AMOUNT, '1.0-0')} ${AMOUNT < 2 ? this.localize.transform('Stunde') : this.localize.transform('Stunden')}`;
		}
		if (DURATION >= ONE_DAY) {
			const AMOUNT = this.pMoment.d(DURATION).asDays();
			return `${this.decimalPipe.transform(AMOUNT, '1.0-0')} ${AMOUNT < 2 ? this.localize.transform('Tag') : this.localize.transform('Tage')}`;
		}
		return '-';
	}

	/**
	 * Text for the button that is only visible in test-accounts
	 */
	public get testaccountButtonText() : string {
		return this.localize.transform({sourceString: 'Test: noch ${time}', params: { time: this.getTime() }});
	}

	/**
	 * Is this page a menu?
	 */
	public get isMenuPage() : boolean {
		return this.pRouterService.url.includes('/client/menu') || this.pRouterService.url.includes('/client/profile-settings');
	}

	public avatarHasErrors : boolean = false;

	/**
	 * Set avatar has errors to true
	 */
	public handleAvatarImgError() : void {
		this.avatarHasErrors = true;
	}

	/**
	 * Navigate back to previous page
	 */
	public navBack() : void {
		this.pRouterService.navBack();
	}

	/**
	 * Change the language in the localize pipe
	 */
	public debugToggleLocalizeLanguage() : void {
		this.localize.languageTestSetter(this.otherLanguage);
		this.appRef.tick();
	}

	/**
	 * Returns a language that is currently not active
	 */
	public get otherLanguage() : PSupportedLanguageCodes {
		if (this.localize.languageTestGetter() === PSupportedLanguageCodes.de) {
			return PSupportedLanguageCodes.en;
		}
		return PSupportedLanguageCodes.de;
	}

	/**
	 * Set a way to activate our fancy-schmancy developer tools.
	 */
	public ngOnInit() : void {
		this.setDebugToolsListeners();
		this.makeSureSchedulingApiIsLoadedOnce();
		sortMenuItems(this.dropdownNavigationItems);
	}

	/**
	 * Set a way to activate our fancy-schmancy developer tools.
	 */
	private setDebugToolsListeners() : void {
		if (!(Config.DEBUG && this.devToolsVisible === null)) return;
		this.devToolsVisible = false;
		document.addEventListener('keydown', (event) => { this.showDevTools(event); }, false);
		document.addEventListener('keyup', (event) => { this.stopDevToolsTimeout(event); }, false);
	}

	/**
	 * @deprecated
	 * HACK: This hack solves PLANO-147729.
	 * We can not be sure if schedulingApiService has been loaded by any component.
	 * For example /client/time-stamp does not load schedulingApiService.
	 * But RightsService needs it because it needs the rightGroups to figure out if the user is allowed to see the
	 *   plugin-settings button in the dropdown of this header.
	 * TODO: Remove it. [PLANO-148429]
	 */
	private makeSureSchedulingApiIsLoadedOnce() : void {
		this.zone.runOutsideAngular(() => {
			window.setTimeout(() => {
				if (!this.schedulingApiService.isLoaded() && !this.schedulingApiService.isBackendOperationRunning) {
					void this.schedulingApiService.load();
				}
			}, 2000);
		});
	}

	/**
	 * Are the dev tools (buttons that only developers use) visible in the header?
	 */
	public devToolsVisible : boolean | null = null;

	/**
	 * Show the dev tools (buttons that only developers use) in the header
	 */
	public showDevTools(event : KeyboardEvent) : void {
		if (event.key === 'Alt') {
			this.devToolsVisible = true;
		}
	}

	/**
	 * Hide the dev tools (buttons that only developers use) in the header
	 */
	public stopDevToolsTimeout(event : KeyboardEvent) : void {
		if (event.key === 'Alt') this.devToolsVisible = false;
	}

	/**
	 * Navigation items to be rendered in the dropdown.
	 *
	 * The order here does not matter, it is defined in:
	 * @plano/client/shared/menu-navigation-items
	 */
	public dropdownNavigationItems : DropdownNavigationItem[] = [
		{ label: 'Mein Profil', link: '/client/member/me', condition: () => true, prependedItem: null},
		{ label: 'Account & Standort', link: '/client/account', condition: () => this.meService.data.isOwner, prependedItem: null },
		{ label: 'Rechteverwaltung', link: '/client/rightgroups', condition: () => this.meService.data.isOwner, prependedItem: null },
		{ label: 'Kalender-Synchronisation', link: '/client/ical', condition: () => true, prependedItem: null },
		{ label: 'Benachrichtigungs-Einstellungen', link: '/client/notifications', condition: () => true, prependedItem: null },
		{ label: 'Abmelden', link: '/client/logout', condition: () => true, prependedItem: null },
		{ label: 'Stempeluhr-Einstellungen', link: '/client/devices', condition: () => this.meService.data.isOwner, prependedItem: null },
		{ label: 'Buchungssystem-Einstellungen', link: '/client/plugin', condition: () => !!this.showBookingSystemBtn, prependedItem: null },
	];

}
