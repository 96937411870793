<button class="d-flex justify-content-between align-items-center clickable btn-outline-secondary {{triggerBtnClasses?triggerBtnClasses:''}}"
	[class.border-0]="collapsed || !hasContent"
	[class.border-top-0]="!collapsed && hasContent"
	[class.border-left-0]="!collapsed && hasContent"
	[class.border-right-0]="!collapsed && hasContent"
	[class.rounded]="!hasContent"
	[class.text-danger]="hasDanger"
	[class.uncollapsed]="!collapsed"
	[class.card-header]="size==='lg'"
	[class.btn-sm]="size==='sm'"
	(click)="toggle($event)"
	#collapsibleButton
	[id]="headerHtmlId"
	pAutoFocus
>
	<ng-content select="[trigger]"></ng-content>
	<fa-icon [class]="'ml-3 ' + (iconClasses ? iconClasses : '')" [size]="size" [icon]="collapsed ? enums.PlanoFaIconPool.COLLAPSIBLE_CLOSE : enums.PlanoFaIconPool.COLLAPSIBLE_OPEN"></fa-icon>
</button>
<div [@slideOpen]="!collapsed" style="height: 0; overflow: hidden;">
	<ng-container #ngContentContainer>
		<ng-content select="[content]"></ng-content>
	</ng-container>
</div>
