import { NgModule } from '@angular/core';
import { PDurationPipe } from './p-duration.pipe';
import { PTimeAgoPipe } from './time-ago.pipe';

@NgModule({
	declarations: [
		PDurationPipe,
		PTimeAgoPipe,
	],
	exports: [
		PDurationPipe,
		PTimeAgoPipe,
	],
})
export class ClientPipesModule {}
