<!-- it should be possible to use this component instead of the other template foo -->
<!-- <p-comment
	[memo]="memo"
	(onSave)="api.save()"
	(onOpenModal)="onOpenModal()"
	(onModalSuccess)="onModalSuccess()"
	(onModalDismiss)="onModalDismiss()"
	[showDateInput]="showDateInput"
	[clickable]="clickable"
	[maxLines]="maxLines"
	[maxTextLength]="maxTextLength"
	[userCanSetMemos]="userCanSetMemos"
></p-comment> -->

<div class="position-relative">
	<div class="d-block rounded-bottom o-hidden">
		<ng-template [ngIf]="!api.isLoaded()" [ngIfElse]="loaded">
			<p-spinner class="p-3"></p-spinner>
		</ng-template>
		<ng-template #loaded>
			<div class="card-options"
				*ngIf="userCanSetMemos"
			>
				<ng-template [ngIf]="hasMemo" [ngIfElse]="noMemoBtnTemplate">
					<button type="button"
						title="Löschen" i18n-title
						*ngIf="userCanSetMemos"
						class="hidden-without-hover card-option btn btn-sm btn-light btn-outline-danger ml-auto"
						[disabled]="!api.isLoaded()"
						(click)="removeMemoPrompt(removeMemoModalContent.template)"
					><fa-icon [icon]="enums.PlanoFaIconPool.DELETE"></fa-icon></button>
				</ng-template>
				<ng-template #noMemoBtnTemplate>
					<button type="button"
						*ngIf="userCanSetMemos"
						title="Hinzufügen" i18n-title
						class="card-option btn btn-sm btn-outline-secondary btn-light ml-auto"
						[disabled]="!api.isLoaded()"
						(click)="userCanSetMemos ? openMemo(modalContent.template) : undefined"
					><fa-icon [icon]="enums.PlanoFaIconPool.ADD"></fa-icon></button>
				</ng-template>
			</div>
			<div
				tabindex="0"
				class="w-100 text-left d-block m-0 small p-0"
				[class.card]="!blockquoteIsClickable"
				[class.clickable]="blockquoteIsClickable"
				[class.btn-frameless]="blockquoteIsClickable"
				[class.btn-light]="blockquoteIsClickable"
				(click)="blockquoteIsClickable ? openMemo(modalContent.template) : undefined"
			>
				<blockquote class="m-0" [class.text-muted]="!hasMemo">
					<div class="p-2" *ngIf="hasMemo; else noMemoTemplate">
						<span class="pt-3" [innerHTML]="textToHtml(memo!.message)"></span>
					</div>
					<ng-template #noMemoTemplate>
						<div class="p-1 text-center" i18n>Kein Tageskommentar heute</div>
					</ng-template>
				</blockquote>
			</div>
		</ng-template>
	</div>
</div>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-memo-modal-content
		class="modal-content"
		[memo]="memo"
		[memoModalDay]="dayStart"
		[showDateInput]="false"
		(onClose)="c($event)"
		(dismiss)="d($event)"
		[userCanSetMemos]="userCanSetMemos"
	></p-memo-modal-content>
</ng-template>

<ng-template #removeMemoModalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-modal-content
		[theme]="enums.PThemeEnum.DANGER"
		modalTitle="Tageskommentar löschen?" i18n-modalTitle
		closeBtnLabel="Ja, löschen" i18n-closeBtnLabel
		dismissBtnLabel="Abbrechen" i18n-dismissBtnLabel
		(onDismiss)="d($event)"
		(onClose)="c($event);"
	>
		<p-modal-content-body>
			<p-spinner *ngIf="!memo"></p-spinner>
			<p class="m-0" i18n *ngIf="!!memo">Soll der Kommentar für {{ memo.start | date:'EEEE' }}, den {{ memo.start | date:'shortDate' }} wirklich gelöscht werden?</p>
		</p-modal-content-body>
	</p-modal-content>
</ng-template>
