<button
	type="button"
	class="btn flex-grow-1 text-left overflow-hidden"
	[class.btn-secondary]="!value"
	[class.btn-outline-secondary]="!!value"
	[class.p-0]="!label"

	pEditableModalButton
	#pEditableModalButtonRef
	[hideDismissBtn]="!pEditableModalButtonRef.pEditable"
	[hideCloseBtn]="!pEditableModalButtonRef.pEditable"
	[showBtnIcon]="!value"
	[icon]="icon"
	[label]="label!"
	(onModalOpen)="onModalOpen()"
	(onModalClosed)="onModalClosed()"
	(onModalDismissed)="onModalDismiss();"
>
	<p-editable-modal-button-header class="d-flex flex-grow-1 text-body">
		<div style="padding-block: 10px !important;" class="p-2 align-self-stretch position-relative bg-white pl-3 flex-grow-1 d-flex text-style-reset-to-body">
			<p-color-marker
				class="d-flex"
				[item]="valueItem"
				[hexColor]="valueItem?.color ? '#' + valueItem?.color : null"
				[title]="valueItem ? valueItem.name : undefined"
				[isPacket]="valueItem ? !!valueItem.isPacket : false"
			></p-color-marker> {{ valueItem ? valueItem.name : label }}
		</div>
	</p-editable-modal-button-header>
	<p-editable-modal-form>
		<p-input
			class="mb-3"
			[type]="PApiPrimitiveTypes.Search"
			[(ngModel)]="searchTerm"
		></p-input>
		<ng-template [ngIf]="!!shiftModelsForList.length" [ngIfElse]="noItemsAvailable">
			<p-shiftmodel-list
				[selectedItemId]="selectedItemId"
				[shiftModels]="shiftModelsForList"
				(onItemClick)="onItemClick($event)"
			></p-shiftmodel-list>
		</ng-template>
		<ng-template #noItemsAvailable>
			<p-alert
				[theme]="enums.PThemeEnum.WARNING"
				[dismissable]="false"
				i18n
			>Es wurden keine Tätigkeiten gefunden.</p-alert>
		</ng-template>
	</p-editable-modal-form>
</button>
<button *ngIf="value !== null" type="button" class="btn btn-sm btn-primary flex-grow-0" (click)="value=null"><fa-icon
	[icon]="enums.PlanoFaIconPool.DISMISS"
></fa-icon></button>
