import { NgModule } from '@angular/core';

@NgModule({
	declarations: [
	],
	imports: [
	],
	providers: [
	],
	exports: [
	],
})
export class PSentryModule {}
