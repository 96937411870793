import { AfterContentChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';

@Component({
	selector: 'p-shiftmodel-list',
	templateUrl: './shiftmodel-list.component.html',
	styleUrls: ['./shiftmodel-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftmodelListComponent implements PComponentInterface, AfterContentChecked {
	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftModels : SchedulingApiShiftModels = new SchedulingApiShiftModels(null, null);
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public contentTemplate : TemplateRef<unknown> | null = null;

	/** The headline of this component */
	@Input() public label : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private selectedItemId : Id | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onItemClick : EventEmitter<SchedulingApiShiftModel> = new EventEmitter<SchedulingApiShiftModel>();

	public parentNames : string[] = [];

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasOnItemClickBinding() : boolean {
		return this.onItemClick.observers.length > 0;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onClickItem(shiftModel : SchedulingApiShiftModel) : void {
		if (!this.hasOnItemClickBinding) return;
		this.selectedItemId = shiftModel.id;
		this.onItemClick.emit(shiftModel);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isSelected(shiftModel : SchedulingApiShiftModel) : boolean {
		return this.selectedItemId === shiftModel.id;
	}

	public ngAfterContentChecked() : void {
		this.refreshParentNames();
	}

	private refreshParentNames() : void {
		this.parentNames = this.shiftModels.parentNames.sort();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getShiftModelsIterableByParentName(parentName : string) : readonly SchedulingApiShiftModel[] {
		return this.shiftModels.filterBy(
			shiftModel => shiftModel.parentName === parentName,
		).sortedBy(
			shiftModel => shiftModel.name,
		).iterable();
	}
}
