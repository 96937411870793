/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';

import { AuthenticatedApiRoot } from '@plano/shared/api';


/**
 * This service enables access to the api "authenticated".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	LOCALE = 1;
	ROLE = 2;
	PHONE = 3;
	EMAIL = 4;
	FIRST_NAME = 5;
	LAST_NAME = 6;
	GENDER = 7;
	LOCATION_NAME = 8;
	COMPANY_NAME = 9;
	CLIENT_ID = 10;
	SECURE_TOKEN = 11;
	TEST_ACCOUNT_DEADLINE = 12;
	TAWK_HMAC = 13;
	CLIENT_TYPE = 14;
	LOGGED_IN_WITH_MASTER_PASSWORD = 15;
	LOGGED_IN_WITH_MASTER_PASSWORD_READ_ONLY = 16;
	AVATAR = 17;
}


@Injectable({
  providedIn: 'root',
})
export class AuthenticatedApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'authenticated');
	}

	protected version() : string {
		return '47fc84ebca25803d7a70f28b67a44df6,cd7fc627f7a8b98036b2ab0e8620dfe4';
	}

	private dataWrapper = new AuthenticatedApiRoot(this, );

	get data() : AuthenticatedApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : AuthenticatedApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new AuthenticatedApiRoot(this, );
	}
}

		 
export class AuthenticatedApiRootBase extends ApiObjectWrapper<AuthenticatedApiRoot>
{
	constructor(override readonly api : AuthenticatedApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AuthenticatedApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, AuthenticatedApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiRoot> = new ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiRoot>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: '',
			nodeName: 'ROOT',
			hasRightToSet: () => false,
			defaultValue: function(this : AuthenticatedApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoLocale : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'locale',
			nodeName: 'LOCALE',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToSet: () => false,
			rawDataIndex: 1,
		});
	attributeInfoRole : ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiRole> = new ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiRole>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'role',
			nodeName: 'ROLE',
			primitiveType: PApiPrimitiveTypes.Enum,
			hasRightToSet: () => false,
			rawDataIndex: 2,
		});
	attributeInfoPhone : ApiAttributeInfo<AuthenticatedApiRoot, Tel> = new ApiAttributeInfo<AuthenticatedApiRoot, Tel>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'phone',
			nodeName: 'PHONE',
			primitiveType: PApiPrimitiveTypes.Tel,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[3] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});
	attributeInfoEmail : ApiAttributeInfo<AuthenticatedApiRoot, Email> = new ApiAttributeInfo<AuthenticatedApiRoot, Email>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'email',
			nodeName: 'EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[4] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 4,
		});
	attributeInfoFirstName : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'firstName',
			nodeName: 'FIRST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[5] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 5,
		});
	attributeInfoLastName : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'lastName',
			nodeName: 'LAST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[6] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 6,
		});
	attributeInfoGender : ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiGender> = new ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiGender>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'gender',
			nodeName: 'GENDER',
			primitiveType: PApiPrimitiveTypes.Enum,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[7] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 7,
		});
	attributeInfoLocationName : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'locationName',
			nodeName: 'LOCATION_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[8] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 8,
		});
	attributeInfoCompanyName : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'companyName',
			nodeName: 'COMPANY_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[9] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 9,
		});
	attributeInfoClientId : ApiAttributeInfo<AuthenticatedApiRoot, Id> = new ApiAttributeInfo<AuthenticatedApiRoot, Id>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'clientId',
			nodeName: 'CLIENT_ID',
			primitiveType: PApiPrimitiveTypes.Id,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[10] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 10,
		});
	attributeInfoSecureToken : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'secureToken',
			nodeName: 'SECURE_TOKEN',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToSet: () => false,
			rawDataIndex: 11,
		});
	attributeInfoTestAccountDeadline : ApiAttributeInfo<AuthenticatedApiRoot, DateTime> = new ApiAttributeInfo<AuthenticatedApiRoot, DateTime>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'testAccountDeadline',
			nodeName: 'TEST_ACCOUNT_DEADLINE',
			primitiveType: PApiPrimitiveTypes.DateTime,
			hasRightToSet: () => false,
			rawDataIndex: 12,
		});
	attributeInfoTawkHmac : ApiAttributeInfo<AuthenticatedApiRoot, string> = new ApiAttributeInfo<AuthenticatedApiRoot, string>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'tawkHmac',
			nodeName: 'TAWK_HMAC',
			primitiveType: PApiPrimitiveTypes.string,
			hasRightToSet: () => false,
			rawDataIndex: 13,
		});
	attributeInfoClientType : ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiClientType> = new ApiAttributeInfo<AuthenticatedApiRoot, AuthenticatedApiClientType>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'clientType',
			nodeName: 'CLIENT_TYPE',
			primitiveType: PApiPrimitiveTypes.Enum,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[14] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 14,
		});
	attributeInfoLoggedInWithMasterPassword : ApiAttributeInfo<AuthenticatedApiRoot, boolean> = new ApiAttributeInfo<AuthenticatedApiRoot, boolean>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'loggedInWithMasterPassword',
			nodeName: 'LOGGED_IN_WITH_MASTER_PASSWORD',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : AuthenticatedApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 15,
		});
	attributeInfoLoggedInWithMasterPasswordReadOnly : ApiAttributeInfo<AuthenticatedApiRoot, boolean> = new ApiAttributeInfo<AuthenticatedApiRoot, boolean>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'loggedInWithMasterPasswordReadOnly',
			nodeName: 'LOGGED_IN_WITH_MASTER_PASSWORD_READ_ONLY',
			primitiveType: PApiPrimitiveTypes.boolean,
			hasRightToSet: () => false,
			defaultValue: function(this : AuthenticatedApiRoot, _nodeId : string) {return false;},
			rawDataIndex: 16,
		});
	attributeInfoAvatar : ApiAttributeInfo<AuthenticatedApiRoot, Image> = new ApiAttributeInfo<AuthenticatedApiRoot, Image>({
			apiObjWrapper: this as any as AuthenticatedApiRoot,
			name: 'avatar',
			nodeName: 'AVATAR',
			primitiveType: PApiPrimitiveTypes.Image,
			hasRightToGet: function(this : AuthenticatedApiRoot) {
				return ((this.data[17] !== undefined));
			},
			hasRightToSet: () => false,
			rawDataIndex: 17,
		});

	/**
     *  The locale of the user.
	 *
	 * @type {string}
     */
	get locale() : string {
		this.getterDebugValidations(this.attributeInfoLocale, false);
		return this.data[1];
	}

	set localeTestSetter(v : string) {
        this.setterImpl(1, v, 'locale', true, null, null);
	}

	/**
     *  Role of authenticated entity.
	 *
	 * @type {AuthenticatedApiRole}
     */
	get role() : AuthenticatedApiRole {
		this.getterDebugValidations(this.attributeInfoRole, false);
		return this.data[2];
	}

	set roleTestSetter(v : AuthenticatedApiRole) {
        this.setterImpl(2, v, 'role', true, null, null);
	}

	/**
     *  Phone number of the user.
	 *
	 * @type {Tel}
     */
	get phone() : Tel | null {
		this.getterDebugValidations(this.attributeInfoPhone, false);
		return this.data[3];
	}

	set phoneTestSetter(v : Tel | null) {
        this.setterImpl(3, v, 'phone', true, null, null);
	}

	/**
     *  Email address of the user.
	 *
	 * @type {Email}
     */
	get email() : Email {
		this.getterDebugValidations(this.attributeInfoEmail, false);
		return this.data[4];
	}

	set emailTestSetter(v : Email) {
        this.setterImpl(4, v, 'email', true, null, null);
	}

	/**
     *  First name of the user.
	 *
	 * @type {string}
     */
	get firstName() : string {
		this.getterDebugValidations(this.attributeInfoFirstName, false);
		return this.data[5];
	}

	set firstNameTestSetter(v : string) {
        this.setterImpl(5, v, 'firstName', true, null, null);
	}

	/**
     *  Last name of the user.
	 *
	 * @type {string}
     */
	get lastName() : string {
		this.getterDebugValidations(this.attributeInfoLastName, false);
		return this.data[6];
	}

	set lastNameTestSetter(v : string) {
        this.setterImpl(6, v, 'lastName', true, null, null);
	}

	/**
     *  The gender of the user.
	 *
	 * @type {AuthenticatedApiGender}
     */
	get gender() : AuthenticatedApiGender {
		this.getterDebugValidations(this.attributeInfoGender, false);
		return this.data[7];
	}

	set genderTestSetter(v : AuthenticatedApiGender) {
        this.setterImpl(7, v, 'gender', true, null, null);
	}

	/**
     *  Public location name. Read-only.
	 *
	 * @type {string}
     */
	get locationName() : string {
		this.getterDebugValidations(this.attributeInfoLocationName, false);
		return this.data[8];
	}

	set locationNameTestSetter(v : string) {
        this.setterImpl(8, v, 'locationName', true, null, null);
	}

	/**
     *  Public location name. Read-only.
	 *
	 * @type {string}
     */
	get companyName() : string {
		this.getterDebugValidations(this.attributeInfoCompanyName, false);
		return this.data[9];
	}

	set companyNameTestSetter(v : string) {
        this.setterImpl(9, v, 'companyName', true, null, null);
	}

	private clientIdWrapper : Id = null!;

	/**
     *  The client id of this authenticated. Read-only. "null" is returned if this authenticated is not associated with a client.
	 *
	 * @type {Id}
     */
	get clientId() : Id {
		this.getterDebugValidations(this.attributeInfoClientId, false);
		return this.clientIdWrapper;
	}

	set clientIdTestSetter(v : Id) {
        this.setterImpl(10, v, 'clientId', true, null, () => {this.clientIdWrapper = v;});
	}

	/**
     *  The secure token of this user. Read-only.
	 *
	 * @type {string}
     */
	get secureToken() : string {
		this.getterDebugValidations(this.attributeInfoSecureToken, false);
		return this.data[11];
	}

	set secureTokenTestSetter(v : string) {
        this.setterImpl(11, v, 'secureToken', true, null, null);
	}

	/**
     *  Deadline of the test account. "null" is returned if this is a paid account. Read-only.
	 *
	 * @type {DateTime}
     */
	get testAccountDeadline() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoTestAccountDeadline, false);
		return this.data[12];
	}

	set testAccountDeadlineTestSetter(v : DateTime | null) {
        this.setterImpl(12, v, 'testAccountDeadline', true, null, null);
	}

	/**
     *  Hmac value being needed to use the tawk javascript api.
	 *
	 * @type {string}
     */
	get tawkHmac() : string {
		this.getterDebugValidations(this.attributeInfoTawkHmac, false);
		return this.data[13];
	}

	set tawkHmacTestSetter(v : string) {
        this.setterImpl(13, v, 'tawkHmac', true, null, null);
	}

	/**
     *  The type of this client. Read-only.
	 *
	 * @type {AuthenticatedApiClientType}
     */
	get clientType() : AuthenticatedApiClientType {
		this.getterDebugValidations(this.attributeInfoClientType, false);
		return this.data[14];
	}

	set clientTypeTestSetter(v : AuthenticatedApiClientType) {
        this.setterImpl(14, v, 'clientType', true, null, null);
	}

	/**
     *  Is the user logged in with our master password? This includes both the read-only and write-permission master password.
	 *
	 * @type {boolean}
     */
	get loggedInWithMasterPassword() : boolean {
		this.getterDebugValidations(this.attributeInfoLoggedInWithMasterPassword, false);
		return this.data[15];
	}

	set loggedInWithMasterPasswordTestSetter(v : boolean) {
        this.setterImpl(15, v, 'loggedInWithMasterPassword', true, null, null);
	}

	/**
     *  Is the user logged in with our read-only master password?
	 *
	 * @type {boolean}
     */
	get loggedInWithMasterPasswordReadOnly() : boolean {
		this.getterDebugValidations(this.attributeInfoLoggedInWithMasterPasswordReadOnly, false);
		return this.data[16];
	}

	set loggedInWithMasterPasswordReadOnlyTestSetter(v : boolean) {
        this.setterImpl(16, v, 'loggedInWithMasterPasswordReadOnly', true, null, null);
	}

	/**
     *  The avatar of currently logged in member.
	 *
	 * @type {Image}
     */
	get avatar() : Image | null {
		this.getterDebugValidations(this.attributeInfoAvatar, false);
		const path = this.data[17] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set avatarTestSetter(v : Image | null) {
        this.setterImpl(17, v, 'avatar', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.data[10] = Meta.getReplacedId(this.data[10], _idReplacements);
		this.clientIdWrapper = Id.create(this.data[10]);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		if(!Meta.isSameId(data ? data[10] : null, this.clientIdWrapper))
			this.clientIdWrapper = data && data[10] ? Id.create(data[10]) : null!;
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : AuthenticatedApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

export enum AuthenticatedApiRole {
	CLIENT_DEFAULT = 1,
	CLIENT_OWNER = 2,
	ADMIN = 3,
	SUPER_ADMIN = 4,
}
export enum AuthenticatedApiGender {
	MALE = 1,
	FEMALE = 2,
	DIVERSE = 3,
}
export enum AuthenticatedApiClientType {
	TEST_NEED_VERIFICATION = 1,
	TEST = 2,
	TEST_EXPIRED = 3,
	PAID = 4,
	TERMINATED = 5,
}


