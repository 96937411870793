<p-dumb-booking-item
	[isLoading]="isLoading"
	[id]="booking.id"
	[model]="booking.model"
	[state]="booking.state"
	[paymentStatus]="booking.paymentStatus"
	[bookingNumber]="booking.bookingNumber"
	[ownerComment]="booking.ownerComment"
	[dateOfBooking]="booking.dateOfBooking"
	[userCanWrite]="userEditBookings"
	[bookingCourseSelector]="booking.courseSelector"
	[bookingComment]="booking.bookingComment"
	[onSelectShiftsLink]="onSelectShiftsLink"
	[showFirstShiftStart]="false"
></p-dumb-booking-item>
