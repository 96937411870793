import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PDictionarySourceString } from './shared/core/pipe/localize.dictionary';
import { LocalizePipe } from './shared/core/pipe/localize.pipe';

/** I18N_VALUES */
const I18N_VALUES : {weekdays : PDictionarySourceString[], months : PDictionarySourceString[]} = {
	weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
	months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
};

// Define custom service providing the months and weekdays translations
@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CustomDatepickerI18n extends NgbDatepickerI18n {

	constructor(
		private localize : LocalizePipe,
	) {
		super();
	}

	/** getWeekdayLabel */
	public getWeekdayLabel(weekday : number, _width ?: TranslationWidth) : string {
		return this.localize.transform(I18N_VALUES.weekdays[weekday - 1]);
	}

	/** getWeekdayShortName */
	public getWeekdayShortName(weekday : number) : string {
		return this.localize.transform(I18N_VALUES.weekdays[weekday - 1]);
	}

	/** getMonthShortName */
	public getMonthShortName(month : number) : string {
		return this.localize.transform(I18N_VALUES.months[month - 1]);
	}

	/** getMonthFullName */
	public getMonthFullName(month : number) : string {
		return this.getMonthShortName(month);
	}

	/** getDayAriaLabel */
	public getDayAriaLabel(date : Required<NgbDateStruct>) : string {
		return this.localize.transform({
			sourceString: '${day}-${month}-${year}',
			params: {
				day: date.day.toString(),
				month: date.month.toString(),
				year: date.year.toString(),
			},
		});
	}
}
