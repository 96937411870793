<button
	pEditableModalButton
	class="p-0 card bg-light w-100"
	[modalSize]="'fullscreen'"
	[theme]="readMode || !disabled ? PBackgroundColorEnum.WHITE : null"

	pAutoFocus

	[pEditable]="pEditable"
	[api]="api"
	[valid]="isValid"
	[saveChangesHook]="saveChangesHook"
	(onSaveStart)="onSaveStart.emit($event)"
	(onSaveSuccess)="onSaveSuccess.emit($event)"
	(onDismiss)="onDismiss.emit($event)"
	(onLeaveCurrent)="onLeaveCurrent.emit($event)"
	(editMode)="editMode.emit($event)"

	(onModalOpen)="openModalSubscriber()"
	(onModalClosed)="closeModalSubscriber()"
	[beforeModalClose]="boundBeforeModalClose"
	[modalTitle]="translatedTitleRef.textContent"
	[title]="translatedTitleRef.textContent"

	[disabled]="disabled || readMode"
>
	<p-editable-showroom>
		<p-shifts-info
				*ngFor="let shiftRef of sortedShiftRefs; let i = index"
				class="align-self-stretch flex-grow-1"
				[class.border-top]="i!==0"
				[shiftId]="shiftRef.id"
				role="listitem"
			>
				<p-shift-info-content-left class="ml-2" *ngIf="member">
					<p-member-badge
						[memberId]="member.id"
						[firstName]="member.firstName"
						[lastName]="member.lastName"
						[isMe]="!!isMe"
						[size]="'small'"
					></p-member-badge>
				</p-shift-info-content-left>
				<p-shift-info-content-right
					*ngIf="showCalendarBtn"
					class="align-self-stretch"
				>
					<a
						[routerLink]="pShiftExchangeService.calendarClickLink(shiftRef)"
						class="btn rounded-0 btn-frameless border-left btn-outline-secondary align-self-stretch card-option"
						(click)="$event.stopPropagation();onCalendarClick(shiftRef)"
						title="Im Kalender ansehen" i18n-title
					>
					<!-- *ngIf="showMultiSelectCheckbox" -->
					<fa-icon [icon]="enums.PlanoFaIconPool.CALENDAR_SELECT_RELATED_SHIFTS"></fa-icon></a>
				</p-shift-info-content-right>
		</p-shifts-info>
		<label class="p-2 m-0 text-danger" *ngIf="sortedShiftRefs.length === 0" i18n><fa-icon [icon]="enums.PlanoFaIconPool.ADD" class="mr-2"></fa-icon>Schichten hinzufügen</label>
	</p-editable-showroom>
	<p-editable-modal-form class="flex-grow-1 d-flex flex-column">
		<p-shift-picker
			class="flex-grow-1 d-flex flex-column"
			#shiftPickerRef
			[formArray]="control"
			[loadDetailedItem]="loadDetailedItem"
			[availableShifts]="availableShifts"
			[shiftTemplate]="shiftTemplate"
			[offersRef]="offersRef"
			[hideAddToOffersBtn]="offersRef && !!offersRef.length"
			(onAddShifts)="onAddShifts.emit($event)"
			[changeOfferHook]="changeOfferHook"
		></p-shift-picker>
	</p-editable-modal-form>
</button>
<div hidden><span i18n #translatedTitleRef>{!!offersRef.length, select, true {Schichten bearbeiten} other {Schichten hinzufügen}}</span></div>
