import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-delete-button',
	templateUrl: './p-delete-button.component.html',
	styleUrls: ['./p-delete-button.component.scss'],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PDeleteButtonComponent {

	/**
	 * Overwrite classes outside.
	 */
	@Input() @HostBinding() public class : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onModalSuccess : EventEmitter<Event> = new EventEmitter<Event>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onModalDismiss : EventEmitter<Event> = new EventEmitter<Event>();

	/** The headline of this component */
	@Input() public label : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('modalText') private _modalText : string | null = null;
	@Input() public disabled : boolean = false;

	@ViewChild('warningModalContent', { static: true }) private warningModalContent ! : PModalTemplateDirective;

	constructor(
		private modalService : ModalService,
		private localize : LocalizePipe,
	) {
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get modalText() : string {
		if (this._modalText) return this._modalText;
		if (this.label) return this.label;
		return this.localize.transform('Löschen');
	}

	public modalTheme : ModalServiceOptions['theme'] = enumsObject.PThemeEnum.DANGER;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onRemoveClick(event : MouseEvent) : ModalRef {
		// if (this.warningModalContentTemplate.elementRef.nativeElement) return;
		const modalRef = this.modalService.openModal(this.warningModalContent.template, {
			theme: this.modalTheme ?? null,
			centered: true,
		});

		void modalRef.result.then(promiseResult => {
			if (promiseResult.modalResult === 'dismiss') {
				this.onModalDismiss.emit(event);
			} else {
				this.onModalSuccess.emit(event);
			}
		});

		return modalRef;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get translatedDeleteWarningModalCloseBtnLabel() : string {
		if (!this.label) return this.localize.transform('Ja');
		return this.localize.transform({sourceString: 'Ja, ${label}', params: { label: this.label }});
	}
}
