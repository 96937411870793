import { ApiDataWrapperBase } from '@plano/shared/api/base/api-data-wrapper-base';
import { ApiAttributeInfoArgsBase, ApiAttributeInfoBase, AttributeInfoVarsBase } from '@plano/shared/api/base/attribute-info/api-attribute-info-base';

/**
 * An object of this class contains meta information for a possible value of an attribute. The value needs to be of type enum or boolean.
 * See `<node><values></values></node>` in the API XML files.
 */
export class ApiAttributeValueInfo<ParentType extends ApiDataWrapperBase>
	extends ApiAttributeInfoBase<ParentType, ApiAttributeInfoArgsBase<ParentType, AttributeInfoVarsBase<ParentType>>> {
}
