/* eslint-disable max-lines */

import { HttpParams } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { SHAKE_SIDEWAYS_ON_BOOLEAN_TRIGGER } from '@plano/animations';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PAlertThemeEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { TransmissionPreviewComponent } from '@plano/client/shared/p-transmission/transmission-preview/transmission-preview.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { AffectedShiftsApiService, MeService, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftChangeSelector, SchedulingApiShiftModel, SchedulingApiShiftRepetitionType, SchedulingApiShifts } from '@plano/shared/api';
import { DateTime } from '@plano/shared/api/base/generated-types.ag';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { LogService } from '@plano/shared/core/log.service';
import { PAutoFocusService } from '@plano/shared/core/p-auto-focus/p-auto-focus.service';
import { ModalContentComponent, ModalContentComponentCloseReason } from '@plano/shared/core/p-modal/modal-content-component.interface';
import { ModalRef } from '@plano/shared/core/p-modal/modal.service';
import { ModalDismissParam } from '@plano/shared/core/p-modal/modal.service.options';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { AngularDatePipeFormat } from '@plano/shared/core/pipe/p-date.pipe';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { AISwitchUIType } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';
import { NgWizardConfig, NgWizardService, NgWizardStep, NgWizardStepComponent, STEP_DIRECTIN, STEP_POSITION, STEP_STATE, StepChangedArgs, StepValidationArgs, THEME } from 'ng-wizard';
import { Observable, of } from 'rxjs';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum PTypeOfChange {
	EDIT = 'other',
	DELETE = 'remove',
	CANCEL = 'cancel',
}

@Component({
	selector: 'p-change-selectors-modal[shiftChangeSelector]',
	templateUrl: './change-selectors-modal.component.html',
	styleUrls: ['./change-selectors-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [SHAKE_SIDEWAYS_ON_BOOLEAN_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ChangeSelectorsModalComponent implements OnInit, ModalContentComponent<ModalContentComponentCloseReason>, AfterContentChecked {

	// eslint-disable-next-line literal-blacklist/literal-blacklist
	@ViewChild('cancellationStep') private cancellationStep ?: NgWizardStep;

	/**
	 * A more fine-grained title can be provided here. If not set a more generic sentence will be used.
	 */
	@Input('title') private _title ?: string;

	/**
	 * Needed to generate nice user-badges in the transmission-preview (little calendar with affected shifts).
	 */
	@Input() public members : TransmissionPreviewComponent['members'] = null;

	/**
	 * The shiftModel where the values have changed.
	 * Only needs to be set if this is a change-selector-modal for a shiftModel.
	 */
	@Input() public shiftModel : SchedulingApiShiftModel | null = null;

	/**
	 * The shift where the values have changed.
	 * Only needs to be set if this is a change-selector-modal for a shift.
	 */
	@Input() public shift : SchedulingApiShift | null = null;

	/**
	 * Was the selected shift selected from the tooltip?
	 */
	@Input() public shiftSelectedFromTooltip : boolean = false;

	/**
	 * The shifts where the values have changed.
	 * Only needs to be set if this ia change-selector-modal for a list of shifts.
	 */
	@Input() public shifts : SchedulingApiShifts | null = null;

	/**
	 * The object containing all params the backend needs to know to transmit values to other shifts.
	 */
	@Input() public shiftChangeSelector ! : SchedulingApiShiftChangeSelector;

	/**
	 * A default start date. If not set, a default will be calculated. E.g. start of shift or 'today'.
	 */
	@Input() private defaultStart ?: DateTime;

	/**
	 * HACK: This indicates if the modal was triggered by one of the course section in shift-forms.
	 * The fields where this is true are (state 13. July 2020)
	 * isCourseOnline
	 * minCourseParticipantCount
	 * maxCourseParticipantCount
	 */
	@Input() public modalForCourseRelatedValues = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public typeOfChange : PTypeOfChange = PTypeOfChange.EDIT;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public minDate : number | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('minEndDate') private _minEndDate : number | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public customWarningAlert : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public customDangerAlert : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('showApplyToShiftModelCheckbox') public _showApplyToShiftModelCheckbox : boolean | null = null;

	@HostBinding('class.modal-content') private _alwaysTrue = true;

	@ViewChild('ngContent', { static: true }) public ngContent ! : ElementRef<HTMLDivElement>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private showSendMailCheckbox = false;

	/**
	 * A function that will be called in the moment the modal closes.
	 * TODO: Check if this should be replaced by a @Output() thingy.
	 */
	@Input() private close : (value : string | ModalContentComponentCloseReason) => void = () => {};

	/**
	 * A function that will be called in the moment the modal is dismissed.
	 * TODO: Check if this should be replaced by a @Output() thingy.
	 */
	@Input() public dismiss : (value : ModalDismissParam) => void = () => {};

	constructor(
		public affectedShiftsApiService : AffectedShiftsApiService,
		private schedulingService : SchedulingService,
		public api : SchedulingApiService,
		private pMoment : PMomentService,
		private localize : LocalizePipe,
		private pFormsService : PFormsService,
		public meService : MeService,
		private changeDetectorRef : ChangeDetectorRef,
		private ngWizardService : NgWizardService,
		private zone : NgZone,
		private toastsService : ToastsService,
		private pAutoFocusService : PAutoFocusService,
		private console : LogService,
	) {
		this.today = +this.pMoment.m().startOf('day');
	}

	public enums = enumsObject;
	public SectionWhitespace = SectionWhitespace;
	public AngularDatePipeFormat = AngularDatePipeFormat;
	public AISwitchUIType = AISwitchUIType;
	public PTypeOfChange = PTypeOfChange;
	public PAlertThemeEnum = PAlertThemeEnum;
	public STEP_STATE = STEP_STATE;

	public componentInitialized = false;

	public today ! : number;

	/**
	 * The Option »Apply to Shift Model« does not always make sense.
	 * Here is the getter to decide if it should be visible.
	 */
	public get showApplyToShiftModelCheckbox() : boolean {
		if (!this.shift) return false;
		if (!this.shiftChangeSelector.addChangeSelectors) return false;
		if (this._showApplyToShiftModelCheckbox !== null) return this._showApplyToShiftModelCheckbox;
		return this.typeOfChange !== PTypeOfChange.DELETE;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public async getAffectedShifts() : Promise<void> {

		const start = +this.pMoment.m(this.transmissionPreviewTimestamp).startOf('month');
		const end = +this.pMoment.m(start).add(1, 'month');

		let queryParams = new HttpParams()
			.set('action', this.typeOfChange)
			.set('start', start.toString())
			.set('end', end.toString());

		if (this.showTransmissionPreview)
			queryParams = queryParams.set('shiftChangeSelector', encodeURIComponent(JSON.stringify(this.shiftChangeSelector.rawData)));

		if (this.shift) {
			const id = this.shift.id.toString();
			const currentShiftIds = JSON.stringify([id]);
			queryParams = queryParams.set('currentShiftIds', currentShiftIds);
		}

		if (this.shifts) {
			const currentShiftIds = JSON.stringify(this.shifts.map(shift => shift.id.toString()));
			queryParams = queryParams.set('currentShiftIds', currentShiftIds);
		}

		await this.affectedShiftsApiService.load({
			searchParams: queryParams,
		});
	}

	private transmissionPreviewTimestamp : number | null = null;

	private validateValues() : void {
		if (!!this.shift && this.members === null) {
			throw new Error('members is required when using ChangeSelectorsModalComponent for a shift');
		}

	}

	public ngOnInit() : void {
		this.initComponent();

		// FIXME: PLANO-35789
		// TODO: Refactor this component so that submit gets obsolete. If you have this, you can remove a bunch of
		//       getAffectedShifts() calls and replace them by the following:
		// this.schedulingApiService?.onChange?.subscribe(() => {
		// 	this.getAffectedShifts();
		// });
	}

	/**
	 * Load and set everything that is necessary for this component
	 */
	public initComponent() : void {
		this.validateValues();

		this.initValues();

		this.componentInitialized = true;
	}

	/**
	 * Set some default values for properties that are not defined yet
	 */
	public initValues() : void {
		if (this.shift) {
			this.transmissionPreviewTimestamp = this.shift.start;
			if (this.alwaysGetsAppliedToPacket) this.setOptionShiftsOfPacket(true);
		} else {
			this.transmissionPreviewTimestamp = this.pMoment.m(this.shifts?.first?.start ?? this.today).startOf('month').valueOf();
		}
	}

	public formGroup = this.pFormsService.group({});

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showCaptureRequest() : boolean {
		return this.applyToSomeOtherShifts || !!this.shifts;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get applyToSomeOtherShifts() : boolean {
		if (this.optionShiftsOfPacket) return true;
		if (this.optionShiftsOfSeries) return true;
		if (this.optionShiftsOfModel) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showApplyToShiftsOfSeriesCheckbox() : boolean {
		assumeNonNull(this.shift);
		if (this.shift.repetition.rawData === undefined) throw new Error('No shift.repetition. Forgot shift.loadDetailed()?');

		return this.shift.repetition.type !== SchedulingApiShiftRepetitionType.NONE;
	}

	/**
	 * Set if changes should be transferred to other shifts or not.
	 * @param applyToSomeOtherShifts Should changes be transferred to other shifts?
	 */
	public async setAddChangeSelectors(applyToSomeOtherShifts : boolean) : Promise<void> {
		if (!applyToSomeOtherShifts) {
			this.shiftChangeSelector.addChangeSelectors = false;
			this.shiftChangeSelector.shiftModelId = null;
			this.shiftChangeSelector.shiftsOfShiftModelId = null;
			this.shiftChangeSelector.shiftsOfShiftModelVersion = null;
			this.shiftChangeSelector.shiftsOfSeriesId = null;
			this.shiftChangeSelector.shiftsOfPacketIndex = null;
			return;
		}

		if (this.shift && this.shift.repetition.rawData === undefined) {
			await this.shift.loadDetailed({
				searchParams: this.schedulingService.queryParams,
			});
		}

		this.shiftChangeSelector.addChangeSelectors = true;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Is the change selector for a shiftModel?
	 */
	public get isChangeSelectorModalForShiftModel() : boolean {
		return !(this.shift ?? this.shifts);
	}

	/**
	 * Is the cancellation step selected?
	 */
	public get isOnCancellationStep() : boolean {
		return !!this.cancellationStep && this.currentStepChange?.step.index === this.cancellationStep.index;
	}

	/**
	 * Conditions for when the continue button of the change selectors modal should be disabled
	 */
	public get continueButtonDisabled() : boolean {
		return this.currentStepChange?.step.state === STEP_STATE.error ||
				!!this.shiftChangeSelector.addChangeSelectors && !this.someOptionIsSelected ||
				(this.isOnCancellationStep && !this.isChangeSelectorModalForShiftModel && this.affectedShiftsApiService.isLoadOperationRunning);
	}

	/**
	 * Check if defaultStartDate would be possible
	 */
	private defaultStartIsValid(defaultStart : DateTime | undefined) : defaultStart is DateTime {
		// If no defaultStartDate is available then it can only be valid.
		if (!defaultStart) return true;

		// If minDate is defined and startDate is before minDate, then it is invalid.
		if (this.minDate && defaultStart < this.minDate) return false;

		if (this.shiftChangeSelector.end) {
			const oneDayAsMilliseconds = 1000 * 60 * 60 * 24;
			return (this.shiftChangeSelector.end - oneDayAsMilliseconds) > defaultStart;
		}

		return true;
	}

	/**
	 * Initialize start date for datepicker
	 * @param input Should the start date be initialized to a date? If false, the start date will be set to null.
	 */
	private initChangeSelectorStart(input : boolean) : void {
		if (!this.shift) return;

		if (input === false) {
			this.shiftChangeSelector.start = null;
			return;
		}

		if (this.defaultStartIsValid(this.defaultStart)) {
			this.shiftChangeSelector.start = this.pMoment.m(this.defaultStart).startOf('D').valueOf();
		} else if (this.shiftChangeSelector.end) {
			const oneDayAsMilliseconds = 1000 * 60 * 60 * 24;
			this.shiftChangeSelector.start = this.shiftChangeSelector.end - oneDayAsMilliseconds;
		} else {
			// If there is no other solution then set today as default, because its always better do have a default then
			// to change all the items in the past 💣
			this.shiftChangeSelector.start = this.today;
		}
	}

	/**
	 * Returns true if changeSelector-flags are set to "apply to shifts of this shiftModel"
	 */
	public get optionToShiftModel() : boolean {
		return this.shiftChangeSelector.shiftModelId !== null;
	}
	public set optionToShiftModel(input : boolean) {
		if (input) {
			this.shiftChangeSelector.shiftModelId = this.shiftModel!.id;
		} else {
			this.shiftChangeSelector.shiftModelId = null;
		}
	}

	/**
	 * Set shiftModelId in changeSelector flags
	 * shiftModelId is required for all other changeSelector flags
	 */
	public toggleOptionToShiftModel() : void {
		this.optionToShiftModel = this.shiftChangeSelector.shiftModelId === null;
		if (this.shift) void this.getAffectedShifts();
	}

	/**
	 * Returns true if changeSelector-flags are set to "apply to shifts of this shiftModel"
	 */
	public get optionShiftsOfModel() : boolean {
		return this.shiftChangeSelector.shiftsOfShiftModelId !== null &&
			this.shiftChangeSelector.shiftsOfShiftModelVersion === null &&
			this.shiftChangeSelector.shiftsOfSeriesId === null &&
			this.shiftChangeSelector.shiftsOfPacketIndex === null;
	}

	public set optionShiftsOfModel(input : boolean) {
		if (input) {
			this.shiftChangeSelector.shiftsOfShiftModelId = this.shiftModel!.id;
			this.shiftChangeSelector.shiftsOfShiftModelVersion = null;
			this.shiftChangeSelector.shiftsOfSeriesId = null;
			this.shiftChangeSelector.shiftsOfPacketIndex = null;
		} else {
			this.setOptionShiftsOfPacket(this.alwaysGetsAppliedToPacket);
		}
	}

	/**
	 * Set necessary flags to apply changes to shifts of this model at save
	 * @param input The new value for optionShiftsOfModel
	 */
	public setOptionShiftsOfModel(input : boolean) : void {
		this.optionShiftsOfModel = input;
		if (this.shift) {
			if (!this.shiftChangeSelector.start) this.initChangeSelectorStart(this.applyToSomeOtherShifts);
			void this.getAffectedShifts();
		}
	}

	/**
	 * Returns true if changeSelector-flags are set to "apply to shifts of this series"
	 */
	public get optionShiftsOfSeries() : boolean {
		const hasBeenSelected = (
			this.shiftChangeSelector.shiftsOfShiftModelId !== null &&
			this.shiftChangeSelector.shiftsOfShiftModelVersion !== null &&
			this.shiftChangeSelector.shiftsOfSeriesId !== null &&
			this.shiftChangeSelector.shiftsOfPacketIndex === null
		);
		return this.optionShiftsOfModel || hasBeenSelected;
	}

	public set optionShiftsOfSeries(input : boolean) {
		if (input) {
			assumeNonNull(this.shift);
			this.shiftChangeSelector.shiftsOfShiftModelId = this.shiftModel!.id;
			this.shiftChangeSelector.shiftsOfShiftModelVersion = this.shift.id.shiftModelVersion;
			this.shiftChangeSelector.shiftsOfSeriesId = this.shift.id.seriesId;
			this.shiftChangeSelector.shiftsOfPacketIndex = null;
		} else {
			this.setOptionShiftsOfPacket(this.alwaysGetsAppliedToPacket);
		}
	}

	/**
	 * Set necessary flags to apply changes to shifts of this series at save
	 * @param input The new value for optionShiftsOfSeries
	 */
	public setOptionShiftsOfSeries(input : boolean) : void {
		this.optionShiftsOfSeries = input;
		if (this.shift) {
			if (!this.shiftChangeSelector.start) this.initChangeSelectorStart(this.applyToSomeOtherShifts);
			void this.getAffectedShifts();
		}
	}

	/**
	 * Returns true if changeSelector-flags are set to "apply to shifts of this packet"
	 */
	public get optionShiftsOfPacket() : boolean | null {
		const hasBeenSelected = (
			this.shiftChangeSelector.shiftsOfShiftModelId !== null &&
			this.shiftChangeSelector.shiftsOfShiftModelVersion !== null &&
			this.shiftChangeSelector.shiftsOfSeriesId !== null &&
			this.shiftChangeSelector.shiftsOfPacketIndex !== null
		);
		return this.optionShiftsOfSeries || hasBeenSelected;
	}

	public set optionShiftsOfPacket(input : boolean | null) {
		if (input) {
			assumeNonNull(this.shift);
			this.shiftChangeSelector.shiftsOfShiftModelId = this.shiftModel!.id;
			this.shiftChangeSelector.shiftsOfShiftModelVersion = this.shift.id.shiftModelVersion;
			this.shiftChangeSelector.shiftsOfSeriesId = this.shift.id.seriesId;
			this.shiftChangeSelector.shiftsOfPacketIndex = this.shift.id.packetIndex;
		} else {
			this.shiftChangeSelector.shiftsOfShiftModelId = null;
			this.shiftChangeSelector.shiftsOfShiftModelVersion = null;
			this.shiftChangeSelector.shiftsOfSeriesId = null;
			this.shiftChangeSelector.shiftsOfPacketIndex = null;
		}
	}

	/**
	 * Set necessary flags to apply changes to shifts of this packet at save
	 */
	public setOptionShiftsOfPacket(input : boolean | null) : void {
		this.optionShiftsOfPacket = input;

		if (this.shift) {
			if (
				this.shiftChangeSelector.attributeInfoStart.isAvailable &&
				!this.shiftChangeSelector.start
			) this.initChangeSelectorStart(this.applyToSomeOtherShifts);
			void this.getAffectedShifts();
		}
	}

	/**
	 * Submit the form
	 * TODO: 	Refactor this component so that submit gets obsolete.
	 * 				See ngOnInit()
	 */
	public submit() : void {
		if (
			this.api.data.attributeInfoAutomaticBookingCancellationSettings.isAvailable &&
			this.api.data.automaticBookingCancellationSettings.attributeInfoAutomaticOnlineRefund.isAvailable &&
			this.api.data.automaticBookingCancellationSettings.automaticOnlineRefund
		) {
			this.toastsService.addToast({
				title: this.localize.transform('Check deine Emails'),
				content: this.localize.transform('Wir haben dir geschrieben, bei welchen Buchungen eine Rückerstattung veranlasst wurde.'),
				theme: this.enums.PThemeEnum.INFO,
				icon: enumsObject.PlanoFaIconPool.EMAIL_NOTIFICATION,
			});
		}
		this.affectedShiftsApiService.unload();

		this.close('Close click');

	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasNgContent() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.ngContent.nativeElement, 'this.ngContent.nativeElement');
		return this.ngContent.nativeElement.children.length > 0;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showDateRangeSelection() : boolean {
		if (!this.shift) return false;

		if (this.shift.packetShifts.length > 0) return true;
		if (this.showApplyToShiftsOfSeriesCheckbox) return true;
		if (this.shiftChangeSelector.shiftsOfShiftModelId !== null) return true;

		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get translatedAddChangeSelectorsRadioValue() : string {
		const thisThing = this.localize.transform(!this.shift ? 'diese Vorlage' : (this.alwaysGetsAppliedToPacket ? 'dieses Schicht-Paket' : 'diese Schicht'));
		return this.localize.transform({ sourceString: 'Nein, nur auf ${thisThing}', params: {
			thisThing: thisThing,
		}});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get title() : string {
		if (this._title) return this._title;
		if (this.typeOfChange === PTypeOfChange.DELETE)
			return this.localize.transform('Das Löschen auf andere Bereiche übertragen?');
		if (this.typeOfChange === PTypeOfChange.CANCEL)
			return this.localize.transform('Die Stornierung auf andere Bereiche übertragen?');
		return this.localize.transform('Änderung auf andere Bereiche übertragen?');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get submitBtnIsDisabled() : boolean {

		if (this.formGroup.invalid) return true;

		if (!this.shift && !this.shifts) return false;

		if (!this.showCaptureRequest) return false;

		// If the user wants to apply this change to any other shifts or shiftModels, the user must confirm with capture.
		if (this.captureInput?.toLowerCase() !== this.captureRequest.toLowerCase()) return true;
		else if (this.shifts) {
			return false;
		}

		if (this.shift) {
			if (this.shiftChangeSelector.attributeInfoStart.isAvailable && !!this.shiftChangeSelector.start) return false;
		} else {
			if (this.shiftChangeSelector.attributeInfoEnd.isAvailable && !!this.shiftModel!.changeSelector.start) return false;
		}
		if (this.shiftChangeSelector.shiftModelId !== null) return false;
		if (this.optionShiftsOfPacket) return false;
		if (this.optionShiftsOfSeries) return false;
		if (this.optionShiftsOfModel) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get changeSelectorEndPlaceholder() : string | null {
		return this.shiftChangeSelector.end === null ? this.localize.transform('Unbegrenzt') : null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get changeSelectorStartPlaceholder() : string | null {
		if (!this.shift) return null;
		return this.shiftChangeSelector.start === null ? this.localize.transform('Unbegrenzt') : null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public transmissionPreviewTimestampChanged(input : number) : void {
		this.transmissionPreviewTimestamp = input;
		void this.getAffectedShifts();
	}

	/**
	 * If there are packetShifts, the course related value changes will always be applied to the
	 * whole packet. The checkbox should be visible, checked and disabled.
	 * More details: PLANO-5297
	 */
	private get alwaysGetsAppliedToPacket() : boolean | null {
		if (this.shifts) return false;
		assumeNonNull(this.shift);
		if (this.shift.packetShifts.length === 0) return false;
		if (this.typeOfChange === PTypeOfChange.CANCEL) return true;
		if (this.modalForCourseRelatedValues) return true;
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showApplyToShiftPacketCheckbox() : boolean {
		if (this.alwaysGetsAppliedToPacket) return true;
		return !!this.shift && this.shift.packetShifts.length > 0;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get applyToShiftPacketCheckboxIsDisabled() : boolean {
		if (this.alwaysGetsAppliedToPacket) return true;

		if (
			this.shiftChangeSelector.shiftsOfPacketIndex === null &&
			this.shiftChangeSelector.shiftsOfSeriesId !== null &&
			this.shiftChangeSelector.shiftsOfShiftModelId !== null
		) {
			return true;
		}
		if (
			this.shiftChangeSelector.shiftsOfPacketIndex === null &&
			this.shiftChangeSelector.shiftsOfSeriesId === null &&
			this.shiftChangeSelector.shiftsOfShiftModelId !== null
		) {
			return true;
		}
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showTransmissionPreview() : boolean {
		if (!this.shift) return false;
		if (!this.shiftChangeSelector.addChangeSelectors) return false;
		if (this.shiftChangeSelector.attributeInfoEnd.isAvailable && this.shiftChangeSelector.end === undefined) return false;
		return true;
	}

	/**
	 * Sort the shifts by date
	 */
	public sortedShiftsByDate(shifts : SchedulingApiShifts) : readonly SchedulingApiShift[] {
		return shifts.sortedBy(shift => shift.start).iterable();
	}

	private modalRef : ModalRef<ModalContentComponentCloseReason> | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public initModalContentComponent(
		modalRef : ChangeSelectorsModalComponent['modalRef'],
		title : ChangeSelectorsModalComponent['title'],
		members : ChangeSelectorsModalComponent['members'],
		shiftModel : ChangeSelectorsModalComponent['shiftModel'],
		shift : ChangeSelectorsModalComponent['shift'],
		shiftChangeSelector : ChangeSelectorsModalComponent['shiftChangeSelector'],
	) : void {
		this.modalRef = modalRef;
		this._title = title;
		this.members = members;
		this.shiftModel = shiftModel;
		this.shift = shift;
		this.shiftChangeSelector = shiftChangeSelector;
		this.initComponent();
	}

	/**
	 * Dismiss the current modal
	 */
	public dismissModal(event : Event) : void {
		this.dismiss(event);
		if (this.modalRef) this.modalRef.dismiss();
		this.affectedShiftsApiService.unload();
	}

	/**
	 * Close the current modal
	 */
	public closeModal(reason : ModalContentComponentCloseReason) : void {
		this.close(reason);
		if (this.modalRef) this.modalRef.close(reason);
	}

	public config : NgWizardConfig = {
		selected: 0,
		toolbarSettings: {
			// toolbarExtraButtons: [
			// 	{ text: 'Finish', class: 'btn btn-info', event: () => { alert('Finished!!!'); } },
			// ],
			showNextButton: false,
			showPreviousButton: false,
		},
		anchorSettings: {
			anchorClickable: true,
		},
	};

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public showPreviousStep(_event ?: Event) : void {
		this.ngWizardService.previous();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public showNextStep(_event ?: Event) : void {
		this.ngWizardService.next();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public resetWizard(_event ?: Event) : void {
		this.ngWizardService.reset();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setTheme(theme : THEME) : void {
		this.ngWizardService.theme(theme);
	}

	public STEP_POSITION = STEP_POSITION;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public stepChanged(args : StepChangedArgs) : void {

		this.currentStepChange = args;

		if (this.isOnCancellationStep && !this.isChangeSelectorModalForShiftModel)
			void this.getAffectedShifts();

		this.zone.runOutsideAngular(() => {
			requestAnimationFrame(() => {

				// TODO: There must be a safer way to do this.
				const stepElement = (args.step as NgWizardStepComponent).stepContent.viewContainerRef.element.nativeElement;
				const scrollTarget = stepElement.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;

				if (!scrollTarget) return;
				const el = scrollTarget;
				if (el) el.scrollIntoView();

				this.pAutoFocusService.refreshFocus();
			});
		});

	}

	public isValidTypeBoolean = true;
	public currentStepChange : StepChangedArgs | undefined;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isValidFunctionReturnsBoolean(_args : StepValidationArgs) : boolean {
		// cSpell:ignore DIRECTIN
		if (_args.direction === STEP_DIRECTIN.backward) return true;
		return !this.formGroup.invalid;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isValidFunctionReturnsObservable(_args : StepValidationArgs) : Observable<boolean> {
		return of(true);
	}

	/**
	 * Show we show the tip to delete shifts?
	 */
	public get showManyShiftsAlert() : boolean {
		return !!this.shifts && this.shiftsGroupedByShiftModel.some(shiftsOfShiftModel => shiftsOfShiftModel.length >= 4);
	}

	/**
	 * Number of current steps in the wizard
	 */
	public get numberOfSteps() : number {
		// Zusammenfassung step is always shown
		let numberOfSteps = 1;
		if (this.showCancellationSettings)
			numberOfSteps++;
		if (this.showTransmissionSettings)
			numberOfSteps++;
		return numberOfSteps;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showCancellationSettings() : boolean {
		if (this.typeOfChange === PTypeOfChange.EDIT) return false;
		if (this.shift?.isCourse) return true;
		if (this.shiftModel?.isCourse) return true;
		if (this.shifts?.some(shift => shift.isCourse)) return true;
		return this.api.data.attributeInfoAutomaticBookingCancellationSettings.isAvailable === true;
	}

	/**
	 * Don't show transmission settings if multiple shifts were deleted, or if
	 * shift was deleted using the tooltip
	 */
	public get showTransmissionSettings() : boolean {
		if (this.shifts) return false;
		if (this.shiftSelectedFromTooltip) return false;
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get captureRequest() : string {
		switch (this.typeOfChange) {
			case PTypeOfChange.EDIT:
				return this.localize.transform('Bearbeiten');
			case PTypeOfChange.CANCEL:
				return this.localize.transform('Stornieren');
			case PTypeOfChange.DELETE:
				return this.localize.transform('Löschen');
			default:
				throw new Error('Unexpected value for typeOfChange');
		}
	}

	public captureInput : string | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get typeRelatedIcon() : PFaIcon {
		switch (this.typeOfChange) {
			case PTypeOfChange.EDIT:
				return enumsObject.PlanoFaIconPool.EDIT;
			case PTypeOfChange.CANCEL:
				return enumsObject.PlanoFaIconPool.CANCELED;
			case PTypeOfChange.DELETE:
				return enumsObject.PlanoFaIconPool.DELETE;
			default:
				throw new Error('Unexpected value for typeOfChange');
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get typeRelatedIconTheme() : ExtractFromUnion<'success' | 'danger', PThemeEnum> {
		switch (this.typeOfChange) {
			case PTypeOfChange.EDIT:
				return enumsObject.PThemeEnum.SUCCESS;
			case PTypeOfChange.CANCEL:
				return enumsObject.PThemeEnum.DANGER;
			case PTypeOfChange.DELETE:
				return enumsObject.PThemeEnum.DANGER;
			default:
				throw new Error('Unexpected value for typeOfChange');
		}
	}

	private _shiftsGroupedByShiftModel : readonly SchedulingApiShifts [] | null = null;

	/**
	 * Get the shifts grouped by shiftModel
	 */
	public get shiftsGroupedByShiftModel() : readonly SchedulingApiShifts [] {
		if (!!this.shifts && !this._shiftsGroupedByShiftModel) {
			this._shiftsGroupedByShiftModel = this.shifts.sortedBy(shift => shift.shiftModelId.toString()).groupedBy((shiftA, shiftB) => {
				if (shiftA.shiftModelId.equals(shiftB.shiftModelId)) return 0; return 1;
			}, false).sort([
				(shiftsA, shiftsB)=> shiftsB.length - shiftsA.length,
				(shiftsA, shiftsB)=> shiftsA.get(0)!.model.name.localeCompare(shiftsB.get(0)!.model.name)]).iterable();
		}
		return this._shiftsGroupedByShiftModel ?? [];
	}

	public ngAfterContentChecked() : void {
		if (!this.currentStepChange) return;
		if (this.formGroup.invalid) {
			this.currentStepChange.step.state = STEP_STATE.error;
		} else {
			this.currentStepChange.step.state = STEP_STATE.normal;
		}
	}

	/** Did the user choose any of the offered options? */
	public get someOptionIsSelected() : boolean {
		if (!this.shift) return !!this.shiftModel!.changeSelector.start;
		return !!this.optionToShiftModel || !!this.optionShiftsOfModel || !!this.optionShiftsOfPacket || !!this.optionShiftsOfSeries;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showNotificationCheckbox() : boolean {
		if (this.showSendMailCheckbox) return true;
		if (!this.shift && !this.shifts) return false;
		if (this.typeOfChange === PTypeOfChange.DELETE) return true;
		if (this.typeOfChange === PTypeOfChange.CANCEL) return true;
		return false;
	}

	public shakeSidewaysTrigger = false;

	/** Make it easier for people to apply changes to other shifts by hitting enter */
	public onCaptureInputKeyUp(event : KeyboardEvent) : void {
		if (event.key !== 'Enter') return;
		if (this.submitBtnIsDisabled) { this.shakeSidewaysTrigger = !this.shakeSidewaysTrigger; return; }
		this.submit();
	}
}
