import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { BootstrapRounded } from '@plano/client/shared/bootstrap-styles.enum';
import { SchedulingApiService, SchedulingApiShift, SchedulingApiShiftExchange, SchedulingApiShiftModel, SchedulingApiShiftModels, SchedulingApiTodaysShiftDescription, TimeStampApiShift, TimeStampApiShiftModel } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';

type PossibleItemTypes = (
	SchedulingApiShiftModel |
	TimeStampApiShiftModel |
	SchedulingApiShift |
	TimeStampApiShift |
	SchedulingApiTodaysShiftDescription |
	SchedulingApiShiftExchange
);

@Component({
	selector: 'p-color-marker',
	templateUrl: './color-marker.component.html',
	styleUrls: ['./color-marker.component.scss'],

	// TODO: set this to OnPush, but beware… something was broken when we had this on OnPush. We changed it in https://bitbucket.org/drplanoteam/drplano/pull-requests/1080, but we dont know the issue anymore.
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ColorMarkerComponent implements PComponentInterface {
	@Input() public item ?: PossibleItemTypes | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public items ?: SchedulingApiShiftModels;

	/**
	 * Set hexColor if you don’t want to provide item or items, or want to overwrite the background color calculations
	 * based on item or items.
	 */
	@Input('hexColor') private _hexColor : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('title') private _title ?: string;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('isPacket') private _isPacket : boolean | null = null;

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public rounded : BootstrapRounded | 'top-left' | null = null;

	constructor(
		private api : SchedulingApiService,
		private console : LogService,
		private el : ElementRef<HTMLElement>,
	) {
		if (Config.DEBUG && this.item instanceof SchedulingApiShiftModel) {
			this.console.debug('IMPROVE: remove ShiftModel from color-marker to make it dumb');
		}
	}

	public BootstrapRounded = BootstrapRounded;

	private shiftModel(item : PossibleItemTypes | undefined) : SchedulingApiShiftModel | null | undefined {
		if (!item) return undefined;

		if (Config.DEBUG && !this.items) {
			if (item instanceof SchedulingApiShiftModel || this.item instanceof TimeStampApiShiftModel) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [shiftModel]="shiftModel" to make this a dump component.');
			}
			if (item instanceof SchedulingApiShift || item instanceof TimeStampApiShift) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [shiftModel]="shift.model" to make this a dump component.');
			}
			if (item instanceof SchedulingApiShiftExchange) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [shiftModel]="shiftExchange.model" to make this a dump component.');
			}
			if (item instanceof SchedulingApiTodaysShiftDescription) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [shiftModel]="todaysShiftDescription.model" to make this a dump component.');
			}
		}

		if (item instanceof SchedulingApiShiftModel || this.item instanceof TimeStampApiShiftModel) {
			return item as SchedulingApiShiftModel;
		}
		if (item instanceof SchedulingApiShift || item instanceof TimeStampApiShift) {
			return item.model as SchedulingApiShiftModel;
		}
		if (item instanceof SchedulingApiShiftExchange) {
			return item.shiftModel;
		}
		if (item instanceof SchedulingApiTodaysShiftDescription) {
			if (!this.api.isLoaded()) return undefined;
			return this.api.data.shiftModels.get(item.id.shiftModelId);
		}

		this.console.warn('Could not get ShiftModel');
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hexColor(item : PossibleItemTypes | null) : string | null {
		if (this.isLoading) return '#eee';

		// The color for when there is no value
		const NO_VALUE_COLOR = '#eeeeee00';

		if (this._hexColor !== null) {
			const HEX_COLOR_IS_INVALID = !this.hexColorIsValid(this._hexColor);
			if (HEX_COLOR_IS_INVALID) {
				this.console.error(`defined hexadecimal color »${this._hexColor}« is invalid`);
				return null;
			}
			return this._hexColor;
		}

		if (item === null) {
			const itemsColor = this.items?.get(0)?.color;
			if (!itemsColor) return NO_VALUE_COLOR;
			return itemsColor;
		}

		// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition -- I dont now why this happens
		if (!item) return NO_VALUE_COLOR;

		if (Config.DEBUG && !this.items) {
			if (item instanceof SchedulingApiShiftModel || this.item instanceof TimeStampApiShiftModel) {
				this.console.debug(`IMPROVE: Replace [item]="shiftModel" by [hexColor]="shiftModel.color" [isPacket]="shiftModel.isPacket" to make this a dump component. (title is »${this._title}«)`);
			}
			if (item instanceof SchedulingApiShift || item instanceof TimeStampApiShift) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Replace [item]="shift" by [hexColor]="shift.color" to make this a dump component.');
			}
			if (item instanceof SchedulingApiShiftExchange) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Replace [item]="shiftExchange" by [hexColor]="shiftExchange.color" to make this a dump component.');
			}
			if (item instanceof SchedulingApiTodaysShiftDescription) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Replace [item]="todaysShiftDescription" by [hexColor]="todaysShiftDescription.color" to make this a dump component.');
			}
		}

		const shiftModel = this.shiftModel(item);
		if (!shiftModel) return null;
		return shiftModel.color;
	}

	private isPacket(item : PossibleItemTypes | null) : boolean | null {
		if (this.isLoading) return null;

		if (this._isPacket !== null) return this._isPacket;

		if (this.items) {
			const firstShiftModel = this.items.get(0)!;
			if (this.items.length === 1) return firstShiftModel.isPacket;
			const firstShiftModelId = firstShiftModel.id;
			const allShiftModelsAreTheSame = this.items.every(shiftModel => shiftModel.id.equals(firstShiftModelId));
			if (allShiftModelsAreTheSame) {
				return firstShiftModel.isPacket;
			}
			return false;
		}

		if (!item) return null;

		if (item instanceof SchedulingApiShiftModel) {
			if (!item.repetition.rawData) return false;
			return item.isPacket;
		}

		if (item instanceof SchedulingApiTodaysShiftDescription) {
			this.console.debug('IMPROVE: remove TodaysShiftDescription from color-marker to make it dumb');
			const shift = this.api.data.shifts.get(item.id);
			if (shift === null) throw new Error('Could not find shift');
			return !!shift.packetShifts.length;
		}

		return null;
	}

	/** Check if input matches a hexadecimal color like transparent, #555, #303030 or #ffffff11 */
	private hexColorIsValid(input : string) : boolean {
		if (input === 'transparent') return true;

		return !!input.match(/^#(?:[\dA-Fa-f]{6}|[\dA-Fa-f]{3})(?:[\dA-Fa-f]{2})?$/);
	}

	/**
	 * Determine the color for this item
	 */
	public backgroundColor(item : PossibleItemTypes) : string {
		let result : string;
		const hexColor = this.hexColor(item);
		assumeDefinedToGetStrictNullChecksRunning(hexColor, 'hexColor');
		if (this.hexColorIsValid(hexColor)) {
			result = hexColor;
		} else {
			result = `#${hexColor}`;
		}
		return result;
	}

	/**
	 * Title for this item
	 */
	public title(item : PossibleItemTypes | null = null) : string {
		if (this.isLoading) return '';
		if (this._title !== undefined) return this._title;
		if (item === null) return '';
		if (item instanceof SchedulingApiTodaysShiftDescription) {
			this.console.debug('IMPROVE: remove title(TodaysShiftDescription) from color-marker to make it dumb');
			const shiftModel = this.api.data.shiftModels.get(item.id.shiftModelId);
			if (!shiftModel) throw new Error('shiftModel is not defined');
			return shiftModel.name;
		}
		if (!this.items) {
			if (item instanceof SchedulingApiShiftModel) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [title]="shiftModel.name" to make this a dump component.');
			}
			if (item instanceof SchedulingApiShift) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [title]="shift.name" to make this a dump component.');
			}
			if (item instanceof SchedulingApiShiftExchange) {
				// eslint-disable-next-line literal-blacklist/literal-blacklist
				this.console.debug('IMPROVE: Add [title]="shiftExchange.shiftModel.name" to make this a dump component.');
				return item.shiftModel!.name;
			}
			return item.name;
		}
		if (!(item instanceof SchedulingApiShiftExchange)) {
			return item.name;
		}
		throw new Error('This broke with the ng10 update.');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hasDots(singleItem : PossibleItemTypes | null) : boolean {
		return !!this.isPacket(singleItem);
	}
}
