/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "testing/ai_switch_showcase".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	ALL_TYPES = 1;
	IS_NULL = 2;
	VALIDATIONS = 3;
	ALL_TYPES_BOOLEAN = 1;
	ALL_TYPES_NUMBER = 2;
	ALL_TYPES_INTEGER = 3;
	ALL_TYPES_CLIENT_CURRENCY = 4;
	ALL_TYPES_STRING = 5;
	ALL_TYPES_TEL = 6;
	ALL_TYPES_EMAIL = 7;
	ALL_TYPES_PASSWORD = 8;
	ALL_TYPES_POSTAL_CODE = 9;
	ALL_TYPES_SEARCH = 10;
	ALL_TYPES_URL = 11;
	ALL_TYPES_IBAN = 12;
	ALL_TYPES_BIC = 13;
	ALL_TYPES_DATE_TIME = 14;
	ALL_TYPES_DATE_WITHOUT_TIME = 15;
	ALL_TYPES_DATE_EXCLUSIVE_END = 16;
	ALL_TYPES_MINUTES = 17;
	ALL_TYPES_HOURS = 18;
	ALL_TYPES_DAYS = 19;
	ALL_TYPES_MONTHS = 20;
	ALL_TYPES_YEARS = 21;
	ALL_TYPES_DURATION = 22;
	ALL_TYPES_LOCAL_TIME = 23;
	ALL_TYPES_ENUM = 24;
	ALL_TYPES_IMAGE = 25;
	ALL_TYPES_PDF = 26;
	ALL_TYPES_COLOR = 27;
	ALL_TYPES_PERCENT = 28;
	IS_NULL_BOOLEAN = 1;
	IS_NULL_NUMBER = 2;
	IS_NULL_INTEGER = 3;
	IS_NULL_CLIENT_CURRENCY = 4;
	IS_NULL_STRING = 5;
	IS_NULL_TEL = 6;
	IS_NULL_EMAIL = 7;
	IS_NULL_PASSWORD = 8;
	IS_NULL_POSTAL_CODE = 9;
	IS_NULL_SEARCH = 10;
	IS_NULL_URL = 11;
	IS_NULL_IBAN = 12;
	IS_NULL_BIC = 13;
	IS_NULL_DATE_TIME = 14;
	IS_NULL_DATE_WITHOUT_TIME = 15;
	IS_NULL_DATE_EXCLUSIVE_END = 16;
	IS_NULL_MINUTES = 17;
	IS_NULL_HOURS = 18;
	IS_NULL_DAYS = 19;
	IS_NULL_MONTHS = 20;
	IS_NULL_YEARS = 21;
	IS_NULL_DURATION = 22;
	IS_NULL_LOCAL_TIME = 23;
	IS_NULL_ENUM = 24;
	IS_NULL_IMAGE = 25;
	IS_NULL_PDF = 26;
	IS_NULL_COLOR = 27;
	IS_NULL_PERCENT = 28;
	VALIDATIONS_TEL = 1;
	VALIDATIONS_EMAIL = 2;
	VALIDATIONS_PASSWORD = 3;
	VALIDATIONS_POSTAL_CODE = 4;
	VALIDATIONS_URL = 5;
	VALIDATIONS_IBAN = 6;
	VALIDATIONS_BIC = 7;
	VALIDATIONS_CLIENT_CURRENCY = 8;
	VALIDATIONS_INTEGER = 9;
	VALIDATIONS_REQUIRED = 10;
	VALIDATIONS_MIN = 11;
	VALIDATIONS_MAX = 12;
	VALIDATIONS_MAX_DECIMAL_PLACES = 13;
	VALIDATIONS_MAX_LENGTH = 14;
	VALIDATIONS_TEL_FAILS = 1;
	VALIDATIONS_TEL_FAILS_BAD_VALUE = 1;
	VALIDATIONS_TEL_FAILS_TOO_SHORT = 2;
	VALIDATIONS_TEL_FAILS_WHITESPACE = 3;
	VALIDATIONS_EMAIL_FAILS = 1;
	VALIDATIONS_EMAIL_FAILS_NO_AT = 1;
	VALIDATIONS_EMAIL_FAILS_WRONG_FORMAT = 2;
	VALIDATIONS_PASSWORD_FAILS = 1;
	VALIDATIONS_PASSWORD_FAILS_TOO_SHORT = 1;
	VALIDATIONS_PASSWORD_FAILS_NEEDS_DIGITS = 2;
	VALIDATIONS_POSTAL_CODE_FAILS = 1;
	VALIDATIONS_POSTAL_CODE_FAILS_TOO_LONG = 1;
	VALIDATIONS_POSTAL_CODE_FAILS_TOO_SHORT = 2;
	VALIDATIONS_URL_VALID = 1;
	VALIDATIONS_URL_FAILS = 2;
	VALIDATIONS_URL_VALID_WITH_WWW = 1;
	VALIDATIONS_URL_VALID_WITHOUT_WWW = 2;
	VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_INVALID_DOMAIN_FORMAT = 1;
	VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_VALID_DOMAIN_FORMAT = 2;
	VALIDATIONS_URL_FAILS_URL_INCOMPLETE = 3;
	VALIDATIONS_URL_FAILS_WRONG_FORMAT = 4;
	VALIDATIONS_URL_FAILS_STARTING_WWW = 5;
	VALIDATIONS_URL_FAILS_WHITESPACE = 6;
	VALIDATIONS_IBAN_FAILS = 1;
	VALIDATIONS_IBAN_FAILS_BAD_VALUE = 1;
	VALIDATIONS_BIC_FAILS = 1;
	VALIDATIONS_BIC_FAILS_BAD_VALUE = 1;
	VALIDATIONS_CLIENT_CURRENCY_FAILS = 1;
	VALIDATIONS_CLIENT_CURRENCY_FAILS_TOO_MANY_DIGITS = 1;
	VALIDATIONS_INTEGER_FAILS = 1;
	VALIDATIONS_INTEGER_FAILS_HAS_DECIMAL_PLACES = 1;
	VALIDATIONS_REQUIRED_VALID = 1;
	VALIDATIONS_REQUIRED_FAILS = 2;
	VALIDATIONS_REQUIRED_VALID_NUMBER = 1;
	VALIDATIONS_REQUIRED_VALID_INTEGER = 2;
	VALIDATIONS_REQUIRED_VALID_CLIENT_CURRENCY = 3;
	VALIDATIONS_REQUIRED_VALID_STRING = 4;
	VALIDATIONS_REQUIRED_VALID_TEL = 5;
	VALIDATIONS_REQUIRED_VALID_EMAIL = 6;
	VALIDATIONS_REQUIRED_VALID_PASSWORD = 7;
	VALIDATIONS_REQUIRED_VALID_POSTAL_CODE = 8;
	VALIDATIONS_REQUIRED_VALID_SEARCH = 9;
	VALIDATIONS_REQUIRED_VALID_URL = 10;
	VALIDATIONS_REQUIRED_VALID_IBAN = 11;
	VALIDATIONS_REQUIRED_VALID_BIC = 12;
	VALIDATIONS_REQUIRED_VALID_DATE_TIME = 13;
	VALIDATIONS_REQUIRED_VALID_DATE_WITHOUT_TIME = 14;
	VALIDATIONS_REQUIRED_VALID_DATE_EXCLUSIVE_END = 15;
	VALIDATIONS_REQUIRED_VALID_MINUTES = 16;
	VALIDATIONS_REQUIRED_VALID_HOURS = 17;
	VALIDATIONS_REQUIRED_VALID_DAYS = 18;
	VALIDATIONS_REQUIRED_VALID_MONTHS = 19;
	VALIDATIONS_REQUIRED_VALID_YEARS = 20;
	VALIDATIONS_REQUIRED_VALID_DURATION = 21;
	VALIDATIONS_REQUIRED_VALID_LOCAL_TIME = 22;
	VALIDATIONS_REQUIRED_VALID_ENUM = 23;
	VALIDATIONS_REQUIRED_VALID_COLOR = 24;
	VALIDATIONS_REQUIRED_VALID_PERCENT = 25;
	VALIDATIONS_REQUIRED_FAILS_NUMBER = 1;
	VALIDATIONS_REQUIRED_FAILS_INTEGER = 2;
	VALIDATIONS_REQUIRED_FAILS_CLIENT_CURRENCY = 3;
	VALIDATIONS_REQUIRED_FAILS_STRING = 4;
	VALIDATIONS_REQUIRED_FAILS_TEL = 5;
	VALIDATIONS_REQUIRED_FAILS_EMAIL = 6;
	VALIDATIONS_REQUIRED_FAILS_PASSWORD = 7;
	VALIDATIONS_REQUIRED_FAILS_POSTAL_CODE = 8;
	VALIDATIONS_REQUIRED_FAILS_SEARCH = 9;
	VALIDATIONS_REQUIRED_FAILS_URL = 10;
	VALIDATIONS_REQUIRED_FAILS_IBAN = 11;
	VALIDATIONS_REQUIRED_FAILS_BIC = 12;
	VALIDATIONS_REQUIRED_FAILS_DATE_TIME = 13;
	VALIDATIONS_REQUIRED_FAILS_DATE_WITHOUT_TIME = 14;
	VALIDATIONS_REQUIRED_FAILS_DATE_EXCLUSIVE_END = 15;
	VALIDATIONS_REQUIRED_FAILS_MINUTES = 16;
	VALIDATIONS_REQUIRED_FAILS_HOURS = 17;
	VALIDATIONS_REQUIRED_FAILS_DAYS = 18;
	VALIDATIONS_REQUIRED_FAILS_MONTHS = 19;
	VALIDATIONS_REQUIRED_FAILS_YEARS = 20;
	VALIDATIONS_REQUIRED_FAILS_DURATION = 21;
	VALIDATIONS_REQUIRED_FAILS_LOCAL_TIME = 22;
	VALIDATIONS_REQUIRED_FAILS_ENUM = 23;
	VALIDATIONS_REQUIRED_FAILS_IMAGE = 24;
	VALIDATIONS_REQUIRED_FAILS_PDF = 25;
	VALIDATIONS_REQUIRED_FAILS_PERCENT = 26;
	VALIDATIONS_MIN_VALID = 1;
	VALIDATIONS_MIN_FAILS = 2;
	VALIDATIONS_MIN_VALID_COMPARE_CONST = 1;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_IS_EQUAL = 1;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL = 2;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL = 3;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_IS_EQUAL = 4;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL = 5;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL = 6;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL = 7;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL = 8;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL = 9;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL = 10;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL = 11;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL = 12;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_IS_EQUAL = 13;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_THIS_IS_NULL = 14;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL = 15;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL = 16;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL = 17;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL = 18;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_IS_EQUAL = 19;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL = 20;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL = 21;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_IS_EQUAL = 22;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL = 23;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL = 24;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_IS_EQUAL = 25;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL = 26;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL = 27;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_IS_EQUAL = 28;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL = 29;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL = 30;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_IS_EQUAL = 31;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL = 32;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL = 33;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_IS_EQUAL = 34;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL = 35;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL = 36;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL = 37;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL = 38;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL = 39;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_IS_EQUAL = 40;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL = 41;
	VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL = 42;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST = 1;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE = 2;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED = 1;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED = 2;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED = 3;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED = 4;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED = 5;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED = 6;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED = 7;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED = 8;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED = 9;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED = 10;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED = 11;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED = 12;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED = 13;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED = 14;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED = 15;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED = 16;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED = 17;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED = 18;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED = 19;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED = 20;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED = 21;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED = 22;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED = 23;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED = 24;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED = 25;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED = 26;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED = 27;
	VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED = 28;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_B = 1;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_A = 2;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_B = 3;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_A = 4;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B = 5;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A = 6;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_B = 7;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_A = 8;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B = 9;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A = 10;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B = 11;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A = 12;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_B = 13;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_A = 14;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_B = 15;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_A = 16;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_B = 17;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_A = 18;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_B = 19;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_A = 20;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_B = 21;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_A = 22;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_B = 23;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_A = 24;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_B = 25;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_A = 26;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_B = 27;
	VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_A = 28;
	VALIDATIONS_MAX_VALID = 1;
	VALIDATIONS_MAX_FAILS = 2;
	VALIDATIONS_MAX_VALID_COMPARE_CONST = 1;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_IS_EQUAL = 1;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL = 2;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL = 3;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_IS_EQUAL = 4;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL = 5;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL = 6;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL = 7;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL = 8;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL = 9;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL = 10;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL = 11;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL = 12;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_IS_EQUAL = 13;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_THIS_IS_NULL = 14;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL = 15;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL = 16;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL = 17;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL = 18;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_IS_EQUAL = 19;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL = 20;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL = 21;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_IS_EQUAL = 22;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL = 23;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL = 24;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_IS_EQUAL = 25;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL = 26;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL = 27;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_IS_EQUAL = 28;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL = 29;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL = 30;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_IS_EQUAL = 31;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL = 32;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL = 33;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_IS_EQUAL = 34;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL = 35;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL = 36;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL = 37;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL = 38;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL = 39;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_IS_EQUAL = 40;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL = 41;
	VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL = 42;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST = 1;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE = 2;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED = 1;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED = 2;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED = 3;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED = 4;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED = 5;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED = 6;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED = 7;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED = 8;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED = 9;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED = 10;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED = 11;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED = 12;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED = 13;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED = 14;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED = 15;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED = 16;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED = 17;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED = 18;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED = 19;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED = 20;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED = 21;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED = 22;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED = 23;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED = 24;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED = 25;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED = 26;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED = 27;
	VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED = 28;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_B = 1;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_A = 2;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_B = 3;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_A = 4;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B = 5;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A = 6;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_B = 7;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_A = 8;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B = 9;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A = 10;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B = 11;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A = 12;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_B = 13;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_A = 14;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_B = 15;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_A = 16;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_B = 17;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_A = 18;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_B = 19;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_A = 20;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_B = 21;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_A = 22;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_B = 23;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_A = 24;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_B = 25;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_A = 26;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_B = 27;
	VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_A = 28;
	VALIDATIONS_MAX_DECIMAL_PLACES_FAILS = 1;
	VALIDATIONS_MAX_DECIMAL_PLACES_FAILS_TOO_MANY_DECIMAL_PLACES = 1;
	VALIDATIONS_MAX_LENGTH_FAILS = 1;
	VALIDATIONS_MAX_LENGTH_FAILS_TOO_LONG = 1;
}


@Injectable({
  providedIn: 'root',
})
export class AiSwitchShowcaseApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'testing/ai_switch_showcase');
	}

	protected version() : string {
		return 'fc469c8e224d1796f069ac0342ca890d,5dedfb4409e1e997018ff9ce963b7df1';
	}

	private dataWrapper = new AiSwitchShowcaseApiRoot(this, );

	get data() : AiSwitchShowcaseApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : AiSwitchShowcaseApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new AiSwitchShowcaseApiRoot(this, );
	}
}

		 
export class AiSwitchShowcaseApiRoot extends ApiObjectWrapper<AiSwitchShowcaseApiRoot>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, AiSwitchShowcaseApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiRoot, AiSwitchShowcaseApiRoot> = new ApiAttributeInfo<AiSwitchShowcaseApiRoot, AiSwitchShowcaseApiRoot>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiRoot,
			name: '',
			nodeName: 'ROOT',
			defaultValue: function(this : AiSwitchShowcaseApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});

	private allTypesWrapper : AiSwitchShowcaseApiAllTypes = new AiSwitchShowcaseApiAllTypes(this.api,
		this as unknown as AiSwitchShowcaseApiRoot, );
	public attributeInfoAllTypes = this.allTypesWrapper.attributeInfoThis;

	/**
     *  A list of all available types.
     */
	get allTypes() : AiSwitchShowcaseApiAllTypes {
		this.getterDebugValidations(this.attributeInfoAllTypes, false);
		return this.allTypesWrapper;
	}

	set allTypesTestSetter(v : AiSwitchShowcaseApiAllTypes) {
        this.setterImpl(1, v.rawData, 'allTypes', true, null, () => {this.allTypesWrapper = v;});
	}

	private isNullWrapper : AiSwitchShowcaseApiIsNull = new AiSwitchShowcaseApiIsNull(this.api,
		this as unknown as AiSwitchShowcaseApiRoot, );
	public attributeInfoIsNull = this.isNullWrapper.attributeInfoThis;

	/**
     *  A list of all available types having the appropriate "null" value.
     */
	get isNull() : AiSwitchShowcaseApiIsNull {
		this.getterDebugValidations(this.attributeInfoIsNull, false);
		return this.isNullWrapper;
	}

	set isNullTestSetter(v : AiSwitchShowcaseApiIsNull) {
        this.setterImpl(2, v.rawData, 'isNull', true, null, () => {this.isNullWrapper = v;});
	}

	private validationsWrapper : AiSwitchShowcaseApiValidations = new AiSwitchShowcaseApiValidations(this.api,
		this as unknown as AiSwitchShowcaseApiRoot, );
	public attributeInfoValidations = this.validationsWrapper.attributeInfoThis;

	/**
     *  attributes concerning validation.
     */
	get validations() : AiSwitchShowcaseApiValidations {
		this.getterDebugValidations(this.attributeInfoValidations, false);
		return this.validationsWrapper;
	}

	set validationsTestSetter(v : AiSwitchShowcaseApiValidations) {
        this.setterImpl(3, v.rawData, 'validations', true, null, () => {this.validationsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.allTypesWrapper._fixIds(_idReplacements);
		this.isNullWrapper._fixIds(_idReplacements);
		this.validationsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.allTypesWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.isNullWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.validationsWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiAllTypes extends ApiObjectWrapper<AiSwitchShowcaseApiAllTypes>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiAllTypes> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiAllTypes as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiAllTypes> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiAllTypes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'allTypes',
			nodeName: 'ALL_TYPES',
			defaultValue: function(this : AiSwitchShowcaseApiAllTypes, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoBoolean : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, boolean> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, boolean>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'boolean',
			nodeName: 'ALL_TYPES_BOOLEAN',
			primitiveType: PApiPrimitiveTypes.boolean,
			defaultValue: function(this : AiSwitchShowcaseApiAllTypes, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, number> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'number',
			nodeName: 'ALL_TYPES_NUMBER',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiAllTypes) {
				return [
					() => {
return this.api!.validators.maxDecimalPlacesCount(2, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiAllTypes) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'integer',
			nodeName: 'ALL_TYPES_INTEGER',
			primitiveType: PApiPrimitiveTypes.Integer,
			rawDataIndex: 3,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'clientCurrency',
			nodeName: 'ALL_TYPES_CLIENT_CURRENCY',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			rawDataIndex: 4,
		});
	attributeInfoString : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, string> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'string',
			nodeName: 'ALL_TYPES_STRING',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 5,
		});
	attributeInfoTel : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'tel',
			nodeName: 'ALL_TYPES_TEL',
			primitiveType: PApiPrimitiveTypes.Tel,
			rawDataIndex: 6,
		});
	attributeInfoEmail : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'email',
			nodeName: 'ALL_TYPES_EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			rawDataIndex: 7,
		});
	attributeInfoPassword : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'password',
			nodeName: 'ALL_TYPES_PASSWORD',
			primitiveType: PApiPrimitiveTypes.Password,
			rawDataIndex: 8,
		});
	attributeInfoPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'postalCode',
			nodeName: 'ALL_TYPES_POSTAL_CODE',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			rawDataIndex: 9,
		});
	attributeInfoSearch : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'search',
			nodeName: 'ALL_TYPES_SEARCH',
			primitiveType: PApiPrimitiveTypes.Search,
			rawDataIndex: 10,
		});
	attributeInfoUrl : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'url',
			nodeName: 'ALL_TYPES_URL',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 11,
		});
	attributeInfoIban : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'iban',
			nodeName: 'ALL_TYPES_IBAN',
			primitiveType: PApiPrimitiveTypes.Iban,
			rawDataIndex: 12,
		});
	attributeInfoBic : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'bic',
			nodeName: 'ALL_TYPES_BIC',
			primitiveType: PApiPrimitiveTypes.Bic,
			rawDataIndex: 13,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'dateTime',
			nodeName: 'ALL_TYPES_DATE_TIME',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 14,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'date',
			nodeName: 'ALL_TYPES_DATE_WITHOUT_TIME',
			primitiveType: PApiPrimitiveTypes.Date,
			rawDataIndex: 15,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'dateExclusiveEnd',
			nodeName: 'ALL_TYPES_DATE_EXCLUSIVE_END',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			rawDataIndex: 16,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'minutes',
			nodeName: 'ALL_TYPES_MINUTES',
			primitiveType: PApiPrimitiveTypes.Minutes,
			rawDataIndex: 17,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'hours',
			nodeName: 'ALL_TYPES_HOURS',
			primitiveType: PApiPrimitiveTypes.Hours,
			rawDataIndex: 18,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'days',
			nodeName: 'ALL_TYPES_DAYS',
			primitiveType: PApiPrimitiveTypes.Days,
			rawDataIndex: 19,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'months',
			nodeName: 'ALL_TYPES_MONTHS',
			primitiveType: PApiPrimitiveTypes.Months,
			rawDataIndex: 20,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'years',
			nodeName: 'ALL_TYPES_YEARS',
			primitiveType: PApiPrimitiveTypes.Years,
			rawDataIndex: 21,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'duration',
			nodeName: 'ALL_TYPES_DURATION',
			primitiveType: PApiPrimitiveTypes.Duration,
			rawDataIndex: 22,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'localTime',
			nodeName: 'ALL_TYPES_LOCAL_TIME',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			rawDataIndex: 23,
		});
	attributeInfoEnum : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'enum',
			nodeName: 'ALL_TYPES_ENUM',
			primitiveType: PApiPrimitiveTypes.Enum,
			rawDataIndex: 24,
		});
	attributeInfoImage : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Image> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Image>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'image',
			nodeName: 'ALL_TYPES_IMAGE',
			primitiveType: PApiPrimitiveTypes.Image,
			rawDataIndex: 25,
		});
	attributeInfoPdf : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Pdf> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Pdf>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'pdf',
			nodeName: 'ALL_TYPES_PDF',
			primitiveType: PApiPrimitiveTypes.Pdf,
			rawDataIndex: 26,
		});
	attributeInfoColor : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Color> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Color>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'color',
			nodeName: 'ALL_TYPES_COLOR',
			primitiveType: PApiPrimitiveTypes.Color,
			rawDataIndex: 27,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiAllTypes, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiAllTypes,
			name: 'percent',
			nodeName: 'ALL_TYPES_PERCENT',
			primitiveType: PApiPrimitiveTypes.Percent,
			rawDataIndex: 28,
		});

	/**
     * 
	 *
	 * @type {boolean}
     */
	get boolean() : boolean {
		this.getterDebugValidations(this.attributeInfoBoolean, false);
		return this.data[1];
	}

	set boolean(v : boolean) {
        this.setterImpl(1, v, 'boolean', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[2];
	}

	set number(v : number) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[3];
	}

	set integer(v : Integer) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[4];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(4, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {string}
     */
	get string() : string {
		this.getterDebugValidations(this.attributeInfoString, false);
		return this.data[5];
	}

	set string(v : string) {
        this.setterImpl(5, v, 'string', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Tel}
     */
	get tel() : Tel {
		this.getterDebugValidations(this.attributeInfoTel, false);
		return this.data[6];
	}

	set tel(v : Tel) {
        this.setterImpl(6, v, 'tel', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Email}
     */
	get email() : Email {
		this.getterDebugValidations(this.attributeInfoEmail, false);
		return this.data[7];
	}

	set email(v : Email) {
        this.setterImpl(7, v, 'email', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Password}
     */
	get password() : Password {
		this.getterDebugValidations(this.attributeInfoPassword, false);
		return this.data[8];
	}

	set password(v : Password) {
        this.setterImpl(8, v, 'password', false, null, null);
	}

	/**
     * 
	 *
	 * @type {PostalCode}
     */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.attributeInfoPostalCode, false);
		return this.data[9];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(9, v, 'postalCode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Search}
     */
	get search() : Search {
		this.getterDebugValidations(this.attributeInfoSearch, false);
		return this.data[10];
	}

	set search(v : Search) {
        this.setterImpl(10, v, 'search', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get url() : Url {
		this.getterDebugValidations(this.attributeInfoUrl, false);
		return this.data[11];
	}

	set url(v : Url) {
        this.setterImpl(11, v, 'url', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Iban}
     */
	get iban() : Iban {
		this.getterDebugValidations(this.attributeInfoIban, false);
		return this.data[12];
	}

	set iban(v : Iban) {
        this.setterImpl(12, v, 'iban', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Bic}
     */
	get bic() : Bic {
		this.getterDebugValidations(this.attributeInfoBic, false);
		return this.data[13];
	}

	set bic(v : Bic) {
        this.setterImpl(13, v, 'bic', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[14];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(14, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[15];
	}

	set date(v : Date) {
        this.setterImpl(15, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[16];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(16, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[17];
	}

	set minutes(v : Minutes) {
        this.setterImpl(17, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[18];
	}

	set hours(v : Hours) {
        this.setterImpl(18, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[19];
	}

	set days(v : Days) {
        this.setterImpl(19, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[20];
	}

	set months(v : Months) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[21];
	}

	set years(v : Years) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[22];
	}

	set duration(v : Duration) {
        this.setterImpl(22, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[23];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(23, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
     */
	get enum() : AiSwitchShowcaseApiCountry {
		this.getterDebugValidations(this.attributeInfoEnum, false);
		return this.data[24];
	}

	set enum(v : AiSwitchShowcaseApiCountry) {
        this.setterImpl(24, v, 'enum', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Image}
     */
	get image() : Image | null {
		this.getterDebugValidations(this.attributeInfoImage, false);
		const path = this.data[25] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set image(v : Image | null) {
        this.setterImpl(25, v, 'image', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Pdf}
     */
	get pdf() : Pdf | null {
		this.getterDebugValidations(this.attributeInfoPdf, false);
		const path = this.data[26] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set pdf(v : Pdf | null) {
        this.setterImpl(26, v, 'pdf', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Color}
     */
	get color() : Color | null {
		this.getterDebugValidations(this.attributeInfoColor, false);
		return this.data[27];
	}

	set color(v : Color | null) {
        this.setterImpl(27, v, 'color', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

export enum AiSwitchShowcaseApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
		 
export class AiSwitchShowcaseApiIsNull extends ApiObjectWrapper<AiSwitchShowcaseApiIsNull>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiIsNull> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiIsNull as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiIsNull> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiIsNull>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'isNull',
			nodeName: 'IS_NULL',
			defaultValue: function(this : AiSwitchShowcaseApiIsNull, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	attributeInfoBoolean : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, boolean> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, boolean>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'boolean',
			nodeName: 'IS_NULL_BOOLEAN',
			primitiveType: PApiPrimitiveTypes.boolean,
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, number> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'number',
			nodeName: 'IS_NULL_NUMBER',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiIsNull) {
				return [
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiIsNull) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'integer',
			nodeName: 'IS_NULL_INTEGER',
			primitiveType: PApiPrimitiveTypes.Integer,
			rawDataIndex: 3,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'clientCurrency',
			nodeName: 'IS_NULL_CLIENT_CURRENCY',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			rawDataIndex: 4,
		});
	attributeInfoString : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, string> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'string',
			nodeName: 'IS_NULL_STRING',
			primitiveType: PApiPrimitiveTypes.string,
			rawDataIndex: 5,
		});
	attributeInfoTel : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'tel',
			nodeName: 'IS_NULL_TEL',
			primitiveType: PApiPrimitiveTypes.Tel,
			rawDataIndex: 6,
		});
	attributeInfoEmail : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'email',
			nodeName: 'IS_NULL_EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			rawDataIndex: 7,
		});
	attributeInfoPassword : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'password',
			nodeName: 'IS_NULL_PASSWORD',
			primitiveType: PApiPrimitiveTypes.Password,
			rawDataIndex: 8,
		});
	attributeInfoPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'postalCode',
			nodeName: 'IS_NULL_POSTAL_CODE',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			rawDataIndex: 9,
		});
	attributeInfoSearch : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'search',
			nodeName: 'IS_NULL_SEARCH',
			primitiveType: PApiPrimitiveTypes.Search,
			rawDataIndex: 10,
		});
	attributeInfoUrl : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'url',
			nodeName: 'IS_NULL_URL',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 11,
		});
	attributeInfoIban : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'iban',
			nodeName: 'IS_NULL_IBAN',
			primitiveType: PApiPrimitiveTypes.Iban,
			rawDataIndex: 12,
		});
	attributeInfoBic : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'bic',
			nodeName: 'IS_NULL_BIC',
			primitiveType: PApiPrimitiveTypes.Bic,
			rawDataIndex: 13,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'dateTime',
			nodeName: 'IS_NULL_DATE_TIME',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 14,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'date',
			nodeName: 'IS_NULL_DATE_WITHOUT_TIME',
			primitiveType: PApiPrimitiveTypes.Date,
			rawDataIndex: 15,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'dateExclusiveEnd',
			nodeName: 'IS_NULL_DATE_EXCLUSIVE_END',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			rawDataIndex: 16,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'minutes',
			nodeName: 'IS_NULL_MINUTES',
			primitiveType: PApiPrimitiveTypes.Minutes,
			rawDataIndex: 17,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'hours',
			nodeName: 'IS_NULL_HOURS',
			primitiveType: PApiPrimitiveTypes.Hours,
			rawDataIndex: 18,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'days',
			nodeName: 'IS_NULL_DAYS',
			primitiveType: PApiPrimitiveTypes.Days,
			rawDataIndex: 19,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'months',
			nodeName: 'IS_NULL_MONTHS',
			primitiveType: PApiPrimitiveTypes.Months,
			rawDataIndex: 20,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'years',
			nodeName: 'IS_NULL_YEARS',
			primitiveType: PApiPrimitiveTypes.Years,
			rawDataIndex: 21,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'duration',
			nodeName: 'IS_NULL_DURATION',
			primitiveType: PApiPrimitiveTypes.Duration,
			rawDataIndex: 22,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'localTime',
			nodeName: 'IS_NULL_LOCAL_TIME',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			rawDataIndex: 23,
		});
	attributeInfoEnum : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'enum',
			nodeName: 'IS_NULL_ENUM',
			primitiveType: PApiPrimitiveTypes.Enum,
			rawDataIndex: 24,
		});
	attributeInfoImage : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Image> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Image>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'image',
			nodeName: 'IS_NULL_IMAGE',
			primitiveType: PApiPrimitiveTypes.Image,
			rawDataIndex: 25,
		});
	attributeInfoPdf : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Pdf> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Pdf>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'pdf',
			nodeName: 'IS_NULL_PDF',
			primitiveType: PApiPrimitiveTypes.Pdf,
			rawDataIndex: 26,
		});
	attributeInfoColor : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Color> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Color>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'color',
			nodeName: 'IS_NULL_COLOR',
			primitiveType: PApiPrimitiveTypes.Color,
			rawDataIndex: 27,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiIsNull, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiIsNull,
			name: 'percent',
			nodeName: 'IS_NULL_PERCENT',
			primitiveType: PApiPrimitiveTypes.Percent,
			rawDataIndex: 28,
		});

	/**
     * 
	 *
	 * @type {boolean}
     */
	get boolean() : boolean | null {
		this.getterDebugValidations(this.attributeInfoBoolean, false);
		return this.data[1];
	}

	set boolean(v : boolean | null) {
        this.setterImpl(1, v, 'boolean', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number | null {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[2];
	}

	set number(v : number | null) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer | null {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[3];
	}

	set integer(v : Integer | null) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency | null {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[4];
	}

	set clientCurrency(v : ClientCurrency | null) {
        this.setterImpl(4, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {string}
     */
	get string() : string | null {
		this.getterDebugValidations(this.attributeInfoString, false);
		return this.data[5];
	}

	set string(v : string | null) {
        this.setterImpl(5, v, 'string', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Tel}
     */
	get tel() : Tel | null {
		this.getterDebugValidations(this.attributeInfoTel, false);
		return this.data[6];
	}

	set tel(v : Tel | null) {
        this.setterImpl(6, v, 'tel', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Email}
     */
	get email() : Email | null {
		this.getterDebugValidations(this.attributeInfoEmail, false);
		return this.data[7];
	}

	set email(v : Email | null) {
        this.setterImpl(7, v, 'email', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Password}
     */
	get password() : Password | null {
		this.getterDebugValidations(this.attributeInfoPassword, false);
		return this.data[8];
	}

	set password(v : Password | null) {
        this.setterImpl(8, v, 'password', false, null, null);
	}

	/**
     * 
	 *
	 * @type {PostalCode}
     */
	get postalCode() : PostalCode | null {
		this.getterDebugValidations(this.attributeInfoPostalCode, false);
		return this.data[9];
	}

	set postalCode(v : PostalCode | null) {
        this.setterImpl(9, v, 'postalCode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Search}
     */
	get search() : Search | null {
		this.getterDebugValidations(this.attributeInfoSearch, false);
		return this.data[10];
	}

	set search(v : Search | null) {
        this.setterImpl(10, v, 'search', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get url() : Url | null {
		this.getterDebugValidations(this.attributeInfoUrl, false);
		return this.data[11];
	}

	set url(v : Url | null) {
        this.setterImpl(11, v, 'url', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Iban}
     */
	get iban() : Iban | null {
		this.getterDebugValidations(this.attributeInfoIban, false);
		return this.data[12];
	}

	set iban(v : Iban | null) {
        this.setterImpl(12, v, 'iban', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Bic}
     */
	get bic() : Bic | null {
		this.getterDebugValidations(this.attributeInfoBic, false);
		return this.data[13];
	}

	set bic(v : Bic | null) {
        this.setterImpl(13, v, 'bic', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[14];
	}

	set dateTime(v : DateTime | null) {
        this.setterImpl(14, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date | null {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[15];
	}

	set date(v : Date | null) {
        this.setterImpl(15, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[16];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(16, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes | null {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[17];
	}

	set minutes(v : Minutes | null) {
        this.setterImpl(17, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours | null {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[18];
	}

	set hours(v : Hours | null) {
        this.setterImpl(18, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days | null {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[19];
	}

	set days(v : Days | null) {
        this.setterImpl(19, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months | null {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[20];
	}

	set months(v : Months | null) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years | null {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[21];
	}

	set years(v : Years | null) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration | null {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[22];
	}

	set duration(v : Duration | null) {
        this.setterImpl(22, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime | null {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[23];
	}

	set localTime(v : LocalTime | null) {
        this.setterImpl(23, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
     */
	get enum() : AiSwitchShowcaseApiCountry | null {
		this.getterDebugValidations(this.attributeInfoEnum, false);
		return this.data[24];
	}

	set enum(v : AiSwitchShowcaseApiCountry | null) {
        this.setterImpl(24, v, 'enum', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Image}
     */
	get image() : Image | null {
		this.getterDebugValidations(this.attributeInfoImage, false);
		const path = this.data[25] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set image(v : Image | null) {
        this.setterImpl(25, v, 'image', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Pdf}
     */
	get pdf() : Pdf | null {
		this.getterDebugValidations(this.attributeInfoPdf, false);
		const path = this.data[26] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set pdf(v : Pdf | null) {
        this.setterImpl(26, v, 'pdf', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Color}
     */
	get color() : Color | null {
		this.getterDebugValidations(this.attributeInfoColor, false);
		return this.data[27];
	}

	set color(v : Color | null) {
        this.setterImpl(27, v, 'color', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent | null {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[28];
	}

	set percent(v : Percent | null) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '3';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '3', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidations extends ApiObjectWrapper<AiSwitchShowcaseApiValidations>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidations> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidations as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidations, AiSwitchShowcaseApiValidations> = new ApiAttributeInfo<AiSwitchShowcaseApiValidations, AiSwitchShowcaseApiValidations>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidations,
			name: 'validations',
			nodeName: 'VALIDATIONS',
			defaultValue: function(this : AiSwitchShowcaseApiValidations, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 3,
		});

	private telWrapper : AiSwitchShowcaseApiValidationsTel = new AiSwitchShowcaseApiValidationsTel(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoTel = this.telWrapper.attributeInfoThis;

	/**
     * 
     */
	get tel() : AiSwitchShowcaseApiValidationsTel {
		this.getterDebugValidations(this.attributeInfoTel, false);
		return this.telWrapper;
	}

	set telTestSetter(v : AiSwitchShowcaseApiValidationsTel) {
        this.setterImpl(1, v.rawData, 'tel', true, null, () => {this.telWrapper = v;});
	}

	private emailWrapper : AiSwitchShowcaseApiValidationsEmail = new AiSwitchShowcaseApiValidationsEmail(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoEmail = this.emailWrapper.attributeInfoThis;

	/**
     * 
     */
	get email() : AiSwitchShowcaseApiValidationsEmail {
		this.getterDebugValidations(this.attributeInfoEmail, false);
		return this.emailWrapper;
	}

	set emailTestSetter(v : AiSwitchShowcaseApiValidationsEmail) {
        this.setterImpl(2, v.rawData, 'email', true, null, () => {this.emailWrapper = v;});
	}

	private passwordWrapper : AiSwitchShowcaseApiValidationsPassword = new AiSwitchShowcaseApiValidationsPassword(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoPassword = this.passwordWrapper.attributeInfoThis;

	/**
     * 
     */
	get password() : AiSwitchShowcaseApiValidationsPassword {
		this.getterDebugValidations(this.attributeInfoPassword, false);
		return this.passwordWrapper;
	}

	set passwordTestSetter(v : AiSwitchShowcaseApiValidationsPassword) {
        this.setterImpl(3, v.rawData, 'password', true, null, () => {this.passwordWrapper = v;});
	}

	private postalCodeWrapper : AiSwitchShowcaseApiValidationsPostalCode = new AiSwitchShowcaseApiValidationsPostalCode(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoPostalCode = this.postalCodeWrapper.attributeInfoThis;

	/**
     * 
     */
	get postalCode() : AiSwitchShowcaseApiValidationsPostalCode {
		this.getterDebugValidations(this.attributeInfoPostalCode, false);
		return this.postalCodeWrapper;
	}

	set postalCodeTestSetter(v : AiSwitchShowcaseApiValidationsPostalCode) {
        this.setterImpl(4, v.rawData, 'postalCode', true, null, () => {this.postalCodeWrapper = v;});
	}

	private urlWrapper : AiSwitchShowcaseApiValidationsUrl = new AiSwitchShowcaseApiValidationsUrl(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoUrl = this.urlWrapper.attributeInfoThis;

	/**
     * 
     */
	get url() : AiSwitchShowcaseApiValidationsUrl {
		this.getterDebugValidations(this.attributeInfoUrl, false);
		return this.urlWrapper;
	}

	set urlTestSetter(v : AiSwitchShowcaseApiValidationsUrl) {
        this.setterImpl(5, v.rawData, 'url', true, null, () => {this.urlWrapper = v;});
	}

	private ibanWrapper : AiSwitchShowcaseApiValidationsIban = new AiSwitchShowcaseApiValidationsIban(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoIban = this.ibanWrapper.attributeInfoThis;

	/**
     * 
     */
	get iban() : AiSwitchShowcaseApiValidationsIban {
		this.getterDebugValidations(this.attributeInfoIban, false);
		return this.ibanWrapper;
	}

	set ibanTestSetter(v : AiSwitchShowcaseApiValidationsIban) {
        this.setterImpl(6, v.rawData, 'iban', true, null, () => {this.ibanWrapper = v;});
	}

	private bicWrapper : AiSwitchShowcaseApiValidationsBic = new AiSwitchShowcaseApiValidationsBic(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoBic = this.bicWrapper.attributeInfoThis;

	/**
     * 
     */
	get bic() : AiSwitchShowcaseApiValidationsBic {
		this.getterDebugValidations(this.attributeInfoBic, false);
		return this.bicWrapper;
	}

	set bicTestSetter(v : AiSwitchShowcaseApiValidationsBic) {
        this.setterImpl(7, v.rawData, 'bic', true, null, () => {this.bicWrapper = v;});
	}

	private clientCurrencyWrapper : AiSwitchShowcaseApiValidationsClientCurrency = new AiSwitchShowcaseApiValidationsClientCurrency(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoClientCurrency = this.clientCurrencyWrapper.attributeInfoThis;

	/**
     * 
     */
	get clientCurrency() : AiSwitchShowcaseApiValidationsClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.clientCurrencyWrapper;
	}

	set clientCurrencyTestSetter(v : AiSwitchShowcaseApiValidationsClientCurrency) {
        this.setterImpl(8, v.rawData, 'clientCurrency', true, null, () => {this.clientCurrencyWrapper = v;});
	}

	private integerWrapper : AiSwitchShowcaseApiValidationsInteger = new AiSwitchShowcaseApiValidationsInteger(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoInteger = this.integerWrapper.attributeInfoThis;

	/**
     * 
     */
	get integer() : AiSwitchShowcaseApiValidationsInteger {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.integerWrapper;
	}

	set integerTestSetter(v : AiSwitchShowcaseApiValidationsInteger) {
        this.setterImpl(9, v.rawData, 'integer', true, null, () => {this.integerWrapper = v;});
	}

	private requiredWrapper : AiSwitchShowcaseApiValidationsRequired = new AiSwitchShowcaseApiValidationsRequired(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoRequired = this.requiredWrapper.attributeInfoThis;

	/**
     * 
     */
	get required() : AiSwitchShowcaseApiValidationsRequired {
		this.getterDebugValidations(this.attributeInfoRequired, false);
		return this.requiredWrapper;
	}

	set requiredTestSetter(v : AiSwitchShowcaseApiValidationsRequired) {
        this.setterImpl(10, v.rawData, 'required', true, null, () => {this.requiredWrapper = v;});
	}

	private minWrapper : AiSwitchShowcaseApiValidationsMin = new AiSwitchShowcaseApiValidationsMin(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoMin = this.minWrapper.attributeInfoThis;

	/**
     * 
     */
	get min() : AiSwitchShowcaseApiValidationsMin {
		this.getterDebugValidations(this.attributeInfoMin, false);
		return this.minWrapper;
	}

	set minTestSetter(v : AiSwitchShowcaseApiValidationsMin) {
        this.setterImpl(11, v.rawData, 'min', true, null, () => {this.minWrapper = v;});
	}

	private maxWrapper : AiSwitchShowcaseApiValidationsMax = new AiSwitchShowcaseApiValidationsMax(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoMax = this.maxWrapper.attributeInfoThis;

	/**
     * 
     */
	get max() : AiSwitchShowcaseApiValidationsMax {
		this.getterDebugValidations(this.attributeInfoMax, false);
		return this.maxWrapper;
	}

	set maxTestSetter(v : AiSwitchShowcaseApiValidationsMax) {
        this.setterImpl(12, v.rawData, 'max', true, null, () => {this.maxWrapper = v;});
	}

	private maxDecimalPlacesWrapper : AiSwitchShowcaseApiValidationsMaxDecimalPlaces = new AiSwitchShowcaseApiValidationsMaxDecimalPlaces(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoMaxDecimalPlaces = this.maxDecimalPlacesWrapper.attributeInfoThis;

	/**
     * 
     */
	get maxDecimalPlaces() : AiSwitchShowcaseApiValidationsMaxDecimalPlaces {
		this.getterDebugValidations(this.attributeInfoMaxDecimalPlaces, false);
		return this.maxDecimalPlacesWrapper;
	}

	set maxDecimalPlacesTestSetter(v : AiSwitchShowcaseApiValidationsMaxDecimalPlaces) {
        this.setterImpl(13, v.rawData, 'maxDecimalPlaces', true, null, () => {this.maxDecimalPlacesWrapper = v;});
	}

	private maxLengthWrapper : AiSwitchShowcaseApiValidationsMaxLength = new AiSwitchShowcaseApiValidationsMaxLength(this.api,
		this as unknown as AiSwitchShowcaseApiValidations, );
	public attributeInfoMaxLength = this.maxLengthWrapper.attributeInfoThis;

	/**
     * 
     */
	get maxLength() : AiSwitchShowcaseApiValidationsMaxLength {
		this.getterDebugValidations(this.attributeInfoMaxLength, false);
		return this.maxLengthWrapper;
	}

	set maxLengthTestSetter(v : AiSwitchShowcaseApiValidationsMaxLength) {
        this.setterImpl(14, v.rawData, 'maxLength', true, null, () => {this.maxLengthWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.telWrapper._fixIds(_idReplacements);
		this.emailWrapper._fixIds(_idReplacements);
		this.passwordWrapper._fixIds(_idReplacements);
		this.postalCodeWrapper._fixIds(_idReplacements);
		this.urlWrapper._fixIds(_idReplacements);
		this.ibanWrapper._fixIds(_idReplacements);
		this.bicWrapper._fixIds(_idReplacements);
		this.clientCurrencyWrapper._fixIds(_idReplacements);
		this.integerWrapper._fixIds(_idReplacements);
		this.requiredWrapper._fixIds(_idReplacements);
		this.minWrapper._fixIds(_idReplacements);
		this.maxWrapper._fixIds(_idReplacements);
		this.maxDecimalPlacesWrapper._fixIds(_idReplacements);
		this.maxLengthWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.telWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.emailWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
		this.passwordWrapper._updateRawData(data && data[3] !== undefined ? data[3] : null, generateMissingData);
		this.postalCodeWrapper._updateRawData(data && data[4] !== undefined ? data[4] : null, generateMissingData);
		this.urlWrapper._updateRawData(data && data[5] !== undefined ? data[5] : null, generateMissingData);
		this.ibanWrapper._updateRawData(data && data[6] !== undefined ? data[6] : null, generateMissingData);
		this.bicWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.clientCurrencyWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
		this.integerWrapper._updateRawData(data && data[9] !== undefined ? data[9] : null, generateMissingData);
		this.requiredWrapper._updateRawData(data && data[10] !== undefined ? data[10] : null, generateMissingData);
		this.minWrapper._updateRawData(data && data[11] !== undefined ? data[11] : null, generateMissingData);
		this.maxWrapper._updateRawData(data && data[12] !== undefined ? data[12] : null, generateMissingData);
		this.maxDecimalPlacesWrapper._updateRawData(data && data[13] !== undefined ? data[13] : null, generateMissingData);
		this.maxLengthWrapper._updateRawData(data && data[14] !== undefined ? data[14] : null, generateMissingData);
	}

	protected get dni() : string {
		return '4';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '4', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsTel extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsTel>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsTel> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsTel as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTel, AiSwitchShowcaseApiValidationsTel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTel, AiSwitchShowcaseApiValidationsTel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTel,
			name: 'tel',
			nodeName: 'VALIDATIONS_TEL',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsTel, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsTelFails = new AiSwitchShowcaseApiValidationsTelFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsTel, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsTelFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsTelFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '61';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '61', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsTelFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsTelFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsTel | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsTelFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsTelFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, AiSwitchShowcaseApiValidationsTelFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, AiSwitchShowcaseApiValidationsTelFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_TEL_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsTelFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoBadValue : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'badValue',
			nodeName: 'VALIDATIONS_TEL_FAILS_BAD_VALUE',
			primitiveType: PApiPrimitiveTypes.Tel,
			rawDataIndex: 1,
		});
	attributeInfoTooShort : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'tooShort',
			nodeName: 'VALIDATIONS_TEL_FAILS_TOO_SHORT',
			primitiveType: PApiPrimitiveTypes.Tel,
			rawDataIndex: 2,
		});
	attributeInfoWhitespace : ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsTelFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsTelFails,
			name: 'whitespace',
			nodeName: 'VALIDATIONS_TEL_FAILS_WHITESPACE',
			primitiveType: PApiPrimitiveTypes.Tel,
			rawDataIndex: 3,
		});

	/**
     * 
	 *
	 * @type {Tel}
     */
	get badValue() : Tel {
		this.getterDebugValidations(this.attributeInfoBadValue, false);
		return this.data[1];
	}

	set badValue(v : Tel) {
        this.setterImpl(1, v, 'badValue', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Tel}
     */
	get tooShort() : Tel {
		this.getterDebugValidations(this.attributeInfoTooShort, false);
		return this.data[2];
	}

	set tooShort(v : Tel) {
        this.setterImpl(2, v, 'tooShort', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Tel}
     */
	get whitespace() : Tel {
		this.getterDebugValidations(this.attributeInfoWhitespace, false);
		return this.data[3];
	}

	set whitespace(v : Tel) {
        this.setterImpl(3, v, 'whitespace', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '75';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '75', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsEmail extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsEmail>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsEmail> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsEmail as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmail, AiSwitchShowcaseApiValidationsEmail> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmail, AiSwitchShowcaseApiValidationsEmail>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmail,
			name: 'email',
			nodeName: 'VALIDATIONS_EMAIL',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsEmail, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsEmailFails = new AiSwitchShowcaseApiValidationsEmailFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsEmail, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsEmailFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsEmailFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '62';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '62', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsEmailFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsEmailFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsEmail | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsEmailFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsEmailFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, AiSwitchShowcaseApiValidationsEmailFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, AiSwitchShowcaseApiValidationsEmailFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmailFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_EMAIL_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsEmailFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoNoAt : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmailFails,
			name: 'noAt',
			nodeName: 'VALIDATIONS_EMAIL_FAILS_NO_AT',
			primitiveType: PApiPrimitiveTypes.Email,
			rawDataIndex: 1,
		});
	attributeInfoWrongFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsEmailFails, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsEmailFails,
			name: 'wrongFormat',
			nodeName: 'VALIDATIONS_EMAIL_FAILS_WRONG_FORMAT',
			primitiveType: PApiPrimitiveTypes.Email,
			rawDataIndex: 2,
		});

	/**
     * 
	 *
	 * @type {Email}
     */
	get noAt() : Email {
		this.getterDebugValidations(this.attributeInfoNoAt, false);
		return this.data[1];
	}

	set noAt(v : Email) {
        this.setterImpl(1, v, 'noAt', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Email}
     */
	get wrongFormat() : Email {
		this.getterDebugValidations(this.attributeInfoWrongFormat, false);
		return this.data[2];
	}

	set wrongFormat(v : Email) {
        this.setterImpl(2, v, 'wrongFormat', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '79';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '79', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPassword extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPassword>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPassword> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPassword as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPassword, AiSwitchShowcaseApiValidationsPassword> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPassword, AiSwitchShowcaseApiValidationsPassword>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPassword,
			name: 'password',
			nodeName: 'VALIDATIONS_PASSWORD',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPassword, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 3,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsPasswordFails = new AiSwitchShowcaseApiValidationsPasswordFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsPassword, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsPasswordFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsPasswordFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '63';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '63', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPasswordFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPasswordFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsPassword | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPasswordFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPasswordFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, AiSwitchShowcaseApiValidationsPasswordFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, AiSwitchShowcaseApiValidationsPasswordFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPasswordFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_PASSWORD_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPasswordFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoTooShort : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPasswordFails,
			name: 'tooShort',
			nodeName: 'VALIDATIONS_PASSWORD_FAILS_TOO_SHORT',
			primitiveType: PApiPrimitiveTypes.Password,
			rawDataIndex: 1,
		});
	attributeInfoNeedsDigits : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPasswordFails, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPasswordFails,
			name: 'needsDigits',
			nodeName: 'VALIDATIONS_PASSWORD_FAILS_NEEDS_DIGITS',
			primitiveType: PApiPrimitiveTypes.Password,
			rawDataIndex: 2,
		});

	/**
     * 
	 *
	 * @type {Password}
     */
	get tooShort() : Password {
		this.getterDebugValidations(this.attributeInfoTooShort, false);
		return this.data[1];
	}

	set tooShort(v : Password) {
        this.setterImpl(1, v, 'tooShort', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Password}
     */
	get needsDigits() : Password {
		this.getterDebugValidations(this.attributeInfoNeedsDigits, false);
		return this.data[2];
	}

	set needsDigits(v : Password) {
        this.setterImpl(2, v, 'needsDigits', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '82';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '82', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPostalCode extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPostalCode>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPostalCode> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPostalCode as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCode, AiSwitchShowcaseApiValidationsPostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCode, AiSwitchShowcaseApiValidationsPostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCode,
			name: 'postalCode',
			nodeName: 'VALIDATIONS_POSTAL_CODE',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPostalCode, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 4,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsPostalCodeFails = new AiSwitchShowcaseApiValidationsPostalCodeFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsPostalCode, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsPostalCodeFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsPostalCodeFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '64';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '64', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsPostalCodeFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsPostalCodeFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsPostalCode | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsPostalCodeFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsPostalCodeFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, AiSwitchShowcaseApiValidationsPostalCodeFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, AiSwitchShowcaseApiValidationsPostalCodeFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCodeFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_POSTAL_CODE_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsPostalCodeFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoTooLong : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCodeFails,
			name: 'tooLong',
			nodeName: 'VALIDATIONS_POSTAL_CODE_FAILS_TOO_LONG',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			rawDataIndex: 1,
		});
	attributeInfoTooShort : ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsPostalCodeFails, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsPostalCodeFails,
			name: 'tooShort',
			nodeName: 'VALIDATIONS_POSTAL_CODE_FAILS_TOO_SHORT',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			rawDataIndex: 2,
		});

	/**
     * 
	 *
	 * @type {PostalCode}
     */
	get tooLong() : PostalCode {
		this.getterDebugValidations(this.attributeInfoTooLong, false);
		return this.data[1];
	}

	set tooLong(v : PostalCode) {
        this.setterImpl(1, v, 'tooLong', false, null, null);
	}

	/**
     * 
	 *
	 * @type {PostalCode}
     */
	get tooShort() : PostalCode {
		this.getterDebugValidations(this.attributeInfoTooShort, false);
		return this.data[2];
	}

	set tooShort(v : PostalCode) {
        this.setterImpl(2, v, 'tooShort', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '85';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '85', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsUrl extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsUrl>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsUrl> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsUrl as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrl, AiSwitchShowcaseApiValidationsUrl> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrl, AiSwitchShowcaseApiValidationsUrl>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrl,
			name: 'url',
			nodeName: 'VALIDATIONS_URL',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsUrl, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 5,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsUrlValid = new AiSwitchShowcaseApiValidationsUrlValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsUrl, );
	public attributeInfoValid = this.validWrapper.attributeInfoThis;

	/**
     * 
     */
	get valid() : AiSwitchShowcaseApiValidationsUrlValid {
		this.getterDebugValidations(this.attributeInfoValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsUrlValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, () => {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsUrlFails = new AiSwitchShowcaseApiValidationsUrlFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsUrl, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsUrlFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsUrlFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '65';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '65', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsUrlValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsUrlValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsUrl | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsUrlValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsUrlValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, AiSwitchShowcaseApiValidationsUrlValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, AiSwitchShowcaseApiValidationsUrlValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_URL_VALID',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsUrlValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoWithWww : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlValid,
			name: 'withWww',
			nodeName: 'VALIDATIONS_URL_VALID_WITH_WWW',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 1,
		});
	attributeInfoWithoutWww : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlValid, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlValid,
			name: 'withoutWww',
			nodeName: 'VALIDATIONS_URL_VALID_WITHOUT_WWW',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 2,
		});

	/**
     * 
	 *
	 * @type {Url}
     */
	get withWww() : Url {
		this.getterDebugValidations(this.attributeInfoWithWww, false);
		return this.data[1];
	}

	set withWww(v : Url) {
        this.setterImpl(1, v, 'withWww', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get withoutWww() : Url {
		this.getterDebugValidations(this.attributeInfoWithoutWww, false);
		return this.data[2];
	}

	set withoutWww(v : Url) {
        this.setterImpl(2, v, 'withoutWww', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '88';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '88', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsUrlFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsUrlFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsUrl | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsUrlFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsUrlFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, AiSwitchShowcaseApiValidationsUrlFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, AiSwitchShowcaseApiValidationsUrlFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_URL_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsUrlFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	attributeInfoProtocolMissingInvalidDomainFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'protocolMissingInvalidDomainFormat',
			nodeName: 'VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_INVALID_DOMAIN_FORMAT',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 1,
		});
	attributeInfoProtocolMissingValidDomainFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'protocolMissingValidDomainFormat',
			nodeName: 'VALIDATIONS_URL_FAILS_PROTOCOL_MISSING_VALID_DOMAIN_FORMAT',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 2,
		});
	attributeInfoUrlIncomplete : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'urlIncomplete',
			nodeName: 'VALIDATIONS_URL_FAILS_URL_INCOMPLETE',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 3,
		});
	attributeInfoWrongFormat : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'wrongFormat',
			nodeName: 'VALIDATIONS_URL_FAILS_WRONG_FORMAT',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 4,
		});
	attributeInfoStartingWww : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'startingWww',
			nodeName: 'VALIDATIONS_URL_FAILS_STARTING_WWW',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 5,
		});
	attributeInfoWhitespace : ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsUrlFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsUrlFails,
			name: 'whitespace',
			nodeName: 'VALIDATIONS_URL_FAILS_WHITESPACE',
			primitiveType: PApiPrimitiveTypes.Url,
			rawDataIndex: 6,
		});

	/**
     * 
	 *
	 * @type {Url}
     */
	get protocolMissingInvalidDomainFormat() : Url {
		this.getterDebugValidations(this.attributeInfoProtocolMissingInvalidDomainFormat, false);
		return this.data[1];
	}

	set protocolMissingInvalidDomainFormat(v : Url) {
        this.setterImpl(1, v, 'protocolMissingInvalidDomainFormat', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get protocolMissingValidDomainFormat() : Url {
		this.getterDebugValidations(this.attributeInfoProtocolMissingValidDomainFormat, false);
		return this.data[2];
	}

	set protocolMissingValidDomainFormat(v : Url) {
        this.setterImpl(2, v, 'protocolMissingValidDomainFormat', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get urlIncomplete() : Url {
		this.getterDebugValidations(this.attributeInfoUrlIncomplete, false);
		return this.data[3];
	}

	set urlIncomplete(v : Url) {
        this.setterImpl(3, v, 'urlIncomplete', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get wrongFormat() : Url {
		this.getterDebugValidations(this.attributeInfoWrongFormat, false);
		return this.data[4];
	}

	set wrongFormat(v : Url) {
        this.setterImpl(4, v, 'wrongFormat', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get startingWww() : Url {
		this.getterDebugValidations(this.attributeInfoStartingWww, false);
		return this.data[5];
	}

	set startingWww(v : Url) {
        this.setterImpl(5, v, 'startingWww', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get whitespace() : Url {
		this.getterDebugValidations(this.attributeInfoWhitespace, false);
		return this.data[6];
	}

	set whitespace(v : Url) {
        this.setterImpl(6, v, 'whitespace', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '89';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '89', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsIban extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsIban>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsIban> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsIban as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIban, AiSwitchShowcaseApiValidationsIban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIban, AiSwitchShowcaseApiValidationsIban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIban,
			name: 'iban',
			nodeName: 'VALIDATIONS_IBAN',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsIban, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 6,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsIbanFails = new AiSwitchShowcaseApiValidationsIbanFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsIban, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsIbanFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsIbanFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '66';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '66', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsIbanFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsIbanFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsIban | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsIbanFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsIbanFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, AiSwitchShowcaseApiValidationsIbanFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, AiSwitchShowcaseApiValidationsIbanFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIbanFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_IBAN_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsIbanFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoBadValue : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIbanFails, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIbanFails,
			name: 'badValue',
			nodeName: 'VALIDATIONS_IBAN_FAILS_BAD_VALUE',
			primitiveType: PApiPrimitiveTypes.Iban,
			rawDataIndex: 1,
		});

	/**
     * 
	 *
	 * @type {Iban}
     */
	get badValue() : Iban {
		this.getterDebugValidations(this.attributeInfoBadValue, false);
		return this.data[1];
	}

	set badValue(v : Iban) {
        this.setterImpl(1, v, 'badValue', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '98';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '98', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsBic extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsBic>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsBic> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsBic as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsBic, AiSwitchShowcaseApiValidationsBic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsBic, AiSwitchShowcaseApiValidationsBic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsBic,
			name: 'bic',
			nodeName: 'VALIDATIONS_BIC',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsBic, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 7,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsBicFails = new AiSwitchShowcaseApiValidationsBicFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsBic, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsBicFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsBicFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '67';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '67', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsBicFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsBicFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsBic | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsBicFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsBicFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, AiSwitchShowcaseApiValidationsBicFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, AiSwitchShowcaseApiValidationsBicFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsBicFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_BIC_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsBicFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoBadValue : ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsBicFails, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsBicFails,
			name: 'badValue',
			nodeName: 'VALIDATIONS_BIC_FAILS_BAD_VALUE',
			primitiveType: PApiPrimitiveTypes.Bic,
			rawDataIndex: 1,
		});

	/**
     * 
	 *
	 * @type {Bic}
     */
	get badValue() : Bic {
		this.getterDebugValidations(this.attributeInfoBadValue, false);
		return this.data[1];
	}

	set badValue(v : Bic) {
        this.setterImpl(1, v, 'badValue', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '100';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '100', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsClientCurrency extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsClientCurrency>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsClientCurrency> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsClientCurrency as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrency, AiSwitchShowcaseApiValidationsClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrency, AiSwitchShowcaseApiValidationsClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsClientCurrency,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_CLIENT_CURRENCY',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsClientCurrency, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 8,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsClientCurrencyFails = new AiSwitchShowcaseApiValidationsClientCurrencyFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsClientCurrency, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsClientCurrencyFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsClientCurrencyFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '68';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '68', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsClientCurrencyFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsClientCurrencyFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsClientCurrency | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsClientCurrencyFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsClientCurrencyFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, AiSwitchShowcaseApiValidationsClientCurrencyFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, AiSwitchShowcaseApiValidationsClientCurrencyFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsClientCurrencyFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_CLIENT_CURRENCY_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsClientCurrencyFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoTooManyDigits : ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsClientCurrencyFails, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsClientCurrencyFails,
			name: 'tooManyDigits',
			nodeName: 'VALIDATIONS_CLIENT_CURRENCY_FAILS_TOO_MANY_DIGITS',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			rawDataIndex: 1,
		});

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get tooManyDigits() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoTooManyDigits, false);
		return this.data[1];
	}

	set tooManyDigits(v : ClientCurrency) {
        this.setterImpl(1, v, 'tooManyDigits', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '102';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '102', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsInteger extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsInteger>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsInteger> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsInteger as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsInteger, AiSwitchShowcaseApiValidationsInteger> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsInteger, AiSwitchShowcaseApiValidationsInteger>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsInteger,
			name: 'integer',
			nodeName: 'VALIDATIONS_INTEGER',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsInteger, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 9,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsIntegerFails = new AiSwitchShowcaseApiValidationsIntegerFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsInteger, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsIntegerFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsIntegerFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '69';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '69', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsIntegerFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsIntegerFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsInteger | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsIntegerFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsIntegerFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, AiSwitchShowcaseApiValidationsIntegerFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, AiSwitchShowcaseApiValidationsIntegerFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIntegerFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_INTEGER_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsIntegerFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoHasDecimalPlaces : ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsIntegerFails, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsIntegerFails,
			name: 'hasDecimalPlaces',
			nodeName: 'VALIDATIONS_INTEGER_FAILS_HAS_DECIMAL_PLACES',
			primitiveType: PApiPrimitiveTypes.Hours,
			rawDataIndex: 1,
		});

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hasDecimalPlaces() : Hours {
		this.getterDebugValidations(this.attributeInfoHasDecimalPlaces, false);
		return this.data[1];
	}

	set hasDecimalPlaces(v : Hours) {
        this.setterImpl(1, v, 'hasDecimalPlaces', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '104';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '104', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsRequired extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsRequired>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsRequired> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsRequired as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequired, AiSwitchShowcaseApiValidationsRequired> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequired, AiSwitchShowcaseApiValidationsRequired>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequired,
			name: 'required',
			nodeName: 'VALIDATIONS_REQUIRED',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsRequired, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 10,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsRequiredValid = new AiSwitchShowcaseApiValidationsRequiredValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsRequired, );
	public attributeInfoValid = this.validWrapper.attributeInfoThis;

	/**
     * 
     */
	get valid() : AiSwitchShowcaseApiValidationsRequiredValid {
		this.getterDebugValidations(this.attributeInfoValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsRequiredValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, () => {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsRequiredFails = new AiSwitchShowcaseApiValidationsRequiredFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsRequired, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsRequiredFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsRequiredFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '70';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '70', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsRequiredValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsRequiredValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsRequired | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsRequiredValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsRequiredValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiValidationsRequiredValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiValidationsRequiredValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_REQUIRED_VALID',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsRequiredValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'number',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_NUMBER',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.number, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'integer',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_INTEGER',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Integer, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_CLIENT_CURRENCY',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoString : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, string> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'string',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_STRING',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoTel : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'tel',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_TEL',
			primitiveType: PApiPrimitiveTypes.Tel,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoEmail : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'email',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoPassword : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'password',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_PASSWORD',
			primitiveType: PApiPrimitiveTypes.Password,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'postalCode',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_POSTAL_CODE',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.PostalCode, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoSearch : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'search',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_SEARCH',
			primitiveType: PApiPrimitiveTypes.Search,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Search, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoUrl : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'url',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_URL',
			primitiveType: PApiPrimitiveTypes.Url,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Url, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoIban : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'iban',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_IBAN',
			primitiveType: PApiPrimitiveTypes.Iban,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Iban, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	attributeInfoBic : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'bic',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_BIC',
			primitiveType: PApiPrimitiveTypes.Bic,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Bic, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DATE_TIME',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'date',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DATE_WITHOUT_TIME',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DATE_EXCLUSIVE_END',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.DateExclusiveEnd, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'minutes',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_MINUTES',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Minutes, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'hours',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_HOURS',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Hours, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'days',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DAYS',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Days, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'months',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_MONTHS',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Months, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'years',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_YEARS',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Years, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'duration',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_DURATION',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Duration, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'localTime',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_LOCAL_TIME',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoEnum : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'enum',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_ENUM',
			primitiveType: PApiPrimitiveTypes.Enum,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	attributeInfoColor : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Color> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Color>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'color',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_COLOR',
			primitiveType: PApiPrimitiveTypes.Color,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Color, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredValid, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredValid,
			name: 'percent',
			nodeName: 'VALIDATIONS_REQUIRED_VALID_PERCENT',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredValid) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[1];
	}

	set number(v : number) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[2];
	}

	set integer(v : Integer) {
        this.setterImpl(2, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[3];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(3, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {string}
     */
	get string() : string {
		this.getterDebugValidations(this.attributeInfoString, false);
		return this.data[4];
	}

	set string(v : string) {
        this.setterImpl(4, v, 'string', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Tel}
     */
	get tel() : Tel {
		this.getterDebugValidations(this.attributeInfoTel, false);
		return this.data[5];
	}

	set tel(v : Tel) {
        this.setterImpl(5, v, 'tel', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Email}
     */
	get email() : Email {
		this.getterDebugValidations(this.attributeInfoEmail, false);
		return this.data[6];
	}

	set email(v : Email) {
        this.setterImpl(6, v, 'email', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Password}
     */
	get password() : Password {
		this.getterDebugValidations(this.attributeInfoPassword, false);
		return this.data[7];
	}

	set password(v : Password) {
        this.setterImpl(7, v, 'password', false, null, null);
	}

	/**
     * 
	 *
	 * @type {PostalCode}
     */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.attributeInfoPostalCode, false);
		return this.data[8];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(8, v, 'postalCode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Search}
     */
	get search() : Search {
		this.getterDebugValidations(this.attributeInfoSearch, false);
		return this.data[9];
	}

	set search(v : Search) {
        this.setterImpl(9, v, 'search', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get url() : Url {
		this.getterDebugValidations(this.attributeInfoUrl, false);
		return this.data[10];
	}

	set url(v : Url) {
        this.setterImpl(10, v, 'url', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Iban}
     */
	get iban() : Iban {
		this.getterDebugValidations(this.attributeInfoIban, false);
		return this.data[11];
	}

	set iban(v : Iban) {
        this.setterImpl(11, v, 'iban', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Bic}
     */
	get bic() : Bic {
		this.getterDebugValidations(this.attributeInfoBic, false);
		return this.data[12];
	}

	set bic(v : Bic) {
        this.setterImpl(12, v, 'bic', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[13];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(13, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[14];
	}

	set date(v : Date) {
        this.setterImpl(14, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[15];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(15, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[16];
	}

	set minutes(v : Minutes) {
        this.setterImpl(16, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[17];
	}

	set hours(v : Hours) {
        this.setterImpl(17, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[18];
	}

	set days(v : Days) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[19];
	}

	set months(v : Months) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[20];
	}

	set years(v : Years) {
        this.setterImpl(20, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[21];
	}

	set duration(v : Duration) {
        this.setterImpl(21, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[22];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(22, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
     */
	get enum() : AiSwitchShowcaseApiCountry {
		this.getterDebugValidations(this.attributeInfoEnum, false);
		return this.data[23];
	}

	set enum(v : AiSwitchShowcaseApiCountry) {
        this.setterImpl(23, v, 'enum', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Color}
     */
	get color() : Color {
		this.getterDebugValidations(this.attributeInfoColor, false);
		return this.data[24];
	}

	set color(v : Color) {
        this.setterImpl(24, v, 'color', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[25];
	}

	set percent(v : Percent) {
        this.setterImpl(25, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '106';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '106', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsRequiredFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsRequiredFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsRequired | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsRequiredFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsRequiredFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiValidationsRequiredFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiValidationsRequiredFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsRequiredFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'number',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_NUMBER',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.number, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(2, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'integer',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_INTEGER',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Integer, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_CLIENT_CURRENCY',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.ClientCurrency, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoString : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, string> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'string',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_STRING',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoTel : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Tel> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Tel>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'tel',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_TEL',
			primitiveType: PApiPrimitiveTypes.Tel,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoEmail : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Email> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Email>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'email',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoPassword : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Password> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Password>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'password',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_PASSWORD',
			primitiveType: PApiPrimitiveTypes.Password,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoPostalCode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, PostalCode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, PostalCode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'postalCode',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_POSTAL_CODE',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.PostalCode, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoSearch : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Search> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Search>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'search',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_SEARCH',
			primitiveType: PApiPrimitiveTypes.Search,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Search, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoUrl : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Url> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Url>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'url',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_URL',
			primitiveType: PApiPrimitiveTypes.Url,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Url, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoIban : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Iban> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Iban>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'iban',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_IBAN',
			primitiveType: PApiPrimitiveTypes.Iban,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Iban, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	attributeInfoBic : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Bic> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Bic>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'bic',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_BIC',
			primitiveType: PApiPrimitiveTypes.Bic,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Bic, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DATE_TIME',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.DateTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'date',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DATE_WITHOUT_TIME',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Date, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DATE_EXCLUSIVE_END',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.DateExclusiveEnd, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'minutes',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_MINUTES',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Minutes, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'hours',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_HOURS',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Hours, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'days',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DAYS',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Days, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'months',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_MONTHS',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Months, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'years',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_YEARS',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Years, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'duration',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_DURATION',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Duration, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'localTime',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_LOCAL_TIME',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.LocalTime, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoEnum : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiCountry> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, AiSwitchShowcaseApiCountry>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'enum',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_ENUM',
			primitiveType: PApiPrimitiveTypes.Enum,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	attributeInfoImage : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Image> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Image>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'image',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_IMAGE',
			primitiveType: PApiPrimitiveTypes.Image,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Image, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoPdf : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Pdf> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Pdf>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'pdf',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_PDF',
			primitiveType: PApiPrimitiveTypes.Pdf,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Pdf, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsRequiredFails, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsRequiredFails,
			name: 'percent',
			nodeName: 'VALIDATIONS_REQUIRED_FAILS_PERCENT',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Percent, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsRequiredFails) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number | null {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[1];
	}

	set number(v : number | null) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer | null {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[2];
	}

	set integer(v : Integer | null) {
        this.setterImpl(2, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency | null {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[3];
	}

	set clientCurrency(v : ClientCurrency | null) {
        this.setterImpl(3, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {string}
     */
	get string() : string {
		this.getterDebugValidations(this.attributeInfoString, false);
		return this.data[4];
	}

	set string(v : string) {
        this.setterImpl(4, v, 'string', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Tel}
     */
	get tel() : Tel {
		this.getterDebugValidations(this.attributeInfoTel, false);
		return this.data[5];
	}

	set tel(v : Tel) {
        this.setterImpl(5, v, 'tel', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Email}
     */
	get email() : Email {
		this.getterDebugValidations(this.attributeInfoEmail, false);
		return this.data[6];
	}

	set email(v : Email) {
        this.setterImpl(6, v, 'email', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Password}
     */
	get password() : Password {
		this.getterDebugValidations(this.attributeInfoPassword, false);
		return this.data[7];
	}

	set password(v : Password) {
        this.setterImpl(7, v, 'password', false, null, null);
	}

	/**
     * 
	 *
	 * @type {PostalCode}
     */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.attributeInfoPostalCode, false);
		return this.data[8];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(8, v, 'postalCode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Search}
     */
	get search() : Search {
		this.getterDebugValidations(this.attributeInfoSearch, false);
		return this.data[9];
	}

	set search(v : Search) {
        this.setterImpl(9, v, 'search', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Url}
     */
	get url() : Url {
		this.getterDebugValidations(this.attributeInfoUrl, false);
		return this.data[10];
	}

	set url(v : Url) {
        this.setterImpl(10, v, 'url', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Iban}
     */
	get iban() : Iban {
		this.getterDebugValidations(this.attributeInfoIban, false);
		return this.data[11];
	}

	set iban(v : Iban) {
        this.setterImpl(11, v, 'iban', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Bic}
     */
	get bic() : Bic {
		this.getterDebugValidations(this.attributeInfoBic, false);
		return this.data[12];
	}

	set bic(v : Bic) {
        this.setterImpl(12, v, 'bic', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[13];
	}

	set dateTime(v : DateTime | null) {
        this.setterImpl(13, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date | null {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[14];
	}

	set date(v : Date | null) {
        this.setterImpl(14, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[15];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(15, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes | null {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[16];
	}

	set minutes(v : Minutes | null) {
        this.setterImpl(16, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours | null {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[17];
	}

	set hours(v : Hours | null) {
        this.setterImpl(17, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days | null {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[18];
	}

	set days(v : Days | null) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months | null {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[19];
	}

	set months(v : Months | null) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years | null {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[20];
	}

	set years(v : Years | null) {
        this.setterImpl(20, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration | null {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[21];
	}

	set duration(v : Duration | null) {
        this.setterImpl(21, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime | null {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[22];
	}

	set localTime(v : LocalTime | null) {
        this.setterImpl(22, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {AiSwitchShowcaseApiCountry}
     */
	get enum() : AiSwitchShowcaseApiCountry | null {
		this.getterDebugValidations(this.attributeInfoEnum, false);
		return this.data[23];
	}

	set enum(v : AiSwitchShowcaseApiCountry | null) {
        this.setterImpl(23, v, 'enum', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Image}
     */
	get image() : Image | null {
		this.getterDebugValidations(this.attributeInfoImage, false);
		const path = this.data[24] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set image(v : Image | null) {
        this.setterImpl(24, v, 'image', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Pdf}
     */
	get pdf() : Pdf | null {
		this.getterDebugValidations(this.attributeInfoPdf, false);
		const path = this.data[25] as string;
		return (path && !path.startsWith('data:')) ? Config.API_FILE_BASE_URL + path : path;
	}

	set pdf(v : Pdf | null) {
        this.setterImpl(25, v, 'pdf', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent | null {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[26];
	}

	set percent(v : Percent | null) {
        this.setterImpl(26, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '107';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '107', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMin extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMin>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMin> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMin as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMin, AiSwitchShowcaseApiValidationsMin> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMin, AiSwitchShowcaseApiValidationsMin>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMin,
			name: 'min',
			nodeName: 'VALIDATIONS_MIN',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMin, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 11,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsMinValid = new AiSwitchShowcaseApiValidationsMinValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMin, );
	public attributeInfoValid = this.validWrapper.attributeInfoThis;

	/**
     * 
     */
	get valid() : AiSwitchShowcaseApiValidationsMinValid {
		this.getterDebugValidations(this.attributeInfoValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsMinValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, () => {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsMinFails = new AiSwitchShowcaseApiValidationsMinFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMin, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsMinFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMinFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '71';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '71', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMin | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValid, AiSwitchShowcaseApiValidationsMinValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValid, AiSwitchShowcaseApiValidationsMinValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_MIN_VALID',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMinValidCompareConst = new AiSwitchShowcaseApiValidationsMinValidCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMinValid, );
	public attributeInfoCompareConst = this.compareConstWrapper.attributeInfoThis;

	/**
     * 
     */
	get compareConst() : AiSwitchShowcaseApiValidationsMinValidCompareConst {
		this.getterDebugValidations(this.attributeInfoCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, () => {this.compareConstWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '159';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '159', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinValidCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinValidCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMinValid | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinValidCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinValidCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, AiSwitchShowcaseApiValidationsMinValidCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, AiSwitchShowcaseApiValidationsMinValidCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoNumberIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'numberIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoNumberThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'numberThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoNumberOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'numberOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoIntegerIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'integerIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoIntegerThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'integerThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoIntegerOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'integerOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoClientCurrencyIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'clientCurrencyIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoClientCurrencyThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'clientCurrencyThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoClientCurrencyOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'clientCurrencyOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoDateTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateTimeIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(1577833200000, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDateTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateTimeThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(1577833200000, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	attributeInfoDateTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoDateIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(1577833200000, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	attributeInfoDateThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(1577833200000, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoDateOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	attributeInfoDateExclusiveEndIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateExclusiveEndIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(1577833200000, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoDateExclusiveEndThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateExclusiveEndThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(1577833200000, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	attributeInfoDateExclusiveEndOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'dateExclusiveEndOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMinutesIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'minutesIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	attributeInfoMinutesThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'minutesThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoMinutesOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'minutesOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	attributeInfoHoursIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'hoursIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoHoursThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'hoursThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	attributeInfoHoursOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'hoursOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoDaysIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'daysIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	attributeInfoDaysThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'daysThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	attributeInfoDaysOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'daysOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	attributeInfoMonthsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'monthsIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});
	attributeInfoMonthsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'monthsThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	attributeInfoMonthsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'monthsOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 30,
		});
	attributeInfoYearsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'yearsIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 31,
		});
	attributeInfoYearsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'yearsThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 32,
		});
	attributeInfoYearsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'yearsOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 33,
		});
	attributeInfoDurationIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'durationIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 34,
		});
	attributeInfoDurationThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'durationThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 35,
		});
	attributeInfoDurationOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'durationOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 36,
		});
	attributeInfoLocalTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'localTimeIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(3600000, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 37,
		});
	attributeInfoLocalTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'localTimeThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(3600000, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 38,
		});
	attributeInfoLocalTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'localTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 39,
		});
	attributeInfoPercentIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'percentIsEqual',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(0.5, true, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 40,
		});
	attributeInfoPercentThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'percentThisIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(0.7, true, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 41,
		});
	attributeInfoPercentOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinValidCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinValidCompareConst,
			name: 'percentOtherIsNull',
			nodeName: 'VALIDATIONS_MIN_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 42,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get numberIsEqual() : number {
		this.getterDebugValidations(this.attributeInfoNumberIsEqual, false);
		return this.data[1];
	}

	set numberIsEqual(v : number) {
        this.setterImpl(1, v, 'numberIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get numberThisIsNull() : number | null {
		this.getterDebugValidations(this.attributeInfoNumberThisIsNull, false);
		return this.data[2];
	}

	set numberThisIsNull(v : number | null) {
        this.setterImpl(2, v, 'numberThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get numberOtherIsNull() : number {
		this.getterDebugValidations(this.attributeInfoNumberOtherIsNull, false);
		return this.data[3];
	}

	set numberOtherIsNull(v : number) {
        this.setterImpl(3, v, 'numberOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerIsEqual() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerIsEqual, false);
		return this.data[4];
	}

	set integerIsEqual(v : Integer) {
        this.setterImpl(4, v, 'integerIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerThisIsNull() : Integer | null {
		this.getterDebugValidations(this.attributeInfoIntegerThisIsNull, false);
		return this.data[5];
	}

	set integerThisIsNull(v : Integer | null) {
        this.setterImpl(5, v, 'integerThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerOtherIsNull() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerOtherIsNull, false);
		return this.data[6];
	}

	set integerOtherIsNull(v : Integer) {
        this.setterImpl(6, v, 'integerOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyIsEqual() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyIsEqual, false);
		return this.data[7];
	}

	set clientCurrencyIsEqual(v : ClientCurrency) {
        this.setterImpl(7, v, 'clientCurrencyIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyThisIsNull() : ClientCurrency | null {
		this.getterDebugValidations(this.attributeInfoClientCurrencyThisIsNull, false);
		return this.data[8];
	}

	set clientCurrencyThisIsNull(v : ClientCurrency | null) {
        this.setterImpl(8, v, 'clientCurrencyThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyOtherIsNull() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyOtherIsNull, false);
		return this.data[9];
	}

	set clientCurrencyOtherIsNull(v : ClientCurrency) {
        this.setterImpl(9, v, 'clientCurrencyOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeIsEqual() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeIsEqual, false);
		return this.data[10];
	}

	set dateTimeIsEqual(v : DateTime) {
        this.setterImpl(10, v, 'dateTimeIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeThisIsNull() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoDateTimeThisIsNull, false);
		return this.data[11];
	}

	set dateTimeThisIsNull(v : DateTime | null) {
        this.setterImpl(11, v, 'dateTimeThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeOtherIsNull() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeOtherIsNull, false);
		return this.data[12];
	}

	set dateTimeOtherIsNull(v : DateTime) {
        this.setterImpl(12, v, 'dateTimeOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateIsEqual() : Date {
		this.getterDebugValidations(this.attributeInfoDateIsEqual, false);
		return this.data[13];
	}

	set dateIsEqual(v : Date) {
        this.setterImpl(13, v, 'dateIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateThisIsNull() : Date | null {
		this.getterDebugValidations(this.attributeInfoDateThisIsNull, false);
		return this.data[14];
	}

	set dateThisIsNull(v : Date | null) {
        this.setterImpl(14, v, 'dateThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateOtherIsNull() : Date {
		this.getterDebugValidations(this.attributeInfoDateOtherIsNull, false);
		return this.data[15];
	}

	set dateOtherIsNull(v : Date) {
        this.setterImpl(15, v, 'dateOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndIsEqual() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndIsEqual, false);
		return this.data[16];
	}

	set dateExclusiveEndIsEqual(v : DateExclusiveEnd) {
        this.setterImpl(16, v, 'dateExclusiveEndIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndThisIsNull() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndThisIsNull, false);
		return this.data[17];
	}

	set dateExclusiveEndThisIsNull(v : DateExclusiveEnd | null) {
        this.setterImpl(17, v, 'dateExclusiveEndThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndOtherIsNull() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndOtherIsNull, false);
		return this.data[18];
	}

	set dateExclusiveEndOtherIsNull(v : DateExclusiveEnd) {
        this.setterImpl(18, v, 'dateExclusiveEndOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesIsEqual() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesIsEqual, false);
		return this.data[19];
	}

	set minutesIsEqual(v : Minutes) {
        this.setterImpl(19, v, 'minutesIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesThisIsNull() : Minutes | null {
		this.getterDebugValidations(this.attributeInfoMinutesThisIsNull, false);
		return this.data[20];
	}

	set minutesThisIsNull(v : Minutes | null) {
        this.setterImpl(20, v, 'minutesThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesOtherIsNull() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesOtherIsNull, false);
		return this.data[21];
	}

	set minutesOtherIsNull(v : Minutes) {
        this.setterImpl(21, v, 'minutesOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursIsEqual() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursIsEqual, false);
		return this.data[22];
	}

	set hoursIsEqual(v : Hours) {
        this.setterImpl(22, v, 'hoursIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursThisIsNull() : Hours | null {
		this.getterDebugValidations(this.attributeInfoHoursThisIsNull, false);
		return this.data[23];
	}

	set hoursThisIsNull(v : Hours | null) {
        this.setterImpl(23, v, 'hoursThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursOtherIsNull() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursOtherIsNull, false);
		return this.data[24];
	}

	set hoursOtherIsNull(v : Hours) {
        this.setterImpl(24, v, 'hoursOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysIsEqual() : Days {
		this.getterDebugValidations(this.attributeInfoDaysIsEqual, false);
		return this.data[25];
	}

	set daysIsEqual(v : Days) {
        this.setterImpl(25, v, 'daysIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysThisIsNull() : Days | null {
		this.getterDebugValidations(this.attributeInfoDaysThisIsNull, false);
		return this.data[26];
	}

	set daysThisIsNull(v : Days | null) {
        this.setterImpl(26, v, 'daysThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysOtherIsNull() : Days {
		this.getterDebugValidations(this.attributeInfoDaysOtherIsNull, false);
		return this.data[27];
	}

	set daysOtherIsNull(v : Days) {
        this.setterImpl(27, v, 'daysOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsIsEqual() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsIsEqual, false);
		return this.data[28];
	}

	set monthsIsEqual(v : Months) {
        this.setterImpl(28, v, 'monthsIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsThisIsNull() : Months | null {
		this.getterDebugValidations(this.attributeInfoMonthsThisIsNull, false);
		return this.data[29];
	}

	set monthsThisIsNull(v : Months | null) {
        this.setterImpl(29, v, 'monthsThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsOtherIsNull() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsOtherIsNull, false);
		return this.data[30];
	}

	set monthsOtherIsNull(v : Months) {
        this.setterImpl(30, v, 'monthsOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsIsEqual() : Years {
		this.getterDebugValidations(this.attributeInfoYearsIsEqual, false);
		return this.data[31];
	}

	set yearsIsEqual(v : Years) {
        this.setterImpl(31, v, 'yearsIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsThisIsNull() : Years | null {
		this.getterDebugValidations(this.attributeInfoYearsThisIsNull, false);
		return this.data[32];
	}

	set yearsThisIsNull(v : Years | null) {
        this.setterImpl(32, v, 'yearsThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsOtherIsNull() : Years {
		this.getterDebugValidations(this.attributeInfoYearsOtherIsNull, false);
		return this.data[33];
	}

	set yearsOtherIsNull(v : Years) {
        this.setterImpl(33, v, 'yearsOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationIsEqual() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationIsEqual, false);
		return this.data[34];
	}

	set durationIsEqual(v : Duration) {
        this.setterImpl(34, v, 'durationIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationThisIsNull() : Duration | null {
		this.getterDebugValidations(this.attributeInfoDurationThisIsNull, false);
		return this.data[35];
	}

	set durationThisIsNull(v : Duration | null) {
        this.setterImpl(35, v, 'durationThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationOtherIsNull() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationOtherIsNull, false);
		return this.data[36];
	}

	set durationOtherIsNull(v : Duration) {
        this.setterImpl(36, v, 'durationOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeIsEqual() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeIsEqual, false);
		return this.data[37];
	}

	set localTimeIsEqual(v : LocalTime) {
        this.setterImpl(37, v, 'localTimeIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeThisIsNull() : LocalTime | null {
		this.getterDebugValidations(this.attributeInfoLocalTimeThisIsNull, false);
		return this.data[38];
	}

	set localTimeThisIsNull(v : LocalTime | null) {
        this.setterImpl(38, v, 'localTimeThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeOtherIsNull() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeOtherIsNull, false);
		return this.data[39];
	}

	set localTimeOtherIsNull(v : LocalTime) {
        this.setterImpl(39, v, 'localTimeOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentIsEqual() : Percent {
		this.getterDebugValidations(this.attributeInfoPercentIsEqual, false);
		return this.data[40];
	}

	set percentIsEqual(v : Percent) {
        this.setterImpl(40, v, 'percentIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentThisIsNull() : Percent | null {
		this.getterDebugValidations(this.attributeInfoPercentThisIsNull, false);
		return this.data[41];
	}

	set percentThisIsNull(v : Percent | null) {
        this.setterImpl(41, v, 'percentThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentOtherIsNull() : Percent {
		this.getterDebugValidations(this.attributeInfoPercentOtherIsNull, false);
		return this.data[42];
	}

	set percentOtherIsNull(v : Percent) {
        this.setterImpl(42, v, 'percentOtherIsNull', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '161';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '161', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMin | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFails, AiSwitchShowcaseApiValidationsMinFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFails, AiSwitchShowcaseApiValidationsMinFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MIN_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMinFailsCompareConst = new AiSwitchShowcaseApiValidationsMinFailsCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMinFails, );
	public attributeInfoCompareConst = this.compareConstWrapper.attributeInfoThis;

	/**
     * 
     */
	get compareConst() : AiSwitchShowcaseApiValidationsMinFailsCompareConst {
		this.getterDebugValidations(this.attributeInfoCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, () => {this.compareConstWrapper = v;});
	}

	private compareNodeWrapper : AiSwitchShowcaseApiValidationsMinFailsCompareNode = new AiSwitchShowcaseApiValidationsMinFailsCompareNode(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMinFails, );
	public attributeInfoCompareNode = this.compareNodeWrapper.attributeInfoThis;

	/**
     * 
     */
	get compareNode() : AiSwitchShowcaseApiValidationsMinFailsCompareNode {
		this.getterDebugValidations(this.attributeInfoCompareNode, false);
		return this.compareNodeWrapper;
	}

	set compareNodeTestSetter(v : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
        this.setterImpl(2, v.rawData, 'compareNode', true, null, () => {this.compareNodeWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
		this.compareNodeWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.compareNodeWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '160';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '160', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinFailsCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinFailsCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMinFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinFailsCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinFailsCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, AiSwitchShowcaseApiValidationsMinFailsCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, AiSwitchShowcaseApiValidationsMinFailsCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'number',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7.5, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(3, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoNumberEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'numberEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, false, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'integer',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoIntegerEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'integerEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, false, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoClientCurrencyEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'clientCurrencyEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, false, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(1605135600000, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoDateTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(1605135600000, false, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'date',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(1605135600000, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoDateEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(1605135600000, false, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(1605135600000, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	attributeInfoDateExclusiveEndEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'dateExclusiveEndEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(1605135600000, false, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	attributeInfoMinutesEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'minutesEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, false, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'hours',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	attributeInfoHoursEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'hoursEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, false, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'days',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	attributeInfoDaysEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'daysEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, false, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'months',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	attributeInfoMonthsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'monthsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, false, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'years',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	attributeInfoYearsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'yearsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, false, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'duration',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(604800000, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	attributeInfoDurationEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'durationEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(7, false, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(3600000, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	attributeInfoLocalTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'localTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(3600000, false, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'percent',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(0.7, true, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	attributeInfoPercentEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareConst,
			name: 'percentEqualNotAllowed',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
					() => {
return this.api!.validators.min(0.5, false, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[1];
	}

	set number(v : number) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get numberEqualNotAllowed() : number {
		this.getterDebugValidations(this.attributeInfoNumberEqualNotAllowed, false);
		return this.data[2];
	}

	set numberEqualNotAllowed(v : number) {
        this.setterImpl(2, v, 'numberEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[3];
	}

	set integer(v : Integer) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerEqualNotAllowed() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerEqualNotAllowed, false);
		return this.data[4];
	}

	set integerEqualNotAllowed(v : Integer) {
        this.setterImpl(4, v, 'integerEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[5];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(5, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyEqualNotAllowed() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyEqualNotAllowed, false);
		return this.data[6];
	}

	set clientCurrencyEqualNotAllowed(v : ClientCurrency) {
        this.setterImpl(6, v, 'clientCurrencyEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[7];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(7, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeEqualNotAllowed() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeEqualNotAllowed, false);
		return this.data[8];
	}

	set dateTimeEqualNotAllowed(v : DateTime) {
        this.setterImpl(8, v, 'dateTimeEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[9];
	}

	set date(v : Date) {
        this.setterImpl(9, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateEqualNotAllowed() : Date {
		this.getterDebugValidations(this.attributeInfoDateEqualNotAllowed, false);
		return this.data[10];
	}

	set dateEqualNotAllowed(v : Date) {
        this.setterImpl(10, v, 'dateEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[11];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndEqualNotAllowed() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndEqualNotAllowed, false);
		return this.data[12];
	}

	set dateExclusiveEndEqualNotAllowed(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEndEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[13];
	}

	set minutes(v : Minutes) {
        this.setterImpl(13, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesEqualNotAllowed() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesEqualNotAllowed, false);
		return this.data[14];
	}

	set minutesEqualNotAllowed(v : Minutes) {
        this.setterImpl(14, v, 'minutesEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[15];
	}

	set hours(v : Hours) {
        this.setterImpl(15, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursEqualNotAllowed() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursEqualNotAllowed, false);
		return this.data[16];
	}

	set hoursEqualNotAllowed(v : Hours) {
        this.setterImpl(16, v, 'hoursEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[17];
	}

	set days(v : Days) {
        this.setterImpl(17, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysEqualNotAllowed() : Days {
		this.getterDebugValidations(this.attributeInfoDaysEqualNotAllowed, false);
		return this.data[18];
	}

	set daysEqualNotAllowed(v : Days) {
        this.setterImpl(18, v, 'daysEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[19];
	}

	set months(v : Months) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsEqualNotAllowed() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsEqualNotAllowed, false);
		return this.data[20];
	}

	set monthsEqualNotAllowed(v : Months) {
        this.setterImpl(20, v, 'monthsEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[21];
	}

	set years(v : Years) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsEqualNotAllowed() : Years {
		this.getterDebugValidations(this.attributeInfoYearsEqualNotAllowed, false);
		return this.data[22];
	}

	set yearsEqualNotAllowed(v : Years) {
        this.setterImpl(22, v, 'yearsEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[23];
	}

	set duration(v : Duration) {
        this.setterImpl(23, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationEqualNotAllowed() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationEqualNotAllowed, false);
		return this.data[24];
	}

	set durationEqualNotAllowed(v : Duration) {
        this.setterImpl(24, v, 'durationEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[25];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(25, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeEqualNotAllowed() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeEqualNotAllowed, false);
		return this.data[26];
	}

	set localTimeEqualNotAllowed(v : LocalTime) {
        this.setterImpl(26, v, 'localTimeEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[27];
	}

	set percent(v : Percent) {
        this.setterImpl(27, v, 'percent', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentEqualNotAllowed() : Percent {
		this.getterDebugValidations(this.attributeInfoPercentEqualNotAllowed, false);
		return this.data[28];
	}

	set percentEqualNotAllowed(v : Percent) {
        this.setterImpl(28, v, 'percentEqualNotAllowed', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '204';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '204', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMinFailsCompareNode extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMinFailsCompareNode>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMinFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMinFailsCompareNode> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMinFailsCompareNode as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, AiSwitchShowcaseApiValidationsMinFailsCompareNode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, AiSwitchShowcaseApiValidationsMinFailsCompareNode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'compareNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	attributeInfoNumberComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'numberComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_B',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'number',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_NUMBER_A',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.numberComparedNode, true, PApiPrimitiveTypes.number, this.attributeInfoNumberComparedNode.id, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(0, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoIntegerComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'integerComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_B',
			primitiveType: PApiPrimitiveTypes.Integer,
			rawDataIndex: 3,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'integer',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_INTEGER_A',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.integerComparedNode, true, PApiPrimitiveTypes.Integer, this.attributeInfoIntegerComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoClientCurrencyComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'clientCurrencyComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			rawDataIndex: 5,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.clientCurrencyComparedNode, true, PApiPrimitiveTypes.ClientCurrency, this.attributeInfoClientCurrencyComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoDateTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateTimeComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_B',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 7,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_TIME_A',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.dateTimeComparedNode, true, PApiPrimitiveTypes.DateTime, this.attributeInfoDateTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoDateComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B',
			primitiveType: PApiPrimitiveTypes.Date,
			rawDataIndex: 9,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'date',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.dateComparedNode, true, PApiPrimitiveTypes.Date, this.attributeInfoDateComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDateExclusiveEndComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateExclusiveEndComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			rawDataIndex: 11,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.dateExclusiveEndComparedNode, true, PApiPrimitiveTypes.DateExclusiveEnd, this.attributeInfoDateExclusiveEndComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoMinutesComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'minutesComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_B',
			primitiveType: PApiPrimitiveTypes.Minutes,
			rawDataIndex: 13,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MINUTES_A',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.minutesComparedNode, true, PApiPrimitiveTypes.Minutes, this.attributeInfoMinutesComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoHoursComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'hoursComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_B',
			primitiveType: PApiPrimitiveTypes.Hours,
			rawDataIndex: 15,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'hours',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_HOURS_A',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.hoursComparedNode, true, PApiPrimitiveTypes.Hours, this.attributeInfoHoursComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoDaysComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'daysComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_B',
			primitiveType: PApiPrimitiveTypes.Days,
			rawDataIndex: 17,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'days',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DAYS_A',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.daysComparedNode, true, PApiPrimitiveTypes.Days, this.attributeInfoDaysComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMonthsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'monthsComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_B',
			primitiveType: PApiPrimitiveTypes.Months,
			rawDataIndex: 19,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'months',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_MONTHS_A',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.monthsComparedNode, true, PApiPrimitiveTypes.Months, this.attributeInfoMonthsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoYearsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'yearsComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_B',
			primitiveType: PApiPrimitiveTypes.Years,
			rawDataIndex: 21,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'years',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_YEARS_A',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.yearsComparedNode, true, PApiPrimitiveTypes.Years, this.attributeInfoYearsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoDurationComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'durationComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_B',
			primitiveType: PApiPrimitiveTypes.Duration,
			rawDataIndex: 23,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'duration',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_DURATION_A',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.durationComparedNode, true, PApiPrimitiveTypes.Duration, this.attributeInfoDurationComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoLocalTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'localTimeComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_B',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			rawDataIndex: 25,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_LOCAL_TIME_A',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.localTimeComparedNode, true, PApiPrimitiveTypes.LocalTime, this.attributeInfoLocalTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	attributeInfoPercentComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'percentComparedNode',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_B',
			primitiveType: PApiPrimitiveTypes.Percent,
			rawDataIndex: 27,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMinFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMinFailsCompareNode,
			name: 'percent',
			nodeName: 'VALIDATIONS_MIN_FAILS_COMPARE_NODE_PERCENT_A',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
					() => {
return this.api!.validators.min(() => this.percentComparedNode, true, PApiPrimitiveTypes.Percent, this.attributeInfoPercentComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMinFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get numberComparedNode() : number {
		this.getterDebugValidations(this.attributeInfoNumberComparedNode, false);
		return this.data[1];
	}

	set numberComparedNode(v : number) {
        this.setterImpl(1, v, 'numberComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[2];
	}

	set number(v : number) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerComparedNode() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerComparedNode, false);
		return this.data[3];
	}

	set integerComparedNode(v : Integer) {
        this.setterImpl(3, v, 'integerComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[4];
	}

	set integer(v : Integer) {
        this.setterImpl(4, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyComparedNode() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyComparedNode, false);
		return this.data[5];
	}

	set clientCurrencyComparedNode(v : ClientCurrency) {
        this.setterImpl(5, v, 'clientCurrencyComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[6];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(6, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeComparedNode() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeComparedNode, false);
		return this.data[7];
	}

	set dateTimeComparedNode(v : DateTime) {
        this.setterImpl(7, v, 'dateTimeComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[8];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(8, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateComparedNode() : Date {
		this.getterDebugValidations(this.attributeInfoDateComparedNode, false);
		return this.data[9];
	}

	set dateComparedNode(v : Date) {
        this.setterImpl(9, v, 'dateComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[10];
	}

	set date(v : Date) {
        this.setterImpl(10, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndComparedNode() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndComparedNode, false);
		return this.data[11];
	}

	set dateExclusiveEndComparedNode(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEndComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[12];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesComparedNode() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesComparedNode, false);
		return this.data[13];
	}

	set minutesComparedNode(v : Minutes) {
        this.setterImpl(13, v, 'minutesComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[14];
	}

	set minutes(v : Minutes) {
        this.setterImpl(14, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursComparedNode() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursComparedNode, false);
		return this.data[15];
	}

	set hoursComparedNode(v : Hours) {
        this.setterImpl(15, v, 'hoursComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[16];
	}

	set hours(v : Hours) {
        this.setterImpl(16, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysComparedNode() : Days {
		this.getterDebugValidations(this.attributeInfoDaysComparedNode, false);
		return this.data[17];
	}

	set daysComparedNode(v : Days) {
        this.setterImpl(17, v, 'daysComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[18];
	}

	set days(v : Days) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsComparedNode() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsComparedNode, false);
		return this.data[19];
	}

	set monthsComparedNode(v : Months) {
        this.setterImpl(19, v, 'monthsComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[20];
	}

	set months(v : Months) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsComparedNode() : Years {
		this.getterDebugValidations(this.attributeInfoYearsComparedNode, false);
		return this.data[21];
	}

	set yearsComparedNode(v : Years) {
        this.setterImpl(21, v, 'yearsComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[22];
	}

	set years(v : Years) {
        this.setterImpl(22, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationComparedNode() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationComparedNode, false);
		return this.data[23];
	}

	set durationComparedNode(v : Duration) {
        this.setterImpl(23, v, 'durationComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[24];
	}

	set duration(v : Duration) {
        this.setterImpl(24, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeComparedNode() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeComparedNode, false);
		return this.data[25];
	}

	set localTimeComparedNode(v : LocalTime) {
        this.setterImpl(25, v, 'localTimeComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[26];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(26, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentComparedNode() : Percent {
		this.getterDebugValidations(this.attributeInfoPercentComparedNode, false);
		return this.data[27];
	}

	set percentComparedNode(v : Percent) {
        this.setterImpl(27, v, 'percentComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '205';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '205', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMax extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMax>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMax> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMax as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMax, AiSwitchShowcaseApiValidationsMax> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMax, AiSwitchShowcaseApiValidationsMax>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMax,
			name: 'max',
			nodeName: 'VALIDATIONS_MAX',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMax, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 12,
		});

	private validWrapper : AiSwitchShowcaseApiValidationsMaxValid = new AiSwitchShowcaseApiValidationsMaxValid(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMax, );
	public attributeInfoValid = this.validWrapper.attributeInfoThis;

	/**
     * 
     */
	get valid() : AiSwitchShowcaseApiValidationsMaxValid {
		this.getterDebugValidations(this.attributeInfoValid, false);
		return this.validWrapper;
	}

	set validTestSetter(v : AiSwitchShowcaseApiValidationsMaxValid) {
        this.setterImpl(1, v.rawData, 'valid', true, null, () => {this.validWrapper = v;});
	}

	private failsWrapper : AiSwitchShowcaseApiValidationsMaxFails = new AiSwitchShowcaseApiValidationsMaxFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMax, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsMaxFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMaxFails) {
        this.setterImpl(2, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.validWrapper._fixIds(_idReplacements);
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.validWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.failsWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '72';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '72', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxValid extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxValid>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMax | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxValid> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxValid as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValid, AiSwitchShowcaseApiValidationsMaxValid> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValid, AiSwitchShowcaseApiValidationsMaxValid>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValid,
			name: 'valid',
			nodeName: 'VALIDATIONS_MAX_VALID',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxValid, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMaxValidCompareConst = new AiSwitchShowcaseApiValidationsMaxValidCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxValid, );
	public attributeInfoCompareConst = this.compareConstWrapper.attributeInfoThis;

	/**
     * 
     */
	get compareConst() : AiSwitchShowcaseApiValidationsMaxValidCompareConst {
		this.getterDebugValidations(this.attributeInfoCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, () => {this.compareConstWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '262';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '262', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxValidCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxValidCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxValid | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxValidCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxValidCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, AiSwitchShowcaseApiValidationsMaxValidCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, AiSwitchShowcaseApiValidationsMaxValidCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoNumberIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'numberIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoNumberThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'numberThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoNumberOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'numberOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_NUMBER_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoIntegerIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'integerIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoIntegerThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'integerThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoIntegerOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'integerOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_INTEGER_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoClientCurrencyIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'clientCurrencyIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoClientCurrencyThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'clientCurrencyThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(5, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoClientCurrencyOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'clientCurrencyOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_CLIENT_CURRENCY_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.min(null, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoDateTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateTimeIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(1577919600000, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDateTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateTimeThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(1577919600000, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	attributeInfoDateTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_TIME_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoDateIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(1577919600000, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	attributeInfoDateThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(1577919600000, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoDateOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	attributeInfoDateExclusiveEndIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateExclusiveEndIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(1577919600000, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoDateExclusiveEndThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateExclusiveEndThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(1577919600000, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	attributeInfoDateExclusiveEndOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'dateExclusiveEndOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DATE_EXCLUSIVE_END_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMinutesIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'minutesIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	attributeInfoMinutesThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'minutesThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoMinutesOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'minutesOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MINUTES_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	attributeInfoHoursIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'hoursIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoHoursThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'hoursThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	attributeInfoHoursOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'hoursOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_HOURS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoDaysIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'daysIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	attributeInfoDaysThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'daysThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	attributeInfoDaysOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'daysOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DAYS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	attributeInfoMonthsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'monthsIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});
	attributeInfoMonthsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'monthsThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 29,
		});
	attributeInfoMonthsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'monthsOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_MONTHS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 30,
		});
	attributeInfoYearsIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'yearsIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 31,
		});
	attributeInfoYearsThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'yearsThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 32,
		});
	attributeInfoYearsOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'yearsOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_YEARS_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 33,
		});
	attributeInfoDurationIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'durationIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 34,
		});
	attributeInfoDurationThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'durationThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(5, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 35,
		});
	attributeInfoDurationOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'durationOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_DURATION_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 36,
		});
	attributeInfoLocalTimeIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'localTimeIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(3600000, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 37,
		});
	attributeInfoLocalTimeThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'localTimeThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(3600000, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 38,
		});
	attributeInfoLocalTimeOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'localTimeOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_LOCAL_TIME_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 39,
		});
	attributeInfoPercentIsEqual : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'percentIsEqual',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_IS_EQUAL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(0.5, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 40,
		});
	attributeInfoPercentThisIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'percentThisIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_THIS_IS_NULL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(0.5, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 41,
		});
	attributeInfoPercentOtherIsNull : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxValidCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxValidCompareConst,
			name: 'percentOtherIsNull',
			nodeName: 'VALIDATIONS_MAX_VALID_COMPARE_CONST_PERCENT_OTHER_IS_NULL',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
					() => {
return this.api!.validators.max(null, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxValidCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 42,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get numberIsEqual() : number {
		this.getterDebugValidations(this.attributeInfoNumberIsEqual, false);
		return this.data[1];
	}

	set numberIsEqual(v : number) {
        this.setterImpl(1, v, 'numberIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get numberThisIsNull() : number | null {
		this.getterDebugValidations(this.attributeInfoNumberThisIsNull, false);
		return this.data[2];
	}

	set numberThisIsNull(v : number | null) {
        this.setterImpl(2, v, 'numberThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get numberOtherIsNull() : number {
		this.getterDebugValidations(this.attributeInfoNumberOtherIsNull, false);
		return this.data[3];
	}

	set numberOtherIsNull(v : number) {
        this.setterImpl(3, v, 'numberOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerIsEqual() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerIsEqual, false);
		return this.data[4];
	}

	set integerIsEqual(v : Integer) {
        this.setterImpl(4, v, 'integerIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerThisIsNull() : Integer | null {
		this.getterDebugValidations(this.attributeInfoIntegerThisIsNull, false);
		return this.data[5];
	}

	set integerThisIsNull(v : Integer | null) {
        this.setterImpl(5, v, 'integerThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerOtherIsNull() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerOtherIsNull, false);
		return this.data[6];
	}

	set integerOtherIsNull(v : Integer) {
        this.setterImpl(6, v, 'integerOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyIsEqual() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyIsEqual, false);
		return this.data[7];
	}

	set clientCurrencyIsEqual(v : ClientCurrency) {
        this.setterImpl(7, v, 'clientCurrencyIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyThisIsNull() : ClientCurrency | null {
		this.getterDebugValidations(this.attributeInfoClientCurrencyThisIsNull, false);
		return this.data[8];
	}

	set clientCurrencyThisIsNull(v : ClientCurrency | null) {
        this.setterImpl(8, v, 'clientCurrencyThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyOtherIsNull() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyOtherIsNull, false);
		return this.data[9];
	}

	set clientCurrencyOtherIsNull(v : ClientCurrency) {
        this.setterImpl(9, v, 'clientCurrencyOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeIsEqual() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeIsEqual, false);
		return this.data[10];
	}

	set dateTimeIsEqual(v : DateTime) {
        this.setterImpl(10, v, 'dateTimeIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeThisIsNull() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoDateTimeThisIsNull, false);
		return this.data[11];
	}

	set dateTimeThisIsNull(v : DateTime | null) {
        this.setterImpl(11, v, 'dateTimeThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeOtherIsNull() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeOtherIsNull, false);
		return this.data[12];
	}

	set dateTimeOtherIsNull(v : DateTime) {
        this.setterImpl(12, v, 'dateTimeOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateIsEqual() : Date {
		this.getterDebugValidations(this.attributeInfoDateIsEqual, false);
		return this.data[13];
	}

	set dateIsEqual(v : Date) {
        this.setterImpl(13, v, 'dateIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateThisIsNull() : Date | null {
		this.getterDebugValidations(this.attributeInfoDateThisIsNull, false);
		return this.data[14];
	}

	set dateThisIsNull(v : Date | null) {
        this.setterImpl(14, v, 'dateThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateOtherIsNull() : Date {
		this.getterDebugValidations(this.attributeInfoDateOtherIsNull, false);
		return this.data[15];
	}

	set dateOtherIsNull(v : Date) {
        this.setterImpl(15, v, 'dateOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndIsEqual() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndIsEqual, false);
		return this.data[16];
	}

	set dateExclusiveEndIsEqual(v : DateExclusiveEnd) {
        this.setterImpl(16, v, 'dateExclusiveEndIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndThisIsNull() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndThisIsNull, false);
		return this.data[17];
	}

	set dateExclusiveEndThisIsNull(v : DateExclusiveEnd | null) {
        this.setterImpl(17, v, 'dateExclusiveEndThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndOtherIsNull() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndOtherIsNull, false);
		return this.data[18];
	}

	set dateExclusiveEndOtherIsNull(v : DateExclusiveEnd) {
        this.setterImpl(18, v, 'dateExclusiveEndOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesIsEqual() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesIsEqual, false);
		return this.data[19];
	}

	set minutesIsEqual(v : Minutes) {
        this.setterImpl(19, v, 'minutesIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesThisIsNull() : Minutes | null {
		this.getterDebugValidations(this.attributeInfoMinutesThisIsNull, false);
		return this.data[20];
	}

	set minutesThisIsNull(v : Minutes | null) {
        this.setterImpl(20, v, 'minutesThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesOtherIsNull() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesOtherIsNull, false);
		return this.data[21];
	}

	set minutesOtherIsNull(v : Minutes) {
        this.setterImpl(21, v, 'minutesOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursIsEqual() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursIsEqual, false);
		return this.data[22];
	}

	set hoursIsEqual(v : Hours) {
        this.setterImpl(22, v, 'hoursIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursThisIsNull() : Hours | null {
		this.getterDebugValidations(this.attributeInfoHoursThisIsNull, false);
		return this.data[23];
	}

	set hoursThisIsNull(v : Hours | null) {
        this.setterImpl(23, v, 'hoursThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursOtherIsNull() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursOtherIsNull, false);
		return this.data[24];
	}

	set hoursOtherIsNull(v : Hours) {
        this.setterImpl(24, v, 'hoursOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysIsEqual() : Days {
		this.getterDebugValidations(this.attributeInfoDaysIsEqual, false);
		return this.data[25];
	}

	set daysIsEqual(v : Days) {
        this.setterImpl(25, v, 'daysIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysThisIsNull() : Days | null {
		this.getterDebugValidations(this.attributeInfoDaysThisIsNull, false);
		return this.data[26];
	}

	set daysThisIsNull(v : Days | null) {
        this.setterImpl(26, v, 'daysThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysOtherIsNull() : Days {
		this.getterDebugValidations(this.attributeInfoDaysOtherIsNull, false);
		return this.data[27];
	}

	set daysOtherIsNull(v : Days) {
        this.setterImpl(27, v, 'daysOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsIsEqual() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsIsEqual, false);
		return this.data[28];
	}

	set monthsIsEqual(v : Months) {
        this.setterImpl(28, v, 'monthsIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsThisIsNull() : Months | null {
		this.getterDebugValidations(this.attributeInfoMonthsThisIsNull, false);
		return this.data[29];
	}

	set monthsThisIsNull(v : Months | null) {
        this.setterImpl(29, v, 'monthsThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsOtherIsNull() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsOtherIsNull, false);
		return this.data[30];
	}

	set monthsOtherIsNull(v : Months) {
        this.setterImpl(30, v, 'monthsOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsIsEqual() : Years {
		this.getterDebugValidations(this.attributeInfoYearsIsEqual, false);
		return this.data[31];
	}

	set yearsIsEqual(v : Years) {
        this.setterImpl(31, v, 'yearsIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsThisIsNull() : Years | null {
		this.getterDebugValidations(this.attributeInfoYearsThisIsNull, false);
		return this.data[32];
	}

	set yearsThisIsNull(v : Years | null) {
        this.setterImpl(32, v, 'yearsThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsOtherIsNull() : Years {
		this.getterDebugValidations(this.attributeInfoYearsOtherIsNull, false);
		return this.data[33];
	}

	set yearsOtherIsNull(v : Years) {
        this.setterImpl(33, v, 'yearsOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationIsEqual() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationIsEqual, false);
		return this.data[34];
	}

	set durationIsEqual(v : Duration) {
        this.setterImpl(34, v, 'durationIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationThisIsNull() : Duration | null {
		this.getterDebugValidations(this.attributeInfoDurationThisIsNull, false);
		return this.data[35];
	}

	set durationThisIsNull(v : Duration | null) {
        this.setterImpl(35, v, 'durationThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationOtherIsNull() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationOtherIsNull, false);
		return this.data[36];
	}

	set durationOtherIsNull(v : Duration) {
        this.setterImpl(36, v, 'durationOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeIsEqual() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeIsEqual, false);
		return this.data[37];
	}

	set localTimeIsEqual(v : LocalTime) {
        this.setterImpl(37, v, 'localTimeIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeThisIsNull() : LocalTime | null {
		this.getterDebugValidations(this.attributeInfoLocalTimeThisIsNull, false);
		return this.data[38];
	}

	set localTimeThisIsNull(v : LocalTime | null) {
        this.setterImpl(38, v, 'localTimeThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeOtherIsNull() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeOtherIsNull, false);
		return this.data[39];
	}

	set localTimeOtherIsNull(v : LocalTime) {
        this.setterImpl(39, v, 'localTimeOtherIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get percentIsEqual() : LocalTime {
		this.getterDebugValidations(this.attributeInfoPercentIsEqual, false);
		return this.data[40];
	}

	set percentIsEqual(v : LocalTime) {
        this.setterImpl(40, v, 'percentIsEqual', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get percentThisIsNull() : LocalTime | null {
		this.getterDebugValidations(this.attributeInfoPercentThisIsNull, false);
		return this.data[41];
	}

	set percentThisIsNull(v : LocalTime | null) {
        this.setterImpl(41, v, 'percentThisIsNull', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get percentOtherIsNull() : LocalTime {
		this.getterDebugValidations(this.attributeInfoPercentOtherIsNull, false);
		return this.data[42];
	}

	set percentOtherIsNull(v : LocalTime) {
        this.setterImpl(42, v, 'percentOtherIsNull', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '264';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '264', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMax | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFails, AiSwitchShowcaseApiValidationsMaxFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFails, AiSwitchShowcaseApiValidationsMaxFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MAX_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});

	private compareConstWrapper : AiSwitchShowcaseApiValidationsMaxFailsCompareConst = new AiSwitchShowcaseApiValidationsMaxFailsCompareConst(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxFails, );
	public attributeInfoCompareConst = this.compareConstWrapper.attributeInfoThis;

	/**
     * 
     */
	get compareConst() : AiSwitchShowcaseApiValidationsMaxFailsCompareConst {
		this.getterDebugValidations(this.attributeInfoCompareConst, false);
		return this.compareConstWrapper;
	}

	set compareConstTestSetter(v : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
        this.setterImpl(1, v.rawData, 'compareConst', true, null, () => {this.compareConstWrapper = v;});
	}

	private compareNodeWrapper : AiSwitchShowcaseApiValidationsMaxFailsCompareNode = new AiSwitchShowcaseApiValidationsMaxFailsCompareNode(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxFails, );
	public attributeInfoCompareNode = this.compareNodeWrapper.attributeInfoThis;

	/**
     * 
     */
	get compareNode() : AiSwitchShowcaseApiValidationsMaxFailsCompareNode {
		this.getterDebugValidations(this.attributeInfoCompareNode, false);
		return this.compareNodeWrapper;
	}

	set compareNodeTestSetter(v : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
        this.setterImpl(2, v.rawData, 'compareNode', true, null, () => {this.compareNodeWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.compareConstWrapper._fixIds(_idReplacements);
		this.compareNodeWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.compareConstWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
		this.compareNodeWrapper._updateRawData(data && data[2] !== undefined ? data[2] : null, generateMissingData);
	}

	protected get dni() : string {
		return '263';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '263', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxFailsCompareConst extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxFailsCompareConst>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxFailsCompareConst> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxFailsCompareConst as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, AiSwitchShowcaseApiValidationsMaxFailsCompareConst> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, AiSwitchShowcaseApiValidationsMaxFailsCompareConst>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'compareConst',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'number',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7.5, true, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(3, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoNumberEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'numberEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_NUMBER_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7.5, false, PApiPrimitiveTypes.number, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'integer',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoIntegerEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'integerEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_INTEGER_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoClientCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'clientCurrency',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoClientCurrencyEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'clientCurrencyEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_CLIENT_CURRENCY_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.ClientCurrency, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(1605135600000, true, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoDateTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_TIME_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(1605135600000, false, PApiPrimitiveTypes.DateTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'date',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(1605135600000, true, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoDateEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_WITHOUT_TIME_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(1605135600000, false, PApiPrimitiveTypes.Date, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(1605135600000, true, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});
	attributeInfoDateExclusiveEndEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'dateExclusiveEndEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DATE_EXCLUSIVE_END_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(1605135600000, false, PApiPrimitiveTypes.DateExclusiveEnd, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 13,
		});
	attributeInfoMinutesEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'minutesEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MINUTES_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Minutes, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'hours',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 15,
		});
	attributeInfoHoursEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'hoursEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_HOURS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Hours, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'days',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 17,
		});
	attributeInfoDaysEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'daysEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DAYS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'months',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 19,
		});
	attributeInfoMonthsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'monthsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_MONTHS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Months, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'years',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, true, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 21,
		});
	attributeInfoYearsEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'yearsEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_YEARS_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Years, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'duration',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max((5 * 60 * 60 * 1000) + (30 * 60 * 1000), true, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 23,
		});
	attributeInfoDurationEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'durationEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_DURATION_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(7, false, PApiPrimitiveTypes.Duration, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(3600000, true, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 25,
		});
	attributeInfoLocalTimeEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'localTimeEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_LOCAL_TIME_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(3600000, false, PApiPrimitiveTypes.LocalTime, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'percent',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(0.5, true, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 27,
		});
	attributeInfoPercentEqualNotAllowed : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareConst, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareConst,
			name: 'percentEqualNotAllowed',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_CONST_PERCENT_EQUAL_NOT_ALLOWED',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
					() => {
return this.api!.validators.max(0.7, false, PApiPrimitiveTypes.Percent, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareConst) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[1];
	}

	set number(v : number) {
        this.setterImpl(1, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get numberEqualNotAllowed() : number {
		this.getterDebugValidations(this.attributeInfoNumberEqualNotAllowed, false);
		return this.data[2];
	}

	set numberEqualNotAllowed(v : number) {
        this.setterImpl(2, v, 'numberEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[3];
	}

	set integer(v : Integer) {
        this.setterImpl(3, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerEqualNotAllowed() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerEqualNotAllowed, false);
		return this.data[4];
	}

	set integerEqualNotAllowed(v : Integer) {
        this.setterImpl(4, v, 'integerEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrency() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrency, false);
		return this.data[5];
	}

	set clientCurrency(v : ClientCurrency) {
        this.setterImpl(5, v, 'clientCurrency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get clientCurrencyEqualNotAllowed() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoClientCurrencyEqualNotAllowed, false);
		return this.data[6];
	}

	set clientCurrencyEqualNotAllowed(v : ClientCurrency) {
        this.setterImpl(6, v, 'clientCurrencyEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[7];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(7, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeEqualNotAllowed() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeEqualNotAllowed, false);
		return this.data[8];
	}

	set dateTimeEqualNotAllowed(v : DateTime) {
        this.setterImpl(8, v, 'dateTimeEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[9];
	}

	set date(v : Date) {
        this.setterImpl(9, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateEqualNotAllowed() : Date {
		this.getterDebugValidations(this.attributeInfoDateEqualNotAllowed, false);
		return this.data[10];
	}

	set dateEqualNotAllowed(v : Date) {
        this.setterImpl(10, v, 'dateEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[11];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndEqualNotAllowed() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndEqualNotAllowed, false);
		return this.data[12];
	}

	set dateExclusiveEndEqualNotAllowed(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEndEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[13];
	}

	set minutes(v : Minutes) {
        this.setterImpl(13, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesEqualNotAllowed() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesEqualNotAllowed, false);
		return this.data[14];
	}

	set minutesEqualNotAllowed(v : Minutes) {
        this.setterImpl(14, v, 'minutesEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[15];
	}

	set hours(v : Hours) {
        this.setterImpl(15, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursEqualNotAllowed() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursEqualNotAllowed, false);
		return this.data[16];
	}

	set hoursEqualNotAllowed(v : Hours) {
        this.setterImpl(16, v, 'hoursEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[17];
	}

	set days(v : Days) {
        this.setterImpl(17, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysEqualNotAllowed() : Days {
		this.getterDebugValidations(this.attributeInfoDaysEqualNotAllowed, false);
		return this.data[18];
	}

	set daysEqualNotAllowed(v : Days) {
        this.setterImpl(18, v, 'daysEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[19];
	}

	set months(v : Months) {
        this.setterImpl(19, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsEqualNotAllowed() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsEqualNotAllowed, false);
		return this.data[20];
	}

	set monthsEqualNotAllowed(v : Months) {
        this.setterImpl(20, v, 'monthsEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[21];
	}

	set years(v : Years) {
        this.setterImpl(21, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsEqualNotAllowed() : Years {
		this.getterDebugValidations(this.attributeInfoYearsEqualNotAllowed, false);
		return this.data[22];
	}

	set yearsEqualNotAllowed(v : Years) {
        this.setterImpl(22, v, 'yearsEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[23];
	}

	set duration(v : Duration) {
        this.setterImpl(23, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationEqualNotAllowed() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationEqualNotAllowed, false);
		return this.data[24];
	}

	set durationEqualNotAllowed(v : Duration) {
        this.setterImpl(24, v, 'durationEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[25];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(25, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeEqualNotAllowed() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeEqualNotAllowed, false);
		return this.data[26];
	}

	set localTimeEqualNotAllowed(v : LocalTime) {
        this.setterImpl(26, v, 'localTimeEqualNotAllowed', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[27];
	}

	set percent(v : Percent) {
        this.setterImpl(27, v, 'percent', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentEqualNotAllowed() : Percent {
		this.getterDebugValidations(this.attributeInfoPercentEqualNotAllowed, false);
		return this.data[28];
	}

	set percentEqualNotAllowed(v : Percent) {
        this.setterImpl(28, v, 'percentEqualNotAllowed', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '307';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '307', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxFailsCompareNode extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxFailsCompareNode>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxFails | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxFailsCompareNode> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxFailsCompareNode as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, AiSwitchShowcaseApiValidationsMaxFailsCompareNode> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, AiSwitchShowcaseApiValidationsMaxFailsCompareNode>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'compareNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 2,
		});
	attributeInfoNumberComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'numberComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_B',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.maxDecimalPlacesCount(1, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoNumber : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'number',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_NUMBER_A',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.numberComparedNode, true, PApiPrimitiveTypes.number, this.attributeInfoNumberComparedNode.id, undefined);						return null;
					},
					() => {
return this.api!.validators.maxDecimalPlacesCount(3, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoIntegerComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'integerComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_B',
			primitiveType: PApiPrimitiveTypes.Integer,
			rawDataIndex: 3,
		});
	attributeInfoInteger : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Integer>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'integer',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_INTEGER_A',
			primitiveType: PApiPrimitiveTypes.Integer,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.integerComparedNode, true, PApiPrimitiveTypes.Integer, this.attributeInfoIntegerComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoCurrencyComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'currencyComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_B',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			rawDataIndex: 5,
		});
	attributeInfoCurrency : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, ClientCurrency>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'currency',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_CLIENT_CURRENCY_A',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.currencyComparedNode, true, PApiPrimitiveTypes.ClientCurrency, this.attributeInfoCurrencyComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoDateTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateTimeComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_B',
			primitiveType: PApiPrimitiveTypes.DateTime,
			rawDataIndex: 7,
		});
	attributeInfoDateTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_TIME_A',
			primitiveType: PApiPrimitiveTypes.DateTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.dateTimeComparedNode, true, PApiPrimitiveTypes.DateTime, this.attributeInfoDateTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoDateComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_B',
			primitiveType: PApiPrimitiveTypes.Date,
			rawDataIndex: 9,
		});
	attributeInfoDate : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Date>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'date',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_WITHOUT_TIME_A',
			primitiveType: PApiPrimitiveTypes.Date,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.dateComparedNode, true, PApiPrimitiveTypes.Date, this.attributeInfoDateComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDateExclusiveEndComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateExclusiveEndComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_B',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			rawDataIndex: 11,
		});
	attributeInfoDateExclusiveEnd : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, DateExclusiveEnd>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'dateExclusiveEnd',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DATE_EXCLUSIVE_END_A',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.dateExclusiveEndComparedNode, true, PApiPrimitiveTypes.DateExclusiveEnd, this.attributeInfoDateExclusiveEndComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoMinutesComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'minutesComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_B',
			primitiveType: PApiPrimitiveTypes.Minutes,
			rawDataIndex: 13,
		});
	attributeInfoMinutes : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Minutes>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'minutes',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MINUTES_A',
			primitiveType: PApiPrimitiveTypes.Minutes,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.minutesComparedNode, true, PApiPrimitiveTypes.Minutes, this.attributeInfoMinutesComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 14,
		});
	attributeInfoHoursComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'hoursComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_B',
			primitiveType: PApiPrimitiveTypes.Hours,
			rawDataIndex: 15,
		});
	attributeInfoHours : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Hours>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'hours',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_HOURS_A',
			primitiveType: PApiPrimitiveTypes.Hours,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.hoursComparedNode, true, PApiPrimitiveTypes.Hours, this.attributeInfoHoursComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 16,
		});
	attributeInfoDaysComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'daysComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_B',
			primitiveType: PApiPrimitiveTypes.Days,
			rawDataIndex: 17,
		});
	attributeInfoDays : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Days>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'days',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DAYS_A',
			primitiveType: PApiPrimitiveTypes.Days,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.daysComparedNode, true, PApiPrimitiveTypes.Days, this.attributeInfoDaysComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 18,
		});
	attributeInfoMonthsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'monthsComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_B',
			primitiveType: PApiPrimitiveTypes.Months,
			rawDataIndex: 19,
		});
	attributeInfoMonths : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Months>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'months',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_MONTHS_A',
			primitiveType: PApiPrimitiveTypes.Months,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.monthsComparedNode, true, PApiPrimitiveTypes.Months, this.attributeInfoMonthsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 20,
		});
	attributeInfoYearsComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'yearsComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_B',
			primitiveType: PApiPrimitiveTypes.Years,
			rawDataIndex: 21,
		});
	attributeInfoYears : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Years>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'years',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_YEARS_A',
			primitiveType: PApiPrimitiveTypes.Years,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.yearsComparedNode, true, PApiPrimitiveTypes.Years, this.attributeInfoYearsComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 22,
		});
	attributeInfoDurationComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'durationComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_B',
			primitiveType: PApiPrimitiveTypes.Duration,
			rawDataIndex: 23,
		});
	attributeInfoDuration : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Duration>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'duration',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_DURATION_A',
			primitiveType: PApiPrimitiveTypes.Duration,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.durationComparedNode, true, PApiPrimitiveTypes.Duration, this.attributeInfoDurationComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 24,
		});
	attributeInfoLocalTimeComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'localTimeComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_B',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			rawDataIndex: 25,
		});
	attributeInfoLocalTime : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, LocalTime>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'localTime',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_LOCAL_TIME_A',
			primitiveType: PApiPrimitiveTypes.LocalTime,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.localTimeComparedNode, true, PApiPrimitiveTypes.LocalTime, this.attributeInfoLocalTimeComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 26,
		});
	attributeInfoPercentComparedNode : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'percentComparedNode',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_B',
			primitiveType: PApiPrimitiveTypes.Percent,
			rawDataIndex: 27,
		});
	attributeInfoPercent : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxFailsCompareNode, Percent>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxFailsCompareNode,
			name: 'percent',
			nodeName: 'VALIDATIONS_MAX_FAILS_COMPARE_NODE_PERCENT_A',
			primitiveType: PApiPrimitiveTypes.Percent,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
					() => {
return this.api!.validators.max(() => this.percentComparedNode, true, PApiPrimitiveTypes.Percent, this.attributeInfoPercentComparedNode.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxFailsCompareNode) {
				return [
				];
			}
			,
			rawDataIndex: 28,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get numberComparedNode() : number {
		this.getterDebugValidations(this.attributeInfoNumberComparedNode, false);
		return this.data[1];
	}

	set numberComparedNode(v : number) {
        this.setterImpl(1, v, 'numberComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {number}
     */
	get number() : number {
		this.getterDebugValidations(this.attributeInfoNumber, false);
		return this.data[2];
	}

	set number(v : number) {
        this.setterImpl(2, v, 'number', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integerComparedNode() : Integer {
		this.getterDebugValidations(this.attributeInfoIntegerComparedNode, false);
		return this.data[3];
	}

	set integerComparedNode(v : Integer) {
        this.setterImpl(3, v, 'integerComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Integer}
     */
	get integer() : Integer {
		this.getterDebugValidations(this.attributeInfoInteger, false);
		return this.data[4];
	}

	set integer(v : Integer) {
        this.setterImpl(4, v, 'integer', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get currencyComparedNode() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoCurrencyComparedNode, false);
		return this.data[5];
	}

	set currencyComparedNode(v : ClientCurrency) {
        this.setterImpl(5, v, 'currencyComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {ClientCurrency}
     */
	get currency() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoCurrency, false);
		return this.data[6];
	}

	set currency(v : ClientCurrency) {
        this.setterImpl(6, v, 'currency', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTimeComparedNode() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTimeComparedNode, false);
		return this.data[7];
	}

	set dateTimeComparedNode(v : DateTime) {
        this.setterImpl(7, v, 'dateTimeComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateTime}
     */
	get dateTime() : DateTime {
		this.getterDebugValidations(this.attributeInfoDateTime, false);
		return this.data[8];
	}

	set dateTime(v : DateTime) {
        this.setterImpl(8, v, 'dateTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get dateComparedNode() : Date {
		this.getterDebugValidations(this.attributeInfoDateComparedNode, false);
		return this.data[9];
	}

	set dateComparedNode(v : Date) {
        this.setterImpl(9, v, 'dateComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Date}
     */
	get date() : Date {
		this.getterDebugValidations(this.attributeInfoDate, false);
		return this.data[10];
	}

	set date(v : Date) {
        this.setterImpl(10, v, 'date', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEndComparedNode() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEndComparedNode, false);
		return this.data[11];
	}

	set dateExclusiveEndComparedNode(v : DateExclusiveEnd) {
        this.setterImpl(11, v, 'dateExclusiveEndComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {DateExclusiveEnd}
     */
	get dateExclusiveEnd() : DateExclusiveEnd {
		this.getterDebugValidations(this.attributeInfoDateExclusiveEnd, false);
		return this.data[12];
	}

	set dateExclusiveEnd(v : DateExclusiveEnd) {
        this.setterImpl(12, v, 'dateExclusiveEnd', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutesComparedNode() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutesComparedNode, false);
		return this.data[13];
	}

	set minutesComparedNode(v : Minutes) {
        this.setterImpl(13, v, 'minutesComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Minutes}
     */
	get minutes() : Minutes {
		this.getterDebugValidations(this.attributeInfoMinutes, false);
		return this.data[14];
	}

	set minutes(v : Minutes) {
        this.setterImpl(14, v, 'minutes', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hoursComparedNode() : Hours {
		this.getterDebugValidations(this.attributeInfoHoursComparedNode, false);
		return this.data[15];
	}

	set hoursComparedNode(v : Hours) {
        this.setterImpl(15, v, 'hoursComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Hours}
     */
	get hours() : Hours {
		this.getterDebugValidations(this.attributeInfoHours, false);
		return this.data[16];
	}

	set hours(v : Hours) {
        this.setterImpl(16, v, 'hours', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get daysComparedNode() : Days {
		this.getterDebugValidations(this.attributeInfoDaysComparedNode, false);
		return this.data[17];
	}

	set daysComparedNode(v : Days) {
        this.setterImpl(17, v, 'daysComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Days}
     */
	get days() : Days {
		this.getterDebugValidations(this.attributeInfoDays, false);
		return this.data[18];
	}

	set days(v : Days) {
        this.setterImpl(18, v, 'days', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get monthsComparedNode() : Months {
		this.getterDebugValidations(this.attributeInfoMonthsComparedNode, false);
		return this.data[19];
	}

	set monthsComparedNode(v : Months) {
        this.setterImpl(19, v, 'monthsComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Months}
     */
	get months() : Months {
		this.getterDebugValidations(this.attributeInfoMonths, false);
		return this.data[20];
	}

	set months(v : Months) {
        this.setterImpl(20, v, 'months', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get yearsComparedNode() : Years {
		this.getterDebugValidations(this.attributeInfoYearsComparedNode, false);
		return this.data[21];
	}

	set yearsComparedNode(v : Years) {
        this.setterImpl(21, v, 'yearsComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Years}
     */
	get years() : Years {
		this.getterDebugValidations(this.attributeInfoYears, false);
		return this.data[22];
	}

	set years(v : Years) {
        this.setterImpl(22, v, 'years', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get durationComparedNode() : Duration {
		this.getterDebugValidations(this.attributeInfoDurationComparedNode, false);
		return this.data[23];
	}

	set durationComparedNode(v : Duration) {
        this.setterImpl(23, v, 'durationComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Duration}
     */
	get duration() : Duration {
		this.getterDebugValidations(this.attributeInfoDuration, false);
		return this.data[24];
	}

	set duration(v : Duration) {
        this.setterImpl(24, v, 'duration', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTimeComparedNode() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTimeComparedNode, false);
		return this.data[25];
	}

	set localTimeComparedNode(v : LocalTime) {
        this.setterImpl(25, v, 'localTimeComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {LocalTime}
     */
	get localTime() : LocalTime {
		this.getterDebugValidations(this.attributeInfoLocalTime, false);
		return this.data[26];
	}

	set localTime(v : LocalTime) {
        this.setterImpl(26, v, 'localTime', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percentComparedNode() : Percent {
		this.getterDebugValidations(this.attributeInfoPercentComparedNode, false);
		return this.data[27];
	}

	set percentComparedNode(v : Percent) {
        this.setterImpl(27, v, 'percentComparedNode', false, null, null);
	}

	/**
     * 
	 *
	 * @type {Percent}
     */
	get percent() : Percent {
		this.getterDebugValidations(this.attributeInfoPercent, false);
		return this.data[28];
	}

	set percent(v : Percent) {
        this.setterImpl(28, v, 'percent', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '308';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '308', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxDecimalPlaces extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxDecimalPlaces>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxDecimalPlaces> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxDecimalPlaces as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlaces, AiSwitchShowcaseApiValidationsMaxDecimalPlaces> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlaces, AiSwitchShowcaseApiValidationsMaxDecimalPlaces>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxDecimalPlaces,
			name: 'maxDecimalPlaces',
			nodeName: 'VALIDATIONS_MAX_DECIMAL_PLACES',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlaces, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 13,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails = new AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxDecimalPlaces, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '73';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '73', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxDecimalPlaces | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MAX_DECIMAL_PLACES_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoTooManyDecimalPlaces : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, number> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails, number>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails,
			name: 'tooManyDecimalPlaces',
			nodeName: 'VALIDATIONS_MAX_DECIMAL_PLACES_FAILS_TOO_MANY_DECIMAL_PLACES',
			primitiveType: PApiPrimitiveTypes.number,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails) {
				return [
					() => {
return this.api!.validators.maxDecimalPlacesCount(3, PApiPrimitiveTypes.number, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxDecimalPlacesFails) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
     * 
	 *
	 * @type {number}
     */
	get tooManyDecimalPlaces() : number {
		this.getterDebugValidations(this.attributeInfoTooManyDecimalPlaces, false);
		return this.data[1];
	}

	set tooManyDecimalPlaces(v : number) {
        this.setterImpl(1, v, 'tooManyDecimalPlaces', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '365';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '365', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxLength extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxLength>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidations | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxLength> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxLength as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLength, AiSwitchShowcaseApiValidationsMaxLength> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLength, AiSwitchShowcaseApiValidationsMaxLength>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxLength,
			name: 'maxLength',
			nodeName: 'VALIDATIONS_MAX_LENGTH',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxLength, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 14,
		});

	private failsWrapper : AiSwitchShowcaseApiValidationsMaxLengthFails = new AiSwitchShowcaseApiValidationsMaxLengthFails(this.api,
		this as unknown as AiSwitchShowcaseApiValidationsMaxLength, );
	public attributeInfoFails = this.failsWrapper.attributeInfoThis;

	/**
     * 
     */
	get fails() : AiSwitchShowcaseApiValidationsMaxLengthFails {
		this.getterDebugValidations(this.attributeInfoFails, false);
		return this.failsWrapper;
	}

	set failsTestSetter(v : AiSwitchShowcaseApiValidationsMaxLengthFails) {
        this.setterImpl(1, v.rawData, 'fails', true, null, () => {this.failsWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.failsWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.failsWrapper._updateRawData(data && data[1] !== undefined ? data[1] : null, generateMissingData);
	}

	protected get dni() : string {
		return '74';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '74', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class AiSwitchShowcaseApiValidationsMaxLengthFails extends ApiObjectWrapper<AiSwitchShowcaseApiValidationsMaxLengthFails>
{
	constructor(override readonly api : AiSwitchShowcaseApiService | null,
		override readonly parent : AiSwitchShowcaseApiValidationsMaxLength | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<AiSwitchShowcaseApiValidationsMaxLengthFails> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, AiSwitchShowcaseApiValidationsMaxLengthFails as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | null | undefined = undefined;
	get id() : Id | null {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, AiSwitchShowcaseApiValidationsMaxLengthFails> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, AiSwitchShowcaseApiValidationsMaxLengthFails>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxLengthFails,
			name: 'fails',
			nodeName: 'VALIDATIONS_MAX_LENGTH_FAILS',
			defaultValue: function(this : AiSwitchShowcaseApiValidationsMaxLengthFails, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 1,
		});
	attributeInfoTooLong : ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, string> = new ApiAttributeInfo<AiSwitchShowcaseApiValidationsMaxLengthFails, string>({
			apiObjWrapper: this as any as AiSwitchShowcaseApiValidationsMaxLengthFails,
			name: 'tooLong',
			nodeName: 'VALIDATIONS_MAX_LENGTH_FAILS_TOO_LONG',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : AiSwitchShowcaseApiValidationsMaxLengthFails) {
				return [
					() => {
						return this.api!.validators.maxLength(3, PApiPrimitiveTypes.string, undefined);
					},
				];
			},
			asyncValidations: function(this : AiSwitchShowcaseApiValidationsMaxLengthFails) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});

	/**
     * 
	 *
	 * @type {string}
     */
	get tooLong() : string {
		this.getterDebugValidations(this.attributeInfoTooLong, false);
		return this.data[1];
	}

	set tooLong(v : string) {
        this.setterImpl(1, v, 'tooLong', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		
		this.backendId = (idRawData === null || idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '367';
	}

	static async loadDetailed(	api : AiSwitchShowcaseApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '367', { success: success, error: error, searchParams: searchParams});
	}
}



