import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ANIMATION_SPEED_FAST } from '@plano/animations';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PCollapsibleEvent = {event : MouseEvent | null, collapsedState : boolean};

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export const pCollapsibleAnimationSpeed = ANIMATION_SPEED_FAST;

/**
 * A collapsible component that can be used to hide content.
 *
 * @example with content
 * <p-collapsible>
 * 	<span trigger>Trigger</span>
 * 	<div content>Content</div>
 * </p-collapsible>
 *
 * @example without content
 * 	<p-collapsible #collapsibleTrigger>
 * 		<span trigger>Trigger</span>
 * 	</p-collapsible>
 * 	<div class="fancy-thing" *ngIf="!collapsibleTrigger.collapsed"></div>
 */
@Component({
	selector: 'p-collapsible',
	templateUrl: './p-collapsible.component.html',
	styleUrls: ['./p-collapsible.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [
		trigger(
			'slideOpen',
			[
				state('false, void', style({ height: '0px', overflow: 'hidden' })),
				state('true', style({ height: '*' })),
				transition( 'true <=> false', [animate(pCollapsibleAnimationSpeed)]),
			],
		),
	],
})
export class PCollapsibleComponent extends UniqueAriaLabelByDirective implements AfterViewInit {
	@HostBinding('class.border-danger')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasDanger : boolean = false;

	@HostBinding('class.border-primary')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public borderPrimary : boolean = false;

	/**
	 * Classes to be added to the icon of the collapsible.
	 */
	@Input() public iconClasses : string | null = null;

	/**
	 * Class to add to the trigger button of the collapsible
	 */
	@Input() public triggerBtnClasses : string | null = null;

	/**
	 * Visual size of this component.
	 * Can be useful if you have few space in a button-bar or want to have large buttons on mobile.
	 */
	@Input() public size : typeof enumsObject.BootstrapSize.SM | typeof enumsObject.BootstrapSize.LG = enumsObject.BootstrapSize.LG;

	@ViewChild('ngContentContainer') private ngContentContainer ?: ElementRef<HTMLElement>;

	@ViewChild('collapsibleButton') private collapsibleButton ! : ElementRef<HTMLButtonElement>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public collapsedChange = new EventEmitter<PCollapsibleEvent>();

	@HostBinding('class.card') private _alwaysTrue = true;

	@HostBinding('class.mb-1') private get sizeIsSm() : boolean {
		return this.size === enumsObject.BootstrapSize.SM;
	}

	@HostBinding('class.collapsed')
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public collapsed : boolean = true;

	/**
	 * Sometimes we have more than one sticky element, and in such cases we might need to pass the
	 * stickyOffset so the elements don't overlap
	 */
	@Input() public stickyOffset : number = 0;

	@HostBinding('class.shadow-lg')
	private get hasShadowLg() : boolean {
		return !this.collapsed && this.size === enumsObject.BootstrapSize.LG;
	}

	@HostBinding('class.has-content')
	public hasContent : boolean = false;

	@HostBinding('class.shadow')
	private get hasShadow() : boolean {
		return !this.collapsed && this.size === enumsObject.BootstrapSize.SM;
	}

	public enums = enumsObject;

	public ngAfterViewInit() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.ngContentContainer, 'ngContentContainer');
		this.hasContent = !!this.ngContentContainer.nativeElement.previousSibling;
	}

	/**
	 * Toggle the state if this is collapsed or not.
	 */
	private setCollapsed(input : PCollapsibleEvent) : void {
		this.collapsed = input.collapsedState;
		if (!this.collapsed) {
			window.setTimeout(() => {
				const wholeHeight = this.collapsibleButton.nativeElement.parentElement!.getBoundingClientRect().height;
				if (wholeHeight > window.innerHeight * 0.5) {
					this.collapsibleButton.nativeElement.style.position = 'sticky';
					this.collapsibleButton.nativeElement.style.top = `${this.stickyOffset}px`;
				}
			}, pCollapsibleAnimationSpeed + 10);
		} else {
			this.collapsibleButton.nativeElement.style.position = 'relative';
			this.collapsibleButton.nativeElement.style.top = '';
		}
		this.collapsedChange.emit(input);
	}

	private isVisible(element : HTMLElement) : boolean {
		const rect = element.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	/**
	 * Toggle the state if this is collapsed or not.
	 */
	public toggle(event : PCollapsibleEvent['event']) : void {
		this.setCollapsed({event: event, collapsedState: !this.collapsed});
		if (this.collapsed && !this.isVisible(this.collapsibleButton.nativeElement)) {
			this.collapsibleButton.nativeElement.scrollIntoView({ behavior: 'smooth', block:'start'});
		}
	}

	/**
	 * An unique HTML id for the header.
	 */
	public get headerHtmlId() : string {
		return this.ariaLabelHtmlId;
	}

	// /**
	//  * Makes this component more accessible
	//  */
	// public handleKeyup(event : KeyboardEvent) : void {
	// 	event.stopPropagation();
	// 	switch (event.key) {
	// 		case 'Enter':
	// 			this.toggle();
	// 			break;
	// 		case 'Escape':
	// 			this.setCollapsed(true);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }
}
