<ng-container *ngTemplateOutlet="mightySwitch; context: {$implicit: attributeInfo, parent: formGroup}" ></ng-container>

<ng-template #mightySwitch let-ai let-parent="parent">
	<ng-container [ngSwitch]="ai.primitiveType">
		<ng-container *ngSwitchCase="PApiPrimitiveTypes.ApiList">
			<p-ai-form-array
				[label]="ai.nodeName"
				class="card card-body"
				[formParent]="parent"
				[attributeInfo]="ai"
				#feesFormArrayRef
			>
				<ng-container *ngIf="getIterableValuesOfList(ai).length === 0">
					<div class="card card-body bg-light">
						Noch kein Eintrag
					</div>
				</ng-container>
				<div class="card card-body bg-light" *ngFor="let childAi of getIterableValuesOfList(ai)" [@popShow]>
					<ng-container *ngTemplateOutlet="mightySwitch; context: {$implicit: childAi, parent: feesFormArrayRef.childArray}"></ng-container>
				</div>
			</p-ai-form-array>
		</ng-container>
		<ng-container *ngSwitchCase="null">
			<p-ai-form-group
				class="card card-body"
				[formParent]="parent"
				[attributeInfo]="ai"
				#feesFormGroupRef
			>
				<h4>{{ai.nodeName}}</h4>
				<ng-container *ngFor="let childAi of getChildAttributeInfos(ai)">
					<ng-container *ngTemplateOutlet="mightySwitch; context: {$implicit: childAi, parent: feesFormGroupRef.childGroup}"></ng-container>
				</ng-container>
			</p-ai-form-group>
		</ng-container>
		<ng-container *ngSwitchCase="PApiPrimitiveTypes.Id">
			<ng-container *ngTemplateOutlet="notSupported; context: {$implicit: ai}"></ng-container>
		</ng-container>

		<ng-container *ngSwitchDefault>
			<p-ai-switch
				[label]="ai.nodeName"
				[group]="parent"
				[attributeInfo]="ai"
				[pEditable]="pEditable"
			></p-ai-switch>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #notSupported let-ai>
	<p-bootstrap-form-group
		[label]="ai.nodeName"
	>
		<div class="form-control bg-muted text-monospace">PApiPrimitiveTypes.Enum is not supported by the ai-form-builder</div>
	</p-bootstrap-form-group>
</ng-template>
