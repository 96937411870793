/* NOTE: Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-lines: ["error", 1700] */
/* eslint max-classes-per-file: ["error", 25] */
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { SchedulingApiShiftModel, SchedulingApiShiftModels } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { SchedulingApiShift, SchedulingApiShifts } from '@plano/client/scheduling/shared/api/scheduling-api-shift.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { ApiErrorHandler, ApiLoadArgs, ApiSaveArgs, SchedulingApiAbsenceBase, SchedulingApiAbsenceType, SchedulingApiAbsencesBase, SchedulingApiAccountingPeriodBase, SchedulingApiAccountingPeriodExpectedMemberDataBase, SchedulingApiAccountingPeriodExpectedMemberDataItem, SchedulingApiAccountingPeriodsBase, SchedulingApiAssignmentProcessBase, SchedulingApiAssignmentProcessesBase, SchedulingApiBookableCreatedBy, SchedulingApiGiftCardBase, SchedulingApiGiftCardOverrideWaysToRedeem, SchedulingApiGiftCardStatus, SchedulingApiGiftCardsBase, SchedulingApiHolidayBase, SchedulingApiHolidaysBase, SchedulingApiMemberAssignableShiftModelBase, SchedulingApiMemberAssignableShiftModelsBase, SchedulingApiMemberBase, SchedulingApiMembersBase, SchedulingApiMemo, SchedulingApiMemosBase, SchedulingApiMessages, SchedulingApiOnlineRefundInfo, SchedulingApiPosSystem, SchedulingApiRightGroupBase, SchedulingApiRightGroupRole, SchedulingApiRightGroupShiftModelRight, SchedulingApiRightGroupShiftModelRightsBase, SchedulingApiRightGroupsBase, SchedulingApiRootBase, SchedulingApiServiceBase, SchedulingApiTodaysShiftDescriptionBase, SchedulingApiTodaysShiftDescriptionsBase, SchedulingApiTransactions, SchedulingApiWarningSeverity, ShiftId } from '@plano/shared/api';
import { ClientCurrency, DateTime, Duration } from '@plano/shared/api/base/generated-types.ag';
import { Id } from '@plano/shared/api/base/id/id';
import { NOT_CHANGED } from '@plano/shared/api/base/object-diff/object-diff';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { ModalRef, ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe, PDictionarySourceStringAndParams } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { Assertions } from '@plano/shared/core/utils/assertions';
import { errorUtils } from '@plano/shared/core/utils/error-utils';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { SchedulingApiBookable } from './scheduling-api-bookable.service';
import { ISchedulingApiMember } from './scheduling-api.interfaces';
import { PPaymentStatusEnum } from './scheduling-api.utils';

@Injectable({
	providedIn: 'root',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiService extends SchedulingApiServiceBase {

	private automaticWarningsUpdateForChanges : string[] | null = null;

	private updateWarningsTimeout : number | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public enableAutomaticWarningsUpdateOnChange(forChanges : string[]) : void {
		this.automaticWarningsUpdateForChanges = forChanges;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public disableAutomaticWarningsUpdateOnChange() : void {
		this.automaticWarningsUpdateForChanges = null;
	}

	public override changed(change : string) : void {
		// automatic update enabled and we are supposed to update for current change?
		// eslint-disable-next-line sonarjs/no-collapsible-if
		if (	this.automaticWarningsUpdateForChanges?.includes(change)) {
			// update warnings lazy.
			// E.g. when several api changes happen at the same time
			// updateWarnings() should be called only once.
			if (!this.updateWarningsTimeout) {
				this.zone.runOutsideAngular(() => {
					this.updateWarningsTimeout = window.setTimeout(() => {
						this.zone.run(() => {
							this.updateWarnings();
							this.updateWarningsTimeout = null;
						});
					});
				});
			}
		}

		// super
		// Hack to improve text dialogs: we ignore comment properties changes so the
		// Data properties are not recalculated
		if (	change !== 'message' 	&&
		change !== 'description' 	&&
		change !== 'illnessResponderCommentToMembers' 	&&
		change !== 'indisposedMemberComment' 	&&
		change !== 'performActionComment') {
			super.changed(change);
		}
	}

	public override async save({
		success = null,
		error = null,
		additionalSearchParams = null,
		saveEmptyData = false,
		sendRootMetaOnEmptyData = false,
		onlySavePath = null,
	} : ApiSaveArgs = {}) : Promise<HttpResponse<unknown>> {
		return super.save(
			{
				success: (response, savedData) => {
					if (this.data.attributeInfoMessages.isAvailable)
						this.showBackendMessageToasts(this.data.messages, savedData);

					if (success)
						success(response, savedData);
				},
				error: error,
				additionalSearchParams: additionalSearchParams,
				saveEmptyData: saveEmptyData,
				sendRootMetaOnEmptyData: sendRootMetaOnEmptyData,
				onlySavePath: onlySavePath,
			},
		);
	}

	public override async load({
		success = undefined,
		error = undefined,
		searchParams = new HttpParams(),
	} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		// if automaticWarningsUpdateOnChange is enabled then add current changes to search params
		if (this.automaticWarningsUpdateForChanges) {
			const currentChanges = this.dataStack.getDataToSave(null);

			if (currentChanges !== NOT_CHANGED) {

				if (!searchParams) {
					searchParams = new HttpParams();
				}

				searchParams = searchParams.set('warningsChanges', encodeURIComponent(JSON.stringify(currentChanges)));
			}
		}

		// super
		const promise = super.load({
			success : success ?? null,
			error : error ?? null,
			searchParams : searchParams,
		});

		// "warningsChanges" should only be send for this api call.
		if (this.automaticWarningsUpdateForChanges) {
			this.removeParamFromLastLoadSearchParams('warningsChanges');
		}

		return promise;
	}

	private runningUpdateWarningsApiCallCount = 0;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateWarnings() : void {
		// no need to update something if no data is loaded

		if (!this.isLoaded() || !this.lastExecutedLoadSearchParams)
			return;

		// tell backend that we are only interested in warnings
		let searchParams = this.lastExecutedLoadSearchParams
			.set('onlyWarnings', 'true');

		// send current changes data to backend
		const currentChanges = this.dataStack.getDataToSave(null);

		if (currentChanges !== NOT_CHANGED) {
			searchParams = searchParams.set('warningsChanges', encodeURIComponent(JSON.stringify(currentChanges)));
		}

		// don’t show stale warnings
		this.data.warnings._updateRawData([], true);

		// execute
		++this.runningUpdateWarningsApiCallCount;

		this.http.get(this.apiUrl, this.getRequestOptions(searchParams)).subscribe(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(response : HttpResponse<any>) => {
				--this.runningUpdateWarningsApiCallCount;

				// we expect to receive only warnings data.
				// So update them manually
				const newData = response.body;
				const warningsNewData = newData[this.consts.WARNINGS];
				this.data.warnings._updateRawData(warningsNewData, false);
			},
			(response : unknown) => {
				--this.runningUpdateWarningsApiCallCount;
				this.onError(null, response);
			},
		);
	}

	private _modalService : ModalService | null = null;

	/**
	 * Get the `ModalService` if needed.
	 */
	private get modalService() : ModalService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._modalService)
			this._modalService = this.injector.get(ModalService);

		return this._modalService;
	}

	private _schedulingService : SchedulingService | null = null;

	/**
		 * Get the `ModalService` if needed.
		 */
	private get schedulingService() : SchedulingService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._schedulingService)
			this._schedulingService = this.injector.get(SchedulingService);

		return this._schedulingService;
	}

	private _pRouterService : PRouterService | null = null;

	/**
	 * Get the `PRouterService` if needed.
	 */
	private get pRouterService() : PRouterService {
		// To avoid circular dependencies we use lazy initialization
		if (!this._pRouterService)
			this._pRouterService = this.injector.get(PRouterService);

		return this._pRouterService;
	}

	/** Open a modal which tells the user about our calendar-limit */
	private async openCalendarRangeModal(
		modalService : ModalService,
		successLink : string,
		text : PDictionarySourceStringAndParams,
		limit : number,
		pRouterService : PRouterService,
	) : ModalRef['result'] {
		const isOnDetailPage = !this.pRouterService.url.startsWith('/client/scheduling');
		const modalRef = modalService.openDefaultModal({
			icon: 'ghost',
			description: `<p>${this.localizePipe.transform(text)}</p>`,
			modalTitle: '',
			closeBtnLabel: isOnDetailPage ? this.localizePipe.transform('OK') : this.localizePipe.transform({
				sourceString: 'Zum ${limit}',
				params: {
					limit: this.datePipe.transform(limit),
				},
			}),
			dismissBtnLabel: this.localizePipe.transform('Abbrechen'),
			hideDismissBtn: isOnDetailPage || pRouterService.historyIsEmpty ? true : false,
		}, {
			theme: enumsObject.PThemeEnum.WARNING,
			size: enumsObject.BootstrapSize.SM,
		});
		return modalRef.result.then(value => {
			if (value.modalResult === 'success') {
				// If we are on the detail page the calendar is usually inside a modal and we can not navigate somewhere to
				// change the calendar-view. So we do nothing. The user have to be smart enough to nav back.
				if (isOnDetailPage) return value;

				// It‘s important to provide this navigation, because a reload would
				// load the same time-range again which would lead to an endless loop of error modals.
				window.location = successLink as unknown as Location;
			} else {
				// If we are on the detail page the calendar is usually inside a modal and we can not navigate somewhere to
				// change the calendar-view. So we do nothing. The user have to be smart enough to nav back.
				if (isOnDetailPage) return value;

				if (pRouterService.historyIsEmpty) return value;
				pRouterService.navBack();

			}
			return value;
		});
	}

	private async handleCalendarRangeError() : ModalRef['result'] {

		// Get start and end from search params
		const start = this.lastExecutedLoadSearchParams ? +this.lastExecutedLoadSearchParams.get('start')! : null;
		const end = this.lastExecutedLoadSearchParams ? +this.lastExecutedLoadSearchParams.get('end')! : null;

		// Determine if the requested date-range is in the past or future
		const reachedLimitDirection : 'past' | 'future' | null = (() => {
			const now = Date.now();
			if (start && start > now) return 'future';
			if (end && end < now) return 'past';
			throw new Error('reachedLimitDirection could not be determined');
		})();

		let limit : number;
		const text : PDictionarySourceStringAndParams = (() => {
			if (reachedLimitDirection === 'past') {
				limit = +this.pMoment.m('2017').startOf('year');
				const requestedDate = +this.pMoment.m(start).startOf('day');
				return {
					sourceString: '${requestedDate} liegt zu weit in der Vergangenheit. Du kannst maximal bis zum ${limit} navigieren.',
					params: {
						requestedDate: `<mark>${this.datePipe.transform(requestedDate)}</mark>`,
						limit: `<mark>${this.datePipe.transform(limit)}</mark>`,
					},
				};
			} else {
				limit = +this.pMoment.m().endOf('month').add('years', 3);
				const requestedDate = +this.pMoment.m(end).endOf('day');
				return {
					sourceString: '${requestedDate} liegt zu weit in der Zukunft. Du kannst maximal bis zum ${limit} navigieren.',
					params: {
						requestedDate: `<mark>${this.datePipe.transform(requestedDate)}</mark>`,
						limit: `<mark>${this.datePipe.transform(limit)}</mark>`,
					},
				};
			}
		})();

		const calendarMode = (
			this.schedulingService.urlParam.calendarMode === CalendarModes.WEEK ? CalendarModes.DAY : this.schedulingService.urlParam.calendarMode
		);

		return this.openCalendarRangeModal(
			this.modalService,
			`${Config.FRONTEND_URL_LOCALIZED}/client/scheduling/${calendarMode}/${limit}`,
			text,
			limit,
			this.pRouterService,
		);

	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected override onError(handler : ApiErrorHandler | null, response : any) : void {
		// we are not interested in errors which are not concerned api
		if (!errorUtils.isTypeHttpErrorResponse(response))
			throw response;

		if (response.status === this.consts.SCHEDULING_API_INVALID_TIME_RANGE) {
			void this.handleCalendarRangeError();
			return;
		}

		super.onError(handler, response);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isUpdatingWarnings() : boolean {
		return this.runningUpdateWarningsApiCallCount > 0;
	}

	/**
	 * One method to rule them all
	 */
	public deselectAllSelections() : void {
		if (this.data.attributeInfoShifts.isAvailable)
			this.data.shifts.setSelected(false);
		if (this.data.attributeInfoShiftModels.isAvailable)
			this.data.shiftModels.setSelected(false);
		if (this.data.attributeInfoMembers.isAvailable)
			this.data.members.setSelected(false);
		if (this.data.attributeInfoAssignmentProcesses.isAvailable)
			this.data.assignmentProcesses.setSelected(false);
	}

	/**
	 * Check if anything is selected. No matter if the related shifts are in the current set of shifts.
	 */
	public get hasSelectedItems() : boolean {
		if (this.data.attributeInfoAssignmentProcesses.isAvailable && this.data.assignmentProcesses.hasSelectedItem) return true;
		if (this.data.attributeInfoShiftModels.isAvailable && this.data.shiftModels.hasSelectedItem) return true;
		if (this.data.attributeInfoShifts.isAvailable && this.data.shifts.hasSelectedItem) return true;
		if (this.data.attributeInfoMembers.isAvailable && this.data.members.hasSelectedItem) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isSwitzerland() : boolean {
		// TODO: (PLANO-13753): Currently scheduling-api does not provide the country. So, we use this hack.
		if (this.data.possibleVatPercents.length < 2) return false;
		return this.data.possibleVatPercents.get(1) === 0.025;
	}

	/**
	 * Shows toasts based on messages send from backend.
	 * @param messages Messages from backend.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private showBackendMessageToasts(messages : SchedulingApiMessages, savedData : any[] | typeof NOT_CHANGED) : void {
		if (!messages.rawData)
			return;

		const localizePipe = this.injector.get<LocalizePipe>(LocalizePipe);

		// removedDuplicateReCaptchaWhiteListedHostName
		if (messages.removedDuplicateReCaptchaWhiteListedHostName) {
			this.toasts.addToast({
				content: localizePipe.transform('Die angegebene Domain war bereits vorhanden und wurde daher automatisch gelöscht.'),
				theme: enumsObject.PThemeEnum.WARNING,
				visibilityDuration: 'long',
			});
		}

		// sentRequestorReportAboutRemovedAssignments
		if (messages.sentRequestorReportAboutRemovedAssignments) {
			this.toasts.addToast({
				title: localizePipe.transform('Vorhandene Schichtbesetzung entfernt'),
				content: localizePipe.transform({
					sourceString: 'Deine Übertragung hat bereits vorhandene Schichtbesetzungen entfernt. Eine genaue Auflistung haben wir an deine Email geschickt <mark>${email}</mark>',
					params: {email : this.me.data.email},
				}),
				icon: enumsObject.PlanoFaIconPool['NOT_POSSIBLE'],
				theme: enumsObject.PThemeEnum.WARNING,
				visibilityDuration: 'infinite',
			});
		}

		// onlineRefundInfo
		const onlineRefundInfo = messages.onlineRefundInfo;

		switch (onlineRefundInfo) {
			case SchedulingApiOnlineRefundInfo.ONLINE_REFUND_SUCCESSFUL:
				this.toasts.addToast({
					content: localizePipe.transform('Online-Rückerstattung erfolgreich veranlasst.<br>Das Geld sollte in wenigen Werktagen beim Kunden ankommen.'),
					theme: enumsObject.PThemeEnum.SUCCESS,
					visibilityDuration: 'long',
				});
				break;
			case SchedulingApiOnlineRefundInfo.ONLINE_REFUND_PARTIALLY:
				this.toasts.addToast({
					content: localizePipe.transform('Die Rückerstattung benötigte mehrere Teilzahlungen. Leider konnten nicht alle davon erfolgreich veranlasst werden. Für mehr Infos siehe »Zahlungen« in der Buchung.'),
					theme: enumsObject.PThemeEnum.DANGER,
					visibilityDuration: 'infinite',
				});
				break;
			case SchedulingApiOnlineRefundInfo.ONLINE_REFUND_FAILED:
				this.toasts.addToast({
					content: localizePipe.transform('Die Online-Rückerstattung konnte leider nicht veranlasst werden. Bitte versuche es etwas später erneut.'),
					theme: enumsObject.PThemeEnum.DANGER,
					visibilityDuration: 'infinite',
				});
				break;
			default:
				break;
		}

		// customBookableMailsInfo
		const customBookableMailsInfo = messages.customBookableMailsInfo;

		if (savedData !== NOT_CHANGED && customBookableMailsInfo.eventTriggered) {
			// email was send?
			if (customBookableMailsInfo.emailSentToBookingPerson || customBookableMailsInfo.emailSentToParticipants) {
				let text : PDictionarySourceString;
				if (customBookableMailsInfo.emailSentToBookingPerson) {
					if (customBookableMailsInfo.emailSentToParticipants) {
						text = 'An buchende Person und Teilnehmende';
					} else {
						text = 'An buchende Person';
					}
				} else {
					text = 'An Teilnehmende';
				}

				this.toasts.addToast({
					title: this.localizePipe.transform(`Email an Kunden verschickt`),
					content: this.localizePipe.transform(text),
					icon: enumsObject.PlanoFaIconPool['EMAIL_NOTIFICATION'],
					theme: enumsObject.PThemeEnum.INFO,
				});
			} else {
				// Inform that no email was send
				let toastContent = '';

				if (customBookableMailsInfo.emailNotSentBecauseOfMissingEmail) {
					toastContent = this.localizePipe.transform('Die Email-Adresse der buchenden Person fehlt.');
				} else if (customBookableMailsInfo.affectedShiftModelId) {
					toastContent = this.localizePipe.transform({
						// eslint-disable-next-line literal-blacklist/literal-blacklist
						sourceString: 'Entsprechend deiner <a href="client/shiftmodel/${shiftModelId}/bookingsettings#automatic-mails" target="_blank">Einstellungen</a> in der Tätigkeit.',
						params: {shiftModelId: customBookableMailsInfo.affectedShiftModelId.toString()},
					});
				}

				this.toasts.addToast({
					title: this.localizePipe.transform('Keine Email an Kunden verschickt'),
					content: toastContent,
					icon: enumsObject.PlanoFaIconPool['EMAIL_NOTIFICATION'],
					visibilityDuration: 'long',
					theme: enumsObject.PThemeEnum.WARNING,
				});
			}
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get hasFatalApiWarning() : boolean {
		if (!this.isLoaded()) return false;
		if (this.isUpdatingWarnings) return false;
		assumeDefinedToGetStrictNullChecksRunning(this.data.warnings, 'data.warnings');
		const fatalApiWarnings = this.data.warnings.filterBy(item => {
			return item.severity === SchedulingApiWarningSeverity.FATAL;
		});
		if (!fatalApiWarnings.length) return false;
		return true;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiRoot extends SchedulingApiRootBase {
	/**
	 * Get a label for the `posSystem` attribute.
	 */
	public get posSystemLabel() : string | null {
		switch (this.posSystem) {
			case SchedulingApiPosSystem.BOULDERADO :
				return 'Boulderado';
			case SchedulingApiPosSystem.FREECLIMBER :
				return 'Freeclimber';
			case SchedulingApiPosSystem.BETA_7 :
				return 'BETA7';
			case null :
				return null;
		}
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiHoliday extends SchedulingApiHolidayBase {

	public isHovered : boolean = false;
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiHolidays extends SchedulingApiHolidaysBase {

	/**
	 * get absences of day
	 * This includes al absences that start at, end at oder happen during the provided day.
	 * @param dayStart - timestamp of the desired day
	 */
	public getByDay(dayStart : number) : SchedulingApiHolidays {
		Assertions.ensureIsDayStart(dayStart);

		const dayEnd = +(new PMomentService(Config.LOCALE_ID).m(dayStart).add(1, 'day'));
		Assertions.ensureIsDayStart(dayEnd);

		return this.filterBy(item => {
			if (dayStart >= item.time.end) return false;
			if (dayEnd <= item.time.start) return false;
			return true;
		});
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAbsence extends SchedulingApiAbsenceBase {

	public isHovered : boolean = false;

	/**
	 * Get the type as human readable string
	 */
	public get title() : PDictionarySourceString | null {
		switch (this.type) {
			case SchedulingApiAbsenceType.ILLNESS :
				return 'Krankheit';
			case SchedulingApiAbsenceType.VACATION :
				return 'Urlaub';
			case SchedulingApiAbsenceType.OTHER :
				return 'Sonstiges';
			default :
				return null;
		}
	}

	/**
	 * Get a icon name for the type
	 */
	public get typeIconName() : (
		typeof enumsObject.PlanoFaIconPool.ITEMS_ABSENCE_ILLNESS |
		typeof enumsObject.PlanoFaIconPool.ITEMS_ABSENCE_VACATION |
		typeof enumsObject.PlanoFaIconPool.MORE_ACTIONS |
		null
	) {
		switch (this.type) {
			case SchedulingApiAbsenceType.ILLNESS :
				return enumsObject.PlanoFaIconPool['ITEMS_ABSENCE_ILLNESS'];
			case SchedulingApiAbsenceType.VACATION :
				return enumsObject.PlanoFaIconPool['ITEMS_ABSENCE_VACATION'];
			case SchedulingApiAbsenceType.OTHER :
				return enumsObject.PlanoFaIconPool['MORE_ACTIONS'];
			default :
				return null;
		}
	}

	/**
	 * Payroll duration
	 */
	public get duration() : number {
		let result : number;
		assumeDefinedToGetStrictNullChecksRunning(this.workingTimePerDay, 'workingTimePerDay');
		if (this.workingTimePerDay > -1) {
			result = this.workingTimePerDay * this.totalDays;
		} else {
			result = this.time.end - this.time.start;
		}
		return result;
	}

	/**
	 * @param min The absence time to be clamped by this min date-time. If `null` is passed here, instead the absence
	 * 	time is clamped by `this.time.start`.
	 * @param max The absence time to be clamped by this max date-time. If `null` is passed here, instead the absence
	 * 	time is clamped by `this.time.end`.
	 * @returns Payroll duration for this absence clamped by `min` and `max`.
	 */
	public durationBetween(min : DateTime | null = null, max : DateTime | null = null) : Duration {

		// if workingTimePerDay is specified, multiply it by the number of days in the range
		if (this.workingTimePerDay) return this.workingTimePerDay * this.totalDaysBetween(min, max);

		const START = (min && min > this.time.start) ? min : this.time.start;
		const END = (max && max < this.time.end) ? max : this.time.end;
		const duration = END - START;
		if (duration < 0) return 0;
		return duration;
	}

	/**
	 * Get calculated total payroll duration in hours as float
	 */
	private get totalDurationInHours() : number {
		const pMoment = new PMomentService();
		assumeDefinedToGetStrictNullChecksRunning(this.workingTimePerDay, 'workingTimePerDay');
		if (this.workingTimePerDay > -1) {
			return pMoment.duration(this.workingTimePerDay).asHours() *
			this.totalDays;
		}
		return pMoment.duration(this.duration).asHours();
	}

	/**
	 * Get calculated total earnings of a absence entry
	 */
	public get totalEarnings() : number {
		let result : number;
		if (!this.hourlyEarnings) {
			result = 0;
		} else {
			result = this.hourlyEarnings * this.totalDurationInHours;
		}
		return result;
	}

	/**
	 * Get calculated total earnings of an absence entry between two timestamps
	 */
	public totalEarningsBetween(min : DateTime | null = null, max : DateTime | null = null) : number | null {
		const partialDuration = this.durationBetween(min, max);

		const pMoment = new PMomentService(undefined);
		if (this.hourlyEarnings === null) return 0;
		return this.hourlyEarnings * pMoment.duration(partialDuration).asHours();
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get totalDays() : number {
		return this.totalDaysBetween(this.time.start, this.time.end);
	}

	/**
	 * @param min The starting time from which to start counting days. If `null` is passed here, instead the starting
	 * 	time is clamped by `this.time.start`.
	 * @param max The ending time at which to stop counting days. If `null` is passed here, instead the ending time is
	 * 	clamped by `this.time.end`.
	 * @returns The amount of days between the given range
	 */
	public totalDaysBetween(min : DateTime | null = null, max : DateTime | null = null) : number {
		if (!this.time.rawData) throw new Error('SchedulingApiAbsence.time is not defined [PLANO-19822]');
		const START = (min && min > this.time.start) ? min : this.time.start;
		const END = (max && max < this.time.end) ? max : this.time.end;

		const pMoment = new PMomentService(Config.LOCALE_ID);
		const startMoment = pMoment.m(END);
		const endMoment = pMoment.m(START);

		const days = startMoment.diff(endMoment, 'days', true);

		if (days < 0) return 0;

		return Math.round(days * 100) / 100;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isFullDay() : boolean {
		if (this.workingTimePerDay === null) return true; // Not sure if this is correct. Just re-implemented pre-null-check-behaviour
		return this.workingTimePerDay > -1;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAbsences extends SchedulingApiAbsencesBase {

	/**
	 * get absences of day
	 * This includes al absences that start at, end at oder happen during the provided day.
	 * @param dayStart - timestamp of the desired day
	 */
	public getByDay(dayStart : number) : SchedulingApiAbsences {
		Assertions.ensureIsDayStart(dayStart);

		const dayEnd = +(new PMomentService(Config.LOCALE_ID).m(dayStart).add(1, 'day'));
		Assertions.ensureIsDayStart(dayEnd);

		return this.filterBy(item => {
			if (dayStart >= item.time.end) { return false; }
			if (dayEnd <= item.time.start) { return false; }
			return true;
		});
	}

	/**
	 * Get sum of total earnings of all contained absences
	 */
	public get totalEarnings() : number {
		let result : number = 0;
		for (const absence of this.iterable()) {
			result += absence.totalEarnings;
		}
		return result;
	}

	/**
	 * Get sum of partial earnings of all contained absences
	 */
	public totalEarningsBetween(min : DateTime | null = null, max : DateTime | null = null) : number {
		let result : number = 0;
		for (const absence of this.iterable()) {
			const totalEarnings = absence.totalEarningsBetween(min, max);
			assumeNonNull(totalEarnings, 'totalEarnings');
			result += totalEarnings;
		}
		return result;
	}

	/**
	 * Sum of payroll durations
	 */
	public get duration() : number {
		let result : number = 0;
		for (const absence of this.iterable()) {
			result += absence.duration;
		}
		return result;
	}

	/**
	 * Sum of partial payroll durations
	 */
	public durationBetween(min : DateTime | null = null, max : DateTime | null = null) : Duration {
		let result : number = 0;
		for (const absence of this.iterable()) {
			result += absence.durationBetween(min, max);
		}
		return result;
	}

	/**
	 * Get absences by Member in a new ListWrapper
	 */
	public getByMember( member : SchedulingApiMember ) : SchedulingApiAbsences {
		return this.filterBy(item => {
			if (!item.memberId.equals(member.id)) return false;
			return true;
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get commentAmount() : number {
		return this.filterBy(item => !!item.ownerComment?.length).length;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiMembers extends SchedulingApiMembersBase {

	private _selectedItems : SchedulingApiMembers | null = null;

	/**
	 * get members with same birthday as day
	 * This includes al absences that start at, end at oder happen during the provided day.
	 * @param dayStart - timestamp of the desired day
	 */
	public getByBirthday(dayStart : number) : SchedulingApiMembers {
		Assertions.ensureIsDayStart(dayStart);

		const dayEnd = +(new PMomentService(Config.LOCALE_ID).m(dayStart).startOf('day').add(1, 'day'));
		Assertions.ensureIsDayStart(dayEnd);

		return this.filterBy(item => {
			const tempMoment = new PMomentService(Config.LOCALE_ID).m(item.birthday);
			const birthdayDay = tempMoment.get('date');
			const birthdayMonth = tempMoment.get('month');
			const birthday = new PMomentService(Config.LOCALE_ID).m().set('month', birthdayMonth).set('date', birthdayDay).startOf('day');
			const itemTimestampForCurrentYear = +birthday;
			return dayStart === itemTimestampForCurrentYear;
		});
	}

	/**
	 * Set all items to the given value.
	 * value is true by default.
	 */
	public setSelected(value : boolean = true) : void {
		for (const shift of this.iterable()) {
			shift.selected = value;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public containsAll( members : SchedulingApiMembers ) : boolean {
		let result = true;
		for (const member of members.iterable()) {
			if (!this.contains(member)) {
				result = false;
			}
		}
		return result;
	}

	/**
	 * Check if at least one item of this list is selected
	 */
	public get hasSelectedItem() : boolean {
		return this.some(item => item.selected);
	}

	/**
	 * Get all selected Items as a new SchedulingApiMembers().
	 */
	public get selectedItems() : SchedulingApiMembers {
		if (this._selectedItems === null) {
			this._selectedItems = new SchedulingApiMembers(this.api, null);
		}
		this._selectedItems.clear();
		const result = this._selectedItems;
		for (const item of this.iterable()) {
			if (item.selected) {
				result.push(item);
			}
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateSelectedStates() : void {
		for (const item of this.iterable()) {
			item.updateSelectedState();
		}
	}

	/**
	 * Check if there is at least one untrashed item
	 */
	public get hasUntrashedItem() : boolean {
		return this.some(item => !item.trashed);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public search(input : Parameters<SchedulingApiMember['fitsSearch']>[0]) : SchedulingApiMembers {
		return this.filterBy(item => item.fitsSearch(input));
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PParentName = string;

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignmentProcess extends SchedulingApiAssignmentProcessBase {

	public selectedState : boolean = false;

	public collapsed : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get affected() : boolean {
		if (this.selected) return false;

		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		for (const shift of this.api.data.shifts.selectedItems.iterable()) {
			if (shift.relatesTo(this)) {
				return true;
			}
		}
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get selected() : boolean {
		return this.selectedState;
	}
	public set selected( state : boolean ) {
		this.selectedState = state;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateSelectedState() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const relatedShifts = this.api.data.shifts.filterByAssignmentProcess(this);
		if (!relatedShifts.length || relatedShifts.length !== relatedShifts.selectedItems.length) {
			this.selectedState = false;
		}
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public containsAnyShift(shifts : SchedulingApiShifts) : boolean {
		return !!this.shiftRefs.containsAnyShift(shifts);
	}

	/**
	 *  The number of shifts for which the user has to do something.
	 */
	public get todoShiftsCountTotal() : number {
		return (
			this.todoShiftsCountCurrentView +
			this.todoShiftsCountRightView +
			this.todoShiftsCountLeftView
		);
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignmentProcesses extends SchedulingApiAssignmentProcessesBase {

	/**
	 * Set all items to the given value.
	 * value is true by default.
	 */
	public setSelected(value : boolean = true) : void {
		for (const item of this.iterable()) {
			item.selected = value;
		}
	}

	/**
	 * Check if at least one item of this list is selected
	 */
	public get hasSelectedItem() : boolean {
		return !!this.findBy(item => item.selected);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get selectedItems() : SchedulingApiAssignmentProcesses {
		const result : SchedulingApiAssignmentProcesses = new SchedulingApiAssignmentProcesses(this.api, null);
		for (const item of this.iterable()) {
			if (item.selected) {
				result.push(item);
			}
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateSelectedStates() : void {
		for (const item of this.iterable()) {
			item.updateSelectedState();
		}
	}

	/**
	 * Get the assignmentProcess where the provided shift is contained
	 */
	public getByShiftId(id : ShiftId) : SchedulingApiAssignmentProcess | null {
		for (const assignmentProcess of this.iterable()) {
			if (assignmentProcess.shiftRefs.contains(id)) {
				return assignmentProcess;
			}
		}
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public containsAnyShift(shifts : SchedulingApiShifts) : boolean {
		return !!this.findBy(item => item.containsAnyShift(shifts));
	}

	private getTotalOfNumberProperty(propertyName : keyof SchedulingApiAssignmentProcess) : number {
		const todoCountArray = this.iterable()
			.map(item => item[propertyName] as number);
		if (!todoCountArray.length) return 0;
		return todoCountArray.reduce((a, b) => a + b);
	}

	/**
	 * The number of shifts for which the user has to do something on the left side of current view. Read-only.
	 */
	public get todoShiftsCountLeftView() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountLeftView');
	}

	/**
	 * The number of shifts for which the user has to do something on the right side of current view. Read-only.
	 */
	public get todoShiftsCountRightView() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountRightView');
	}

	/**
	 * The number of shifts for which the user has to do something on the current view. Read-only.
	 */
	public get todoShiftsCountCurrentView() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountCurrentView');
	}

	/**
	 * The number of shifts for which the user has to do something in total. Read-only.
	 */
	public get todoShiftsCountTotal() : number {
		return this.getTotalOfNumberProperty('todoShiftsCountTotal');
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiRightGroups extends SchedulingApiRightGroupsBase {
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiMember extends SchedulingApiMemberBase implements ISchedulingApiMember {

	public isHovered : boolean = false;

	private selectedState : boolean = false;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get rightGroups() : SchedulingApiRightGroups {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		return this.api.data.rightGroups.filterBy(item => this.rightGroupIds.contains(item.id));
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public fitsSearch(term : string | null) : boolean {
		if (term === null) return true;
		if (term === '') return true;
		for (const termItem of term.split(' ')) {
			const termLow = termItem.toLowerCase();
			const firstNameLow = this.firstName.toLowerCase();
			const lastNameLow = this.lastName.toLowerCase();
			if (firstNameLow.includes(termLow)) continue;
			if (lastNameLow.includes(termLow)) continue;
			return false;
		}
		return true;
	}

	// eslint-disable-next-line sonarjs/no-identical-functions, jsdoc/require-jsdoc
	public get affected() : boolean {
		if (this.selected) return false;

		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		for (const shift of this.api.data.shifts.selectedItems.iterable()) {
			if (shift.relatesTo(this)) {
				return true;
			}
		}
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get selected() : boolean {
		return this.selectedState;
	}
	public set selected( state : boolean ) {
		this.selectedState = state;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public updateSelectedState() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const relatedShifts = this.api.data.shifts.filterBy(item => item.assignedMemberIds.contains(this.id));
		if (!relatedShifts.length || relatedShifts.length !== relatedShifts.selectedItems.length) {
			this.selectedState = false;
		}
	}

	/**
	 * @returns Returns from the assigned right groups the role with the highest power.
	 */
	public get role() : SchedulingApiRightGroupRole | null {
		let role = null;

		for (const rightGroupId of this.rightGroupIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const rightGroup = this.api.data.rightGroups.get(rightGroupId);
			if (rightGroup === null) throw new Error('Could not find RIGHT_GROUP');

			if (!role || rightGroup.role > role) role = rightGroup.role;
		}

		return role;
	}

	/**
	 * Check if this Member can read given shift.
	 * Note that this also checks the role (isOwner?)
	 */
	public canRead(shiftModelId : Id) : boolean | undefined {
		// Owners can read everything
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) return true;

		// Assignable Member can read
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const shiftModel = this.api.data.shiftModels.get(shiftModelId);
		if (!shiftModel) return undefined;
		if (shiftModel.attributeInfoAssignableMembers.isAvailable && shiftModel.assignableMembers.contains(this.id)) return true;

		// Members with right-access given from rights-management can read
		for (const rightGroupId of this.rightGroupIds.iterable()) {
			const rightGroup = this.api.data.rightGroups.get(rightGroupId);
			if (rightGroup === null) throw new Error('Could not find RIGHT_GROUP');
			const SHIFTMODEL_RIGHT = rightGroup.shiftModelRights.getByItem(shiftModelId);
			if (SHIFTMODEL_RIGHT?.canRead) return true;
		}

		return false;
	}

	/**
	 * @returns Can this Member write bookings of `shiftModel`?
	 */
	public canWriteBookings(shiftModel : SchedulingApiShiftModel) : boolean {
		// Owners can write bookings and everything
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) return true;

		// Members with permission from rights-management can canWriteBookings
		for (const rightGroupId of this.rightGroupIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const rightGroup = this.api.data.rightGroups.get(rightGroupId);
			if (rightGroup === null) throw new Error('Could not find RIGHT_GROUP');
			const SHIFTMODEL_RIGHT = rightGroup.shiftModelRights.getByItem(shiftModel.id);
			if (SHIFTMODEL_RIGHT?.canWriteBookings) return true;
		}

		return false;
	}

	/**
	 * @returns Can this Member execute online-refunds?
	 */
	public canOnlineRefund(shiftModel : SchedulingApiShiftModel) : boolean {
		// Owners can always online-refund
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) return true;

		// Members with permission from rights-management can online-refund
		for (const rightGroupId of this.rightGroupIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const rightGroup = this.api.data.rightGroups.get(rightGroupId);
			if (rightGroup === null) throw new Error('Could not find RIGHT_GROUP');
			const SHIFTMODEL_RIGHT = rightGroup.shiftModelRights.getByItem(shiftModel.id);
			if (SHIFTMODEL_RIGHT?.canOnlineRefund) return true;
		}

		return false;
	}

	/**
	 * Check if this Member can write given shift.
	 * Note that this also checks the role (isOwner?)
	 */
	public canWrite(
		item : (
			SchedulingApiShift |
			SchedulingApiShiftModel |
			SchedulingApiTodaysShiftDescription |
			Id
		),
	) : boolean {
		// Owners can write everything
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) return true;

		let shiftModelId : Id | null = null;
		if (item instanceof SchedulingApiShift) {
			shiftModelId = item.shiftModelId;
		} else if (item instanceof SchedulingApiShiftModel) {
			shiftModelId = item.id;
		} else if (item instanceof SchedulingApiTodaysShiftDescription) {
			shiftModelId = item.id.shiftModelId;
		} else if (item instanceof Id) {
			shiftModelId = item;
		}

		assumeDefinedToGetStrictNullChecksRunning(shiftModelId, 'shiftModelId', 'PLANO-FE-4KZ');

		for (const rightGroupId of this.rightGroupIds.iterable()) {
			const shiftModelRight = this.getShiftModelRight(rightGroupId, shiftModelId);

			if (shiftModelRight?.canWrite) return true;
		}

		return false;
	}

	private getShiftModelRight(rightGroupId : Id, shiftModelId : Id) : SchedulingApiRightGroupShiftModelRight | null	{
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const rightGroup = this.api.data.rightGroups.get(rightGroupId);
		assumeDefinedToGetStrictNullChecksRunning(rightGroup, 'rightGroup');
		return rightGroup.shiftModelRights.getByItem(shiftModelId);
	}

	/**
	 * Check if this Member can get Manager Notifications for the given shift, shiftModel, or a directly given id.
	 *
	 * If no item is provided, it will check if the member can get manager notifications for any of the existing shiftModels.
	 *
	 * Note that this also checks the role (isOwner?)
	 */
	public canGetManagerNotifications(item : SchedulingApiShift | SchedulingApiShiftModel | Id | null = null) : boolean {
		if (item) {
			const shiftModelId = (() => {
				if (item instanceof SchedulingApiShift) return item.shiftModelId;
				return item instanceof Id ? item : item.id;
			})();
			const isClientOwner = (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER);

			for (const rightGroupId of this.rightGroupIds.iterable()) {
				const shiftModelRight = this.getShiftModelRight(rightGroupId, shiftModelId);

				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
				if (	(shiftModelRight?.canGetManagerNotifications)	||
				(!shiftModelRight && isClientOwner)) {
					return true;
				}
			}

			return false;
		} else {
			// Check if member is getting manager notifications for any shift-model.
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			for (const shiftModel of this.api.data.shiftModels.iterable()) {
				if (!shiftModel.trashed && this.canGetManagerNotifications(shiftModel))
					return true;
			}

			return false;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hasManagerRights(item : SchedulingApiShift | SchedulingApiShiftModel | Id) : boolean {
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) return true;

		return this.canWrite(item) && this.canGetManagerNotifications(item);
	}

	/**
	 * Check if this Member can write at least one shiftModel.
	 */
	public get canWriteAnyShiftModel() : boolean {
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) {
			return true;
		}

		// any assigned right-group grants write permissions?
		for (const rightGroupId of this.rightGroupIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const rightGroup = this.api.data.rightGroups.get(rightGroupId);
			if (rightGroup === null) throw new Error('Could not find RIGHT_GROUP');
			for (const shiftModelRight of rightGroup.shiftModelRights.iterable()) {
				if (shiftModelRight.canWrite) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Check if this Member can read at least one shiftModel.
	 */
	public get canReadAnyShiftModel() : boolean {
		if (this.role === SchedulingApiRightGroupRole.CLIENT_OWNER) return true;

		// any assigned right-group grants write permissions?
		for (const rightGroupId of this.rightGroupIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const rightGroup = this.api.data.rightGroups.get(rightGroupId);
			if (rightGroup === null) throw new Error('Could not find RIGHT_GROUP');
			for (const shiftModelRight of rightGroup.shiftModelRights.iterable()) {
				if (shiftModelRight.canRead) return true;
			}
		}

		return false;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAccountingPeriodExpectedMemberData
	extends SchedulingApiAccountingPeriodExpectedMemberDataBase {

	/**
	 * NOTE: getByMember() is faster
	 */
	public getByMemberId(item : Id) : SchedulingApiAccountingPeriodExpectedMemberDataItem | null {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
		const member = this.api.data.members.get(item);
		if (!member) throw new Error('Could not find by member');
		return this.getByMember(member);
	}

	/**
	 * Gets by member. Doesn’t work if logged in user is not owner.
	 */
	public getByMember(item : SchedulingApiMember) : SchedulingApiAccountingPeriodExpectedMemberDataItem | null {
		return this.get(item.id);
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAccountingPeriod extends SchedulingApiAccountingPeriodBase {

}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAccountingPeriods extends SchedulingApiAccountingPeriodsBase {
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiRightGroup extends SchedulingApiRightGroupBase {

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public canGetManagerNotificationByItem(input : SchedulingApiShiftModel | Id | string) : boolean {
		const shiftModelRight = this.shiftModelRights.getByItem(input);

		if (shiftModelRight) return shiftModelRight.canGetManagerNotifications;

		return this.role === SchedulingApiRightGroupRole.CLIENT_OWNER;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiRightGroupShiftModelRights extends SchedulingApiRightGroupShiftModelRightsBase {

	/**
	 * Gets shiftModelRight by shiftModel or id of shiftModel
	 * If there is no shiftModelRight for the shiftModel it tries to find one for its parent
	 */
	public getByItem(input : SchedulingApiShiftModel | Id | SchedulingApiShiftModel['parentName']) : SchedulingApiRightGroupShiftModelRight | null {
		let result : SchedulingApiRightGroupShiftModelRight | null = null;

		// If its a string it can only be searched by Parent
		if (typeof input === 'string') return this.getByShiftModelParent(input);

		// If its not a string try to find rule for shiftModel
		const id = input instanceof SchedulingApiShiftModel ? input.id : input;
		result = this.getByShiftModel(id);
		if (result) return result;

		// Could not find rule for shiftModel
		// Try to find one for its Parent
		let shiftModel : SchedulingApiShiftModel;
		if (input instanceof SchedulingApiShiftModel) {
			shiftModel = input;
		} else {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const searchedShiftModel = this.api.data.shiftModels.get(input);
			assumeNonNull(searchedShiftModel, 'searchedShiftModel', `Could not find shiftModel »${input.toString()}« in »${this.api.data.shiftModels.length}« items.`);
			shiftModel = searchedShiftModel;
		}
		return this.getByShiftModelParent(shiftModel);
	}

	/**
	 * Gets shiftModelRight by shiftModel or id of shiftModel
	 */
	public getByShiftModel(input : SchedulingApiShiftModel | Id) : SchedulingApiRightGroupShiftModelRight | null {
		const id = input instanceof Id ? input : input.id;
		for (const shiftModelRight of this.iterable()) {
			if (shiftModelRight.shiftModelId?.equals(id)) {
				return shiftModelRight;
			}
		}
		return null;
	}

	/**
	 * Gets shiftModelRight by shiftModelParent or id of shiftModel
	 */
	public getByShiftModelParent(input : SchedulingApiShiftModel | SchedulingApiShiftModel['parentName']) : SchedulingApiRightGroupShiftModelRight | null {
		const shiftModelParent = input instanceof SchedulingApiShiftModel ? input.parentName : input;
		for (const shiftModelRight of this.iterable()) {
			if (shiftModelRight.shiftModelParentName === shiftModelParent) {
				return shiftModelRight;
			}
		}
		return null;
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignableShiftModel extends SchedulingApiMemberAssignableShiftModelBase {

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftModel() : SchedulingApiShiftModel {
		assumeNonNull(this.api, 'this.api', 'Api must be defined to get shiftmodels');
		return this.api.data.shiftModels.get(this.shiftModelId)!;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiAssignableShiftModels extends SchedulingApiMemberAssignableShiftModelsBase {

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public getByShiftModel(input : SchedulingApiShiftModel) : SchedulingApiAssignableShiftModel | undefined {
		for (const assignableShiftModel of this.iterable()) {
			if (assignableShiftModel.shiftModelId.equals(input.id)) {
				return assignableShiftModel;
			}
		}
		return undefined;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftModels() : SchedulingApiShiftModels {
		const result = new SchedulingApiShiftModels(this.api, null);
		for (const assignableShiftModel of this.iterable()) {
			const shiftModelToPush = assignableShiftModel.shiftModel;
			result.push(shiftModelToPush);
		}
		return result;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public containsShiftModel(item : SchedulingApiShiftModel) : boolean {
		return !!this.getByShiftModel(item);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public addNewShiftModel(
		shiftModel : SchedulingApiShiftModel,
		earning : number = 0,
	) : void {
		// NOTE: duplicate! This method exists here:
		// SchedulingApiAssignableShiftModels
		// SchedulingApiShiftModelAssignableMembers

		if (this.containsShiftModel(shiftModel)) return;

		const assignableShiftModel = this.createNewItem();
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		assignableShiftModel.hourlyEarnings = earning ? earning : 0;
		assignableShiftModel.shiftModelId = shiftModel.id;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public removeShiftModel(item : SchedulingApiShiftModel) : void {
		const assignableShiftModel = this.getByShiftModel(item);
		if (!assignableShiftModel) throw new Error('Could not get assignableShiftModel');
		this.removeItem(assignableShiftModel);
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiMemos extends SchedulingApiMemosBase {

	/**
	 * Get the Memo where the start is at the same day as the day of the provided timestamp
	 */
	public getByDay(dayStart : number) : SchedulingApiMemo | null {
		Assertions.ensureIsDayStart(dayStart);
		if (!dayStart) throw new Error('Can not get Memo. Timestamp is not defined.');

		for (const memo of this.iterable()) {
			// We assume that memo.start is start of day
			Assertions.ensureIsDayStart(memo.start);

			if (memo.start === dayStart) return memo;
		}
		return null;
	}

}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiTodaysShiftDescriptions extends SchedulingApiTodaysShiftDescriptionsBase {

}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiTodaysShiftDescription extends SchedulingApiTodaysShiftDescriptionBase {

	private _model : Data<SchedulingApiShiftModel | null> = new Data<SchedulingApiShiftModel>(this.api);

	/**
	 * shorthand that returns the related model
	 */
	public get model() : SchedulingApiShiftModel {
		// NOTE: This methods exists on multiple classes:
		// TimeStampApiShift
		// SchedulingApiShift
		// SchedulingApiBooking
		// SchedulingApiTodaysShiftDescription
		const SHIFT_MODEL = this._model.get(() => {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			return this.api.data.shiftModels.get(this.id.shiftModelId);
		});
		assumeNonNull(SHIFT_MODEL, 'SHIFT_MODEL');

		return SHIFT_MODEL;
	}

	/**
	 * Get the name based on the linked shiftModel
	 */
	public get name() : SchedulingApiShiftModel['name'] {
		// NOTE: This methods exists on multiple classes:
		// SchedulingApiRoot
		// TimeStampApiRoot
		return this.model.name;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isRequesterAssigned() : boolean {
		for (const assignedMemberId of this.assignedMemberIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			if (this.api.rightsService.isMe(assignedMemberId))
				return true;
		}

		return false;
	}

	/**
	 * A readonly getter for Members that are assigned.
	 * returns a new instance of SchedulingApiMembers.
	 */
	public get assignedMembers() : SchedulingApiMembers {
		const members = new SchedulingApiMembers(this.api, null);

		// TODO: PLANO-156519
		if (!this.attributeInfoAssignedMemberIds.isAvailable) return new SchedulingApiMembers(this.api, null);
		for (const memberId of this.assignedMemberIds.iterable()) {
			assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');
			const member = this.api.data.members.get(memberId);
			if (!member) throw new Error('Could not find by member');
			members.push(member);
		}

		// FIXME: PLANO--7458
		// https://drplano.atlassian.net/browse/PLANO-7458

		members.push = () => {
			throw new Error('assignedMembers is readonly');
		};
		members.remove = () => {
			throw new Error('assignedMembers is readonly');
		};

		return members;
	}
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiGiftCards extends SchedulingApiGiftCardsBase {
}

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingApiGiftCard extends SchedulingApiGiftCardBase {

	/**
	 * Setting this to `true` will activate min/max validators for `expirationDate` depending on `status`.
	 */
	public forceExpirationDateBeAlignedWithStatus = false;

	/**
	 * @returns All transactions belonging to this giftCard.
	 */
	public get transactions() : SchedulingApiTransactions {
		assumeDefinedToGetStrictNullChecksRunning(this.api, 'api');

		// TODO: PLANO-156519
		if (!this.api.data.attributeInfoTransactions.isAvailable) return new SchedulingApiTransactions(this.api, null, false);
		return this.api.data.transactions.filterBy(item => this.id.equals(item.giftCardId));
	}

	/**
	 * The full name of the booking person. If the name is not known `-` is returned.
	 */
	public get bookingPersonName() : string {
		if (!this.firstName && !this.lastName)
			return '–';

		return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
	}

	/**
	 * Does the shopper need to pay {@link price}?
	 */
	public get shopperNeedsToPayPrice() : boolean {
		return this.status === SchedulingApiGiftCardStatus.BOOKED || this.status === SchedulingApiGiftCardStatus.EXPIRED;
	}

	/**
	 * How much needs to be paid for this gift-card overall. This value is independent of how much has been paid already
	 * (i.e. `currentlyPaid`).
	 */
	public get amountToPay() : ClientCurrency | null {
		if (
			// eslint-disable-next-line unicorn/prefer-number-properties
			isNaN(this.cancellationFee) ||
			this.cancellationFee < 0
		) return null;
		let amountToPay = this.shopperNeedsToPayPrice ? this.price : 0;
		amountToPay += this.cancellationFee;
		return amountToPay;
	}

	/**
	 * Overall amount which can be refunded.
	 */
	public get refundableAmount() : ClientCurrency {
		return this.currentlyPaid;
	}

	/**
	 * Should we show the user that the current gift-card value is unknown?
	 * We dont show it when the user explicitly chose to ignore that warning.
	 */
	public get visualizeCurrentValueUnknown() : boolean {
		return !this.isCurrentValueKnown &&
			(!this.attributeInfoOverrideWaysToRedeem.isAvailable || this.overrideWaysToRedeem !== SchedulingApiGiftCardOverrideWaysToRedeem.IGNORE_WARNINGS);
	}

	/**
	 * @see SchedulingApiBookingBase#currentlyPaidWithoutLatestCreatedTransaction
	 */
	public get currentlyPaidWithoutLatestCreatedTransaction() : ClientCurrency {
		return SchedulingApiBookable.calculateCurrentlyPaidWithoutLatestCreatedTransaction(this, super.currentlyPaid);
	}

	/**
	 * @see SchedulingApiBookingBase#currentlyPaid
	 */
	public override get currentlyPaid() : ClientCurrency {
		return SchedulingApiBookable.calculateCurrentlyPaid(this, super.currentlyPaid);
	}

	/**
	 * @see SchedulingApiBookable.getOpenAmount
	 */
	public getOpenAmount(currentlyPaid : ClientCurrency | null = null) : ClientCurrency | null {
		return SchedulingApiBookable.getOpenAmount(this, currentlyPaid);
	}

	/**
	 * getter for the status of payment
	 */
	public get paymentStatus() : PPaymentStatusEnum | null {
		return SchedulingApiBookable.paymentStatus(this);
	}

	/**
	 * Is this gift-card canceled?
	 */
	public get isCanceled() : boolean {
		return this.status === SchedulingApiGiftCardStatus.CANCELED;
	}

	/**
	 * Was the gift-card created via a marketing gift-card?
	 */
	public get isMarketingGiftCard() : boolean {
		return this.createdBy === SchedulingApiBookableCreatedBy.MARKETING_GIFT_CARD;
	}

	/**
	 * Is the gift-card a refunded gift-card?
	 */
	public get isRefundGiftCard() : boolean {
		return this.createdBy === SchedulingApiBookableCreatedBy.MANUAL_REFUND_VIA_GIFT_CARD ||
			this.createdBy === SchedulingApiBookableCreatedBy.ONLINE_CANCELLATION_REFUND_VIA_GIFT_CARD;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- TODO: PLANO-167746 remove this
	public get state() : SchedulingApiGiftCardStatus { return this.status; }
	public set state(value : SchedulingApiGiftCardStatus) { this.status = value; }
}
