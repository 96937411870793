/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint @typescript-eslint/no-explicit-any: ['warn'] */
import { KeyValue, KeyValuePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, FormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ApiListWrapper } from '@plano/shared/api';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { AsyncValidatorsService } from '@plano/shared/core/async-validators.service';
import { Data } from '@plano/shared/core/data/data';
import { PrimitiveDataInput } from '@plano/shared/core/data/primitive-data-input';
import { LogService } from '@plano/shared/core/log.service';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNotUndefined } from '@plano/shared/core/utils/null-type-utils';
import { PPossibleErrorNames, PValidationErrorValue, PValidatorObject } from '@plano/shared/core/validators.types';
import { PFormArrayBasedOnAI, PFormControl, PFormControlSignatureObject, PFormControlSignatureObjectValidatorOrOptsType, PFormGroupBasedOnAI } from '@plano/shared/p-forms/p-form-control';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type VisibleErrorsType = KeyValue<PPossibleErrorNames, PValidationErrorValue>[];

@Injectable( { providedIn: 'root' } )

/**
 * Helper functions to build new FormGroups.
 */

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PFormsService {
	constructor(
		private console : LogService,
		private asyncValidatorsService : AsyncValidatorsService,
		private formBuilder : FormBuilder,
		private keyValuePipe : KeyValuePipe,
	) {
	}

	/**
	 * Add a FormControl to a FormArray.
	 */
	public addItemToFormArray(
		array : FormArray<PFormControl>,
		value : unknown,
	) : void {
		array.push(new PFormControl({
			formState: {
				value: value,
				disabled: false,
			},
		}));
	}

	/**
	 * Get the PFormControl that is related to the provided attributeInfo.
	 * If there is no related PFormControl in the provided FormGroup yet,
	 * one will be created and added.
	 *
	 * @deprecated  Use <p-ai-switch …> instead.
	 *              It is dangerous to use this in e.g. a getter. It would always create a new formControl when you 'get'
	 * 							it. But we want p-ai-switch to handle the creation and to destroying of the attributeInfo
	 * 							related formControls.
	 * 							So don’t use it. Instead do it like this:
	 * 							If you want to get it simply write this.group.controls[attributeInfo.id]
	 * 							If you want to create it, use pForms.addControlByAttInfo(…)
	 */
	public getByAI(
		group : FormGroup,
		attributeInfo : ApiAttributeInfo<any, unknown>,
		label : string | null = null,
		validatorObjects : PValidatorObject[] | null = null,
	) : PFormControl {
		if (!group.get(attributeInfo.id)) {
			this.addControlByAttInfo(
				group,
				attributeInfo,
				label,
				validatorObjects ?? null,
			);
		}
		return group.controls[attributeInfo.id] as PFormControl;
	}

	/** Get a form array by the id of the provided AI. If not available yet, the method will create one. */
	public getFormArrayByAI<
		AIType extends ApiAttributeInfo<any, ApiListWrapper<any>>,
		FormGroupType extends FormGroup<{ [key : string] : PFormArrayBasedOnAI }> = FormGroup<{ [key : string] : PFormArrayBasedOnAI }>,
	>(
		group : FormGroupType,
		attributeInfo : AIType,
	) : PFormArrayBasedOnAI {
		if (!group.get(attributeInfo.id)) return this.addFormArrayByAttInfo(group, attributeInfo);
		return group.controls[attributeInfo.id];
	}

	/** Get a form group by the id of the provided AI. If not available yet, the method will create one. */
	public getFormGroupByAI<
		AIType extends ApiAttributeInfo<any, unknown>,
		ParentType extends FormGroup<{ [key : string] : FormGroup }> | FormArray<PFormGroupBasedOnAI> = FormGroup<{ [key : string] : FormGroup }>,
	>(
		parent : ParentType,
		attributeInfo : AIType,
	) : FormGroup {
		if (parent instanceof FormGroup) {
			if (!parent.get(attributeInfo.id)) return this.addFormGroupByAttInfo(parent, attributeInfo);
			return parent.controls[attributeInfo.id];
		}

		const controlAlreadyExists = parent.controls.some(item => item.ai.id === attributeInfo.id);
		if (!controlAlreadyExists) return this.addFormGroupByAttInfo(parent, attributeInfo);
		const result = parent.controls.find(item => item.ai.id === attributeInfo.id);
		assumeNotUndefined(result, 'result', `Could not find FormGroup for ${attributeInfo.id} in FormArray`);
		return result;
	}

	/**
	 * A Method to create a new PFormControl by passing a ApiAttributeInfo
	 */
	private addControlByAttInfo(
		formGroup : FormGroup,
		attributeInfo : ApiAttributeInfo<any, any>,
		label : string | null = null,
		validatorObjects : PValidatorObject[] | null,
	) : void {
		const asyncValidators = attributeInfo.primitiveType ?
			this.getAsyncValidatorsForPrimitiveType(attributeInfo.primitiveType, attributeInfo.asyncValidations) :
			null;

		this.addPControl(
			formGroup,
			attributeInfo.id,
			{
				formState: {
					value: attributeInfo.value,

					// TODO: This requires this.api to be defined.
					// So i can not use this for unit tests or storybook yet.
					// disabled: !attributeInfo.canSet,
					disabled: !attributeInfo.canSet,
				},
				labelText: label ?? null,
				asyncValidator: asyncValidators ?? null,
				attributeInfo: attributeInfo,
				validatorObjects: validatorObjects,
				subscribe: (newValue) => {
					if (attributeInfo.canSet && attributeInfo.value !== newValue) attributeInfo.value = newValue;
				},
			},
		);
	}

	/**
	 * A Method to create a new PFormControl by passing a ApiAttributeInfo
	 */
	private addFormArrayByAttInfo(
			formGroup : FormGroup,
			attributeInfo : ApiAttributeInfo<any, any>,
	) : PFormArrayBasedOnAI<any, ApiAttributeInfo<any, any>> {
		const asyncValidatorFns = this.getAsyncValidatorsFromValidatorObjects(attributeInfo.asyncValidations);
		const validatorFns = this.getValidatorsFromValidatorObjects(attributeInfo.validations);
		const newFormArray = new PFormArrayBasedOnAI<any>(attributeInfo, [], validatorFns, asyncValidatorFns);

		formGroup.addControl(attributeInfo.id, newFormArray);
		newFormArray.setParent(formGroup);
		return newFormArray;
	}

	/**
	 * A Method to create a new PFormControl by passing a ApiAttributeInfo
	 * @deprecated Use <p-ai-form-group …> in your template instead.
	 */
	public addFormGroupByAttInfo(
		formParent : FormGroup | FormArray,
		attributeInfo : ApiAttributeInfo<any, any>,
	) : PFormGroupBasedOnAI<any, ApiAttributeInfo<any, any>> {
		const validatorFns = this.getValidatorsFromValidatorObjects(attributeInfo.validations);
		const asyncValidatorFns = this.getAsyncValidatorsFromValidatorObjects(attributeInfo.asyncValidations);
		const newFormGroup = new PFormGroupBasedOnAI(attributeInfo, {}, validatorFns, asyncValidatorFns);

		if (formParent instanceof FormGroup) {
			formParent.addControl(attributeInfo.id, newFormGroup);
		} else {
			formParent.push(newFormGroup);
		}
		newFormGroup.setParent(formParent);
		return newFormGroup;
	}

	/**
	 * Very often i need a control with a subscriber.
	 * This function is just a helper for easier code writing.
	 */
	public addPControl(
		formGroup : FormGroup,
		name : string,
		pFormControlContent : PFormControlSignatureObject,
	) : void {
		const newControl = new PFormControl(pFormControlContent);
		formGroup.addControl(
			name,
			newControl,
		);
		// eslint-disable-next-line @typescript-eslint/ban-types
		newControl.setParent(formGroup as UntypedFormGroup);
	}

	/** Turn validationObjects of async validators in a form that angular understands */
	public getAsyncValidatorsFromValidatorObjects(asyncValidations : ApiAttributeInfo<any, any>['asyncValidations']) : AsyncValidatorFn[] {
		return asyncValidations
			.map((item) => item())
			.filter((item) : item is PValidatorObject<'async'> => item instanceof PValidatorObject<'async'>)
			.map(item => item.fn);
	}

	/** Turn validationObjects of validators in a form that angular understands */
	private getValidatorsFromValidatorObjects(validations : ApiAttributeInfo<any, any>['validations']) : ValidatorFn[] {
		return validations
			.map((item) => item())
			.filter((item) : item is PValidatorObject => item instanceof PValidatorObject)
			.map(item => item.fn);
	}

	/**
	 * Remove from formGroup and leave no traces 🤫
	 */
	public removePControl(
		formGroup : FormGroup,
		name : string,
	) : void {
		const CONTROL = formGroup.get(name) as PFormControl;
		CONTROL.unsubscribe();
		formGroup.removeControl(name);
	}

	/**
	 * @deprecated Please use addPControl instead
	 *
	 * Very often i need a control with a subscriber.
	 * This function is just a helper for easier code writing.
	 */
	public addControl(
		formGroup : FormGroup,
		name : string,
		input : {
			value ?: unknown,
			disabled ?: boolean,
		},
		validators : PFormControlSignatureObjectValidatorOrOptsType = [],
		subscribe ?: (value : any) => void,
		asyncValidator ?: PValidatorObject<'async'> | PValidatorObject<'async'>[],
	) : void {
		formGroup.addControl(
			name,
			new PFormControl({
				formState: {
					value : input.value,
					disabled: input.disabled,
				},
				validatorObjects: validators ?? null,
				subscribe: subscribe,
				asyncValidator: asyncValidator ?? null,
			}),
		);
	}

	/**
	 * This function is just a helper for easier code writing.
	 */
	public addArray(
		formGroup : FormGroup,
		name : string,
		input : PFormControl[],
		validators : ValidatorFn[] = [],
		subscribe ?: (value : unknown) => void,
		asyncValidator ?: AsyncValidatorFn | AsyncValidatorFn[],
	) : void {
		const newFormArray = new UntypedFormArray(input, validators, asyncValidator);
		formGroup.addControl(name, newFormArray);
		newFormArray.valueChanges.subscribe((value) => {
			if (subscribe) { subscribe(value); }
		});
	}

	/**
	 * Same like addControl, but for FormGroup
	 * @deprecated Use <p-ai-form-group …> in your template instead.
	 */
	public addFormGroup(
		parentFormGroup : FormGroup,
		name : string,
		childFormGroup : FormGroup,
	) : void {
		parentFormGroup.addControl(name, childFormGroup);
	}

	/**
	 * This logs all errors of a formGroup to the browser console.
	 *
	 * NOTE: Sadly it does not log all errors. It has issues with nested formGroups and nested formArrays
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	public getFormValidationErrors(formGroup : FormGroup | UntypedFormArray | null = null) : ValidationErrors | null {
		if (!formGroup) return null;
		const result : ValidationErrors = [];
		for (const key of Object.keys(formGroup.controls)) {
			const control = formGroup.get(key);
			if (!control) throw new Error(`Could not find control ${key}`);
			const controlErrors = control.errors;
			if (controlErrors === null) continue;

			for (const keyError of Object.keys(controlErrors)) {
				result[keyError] = controlErrors[keyError];
				this.console.log(`Key control: ${key}, keyError: ${keyError}, err value: `, controlErrors[keyError]);
			}
		}
		return result;
	}

	/**
	 * Get all async validators that should ALWAYS apply to the provided primitiveType
	 */
	public getAsyncValidatorsForPrimitiveType(primitiveType : PApiPrimitiveTypes, attributeInfoAsyncValidators : (() => PValidatorObject<'async'> | null)[] = []) : PValidatorObject<'async'>[] {
		switch (primitiveType) {
			case PApiPrimitiveTypes.Date:
			case PApiPrimitiveTypes.DateExclusiveEnd:
			case PApiPrimitiveTypes.DateTime:
			case PApiPrimitiveTypes.Enum:
			case PApiPrimitiveTypes.Id:
			case PApiPrimitiveTypes.LocalTime:
			case PApiPrimitiveTypes.ShiftId:
			case PApiPrimitiveTypes.ShiftSelector:
			case PApiPrimitiveTypes.any:
			case PApiPrimitiveTypes.boolean:
			case PApiPrimitiveTypes.number:
			case PApiPrimitiveTypes.string:
			case PApiPrimitiveTypes.Search:
			case PApiPrimitiveTypes.Url:
			case PApiPrimitiveTypes.Iban:
			case PApiPrimitiveTypes.Bic:
			case PApiPrimitiveTypes.PostalCode:
			case PApiPrimitiveTypes.Tel:
			case PApiPrimitiveTypes.Password:
			case PApiPrimitiveTypes.ClientCurrency:
			case PApiPrimitiveTypes.Euro:
			case PApiPrimitiveTypes.Integer:
			case PApiPrimitiveTypes.Duration:
			case PApiPrimitiveTypes.Minutes:
			case PApiPrimitiveTypes.Hours:
			case PApiPrimitiveTypes.Days:
			case PApiPrimitiveTypes.Percent:
			case PApiPrimitiveTypes.Months:
			case PApiPrimitiveTypes.Years:
			case PApiPrimitiveTypes.Image:
			case PApiPrimitiveTypes.Pdf:
			case PApiPrimitiveTypes.Color:
			case PApiPrimitiveTypes.ApiList:
				return [];
			case PApiPrimitiveTypes.Email:
				if (attributeInfoAsyncValidators.length > 0) {
					for (const asyncValidatorFromAI of attributeInfoAsyncValidators) {
						if (asyncValidatorFromAI.name === 'bound emailValidAsync') {
							return [];
						}
					}
				}
				return [ this.asyncValidatorsService.emailValidAsync(false) ];
		}
	}

	private getMinErrors(keyValueArray : VisibleErrorsType) : VisibleErrorsType {
		return keyValueArray.filter(error => {
			if (error.key === PPossibleErrorNames.MIN) return true;
			if (error.key === PPossibleErrorNames.GREATER_THAN) return true;
			return false;
		});
	}
	private getFormattingErrors(keyValueArray : VisibleErrorsType) : VisibleErrorsType {
		return keyValueArray.filter(error => {
			if (error.key === PPossibleErrorNames.FLOAT) return true;
			if (error.key === PPossibleErrorNames.INTEGER) return true;
			if (error.key === PPossibleErrorNames.NUMBER_NAN) return true;
			return false;
		});
	}

	private getTypeRelatedFormattingErrors(keyValueArray : VisibleErrorsType) : VisibleErrorsType {
		return keyValueArray.filter(error => error.key === PPossibleErrorNames.CURRENCY);
	}

	private getDetailedFormattingErrors(keyValueArray : VisibleErrorsType) : VisibleErrorsType {
		// TODO: PLANO-168739 Check if this code is obsolete after PLANO-168739 is done.
		/*
		 * Imagine you type 13.0 into a 'Currency' field while locale is 'de'.
		 * There should be one error for the field. But there are two.
		 *
		 * The one error should say that it is not a valid (german) currency/number, as german numbers
		 * have ',' as decimal separator and '.' as thousand separator. So
		 * '13,0' would be a valid german number and
		 * '13.0000' would be a valid german number as well.
		 *
		 * But the component still writes the string '13.0' into the ai as long as PLANO-168739 is not done.
		 * The ai validates the decimal places count. The validator for decimal places count accepts string as input as
		 * long as PLANO-168739 is not done.
		 * The string '13.0' will then be checked for max decimal places count and will fail.
		 * In that case there will be two errors for the same field - and CURRENCY is the more important one.
		 */
		const nanErrors = keyValueArray.filter(error => error.key === PPossibleErrorNames.CURRENCY);
		const maxDecimalPlacesCountErrors = keyValueArray.filter(error => error.key === PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT);
		if (nanErrors.length && maxDecimalPlacesCountErrors.length) return nanErrors;

		return maxDecimalPlacesCountErrors;
	}

	private visibleErrorsCache = new Map<AbstractControl, Data<VisibleErrorsType>>();

	/**
	 * Some validation information's have a higher priority than others.
	 *
	 * Example:
	 * Lets say we have a value, where the component validator says the min length is not ok for that type, but at the
	 * same time a required validator from the ai, because the ai.value is still null. Then the user should only see the
	 * min error.
	 *
	 * @param formControl The form control to be checked
	 * @param keyValueArray The errors of the control
	 */
	private getErrorsByPriority(formControl : AbstractControl, keyValueArray : VisibleErrorsType) : VisibleErrorsType | null {
		const MIN_ERRORS = this.getMinErrors(keyValueArray);
		if (MIN_ERRORS.length) return this.getUpdatedCacheValue(formControl, MIN_ERRORS[0]);

		const DETAILED_FORMATTING_ERRORS = this.getDetailedFormattingErrors(keyValueArray);
		if (DETAILED_FORMATTING_ERRORS.length) return this.getUpdatedCacheValue(formControl, DETAILED_FORMATTING_ERRORS[0]);

		// Some formatting issues, like when user is about to type 10,5 and typed 10 in a 'Days' field, lead to nullish values.
		// In that case its better to show the formatting error before complaining about required.
		const FORMATTING_ERRORS = this.getFormattingErrors(keyValueArray);
		if (FORMATTING_ERRORS.length) return this.getUpdatedCacheValue(formControl, FORMATTING_ERRORS[0]);

		const TYPE_RELATED_FORMATTING_ERRORS = this.getTypeRelatedFormattingErrors(keyValueArray);
		if (TYPE_RELATED_FORMATTING_ERRORS.length) return this.getUpdatedCacheValue(formControl, TYPE_RELATED_FORMATTING_ERRORS[0]);

		const REQUIRED_ERRORS = keyValueArray.filter(error => error.key === PPossibleErrorNames.REQUIRED);
		if (REQUIRED_ERRORS.length) return this.getUpdatedCacheValue(formControl, REQUIRED_ERRORS[0]);

		return null;
	}

	private getFilteredErrors(
		formControl : AbstractControl,
		keyValueArray : VisibleErrorsType,
		additionalFilterKeys : PPossibleErrorNames[],
	) : VisibleErrorsType {
		return keyValueArray.filter(error => {
			if (additionalFilterKeys.includes(error.key)) return false;
			switch (error.key) {
				case PPossibleErrorNames.FLOAT :
					assumeDefinedToGetStrictNullChecksRunning(formControl, 'formControl');
					return !(formControl.errors && (
						formControl.errors[PPossibleErrorNames.INTEGER] ||
						formControl.errors[PPossibleErrorNames.MAX_DECIMAL_PLACES_COUNT]
					));
				default :
					return true;
			}
		}).splice(0, 1);
	}

	/**
	 * Errors that should be shown to the UI transformed to a array objects with key/value pairs.
	 */
	public visibleErrors(formControl : AbstractControl, additionalFilterKeys : PPossibleErrorNames[] = []) : VisibleErrorsType {
		if (!this.visibleErrorsCache.has(formControl)) {
			this.visibleErrorsCache.set(formControl, new Data<VisibleErrorsType>(
				new PrimitiveDataInput(() => formControl.value),
				new PrimitiveDataInput(() => formControl.status),
				new PrimitiveDataInput(() => formControl.errors),
			));
		}
		return this.visibleErrorsCache.get(formControl)!.get(() => {
			if (formControl.errors === null) return [];
			if (!Object.values(formControl.errors).length) return [];

			const keyValueArray = this.keyValuePipe.transform(formControl.errors) as VisibleErrorsType;

			const PRIORITIZED_ERRORS = this.getErrorsByPriority(formControl, keyValueArray);
			if (PRIORITIZED_ERRORS) return PRIORITIZED_ERRORS;

			const filteredErrors = this.getFilteredErrors(formControl, keyValueArray, additionalFilterKeys);

			const control = this.visibleErrorsCache.get(formControl)!;

			if (filteredErrors.length > 0) {
				const filteredError = filteredErrors.at(0)!;
				if (!this.visibleErrorsCache.has(formControl)) {
					return [filteredError];
				}
				const cachedError = control.currentCachedValue?.[0];
				if (!cachedError) {
					return [filteredError];
				}
				if (cachedError.key !== filteredError.key || cachedError.value.name !== filteredError.value.name) {
					cachedError.key = filteredError.key;
					cachedError.value = filteredError.value;
				}
				return control.currentCachedValue;
			} else {
				if (this.visibleErrorsCache.has(formControl)) {
					this.visibleErrorsCache.delete(formControl);
					return [];
				}
			}
			return control.currentCachedValue!;
		});

	}

	/**
	 * @param formControl The form control to be removed from the cache
	 */
	public removeFormControlFromCache(formControl : AbstractControl) : void {
		this.visibleErrorsCache.delete(formControl);
	}

	/**
	 * Same as formBuilder.group() but with better typing.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	public group<T extends {}>(controls : T, options ?: AbstractControlOptions | null) : UntypedFormGroup {
		return this.formBuilder.group<T>(controls, options);
	}

	/**
	 * Will update the cache and return the correct value
	 */
	private getUpdatedCacheValue(formControl : AbstractControl, error : KeyValue<PPossibleErrorNames, PValidationErrorValue>) : VisibleErrorsType {
		const control = this.visibleErrorsCache.get(formControl)!;
		if (control.currentCachedValue?.[0]) {
			const cachedError = control.currentCachedValue[0];
			if (error.key !== cachedError.key || error.value.name !== cachedError.value.name) {
				cachedError.key = error.key;
				cachedError.value = error.value;
			}
		} else {
			return [error];
		}
		return control.currentCachedValue;
	}
}
