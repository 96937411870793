<p-bootstrap-form-group [label]="label">
	<div class="d-flex w-100 justify-content-between flex-column flex-lg-row col-gap-3">
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnMonday)"
			[valueText]="Config.IS_MOBILE?'Montag':'Mo' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnTuesday)"
			[valueText]="Config.IS_MOBILE?'Dienstag':'Di' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnWednesday)"
			[valueText]="Config.IS_MOBILE?'Mittwoch':'Mi' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnThursday)"
			[valueText]="Config.IS_MOBILE?'Donnerstag':'Do' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnFriday)"
			[valueText]="Config.IS_MOBILE?'Freitag':'Fr' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnSaturday)"
			[valueText]="Config.IS_MOBILE?'Samstag':'Sa' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			[group]="group"
			[attributeInfo]="$any(repetition.attributeInfoIsRepeatingOnSunday)"
			[valueText]="Config.IS_MOBILE?'Sonntag':'So' | localize"
			[hasButtonStyle]="false"
			[size]="enums.BootstrapSize.SM"
		></p-ai-switch>
	</div>
</p-bootstrap-form-group>
