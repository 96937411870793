import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BootstrapGridWidth, BootstrapPaddings, BootstrapSizePool, PBackgroundColorEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PTabsComponent } from '@plano/client/shared/p-tabs/p-tabs/p-tabs.component';
import { PHeadlineComponent } from '@plano/shared/core/component/p-headline/p-headline.component';
import { OddEvenDirective, OddOrEvenInfo } from '@plano/shared/core/directive/odd-even-parent.directive';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PlanoFaIconPoolValues } from '@plano/shared/core/utils/plano-fa-icon-pool.enum';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion } from '@plano/shared/core/utils/typescript-utils-types';
import { Subscription } from 'rxjs';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export enum SectionWhitespace {
	MEDIUM, // TODO: Rename to MD
	NONE,
	LG, // Equal to p-tabs LG
}

type VerticalPaddingClasses = {
	top : `pt-${string}`[];
	bottom : `pb-${string}`[];
};

/**
 * A section is a wrapper for content that should be grouped together.
 * Usually a section has a headline.
 * Note that the people who write gherkins consider almost everything that has a headline a section.
 *
 * @example
 * <p-section label="My Section">
 * 	<p>Some text</p>
 * </p-section>
 *
 * @example
 * With background color a p-section should usually go all the way to the edge of the screen,
 * so a bootstrap container class is needed
 * <p-section label="My Section" background="light-cold" containerSize="sm">
 * 	<p>Some text</p>
 * </p-section>
 */
@Component({
	selector: 'p-section[label][pAnchorLink], p-section:not([pAnchorLink]):not([label])',
	templateUrl: './section.component.html',
	styleUrls: ['./section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PSectionComponent implements AfterViewInit, OnInit, OnDestroy {
	@HostBinding('class.d-flex')
	@HostBinding('class.flex-column')
	protected _alwaysTrue = true;

	/**
	 * Should the elements of this section be separated
	 * into a grid with a descriptive and a visual element
	 * side by side? If so, it is required that one child of the section
	 * is marked as descriptive and the other as visual.
	 */
	@Input() public isDescriptiveVisualGrid = false;

	/**
	 * Icon that will be added to the label of this section
	 */
	@Input() public sectionIcon : PlanoFaIconPoolValues | null = null;

	/** @see PHeadlineComponent#level */
	@Input() public headlineLevel : PHeadlineComponent['level'] = 4;

	/**
	 * Is the descriptive block on the left of the grid?
	 * To be used in combination with isDescriptiveVisualGrid
	 */
	@Input() public isDescriptiveLeft = true;

	/**
	 * Use the classes to establish the vertical padding
	 * instead of internal calculations.
	 */
	@Input() public useClassesPadding = false;

	/**
	 *	Is the label wrapped in an h1 and centered on the page?
	 */
	@Input() public headlineClasses : string = '';

	/** The headline of this component */
	@Input() public label : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hasDanger : boolean = false;

	/**
	 * Should the content of the p-section be wrapped in a container?
	 */
	@Input() public containerSize : 'default' | 'sm' | null = null;

	/**
	 * Background color of the whole Section
	 */
	@Input() public background : ExtractFromUnion<'light' | 'dark', PThemeEnum> | 'light-cold' | PBackgroundColorEnum.WHITE | null = null;

	/**
	 * How much whitespace should there be horizontally?
	 */
	@Input() public whitespace : SectionWhitespace = SectionWhitespace.MEDIUM;

	@HostBinding('attr.aria-label') private get ariaLabel() : string | null {
		return this.label;
	}

	@HostBinding('class.p-0') private get noPaddingInsideModal() : boolean {
		return this.isInsideModalWithPadding;
	}

	/**
	 * The grid breakpoint where the grid should be split into two columns
	 * @default md
	 */
	@Input() public gridBreakpoint : BootstrapSizePool = enumsObject.BootstrapSize.MD;

	/**
	 * The area that should be displayed first in the grid, when the grid is one column (usually on mobile)
	 */
	@Input() private areaWithHighestOrder : 'descriptive' | 'visual' = 'visual';

	/**
	 * How wide should the descriptive area be above the breakpoint?
	 * Width of the visual area will be the remaining space.
	 */
	@Input() protected gridDescriptiveSize : Exclude<BootstrapGridWidth, 12> = 7;

	constructor(
		public elementRef : ElementRef<HTMLElement>,
		public changeDetectorRef : ChangeDetectorRef,
		private modalService : ModalService,
		private zone : NgZone,
		@Optional() private oddEvenParent ?: OddEvenDirective,
		@Optional() public pTabsParent ?: PTabsComponent,
		@Optional() @SkipSelf() public pSectionParent ?: PSectionComponent,
	) {
	}

	public oddOrEvenInfo : OddOrEvenInfo | null = null;

	private isSectionParentChildOfOddEven() : boolean {
		if (!this.oddEvenParent || !this.pSectionParent) return false;

		return this.pSectionParent.oddEvenParent === this.oddEvenParent;
	}

	/**
	 * return the vertical padding classes for this section,
	 * separated in an object with the classes to be applied to the top and to the bottom
	 */
	private verticalPaddingOfSection() : VerticalPaddingClasses {
		const verticalPaddingClasses : VerticalPaddingClasses = {top: [], bottom: []};
		if (this.useClassesPadding) return verticalPaddingClasses;
		let paddingNumber : BootstrapPaddings = 3;
		const isNextDifferent : boolean = !!this.oddOrEvenInfo && this.oddEvenParent!.isNextDifferent(this.oddOrEvenInfo.assignedIndex);
		const isPreviousDifferent : boolean = !!this.oddOrEvenInfo && this.oddEvenParent!.isPreviousDifferent(this.oddOrEvenInfo.assignedIndex);

		switch (this.whitespace) {
			case SectionWhitespace.MEDIUM:
				paddingNumber = 3;
				break;
			case SectionWhitespace.LG:
				paddingNumber = 4;
				break;
			case SectionWhitespace.NONE:
				break;
			default:
				break;
		}

		if (isNextDifferent && isPreviousDifferent) {
			verticalPaddingClasses.top.push('pt-5');
			verticalPaddingClasses.bottom.push('pb-5');
		} else if (isNextDifferent) {
			verticalPaddingClasses.bottom.push('pb-5');
			verticalPaddingClasses.top.push(`pt-${paddingNumber}`);
		} else if (isPreviousDifferent) {
			verticalPaddingClasses.top.push('pt-5');
			verticalPaddingClasses.bottom.push(`pb-${paddingNumber}`);
		}

		if (!this.oddOrEvenInfo || !(isNextDifferent || isPreviousDifferent)) {
			verticalPaddingClasses.top.push(`pt-${paddingNumber}`);
			verticalPaddingClasses.bottom.push(`pb-${paddingNumber}`);
		}
		return verticalPaddingClasses;
	}

	private horizontalPaddingOfSection() : string[] {
		const result : string [] = [];
		if (this.useClassesPadding) return result;
		switch (this.whitespace) {
			case SectionWhitespace.MEDIUM:
				result.push('px-3', 'px-lg-4');
				break;
			case SectionWhitespace.LG:
				result.push('px-4', 'px-lg-5');
				break;
			case SectionWhitespace.NONE:
				break;

			default:
				break;
		}
		return result;
	}

	private modalOpenSubscription : Subscription | null = null;

	private isInsideModalWithPadding : boolean = false;

	private isInsideModalWithoutPadding : boolean = false;

	private handleInsideModalBooleans(modalBodyElement : Element) : void {
		const modalPadding = Number.parseFloat(window.getComputedStyle(modalBodyElement).padding);
		if (modalPadding > 0)
			this.isInsideModalWithPadding = true;
		else {
			this.isInsideModalWithoutPadding = true;
		}
	}

	/**
	 * Handle padding of a section when inside a modal
	 */
	private handlePaddingInsideModal() : void {
		// get the modal body element from the DOM
		const modalBodyElement : Element | null = Array.from(document.querySelectorAll('.modal-body')).at(-1) ?? null;

		// check if the section is inside the opened modal
		if (modalBodyElement?.contains(this.elementRef.nativeElement)) {

			// if the section is inside a modal, it should never have the container class
			if (this.containerSize !== null) {
				this.containerSize = null;
				this.changeDetectorRef.detectChanges();
			}

			this.elementRef.nativeElement.classList.remove(...this.horizontalPaddingOfSection());

			// set the booleans based on if the opened modal has already some padding or not
			this.handleInsideModalBooleans(modalBodyElement);

			if (this.isInsideModalWithoutPadding) {
				this.elementRef.nativeElement.classList.add('px-3');
			}

			// get a list of the sections inside the modal
			const sectionsInsideModal = Array.from(modalBodyElement.querySelectorAll('p-section'));

			const verticalPaddings = this.verticalPaddingOfSection();
			if (sectionsInsideModal.at(-1) === this.elementRef.nativeElement) {

				// if the section is the last one, we remove the calculated padding bottom
				this.elementRef.nativeElement.classList.remove(...verticalPaddings.bottom);

				// if the modal has no padding we add the default one
				if (this.isInsideModalWithoutPadding)
					this.elementRef.nativeElement.classList.add('pb-3');
			}

			if (sectionsInsideModal.at(0) === this.elementRef.nativeElement) {

				// if the section is the first one, we remove the padding top
				this.elementRef.nativeElement.classList.remove(...verticalPaddings.top);

				// if the modal has no padding we add the default one
				if (this.isInsideModalWithoutPadding)
					this.elementRef.nativeElement.classList.add('pt-3');
			}
		}
	}

	/**
	 * Set a subscriber that will be triggered anytime a modal gets opened,
	 * if the section is inside the opened modal we adjust the paddings
	 */
	private handleSubscriberToSectionsInsideModals() : void {
		this.modalOpenSubscription = this.modalService.modalStateOpenSubject.subscribe(() => {
			this.zone.runOutsideAngular(() => {
				this.handlePaddingInsideModal();
			});
		});
	}

	public ngOnInit() : void {
		if (this.useClassesPadding) return;
		this.handleSubscriberToSectionsInsideModals();
	}

	private handleSectionPaddings() : void {
		this.elementRef.nativeElement.classList.add(...this.horizontalPaddingOfSection());
		const verticalPaddings = this.verticalPaddingOfSection();
		this.elementRef.nativeElement.classList.add(...verticalPaddings.top, ...verticalPaddings.bottom);
	}

	public ngAfterViewInit() : void {
		if (this.background !== null) {
			this.elementRef.nativeElement.classList.add(`bg-${this.background}`);
			if (this.oddEvenParent)
				this.oddOrEvenInfo = this.oddEvenParent.requestOddOrEven(false);
		} else {
			if (this.oddOrEvenInfo === null && this.oddEvenParent && !this.isSectionParentChildOfOddEven()) {
				this.oddOrEvenInfo = this.oddEvenParent.requestOddOrEven();
			}
			if (this.oddOrEvenInfo && this.oddOrEvenInfo.oddOrEven === 'odd') {
				this.background = 'light-cold';
				this.elementRef.nativeElement.classList.add(`bg-${this.background}`);
				if (this.isDescriptiveVisualGrid)
					this.isDescriptiveLeft = false;
			}
		}

		this.handleSectionPaddings();

		// update the container size if the parent or the grandparent are tabs with the side theme
		if (this.containerSize === null &&
			this.pTabsParent &&
			(!this.pTabsParent.hasNotSideTheme || this.pTabsParent.pTabsParent &&
			!this.pTabsParent.pTabsParent.hasNotSideTheme)) {
			this.containerSize = 'default';
			this.changeDetectorRef.detectChanges();
		}

		this.handlePaddingInsideModal();
	}

	/**
	 * Get the order of the descriptive template
	 */
	protected get descriptiveTemplateOrder() : number {
		return this.areaWithHighestOrder === 'descriptive' ? 1 : 2;
	}

	/**
	 * Get the order of the visual template
	 */
	protected get visualTemplateOrder() : number {
		return this.descriptiveTemplateOrder === 1 ? 2 : 1;
	}

	/**
	 * Get the margin bottom of area of the section when the grid is only one column (usually on mobile)
	 */
	protected get descriptiveTemplateMarginBottom() : 0 | 1 | 2 | 3 | 4 | 5 {
		return this.descriptiveTemplateOrder === 1 ? 4 : 0;
	}

	/**
	 * Get the margin bottom of area of the section when the grid is only one column (usually on mobile)
	 */
	protected get visualTemplateMarginBottom() : 0 | 1 | 2 | 3 | 4 | 5 {
		return this.visualTemplateOrder === 1 ? 4 : 0;
	}

	public ngOnDestroy() : void {
		this.modalOpenSubscription?.unsubscribe();
	}

	/**
	 * How wide should the visual area be above the breakpoint?
	 */
	protected get gridVisualSize() : Exclude<BootstrapGridWidth, 12> {
		return 12 - this.gridDescriptiveSize as Exclude<BootstrapGridWidth, 12>;
	}
}
