import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { RightsService } from '@plano/client/accesscontrol/rights.service';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { PCalendarService } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar.service';
import { PAllDayItemsListComponent } from '@plano/client/scheduling/shared/p-scheduling-calendar/p-calendar/p-all-day-items-list/p-all-day-items-list.component';
import { FilterService } from '@plano/client/shared/filter.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { SchedulingApiService, SchedulingApiShifts } from '@plano/shared/api';
import { Data } from '@plano/shared/core/data/data';
import { PrimitiveDataInput } from '@plano/shared/core/data/primitive-data-input';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { MonthViewDay } from 'calendar-utils';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
	selector: 'p-month-cell[day][shifts][absences][holidays][birthdays]',
	templateUrl: './month-cell.component.html',
	styleUrls: ['./month-cell.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class MonthCellComponent extends UniqueAriaLabelByDirective implements AfterContentInit, AfterContentChecked, OnDestroy {

	@HostBinding('attr.role') private _role = 'gridcell';

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private day ! : MonthViewDay;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shifts ! : SchedulingApiShifts;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public absences ! : PAllDayItemsListComponent['absences'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public holidays ! : PAllDayItemsListComponent['holidays'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public birthdays ! : PAllDayItemsListComponent['birthdays'];

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private selectedDate : number | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public neverShowDayTools : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onDayClick : EventEmitter<number> = new EventEmitter<number>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	/**
	 * If this is true, the shift-items in ui will be just skeletons/placeholders.
	 */
	@Input() public delayIsActive : boolean = false;

	constructor(
		public api : SchedulingApiService,
		private pCalendarService : PCalendarService,
		private filterService : FilterService,
		public rightsService : RightsService,
		private pMoment : PMomentService,
	) {
		super();
	}

	public enums = enumsObject;

	public dayAsTimestamp ! : number;

	public CalendarModes = CalendarModes;

	private _shiftsOfDay : Data<SchedulingApiShifts> =
		new Data<SchedulingApiShifts>(this.api, this.filterService, new PrimitiveDataInput<number>(() => {
			assumeDefinedToGetStrictNullChecksRunning(this.dayAsTimestamp, 'dayAsTimestamp');
			return this.dayAsTimestamp;
		}));

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftsOfDay() : SchedulingApiShifts {
		return this._shiftsOfDay.get(() => {
			assumeDefinedToGetStrictNullChecksRunning(this.dayAsTimestamp, 'dayAsTimestamp');
			return this.shifts.getByDay(this.dayAsTimestamp);
		});
	}

	public pinStickyNote : boolean | null = null;

	private subscription : ISubscription | null = null;

	public ngAfterContentChecked() : void {
		this.dayAsTimestamp = this.day.date.getTime();
	}

	public ngAfterContentInit() : void {
		this.dayAsTimestamp = this.day.date.getTime();
		this.refreshPinStickyNote();
		this.subscription = this.api.onChange.subscribe(() => {
			this.refreshPinStickyNote();
		});
	}

	private refreshPinStickyNote() : void {
		if (this.dayAsTimestamp === undefined!) return;
		this.pinStickyNote = this.pCalendarService.hasImportantNoteForDay(this.dayAsTimestamp, false);
	}

	public ngOnDestroy() : void {
		this.subscription?.unsubscribe();
	}

	/**
	 * Unique html id for this element
	 */
	public get htmlElementId() : string {
		return this.ariaLabelHtmlId;
	}

	/**
	 * Check if shifts of that day have any descriptions
	 */
	public get shiftsOfDayHaveDescriptions() : boolean {
		const result = this.pCalendarService.shiftsOfDayHaveDescriptions(this.dayAsTimestamp, { onlyForUser : true });
		assumeDefinedToGetStrictNullChecksRunning(result, 'result');
		return result;
	}

	/**
	 * This changes the selected timestamp
	 * NOTE: Relict from old times
	 */
	public onCellTopClick() : void {
		if (!this.selectedDate) throw new Error('selectedDate is not defined');
		if (!this.pMoment.m(this.selectedDate).isSame(this.dayAsTimestamp, 'month')) return;
		this.onDayClick.emit(this.dayAsTimestamp);
	}
}
