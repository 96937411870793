<!-- linthtml-configure tag-req-attr="false" -->
<button
	type="button"
	class="item bg-all-day-item border-all-day-item text-left small crop-on-overflow no-tooltip-when-cropped clickable"
	[class.item-start]="isFirstItemOfItem || itemStartsInsideInterval"
	[class.item-end]="isLastItemOfItem || itemEndsInsideInterval"
	[class.show-end-dots]="isEndOfBar || Config.IS_MOBILE"
	[class.show-start-dots]="isStartOfBar || Config.IS_MOBILE"
	[class.border-light]="style === enums.PThemeEnum.LIGHT && !(isHighlightedItem || isHovered)"
	[class.btn-outline-secondary]="style === enums.PThemeEnum.LIGHT && !(isHighlightedItem || isHovered)"
	[class.bg-light]="style === enums.PThemeEnum.LIGHT && !(isHighlightedItem || isHovered)"
	[class.text-white]="(style === enums.PThemeEnum.DARK || style === enums.PThemeEnum.INFO) || (isHighlightedItem || isHovered)"
	[class.border-dark]="style === enums.PThemeEnum.DARK && !(isHighlightedItem || isHovered)"
	[class.btn-dark]="style === enums.PThemeEnum.DARK && !(isHighlightedItem || isHovered)"
	[class.bg-dark]="style === enums.PThemeEnum.DARK && !(isHighlightedItem || isHovered)"
	[class.border-info]="style === enums.PThemeEnum.INFO && !(isHighlightedItem || isHovered)"
	[class.btn-info]="style === enums.PThemeEnum.INFO && !(isHighlightedItem || isHovered)"
	[class.bg-info]="style === enums.PThemeEnum.INFO && !(isHighlightedItem || isHovered)"
	[class.border-primary]="(isHighlightedItem || isHovered)"
	[class.bg-primary]="(isHighlightedItem || isHovered)"
	[class.bg-gradient-primary]="(isHighlightedItem || isHovered)"
	[class.hovered]="isHovered"
	[disabled]="false"

	[style.opacity]="muteItem ? 0.5 : 1"

	(click)="onClick($event)"
	(mouseenter)="isHovered=true"
	(mouseleave)="isHovered=false"

	[pTooltip]="tooltipRef"
	[pTooltipPlacement]="popperPlacement"
	[pTooltipTheme]="null"
	[pTooltipAppendToBody]="false"
	pTooltipTrigger="custom"
	[pTooltipShow]="Config.IS_MOBILE ? false : showTooltip"

><ng-template [ngIf]="hasBarInfo" [ngIfElse]="noTitle"><ng-container *ngTemplateOutlet="titleTemplate; context: {$implicit: true}"></ng-container></ng-template><ng-template #noTitle>&nbsp;</ng-template></button>

<ng-template #tooltipRef>
	<div
		class="card shadow-lg m-0 o-hidden border-all-day-item"
		aria-labelledby="all-day-item-label"
		[class.border-dark]="style === enums.PThemeEnum.DARK"
		(click)="showTooltip=false"
		[class.border-info]="style === enums.PThemeEnum.INFO"
		tabindex="0"
		style="border-radius: 0.5rem;"
	>
		<div
			id="all-day-item-label"
			class="card-header card-options bg-all-day-item"
			[class.bg-dark]="style === enums.PThemeEnum.DARK"
			[class.bg-info]="style === enums.PThemeEnum.INFO"
			[class.text-white]="(style === enums.PThemeEnum.DARK || style === enums.PThemeEnum.INFO)"
		>
			<div class="p-2 pl-3 pr-3 d-flex align-items-center justify-content-between mr-auto">
				<ng-container *ngTemplateOutlet="titleTemplate; context: {$implicit: true, _title: tooltipTitle}"></ng-container>
			</div>
			<ng-template [ngIf]="showEditButton">
				<a
					(click)="highlightService.setHighlighted(null)"
					class="card-option btn"
					[class.btn-outline-secondary]="style === enums.PThemeEnum.LIGHT"
					[class.btn-dark]="style === enums.PThemeEnum.DARK"
					[class.btn-info]="style === enums.PThemeEnum.INFO"
					[routerLink]="detailFormLink()"
				><fa-icon icon="pen"></fa-icon></a>
			</ng-template>
			<button
				type="button"
				class="card-option btn border-bottom-0"
				[class.btn-outline-secondary]="style === enums.PThemeEnum.LIGHT"
				[class.btn-dark]="style === enums.PThemeEnum.DARK"
				[class.btn-info]="style === enums.PThemeEnum.INFO"
				(click)="Config.IS_MOBILE ? modalService.modalRef?.close() : onClick($event)"
				[disabled]="false"
			><fa-icon
				[icon]="enums.PlanoFaIconPool.DISMISS"
			></fa-icon></button>
		</div>
		<div class="card-body p-3 pl-3 pr-3">
			<ng-template [ngIf]="isHoliday">
				<div><span>{{ typeTitle }}</span></div>
				<hr class="mt-2 mb-2">
				<ng-template [ngIf]="federalState">
					<div>
						<span i18n>Gilt für {!!isSwitzerland, select, true {den Kanton} other {das Bundesland}} {{ federalState }}</span>
						<p-icon-tooltip i18n>Das Bundesland wurde anhand deiner Firmenanschrift ermittelt.</p-icon-tooltip>
					</div>
					<hr class="mt-2 mb-2">
				</ng-template>
			</ng-template>

			<ng-template [ngIf]="isAbsence">
				<div class="d-flex align-items-center">
					<p-member-badge
						class="mr-2"
						size="small"
						[memberId]="member!.id"
						[firstName]="member!.firstName"
						[lastName]="member!.lastName"
						[isMe]="!!rightsService.isMe(member!.id)"
					></p-member-badge>
					<ng-container *ngTemplateOutlet="titleTemplate; context: {$implicit: false}"></ng-container>
				</div>
				<hr class="mt-2 mb-2">
			</ng-template>

			<ng-template [ngIf]="isBirthday && !!member">
				<div class="d-flex gap-2">
					<p-member-badge [member]="member" size="small"/>{{birthdayTooltipText}}
				</div>
			</ng-template>

			<div *ngIf="isHoliday || isAbsence" class="d-flex align-items-center justify-content-center text-monospace">
				<ng-template [ngIf]="start !== end" [ngIfElse]="oneDayEventTemplate">
					<span [innerHTML]="start! | pSafeHtml"></span>
					<fa-icon icon="long-arrow-alt-right" class="ml-2 mr-2"></fa-icon>
					<span [innerHTML]="end! | pSafeHtml"></span>
				</ng-template>
				<ng-template #oneDayEventTemplate>
					<span [innerHTML]="start! | pSafeHtml"></span>
				</ng-template>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #titleTemplate let-showIconIfAvailable let-_title="_title">
	<span class="crop-on-overflow"><fa-icon *ngIf="showIconIfAvailable && icon" class="mr-2 crop-on-overflow" [icon]="icon"></fa-icon>{{ _title ? _title : title }}</span>
</ng-template>
