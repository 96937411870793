import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { PBtnTheme } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

type ValueType = boolean;

/**
 * <p-checkbox> extends <input type="checkbox"> with all the options for pEditables
 * @example with PFormControl binding
 * 	<form [formGroup]="myFormGroup">
 * 		<p-checkbox
 * 			[formControl]="myFormGroup.get('isAwesome')"
 * 		></p-checkbox>
 * 	</form>
 * @example with model binding
 * 	<p-checkbox
 * 		[(ngModel)]="member.isAwesome"
 * 	></p-checkbox>
 * @example as editable
 * 	<form [formGroup]="myFormGroup">
 * 		<p-checkbox
 * 			[pEditable]="!member.isNewItem()"
 * 			[api]="api"
 *
 * 			[formControl]="myFormGroup.get('isAwesome')"
 * 		></p-checkbox>
 * 	</form>
 */
@Component({
	selector: 'p-checkbox',
	templateUrl: './p-checkbox.component.html',
	styleUrls: ['./p-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PCheckboxComponent),
			multi: true,
		},
	],
})
export class PCheckboxComponent extends ControlWithEditableDirective
	implements PComponentInterface, ControlValueAccessor, EditableControlInterface,
AfterViewInit, PFormControlComponentInterface {
	/**
	 * The button text that is shown to the user.
	 */
	@Input() private valueText : string | TemplateRef<unknown> | null = null;

	@HostBinding('class.flex-column')
	@HostBinding('class.align-items-stretch')

	@HostBinding('class.p-0')
	@HostBinding('class.mb-0') protected _alwaysTrue = true;
	@HostBinding('class.required') private get _hasRequiredClass() : boolean {
		return this.hasRequiredError;
	}

	@HostBinding('class.disabled') private get _isDisabled() : boolean {
		return this.disabled && this.hasButtonStyle;
	}

	/**
	 * Should this component have a btn style?
	 * (Background Color, rounded borders etc.)
	 */
	@Input() public hasButtonStyle : boolean = true;

	/**
	 * Hide the checkbox icon
	 */
	@Input() public hideValueIcon : boolean = false;

	/** @see PCheckboxComponent#hasPaddingX */
	@Input('hasPaddingX') protected _hasPaddingX : boolean | null = null;

	/** @see PCheckboxComponent#hasPaddingY */
	@Input() protected hasPaddingY : boolean | null = true;

	/**
	 * Should this element have a padding left and right?
	 */
	public get hasPaddingX() : boolean {
		if (this._hasPaddingX !== null) return this._hasPaddingX;
		return this.hasButtonStyle;
	}

	/**
	 * The bootstrap button style for this checkbox
	 */
	@Input() public theme : PBtnTheme | null = enumsObject.PThemeEnum.SECONDARY;

	/**
	 * The HTML title attribute to be added to the checkbox,
	 * this should be used when we want to overwrite the title of the checkbox,
	 * which usually will be the same as the {@link PCheckboxComponent#valueText}, but for cases where we want a different
	 * title or no {@link PCheckboxComponent#valueText} is provided, this input should be used.
	 */
	@Input('checkboxTitle') private _checkboxTitle : string | null = null;

	/** @see PCheckboxComponent#size */
	@Input('size') protected _size : PFormControlComponentInterface['size'] | null = null;

	/**
	 * Visual size of this component.
	 * Can be useful if you have few space in a button-bar or want to have large buttons on mobile.
	 */
	public get size() : PFormControlComponentInterface['size'] | null {
		return this._size;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick = new EventEmitter<MouseEvent>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('textWhite') private _textWhite : boolean = false;

	/**
	 * Should the checkbox and text be white? Useful for e.g. primary background
	 */
	public get textWhite() : boolean {
		return this._textWhite;
	}
	public set textWhite(input : boolean) {
		this._textWhite = input;
	}

	/** @see PComponentInterface#isLoading */
	@Input() public isLoading : PComponentInterface['isLoading'] = false;

	/**
	 * Template to be shown inside the cannotSetHint modal, if any, otherwise the text from the
	 * cannotSetHint will be shown.
	 * This template will receive as context the source of the cannotSetHint.
	 */
	@Input() public cannotSetHintTemplate : TemplateRef<unknown> | null = null;

	/** icon next to the label text */
	@Input() public icon : FaIcon | null = null;

	/** Position of the checkbox icon */
	@Input() protected checkboxIconPosition : 'center' | 'start' = 'center';

	constructor(
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override console : LogService,
		protected override pFormsService : PFormsService,
		private modalService : ModalService,
	) {
		super(true, changeDetectorRef, pFormsService, console);
	}

	public enums = enumsObject;

	protected override attributeInfoRequired = false;

	public ngAfterViewInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.validateValues();
		return 'TypeToEnsureLifecycleHooksHaveBeenCalled';
	}

	/** Which icon should represent the checkbox? */
	public get valueIcon() : FaIcon {
		return this.value ? enumsObject.PlanoFaIconPool.CHECKBOX_SELECTED : enumsObject.PlanoFaIconPool.CHECKBOX_UNSELECTED;
	}

	/**
	 * Validate if required attributes are set and
	 * if the set values work together / make sense / have a working implementation.
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private validateValues() : void {}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onClickCheckbox(event : MouseEvent) : void {
		this.value = !this.value;
		this.onClick.emit(event);
	}

	/**
	 * This is the minimum code that is required for a custom control in Angular.
	 * Its necessary to make [(ngModel)] and [formControl] work.
	 */
	public _disabled : boolean = false;

	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}

	@Input('disabled') public override set disabled(input : boolean) {
		this._disabled = input;
		super.disabled = input;
	}
	@Input('formControl') public override control : PFormControl | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') protected _readMode : PFormControlComponentInterface['readMode'] = null;
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public keyup = new EventEmitter<KeyboardEvent>();

	/** Get keyup event from inside this component, and pass it on. */
	public onKeyUp(event : KeyboardEvent) : void {
		this._onChange((event.target as HTMLInputElement).checked);
		this.keyup.emit(event);
	}
	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<Event> = new EventEmitter<Event>();
	public override _onChange : (value : ValueType | null) => void = () => {};
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onChange(event : Event) : void {
		this._onChange((event.target as HTMLInputElement).checked);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	@Input()
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (this._value === value) return;

		this._value = !!value;
		this.changeDetectorRef.markForCheck();
		this._onChange(!!value);
	}
	private _value : ValueType = false;

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = !!value;

		// FIXME: There have been issues with the change detection on this component.
		//        This will be fixed in 3.0. I’m afraid to fix this in a bugfix release.
		//        Not enough time for testing. At least in 2.2.19.
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this._onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			// make sure the formControl value is up-to-date with the AI value
			if (!this.disabled && this.attributeInfo) this.refreshValue();
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType {
		return this.pFormsService.visibleErrors(this.control!);
	}

	/**
	 * Open a Modal like info-circle does it when in IS_MOBILE mode.
	 */
	public openCannotSetHint(contentTemplateRef : TemplateRef<unknown> | null) : void {
		assumeDefinedToGetStrictNullChecksRunning(this.cannotSetHint, 'cannotSetHint');
		this.modalService.openCannotSetHintModal(this.cannotSetHint, this.cannotSetHintTemplate ? contentTemplateRef! : undefined);
	}

	/** get valueText if available and string */
	protected get valueTextString() : string | null {
		if (this.valueText === null) return null;
		if (typeof this.valueText !== 'string') return null;
		return this.valueText;
	}

	/** get valueText if available and type templateRef */
	protected get valueTextTemplateRef() : TemplateRef<unknown> | null {
		if (this.valueText === null) return null;
		if (typeof this.valueText === 'string') return null;
		return this.valueText;
	}

	private get valueTextAsString() : string | null {
		return this.valueTextString ?? this.valueTextTemplateRef?.elementRef.nativeElement.textContent ?? null;
	}

	/** @see PCheckboxComponent#_checkboxTitle */
	public get checkboxTitle() : string | null {
		return this._checkboxTitle ?? this.valueTextAsString;
	}
}
