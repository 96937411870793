/*
	eslint-disable @typescript-eslint/no-explicit-any --
	on many methods we rely on the same types as js have it e.g. for the params of console.log(…)
*/
/* eslint-disable no-console */
import { Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PSeverity } from '@plano/global-error-handler/error-utils';
import { ErrorModalContentComponent } from '@plano/shared/core/component/error-modal-content/error-modal-content.component';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import { Config } from './config';

/**
 * Overwrites console
 * One problem that gets solved here: When a component has e.g. a console.debug(…) and it gets used hundreds of times
 * then the console gets spammed and the app gets slow. Use LogService and the message gets logged one time.
 */
@Injectable({
	providedIn: 'root',
})
export class LogService {
	constructor(
		private pSentryService ?: PSentryService,
		private injector ?: Injector,
	) {
	}

	private spamBlockerIsActive = false;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public debug(firstArg : any, ...otherArgs : any[]) : void {
		this.logItToConsole(console.debug, '🐛', firstArg, '#495057', ...otherArgs);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public info(firstArg : any, ...otherArgs : any[]) : void {
		this.logItToConsole(console.info, '🚧', firstArg, '#495057', ...otherArgs);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public log(firstArg : any, ...otherArgs : any[]) : void {
		this.logItToConsole(console.log, 'ℹ️', firstArg, '#2c93a5', ...otherArgs);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public warn(firstArg : any, ...otherArgs : any[]) : void {
		if (this.sameArgsAsBefore(firstArg, otherArgs)) return;
		this.logItToConsole(console.warn, '⚠️', firstArg, '#e28e33', ...otherArgs);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public deprecated(firstArg : any, ...otherArgs : any[]) : void {
		if (this.sameArgsAsBefore(firstArg, otherArgs)) return;
		this.logItToConsole(console.debug, '♻️', firstArg, '#f3f', ...otherArgs);
	}

	/**
	 * An error that gets logged to the console and also sent to sentry.io.
	 * In DEBUG mode it will also trigger the error-modal with a silently different message than the usual error-modal,
	 * which opens when an error gets thrown.
	 * @param firstArg whatever should be logged. This will also be the message that gets sent to sentry.io
	 * @param otherArgs more information about the error
	 */
	public error(firstArg : any, ...otherArgs : any[]) : void {
		if (this.sameArgsAsBefore(firstArg, otherArgs)) return;

		void this.reportLogError(console.error, '🚨', firstArg, '#d12733', ...otherArgs);
	}

	private async reportLogError(
		fn : (msg ?: any, ...optionalParams : any[]) => void,
		icon : string,
		firstArg : string,
		color : string = '#ccc',
		...otherArgs : any[]
	) : Promise<void> {
		if (this.spamBlockerIsActive) {
			console.warn('Error reporting has been blocked', firstArg, ...otherArgs);
			return;
		}

		this.spamBlockerIsActive = true;
		window.setTimeout(() => {
			this.spamBlockerIsActive = false;
		}, 1000);

		if (Config.DEBUG && Config.APPLICATION_MODE !== 'TEST') {
			// In debug mode a console.error(…) gets handled like an error in the UI
			console.error('You are in DEBUG mode. This error is triggering the error-modal only in DEBUG mode.', firstArg, ...otherArgs);

			const modal = this.injector?.get<NgbModal>(NgbModal);
			if (!modal) throw new Error(`Could not get modal service for console.error(${firstArg})`);
			const errorModal = modal.open(ErrorModalContentComponent, {
				// size: enumsObject.BootstrapSize.LG
				backdrop: 'static',
				backdropClass: 'not-clickable bg-dark',
				keyboard: false,
			}).componentInstance as ErrorModalContentComponent;
			void errorModal.initModal(new Error(firstArg), PSeverity.WARNING);
		} else {
			// NOTE: If app is not in debug mode then its probably one of our real users online.
			// don’t bother him with error-modals, but create a sentry entry with some data about this error.
			this.logItToConsole(fn, icon, firstArg, color, ...otherArgs);
		}

		this.logItToConsole(fn, icon, 'This error will be reported to sentry.io', color);
		await this.pSentryService?.captureMessage(firstArg, {
			level: PSeverity.WARNING,
			fingerprint: [firstArg, ...otherArgs],
			extra: {
				error: firstArg,
			},
		});
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public group(firstArg : any) : void {
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		console.group(`%c${firstArg}`, 'font-weight:normal;font-family:"FiraMono", monospace;font-size:0.8rem;padding: 4px 0');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public groupCollapsed(firstArg : any) : void {
		// eslint-disable-next-line literal-blacklist/literal-blacklist
		console.groupCollapsed(`%c${firstArg}`, 'font-weight:normal;font-family:"FiraMono", monospace;font-size:0.8rem;padding: 4px 0');
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public groupEnd() : void {
		console.groupEnd();
	}

	private logItToConsole(
		fn : (msg ?: any, ...optionalParams : any[]) => void,
		icon : string,
		firstArg : any,
		color : string = '#ccc',
		...otherArgs : any[]
	) : void {
		if (fn === console.debug) {
			const params = [`${icon} ${firstArg}`];
			if (typeof firstArg !== 'string') params.push(firstArg);
			params.push(...otherArgs);
			console.debug(...params);
			return;
		}

		console.groupCollapsed(
			`%c${icon} ${firstArg}`,
			`color:${color};font-weight:normal;font-family:"FiraMono", monospace;font-size:0.8rem;padding: 4px 0`,
		);
		fn('args:', ...otherArgs);
		console.trace();
		console.groupEnd();
	}

	private firstArgCache : any;
	private otherArgsCache : any;
	private sameArgsAsBefore(
		firstArg : any,
		...otherArgs : any[]
	) : boolean {
		const otherArgsString = JSON.stringify(otherArgs, (k, v) => (k ? `${v}` : v));
		if (this.firstArgCache === firstArg && this.otherArgsCache === otherArgsString) return true;

		this.firstArgCache = firstArg;
		this.otherArgsCache = otherArgsString;
		return false;
	}

}
