import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, Output, QueryList, ViewChild } from '@angular/core';
import { PBackgroundColorEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface, EditableDirective, EditableTriggerClickableDirective } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PEditableModalButtonComponent } from '@plano/client/shared/p-editable/p-editable-modal-button/p-editable-modal-button.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { UniqueAriaLabelByDirective } from '@plano/client/shared/unique-aria-labelledby.directive';
import { FaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { PTooltipDirective } from '@plano/shared/core/p-tooltip/tooltip.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

/**
 * Padding to be added to the header of editable boxes
 *
 * It is important to be this precise because of the icon inside the showroom.
 */
export const EDITABLE_MODAL_BOX_HEADER_PADDING = '0.375rem 1rem';

@Component({
	selector: 'p-editable-modal-box-header',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableModalBoxHeaderComponent implements AfterViewInit {
	@HostBinding('class.w-100')
	@HostBinding('class.d-flex')
	@HostBinding('class.justify-content-between')
	@HostBinding('class.align-items-center') private _alwaysTrue = true;

	constructor(
		private elementRef : ElementRef<HTMLElement>,
	) {

	}

	public ngAfterViewInit() : void {
		const labelElement = this.elementRef.nativeElement.querySelector('label');
		if (labelElement) {
			labelElement.classList.add('text-wrap', 'd-flex', 'flex-column', 'flex-md-row');
		}
	}
}

@Component({
	selector: 'p-editable-modal-box-showroom',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PEditableModalBoxShowroomComponent {
	@HostBinding('class.modal-box-showroom')
	@HostBinding('class.d-block')
	@HostBinding('class.p-3') protected _alwaysTrue = true;
	@HostBinding('class.p-0') private get _classP0() : boolean { return this.size === 'frameless'; }
	@HostBinding('class.p-1') private get _classP1() : boolean { return this.size === 'small'; }
	@HostBinding('class.p-2') private get _classP2() : boolean { return this.size === 'medium'; }
	@HostBinding('class.p-3') private get _classP3() : boolean { return this.size === 'large'; }

	/**
	 * Should this be wrapped with a card body class?
	 */
	@HostBinding('class.card-body')
	@Input() public wrapWithCardBody : boolean = true;

	@HostBinding('class.pr-3') private get _isWrappedWithCardBody() : boolean {
		return !this.wrapWithCardBody && this.size !== 'frameless';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public size : 'small' | 'medium' | 'large' | 'frameless' | null = null;
}

/**
 * A component that shows a box with some content (can be defined in <p-editable-modal-box-showroom> inside).
 * And has an edit button, which opens a form (can be defined in <p-editable-modal-form> inside) to edit these contents.
 * The form will be shown in a modal.
 *
 * This concept makes it possible to encapsule inputs that are related through validation. The whole changes can only
 * be dismissed or confirmed at once by the user.
 *
 * If pEditable is set to true, then it will save all data of the contained form when the contained form gets closed.
 *
 * If you want another Modal to open right before save, you can use editables [saveChangesHook].
 */
@Component({
	selector: 'p-editable-modal-box',
	templateUrl: './p-editable-modal-box.component.html',
	styleUrls: ['./p-editable-modal-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class PEditableModalBoxComponent extends UniqueAriaLabelByDirective implements EditableControlInterface, AfterContentInit {
	@ContentChildren(PEditableModalBoxShowroomComponent) private contentShowroom ?: QueryList<PEditableModalBoxShowroomComponent>;
	@ContentChildren(PEditableModalBoxHeaderComponent) private contentHeader ?: QueryList<PEditableModalBoxHeaderComponent>;

	// HACK: Need this for p-input-member-id
	// TODO: Make this obsolete
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideRemoveBtn : boolean = false;

	/**
	 * The title to be used as the modal title
	 */
	@Input() public modalTitle : string | null = null;

	/**
	 * Cannot set hint for the editable box
	 */
	@Input() public cannotSetHint : string | null = null;

	@ViewChild('showroom', { static: true }) private showroom ! : ElementRef<HTMLElement>;
	@ViewChild('modalButtonRef') public modalButtonRef ?: PEditableModalButtonComponent;

	/**
	 * Has the card style
	 */
	public get hasCardStyle() : boolean {
		return !(this.disabled && !this.showShowroom);
	}

	/**
	 * Form control styles
	 */
	public get formControlStyles() : string {
		// NOTE: compare this to showSimpleReadOnlyMode
		if (this.disabled && !this.showShowroom && !this.label)
			return 'form-control-read-mode p-0';
		return '';
	}

	/**
	 * Should have the class border-danger
	 */
	public get hasClassBorderDanger() : boolean {
		// if (this.required) return false;
		return this.isValid === false && !this.disabled || this.borderStyle === 'danger';
	}

	/**
	 * Should have the class required
	 */
	public get hasClassRequired() : boolean {
		return this.required;
	}

	/**
	 * Should have class border-primary
	 */
	public get hasClassBorderPrimary() : boolean {
		return !this.isValid && this.borderStyle === 'primary';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('required') private required : boolean = false;

	/**
	 * Whether a backdrop element should be created for a given modal (true by default).
	 * Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
	 */
	@Input() public backdrop : ModalServiceOptions['backdrop'];

	/**
	 * Theme defines the background color/style of the Box and Modal
	 */
	@Input() public theme : typeof enumsObject.PThemeEnum.DARK | typeof enumsObject.PThemeEnum.LIGHT | PBackgroundColorEnum.WHITE | null = null;

	/** @see ModalServiceOptions#size */
	@Input() public size : ModalServiceOptions['size'] = enumsObject.BootstrapSize.MD;

	/** @see ModalServiceOptions#centered */
	@Input() public centered : ModalServiceOptions['centered'];

	/**
	 * Defines the background color/style of the Modal
	 */
	@Input() public borderStyle : 'primary' | 'danger' | null = null;

	/** The headline of this component */
	@Input() public label : string | null = null;

	/**
	 * Label gets used as edit button text.
	 */
	@Input() public btnLabel : string | null = null;

	/**
	 * Label gets used as edit button text.
	 */
	@Input('showBtnLabel') public _showBtnLabel : PEditableModalButtonComponent['showBtnLabel'] | null = null;

	/**
	 * Icon gets used as edit button text.
	 */
	@Input() public btnIcon : FaIcon | null = null;

	/**
	 * Should the icon be visible?
	 */
	@Input() public showBtnIcon : PEditableModalButtonComponent['showBtnLabel'] | null = null;

	/**
	 * Popover content of modal’s save button
	 */
	@Input() public saveButtonPopover : PTooltipDirective['pTooltip'] = null;

	/**
	 * Popover content of modal’s edit button
	 */
	@Input() public editButtonPopover : PTooltipDirective['pTooltip'] = null;

	/**
	 * If set to true, the modal will close when the pEditable is done with the api-call.
	 */
	@Input() public waitForEditableCompleteBeforeClose : boolean = false;

	/**
	 * Make the editable modal box headless
	 */
	@Input() public headless : boolean = false;

	/**
	 * Component disabled?
	 * If set to true, the edit button doesn’t show up
	 */
	@Input() public disabled : boolean = false;

	/**
	 * The only reason why whe have this button is one use case.
	 * https://bitbucket.org/drplanoteam/drplano/pull-requests/1446#comment-380770902
	 * Please avoid it. Until you really find more than the one use-case.
	 */
	@Input() public hideEditButton : boolean = false;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemoveItemClick = new EventEmitter<Event>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public removeButtonDisabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public removeModalText : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public removeModalButtonLabel : string | null = null;

	/**
	 * Triggers when modal gets opened.
	 * If this is a modal with pEditable set to true, then the onModalOpen() method happens AFTER api.createDataCopy()
	 */
	@Output() public onModalOpen = new EventEmitter<Event>();

	/** @see PEditableModalButtonComponent#onModalClosed */
	@Output() public onModalClosed : PEditableModalButtonComponent['onModalClosed'] = new EventEmitter();

	/** @see PEditableModalButtonComponent#onModalDismissed */
	@Output() public onModalDismissed : PEditableModalButtonComponent['onModalDismissed'] = new EventEmitter();

	/**
	 * Triggers before modal gets closed
	 */
	@Input() public beforeModalClose : (() => Promise<boolean>) | null = null;

	/**
	 * How much whitespace should there be inside the modal?
	 */
	@Input() public modalWhitespace : SectionWhitespace = SectionWhitespace.MEDIUM;

	// These are necessary Inputs and Outputs for pEditable form-element
	@Input() public pEditable : EditableControlInterface['pEditable'] = false;
	@Input() public api : EditableControlInterface['api'] = null;
	@Input() public valid : EditableControlInterface['valid'] = null;
	@Input() public saveChangesHook ?: EditableControlInterface['saveChangesHook'];
	@Output() public onSaveStart : EditableControlInterface['onSaveStart'] = new EventEmitter();
	@Output() public onSaveSuccess : EditableControlInterface['onSaveSuccess'] = new EventEmitter();
	@Output() public onDismiss : EditableDirective['onDismiss'] = new EventEmitter();
	@Output() public onLeaveCurrent : EditableControlInterface['onLeaveCurrent'] = new EventEmitter();
	@Output() public editMode : EditableControlInterface['editMode'] = new EventEmitter<boolean>(undefined);

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get isValid() : boolean {
		// TODO: implement formControl support
		// return this.valid !== null ? this.valid : !this.formControl?.invalid;
		return this.valid !== null ? this.valid : true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public beforeEditHook : EditableTriggerClickableDirective['beforeEditHook'];

	/**
	 * Should have the shadow-sm class?
	 */
	public get hasShadow() : boolean {
		if (this.disabled) return false;
		return true;
	}

	/**
	 * Should have the shadow-hover class?
	 */
	public get hasShadowHover() : boolean {
		if (Config.IS_MOBILE) return false;
		if (!this.hasShadow) return false;
		return true;
	}

	constructor(
		public modalService : ModalService,
		private localizePipe : LocalizePipe,
	) {
		super();
	}

	public enums = enumsObject;

	public boxEditMode : boolean = false;

	/**
	 * Get bg class for wrapper
	 */
	public get bgClass() : string {
		if (!this.theme) return 'bg-light-cold';
		return `bg-${this.theme}`;
	}

	public ngAfterContentInit() : void {
		if (this.contentShowroom && this.contentShowroom.length > 0) {
			this.showShowroom = true;
		}
	}

	/**
	 * Different button classes depending if the button in on the showroom or not
	 */
	public get btnClassesInsideShowroom() : string {
		if (this.showShowroom) {
			// TODO: PLANO-165618 check if border-right-none gets obsolete
			return 'w-100 p-0 m-0 border-0 border-right-none border-top-radius-0';
		}
		return 'btn';
	}

	public NgxPopperjsPlacements = NgxPopperjsPlacements;

	public showShowroom : boolean = false;

	public editableBoxHeaderPadding = EDITABLE_MODAL_BOX_HEADER_PADDING;

	/**
	 * Should header complex be shown?
	 */
	public get showHeader() : boolean {
		return (!!this.label || (!!this.contentHeader && this.contentHeader.length > 0));
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public updateEditMode(event : boolean) : void {
		this.boxEditMode = event;
		this.editMode.emit(event);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showSimpleReadOnlyMode() : boolean {
		return this.disabled && !this.showShowroom && !!this.label;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showEditButtonPopover() : boolean {
		if (Config.IS_MOBILE) return false;
		return true;
	}

	/**
	 * Should the button-label be visible?
	 */
	public get showBtnLabel() : PEditableModalButtonComponent['showBtnLabel'] {
		if (Config.IS_MOBILE) return false;
		if (this._showBtnLabel !== null) return this._showBtnLabel;
		return !!this.btnLabel;
	}

	/**
	 * Get a aria label for the trigger
	 */
	protected get ariaLabel() : string {
		if (this.btnLabel !== null) return this.btnLabel;
		if (this.modalTitle !== null) return `${this.modalTitle} bearbeiten`;
		return this.localizePipe.transform('Inhalte bearbeiten');
	}
}
