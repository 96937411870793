/* NOTE: Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-lines: ["error", 1400] */

import { DecimalPipe, NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { PFormsService, VisibleErrorsType } from '@plano/client/service/p-forms.service';
import { BootstrapRounded, PBtnThemeEnum, PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { INITIALIZED_IN_BACKEND, PSimpleChanges } from '@plano/shared/api';
import { Duration, PApiPrimitiveTypes, PSupportedCurrencyCodes, PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { PFaIcon } from '@plano/shared/core/component/fa-icon/fa-icon-types';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS } from '@plano/shared/core/pipe/decimal-pipe.consts';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PCurrencyPipe } from '@plano/shared/core/pipe/p-currency.pipe';
import { AngularDatePipeFormat, PDatePipe } from '@plano/shared/core/pipe/p-date.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ExtractFromUnion, TypeToEnsureLifecycleHooksHaveBeenCalled } from '@plano/shared/core/utils/typescript-utils-types';
import { ValidatorsService } from '@plano/shared/core/validators.service';
import { PPossibleErrorNames, PValidationErrors } from '@plano/shared/core/validators.types';
import { ControlWithEditableDirective } from '@plano/shared/p-forms/control-with-editable.directive';
import { InputGroupAppendDirective, InputGroupPrependDirective } from '@plano/shared/p-forms/input-groups.directive';
import { PDropdownItemComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown-item/p-dropdown-item.component';
import { PFormControl } from '@plano/shared/p-forms/p-form-control';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PInputService, PInputType } from './p-input.service';
import { DurationUIType, PTextAlignType } from './p-input.types';

type ValueType = string | number | null;

type HTMLInputAutocompleteValue = 'off' | 'on' | 'postal-code';

/**
 * `<p-input>` extends `<input>` with all the options for pEditables
 * @example
 * with PFormControl binding
 * 	<form [formGroup]="myFormGroup">
 * 		<p-input
 * 			[formControl]="myFormGroup.get('lastName')"
 * 		></p-input>
 * 	</form>
 * @example with model binding
 * 	<p-input
 * 		[(ngModel)]="member.lastName"
 * 	></p-input>
 * @example as editable
 * 	<form [formGroup]="myFormGroup">
 * 		<p-input
 * 			[pEditable]="!member.isNewItem()"
 * 			[api]="api"
 *
 * 			[formControl]="myFormGroup.get('lastName')"
 * 			placeholder="Plano" i18n-placeholder
 * 		></p-input>
 * 	</form>
 */
@Component({
	selector: 'p-input',
	templateUrl: './p-input.component.html',
	styleUrls: ['./p-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PInputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PInputComponent,
			multi: true,
		},
	],
})
export class PInputComponent extends ControlWithEditableDirective
	implements ControlValueAccessor, Validator, EditableControlInterface, AfterContentInit, OnInit, AfterViewInit,
PFormControlComponentInterface, OnDestroy, OnChanges {

	@ContentChildren(InputGroupPrependDirective, {read: ElementRef}) private contentInputPrependGroups ?: QueryList<ElementRef<HTMLElement>>;

	@ContentChildren(InputGroupAppendDirective, {read: ElementRef}) private contentInputAppendGroups ?: QueryList<ElementRef<HTMLElement>>;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('readMode') private _readMode : PFormControlComponentInterface['readMode'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public formGroup : FormGroup | null = null;

	@ViewChild('inputEl') public inputEl ?: ElementRef<HTMLInputElement>;

	@ViewChild('appendEditableButtons') public appendEditableButtons ?: ElementRef<HTMLInputElement>;

	@ContentChildren(PDropdownItemComponent) public dropdownItems ?: QueryList<PDropdownItemComponent> | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public dropdownValue : unknown;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public dropdownValueChange = new EventEmitter<unknown>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hintTheme : ExtractFromUnion<'danger' | 'warning' | 'info' | 'secondary' | 'light', PThemeEnum> | null = null;

	/** Is this loading? If true, skeleton will show. */
	@Input() public set isLoading(_input : boolean) {
		throw new Error('Not implemented yet');
	}

	/** @see PAutoFocusInsideModalDirective#hasAutoFocusPriority */
	@Input() public hasAutoFocusPriority = false;

	/**
	 * See HTML autocomplete
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
	 */
	@Input() public autocomplete : HTMLInputAutocompleteValue = 'off';

	/** @see PFormControlComponentInterface#size */
	@Input() public size ?: typeof enumsObject.BootstrapSize.SM | typeof enumsObject.BootstrapSize.LG;

	/**
	 * The text that should be shown if there is no value yet.
	 * Usually used for example input.
	 */
	@Input('placeholder') private _placeholder : string | null = null;

	/**
	 * Overwrite the type that is read from apiAttributeInfo.primitiveType.
	 * @example <p-input [type]="PApiPrimitiveTypes.Email">
	 * @example <p-input [type]="PApiPrimitiveTypes.ClientCurrency">
	 */
	@Input() public type : PInputType = PApiPrimitiveTypes.string;

	/**
	 * Duration would not be a user-friendly type. It’s stored in Milliseconds.
	 * If you use this component for a PrimitiveType Duration it is required to set a `durationUIType`.
	 * You can set it to a specific unit, or you can set it to null.
	 * If you set it to null, a dropdown with some units will be shown.
	 */
	public get durationUIType() : PInputComponent['_durationUIType'] {
		return this._durationUIType;
	}
	@Input() public set durationUIType(input : PInputComponent['_durationUIType']) {
		if (input === this._durationUIType) return;
		this._durationUIType = input;
		if (input === null) {
			this._value = null;
		}
		this._onChange(this.value);
		this.durationUITypeChange.emit(input);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public durationUITypeChange = new EventEmitter<PInputComponent['_durationUIType']>();

	/**
	 * If type is set to 'Currency', you can set the currency for another country here.
	 * If undefined, a global currency code based on the locale (Config.CURRENCY_CODE) will be used.
	 * @example <p-input [type]="PApiPrimitiveTypes.ClientCurrency" currencyCode="CZK">
	 */
	@Input() public currencyCode : PSupportedCurrencyCodes | null = null;

	/* type date */
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public min : string | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public max : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public textAlign : PTextAlignType | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('inputGroupAppendText') private _inputGroupAppendText ?: PInputComponent['inputGroupAppendText'];
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private inputGroupAppendIcon : PFaIcon | null = null;

	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public focusout = new EventEmitter<FocusEvent>();
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public focus = new EventEmitter<FocusEvent>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('locale') public _locale : PSupportedLocaleIds | null = null;

	/**
	 * An array with suggested values. Suggested values show up when user starts to type.
	 */
	@Input() public typeahead : string[] = [];

	/**
	 * Should this typeahead be appended to the body?
	 * Useful to prevent cropping by modal footers
	 */
	@Input() public appendOnBody : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public readonly : boolean = false;

	/**
	 * Write 10.000.000 instead of 10000000 into input when model is 10000000
	 * @default true
	 */
	@Input() public useSeparatorForThousands = true;

	/**
	 * Should the password strength meter be visible?
	 * Only use this if type is Password.
	 */
	@Input('showPasswordMeter') public _showPasswordMeter : boolean | null = null;

	/** @deprecated This is a relict. don’t set it. */
	@Input() public override saveChangesHook ?: EditableControlInterface['saveChangesHook'];

	@HostBinding('class.dark-theme')
	@Input() public theme : PBtnThemeEnum.OUTLINE_DARK | null = null;

	/**
	 * Set this to true if you want the component to return a `undefined` to the api when user clears the input.
	 * This e.g. gets used when the input has a dropdown with a `null` option and the form control has a notUndefined()
	 * validator.
	 * If the user chose a non-null option in the dropdown but did not set any value in the input, component returns
	 * `undefined` and user gets prompted that value is required.
	 */
	@Input() public supportsUnset : boolean | null = null;

	/**
	 * How many digits are allowed?
	 * This obviously only makes sense on number-inputs.
	 */
	@Input() private maxDecimalPlacesCount : number | null = null;

	/**
	 * By default, if there is an appendText then the prependIcon is placed
	 * on the append. Set this to true to disable this behaviour
	 */
	@Input() public forcePrependIconOnPrepend : boolean = false;

	@Input('hidePassword') private set setHidePasswordInput(input : boolean) {
		this.hidePasswordInput = input;
	}

	/** Sets the native html property hidden on the <input> */
	@Input() public inputHidden : boolean | null = null;

	/**
	 * Is this button disabled?
	 */
	public override get disabled() : boolean {
		return this._disabled || !this.canSet;
	}
	@Input('disabled') public override set disabled(input : boolean) {
		this.setDisabledState(input);
		this._disabled = input;
		super.disabled = input;
	}

	@Input('formControl') public override control : PFormControl | null = null;
	@Input() public override group ?: FormGroup;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,
		private decimalPipe : DecimalPipe,
		private localize : LocalizePipe,
		private pCurrencyPipe : PCurrencyPipe,
		private pInputService : PInputService,
		private pMoment : PMomentService,
		private pDatePipe : PDatePipe,
		private currencyPipe : PCurrencyPipe,
		private validators : ValidatorsService,
		private elementRef : ElementRef<HTMLElement>,
		private modalService : ModalService,
		private zone : NgZone,
	) {
		super(false, changeDetectorRef, pFormsService, console);
		window.addEventListener('resize', this.boundCalculateAppendGroupsClasses);
	}

	public Config = Config;

	private boundCalculateAppendGroupsClasses = this.calculateAppendGroupsClasses.bind(this);

	private groupAppendedGroupsByOffsetTop() : Map<number, HTMLElement []> {
		// list of elements grouped by offsetOfElements
		const offsetOfElements = new Map<number, HTMLElement []>();

		let mainGroupWrapped = false;

		// check if the main group of the appends has wrapped
		const mainAppendGroup : HTMLElement | null = this.elementRef.nativeElement.querySelector('.input-group-main-append');
		if (mainAppendGroup && mainAppendGroup.offsetTop > 0) {
			mainGroupWrapped = true;
		}

		// get a list of the appended elements
		const appendedElements : NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.input-group-append');

		for (const appendedElement of appendedElements) {
			// if the element is not visible we can ignore it
			if (window.getComputedStyle(appendedElement).display === 'none') continue;

			const appendedElementOffsetTop = appendedElement.offsetTop + ((mainAppendGroup?.contains(appendedElement)) ? mainAppendGroup.offsetTop : 0);

			// if the element has an offsetTop bigger than 0 or the main group is wrapped we add it to the map
			if (appendedElementOffsetTop > 0 || mainGroupWrapped) {
				if (!offsetOfElements.has(appendedElementOffsetTop)) {
					offsetOfElements.set(appendedElementOffsetTop, []);
				}
				offsetOfElements.get(appendedElementOffsetTop)!.push(appendedElement);
			}
		}
		return offsetOfElements;

	}

	/**
	 * Decide the correct classes for the appended groups
	 */
	private calculateAppendGroupsClasses() : void {
		this.zone.runOutsideAngular(() => {
			const offsetOfElements = this.groupAppendedGroupsByOffsetTop();
			if (this.isAppendWrapped) {
				const maxOffsetTop = Math.max(...Array.from(offsetOfElements.keys()));
				for (const lastLineElement of offsetOfElements.get(maxOffsetTop)!) {
					lastLineElement.classList.add('append-on-last-line');
				}
				for (const otherOffset of Array.from(offsetOfElements.keys())) {
					const otherOffsetElements = offsetOfElements.get(otherOffset)!;
					for (const element of otherOffsetElements) {
						element.classList.remove('last-element-on-line');
						if (otherOffset !== maxOffsetTop)
							element.classList.remove('append-on-last-line');
					}
					otherOffsetElements.at(- 1)!.classList.add('last-element-on-line');
				}
			}
		});
	}

	/**
	 * Is there an appended group that is wrapped?
	 */
	public get isAppendWrapped() : boolean {
		const appendedElements = this.elementRef.nativeElement.querySelectorAll<HTMLElement>('.input-group-append, .input-group-main-append');
		return Array.from(appendedElements).some(el => el.offsetTop > 0);
	}

	public enums = enumsObject;
	public PApiPrimitiveTypes = PApiPrimitiveTypes;
	public PBtnThemeEnum = PBtnThemeEnum;
	public BootstrapRounded = BootstrapRounded;

	/** @see PInputComponent.durationUIType */
	public _durationUIType : DurationUIType | null = null;

	/**
	 * classes to be added to the input wrapper
	 */
	public get inputWrapperClasses() : string {
		const classesToReturn : string [] = [];
		if (this.size)
			classesToReturn.push(`input-group-${this.size}`);
		if (this.theme !== null)
			classesToReturn.push(`input-group-${this.theme}`);
		if (this.hasRequiredError)
			classesToReturn.push('required');
		if (this.hasDanger)
			classesToReturn.push('has-danger');
		if (this.hasWarning)
			classesToReturn.push('has-warning');
		if (this.hasInteractivePrepend === false)
			classesToReturn.push('has-non-interactive-prepend');
		if (this.hasInteractiveAppend === true)
			classesToReturn.push('has-interactive-append');
		return classesToReturn.join(' ');
	}

	/**
	 * Take the internal type from PApiPrimitiveTypes and get a proper html input type
	 * See https://developer.mozilla.org/de/docs/Web/HTML/Element/Input#arten_des_%3Cinput%3E-elements
	 */
	public get inputType() : PInputComponent['type'] {
		if (this.type === PApiPrimitiveTypes.number) return PApiPrimitiveTypes.string;
		if (this.type === PApiPrimitiveTypes.Password || this.type === 'ConfirmPassword') {
			return this.hidePassword ? PApiPrimitiveTypes.Password : PApiPrimitiveTypes.string;
		}
		return this.type;
	}

	private get locale() : PSupportedLocaleIds {
		return this._locale ?? Config.LOCALE_ID;
	}

	/** This element would have unwanted ui effects if we dont remove it (e.g. no border radius on the left side) */
	private removePInputAppendAndPrependIfEmpty() : void {
		const appendItems = this.elementRef.nativeElement.querySelectorAll('.input-group-append');
		for (const appendItem of appendItems) {
			const isEmpty = !appendItem.textContent?.length && appendItem.children.length === 0;
			if (isEmpty) {
				appendItem.remove();
			}
		}
		const prependItems = this.elementRef.nativeElement.querySelectorAll('.input-group-prepend');
		for (const prependItem of prependItems) {
			const isEmpty = !prependItem.textContent?.length && prependItem.children.length === 0;
			if (isEmpty) {
				prependItem.remove();
			}
		}
	}

	private resizeObserver ?: ResizeObserver;

	private addResizeObserver() : void {
		this.resizeObserver = new ResizeObserver(() => {
			this.calculateAppendGroupsClasses();
		});
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	/**
	 * If the input is a typeahead we need to set some listeners on the page
	 */
	private handleTypeaheadInput() : void {
		if (this.typeahead.length > 0) {
			this.mutationObserver = new MutationObserver(() => {
				const typeaheadPopup = document.querySelector<HTMLElement>('typeahead-container');
				const ariaOwnsAttribute = this.inputEl?.nativeElement.getAttribute('aria-owns') ?? null;
				if (typeaheadPopup && ariaOwnsAttribute && document.querySelector(`#${ariaOwnsAttribute}`) === typeaheadPopup) {
					typeaheadPopup.style.maxWidth = `${this.inputEl?.nativeElement.offsetWidth}px`;
					typeaheadPopup.style.minWidth = `${this.inputEl?.nativeElement.offsetWidth}px`;

					// if the typeahead is on the body we can't lose focus of the input when selecting an option
					if (this.appendOnBody) {
						typeaheadPopup.addEventListener('mousedown', (event) => {
							event.preventDefault();
							event.stopPropagation();
						});
					}
				}
			});
			this.mutationObserver.observe(this.appendOnBody ? document.body : this.elementRef.nativeElement.firstElementChild!, {childList: true});
		}
	}

	private mutationObserver : MutationObserver | null = null;

	public ngAfterViewInit() : void {
		this.removePInputAppendAndPrependIfEmpty();
		this.addResizeObserver();
		this.handleTypeaheadInput();
	}

	/**
	 * Run input-type based validations.
	 * These validations validate against the value of this input, not the transformed value, that gets returned to the
	 * bound model / to the api.
	 *
	 * Why we need this: A Integer-Validator (no digits allowed) makes no sense on a Duration. Because the duration
	 * gets stored as milliseconds. But it can make sense, to validate that the "Minutes" that are visible in the UI do
	 * not have digits.
	 *
	 * @see Validator#validate
	 */
	public validate(_control : PFormControl) : PValidationErrors | null {
		if (this.attributeInfo) return null;
		return this.validateInternalValue();
	}

	public ngOnChanges(changes : PSimpleChanges<PInputComponent>) : void {
		if (changes.control) {
			this.addComponentValidatorsToFormControl();
		}
		if (changes.inputHidden) {
			this.checkAppends();
			this.checkPrepends();
		}
	}

	private validateInternalValue() : PValidationErrors | null {
		if (!this.value) return null;
		const VALUE = typeof this.value === 'string' && this.type !== PApiPrimitiveTypes.Password && this.type !== 'ConfirmPassword' ? this.value.trim() : this.value;
		const CONTROL = { value : VALUE } as unknown as AbstractControl;
		switch (this.type) {
			case PApiPrimitiveTypes.Search:
			case PApiPrimitiveTypes.string:
				return null;
			case PApiPrimitiveTypes.Url:
				// TODO: Obsolete?
				return this.validators.url().fn(CONTROL);
			case 'Domain':
				return this.validators.domain().fn(CONTROL);
			case PApiPrimitiveTypes.Iban:
				// TODO: Obsolete?
				// cSpell:ignore iban
				return this.validators.iban().fn(CONTROL);
			case PApiPrimitiveTypes.Bic:
				// TODO: Obsolete?
				return this.validators.bic().fn(CONTROL);
			case PApiPrimitiveTypes.PostalCode:
				// TODO: Obsolete?
				return this.validators.plz().fn(CONTROL);
			case PApiPrimitiveTypes.LocalTime :
				return this.pInputService.validateLocaleAwareTime(CONTROL);
			case PApiPrimitiveTypes.Minutes :
			case PApiPrimitiveTypes.Hours :
			case PApiPrimitiveTypes.Days :
			case PApiPrimitiveTypes.Months :
			case PApiPrimitiveTypes.Years :
			case PApiPrimitiveTypes.Percent:
				const MIN_ERRORS = this.pInputService.min(0, CONTROL, this.locale);
				if (MIN_ERRORS) return MIN_ERRORS;
				const NUMBER_ERRORS2 = this.pInputService.validateLocaleAwareNumber(CONTROL, this.locale);
				if (NUMBER_ERRORS2) return NUMBER_ERRORS2;

				return this.pInputService.integer(this.type, this.locale)(CONTROL);
			case PApiPrimitiveTypes.number :
				return this.pInputService.validateLocaleAwareFloat(CONTROL, this.locale);
			case PApiPrimitiveTypes.ClientCurrency :
			case PApiPrimitiveTypes.Euro :
				return this.pInputService.validateLocaleAwareCurrency(CONTROL, this.locale, this.currencyCode);
			case PApiPrimitiveTypes.Tel :
				// TODO: Obsolete?
				return this.validators.phone().fn(CONTROL);
			case PApiPrimitiveTypes.Email :
				// TODO: Obsolete?
				return this.validators.email().fn(CONTROL);
			case 'ConfirmPassword' :
				return null;
			case PApiPrimitiveTypes.Password :
				// TODO: Obsolete?
				return this.validators.password().fn(CONTROL);
			case PApiPrimitiveTypes.Duration :
				const FLOAT_ERRORS = this.pInputService.validateLocaleAwareFloat(CONTROL, this.locale);
				if (FLOAT_ERRORS) return FLOAT_ERRORS;
				const DURATION_MIN_ERRORS = this.pInputService.min(0, CONTROL, this.locale);
				if (DURATION_MIN_ERRORS) return DURATION_MIN_ERRORS;
				if (this.maxDecimalPlacesCount !== null) {
					const DIGITS_ERRORS = this.pInputService.maxDecimalPlacesCount(this.maxDecimalPlacesCount, CONTROL, this.locale);
					if (DIGITS_ERRORS) return DIGITS_ERRORS;
				}
				return null;
			case PApiPrimitiveTypes.Integer :
				// TODO: Obsolete?
				return this.pInputService.integer(this.type, this.locale)(CONTROL);
			default :
				return null;
		}
	}

	private addComponentValidatorsToFormControl() : void {
		if (!this.control) return;
		this.control.componentValidators = () => {
			return this.validateInternalValue();
		};
	}

	/**
	 * Does this input have an interactive append?
	 * Null if there is no append at all
	 */
	public hasInteractiveAppend : boolean | null = null;

	/**
	 * Does this input have an interactive prepend?
	 * Null if there is no prepend at all
	 */
	public hasInteractivePrepend : boolean | null = null;

	public appendHasContent : boolean = false;

	/**
	 * Check all the appended groups to decide if there are any,
	 * and if they are interactive
	 */
	private checkAppends() : void {
		if (this.disabled && this.cannotSetHint) {
			this.hasInteractiveAppend = true;
			return;
		}

		// is there any element in the content of this component with the class input-group-append?
		if (this.contentInputAppendGroups?.length) {
			// loop through the content
			for (const inputGroupAppendChild of this.contentInputAppendGroups) {
				const nativeElement = inputGroupAppendChild.nativeElement;

				// If the element is empty skip to next iteration
				if (!nativeElement.textContent?.length && nativeElement.children.length === 0) {
					continue;
				}
				this.appendHasContent = true;

				// If there is an element that is interactive or has
				// interactive children set the value as true and end execution
				if (nativeElement.matches('button') || nativeElement.querySelector('button')) {
					this.hasInteractiveAppend = true;
					return;
				}

				// If none of the above we can set this value as false,
				// since there is an append but is not interactive
				this.hasInteractiveAppend = false;
			}
		}

		// Are there dropdowns items or is the showPassword button visible?
		const hasOtherInteractiveOptions = !!this.dropdownItems?.length || this.showPasswordVisibilityToggleButton;

		if (hasOtherInteractiveOptions) {
			// If so, set the value as true and end execution
			this.hasInteractiveAppend = true;
			return;
		}

		// Is there any appended text, or cannotSetHint? If so, there is an a non interactive append
		if (!!this.inputGroupAppendText || (this.disabled && !!this.cannotSetHint)) {
			this.hasInteractiveAppend = false;
		}
	}

	/**
	 * Check all the prepended groups to decide if there are any,
	 * and if they are interactive
	 */
	private checkPrepends() : void {
		// is there any element in the content of this component with the class input-group-prepend?
		if (this.contentInputPrependGroups?.length) {
			// loop through the content
			for (const inputGroupPrependChild of this.contentInputPrependGroups) {
				const nativeElement = inputGroupPrependChild.nativeElement;

				// If the element is empty skip to next iteration
				if (!nativeElement.textContent?.length && nativeElement.children.length === 0) {
					continue;
				}

				// If there is an element that is interactive or has
				// interactive children set the value as true and end execution
				if (nativeElement.matches('button') || nativeElement.querySelector('button')) {
					this.hasInteractivePrepend = true;
					return;
				}

				// If none of the above we can set this value as false,
				// since there is an append but is not interactive
				this.hasInteractivePrepend = false;
			}
		}

		// If the type is search the prepend is interactive
		if (this.type === PApiPrimitiveTypes.Search) {
			this.hasInteractivePrepend = true;
			return;
		}

		// Is there a prepend icon placed in the prepend group of the input?
		const hasOtherNonInteractiveOptions =
			this.inputGroupPrependIcon && (!this.inputGroupAppendText || this.forcePrependIconOnPrepend);

		if (hasOtherNonInteractiveOptions) {
			// If so, set the value as false and end execution
			this.hasInteractivePrepend = false;
		}
	}

	public override ngAfterContentInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.initDurationValues();
		if (this.maxDecimalPlacesCount !== null && this.type !== PApiPrimitiveTypes.Duration) this.console.error('Not implemented yet');

		this.addComponentValidatorsToFormControl();

		this.checkAppends();

		this.checkPrepends();

		return super.ngAfterContentInit();
	}

	public override ngOnInit() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		this.validateValues();
		this.initValues();
		return super.ngOnInit();
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private initValues() : void {
	}

	public override ngOnDestroy() : TypeToEnsureLifecycleHooksHaveBeenCalled {
		window.removeEventListener('resize', this.boundCalculateAppendGroupsClasses);
		this.resizeObserver?.disconnect();
		this.mutationObserver?.disconnect();
		return super.ngOnDestroy();
	}

	/**
	 * Are the editable buttons of the input visible?
	 */
	public get editableButtonsVisible() : boolean {
		return !!(this.appendEditableButtons?.nativeElement) && !this.appendEditableButtons.nativeElement.hidden;
	}

	/**
	 * Set an initial durationUiType if needed
	 */
	private initDurationValues() : void {
		// Nothing to do if this is something else than duration
		if (this.type !== PApiPrimitiveTypes.Duration) return;

		// Nothing to do if there is a valid durationUiType already set
		if (this.durationUIType !== null) return;

		// Set a default for the _durationUIType
		this._durationUIType = PApiPrimitiveTypes.Minutes;
	}

	private validateValues() : void {
		if (this._showPasswordMeter && this.type !== PApiPrimitiveTypes.Password && this.type !== 'ConfirmPassword') {
			throw new Error('showPasswordMeter can only be true if type is password.');
		}
	}

	/**
	 * Happens when user selects a typeahead item from the typeahead-dropdown
	 */
	public typeaheadOnSelect(
		input : TypeaheadMatch,
		pEditableTriggerFocussableRef : HTMLInputElement,
	) : void {
		this.value = input.value;
		$(pEditableTriggerFocussableRef).trigger('enter');
	}

	/**
	 * Get a proper placeholder for the provided value type
	 */
	public get placeholder() : string | undefined {
		if (this.disabled === true) return undefined;
		if (this._placeholder !== null) return this._placeholder;

		switch (this.type) {
			case PApiPrimitiveTypes.LocalTime :
				return this.localize.transform({
					sourceString: 'z.B. »${example1}«',
					params: { example1: this.pDatePipe.transform(86340000, AngularDatePipeFormat.SHORT_TIME)},
				});
			case PApiPrimitiveTypes.Search :
				return this.localize.transform('Suche…');
			case PApiPrimitiveTypes.ClientCurrency :
				return this.localize.transform({
					sourceString: 'z.B. »${example1}«',
					params: {example1: this.currencyPipe.transform(10.5, Config.CURRENCY_CODE, '')!.trim()},
				});
			case PApiPrimitiveTypes.Euro :
				return this.localize.transform({
					sourceString: 'z.B. »${example1}«',
					params: {example1: this.currencyPipe.transform(10.5, 'EUR', '')!.trim()},
				});
			case PApiPrimitiveTypes.Hours :
			case PApiPrimitiveTypes.Minutes :
			case PApiPrimitiveTypes.Percent :
				return this.localize.transform({
					sourceString: 'z.B. »${example1}«',
					params: {example1: this.decimalPipe.transform(10, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!},
				});
			case PApiPrimitiveTypes.Days :
				return this.localize.transform({
					sourceString: 'z.B. »${example1}«',
					params: {example1: this.decimalPipe.transform(3, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS)!},
				});
			case PApiPrimitiveTypes.Tel :
				return this.localize.transform('+491230000000', false, false);
			case PApiPrimitiveTypes.Url :
				return 'https://';
			case PApiPrimitiveTypes.Email:
				return this.localize.transform('beispiel@domain.com', false, false);
			case PApiPrimitiveTypes.Iban:
			case PApiPrimitiveTypes.Bic:
			case PApiPrimitiveTypes.Months:
			case PApiPrimitiveTypes.Years:
			case PApiPrimitiveTypes.Duration:
			case PApiPrimitiveTypes.Password:
			case PApiPrimitiveTypes.PostalCode:
			case PApiPrimitiveTypes.Integer:
			case PApiPrimitiveTypes.number:
			case PApiPrimitiveTypes.string:
			case 'Domain':
			case 'ConfirmPassword':
				return '';
		}
	}

	/**
	 * Get a proper icon for the provided value type
	 */
	public get inputGroupPrependIcon() : PFaIcon | null {
		if (this.inputGroupAppendIcon !== null) return this.inputGroupAppendIcon;
		if (this.inputHidden) return null;
		switch (this.type) {
			case PApiPrimitiveTypes.ClientCurrency :
				return this.pCurrencyPipe.getCurrencyIcon(this.currencyCode);
			case PApiPrimitiveTypes.Euro:
				return enumsObject.PlanoFaIconPool.EUR;
			case PApiPrimitiveTypes.LocalTime :
				return enumsObject.PlanoFaIconPool.CLOCK;
			case PApiPrimitiveTypes.Minutes :
			case PApiPrimitiveTypes.Hours :
			case PApiPrimitiveTypes.Duration :
				return 'stopwatch';
			case PApiPrimitiveTypes.Months :
				return 'calendar';
			case PApiPrimitiveTypes.Days :
				return enumsObject.PlanoFaIconPool.CALENDAR_DAY;
			case PApiPrimitiveTypes.Years :
				return 'calendar';
			case PApiPrimitiveTypes.Percent:
				return 'percent';
			case PApiPrimitiveTypes.Search :
				return enumsObject.PlanoFaIconPool.SEARCH;
			case PApiPrimitiveTypes.Url :
			case 'Domain' :
				return enumsObject.PlanoFaIconPool.BRAND_INTERNET_EXPLORER;
			case PApiPrimitiveTypes.Email :
				if (this.control?.pending) {
					return enumsObject.PlanoFaIconPool.SYNCING;
				}
				return enumsObject.PlanoFaIconPool.EMAIL_AT;
			case PApiPrimitiveTypes.Tel :
				return 'phone';
			case PApiPrimitiveTypes.Password :
			case 'ConfirmPassword' :
				return 'key';
			case PApiPrimitiveTypes.PostalCode :
			case PApiPrimitiveTypes.number :
			case PApiPrimitiveTypes.string :
			case PApiPrimitiveTypes.Integer :
			case PApiPrimitiveTypes.Iban :
			case PApiPrimitiveTypes.Bic :
				return null;
			default :
				return this.type;
		}
	}

	public _hidePassword : boolean = true;
	public hidePasswordInput : boolean | null = null;

	/**
	 * Passwords are hidden by default. Prevent that some other person in the room sees the user’s password.
	 */
	public get hidePassword() : boolean {
		if (this.hidePasswordInput !== null) return this.hidePasswordInput;
		return this._hidePassword;
	}
	public set hidePassword(input : boolean) {
		this._hidePassword = input;
	}

	/**
	 * A Text that will be appended to the input
	 * Can be used to write the name of the unit.
	 * Gets set automatically if this is some kind of Duration input.
	 */
	public get inputGroupAppendText() : string | null {
		// TODO: 	Milad and Nils think that this should be something like `if (this._inputGroupAppendText === undefined) …`
		// 				It should be possible to set it to `null` to remove the append text from UI.
		if (this._inputGroupAppendText !== undefined) return this._inputGroupAppendText;
		if (this.inputHidden) return null;
		if (this.dropdownItems?.length) return null;
		const getText = (type : PInputComponent['type'] | null) : string | null => {
			switch (type) {
				case PApiPrimitiveTypes.Minutes :
					return this.localize.transform('Minuten');
				case PApiPrimitiveTypes.Hours :
					return this.localize.transform('Stunden');
				case PApiPrimitiveTypes.Days :
					return this.localize.transform('Tage');
				case PApiPrimitiveTypes.Months :
					return this.localize.transform('Monate');
				case PApiPrimitiveTypes.Years :
					return this.localize.transform('Jahre');
				case null :
				default :
					return null;
			}
		};
		const UI_TYPE = this.type === PApiPrimitiveTypes.Duration ? this.durationUIType : this.type;
		return getText(UI_TYPE);
	}

	/** @see PInputService#transformUiValueIntoModelValue */
	private transformUiValueIntoModelValue(value : string) : string | number | Duration | null {
		return this.pInputService.transformUiValueIntoModelValue(
			value,
			this.locale,
			this.type,
			this.supportsUnset,
			this.durationUIType,
		) as string | number | Duration | null;
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	private transformModelValueIntoUiValue(valueInput : string | Duration | null) : PInputComponent['_value'] {
		// Dont visualize "special" values
		if (valueInput === INITIALIZED_IN_BACKEND)
			return '';

		// transform
		let value : PInputComponent['_value'];

		switch (this.type) {
			case PApiPrimitiveTypes.Minutes :
			case PApiPrimitiveTypes.Hours :
			case PApiPrimitiveTypes.Days :
			case PApiPrimitiveTypes.Months :
			case PApiPrimitiveTypes.Years :
				// eslint-disable-next-line sonarjs/no-nested-switch, sonarjs/no-small-switch
				switch (valueInput) {
					case null :
						value = null;
						break;
					default:
						value = this.pInputService.turnNumberIntoLocaleNumber(this.locale, valueInput as number | null | undefined);
						break;
				}
				break;
			case PApiPrimitiveTypes.Percent:
				// eslint-disable-next-line sonarjs/no-nested-switch, sonarjs/no-small-switch
				switch (valueInput) {
					case null :
						value = null;
						break;
					default:
						value = this.pInputService.turnNumberIntoLocaleNumber(this.locale, (valueInput as number) * 100);
						break;
				}
				break;
			case PApiPrimitiveTypes.LocalTime :
				// eslint-disable-next-line sonarjs/no-nested-switch, sonarjs/no-small-switch
				switch (valueInput) {
					case null :
						value = null;
						break;
					default :
						// FIXME: PLANO-33048
						// NOTE: Wenn ich das hier auf pDatePipe umschalte, schlägt die interne Validierung fehl weil die
						// noch auf HH:mm prüft.
						// value = this.pDatePipe.transform(valueInput, DateFormat.shortTime);
						value = this.pMoment.d(valueInput as string | number | undefined).format('HH:mm');
				}
				break;
			case PApiPrimitiveTypes.Duration :
				// eslint-disable-next-line sonarjs/no-nested-switch
				switch (this.durationUIType) {
					case PApiPrimitiveTypes.Minutes :
					case null :
						value = this.timestampToMinutes(valueInput as Duration);
						value = this.decimalPipe.transform(value, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS, this.locale);
						break;
					case PApiPrimitiveTypes.Hours :
						value = this.timestampToHours(valueInput as Duration);
						value = this.decimalPipe.transform(value, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS, this.locale);
						break;
					case PApiPrimitiveTypes.Days :
						value = this.timestampToDays(valueInput as Duration);
						value = this.decimalPipe.transform(value, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS, this.locale);
						break;
					default:
						throw new Error('If type of p-input is Duration, then [durationUIType] is required.');
				}
				break;
			case PApiPrimitiveTypes.Euro :
			case PApiPrimitiveTypes.ClientCurrency :
				let result : string | null = null;
				if (!!valueInput && Number.isNaN(+valueInput)) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					value = valueInput as any;
					break;
				}

				/* BUG: fix shadowed value param */
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const countDecimals = (value : number) : number => {
					if (Math.floor(value) !== value) {
						const charsAfterSeparator = value.toString().split('.')[1];
						if (!charsAfterSeparator) return 0;
						return charsAfterSeparator.length || 0;
					}
					return 0;
				};

				if (countDecimals(valueInput === null ? 0 : +valueInput) > 2) {
					assumeDefinedToGetStrictNullChecksRunning(this.locale, 'this.locale');
					result = this.decimalPipe.transform(valueInput, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS, this.locale);
				} else {
					result = this.pCurrencyPipe.transform(valueInput as number, this.type === PApiPrimitiveTypes.Euro ? 'EUR' : this.currencyCode, '', undefined, this.locale, true);
				}
				if (result) result = result.trim();
				value = result ?? null;
				break;
			case PApiPrimitiveTypes.Integer :
			case PApiPrimitiveTypes.number :
				if (this.useSeparatorForThousands && (typeof valueInput !== 'string' || this.pInputService.validateLocaleAwareNumber({value: valueInput}, this.locale) === null)) {
					value = this.decimalPipe.transform(valueInput, DIGITS_INFO_THAT_DOES_NOT_TRIM_DECIMALS, this.locale);
				} else {
					value = valueInput as number | null;
				}
				break;
			default :
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				value = valueInput as any;
		}
		const thousandsSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Group);
		if (value && typeof value === 'string' && thousandsSeparator === '’') {
			value = value.replace(/’/g, '\'');
		}
		return value;
	}

	private timestampToMinutes(timestamp : Duration | null = null) : number | null {
		// TODO: Can probably be replaced by return PMomentService.d(timestamp).asMinutes();
		if (timestamp === null) return null;
		return Math.round((timestamp / 60 / 1000) * 100) / 100;
	}
	private timestampToHours(timestamp : Duration | null = null) : number | null {
		// TODO: Can probably be replaced by return PMomentService.d(timestamp).asHours();
		if (timestamp === null) return null;
		return Math.round((timestamp / 60 / 60 / 1000) * 100) / 100;
	}
	private timestampToDays(timestamp : Duration | null = null) : number | null {
		// TODO: Can probably be replaced by return PMomentService.d(timestamp).asDays();
		if (timestamp === null) return null;
		return Math.round((timestamp / 24 / 60 / 60 / 1000) * 100) / 100;
	}
	private timestampToDuration(timestamp : Duration | null = null) : number | null {
		if (timestamp === null) return null;
		return timestamp;
	}

	public _disabled : boolean = false;

	/**
	 * Is the input in only read mode or is editable (default)?
	 */
	public get readMode() : PFormControlComponentInterface['readMode'] {
		if (this._readMode !== null) return this._readMode;
		return this.disabled;
	}

	/** This always stores the user input, no matter if the type is correct or content is valid */
	private _value : ValueType | null = null;
	public override _onChange : (value : ValueType | null) => void = () => {};
	/* eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public onKeyUp : EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onKeyDown(event : KeyboardEvent) : void {
		/*
			We have in multiple cases that a button exists inside the form that doesn't have the type="button",
			on such cases, when we press enter while the input is focused it will click the first button inside the form
			that doesn't have that type, causing some weird behaviour like uncollapse collapsibles or opening can not set hints.

			For that reason we prevent the default behaviour of the enter key for the inputs,
			instead of having to remember to set it in each button.
		*/
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public keyUp(event : KeyboardEvent) : void {
		// eslint-disable-next-line sonarjs/no-small-switch
		switch (event.key) {
			case 'Escape':
				if (this.type === PApiPrimitiveTypes.Search) {
					event.stopPropagation();
					this.clearValue();
					(event.target as HTMLInputElement).value = '';
					this.inputEl?.nativeElement.blur();
				}
				break;
			default:
				break;
		}

		const newValue = (event.target as HTMLInputElement).value;
		if (this._value !== newValue) this._onChange(newValue);
		this.onKeyUp.emit(event);
	}
	/* eslint-disable-next-line @angular-eslint/no-output-native */
	@Output() public blur = new EventEmitter<FocusEvent>();
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onBlur() : void {
		this.onTouched();
		this.blur.emit();
	}
	/* eslint-disable-next-line @angular-eslint/no-output-native, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Output() public change : EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
	/* eslint-disable-next-line jsdoc/require-jsdoc, @typescript-eslint/no-explicit-any */
	public onChange(event : any) : void {
		const newValue = (event.target as HTMLInputElement).value;
		if (this._value === newValue) return;
		this._onChange(event.target.value);
		this.change.emit(event);
	}

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control that is visible to the user */
	public get value() : ValueType | null { return this._value; }

	/** the value of this control that is visible to the user */
	public set value(value : ValueType | null) {
		if (this._value === value) return;

		this._value = value;
		this._onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param valueInput The new value for the element
	 */
	public writeValue(valueInput : ValueType) : void {
		const newValue = this.transformModelValueIntoUiValue(valueInput);

		if (newValue === null && this.supportsUnset) return;

		if (this._value === newValue) return;
		this._value = newValue;

		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this._onChange = (value : any) => {
			const newValue = this.transformUiValueIntoModelValue(value);
			fn(newValue);
		};
	}

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this._disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this._disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			// make sure the formControl value is up-to-date with the AI value
			if (!this.disabled && this.attributeInfo) this.refreshValue();
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}

	/**
	 * Is the input focused?
	 */
	public get isInputFocused() : boolean {
		return document.activeElement === this.inputEl?.nativeElement;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get inputGroupPrependIconSpin() : boolean {
		if (this.inputGroupPrependIcon === enumsObject.PlanoFaIconPool.SYNCING) return true;
		return false;
	}

	/** Filter all errors that should be shown in the ui. */
	public get visibleErrors() : VisibleErrorsType | null {
		if (this.control)
			return this.pFormsService.visibleErrors(this.control, this._showPasswordMeter ? [
				PPossibleErrorNames.MIN_LENGTH,
				PPossibleErrorNames.LETTERS_REQUIRED,
				PPossibleErrorNames.NUMBERS_REQUIRED,
				PPossibleErrorNames.UPPERCASE_REQUIRED,
				PPossibleErrorNames.PASSWORD,
			] : []);
		return null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showPasswordMeter() : boolean {
		if (!this._showPasswordMeter) return false;
		if (this.control) {
			if (this.hasFocus) return true;
			return this.control.touched && this.control.invalid;
		}
		return true;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get readModeValue() : ValueType {
		if (!this.value) return ' ';
		if (this.type === PApiPrimitiveTypes.Password || this.type === 'ConfirmPassword') {
			let result = '';
			for (const _ITEM of this.value.toString()) result += '•';
			return result;
		}
		return this.value;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showPasswordVisibilityToggleButton() : boolean {
		if (this.type !== PApiPrimitiveTypes.Password && this.type !== 'ConfirmPassword') return false;
		if (this.hidePasswordInput !== null) return false;
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		if (this.readMode || this.disabled) return false;
		return true;
	}

	public hasFocus : boolean | null = null;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onFocusOut(event : FocusEvent) : void {
		this.hasFocus = false;
		this.focusout.emit(event);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onFocus(event : FocusEvent) : void {
		this.hasFocus = true;
		this.focus.emit(event);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public clearValue() : void {
		this.value = '';
	}

	/**
	 * The `role` attribute to be set on the `<input>` HTML element.
	 */
	public get ariaRole() : string {
		if (this.type === PApiPrimitiveTypes.Search) {
			return 'search';
		} else {
			return 'text';
		}
	}

	/**
	 * Set focus in input when prepend icon is clicked
	 */
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents, jsdoc/require-jsdoc */
	public onClickPrependIcon(input : ElementRef<HTMLInputElement> | any) : void {
		if (this.disabled) return;
		input?.nativeElement.focus();
	}

	/**
	 * Open a Modal like info-circle does it when in IS_MOBILE mode.
	 */
	public openCannotSetHint() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.cannotSetHint, 'this.cannotSetHint');
		this.modalService.openCannotSetHintModal(this.cannotSetHint);
	}

	/**
	 * Handle dropdown click
	 */
	public onDropdownClick(dropdownItem : PDropdownItemComponent, event : unknown) : void {
		dropdownItem.onClick.emit(event);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showWholeInputWrapper() : boolean {
		return (this.hasInteractivePrepend === false || this.hasInteractiveAppend === false) && !this.inputHidden;
	}
}
