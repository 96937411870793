import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { CourseFilterService } from '@plano/client/scheduling/course-filter.service';
import { SchedulingService } from '@plano/client/scheduling/scheduling.service';
import { defaultSortingForMembers } from '@plano/client/scheduling/shared/api/scheduling-api-members-sorting.const';
import { ISchedulingApiShift } from '@plano/client/scheduling/shared/api/scheduling-api.interfaces';
import { CalenderTimelineLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-timeline-layout.service';
import { PWishesService } from '@plano/client/scheduling/wishes.service';
import { ToastsService } from '@plano/client/service/toasts.service';
import { AbsenceService } from '@plano/client/shared/absence.service';
import { ShiftAndShiftModelFormTabs } from '@plano/client/shared/component/p-shift-and-shiftmodel-form/p-shift-and-shiftmodel-form.component';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { PTypeOfChange } from '@plano/client/shared/p-transmission/change-selectors-modal.component';
import { SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW } from '@plano/client/shift/shift-modal-sizes';
import { ApiListWrapper, MeService, RightsService, SchedulingApiAssignmentProcess, SchedulingApiAssignmentProcessState, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShifts } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { Data } from '@plano/shared/core/data/data';
import { PComponentInterface } from '@plano/shared/core/interfaces/component.interface';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PModalTemplateDirective } from '@plano/shared/core/p-modal/p-modal-content-template/p-modal-content-template.directive';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { ShiftItemViewStyles } from './shift-item-styles';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export interface IShiftItemComponent extends PComponentInterface {
	readMode : boolean;

	/**
	 * Items in the past can be styles differently from items in the future.
	 */
	isInThePast : boolean;

	muteItem : boolean;
}

@Component({
	selector: 'p-shift-item[shift]',
	templateUrl: './shift-item.component.html',
	styleUrls: ['./shift-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ShiftItemComponent implements PComponentInterface, OnInit, IShiftItemComponent {
	@HostBinding('id') private get hasId() : string | null {
		if (this.shift!.isNewItem()) return null;
		return `${this.shift!.id.toPrettyString()}`;
	}

	@HostBinding('attr.role') private _role = 'listitem';

	/** @see PComponentInterface#isLoading */
	public get isLoading() : PComponentInterface['isLoading'] {
		if (this._isLoading) return true;
		return !this.api.isLoaded() || !this.meService.isLoaded() || !this.shift;
	}
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('isLoading') public _isLoading : PComponentInterface['isLoading'] = false;

	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	@HostBinding('class.clickable')
	@HostBinding('class.card') private _alwaysTrue : boolean = true;

	@HostBinding('class.showAsList') private get _hasShowAsListClass() : boolean {
		return this.showAsList;
	}
	@HostBinding('class.showAsTimeline') private get _hasShowAsTimelineClass() : boolean {
		return !this.showAsList;
	}
	@HostBinding('class.mr-1')
	@HostBinding('class.ml-1')
	@HostBinding('class.mb-2') private get _hasSomeBottomSpaceClass() : boolean {
		return this.hasSomeSpaceAround;
	}

	@HostBinding('class.shadow') private get _hasShadowClass() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		return !Config.IS_MOBILE && this.highlightService.isHighlighted(this.shift);
	}
	@HostBinding('class.btn-outline-secondary')
	@HostBinding('class.border-left-0')
	@HostBinding('class.border-right-0')
	@HostBinding('class.o-hidden') private get _isMobile() : boolean {
		return Config.IS_MOBILE;
	}

	@HostBinding('class.highlighted') private get _hasHighlightedClass() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		return this.highlightService.isHighlighted(this.shift);
	}
	@HostBinding('style.z-index') private get _styleZIndex() : string {
		if (!this.showAsList) {
			return this.layout.getLayout(this.shift as ISchedulingApiShift).z.toString();
		}
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		if (!this.highlightService.isHighlighted(this.shift)) return '0';
		return '1020';
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	@HostBinding('class.is-in-the-past-bg') public get isInThePast() : boolean {
		return this._isInThePast;
	}

	@HostBinding('style.left.px') private get _styleLeft() : number | undefined {
		if (this.showAsList) return undefined;
		return this.layout.getLayout(this.shift as ISchedulingApiShift).x;
	}

	@HostBinding('style.top.px') 	private get _styleTop() : number | undefined {
		if (this.showAsList) return undefined;
		return this.layout.getLayout(this.shift as ISchedulingApiShift).y;
	}

	@HostBinding('style.width.px') 	private get _styleWidth() : number | undefined {
		if (this.showAsList) return undefined;
		return this.layout.getLayout(this.shift as ISchedulingApiShift).width;
	}

	@HostBinding('style.height.px') private get _styleHeight() : number | undefined {
		if (this.showAsList) return undefined;
		return this.layout.getLayout(this.shift as ISchedulingApiShift).height;
	}

	@HostBinding('class.max-width-600') private get _hasClassMaxWidth600() : boolean {
		return this.classMaxWidth600;
	}

	@HostBinding('class.m-0') private get _hasClassM0() : boolean {
		return !this.classMaxWidth600;
	}

	@HostBinding('style.border-color') private get _borderColor() : string {
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		return this.highlightService.isHighlighted(this.shift) ? `#${this.shift.model.color}` : '';
	}

	@HostBinding('class.wiggle') private get _hasWiggleClass() : boolean {
		if (!this.showAsList) return false;
		if (Config.IS_MOBILE) return false;
		if (this.shift!.wiggle) {
			this.zone.runOutsideAngular(() => {
				window.setTimeout(() => {
					this.shift!.wiggle = false;
				}, 2000);
			});
		}
		return this.shift!.wiggle;
	}

	/**
	 * FIXME: Quick n dirty for 1.7.0
	 */
	@Input('showAsList') private _showAsList : boolean = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shift ?: SchedulingApiShift | null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public emptyMemberSlots : SchedulingApiShift['emptyMemberSlots'] = 0;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public viewStyle : ShiftItemViewStyles = ShiftItemViewStyles.SMALL;

	/**
	 * With this boolean the multi-select checkboxes can be turned off for this shift
	 */
	@Input() public selectable : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public selectedChange : EventEmitter<boolean> = new EventEmitter<boolean>();

	/** @see ShiftItemListComponent#onDismissShiftSelected */
	@Output() public onDismissShiftSelected = new EventEmitter<SchedulingApiShifts>();

	/**
	 * A property to overwrite any internal logic that decides if course-info is visible or not.
	 */
	@Input('showCourseInfo') private _showCourseInfo : boolean | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onClick = new EventEmitter<{shift : SchedulingApiShift, event : MouseEvent}>();

	/**
	 * Modal to remove a shift
	 */
	@ViewChild('modalContent') private removeShiftModal ?: PModalTemplateDirective;

	/**
	 * Mark shift as highlighted
	 */
	@HostListener('click', ['$event']) private _onClickShift( event : MouseEvent) : void {
		// a click on the calendar removes the highlighted shift.
		event.stopPropagation();

		if (
			this.viewStyle !== ShiftItemViewStyles.BUTTON &&
			this.viewStyle !== ShiftItemViewStyles.MEDIUM
		) {
			assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
			if (this.highlightService.isHighlighted(this.shift)) {
				this.highlightService.setHighlighted(null);
				this.wishesService.item = null;
			} else {
				this.highlightService.setHighlighted(this.shift);
				this.wishesService.item = this.shift;
			}
		}

		this.onClick.emit({
			shift: this.shift!,
			event: event,
		});
	}

	constructor(
		public api : SchedulingApiService,
		public meService : MeService,
		public layout : CalenderTimelineLayoutService,
		public highlightService : HighlightService,
		private schedulingService : SchedulingService,
		private absenceService : AbsenceService,
		public rightsService : RightsService,
		private wishesService : PWishesService,
		public courseService : CourseFilterService,
		private pShiftExchangeService : PShiftExchangeService,
		private pMoment : PMomentService,
		private modalService : ModalService,
		private localize : LocalizePipe,
		private toastsService : ToastsService,
		private zone : NgZone,
	) {
	}

	public enums = enumsObject;
	public ShiftAndShiftModelFormTabs = ShiftAndShiftModelFormTabs;
	public PTypeOfChange = PTypeOfChange;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showCourseInfo() : boolean {
		if (this._showCourseInfo !== null) return this._showCourseInfo;
		if (this.courseService.courseVisible !== null) return this.courseService.courseVisible;
		return false;
	}

	private now ! : number;
	private _isInThePast : boolean = false;

	public ngOnInit() : void {
		this.now = +this.pMoment.m();
		this._isInThePast = this.pMoment.m(this.shift!.end).isBefore(this.now);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showAsList() : boolean {
		if (this.schedulingService.urlParam.calendarMode === CalendarModes.MONTH) return true;
		return this._showAsList;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showProcessStatusIcon() : boolean {
		// Status icon is not relevant while user is in earlyBirdMode
		if (this.showAssignMeButton) return false;

		// Is not part of a process?
		if (!this.process) return false;

		// Owners and bereichsleitende can see all status icons
		if (this.rightsService.userCanSetAssignmentProcess(this.process)) {
			return true;
		}

		// Members can see the state if it is not APPROVE or EARLY_BIRD_FINISHED
		if (this.process.state === this.states.APPROVE) return false;
		if (this.process.state === this.states.EARLY_BIRD_FINISHED) return false;

		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shiftIsSelectable() : boolean {
		if (!this.schedulingService.wishPickerMode) return true;
		if (this.allowedToPickWish) return true;

		return false;
	}

	/**
	 * Close tooltip if any and open details of shift
	 */
	public clickOpenShiftItem(event : MouseEvent) : void {
		if (this.modalService.modalRef) {
			this.modalService.modalRef.dismiss();
		}
		event.stopPropagation();
		this.highlightService.setHighlighted(null);
	}

	/**
	 * Router link to open shift items
	 */
	public openShiftItemLink(input : {
		shift : SchedulingApiShift,
		openTab ?: ShiftAndShiftModelFormTabs,
	}) : string {
		const openTabAsString : string = input.openTab ?? ShiftAndShiftModelFormTabs.basissettings;
		return `/client/shift/${input.shift.id.toUrl()}/${openTabAsString}`;
	}

	/**
	 * Open remove shift modal when clicked from the tooltip
	 */
	public removeShiftItem(input : {
		shift : SchedulingApiShift,
		event : MouseEvent
	}) : void {
		input.event.stopPropagation();
		this.api.createDataCopy();

		// You would probably expect
		// this.pDetailFormUtilsService.onRemoveClick(…);
		// here, but since we need a change-selectors-modal, things are different.
		void this.modalService.openModal(this.removeShiftModal!.template, {
			size: SIZE_OF_SHIFT_MODAL_WITH_TRANSMISSION_PREVIEW,
		}).result.then(async (value) => {
			if (value.modalResult === 'dismiss') {
				this.api.dismissDataCopy();
			} else {
				this.api.mergeDataCopy();
				this.api.data.shifts.removeItem(input.shift);
				return this.api.save({
					success: () => {
						this.toastsService.addToast({
							title: null,
							content: this.localize.transform({sourceString:'»${item}« wurde gelöscht.', params:{
								item: input.shift.name,
							}}),
							theme: this.enums.PThemeEnum.DANGER,
							visibilityDuration: 'medium',
						});
					},
				});
			}
		});
	}

	private _process : Data<SchedulingApiAssignmentProcess | null> =
		new Data<SchedulingApiAssignmentProcess>(this.api);

	/**
	 * Get the the process where this shift-item is included
	 */
	public get process() : SchedulingApiAssignmentProcess | null {
		return this._process.get(() => {
			// TODO: PLANO-156519
			if (!this.api.data.attributeInfoAssignmentProcesses.isAvailable) return null;
			if (!this.api.data.assignmentProcesses.length) return null;
			return this.api.data.assignmentProcesses.getByShiftId(this.shift!.id);
		});
	}

	/**
	 * Check if logged in user is assignable to this shift.
	 */
	public get meIsAssignable() : boolean {
		if (!this.meService.isLoaded()) return false;
		return this.shift!.assignableMembers.containsMemberId(this.meService.data.id);
	}

	private get allowedToPickWish() : boolean {
		// backend decides if user can pick wish
		if (!this.process) return false;
		const SHIFT_REFS = this.process.shiftRefs;
		const RELATED_SHIFT_REF = SHIFT_REFS.get(this.shift!.id);
		if (!RELATED_SHIFT_REF) throw new Error('No related shiftRef');
		return RELATED_SHIFT_REF.requesterCanSetPref === true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get muteItem() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		if (this.shift.selected) return false;
		if (this.highlightService.highlightedItem && this.highlightService.isMuted(this.shift)) {
			return true;
		}
		if (!this.shiftIsSelectable) return true;

		// App is in assign-me-mode but user is not assignable
		if (this.schedulingService.earlyBirdMode && !this.showAssignMeButton) return true;

		if (
			Config.IS_MOBILE &&
			this.schedulingService.wishPickerMode &&
			!(this.selectable && this.allowedToPickWish)
		) return true;

		return false;
	}

	public states : typeof SchedulingApiAssignmentProcessState = SchedulingApiAssignmentProcessState;

	/**
	 * Is the current user allowed to be assigned? Is the related process an early bird thing etc.
	 */
	public get showAssignMeButton() : boolean {
		// Is not in assign-me-mode?
		if (!this.schedulingService.earlyBirdMode) return false;

		// backend decides if user can pick wish
		return this.process?.shiftRefs.get(this.shift!.id)?.requesterCanDoEarlyBird === true || false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showMultiSelectCheckbox() : boolean | 'only-on-hover' {
		// Should never be visible in combination with earlyBirdMode
		if (this.schedulingService.earlyBirdMode) return false;
		if (this.schedulingService.wishPickerMode) return this.shiftIsSelectable;

		if (this.api.isLoaded() && this.api.hasSelectedItems) return true;
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		if (!Config.IS_MOBILE && this.highlightService.isHighlighted(this.shift)) return true;
		if (
			!Config.IS_MOBILE &&
			!this.shift.selected
		) return 'only-on-hover';
		return false;
	}

	private _assignedMembers : Data<ApiListWrapper<SchedulingApiMember>> =
		new Data<ApiListWrapper<SchedulingApiMember>>(this.api);
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get assignedMembers() : ApiListWrapper<SchedulingApiMember> {
		return this._assignedMembers.get(() => {
			return this.shift!.assignedMembers.sortedBy(
				[
					// Prio 1: Absent members
					(item) => {
						assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
						!this.absenceService.overlappingAbsences(item.id, this.shift).length;
					},

					// Prio 2: Current user
					(item) => !item.id.equals(this.meService.data.id),

					// Sort them by name
					...defaultSortingForMembers,
				],
			);
		});
	}

	/**
	 * Check if this component is fully loaded.
	 * Can be used to show skeletons/spinners then false.
	 */
	public get isLoaded() : boolean {
		if (!this.api.isLoaded()) return false;

		// NOTE: The item will be null if it could not be found
		if (this.shift === null) return true;
		if (!this.meService.isLoaded()) return false;
		if (!this.shift) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasSomeBottomSpace() : boolean {
		if (this.viewStyle === ShiftItemViewStyles.DETAILED) return false;
		if (!this.hasSomeSpaceAround) return false;
		return true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get hasSomeSpaceAround() : boolean {
		if (Config.IS_MOBILE) return false;
		if (this.viewStyle === ShiftItemViewStyles.MULTI_SELECT) return true;
		if (this.viewStyle === ShiftItemViewStyles.MEDIUM) return true;
		if (this.viewStyle === ShiftItemViewStyles.MEDIUM_MULTI_SELECT) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get classMaxWidth600() : boolean {
		if (!this.showAsList) return false;
		if (this.viewStyle === ShiftItemViewStyles.MEDIUM) return true;
		if (this.viewStyle === ShiftItemViewStyles.MEDIUM_MULTI_SELECT) return true;
		if (this.viewStyle === ShiftItemViewStyles.DETAILED) return true;
		return false;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showShiftExchangeIcon() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		return this.pShiftExchangeService.shiftHasActiveShiftExchangeSearch(this.shift);
	}
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showIllnessIcon() : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shift, 'this.shift');
		return this.pShiftExchangeService.shiftHasActiveIllness(this.shift);
	}
}
