import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { sortMenuItems } from '@plano/client/shared/menu-navigation-items';
import { MemberBadgeComponent } from '@plano/client/shared/p-member/member-badges/member-badge/member-badge.component';
import { MeService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { PDictionarySourceString } from '@plano/shared/core/pipe/localize.dictionary';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PDropdownItemComponent } from '@plano/shared/p-forms/p-dropdown/p-dropdown-item/p-dropdown-item.component';

type AnchorSettingsInfo = {
	anchorRouterLink : `/${string}`,
	label : PDictionarySourceString,
	prependedItem : PDropdownItemComponent['prependedItem'],
	anchorShowCondition ?: boolean
};

@Component({
	selector: 'p-settings-menu-for-mobile',
	templateUrl: './settings-menu-for-mobile.component.html',
	styleUrls: ['./settings-menu-for-mobile.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SettingsMenuForMobileComponent implements AfterViewInit, OnDestroy {

	@HostBinding('class.bg-light-cold') private _alwaysTrue = true;

	@ViewChild('memberBadgeComponent') public memberBadgeComponent ?: MemberBadgeComponent;

	constructor(
		public meService : MeService,
		private modalService : ModalService,
		private localize : LocalizePipe,
		private pRouterService : PRouterService,
	) {
		sortMenuItems(this.anchorSettingsOptions);
	}

	public readonly CONFIG = Config;

	public ngOnDestroy() : void {
		document.body.querySelector('p-app-root')?.classList.remove('over-tawk');
	}
	public ngAfterViewInit() : void {
		document.body.querySelector('p-app-root')?.classList.add('over-tawk');
	}

	/**
	 * Get the width to be applied to the icons based on the member badge width
	 */
	public iconWidthStyle(memberBadgeWidth : number) : string {
		return `width:${memberBadgeWidth}px; min-width:${memberBadgeWidth}px`;
	}

	public enums = enumsObject;

	private iconClickedCount = 0;

	/**
	 * Event being called when the icon at bottom of this component is being clicked.
	 */
	public iconClicked() : void {
		++this.iconClickedCount;

		// notify the native app that it should open staging page.
		// This makes it easier to test local staging version without the need
		// to start the native apps locally.
		const isNativeApp = (Config.platform === 'appAndroid' || Config.platform === 'appIOS');

		if (this.iconClickedCount >= 10 && isNativeApp) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).nsWebViewInterface.emit('openStaging');
		}
	}

	/**
	 * Method to handle the confirmation modal to logout on mobile
	 */
	public async logoutConfirmationModal() : Promise<void> {
		const result = await this.modalService.confirm({
			description: this.localize.transform('Möchtest du dich wirklich ausloggen?'),
			modalTitle: null,
			closeBtnLabel: this.localize.transform('Ja'),
		},{
			theme: enumsObject.PThemeEnum.WARNING,
		}).result;
		if (result.modalResult === 'success') {
			// eslint-disable-next-line ban/ban -- intended navigation
			await this.pRouterService.navigate(['/client/logout']);
		}
	}

	/**
	 * Items to be displayed as options in the mobile menu.
	 *
	 * The order here does not matter, it is defined in:
	 * @plano/client/shared/menu-navigation-items
	 */
	public anchorSettingsOptions : AnchorSettingsInfo[] = [
		{
			anchorRouterLink: '/client/member/me',
			label: 'Mein Profil',
			prependedItem: null,
		},
		{
			anchorRouterLink: '/client/account',
			label: 'Account & Standort',
			anchorShowCondition: this.meService.data.isOwner,
			prependedItem: null,
		},
		{
			anchorRouterLink: '/client/ical',
			label: 'Kalender-Synchronisation',
			prependedItem: null,
		},
		{
			anchorRouterLink: '/client/devices',
			label: 'Stempeluhr-Einstellungen',
			anchorShowCondition: this.meService.data.isOwner,
			prependedItem: null,
		},
		{
			anchorRouterLink: '/client/notifications',
			label: 'Benachrichtigungs-Einstellungen',
			prependedItem: null,
		},
		{
			anchorRouterLink: '/client/plugin',
			label: 'Buchungssystem-Einstellungen',
			anchorShowCondition: this.meService.data.isOwner,
			prependedItem: null,
		},
	];
}
