import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EditableHookType } from '@plano/client/shared/p-editable/editable/editable.directive';
import { AiSwitchShowcaseApiService } from '@plano/shared/api';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { AI_SWITCH_OPTION_REQUIRED } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';
import { PInputDateTypes } from '@plano/shared/p-forms/p-input-date/p-input-date.component';

/**
 * TODO: At the time of implementing this, we dont have access on storybook in Bitbucket pipeline. So, this is a quick solution
 * to show the attributes defined in `AiSwitchShowcaseApiService` in the app to be able to define UI tests for them.
 *
 * This should be moved to story-book when we have access to storybook in pipeline.
 */
@Component({
	selector: 'p-ai-switch-showcase-custom',
	templateUrl: './p-ai-switch-showcase-custom.component.html',
	styleUrls: ['./p-ai-switch-showcase-custom.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class PAiSwitchShowcaseCustomComponent {
	constructor(
		public aiSwitchShowcaseApi : AiSwitchShowcaseApiService,
		private modalService : ModalService,
	) {
		void this.aiSwitchShowcaseApi.load();
	}

	public PInputDateTypes = PInputDateTypes;
	public AI_SWITCH_OPTION_REQUIRED = AI_SWITCH_OPTION_REQUIRED;

	public formGroup = new UntypedFormGroup({});
	public daysBefore : number | null = null;

	/** A modal that opens and asks if the user is sure he/she wants to save the changes */
	public get saveChangesHook() : () => EditableHookType | null {
		return async () => this.modalService.confirm({
			modalTitle: 'Sure?',
			description: 'Are you sure you want to save the changes?',
		}).result;
	}
}
