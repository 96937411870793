<table class="table">
	<tr *ngIf="!members.length">
		<td i18n>Aus deinem Team ist sonst niemand eingestempelt 💤</td>
	</tr>
	<tr class="hover-hide-trashed" *ngFor="let stampedMember of members.iterable()">
		<td>
			<div class="d-flex align-items-center gap-2">
				<p-member-badge
					[memberId]="stampedMember.id"
					[firstName]="stampedMember.firstName"
					[lastName]="stampedMember.lastName"
					[avatar]="stampedMember.avatar"
					size="normal"
				></p-member-badge>
				<span>
					{{ stampedMember.firstName }} {{ stampedMember.lastName | pLimitTo : '1' }}.
				</span>
			</div>
		</td>
		<td class="text-right text-monospace">
			<span [innerHTML]="stampedMember.activityDuration | pDurationTime:false | pSafeHtml"></span>
		</td>
		<td>
			<p-stopwatch-image [stampedMember]="stampedMember"></p-stopwatch-image>
		</td>
	</tr>
</table>
