<div
	*ngIf="showContent"
	class="flex-grow-1 crop-on-overflow nowrap selected align-self-stretch d-flex align-items-center px-3 rounded-left">
	<strong i18n>{!!selectedAmount, select, true {<span class="text-primary">{{selectedAmount}}</span> {!!selectedAmount && +selectedAmount, plural, one {Schicht} other {Schichten}} selektiert}}</strong>
</div>
<div class="btn-group">
	<button
		pTooltip="Alles deselektieren" i18n-pTooltip
		type="button"
		class="btn btn-sm btn-secondary rounded-0"
		(click)="onClickDeselect()"
		><fa-icon class="text-primary" [icon]="enums.PlanoFaIconPool.CHECKBOX_SELECTED"></fa-icon></button>
	<button
		[pTooltip]="!isRemoveBtnDisabled ? 'Selektierte Schichten löschen' : 'Du kannst maximal <strong>15</strong> Schichten auf einmal löschen. Für größere Lösch-Vorhaben nutze bitte die Übertragungsfunktion: Gehe dazu in eine der gewünschten Schichten, lösche sie und übertrage das auf beliebig viele weitere Schichten.'" i18n-pTooltip
		*ngIf="showRemoveShiftsBtn"
		type="button"
		class="btn btn-sm btn-secondary"
		[disabled]="isRemoveBtnDisabled"
		(click)="onClickRemove(modalContent.template)"
		><fa-icon class="text-danger" [icon]="enums.PlanoFaIconPool.TRASHED"></fa-icon></button>
</div>

<ng-template #modalContent="pModalTemplateExportAsRef" pModalTemplate let-c="close" let-d="dismiss">
	<p-change-selectors-modal
		*ngIf="api.isLoaded()"
		[showApplyToShiftModelCheckbox]="false"
		[shiftChangeSelector]="api.data.shiftChangeSelector"
		[close]="c"
		[dismiss]="d"
		[shifts]="this.api.data.shifts.selectedItems"
		[typeOfChange]="PTypeOfChange.DELETE"
		[members]="api.data.members"
	></p-change-selectors-modal>
</ng-template>


