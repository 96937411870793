import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AiSwitchShowcaseApiService } from '@plano/shared/api';

/**
 * TODO: At the time of implementing this, we dont have access on storybook in Bitbucket pipeline. So, this is a quick solution
 * to show the attributes defined in `AiSwitchShowcaseApiService` in the app to be able to define UI tests for them.
 *
 * This should be moved to story-book when we have access to storybook in pipeline.
 */
@Component({
	selector: 'p-ai-switch-showcase-editable',
	templateUrl: './p-ai-switch-showcase-editable.component.html',
	styleUrls: ['./p-ai-switch-showcase-editable.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class PAiSwitchShowcaseEditableComponent {
	constructor(public aiSwitchShowcaseApi : AiSwitchShowcaseApiService) {
		void this.aiSwitchShowcaseApi.load();
	}
}
