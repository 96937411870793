/* eslint max-lines: ["error", 200] -- It is expected that a core module has more lines than the average */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertComponent } from '@plano/shared/core/component/alert/alert.component';
import { WarningsComponent } from '@plano/shared/core/component/alerts/warnings.component';
import { ErrorModalContentComponent } from '@plano/shared/core/component/error-modal-content/error-modal-content.component';
import { PFaIconModule } from '@plano/shared/core/component/fa-icon/fa-icon.module';
import { PGridModule } from '@plano/shared/core/component/grid/grid.module';
import { PPageHeadlineComponent } from '@plano/shared/core/component/icon-headline/page-headline.component';
import { PIconTooltipComponent } from '@plano/shared/core/component/icon-tooltip/icon-tooltip.component';
import { PNavBackHeadlineComponent } from '@plano/shared/core/component/nav-back-headline/p-nav-back-headline.component';
import { POnlinePaymentNotSupportedAlertComponent } from '@plano/shared/core/component/online-payment-not-supported-alert/online-payment-not-supported-alert.component';
import { PDebugComponent } from '@plano/shared/core/component/p-debug/p-debug.component';
import { PHeadlineComponent } from '@plano/shared/core/component/p-headline/p-headline.component';
import { PNavBackAnchorComponent } from '@plano/shared/core/component/p-nav-back-anchor/nav-back-anchor.component';
import { PProgressComponent } from '@plano/shared/core/component/p-progress/p-progress.component';
import { PToastComponent } from '@plano/shared/core/component/p-toast/p-toast.component';
import { PTodoComponent } from '@plano/shared/core/component/p-todo/p-todo.component';
import { PYoutubeComponent } from '@plano/shared/core/component/p-youtube/p-youtube.component';
import { SpinnerComponent } from '@plano/shared/core/component/spinner/spinner.component';
import { ValidationHintComponent } from '@plano/shared/core/component/validation-hint/validation-hint.component';
import { YouKnowWhatComponent } from '@plano/shared/core/component/you-know-what/you-know-what.component';
import { RouterStorageDirective } from '@plano/shared/core/directive/anchor-url-storage.directive';
import { DisabledDirective } from '@plano/shared/core/directive/disabled.directive';
import { PAutoFocusInsideModalDirective } from '@plano/shared/core/p-auto-focus/p-auto-focus.directive';
import { PModalModule } from '@plano/shared/core/p-modal/modal.module';
import { PTooltipModule } from '@plano/shared/core/p-tooltip/tooltip.module';
import { CorePipesModule } from '@plano/shared/core/pipe/core-pipes.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgProgressModule } from 'ngx-progressbar';

@NgModule({
	imports: [
		NgxPopperjsModule.forRoot(),
		CommonModule,
		CorePipesModule,
		DisabledDirective,
		FormsModule,
		NgProgressModule,
		PAutoFocusInsideModalDirective,
		PFaIconModule,
		PGridModule,
		PModalModule,
		PTooltipModule,
		RouterModule,
		RouterStorageDirective,
		TooltipModule,
		YouKnowWhatComponent,
	],
	declarations: [
		AlertComponent,
		ErrorModalContentComponent,
		PDebugComponent,
		PHeadlineComponent,
		PIconTooltipComponent,
		PNavBackAnchorComponent,
		PNavBackHeadlineComponent,
		POnlinePaymentNotSupportedAlertComponent,
		PPageHeadlineComponent,
		PProgressComponent,
		PToastComponent,
		PTodoComponent,
		PYoutubeComponent,
		SpinnerComponent,
		ValidationHintComponent,
		WarningsComponent, // TODO: This component includes MeService. Move to another Module, to make CoreComponentsModule Api-free. Alternative: Get SchedulingApiWarnings without bootstrapping our whole api-code.
	],
	providers: [],
	exports: [
		AlertComponent,
		DisabledDirective,
		ErrorModalContentComponent,
		NgxPopperjsModule,
		PAutoFocusInsideModalDirective,
		PDebugComponent,
		PHeadlineComponent,
		PIconTooltipComponent,
		PNavBackAnchorComponent,
		PNavBackHeadlineComponent,
		POnlinePaymentNotSupportedAlertComponent,
		PPageHeadlineComponent,
		PProgressComponent,
		PToastComponent,
		PTodoComponent,
		PYoutubeComponent,
		SpinnerComponent,
		ValidationHintComponent,
		WarningsComponent,
	],
})
export class CoreComponentsModule {}
