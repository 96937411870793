import { Inject, Injectable, LOCALE_ID, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastsService } from '@plano/client/service/toasts.service';
import { PSupportedLocaleIds } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { PCookieService, PServiceWithCookiesInterface } from '@plano/shared/core/p-cookie.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { CalendarModes } from './calendar-modes';
import { AbstractSchedulingApiBasedPagesService } from './scheduling-api-based-pages.service';
import { BookingsService } from './shared/p-bookings/bookings.service';
import { PWishesService } from './wishes.service';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class SchedulingService extends AbstractSchedulingApiBasedPagesService implements PServiceWithCookiesInterface {
	constructor(
		protected override readonly zone : NgZone,
		protected override readonly bookingsService : BookingsService,
		protected override readonly pCookieService : PCookieService,
		protected override readonly console : LogService,
		protected override readonly router : Router,
		@Inject(LOCALE_ID) protected override readonly locale : PSupportedLocaleIds,
		private localizePipe ?: LocalizePipe,
		private pWishesService ?: PWishesService,
		private toastsService ?: ToastsService,
	) {
		super('calendar', zone, bookingsService, pCookieService, console, router, locale);
		this.readCookies();
		this.initValues();
	}

	private _showDayAsList : boolean | null = null;
	private _showWeekAsList : boolean | null = null;
	private _wishPickerMode : boolean | null = null;
	private _earlyBirdMode : boolean | null = null;

	/**
	 * afterNavigationCallbacks can store callbacks that can be executed later when the api is loaded
	 */
	public override afterNavigationCallbacks : (() => void)[] = [];

	/**
	 * Init all necessary default values for this class
	 */
	public override initValues() : void {
		super.initValues();

		if (this._wishPickerMode === null) this._wishPickerMode = false;
		if (this._earlyBirdMode === null) this._earlyBirdMode = false;
		if (this._showDayAsList === null) this._showDayAsList = false;
		if (this._showWeekAsList === null) this._showWeekAsList = true;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showDayAsList() : boolean {
		if (Config.IS_MOBILE || this._showDayAsList === null || this.urlParam.calendarMode === CalendarModes.MONTH) {
			return true;
		}
		return this._showDayAsList;
	}
	public set showDayAsList(value : boolean) {
		this.pCookieService.put({name: 'showDayAsList', prefix: null}, value);
		this._showDayAsList = value;
		this.changed(undefined);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get showWeekAsList() : boolean {
		if (Config.IS_MOBILE || this._showWeekAsList === null || this.urlParam.calendarMode === CalendarModes.MONTH) {
			return true;
		}
		return this._showWeekAsList;
	}
	public set showWeekAsList(value : boolean) {
		this.pCookieService.put({name: 'showWeekAsList', prefix: null}, value);
		this._showWeekAsList = value;
		this.changed(undefined);
	}

	private triggerThankYouMessage() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.localizePipe);
		let msg : string = this.localizePipe.transform('Danke für deine Schichtwünsche.');
		assumeDefinedToGetStrictNullChecksRunning(this.pWishesService);
		if (this.pWishesService.freeWishesCount) {
			msg += `<br>${this.pWishesService.freeWishesCount}`;
			if (this.pWishesService.freeWishesCount === 1) {
				msg += ` ${this.localizePipe.transform('Schichtwunsch ist noch offen.')}`;
			} else if (this.pWishesService.freeWishesCount > 1) {
				msg += ` ${this.localizePipe.transform('Schichtwünsche sind noch offen.')}`;
			}
		}

		assumeDefinedToGetStrictNullChecksRunning(this.toastsService);
		this.toastsService.addToast({
			content: msg,
			theme: this.pWishesService.freeWishesCount ? enumsObject.PThemeEnum.INFO : enumsObject.PThemeEnum.SUCCESS,
			visibleOnMobile: true,
		});
	}

	/**
	 * Is currently the wish-picking mode started?
	 */
	public get wishPickerMode() : boolean {
		return this._wishPickerMode!;
	}
	public set wishPickerMode(value : boolean) {
		// NOTE: don’t write wishPickerMode to cookies. https://drplano.atlassian.net/browse/PLANO-7903
		// this.cookie.put({name: 'wishPickerMode', prefix: null}, value);
		this._earlyBirdMode = false;
		this._wishPickerMode = value;
		this.changed(undefined);
		if (value === false) this.triggerThankYouMessage();
	}

	/**
	 * Is currently the early-bird picking mode started?
	 */
	public get earlyBirdMode() : boolean {
		return this._earlyBirdMode!;
	}
	public set earlyBirdMode(value : boolean) {
		// NOTE: don’t write earlyBirdMode to cookies. https://drplano.atlassian.net/browse/PLANO-7903
		// this.cookie.put({name: 'earlyBirdMode', prefix: null}, value);
		this._wishPickerMode = false;
		this._earlyBirdMode = value;
		this.changed(undefined);
	}

	/**
	 * Read values from cookies if available
	 */
	public override readCookies() : void {
		super.readCookies();

		if (this.pCookieService.has({name: 'showWeekAsList', prefix: null})) {
			this.showWeekAsList = this.pCookieService.get({name: 'showWeekAsList', prefix: null}) === 'true';
		}
		if (this.pCookieService.has({name: 'showDayAsList', prefix: null})) {
			this.showDayAsList = this.pCookieService.get({name: 'showDayAsList', prefix: null}) === 'true';
		}

		// NOTE: Don’t write wishPickerMode to cookies. https://drplano.atlassian.net/browse/PLANO-7903
		if (this.pCookieService.has({name: 'wishPickerMode', prefix: null})) {
			this.wishPickerMode = this.pCookieService.get({name: 'wishPickerMode', prefix: null}) === 'true';
		}

		// NOTE: Don’t write earlyBirdMode to cookies. https://drplano.atlassian.net/browse/PLANO-7903
		if (this.pCookieService.has({name: 'earlyBirdMode', prefix: null})) {
			this.earlyBirdMode = this.pCookieService.get({name: 'earlyBirdMode', prefix: null}) === 'true';
		}
	}

	/** @see PServiceInterface#unload */
	public override unload() : void {
		super.unload();
		this._showDayAsList = null;
		this._showWeekAsList = null;
		this._wishPickerMode = null;
		this._earlyBirdMode = null;
	}
}
