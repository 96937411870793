/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';



/**
 * This service enables access to the api "register_test_account".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	OWNER_GENDER = 1;
	OWNER_FIRST_NAME = 2;
	OWNER_LAST_NAME = 3;
	OWNER_EMAIL = 4;
	OWNER_PHONE = 5;
	OWNER_PASSWORD = 6;
	COMPANY = 7;
	POSTAL_CODE = 8;
	COUNTRY = 9;
	HOW_DO_YOU_KNOW = 10;
	HOW_DO_YOU_KNOW_TEXT = 11;
}


@Injectable({
  providedIn: 'root',
})
export class RegisterTestAccountApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'register_test_account');
	}

	protected version() : string {
		return '03546e3bfc4fddd8b395cc395ddd182e,b3279cce888d7443772e78b15d0dfe6f';
	}

	private dataWrapper = new RegisterTestAccountApiRoot(this, );

	get data() : RegisterTestAccountApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : RegisterTestAccountApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new RegisterTestAccountApiRoot(this, );
	}
}

		 
export class RegisterTestAccountApiRoot extends ApiObjectWrapper<RegisterTestAccountApiRoot>
{
	constructor(override readonly api : RegisterTestAccountApiService | null,
		
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<RegisterTestAccountApiRoot> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, null, RegisterTestAccountApiRoot as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiRoot> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiRoot>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: '',
			nodeName: 'ROOT',
			defaultValue: function(this : RegisterTestAccountApiRoot, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoOwnerGender : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiGender> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiGender>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerGender',
			nodeName: 'OWNER_GENDER',
			primitiveType: PApiPrimitiveTypes.Enum,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoOwnerFirstName : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerFirstName',
			nodeName: 'OWNER_FIRST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoOwnerLastName : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerLastName',
			nodeName: 'OWNER_LAST_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 3,
		});
	attributeInfoOwnerEmail : ApiAttributeInfo<RegisterTestAccountApiRoot, Email> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Email>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerEmail',
			nodeName: 'OWNER_EMAIL',
			primitiveType: PApiPrimitiveTypes.Email,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Email, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
						this.api!.asyncValidators.emailValidAsync.bind(this.api!.asyncValidators, true
						 ),
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoOwnerPhone : ApiAttributeInfo<RegisterTestAccountApiRoot, Tel> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Tel>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerPhone',
			nodeName: 'OWNER_PHONE',
			primitiveType: PApiPrimitiveTypes.Tel,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Tel, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 5,
		});
	attributeInfoOwnerPassword : ApiAttributeInfo<RegisterTestAccountApiRoot, Password> = new ApiAttributeInfo<RegisterTestAccountApiRoot, Password>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'ownerPassword',
			nodeName: 'OWNER_PASSWORD',
			primitiveType: PApiPrimitiveTypes.Password,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Password, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoCompany : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'company',
			nodeName: 'COMPANY',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoPostalCode : ApiAttributeInfo<RegisterTestAccountApiRoot, PostalCode> = new ApiAttributeInfo<RegisterTestAccountApiRoot, PostalCode>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'postalCode',
			nodeName: 'POSTAL_CODE',
			primitiveType: PApiPrimitiveTypes.PostalCode,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.PostalCode, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoCountry : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiCountry> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiCountry>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'country',
			nodeName: 'COUNTRY',
			primitiveType: PApiPrimitiveTypes.Enum,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoHowDoYouKnow : ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiHowDoYouKnow> = new ApiAttributeInfo<RegisterTestAccountApiRoot, RegisterTestAccountApiHowDoYouKnow>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'howDoYouKnow',
			nodeName: 'HOW_DO_YOU_KNOW',
			primitiveType: PApiPrimitiveTypes.Enum,
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoHowDoYouKnowText : ApiAttributeInfo<RegisterTestAccountApiRoot, string> = new ApiAttributeInfo<RegisterTestAccountApiRoot, string>({
			apiObjWrapper: this as any as RegisterTestAccountApiRoot,
			name: 'howDoYouKnowText',
			nodeName: 'HOW_DO_YOU_KNOW_TEXT',
			primitiveType: PApiPrimitiveTypes.string,
			isAvailableByBusinessLogic: function(this : RegisterTestAccountApiRoot) {
				return ((this.howDoYouKnow === RegisterTestAccountApiHowDoYouKnow.OTHER));
			},
			validations: function(this : RegisterTestAccountApiRoot) {
				return [
					() => {
		if(((this.howDoYouKnow === RegisterTestAccountApiHowDoYouKnow.OTHER)))
		{
			return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);
		}
		else
		{
			return this.api!.validators.ensureNull(PApiPrimitiveTypes.string, undefined);
		}
						return null;
					},
				];
			},
			asyncValidations: function(this : RegisterTestAccountApiRoot) {
				return [
				];
			}
			,
			rawDataIndex: 11,
		});

	/**
     *  The gender of the user.
	 *
	 * @type {RegisterTestAccountApiGender}
     */
	get ownerGender() : RegisterTestAccountApiGender {
		this.getterDebugValidations(this.attributeInfoOwnerGender, false);
		return this.data[1];
	}

	set ownerGender(v : RegisterTestAccountApiGender) {
        this.setterImpl(1, v, 'ownerGender', false, null, null);
	}

	/**
     *  Owner's first-name
	 *
	 * @type {string}
     */
	get ownerFirstName() : string {
		this.getterDebugValidations(this.attributeInfoOwnerFirstName, false);
		return this.data[2];
	}

	set ownerFirstName(v : string) {
        this.setterImpl(2, v, 'ownerFirstName', false, null, null);
	}

	/**
     *  Owner's last-name
	 *
	 * @type {string}
     */
	get ownerLastName() : string {
		this.getterDebugValidations(this.attributeInfoOwnerLastName, false);
		return this.data[3];
	}

	set ownerLastName(v : string) {
        this.setterImpl(3, v, 'ownerLastName', false, null, null);
	}

	/**
     *  Owner's email address
	 *
	 * @type {Email}
     */
	get ownerEmail() : Email {
		this.getterDebugValidations(this.attributeInfoOwnerEmail, false);
		return this.data[4];
	}

	set ownerEmail(v : Email) {
        this.setterImpl(4, v, 'ownerEmail', false, null, null);
	}

	/**
     *  Owner's email phone number
	 *
	 * @type {Tel}
     */
	get ownerPhone() : Tel {
		this.getterDebugValidations(this.attributeInfoOwnerPhone, false);
		return this.data[5];
	}

	set ownerPhone(v : Tel) {
        this.setterImpl(5, v, 'ownerPhone', false, null, null);
	}

	/**
     *  Owner's password
	 *
	 * @type {Password}
     */
	get ownerPassword() : Password {
		this.getterDebugValidations(this.attributeInfoOwnerPassword, false);
		return this.data[6];
	}

	set ownerPassword(v : Password) {
        this.setterImpl(6, v, 'ownerPassword', false, null, null);
	}

	/**
     *  Company name
	 *
	 * @type {string}
     */
	get company() : string {
		this.getterDebugValidations(this.attributeInfoCompany, false);
		return this.data[7];
	}

	set company(v : string) {
        this.setterImpl(7, v, 'company', false, null, null);
	}

	/**
     *  Companies postal-code.
	 *
	 * @type {PostalCode}
     */
	get postalCode() : PostalCode {
		this.getterDebugValidations(this.attributeInfoPostalCode, false);
		return this.data[8];
	}

	set postalCode(v : PostalCode) {
        this.setterImpl(8, v, 'postalCode', false, null, null);
	}

	/**
     *  Account country
	 *
	 * @type {RegisterTestAccountApiCountry}
     */
	get country() : RegisterTestAccountApiCountry {
		this.getterDebugValidations(this.attributeInfoCountry, false);
		return this.data[9];
	}

	set country(v : RegisterTestAccountApiCountry) {
        this.setterImpl(9, v, 'country', false, null, null);
	}

	/**
     * 
	 *
	 * @type {RegisterTestAccountApiHowDoYouKnow}
     */
	get howDoYouKnow() : RegisterTestAccountApiHowDoYouKnow {
		this.getterDebugValidations(this.attributeInfoHowDoYouKnow, false);
		return this.data[10];
	}

	set howDoYouKnow(v : RegisterTestAccountApiHowDoYouKnow) {
        this.setterImpl(10, v, 'howDoYouKnow', false, null, null);
	}

	/**
     *  only set when HOW_DO_YOU_KNOW==OTHER
	 *
	 * @type {string}
     */
	get howDoYouKnowText() : string | null {
		this.getterDebugValidations(this.attributeInfoHowDoYouKnowText, false);
		// This attribute has a show() condition (When we reach this line then show() is true). If no value is available ensure that it is initialized by its default value.
		if(this.data[11] === undefined) {
			this.data[11] = this.attributeInfoHowDoYouKnowText.defaultValue;
			
		}
		return this.data[11];
	}

	set howDoYouKnowText(v : string | null) {
        this.setterImpl(11, v, 'howDoYouKnowText', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('ROOT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '1';
	}

	static async loadDetailed(	api : RegisterTestAccountApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '1', { success: success, error: error, searchParams: searchParams});
	}
}

export enum RegisterTestAccountApiGender {
	MALE = 1,
	FEMALE = 2,
	DIVERSE = 3,
}
export enum RegisterTestAccountApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
export enum RegisterTestAccountApiHowDoYouKnow {
	RECOMMENDATION = 1,
	INTERNET_SEARCH = 2,
	ASKED_BY_PLANO = 3,
	SOCIAL_MEDIA_FACEBOOK = 4,
	FAIR = 5,
	OTHER = 6,
}


