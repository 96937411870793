import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SLIDE_ON_NGIF_TRIGGER } from '@plano/animations';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { CourseFilterService } from '@plano/client/scheduling/course-filter.service';
import { SchedulingFilterService } from '@plano/client/scheduling/scheduling-filter.service';
import { BirthdayService } from '@plano/client/scheduling/shared/api/birthday.service';
import { SchedulingApiBirthdays } from '@plano/client/scheduling/shared/api/scheduling-api-birthday.service';
import { CalenderAllDayItemLayoutService } from '@plano/client/scheduling/shared/p-scheduling-calendar/calender-all-day-item-layout.service';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { PAbstractControlComponentBaseDirective } from '@plano/client/shared/p-attribute-info/attribute-info-component-base';
import { PMomentService } from '@plano/client/shared/p-moment.service';
import { PShiftPickerService } from '@plano/client/shared/p-shift-picker/p-shift-picker.service';
import { PossibleShiftPickerValueItemType } from '@plano/client/shared/p-shift-picker/p-shift-picker/p-shift-picker.component';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { MeService, SchedulingApiAbsences, SchedulingApiHolidays, SchedulingApiService, SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOffers, SchedulingApiShifts, SchedulingApiWarnings } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';
import { Config } from '@plano/shared/core/config';
import { LogService } from '@plano/shared/core/log.service';
import { assumeDefinedToGetStrictNullChecksRunning, assumeNonNull } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

type ValueType = Id | null;

@Component({
	selector: 'p-offer-picker[offers]',
	templateUrl: './p-offer-picker.component.html',
	styleUrls: ['./p-offer-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		SchedulingFilterService,
		CourseFilterService,
		FilterService,
		CalenderAllDayItemLayoutService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => POfferPickerComponent),
			multi: true,
		},
	],
	animations: [SLIDE_ON_NGIF_TRIGGER],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class POfferPickerComponent extends PAbstractControlComponentBaseDirective implements ControlValueAccessor, OnInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public offers ! : SchedulingApiShiftExchangeCommunicationSwapOffers;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftTemplate : TemplateRef<unknown> | null = null;

	/**
	 *	The shift exchange that is detailed loaded
	 */
	@Input() public loadDetailedItem ! : SchedulingApiShiftExchange;

	constructor(
		protected override console : LogService,
		protected override changeDetectorRef : ChangeDetectorRef,
		protected override pFormsService : PFormsService,

		public api : SchedulingApiService,
		private meService : MeService,
		private pMoment : PMomentService,
		private birthdayService : BirthdayService,
		private schedulingFilterService : SchedulingFilterService,
		private pShiftPickerService : PShiftPickerService,

	) {
		super(false, changeDetectorRef, pFormsService, console);
		this.initValues();
	}

	public readonly CONFIG = Config;

	public showList : boolean = true;

	public selectedDate ! : number;
	public calendarMode : CalendarModes = CalendarModes.MONTH;

	public enums = enumsObject;
	public CalendarModes = CalendarModes;
	public SectionWhitespace = SectionWhitespace;

	public isLoading = true;

	public override ngOnInit() : 'TypeToEnsureLifecycleHooksHaveBeenCalled' {

		this.schedulingFilterService.cookiePrefix = 'POfferPicker';
		this.schedulingFilterService.readCookies();

		const loadDetailedShiftExchange = async () : Promise<void> => {

			this.pShiftPickerService.updateQueryParams();
			await this.loadDetailedItem.loadDetailed({searchParams: this.pShiftPickerService.queryParams});
			this.isLoading = false;

		};
		void loadDetailedShiftExchange();

		return super.ngOnInit();
	}

	/**
	 * Get the absences that should be available to the calendar component
	 */
	public get absences() : SchedulingApiAbsences {
		if (this.schedulingFilterService.hideAllAbsences) return new SchedulingApiAbsences(null, null);
		if (!this.api.isLoaded()) return new SchedulingApiAbsences(null, null);

		// TODO: PLANO-156519
		if (!this.api.data.attributeInfoAbsences.isAvailable) return new SchedulingApiAbsences(null, null);
		return this.api.data.absences;
	}

	/**
	 * Get the holidays that should be available to the calendar component
	 */
	public get holidays() : SchedulingApiHolidays {
		if (!this.api.isLoaded()) return new SchedulingApiHolidays(null, null);

		// TODO: PLANO-156519
		if (!this.api.data.attributeInfoHolidays.isAvailable) return new SchedulingApiHolidays(null, null);
		return this.api.data.holidays;
	}

	/**
	 * Get the birthdays that should be available to the calendar component
	 */
	public get birthdays() : SchedulingApiBirthdays {
		if (!this.api.isLoaded()) return new SchedulingApiBirthdays(null, null, null, false);
		return this.birthdayService.birthdays;
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		this.selectedDate = +this.pMoment.m().startOf('day');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onSelectOffer(offer : SchedulingApiShiftExchangeCommunicationSwapOffer | PossibleShiftPickerValueItemType | null) : void {
		// We know that it only can be an SchedulingApiShiftExchangeCommunicationSwapOffer or null here
		const OFFER = offer as SchedulingApiShiftExchangeCommunicationSwapOffer | null;

		if (!OFFER || this.value?.equals(OFFER.id)) {
			this.value = null;
			this.highlightOffer(null);
			return;
		}
		this.value = OFFER.id;
		this.highlightOffer(OFFER);
	}

	private highlightOffer(offer : SchedulingApiShiftExchangeCommunicationSwapOffer | null) : void {
		this.api.deselectAllSelections();
		if (offer === null) return;
		for (const shiftRef of offer.shiftRefs.iterable()) {
			const shift = this.api.data.shifts.get(shiftRef.id);
			assumeNonNull(shift, 'shift', '[PLANO-FE-4S7]');
			shift.selected = true;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setSelectedDateAndLoadData(value : number) : void {
		this.selectedDate = value;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setCalendarModeAndLoadData(value : CalendarModes) : void {
		this.calendarMode = value;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftsForOfferPicker() : SchedulingApiShifts {
		if (!this.offers.length) return new SchedulingApiShifts(null, null);
		const listOfShiftIdsInShiftsRef = this.loadDetailedItem.shiftRefs.map(shiftRef => shiftRef.id);
		return this.api.data.shifts.filterBy((item) => {
			if (this.offers.containsShiftId(item.id)) return true;
			if (!listOfShiftIdsInShiftsRef.some(shiftRefId => item.id.equals(shiftRefId))) return false;
			if (item.assignableMembers.contains(this.meService.data.id)) return true;
			return false;
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get warnings() : SchedulingApiWarnings {
		assumeDefinedToGetStrictNullChecksRunning(this.api.data.warnings, 'api.data.warnings');
		return this.api.data.warnings;
	}

	private _value : ValueType | null = null;
	public onChange : (value : ValueType | null) => void = () => {};

	/** onTouched */
	public onTouched = () : void => {};

	/** the value of this control */
	public get value() : ValueType | null { return this._value; }
	public set value(value : ValueType | null) {
		if (value === this._value) return;

		this._value = value;
		this.onChange(value);
	}

	/**
	 * Write a new value to the element.
	 * This happens when the model that is bound to this component changes.
	 * @see ControlValueAccessor#writeValue
	 * @param value The new value for the element
	 */
	public writeValue(value : ValueType) : void {
		if (this._value === value) return;
		this._value = value;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * @see ControlValueAccessor#registerOnChange
	 *
	 * Note that registerOnChange() only gets called if a formControl is bound.
	 * @param fn Accepts a callback function which you can call when changes happen so that you can notify the outside world that
	 * the data model has changed.
	 * Note that you call it with the changed data model value.
	 */
	public registerOnChange(fn : (value : ValueType | null) => void) : ReturnType<ControlValueAccessor['registerOnChange']> { this.onChange = fn; }

	/**
	 * @see ControlValueAccessor#registerOnTouched
	 * Set the function to be called when the control receives a touch event.
	 */
	public registerOnTouched(fn : () => void) : void { this.onTouched = fn; }

	/** @see ControlValueAccessor#registerOnChange */
	public setDisabledState(isDisabled : boolean) : void {
		if (this.disabled === isDisabled) return;

		// Set internal attribute which gets used in the template.
		this.disabled = isDisabled;

		// Refresh the formControl. #two-way-binding
		if (this.control && this.control.disabled !== this.disabled) {
			this.disabled ? this.control.disable() : this.control.enable();
		}
	}
}
