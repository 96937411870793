import { AfterViewChecked, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Config } from '@plano/shared/core/config';

/**
 * Add the class.ignore-gutter to every child of div with the .content class,
 * which has a width of 100% and a background color
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '.content',
	standalone: true,
})
export class IgnoreGutterDirective implements AfterViewChecked, OnDestroy {

	constructor(private el : ElementRef<HTMLElement>) {

	}

	public Config = Config;

	private addNecessaryGutterClasses(element : Element, comparableWidth : number) : void {
		if (Config.IS_MOBILE) return;
		const widthOfScrollbar = 12;
    	for (const elementChild of element.children) {
    		// is the element the size of the container minus the size of the scrollbar?
    		if (elementChild.getBoundingClientRect().width < comparableWidth - widthOfScrollbar) {
    			continue;
    		} else if ([...elementChild.classList].some(classOfChild => classOfChild.includes('bg-'))) {
    			elementChild.classList.add('ignore-gutter');
    		} else {
    			this.addNecessaryGutterClasses(elementChild, comparableWidth);
    		}
    	}
	}

	private mutationObserver : MutationObserver | null = null;

	public ngAfterViewChecked() : void {
		if (Config.IS_MOBILE) return;
		if (!this.mutationObserver) {
			this.mutationObserver = new MutationObserver(_mutations => {
				this.addNecessaryGutterClasses(this.el.nativeElement, this.el.nativeElement.getBoundingClientRect().width);

			});
			const contentInner = this.el.nativeElement.querySelector('.content-inner');
			if (contentInner)
				this.mutationObserver.observe(contentInner, {childList: true});
		}
	}

	public ngOnDestroy() : void {
		this.mutationObserver?.disconnect();
	}
}