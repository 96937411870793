import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { ErrorArray } from '@plano/client/shared/p-shift-module/shift-member-exchange.service';
import { PossibleShiftPickerValueItemType } from '@plano/client/shared/p-shift-picker/p-shift-picker/p-shift-picker.component';
import { SchedulingApiService, SchedulingApiShiftExchangeCommunicationSwapOffer, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef, SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs, SchedulingApiShiftExchangeCommunicationSwapOffers, SchedulingApiShiftExchangeShiftRef, SchedulingApiShiftExchangeShiftRefs, SchedulingApiShiftExchangeSwappedShiftRefs, SchedulingApiShifts } from '@plano/shared/api';
import { Data } from '@plano/shared/core/data/data';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export type PossibleShiftPickerValueType = (
	SchedulingApiShiftExchangeCommunicationSwapOffers |
	SchedulingApiShiftExchangeShiftRefs |
	SchedulingApiShiftExchangeSwappedShiftRefs

	// | ShiftId
);

@Component({
	selector: 'p-shift-picker-picked-offers[shiftsToBeAdded]',
	templateUrl: './shift-picker-picked-offers.component.html',
	styleUrls: ['./shift-picker-picked-offers.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftPickerPickedOffersComponent implements AfterContentInit {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideAddToOffersBtn : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public offerTemplate : TemplateRef<unknown> | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public showBoundShiftOfferSetBtn : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public alerts : ErrorArray | null = null;

	// TODO: Get rid of `| any`
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
	@Input() public offers : SchedulingApiShiftExchangeCommunicationSwapOffers | SchedulingApiShiftExchangeShiftRefs | any;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftsToBeAdded ! : SchedulingApiShifts;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public addToOffer = new EventEmitter<SchedulingApiShiftExchangeCommunicationSwapOfferShiftRefs | SchedulingApiShiftExchangeShiftRefs>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public addToOffers = new EventEmitter<undefined>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public addSelectedShiftsAsPacket = new EventEmitter<undefined>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemoveOffer =
		new EventEmitter<PossibleShiftPickerValueItemType>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onRemoveShiftRefFromOffer = new EventEmitter<{
		shiftRef : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef | SchedulingApiShiftExchangeShiftRef,
		offer : SchedulingApiShiftExchangeCommunicationSwapOffer,
	}>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public addToOffersBtnLabel : string | null = null;

	constructor(
		private pShiftExchangeService : PShiftExchangeService,
		private localize : LocalizePipe,
		private api : SchedulingApiService,
	) {
	}

	public enums = enumsObject;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public offerSelected(
		// TODO: Get rid of `| any`
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents */
		offer : PossibleShiftPickerValueType | any,
	) : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shiftsToBeAdded, 'shiftsToBeAdded');
		return this.pShiftExchangeService.offerSelected(offer, this.shiftsToBeAdded);
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public offerAffected(
		// TODO: Get rid of `| any`
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents */
		offer : PossibleShiftPickerValueType | any,
	) : boolean {
		assumeDefinedToGetStrictNullChecksRunning(this.shiftsToBeAdded, 'shiftsToBeAdded');
		return this.pShiftExchangeService.offerAffected(offer, this.shiftsToBeAdded);
	}

	public ngAfterContentInit() : void {
		this.initValues();
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		if (this.addToOffersBtnLabel === null) {
			this.addToOffersBtnLabel = this.localize.transform('Der Auswahl hinzufügen');
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public addToOfferBtnDisabled(offer : PossibleShiftPickerValueItemType) : boolean | undefined {
		if (offer instanceof SchedulingApiShiftExchangeShiftRefs) {
			if (!this.shiftsToBeAdded.length) return true;
			return false;
		}
		if (!(offer instanceof SchedulingApiShiftExchangeCommunicationSwapOffer)) return undefined;
		if (!this.shiftsToBeAdded.length) return true;
		const shiftsCanBeAddedCounter = this.shiftsToBeAdded.filterBy((item) => {

			/** Shifts can be added if they are not already contained */
			return !offer.shiftRefs.contains(item.id);
		}).length;
		if (!shiftsCanBeAddedCounter) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public removeFromOffer(
		shiftRef : SchedulingApiShiftExchangeCommunicationSwapOfferShiftRef | SchedulingApiShiftExchangeShiftRef,

		// TODO: Get rid of `| any`
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents */
		offer : SchedulingApiShiftExchangeCommunicationSwapOffer | any,
	) : void {
		this.onRemoveShiftRefFromOffer.emit({
			shiftRef: shiftRef,
			offer: offer,
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get isShiftExchangeShiftRefs() : boolean {
		return !!this.offers && this.offers instanceof SchedulingApiShiftExchangeShiftRefs;
	}

	private _shiftRefs = new Data<SchedulingApiShiftExchangeShiftRefs | undefined>(this.api);

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftRefs() : SchedulingApiShiftExchangeShiftRefs | undefined {
		return this._shiftRefs.get(() => {
			if (!this.offers) return undefined;
			if (!(this.offers instanceof SchedulingApiShiftExchangeShiftRefs)) return undefined;
			return this.offers.sortedBy([
				item => item.start,
				item => item.end,
			]);
		});
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get boundShiftOfferSetBtnDisabled() : boolean {
		return this.shiftsToBeAdded.length <= 1;
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get addToOffersBtnDisabled() : boolean {
		return !this.shiftsToBeAdded.length;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get addToOffersBtnPopover() : string | null {
		if (!this.addToOffersBtnDisabled) return null;
		return this.localize.transform('Wähle im Kalender mindestens 1 Schicht, die du hinzufügen möchtest.');
	}
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get boundShiftOfferSetBtnPopover() : string | null {
		if (!this.boundShiftOfferSetBtnDisabled) return null;
		return this.localize.transform('Fügst du 2 oder mehr Schichten gebündelt hinzu, müssen sie komplett von einer Person übernommen werden.');
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get shiftExchangeShiftRefs() : SchedulingApiShiftExchangeShiftRefs | undefined {
		if (this.offers instanceof SchedulingApiShiftExchangeCommunicationSwapOffers) return undefined;
		return this.offers;
	}

}
