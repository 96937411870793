import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { CalendarModes } from '@plano/client/scheduling/calendar-modes';
import { PBtnThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { HighlightService } from '@plano/client/shared/highlight.service';
import { PMoment, PMomentService } from '@plano/client/shared/p-moment.service';
import { PSimpleChanges, SchedulingApiAssignmentProcessState, SchedulingApiAssignmentProcesses, SchedulingApiService } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PFormControlComponentInterface } from '@plano/shared/p-forms/p-form-control.interface';

@Component({
	selector: 'p-calendar-nav[calendarMode][selectedDate]',
	templateUrl: './calendar-nav.component.html',
	styleUrls: ['./calendar-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class CalendarNavComponent implements OnChanges {
	@HostBinding('class.d-flex')
	@HostBinding('class.justify-content-between') protected _alwaysTrue = true;

	@Input() public disabled : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public hideLabels : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public earlyBirdMode : boolean = false;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public wishPickerMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public selectedDate ! : number;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public selectedDateChange = new EventEmitter<number>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public onNavToToday = new EventEmitter<number>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public calendarMode : CalendarModes | null = null;

	/**
	 * Visual size of this component.
	 * Can be useful if you have few space in a button-bar or want to have large buttons on mobile.
	 */
	@Input() public size ?: PFormControlComponentInterface['size'] = null;

	constructor(
		public api : SchedulingApiService,
		private pMoment : PMomentService,
		private highlightService : HighlightService,
	) {
		this.now = +this.pMoment.m();
	}

	public enums = enumsObject;
	public PBtnThemeEnum = PBtnThemeEnum;

	private now ! : number;
	public config : typeof Config = Config;

	/**
	 * Avoid that the calendar navigation rechecks everytime if the selected time is today,
	 * we only need to this when we change the calendar mode or the selected date.
	 */
	private recheckSameDay = true;

	public ngOnChanges(changes : PSimpleChanges<CalendarNavComponent>) : void {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Remove this before you work here.
		if (changes.selectedDate || changes.calendarMode) {
			this.recheckSameDay = true;
		}
	}

	/**
	 * @param input The timestamp to clean up
	 * @returns The timestamp for the start of the selected calendar mode or, if the timestamp is in the same range of
	 * the current calendarMode, it will return start of today.
	 */
	private cleanUpUrlTimestamp(input : PMoment.Moment) : number {
		if (!input.isSame(this.now, this.calendarMode)) return +input.startOf(this.calendarMode);
		return +this.pMoment.m(this.now).startOf('day');
	}

	/**
	 * Navigate to previous day|week|month…
	 */
	public navPrev() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.calendarMode, 'this.calendarMode');
		const target = this.pMoment.m(this.selectedDate).subtract(1, this.calendarMode);
		this.selectedDateChange.emit(this.cleanUpUrlTimestamp(target));
		this.highlightService.clear();
	}

	/**
	 * Navigate to current day|week|month…
	 */
	public navToToday() : void {
		const day = this.pMoment.m();
		if (!this.pMoment.m(this.selectedDate).isSame(day, 'day')) {
			this.api.deselectAllSelections();
			this.selectedDateChange.emit(+day.startOf('day'));
		}
		this.onNavToToday.emit();
		this.highlightService.clear();
	}

	/**
	 * Navigate to next day|week|month…
	 */
	public navNext() : void {
		assumeDefinedToGetStrictNullChecksRunning(this.calendarMode, 'this.calendarMode');
		const target = this.pMoment.m(this.selectedDate).add(1, this.calendarMode);
		this.selectedDateChange.emit(this.cleanUpUrlTimestamp(target));
		this.highlightService.clear();
	}

	/**
	 * Navigate to next day|week|month…
	 * @param input The timestamp to navigate to
	 */
	public navTo(input : number) : void {
		const target = this.pMoment.m(input);
		this.selectedDateChange.emit(this.cleanUpUrlTimestamp(target));
		this.highlightService.clear();
	}

	private _viewDateIsToday : boolean | null = null;

	/**
	 * Check if viewDate is today
	 * Helpful for highlighting »today« buttons
	 */
	public get viewDateIsToday() : boolean {
		if (this._viewDateIsToday === null || this.recheckSameDay) {
			this._viewDateIsToday = this.pMoment.m(this.now).isSame(this.selectedDate, this.calendarMode);
			this.recheckSameDay = false;
		}
		return this._viewDateIsToday;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get todoLeftView() : number | null {
		return this.relevantTodoProcesses?.todoShiftsCountLeftView ?? null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get todoCurrentView() : number | null {
		return this.relevantTodoProcesses?.todoShiftsCountCurrentView ?? null;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get todoRightView() : number | null {
		return this.relevantTodoProcesses?.todoShiftsCountRightView ?? null;
	}

	private get relevantTodoProcesses() : SchedulingApiAssignmentProcesses | null {
		// get relevant process state
		let relevantState : SchedulingApiAssignmentProcessState | null = null;

		if (this.earlyBirdMode) {
			relevantState = SchedulingApiAssignmentProcessState.EARLY_BIRD_SCHEDULING;
		}

		if (this.wishPickerMode) {
			relevantState = SchedulingApiAssignmentProcessState.ASKING_MEMBER_PREFERENCES;
		}

		if (!relevantState) return null;

		if (!this.api.data.attributeInfoAssignmentProcesses.isAvailable) return null;

		// get relevant processes
		const assignmentProcesses = this.api.data.assignmentProcesses.filterBy((process) => {
			return process.state === relevantState;
		});
		if (!assignmentProcesses.length) return null;

		return assignmentProcesses;
	}

	public isHighlightedFn = (input : number) : boolean => {
		return this.pMoment.m(input).isSame(this.selectedDate, this.calendarMode);
	};
}
