import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MemberService } from '@plano/client/shared/p-member/p-member.service';
import { PossibleActionIcons } from '@plano/client/shared/p-shift-exchange/p-action-data';
import { PShiftExchangeService } from '@plano/client/shared/p-shift-exchange/shift-exchange.service';
import { ActionData, ApiListWrapper, PShiftExchangeConceptService, RightsService, SchedulingApiMember, SchedulingApiService, SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunication, SchedulingApiShiftExchangeCommunicationAction, SchedulingApiShiftExchangeCommunicationRequesterRole, SchedulingApiShiftExchangeCommunicationState, SchedulingApiShiftExchangeCommunications } from '@plano/shared/api';
import { Config } from '@plano/shared/core/config';
import { ModalContentOptions } from '@plano/shared/core/p-modal/modal-default-template/modal-default-template.component';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { ModalServiceOptions } from '@plano/shared/core/p-modal/modal.service.options';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { PShiftExchangeCommunicationModalComponent } from './p-shift-exchange-communication-modal/p-shift-exchange-communication-modal.component';

@Component({
	selector: 'p-shift-exchange-communication[shiftExchange]',
	templateUrl: './p-shift-exchange-communication.component.html',
	styleUrls: ['./p-shift-exchange-communication.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeCommunicationComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftExchange ! : SchedulingApiShiftExchange;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private input : SchedulingApiShiftExchangeCommunication | SchedulingApiShiftExchangeCommunications | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onPerformActionModalSuccess : EventEmitter<ActionData> = new EventEmitter<ActionData>();
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private onPerformActionModalDismiss : EventEmitter<ActionData> = new EventEmitter<ActionData>();

	@HostBinding('class.muted-item') private get _isMuted() : boolean {
		if (!this.shiftExchange.isClosed) return false;
		if (this.availableActionDataArray.length) return false;

		return true;
	}

	constructor(
		public api : SchedulingApiService,
		private pShiftExchangeConceptService : PShiftExchangeConceptService,
		private modalService : ModalService,
		private memberService : MemberService,
		private rightsService : RightsService,
		private pShiftExchangeService : PShiftExchangeService,
		private localize : LocalizePipe,
	) {
	}

	public readonly CONFIG = Config;

	public enums = enumsObject;

	public showNames : boolean = false;
	public showTextarea : boolean = false;

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get communications() : ApiListWrapper<SchedulingApiShiftExchangeCommunication> | undefined {
		if (this.input && this.input instanceof SchedulingApiShiftExchangeCommunications) {
			return this.input.sortedBy([
				item => item.communicationPartner!.firstName,
				item => item.communicationPartner!.lastName,
			]);
		}
		return undefined;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get communication() : SchedulingApiShiftExchangeCommunication {
		if (this.input && this.input instanceof SchedulingApiShiftExchangeCommunication) return this.input;
		return this.communications!.get(0)!;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get showLastActionTime() : boolean {
		switch (this.communication.communicationState) {
			case SchedulingApiShiftExchangeCommunicationState.CP_NOT_RESPONDED :
			case SchedulingApiShiftExchangeCommunicationState.ILLNESS_NEEDS_CONFIRMATION :
				return false;
			default :
				return !!this.communication.lastActionTime;
		}
	}

	private get isMyCommunication() : boolean {
		return !!this.rightsService.isMe(this.communication.communicationPartnerId);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get mePerformedLastAction() : boolean {
		const lastActionData = this.pShiftExchangeConceptService.getActionData(this.communication.lastAction);
		return lastActionData.requesterRole === this.communication.requesterRole;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get indisposedMemberPerformedLastAction() : boolean {
		const actionData = this.pShiftExchangeConceptService.getActionData(this.communication.lastAction);
		return actionData.requesterRole === SchedulingApiShiftExchangeCommunicationRequesterRole.IM;
	}

	private get iAmTheCreator() : boolean {
		return !!this.rightsService.isMe(this.shiftExchange.indisposedMemberId);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get availableActionDataArray() : ActionData[] {
		if (!this.iAmTheCreator && !this.isMyCommunication) return [];
		const availableActions = this.pShiftExchangeConceptService.getAvailableActions(
			this.shiftExchange,
			this.communication,
		);

		const sortingPriorities : PossibleActionIcons[] = [
			enumsObject.PlanoFaIconPool.EDIT,
			enumsObject.PlanoFaIconPool.EXCHANGE_SHIFT,
			enumsObject.PlanoFaIconPool.EXCHANGE_SHIFT_OFFER,
			enumsObject.PlanoFaIconPool.SUCCESS,
			enumsObject.PlanoFaIconPool.CANCELED,
		];
		return availableActions.sort((a, b) => {
			const iconA = this.availableActionIcon(a.action);
			const iconB = this.availableActionIcon(b.action);
			const prioA = sortingPriorities.indexOf(iconA!);
			const prioB = sortingPriorities.indexOf(iconB!);
			return prioA - prioB;
		});
	}

	/**
	 * Get the action text for a given available action
	 * Use this as label for the action button
	 */
	public availableActionText(
		availableAction : SchedulingApiShiftExchangeCommunicationAction,
		shiftExchange : SchedulingApiShiftExchange,
	) : ReturnType<PShiftExchangeConceptService['getActionText']> {
		return this.pShiftExchangeConceptService.getActionText(availableAction, shiftExchange);
	}

	/**
	 * Get the icon for a given available action
	 * Use this as icon for the action button
	 */
	public availableActionIcon(availableAction : SchedulingApiShiftExchangeCommunicationAction) : ReturnType<PShiftExchangeConceptService['getActionIcon']> {
		return this.pShiftExchangeConceptService.getActionIcon(availableAction);
	}

	/**
	 * Get the color for a given available action
	 * Use this as color for the action button icon
	 */
	public availableActionIconStyle(availableAction : SchedulingApiShiftExchangeCommunicationAction) : ReturnType<PShiftExchangeConceptService['getActionIconStyle']> {
		return this.pShiftExchangeConceptService.getActionIconStyle(availableAction);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get needsReview() : boolean {
		if (this.needsReviewLabel) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get needsReviewLabel() : string | undefined {
		let memberThatNeedsToReply : SchedulingApiMember | null = null;
		if (this.pShiftExchangeConceptService.needsReviewByIM(
			this.shiftExchange.requesterRelationship,
			this.communication.communicationState,
		)) memberThatNeedsToReply = this.shiftExchange.indisposedMember;
		if (this.pShiftExchangeConceptService.needsReviewByCP(
			this.shiftExchange.requesterRelationship,
			this.communication.communicationState,
		)) memberThatNeedsToReply = this.communication.communicationPartner;
		if (!memberThatNeedsToReply) return undefined;
		let text : string = '';
		if (this.rightsService.isMe(memberThatNeedsToReply)) {
			text += 'Deine';
		} else {
			text += this.memberService.makeNameGenitive(memberThatNeedsToReply.firstName);
		}
		text += ' Bestätigung nötig';
		return text;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get lastActionStateText() : ReturnType<PShiftExchangeConceptService['getActionStateText']> {
		return this.pShiftExchangeConceptService.getActionStateText(
			this.shiftExchange,
			this.communication,
			this.communication.lastAction,
		);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get stateTheme() : ReturnType<PShiftExchangeConceptService['getCommunicationStateStyle']> {
		return this.pShiftExchangeConceptService.getCommunicationStateStyle(
			this.communication.communicationState,
			this.communication.lastAction,
		);
	}

	/**
	 * In some exceptions we don’t need a warning-modal.
	 */
	private noPrefMismatchWarningNeeded(clickedActionData : ActionData) : boolean {
		switch (clickedActionData.prevCommunicationState) {
			case SchedulingApiShiftExchangeCommunicationState.IM_CHANGED_MIND_WANTS_SWAP : // IM declined CP's offer but changed mind later?
			case clickedActionData.nextCommunicationState : // CP edits existing offer?
				return true;
			default :
				return false;
		}
	}

	private get iMWantsSwap() : boolean {
		if (this.communication.iMChangedMindWantsSwap) return true;
		if (this.communication.iMChangedMindWantsTake) return false;
		return this.shiftExchange.indisposedMemberPrefersSwapping;
	}
	private iMWantsSwapCPWantsTake(clickedActionData : ActionData) : boolean {
		if (!this.iMWantsSwap) return false;

		const STATE_ENUM = SchedulingApiShiftExchangeCommunicationState;
		if (clickedActionData.nextCommunicationState === STATE_ENUM.CP_WANTS_TAKE) return true;
		if (clickedActionData.nextCommunicationState === STATE_ENUM.TAKE_SUCCESSFUL) return true;
		return false;
	}
	private get iMWantsTake() : boolean {
		if (this.communication.iMChangedMindWantsTake) return true;
		if (this.communication.iMChangedMindWantsSwap) return false;
		return !this.shiftExchange.indisposedMemberPrefersSwapping;
	}
	private iMWantsTakeCPWantsSwap(clickedActionData : ActionData) : boolean {
		if (!this.iMWantsTake) return false;

		const STATE_ENUM = SchedulingApiShiftExchangeCommunicationState;
		if (clickedActionData.nextCommunicationState === STATE_ENUM.CP_WANTS_SWAP) return true;
		if (clickedActionData.nextCommunicationState === STATE_ENUM.SWAP_SUCCESSFUL) return true;
		return false;
	}

	private getPrefMismatchModalContentOptions(clickedActionData : ActionData) : ModalContentOptions | undefined {
		if (!this.rightsService.isMe(this.communication.communicationPartnerId)) return undefined;
		const closeBtnLabel = this.localize.transform('Ok, weitermachen');
		const dismissBtnLabel = this.localize.transform('Abbrechen');

		if (this.noPrefMismatchWarningNeeded(clickedActionData)) return;

		if (this.iMWantsTakeCPWantsSwap(clickedActionData)) {
			const shift = this.shiftExchange.shiftRefs.length > 1 ? this.localize.transform('Schichten') : this.localize.transform('Schicht');
			const description = this.localize.transform({
				sourceString: '${firstName} möchte die ${shift} nur abgeben, ohne im Gegenzug was zu übernehmen. Willst du ${firstName} trotzdem einen Tausch anbieten?',
				params: {
					firstName: this.shiftExchange.indisposedMember!.firstName,
					shift: shift,
				},
			});
			return {
				modalTitle: this.localize.transform('Unterschiedliche Präferenzen'),
				description: description,
				dismissBtnLabel: dismissBtnLabel,
				closeBtnLabel: closeBtnLabel,
			};
		}

		if (this.iMWantsSwapCPWantsTake(clickedActionData)) {
			const shift = this.shiftExchange.shiftRefs.length > 1 ? this.localize.transform('Schichten') : this.localize.transform('Schicht');
			const description = this.localize.transform({
				sourceString: '${firstName} möchte die ${shift} tauschen, anstatt sie nur abzugeben. Wenn du nicht tauschen möchtest, wird ${firstName} deinem Vorschlag erst zustimmen müssen, damit euer Deal zustande kommt.',
				params: {
					firstName: this.shiftExchange.indisposedMember!.firstName,
					shift: shift,
				},
			});
			return {
				modalTitle: this.localize.transform('Unterschiedliche Präferenzen'),
				description: description,
				dismissBtnLabel: dismissBtnLabel,
				closeBtnLabel: closeBtnLabel,
			};
		}
	}

	private get giveUserAHintAboutUnusedSelectedShifts() : boolean {
		if (!this.api.data.shifts.findBy(item => item.selected)) return false;
		if (this.pShiftExchangeService.iAmTheResponsiblePersonForThisIllness(this.shiftExchange)) {
			return false;
		}
		return true;
	}

	public beforeModalClose : (success : () => void) => void = (success) => {
		if (!this.giveUserAHintAboutUnusedSelectedShifts) {
			success();
			return;
		}
		void this.modalService.confirm({
			modalTitle: this.localize.transform('Sicher?'),
			description: this.localize.transform('Du hast Schichten im Kalender selektiert, aber sie nicht der Tauschbörse hinzugefügt.'),
			closeBtnLabel: this.localize.transform('Trotzdem schließen'),
			dismissBtnLabel: this.localize.transform('Zurück'),
		}, {
			theme: enumsObject.PThemeEnum.WARNING,
			size: enumsObject.BootstrapSize.MD,
		}).result.then(promiseValue => {
			if (promiseValue.modalResult === 'dismiss') return;
			this.api.data.shifts.selectedItems.setSelected(false);
			success();
		});
	};

	private openPerformActionModal(clickedActionData : ActionData) : void {
		this.api.createDataCopy();

		this.communication.performAction = clickedActionData.action;

		const HAS_CALENDAR : boolean = PShiftExchangeCommunicationModalComponent.hasCalendar(clickedActionData);
		const MODAL_SIZE : ModalServiceOptions['size'] = HAS_CALENDAR ? 'fullscreen' : enumsObject.BootstrapSize.LG;

		const successCallback = (
			action : SchedulingApiShiftExchangeCommunicationAction | null = null,
		) : void => {
			let response : ActionData;
			if (action) {
				response = this.pShiftExchangeConceptService.getActionData(action)!;
			} else {
				response = clickedActionData;
			}
			this.onPerformActionModalSuccess.emit(response);
		};

		const pShiftExchangeCommunicationModalComponent = (() => {
			const modalRef = this.modalService.openModal<SchedulingApiShiftExchangeCommunicationAction>(PShiftExchangeCommunicationModalComponent, {
				size: MODAL_SIZE,
			});
			void modalRef.result.then(value => {
				if (value.modalResult === 'success') {
					successCallback(value.value);
				} else {
					this.onPerformActionModalDismiss.emit(clickedActionData);
				}
			});
			return modalRef.componentInstance as PShiftExchangeCommunicationModalComponent;
		})();

		pShiftExchangeCommunicationModalComponent.initModal(
			this.shiftExchange,
			this.communication,
			clickedActionData,
			this.beforeModalClose,
		);
	}

	/**
	 * User wants to perform action. Open modal with the detail settings for this action if necessary.
	 */
	public onPerformAction(clickedActionData : ActionData) : void {
		const prefMismatchModalContentOptions = this.getPrefMismatchModalContentOptions(clickedActionData);
		if (prefMismatchModalContentOptions) {
			// prefersSwapping of IM and other person don’t match. Give user a hint what the effects will be.
			void this.modalService.confirm(
				prefMismatchModalContentOptions,
				{
					theme: enumsObject.PThemeEnum.INFO,
					size: enumsObject.BootstrapSize.LG,
				},
			).result.then(value => {
				if (value.modalResult === 'dismiss') return;
				this.openPerformActionModal(clickedActionData);
			});
			return;
		}

		// What user selected is not a mismatch with the prefersSwapping settings that IM made.
		// So lets go straight to the action-modal
		this.openPerformActionModal(clickedActionData);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public get badgeIcon() : (
		typeof enumsObject.PlanoFaIconPool.CANCELED |
		typeof enumsObject.PlanoFaIconPool.SUCCESS |
		typeof enumsObject.PlanoFaIconPool.QUESTION |
		null) {
		const icon = this.pShiftExchangeConceptService.getBadgeIcon(this.shiftExchange);
		if (icon) return icon;
		return null;
	}

	public badgeAlign : 'left' | 'right' = 'right';

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public onStartEditComment() : void {
		if (this.showTextarea) return;
		this.showTextarea = !this.showTextarea;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public isMe(member : SchedulingApiMember | null) : boolean | null {
		if (!member) return null;
		return this.rightsService.isMe(member.id) ?? null;
	}

}
