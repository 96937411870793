import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'p-timeline-shiftmodel-parent-column',
	templateUrl: './timeline-shiftmodel-parent-column.component.html',
	styleUrls: ['./timeline-shiftmodel-parent-column.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTimelineShiftModelParentColumnComponent {
	@HostBinding('class.border-left')
	@HostBinding('class.p-2') protected _alwaysTrue = true;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public name : string | null = null;

}
