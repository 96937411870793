<p-nav-back-headline class="shadow-sm" label="Profil & Einstellungen" i18n-label/>

<scroll-shadow-box class="p-0 flex-grow-1 position-relative">
	<!--Needed to keep a bigger space between the headline and the options-->
	<div class="pt-4"></div>
	<ng-container *ngFor="let anchorSettingsTemplateInfo of anchorSettingsOptions">
		<ng-template *ngTemplateOutlet="anchorSettingsTemplate; context: anchorSettingsTemplateInfo"></ng-template>
	</ng-container>
	<div
		tabindex="0"
		class="btn btn-lg d-flex justify-content-start align-items-center w-100 nowrap border-top-0 border-left-0 border-right-0 py-2 px-3 crop-on-overflow"
		(click)="logoutConfirmationModal()"
	><fa-icon [style]="memberBadgeComponent ? iconWidthStyle(memberBadgeComponent.badgeNativeWidth) : ''" class="d-flex align-items-center justify-content-center" [icon]="enums.PlanoFaIconPool.LOGOUT"></fa-icon><span class="crop-on-overflow">&nbsp;{{'Abmelden' | localize}}</span></div>
	<hr>
	<div class="px-3 w-100">
		<div class="mt-3 d-flex"><fa-icon [style]="memberBadgeComponent ? iconWidthStyle(memberBadgeComponent.badgeNativeWidth) : ''" class="d-flex align-items-center justify-content-center" icon="headset"/>&nbsp;<a class="external-link" [externalLink]="'mailto:service@dr-plano.com'">service@dr-plano.com</a></div>
		<div class="mt-3 d-flex"><fa-icon [style]="memberBadgeComponent ? iconWidthStyle(memberBadgeComponent.badgeNativeWidth) : ''" class="d-flex align-items-center justify-content-center" icon="user-lock"/>&nbsp;<a class="external-link" routerLink="/privacy">Datenschutzerklärung</a></div>
	</div>
	<!-- eslint-disable-next-line @angular-eslint/template/accessibility-interactive-supports-focus -->
	<div class="text-center mt-5 mb-3" (click)="iconClicked()">✌</div>
</scroll-shadow-box>

<ng-template
	#anchorSettingsTemplate
	let-anchorRouterLink="anchorRouterLink"
	let-anchorText="label"
	let-anchorIcon="prependedItem"
	let-anchorShowCondition="anchorShowCondition">
	<a
		*ngIf="anchorShowCondition===undefined ? true : anchorShowCondition"
		class="btn btn-lg d-flex justify-content-start align-items-center w-100 nowrap border-top-0 border-left-0 border-right-0 py-2 px-3 crop-on-overflow"
		[routerLink]="anchorRouterLink"
		routerLinkActive
	><fa-icon *ngIf="anchorIcon; else memberBadge" [style]="memberBadgeComponent ? iconWidthStyle(memberBadgeComponent.badgeNativeWidth) : ''" class="d-flex align-items-center justify-content-center" [icon]="anchorIcon"></fa-icon><span class="crop-on-overflow">&nbsp;{{anchorText | localize}}</span></a>
	<ng-template #memberBadge>
		<p-member-badge
			#memberBadgeComponent
			[memberId]="meService.data.id"
			[firstName]="meService.data.firstName"
			[lastName]="meService.data.lastName"
			[avatar]="meService.data.avatar"
			[isMe]="true"
			size="normal"
		/>
	</ng-template>
</ng-template>
