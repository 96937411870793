import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormattedDateTimePipe } from '@plano/client/shared/formatted-date-time.pipe';
import { ShiftId, TimeStampApiService, TimeStampApiShift, TimeStampApiShifts } from '@plano/shared/api';

@Component({
	selector: 'p-shift-select',
	templateUrl: './shift-select.component.html',
	styleUrls: ['./shift-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class ShiftSelectComponent {
	@Input() public disabled : boolean | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private selectedShiftId : ShiftId | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() private selectedShiftIdChange = new EventEmitter<ShiftId>();

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('shifts') private _shifts : TimeStampApiShifts | null = null;

	/**
	 * Label for the trigger-button. Has a default like "Wähle deine Schicht" but can be overwritten here.
	 */
	@Input() public placeholder : string | null = null;

	constructor(
		public api : TimeStampApiService,
		public formattedDateTimePipe : FormattedDateTimePipe,
	) {
	}

	public isOpen : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public onSelect(shift : TimeStampApiShift) : void {
		this.isOpen = false;
		this.selectedShiftIdChange.emit(shift.id);
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get selectedShift() : TimeStampApiShift | null {
		if (this.selectedShiftId !== null) {
			return this._shifts!.get(this.selectedShiftId);
		}
		return null;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get shifts() : readonly TimeStampApiShift[] {
		return this._shifts!.sortedBy(item => item.start).iterable();
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public getFormattedTimeInfo(start : number, end ?: number) : string | null {
		return this.formattedDateTimePipe.getFormattedTimeInfo(start, end).full;
	}
}
