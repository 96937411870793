import { Directive } from '@angular/core';

/**
 * Directive to wrap elements with the class .input-group-append
 */
@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: '.input-group-append',
})
export class InputGroupAppendDirective {
}

/**
 * Directive to wrap elements with the class .input-group-prepend
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '.input-group-prepend',
})
export class InputGroupPrependDirective {
}