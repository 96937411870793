/* eslint max-lines: ["error", 170] */
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, KeyValuePipe, PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconDirective } from '@plano/client/shared/p-icon/p-icon.directive';
import { getPopoverConfig, getTooltipConfig } from '@plano/ngx-bootstrap.config';
import { RouterStorageDirective } from '@plano/shared/core/directive/anchor-url-storage.directive';
import { AsyncSaveButtonDirective } from '@plano/shared/core/directive/async-save-button.directive';
import { BadgeDirective } from '@plano/shared/core/directive/badge.directive';
import { DisabledDirective } from '@plano/shared/core/directive/disabled.directive';
import { ForceStickyDirective } from '@plano/shared/core/directive/force-sticky.directive';
import { IconListDirective } from '@plano/shared/core/directive/icon-list.directive';
import { NgVarDirective } from '@plano/shared/core/directive/ng-var.directive';
import { OddEvenDirective } from '@plano/shared/core/directive/odd-even-parent.directive';
import { TitleToAriaLabelDirective } from '@plano/shared/core/directive/title-to-aria-label.directive';
import { ValidFragmentsDirective } from '@plano/shared/core/directive/valid-fragments.directive';
import { PExternalLinkComponent } from '@plano/shared/core/external-link/p-external-link.component';
import { IgnoreGutterDirective } from '@plano/shared/core/ignore-gutter.directive';
import { PAutoFocusInsideModalDirective } from '@plano/shared/core/p-auto-focus/p-auto-focus.directive';
import { TabIndexDirective } from '@plano/shared/core/tabindex.directive';
import { PSentryModule } from '@plano/shared/sentry/sentry.module';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { CoreComponentsModule } from './component/core-components.module';
import { PFaIconModule } from './component/fa-icon/fa-icon.module';
import { PContrastPipe } from './contrast.service';
import { AffectedDirective } from './directive/affected.directive';
import { ChangeDetectionDirective } from './directive/change-detection.directive';
import { PCropOnOverflowDirective } from './directive/crop-on-overflow.directive';
import { RowIsMissingDirective } from './directive/deprecated-row.directive';
import { ExternalLinkDirective } from './directive/external-link.directive';
import { MutedDirective } from './directive/muted.directive';
import { SelectedDirective } from './directive/selected.directive';
import { PModalModule } from './p-modal/modal.module';
import { PTooltipModule } from './p-tooltip/tooltip.module';
import { CorePipesModule } from './pipe/core-pipes.module';
import { LocalizeFilePipe } from './pipe/localize-file.pipe';
import { LocalizePipe } from './pipe/localize.pipe';
import { PCurrencyPipe } from './pipe/p-currency.pipe';
import { PDatePipe } from './pipe/p-date.pipe';
import { TranslatePipe } from './pipe/translate.pipe';

@NgModule({
	imports: [
		AsyncSaveButtonDirective,
		BadgeDirective,
		CommonModule,
		CoreComponentsModule,
		CorePipesModule,
		DisabledDirective,
		ForceStickyDirective,
		FormsModule,
		HttpClientModule,
		IconListDirective,
		IgnoreGutterDirective,
		NgVarDirective,
		NgxPrintModule,
		NgxPrintModule,
		OddEvenDirective,
		TooltipModule.forRoot(),
		PAutoFocusInsideModalDirective,
		PFaIconModule,
		PModalModule,

		PopoverModule,

		PSentryModule,
		PTooltipModule,
		ReactiveFormsModule,
		RouterModule,
		RouterStorageDirective,
		TabIndexDirective,
		TitleToAriaLabelDirective,
		ValidFragmentsDirective,
	],
	declarations: [
		AffectedDirective,
		ChangeDetectionDirective,
		ExternalLinkDirective,
		IconDirective,
		MutedDirective,
		PCropOnOverflowDirective,
		PExternalLinkComponent,
		RowIsMissingDirective,

		// TODO: 	Add note here why this must be declared in core module.
		// 				Adding this component here also means: if you want to load the simple SelectedDirective, you need to have
		// 				the LoginFormComponent available. This seems illogical.
		SelectedDirective,
	],
	providers: [

		// TODO: Move to another module with all api-related stuff(PApiModule?)
		// ApiErrorService,
		{
			provide: TooltipConfig,
			useFactory: getTooltipConfig,
		},
		{
			provide: PopoverConfig,
			useFactory: getPopoverConfig,
		},
		CurrencyPipe,
		DatePipe,
		DecimalPipe,
		KeyValuePipe,
		LocalizeFilePipe,
		LocalizePipe,
		PCurrencyPipe,
		PDatePipe,
		PercentPipe,
		TranslatePipe,
	],
	exports: [
		AffectedDirective,
		AsyncSaveButtonDirective,
		BadgeDirective,
		ChangeDetectionDirective,
		CommonModule,
		CoreComponentsModule,
		CorePipesModule,
		DisabledDirective,
		ExternalLinkDirective,
		ForceStickyDirective,
		FormsModule,
		IconDirective,
		IconListDirective,
		IgnoreGutterDirective,
		LocalizeFilePipe,
		LocalizePipe,
		MutedDirective,
		NgVarDirective,
		NgxPrintModule,
		OddEvenDirective,
		PAutoFocusInsideModalDirective,
		PContrastPipe,
		PCropOnOverflowDirective,
		PCurrencyPipe,
		PDatePipe,
		PExternalLinkComponent,
		PFaIconModule,
		PModalModule,
		PopoverModule,
		PTooltipModule,
		ReactiveFormsModule,
		RouterModule,
		RouterStorageDirective,
		RowIsMissingDirective,
		SelectedDirective,
		TabIndexDirective,
		TitleToAriaLabelDirective,
		TooltipModule,
		TranslatePipe,
		ValidFragmentsDirective,
	],
})
export class CoreModule {}
