<p-all-day-items-list
	[calendarMode]="CalendarModes.WEEK"
	[startOfDay]="weekday"
	[heightOfLine]="heightOfLine"
	[popperPlacement]="NgxPopperjsPlacements.BOTTOMSTART"
	[readMode]="readMode"

	[absences]="absencesOfDay(weekday)"
	[holidays]="holidaysOfDay(weekday)"
	[birthdays]="birthdaysOfDay(weekday)"
></p-all-day-items-list>
