<ng-template [ngIf]="merged" [ngIfElse]="dontMerge">
	<span
		[innerHTML]="(duration! + automaticPauseDuration!) | pDurationAsHours: false | pSafeHtml"
		[pTooltip]="automaticPauseDuration ? tooltipMergedPauseDuration : null"
		[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
	></span><span *ngIf="!(duration! + automaticPauseDuration!)">0<sub>M</sub></span>
	<ng-template #tooltipMergedPauseDuration>
		<div class="d-block clickable card m-0 text-left o-hidden"
		>
			<div class="card-body p-1 pl-2 pr-2">
				<div class="text-center"><span
					*ngIf="!duration"
				>0<sub i18n>M</sub></span><strong
					*ngIf="duration"
					[innerHTML]="duration | pDurationAsHours: false | pSafeHtml"
				></strong><span
					class="text-warning"
					*ngIf="automaticPauseDuration"
				>+<strong
					[innerHTML]="automaticPauseDuration | pDurationAsHours: false | pSafeHtml"
				></strong>
				</span></div>
				<div i18n>Es wurden Zeiten automatisch hinzugefügt, da die <strong *ngIf="memberName">von {{ memberName }} </strong>eingetragenen Pausen unter dem gesetzlichen Minimum liegen.</div>
			</div>
		</div>
	</ng-template>
</ng-template>
<ng-template #dontMerge>
	<span
		[innerHTML]="duration! | pDurationAsHours: false | pSafeHtml"
	></span><span *ngIf="!duration">0<sub i18n>M</sub></span><span
		class="text-warning"
		*ngIf="automaticPauseDuration"

		[pTooltip]="tooltipTemplate ? tooltipTemplate : tooltipAutomaticPauseDurationTemplate"
		[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
	>+<span [innerHTML]="automaticPauseDuration | pDurationAsHours: false | pSafeHtml"></span></span>
	<ng-template #tooltipAutomaticPauseDurationTemplate>
		<ng-template [ngIf]="!isForecast" [ngIfElse]="isForecastTemplate"><ng-container i18n>Diese Zeit wurde automatisch hinzugefügt, da die <strong *ngIf="memberName">von {{ memberName }} </strong>eingetragene Pause <strong [innerHTML]="(regularPauseDuration ? regularPauseDuration : duration!) | pDurationAsHours: false | pSafeHtml"></strong> unter dem gesetzlichen Minimum liegt.</ng-container></ng-template>
		<ng-template #isForecastTemplate><ng-container i18n>Laut Gesetz wird<strong *ngIf="memberName"> {{ memberName }}</strong> mindestens <span [innerHTML]="automaticPauseDuration! | pDurationAsHours: false | pSafeHtml"></span> Pause machen müssen.</ng-container></ng-template>
	</ng-template>
</ng-template>
