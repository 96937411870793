import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PThemeEnum } from '@plano/client/shared/bootstrap-styles.enum';
import { PShiftExchangeConceptService, SchedulingApiShiftExchange, SchedulingApiShiftExchangeCommunication } from '@plano/shared/api';

@Component({
	selector: 'p-shift-exchange-state-badge[shiftExchange]',
	templateUrl: './p-shift-exchange-state-badge.component.html',
	styleUrls: ['./p-shift-exchange-state-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftExchangeStateBadgeComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public shiftExchange ! : SchedulingApiShiftExchange;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public communication : SchedulingApiShiftExchangeCommunication | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('text') private _text : string | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input('theme') private _theme : PThemeEnum | null = null;

	@HostBinding('title')
	// eslint-disable-next-line jsdoc/require-jsdoc
	public get text() : string | null {
		if (this._text) return this._text;
		if (this.communication) {
			return this.pShiftExchangeConceptService.getActionStateText(
				this.shiftExchange,
				this.communication,
				this.communication.lastAction,
			);
		}
		return this.pShiftExchangeConceptService.getStateText(this.shiftExchange);
	}

	constructor(
		private pShiftExchangeConceptService : PShiftExchangeConceptService,
	) {
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get theme() : PThemeEnum | null {
		if (this._theme) return this._theme;
		if (this.communication) {
			this.pShiftExchangeConceptService.getCommunicationStateStyle(
				this.communication.communicationState,
				this.communication.lastAction,
			);
		}
		return this.pShiftExchangeConceptService.getStateStyle(this.shiftExchange);
	}
}
