import './polyfills.ts';

// import 'hammerjs';

import { ApplicationRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { Config } from './shared/core/config';

// Set ngZoneEventCoalescing to "merge" multiple change detection cycles. My tests did not result
// in significant performance boosts but it should hurt.
// See https://github.com/angular/angular/pull/30533/files
void platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
	.then((moduleRef) => {
		if (!Config.DEBUG) return;

		// allows to run debug tools like `ng.profiler.timeChangeDetection();`
		const applicationRef = moduleRef.injector.get(ApplicationRef);
		const componentRef = applicationRef.components[0];
		enableDebugTools(componentRef);
	});

// TODO: 	[@storybook/angular@>6.6]:
// 				react and react-dom can probably be removed from package.json
// 				➡ https://drplano.atlassian.net/browse/PLANO-150062?focusedCommentId=26413
//        ➡ https://github.com/storybookjs/storybook/discussions/17835#discussioncomment-3077634

// TODO: [typescript@>=5.0.0]
// 				Remove ncurc.json entry for
// chalk

// TODO: [@angular/core@>=15.3]
// 				Remove ncurc.json entry for
// "ngx-cookie-service",
// "ngx-progressbar",
// "typescript",
