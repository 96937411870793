<div class="my-1">
	<div class="progress">
		<!-- <div class="progress progress-bar-striped"> -->
		<div
			class="progress-bar"
			[style.width]="score + '%'"
			[class.bg-danger]="passwordStrength === null || passwordStrength === PasswordStrengthEnum.WEAK"
			[class.bg-warning]="passwordStrength === PasswordStrengthEnum.FAIR"
			[class.bg-success]="passwordStrength === PasswordStrengthEnum.STRONG"
		></div>
	</div>

	<div class="d-flex justify-content-between align-items-end flex-wrap">
		<span class="flex-grow-1 nowrap mr-2 form-control-feedback"><ng-container i18n>Passwortstärke</ng-container>: <span class="font-weight-bold">{{ verbalizedPasswordStrength }}</span></span>
		<div class="d-flex" style="flex-wrap: wrap;">
			<div
				class="nowrap mr-2 form-control-feedback"
				[class.text-muted]="!variations.minLength"
			>
				<fa-icon
					[fixedWidth]="true"
					[icon]="variations.minLength ? enums.PlanoFaIconPool.SUCCESS : enums.PlanoFaIconPool.DISMISS"
					[theme]="variations.minLength ? enums.PThemeEnum.SUCCESS : enums.PThemeEnum.DANGER"
					[textAlign]="'left'"
				>
				</fa-icon>
				<span
					[class.text-danger]="!variations.minLength"
				>{{minLengthHintText}}</span>
			</div>
			<div
				class="nowrap mr-2 form-control-feedback"
				[class.text-muted]="!variations.digits"
			>
				<fa-icon
					[fixedWidth]="true"
					[icon]="variations.digits ? enums.PlanoFaIconPool.SUCCESS : enums.PlanoFaIconPool.DISMISS"
					[theme]="variations.digits ? enums.PThemeEnum.SUCCESS : enums.PThemeEnum.DANGER"
					[textAlign]="'left'"
				>
				</fa-icon>
				<span
					[class.text-danger]="!variations.digits"
					i18n
				>Zahlen</span>
			</div>
			<div
				class="nowrap mr-2 form-control-feedback"
				[class.text-muted]="!variations.upperCase"
			>
				<fa-icon
					[fixedWidth]="true"
					[icon]="variations.upperCase ? enums.PlanoFaIconPool.SUCCESS : enums.PlanoFaIconPool.DISMISS"
					[theme]="variations.upperCase ? enums.PThemeEnum.SUCCESS : enums.PThemeEnum.DANGER"
					[textAlign]="'left'"
				>
				</fa-icon>
				<span
					[class.text-danger]="!variations.upperCase"
					i18n
				>Großbuchstaben</span>
			</div>
			<div
				class="nowrap mr-2 form-control-feedback"
				[class.text-muted]="!variations.lowerCase"
			>
				<fa-icon
					[fixedWidth]="true"
					[icon]="variations.lowerCase ? enums.PlanoFaIconPool.SUCCESS : enums.PlanoFaIconPool.DISMISS"
					[theme]="variations.lowerCase ? enums.PThemeEnum.SUCCESS : enums.PThemeEnum.SECONDARY"
					[textAlign]="'left'"
				>
				</fa-icon>
				<span i18n>Kleinbuchstaben</span>
			</div>
			<div
				class="nowrap form-control-feedback"
				[class.text-muted]="!variations.nonWords"
			>
				<fa-icon
					[fixedWidth]="true"
					[icon]="variations.nonWords ? enums.PlanoFaIconPool.SUCCESS : enums.PlanoFaIconPool.DISMISS"
					[theme]="variations.nonWords ? enums.PThemeEnum.SUCCESS : enums.PThemeEnum.SECONDARY"
					[textAlign]="'left'"
				>
				</fa-icon>
				<span i18n>Sonderzeichen</span>
			</div>
		</div>
	</div>
</div>
