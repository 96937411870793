<p-modal-content
	[modalTitle]="modalContentOptions.modalTitle"
	[icon]="modalContentOptions.icon ?? null"
	[dismissBtnLabel]="modalContentOptions.dismissBtnLabel ?? null"
	[dismissBtnIcon]="modalContentOptions.dismissBtnIcon ?? null"
	[closeBtnLabel]="modalContentOptions.closeBtnLabel ?? null"
	[closeBtnIcon]="modalContentOptions.closeBtnIcon ?? null"
	[closeBtnTheme]="modalContentOptions.closeBtnTheme"
	[hideDismissBtn]="!!modalContentOptions.hideDismissBtn"
	[closeBtnDisabled]="closeBtnDisabled"

	[showCustomFooter]="showCustomFooter"
	[theme]="theme ?? null"
	(onDismiss)="onDismiss()"
	(onClose)="onClose();"
>
	<p-modal-content-body>
		<p *ngIf="modalContentOptions.description" [innerHtml]="modalContentOptions.description"></p>
		<ng-container #content></ng-container>

	</p-modal-content-body>

	<p-modal-content-footer>
		<ng-container #footer></ng-container>
	</p-modal-content-footer>
</p-modal-content>
