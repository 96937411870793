<div class="btn-group w-100 raw" role="group"
	[style.opacity]="hovered || settingExistsForThisArea ? 1 : 0.3"
	(mouseover)="hovered=true"
	(mouseout)="hovered=false"
>
	<ng-template
		[ngIf]="isMemberRightGroup"
		[ngIfElse]="showNotificationToggle"
	>
		<button type="button"
			class="btn col"
			pEditableInstantSaveOnClick
			[class.btn-primary]="rightGroup.shiftModelRights.getByItem(item) ? rightGroup.shiftModelRights.getByItem(item)!.canRead : false"
			[class.btn-outline-secondary]="rightGroup.shiftModelRights.getByItem(item) ? !rightGroup.shiftModelRights.getByItem(item)!.canRead : true"
			(triggerClick)="toggleCanRead()"
			[disabled]="!api.isLoaded() || disabled"
			title="Leserecht" i18n-title
		>
			<ng-container *ngTemplateOutlet="eyeIcon"></ng-container>
		</button>
		<button type="button"
			class="btn col"
			pEditableInstantSaveOnClick
			[class.btn-primary]="rightGroup && rightGroup.shiftModelRights.getByItem(item) ? rightGroup.shiftModelRights.getByItem(item)!.canWriteBookings : false"
			[class.btn-outline-secondary]="rightGroup && rightGroup.shiftModelRights.getByItem(item) ? !rightGroup.shiftModelRights.getByItem(item)!.canWriteBookings : true"
			(triggerClick)="toggleCanManageBookings()"
			[disabled]="!api.isLoaded() || disabled"
			title="Buchungen verwalten" i18n-title
		>
			<fa-icon [icon]="enums.PlanoFaIconPool.ITEMS_SALES"></fa-icon>
		</button>
		<button type="button"
			class="btn col"
			pEditableInstantSaveOnClick
			[class.btn-primary]="rightGroup && rightGroup.shiftModelRights.getByItem(item) ? rightGroup.shiftModelRights.getByItem(item)!.canWrite : false"
			[class.btn-outline-secondary]="rightGroup && rightGroup.shiftModelRights.getByItem(item) ? !rightGroup.shiftModelRights.getByItem(item)!.canWrite : true"
			(triggerClick)="toggleCanWrite()"
			[disabled]="!api.isLoaded() || disabled"
			title="Schreibrecht" i18n-title
		>
			<ng-container *ngTemplateOutlet="editIcon"></ng-container>
		</button>
		<button type="button"
			class="btn col"
			pEditableInstantSaveOnClick
			[class.btn-primary]="rightGroup && rightGroup.shiftModelRights.getByItem(item) ? rightGroup.shiftModelRights.getByItem(item)!.canOnlineRefund : false"
			[class.btn-outline-secondary]="rightGroup && rightGroup.shiftModelRights.getByItem(item) ? !rightGroup.shiftModelRights.getByItem(item)!.canOnlineRefund : true"
			(triggerClick)="toggleCanOnlineRefund()"
			[disabled]="!api.isLoaded() || disabled"
			title="Online-Rückerstattung" i18n-title
		>
			<fa-icon [icon]="enums.PlanoFaIconPool.BOOKING_PAYMENT_STATUS"></fa-icon>
		</button>
	</ng-template>
	<ng-template #showNotificationToggle>
		<button type="button"
			class="btn col btn-primary"
			[disabled]="true"

			[pTooltip]="tooltipAdminNotEditable"
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
			title="Leserecht" i18n-title
		>
			<ng-container *ngTemplateOutlet="eyeIcon"></ng-container>
		</button>
		<button type="button"
			class="btn col btn-primary"
			[disabled]="true"

			[pTooltip]="tooltipAdminNotEditable"
			[pTooltipPlacement]="NgxPopperjsPlacements.BOTTOM"
			title="Schreibrecht" i18n-title
		>
			<ng-container *ngTemplateOutlet="editIcon"></ng-container>
		</button>
	</ng-template>
	<button type="button"
		class="btn col"
		pEditableInstantSaveOnClick
		[disabled]="!api.isLoaded() || disabled"
		[class.btn-primary]="rightGroup && api.isLoaded() && rightGroup.canGetManagerNotificationByItem(item)"
		[class.btn-outline-secondary]="rightGroup && api.isLoaded() && !rightGroup.canGetManagerNotificationByItem(item)"
		(triggerClick)="toggleCanGetManagerNotifications()"
		title="Benachrichtigungen für Bereichsleitende" i18n-title
	>
		<ng-container *ngTemplateOutlet="envelopeIcon"></ng-container>
	</button>
</div>

<ng-template #tooltipAdminNotEditable>
	<div class="d-block clickable card m-0 text-left o-hidden"
	>
		<div class="p-3">
			<div i18n>Aktuell dürfen Admins alles sehen und editieren 🎉&nbsp; Eine Differenzierung ist noch nicht möglich.</div>
		</div>
	</div>
</ng-template>

<ng-template #eyeIcon>
	<fa-icon [icon]="['regular','eye']"></fa-icon>
</ng-template>
<ng-template #editIcon>
	<fa-icon icon="pen"></fa-icon>
</ng-template>
<ng-template #envelopeIcon>
	<fa-icon icon="envelope"></fa-icon>
</ng-template>
