/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 * BEFORE EDITING THESE DIRECTIVES, CHECK THIS:
 * https://drplano.atlassian.net/browse/PLANO-20987
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

/* NOTE: Dont make this file even bigger. Invest some time to cleanup/split into several files */
/* eslint max-lines: ["error", 1000] */
/* eslint max-classes-per-file: ["error", 10] */

/**
 * This is a quite complex set of directives. Here is an overview. Note: These things are all directives. I only
 * start lines with »(at)param« for code editor highlighting reasons.
 * @param pEditable: A wrapper for all the other Directives
 * @param pEditableTriggerFocussable Triggers the edit-mode of the pEditable. Its needed for inputs, textareas, etc.
 * @param pEditableTriggerClickable Triggers the edit-mode of the pEditable. Its needed for pEditable boxes with
 * e.g. an button with pencil icon.
 * @param pEditableInstantSaveOnClick Instantly saves value to api. Its needed for e.g. checkboxes.
 * @param pEditableInstantDismissOnClick Instantly dismiss. Its needed for e.g. aborting editable modals.
 * @param pEditableSuccessButton A save button that is shown when the pEditable is in edit-mode.
 * @param pEditableDismissButton A dismiss button that is shown when the pEditable is in edit-mode.
 * @param pVisibleInEditMode This only controls the »hidden« attribute of html elements related to the edit-mode.
 * @param pHiddenInEditMode This only controls the »hidden« attribute of html elements related to the edit-mode.
 */

// * @param disabledIfAnyEditMode This sets an html element to disable if any edit-mode is active in the whole app.

import { HttpResponse } from '@angular/common/http';
import { AfterContentChecked, AfterContentInit, AfterViewInit, ApplicationRef, ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, NgZone, OnDestroy, Optional, Output } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { ApiBase } from '@plano/shared/api';
import { NOT_CHANGED } from '@plano/shared/api/base/object-diff/object-diff';
import { DisabledDirective } from '@plano/shared/core/directive/disabled.directive';
import { TitleToAriaLabelDirective } from '@plano/shared/core/directive/title-to-aria-label.directive';
import { LogService } from '@plano/shared/core/log.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PTextEditorComponent } from '@plano/shared/p-forms/p-text-editor/p-text-editor.component';
import { PTextareaComponent } from '@plano/shared/p-forms/p-textarea/p-textarea.component';
import { PSentryService } from '@plano/shared/sentry/sentry.service';
import * as $ from 'jquery';

// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export let activeEditable : EditableDirective | null = null;

/**
 * Collects all pEditables that are on the page
 */
const allEditables : EditableDirective[] = [];

/**
 * Is the modal-hook for a pEditable open / currently shown to the user?
 */
let hookIsActive = false;

/**
 * This exists to make sure the handler gets created only once
 */
let globalClickHandlerCreated = false;

/**
 * Figure out if the user has clicked an area outside any pEditable like whitespace
 */
const determineIfClickedOutside = async (event : Event) : Promise<void> => {
	const previouslySelectedEditable = activeEditable;
	if (!previouslySelectedEditable) return;
	const targetHtmlElement = event.target as Node;

	// Get the new targeted Editable
	let newSelectedEditable : EditableDirective | null = null;
	newSelectedEditable = allEditables.find((item) => {
		return item.el.nativeElement.contains(targetHtmlElement);
	}) ?? null;

	// Is outside if target is not an Editable
	if (newSelectedEditable) return;

	// Nothing to do here if outsideClick's are blocked
	if (previouslySelectedEditable.blockOutsideClick) return;

	// saveChanges() sets previouslySelectedEditable to null but we need the applicationRef in the success callback.
	const applicationRef = previouslySelectedEditable.applicationRef;

	await previouslySelectedEditable.endEditable(event);

	// this code is run outside angular. So manually trigger change detection
	applicationRef.tick();
};

/**
 * Hook that will be used inside an »Editable«
 * @see EditableInterface#saveChangesHook
 */
export type EditableHookType<ValueType = unknown> = Promise<{
	modalResult : 'success' | 'dismiss',
	value : ValueType,
}>;

/**
 * This is a interface for the pEditable-component
 */
export interface EditableInterface<ApiClass extends ApiBase = ApiBase> {

	/**
	 * Api is needed if this is a editable interface element.
	 */
	api : ApiClass | null;

	/**
	 * Is this a valid form element or are there e.g. any validation errors?
	 * If there are validation errors the EditableDirective will never try to
	 * save this broken data.
	 */
	valid : boolean | null;

	/**
	 * Here you can set a function that is able to dismiss or continue the editable process.
	 * Your The function will be executed. If the provided Promise succeeds, the EditableDirective
	 * continues as if nothing happened. If your Promise gets rejected, the editable stops and resets all changes
	 * that the user was about to make.
	 *
	 * @example
	 *   <foo
	 *     [pEditable]="true"
	 *     [onSaveChangesHook]="someHook"
	 *     …
	 *
	 *   public get someHook() : EditableInterface['saveChangesHook'] {
	 *     return () => this.modalService.openModal(this.modalContent).result;
	 *   }
	 *
	 * @example
	 *   <foo
	 *     [pEditable]="true"
	 *     [onSaveChangesHook]="someHook"
	 *     …
	 *
	 *   public someHook() : EditableDirective['saveChangesHook'] {
	 *     return () => {
	 *       if (this.noModalNecessary) return null;
	 *       return this.modalService.openModal(this.modalContent).result;
	 *     }
	 *   }
	 */
	saveChangesHook ?: (event : Event) => EditableHookType | null;

	/**
	 * Happens right before saving. (after hook if hook is set)
	 */
	onSaveStart : EventEmitter<undefined>;

	/**
	 * Happens after the data has been saved.
	 */
	onSaveSuccess : EventEmitter<undefined>;

	// NOTE: Not all pEditables have a possibility to fire a dismiss event. E.g. radio-inputs.
	// onDismiss : EventEmitter<undefined>;

	/**
	 * Happens when user leaves change-mode. No matter if changes have been saved or not.
	 * Note that this does not wait for api callbacks.
	 */
	onLeaveCurrent : EventEmitter<undefined>;

	/**
	 * State of this pEditable.
	 */
	editMode : EventEmitter<boolean>;
}

/**
 * This is a interface for formControls based on pEditables
 */
export interface EditableControlInterface<T extends ApiBase = ApiBase> extends EditableInterface<T> {

	/**
	 * Should this be a editable?
	 * Usually false if its a new item (new member, new shift, etc.).
	 * Usually true if its not a new item (existing member, existing shift, etc.).
	 */
	pEditable : boolean;
}

/**
 * A directive that adds methods to save, dismiss, etc. api data.
 */
@Directive({
	selector: '[pEditable][api]',
	exportAs: 'pEditable',
})
export class EditableDirective
implements AfterContentInit, AfterContentChecked, AfterViewInit, EditableInterface, OnDestroy {

	/** @see EditableControlInterface.pEditable */
	@Input() public pEditable ! : EditableControlInterface['pEditable'];

	/** @see EditableControlInterface.api */
	@Input() public api ! : EditableInterface['api'] | null;

	/** @see EditableControlInterface.valid */
	@Input() public valid : boolean | null = true;

	// eslint-disable-next-line jsdoc/require-jsdoc
	@HostBinding('class.is-active') public get getActive() : boolean | null {
		return this.pEditable;
	}

	/** @see EditableInterface#saveChangesHook */
	@Input() public saveChangesHook ?: EditableInterface['saveChangesHook'];

	/** @see EditableInterface#onSaveStart */
	@Output() public onSaveStart : EditableInterface['onSaveStart'] = new EventEmitter<undefined>();

	/** @see EditableInterface#onSaveSuccess */
	@Output() public onSaveSuccess : EditableInterface['onSaveSuccess'] = new EventEmitter<undefined>();

	/**
	 * Happens after the changes (dataCopy of the api) have been dismissed
	 */
	@Output() public onDismiss = new EventEmitter<undefined>();

	@Output() public onLeaveCurrent = new EventEmitter<undefined>();

	@Output() public editMode : EventEmitter<boolean> = new EventEmitter<boolean>();

	// TODO: This should always be set to true when checkbox
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public pEditableDisableOnDestroy : boolean = false;

	// Listener for strg + enter on Windows.
	@HostListener('keydown.control.enter', ['$event'])

	// Listener for command + enter on macOS.
	@HostListener('keydown.meta.enter', ['$event'])
	private async _onShiftEnter(event : KeyboardEvent) : Promise<void> {
		return this.onEnter(event);
	}

	@HostListener('keydown.enter', ['$event']) private async _onEnter(event : KeyboardEvent) : Promise<void> {
		if (this.supportsLineBreaks) return;
		return this.onEnter(event);
	}

	constructor(
		protected changeDetectorRef : ChangeDetectorRef,
		public el : ElementRef<HTMLElement>,
		protected zone : NgZone,
		public applicationRef : ApplicationRef,
		protected console : LogService,
		protected toastsService : ToastsService,
		protected localize : LocalizePipe,
		@Optional() private pTextareaParent ?: PTextareaComponent,
		@Optional() private pTextEditorParent ?: PTextEditorComponent,
	) {
	}

	/**
	 * Handle enter
	 * @param event The event that triggered this method. Usually a enter key press.
	 */
	public async onEnter(event : KeyboardEvent) : Promise<void> {
		this.console.debug('EditableDirective: onEnter()');
		if (!this.showBtns) return;

		event.preventDefault();
		event.stopPropagation();

		if (this.pEditable) {
			await this.onSuccess(event);
			this.handleBlur(event);
		}
	}

	private get supportsLineBreaks() : boolean {
		const isTextarea = this.el.nativeElement.tagName === 'TEXTAREA';
		const containsTextarea = $(this.el.nativeElement).find('textarea').length > 0;
		const isTextareaChild = !!this.pTextareaParent;
		const isTextEditorChild = !!this.pTextEditorParent;
		return isTextarea || containsTextarea || isTextareaChild || isTextEditorChild;
	}

	/**
	 * Duration of animations like the pulsing success-button
	 */
	private animationDuration : number = 700;

	/**
	 * Should success (and dismiss) buttons be visible?
	 */
	public showBtns : boolean = false;

	/**
	 * Flag that checks if dismiss button has been clicked
	 */
	public clickedDismiss : boolean = false;

	/**
	 * Flag that checks if success button has been clicked
	 */
	public clickedSuccess : boolean = false;

	public ngAfterContentChecked() : void {
		if (this.pEditable && !allEditables.includes(this)) {
			allEditables.push(this);
		}
	}

	public ngAfterContentInit() : void {
		this.initValues();
	}

	public ngAfterViewInit() : void {
		if (globalClickHandlerCreated) return;

		globalClickHandlerCreated = true;
		this.zone.runOutsideAngular(() => {
			document.addEventListener('mousedown', determineIfClickedOutside);
		});
	}

	/**
	 * Set values that are necessary for this component.
	 * These initValues methods are used in many components.
	 * They mostly get used for class attributes that would cause performance issues as a getter.
	 */
	private initValues() : void {
		if (this.pEditable && (this.api as unknown) === undefined) throw new Error('You must set api input.');
	}

	/**
	 * Check if a double click just happened
	 */
	public get doubleClick() : boolean {
		return this.clickedSuccess || this.clickedDismiss;
	}

	/**
	 * Dismiss if this pEditable was activated
	 */
	public onUndo() : void {
		this.console.debug('EditableDirective: onUndo()');

		if (!this.pEditable) return;
		if (this.doubleClick) return;

		if (!this.api!.hasDataCopy()) throw new Error('No data copy available. [PLANO-FE-188]');

		void this.dismissChanges();
	}

	/**
	 * Try to save if this pEditable was activated
	 */
	public async onSuccess(
		event : Event,
	) : Promise<void | HttpResponse<unknown>> {
		this.console.debug('EditableDirective: onSuccess()');

		if (!this.pEditable) return;
		if (this.doubleClick) return;

		return await this.endEditable(event);
	}

	/**
	 * Handle esc
	 */
	public onEsc(event : KeyboardEvent) : void {
		this.console.debug('EditableDirective: onEsc()');
		if (this.pEditable) {
			this.onUndo();
			this.handleBlur(event);
		}
	}

	/**
	 * Blur the current element, and set focus to the underlying modal if any
	 */
	public handleBlur(event : Event) : void {
		event.preventDefault();
		event.stopPropagation();
		(event.target as HTMLElement).blur();
		if ($('.modal.show').length > 0) {
			$('.modal.show').trigger('focus');
		}
	}

	/**
	 * Check if the clicked item is a date-picker Modal or something comparable
	 */
	public get blockOutsideClick() : boolean {
		let result : boolean = false;
		const modals = $('ngb-modal-window');
		if (modals.length === 0) {
			// If there is no modal, then there is nothing that needs to be blocked.
			result = false;
		} else if (this.el.nativeElement.contains($(':focus')[0]) || modals[0].contains($(':focus')[0])) {
			// If the focus is inside the current pEditable then its fine
			// This is the case when user has closed the date-picker modal
			result = true;
		} else {
			// The last item is the modal that the user is looking at.
			const lastItem = modals.last();

			// Is the modal that the user is looking at the one with the current pEditable?
			// eslint-disable-next-line unicorn/no-array-callback-reference
			const lastItemContainsCurrentEditable = lastItem.find(this.el.nativeElement).length;

			// If not then its probably a date-picker or something comparable and the click should be ignored.
			result = !lastItemContainsCurrentEditable;
		}
		return result;
	}

	/**
	 * Starts this pEditable.
	 * @returns
	 * 	 Has the editable mode been started?
	 *   Reasons why it probably did not start:
	 *   - Was not active at the moment it has been called.
	 * 	 (TODO: Remove these cases. Expect editable to be active when method get called.)
	 *   - Another Editable was in active mode and needed to be handled first.
	 */
	public startEditable(event : Event) : boolean {
		// Is an inactive pEditable? Then do nothing.
		if (!this.pEditable) return false;

		// Start this pEditable
		this.console.debug('EditableDirective: startEditable()');

		// End previous pEditable
		if (activeEditable) void activeEditable.endEditable(event);

		// if hook from previous pEditable is still active we cannot start the new one.
		// TODO: PLANO-170690 I assume this gets obsolete, if we switch from void to await in the line above.
		if (hookIsActive) {
			this.handleBlur(event);
			return false;
		}

		if (this.api!.hasDataCopy()) {
			// About the extra optional chaining here:
			// We assume event and target will never be undefined here, but we want to be sure that no exception can happen.
			// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
			const targetContent = (event?.target as HTMLElement | undefined)?.textContent ?? null;

			// Sentry does not tell us which editable UI element has caused this issue. So we log it here, so we can read it
			// later in the logs of the Sentry event.
			this.console.error(`Target content: ${targetContent}`);

			throw new Error(`Old data copy existed while starting a new edit mode.`);
		}

		// start this pEditable
		// eslint-disable-next-line unicorn/no-this-assignment, @typescript-eslint/no-this-alias
		activeEditable = this;
		this.showBtns = true;
		this.changeDetectorRef.markForCheck();

		this.api!.createDataCopy();
		this.editMode.emit(this.api!.hasDataCopy());

		return true;
	}

	private async dismissChanges() : Promise<void> {
		this.api!.dismissDataCopy();
		activeEditable = null;

		this.onLeaveCurrent.emit();
		this.editMode.emit(this.api!.hasDataCopy());
		this.onDismiss.emit();
		await this.animateDismissButton();
	}

	/**
	 * The important one method that tries to save data. This is where the editable magic happens.
	 * @param event The event that triggered this method. Usually a click or keyup event.
	 * @param forceDismiss Sometimes the form is not invalid, but we should still dismiss the changes inside
	 * the editable. For such cases pass true to this param. example: A modal with the dismiss button should dismiss
	 * changes independently of the validity of the form inside.
	 */
	public async endEditable(event : Event, forceDismiss : boolean = false) : Promise<HttpResponse<unknown> | void> {
		this.console.debug('EditableDirective: endEditable()');

		if (!this.pEditable) throw new Error('Editable not active.');

		// This method should not be called when no data copy exists.
		if (!this.api!.hasDataCopy()) throw new Error('No data copy.');

		//
		// 	Form invalid? Then dismiss
		//
		if (this.valid === false || forceDismiss) {
			if (this.valid === false)
				this.console.debug('EditableDirective: invalid form');
			await this.dismissChanges();
			return;
		}

		const HAS_DATA_COPY_CHANGED = this.api!.hasDataCopyChanged();
		if (!HAS_DATA_COPY_CHANGED) this.console.debug('EditableDirective: data has not changed');

		if (!HAS_DATA_COPY_CHANGED) {
			return this.saveProcess();
		}

		const saveChangesHook = this.saveChangesHook?.(event) ?? null;

		if (saveChangesHook === null) {
			return this.saveProcess();
		}

		// Start hook
		this.console.debug('EditableDirective: Start hook');

		hookIsActive = true;

		const promiseResult = await saveChangesHook;
		hookIsActive = false;
		if (promiseResult.modalResult === 'success') {
			this.console.debug('EditableDirective: Hook closed', promiseResult.value);
			return this.saveProcess();
		} else {
			this.console.debug('EditableDirective: Hook dismissed');
			await this.dismissChanges();
			this.setFocusBackToClosestFocussableEditableTrigger();
		}
	}

	/**
	 * Set focus to the closest focussable editable trigger,
	 * because we assume the user dismissed because some input was not intended and the user wants to correct it.
	 */
	private setFocusBackToClosestFocussableEditableTrigger() : void {
		const focussableEditableTrigger = this.el.nativeElement.querySelector<HTMLElement>('[pEditableTriggerFocussable]') ?? this.el.nativeElement.querySelector<HTMLElement>('[pEditableInstantSaveOnClick]');

		// It is possible that the user already navigated (e.g. to another tab) so the element is gone. In that case we
		// do nothing.
		if (!focussableEditableTrigger) return;

		focussableEditableTrigger.focus();
	}

	private async saveProcess() : Promise<HttpResponse<unknown>> {
		activeEditable = null;
		this.onSaveStart.emit();

		this.api!.mergeDataCopy();

		// NOTE: We often get this kind of error. Throws will turn into JIRA Tickets automatically.
		// We added a throw here, to give JIRA the info if this kind of error was caused by a pEditable.
		if (!this.api!.isLoaded()) throw new Error('[Editable] You cannot call save() when api is not loaded.');

		// Do not wait for the servers answer to animate the button. It would look bad because it sometimes takes to long.
		const saveStartSubscriber = this.api!.onDataSaveStart.subscribe(() => {
			void this.animateSuccessButton();
		});

		try {
			const apiSavePromise = this.api!.save({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				success: (_response : HttpResponse<unknown> | null, savedData : any[] | typeof NOT_CHANGED) => {
					if (savedData === NOT_CHANGED) {
						this.showBtns = false;
						this.changeDetectorRef.markForCheck();
					} else {
						void this.animateSuccessButton();
						this.onSaveSuccess.emit();
					}

					saveStartSubscriber.unsubscribe();
				},
			});
			await apiSavePromise;
			this.editMode.emit(this.api!.hasDataCopy());
			this.onLeaveCurrent.emit();
			return apiSavePromise;
		} catch (error) {
			saveStartSubscriber.unsubscribe();
			throw error;
		}
	}

	private async animateDismissButton() : Promise<void> {
		this.clickedDismiss = true;
		this.changeDetectorRef.markForCheck();
		await this.waitForAnimation();
		this.clickedDismiss = false;
		this.changeDetectorRef.markForCheck();
	}

	private async animateSuccessButton() : Promise<void> {
		this.clickedSuccess = true;
		this.changeDetectorRef.markForCheck();
		await this.waitForAnimation();
		this.clickedSuccess = false;
		this.changeDetectorRef.markForCheck();
	}

	private lostItsFocus() : boolean {
		return !activeEditable || this !== activeEditable;
	}

	private async waitForAnimation() : Promise<void> {
		return new Promise((resolve) => {
			this.zone.runOutsideAngular(() => {
				window.setTimeout(() => {
					this.zone.run(() => {
						if ( this.lostItsFocus() ) {
							this.showBtns = false;
							this.changeDetectorRef.markForCheck();
						}
						resolve();
					});
				}, this.animationDuration);
			});
		});
	}

	public ngOnDestroy() : void {
		// remove this from global edit components Array
		const index = allEditables.indexOf(this);

		if (allEditables.at(index) === activeEditable) {
			activeEditable = null;
		}
		if (index >= 0) allEditables.splice(index, 1);
	}
}

/**
 * pEditableTriggerFocussable is needed for inputs, textareas, etc.
 * note that there is a pEditableTriggerClickable too
 */
@Directive({ selector: '[pEditableTriggerFocussable]' })
export class EditableTriggerFocussableDirective {
	@HostBinding('class.p-editable-active') private get _isActive() : boolean {
		return !!this.pEditableRef.pEditable;
	}

	@HostBinding('class.rounded-right') private get _roundedBorder() : boolean {
		return !!this.pEditableRef.pEditable && !this.pEditableRef.showBtns || !this.pEditableRef.pEditable;
	}

	@HostBinding('class.p-editable-has-hook') private get _hasHook() : boolean {
		return !!this.pEditableRef.saveChangesHook;
	}

	@HostListener('focus', ['$event']) private _onFocus(event : FocusEvent) : void {
		this.pEditableRef.startEditable(event);
	}

	/**
	 * Handle esc
	 */
	@HostListener('keyup.esc', ['$event']) protected _onEsc(event : KeyboardEvent) : void {
		this.pEditableRef.onEsc(event);
	}

	constructor(
		public pEditableRef : EditableDirective,
		public elementRef : ElementRef<HTMLElement>,
	) {}
}

/**
 * pEditableTriggerClickable is needed for buttons, links etc.
 * note that there is a pEditableTriggerFocussable too
 */
@Directive({ selector: '[pEditableTriggerClickable]' })
export class EditableTriggerClickableDirective extends DisabledDirective {
	@HostBinding('hidden') private get _isHidden() : boolean {
		return !(!this.pEditableRef.showBtns && this.pEditableRef.pEditable);
	}

	@HostBinding('disabled') private get _isDisabled() : boolean {
		if (this.disabled !== null) return this.disabled;
		if (!this.pEditableRef.pEditable) return false;
		return !this.pEditableRef.valid || this.pEditableRef.api!.hasDataCopy();
	}

	/**
	 * Pass a function that takes in the event and return a boolean
	 * on wether a new editable should be started or not.
	 */
	@Input() public preventDefault : ((event : Event) => boolean) | null = null;

	/**
	 * Like beforeEditHook but happens right before edit mode starts.
	 */
	@Input() public beforeEditHook ?: () => EditableHookType;

	@HostListener('click', ['$event']) private async _onClick(event : MouseEvent) : Promise<void> {
		if (this.preventDefault?.(event)) return;

		if (this.beforeEditHook) {
			await this.beforeEditHook();
		}

		this.pEditableRef.startEditable(event);
	}

	/**
	 * Handle esc
	 */
	@HostListener('keyup.esc', ['$event']) protected _onEsc(event : KeyboardEvent) : void {
		this.pEditableRef.onEsc(event);
	}

	constructor(
		public pEditableRef : EditableDirective,
		elementRef : ElementRef<HTMLElement>,
	) {
		super(elementRef);
	}
}

/**
 * pEditableInstantSaveOnClick is needed for e.g. checkboxes
 * when pEditableInstantSaveOnClick is clicked the value gets saved instantly
 * no success or save button needed
 */
@Directive({ selector: '[pEditableInstantSaveOnClick]' })
export class EditableInstantSaveOnClickDirective extends DisabledDirective {
	@HostBinding('class.p-editable-has-hook') private get _hasHook() : boolean {
		return !!this.pEditableRef.saveChangesHook;
	}

	@HostBinding('class.pulse')
	@HostBinding('class.pulse-success')
	@HostBinding('class.p-editable-active') private get _isActive() : boolean {
		return !!this.pEditableRef.pEditable;
	}

	@HostBinding('disabled') private get _isDisabled() : boolean {
		return !!this.disabled;
	}

	@HostBinding('class.show-animation') private get _isClicked() : boolean { return this.pEditableRef.clickedSuccess; }

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public triggerClick = new EventEmitter<MouseEvent>();

	/**
	 * Handle click
	 */
	@HostListener('click', ['$event']) private async _onClick(event : MouseEvent) : Promise<void> {

		if (this.pEditableRef.doubleClick)
			return;
		if (this.triggerClick.observers.length <= 0) return;

		if (!this.pEditableRef.pEditable) {
			this.triggerClick.emit(event);
			return;
		}

		if (await this.pEditableRef.startEditable(event)) {
			this.triggerClick.emit(event);
			await this.pEditableRef.endEditable(event);
		}
	}

	constructor(
		public pEditableRef : EditableDirective,
		private pSentryService : PSentryService,
		elementRef : ElementRef<HTMLElement>,
	) {
		super(elementRef);
	}
}

@Directive({ selector: '[pEditableInstantDismissOnClick]' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class EditableInstantDismissOnClickDirective extends DisabledDirective {
	@HostBinding('class.pulse')
	@HostBinding('class.pulse-success')
	@HostBinding('class.p-editable-active') private get _isActive() : boolean {
		return !!this.pEditableRef.pEditable;
	}

	@HostBinding('disabled') private get _isDisabled() : boolean {
		return !!this.disabled;
	}

	@HostBinding('class.show-animation') private get _isClicked() : boolean { return this.pEditableRef.clickedDismiss; }

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public triggerClick : EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	/**
	 * Handle click
	 */
	@HostListener('click', ['$event']) private async _onClick(event : MouseEvent) : Promise<void> {

		if (this.pEditableRef.doubleClick) return;
		if (this.triggerClick.observers.length <= 0) return;

		if (!this.pEditableRef.pEditable) {
			this.triggerClick.emit(event);
			return;
		}

		if (await this.pEditableRef.startEditable(event)) {
			this.triggerClick.emit(event);
			this.pEditableRef.onUndo();
		}
	}

	constructor(
		public pEditableRef : EditableDirective,
		private pSentryService : PSentryService,
		el : ElementRef<HTMLElement>,
	) {
		super(el);
	}
}

@Directive({ selector: '[pEditableSuccessButton]' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class EditableSuccessButtonDirective extends TitleToAriaLabelDirective {
	@HostBinding('class.pulse')
	@HostBinding('class.pulse-success')
	@HostBinding('class.btn')
	@HostBinding('class.btn-outline-secondary') protected _alwaysTrue = true;
	@HostBinding('type') private _type : string = 'button';
	public override get title() : string {
		return this.localize.transform('Speichern');
	}

	@HostBinding('disabled')
	@HostBinding('class.disabled') private get _isDisabled() : boolean {
		return !this.pEditableRef.valid;
	}

	@HostBinding('hidden') private get _isHidden() : boolean {
		return !(this.pEditableRef.showBtns && this.pEditableRef.pEditable);
	}

	@HostBinding('class.show-animation') private get _isClicked() : boolean { return this.pEditableRef.clickedSuccess; }
	@HostListener('click', ['$event']) private _onClick(event : MouseEvent) : void { void this.pEditableRef.onSuccess(event); }

	constructor( public pEditableRef : EditableDirective, private localize : LocalizePipe, el : ElementRef<HTMLElement> ) {
		super(el);
	}
}

@Directive({ selector: '[pEditableDismissButton]' })
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class EditableDismissButtonDirective extends TitleToAriaLabelDirective {
	@HostBinding('type') private _type : string = 'button';
	@HostBinding('class.pulse')
	@HostBinding('class.btn')
	@HostBinding('class.btn-outline-secondary') protected _alwaysTrue = true;

	@HostBinding('disabled')
	@HostBinding('class.disabled') private get _isDisabled() : boolean {
		return false;
	}

	@HostBinding('hidden') private get _isHidden() : boolean {
		return !(this.pEditable.showBtns && this.pEditable.pEditable);
	}

	@HostBinding('class.show-animation') private get _isClicked() : boolean { return this.pEditable.clickedDismiss; }
	@HostListener('click', ['$event']) private _onClick() : void { this.pEditable.onUndo(); }

	public override get title() : string {
		return this.localize.transform('Verwerfen');
	}

	constructor( public pEditable : EditableDirective, el : ElementRef<HTMLElement>, private localize : LocalizePipe ) {
		super(el);
	}
}

@Directive({
	selector: '[pVisibleInEditMode]',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PVisibleInEditModeDirective {
	@HostBinding('hidden') private get _isHidden() : boolean {
		// Editable is inactive when its e.g. a new member/shift/etc.
		const active = this.pEditable.pEditable;
		return !(!active || this.pEditable.showBtns);
	}

	constructor( public pEditable : EditableDirective ) {}
}

@Directive({
	selector: '[pHiddenInEditMode]',
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class HiddenInEditModeDirective {
	@HostBinding('hidden') private get _isHidden() : boolean {
		// Editable is active when its e.g. a existing member/shift/etc. form
		const active = this.pEditable.pEditable;
		return !active || this.pEditable.showBtns;
	}

	constructor( public pEditable : EditableDirective ) {}
}
