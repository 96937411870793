<p-report-row
	[headline]="false"
	[hideEditBtn]="hideEditBtn"
	[absence]="absence"
	[isEditable]="true"
>
	<span report-row-title class="crop-on-overflow">
		<span class="mr-3"><fa-icon [icon]="absence.typeIconName"></fa-icon> {{ absence.title! | localize }}</span>
		<span class="text-monospace d-none d-lg-inline mr-3"><span>{{ getDateInfo(absence).start }}</span>{{ getDateInfo(absence).separator }}<span>{{ getDateInfo(absence).end }}</span></span>
		<span class="text-monospace d-none d-lg-inline"
			*ngIf="absence.workingTimePerDay === null"
		>{{ absence.time.start | date:'shortTime' }} – <span>{{ absence.time.end | date:'shortTime' }}</span></span>
	</span>
	<strong report-row-earnings class="nowrap">{{ absence.totalEarningsBetween() | currency:Config.CURRENCY_CODE:'symbol':'1.2-2' }}</strong>
</p-report-row>
