/* eslint-disable no-restricted-syntax */ // Added this to be allowed to use "new ApiAttributeInfo"
import { Injectable, NgZone, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiBase, INITIALIZED_IN_BACKEND } from '@plano/shared/api/base/api-base/api-base';
import { Id } from '@plano/shared/api/base/id/id';
import { Meta } from '@plano/shared/api/base/meta';
import { ApiAttributeInfo } from '@plano/shared/api/base/attribute-info/api-attribute-info';
import { ApiAttributeValueInfo } from '@plano/shared/api/base/attribute-info/api-attribute-value-info';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { ApiSaveArgs, ApiLoadArgs, ShiftId, ShiftSelector, ApiListWrapper, SchedulingApiPosSystem, SchedulingApiBooking, SchedulingApiAccountHolderState, SchedulingApiGiftCardSettingsWaysToRedeem, ApiObjectWrapper, ApiDataWrapperBase, ApiObjectWrapperNewItemParams, ApiObjectWrapperExistingRawDataParams, AuthenticatedApiRole } from '@plano/shared/api';
import { DateTime, Date, DateExclusiveEnd, LocalTime, Duration, Minutes, Hours, Days, Months, Years, Percent, Email, Search, Tel, ClientCurrency, Euro, Password, PostalCode, Integer, Url, Iban, Bic, Image, Pdf, Color } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { AbstractControl } from '@angular/forms';
import { ApiErrorService } from '@plano/shared/api/api-error.service';
import { Assertions } from '@plano/shared/core/utils/assertions';

import { ClientsApiClient } from '@plano/shared/api';


/**
 * This service enables access to the api "admin/clients".
 * This file is auto generated by de.sage.scheduler.api_generator.ApiGenerator.
 */

// constants
class Consts
{
	CLIENT_COMPANY_NAME = 1;
	CLIENT_LOCATION_NAME = 2;
	CLIENT_TYPE = 3;
	CLIENT_TEST_ACCOUNT_DEADLINE = 4;
	CLIENT_TERMINATION_DATE = 5;
	CLIENT_LOCATION_COUNTRY = 6;
	CLIENT_ADYEN_ACCOUNT = 7;
	CLIENT_BILLING = 8;
	CLIENT_TEST_ACCOUNT_DURATION = 9;
	CLIENT_DISCOUNT_CODE = 10;
	CLIENT_BILLING_COUNTRY = 1;
	CLIENT_BILLING_HAS_TO_PAY_VAT = 2;
	CLIENT_BILLING_DISABLE_INVOICE_CREATION = 3;
	CLIENT_BILLING_DISCOUNT_TYPE = 4;
	CLIENT_BILLING_VAT_NUMBER = 5;
	CLIENT_BILLING_DISCOUNT_VALUE = 6;
	CLIENT_BILLING_DISCOUNT_START = 7;
	CLIENT_BILLING_DISCOUNT_END = 8;
	CLIENT_BILLING_BILLED_MEMBERS_COUNT_MAX = 9;
	CLIENT_BILLING_BILLED_MEMBERS_COUNT_SUBTRACT = 10;
	CLIENT_BILLING_DISCOUNT_COMMENT = 11;
	CLIENT_BILLING_CREDIT = 12;
	CLIENT_BILLING_EXEMPT_GIFT_CARD_SALES_FEES = 13;
	CLIENT_ADYEN_ACCOUNT_AUTO_DEBIT_ENABLED = 1;
	CLIENT_ADYEN_ACCOUNT_BALANCE = 2;
	CLIENT_ADYEN_ACCOUNT_DESIRED_DEPOSIT = 3;
	CLIENT_ADYEN_ACCOUNT_ONBOARDING_PASSED_DATE = 4;
	CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_CODE = 5;
	CLIENT_ADYEN_ACCOUNT_LAST_TRANSFER_FUNDS_EXECUTION_DATE = 6;
	CLIENT_ADYEN_ACCOUNT_LAST_AUTO_DEBIT_DATE = 7;
	CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PAYOUT_STATE = 8;
	CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PROCESSING_STATE = 9;
	CLIENT_ADYEN_ACCOUNT_OUTSTANDING_TRANSFER_FUNDS = 10;
	CLIENT_ADYEN_ACCOUNT_TRIGGER_PAYOUT_PROCESS = 11;
	CLIENT_ADYEN_ACCOUNT_MARK_ADYEN_EVENT_AS_SETTLED = 12;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK = 13;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_FAILURE_SINCE = 1;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_EXPECTED_BALANCE = 2;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_BALANCE = 3;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_DIFF = 4;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_PENDING_BALANCE = 5;
	CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_ON_HOLD_BALANCE = 6;
}


@Injectable({
  providedIn: 'root',
})
export class ClientsApiService extends ApiBase
{
	consts = new Consts();

	constructor(h : HttpClient
			,	router : Router
			,	apiE : ApiErrorService
			,	zone : NgZone
			,	injector : Injector) {
		super(h, router, apiE, zone, injector, 'admin/clients');
	}

	protected version() : string {
		return '459608953aa1eb9197fab24a4bc115c3,ef53c3563372024166647ed811d15f49';
	}

	private dataWrapper = new ClientsApiRoot(this, false);

	get data() : ClientsApiRoot {
		return this.dataWrapper;
	}

	protected getRootWrapper() : ClientsApiRoot {
		return this.dataWrapper;
	}

	protected recreateRootWrapper() : void {
		this.dataWrapper = new ClientsApiRoot(this, false);
	}
}

		 export class ClientsApiRoot extends ApiListWrapper<ClientsApiClient>
{
	constructor(override readonly api : ClientsApiService | null,
		
		removeDestroyedItems : boolean = false
	) {
		super(api, null, removeDestroyedItems, '');
	}

	override wrapRawData(itemRawData : any) : ClientsApiClient {
		return new ClientsApiClient(this.api, this as unknown as ClientsApiRoot, {rawData: itemRawData});
	}

	protected containsPrimitives() : boolean {
		return false;
	}

	protected containsIds() : boolean {
		return false;
	}

	protected createInstance(_parent : ApiDataWrapperBase | null, removeDestroyedItems : boolean) : this {
		return new ClientsApiRoot(this.api, removeDestroyedItems) as unknown as typeof this;
	}

	protected get dni() : string {
		return '1';
	}

	override createNewItem(_initCode : ((newItem : ClientsApiClient) => void) | null = null, _backendId : Id | null = null) : ClientsApiClient {
		const newItem = new ClientsApiClient(this.api, this as unknown as ClientsApiRoot, {backendIdRaw: _backendId ? _backendId.rawData : undefined, initCode: _initCode});
		this.push(newItem);

		// notify others
		if(this.api)
			this.api.changed('');

		return newItem;
	}

	override attributeInfoThis : ApiAttributeInfo<ClientsApiRoot, ClientsApiRoot> = new ApiAttributeInfo<ClientsApiRoot, ClientsApiRoot>({
			apiObjWrapper: this as any as ClientsApiRoot,
			name: '',
			nodeName: 'ROOT',
			primitiveType: PApiPrimitiveTypes.ApiList,
			hasRightToGet: function(this : ClientsApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			hasRightToSet: function(this : ClientsApiRoot) {
				return ((this.api!.rightsService.requesterIs(AuthenticatedApiRole.SUPER_ADMIN)));
			},
			defaultValue: function(this : ClientsApiRoot, _nodeId : string) {return Meta.createNewList();},
			
		});
}

				 
export class ClientsApiClientBase extends ApiObjectWrapper<ClientsApiClient>
{
	constructor(override readonly api : ClientsApiService | null,
		override readonly parent : ClientsApiRoot | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ClientsApiClient> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClient as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ClientsApiClient, ClientsApiClient> = new ApiAttributeInfo<ClientsApiClient, ClientsApiClient>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'client',
			nodeName: 'CLIENT',
			defaultValue: function(this : ClientsApiClient, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			
		});
	attributeInfoCompanyName : ApiAttributeInfo<ClientsApiClient, string> = new ApiAttributeInfo<ClientsApiClient, string>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'companyName',
			nodeName: 'CLIENT_COMPANY_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : ClientsApiClient) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 1,
		});
	attributeInfoLocationName : ApiAttributeInfo<ClientsApiClient, string> = new ApiAttributeInfo<ClientsApiClient, string>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'locationName',
			nodeName: 'CLIENT_LOCATION_NAME',
			primitiveType: PApiPrimitiveTypes.string,
			validations: function(this : ClientsApiClient) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.string, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 2,
		});
	attributeInfoType : ApiAttributeInfo<ClientsApiClient, ClientsApiType> = new ApiAttributeInfo<ClientsApiClient, ClientsApiType>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'type',
			nodeName: 'CLIENT_TYPE',
			primitiveType: PApiPrimitiveTypes.Enum,
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});
	attributeInfoTestAccountDeadline : ApiAttributeInfo<ClientsApiClient, DateTime> = new ApiAttributeInfo<ClientsApiClient, DateTime>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'testAccountDeadline',
			nodeName: 'CLIENT_TEST_ACCOUNT_DEADLINE',
			primitiveType: PApiPrimitiveTypes.DateTime,
			hasRightToSet: () => false,
			rawDataIndex: 4,
		});
	attributeInfoTerminationDate : ApiAttributeInfo<ClientsApiClient, Date> = new ApiAttributeInfo<ClientsApiClient, Date>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'terminationDate',
			nodeName: 'CLIENT_TERMINATION_DATE',
			primitiveType: PApiPrimitiveTypes.Date,
			isAvailableByBusinessLogic: function(this : ClientsApiClient) {
				return ((this.type === ClientsApiType.PAID)||(this.type === ClientsApiType.TERMINATED));
			},
			canSetByBusinessLogic: function(this : ClientsApiClient) {
				return ((this.type === ClientsApiType.PAID));
			},
			rawDataIndex: 5,
		});
	attributeInfoLocationCountry : ApiAttributeInfo<ClientsApiClient, ClientsApiCountry> = new ApiAttributeInfo<ClientsApiClient, ClientsApiCountry>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'locationCountry',
			nodeName: 'CLIENT_LOCATION_COUNTRY',
			primitiveType: PApiPrimitiveTypes.Enum,
			hasRightToSet: () => false,
			rawDataIndex: 6,
		});
	attributeInfoTestAccountDuration : ApiAttributeInfo<ClientsApiClient, Days> = new ApiAttributeInfo<ClientsApiClient, Days>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'testAccountDuration',
			nodeName: 'CLIENT_TEST_ACCOUNT_DURATION',
			primitiveType: PApiPrimitiveTypes.Days,
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : ClientsApiClient) {
				return ((this.type === ClientsApiType.TEST || this.type === ClientsApiType.TEST_EXPIRED));
			},
			validations: function(this : ClientsApiClient) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Days, undefined);						return null;
					},
					() => {
return this.api!.validators.min(1, true, PApiPrimitiveTypes.Days, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoDiscountCode : ApiAttributeInfo<ClientsApiClient, string> = new ApiAttributeInfo<ClientsApiClient, string>({
			apiObjWrapper: this as any as ClientsApiClient,
			name: 'discountCode',
			nodeName: 'CLIENT_DISCOUNT_CODE',
			primitiveType: PApiPrimitiveTypes.string,
			isDetailedAttribute: true,
			validations: function(this : ClientsApiClient) {
				return [
					() => {
						return this.api!.validators.maxLength(50, PApiPrimitiveTypes.string, undefined);
					},
				];
			},
			asyncValidations: function(this : ClientsApiClient) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});

	/**
     *  Name of the client.
	 *
	 * @type {string}
     */
	get companyName() : string {
		this.getterDebugValidations(this.attributeInfoCompanyName, false);
		return this.data[1];
	}

	set companyName(v : string) {
        this.setterImpl(1, v, 'companyName', false, null, null);
	}

	/**
     *  Location Name of the client.
	 *
	 * @type {string}
     */
	get locationName() : string {
		this.getterDebugValidations(this.attributeInfoLocationName, false);
		return this.data[2];
	}

	set locationName(v : string) {
        this.setterImpl(2, v, 'locationName', false, null, null);
	}

	/**
     *  Client type. Read-only
	 *
	 * @type {ClientsApiType}
     */
	get type() : ClientsApiType {
		this.getterDebugValidations(this.attributeInfoType, false);
		return this.data[3];
	}

	set typeTestSetter(v : ClientsApiType) {
        this.setterImpl(3, v, 'type', true, null, null);
	}

	/**
     *  Deadline of the test account. Read-only.
	 *
	 * @type {DateTime}
     */
	get testAccountDeadline() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoTestAccountDeadline, false);
		return this.data[4];
	}

	set testAccountDeadlineTestSetter(v : DateTime | null) {
        this.setterImpl(4, v, 'testAccountDeadline', true, null, null);
	}

	/**
     *  Date when the account will get terminated.
	 *
	 * @type {Date}
     */
	get terminationDate() : Date | null {
		this.getterDebugValidations(this.attributeInfoTerminationDate, false);
		// This attribute has a show() condition (When we reach this line then show() is true). If no value is available ensure that it is initialized by its default value.
		if(this.data[5] === undefined) {
			this.data[5] = this.attributeInfoTerminationDate.defaultValue;
			
		}
		return this.data[5];
	}

	set terminationDate(v : Date | null) {
        this.setterImpl(5, v, 'terminationDate', false, null, null);
	}

	/**
     *  The location country of this client. Read-only.
	 *
	 * @type {ClientsApiCountry}
     */
	get locationCountry() : ClientsApiCountry {
		this.getterDebugValidations(this.attributeInfoLocationCountry, false);
		return this.data[6];
	}

	set locationCountryTestSetter(v : ClientsApiCountry) {
        this.setterImpl(6, v, 'locationCountry', true, null, null);
	}

	private adyenAccountWrapper : ClientsApiClientAdyenAccount = new ClientsApiClientAdyenAccount(this.api,
		this as unknown as ClientsApiClient, );
	public attributeInfoAdyenAccount = this.adyenAccountWrapper.attributeInfoThis;

	/**
     *  Information about Online-Payment.
     */
	get adyenAccount() : ClientsApiClientAdyenAccount {
		this.getterDebugValidations(this.attributeInfoAdyenAccount, false);
		return this.adyenAccountWrapper;
	}

	set adyenAccountTestSetter(v : ClientsApiClientAdyenAccount) {
        this.setterImpl(7, v.rawData, 'adyenAccount', true, null, () => {this.adyenAccountWrapper = v;});
	}

	private billingWrapper : ClientsApiClientBilling = new ClientsApiClientBilling(this.api,
		this as unknown as ClientsApiClient, );
	public attributeInfoBilling = this.billingWrapper.attributeInfoThis;

	/**
     *  Information about billing of this client.
     */
	get billing() : ClientsApiClientBilling {
		this.getterDebugValidations(this.attributeInfoBilling, false);
		return this.billingWrapper;
	}

	set billingTestSetter(v : ClientsApiClientBilling) {
        this.setterImpl(8, v.rawData, 'billing', true, null, () => {this.billingWrapper = v;});
	}

	/**
     * (Detail)  Test account duration in days.
	 *
	 * @type {Days}
     */
	get testAccountDuration() : Days {
		this.getterDebugValidations(this.attributeInfoTestAccountDuration, false);
		// This attribute has a show() condition (When we reach this line then show() is true). If no value is available ensure that it is initialized by its default value.
		if(this.data[9] === undefined) {
			this.data[9] = this.attributeInfoTestAccountDuration.defaultValue;
			
		}
		return this.data[9];
	}

	set testAccountDuration(v : Days) {
        this.setterImpl(9, v, 'testAccountDuration', false, null, null);
	}

	/**
     * (Detail)  The discount-code which was entered by client owner when transferring the client to paid account.
	 *
	 * @type {string}
     */
	get discountCode() : string | null {
		this.getterDebugValidations(this.attributeInfoDiscountCode, false);
		return this.data[10];
	}

	set discountCode(v : string | null) {
        this.setterImpl(10, v, 'discountCode', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.adyenAccountWrapper._fixIds(_idReplacements);
		this.billingWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.adyenAccountWrapper._updateRawData(data && data[7] !== undefined ? data[7] : null, generateMissingData);
		this.billingWrapper._updateRawData(data && data[8] !== undefined ? data[8] : null, generateMissingData);
	}

	protected get dni() : string {
		return '2';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '2', { success: success, error: error, searchParams: searchParams});
	}
}

export enum ClientsApiType {
	TEST_NEED_VERIFICATION = 1,
	TEST = 2,
	TEST_EXPIRED = 3,
	PAID = 4,
	TERMINATED = 5,
}
export enum ClientsApiCountry {
	GERMANY = 1,
	AUSTRIA = 2,
	SWITZERLAND = 3,
	ITALY = 4,
	ROMANIA = 5,
	NETHERLANDS = 6,
	BELGIUM = 7,
	UNITED_KINGDOM = 8,
	CZECH_REPUBLIC = 9,
	SWEDEN = 10,
	LUXEMBOURG = 11,
}
		 
export class ClientsApiClientAdyenAccount extends ApiObjectWrapper<ClientsApiClientAdyenAccount>
{
	constructor(override readonly api : ClientsApiService | null,
		override readonly parent : ClientsApiClient | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ClientsApiClientAdyenAccount> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClientAdyenAccount as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiClientAdyenAccount> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiClientAdyenAccount>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'adyenAccount',
			nodeName: 'CLIENT_ADYEN_ACCOUNT',
			defaultValue: function(this : ClientsApiClientAdyenAccount, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 7,
		});
	attributeInfoAutoDebitEnabled : ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'autoDebitEnabled',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_AUTO_DEBIT_ENABLED',
			primitiveType: PApiPrimitiveTypes.boolean,
			validations: function(this : ClientsApiClientAdyenAccount) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientAdyenAccount) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientAdyenAccount, _nodeId : string) {return false;},
			rawDataIndex: 1,
		});
	attributeInfoBalance : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'balance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_BALANCE',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			hasRightToSet: () => false,
			rawDataIndex: 2,
		});
	attributeInfoDesiredDeposit : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'desiredDeposit',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_DESIRED_DEPOSIT',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});
	attributeInfoOnboardingPassedDate : ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'onboardingPassedDate',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ONBOARDING_PASSED_DATE',
			primitiveType: PApiPrimitiveTypes.DateTime,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 4,
		});
	attributeInfoAccountHolderCode : ApiAttributeInfo<ClientsApiClientAdyenAccount, string> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, string>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'accountHolderCode',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_CODE',
			primitiveType: PApiPrimitiveTypes.string,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 5,
		});
	attributeInfoLastTransferFundsExecutionDate : ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'lastTransferFundsExecutionDate',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_LAST_TRANSFER_FUNDS_EXECUTION_DATE',
			primitiveType: PApiPrimitiveTypes.DateTime,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 6,
		});
	attributeInfoLastAutoDebitDate : ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'lastAutoDebitDate',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_LAST_AUTO_DEBIT_DATE',
			primitiveType: PApiPrimitiveTypes.DateTime,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 7,
		});
	attributeInfoAccountHolderPayoutState : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderPayoutState> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderPayoutState>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'accountHolderPayoutState',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PAYOUT_STATE',
			primitiveType: PApiPrimitiveTypes.Enum,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 8,
		});
	attributeInfoAccountHolderProcessingState : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderProcessingState> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientsApiAccountHolderProcessingState>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'accountHolderProcessingState',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_ACCOUNT_HOLDER_PROCESSING_STATE',
			primitiveType: PApiPrimitiveTypes.Enum,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 9,
		});
	attributeInfoOutstandingTransferFunds : ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'outstandingTransferFunds',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_OUTSTANDING_TRANSFER_FUNDS',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 10,
		});
	attributeInfoTriggerPayoutProcess : ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, boolean>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'triggerPayoutProcess',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_TRIGGER_PAYOUT_PROCESS',
			primitiveType: PApiPrimitiveTypes.boolean,
			isDetailedAttribute: true,
			validations: function(this : ClientsApiClientAdyenAccount) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientAdyenAccount) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientAdyenAccount, _nodeId : string) {return false;},
			rawDataIndex: 11,
		});
	attributeInfoMarkAdyenEventAsSettled : ApiAttributeInfo<ClientsApiClientAdyenAccount, Integer> = new ApiAttributeInfo<ClientsApiClientAdyenAccount, Integer>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccount,
			name: 'markAdyenEventAsSettled',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_MARK_ADYEN_EVENT_AS_SETTLED',
			primitiveType: PApiPrimitiveTypes.Integer,
			isDetailedAttribute: true,
			rawDataIndex: 12,
		});

	/**
     *  Is adyen auto-debit enabled for this account?
	 *
	 * @type {boolean}
     */
	get autoDebitEnabled() : boolean {
		this.getterDebugValidations(this.attributeInfoAutoDebitEnabled, false);
		return this.data[1];
	}

	set autoDebitEnabled(v : boolean) {
        this.setterImpl(1, v, 'autoDebitEnabled', false, null, null);
	}

	/**
     *  The current online balance.
	 *
	 * @type {ClientCurrency}
     */
	get balance() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoBalance, false);
		return this.data[2];
	}

	set balanceTestSetter(v : ClientCurrency) {
        this.setterImpl(2, v, 'balance', true, null, null);
	}

	/**
     * (Detail)  The current deposit level we expect currently for this client.
	 *
	 * @type {ClientCurrency}
     */
	get desiredDeposit() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoDesiredDeposit, false);
		return this.data[3];
	}

	set desiredDepositTestSetter(v : ClientCurrency) {
        this.setterImpl(3, v, 'desiredDeposit', true, null, null);
	}

	/**
     * (Detail)  When has this account passed Adyen onboarding?
	 *
	 * @type {DateTime}
     */
	get onboardingPassedDate() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoOnboardingPassedDate, false);
		return this.data[4];
	}

	set onboardingPassedDateTestSetter(v : DateTime | null) {
        this.setterImpl(4, v, 'onboardingPassedDate', true, null, null);
	}

	/**
     * (Detail)  Adyens account holder code which uniquely identifies the sub-merchant.
	 *
	 * @type {string}
     */
	get accountHolderCode() : string | null {
		this.getterDebugValidations(this.attributeInfoAccountHolderCode, false);
		return this.data[5];
	}

	set accountHolderCodeTestSetter(v : string | null) {
        this.setterImpl(5, v, 'accountHolderCode', true, null, null);
	}

	/**
     * (Detail)  When was the last transfer-funds executed. This is the pre-step for payouts (See https://drplano.atlassian.net/l/c/7A3Zd2Kz).
	 *
	 * @type {DateTime}
     */
	get lastTransferFundsExecutionDate() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoLastTransferFundsExecutionDate, false);
		return this.data[6];
	}

	set lastTransferFundsExecutionDateTestSetter(v : DateTime | null) {
        this.setterImpl(6, v, 'lastTransferFundsExecutionDate', true, null, null);
	}

	/**
     * (Detail)  When was the last auto-debit executed?
	 *
	 * @type {DateTime}
     */
	get lastAutoDebitDate() : DateTime | null {
		this.getterDebugValidations(this.attributeInfoLastAutoDebitDate, false);
		return this.data[7];
	}

	set lastAutoDebitDateTestSetter(v : DateTime | null) {
        this.setterImpl(7, v, 'lastAutoDebitDate', true, null, null);
	}

	/**
     * (Detail)  AccountHolder-Payout State for Adyen
	 *
	 * @type {ClientsApiAccountHolderPayoutState}
     */
	get accountHolderPayoutState() : ClientsApiAccountHolderPayoutState {
		this.getterDebugValidations(this.attributeInfoAccountHolderPayoutState, false);
		return this.data[8];
	}

	set accountHolderPayoutStateTestSetter(v : ClientsApiAccountHolderPayoutState) {
        this.setterImpl(8, v, 'accountHolderPayoutState', true, null, null);
	}

	/**
     * (Detail)  AccountHolder-Processing State for Adyen
	 *
	 * @type {ClientsApiAccountHolderProcessingState}
     */
	get accountHolderProcessingState() : ClientsApiAccountHolderProcessingState {
		this.getterDebugValidations(this.attributeInfoAccountHolderProcessingState, false);
		return this.data[9];
	}

	set accountHolderProcessingStateTestSetter(v : ClientsApiAccountHolderProcessingState) {
        this.setterImpl(9, v, 'accountHolderProcessingState', true, null, null);
	}

	/**
     * (Detail)  The fees which still need to be transferred by transfer-funds (See https://drplano.atlassian.net/l/c/7A3Zd2Kz). This includes the OUTSTANDING and PENDING state.
	 *
	 * @type {ClientCurrency}
     */
	get outstandingTransferFunds() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoOutstandingTransferFunds, false);
		return this.data[10];
	}

	set outstandingTransferFundsTestSetter(v : ClientCurrency) {
        this.setterImpl(10, v, 'outstandingTransferFunds', true, null, null);
	}

	/**
     * (Detail)  Set this to "true" to trigger the whole payout process (See https://drplano.atlassian.net/l/c/7A3Zd2Kz). This ignores all preconditions (last payout/TF, consistency check) and will trigger it again.
	 *
	 * @type {boolean}
     */
	get triggerPayoutProcess() : boolean {
		this.getterDebugValidations(this.attributeInfoTriggerPayoutProcess, false);
		return this.data[11];
	}

	set triggerPayoutProcess(v : boolean) {
        this.setterImpl(11, v, 'triggerPayoutProcess', false, null, null);
	}

	/**
     * (Detail)  Set here the id of an Adyen event which should be marked as settled. This is typically used to fix failing consistency check.
	 *
	 * @type {Integer}
     */
	get markAdyenEventAsSettled() : Integer | null {
		this.getterDebugValidations(this.attributeInfoMarkAdyenEventAsSettled, false);
		return this.data[12];
	}

	set markAdyenEventAsSettled(v : Integer | null) {
        this.setterImpl(12, v, 'markAdyenEventAsSettled', false, null, null);
	}

	private consistencyCheckWrapper : ClientsApiClientAdyenAccountConsistencyCheck = new ClientsApiClientAdyenAccountConsistencyCheck(this.api,
		this as unknown as ClientsApiClientAdyenAccount, );
	public attributeInfoConsistencyCheck = this.consistencyCheckWrapper.attributeInfoThis;

	/**
     * (Detail)  Returns information for debugging our Adyen balance consistency check.
     */
	get consistencyCheck() : ClientsApiClientAdyenAccountConsistencyCheck {
		this.getterDebugValidations(this.attributeInfoConsistencyCheck, false);
		// This attribute has a show() condition (When we reach this line then show() is true). If no value is available ensure that it is initialized by its default value.
		if(this.data[13] === undefined) {
			this.data[13] = this.attributeInfoConsistencyCheck.defaultValue;
			this.consistencyCheckWrapper._updateRawData(this.data[13], true);
		}
		return this.consistencyCheckWrapper;
	}

	set consistencyCheckTestSetter(v : ClientsApiClientAdyenAccountConsistencyCheck) {
        this.setterImpl(13, v.rawData, 'consistencyCheck', true, null, () => {this.consistencyCheckWrapper = v;});
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
		this.consistencyCheckWrapper._fixIds(_idReplacements);
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT_ADYEN_ACCOUNT should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
		this.consistencyCheckWrapper._updateRawData(data && data[13] !== undefined ? data[13] : null, generateMissingData);
	}

	protected get dni() : string {
		return '9';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '9', { success: success, error: error, searchParams: searchParams});
	}
}

export enum ClientsApiAccountHolderPayoutState {
	PAYOUT_ALLOWED = 1,
	PAYOUT_DISABLED = 2,
}
export enum ClientsApiAccountHolderProcessingState {
	PROCESSING_ALLOWED = 1,
	PROCESSING_DISABLED = 2,
}
		 
export class ClientsApiClientAdyenAccountConsistencyCheck extends ApiObjectWrapper<ClientsApiClientAdyenAccountConsistencyCheck>
{
	constructor(override readonly api : ClientsApiService | null,
		override readonly parent : ClientsApiClientAdyenAccount | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ClientsApiClientAdyenAccountConsistencyCheck> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClientAdyenAccountConsistencyCheck as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientsApiClientAdyenAccountConsistencyCheck> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientsApiClientAdyenAccountConsistencyCheck>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'consistencyCheck',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK',
			isDetailedAttribute: true,
			isAvailableByBusinessLogic: function(this : ClientsApiClientAdyenAccountConsistencyCheck) {
				return ((this.parent!.attributeInfoAccountHolderProcessingState.isAvailable && this.parent!.accountHolderProcessingState !== ClientsApiAccountHolderProcessingState.PROCESSING_DISABLED));
			},
			hasRightToSet: () => false,
			defaultValue: function(this : ClientsApiClientAdyenAccountConsistencyCheck, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 13,
		});
	attributeInfoBalanceConsistencyCheckFailureSince : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, DateTime> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, DateTime>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'balanceConsistencyCheckFailureSince',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_FAILURE_SINCE',
			primitiveType: PApiPrimitiveTypes.DateTime,
			hasRightToSet: () => false,
			rawDataIndex: 1,
		});
	attributeInfoExpectedBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'expectedBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_EXPECTED_BALANCE',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			hasRightToSet: () => false,
			rawDataIndex: 2,
		});
	attributeInfoActualBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'actualBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_BALANCE',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			hasRightToSet: () => false,
			rawDataIndex: 3,
		});
	attributeInfoDiff : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'diff',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_DIFF',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			hasRightToSet: () => false,
			rawDataIndex: 4,
		});
	attributeInfoActualPendingBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'actualPendingBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_PENDING_BALANCE',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			hasRightToSet: () => false,
			rawDataIndex: 5,
		});
	attributeInfoActualOnHoldBalance : ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency> = new ApiAttributeInfo<ClientsApiClientAdyenAccountConsistencyCheck, ClientCurrency>({
			apiObjWrapper: this as any as ClientsApiClientAdyenAccountConsistencyCheck,
			name: 'actualOnHoldBalance',
			nodeName: 'CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK_ACTUAL_ON_HOLD_BALANCE',
			primitiveType: PApiPrimitiveTypes.ClientCurrency,
			hasRightToSet: () => false,
			rawDataIndex: 6,
		});

	/**
     *  Since when is the consistency check failing? This will block payouts.
	 *
	 * @type {DateTime}
     */
	get balanceConsistencyCheckFailureSince() : DateTime {
		this.getterDebugValidations(this.attributeInfoBalanceConsistencyCheckFailureSince, false);
		return this.data[1];
	}

	set balanceConsistencyCheckFailureSinceTestSetter(v : DateTime) {
        this.setterImpl(1, v, 'balanceConsistencyCheckFailureSince', true, null, null);
	}

	/**
     *  What Adyen balance the consistency check expects.
	 *
	 * @type {ClientCurrency}
     */
	get expectedBalance() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoExpectedBalance, false);
		return this.data[2];
	}

	set expectedBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(2, v, 'expectedBalance', true, null, null);
	}

	/**
     *  Actual Adyen balance. This is the value we compare against "expectedBalance" during consistency check.
	 *
	 * @type {ClientCurrency}
     */
	get actualBalance() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoActualBalance, false);
		return this.data[3];
	}

	set actualBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(3, v, 'actualBalance', true, null, null);
	}

	/**
     *  The difference between expected and actual balance.
	 *
	 * @type {ClientCurrency}
     */
	get diff() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoDiff, false);
		return this.data[4];
	}

	set diffTestSetter(v : ClientCurrency) {
        this.setterImpl(4, v, 'diff', true, null, null);
	}

	/**
     *  Actual "pending" Adyen balance.
	 *
	 * @type {ClientCurrency}
     */
	get actualPendingBalance() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoActualPendingBalance, false);
		return this.data[5];
	}

	set actualPendingBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(5, v, 'actualPendingBalance', true, null, null);
	}

	/**
     *  Actual "on-hold" Adyen balance.
	 *
	 * @type {ClientCurrency}
     */
	get actualOnHoldBalance() : ClientCurrency {
		this.getterDebugValidations(this.attributeInfoActualOnHoldBalance, false);
		return this.data[6];
	}

	set actualOnHoldBalanceTestSetter(v : ClientCurrency) {
        this.setterImpl(6, v, 'actualOnHoldBalance', true, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT_ADYEN_ACCOUNT_CONSISTENCY_CHECK should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '38';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '38', { success: success, error: error, searchParams: searchParams});
	}
}

		 
export class ClientsApiClientBilling extends ApiObjectWrapper<ClientsApiClientBilling>
{
	constructor(override readonly api : ClientsApiService | null,
		override readonly parent : ClientsApiClient | null,
		// eslint-disable-next-line unicorn/no-object-as-default-parameter
		params : ApiObjectWrapperNewItemParams<ClientsApiClientBilling> | ApiObjectWrapperExistingRawDataParams = {backendIdRaw: undefined, initCode: null}
	) {
		super(api, parent, ClientsApiClientBilling as any);

		this._initObjectWrapper(params, false);
	}

	private backendId : Id | undefined = undefined;
	get id() : Id {
		return this.backendId === undefined ? Id.create(Meta.getNewItemId(this.rawData) as any) : this.backendId;
	}

	override attributeInfoThis : ApiAttributeInfo<ClientsApiClientBilling, ClientsApiClientBilling> = new ApiAttributeInfo<ClientsApiClientBilling, ClientsApiClientBilling>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'billing',
			nodeName: 'CLIENT_BILLING',
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return Meta.createNewObject(false, undefined, _nodeId);},
			rawDataIndex: 8,
		});
	attributeInfoCountry : ApiAttributeInfo<ClientsApiClientBilling, ClientsApiCountry> = new ApiAttributeInfo<ClientsApiClientBilling, ClientsApiCountry>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'country',
			nodeName: 'CLIENT_BILLING_COUNTRY',
			primitiveType: PApiPrimitiveTypes.Enum,
			hasRightToSet: () => false,
			rawDataIndex: 1,
		});
	attributeInfoHasToPayVat : ApiAttributeInfo<ClientsApiClientBilling, boolean> = new ApiAttributeInfo<ClientsApiClientBilling, boolean>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'hasToPayVat',
			nodeName: 'CLIENT_BILLING_HAS_TO_PAY_VAT',
			primitiveType: PApiPrimitiveTypes.boolean,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return false;},
			rawDataIndex: 2,
		});
	attributeInfoDisableInvoiceCreation : ApiAttributeInfo<ClientsApiClientBilling, boolean> = new ApiAttributeInfo<ClientsApiClientBilling, boolean>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'disableInvoiceCreation',
			nodeName: 'CLIENT_BILLING_DISABLE_INVOICE_CREATION',
			primitiveType: PApiPrimitiveTypes.boolean,
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return false;},
			rawDataIndex: 3,
		});
	attributeInfoDiscountType : ApiAttributeInfo<ClientsApiClientBilling, ClientsApiDiscountType> = new ApiAttributeInfo<ClientsApiClientBilling, ClientsApiDiscountType>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountType',
			nodeName: 'CLIENT_BILLING_DISCOUNT_TYPE',
			primitiveType: PApiPrimitiveTypes.Enum,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Enum, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 4,
		});
	attributeInfoVatNumber : ApiAttributeInfo<ClientsApiClientBilling, string> = new ApiAttributeInfo<ClientsApiClientBilling, string>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'vatNumber',
			nodeName: 'CLIENT_BILLING_VAT_NUMBER',
			primitiveType: PApiPrimitiveTypes.string,
			isDetailedAttribute: true,
			hasRightToSet: () => false,
			rawDataIndex: 5,
		});
	attributeInfoDiscountValue : ApiAttributeInfo<ClientsApiClientBilling, Percent|Euro> = new ApiAttributeInfo<ClientsApiClientBilling, Percent|Euro>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountValue',
			nodeName: 'CLIENT_BILLING_DISCOUNT_VALUE',
			primitiveType: () => (this.attributeInfoDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiPrimitiveTypes.Percent : PApiPrimitiveTypes.Euro,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.min(0, true, () => (this.attributeInfoDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiPrimitiveTypes.Percent : PApiPrimitiveTypes.Euro, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.max(() => this.discountType === ClientsApiDiscountType.PERCENT ? 1 : null, true, () => (this.attributeInfoDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiPrimitiveTypes.Percent : PApiPrimitiveTypes.Euro, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.required(() => (this.attributeInfoDiscountType.isAvailable && this.discountType === ClientsApiDiscountType.PERCENT) ? PApiPrimitiveTypes.Percent : PApiPrimitiveTypes.Euro, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 6,
		});
	attributeInfoDiscountStart : ApiAttributeInfo<ClientsApiClientBilling, Date> = new ApiAttributeInfo<ClientsApiClientBilling, Date>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountStart',
			nodeName: 'CLIENT_BILLING_DISCOUNT_START',
			primitiveType: PApiPrimitiveTypes.Date,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.max(() => this.discountEnd, false, PApiPrimitiveTypes.Date, this.attributeInfoDiscountEnd.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 7,
		});
	attributeInfoDiscountEnd : ApiAttributeInfo<ClientsApiClientBilling, DateExclusiveEnd> = new ApiAttributeInfo<ClientsApiClientBilling, DateExclusiveEnd>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountEnd',
			nodeName: 'CLIENT_BILLING_DISCOUNT_END',
			primitiveType: PApiPrimitiveTypes.DateExclusiveEnd,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.min(() => this.discountStart, false, PApiPrimitiveTypes.DateExclusiveEnd, this.attributeInfoDiscountStart.id, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 8,
		});
	attributeInfoBilledMembersCountMax : ApiAttributeInfo<ClientsApiClientBilling, Integer> = new ApiAttributeInfo<ClientsApiClientBilling, Integer>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'billedMembersCountMax',
			nodeName: 'CLIENT_BILLING_BILLED_MEMBERS_COUNT_MAX',
			primitiveType: PApiPrimitiveTypes.Integer,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.min(0, true, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 9,
		});
	attributeInfoBilledMembersCountSubtract : ApiAttributeInfo<ClientsApiClientBilling, Integer> = new ApiAttributeInfo<ClientsApiClientBilling, Integer>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'billedMembersCountSubtract',
			nodeName: 'CLIENT_BILLING_BILLED_MEMBERS_COUNT_SUBTRACT',
			primitiveType: PApiPrimitiveTypes.Integer,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.min(0, false, PApiPrimitiveTypes.Integer, undefined, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 10,
		});
	attributeInfoDiscountComment : ApiAttributeInfo<ClientsApiClientBilling, string> = new ApiAttributeInfo<ClientsApiClientBilling, string>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'discountComment',
			nodeName: 'CLIENT_BILLING_DISCOUNT_COMMENT',
			primitiveType: PApiPrimitiveTypes.string,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			rawDataIndex: 11,
		});
	attributeInfoCredit : ApiAttributeInfo<ClientsApiClientBilling, Euro> = new ApiAttributeInfo<ClientsApiClientBilling, Euro>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'credit',
			nodeName: 'CLIENT_BILLING_CREDIT',
			primitiveType: PApiPrimitiveTypes.Euro,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.min(0, true, PApiPrimitiveTypes.Euro, undefined, undefined);						return null;
					},
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.Euro, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			rawDataIndex: 12,
		});
	attributeInfoExemptGiftCardSalesFees : ApiAttributeInfo<ClientsApiClientBilling, boolean> = new ApiAttributeInfo<ClientsApiClientBilling, boolean>({
			apiObjWrapper: this as any as ClientsApiClientBilling,
			name: 'exemptGiftCardSalesFees',
			nodeName: 'CLIENT_BILLING_EXEMPT_GIFT_CARD_SALES_FEES',
			primitiveType: PApiPrimitiveTypes.boolean,
			isDetailedAttribute: true,
			canSetByBusinessLogic: function(this : ClientsApiClientBilling) {
				return ((!this.disableInvoiceCreation));
			},
			validations: function(this : ClientsApiClientBilling) {
				return [
					() => {
return this.api!.validators.required(PApiPrimitiveTypes.boolean, undefined);						return null;
					},
				];
			},
			asyncValidations: function(this : ClientsApiClientBilling) {
				return [
				];
			}
			,
			defaultValue: function(this : ClientsApiClientBilling, _nodeId : string) {return false;},
			rawDataIndex: 13,
		});

	/**
     *  The billing country of this client. Read-only.
	 *
	 * @type {ClientsApiCountry}
     */
	get country() : ClientsApiCountry | null {
		this.getterDebugValidations(this.attributeInfoCountry, false);
		return this.data[1];
	}

	set countryTestSetter(v : ClientsApiCountry | null) {
        this.setterImpl(1, v, 'country', true, null, null);
	}

	/**
     *  Does this client need to pay vat for dr-plano fee?
	 *
	 * @type {boolean}
     */
	get hasToPayVat() : boolean {
		this.getterDebugValidations(this.attributeInfoHasToPayVat, false);
		return this.data[2];
	}

	set hasToPayVat(v : boolean) {
        this.setterImpl(2, v, 'hasToPayVat', false, null, null);
	}

	/**
     *  Should the whole invoice creation be disabled? When "true" no Billomat client and VAT transactions will be created anymore for this client.
	 *
	 * @type {boolean}
     */
	get disableInvoiceCreation() : boolean {
		this.getterDebugValidations(this.attributeInfoDisableInvoiceCreation, false);
		return this.data[3];
	}

	set disableInvoiceCreation(v : boolean) {
        this.setterImpl(3, v, 'disableInvoiceCreation', false, null, null);
	}

	/**
     *  The current discountType for this account
	 *
	 * @type {ClientsApiDiscountType}
     */
	get discountType() : ClientsApiDiscountType {
		this.getterDebugValidations(this.attributeInfoDiscountType, false);
		return this.data[4];
	}

	set discountType(v : ClientsApiDiscountType) {
        this.setterImpl(4, v, 'discountType', false, null, null);
	}

	/**
     * (Detail)  The vat-number of this client. Only being send when client does not have to pay vat. Read-only.
	 *
	 * @type {string}
     */
	get vatNumber() : string | null {
		this.getterDebugValidations(this.attributeInfoVatNumber, false);
		return this.data[5];
	}

	set vatNumberTestSetter(v : string | null) {
        this.setterImpl(5, v, 'vatNumber', true, null, null);
	}

	/**
     * (Detail)  The discount value to be applied on the final invoice amount. Depending on "discountType" this is either an absolute discount (for all clients in euro) or a percentage discount.
	 *
	 * @type {Percent|Euro}
     */
	get discountValue() : Percent|Euro {
		this.getterDebugValidations(this.attributeInfoDiscountValue, false);
		return this.data[6];
	}

	set discountValue(v : Percent|Euro) {
        this.setterImpl(6, v, 'discountValue', false, null, null);
	}

	/**
     * (Detail)  The start-time from when "discountValue" should be applied. "null" means no such start-limit exists.
	 *
	 * @type {Date}
     */
	get discountStart() : Date | null {
		this.getterDebugValidations(this.attributeInfoDiscountStart, false);
		return this.data[7];
	}

	set discountStart(v : Date | null) {
        this.setterImpl(7, v, 'discountStart', false, null, null);
	}

	/**
     * (Detail)  The end-time from when "discountValue" should be applied. "null" means no such end-limit exists.
	 *
	 * @type {DateExclusiveEnd}
     */
	get discountEnd() : DateExclusiveEnd | null {
		this.getterDebugValidations(this.attributeInfoDiscountEnd, false);
		return this.data[8];
	}

	set discountEnd(v : DateExclusiveEnd | null) {
        this.setterImpl(8, v, 'discountEnd', false, null, null);
	}

	/**
     * (Detail)  The upper limit for maximum number of members to be billed for this client. "null" means no such limit exist.
	 *
	 * @type {Integer}
     */
	get billedMembersCountMax() : Integer | null {
		this.getterDebugValidations(this.attributeInfoBilledMembersCountMax, false);
		return this.data[9];
	}

	set billedMembersCountMax(v : Integer | null) {
        this.setterImpl(9, v, 'billedMembersCountMax', false, null, null);
	}

	/**
     * (Detail)  The value to be subtract from the maximum number of members to be billed.
	 *
	 * @type {Integer}
     */
	get billedMembersCountSubtract() : Integer | null {
		this.getterDebugValidations(this.attributeInfoBilledMembersCountSubtract, false);
		return this.data[10];
	}

	set billedMembersCountSubtract(v : Integer | null) {
        this.setterImpl(10, v, 'billedMembersCountSubtract', false, null, null);
	}

	/**
     * (Detail)  Internal comment about discounting.
	 *
	 * @type {string}
     */
	get discountComment() : string | null {
		this.getterDebugValidations(this.attributeInfoDiscountComment, false);
		return this.data[11];
	}

	set discountComment(v : string | null) {
        this.setterImpl(11, v, 'discountComment', false, null, null);
	}

	/**
     * (Detail)  A one-time credit which will be subtracted from "misc" invoices.
	 *
	 * @type {Euro}
     */
	get credit() : Euro {
		this.getterDebugValidations(this.attributeInfoCredit, false);
		return this.data[12];
	}

	set credit(v : Euro) {
        this.setterImpl(12, v, 'credit', false, null, null);
	}

	/**
     * (Detail)  Should gift-card sales fees be exempted for this client? Note, that still online-payment fees will occur if the gift cards are sold using online-payment. This is a global setting and will be applied when an invoice is created. You cannot limit it to a specific time-interval.
	 *
	 * @type {boolean}
     */
	get exemptGiftCardSalesFees() : boolean {
		this.getterDebugValidations(this.attributeInfoExemptGiftCardSalesFees, false);
		return this.data[13];
	}

	set exemptGiftCardSalesFees(v : boolean) {
        this.setterImpl(13, v, 'exemptGiftCardSalesFees', false, null, null);
	}


	override _fixIds(_idReplacements : Map<any, number>) : void {
	}

	override _updateRawData(data : any[] | null, generateMissingData : boolean) : void {
		super._updateRawData(data, generateMissingData);

		this.data = data;

		// update id wrapper
		const idRawData = Meta.getBackendId(data);
		if(idRawData === null) throw new Error('CLIENT_BILLING should not have a "null" id, as "javaIdNullable" is not set to true.');
		this.backendId = (idRawData === undefined) ? idRawData : Id.create(idRawData as any);

		// create missing/default data
		if(generateMissingData && data) {
			this.generateMissingData(data);
		}

		// propagate new raw data to children
	}

	protected get dni() : string {
		return '10';
	}

	static async loadDetailed(	api : ClientsApiService
						,	id : Id
						,	{success = null, error = null, searchParams = null} : ApiLoadArgs = {}) : Promise<HttpResponse<unknown>> {
		return await ApiObjectWrapper.loadDetailedImpl(api, id, '10', { success: success, error: error, searchParams: searchParams});
	}
}

export enum ClientsApiDiscountType {
	ABSOLUTE = 1,
	PERCENT = 2,
}


