<ng-content></ng-content>

<ng-template [ngIf]="!!childGroup">
	<p-validation-hint
		*ngFor="let error of visibleErrors"
		[control]="childGroup"
		[isInvalid]="true"
		[validationName]="error.key"
		[errorValue]="error.value"
		[checkTouched]="checkTouched"
	></p-validation-hint>
</ng-template>
