import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PShiftService } from '@plano/client/shared/p-shift-module/p-shift.service';
import { SchedulingApiCourseType, SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Component({
	selector: 'p-course-info',
	templateUrl: './p-course-info.component.html',
	styleUrls: ['./p-course-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PCourseInfoComponent {
	/** @see ApiAttributeInfo#readMode */
	@Input() public readMode : boolean = false;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isCourse : SchedulingApiShiftModel['isCourse'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public courseType : SchedulingApiShiftModel['courseType'] = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public onlyWholeCourseBookable : SchedulingApiShiftModel['onlyWholeCourseBookable'] | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isCourseOnline : SchedulingApiShift['isCourseOnline'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public isCourseCanceled : SchedulingApiShift['isCourseCanceled'] | null = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public minCourseParticipantCount : SchedulingApiShift['minCourseParticipantCount'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public currentCourseParticipantCount : SchedulingApiShift['currentCourseParticipantCount'] | null = null;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public maxCourseParticipantCount : SchedulingApiShift['maxCourseParticipantCount'] = null;

	private get courseStateInfoText() : string {
		if (this.isCourseCanceled) return this.localize.transform('Angebot fällt aus');
		if (this.isOpenCourse) return this.localize.transform('Offenes Angebot – benötigt keine Buchungen');
		// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
		return `${this.localize.transform('Mindestens')}: ${this.minCourseParticipantCount} | ${this.localize.transform('Gebucht')}: ${this.currentCourseParticipantCount} | ${this.localize.transform('Maximal')}: ${this.maxCourseParticipantCount ? this.maxCourseParticipantCount : '∞'}`;
	}

	private get ledStateInfoText() : string {
		if (this.ledOff) return `× ${this.localize.transform('Angebot ist online nicht sichtbar')}`;
		return `✓ ${this.localize.transform('Angebot ist online sichtbar')}`;
	}

	@HostBinding('attr.aria-label')
	@HostBinding('title') private get _title() : string {
		return `${this.courseStateInfoText} | ${this.ledStateInfoText}`;
	}

	@HostBinding('attr.role') private _role = 'status';

	constructor(
		private pShiftService : PShiftService,
		private localize : LocalizePipe,
	) {}

	public enums = enumsObject;

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get ledOff() : boolean {
		return !this.isCourseOnline;
	}

	// eslint-disable-next-line jsdoc/require-jsdoc
	public get isOpenCourse() : boolean {
		return this.courseType === SchedulingApiCourseType.NO_BOOKING;
	}

	/**
	 * Calculate color
	 */
	public get participantsCountTheme() : ReturnType<PShiftService['participantsCountTheme']> {
		return this.pShiftService.participantsCountTheme({
			currentCourseParticipantCount: this.currentCourseParticipantCount!,
			isCourseCanceled: this.isCourseCanceled!,
			maxCourseParticipantCount: this.maxCourseParticipantCount!,
			minCourseParticipantCount: this.minCourseParticipantCount!,
		}, {
			courseType: this.courseType,
			onlyWholeCourseBookable: this.onlyWholeCourseBookable!,
		});
	}

}
