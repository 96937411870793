import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastsService } from '@plano/client/service/toasts.service';
import { FilterService } from '@plano/client/shared/filter.service';
import { ApiBase, ApiListWrapper, ApiObjectWrapper, SchedulingApiMember, SchedulingApiService, SchedulingApiShift, SchedulingApiShiftModel } from '@plano/shared/api';
import { PUrlParamsServiceInterface } from '@plano/shared/core/interfaces/p-service.interface';
import { LogService } from '@plano/shared/core/log.service';
import { ModalService } from '@plano/shared/core/p-modal/modal.service';
import { LocalizePipe } from '@plano/shared/core/pipe/localize.pipe';
import { PRouterService } from '@plano/shared/core/router.service';
import { assumeDefinedToGetStrictNullChecksRunning } from '@plano/shared/core/utils/null-type-utils';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';

@Injectable( { providedIn: 'root' } )
/* eslint-disable-next-line @typescript-eslint/no-explicit-any, jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators */
export class PDetailFormUtilsService<T extends ApiObjectWrapper<any>> {
	constructor(
		private toastsService : ToastsService,
		private localize : LocalizePipe,
		private console : LogService,
		private modalService : ModalService,
		private pRouterService : PRouterService,
		private filterService : FilterService,
	) {
	}

	/**
	 * Save the provided new item to the database
	 */
	public async saveNewItem(
		api : SchedulingApiService,
		item : T,
		itemTitle : string,

		// PLANO-170639 remove null type from success and can be new item
		success : (() => void) | null = null,
		canBeANewItem : boolean | null = null,

		// PLANO-170639 remove this argument
		skipInternalNavigation : boolean = false,
	) : Promise<HttpResponse<unknown>> {
		assumeDefinedToGetStrictNullChecksRunning(item, 'item');
		// eslint-disable-next-line no-extra-boolean-cast
		assumeDefinedToGetStrictNullChecksRunning(!!itemTitle ? itemTitle : undefined, 'itemTitle');
		if (!item.isNewItem() && !canBeANewItem) throw new Error('This is not a new item');

		api.mergeDataCopy();
		this.console.debug('saveNewItem(…) > api.mergeDataCopy()');

		const text : string = this.localize.transform({ sourceString: '${itemTitle} wurde angelegt.', params: { itemTitle: itemTitle }});
		return item.saveDetailed({
			success: () : void => {
				this.toastsService.addToast({
					content: text,
					theme: enumsObject.PThemeEnum.SUCCESS,
				});
				if (success) success();

				// PLANO-170639/PLANO-172049 remove these if conditions
				if (item.rawData === null) {
					this.console.warn('No raw data for item! Backend did not send the data back?');
				}
				if (!skipInternalNavigation) {
					const itemFragment = item instanceof SchedulingApiShift ? item.id.toPrettyString() : item.id?.toString() ?? null;
					// eslint-disable-next-line ban/ban -- intended navigation
					void this.pRouterService.navigate([this.pRouterService.navBackInfo().url], {fragment: itemFragment ?? undefined});

					this.hideItemIfGroupHidden(api, item);
				}

			},
		});
	}

	/**
	 * If when creating a member or a shiftModel the group is hidden then the newly created item
	 * should be hidden too. Meaning that for a shiftModel if the area of activities is filtered out, then
	 * the new one should be too and for members, if all active users are hidden then the new one should be too.
	 */
	private hideItemIfGroupHidden(api : ApiBase, item : T) : void {
		if (item instanceof SchedulingApiShiftModel) {
			if (!this.filterService.isVisible(
				api.schedulingApi.data.shiftModels.filterBy(existingShiftModel =>
					existingShiftModel.parentName === item.parentName &&
					!existingShiftModel.id.equals(item.id)),
			)
			) {
				this.filterService.toggleItem(item);
			}
		} else if (item instanceof SchedulingApiMember && !this.filterService.isVisible(api.schedulingApi.data.members)) {
			this.filterService.toggleItem(item);
		}
	}

	/**
	 * Create new item which than can be filled with data from the form
	 */
	public async createNewItem(
		api : SchedulingApiService,
		items : ApiListWrapper<T>,
		paramsService : PUrlParamsServiceInterface,
	) : Promise<T> {
		const createIt = () : T => {
			api.createDataCopy();
			this.console.debug('createNewItem(…) > api.createDataCopy()');
			return items.createNewItem();
		};

		if (api.isLoaded()) {
			return createIt();
		}

		// Make sure other data is loaded so that a new item can be created.
		paramsService.updateQueryParams();
		assumeDefinedToGetStrictNullChecksRunning(paramsService.queryParams, 'paramsService.queryParams');
		await api.load({
			searchParams: paramsService.queryParams,
		});
		return createIt();
	}

	/** onDestroy */
	public onDestroy(api : ApiBase) : void {
		if (api.hasDataCopy()) {
			api.dismissDataCopy();
			this.console.debug('onDestroy(…) > api.dismissDataCopy()');
		}
	}

	/**
	 * Handle Click on delete button
	 */
	public onRemoveClick({
		modalTitle = undefined!,
		description = undefined!,
		itemName = undefined!,
		action = undefined,
		api = undefined!,
		items = undefined!,
		item = undefined!,
		removeItemFn = undefined,
	} : {
		modalTitle : string, description : string, itemName : string, action ?: string,
		api : ApiBase, items : ApiListWrapper<T>, item : T,

		/**
		 * A function that removes the item.
		 * Setting this overwrites the default how a item gets removed (default is item gets removed from items array).
		 *
		 * @example removeItemFn: () => { item.trashed = true; }
		 */
		removeItemFn ?: () => void,
	}) : void {
		void this.modalService.openDefaultModal({
			modalTitle: modalTitle,
			description: description,
			closeBtnLabel: this.localize.transform({
				sourceString: 'Ja, ${action}',
				params: {
					// eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
					action: action ? action : this.localize.transform('Löschen'),
				},
			}),
			hideDismissBtn: false,
		}, {
			theme: enumsObject.PThemeEnum.DANGER,
			centered: true,
			size: enumsObject.BootstrapSize.MD,
		}).result.then(async (value) => {
			if (value.modalResult === 'success') {
				const afterSuccess = async () : Promise<void> => {
					this.pRouterService.navBack();
					await api.save();
					this.console.debug('onRemoveClick(…) > api.save()');

					this.toastsService.addToast({
						title: null,
						content: this.localize.transform({
							sourceString: '»${item}« wurde gelöscht.',
							params: {
								item : itemName,
							},
						}),
						theme: enumsObject.PThemeEnum.DANGER,
						visibilityDuration: 'medium',
					});
				};

				if (removeItemFn) {
					removeItemFn();
				} else {
					// Remove this item from the list of items
					items.removeItem(item);
				}
				return afterSuccess();
			}
		});
	}

	// /**
	//  * Get id from route
	//  */
	// public get routeId() : Id | null {
	// 	const ID_AS_STRING = this.activatedRoute.snapshot.paramMap.get('id');
	// 	if (ID_AS_STRING === '0') return null;
	// 	if (ID_AS_STRING === null) return null;
	// if (!+ID_AS_STRING) return null;
	// 	return Id.create(+ID_AS_STRING);
	// }

	// /**
	//  * Get the item by the provided id
	//  */
	// private async getByRouteId(api : ApiBase) : Promise<boolean | null> {
	// 	if (!this.routeId) return false;

	// 	let item : SchedulingApiAbsence | null = null;
	// 	// TODO: PLANO-156519
	// 	if (this.api.data.attributeInfoAbsences.isAvailable) item = this.api.data.absences.get(this.routeId);

	// 	if (!item) {
	// 		await SchedulingApiAbsence.loadDetailed(this.api, this.routeId);
	// 		item = this.api.data.absences.get(this.routeId);
	// 		this.item = item;
	// 		return true;
	// 	}
	// 	if (item.isNewItem()) {
	// 		this.item = item;
	// 		return true;
	// 	}

	// 	await item.loadDetailed();
	// 	this.item = item;
	// 	return true;
	// }
}
